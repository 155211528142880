import {useCallback, useEffect, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'
import {useDispatch} from 'react-redux'
import colors from 'tailwindcss/colors'
import {FaSearch} from 'react-icons/fa'

import {Button, Input, SelectField} from 'app/common'

import {getFormLists, updateFormFromApi} from 'redux-src'

import {FormTempleteList} from './components'
import {SettingsHeader} from 'app/pages/settings/components'
import {useDebounceValue} from 'hooks'

export const FormsMainPage = () => {
  const [searchValue, setSearchValue] = useState<string>()
  const [data, {setValue}] = useFormInput({
    status: statusOptions?.find((item) => item?.value === 'all')
  })

  const {
    navigation: {navigate, routes}
  } = useNavigation()

  const dispatch = useDispatch()
  const debounceValue = useDebounceValue(searchValue)

  const fetchFormList = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getFormLists({page, limit, search: debounceValue}))
    },
    [debounceValue, dispatch]
  )

  useEffect(() => {
    const params = {
      page: 1,
      limit: 10,
      search: debounceValue,
      status: data?.status?.value === 'all' ? undefined : data?.status?.value
    }

    dispatch(getFormLists(params))
  }, [data?.status?.value, debounceValue, dispatch])

  return (
    <div>
      <SettingsHeader
        title="Forms"
        overview
        help
        subHeader={`The template builder allows you to create custom templates that are
          specific to your business.`}
      />

      <div className="my-20 flex gap-10 w-full justify-between">
        <div className="flex gap-10">
          <Input
            name="search"
            value={searchValue}
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
            placeholder="Search forms"
            containerClass="w-[200px]"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <SelectField
            options={statusOptions}
            className="w-[100px]"
            value={data?.status}
            onChangeValue={(value) => {
              setValue('status', value)
            }}
          />
        </div>
        <div>
          <Button
            title={'New Template'}
            onClick={() => {
              dispatch(
                updateFormFromApi(undefined, () => {
                  navigate(routes['FormBuilder Create Page'].path)
                })
              )
            }}
          />
        </div>
      </div>
      <div className="my-10 border-b-1 border-gray-200" />
      <div className="mt-24">
        <FormTempleteList fetchFormList={fetchFormList} />
      </div>
    </div>
  )
}

const statusOptions = [
  {
    id: 1,
    label: 'All Status',
    value: 'all'
  },
  {
    id: 2,
    label: 'Draft',
    value: 'draft'
  },

  {
    id: 3,
    label: 'Published',
    value: 'published'
  }
]
