import {Button, Input} from 'app/common'
import React, {useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'
import {SupplierModal} from '../supplierModal/supplierModal.component'
import {SettingsHeader} from 'app/pages/settings/components'

export const Supplier = ({setSearchValue}: any) => {
  const [newSupplier, setNewSupplier] = useState(false)

  return (
    <div>
      <SettingsHeader
        title={'Suppliers'}
        append={
          <Button
            title="Add New Supplier"
            onClick={() => setNewSupplier(true)}
          />
        }
        subHeader={'The list allows you to manage suppliers'}
      />

      <div className="my-20">
        <div className="flex items-center justify-between">
          <Input
            placeholder="Search suppliers"
            name="filterValue"
            onChange={(e: any) => {
              setSearchValue(e.target.value)
            }}
            containerClass="w-[300px]"
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
          />
        </div>
      </div>

      <div className="my-10 mb-24 border-b-1 border-gray-200" />
      <SupplierModal
        itemTypeModal={newSupplier}
        close={() => setNewSupplier(false)}
      />
    </div>
  )
}
