import { BsFillPatchCheckFill } from 'react-icons/bs'
import colors from 'tailwindcss/colors'

export const PasswordValidationInfo = () => {
	return (
		<div className="p-16 bg-blue-100 rounded-md text-gray-500">
			<h1 className="font-bold text-md mb-12">
				To ensure your account is secure a password must:
			</h1>

			<div className="flex items-center gap-8 text-sm text-gray-500 mb-8">
				<BsFillPatchCheckFill color={colors.blue[400]} size={18} />
				<p>Contain 8 or more characters</p>
			</div>
			<div className="flex items-center gap-8 text-sm text-gray-500 mb-8">
				<BsFillPatchCheckFill color={colors.blue[400]} size={18} />
				<p>Include both upper and lower case letters</p>
			</div>
			<div className="flex items-center gap-8 text-sm text-gray-500">
				<BsFillPatchCheckFill color={colors.blue[400]} size={18} />
				<p>Include at least one number</p>
			</div>

			<h1 className="font-bold text-md my-12">
				For added security your password should:
			</h1>

			<div className="flex items-center gap-8 text-sm text-gray-500 mb-8">
				<BsFillPatchCheckFill color={colors.blue[400]} size={18} />
				<p>Include at least one special character (e.g. #$%&)</p>
			</div>
		</div>
	)
}
