import { useCallback, useEffect, useState } from 'react'
import { TransitionBlock, AnimatedBlock, bInterpolate } from 'react-ui-animate'
import { BsDashLg } from 'react-icons/bs'
import { MdClose, MdSettings } from 'react-icons/md'
import { FaWindowMaximize } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth, useNavigation } from 'react-auth-navigation'
import {
  checkUncheckChecklist,
  getJobCheckListById,
  getSiteVisitCheckList,
  updateJobChecklist,
} from '../../../../../../../redux-src'
import moment from 'moment'
import { Button, Loading } from '../../../../../../common'
import { ChecklistStatusChip } from '../../../../components'

export const SiteVisitBottomModal = ({
  isOpen,
  onClose,
  checklistDetails: { checklistId, status },
}: {
  isOpen: boolean
  onClose: () => void
  checklistDetails: {
    checklistId: number
    status: string
  }
}) => {
  const { params } = useNavigation()
  const { siteVisitId, projectId } = params as any

  const dispatch = useDispatch()
  const { companyDetails, toast, userDetails } = useAuth()

  const [minimized, setMinimized] = useState(false)
  const [checklistDetails, setChecklistDetails] = useState<any>({
    id: 0,
    name: '',
    due_date: '',
    created_at: '',
    template_id: 0,
    site_visit_id: 0,
  })
  const [checklistItems, setChecklistItems] = useState<any>([
    {
      id: 0,
      type: '',
      checked_by: 0,
      is_checked: false,
      description: '',
      checklist_id: 0,
    },
  ])

  const { getSiteVisitDetail } = useSelector((state: any) => state.siteVisit)
  const { get_loading, checklistById, update_item_loading, checklist_item_id } =
    useSelector((state: any) => state.checkList)

  const getJobChecklistByIdSuccessCallback = useCallback(() => {
    // const { checklist_details, checklist_items } = checklistById
    setChecklistDetails(checklistById?.checklist_details)
    setChecklistItems(checklistById?.checklist_items)
  }, [checklistById])

  useEffect(() => {
    if (checklistId === 0) {
      return
    }
    dispatch(getJobCheckListById(Number(checklistId)))
  }, [checklistId, dispatch])

  useEffect(() => {
    getJobChecklistByIdSuccessCallback()
  }, [getJobChecklistByIdSuccessCallback])

  const onCheckListItemCheckedChange = (item: any) => {
    const existing = [...checklistItems]
    existing.map((_item) => {
      //! delete the following line in production
      _item.checked_by = userDetails?.appUser?.id
      if (_item.id === item.id) {
        _item.is_checked = !_item.is_checked
        _item.checked_by = userDetails?.appUser?.id
      }
      _item.checklist_id = undefined
      _item.position = undefined

      return item
    })
    // console.log('existing: ', existing)
    setChecklistItems(existing)
  }

  const handleCheckedSave = () => {
    const _requestData = {
      checklistItems,
    }

    dispatch(
      updateJobChecklist(
        Number(checklistId),
        _requestData,
        () => {
          dispatch(
            getSiteVisitCheckList(Number(projectId), Number(siteVisitId))
          )
          dispatch(getJobCheckListById(Number(checklistId)))
          getJobChecklistByIdSuccessCallback()
          onClose()
        },
        toast
      )
    )
  }

  const handleCheckedItemClicked = (
    checklistItemId: number,
    isChecked: boolean
  ) => {
    // // console.log('is_checked: ', isChecked)
    const _requestData = {
      is_checked: !isChecked,
    }

    // // console.log('checkItem: ', _requestData)

    dispatch(
      checkUncheckChecklist(
        checklistItemId,
        _requestData,
        () => {
          dispatch(
            getSiteVisitCheckList(Number(projectId), Number(siteVisitId))
          )
          dispatch(getJobCheckListById(Number(checklistId)))
          getJobChecklistByIdSuccessCallback()
        },
        toast
      )
    )
  }

  const CheckListItem = ({ item, index }: any) => {
    return (
      <div className="flex flex-wrap items-center text-sm" key={index}>
        {item.type === 'header' && (
          <div className="text-gray-800 text-[16px] font-[600]">
            {item.description}
          </div>
        )}
        {item.type === 'text' && (
          <div className="text-gray-700 text-[14px]">{item.description}</div>
        )}
        {item.type === 'checkbox' && (
          <div
            className="text-gray-700 text-[14px] flex items-center gap-10"
            onClick={() => handleCheckedItemClicked(+item.id, item.is_checked)}
          >
            {update_item_loading && item.id === checklist_item_id ? (
              <Loading medium />
            ) : (
              <input
                className=" cursor-pointer"
                type="checkbox"
                value={item.is_checked}
                checked={!!item.is_checked}
                onChange={() => onCheckListItemCheckedChange(item)}
              />
            )}
            {item.description}
          </div>
        )}
      </div>
    )
  }

  return (
    isOpen && (
      <TransitionBlock state={minimized}>
        {(animation) => (
          <AnimatedBlock
            style={{
              width: bInterpolate(animation.value, 500, 300),
              bottom: bInterpolate(animation.value, 0, -450),
            }}
            className="fixed right-32 bottom-0 bg-white w-[500px] h-[500px] shadow-2xl rounded-t-sm "
          >
            {get_loading ? (
              <Loading />
            ) : (
              <>
                <div className="bg-gray-500 text-white flex items-center justify-between rounded-t-sm p-16 gap-16 h-[50px] relative">
                  <div className="flex-1">
                    <input
                      value={checklistDetails?.name}
                      className="bg-transparent border-1 border-dashed border-gray-300 w-full px-4 font-bold"
                      onChange={(e: any) =>
                        setChecklistDetails({
                          ...checklistDetails,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="flex items-center gap-8">
                    {minimized ? (
                      <FaWindowMaximize
                        className="cursor-pointer"
                        onClick={() => setMinimized((prev) => !prev)}
                      />
                    ) : (
                      <BsDashLg
                        className="cursor-pointer"
                        onClick={() => setMinimized((prev) => !prev)}
                      />
                    )}

                    <MdClose
                      size={20}
                      className="cursor-pointer"
                      onClick={() => onClose()}
                    />
                  </div>
                </div>
                <div className="px-16 flex gap-14 flex-col overflow-y-auto h-[inherit] pb-80 relative">
                  {/* header */}
                  <div className="py-16 flex gap-14 flex-col border-b-1 border-gray-200">
                    <div className="flex justify-between">
                      <div className="flex text-sm text-gray-400">
                        Created{' '}
                        {moment(checklistDetails?.created_at).format('ddd, LL')}{' '}
                        by {companyDetails?.company_details?.name} on{' '}
                        <div className="text-blue-400 ml-4 cursor-pointer">
                          {getSiteVisitDetail?.title}
                        </div>
                      </div>
                      <div className="flex badge items-center uppercase">
                        <span>
                          <ChecklistStatusChip
                            checklist={{
                              checked_item: checklistById?.checked_item,
                              total_item: checklistById?.total_item,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="flex justify-center items-center gap-10">
                        <div className="hidden">
                          <div className="font-bold">Assigned To:</div>-
                        </div>
                        <div className="flex">
                          <div className="font-bold">Due Date:</div>
                          {moment(checklistDetails?.due_date).format('ddd, LL')}
                        </div>
                      </div>
                      {false && (
                        <div className="transition-all duration-100 ease-in-out flex justify-center items-center p-4 border-1 border-gray-200 rounded-sm hover:bg-gray-200 cursor-pointer">
                          <MdSettings />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* body */}
                  {checklistItems?.map((item: any, index: number) => {
                    return <CheckListItem item={item} index={index} />
                  })}
                </div>
                {isOpen && !minimized && (
                  <div className="fixed right-60 bottom-20 hidden">
                    <Button onClick={handleCheckedSave} title="Save" />
                  </div>
                )}
              </>
            )}
          </AnimatedBlock>
        )}
      </TransitionBlock>
    )
  )
}
