import {useEffect, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'

import {getAllSiteVisitsWebView} from '../../../redux-src'
import {MessageBox} from '../../components'
import {FloorPlan} from './components'

export const FloorMapWebView = () => {
  const dispatch = useDispatch()
  const {params, location} = useNavigation()

  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null)
  const projectId = (params as any).projectId
  const {siteVisits, loading} = useSelector(
    (state: any) => state.floorPlanWebView
  )
  const searchParams = new URLSearchParams(location.search)
  const siteVisitId = searchParams.get('siteVisitId')
  const token = searchParams.get('token')

  useEffect(() => {
    if (projectId && token) {
      dispatch(getAllSiteVisitsWebView({projectId: Number(projectId), token}))
    }
  }, [dispatch, projectId, token])

  useEffect(() => {
    if (siteVisits.length > 0 && siteVisitId) {
      setSelectedSiteVisit(
        siteVisits.find(
          (s: any) => s.site_visit_details.id === Number(siteVisitId)
        )
      )
    }
  }, [siteVisits, siteVisitId])

  if (loading) {
    return <div>Loading...</div>
  }

  if (!selectedSiteVisit) {
    return (
      <div className="mx-[10%]">
        <MessageBox message={'Sorry, No site visits found!'} />
      </div>
    )
  }

  return <FloorPlan siteVisit={selectedSiteVisit} />
}
