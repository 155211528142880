import {
  CREATE_LABOUR_ELEMENT_SETTING,
  UPDATE_LABOUR_ELEMENT_SETTING,
  GET_LABOUR_ELEMENT_SETTING,
  CREATE_LABOUR_ELEMENT_GROUP,
  UPDATE_LABOUR_ELEMENT_GROUP,
  GET_LABOUR_ELEMENT_GROUP,
  GET_LABOUR_ELEMENT_UNITS,
  ATTACH_LABOUR_ELEMENT_TO_GROUP,
  REMOVE_LABOUR_ELEMENT_FROM_GROUP,
  GET_LABOUR_GROUP_BY_ID,
  GET_LABOUR_ELEMENT_FOR_SELECT_FIELD,
  GET_LABOUR_ELEMENT_AND_GROUP_LIST,
  UPLOAD_LABOUR_ELEMENT_FILE
} from './actionTypes.action'

import {api, APIS, LOCAL_BASE_URL} from '../../config'

import {errorHandler} from '../../utils'
import {getParsedUrl} from 'helpers'
import {toast} from 'app/common'

// Get unit

export function getUnitListAction(successCallback?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ELEMENT_UNITS.LOADING})

      res = await api<Api.Base<Api.UnitList>>(
        `${APIS.get_labour_elements}/units`
      )

      const {
        success,
        data: {
          data: {total, rows},
          message
        }
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ELEMENT_UNITS.SUCCESS,
          payload: {total, rows}
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_LABOUR_ELEMENT_UNITS.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ELEMENT_UNITS.ERROR})

      return 0
    }
  }
}

export function addLabourElementAction(
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_LABOUR_ELEMENT_SETTING.LOADING})

      res = await api<any>(`${APIS.add_labour_elements}`, 'POST', body)

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: CREATE_LABOUR_ELEMENT_SETTING.SUCCESS})

        successCallback?.()
        toast.success('Labour element successfully added!')
        return 1
      } else {
        dispatch({
          type: CREATE_LABOUR_ELEMENT_SETTING.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_LABOUR_ELEMENT_SETTING.ERROR})

      return 0
    }
  }
}

export function getLabourElementAction(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10
  },
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ELEMENT_SETTING.LOADING})

      res = await api<Api.Base<Api.LabourElementList>>(
        getParsedUrl(APIS.get_labour_elements, params)
      )

      const {
        success,
        data: {
          data: {total, rows},
          message
        }
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ELEMENT_SETTING.SUCCESS,
          payload: {total, rows}
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_LABOUR_ELEMENT_SETTING.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ELEMENT_SETTING.ERROR})

      return 0
    }
  }
}

export function updateLabourElementAction(
  labourElementId: number | string,
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_LABOUR_ELEMENT_SETTING.LOADING})

      res = await api<any>(
        `${APIS.add_labour_elements}/${labourElementId}`,
        'PATCH',
        body
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_LABOUR_ELEMENT_SETTING.SUCCESS})

        successCallback?.()
        toast.success('Labour element successfully updated!')
        return 1
      } else {
        dispatch({
          type: UPDATE_LABOUR_ELEMENT_SETTING.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_LABOUR_ELEMENT_SETTING.ERROR})

      return 0
    }
  }
}

export function deleteLabourElementAction(
  labourElementId: number | string,
  successCallback?: () => void,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_LABOUR_ELEMENT_SETTING.LOADING})

      res = await api<any>(
        `${APIS.add_labour_elements}/${labourElementId}`,
        'DELETE'
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_LABOUR_ELEMENT_SETTING.SUCCESS})
        successCallback?.()
        toast.success('Labour element successfully deleted!')
        return 1
      } else {
        dispatch({
          type: UPDATE_LABOUR_ELEMENT_SETTING.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_LABOUR_ELEMENT_SETTING.ERROR})
      console.error(e)
      return 0
    }
  }
}

// LABOUR ELEMENT GROUP SETTING

export function addLabourElementGroupAction(
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_LABOUR_ELEMENT_GROUP.LOADING})

      res = await api<any>(`${APIS.add_labour_elements}/groups`, 'POST', body)

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: CREATE_LABOUR_ELEMENT_GROUP.SUCCESS})

        successCallback?.()
        toast.success('Labour element group successfully added!')
        return 1
      } else {
        dispatch({
          type: CREATE_LABOUR_ELEMENT_GROUP.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_LABOUR_ELEMENT_GROUP.ERROR})

      return 0
    }
  }
}

export function updateLabourElementGroupAction(
  groupId: number | string,
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_LABOUR_ELEMENT_GROUP.LOADING})

      res = await api<any>(
        `${APIS.add_labour_elements}/groups/${groupId}`,
        'PATCH',
        body
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_LABOUR_ELEMENT_GROUP.SUCCESS})
        successCallback?.()
        toast.success('Labour element group successfully updated!')
        return 1
      } else {
        dispatch({
          type: UPDATE_LABOUR_ELEMENT_GROUP.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_LABOUR_ELEMENT_GROUP.ERROR})

      return 0
    }
  }
}

export function deleteLabourElementGroupAction(
  groupId: number | string,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_LABOUR_ELEMENT_GROUP.LOADING})

      res = await api<any>(
        `${APIS.add_labour_elements}/groups/${groupId}`,
        'DELETE'
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_LABOUR_ELEMENT_GROUP.SUCCESS})

        successCallback?.()
        toast.success('Labour element group successfully updated!')
        return 1
      } else {
        dispatch({
          type: UPDATE_LABOUR_ELEMENT_GROUP.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_LABOUR_ELEMENT_GROUP.ERROR})

      return 0
    }
  }
}

export function getLabourElementGroupAction(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10
  },
  successCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ELEMENT_GROUP.LOADING})

      res = await api<Api.Base<Api.LabourGroupList>>(
        getParsedUrl(`${APIS.get_labour_elements}/groups`, params)
      )

      const {
        success,
        data: {
          data: {total, rows},
          message
        }
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ELEMENT_GROUP.SUCCESS,
          payload: {total, rows}
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_LABOUR_ELEMENT_GROUP.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ELEMENT_GROUP.ERROR})

      return 0
    }
  }
}

// Attach labour element to labourElement group

export function attachLabourElementAction(
  groupId: number | string,
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ATTACH_LABOUR_ELEMENT_TO_GROUP.LOADING})

      res = await api<any>(
        `${APIS.add_labour_elements}/groups/${groupId}/attach`,
        'POST',
        body
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: ATTACH_LABOUR_ELEMENT_TO_GROUP.SUCCESS})

        successCallback?.()
        toast.success('Labour elements successfully attached to the  group  !')
        return 1
      } else {
        dispatch({
          type: ATTACH_LABOUR_ELEMENT_TO_GROUP.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: ATTACH_LABOUR_ELEMENT_TO_GROUP.ERROR})

      return 0
    }
  }
}

export function removeLabourElementFromGroupAction(
  groupId: number | string,
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_LABOUR_ELEMENT_FROM_GROUP.LOADING})

      res = await api<any>(
        `${APIS.add_labour_elements}/groups/${groupId}/remove`,
        'DELETE',
        body
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: REMOVE_LABOUR_ELEMENT_FROM_GROUP.SUCCESS})

        successCallback?.()
        toast.success('Labour elements successfully removed from the  group  !')
        return 1
      } else {
        dispatch({
          type: REMOVE_LABOUR_ELEMENT_FROM_GROUP.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_LABOUR_ELEMENT_FROM_GROUP.ERROR})

      return 0
    }
  }
}

// Get labourGroupByIdAction

export function getLabourGroupByIdAction(
  groupId: number | string,
  successCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_GROUP_BY_ID.LOADING})

      res = await api<any>(`${APIS.get_labour_elements}/groups/${groupId}`)

      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_GROUP_BY_ID.SUCCESS,
          payload: data
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_LABOUR_GROUP_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_GROUP_BY_ID.ERROR})

      return 0
    }
  }
}

// for select labour element list

export function getLabourElementForSelectFieldAction(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 100
  },
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ELEMENT_FOR_SELECT_FIELD.LOADING})

      res = await api<Api.Base<Api.LabourElementList>>(
        getParsedUrl(APIS.get_labour_elements, params)
      )

      const {
        success,
        data: {
          data: {total, rows},
          message
        }
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ELEMENT_FOR_SELECT_FIELD.SUCCESS,
          payload: {total, rows}
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_LABOUR_ELEMENT_FOR_SELECT_FIELD.ERROR,
          payload: message
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ELEMENT_FOR_SELECT_FIELD.ERROR})

      return 0
    }
  }
}

export function getLabourElementAndGroupListAction(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 100
  },
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ELEMENT_AND_GROUP_LIST.LOADING})

      res = await api<Api.Base<Api.LabourElementAndGroupIndividualList>>(
        getParsedUrl(APIS.get_labour_elements, params)
      )

      const {
        success,
        data: {
          data: {total, rows},
          message
        }
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ELEMENT_AND_GROUP_LIST.SUCCESS,
          payload: {total, rows}
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_LABOUR_ELEMENT_AND_GROUP_LIST.ERROR,
          payload: message
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ELEMENT_AND_GROUP_LIST.ERROR})

      return 0
    }
  }
}

// * To upload csv or excel file in order to import as labour element
export const uploadLabourElementFile = (
  formData: FormData,
  type: 'csv' | 'excel',
  callback?: () => void
) => {
  return async (dispatch: any) => {
    dispatch({type: UPLOAD_LABOUR_ELEMENT_FILE.LOADING})
    let res
    try {
      let endpoint = type === 'csv' ? 'upload-csv' : 'upload-excel'

      res = await api<Api.Base<any>>(
        `${LOCAL_BASE_URL}/labour-elements/${endpoint}`,
        'POST',
        formData
      )

      if (res.data !== undefined) {
        const {success} = res.data
        if (success) {
          dispatch({type: UPLOAD_LABOUR_ELEMENT_FILE.SUCCESS})
          toast.success('File imported successfully.')
          callback?.()
        } else {
          dispatch({type: UPLOAD_LABOUR_ELEMENT_FILE.ERROR})
          errorHandler(res.data, toast)
        }
      } else {
        dispatch({type: UPLOAD_LABOUR_ELEMENT_FILE.ERROR})
        errorHandler(res.data, toast)
      }
    } catch (e) {
      dispatch({type: UPLOAD_LABOUR_ELEMENT_FILE.ERROR})

      errorHandler(res.data, toast)
    }
  }
}
