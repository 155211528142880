import {useEffect, useState, useImperativeHandle, forwardRef} from 'react'
import {ActiveLink, useAuth, useNavigation} from 'react-auth-navigation'
import {FaCaretDown} from 'react-icons/fa'
import {getInitialChar} from '../../../utils'
import {PHASES_DATA} from '../../pages/jobs/pages/siteVisits/components'

export type SidePanelItemType = {
  name: string
  icon: React.ReactNode
  url: string
}

const CollapsibleComp = (
  {
    title,
    children,
    active = true
  }: {
    title: React.ReactNode
    children?: React.ReactNode
    active?: boolean
  },
  ref: any
) => {
  const [open, setOpen] = useState(true)

  useImperativeHandle(ref, () => ({
    setOpenCollapse: () => {
      setOpen(true)
      console.log('hello there')
    }
  }))

  useEffect(() => {
    setOpen(active)
  }, [active])
  return (
    <div className="mb-16">
      <h3
        className="font-bold text-sm uppercase mb-4 cursor-pointer flex items-center justify-between"
        onClick={() => setOpen((prev) => !prev)}
      >
        {title}

        <div
          style={{
            transform: open ? 'rotate(0deg)' : 'rotate(-90deg)'
          }}
        >
          <FaCaretDown />
        </div>
      </h3>

      {open && <div>{children}</div>}
    </div>
  )
}

export const Collapsible = forwardRef(CollapsibleComp)

export const SiteVisitLink = ({to, detail}: {to: string; detail?: any}) => {
  const {currencySymbol} = useAuth()
  const getStatus = (status: string) => {
    const ssss = PHASES_DATA.find(({value}: any) => value === status)
    return ssss?.name
  }

  // console.log('siteVisitDetails', detail)

  return (
    <ActiveLink
      to={to}
      className="group block cursor-pointer px-4 py-8 border-b-1 border-gray-200 text-gray-400 hover:bg-blue-300 hover:text-white"
      activeClassName="bg-blue-300 highlight"
    >
      <div className="flex gap-4 items-center font-bold">
        <div className="border-r-1 border-gray-200 pr-4">
          {getInitialChar(detail?.id)}
        </div>
        <div>{detail?.title}</div>
      </div>
      <div className="flex gap-8 items-center text-sm">
        <span className="group-hover:text-white text-gray-400">
          {getStatus(detail?.status)}
        </span>
        <span className="group-hover:text-white text-blue-200">
          {currencySymbol}
          {detail?.labour_price ?? 0}
        </span>
      </div>
    </ActiveLink>
  )
}

export const SidePanelGroup = ({item}: {item: SidePanelItemType}) => {
  const {
    params,
    navigation: {routes},
    location
  } = useNavigation()
  const projectId = params as any

  const isSettingsRoute = location.pathname.includes('/settings')

  if (!isSettingsRoute && !projectId.projectId) {
    return null
  }

  return (
    <li>
      <ActiveLink
        to={{
          pathname: isSettingsRoute
            ? item.url
            : `${routes['Projects Page']?.path.replace('/:projectId', '')}/${
                projectId.projectId
              }/${item.url}`
        }}
        exact={item.url === '/settings' ? true : false}
        activeClassName="bg-blue-300 highlight"
        className="group flex items-center text-sm py-4 px-4 pl-14 text-blue-300 hover:bg-blue-300 hover:text-white"
      >
        <span className="p-10 text-lg relative mr-6">
          <span className="flex items-center justify-center absolute inset-0">
            {item.icon}
          </span>
        </span>
        <span className="text-gray-800 group-hover:text-white">
          {item.name}
        </span>
      </ActiveLink>
    </li>
  )
}

export const SidePanel = ({
  data
}: {
  data: {[key: string]: SidePanelItemType[]}
}) => {
  return (
    <>
      {Object.keys(data).map((group, index) => (
        <div key={index} className="mb-16 last:mb-0 w-[200px]">
          <h3 className="font-bold text-sm uppercase mb-4 pl-14">{group}</h3>
          <ul>
            {data[group].map((item: SidePanelItemType, i: number) => {
              return <SidePanelGroup key={i} item={item} />
            })}
          </ul>
        </div>
      ))}
    </>
  )
}

export const CustomSidePanel = ({
  append,
  routeName,
  routeIdAlias,
  data
}: {
  append?: React.ReactNode
  routeName: string
  routeIdAlias: string
  data: SidePanelItemType[]
}) => {
  const {
    params,
    navigation: {routes}
  }: any = useNavigation()

  return (
    <div className="min-w-[180px]">
      {append}
      <div className="mb-16 last:mb-0">
        <ul>
          {data.map((item, index) => (
            <li key={item.name}>
              <ActiveLink
                to={{
                  pathname: `${routes[routeName].path.replace(
                    `:${routeIdAlias}`,
                    `${params[routeIdAlias]}`
                  )}/${item.url}`
                }}
                exact={!!!item.url}
                activeClassName="bg-blue-300 highlight"
                className="group flex items-center text-sm py-4 px-14 text-blue-300 hover:bg-blue-300 hover:text-white"
              >
                <span className="p-10 text-lg relative mr-10">
                  <span className="flex items-center justify-center absolute inset-0">
                    {item.icon}
                  </span>
                </span>
                <span className="text-gray-800 group-hover:text-white">
                  {item.name}
                </span>
              </ActiveLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
