// import React, { useEffect, useState } from 'react'
import moment from "moment";
import { MdLocationPin } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useAuth, useNavigation } from "react-auth-navigation";
import { HiDotsHorizontal } from "react-icons/hi";

import { Dropdown } from "app/common";
import { NotesSummary } from "app/components";
import { activateSiteAction, archivedSiteAction, getSiteById } from "redux-src";

export const SiteHeader = () => {
  const { userDetails } = useAuth();
  const { siteById: site }: RT.SiteReduxType = useSelector(
    (state: any) => state.site
  );

  const { params } = useNavigation();
  const { siteId } = params as any;

  const dispatch = useDispatch();

  const handleArchiveSite = () => {
    dispatch(
      archivedSiteAction(siteId, () => {
        dispatch(getSiteById(siteId));
      })
    );
  };

  const handleActiveSite = () => {
    dispatch(
      activateSiteAction(siteId, () => {
        dispatch(getSiteById(siteId));
      })
    );
  };

  return (
    <div className="p-16 pt-30 bg-blue-100">
      <div className="flex items-center gap-10 mb-10">
        <div className="bg-gray-200 rounded-[50%] h-[60px] w-[60px] flex flex-nowrap items-center justify-center">
          <MdLocationPin className="text-white" size={35} />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row w-full justify-between items-center">
            <div className="text-lg font-bold text-black flex items-center ">
              {site?.physical_address_details?.address}
              <span className="bg-blue-300 font-bold text-xs rounded-full w-[max-content] ml-10 text-white px-10 py-[2px] uppercase">
                {site?.site_details?.status}
              </span>
            </div>
            <Dropdown
              placement="bottomright"
              triggerElement={
                <button className="p-10 py-6 bg-grey-100 shadow-sm rounded-sm border-1 transition-all duration-200 ease-in-out border-none hover:bg-grey-200">
                  <HiDotsHorizontal size={20} />
                </button>
              }
            >
              <div style={{ minWidth: "12rem", zIndex: 1 }}>
                <div className=" w-full mt-10 bg-white">
                  {/* <DropDownFiles /> */}

                  <div
                    className="flex-1 w-full   overflow-visible "
                    style={{ width: 200 }}
                  >
                    <ul className=" border-gray-200 rounded-sm shadow-xl overflow-auto ">
                      {site?.site_details?.status === "active" ? (
                        <li
                          className="p-10 pl-20 cursor-pointer  bg-white text-black hover:bg-blue-100  text-md "
                          onClick={handleArchiveSite}
                        >
                          <span className="text-left inline-flex cursor-pointer items-center gap-4">
                            Archive Site
                          </span>
                        </li>
                      ) : (
                        <li
                          className="p-10 pl-20 cursor-pointer  bg-white text-black hover:bg-blue-100  text-md "
                          onClick={handleActiveSite}
                        >
                          <span className="text-left inline-flex cursor-pointer items-center gap-4">
                            Activate Site
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Dropdown>
          </div>
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex flex-row flex-nowrap px-10 py-4 bg-white border-1 border-gray-200 gap-4 rounded-sm items-center">
              <MdLocationPin />
              <div className="text-normal">
                {site?.default_contact_details?.firstname}{" "}
                {site?.default_contact_details?.lastname}
              </div>
            </div>

            <div className="flex items-center gap-16">
              <div className="text-sm text-gray-400">
                Created{" "}
                {moment(site?.site_details?.created_at ?? Date.now()).format(
                  "ddd, LL"
                )}{" "}
                by{" "}
                {site?.user_details?.display_name ??
                  userDetails?.appUser?.display_name}
              </div>
            </div>
          </div>
        </div>
      </div>

      <NotesSummary hostName="site" type="site" />
    </div>
  );
};
