import { STATUS_BOARD } from '../actions'

const initalState: any = {
  loading: true,
  data: null,
}

export function statusBoardReducer(state = initalState, action: any) {
  const { type, payload } = action

  switch (type) {
    case STATUS_BOARD.LOADING:
      return { ...state, loading: true }
    case STATUS_BOARD.SUCCESS:
      return { ...state, loading: false, data: payload }
    case STATUS_BOARD.ERROR:
      return { ...state, loading: false }

    default:
      return state
  }
}
