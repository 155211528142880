import {useCallback, useEffect, useMemo} from 'react'
import {TableColumn} from 'react-data-table-component'
import {FaBuilding, FaSearch, FaUser} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import moment from 'moment'

import {DataTable, DescriptionBox} from 'app/components'
import {getProjectListByDefinedId} from 'redux-src'
import {useDebounceValue} from 'hooks'

interface ProjectListRow {
  projectNo: string
  created: string
  status: string
  projectType: string
  title: string
  siteContact: string
  siteAddress: string
  id: number
  siteId: number
}

const columns: TableColumn<ProjectListRow>[] = [
  {
    name: 'PROJECT NO.',
    selector: (row) => row.id,
    sortable: true,

    cell: (row) => (
      <Link
        to={`/projects/${row.id}/quote`}
        className="text-sm font-bold text-blue-300 cursor-pointer"
      >
        {row.projectNo}
      </Link>
    )
  },
  {
    name: 'CREATED',
    selector: (row) => row.created,
    sortable: true,

    cell: (row) => (
      <div className="text-sm">{moment(row.created).format('DD/MM/YYYY')}</div>
    )
  },
  {
    name: 'STATUS',
    selector: (row) => row.status,

    cell: (row) => (
      <div className="text-xs font-bold bg-blue-300 text-white px-6 py-4 rounded-full uppercase w-[max-content]">
        {row.status}
      </div>
    )
  },
  {
    name: 'PROJECT TYPE',

    selector: (row) => row.projectType,
    cell: (row) => (
      <div className="text-xs font-bold bg-gray-400 text-white px-6 py-4 rounded-full uppercase w-[max-content]">
        {row.projectType}
      </div>
    )
  },
  {
    name: 'DESCRIPTION',
    selector: (row) => row.title,

    sortable: true,
    cell: (row) => (
      <Link
        to={`/projects/${row.id}/projects-summary`}
        className="text-sm font-bold text-blue-300 cursor-pointer"
      >
        {row.title}
      </Link>
    )
  },

  {
    name: 'SITE',
    sortable: true,
    selector: (row) => row.siteContact,
    cell: (row) => (
      <Link
        className="text-sm flex flex-col w-full"
        to={`/sites/view/${row.siteId}`}
      >
        <div className="flex flex-row gap-4 text-black">
          <FaUser size={12} />
          <span className="font-normal">{row.siteContact}</span>
        </div>
        <div className="flex flex-row gap-4 text-black">
          <FaBuilding size={12} />
          <span className="font-bold text-blue-300">{row.siteAddress}</span>
        </div>
      </Link>
    )
  }
]

export const CustomerProjectList = () => {
  const {params} = useNavigation()
  const {customerId} = params as any
  const dispatch = useDispatch()

  const [filterData, filterHandler] = useFormInput({
    filterValue: undefined
  })

  const debounceFilterValue = useDebounceValue(filterData.filterValue)

  const getProjects = useCallback(
    (page: number = 1, tableLimit: number = 10) => {
      dispatch(
        getProjectListByDefinedId('customers', customerId, {
          page,
          limit: tableLimit,
          search: debounceFilterValue === '' ? undefined : debounceFilterValue
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, debounceFilterValue]
  )
  const {
    projectListByDefinedId,
    projectListByDefinedIdTotal,
    projectListByDefinedIdLoading
  }: RT.ProjectReduxType = useSelector((state: any) => state.project)

  useEffect(() => {
    getProjects()
  }, [getProjects])

  const newJobList = useMemo(() => {
    const _temp: ProjectListRow[] = []
    projectListByDefinedId?.map((item) => {
      return _temp.push({
        projectNo:
          item.project_details?.project_prefix +
          '-' +
          item.project_details?.project_number,
        created: item.project_details?.created_at,
        status: item.project_details?.project_status,
        projectType: item.project_details?.project_type,
        title: item.project_details?.title,
        //! site contact details necessary instead of customer_details
        siteContact: item.customer_details?.name,
        siteAddress: item.site_address_details?.address,
        id: item.project_details?.id,
        siteId: item.project_details?.site_id
      })
    })
    return _temp
  }, [projectListByDefinedId])

  return (
    <div>
      <DescriptionBox
        bodyClassName="m-0 p-0"
        title={
          <div className="flex justify-between items-center ">
            <div className="flex items-center gap-6">
              <span className="font-bold text-lg">All Projects</span>
            </div>

            <div className="relative">
              <FaSearch
                size={16}
                className="absolute top-[50%] translate-y-[-50%] left-10"
              />
              <input
                placeholder="Search for project name"
                className="px-10 py-8 pl-32 border-1 border-gray-200 rounded-sm text-sm font-normal"
                value={filterData?.filterValue}
                name="filterValue"
                onChange={filterHandler.onChange}
              />
            </div>
          </div>
        }
      >
        <DataTable
          columns={columns}
          data={newJobList}
          // customStyles={customStyles}
          pagination
          persistTableHead
          dense
          fetchData={getProjects}
          totalRows={projectListByDefinedIdTotal}
          loading={projectListByDefinedIdLoading}
        />
      </DescriptionBox>
    </div>
  )
}
