import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {FiChevronRight} from 'react-icons/fi'
import {AnimatedBlock, useAnimatedValue, useMeasure} from 'react-ui-animate'

export const Collapsible = ({
  title,
  children,
  containerClassName,
  rightElement,
  isOpen = true,
}: {
  title: string | React.ReactNode
  children?: React.ReactNode
  containerClassName?: string
  rightElement?: React.ReactNode
  isOpen?: boolean
}) => {
  const [open, setOpen] = useState(true)
  useEffect(() => {
    setOpen(!!isOpen)
  }, [isOpen])
  const [height, setHeight] = useState<any>(0)
  const heightAnimation = useAnimatedValue(open ? height : 0)

  const bind = useMeasure(({height}) => {
    setHeight(height)
  })

  return (
    <div className={clsx('mb-16', containerClassName)}>
      <div className="flex items-center justify-between">
        <h3
          className="font-bold mb-4 cursor-pointer flex items-center gap-6 w-full"
          onClick={() => setOpen(prev => !prev)}
        >
          <div
            style={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
          >
            <FiChevronRight size={20} className="text-blue-200" />
          </div>
          {title}
        </h3>

        {rightElement}
      </div>

      <AnimatedBlock
        style={{
          height: heightAnimation.value,
          overflowY: 'hidden',
        }}
      >
        <div {...bind()}>{children}</div>
      </AnimatedBlock>
    </div>
  )
}

export const CollapsibleBlock = ({
  topContainer,
  children,
  containerClassName,
  rightElement,
  indicator,
  topContainerClassName,
}: {
  topContainer: React.ReactNode
  children?: React.ReactNode
  containerClassName?: string
  rightElement?: React.ReactNode
  indicator?: boolean
  topContainerClassName?: string
}) => {
  const [open, setOpen] = useState(true)
  const [height, setHeight] = useState<any>(0)
  const heightAnimation = useAnimatedValue(open ? height : 0)
  const iconAnimation = useAnimatedValue(open ? 90 : 0)

  const bind = useMeasure(({height}) => {
    setHeight(height)
  })

  return (
    <div className={containerClassName}>
      <div
        className={topContainerClassName}
        onClick={() => setOpen(prev => !prev)}
      >
        {topContainer}{' '}
        {indicator && (
          <AnimatedBlock
            style={{
              rotate: iconAnimation.value,
            }}
          >
            <FiChevronRight size={20} className="text-blue-200" />
          </AnimatedBlock>
        )}
      </div>

      <AnimatedBlock
        style={{
          height: heightAnimation.value,
          overflowY: 'hidden',
        }}
      >
        <div {...bind()}>{children}</div>
      </AnimatedBlock>
    </div>
  )
}
