import {AiFillTags} from 'react-icons/ai'
import {IoMdClose} from 'react-icons/io'
import {clsx} from 'clsx'
import {useAuth} from 'react-auth-navigation'

import {LineItemInput} from '../lineItemInput'
import {PRELIMINARY_TABLE_HEADINGS} from '../../../../constants'
import type {
  PreliminaryTypes,
  RemappedLineItemType,
} from '../../../../constants'

interface PreliminarySectionTableType {
  preliminaryType: PreliminaryTypes
  preliminaryHeader: Array<{
    key: string
    title: string
    width: string
    hidden: boolean
  }>
  preliminaryData: Array<RemappedLineItemType>
  isPreview?: boolean
  sectionDetails: Api.PreliminaryItemsDetails
  onRemoveLineItem?: (itemId: number) => void
  onUpdateLineItemHandler?: (
    key: 'name' | 'rate',
    value: any,
    item: any,
  ) => void
}

type LineItemKeys = 'name' | 'rate'

export const PreliminarySectionTable = ({
  preliminaryType,
  preliminaryHeader,
  preliminaryData,
  sectionDetails,
  onRemoveLineItem,
  onUpdateLineItemHandler,
  isPreview = false,
}: PreliminarySectionTableType) => {
  const {currencySymbol} = useAuth()

  return (
    <>
      <table width={'100%'}>
        {preliminaryHeader?.map((item, index: number) => {
          return (
            <th
              key={item.key + index}
              style={{
                width: item?.width,
                paddingBottom: '7px',
              }}
              className={clsx(
                index === 0
                  ? 'text-left'
                  : index === preliminaryHeader?.length - 1
                  ? 'text-right'
                  : 'text-center',
                PRELIMINARY_TABLE_HEADINGS[preliminaryType]?.find(
                  (heading) => heading?.key === item.key,
                )?.hidden && 'text-gray-400',
              )}
            >
              {item?.key === 'noOfQuantity'
                ? `No. of ${sectionDetails?.frequency_unit}`
                : `${item.title}`}
            </th>
          )
        })}
        {preliminaryData?.map((data, index: number) => {
          return (
            <tr>
              {preliminaryHeader?.map((header, idx: number) => {
                return (
                  <td className={header.key === 'name' ? 'flex w-[100%]' : ''}>
                    {header.key === 'name' && !isPreview && (
                      <div className="flex justify-center item-center">
                        <input type="checkbox" disabled />
                        <AiFillTags size={14} />
                      </div>
                    )}
                    <LineItemInput
                      key={index}
                      // width={
                      //   PRELIMINARY_TABLE_HEADINGS[preliminaryType]?.find(
                      //     (heading) => heading?.key === header.key
                      //   )?.width
                      // }
                      type={header.key === 'name' ? 'text' : 'number'}
                      value={data?.[header.key as keyof RemappedLineItemType]}
                      disabled={
                        header?.key === 'noOfWeeks' ||
                        (header?.key === 'name' && isPreview) ||
                        (header?.key === 'rate' && isPreview) ||
                        header?.key === 'noOfTransport' ||
                        header?.key === 'noOfQuantity' ||
                        header?.key === 'quantityUnit' ||
                        header?.key === 'totalCost'
                      }
                      minNumber={0}
                      className={clsx(
                        idx === 0
                          ? 'text-left'
                          : idx === preliminaryHeader?.length - 1
                          ? 'text-right'
                          : 'text-center',
                        PRELIMINARY_TABLE_HEADINGS[preliminaryType]?.find(
                          (heading) => heading?.key === data?.name,
                        )?.hidden && 'bg-transparent',
                        header.key === 'name' && 'w-[90%]',
                      )}
                      onUpdate={(text: any) =>
                        onUpdateLineItemHandler?.(
                          header?.key as LineItemKeys,
                          text,
                          data,
                        )
                      }
                    />
                  </td>
                )
              })}
              <td width={'2%'}>
                {onRemoveLineItem && (
                  <div
                    className="flex cursor-pointer w-[max-content] items-center justify-center hover:text-red-300 transition-all duration-200 ease-in-out"
                    onClick={() => onRemoveLineItem?.(data?.id)}
                  >
                    <IoMdClose />
                  </div>
                )}
              </td>
            </tr>
          )
        })}
      </table>
      <div className="flex flex-row justify-end items-center gap-6 mt-10">
        <div className="text-sm "> Total :</div>
        <div className=" text-md ">
          {currencySymbol} {sectionDetails?.total_cost ?? '0.00'}
        </div>
      </div>
    </>
  )
}
