import React from 'react'

import {ItemManagementComp} from './component'

export const ItemManagementPage = () => {
  return (
    <div className="flex flex-col gap-40 m-10 mt-4 mb-10 min-h-[85vh]">
      <ItemManagementComp />
    </div>
  )
}
