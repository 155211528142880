import {createContext, useCallback, useEffect, useState} from 'react'
import {Auth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {FaHome} from 'react-icons/fa'

import {Button, Dropdown} from 'app/common'
import {
  createNewInvoiceAction,
  getInvoiceById,
  getInvoicesAction,
} from 'redux-src'

import {
  InvoiceBasicDetailModal,
  InvoiceFlowHeadComponent,
  SelectTicketModal,
} from './components'

export const InvoicingContext = createContext(null)

export const InvoicingPage = () => {
  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const {invoiceDetail}: RT.ProjectInvoicingReduxType = useSelector(
    (state: any) => state.invoices,
  )

  const dispatch = useDispatch()

  const {
    params,
    navigation: {
      navigate,
      routes: {'Invoicing Detail': invoiceDetailRoute},
    },
  } = useNavigation()
  const {projectId} = params as any

  const [isInvoiceFlag, setInvoiceFlag] = useState(true)
  const [isInvoiceLoading, setInvoiceLoading] = useState(false)
  const [isInvoiceDetailRoute, setInvoiceDetailRoute] = useState(false)
  const [activeInvoice, setActiveInvoice] = useState<any>({})

  const [openSelectTicketModal, setSelectTicketModal] = useState<boolean>(false)
  const [openInvoiceBasicModal, setInvoiceBasicModal] = useState<boolean>(false)

  const toggleLoading = useCallback((value: boolean) => {
    setInvoiceLoading(value)
  }, [])

  useEffect(() => {
    setInvoiceDetailRoute(invoiceDetailRoute.active)
  }, [invoiceDetailRoute.active, isInvoiceDetailRoute])

  const {activeInvoice: activeInvoiceFromAPI} = useSelector(
    (state: any) => state.invoices,
  )

  useEffect(() => {
    setActiveInvoice(activeInvoiceFromAPI)
  }, [activeInvoiceFromAPI])

  const {invoicesLoading, invoiceDetailLoading, addInvoicesLoading} =
    useSelector((state: any) => state.invoices)

  useEffect(() => {
    // console.table({ addInvoicesLoading, invoiceDetailLoading, invoicesLoading })
    // invoiceDetailLoading
    //   ? toggleLoading(true)
    //   :
    invoicesLoading
      ? toggleLoading(true)
      : addInvoicesLoading
      ? toggleLoading(true)
      : toggleLoading(false)

    return () => toggleLoading(false)
  }, [addInvoicesLoading, invoicesLoading, toggleLoading])

  useEffect(() => {
    dispatch(getInvoicesAction(projectId))
    setInvoiceFlag(false)
  }, [dispatch, isInvoiceFlag, projectId])

  const firstItem = {
    name: 'Invoicing',
    icon: <FaHome />,
    url: `/projects/${projectId}/invoicing`,
  }

  const secondItem = {
    name:
      invoiceDetail?.invoice_details?.type === 'draft'
        ? 'Draft'
        : invoiceDetail?.invoice_details?.invoice_number,
    icon: '',
    url: `/projects/${projectId}/invoicing/${invoiceDetail?.invoice_details?.id}`,
  }

  const navigateToDraftInvoice = (invoiceBody: any) => {
    setInvoiceLoading(true)

    dispatch(
      createNewInvoiceAction(Number(projectId), invoiceBody, (data) => {
        dispatch(getInvoiceById(projectId, data?.invoice_id))
        navigate(`/projects/${projectId}/invoicing/${data?.invoice_id}`)
        setInvoiceBasicModal(false)
        dispatch(getInvoicesAction(Number(projectId)))
      }),
    )
  }

  return (
    <div className="w-full min-h-[80vh]">
      <div className="w-full flex justify-between">
        <h1 className="font-bold text-xl mb-4">Invoicing</h1>

        {!isInvoiceDetailRoute && (
          <Dropdown
            triggerElement={
              <Button
                title="Create Invoice"
                className={`${
                  isInvoiceDetailRoute
                    ? 'cursor-not-allowed bg-blue-200 hover:bg-blue-150'
                    : ''
                }`}
              />
            }
            triggerToggle
            placement="bottomright"
          >
            <div className="bg-white">
              <div
                className="bg-white shadow-md rounded-sm overflow-hidden"
                onClick={() => {
                  setInvoiceBasicModal((prev) => !prev)
                }}
              >
                <div className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer">
                  Create Blank Invoice
                </div>
              </div>

              <div
                className={`shadow-md rounded-sm overflow-hidden ${
                  projectDetail?.project_details?.is_quote_accepted
                    ? 'pointer-events-auto bg-white cursor-default'
                    : 'pointer-events-none bg-gray-100 text-gray-300 cursor-not-allowed'
                }`}
                onClick={() => {
                  setSelectTicketModal((prev) => !prev)
                }}
              >
                <div className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer">
                  Create Invoice Using Tickets
                </div>
              </div>
            </div>
          </Dropdown>
        )}
      </div>
      <InvoicingContext.Provider
        value={{
          loader: {
            isInvoiceLoading,
            setInvoiceLoading,
            toggleLoading,
            invoiceDetailLoading,
          },
          activeRoute: {isInvoiceDetailRoute, setInvoiceDetailRoute},
          selectInvoice: {activeInvoice, setActiveInvoice},
        }}
      >
        <InvoiceFlowHeadComponent
          firstItem={firstItem}
          secondItem={secondItem}
        />

        <Auth.Screens path="/projects/:projectId/invoicing" />

        <SelectTicketModal
          openModal={openSelectTicketModal}
          setOpenModal={setSelectTicketModal}
        />
        <InvoiceBasicDetailModal
          openModal={openInvoiceBasicModal}
          setOpenMOdal={setInvoiceBasicModal}
          callbackFunction={navigateToDraftInvoice}
        />
      </InvoicingContext.Provider>
    </div>
  )
}
