import {
  ADD_SITE,
  GET_SITE,
  GET_SITE_BY_ID,
  UPLOAD_SITE_FILES,
  GET_SITE_FILES,
  UPDATE_SITE_CONTACT,
  ADD_SITE_CONTACT,
  GET_SITE_CONTACT,
  ALTER_SITE_CONTACT,
  ARCHIVE_SITE,
  GET_ARCHIVED_SITE,
  ACTIVATE_SITE,
} from "../actions";

const initalState: RT.SiteReduxType = {
  sites: [],
  siteTotal: 0,
  add_loading: false,
  add_error: "",

  archivedSiteList: undefined,
  getArchivedSiteLoading: false,
  archivedTotal: 0,

  activateSiteLoading: false,

  get_loading: false,
  get_error: "",

  upload_file_loading: false,

  get_site_files_loading: false,
  site_files: undefined,

  getSiteByIdLoading: false,
  siteById: undefined,

  get_site_contact_loading: false,
  update_site_contact_loading: false,
  add_site_contact_loading: false,
  site_contact: undefined,

  alter_site_contact_loading: false,
};

export function siteReducer(state = initalState, action: any) {
  const { type, payload } = action;

  //

  switch (type) {
    case ADD_SITE.LOADING:
      return { ...state, add_loading: true, add_error: false };
    case ADD_SITE.SUCCESS:
      return {
        ...state,
        add_loading: false,
        add_error: false,
      };
    case ADD_SITE.ERROR:
      return { ...state, add_loading: false, add_error: payload };

    case GET_SITE.LOADING:
      return {
        ...state,
        get_loading: true,
        get_error: false,
      };
    case GET_SITE.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        sites: payload.rows,
        siteTotal: payload.total,
      };

    case GET_SITE.ERROR:
      return { ...state, get_loading: false, get_error: payload };

    case GET_ARCHIVED_SITE.LOADING:
      return {
        ...state,
        get_loading: true,
        get_error: false,
      };
    case GET_ARCHIVED_SITE.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        archivedSiteList: payload.rows,
        archivedTotal: payload.total,
      };
    case GET_ARCHIVED_SITE.ERROR:
      return { ...state, get_loading: false, get_error: payload };

    case GET_SITE_BY_ID.LOADING:
      return {
        ...state,
        getSiteByIdLoading: true,
      };
    case GET_SITE_BY_ID.SUCCESS:
      return {
        ...state,
        getSiteByIdLoading: false,
        siteById: payload,
      };
    case GET_SITE_BY_ID.ERROR:
      return { ...state, getSiteByIdLoading: false };

    case UPLOAD_SITE_FILES.LOADING:
      return { ...state, upload_site_loading: true };
    case UPLOAD_SITE_FILES.SUCCESS:
      return { ...state, upload_site_loading: false };
    case UPLOAD_SITE_FILES.ERROR:
      return { ...state, upload_site_loading: false };

    case GET_SITE_FILES.LOADING:
      return { ...state, get_site_files_loading: true };
    case GET_SITE_FILES.SUCCESS:
      return { ...state, get_site_files_loading: false, site_files: payload };
    case GET_SITE_FILES.ERROR:
      return { ...state, get_site_files_loading: false };

    case GET_SITE_CONTACT.LOADING:
      return { ...state, get_site_contact_loading: true };
    case GET_SITE_CONTACT.SUCCESS:
      return {
        ...state,
        get_site_contact_loading: false,
        site_contact: payload,
      };
    case GET_SITE_CONTACT.ERROR:
      return { ...state, get_site_contact_loading: false };

    case UPDATE_SITE_CONTACT.LOADING:
      return { ...state, update_site_contact_loading: true };
    case UPDATE_SITE_CONTACT.SUCCESS:
      return {
        ...state,
        update_site_contact_loading: false,
      };
    case UPDATE_SITE_CONTACT.ERROR:
      return { ...state, update_site_contact_loading: false };

    case ADD_SITE_CONTACT.LOADING:
      return { ...state, add_site_contact_loading: true };
    case ADD_SITE_CONTACT.SUCCESS:
      return {
        ...state,
        add_site_contact_loading: false,
      };
    case ADD_SITE_CONTACT.ERROR:
      return { ...state, add_site_contact_loading: false };

    case ALTER_SITE_CONTACT.LOADING:
      return { ...state, alter_site_contact_loading: true };
    case ALTER_SITE_CONTACT.SUCCESS:
      return {
        ...state,
        alter_site_contact_loading: false,
      };
    case ALTER_SITE_CONTACT.ERROR:
      return { ...state, alter_site_contact_loading: false };

    case ARCHIVE_SITE.LOADING:
      return { ...state, getArchivedSiteLoading: true };
    case ARCHIVE_SITE.SUCCESS:
      return {
        ...state,
        getArchivedSiteLoading: false,
      };
    case ARCHIVE_SITE.ERROR:
      return { ...state, getArchivedSiteLoading: false };

    case ACTIVATE_SITE.LOADING:
      return { ...state, activateSiteLoading: true };
    case ACTIVATE_SITE.SUCCESS:
      return {
        ...state,
        activateSiteLoading: false,
      };
    case ACTIVATE_SITE.ERROR:
      return { ...state, activateSiteLoading: false };

    default:
      return state;
  }
}
