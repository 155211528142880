import {
  ADD_FILES_AND_PHOTOS,
  DOWNLOAD_PDF_COMMON,
  DOWNLOAD_PROJECT_PRELIMINARY,
  DOWNLOAD_PURCHASE_ORDER_SLIP,
  GET_FILES_AND_PHOTOS
} from '.'
import {APIS, api} from '../../config'

import {toast} from 'app/common'

export function getFiles(projectId: number, successCallback?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_FILES_AND_PHOTOS.LOADING})

      res = await api<any>(
        `${APIS.project_files_and_photos + '/' + projectId}`,
        'GET'
      )

      const {
        success,
        data: {
          message,
          data: {rows: files}
        }
      } = res.data

      if (success) {
        dispatch({type: GET_FILES_AND_PHOTOS.SUCCESS, payload: files})

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_FILES_AND_PHOTOS.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_FILES_AND_PHOTOS.ERROR, payload: message})
      return 0
    }
  }
}

export function addFiles(
  projectId: number,
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_FILES_AND_PHOTOS.LOADING})

      res = await api<any>(
        `${APIS.files_and_photos + '/' + projectId}`,
        'POST',
        body
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: ADD_FILES_AND_PHOTOS.SUCCESS})

        successCallback && successCallback()
        toast.success('Checklist successfully added!')
        return 1
      } else {
        dispatch({type: ADD_FILES_AND_PHOTOS.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: ADD_FILES_AND_PHOTOS.ERROR, payload: message})
      toast.error('Error saving the checklist!')

      return 0
    }
  }
}

export function downloadPurchaseOrderAction(
  projectId: number,
  body: any,
  successCallback?: (data: any) => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DOWNLOAD_PURCHASE_ORDER_SLIP.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/purchase-orders/download`,
        'POST',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (success) {
        dispatch({type: DOWNLOAD_PURCHASE_ORDER_SLIP.SUCCESS, payload: data})

        successCallback && successCallback(data)
        toast.success('Successfully Downloaded purchase order details')
        return 1
      } else {
        dispatch({type: DOWNLOAD_PURCHASE_ORDER_SLIP.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: DOWNLOAD_PURCHASE_ORDER_SLIP.ERROR, payload: message})
      toast.error('Error downloading purchase order details')

      return 0
    }
  }
}

export function downloadProjectPreliminaryAction(
  projectId: number,
  body: any,
  successCallback?: (data: any) => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DOWNLOAD_PROJECT_PRELIMINARY.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items/download`,
        'POST',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (success) {
        dispatch({type: DOWNLOAD_PROJECT_PRELIMINARY.SUCCESS, payload: data})

        successCallback && successCallback(data)
        toast.success('Successfully Downloaded project preliminary details')
        return 1
      } else {
        dispatch({type: DOWNLOAD_PROJECT_PRELIMINARY.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: DOWNLOAD_PROJECT_PRELIMINARY.ERROR, payload: message})
      toast.error('Error downloading project preliminary details')

      return 0
    }
  }
}

export const downloadPdfAction = (
  body: {html: string},
  successCallback?: (data: any) => void,
  errorCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DOWNLOAD_PDF_COMMON.LOADING})

      res = await api<any>(`${APIS.generate_pdf}`, 'POST', body)

      if (res.status === 200) {
        dispatch({type: DOWNLOAD_PDF_COMMON.SUCCESS, payload: res})

        const bytes = atob(res.data as string)
        let length = bytes.length
        let out = new Uint8Array(length)

        while (length--) {
          out[length] = bytes.charCodeAt(length)
        }

        const resData = new Blob([out], {type: 'application/pdf'})

        successCallback?.(resData)
        toast.success('File ready for download!')
      } else {
        dispatch({type: DOWNLOAD_PDF_COMMON.ERROR})

        errorCallback?.()
        toast.error('Error while downloading!')
      }
    } catch (error) {
      console.error({error})
      dispatch({type: DOWNLOAD_PDF_COMMON.ERROR, payload: error})
    }
  }
}
