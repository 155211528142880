import React, {useCallback, useEffect, useMemo} from 'react'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import moment from 'moment'

import {DataTable} from 'app/components'
import {getStatusChip} from 'app/pages/jobs/pages/ticket/components/planList/components'
import {RootState, getOverdueTicket} from 'redux-src'

export function OverdueTab() {
  const dispatch = useDispatch()

  const {overdueTicketList, isOverdueTicketLoading}: RT.CompanyReduxType =
    useSelector((state: RootState) => state.company)

  // * fetch ticket overdue when page is loaded first time or when limit or page changes
  const fetchTickets = useCallback(
    (page: number = 1, limit: number = 10) => {
      dispatch(getOverdueTicket(limit, page))
    },
    [dispatch],
  )

  useEffect(() => {
    fetchTickets()
  }, [fetchTickets])

  // * Define table data from overdue ticket list
  const tableData = useMemo(
    () =>
      overdueTicketList?.rows?.map((data) => ({
        ...data?.ticket_details,
        project_detail: `${data?.project_details?.project_prefix}-${data?.project_details?.project_number}`,
        project_id: data?.project_details?.id,
      })),
    [overdueTicketList],
  )

  const tableColumn = useMemo<Array<TableColumn<any>>>(
    () => [
      {
        name: 'Title',
        sortable: true,
        selector: (row) => row.title,
        cell: (row) => (
          <span
            className="py-4 mr-1 leading-none text-blue-300 cursor-pointer"
            onClick={() => {
              window.open(
                `#/projects/${row?.project_id}/ticket?showTicket=true&ticketId=${row?.id}`,
                '_blank',
              )
            }}
          >
            {row?.title}
          </span>
        ),
      },
      {
        name: 'Status',
        cell: (row) => {
          // console.log
          return getStatusChip(
            row.status as 'open' | 'in progress' | 'feedback' | 'completed',
          )
        },
      },
      {
        name: 'Project',
        sortable: true,
        selector: (row) => row?.project_detail,
        cell: (row) => (
          <Link to={`/projects/${row?.project_id}/projects-summary`}>
            <span className="py-4 mr-1 text-blue-300 leading-none ">
              {row?.project_detail}
            </span>
          </Link>
        ),
      },

      {
        name: 'Due Date',
        cell: (row) => (
          <span className="py-4 mr-1 leading-none ">{row?.due_date}</span>
        ),
      },

      {
        name: 'Created At',
        cell: (row) => (
          <span className="py-4 mr-1 leading-none ">
            {moment(row?.created_at)?.format('YYYY-MM-DD')}
          </span>
        ),
      },
    ],
    [],
  )

  return (
    <div>
      <DataTable
        columns={tableColumn}
        data={tableData}
        loading={isOverdueTicketLoading}
        pagination={true}
        fetchData={(newPage, newLimit) => {
          fetchTickets(newPage, newLimit)
        }}
        totalRows={+overdueTicketList?.total}
      />
    </div>
  )
}
