import {
  ADD_FILES_AND_PHOTOS,
  DOWNLOAD_PROJECT_PRELIMINARY,
  DOWNLOAD_PURCHASE_ORDER_SLIP,
  GET_FILES_AND_PHOTOS,
  DOWNLOAD_PDF_COMMON
} from '../actions'

const initialState = {
  files: [] as object[],
  jobFiles: [] as object[],
  add_loading: false,
  downloadPurchaseOrderLoading: false,
  add_error: '',

  get_loading: false,
  get_error: '',

  get_purchase_error: '',
  purchaseOrderLink: '',

  get_project_preliminary_error: '',

  downloadPdfLoading: false
}

export function filesReducer(state = initialState, action: any) {
  const {type, payload} = action

  //* files

  switch (type) {
    case ADD_FILES_AND_PHOTOS.LOADING:
      return {...state, add_loading: true, add_error: false}
    case ADD_FILES_AND_PHOTOS.SUCCESS:
      return {
        ...state,
        add_loading: false,
        add_error: false
      }
    case ADD_FILES_AND_PHOTOS.ERROR:
      return {...state, add_loading: false, add_error: payload}

    case GET_FILES_AND_PHOTOS.LOADING:
      return {
        ...state,
        get_loading: true,
        get_error: false
      }
    case GET_FILES_AND_PHOTOS.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        files: payload
      }
    case GET_FILES_AND_PHOTOS.ERROR:
      return {...state, get_loading: false, get_error: payload}

    case DOWNLOAD_PURCHASE_ORDER_SLIP.LOADING:
      return {
        ...state,
        get_purchase_error: false,
        downloadPurchaseOrderLoading: true
      }
    case DOWNLOAD_PURCHASE_ORDER_SLIP.SUCCESS:
      return {
        ...state,
        get_purchase_error: false,
        downloadPurchaseOrderLoading: false,
        purchaseOrderLink: payload
      }
    case DOWNLOAD_PURCHASE_ORDER_SLIP.ERROR:
      return {
        ...state,
        get_purchase_error: payload,
        downloadPurchaseOrderLoading: false
      }

    case DOWNLOAD_PROJECT_PRELIMINARY.LOADING:
      return {
        ...state,
        get_project_preliminary_error: false
      }
    case DOWNLOAD_PROJECT_PRELIMINARY.SUCCESS:
      return {
        ...state,
        get_project_preliminary_error: false
      }
    case DOWNLOAD_PROJECT_PRELIMINARY.ERROR:
      return {...state, get_project_preliminary_error: payload}

    case DOWNLOAD_PDF_COMMON.LOADING:
      return {
        ...state,
        downloadPdfLoading: true
      }
    case DOWNLOAD_PDF_COMMON.SUCCESS:
      return {
        ...state,
        downloadPdfLoading: false
      }
    case DOWNLOAD_PDF_COMMON.ERROR:
      return {...state, downloadPdfLoading: false}

    default:
      return state
  }
}
