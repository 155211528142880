import {useEffect} from 'react'
import {Auth} from 'react-auth-navigation'
import {useDispatch} from 'react-redux'
import {getFormFieldDataType} from 'redux-src'

export const FormsPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFormFieldDataType())
  }, [dispatch])

  return (
    <div className="mx-10 mt-6 min-h-[85vh]">
      <Auth.Screens path="/settings/forms" />
    </div>
  )
}
