// import React from "react";
export const Textarea = ({
  name,
  label,
  value,
  defaultValue,
  placeholder,
  onChange,
  containerClass,
  className,
  error,
  size,
  required,
  ...rest
}: Com.TextAreaProps) => {
  return (
    <div
      className={`${
        !containerClass ? 'relative w-full form-group mb-12' : containerClass
      }`}
    >
      {label && (
        <label className="flex gap-6 text-sm mb-6 font-bold">
          <span>{label}</span>
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <textarea
        value={value}
        defaultValue={defaultValue}
        name={name}
        rows={3}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        className={`border-1 rounded-sm w-full h-full outline-none ${
          error ? 'border-red-300' : ' border-gray-200'
        } ${size === 'sm' ? 'px-10 py-4' : 'px-16 py-10'} ${className} `}
        {...rest}
      />
      {error && (
        <p className="text-red-300 text-sm absolute -bottom-8 left-8 -my-6">
          {error}
        </p>
      )}
    </div>
  )
}
