import {useEffect, useState} from 'react'

interface LineItemInputProps {
  width: string | number
  value: string | number
  className?: string
  disabled?: boolean
  onUpdate?: (a: any) => void
  type?: string
  openTicketModal?: () => void
  inputType?: 'number' | 'decimal' | 'text'
}

export const LineItemInput = ({
  width,
  className,
  value,
  onUpdate,
  disabled,
  type,
  openTicketModal,
  inputType = 'text',
  ...rest
}: LineItemInputProps) => {
  const [inputText, setInputText] = useState<string | number>()
  const onBlurHandler = (e: any) => {
    e.preventDefault()
    if (value !== inputText) {
      onUpdate(inputText)
    }
  }

  useEffect(() => {
    setInputText(value ?? '')
  }, [value])

  return (
    <input
      name="sectionItemName"
      className={`text-sm border-b-1 border-dotted border-white hover:border-gray-300 bg-white ${className}`}
      style={{
        width
      }}
      {...rest}
      value={inputText}
      onFocus={(e) => e.target.select()}
      onChange={(e) => {
        if (inputType === 'number') {
          if (/^(\d*)$/i.test(e?.target?.value)) {
            e.preventDefault()
            setInputText(e.target.value)
            return
          }
          return
        }

        if (inputType === 'decimal') {
          if (/^((\d*(\.\d*)?)|(\.\d+))$/i.test(e?.target?.value)) {
            e.preventDefault()
            setInputText(e.target.value)
            return
          }
          return
        }

        setInputText(e.target.value)
      }}
      onBlur={onBlurHandler}
      disabled={disabled}
      type={type === 'number' ? 'text' : type}
      onClick={openTicketModal}
    />
  )
}
