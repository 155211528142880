import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {Button, Modal, Loading, toast} from 'app/common'

import {DynamicSearchSelect} from 'app/components'

import {addUserToGroupAction, getCompanyUserByIdAction} from 'redux-src'

export const AddUserToGroup = ({newGroupModal, setNewGroupModal}: any) => {
  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {id} = params as any

  const {
    getAppUserList_loading
  }: // companyUserList,
  RT.UserReduxType = useSelector((state: any) => state.user)

  const {add_loading} = useSelector((state: any) => state.group)

  const [formData, {onSubmit, setValue}] = useFormInput<{
    groupId: number | undefined
  }>(
    {
      groupId: undefined
    },
    (data) => {
      if (data?.groupId === 0 || data?.groupId === undefined) {
        return toast.error('Please select a group!')
      }

      dispatch(
        addUserToGroupAction(formData?.groupId, id, () => {
          setNewGroupModal(false)
          dispatch(getCompanyUserByIdAction(id))
        })
      )
    }
  )

  return (
    <>
      <Modal
        size="sm"
        isActive={newGroupModal}
        toggleModal={setNewGroupModal}
        title="Add to group"
      >
        {getAppUserList_loading || add_loading ? (
          <Loading />
        ) : (
          <form onSubmit={onSubmit}>
            <div className="mx-16 my-6 mb-0 h-[20vh] flex flex-col flex-nowrap  overflow-y-auto">
              <div className="w-full px-16 my-8 flex flex-col gap-6 mt-[-4px]">
                <div className="mt-12">
                  <DynamicSearchSelect
                    actionUrl={`/users/group/unattached/${id}`}
                    remapOptions={(data: any) => {
                      return data?.map((item: any) => {
                        return {
                          id: item?.group_details?.id,
                          label: item?.group_details?.name,
                          value: item?.group_details?.name
                        }
                      })
                    }}
                    placeholder={'Add a group'}
                    onChangeValue={(value) => {
                      setValue('groupId', value.id)
                    }}
                    // onMultiChangeValue={(value) => {
                    //   setValue(
                    //     'groupIds',
                    //     value?.map((group) => group.id)
                    //   )
                    // }}
                    // isMulti
                  />
                </div>
              </div>
            </div>

            <div className="border-t-1 border-gray-200 "></div>

            <div className="flex justify-end px-16 py-10">
              <Button
                title="cancel"
                buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
                size="sm"
                type="button"
                onClick={() => setNewGroupModal(false)}
              />
              <div className="ml-16">
                <Button title="Save" size="sm" type="submit" />
              </div>
            </div>
          </form>
        )}
      </Modal>
    </>
  )
}
