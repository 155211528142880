import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import clsx from 'clsx'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {IoMdClose} from 'react-icons/io'
import {AiFillTags} from 'react-icons/ai'
import {MdDelete} from 'react-icons/md'

import {camelCase} from 'change-case'

import {Textarea, ToolTip} from 'app/common'
import {
  createNewLineItemAction,
  deleteLineItemAction,
  getQuoteBasicDetailsAction,
  updateLineItemAction,
  updateQuoteSectionTitleAction
} from 'redux-src'

import {LineItemInput, AddLineItem} from './components'
import {QUOTE_TABLE_HEADINGS} from '../../constants'

export const SectionCard = ({
  sectionDetail,
  sectionLineItemList,
  onRemoveSection,
  sectionList,
  // setSectionList,
  sectionIndex,
  fetchQuoteItem,
  activeQuoteId
}: {
  sectionDetail?: Api.QuoteItemIndividual
  onRemoveSection: () => void
  sectionList: Api.QuoteDetailsById
  setSectionList?: (a: any) => void
  sectionIndex: number
  sectionLineItemList: Api.LineItemIndividual[]
  fetchQuoteItem?: () => void
  activeQuoteId?: number
}) => {
  const dispatch = useDispatch()
  const {toast, currencySymbol} = useAuth()
  // const sectionDetail = sectionList[sectionIndex]
  const [sectionTitle, setSectionTitle] = useState(() => sectionDetail?.title)
  const [quoteDescription, setQuoteDescription] = useState<string>(
    () => sectionDetail?.description ?? undefined
  )

  const {params} = useNavigation()
  const {projectId} = params as any

  const onAddLineItemHandler = (body: any) => {
    dispatch(
      createNewLineItemAction(
        projectId,
        sectionDetail?.quote_id,
        sectionDetail?.id,
        body?.id,
        () => {
          fetchQuoteItem()
          dispatch(
            getQuoteBasicDetailsAction(projectId, activeQuoteId, () => {})
          )
        }
      )
    )
  }

  type LineItemKeys =
    | 'name'
    | 'taxPercent'
    | 'quantity'
    | 'discountPercent'
    | 'cost'

  const onUpdateLineItemHandler = (
    key: LineItemKeys,
    value: any,
    item: any
  ) => {
    if (+value === +item[key]) return

    const body = {
      quantity: item.quantity,
      discountPercent: item.discount_percent,
      taxPercent: item.tax_percent,
      name: item.name,
      cost: item.cost
    }

    body[key] = value

    if (key === 'cost') {
      body[key] = Number(value)
    }

    dispatch(
      updateLineItemAction(
        projectId,
        sectionDetail?.quote_id,
        sectionDetail?.id,
        item.id,
        body,
        () => {
          fetchQuoteItem()
          dispatch(
            getQuoteBasicDetailsAction(projectId, activeQuoteId, () => {})
          )
        }
      )
    )
  }

  // const onUpdateLineItemCode = (lineItemId: any, salesItem: any) => {
  //   const body = {
  //     type: salesItem?.id ?? 1,
  //   }
  //   dispatch(
  //     updateLineItemAction(sectionDetail?.id, lineItemId, body, toast, () => {
  //       fetchQuoteItem()
  //     })
  //   )
  // }

  const onRemoveHandler = (id: number) => {
    dispatch(
      deleteLineItemAction(
        projectId,
        sectionDetail?.quote_id,
        sectionDetail?.id,
        id,
        toast,
        () => {
          fetchQuoteItem()
        }
      )
    )
  }

  const onUpdateSectionTitle = (e: any) => {
    e.preventDefault()

    if (
      sectionDetail?.title === sectionTitle &&
      sectionDetail?.description === quoteDescription
    ) {
      return
    }

    const body = {
      title: sectionTitle,
      description: quoteDescription
    }
    dispatch(
      updateQuoteSectionTitleAction(
        projectId,
        activeQuoteId,
        sectionDetail?.id,
        body,
        toast,
        () => {
          fetchQuoteItem()
        }
      )
    )
  }

  return (
    <div className="mt-30">
      <div className="flex gap-10">
        <input
          className="w-1/3 rounded-sm text-xl border-1 border-gray-200 shadow-sm font-bold mb-12 px-8"
          value={sectionTitle}
          onChange={(e: any) => setSectionTitle(e.target.value)}
          onBlur={onUpdateSectionTitle}
        />
        <ToolTip text="Delete Section" top>
          <button
            type="button"
            className="border-1 py-2 h-[31px] rounded-sm px-10 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-red-300 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white transition-all duration-200 ease-in-out"
            onClick={() => {
              onRemoveSection()
            }}
          >
            <MdDelete size={20} />
          </button>
        </ToolTip>
      </div>

      <Textarea
        id="section-description"
        className="text-sm mt-16 mb-16"
        placeholder="Click here to enter description"
        size="sm"
        value={quoteDescription}
        onChange={(e: any) => {
          setQuoteDescription(e.target.value)
        }}
        onBlur={onUpdateSectionTitle}
      />

      <AddLineItem
        onAddLineItem={onAddLineItemHandler}
        sectionDetail={sectionDetail}
        fetchQuoteItem={fetchQuoteItem}
      />

      <div className="mt-8">
        {sectionLineItemList?.length > 0 && (
          <>
            {sectionLineItemList?.map((item, index) => {
              const lineItem = Object.keys(item ?? {})

              if (item === null)
                return <React.Fragment key={index}> </React.Fragment>

              return (
                <div className="pt-4" key={item?.id}>
                  <div className="w-full flex">
                    <div className="flex justify-center items-center">
                      <input type="checkbox" disabled />
                    </div>
                    <AiFillTags size={14} />
                    {[
                      'name',
                      'quantity',
                      'cost',
                      'price',
                      'tax_percent',
                      'discount_percent',
                      'total_cost'
                    ]?.map((key, index) => (
                      <LineItemInput
                        key={index}
                        width={
                          QUOTE_TABLE_HEADINGS?.find(
                            (heading) => heading?.key === key
                          )?.width
                        }
                        type={key === 'name' ? 'string' : 'number'}
                        inputType={
                          key === 'quantity'
                            ? 'number'
                            : key === 'name'
                            ? 'text'
                            : 'decimal'
                        }
                        value={String(
                          item?.[key as keyof Api.LineItemIndividual]
                        )}
                        disabled={
                          !!item?.supplier_id
                            ? key === 'cost' || key === 'name'
                            : key === 'total_cost' || key === 'price'
                        }
                        className={clsx(
                          index === 0
                            ? 'text-left'
                            : index === lineItem?.length - 1
                            ? 'text-right'
                            : 'text-center',
                          QUOTE_TABLE_HEADINGS?.find(
                            (heading) => heading?.key === key
                          )?.hidden && 'text-gray-300'
                        )}
                        onUpdate={(text: any) =>
                          onUpdateLineItemHandler(
                            camelCase(key) as LineItemKeys,
                            text,
                            item
                          )
                        }
                      />
                    ))}

                    <div
                      className="cursor-pointer hover:text-red-300 transition-all duration-200 ease-in-out"
                      onClick={() => onRemoveHandler(item?.id)}
                    >
                      <IoMdClose />
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="flex flex-row justify-between">
              <div></div>
              <div
                style={{width: '11%'}}
                className="flex flex-row justify-center"
              >
                <div className="font-bold text-sm ">
                  {currencySymbol} {sectionDetail?.total_cost ?? '0.00'}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
