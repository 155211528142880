import moment from 'moment'
import React, {useCallback, useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {TableColumn} from 'react-data-table-component'

import {DataTable} from 'app/components'
import {Modal} from 'app/common'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectListByFormId} from 'redux-src'

interface FormProjectListModalProps {
  isModalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  formTemplateId: number
}

export const FormProjectListModal = ({
  isModalVisible,
  setModalVisible,
  formTemplateId
}: FormProjectListModalProps) => {
  const dispatch = useDispatch()

  const fetchProjectListByFormId = useCallback(
    (page: number, limit: number) => {
      !!formTemplateId &&
        dispatch(
          getProjectListByFormId(
            {page: page, limit: limit},
            formTemplateId,
            () => {}
          )
        )
    },
    [dispatch, formTemplateId]
  )

  useEffect(() => {
    fetchProjectListByFormId(1, 10)
  }, [fetchProjectListByFormId])

  return (
    <Modal
      isActive={isModalVisible}
      toggleModal={setModalVisible}
      title="Project List"
      size="lg"
    >
      <div className="flex flex-col p-16">
        <ProjectListSetting
          fetchProjectListByFormId={fetchProjectListByFormId}
        />
      </div>
    </Modal>
  )
}

interface RemappedProjectListTypes {
  id: number
  projectNumber: number
  title: string
  projectPrefix: string
  projectType: string
  status: string
  customerName: string
  dateCreated: string
  customerId: number
}

const ProjectListSetting = ({
  fetchProjectListByFormId
}: {
  fetchProjectListByFormId: (page: number, limit: number) => void
}) => {
  const {
    projectListByFormId: projectListFromApi,
    projectListByFormIdTotal,
    getProjectListByFormIdLoading
  }: RT.FormsReduxType = useSelector((state: any) => state.forms)

  const remappedProjectList: Array<RemappedProjectListTypes> = useMemo(() => {
    if (!projectListFromApi) return []

    const remappedList = projectListFromApi?.map(
      ({customer_details, project_details}) => {
        return {
          id: project_details?.id,
          title: project_details?.title,
          projectNumber: project_details?.project_number,
          projectPrefix: project_details?.project_prefix,
          projectType: project_details?.project_type,
          status: project_details?.project_status,
          customerName: customer_details?.name,
          dateCreated: project_details?.created_at,
          customerId: customer_details?.id
        }
      }
    )

    return remappedList
  }, [projectListFromApi])

  const columns: TableColumn<RemappedProjectListTypes>[] = [
    {
      name: 'Project',
      selector: (row) => row.projectPrefix,
      sortable: true,

      cell: (row) => (
        <Link
          to={`/projects/${row.id}/projects-summary`}
          className="text-sm font-bold text-blue-300 cursor-pointer"
        >
          {row.projectPrefix}-{row.projectNumber}
        </Link>
      )
    },
    {
      name: 'Project Type',
      selector: (row) => row.projectType,
      sortable: true,

      cell: (row) => (
        <div className="text-xs font-bold bg-blue-300 text-white px-6 py-4 rounded-full uppercase w-[max-content]">
          {row.projectType}
        </div>
      )
    },
    {
      name: 'Project Status',
      selector: (row) => row.status,

      cell: (row) => (
        <div className="text-xs font-bold bg-blue-300 text-white px-6 py-4 rounded-full uppercase w-[max-content]">
          {row.status}
        </div>
      )
    },
    {
      name: 'Title',
      selector: (row) => row.title,
      cell: (row) => (
        <Link
          to={`/projects/${row.id}/projects-summary`}
          className="text-sm text-blue-300 cursor-pointer"
        >
          {row.title}
        </Link>
      )
    },
    {
      name: 'Customer',
      selector: (row) => row.customerName,

      sortable: true,
      cell: (row) => (
        <Link
          to={`/customers/view/${row.customerId}`}
          className="text-sm text-blue-300 cursor-pointer"
        >
          {row.customerName}
        </Link>
      )
    },
    {
      name: 'Date Created',
      sortable: true,
      selector: (row) => row.dateCreated,
      cell: (row) => (
        <div className="text-sm">
          <div className="text-sm">
            {moment(row.dateCreated).format('DD/MM/YYYY')}
          </div>
        </div>
      )
    }
  ]

  return (
    <div className="flex flex-col items-center justify-center">
      <DataTable
        columns={columns}
        data={remappedProjectList ?? []}
        totalRows={projectListByFormIdTotal}
        loading={getProjectListByFormIdLoading}
        fetchData={fetchProjectListByFormId}
        pagination
      />
    </div>
  )
}
