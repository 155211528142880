// import React from 'react';
// import {Link} from 'react-router-dom';
export const IconButton = ({ icon, label, type, onClick, disabled }: any) => {
  if (type === 'button') {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className="border-1 border-blue-400 px-16 py-8 rounded-sm flex items-center text-sm leading-tight transition-all duration-200 ease group hover:bg-blue-300"
      >
        <span className="icon text-blue-400 mr-16 relative -top-2 text-lg  transition-all duration-200 ease group-hover:text-white">
          {icon}
        </span>
        <span className="label font-medium  transition-all duration-200 ease group-hover:text-white">
          {label}
        </span>
      </button>
    )
  }
  return (
    <div className="border-1 border-blue-400 px-16 py-8 rounded-sm flex items-center text-sm leading-tight transition-all duration-200 ease group hover:bg-blue-300">
      <span className="icon text-blue-400 mr-16 relative -top-2 text-lg  transition-all duration-200 ease group-hover:text-white">
        <img src={icon} alt={label} />
      </span>
      <span className="label font-medium  transition-all duration-200 ease group-hover:text-white">
        {label}
      </span>
    </div>
  )
}
