import {useCallback, useEffect, useMemo, useState} from 'react'
import {useFormInput} from 'use-form-input'
import {useDispatch} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {FaTrash} from 'react-icons/fa'
import {BiPlus} from 'react-icons/bi'

import {Input, SelectField, Textarea, ToolTip} from 'app/common'

import {
  updatePreliminaryItemTemplate,
  updatePreliminaryItemMandatoryInfo,
  createPreliminaryLineItemTemplate,
  updatePreliminaryLineItemTemplate,
  deletePreliminaryLineItemTemplate,
} from 'redux-src'

import {PreliminarySectionTable} from './components'
import {PRELIMINARY_TABLE_HEADINGS} from '../../constants'
import type {PreliminaryTypes} from '../../constants'

export const PreliminaryTemplateSectionCard = ({
  sectionDetail,
  onRemoveSection,
  lineItemList,
  fetchPreliminaryTemplateDetails,
  sectionList,
  bottomSeparator,
}: {
  sectionDetail?: Api.PreliminaryItemTemplateDetails['prelim_item_template_details']
  onRemoveSection?: (sectionId: number) => void
  sectionList?: Api.PreliminaryItemTemplateDetails['prelim_item_template_details']
  lineItemList?: Api.PreliminaryItemTemplateDetails['line_items']
  fetchPreliminaryTemplateDetails?: () => void
  bottomSeparator?: boolean
}) => {
  const [lineItemName, setLineItemName] = useState<string>('')
  // const [lineItems, setLineItems] = useState<Array<RemappedLineItemType>>()

  const {params} = useNavigation()
  const {
    // projectId,
    preliminaryTemplateId,
  } = params as any

  const dispatch = useDispatch()

  const [constantData, {onChange, setValue}] = useFormInput<{
    noOfQuantity: number
    unitName: string
    noOfTransport: number
    sectionTitle: string
    type: PreliminaryTypes
    description: string
  }>(
    {
      noOfQuantity: 0,
      unitName: '',
      noOfTransport: 0,
      sectionTitle: '',
      type: 'item',
      description: '',
    },
    () => {},
  )

  type LineItemKeys = 'name' | 'rate'

  const onUpdateLineItemHandler = (
    key: LineItemKeys,
    value: any,
    item: any,
  ) => {
    if (+value === +item[key]) return

    const lineItemBody = {
      name: item?.name,
      rate: item?.rate,
    }

    lineItemBody[key] = value

    dispatch(
      updatePreliminaryLineItemTemplate(
        preliminaryTemplateId,
        sectionDetail?.id,
        item?.id,
        lineItemBody,
        () => {
          fetchPreliminaryTemplateDetails()
          // dispatch(getProjectByIdAction(projectId))
        },
      ),
    )
  }

  const lineItems = useMemo(() => {
    if (!lineItemList) return []

    return lineItemList?.map((lineItem) => {
      return {
        id: lineItem?.id,
        name: lineItem?.name,
        noOfQuantity: sectionDetail?.total_frequency,
        rate: lineItem?.rate,
        noOfTransport: sectionDetail?.total_vehicles,
        // quantityUnit: sectionDetail?.frequency_unit,
        totalCost: lineItem?.total_cost,
      }
    })
  }, [lineItemList, sectionDetail])

  const onUpdatePreliminaryMonetaryInfo = () => {
    if (
      sectionDetail?.total_frequency === constantData?.noOfQuantity &&
      sectionDetail?.total_vehicles === constantData?.noOfTransport
    ) {
      return
    }

    const monetaryInfoBody = {
      totalFrequency: Number(constantData?.noOfQuantity),
      totalVehicles: Number(constantData?.noOfTransport),
    }

    dispatch(
      updatePreliminaryItemMandatoryInfo(
        preliminaryTemplateId,
        sectionDetail?.id,
        monetaryInfoBody,
        () => {
          fetchPreliminaryTemplateDetails()
          // dispatch(getProjectByIdAction(projectId))
        },
      ),
    )
  }

  const onUpdatePreliminarySectionInfo = () => {
    if (
      sectionDetail?.description === constantData?.description &&
      sectionDetail?.type === constantData?.type &&
      sectionDetail?.title === constantData?.sectionTitle &&
      sectionDetail?.frequency_unit === constantData?.unitName
    ) {
      return
    }

    const infoBody = {
      title: constantData?.sectionTitle,
      type: constantData?.type,
      description: constantData?.description ?? undefined,
      frequencyUnit: String(constantData?.unitName),
    }

    dispatch(
      updatePreliminaryItemTemplate(
        preliminaryTemplateId,
        sectionDetail?.id,
        infoBody,
        () => {
          fetchPreliminaryTemplateDetails()
          // dispatch(getProjectByIdAction(projectId))
        },
      ),
    )
  }

  const onCreatePreliminaryLineItem = (event: any) => {
    event.preventDefault()

    if (lineItemName !== '') {
      const lineItemBody = {
        name: lineItemName,
        rate: 0,
      }

      dispatch(
        createPreliminaryLineItemTemplate(
          preliminaryTemplateId,
          sectionDetail?.id,
          lineItemBody,
          () => {
            fetchPreliminaryTemplateDetails()
            setLineItemName('')
            // dispatch(getProjectByIdAction(projectId))
          },
        ),
      )
    }
  }

  const onRemoveLineItem = (lineItemId: number) => {
    dispatch(
      deletePreliminaryLineItemTemplate(
        preliminaryTemplateId,
        sectionDetail?.id,
        lineItemId,
        () => {
          fetchPreliminaryTemplateDetails()
          // dispatch(getProjectByIdAction(projectId))
        },
      ),
    )
  }

  const clearField = useCallback(() => {
    setValue('noOfQuantity', 0)
    setValue('unitName', sectionDetail?.frequency_unit)
    setValue('noOfTransport', 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constantData?.type])

  const preliminaryData = useMemo(() => {
    clearField()
    return {
      type: constantData?.type,
      header: PRELIMINARY_TABLE_HEADINGS[constantData?.type],
      data: lineItems,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constantData?.type, lineItems])

  useEffect(() => {
    if (sectionDetail !== undefined && sectionDetail !== null) {
      setValue('type', sectionDetail?.type)
      setValue('noOfQuantity', sectionDetail?.total_frequency ?? 0)
      setValue('sectionTitle', sectionDetail?.title)
      setValue('unitName', sectionDetail?.frequency_unit)
      setValue('noOfTransport', sectionDetail?.total_vehicles)
      setValue('description', sectionDetail?.description)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionDetail])

  return (
    <>
      <div className="mt-6 py-16 px-20 bg-gray-100 rounded-md">
        <div className="flex gap-8">
          <input
            className="w-1/3 rounded-sm text-xl border-1 border-gray-200 shadow-sm font-bold mb-12 px-6"
            value={constantData?.sectionTitle}
            name="sectionTitle"
            onChange={onChange}
            onBlur={onUpdatePreliminarySectionInfo}
          />

          <SelectField
            options={options}
            placeholder="Select..."
            className={'w-[100px]'}
            value={options.find((item) => item.value === constantData?.type)}
            onChangeValue={(value) => {
              setValue('type', value?.value)
            }}
            onBlur={onUpdatePreliminarySectionInfo}
            isDisabled={lineItemList === null ? false : true}
          />

          <ToolTip text="Delete Section" top>
            <div
              className="flex justify-center items-center h-[32px] cursor-pointer hover:text-red-300 transition-all duration-200 ease-in-out"
              onClick={() => {
                onRemoveSection(sectionDetail?.id)
              }}
            >
              <FaTrash size={16} />
            </div>
          </ToolTip>
        </div>

        <div>
          {constantData?.type === 'transport' && (
            <div className="flex gap-16">
              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of quantity : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfQuantity"
                    value={constantData?.noOfQuantity}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div>

              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">Unit name : </label>
                <span className="">
                  <Input
                    type="text"
                    name="unitName"
                    value={constantData?.unitName}
                    onChange={onChange}
                    onBlur={onUpdatePreliminarySectionInfo}
                  />
                </span>
              </div>

              {/* <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of weeks : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfWeeks"
                    value={constantData?.noOfWeeks}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div> */}
            </div>
          )}

          {constantData?.type === 'dynamic item' && (
            <div className="flex gap-16">
              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of quantity : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfQuantity"
                    value={constantData?.noOfQuantity}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div>

              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">Unit name : </label>
                <span className="">
                  <Input
                    type="text"
                    name="unitName"
                    value={constantData?.unitName}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminarySectionInfo}
                  />
                </span>
              </div>
            </div>
          )}

          {constantData?.type === 'fuel' && (
            <div className="flex gap-16">
              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of quantity : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfQuantity"
                    value={constantData?.noOfQuantity}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div>

              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">Unit name : </label>
                <span className="">
                  <Input
                    type="text"
                    name="unitName"
                    value={constantData?.unitName}
                    onChange={onChange}
                    onBlur={onUpdatePreliminarySectionInfo}
                  />
                </span>
              </div>

              {/* <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of weeks : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfWeeks"
                    value={constantData?.noOfWeeks}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div> */}
              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of transport : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfTransport"
                    value={constantData?.noOfTransport}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div>
            </div>
          )}
        </div>

        <Textarea
          className="text-sm mt-10"
          placeholder="Click here to enter description"
          size="sm"
          name="description"
          value={constantData?.description}
          onChange={onChange}
          onBlur={onUpdatePreliminarySectionInfo}
        />
        {preliminaryData?.data?.length > 0 && (
          <div className="flex flex-col gap-14">
            <PreliminarySectionTable
              preliminaryHeader={preliminaryData?.header}
              preliminaryType={preliminaryData?.type}
              preliminaryData={preliminaryData?.data}
              onRemoveLineItem={onRemoveLineItem}
              onUpdateLineItemHandler={onUpdateLineItemHandler}
              sectionDetails={sectionDetail as any}
            />
          </div>
        )}
      </div>
      <div className="mt-10 w-[400px]">
        <form onSubmit={(e) => onCreatePreliminaryLineItem(e)}>
          <Input
            prepend={<BiPlus size={24} color="black" />}
            type="text"
            name="lineItemName"
            value={lineItemName}
            placeholder="Enter a line item"
            onChange={(e) => setLineItemName(e.target.value)}
            onBlur={(e) => onCreatePreliminaryLineItem(e)}
          />
        </form>
      </div>

      {bottomSeparator && (
        <div className="w-full h-[1px] border-t-1 border-gray-200 border-dashed" />
      )}
    </>
  )
}

const options: Array<{id: number; label: string; value: string}> = [
  {
    id: 1,
    label: 'Dynamic Item',
    value: 'dynamic item',
  },
  {
    id: 2,
    label: 'Transport',
    value: 'transport',
  },
  {
    id: 3,
    label: 'Fuel',
    value: 'fuel',
  },
  {
    id: 4,
    label: 'Item',
    value: 'item',
  },
]
