import {Auth} from 'react-auth-navigation'
import {
  FaBook,
  FaFile,
  FaSlidersH,
  FaQrcode,
  FaClipboardList,
  FaDollarSign,
  FaPersonBooth,
  FaUserAlt,
  // FaUserAlt
} from 'react-icons/fa'
import {SiMaterialdesign} from 'react-icons/si'

import {RiShoppingBag3Fill, RiTruckFill} from 'react-icons/ri'
import {BiSitemap} from 'react-icons/bi'
import {HiTemplate, HiUserGroup} from 'react-icons/hi'

import {SidePanel} from '../../components'
import {BsFileEarmarkRuledFill} from 'react-icons/bs'

export const SettingsPage = () => {
  return (
    <div className="w-9/12 mx-auto">
      <div className="flex border-t-1 border-t-gray-200">
        <div
          className="bg-white border-r-1 border-r-gray-200 py-10"
          style={{width: 200}}
        >
          <SidePanel
            data={{
              Settings: [
                {
                  name: 'General',
                  icon: <FaSlidersH size={14} />,
                  url: '/settings',
                },
                {
                  name: 'Quoting',
                  icon: <FaFile size={14} />,
                  url: '/settings/quoting',
                },
                // {
                //   name: 'Invoicing',
                //   icon: <FaDollarSign size={14} />,
                //   url: '/settings/invoicing'
                // },
                {
                  name: 'Forms',
                  icon: <BsFileEarmarkRuledFill size={14} />,
                  url: '/settings/forms',
                },
                {
                  name: 'Preliminary Template',
                  icon: <HiTemplate size={14} />,
                  url: '/settings/preliminary-templates',
                },
                // {
                //   name: 'CheckLists',
                //   icon: <FaClipboardList size={14} />,
                //   url: '/settings/checklists',
                // },
                // {
                //   name: 'Price Books',
                //   icon: <FaBook size={14} />,
                //   url: '/settings/price-books',
                // },
                // {
                //   name: 'Sales Account Codes',
                //   icon: <FaQrcode size={14} />,
                //   url: '/settings/sales-account-codes',
                // },
                {
                  name: 'Item Type Management',
                  icon: <RiShoppingBag3Fill size={14} />,
                  url: '/settings/item-type-management',
                },
                {
                  name: 'Supplier',
                  icon: <RiTruckFill size={14} />,
                  url: '/settings/supplier',
                },
                {
                  name: 'Labour Element',
                  icon: <FaPersonBooth size={14} />,
                  url: '/settings/labour-element-management',
                },
                {
                  name: 'Material List',
                  icon: <SiMaterialdesign size={14} />,
                  url: '/settings/material-list',
                },
                {
                  name: 'Labour and Material Combined',
                  icon: <BiSitemap size={14} />,
                  url: '/settings/labour-and-material-combined',
                },
                {
                  name: 'Users',
                  icon: <FaUserAlt size={14} />,
                  url: '/settings/users',
                },
                {
                  name: 'Groups',
                  icon: <HiUserGroup size={14} />,
                  url: '/settings/groups',
                },
              ],
            }}
          />
        </div>

        <div className="flex-1 bg-white pt-10 px-16">
          <Auth.Screens path="/settings" />
        </div>
      </div>
    </div>
  )
}
