import {useEffect, useState} from 'react'
import moment from 'moment'
import {FaNewspaper} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'

import {InfiniteScrollBlock, Loading, toast} from 'app/common'
import {RootState, createNotice, getNotice} from 'redux-src'
import {getAssetUrl} from 'helpers'

export const NoticeBoard = () => {
  const {isNoticeLoading, notice}: RT.NoticeReduxType = useSelector(
    (state: RootState) => state.notice,
  )
  const dispatch = useDispatch()
  const [noticeLimit, setNoticeLimit] = useState(10)

  useEffect(() => {
    dispatch(getNotice({limit: noticeLimit}))
  }, [dispatch, noticeLimit])

  const [noticeText, setNoticeText] = useState<string>('')

  return (
    <div className="rounded-sm bg-white border-1 border-[#d1d1d1]">
      <div className="p-16">
        <div className="flex gap-x-6 items-center">
          <h2 className="text-base text-gray-500 font-bold">Notice Board</h2>
          <div className="w-max p-[2px] px-6 bg-gray-200 rounded rounded-sm ">
            {notice?.total}
          </div>
        </div>
        <div className="mt-6">
          <input
            name="address"
            className="w-[100%] px-10 py-8 border-1 rounded-sm border-[#d1d1d1]"
            placeholder="Post something"
            style={{
              borderColor: '#d1d1d1',
            }}
            value={noticeText}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (noticeText?.length > 0) {
                  dispatch(
                    createNotice(
                      {
                        message: noticeText,
                      },
                      () => {
                        dispatch(getNotice())
                      },
                    ),
                  )
                  setNoticeText('')
                } else {
                  toast.error('Please enter a notice')
                }
              }
            }}
            onChange={(e) => {
              setNoticeText(e.target.value)
            }}
          />
        </div>
      </div>

      <div className="m-16 mt-0 flex">
        <div
          className="border-r-1 flex items-center justify-center px-16 bg-gray-400 text-white rounded-l-sm"
          style={{
            borderColor: '#d1d1d1',
          }}
        >
          <FaNewspaper size={20} />
        </div>

        <div
          className="flex-1 p-6 text-sm border-1 border-l-0 rounded-r-sm text-gray-400"
          style={{
            borderColor: '#d1d1d1',
          }}
        >
          This is the notice board. You (or any other employee) can post
          something here and it can be seen by everyone in your company.
        </div>
      </div>
      {isNoticeLoading && !notice && <Loading />}

      {notice?.rows?.length > 0 && (
        <div className="pb-20">
          <InfiniteScrollBlock
            triggerCallback={() => {
              setNoticeLimit((prev) => prev + 10)
            }}
            isLast={notice?.isLast}
            className="flex flex-col gap-20 max-h-[41.2vh] w-full p-20 flex-1  overflow-y-auto"
          >
            {notice &&
              notice?.rows?.map((item) => (
                <NoticeContent key={item.created_at} notice={item} />
              ))}
          </InfiniteScrollBlock>
        </div>
      )}
    </div>
  )
}

interface NoticeContentProps {
  notice: Api.Notice
}
const NoticeContent = ({notice}: NoticeContentProps) => {
  const {created_at, message, user_image_details, user_details} = notice
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row w-full gap-6">
        {!!user_image_details ? (
          <>
            <img
              src={getAssetUrl(user_image_details)}
              alt={user_details?.display_name}
              className="h-[30px] w-[30px] object-cover rounded-md overflow-hidden"
            />
          </>
        ) : (
          <>
            <span className="flex justify-center h-[30px] w-[30px] rounded-md text-white bg-blue-400 text-sm items-center">
              {user_details?.display_name
                ?.split(' ')
                ?.at(0)
                ?.charAt(0)
                ?.toUpperCase()}
              {user_details?.lastname
                ?.split(' ')
                ?.at(0)
                ?.charAt(0)
                ?.toUpperCase()}
            </span>
          </>
        )}

        <div className="flex flex-col gap-16 w-full">
          <div className="flex justify-between w-full">
            <div className="flex gap-10">
              <span className="text-sm font-bold">
                {user_details?.display_name
                  ?.split(' ')
                  ?.at(0)
                  ?.charAt(0)
                  ?.toUpperCase()}
                .{user_details?.lastname?.split(' ')?.at(0)}
              </span>
            </div>
            <div className="text-sm text-gray-400">
              {moment(created_at).format('DD/MM/YYYY hh:mm a')}
            </div>
          </div>
          <div className="pr-6 mt-[-10px] w-full">{message}</div>
        </div>
      </div>
    </div>
  )
}
