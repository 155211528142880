import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useSelector, useDispatch} from 'react-redux'
import {TableColumn} from 'react-data-table-component'
import {useFormInput} from 'use-form-input'

import {DataTable, DynamicSearchSelect, MessageBox} from 'app/components'
import {
  Modal,
  Checkbox,
  toast,
  Button,
  ToolTip,
  ConfirmationModal
} from 'app/common'
import {
  attachLabourElementToSubTicket,
  getLabourElementsOfTickets,
  getTicketDetailsById,
  makeLabourElementMandatory,
  makeLabourElementToInComplete,
  removeLabourElementMandatoryStatus,
  getLabourElementsOfSubTickets,
  deattachLabourElementToSubTicket,
  makeSubTicketLabourCompleteAction,
  getLabourElementsOfParentTickets,
  getParentTicketDetails
} from 'redux-src'

import {AllAttachmentsModal} from '../allAttachmentsModal'

import {TicketContext} from '../../ticket.page'
import {APIS} from 'config'

interface HandleLabourElementModalProps {
  ticketId?: number
  isModalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  edit?: boolean
  lineItemLabourElementList: Api.LineItemLabourElementList
  selectedQuoteLineItem: {id: number; label: string; value: number}
  isSubTicketModal?: boolean
  isSubTicketEdit?: boolean
  selectedLabourElements?: Array<LabourElementsRemappedTypes>
  onCloseModal?: () => void
}

export interface LabourElementsRemappedTypes {
  id: number
  label: string
  value: string
  isChecked: boolean
  isMandatory: boolean
  hasAttachments: boolean
  subTicket: string
  subTicketId: number
  ticketId: number
}

export const HandleLabourElementModal = ({
  ticketId,
  setModalVisible,
  isModalVisible,
  edit,
  lineItemLabourElementList,
  selectedQuoteLineItem,
  isSubTicketEdit,
  isSubTicketModal,
  onCloseModal,
  selectedLabourElements: selectedLabourElementList
}: HandleLabourElementModalProps) => {
  const {
    ticketDetailsById: ticketDetailsFromApi,
    labourElementListLoading,
    labourElements: labourElementList,
    labourElementsOfSubTicket,
    attachLabourElementToSubTicketLoading,
    labourElementListOfParentTickets,
    parentTicketDetails
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project
  )

  const [labourElements, setLabourElements] =
    useState<Array<LabourElementsRemappedTypes>>()

  const {setProjectTicketId, setCreateSubTicketModal} =
    useContext(TicketContext)

  const [selectedLabourElements, setSelectedLabourElements] = useState<
    Array<LabourElementsRemappedTypes>
  >([])

  const [data, {setValue}] = useFormInput({
    selectedLabourElement: undefined,
    addedLabourElement: undefined
  })

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const remappedLabourElements = useCallback(() => {
    if (!!!labourElementList) return

    const remappedList = labourElementList?.map(
      ({
        quote_labour_el_details,
        ticket_labour_el_details,
        sub_ticket_details
      }) => {
        return {
          id: ticket_labour_el_details?.id,
          label: quote_labour_el_details?.name,
          value: quote_labour_el_details?.name,
          isChecked: ticket_labour_el_details?.is_completed,
          isMandatory: ticket_labour_el_details?.is_mandatory,
          hasAttachments: ticket_labour_el_details?.has_attachments,
          subTicket: sub_ticket_details?.title,
          subTicketId: sub_ticket_details?.id,
          ticketId: ticket_labour_el_details?.ticket_id
        }
      }
    )

    setLabourElements(remappedList)
  }, [labourElementList])

  const remappedSubTicketLabourElements = useMemo(() => {
    if (!!!labourElementsOfSubTicket) return []

    const remappedList = labourElementsOfSubTicket?.map(
      ({
        quote_labour_el_details,
        ticket_labour_el_details,
        sub_ticket_details
      }) => {
        return {
          id: ticket_labour_el_details?.id,
          label: quote_labour_el_details?.name,
          value: quote_labour_el_details?.name,
          isChecked: ticket_labour_el_details?.is_completed,
          isMandatory: ticket_labour_el_details?.is_mandatory,
          hasAttachments: ticket_labour_el_details?.has_attachments,
          subTicket: sub_ticket_details?.title,
          subTicketId: sub_ticket_details?.id,
          ticketId: ticket_labour_el_details?.ticket_id
        }
      }
    )

    return remappedList
  }, [labourElementsOfSubTicket])

  useEffect(() => {
    isSubTicketModal &&
      isSubTicketEdit &&
      setSelectedLabourElements(remappedSubTicketLabourElements)
  }, [remappedSubTicketLabourElements, isSubTicketEdit, isSubTicketModal])

  const handleCheckboxChange = (
    ticketLabourId: number,
    type: 'isComplete' | 'isMandatory'
  ) => {
    if (type === 'isComplete') {
      const inCompleteLabour = labourElements?.map((item) => {
        if (item?.id === ticketLabourId) {
          return {
            ...item,
            isChecked: false
          }
        } else {
          return item
        }
      })

      setLabourElements(inCompleteLabour)

      const selectedLabour = labourElements?.find(
        (item) => item?.id === ticketLabourId
      )

      if (!!ticketDetailsFromApi?.ticket_details?.site_visit_id) {
        if (selectedLabour?.isChecked) {
          if (false && isSubTicketModal && isSubTicketEdit) {
            dispatch(
              makeSubTicketLabourCompleteAction(
                projectId,
                ticketDetailsFromApi?.parent_ticket_details?.id,
                ticketDetailsFromApi?.ticket_details?.id,
                ticketLabourId,
                () => {}
              )
            )
          } else {
            dispatch(
              makeLabourElementToInComplete(
                projectId,
                ticketDetailsFromApi?.ticket_details?.id,
                ticketLabourId,
                () => {
                  dispatch(
                    getTicketDetailsById(
                      projectId,
                      ticketDetailsFromApi?.ticket_details?.id,
                      () => {}
                    )
                  )
                }
              )
            )
          }
        } else {
          return toast.error(
            'You cannot change the labour element status to "complete"!! '
          )
        }
      } else {
        return toast.error(
          'Please assign this ticket to site visit first, to change the labour element status to "In complete" !!'
        )
      }
    } else {
      const isLabourMandatory = labourElements?.map((item) => {
        if (item?.id === ticketLabourId) {
          return {
            ...item,
            isMandatory: false
          }
        } else {
          return item
        }
      })

      setLabourElements(isLabourMandatory)

      const selectedLabour = labourElements?.find(
        (item) => item?.id === ticketLabourId
      )

      if (!!ticketDetailsFromApi?.ticket_details?.site_visit_id) {
        if (selectedLabour?.isMandatory) {
          dispatch(
            removeLabourElementMandatoryStatus(
              projectId,
              ticketDetailsFromApi?.ticket_details?.id,
              ticketLabourId,
              () => {
                dispatch(
                  getTicketDetailsById(
                    projectId,
                    ticketDetailsFromApi?.ticket_details?.id,
                    () => {}
                  )
                )
              }
            )
          )
        } else {
          dispatch(
            makeLabourElementMandatory(
              projectId,
              ticketDetailsFromApi?.ticket_details?.id,
              ticketLabourId,
              () => {
                dispatch(
                  getTicketDetailsById(
                    projectId,
                    ticketDetailsFromApi?.ticket_details?.id,
                    () => {}
                  )
                )
              }
            )
          )
        }
      } else {
        return toast.error(
          'You have to assign this ticket to site visits first, to change the "labour element mandatory status" !!'
        )
      }
    }
  }

  const removeLabourElement = (labourId: number) => {
    setSelectedLabourElements((prev) => {
      if (!!prev) {
        return prev.filter((item) => item?.id !== labourId)
      }
      return undefined
    })
  }

  useEffect(() => {
    remappedLabourElements()
  }, [remappedLabourElements])

  useEffect(() => {
    const unassignedLabourElements = labourElementListOfParentTickets
      ?.filter(({sub_ticket_details}) => !!!sub_ticket_details)
      ?.map(
        ({
          quote_labour_el_details,
          ticket_labour_el_details,
          sub_ticket_details
        }) => {
          return {
            id: ticket_labour_el_details?.id,
            label: quote_labour_el_details?.name,
            value: quote_labour_el_details?.name,
            isChecked: ticket_labour_el_details?.is_completed,
            isMandatory: ticket_labour_el_details?.is_mandatory,
            hasAttachments: ticket_labour_el_details?.has_attachments,
            subTicket: sub_ticket_details?.title,
            subTicketId: sub_ticket_details?.id,
            ticketId: ticket_labour_el_details?.ticket_id
          }
        }
      )

    const addedLabourElementList = unassignedLabourElements
      ?.filter((el) =>
        selectedLabourElementList?.find((labourEl) => labourEl.id === el.id)
      )
      .map((finalEl) => ({
        id: finalEl.id,
        value: finalEl.id,
        label: finalEl.label
      }))

    setValue('addedLabourElement', addedLabourElementList)

    setSelectedLabourElements(selectedLabourElementList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLabourElementList])

  const fetchLinItemLabourElementList = useCallback(() => {
    if (!!ticketDetailsFromApi?.ticket_details?.id) {
      dispatch(
        getLabourElementsOfTickets(
          projectId,
          ticketDetailsFromApi?.ticket_details?.id
        )
      )

      dispatch(
        getLabourElementsOfParentTickets(
          projectId,
          ticketDetailsFromApi?.ticket_details?.id,
          {attachedToSubticket: false}
        )
      )
    }
  }, [dispatch, projectId, ticketDetailsFromApi?.ticket_details])

  useEffect(() => {
    fetchLinItemLabourElementList()
  }, [fetchLinItemLabourElementList])

  useEffect(() => {
    !!ticketDetailsFromApi?.parent_ticket_details &&
      !!ticketDetailsFromApi?.ticket_details &&
      dispatch(
        getLabourElementsOfSubTickets(
          projectId,
          ticketDetailsFromApi?.parent_ticket_details?.id,
          ticketDetailsFromApi?.ticket_details?.id
        )
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projectId, ticketDetailsFromApi])

  const onAddLabourELementOnCreateHandle = () => {
    if (!!!data?.addedLabourElement) return

    const filteredElements = labourElements?.filter(
      (item) =>
        item?.id ===
        data?.addedLabourElement?.find((itm: any) => itm.id === item.id)?.id
    )

    setSelectedLabourElements(filteredElements)
  }

  const handleAttachLabourElementToTicket = () => {
    !!data?.selectedLabourElement &&
      dispatch(
        attachLabourElementToSubTicket(
          projectId,
          ticketDetailsFromApi?.parent_ticket_details?.id,
          ticketDetailsFromApi?.ticket_details?.id,
          data?.selectedLabourElement?.id,
          () => {
            dispatch(
              getLabourElementsOfSubTickets(
                projectId,
                ticketDetailsFromApi?.parent_ticket_details?.id,
                ticketDetailsFromApi?.ticket_details?.id
              )
            )
            setValue('selectedLabourElement', undefined)
          }
        )
      )
  }

  return (
    <Modal
      toggleModal={setModalVisible}
      isActive={isModalVisible}
      title={edit ? 'Handle Labour Elements' : 'See Labour Elements'}
      size="md"
    >
      <div className="flex flex-col w-full px-24 py-18 min-h-[300px]">
        <div className="flex gap-8">
          <div className="text-md text-black font-bold">Quote Line Item:</div>
          <div className=" text-md text-blue-300">
            {selectedQuoteLineItem?.label}
          </div>
        </div>

        <MessageBox
          message={
            <div className="text-sm text-center px-24">
              {edit ? (
                <>
                  <div>
                    You can review and set the labour elements status incomplete
                    by unchecking if deemed necessary.
                  </div>
                  {!isSubTicketEdit && (
                    <div>
                      You can create a sub ticket by selecting labour elements
                      and clicking on New Sub Ticket button.
                    </div>
                  )}
                </>
              ) : (
                `You can review the list of labour elements for selected ${
                  projectDetail?.project_details?.project_type === 'Quote'
                    ? 'quote line'
                    : ''
                } item.
              `
              )}
            </div>
          }
        />
        <div className="flex flex-col gap-8">
          <div className="flex flex-row items-center justify-between">
            <div className="text-md text-black font-bold w-[max-content]">
              Labour Element List
            </div>
            {!!edit && !isSubTicketModal && (
              <ToolTip
                text={
                  selectedLabourElements?.length === 0
                    ? 'Select labour elements to create a new sub ticket!'
                    : 'Create a new sub ticket!'
                }
                left
              >
                <Button
                  title="New Sub Ticket"
                  isDisabled={
                    !!!selectedLabourElements ||
                    selectedLabourElements?.length === 0
                  }
                  onClick={() => {
                    const details = {
                      selectedLabourElements: selectedLabourElements,
                      parentTicketDetails: ticketDetailsFromApi?.ticket_details
                    }
                    setProjectTicketId(ticketId)
                    dispatch(getParentTicketDetails(details))
                    onCloseModal()
                    setModalVisible(false)
                    setCreateSubTicketModal((prev) => !prev)
                  }}
                />
              </ToolTip>
            )}
            {isSubTicketModal &&
              (!isSubTicketEdit ? (
                <div className="flex justify-end items-center gap-8 max-w-[500px]">
                  <DynamicSearchSelect
                    actionUrl={`${APIS.projects}/${projectId}/tickets/${
                      ticketId ?? parentTicketDetails?.parentTicketDetails?.id
                    }/labour-elements`}
                    placeholder="Select Labour Elements"
                    remapOptions={(
                      data: Api.LabourElementsOfTicketDetails[]
                    ) => {
                      const filteredLabourElements = data?.filter(
                        ({sub_ticket_details}) => !!!sub_ticket_details
                      )

                      return filteredLabourElements?.map((labourEl) => {
                        return {
                          id: labourEl?.ticket_labour_el_details?.id,
                          label: labourEl?.quote_labour_el_details?.name,
                          value: labourEl?.quote_labour_el_details?.id
                        }
                      })
                    }}
                    onMultiChangeValue={(data) => {
                      setValue('addedLabourElement', data)
                    }}
                    multiValue={data.addedLabourElement}
                    params={{
                      attachedToSubticket: false
                    }}
                    isMulti
                  />

                  <div className="w-[270px]">
                    <Button
                      title="Update Labour Elements"
                      onClick={onAddLabourELementOnCreateHandle}
                      isDisabled={data?.addedLabourElement?.length === 0}
                      className="w-[max-content]"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex gap-10 justify-end items-center">
                  <DynamicSearchSelect
                    actionUrl={`${APIS.projects}/${projectId}/tickets/${ticketDetailsFromApi?.parent_ticket_details?.id}/labour-elements`}
                    placeholder="Select Labour Element"
                    value={data?.selectedLabourElement}
                    remapOptions={(
                      data: Api.LabourElementsOfTicketDetails[]
                    ) => {
                      const filteredLabourElements = data?.filter(
                        ({sub_ticket_details}) => !!!sub_ticket_details
                      )

                      return filteredLabourElements?.map((labourEl) => {
                        return {
                          id: labourEl?.ticket_labour_el_details?.id,
                          label: labourEl?.quote_labour_el_details?.name,
                          value: labourEl?.quote_labour_el_details?.id
                        }
                      })
                    }}
                    onChangeValue={(data) => {
                      setValue('selectedLabourElement', data)
                    }}
                    params={{
                      attachedToSubticket: false
                    }}
                    cacheOptions
                  />
                  <div className="">
                    <Button
                      title="Attach"
                      onClick={handleAttachLabourElementToTicket}
                      isDisabled={!!!data?.selectedLabourElement}
                      loading={attachLabourElementToSubTicketLoading}
                    />
                  </div>
                </div>
              ))}
          </div>
          {edit || isSubTicketModal || isSubTicketEdit ? (
            <div className="flex flex-col">
              <LabourElementList
                loading={labourElementListLoading}
                isSubTicketModal={isSubTicketModal}
                {...{
                  setSelectedLabourElements,
                  labourElements,
                  selectedLabourElements,
                  handleCheckboxChange,
                  ticketId,
                  isSubTicketEdit,
                  removeLabourElement,
                  fetchLinItemLabourElementList
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-6 justify-center">
              {!!lineItemLabourElementList &&
                Array.isArray(lineItemLabourElementList) &&
                lineItemLabourElementList?.length > 0 &&
                lineItemLabourElementList?.map(
                  ({quote_labour_element_detail}, index: number) => {
                    return (
                      <span key={index.toString()} className="flex gap-8">
                        <span className="text-black font-bold text-md">
                          {index + 1}.
                        </span>
                        <span className="text-black font-medium text-md">
                          {quote_labour_element_detail?.name}
                        </span>
                      </span>
                    )
                  }
                )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

const LabourElementList = ({
  labourElements,
  handleCheckboxChange,
  loading,
  setSelectedLabourElements,
  ticketId,
  isSubTicketModal,
  isSubTicketEdit,
  selectedLabourElements,
  removeLabourElement,
  fetchLinItemLabourElementList
}: {
  labourElements: Array<LabourElementsRemappedTypes>
  setSelectedLabourElements: React.Dispatch<
    React.SetStateAction<Array<LabourElementsRemappedTypes>>
  >
  loading: boolean
  handleCheckboxChange: (id: number, type: 'isComplete' | 'isMandatory') => void
  ticketId?: number
  isSubTicketModal?: boolean
  isSubTicketEdit?: boolean
  selectedLabourElements?: Array<LabourElementsRemappedTypes>
  removeLabourElement?: (labourId: number) => void
  fetchLinItemLabourElementList?: () => void
}) => {
  const {deattachLabourElementToSubTIcketLoading}: RT.TicktesReduxType =
    useSelector((state: any) => state.tickets)

  const dispatch = useDispatch()

  const {params} = useNavigation()

  const {projectId} = params as any

  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  const [labourDetails, setLabourDetails] =
    useState<LabourElementsRemappedTypes>()

  const [selectedSubTicket, setSelectedSubTicket] = useState<{
    id: number
    value: string
    label: string
  }>()

  const deattachLabourElementFromTicket = (
    ticketId: number,
    subTicketId: number,
    labourId: number,
    cb: () => void
  ) => {
    !!labourId &&
      dispatch(
        deattachLabourElementToSubTicket(
          projectId,
          ticketId,
          subTicketId,
          labourId,
          () => {
            cb()
            fetchLinItemLabourElementList()
            dispatch(
              getLabourElementsOfSubTickets(projectId, ticketId, subTicketId)
            )
          }
        )
      )
  }

  const columns: TableColumn<LabourElementsRemappedTypes>[] = [
    {
      name: 'Labour Element',
      selector: (row) => row.label,
      cell: (row) => {
        return <div className={'text-blue-300 font-medium'}>{row.label}</div>
      },
      sortable: true
    },
    {
      name: 'Complete',
      selector: (row) => row.label,
      omit: isSubTicketModal,
      cell: (row) => {
        return (
          <div className={'text-blue-300 font-medium mt-[-14px]'}>
            {
              <Checkbox
                key={`labourElement-${row?.id}`}
                dataId={`${row?.label}-labourElement`}
                label={''}
                className="px-6"
                isChecked={row?.isChecked}
                onChange={() => {
                  handleCheckboxChange(row?.id, 'isComplete')
                }}
              />
            }
          </div>
        )
      },
      sortable: true
    },
    {
      name: 'Compulsory',
      selector: (row) => row.isMandatory,
      sortable: true,
      omit: isSubTicketModal,
      cell: (row) => {
        return (
          <div className={'text-blue-300 font-medium mt-[-14px]'}>
            {
              <Checkbox
                key={`labourElement-${row?.id}`}
                dataId={`${row?.label}-mandatory`}
                label={''}
                className="px-6"
                isChecked={row?.isMandatory}
                onChange={() => {
                  handleCheckboxChange(row?.id, 'isMandatory')
                }}
              />
            }
          </div>
        )
      }
    },
    {
      name: 'Sub Ticket',
      selector: (row) => row.subTicket,
      sortable: true,
      omit: isSubTicketModal,
      cell: (row) => {
        return (
          <div
            className={'text-blue-300 font-medium mt-[-14px]'}
            onClick={() => row.subTicket && console.log({view: 'subTicket'})}
          >
            {row.subTicket ?? '---'}
          </div>
        )
      }
    },
    {
      name: 'Attachments',
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => {
        return (
          <div className="flex items-center">
            <div className="cursor-pointer flex items-center justify-center hover:text-red-300 transition-all duration-500 ease-in-out">
              <Button
                title="View"
                onClick={() => {
                  setLabourDetails(row)
                  setModalVisible(true)
                }}
              />
            </div>
          </div>
        )
      }
    },
    {
      name: 'Actions',
      selector: (row) => row.id,
      sortable: true,
      right: true,
      cell: (row) => {
        return (
          <div className="flex items-center justify-end">
            {isSubTicketModal ? (
              !isSubTicketEdit ? (
                <Button
                  title="Remove"
                  onClick={() => {
                    removeLabourElement(row.id)
                  }}
                  buttonColor="bg-red-400 hover:bg-red-300 text-white"
                />
              ) : selectedLabourElements?.length <= 1 ? (
                <Button
                  title="Unassign"
                  buttonColor={` ${'bg-red-400 text-white hover:bg-red-300'}`}
                  loading={deattachLabourElementToSubTIcketLoading}
                  isDisabled={selectedLabourElements?.length <= 1}
                />
              ) : (
                row.subTicketId && (
                  <ConfirmationModal
                    label="Do you want to detach this element from the sub ticket?"
                    displayElement={
                      <ToolTip
                        text="Unassign this element from the sub ticket."
                        left
                      >
                        <Button
                          title="Unassign"
                          buttonColor={` ${'bg-red-400 text-white hover:bg-red-300'}`}
                          loading={deattachLabourElementToSubTIcketLoading}
                          isDisabled={
                            isSubTicketModal &&
                            selectedLabourElements?.length <= 1
                          }
                        />
                      </ToolTip>
                    }
                    confirmLabel="Unassign"
                    onConfirmClick={(onCloseModalHandler) => {
                      deattachLabourElementFromTicket(
                        row.ticketId,
                        row.subTicketId,
                        row.id,
                        onCloseModalHandler
                      )
                    }}
                    danger
                  />
                )
              )
            ) : (
              row.subTicketId && (
                <ConfirmationModal
                  label="Do you want to detach this element from the sub ticket?"
                  displayElement={
                    <ToolTip
                      text="Unassign this element from the sub ticket."
                      left
                    >
                      <Button
                        title="Unassign"
                        buttonColor={` ${'bg-red-400 text-white hover:bg-red-300'}`}
                        loading={deattachLabourElementToSubTIcketLoading}
                        isDisabled={
                          isSubTicketModal &&
                          selectedLabourElements?.length <= 1
                        }
                      />
                    </ToolTip>
                  }
                  confirmLabel="Unassign"
                  onConfirmClick={(onCloseModalHandler) => {
                    deattachLabourElementFromTicket(
                      row.ticketId,
                      row.subTicketId,
                      row.id,
                      onCloseModalHandler
                    )
                  }}
                  danger
                />
              )
            )}

            {!isSubTicketModal && !isSubTicketEdit && !row.subTicketId && (
              <ConfirmationModal
                label="Assign labour element to sub ticket"
                displayElement={
                  <ToolTip
                    text="Assign this element to a sub ticket of this ticket."
                    left
                  >
                    <Button title="Assign" />
                  </ToolTip>
                }
                confirmLabel="Assign"
                onConfirmClick={(onCloseModalHandler) => {
                  selectedSubTicket && selectedSubTicket?.id
                    ? dispatch(
                        attachLabourElementToSubTicket(
                          projectId,
                          ticketId,
                          selectedSubTicket?.id,
                          row.id,
                          () => {
                            onCloseModalHandler()
                            fetchLinItemLabourElementList()
                          }
                        )
                      )
                    : toast.error('Please select a sub ticket!')
                }}
              >
                <div className="flex flex-col gap-20">
                  <div className="flex flex-row gap-10">
                    <div>Labour Element: </div>
                    <div className="text-blue-300">{row.label}</div>
                  </div>

                  <DynamicSearchSelect
                    label="Select Sub Ticket: "
                    actionUrl={`/projects/${projectId}/tickets/${ticketId}/sub-tickets`}
                    placeholder="Search sub ticket"
                    value={selectedSubTicket}
                    remapOptions={(
                      data: Array<
                        Pick<Api.SiteVisitTicketIndividual, 'ticket_details'>
                      >
                    ) => {
                      return data?.map(({ticket_details}) => {
                        return {
                          id: ticket_details?.id,
                          label: ticket_details?.title,
                          value: ticket_details?.title
                        }
                      })
                    }}
                    onChangeValue={(value) => {
                      setSelectedSubTicket(value)
                    }}
                  />
                </div>
              </ConfirmationModal>
            )}
          </div>
        )
      }
    }
  ]

  return (
    <React.Fragment>
      <DataTable
        data={isSubTicketModal ? selectedLabourElements : labourElements}
        columns={columns}
        loading={loading}
        selectableRows={
          isSubTicketModal && (!isSubTicketEdit || isSubTicketEdit)
            ? false
            : true
        }
        onSelectedRowsChange={(selected) => {
          setSelectedLabourElements(selected.selectedRows)
        }}
        selectableRowDisabled={(row) => !!row.subTicketId}
      />
      <AllAttachmentsModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setModalVisible}
        labourDetails={labourDetails}
        labelId="attachments"
        isShowLabourAttachments
        edit
      />
    </React.Fragment>
  )
}
