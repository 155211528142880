import FullCalendar from '@fullcalendar/react'
import {
  Collapsible,
  Dropdown,
  Input,
  SelectField,
  ToolTip,
  toast,
} from 'app/common'
import {Avatar, DynamicSearchSelect} from 'app/components'
import {
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {LuChevronsLeft, LuChevronsRight} from 'react-icons/lu'
import {useDispatch, useSelector} from 'react-redux'
import {
  getActiveUserListOfCompany,
  getCompanyUserGroupList,
  getProjectList,
  getUnAssignedTicketForCalendar,
  RootState,
} from 'redux-src'
import {TUnAssignedTicket, useCalendar} from '../../context'
import {generateRandomAvatarColor} from 'helpers/avatarGenerator.helper'
import {darken, lighten} from '@material-ui/core'
import {FaClock, FaPlusCircle, FaRegTimesCircle, FaSearch} from 'react-icons/fa'
import {Draggable} from '@fullcalendar/interaction'
import {MdCheckBoxOutlineBlank, MdOutlineCheckBox} from 'react-icons/md'
import {IoTicketOutline, IoTicketSharp} from 'react-icons/io5'
import moment from 'moment'

interface CalendarSidenavProps {
  calendarRef: MutableRefObject<FullCalendar>
}
export function CalendarSidenav({calendarRef}: CalendarSidenavProps) {
  const dispatch = useDispatch()

  // * Local variables and memos
  const projectTypeSelectorOptions = useMemo(
    () => [
      {value: 'all', label: 'All', id: 1},
      {value: 'Quote', label: 'Quote', id: 2},
      {value: 'Days Work', label: 'Days Work', id: 3},
    ],
    [],
  )

  /**
   * ? Local states
   * @var {boolean} activeOpen - Whether the side nav is open or not.
   * @var {number} projectLimit - number of roject to be showin in sidenav, could be used for infinite scrolling
   * @var selectedProjectType - selected project type from select menu
   */
  const [activeOpen, setActiveOpen] = useState(true)

  useEffect(() => {
    calendarDispatch({
      type: 'SET_SIDEBAR_OPEN',
      payload: activeOpen,
    })
  }, [activeOpen])

  const [projectLimit, setProjectLimit] = useState(10)
  const [selectedProjectType, setSelectedProjectType] = useState({
    value: 'all',
    label: 'All',
    id: 1,
  })

  const [selectedUserType, setSelectedUserType] = useState({
    id: 1,
    label: 'Select by user',
    value: 'user',
  })

  /**
   * ? Redux variables
   */

  const {
    activeUserList,
    unassignedTicketList,
    assignedTicketList,
  }: RT.CalendarReduxType = useSelector((state: RootState) => state.calendar)

  const {companyUserList, companyUserGroupList}: RT.UserReduxType = useSelector(
    (state: RootState) => state.user,
  )

  // * calendar context
  const {calendarDispatch, calendarState, fetchCalendarEvents} = useCalendar()

  /**
   * ? Initially Fetch required data from API
   */

  // * Get Projects List
  useEffect(() => {
    dispatch(getProjectList({limit: projectLimit, page: 1}))
  }, [dispatch, projectLimit])

  // * Get Projects Users List and projects unassigned ticket list for sidebar
  useEffect(() => {
    const projects = calendarState?.selectedProjectsList
      ?.map((p) => p.id)
      .join(',')

    if (selectedUserType.value === 'user') {
      dispatch(
        getActiveUserListOfCompany({
          projects,
        }),
      )
    } else {
      dispatch(
        getCompanyUserGroupList({
          page: 1,
          limit: 1000,
          projectIds: projects,
        }),
      )
    }

    dispatch(
      getUnAssignedTicketForCalendar({
        type:
          selectedProjectType?.value === 'all'
            ? undefined
            : (selectedProjectType?.label as 'Quote' | 'Days Work'),
        projects: projects,
      }),
    )
    // fetchCalendarEvents()
  }, [
    calendarState?.selectedProjectsList,
    dispatch,
    selectedProjectType?.label,
    selectedProjectType?.value,
    selectedUserType.value,
  ])

  // * Set tickets for calendar
  useEffect(() => {
    let un_temp: TUnAssignedTicket = []

    unassignedTicketList?.forEach((ticketDetail) => {
      ticketDetail?.ticket_list?.forEach((ticket) => {
        un_temp.push({...ticket, project_details: ticketDetail.project_details})
      })
    })
    calendarDispatch({
      type: 'SET_UNASSIGNED_TICKETS',
      payload: un_temp,
    })
  }, [calendarDispatch, unassignedTicketList])

  // * Set resources for calendar using calendar context
  useEffect(() => {
    const resourcesTemp: any[] = []
    if (selectedUserType.value === 'user') {
      activeUserList?.forEach((user, i) => {
        const color = generateRandomAvatarColor(
          `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,
        )

        resourcesTemp?.push({
          id: user?.user_details?.id,
          title: `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,

          eventClassNames: `flex h-full align-center justify-center border-4 rounded-sm overflow-hidden`,
          eventBackgroundColor: lighten(color, 0.7),
          eventBorderColor: color,
          eventTextColor: darken(color, 0.5),
        })
      })
    } else {
      companyUserGroupList?.forEach((group) => {
        group?.group_users?.forEach((user) => {
          const color = generateRandomAvatarColor(
            `${user?.display_name} ${user?.lastname}`,
          )
          resourcesTemp?.push({
            id: user?.id,
            title: `${user?.display_name} ${user?.lastname}`,
            eventClassNames: `flex h-full align-center justify-center border-4 rounded-sm overflow-hidden`,
            eventBackgroundColor: lighten(color, 0.7),
            eventBorderColor: color,
            eventTextColor: darken(color, 0.5),
          })
        })
      })
    }
    calendarDispatch({
      type: 'SET_RESOURCES',
      payload: resourcesTemp,
    })
  }, [
    activeUserList,
    calendarDispatch,
    calendarState.selectedProjectsList,
    companyUserGroupList,
    companyUserList,
    selectedUserType.value,
  ])

  // * Make draggable element for unassigned tickets
  useEffect(() => {
    let unAssignedTicketWrapper = document.getElementById(
      'unassigned-ticket-list',
    )
    new Draggable(unAssignedTicketWrapper, {
      itemSelector: '.ticket-card',
      eventData: function (eventEl) {
        return {
          id: eventEl.getAttribute('data-id'),
          title: eventEl.getAttribute('data-title'),
          create: true,
          startTime: '',
        }
      },
    })

    let assignedTicketWrapper = document.getElementById('assigned-ticket-list')
    new Draggable(assignedTicketWrapper, {
      itemSelector: '.ticket-card',
      eventData: function (eventEl) {
        return {
          id: eventEl.getAttribute('data-id'),
          title: eventEl.getAttribute('data-title'),
          create: true,
          startTime: '',
        }
      },
    })
  }, [])

  // * when project type is changed, disselect all the selected projects
  useEffect(() => {
    calendarDispatch({type: 'RESET_SELECTED_PROJECTS'})
    calendarDispatch({
      type: 'SET_SELECTED_PROJECT_TYPE',
      payload: selectedProjectType,
    })
  }, [calendarDispatch, selectedProjectType])

  const getDynamicSearchField = useCallback(
    () => (
      <DynamicSearchSelect
        cacheOptions={true}
        additional={{
          dependencies: [selectedProjectType],
          page: 1,
        }}
        actionUrl={'/projects/active'}
        params={{
          type:
            selectedProjectType?.value !== 'all'
              ? selectedProjectType?.value
              : undefined,
        }}
        remapOptions={(data: Api.ProjectList['rows']) => {
          return data?.map((project) => {
            return {
              value: project?.project_details?.id,
              label: project?.project_details?.title,
              id: project?.project_details?.id,
            }
          })
        }}
        formatOptionLabel={(data) => {
          return (
            <div className="flex gap-8 items-center justify-between">
              <p>{data.label}</p>
            </div>
          )
        }}
        getOptionValue="value"
        placeholder="Select a project"
        isMulti={true}
        onMultiChangeValue={(newValue) => {
          calendarDispatch({
            payload: newValue,
            type: 'SET_SELECTED_PROJECTS',
          })
        }}
        multiValue={calendarState?.selectedProjectsList}
      />
    ),
    [
      calendarDispatch,
      calendarState?.selectedProjectsList,
      selectedProjectType,
    ],
  )

  // * Code to control user selection when group is selected
  const [selectedGroupId, setSelectedGroupId] = useState<Array<number>>([])
  useEffect(() => {
    calendarDispatch({
      type: 'SET_SELECTED_USER_GROUP_ID',
      payload: selectedGroupId,
    })
  }, [selectedGroupId])

  // * Initially select all the groups
  useEffect(() => {
    let temp: any[] = []
    companyUserGroupList?.forEach((group) => {
      temp.push(group?.group_details?.id)
    })

    setSelectedGroupId(temp)
  }, [companyUserGroupList])

  // * Select or deselet users when group selection changes
  useEffect(() => {
    const resourcesTemp: any[] = []

    companyUserGroupList
      ?.filter((group) => {
        return selectedGroupId?.includes(group.group_details.id)
      })
      ?.forEach((group) => {
        group?.group_users?.forEach((user) => {
          const color = generateRandomAvatarColor(
            `${user?.display_name} ${user?.lastname}`,
          )
          resourcesTemp?.push({
            id: user?.id,
            title: `${user?.display_name} ${user?.lastname}`,
            eventClassNames: `flex h-full align-center justify-center border-4 rounded-sm overflow-hidden`,
            eventBackgroundColor: lighten(color, 0.7),
            eventBorderColor: color,
            eventTextColor: darken(color, 0.5),
          })
        })
      })

    calendarDispatch({
      type: 'SET_RESOURCES',
      payload: resourcesTemp,
    })
  }, [calendarDispatch, companyUserGroupList, selectedGroupId])

  const statusColor = useMemo(
    () => ({
      open: '#e2420d',
      in_progress: '#3452c0',
      feedback: '#ffb500',
      completed: '#76cc00',
    }),
    [],
  )
  // * Fetch assigned ticket based on user selection or based on group selection
  // useEffect(() => {
  //   if (selectedUserType?.value !== 'user' && selectedGroupId?.length > 0) {
  //     fetchCalendarEvents()
  //   } else if (calendarState?.resources?.length > 0) {
  //     fetchCalendarEvents()
  //   }
  // }, [
  //   selectedGroupId,
  //   calendarState?.resources,
  //   selectedUserType?.value,
  //   calendarRef,
  //   calendarState.selectedProjectsList,
  // ])

  useEffect(() => {
    calendarDispatch({
      type: 'SET_SELECTED_USER_TYPE',
      payload: selectedUserType?.value as 'user' | 'group',
    })
  }, [calendarDispatch, selectedUserType?.value])

  const [searchUsers, setSearchUsers] = useState('')
  const users = useMemo(() => {
    if (selectedUserType?.value !== 'user') return []
    if (!searchUsers) return activeUserList
    else {
      return activeUserList?.filter((u) =>
        `${u?.user_details?.display_name} ${u?.user_details?.lastname}`
          .toLowerCase()
          .includes(searchUsers.toLowerCase()),
      )
    }
  }, [selectedUserType?.value, searchUsers, activeUserList])

  const groups = useMemo(() => {
    if (selectedUserType?.value === 'user') return []
    if (!searchUsers) return companyUserGroupList
    else {
      return companyUserGroupList?.filter((g) =>
        g?.group_details?.name
          .toLowerCase()
          .includes(searchUsers.toLowerCase()),
      )
    }
  }, [selectedUserType?.value, searchUsers, companyUserGroupList])

  const selectedDateRange = useMemo(
    () =>
      moment(calendarState?.selectedDate?.start).isSame(
        calendarState?.selectedDate?.end,
        'day',
      )
        ? `${moment(calendarState?.selectedDate.start).format('MMM DD')}`
        : `${moment(calendarState?.selectedDate.start).format(
            'MMM DD',
          )} - ${moment(calendarState?.selectedDate.end).format('MMM DD')}`,
    [calendarState?.selectedDate.end, calendarState?.selectedDate.start],
  )

  // * Select assigned tickets based on clicked events
  const assignedTicketsToShow = useMemo(() => {
    if (calendarState?.clickedResources?.length > 0) {
      return calendarState?.assigned_tickets?.filter((ticket) => {
        return calendarState?.clickedResources?.includes(
          ticket?.user_detail?.id,
        )
      })
    } else {
      return calendarState?.assigned_tickets
    }
  }, [calendarState?.assigned_tickets, calendarState?.clickedResources])

  const assignedTicketUsers = useMemo(() => {
    let temp: {id: number; fullname: string}[] = []
    if (calendarState?.clickedResources?.length > 0) {
      calendarState?.resources?.forEach((resource) => {
        if (calendarState?.clickedResources?.includes(+resource?.id)) {
          temp.push({
            id: +resource?.id,
            fullname: resource?.title,
          })
        }
      })
    } else {
      assignedTicketList?.forEach((ticket) => {
        temp.push({
          id: ticket?.user_details?.id,
          fullname: `${ticket?.user_details?.display_name} ${ticket?.user_details?.lastname}`,
        })
      })
    }

    temp = temp.filter((u, i) => temp.findIndex((t) => t.id === u.id) === i)
    return temp.sort((a, b) => a.fullname.localeCompare(b.fullname))
  }, [
    assignedTicketList,
    calendarState?.clickedResources,
    calendarState?.resources,
  ])

  const [isUserDropdownActive, setIsUSerDropdownActive] = useState(false)

  // * Select all or deselect all feature
  // * Individual users in assigned ticket list

  return (
    <div
      className={`calendar-sidenav ${activeOpen ? 'open' : 'closed'} 
         flex flex-col gap-12 border-r-1 border-gray-200 h-full flex-1
      `}
    >
      <div className="calendar-sidenav-header w-full">
        <div
          className={` text-blue-400 font-bold cursor-pointer hover:text-blue-500 flex items-center ${
            activeOpen ? 'justify-start' : 'justify-center'
          } pt-6 gap-4 w-[inherit]`}
          onClick={() => {
            setActiveOpen((prev) => !prev)
          }}
        >
          {activeOpen ? (
            <>
              <LuChevronsLeft size={16} /> Collapse
            </>
          ) : (
            <>
              <LuChevronsRight size={16} />
            </>
          )}
        </div>
      </div>

      {activeOpen && (
        <div className="calendar-sidenav-body overflow-auto overflow-x-hidden h-screen w-[inherit] px-8">
          <div className="calendar-sidenav-body-projects ">
            {/* Heading Starts */}
            <div className="calender-sidenav-body-projects-heading flex flex-col gap-12">
              <div className="title flex flex-row justify-between items-center ">
                <div className="font-bold">
                  Projects ({selectedProjectType?.label})
                </div>
                <ToolTip
                  className="w-[fit-content]"
                  top
                  text="Total number of employees"
                >
                  <div className="font-bold cursor-[help]">
                    All Employee{' '}
                    <span>({assignedTicketUsers?.length ?? 0})</span>
                  </div>
                </ToolTip>
              </div>
              <div className="selectors flex flex-row justify-between items-start gap-x-10 ">
                <div className="flex flex-col w-1/2 gap-4">
                  {/* <div className="selector-title">Type</div> */}
                  <SelectField
                    options={projectTypeSelectorOptions}
                    value={selectedProjectType}
                    onChangeValue={(newValue) =>
                      setSelectedProjectType(newValue)
                    }
                  />
                </div>
                <div className="flex flex-col  w-1/2 gap-8">
                  <SelectField
                    options={[
                      {id: 1, label: 'Select by user', value: 'user'},
                      {id: 2, label: 'Select by user group', value: 'group'},
                    ]}
                    value={selectedUserType}
                    onChangeValue={(newValue) => {
                      setSelectedUserType(newValue)
                    }}
                  />
                  <div className="font-bold flex flex-row justify-end items-center gap-4">
                    {calendarState?.resources?.length > 5 ? (
                      <>
                        {calendarState?.resources?.map((user, i) => {
                          if (i < 5) {
                            return (
                              <Avatar
                                key={'Avatar' + i}
                                id={'Avatar' + i}
                                showTooltip
                                display_name={user?.title?.split(' ')?.at(0)}
                                lastname={user?.title?.split(' ')?.at(1)}
                              />
                            )
                          } else {
                            return (
                              <Fragment
                                key={'Avatar' + Math.random() * 10000}
                              ></Fragment>
                            )
                          }
                        })}

                        <div
                          className={`w-[25px] h-[25px] flex justify-center items-center rounded-full overflow-hidden  text-white cursor-pointer bg-blue-200`}
                        >
                          +{calendarState?.resources?.length - 5}
                        </div>
                      </>
                    ) : (
                      <>
                        {calendarState?.resources?.map((user, i) => {
                          return (
                            <Avatar
                              key={'AvatarUser' + i + Math.random() * 10000}
                              showTooltip
                              display_name={user?.title?.split(' ')?.at(0)}
                              lastname={user?.title?.split(' ')?.at(1)}
                            />
                          )
                        })}
                      </>
                    )}

                    <Dropdown
                      placement="bottomright"
                      // outDismiss={true}
                      inDismiss={false}
                      triggerElement={
                        <div className="cursor-pointer">
                          <FaPlusCircle
                            color={isUserDropdownActive ? '#ff5e5e' : '#75A7C8'}
                            size={24}
                            className={`${
                              isUserDropdownActive ? 'rotate-45' : ''
                            } transition-all duration-200`}
                          />
                        </div>
                      }
                      onActiveChange={(active) => {
                        setIsUSerDropdownActive(active)
                      }}
                    >
                      <div className="bg-white rounded-sm mt-6 w-[300px] font-normal shadow-lg">
                        <div className="header p-12 pb-0">
                          <Input
                            prepend={<FaSearch />}
                            placeholder={`Search ${
                              selectedUserType?.value === 'user'
                                ? 'Employees'
                                : 'Group'
                            }`}
                            value={searchUsers}
                            onChange={(e) => {
                              e.stopPropagation()
                              setSearchUsers(e.target.value)
                            }}
                          />
                        </div>

                        <div className="flex flex-col gap-y-4 max-h-[400px] overflow-hidden overflow-y-auto p-12 pt-0">
                          {selectedUserType.value === 'user' ? (
                            <>
                              {/* {Select all user } */}
                              <div
                                className="flex justify-between items-center cursor-pointer px-[5px] py-[5px] hover:bg-blue-150"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  if (
                                    calendarState?.resources?.length <
                                    activeUserList?.length
                                  ) {
                                    // * Select all user
                                    activeUserList?.forEach((user) => {
                                      const color = generateRandomAvatarColor(
                                        `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,
                                      )
                                      calendarDispatch({
                                        type: 'ADD_RESOURCE',
                                        payload: {
                                          id: user?.user_details?.id,
                                          title: `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,

                                          eventClassNames: `flex h-full align-center justify-center border-4 rounded-sm overflow-hidden`,
                                          eventBackgroundColor: lighten(
                                            color,
                                            0.7,
                                          ),
                                          eventBorderColor: color,
                                          eventTextColor: darken(color, 0.5),
                                        },
                                      })
                                    })
                                  } else {
                                    const user = activeUserList?.at(0)
                                    const color = generateRandomAvatarColor(
                                      `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,
                                    )
                                    calendarDispatch({
                                      type: 'SET_RESOURCES',
                                      payload: [
                                        {
                                          id: user?.user_details?.id,
                                          title: `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,

                                          eventClassNames: `flex h-full align-center justify-center border-4 rounded-sm overflow-hidden`,
                                          eventBackgroundColor: lighten(
                                            color,
                                            0.7,
                                          ),
                                          eventBorderColor: color,
                                          eventTextColor: darken(color, 0.5),
                                        },
                                      ],
                                    })
                                  }
                                }}
                              >
                                <div className="font-bold">
                                  {calendarState?.resources?.length <
                                  activeUserList?.length
                                    ? 'Select'
                                    : 'Unselect'}{' '}
                                  all user
                                </div>
                                <div>
                                  {calendarState?.resources?.length <
                                  activeUserList?.length ? (
                                    <MdCheckBoxOutlineBlank size={18} />
                                  ) : (
                                    <MdOutlineCheckBox size={18} />
                                  )}
                                </div>
                              </div>
                              {users?.map((user) => {
                                const existingUsers =
                                  calendarState?.resources?.map(
                                    (resource) => resource.id,
                                  )
                                return (
                                  <div
                                    key={
                                      'ExistingUser' + user?.user_details?.id
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      if (
                                        existingUsers?.includes(
                                          user?.user_details?.id,
                                        )
                                      ) {
                                        if (
                                          calendarState?.resources?.length > 1
                                        ) {
                                          calendarDispatch({
                                            type: 'REMOVE_RESOURCE',
                                            payload: {
                                              id: user?.user_details?.id,
                                            },
                                          })
                                        } else {
                                          toast.error(
                                            'Atleast one user has to be selected',
                                          )
                                        }
                                      } else {
                                        const color = generateRandomAvatarColor(
                                          `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,
                                        )
                                        calendarDispatch({
                                          payload: {
                                            id: user?.user_details?.id,
                                            title: `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,

                                            eventClassNames: `flex h-full align-center justify-center border-4 rounded-sm overflow-hidden`,
                                            eventBackgroundColor: lighten(
                                              color,
                                              0.7,
                                            ),
                                            eventBorderColor: color,
                                            eventTextColor: darken(color, 0.5),
                                          },
                                          type: 'ADD_RESOURCE',
                                        })
                                      }
                                    }}
                                    className="flex gap-x-4 p-6 align-center hover:bg-blue-150 cursor-pointer justify-between"
                                  >
                                    <div className="flex gap-x-6 align-center h-full">
                                      {/* <Avatar
                                    display_name={
                                      user?.user_details?.display_name
                                    }
                                    lastname={user?.user_details?.lastname}
                                  /> */}
                                      <span>
                                        {user?.user_details?.display_name}{' '}
                                        {user?.user_details?.lastname}
                                      </span>
                                    </div>

                                    <div className="selector">
                                      {existingUsers?.includes(user?.id) ? (
                                        <MdOutlineCheckBox size={18} />
                                      ) : (
                                        <MdCheckBoxOutlineBlank size={18} />
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          ) : (
                            <>
                              <div
                                className="flex justify-between items-center cursor-pointer  py-[5px] "
                                onClick={(e) => {
                                  e.stopPropagation()
                                  if (
                                    calendarState?.selectedUserGroupId?.length <
                                    companyUserGroupList?.length
                                  ) {
                                    // * Select all user group
                                    let temp = companyUserGroupList?.map(
                                      (group) => group.group_details.id,
                                    )
                                    setSelectedGroupId([...temp])
                                  } else {
                                    setSelectedGroupId([
                                      companyUserGroupList?.at(0)?.group_details
                                        ?.id,
                                    ])
                                  }
                                }}
                              >
                                <div className="font-bold">
                                  {calendarState?.selectedUserGroupId?.length <
                                  companyUserGroupList?.length
                                    ? 'Select'
                                    : 'Unselect'}{' '}
                                  all user group
                                </div>
                                <div>
                                  {calendarState?.selectedUserGroupId?.length <
                                  companyUserGroupList?.length ? (
                                    <MdCheckBoxOutlineBlank size={18} />
                                  ) : (
                                    <MdOutlineCheckBox size={18} />
                                  )}
                                </div>
                              </div>
                              {groups?.map((group) => {
                                return (
                                  <Collapsible
                                    isOpen={false}
                                    key={`CompanyGroup-${group?.group_details?.id}`}
                                    title={
                                      <div>
                                        <span>{group.group_details?.name}</span>
                                      </div>
                                    }
                                    rightElement={
                                      <div
                                        className="cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          if (
                                            !selectedGroupId?.includes(
                                              group?.group_details?.id,
                                            )
                                          ) {
                                            setSelectedGroupId([
                                              ...selectedGroupId,
                                              group?.group_details?.id,
                                            ])
                                          } else {
                                            if (
                                              calendarState?.selectedUserGroupId
                                                ?.length > 1
                                            ) {
                                              setSelectedGroupId(
                                                selectedGroupId?.filter(
                                                  (id) =>
                                                    id !==
                                                    group?.group_details?.id,
                                                ),
                                              )
                                            } else {
                                              toast.error(
                                                'Atleast one group has to be selected',
                                              )
                                            }
                                          }
                                        }}
                                      >
                                        {selectedGroupId?.includes(
                                          group?.group_details?.id,
                                        ) ? (
                                          <MdOutlineCheckBox size={18} />
                                        ) : (
                                          <MdCheckBoxOutlineBlank size={18} />
                                        )}
                                      </div>
                                    }
                                  >
                                    {group?.group_users?.map((user) => {
                                      const existingUsers =
                                        calendarState?.resources?.map(
                                          (resource) => resource.id,
                                        )
                                      return (
                                        <div
                                          key={'ExistingUser' + user?.id}
                                          className="flex gap-x-4 p-6 align-center hover:bg-blue-150 cursor-pointer justify-between"
                                        >
                                          <div className="flex gap-x-6 align-center h-full">
                                            <span>
                                              {user?.display_name}{' '}
                                              {user?.lastname}
                                            </span>
                                          </div>

                                          {/* <div className="selector">
                                            {existingUsers?.includes(
                                              user?.id,
                                            ) ? (
                                              <MdOutlineCheckBox size={18} />
                                            ) : (
                                              <MdCheckBoxOutlineBlank
                                                size={18}
                                              />
                                            )}
                                          </div> */}
                                        </div>
                                      )
                                    })}
                                  </Collapsible>
                                )
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            {/* Heading Ends */}

            {/* Project Search Starts*/}
            <div className="calender-sidenav-body-projects-body mt-12 grid gap-4">
              <div className="font-bold">Select a project</div>
              {getDynamicSearchField()}
            </div>
          </div>

          {/* Calendar sidebar tickets section */}
          <div className="calendar-sidenav-body-tickets py-18 grid gap-y-8">
            <div className="unassigned">
              <Collapsible
                isOpen={calendarState?.unassigned_tickets?.length > 0}
                title={
                  <div className="ticket-title font-bold py-6">
                    Unassigned Tickets (
                    {calendarState?.unassigned_tickets?.length ?? 0})
                  </div>
                }
              >
                <div
                  id="unassigned-ticket-list"
                  className="unassigned-tickets-list flex flex-col gap-6 py-6"
                >
                  {unassignedTicketList?.map((unTicket, i) => (
                    <Fragment
                      key={`UnassignedTicketList - ${
                        unTicket?.project_details?.id + i
                      }`}
                    >
                      <Collapsible
                        isOpen={false}
                        title={
                          <div className="font-bold text-blue-300 cursor-pointer">
                            {unTicket?.project_details?.project_prefix}-
                            {unTicket?.project_details?.project_number}
                            {' | '}
                            {unTicket?.project_details?.title}
                          </div>
                        }
                      >
                        <>
                          {calendarState?.unassigned_tickets
                            ?.filter(
                              (ticket) =>
                                +ticket.project_details.id ===
                                +unTicket?.project_details?.id,
                            )
                            .map((ticket, i) => {
                              return (
                                <div
                                  key={`UnassignedTicket-${
                                    ticket?.ticket_details?.id +
                                    Math.random() * 10000
                                  }`}
                                  // draggable
                                  data-id={ticket?.ticket_details?.id}
                                  data-title={ticket?.ticket_details?.title}
                                  className="ticket-card select-none cursor-[move]  flex items-center gap-12 bg-white  p-6 px-10 shadow-sm rounded-sm h-[55px]"
                                >
                                  <div className="icon text-md">
                                    <IoTicketOutline color="gray" size={18} />
                                  </div>
                                  <div className="details grid gap-y-4">
                                    <div className="project-name text-blue-300 font-bold flex items-center gap-4">
                                      <div className="h-[20px] w-[20px] flex items-center justify-center bg-red-400 rounded-md text-white">
                                        {ticket?.ticket_details
                                          ?.ticket_number ??
                                          ticket?.ticket_details?.id}
                                      </div>{' '}
                                      {ticket?.ticket_details?.title}
                                    </div>
                                    {/* <div className="description font-medium  text-sm">
                                {getStatusChip(
                                  ticket?.ticket_details?.status as any,
                                )}
                              </div> */}
                                  </div>
                                </div>
                              )
                            })}
                        </>
                      </Collapsible>
                    </Fragment>
                  ))}
                </div>
              </Collapsible>
            </div>

            <div className="assigned">
              <Collapsible
                containerClassName="w-full"
                isOpen={calendarState?.assigned_tickets?.length > 0}
                title={
                  <div className="flex justify-between w-full items-center">
                    <div className="font-bold py-6 ">
                      Assigned Tickets (
                      {assignedTicketUsers?.length > 0
                        ? assignedTicketsToShow?.length ?? 0
                        : 0}
                      )
                    </div>
                    {!!calendarState?.selectedDate?.start && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                          calendarDispatch({
                            type: 'SET_SELECTED_DATE',
                            payload: {
                              end: undefined,
                              start: undefined,
                            },
                          })
                        }}
                        title="Selected date range"
                        className="text-xs bg-red-300 text-white p-4 rounded-md flex items-center gap-6"
                      >
                        <div>{selectedDateRange}</div>
                        <FaRegTimesCircle />
                      </div>
                    )}
                  </div>
                }
              >
                <div
                  id="assigned-ticket-list"
                  className="assigned-tickets-list flex flex-col gap-6 "
                >
                  {assignedTicketUsers?.map((user, i) => (
                    <Fragment
                      key={`AssignedTicketList - ${
                        user?.id + i + Math.random()
                      }`}
                    >
                      <Collapsible
                        isOpen={
                          // calendarState?.clickedResources?.length > 0
                          //   ? calendarState?.clickedResources?.includes(
                          //       user?.id,
                          //     )
                          //   : false
                          false
                        }
                        title={
                          <div className="font-bold text-blue-300 cursor-pointer capitalize">
                            {user?.fullname} (
                            {assignedTicketsToShow?.filter(
                              (ticket) => ticket.user_detail.id === user?.id,
                            )?.length ?? 0}
                            )
                          </div>
                        }
                      >
                        {assignedTicketsToShow
                          ?.filter(
                            (ticket) => ticket.user_detail.id === user?.id,
                          )
                          ?.map((ticket, i) => (
                            <div
                              key={`AssignedTicket-${
                                ticket?.ticket_details?.id +
                                Math.random() * 10000
                              }`}
                              // draggable
                              data-id={ticket?.ticket_details?.id}
                              data-title={ticket?.ticket_details?.title}
                              className="ticket-card select-none cursor-move flex  bg-white  p-6 px-10 rounded-sm justify-between items-center h-[55px] w-full hover:drop-shadow-md transition-all ease-in-out duration-200 hover:-translate-y-[2px] mb-4 last-of-type:mb-0"
                              onClick={() => {
                                const event = ticket?.event_details
                                if (!!event?.scheduled_from) {
                                  const calendarApi =
                                    calendarRef?.current?.getApi()
                                  calendarApi?.gotoDate(event?.scheduled_from)
                                  calendarDispatch({
                                    type: 'SET_SELECTED_DATE',
                                    payload: {
                                      end: moment(calendarApi?.view?.activeEnd)
                                        .subtract(1, 'day')
                                        .toDate(),
                                      start: new Date(event?.scheduled_from),
                                    },
                                  })
                                }
                              }}
                              onDrag={(e) => {
                                e.stopPropagation()
                              }}
                            >
                              <div className="flex items-center gap-12 ">
                                <div className="icon text-md">
                                  <IoTicketSharp color="gray" size={18} />
                                </div>
                                <div className="details grid gap-y-4">
                                  <div className="project-name text-blue-300 font-bold flex items-center gap-4">
                                    <div
                                      className={`h-[20px] min-w-[20px] w-[20px] flex items-center justify-center bg-[${
                                        statusColor[
                                          (ticket?.ticket_details?.status
                                            ?.split(' ')
                                            .join('_') ??
                                            'open') as keyof typeof statusColor
                                        ]
                                      }] rounded-md text-white`}
                                    >
                                      {ticket?.ticket_details?.ticket_number ??
                                        ticket?.ticket_details?.id}
                                    </div>{' '}
                                    <div className="ticket-title text-ellipsis line-clamp-1">
                                      {ticket?.ticket_details?.title}
                                    </div>
                                  </div>{' '}
                                  <div className="description font-medium  text-xs text-gray-300">
                                    {!!ticket?.event_details
                                      ?.scheduled_from && (
                                      <div className="flex items-center gap-6">
                                        <FaClock />
                                        {moment(
                                          ticket?.event_details?.scheduled_from,
                                        ).format(' hh:mm a, Do [of] MMM')}{' '}
                                        -{' '}
                                        {moment(
                                          ticket?.event_details?.scheduled_to,
                                        ).format('hh:mm a, Do [of] MMM')}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <Avatar
                                display_name={ticket?.user_detail?.display_name}
                                lastname={ticket?.user_detail?.lastname}
                                showTooltip
                                tooltipText={`Assigned to ${ticket?.user_detail?.display_name}`}
                              />
                            </div>
                          ))}
                      </Collapsible>
                    </Fragment>
                  ))}
                </div>
              </Collapsible>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
