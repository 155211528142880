export const sortArrayBasedOnXY = <T extends RT.FormElementType>(
  array: Array<T>
) => {
  return array?.sort((a, b) => {
    if (a.layout.y === b.layout.y) {
      return a.layout.x - b.layout.x
    } else return a.layout.y - b.layout.y
  })
}

export const sortArrayBasedOnXYBuilder = <
  T extends Api.IndividualFormById['fields']
>(
  array: T
) => {
  return array?.sort((a, b) => {
    if (a.properties?.layout.y === b.properties?.layout.y) {
      return a.properties?.layout.x - b.properties?.layout.x
    } else return a.properties?.layout.y - b.properties?.layout.y
  })
}

export const groupArrayBasedOnY = <T extends Array<RT.FormElementType>>(
  array?: T
) => {
  if (array === undefined) {
    return undefined
  }

  const formsForPreviewGroupedByY: RT.FormBuilderReduxType['formsForDesktopPreview'] =
    []

  array?.forEach((item: RT.FormElementType) => {
    const y = item?.layout?.y

    if (!!formsForPreviewGroupedByY && !!!formsForPreviewGroupedByY[y]) {
      formsForPreviewGroupedByY[y] = []
    }

    formsForPreviewGroupedByY[y]?.push(item)
  })

  return formsForPreviewGroupedByY
}
