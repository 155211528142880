import {useContext, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {FloorMap, PlanPositionMap} from '../../../planViewer/components'
import {getTicketLogListAction} from 'redux-src'
import {useNavigation} from 'react-auth-navigation'
import {TicketContext} from '../../ticket.page'

interface SelectFloorPlanPositionProps {
  floorPlanId: number
  containerHeight?: number | string
  disableZoom?: boolean
  ticketStatus: 'open' | 'in progress' | 'feedback' | 'completed'
  edit?: boolean
  planCallback?: () => void
}

export const SelectFloorPlan = ({
  floorPlanId,
  containerHeight,
  disableZoom,
  ticketStatus,
  edit,
  planCallback
}: SelectFloorPlanPositionProps) => {
  const {projectPlanList}: RT.ProjectPlanReduxType = useSelector(
    (state: any) => state.projectPlan
  )

  const {projectTicketId} = useContext(TicketContext)

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const selectedPlan = useMemo(() => {
    const floorPlanDetails = projectPlanList?.find(
      (floorPlan) => floorPlan?.project_plan_details?.id === floorPlanId
    )

    return floorPlanDetails
  }, [floorPlanId, projectPlanList])

  return (
    <div className="flex flex-col">
      <div className="mb-20">
        {disableZoom ? (
          <FloorMap
            key={selectedPlan?.project_plan_details?.name}
            plan={selectedPlan}
            mapContainerHeight={containerHeight}
            disableZoom={disableZoom}
            ticketStatus={ticketStatus}
          />
        ) : (
          <PlanPositionMap
            mapContainerHeight={containerHeight}
            plan={selectedPlan}
            ticketStatus={ticketStatus}
            edit={edit}
            planCallback={() => {
              planCallback()
              dispatch(
                getTicketLogListAction(1, 10, projectId, projectTicketId, 'asc')
              )
            }}
          />
        )}
      </div>
    </div>
  )
}
