import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  LabelList,
  Label,
} from 'recharts'

export function HorizontalSimpleBarchart({
  data,
  height = 240,
  showLegend = true,
  showName = true,
}: {
  data: Api.BasicProjectProgressDetails
  height?: number
  showLegend?: boolean
  showName?: boolean
}) {
  const barData = [
    {
      name: 'Project Duration',
      hours: data?.projectDuration?.totalHours,
      days: data?.projectDuration?.totalDays,
      weeks: data?.projectDuration?.totalWeeks,
      fill: '#3452c0',
      className: 'blue-bar',
    },
    {
      name: 'Estimated Duration',
      hours: data?.estimatedProjectDuration?.hours,
      days: data?.estimatedProjectDuration?.days,
      weeks: data?.estimatedProjectDuration?.weeks,
      fill: '#e2420d',
      className: 'red-bar',
    },

    {
      name: 'Completed Duration',
      hours: data?.completedDuration?.hours,
      days: data?.completedDuration?.days,
      weeks: data?.completedDuration?.weeks,
      fill: '#76cc00',
      className: 'green-bar',
    },
  ]

  const CustomizedLabel = (props: any) => {
    const {content, value, fill, ...rest} = props
    return (
      <Label
        {...rest}
        value={Number(value).toFixed(2)}
        fontSize="12"
        fontWeight="Bold"
        position={props.viewBox?.width <= 50 ? 'right' : 'inside'}
        fill={props.viewBox?.width <= 50 ? '#000' : '#fff'}
      />
    )
  }

  // Custom tooltip to show on barchart

  const CustomTooltip = <T extends string | number, E extends string | number>({
    active,
    payload,
  }: TooltipProps<T, E>) => {
    const hoveredBar = payload?.at(0)

    if (active && payload && payload.length) {
      return (
        <div
          className={`basic-project-details-tooltip ${hoveredBar?.payload?.className}`}
        >
          <div className="label">{hoveredBar?.payload?.name}</div>

          <div className="separator" />

          <div className="description">
            <div className="desc">
              {' '}
              <div className="desc-title">In Hours: </div>
              <div className="desc-value">{hoveredBar?.payload?.hours}</div>
            </div>{' '}
            <div className="desc">
              {' '}
              <div className="desc-title">In Days: </div>
              <div className="desc-value">{hoveredBar?.payload?.days}</div>
            </div>
            <div className="desc">
              {' '}
              <div className="desc-title">In Weeks: </div>
              <div className="desc-value">{hoveredBar?.payload?.weeks}</div>
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart layout="vertical" data={barData} barCategoryGap={'20%'}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" axisLine={false} />
        <XAxis
          type="category"
          dataKey={'name'}
          label={{value: 'Weeks', position: 'insideBottom'}}
        />

        <YAxis type="category" dataKey="name" hide={!showName} />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          name="Weeks"
          dataKey="weeks"
          barSize={30}
          // background={{fill: '#efefef'}}
        >
          <LabelList dataKey="weeks" content={<CustomizedLabel />} />
        </Bar>

        {<Legend iconType="line" align="center" />}
      </BarChart>
    </ResponsiveContainer>
  )
}
