import {useState} from 'react'
import {HiSearch} from 'react-icons/hi'

import {DynamicSearchSelect} from 'app/components'
import {Button} from 'app/common'
import {AddCustomItem} from '../addCustomItem'

export type SelectedItemType = {
  id: number
  value: string
  label: string
  timeMins: number
  numberPerHr: number
}

export const AddLineItem = ({
  onAddLineItem,
  sectionDetail,
  fetchInvoiceItem,
}: {
  onAddLineItem: (a: any, b?: () => void) => void
  sectionDetail?: Api.WorkerInvoiceDetailsById['worker_invoice_item_list'][0]
  fetchInvoiceItem?: () => void
}) => {
  const [selectedItemById, setSelectedItemById] = useState<SelectedItemType>()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleSelectItem = (item: SelectedItemType) => {
    setSelectedItemById(item)
    setOpenModal(true)
    // const {label, totalCost, materialCost, id} = item
    // const body = {
    // title: label,
    // cost: totalCost,
    // price: materialCost,
    // quantity: 1,
    // type: 1,
    // id: id
    // }
    // onAddLineItem(body)
  }

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  return (
    <div className="flex flex-nowrap flex-row">
      <div className="flex ">
        <div className="p-6 border-1 border-r-0 rounded-l-sm rounded-r-none border-gray-200 bg-[rgb(245,245,245)]">
          <HiSearch size={18} />
        </div>

        <DynamicSearchSelect
          height="31px"
          containerClassname="w-[200px]"
          placeholder={selectedItemById?.label ?? 'Add Labour Element'}
          actionUrl={`/labour-elements`}
          value={selectedItemById}
          remapOptions={(data: Api.LabourElementDetails[]) => {
            return data?.map((item) => {
              return {
                id: item?.labour_element_details?.id,
                label: item?.labour_element_details?.name,
                value: item?.labour_element_details?.name,
                timeMins: item?.labour_element_details?.time_mins,
                numberPerHr: item?.labour_element_details?.number_per_hr,
              }
            })
          }}
          formatOptionLabel={(item: {
            id: number
            label: string
            value: string
            timeMins: number
            numberPerHr: number
          }) => (
            <div key={item.id} className="cursor-pointer px-10">
              <div className="flex flex-col gap-6">
                <div className="flex gap-4">
                  <strong>{item.label}</strong>
                </div>
                <div className="flex gap-4 flex-wrap flex-col">
                  <div className="flex gap-4 flex-wrap">
                    Time (mins): <strong>{item.timeMins}</strong>
                  </div>
                </div>
              </div>
            </div>
          )}
          onChangeValue={handleSelectItem}
          cacheOptions
        />
      </div>

      <div className="mx-6">
        <Button
          title="Add Manual Labour Entry"
          onClick={handleModalOpen}
          type={'button'}
        />
      </div>

      <AddCustomItem
        {...{
          openModal,
          setOpenModal,
          fetchInvoiceItem,
          sectionDetail,
          selectedLabour: selectedItemById,
        }}
      />
    </div>
  )
}
