import React, {useCallback, useContext, useEffect, useState} from 'react'
import {saveAs} from 'file-saver'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import classNames from 'classnames'
import {MdArrowDropDown} from 'react-icons/md'
import {BsCloudDownload} from 'react-icons/bs'
import {HiPlusSm} from 'react-icons/hi'
import {IoMdEye} from 'react-icons/io'
import {CiEdit} from 'react-icons/ci'

import {Input, Textarea, Button, Dropdown, DefaultButton} from 'app/common'

import {
  approveAutomaticInvoiceAction,
  changeInvoiceStatusToDispute,
  changeInvoiceStatusToPaid,
  changeInvoiceStatusToUnDispute,
  changeInvoiceStatusToVoid,
  createNewInvoiceItemAction,
  deleteInvoiceItemAction,
  downloadPdfAction,
  getInvoiceById,
  updateNewInvoiceAction,
} from 'redux-src'
import {generateInvoiceHTMLString} from 'helpers'

import {QUOTE_TABLE_HEADINGS} from './constants'
import {LetterHead, SectionCard} from './components'
import {InvoicingContext} from '../../invoicing.page'
import {SelectTicketModal} from '../../components'

export function InvoiceEditorPage() {
  const {params} = useNavigation()

  const {projectId, invoiceId: invoiceIdParam} = params as any

  const {
    selectInvoice: {activeInvoice},
  } = useContext(InvoicingContext)

  const [invoiceId, setInvoiceId] = useState(0)

  useEffect(() => {
    setInvoiceId(activeInvoice?.id)
  }, [activeInvoice?.id])

  const [basicDetails, {onChange: onChangeHandler, setValue}] = useFormInput({
    title: '',
    description: '',
    dueDate: '',
    footerNotes: '',
    remarks: undefined,
  })

  const dispatch = useDispatch()
  const {toast, currencySymbol} = useAuth()
  const {defaultInvoiceSettings} = useSelector(
    (state: any) => state.invoiceSetting,
  )

  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )
  const {invoiceDetail}: RT.ProjectInvoicingReduxType = useSelector(
    (state: any) => state.invoices,
  )

  const {invoice_items, invoice_details} = !!invoiceDetail && invoiceDetail

  const [addCostModal, setAddCostModal] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)

  const [initialSectionName, setInitialSectionName] = useState<string>()

  const initialSubmitSection = (e: React.FormEvent) => {
    e.preventDefault()

    if (initialSectionName.length > 0) {
      const initialSectionNameClone = initialSectionName

      const body = {title: initialSectionNameClone, description: 'Description'}

      dispatch(
        createNewInvoiceItemAction(projectId, invoiceIdParam, body, () => {
          dispatch(getInvoiceById(projectId, invoiceIdParam))
        }),
      )
      setInitialSectionName('')
    }
  }

  const onRemoveHandler = (id: any) => {
    dispatch(
      deleteInvoiceItemAction(
        invoiceIdParam,
        id,
        toast,
        () => {
          dispatch(getInvoiceById(projectId, invoiceIdParam))
        },
        () => {},
      ),
    )
  }

  const onApproveHandler = () => {
    invoiceDetail?.invoice_details?.type === 'draft' &&
      dispatch(
        approveAutomaticInvoiceAction(projectId, invoiceIdParam, () => {
          fetchInvoiceDetails()
        }),
      )
  }

  const onPaidInvoiceHandler = () => {
    invoiceDetail?.invoice_details?.type === 'approved' &&
      dispatch(
        changeInvoiceStatusToPaid(projectId, invoiceIdParam, () => {
          fetchInvoiceDetails()
        }),
      )
  }

  const onChangeInvoiceStatusToDispute = () => {
    invoiceDetail?.invoice_details?.type === 'approved' &&
      dispatch(
        changeInvoiceStatusToDispute(projectId, invoiceIdParam, () => {
          fetchInvoiceDetails()
        }),
      )
  }

  const onChangeInvoiceStatusToUnDispute = () => {
    invoiceDetail?.invoice_details?.type === 'approved' &&
      dispatch(
        changeInvoiceStatusToUnDispute(projectId, invoiceIdParam, () => {
          fetchInvoiceDetails()
        }),
      )
  }

  const onChangeInvoiceStatusToVoid = () => {
    invoiceDetail?.invoice_details?.type === 'approved' &&
      dispatch(
        changeInvoiceStatusToVoid(projectId, invoiceIdParam, () => {
          fetchInvoiceDetails()
        }),
      )
  }

  const onUpdateInvoiceHandler = () => {
    if (
      invoiceDetail?.invoice_details?.title !== basicDetails?.title ||
      invoiceDetail?.invoice_details?.description !==
        basicDetails?.description ||
      invoiceDetail?.invoice_details?.footer_notes !== basicDetails?.footerNotes
    ) {
      const body = {
        title: basicDetails?.title,
        description:
          basicDetails?.description?.length === 0 || !!basicDetails?.description
            ? undefined
            : basicDetails?.description,
        dueDate: basicDetails?.dueDate,
        footerNotes: basicDetails?.footerNotes,
      }

      dispatch(
        updateNewInvoiceAction(projectId, invoiceIdParam, body, () => {
          fetchInvoiceDetails()
        }),
      )
    }
  }

  const fetchInvoiceDetails = useCallback(() => {
    dispatch(getInvoiceById(projectId, invoiceIdParam))
  }, [dispatch, invoiceIdParam, projectId])

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )
  const {downloadPdfLoading} = useSelector((state: any) => state.file)

  const generateInvoicePDF = () => {
    dispatch(
      downloadPdfAction(
        {
          html: generateInvoiceHTMLString(
            companyDetails,
            projectDetail,
            invoiceDetail,
            currencySymbol,
          ).toString(),
        },
        (data: any) => {
          saveAs(data, invoiceDetail?.invoice_details?.invoice_number)
        },
      ),
    )
  }

  useEffect(() => {
    fetchInvoiceDetails()
  }, [fetchInvoiceDetails])

  useEffect(() => {
    invoiceDetail?.invoice_details?.status === 'paid' &&
      invoiceDetail?.invoice_details?.type === 'approved' &&
      setIsPreview(true)
  }, [invoiceDetail])

  useEffect(() => {
    if (invoiceDetail) {
      setValue('title', invoiceDetail?.invoice_details?.title)
      setValue('description', invoiceDetail?.invoice_details?.description)
      setValue('dueDate', invoiceDetail?.invoice_details?.due_date)
      setValue('footerNotes', invoiceDetail?.invoice_details?.footer_notes)
      setValue('remarks', invoiceDetail?.invoice_details?.remarks)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetail])

  return (
    <React.Fragment>
      <div className="flex flex-row justify-between bg-blue-150 p-10 ">
        <div
          className="inline-flex rounded-md shadow-sm justify-between w-full"
          role="group"
        >
          <div className="mr-10 inline-flex gap-16">
            {invoiceDetail && invoice_details?.type === 'draft' && (
              <Button
                title="Add Costs"
                className="px-12 py-[4px]"
                onClick={() => setAddCostModal(true)}
              />
            )}

            <SelectTicketModal
              key={'addcost modal'}
              openModal={addCostModal}
              setOpenModal={setAddCostModal}
              isDetailsPage
            />
          </div>
          <div className="ml-10 inline-flex gap-10 justify-center items-center">
            <div className="flex flex-row justify-center items-center h-[23px]">
              {invoiceDetail?.invoice_details?.status !== 'paid' &&
              invoiceDetail?.invoice_details?.type !== 'approved' ? (
                <button
                  type="button"
                  className={classNames(
                    'flex flex-row items-center justify-between gap-6 border-1 py-2 px-6 text-[10px] font-medium rounded-l-sm',
                    isPreview
                      ? ' text-white z-10 border-blue-400 bg-blue-400'
                      : 'text-gray-400 border-white bg-white',
                  )}
                  onClick={() => {
                    setIsPreview(true)
                  }}
                >
                  <IoMdEye size={14} />
                  Preview
                </button>
              ) : (
                <DefaultButton
                  title="Download Pdf"
                  leftIcon={<BsCloudDownload />}
                  className="w-[max-content] hover:bg-gray-200 bg-white text-black h-[30px]"
                  onClick={generateInvoicePDF}
                  loading={downloadPdfLoading}
                />
              )}

              {invoiceDetail?.invoice_details?.type === 'draft' && (
                <button
                  type="button"
                  className={classNames(
                    'flex flex-row items-center justify-between gap-6 border-1 py-2 px-6 text-[10px] font-medium rounded-r-sm',
                    !isPreview
                      ? ' text-white z-10 border-blue-400 bg-blue-400'
                      : 'text-gray-400 border-white bg-white',
                  )}
                  onClick={() => {
                    setIsPreview(false)
                    // console.log('isPreview: ', isPreview)
                  }}
                >
                  <CiEdit size={14} />
                  Edit
                </button>
              )}
            </div>
            {invoiceDetail?.invoice_details?.status !== 'paid' && (
              <div className="relative flex flex-row justify-center items-center">
                {invoice_details?.type === 'draft' ? (
                  <div className="bg-green-500 pr-0 inline-flex rounded-sm text-sm cursor-pointer text-white">
                    <div
                      className="inline-flex gap-10 hover:bg-green-600 px-12 py-[2px] rounded-sm"
                      onClick={onApproveHandler}
                    >
                      Approve
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-8">
                    {getChipData(invoice_details?.status)}
                    {invoiceDetail?.invoice_details?.status !== 'void' && (
                      <div className="bg-green-500 pr-0 inline-flex rounded-sm text-sm cursor-pointer text-white">
                        <div className="inline-flex gap-10 hover:bg-green-600 px-12 py-[2px] rounded-sm">
                          Approved
                        </div>
                        <div className="border-l-2 border-white" />
                        <div>
                          <Dropdown
                            placement="bottomright"
                            triggerToggle
                            triggerElement={
                              <div className="transition-all duration-100 ease-in-out flex justify-center items-center px-8 h-full py-[4px] hover:bg-green-600 rounded-sm">
                                <MdArrowDropDown size={14} />
                              </div>
                            }
                          >
                            <div className="bg-white shadow-lg rounded-sm overflow-hidden text-black mt-10">
                              {invoiceDetail?.invoice_details?.status !==
                                'paid' &&
                                invoiceDetail?.invoice_details?.status !==
                                  'dispute' &&
                                invoiceDetail?.invoice_details?.status ===
                                  'unpaid' && (
                                  <div
                                    className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                                    onClick={onPaidInvoiceHandler}
                                  >
                                    Paid
                                  </div>
                                )}
                              {invoiceDetail?.invoice_details?.status !==
                                'paid' && (
                                <>
                                  {invoiceDetail?.invoice_details?.status !==
                                  'disputed' ? (
                                    <div
                                      className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                                      onClick={onChangeInvoiceStatusToDispute}
                                    >
                                      Dispute
                                    </div>
                                  ) : (
                                    <div
                                      className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                                      onClick={onChangeInvoiceStatusToUnDispute}
                                    >
                                      Undispute
                                    </div>
                                  )}
                                  {invoiceDetail?.invoice_details?.status !==
                                    'disputed' && (
                                    <div
                                      className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                                      onClick={onChangeInvoiceStatusToVoid}
                                    >
                                      Void
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* letterhead */}
      <LetterHead
        previewMode={isPreview}
        companyDetails={companyDetails}
        invoiceDetail={invoiceDetail}
        defaultInvoiceSettings={defaultInvoiceSettings}
      />
      {/* invoice details */}
      <div className="my-16">
        <div className="inline-flex gap-16 ">
          {basicDetails ? (
            <Input
              className="m-0 mb-0 inline-flex font-bold text-lg w-auto"
              containerClass="m-0"
              name="title"
              size="sm"
              value={basicDetails?.title}
              onChange={onChangeHandler}
              placeholder="Title"
              onBlur={onUpdateInvoiceHandler}
            />
          ) : (
            <div className="inline-flex font-bold text-lg">
              {`INV-${invoice_details?.id}`}
            </div>
          )}
          <div className="border-l-[3px] border-black" />
          <div
            className={`flex flex-row font-bold items-center justify-start text-lg ${
              invoice_details?.status === 'draft' ? 'text-gray-300' : ''
            }`}
          >
            {invoice_details?.status !== 'draft'
              ? invoice_details?.invoice_number
              : 'INV-DRAFT'}
          </div>
        </div>
        {/* invoice description */}
        <div className="text-sm pb-16 border-b-1 border-b-gray-200">
          {isPreview ? (
            <div
              dangerouslySetInnerHTML={{
                __html: basicDetails?.description?.replace('\n', '<br/>'),
              }}
              className="mt-16 text-gray-500"
            ></div>
          ) : (
            <Textarea
              className="text-sm mt-16"
              name="description"
              placeholder={`Description`}
              size="sm"
              value={basicDetails?.description}
              onChange={onChangeHandler}
              onBlur={onUpdateInvoiceHandler}
            />
          )}
        </div>
      </div>
      {/* HEADINGS */}
      <div className="w-full flex">
        {QUOTE_TABLE_HEADINGS.map(({title, width, hidden}, index) => (
          <span
            key={index}
            className={[
              `text-sm font-bold block py-16 first:pl-0 first:text-left text-center last:pr-0 last:${
                isPreview || invoiceDetail?.invoice_details?.type === 'approved'
                  ? 'text-right'
                  : 'text-center'
              }`,
              hidden ? 'text-gray-300' : '',
            ].join(' ')}
            style={{width}}
          >
            {title}
          </span>
        ))}
      </div>
      {/* preview section form */}
      {isPreview || invoiceDetail?.invoice_details?.type !== 'draft' ? (
        <div>
          {invoice_items?.length &&
            invoice_items?.map((item: Api.InvoiceItemDetails) => {
              return (
                <div className="mt-10" key={item?.invoice_item_details?.id}>
                  <h1>
                    <strong>{item?.invoice_item_details?.title}</strong>
                  </h1>
                  <table
                    style={{
                      width: '100%',
                    }}
                  >
                    <tbody>
                      {item?.invoice_line_items?.length > 0 &&
                        item.invoice_line_items.map((item: any, i: number) => {
                          // const lineItem = Object.keys(item)
                          return (
                            <tr key={i}>
                              {[
                                'title',
                                'quantity',
                                'price',
                                'tax_percent',
                                'discount_percent',
                                'total_cost',
                              ].map((key, index) => {
                                return (
                                  <td
                                    key={index}
                                    className="first:text-left text-center last:text-right last:pr-[10px]"
                                    style={{
                                      width: QUOTE_TABLE_HEADINGS.find(
                                        (heading) => heading.key === key,
                                      )?.width,
                                    }}
                                  >
                                    {item[key]}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              )
            })}
        </div>
      ) : (
        <>
          {/* NEW SECTION FORM */}
          <div className="mb-16 w-[50%]">
            <form onSubmit={initialSubmitSection}>
              <Input
                prepend={<HiPlusSm size={24} />}
                placeholder="Type section name and press enter"
                name="sectionName"
                onChange={(e: any) => setInitialSectionName(e.target.value)}
                value={initialSectionName}
                className="rounded-r-sm "
              />
            </form>
          </div>
          {invoice_items?.length > 0 ? (
            <React.Fragment>
              {invoice_items?.map(
                (item: Api.InvoiceItemDetails, index: any) => {
                  return (
                    <div
                      className="px-8 py-8 mb-16 bg-blue-100 rounded-sm"
                      key={item?.invoice_item_details?.id}
                    >
                      <SectionCard
                        invoiceDetail={invoiceDetail}
                        invoiceId={invoiceId}
                        sectionIndex={index}
                        sectionDetail={item}
                        onRemoveSection={onRemoveHandler}
                        sectionList={invoice_details}
                        fetchInvoiceItem={() => fetchInvoiceDetails()}
                      />
                    </div>
                  )
                },
              )}
            </React.Fragment>
          ) : null}
        </>
      )}
      {/* totals */}
      <div className="border-t-1 border-t-gray-200 mt-8 pt-8 flex flex-row justify-end">
        <div>
          <div className="flex flex-row justify-end gap-10">
            <div className="font-bold text-right">SubTotal</div>
            <div className="min-w-[80px] max-w-[90px] font-bold text-right">
              {currencySymbol}
              {Number(invoice_details?.taxable_cost)?.toFixed(2) ?? 0.0}
            </div>
          </div>
          <div className="flex flex-row justify-end gap-10">
            <div className="font-bold text-right">Discount</div>
            <div className="min-w-[80px] max-w-[90px] font-bold text-right">
              -{currencySymbol}
              {Number(invoice_details?.discount).toFixed(2) ?? 0.0}
            </div>
          </div>
          {invoice_details?.discount ? (
            <div className="flex flex-row justify-end gap-10">
              <div className="font-bold text-right">Discounted Sub Total</div>
              <div className="min-w-[80px] max-w-[90px] font-bold text-right">
                {currencySymbol}
                {Number(invoice_details?.discounted_cost)?.toFixed(2) ?? 0.0}
              </div>
            </div>
          ) : null}

          <div className="flex flex-row justify-end gap-10">
            <div className="font-bold text-right">Tax Amount</div>
            <div className="min-w-[80px] max-w-[90px] font-bold text-right">
              {currencySymbol}
              {Number(invoice_details?.tax).toFixed(2) ?? 0.0}
            </div>
          </div>

          <div className="flex flex-row justify-end gap-10 mt-6">
            <div className="font-bold text-right">Total</div>
            <div className="min-w-[80px] max-w-[90px] font-bold text-right">
              {currencySymbol}
              {Number(invoice_details?.total_cost).toFixed(2) ?? 0.0}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 mb-48">
        <div className="text-sm pt-16  ">
          {isPreview ? (
            <div
              dangerouslySetInnerHTML={{
                __html: basicDetails?.footerNotes?.replace('\n', '<br/>'),
              }}
              className="italic text-gray-500"
            ></div>
          ) : (
            <Textarea
              className="text-sm mt-16 italic"
              name="footerNotes"
              placeholder={`Footer Notes`}
              size="sm"
              value={basicDetails?.footerNotes}
              onChange={onChangeHandler}
              onBlur={onUpdateInvoiceHandler}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const getChipData = (value: string) => {
  switch (value) {
    case 'voided':
      return (
        <div className="text-xs font-bold bg-orange-400 text-white px-6 py-4 rounded-full">
          {value.toUpperCase()}
        </div>
      )

    case 'unpaid':
      return (
        <div className="text-xs font-bold bg-red-400 text-white px-6 py-4 rounded-full">
          {value.toUpperCase()}
        </div>
      )
    case 'disputed':
      return (
        <div className="text-xs font-bold bg-orange-400 text-white px-6 py-4 rounded-full">
          <div>{value.toUpperCase()}</div>
        </div>
      )
    case 'paid':
      return (
        <div className="text-xs font-bold bg-green-600 text-white px-6 py-4 rounded-full">
          {value.toUpperCase()}
        </div>
      )
    case 'approved':
      return (
        <div className="text-xs font-bold bg-green-500 text-white px-6 py-4 rounded-full">
          {value.toUpperCase()}
        </div>
      )

    default:
      return null
  }
}
