import {useCallback, useEffect, useState} from 'react'
import {ActiveLink, useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'

import {ConfirmationModal, Loading, ToolTip} from 'app/common'
import {DataTable} from 'app/components'
import {getProjectUserList, removeProjectUsersAction} from 'redux-src'

import {UserListType} from './index'
import {FaTrash} from 'react-icons/fa'

export const UserList = () => {
  const {
    projectUserList,
    getProjetUserListLoading,
    projectUserListTotal
  }: RT.ProjectReduxType = useSelector((state: any) => state.project)

  const [newProjectUserList, setProjectUserList] =
    useState<Array<UserListType>>()

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const remappedCompanyUserList = useCallback(() => {
    const remappedUserList = projectUserList?.map(
      (user: Api.ProjectUserListIndividual) => {
        return {
          id: user?.user_details?.id,
          fullName: `${user?.user_details?.display_name} ${user?.user_details?.lastname}`,
          userType: user?.user_details?.role,
          dateCreated: String(moment(user?.user_details?.created_at)),
          lastLogin: 'Pata Nahi'
        }
      }
    )

    setProjectUserList(remappedUserList)
  }, [projectUserList])

  const getProjectUserListPagination = (page?: number, limit?: number) => {
    dispatch(getProjectUserList(projectId, page, limit, ''))
  }

  const onRemoveUserFromProject = (userId: number) => {
    const body = {
      userIds: [userId]
    }

    dispatch(
      removeProjectUsersAction(projectId, body, () => {
        dispatch(getProjectUserList(projectId, 1, 10, ''))
      })
    )
  }

  useEffect(() => {
    remappedCompanyUserList()
  }, [remappedCompanyUserList])

  const columns: TableColumn<UserListType>[] = [
    {
      name: 'Name',
      selector: (row) => row.fullName,
      cell: (row) => {
        return (
          <ActiveLink
            to={`/settings/users/${row.id}`}
            className={'text-blue-300 font-medium'}
          >
            {row.fullName}
          </ActiveLink>
        )
      },
      sortable: true
    },
    {
      name: 'User Type',
      selector: (row) => row.userType,
      sortable: true,
      cell: (row) => {
        return <div>{row.userType}</div>
      }
    },
    {
      name: 'Date Created',
      selector: (row) => row.dateCreated,
      sortable: true,
      cell: (row) => {
        return <div>{moment(row.dateCreated).format('ddd, MMM DD YYYY')}</div>
      }
    },
    {
      name: 'Actions',
      selector: (row) => row.lastLogin || 'Never',
      sortable: true,
      right: true,
      cell: (row) => {
        return (
          <div className="flex items-center justify-end">
            <ConfirmationModal
              onConfirmClick={(closeModalHandler) => {
                onRemoveUserFromProject(row.id)
                closeModalHandler()
              }}
              danger
              label={
                'Are you sure you want to remove this User from the project?'
              }
              displayElement={
                <ToolTip text="Remove" left>
                  <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </button>
                </ToolTip>
              }
              confirmLabel="Remove"
            />
          </div>
        )
      }
    }
  ]

  return getProjetUserListLoading ? (
    <Loading />
  ) : (
    <div className="mt-16">
      <DataTable
        columns={columns}
        data={newProjectUserList ?? []}
        striped
        totalRows={projectUserListTotal}
        fetchData={getProjectUserListPagination}
        pagination
      />
    </div>
  )
}
