import {
  GET_COMPANY_DISABLED_USER,
  GET_COMPANY_USER_BY_ID
} from './../actions/actionTypes.action'
import {
  CREATE_COMPANY_USER,
  GET_APPUSER_LIST,
  USER,
  LOGOUT,
  GET_USER_LIST,
  GET_COMPANY_USER_LIST,
  GET_COMPANY_USER_GROUP_LIST,
  GET_COMPANY_USER_GROUP_BY_ID
  // GET_COMPANY_USER_GROUP_LIST,
} from '../actions'

const initalState: RT.UserReduxType = {
  loading: false,
  getAppUserList_loading: false,
  userDetails: null,
  verificationSuccess: false,
  appUsers: [],

  userList: [],
  userListLoading: false,

  companyUserDetails: undefined,
  getCompanyUserLoading: false,

  companyUserList: [],
  companyUserListLoading: false,
  companyUserListTotal: 0,

  getCreateCompanyUserLoading: false,

  companyUserGroupList: undefined,
  getCompanyUserGroupListLoading: false,
  companyUserGroupListTotal: 0,

  companyGroupDetailsLOading: false,
  companyGroupDetails: undefined,

  companyDisabledUsers: undefined,
  companyDisabledUserLoading: false
}

export function userReducer(state = initalState, action: any) {
  const {type, payload} = action

  switch (type) {
    case USER.LOADING:
    case LOGOUT.LOADING:
      return {...state, loading: true}

    case GET_APPUSER_LIST.LOADING:
      return {...state, getAppUserList_loading: true}

    case USER.SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: payload,
        verificationSuccess: true
      }
    case GET_APPUSER_LIST.SUCCESS:
      return {
        ...state,
        getAppUserList_loading: false,
        appUsers: payload
      }

    case LOGOUT.SUCCESS:
      return {
        ...state,
        loading: false
      }

    case USER.ERROR:
      return {...state, loading: false, userDetails: null}
    case GET_APPUSER_LIST.ERROR:
      return {...state, getAppUserList_loading: false, appUsers: []}
    case LOGOUT.ERROR:
      return {...state, loading: false}

    case GET_USER_LIST.LOADING:
      return {...state, userListLoading: true}

    case GET_USER_LIST.SUCCESS:
      return {...state, userList: payload.rows, userListLoading: false}

    case GET_USER_LIST.ERROR:
      return {...state, userListLoading: false}

    case GET_COMPANY_USER_LIST.LOADING:
      return {...state, companyUserListLoading: true}

    case GET_COMPANY_USER_LIST.SUCCESS:
      return {
        ...state,
        companyUserList: payload.rows,
        companyUserListTotal: payload.total,
        companyUserListLoading: false
      }

    case GET_COMPANY_USER_LIST.ERROR:
      return {...state, companyUserListLoading: false}

    case CREATE_COMPANY_USER.LOADING:
      return {...state, getCreateCompanyUserLoading: true}

    case CREATE_COMPANY_USER.SUCCESS:
      return {
        ...state,
        getCreateCompanyUserLoading: false
      }

    case CREATE_COMPANY_USER.ERROR:
      return {...state, getCompanyUserGroupListLoading: false}

    case GET_COMPANY_USER_GROUP_LIST.LOADING:
      return {...state, getCompanyUserGroupListLoading: true}

    case GET_COMPANY_USER_GROUP_LIST.SUCCESS:
      return {
        ...state,
        getCompanyUserGroupListLoading: false,
        companyUserGroupList: payload.rows,
        companyUserGroupListTotal: payload.total
      }

    case GET_COMPANY_USER_GROUP_LIST.ERROR:
      return {...state, getCreateCompanyUserLoading: false}

    case GET_COMPANY_USER_BY_ID.LOADING:
      return {...state, getCompanyUserLoading: true}

    case GET_COMPANY_USER_BY_ID.SUCCESS:
      return {
        ...state,
        getCompanyUserLoading: false,
        companyUserDetails: payload
      }

    case GET_COMPANY_USER_BY_ID.ERROR:
      return {...state, getCompanyUserLoading: false}

    case GET_COMPANY_USER_GROUP_BY_ID.LOADING:
      return {...state, companyGroupDetailsLOading: true}

    case GET_COMPANY_USER_GROUP_BY_ID.SUCCESS:
      return {
        ...state,
        companyGroupDetailsLOading: false,
        companyGroupDetails: payload
      }

    case GET_COMPANY_USER_GROUP_BY_ID.ERROR:
      return {...state, companyGroupDetailsLOading: false}

    case GET_COMPANY_DISABLED_USER.LOADING:
      return {...state, companyDisabledUserLoading: true}

    case GET_COMPANY_DISABLED_USER.SUCCESS:
      return {
        ...state,
        companyDisabledUserLoading: false,
        companyDisabledUsers: payload.rows
      }

    case GET_COMPANY_DISABLED_USER.ERROR:
      return {...state, companyDisabledUserLoading: false}

    default:
      return state
  }
}
