import {errorHandler} from '../../utils'
import {GET_QUOTE_SETTINGS, UPDATE_QUOTE_SETTINGS} from '.'
import {api, APIS} from '../../config'

export function getQuoteSettings(companyId: number, successCallback?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_QUOTE_SETTINGS.LOADING})
      res = await api<Api.Base<Api.QuoteSettings>>(
        `/company/${companyId}${APIS.quote_settings}`
      )
      const {
        success,
        data: {data: quoteDetails, message}
      } = res.data

      if (success) {
        dispatch({type: GET_QUOTE_SETTINGS.SUCCESS, payload: quoteDetails})
        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_QUOTE_SETTINGS.ERROR, payload: message})
        return 0
      }
    } catch (err) {
      dispatch({type: GET_QUOTE_SETTINGS.ERROR})
      return 1
    }
  }
}

export function updateQuoteSettings(
  companyId: number,
  body: any,
  callback?: () => void,
  toast?: any
) {
  return async function (dispatch: any) {
    let res

    // const {displayName, emailAddress, password, companyName, mobileNumber}

    try {
      dispatch({type: UPDATE_QUOTE_SETTINGS.LOADING})

      res = await api<any>(
        `/company/${companyId}${APIS.quote_settings}`,
        'PATCH',
        body
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {message}
        } = res.data
        if (success) {
          dispatch({type: UPDATE_QUOTE_SETTINGS.SUCCESS})
          callback()
          toast.success(message)
        } else dispatch({type: UPDATE_QUOTE_SETTINGS.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: UPDATE_QUOTE_SETTINGS.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}
