import {Modal} from 'app/common'
import {SelectFloorPlan} from '../selectFloorPlan'

interface SetPlanPositionModelProps {
  isModelOpen: boolean
  setModelOpen: React.Dispatch<React.SetStateAction<boolean>>
  floorPlanId: number
  ticketStatus: 'open' | 'in progress' | 'feedback' | 'completed'
  edit?: boolean
  planCallback?: () => void
}

export const SetPlanPositionModel = ({
  isModelOpen,
  setModelOpen,
  floorPlanId,
  ticketStatus,
  edit,
  planCallback
}: SetPlanPositionModelProps) => {
  return (
    <Modal
      id={`set-plan-position-modal-${floorPlanId}`}
      title="Set Plan Position"
      isActive={isModelOpen}
      toggleModal={setModelOpen}
      size="full"
    >
      <div className="flex flex-col items-stretch h-[92svh] justify-between px-16">
        <SelectFloorPlan
          floorPlanId={floorPlanId}
          containerHeight={'80dvh'}
          ticketStatus={
            (ticketStatus ?? 'open') as
              | 'open'
              | 'in progress'
              | 'feedback'
              | 'completed'
          }
          edit={edit}
          planCallback={planCallback}
        />
      </div>
    </Modal>
  )
}
