import {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'
import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface SectionProps {
  id: number
  onSelectElement: (id: number) => void
}

interface SectionContentType {
  label: string
}

export const Section = ({id, onSelectElement}: SectionProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualElement} = useIndividualFormElement(id)

  const [sectionDetails, setSectionDetails] = useState<SectionContentType>(
    individualElement?.content as SectionContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setSectionDetails((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, sectionDetails, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionDetails])

  const isSectionAdded = !!(individualElement?.content?.label.length === 0)

  return (
    <div
      className={`border border-1 rounded-sm overflow-hidden gap-4 ${
        selectedFormElementId === id
          ? `border-solid border-${isSectionAdded ? 'red' : 'blue'}-300`
          : `border-dashed border-${
              isSectionAdded ? 'red' : 'gray'
            }-300 hover:bg-${isSectionAdded ? 'red' : 'blue'}-100`
      }`}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      <div className="px-14 py-8">
        <input
          type="text"
          name="label"
          value={sectionDetails?.label}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          placeholder="Type section name here"
          className="text-black text-md w-full bg-transparent"
        />
      </div>
    </div>
  )
}
