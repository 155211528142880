import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {VscFilePdf} from 'react-icons/vsc'
import {useNavigation} from 'react-auth-navigation'

import {InfiniteScrollBlock} from 'app/common'
import {getAssetName, getAssetUrl} from 'helpers'
import {getTicketLogListAction} from 'redux-src'

import {TicketContext} from '../../ticket.page'

export const JournalComponent = ({journalFilter}: {journalFilter: any}) => {
  const {
    ticketLogList: ticketLogListFromApi,
    ticketLogListTotal,
    isLastTicketLogList,
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const [ticketLogList, setTicketLogList] =
    useState<Array<Api.TicketLogIndividual>>()

  const {projectTicketId} = useContext(TicketContext)

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const [limit, setLimit] = useState<number>(10)

  const fetchTicketLogListCB = useCallback(() => {
    const journalType = Array.isArray(journalFilter?.type)
      ? journalFilter?.type?.filter((filter: any) => filter.isChecked)
      : []

    projectTicketId &&
      dispatch(
        getTicketLogListAction(
          1,
          limit,
          projectId,
          projectTicketId,
          journalFilter?.order,
          journalType?.length === 0
            ? undefined
            : journalType?.map((type: any) => type.value)?.join(','),
          () => {},
        ),
      )
  }, [
    dispatch,
    journalFilter?.order,
    journalFilter?.type,
    limit,
    projectId,
    projectTicketId,
  ])

  useEffect(() => {
    fetchTicketLogListCB()
  }, [fetchTicketLogListCB])

  useEffect(() => {
    ticketLogListFromApi && setTicketLogList([...ticketLogListFromApi])
  }, [ticketLogListFromApi])

  return (
    <div className="flex w-full h-full px-8 relative overflow-hidden mb-[44px] pointer-events-auto">
      {ticketLogList?.length > 0 ? (
        <InfiniteScrollBlock
          key={'Infinite scroll'}
          className="flex flex-1 flex-col gap-14 py-6 pr-10 border-r-1 border-gray-200 overflow-y-scroll overflow-x-hidden h-full"
          triggerCallback={() => {
            setLimit((prev) =>
              prev === Math.ceil(Number(ticketLogListTotal) / 10) * 10
                ? prev
                : prev + 10,
            )
          }}
          isLast={isLastTicketLogList}
        >
          <div className="w-full flex flex-col my-10 gap-20">
            {ticketLogList?.map(
              ({asset_details, log_details, user_details}) => {
                return (
                  <CommentContent
                    key={log_details?.id}
                    assetDetails={asset_details}
                    logDetails={log_details}
                    userDetails={user_details}
                  />
                )
              },
            )}
          </div>
        </InfiniteScrollBlock>
      ) : (
        <div className="h-full w-full flex items-center justify-center text-gray-300 text-md font-bold">
          No logs found
        </div>
      )}
    </div>
  )
}

interface CommentContentProps {
  assetDetails?: Api.AssetDetailsType
  logDetails?: Api.TicketLogIndividual['log_details']
  userDetails?: {
    id: number
    lastname: string
    display_name: string
  }
}

export const CommentContent = ({
  assetDetails,
  logDetails,
  userDetails,
}: CommentContentProps) => {
  const feedbackMessage = useMemo(() => {
    const logDetailsMessage = logDetails?.message

    const isFeedback = logDetailsMessage?.includes('Feedback : ')

    const messageReformatted = isFeedback
      ? logDetailsMessage?.replace('Feedback : ', '')
      : logDetailsMessage

    return {
      messageReformatted,
      isFeedback,
    }
  }, [logDetails?.message])

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row w-full gap-6">
        <span className="flex justify-center h-[30px] w-[30px] rounded-md text-white bg-blue-400 text-sm items-center">
          {userDetails?.display_name
            ?.split(' ')
            ?.at(0)
            ?.charAt(0)
            ?.toUpperCase()}
          {userDetails?.lastname?.split(' ')?.at(0)?.charAt(0)?.toUpperCase()}
        </span>

        <div className="flex flex-col gap-16 w-full">
          <div className="flex justify-between w-full">
            <div className="flex gap-10">
              <span className="text-sm font-bold">
                {userDetails?.display_name
                  ?.split(' ')
                  ?.at(0)
                  ?.charAt(0)
                  ?.toUpperCase()}
                .{userDetails?.lastname?.split(' ')?.at(0)}
              </span>
            </div>
            <div className="text-sm text-gray-400">
              {moment(logDetails?.created_at).format('DD/MM/YYYY hh:mm a')}
            </div>
          </div>
          <div className="pr-6 mt-[-10px] w-full">
            {!!assetDetails ? (
              assetDetails?.file_type === 'image' ? (
                <div className="flex flex-col h-[100px] w-[100px] rounded-sm">
                  <img
                    src={getAssetUrl(assetDetails)}
                    alt={'addedAttachment'}
                    className="h-full m-0 w-full rounded-sm object-contain"
                  />
                  <span className="text-sm text-gray-300 w-full truncate inline-block">
                    {assetDetails?.label
                      ? assetDetails?.label
                      : getAssetName(assetDetails)}
                  </span>
                </div>
              ) : (
                <div className="flex items-center justify-center w-[100px] h-[100px] flex-col gap-6 text-gray-400">
                  <VscFilePdf size={50} />
                  <div className="w-full truncate self-start">
                    {assetDetails?.name}
                  </div>
                </div>
              )
            ) : (
              <div
                className={`text-sm py-4 w-[90%] break-all ${
                  logDetails?.type === 'activity log'
                    ? 'px-6 border border-solid border-l-2 border-blue-300 italic'
                    : feedbackMessage?.isFeedback
                    ? 'px-6 border border-solid border-l-2 border-yellow italic'
                    : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html:
                    feedbackMessage?.messageReformatted?.replaceAll(
                      '\n',
                      '<br />',
                    ) ?? '',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
