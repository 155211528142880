import {toast} from 'app/common'
import {getParsedUrl} from 'helpers'
import {
  ACTIVATE_SITE,
  ADD_SITE,
  ADD_SITE_CONTACT,
  ALTER_SITE_CONTACT,
  ARCHIVE_SITE,
  GET_ARCHIVED_SITE,
  GET_SITE,
  GET_SITE_BY_ID,
  GET_SITE_CONTACT,
  GET_SITE_FILES,
  UPDATE_SITE,
  UPDATE_SITE_CONTACT,
  UPLOAD_SITE_FILES,
} from '.'
import {api, APIS} from '../../config'
import {errorHandler} from '../../utils'

export function getSite(
  params: {
    page?: number
    limit?: number
    search?: string
    status?: 'inactive' | 'active' | 'archived'
  } = {
    page: 1,
    limit: 10,
    status: 'active',
  },
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE.LOADING})

      res = await api<Api.Base<Api.SiteListType>>(
        getParsedUrl(APIS.get_site, params),
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({type: GET_SITE.SUCCESS, payload: {total, rows}})

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_SITE.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_SITE.ERROR})
      return 0
    }
  }
}

export function getArchivedSite(
  params: {
    page?: number
    limit?: number
    search?: string
    status?: 'inactive' | 'active' | 'archived'
  } = {
    page: 1,
    limit: 10,
    status: 'archived',
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ARCHIVED_SITE.LOADING})

      res = await api<Api.Base<Api.SiteListType>>(
        getParsedUrl(APIS.get_site, params),
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_ARCHIVED_SITE.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_ARCHIVED_SITE.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_ARCHIVED_SITE.ERROR})
      return 0
    }
  }
}

export function getSiteById(
  siteId: number | string,
  successCallback?: (data: Api.SiteDetailsByIdType) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_BY_ID.LOADING})

      res = await api<Api.Base<Api.SiteDetailsByIdType>>(
        `${APIS.get_site}/${siteId}`,
        'GET',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: GET_SITE_BY_ID.SUCCESS, payload: data})

        successCallback?.(data)
        return 1
      } else {
        dispatch({type: GET_SITE_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_SITE_BY_ID.ERROR})
      return 0
    }
  }
}

export function addSite(body: any, successCallback?: any, toast?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_SITE.LOADING})

      res = await api<Api.Base<Api.AddSiteDetailsResponse>>(
        `${APIS.add_site}`,
        'POST',
        body,
      )

      // console.log(res.data)

      const {success} = res.data

      if (success) {
        // console.log()
        dispatch({type: ADD_SITE.SUCCESS})

        successCallback?.(res.data)
        return 1
      } else {
        dispatch({type: ADD_SITE.ERROR})
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: ADD_SITE.ERROR})
      toast.error('Error adding the site!')
      return 0
    }
  }
}

export function updateSite(
  siteId: number = 1,
  body: any,
  successCallback?: (data: Api.SiteDetailsByIdType) => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_SITE.LOADING})

      res = await api<any>(`${APIS.get_site}/${siteId}`, 'PATCH', body)

      const {
        success,
        data: {data: site, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_SITE.SUCCESS, payload: site})
        successCallback?.(site)
        toast?.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_SITE.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_SITE.ERROR})
      return 0
    }
  }
}

export function createSiteContact(
  siteId: number = 0,
  body: any,
  successCallback?: (data: Api.ContactType) => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_SITE_CONTACT.LOADING})

      res = await api<Api.Base<Api.ContactType>>(
        `${APIS.get_site}/${siteId}/contacts/`,
        'POST',
        body,
      )

      const {
        success,
        data: {data: site_contact, message},
      } = res.data

      if (success) {
        dispatch({type: ADD_SITE_CONTACT.SUCCESS, payload: site_contact})
        toast.success(message)
        successCallback?.(site_contact)
        return 1
      } else {
        dispatch({type: ADD_SITE_CONTACT.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_SITE_CONTACT.ERROR})
      return 0
    }
  }
}

export function archivedSiteAction(
  siteId: number,
  successCallback?: (data: Api.ContactType) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ARCHIVE_SITE.LOADING})

      res = await api<Api.Base<Api.ContactType>>(
        `${APIS.get_site}/${siteId}/archive`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ARCHIVE_SITE.SUCCESS, payload: data})
        toast.success(message)
        successCallback?.(data)
        return 1
      } else {
        dispatch({type: ARCHIVE_SITE.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: ARCHIVE_SITE.ERROR})
      return 0
    }
  }
}

export function activateSiteAction(
  siteId: number,
  successCallback?: (data: Api.ContactType) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ACTIVATE_SITE.LOADING})

      res = await api<Api.Base<Api.ContactType>>(
        `${APIS.get_site}/${siteId}/activate`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ACTIVATE_SITE.SUCCESS, payload: data})
        toast.success(message)
        successCallback?.(data)
        return 1
      } else {
        dispatch({type: ACTIVATE_SITE.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: ACTIVATE_SITE.ERROR})
      return 0
    }
  }
}

export function getSiteContact(
  siteId: number = 0,
  contactId: number = 0,
  successCallback?: (data: Api.SiteContactDetails) => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_CONTACT.LOADING})

      res = await api<Api.Base<Api.SiteContactType>>(
        `${APIS.get_site}/${siteId}/contacts/${contactId}`,
      )

      const {
        success,
        data: {data: site_contact, message},
      } = res.data

      if (success) {
        dispatch({type: GET_SITE_CONTACT.SUCCESS, payload: site_contact})
        toast?.success(message)
        successCallback?.(site_contact?.contact_details)
        return 1
      } else {
        dispatch({type: GET_SITE_CONTACT.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: GET_SITE_CONTACT.ERROR})
      return 0
    }
  }
}

export function updateSiteContact(
  siteId: number = 0,
  contactId: number = 0,
  body: any,
  successCallback?: (data: Api.ContactType) => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_SITE_CONTACT.LOADING})

      res = await api<Api.Base<Api.ContactType>>(
        `${APIS.get_site}/${siteId}/contacts/${contactId}`,
        'PATCH',
        body,
      )

      const {
        success,
        data: {data: site_contact, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_SITE_CONTACT.SUCCESS, payload: site_contact})
        toast.success(message)
        successCallback?.(site_contact)
        return 1
      } else {
        dispatch({type: UPDATE_SITE_CONTACT.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_SITE_CONTACT.ERROR})
      return 0
    }
  }
}

export function deleteSiteContact(
  siteId: number = 0,
  infoId: number = 0,
  contactId: number = 0,
  successCallback?: () => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_SITE_CONTACT.LOADING})

      res = await api<any>(
        `${APIS.get_site}/${siteId}/info/${infoId}/other-contacts/${contactId}`,
        'DELETE',
      )

      const {
        success,
        data: {data: site, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_SITE_CONTACT.SUCCESS, payload: site})
        toast?.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({type: UPDATE_SITE_CONTACT.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_SITE_CONTACT.ERROR})
      return 0
    }
  }
}

export function alterContactTypes(
  siteId: number = 0,
  infoId: number = 0,
  contactId: number = 0,
  contactType: 'other-contacts' | 'main-contact',
  setAs: 'billing' | 'default',
  successCallback?: any,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ALTER_SITE_CONTACT.LOADING})

      res = await api<any>(
        `${APIS.get_site}/${siteId}/info/${infoId}/${contactType}/set-${setAs}-contact/${contactId}`,
        'PATCH',
      )

      const {
        success,
        data: {data: site, message},
      } = res.data

      if (success) {
        dispatch({type: ALTER_SITE_CONTACT.SUCCESS, payload: site})
        toast?.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({type: ALTER_SITE_CONTACT.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: ALTER_SITE_CONTACT.ERROR})
      return 0
    }
  }
}

// UPLOAD SITES FILES AND PHOTOS

export const uploadSiteFiles = (
  siteId: number,
  assets: FormData,
  successCallback?: any,
  toast?: any,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPLOAD_SITE_FILES.LOADING})

      res = await api<any>(
        `${APIS.get_site}/${siteId}/upload-files`,
        'POST',
        assets,
        {
          file: true,
        },
      )

      const {
        success,
        data: {data: site, message},
      } = res.data

      if (success) {
        dispatch({
          type: UPLOAD_SITE_FILES.SUCCESS,
          payload: site,
        })
        toast?.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: UPLOAD_SITE_FILES.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({
        type: UPLOAD_SITE_FILES.ERROR,
      })
      return 0
    }
  }
}

// GET SITE FILES AND PHOTOS

export const getSiteFiles = (
  siteId: number,
  successCallback?: any,
  toast?: any,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_FILES.LOADING})

      res = await api<Api.Base<Api.AssetsList>>(
        `${APIS.get_site}/${siteId}/assets`,
      )

      const {
        success,
        data: {
          data: {rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_SITE_FILES.SUCCESS,
          payload: rows,
        })
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_SITE_FILES.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({
        type: GET_SITE_FILES.ERROR,
      })
      return 0
    }
  }
}
