import { REGISTER } from '../actions'

const initalState = {
	loading: false,
	error: ''
}

export function registerReducer(state = initalState, action: any) {
	const { type } = action

	switch (type) {
		case REGISTER.LOADING:
			return { ...state, loading: true, error: false }
		case REGISTER.SUCCESS:
			return { ...state, loading: false, error: false }
		case REGISTER.ERROR:
			return { ...state, loading: false, error: action.payload }

		default:
			return state
	}
}
