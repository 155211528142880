export const getAddedAndDeletedIds = <T>(
  originalArr: Array<T>,
  newArray: Array<T>
) => {
  let addedIds = []
  let deletedIds = []

  addedIds = newArray?.filter((ids) => !originalArr?.includes(ids))

  deletedIds = originalArr?.filter((ids) => !newArray?.includes(ids))

  return {addedIds, deletedIds}
}
