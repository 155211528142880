import { useState } from 'react'
import { AnimatedBlock, useAnimatedValue, useMeasure } from 'react-ui-animate'

export const Collapsible = ({
  topContainer,
  children,
  containerClassName,
  rightElement,
}: {
  topContainer: any
  children?: React.ReactNode
  containerClassName?: string
  rightElement?: React.ReactNode
}) => {
  const [open, setOpen] = useState(true)
  const [height, setHeight] = useState<any>(0)
  const heightAnimation = useAnimatedValue(open ? height : 0)

  const bind = useMeasure(({ height }) => {
    setHeight(height)
  })

  return (
    <div className={containerClassName}>
      <div onClick={() => setOpen((prev) => !prev)}>{topContainer}</div>

      <AnimatedBlock
        style={{
          height: heightAnimation.value,
          overflowY: 'hidden',
        }}
      >
        <div {...bind()}>{children}</div>
      </AnimatedBlock>
    </div>
  )
}
