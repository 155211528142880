import {useState} from 'react'
import {FaSearch} from 'react-icons/fa'

import {Button, Input} from 'app/common'
import {ItemManagementTable} from '../itemManagementTable'
import {AddItemModal} from '../addItem'
import colors from 'tailwindcss/colors'
import {useDebounceValue} from 'hooks'
import {SettingsHeader} from 'app/pages/settings/components'

export const ItemManagementComp = () => {
  const [searchItem, setSearchItem] = useState<string>()

  const debouncedSearchValue = useDebounceValue(searchItem)

  const [newItem, setNewItem] = useState(false)

  return (
    <div>
      <SettingsHeader
        title={'Labour and Material Combined'}
        append={
          <Button
            title="Add New Combination"
            onClick={() => setNewItem(true)}
          />
        }
        subHeader={
          'The list allows you to manage combination of labour and materials'
        }
      />

      <div className="mt-20">
        <div className="flex  pb-10">
          <Input
            name="filterValue"
            type="text"
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
            containerClass="w-[300px]"
            placeholder="Search item"
            className="w-full text-sm"
            onChange={(e: any) => setSearchItem(e.target.value)}
          />
        </div>
      </div>

      <div className="my-10 mb-24 border-b-1 border-gray-200" />
      <div className="mt-10">
        <ItemManagementTable search={debouncedSearchValue} />
      </div>
      {newItem && (
        <AddItemModal
          addItemModal={newItem}
          setAddItemModal={setNewItem}
          close={() => setNewItem(false)}
          mode="add"
        />
      )}
    </div>
  )
}
