import React from 'react'

import {CustomModal, Button} from '../../common'

interface ConfirmModalProps {
  displayElement: React.ReactNode
  label: React.ReactNode
  onConfirmClick?: (arg: any) => void
  confirmLabel?: string
  cancelLabel?: string
  loading?: boolean
  danger?: boolean
  children?: React.ReactNode
  withPortal?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  isDisabled?: boolean
}
export const ConfirmationModal = ({
  displayElement,
  label,
  onConfirmClick,
  confirmLabel = 'Delete',
  cancelLabel = 'Cancel',
  loading = false,
  danger,
  children,
  withPortal = true,
  size = 'sm',
  isDisabled
}: ConfirmModalProps) => {
  return (
    <CustomModal
      displayElement={displayElement}
      title={label}
      withPortal={withPortal}
      size={size}
    >
      {({onCloseModalHandler}) => (
        <div className="flex flex-col py-10 my-10 px-14 ">
          {children && (
            <div className="flex flex-col px-14 pb-20">{children}</div>
          )}

          <div
            className="flex flex-row justify-end items-center gap-8 "
            style={{
              width: '100%',
              float: 'right'
            }}
          >
            <Button
              buttonColor="text-blue-400 bg-blue-100 hover:bg-blue-150"
              type="button"
              onClick={() => {
                onCloseModalHandler()
              }}
              title={cancelLabel}
              // loading={loading}
            />

            <Button
              type="button"
              buttonColor={
                danger
                  ? 'text-white bg-red-400 hover:bg-red-500 px-10'
                  : 'text-white bg-blue-400 hover:bg-blue-500 px-10'
              }
              onClick={() => {
                onConfirmClick(onCloseModalHandler)
              }}
              title={confirmLabel}
              loading={loading}
            />
          </div>
        </div>
      )}
    </CustomModal>
  )
}
