import moment from 'moment'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'

import {DataTable, DescriptionBox} from 'app/components'
import {getSiteVisitListAction} from 'redux-src'
import {changeNumToLetter} from 'utils/numToLetter'
import {useMemo} from 'react'

export const SiteVisits = () => {
  const dispatch = useDispatch()
  const {
    params,
    navigation: {navigate}
  } = useNavigation()
  const {projectId} = params as any
  const {currencySymbol} = useAuth()
  const {
    getSiteVisitList: siteVisitList,
    siteVisitTotal
  }: RT.SiteVisitReduxType = useSelector((state: any) => state.siteVisit)

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project
  )

  const getSiteVisits = (page?: number, tableLimit?: number) => {
    dispatch(getSiteVisitListAction(projectId, page, tableLimit))
  }

  const columns: TableColumn<any>[] = [
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row: any) => (
        <div className="text-xs uppercase w-[max-content] font-bold bg-orange-700 text-white flex items-center justify-center px-6 py-4 rounded-sm">
          {row?.status?.split('_').join(' ')}
        </div>
      )
    },
    {
      name: 'Site Visit',
      selector: (row) => row.site_visit_number,
      sortable: true,
      cell: (row: any) => (
        <div
          className="text-xs font-bold text-blue-400 rounded-full w-[110px] cursor-pointer"
          onClick={() =>
            navigate(`/projects/${projectId}/site-visit/${row?.id}/scheduling`)
          }
        >
          {projectDetail?.project_details?.project_prefix}-
          {projectDetail?.project_details?.project_number}
          {changeNumToLetter(row?.site_visit_number ?? 1)}
        </div>
      )
    },
    {
      name: 'Title',
      selector: (row) => row.title,
      sortable: true,
      cell: (row: any) => (
        <div className="text-sm text-black px-6 py-4 rounded-full">
          {row.title}
        </div>
      )
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
      cell: (row: any) => (
        <div className="text-sm text-black px-6 py-4 rounded-full">
          {row.description}
        </div>
      )
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Total Cost
        </div>
      ),
      selector: (row) => row.totalCost,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.quote_details.id}`}
            className="text-black text-sm"
          >
            {currencySymbol}
            0.00
          </div>
        </div>
      )
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Total Sell
        </div>
      ),
      selector: (row) => row.totalSell,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.quote_details.id}`}
            className="text-black text-sm"
          >
            {currencySymbol}
            0.00
          </div>
        </div>
      )
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] truncate w-full">
          Created
        </div>
      ),
      selector: (row) => row.created_at,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.id}`}
            className="text-black text-sm"
          >
            {moment(row.created_at).format('DD/MM/YYYY')}
          </div>
        </div>
      )
    }
  ]

  const siteVisitListRemapped = useMemo(
    () =>
      siteVisitList?.map((siteVisit: any) => ({
        id: siteVisit?.site_visit_details?.id,
        site_visit_number: siteVisit?.site_visit_details?.site_visit_number,
        status: siteVisit?.site_visit_details?.status,
        title: siteVisit?.site_visit_details?.title,
        description: siteVisit?.site_visit_details?.description
      })),
    [siteVisitList]
  )

  return (
    <DescriptionBox title="Site Visits" bodyClassName="p-0">
      <DataTable
        columns={columns}
        data={siteVisitListRemapped}
        pagination
        persistTableHead
        dense
        totalRows={siteVisitTotal}
        fetchData={getSiteVisits}
      />
    </DescriptionBox>
  )
}
