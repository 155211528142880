// VALIDATOR
export function validator(errorObject: any) {
  return function (key: any, condition: boolean, callback: () => void) {
    if (condition) {
      errorObject[key] = true
      callback && callback()
    } else {
      delete errorObject[key]
    }
  }
}

export function isValid(errorObject: any) {
  return Object.keys(errorObject).length === 0
}

export function validationHandler(errorObject: any, toast: any, prefix?: any) {
  let flag: boolean = true
  console.log('errorObject: ', errorObject)
  Object.keys(errorObject).map((key: any) => {
    if (typeof errorObject[key] === 'string') {
      if (errorObject[key].length === 0) {
        toast.error(`Please enter the ${prefix ?? ''} ${key}.`)
        flag = false
      }
    } else if (typeof errorObject[key] === 'number') {
      if (errorObject[key] === 0) {
        toast.error(`Please enter the ${prefix ?? ''} ${key}.`)
        flag = false
      }
    }
    return null
  })
  return flag
}

export function emailValidation(emailAddress: any) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return !emailRegex.test(emailAddress)
}

export function passwordValidation(password: any) {
  const whiteSpaceRegex = /^(?=.*\s)/
  const upperCaseRegex = /(?=.*[A-Z])/
  const lowerCaseRegex = /^(?=.*[a-z])/
  const digitRegex = /^(?=.*[0-9])/
  const specialRegex = /(?=.*[!@#$%^&*])/
  const passwordLengthRegex = /^.{7,16}$/

  const whiteSpace = !whiteSpaceRegex.test(password)
  const upperCase = upperCaseRegex.test(password)
  const lowerCase = lowerCaseRegex.test(password)
  const digit = digitRegex.test(password)
  const special = specialRegex.test(password)
  const passwordLength = passwordLengthRegex.test(password)

  if (!whiteSpace)
    return {
      condition: true,
      message: 'Password must not contain white spaces.',
    }
  else if (!upperCase)
    return {
      condition: true,
      message: 'Password must contain at least one upper case.',
    }
  else if (!lowerCase)
    return {
      condition: true,
      message: 'Password must contain at least one lower case.',
    }
  else if (!digit)
    return {
      condition: true,
      message: 'Password must contain at least one digit.',
    }
  else if (!special)
    return {
      condition: true,
      message: 'Password must contain at least one special symbol.',
    }
  else if (!passwordLength)
    return {
      condition: true,
      message: 'Password must be 7-16 characters long.',
    }
  return {
    condition: false,
    message: null,
  }
}
