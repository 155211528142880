import {useContext, useEffect, useState} from 'react'
import {RxCross2} from 'react-icons/rx'
import {useDispatch} from 'react-redux'
import {v4 as uuidv4} from 'uuid'

import {alterFormContent} from 'redux-src'
import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface SingleChoiceOptionsProps {
  id: number
  onSelectElement: (id: number) => void
}

interface SingleChoiceContentType {
  label: string
  description: string
  value: Array<{id: number; text: string; isChecked: boolean}>
}

export const SingleChoiceOptions = ({
  id,
  onSelectElement
}: SingleChoiceOptionsProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {
    individualElement,
    individualStyles,
    alterElementSettings,
    defaultElement
  } = useIndividualFormElement(id)

  const [singleChoiceDetails, setSingleChoice] = useState<{
    label: string
    description: string
    value: Array<{id: number; text: string; isChecked: boolean}>
  }>(individualElement?.content as SingleChoiceContentType)

  const uniqueId = uuidv4()

  const dispatch = useDispatch()

  const onChangeOptions = (event: any, value: string, choiceId: number) => {
    const tempSingleChoiceList = [...singleChoiceDetails?.value]

    const updatedChoiceList = tempSingleChoiceList?.map((choiceItem) => {
      if (choiceItem?.id === choiceId) {
        return {
          ...choiceItem,
          text: value
        }
      } else {
        return choiceItem
      }
    })

    setSingleChoice((prev) => ({...prev, value: updatedChoiceList}))
  }

  const onAddNewChoice = (event: any) => {
    if (event.key === 'Enter') {
      event?.preventDefault()
      setSingleChoice((prev: any) => ({
        ...prev,
        value: [...prev.value, {id: uniqueId, text: '', isChecked: false}]
      }))
    }
  }

  const onRemoveChoiceItem = (choiceId: number) => {
    const tempChoiceList = [...singleChoiceDetails?.value]

    const filteredChoiceList = tempChoiceList?.filter(
      (choiceItem) => choiceItem?.id !== choiceId
    )

    setSingleChoice((prev) => ({...prev, value: filteredChoiceList}))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, singleChoiceDetails, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleChoiceDetails])

  useEffect(() => {
    const updatedLayoutHeight = Array.isArray(singleChoiceDetails?.value)
      ? defaultElement?.layout?.h + (singleChoiceDetails?.value?.length - 1) * 2
      : defaultElement?.layout?.h

    const selectedLayout = {
      ...individualElement.layout,
      h: updatedLayoutHeight
    }

    alterElementSettings({
      layout: selectedLayout
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alterElementSettings,
    defaultElement?.layout?.h,
    singleChoiceDetails?.value
  ])

  return (
    <div
      className={`flex flex-col border border-1 rounded-sm overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
        ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="py-8 flex gap-6">
          <input
            type="text"
            name="label"
            value={singleChoiceDetails?.label}
            onChange={(e: any) => {
              setSingleChoice((prev) => ({...prev, label: e.target.value}))
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />

          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div className="py-4">
          <input
            type="text"
            name="description"
            value={singleChoiceDetails?.description}
            onChange={(e: any) => {
              setSingleChoice((prev) => ({
                ...prev,
                description: e.target.value
              }))
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}
      <div className="flex flex-col mx-12">
        {singleChoiceDetails?.value?.length > 0 &&
          singleChoiceDetails?.value?.map((item, index, array) => {
            return (
              <label
                key={item?.id}
                className="flex items-center gap-6 bg-none hover:bg-blue-100 px-6 rounded-sm"
              >
                <input type="radio" name="radio" className="" disabled />
                <input
                  type="text"
                  name="options"
                  value={item?.text}
                  onChange={(e) => {
                    onChangeOptions(e, e.target.value, item?.id)
                  }}
                  onKeyDown={(event: any) => {
                    onAddNewChoice(event)
                  }}
                  placeholder="Type option here-press enter to add new one"
                  className="text-black px-4 py-6 w-[50%] mt-4 rounded-xs text-sm bg-none hover:bg-none bg-transparent"
                  autoFocus={index === array.length - 1}
                />
                {array?.length > 1 && (
                  <div
                    className="ml-14 items-center"
                    onClick={() => {
                      onRemoveChoiceItem(item?.id)
                    }}
                  >
                    <RxCross2 size={18} className="cursor-pointer" />
                  </div>
                )}
              </label>
            )
          })}
      </div>
    </div>
  )
}
