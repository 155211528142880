import {api, APIS} from '../../config'
import {
  GET_CHECKLIST,
  ADD_CHECKLIST,
  UPDATE_CHECKLIST,
  ARCHIVE_CHECKLIST,
  DELETE_CHECKLIST,
  RESTORE_CHECKLIST,
  GET_JOB_CHECKLIST,
  ADD_JOB_CHECKLIST,
  UPDATE_JOB_CHECKLIST,
  GET_SITE_VISIT_CHECKLIST,
  GET_JOB_CHECKLIST_BY_ID,
  ADD_SITE_VISIT_CHECKLIST,
  UPDATE_JOB_CHECKLIST_ITEM
} from '.'
import {errorHandler} from '../../utils'

export function getCheckList(
  page: number = 1,
  tableLimit: number = 10,
  successCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_CHECKLIST.LOADING})

      res = await api<any>(
        `${APIS.checklists}?page=${page}&limit=${tableLimit}`,
        'GET'
      )

      const {
        success,
        data: {message, data: checkLists}
      } = res.data
      if (success) {
        // console.log('checklists in action: ', checkLists)
        dispatch({type: GET_CHECKLIST.SUCCESS, payload: checkLists})

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_CHECKLIST.ERROR, payload: message})
        return 0
      }
    } catch (message) {
      dispatch({type: GET_CHECKLIST.ERROR, payload: message})
      return 0
    }
  }
}

export function getJobCheckList(projectId: number, successCallback?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_JOB_CHECKLIST.LOADING})

      res = await api<any>(`${APIS.job_checklists + '/' + projectId}`, 'GET')

      const {
        success,
        data: {message, data: checkLists}
      } = res.data

      if (success) {
        dispatch({type: GET_JOB_CHECKLIST.SUCCESS, payload: checkLists})

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_JOB_CHECKLIST.ERROR, payload: message})
        return 0
      }
    } catch (message) {
      dispatch({type: GET_JOB_CHECKLIST.ERROR, payload: message})
      return 0
    }
  }
}

export function getJobCheckListById(
  checklistId: number,
  successCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_JOB_CHECKLIST_BY_ID.LOADING})

      res = await api<any>(`${APIS.get_checklists + '/' + checklistId}`, 'GET')

      const {
        success,
        data: {message, data: checkList}
      } = res.data

      if (success) {
        dispatch({type: GET_JOB_CHECKLIST_BY_ID.SUCCESS, payload: checkList})

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_JOB_CHECKLIST_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (message) {
      dispatch({type: GET_JOB_CHECKLIST_BY_ID.ERROR, payload: message})
      return 0
    }
  }
}

export function getSiteVisitCheckList(
  projectId: number,
  siteVisitId: number,
  successCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_VISIT_CHECKLIST.LOADING})

      res = await api<any>(
        APIS.get_site_visit_checklists
          .replace(':projectId', String(projectId))
          .replace(':siteVisitId', String(siteVisitId)),
        'GET'
      )

      const {
        success,
        data: {
          message,
          data: {checklists}
        }
      } = res.data

      if (success) {
        dispatch({
          type: GET_SITE_VISIT_CHECKLIST.SUCCESS,
          payload: {checklists}
        })

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_SITE_VISIT_CHECKLIST.ERROR, payload: message})
        return 0
      }
    } catch (message) {
      dispatch({type: GET_SITE_VISIT_CHECKLIST.ERROR, payload: message})
      return 0
    }
  }
}

export function addCheckList(body: any, successCallback?: any, toast?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_CHECKLIST.LOADING})

      res = await api<any>(`${APIS.checklists}`, 'POST', body)

      const {success, data: message} = res.data

      if (success) {
        dispatch({type: ADD_CHECKLIST.SUCCESS})

        successCallback && successCallback()
        toast.success('Checklist successfully added!')
        return 1
      } else {
        dispatch({type: ADD_CHECKLIST.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (message) {
      dispatch({type: ADD_CHECKLIST.ERROR, payload: message})
      toast.error('Error saving the checklist!')

      return 0
    }
  }
}

export function addJobCheckList(body: any, successCallback?: any, toast?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_JOB_CHECKLIST.LOADING})

      res = await api<any>(`${APIS.job_checklists}`, 'POST', body)

      const {success, data: message} = res.data

      if (success) {
        dispatch({type: ADD_JOB_CHECKLIST.SUCCESS})

        successCallback && successCallback()
        toast.success('Checklist successfully added!')
        return 1
      } else {
        dispatch({type: ADD_JOB_CHECKLIST.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (message) {
      dispatch({type: ADD_JOB_CHECKLIST.ERROR, payload: message})
      toast.error('Error saving the checklist!')

      return 0
    }
  }
}

export function addSiteVisitCheckList(
  projectId: number,
  siteVisitId: number,
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_SITE_VISIT_CHECKLIST.LOADING})

      res = await api<any>(
        APIS.add_site_visit_checklists
          .replace(':projectId', String(projectId))
          .replace(':siteVisitId', String(siteVisitId)),
        'POST',
        body
      )

      const {success, data: message} = res.data

      if (success) {
        dispatch({type: ADD_SITE_VISIT_CHECKLIST.SUCCESS})

        successCallback && successCallback()
        toast.success('Checklist successfully added!')
        return 1
      } else {
        dispatch({type: ADD_SITE_VISIT_CHECKLIST.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (message) {
      dispatch({type: ADD_SITE_VISIT_CHECKLIST.ERROR, payload: message})
      toast.error('Error saving the checklist!')

      return 0
    }
  }
}

export const updateChecklist = (
  body: any,
  successCallback?: any,
  toast?: any
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: UPDATE_CHECKLIST.LOADING})

      res = await api<any>(`${APIS.checklists}`, 'PATCH', body)

      const {success} = res.data

      if (success) {
        dispatch({type: UPDATE_CHECKLIST.SUCCESS})

        successCallback && successCallback()
        toast.success('Checklist successfully updated!')
        return 1
      } else {
        dispatch({type: UPDATE_CHECKLIST.ERROR})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (message) {
      dispatch({type: UPDATE_CHECKLIST.ERROR, payload: message})
      toast.error('Error saving the checklist!')

      return 0
    }
  }
}

export const updateJobChecklist = (
  checklistId: number,
  body: any,
  successCallback?: any,
  toast?: any
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: UPDATE_JOB_CHECKLIST.LOADING})

      res = await api<any>(
        `${APIS.update_checklists}/${checklistId}`,
        'PATCH',
        body
      )

      const {success, data: message} = res.data

      if (success) {
        dispatch({type: UPDATE_JOB_CHECKLIST.SUCCESS})

        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_JOB_CHECKLIST.ERROR})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (message) {
      dispatch({type: UPDATE_JOB_CHECKLIST.ERROR, payload: message})
      toast.error('Error saving the checklist!')

      return 0
    }
  }
}

export const checkUncheckChecklist = (
  checklistItemId: number,
  body: any,
  successCallback?: any,
  toast?: any
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({
        type: UPDATE_JOB_CHECKLIST_ITEM.LOADING,
        payload: checklistItemId
      })

      res = await api<any>(
        `${APIS.check_uncheck_checklists}/${String(checklistItemId)}`,
        'PATCH',
        body
      )

      const {success, data: message} = res.data

      if (success) {
        dispatch({type: UPDATE_JOB_CHECKLIST_ITEM.SUCCESS})

        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_JOB_CHECKLIST_ITEM.ERROR})
        errorHandler(res.data, toast)

        return 0
      }
    } catch (message) {
      dispatch({type: UPDATE_JOB_CHECKLIST_ITEM.ERROR, payload: message})
      errorHandler(res.data, toast)

      return 0
    }
  }
}

export const deleteChecklist = (
  checkListId: any,
  successCallback?: any,
  toast?: any
) => {
  return async (dispatch: any) => {
    // try {
    let res
    dispatch({type: DELETE_CHECKLIST.LOADING})
    res = await api<any>(`${APIS.checklists + '/' + checkListId}`, 'DELETE')

    if (res.data !== undefined) {
      const {success, data: message} = res.data

      if (success) {
        dispatch({type: DELETE_CHECKLIST.SUCCESS})

        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_CHECKLIST.ERROR})

        errorHandler(res.data, toast)

        return 0
      }
    }
    return 0
    // } catch ( message ) {
    //   dispatch({ type: DELETE_CHECKLIST.ERROR, payload: message })
    //   toast.error('Error deleting the checklist!')

    //   return 0
    // }
  }
}

export const archiveChecklist = (
  checkListId: any,
  successCallback?: any,
  toast?: any
) => {
  return async (dispatch: any) => {
    // try {
    let res
    dispatch({type: ARCHIVE_CHECKLIST.LOADING})
    res = await api<any>(
      `${APIS.checklists + '/' + checkListId + '/archive'}`,
      'PATCH'
    )

    if (res.data !== undefined) {
      const {success, data: message} = res.data

      if (success) {
        dispatch({type: ARCHIVE_CHECKLIST.SUCCESS})

        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ARCHIVE_CHECKLIST.ERROR})

        errorHandler(res.data, toast)

        return 0
      }
    }
    return 0
    // } catch ( message ) {
    //   dispatch({ type: ARCHIVE_CHECKLIST.ERROR, payload: message })
    //   toast.error('Error archiving the checklist!')

    //   return 0
    // }
  }
}

export const restoreChecklist = (
  checkListId: any,
  successCallback?: any,
  toast?: any
) => {
  return async (dispatch: any) => {
    // try {
    let res
    dispatch({type: RESTORE_CHECKLIST.LOADING})
    res = await api<any>(
      `${APIS.checklists + '/' + checkListId + '/restore'}`,
      'PATCH'
    )

    if (res.data !== undefined) {
      const {success, data: message} = res.data

      if (success) {
        dispatch({type: RESTORE_CHECKLIST.SUCCESS})

        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: RESTORE_CHECKLIST.ERROR})

        errorHandler(res.data, toast)

        return 0
      }
    }
    return 0
    // } catch ( message ) {
    //   dispatch({ type: RESTORE_CHECKLIST.ERROR, payload: message })
    //   toast.error('Error restoring the checklist!')
    //   return 0
    // }
  }
}
