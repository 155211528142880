import { useEffect, useRef } from 'react'

export const useFocus = () => {
  const elRef = useRef(null)
  const setFocus = () => {
    elRef.current && elRef.current.focus()
  }

  return { elRef, setFocus }
}

export const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}
