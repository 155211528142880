import {RxCross2} from 'react-icons/rx'

interface ClearableChipProps {
  filteredValue?: string
  isClearable?: boolean
  handleClearValue?: () => void
}

export const ClearableChip = ({
  filteredValue,
  isClearable,
  handleClearValue
}: ClearableChipProps) => {
  return !!filteredValue ? (
    <div className="relative flex flex-row items-center justify-start rounded-full gap-8 px-12 py-4 bg-blue-400 w-[max-content] max-w-full">
      {isClearable && !!filteredValue && (
        <div
          className="p-[2px] rounded-full bg-white cursor-pointer hover:bg-blue-100"
          onClick={handleClearValue}
        >
          <RxCross2 size={12} color="black" />
        </div>
      )}
      <div className="text-white font-normal text-sm">{filteredValue}</div>
    </div>
  ) : (
    <></>
  )
}
