import * as React from 'react'
import {
  useOutsideClick,
  AnimatedBlock,
  useMountedValue,
  AnimationConfigUtils,
} from 'react-ui-animate'

import {DropdownProps, placementType} from './dropdown.type'
import {ReactPortal} from '../reactPortal'

export const DropdownWithPortal = ({
  children,
  // trigger = () => <></>,
  triggerElement,
  active = false,
  isAnimated = true,
  animationType = 'expand',
  style,
  placement = 'bottomleft',
  outDismiss = true,
  inDismiss = true,
  triggerToggle = true,
  containerClass,
  dropdownClass,
  dropdownStyles,
  onActiveChange,
  portalId,
}: DropdownProps & {onActiveChange?: (active: boolean) => void}) => {
  const [scrollTop, setScrollTop] = React.useState<number>(0)

  const containerRef: React.RefObject<HTMLDivElement> =
    React.useRef<HTMLDivElement>(null)

  const menuRef: React.RefObject<HTMLDivElement> =
    React.useRef<HTMLDivElement>(null)

  const [dropdownActive, setDropdownActive] = React.useState<boolean>(active)

  React.useEffect(() => {
    onActiveChange && onActiveChange(dropdownActive)
  }, [dropdownActive])

  const config = isAnimated
    ? {...AnimationConfigUtils.ELASTIC, duration: 100}
    : {duration: 0}

  const dropdownAnimation = useMountedValue(dropdownActive, {
    from: 0,
    enter: 1,
    exit: 0,
    config,
  })

  React.useEffect(() => {
    let isActive = true

    window.addEventListener(
      'scroll',
      () => {
        isActive && setScrollTop(window?.scrollY)
      },
      {passive: true},
    )

    return () => {
      window.removeEventListener('scroll', () => {})
      isActive = false
    }
  }, [scrollTop])

  // Open dropdown method
  const openDropdown: () => void = React.useCallback(() => {
    if (!dropdownActive) {
      setDropdownActive(true)
    }
  }, [dropdownActive])

  // Open dropdown method
  const closeDropdown: () => void = () => {
    setDropdownActive(false)
  }

  // Toggle dropdown
  const toggleDropdown: () => void = React.useCallback(() => {
    if (dropdownActive) {
      closeDropdown()
    } else {
      openDropdown()
    }
  }, [dropdownActive, openDropdown])

  const containerStyles: React.CSSProperties = {
    position: 'relative',
    display: 'inline-block',
  }

  // Direction of dropdown menu
  const getDirectionStyles: (pm: placementType) => any = (
    pm: placementType,
  ) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      // const menu = menuRef.current.getBoundingClientRect()
      switch (pm) {
        case 'bottomleft':
          return {
            transform: `translateX(${rect.left}px) translateY(${
              rect.bottom + scrollTop
            }px) translate(0%, 0%)`,
          }
        case 'bottommiddle':
          return {
            transform: `translateX(${
              rect.left + rect.width / 2
            }px) translateY(${rect.bottom + scrollTop}px) translate(0%, 0%)`,
          }
        case 'bottomright':
          return {
            transform: `translateX(${-rect.left}px) translateY(${
              rect.bottom + scrollTop
            }px) translate(-100%, 0%)`,
          }
        case 'topleft':
          return {
            transform: `translateX(${rect.left}px) translateY(${
              rect.top + scrollTop
            }px) translate(0%, -100%)`,
          }
        case 'topmiddle':
          return {
            transform: `translateX(${
              rect.left + rect.width / 2
            }px) translateY(${rect.top + scrollTop}px) translate(0%, -100%)`,
          }
        case 'topright':
          return {
            transform: `translateX(${rect.right}px) translateY(${
              rect.top + scrollTop
            }px) translate(-100%, -100%)`,
          }
      }
    }
    return {
      transform: `translateX(0px) translateY(0px) translate(0%, 0%)`,
    }
  }

  // Transform origin of dropdown animation
  const getTransformOrigin: (pm: placementType) => React.CSSProperties = (
    pm: placementType,
  ) => {
    switch (pm) {
      case 'bottomleft':
        return {transformOrigin: '0% 0%'}
      case 'bottommiddle':
        return {transformOrigin: '0% 0%'}
      case 'bottomright':
        return {transformOrigin: '100% 0%'}
      case 'topleft':
        return {transformOrigin: '0% 100%'}
      case 'topmiddle':
        return {transformOrigin: '0% 100%'}
      case 'topright':
        return {
          transform: 'translate(100% 100%)',
          transformOrigin: '100% 100%',
        }
    }
  }

  const dropdownElementStyles: React.CSSProperties = {}
  const dropdownMenuStyles: any = {
    zIndex: 101,
    whiteSpace: 'nowrap',
    ...getTransformOrigin(placement),
    ...getDirectionStyles(placement),
    ...style,
  }

  // DismissOnElementClick
  const onClick = !!triggerToggle ? toggleDropdown : openDropdown

  // Handle outside click on container
  useOutsideClick(containerRef, () => {
    outDismiss && closeDropdown()
  })

  return (
    <span
      ref={containerRef}
      className={containerClass}
      style={{...containerStyles}}
    >
      <span
        {...{onClick}}
        // {...trigger({
        //   active: dropdownActive,
        // })}
        style={dropdownElementStyles}
      >
        {triggerElement}
      </span>

      <ReactPortal wrapperId={portalId ?? 'dropdown-portal'}>
        {dropdownAnimation((animation, mounted) => {
          return (
            mounted && (
              <AnimatedBlock
                ref={menuRef}
                onClick={() => (inDismiss ? closeDropdown() : false)}
                style={{
                  pointerEvents: dropdownActive ? 'auto' : 'none',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  justifyItems: 'center',
                  alignItems: 'flex-start',
                  alignContent: 'center',
                  flexDirection: 'column',
                  position: 'absolute',
                  opacity: animation.value,
                  ...dropdownMenuStyles,
                  ...dropdownStyles,
                }}
                className={dropdownClass}
              >
                {children}
              </AnimatedBlock>
            )
          )
        })}
      </ReactPortal>
    </span>
  )
}
