import {useEffect, useRef, useState} from 'react'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  PixelCrop,
  Crop
} from 'react-image-crop'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth, useNavigation} from 'react-auth-navigation'

import {Button, ColorPicker, Modal} from 'app/common'
import {canvasPreview, getTextColor, useDebounceEffect} from 'utils'
import {
  getCompanyUserByIdAction,
  getUserById,
  updateProfilePicOfOtherUser,
  updateUserProfilePic,
  userAuthAction
} from 'redux-src'

import 'react-image-crop/dist/ReactCrop.css'
import {getAssetUrl} from 'helpers'

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 50
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export const AvatarModal = ({
  isAvatarShow,
  setAvatarShow,
  initialChar,
  editMode
}: any) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const {toast} = useAuth()

  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const dispatch = useDispatch()

  const {userDetails}: RT.UserReduxType = useSelector(
    (state: any) => state.user
  )

  const [crop, setCrop] = useState<Crop>()
  const [cropWindow, setCropWindow] = useState(false)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  const [avatar, setAvatar] = useState<string>(null)
  const [srcImage, setSrcImage] = useState(null)

  const [avatarColorCode, setAvatarColorCode] = useState('#7921b1')

  const {params} = useNavigation()
  const {id} = params as any

  const handelAvatarColorChange = (colorCode: any) => {
    setAvatarColorCode(colorCode)
  }

  const avatarHandler = (e: any) => {
    setCrop(undefined)
    const reader = new FileReader()

    reader.onload = () => {
      if (reader.readyState === 2) {
        setCropWindow(true)
        setSrcImage(reader.result)
      }
    }

    reader.readAsDataURL(e.target.files[0])
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const {width, height} = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
  }

  function dataURItoFile(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1])
    else byteString = unescape(dataURI.split(',')[1])

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new File([ia], userDetails?.companyName ?? 'user-profile', {
      type: mimeString
    })
  }

  const saveCroppedImage = () => {
    const base64Image = previewCanvasRef.current?.toDataURL('image/jpeg', 1)

    setAvatar(base64Image)

    setCropWindow(false)
  }

  const uploadCroppedImage = () => {
    const userProfilePicFormData = new FormData()
    const base64Image = previewCanvasRef.current?.toDataURL('image/png', 1)

    const newImgFile = dataURItoFile(base64Image)

    userProfilePicFormData.append('files', newImgFile)

    if (!!editMode) {
      dispatch(
        updateProfilePicOfOtherUser(id, userProfilePicFormData, () => {
          userDetails?.clientId && dispatch(getUserById(userDetails?.clientId))
          dispatch(getCompanyUserByIdAction(id, () => {}))

          setAvatarShow(false)
        })
      )
    } else {
      dispatch(
        updateUserProfilePic(
          userProfilePicFormData,
          () => {
            userDetails?.clientId &&
              dispatch(getUserById(userDetails?.clientId))
            setAvatarShow(false)
          },
          toast
        )
      )
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  useEffect(() => {
    if (userDetails?.appUser?.asset_details) {
      setAvatar(getAssetUrl(userDetails?.appUser?.asset_details))
    }
  }, [userDetails?.appUser?.asset_details])

  return (
    <Modal
      isActive={isAvatarShow}
      toggleModal={setAvatarShow}
      title="Change Avatar"
    >
      <div className="h-[400px] flex flex-col justify-between items-center">
        <canvas ref={previewCanvasRef} className="w-[300px] h-[300px] hidden" />
        {cropWindow ? (
          <div className="mt-20">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              aspect={1}
              onComplete={(c) => setCompletedCrop(c)}
              circularCrop
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={srcImage}
                onLoad={onImageLoad}
                width={300}
                height={300}
              />
            </ReactCrop>
          </div>
        ) : (
          <div className="flex h-full flex-col justify-center items-center my-auto">
            <div className="relative w-[100px]">
              {avatar ? (
                <div
                  style={{
                    border: '7px solid ' + avatarColorCode
                  }}
                  className="rounded-full object-cover w-[100px] h-[100px] overflow-hidden flex justify-center items-center p-0"
                >
                  <img
                    src={avatar}
                    alt="User Avatar"
                    className="scale-[110%]"
                  />
                </div>
              ) : (
                <div className="p-[48px] mr-10 inline-flex  relative">
                  <span
                    style={{
                      backgroundColor: avatarColorCode,
                      color: getTextColor(avatarColorCode)
                    }}
                    className="p-[48px] text-white rounded-full absolute text-[30px] font-bold inset-0 flex items-center justify-center"
                  >
                    {initialChar}
                  </span>
                </div>
              )}
              <div className="absolute right-8 bottom-2">
                <ColorPicker
                  invert
                  onChange={handelAvatarColorChange}
                  value={avatarColorCode}
                />
              </div>
            </div>
            <div className="mt-20 mb-10 p-10 transition duration-500 ease-in-out cursor-pointer text-red-300 hover:text-red-400">
              {avatar && (
                <div onClick={() => setAvatar(null)}>Remove Photo</div>
              )}
            </div>
            <div className="my-10">
              <label
                htmlFor="userAvatar"
                className="transition duration-500 ease-in-out cursor-pointer py-8 px-20 border-2 rounded-sm border-blue-300 text-blue-300 font-bold hover:bg-blue-300 hover:text-white"
              >
                Choose a photo
              </label>
              <input
                className="hidden"
                type="file"
                id="userAvatar"
                name="userAvatar"
                accept="image/*"
                multiple={false}
                onChange={avatarHandler}
                title="Choose a photo"
              />
            </div>
          </div>
        )}

        <div className="flex w-full justify-end px-16 py-10 border-t-1 border-gray-200">
          <Button
            title={cropWindow ? 'Back' : 'Cancel'}
            buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
            size="sm"
            type="button"
            onClick={() =>
              cropWindow ? setCropWindow(false) : setAvatarShow(false)
            }
          />
          <div className="ml-16">
            <Button
              title={cropWindow ? 'Crop' : 'Save'}
              size="sm"
              type="submit"
              onClick={cropWindow ? saveCroppedImage : uploadCroppedImage}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
