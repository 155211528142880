import {Button, DateInput, Input, Modal, Textarea} from 'app/common'
import moment from 'moment'
import React from 'react'
import {FaHourglassStart} from 'react-icons/fa'
import {useFormInput} from 'use-form-input'

interface InvoiceBasicDetailModalProps {
  openModal: boolean
  setOpenMOdal: React.Dispatch<React.SetStateAction<boolean>>
  callbackFunction?: (body: any) => void
}

export const InvoiceBasicDetailModal = ({
  openModal,
  setOpenMOdal,
  callbackFunction,
}: InvoiceBasicDetailModalProps) => {
  const [
    data,
    {onChange: onChangeHandler, setValue, modified, errors, onSubmit},
  ] = useFormInput(
    {
      title: undefined,
      description: undefined,
      dueDate: moment(Date.now()).format('YYYY-MM-DD'),
      footerNotes: undefined,
    },
    (data) => {
      const initialBody = {
        title: data?.title,
        dueDate: data?.dueDate,
        description: !!data?.description ? data?.description : undefined,
        footerNotes: data?.footerNotes,
      }
      callbackFunction(initialBody)
    },
    (data) => {
      const errors: any = {}

      if (data?.title?.length === 0) {
        errors.title = 'Title cannot be empty !!'
      }

      // if (data?.dueDate?.length === 0) {
      //   errors.title = 'Title cannot be empty !!'
      // }
      if (data?.footerNotes?.length === 0) {
        errors.footerNotes = 'Footer Notes cannot be empty !!'
      }

      return errors
    },
  )

  return (
    <Modal
      toggleModal={setOpenMOdal}
      isActive={openModal}
      size="sm"
      title={'Invoice Basic Details'}
    >
      <div className="p-20 flex flex-col gap-6 relative">
        <div className="flex gap-20">
          <Input
            name="title"
            label="Title"
            value={data?.title}
            error={modified?.title && errors?.title}
            onChange={onChangeHandler}
            placeholder="Title"
            required
          />
          <div className="">
            <DateInput
              prepend={<FaHourglassStart className="text-blue-300" />}
              name="dueDate"
              size="sm"
              placeholder="Due Date"
              label="Due Date"
              containerClass="w-full relative"
              value={data?.dueDate}
              error={modified.dueDate && errors.dueDate}
              onChange={(date) => {
                console.log({date})
                setValue('dueDate', date)
              }}
              required
            />
          </div>
        </div>
        <div className="">
          <Textarea
            label="Description"
            name="description"
            value={data?.description}
            onChange={onChangeHandler}
            placeholder="Description"
            className="resize-none"
            error={modified?.description && errors?.description}
          />
        </div>
        <div className="">
          <Textarea
            label="Footer Notes"
            name="footerNotes"
            value={data?.footerNotes}
            onChange={onChangeHandler}
            placeholder="Footer Notes"
            error={modified?.footerNotes && errors?.footerNotes}
            className="resize-none"
            required
          />
        </div>
        <div className="border-t-1 border-gray-200 "></div>

        <div className="flex justify-end px-16 py-10">
          <Button
            title="cancel"
            buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
            size="sm"
            type="button"
            onClick={() => {
              setOpenMOdal(false)
            }}
          />
          <div className="ml-16">
            <Button
              title="Create Invoice"
              size="sm"
              type="submit"
              onClick={(e) => {
                onSubmit(e)
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
