import { GET_ALL_SITEVISITS_WEBVIEW } from '../actions'

const initalState: any = {
  loading: true,
  siteVisits: [],
}

export function floorPlanWebViewReducer(state = initalState, action: any) {
  const { type, payload } = action

  switch (type) {
    case GET_ALL_SITEVISITS_WEBVIEW.LOADING:
      return { ...state, loading: true }
    case GET_ALL_SITEVISITS_WEBVIEW.SUCCESS:
      return {
        ...state,
        loading: false,
        siteVisits: payload,
      }
    case GET_ALL_SITEVISITS_WEBVIEW.ERROR:
      return { ...state, loading: false }

    default:
      return state
  }
}
