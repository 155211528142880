import {useCallback, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {getFormElementStyle} from 'helpers'
import {alterFormSettings} from 'redux-src'
import {formElementsConstants} from 'app/pages/settings/pages/forms/constants'

export const useIndividualFormElement = (
  elementId: number,
  type: 'builder' | 'preview' | 'web view' = 'builder'
) => {
  const {forms}: RT.FormBuilderReduxType = useSelector(
    (state: any) => state.formBuilder
  )

  const dispatch = useDispatch()

  const individualElement = useMemo(() => {
    const tempForms = [...forms]

    return tempForms?.find((element) => element?.id === elementId)
  }, [elementId, forms])

  const defaultElement = useMemo(() => {
    return formElementsConstants.find(
      (el) => el.data_type === individualElement?.formType
    )?.behaviour
  }, [individualElement?.formType])

  const individualStyles = getFormElementStyle(individualElement, type)

  const alterElementSettings = useCallback(
    (formSettings: {
      settings?: {
        hideDescription?: boolean
        hideLabel?: boolean
        markAsRequired?: boolean
        numbered?: boolean
      }
      size?: 'large' | 'medium' | 'small'
      alignment?: 'left' | 'center' | 'right'
      elementSize?: '100%' | '50%' | '33%' | '66%'
      visibleTo?: string[]
      editableBy?: string[]
      layout?: ReactGridLayout.Layout
      asset_details?: Api.AssetDetailsType
    }) => {
      dispatch(alterFormSettings(elementId, formSettings))
    },
    [dispatch, elementId]
  )

  return {
    individualElement,
    individualStyles,
    alterElementSettings,
    defaultElement
  }
}
