import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useNavigation } from 'react-auth-navigation'

import { AddLabourGroup, LabourElementTable } from '../component'
import { Button } from 'app/common'

import { useDispatch, useSelector } from 'react-redux'
import { getLabourGroupByIdAction } from 'redux-src'

export const LabourElementGroupPage = () => {
  const [addLabourGroupModal, setAddLabourGroupModal] = useState<boolean>(false)
  const [groupLabourList, setGroupLabourList] = useState<any>()

  const { individualLabourGroup }: RT.LabourElementReduxType = useSelector(
    (state: any) => state.labourElements
  )

  const {
    navigation: { navigate },
    params: { labourElementGroupId },
  }: any = useNavigation()

  const dispatch = useDispatch()

  const handleModalOpen = () => {
    setAddLabourGroupModal((prev) => !prev)
  }

  const getLabourGroupById = useCallback(() => {
    if (!!!individualLabourGroup) {
      return []
    }

    const labourElementDetails = individualLabourGroup?.labour_elements?.map(
      (labourItem: any) => {
        return {
          id: labourItem.id,
          name: labourItem.name,
          timeMins: labourItem.time_mins,
          unitName: labourItem.unit_name,
          numberPerHr: labourItem.number_per_hr,
        }
      }
    )

    return setGroupLabourList(labourElementDetails)
  }, [individualLabourGroup])

  useEffect(() => {
    getLabourGroupById()
  }, [getLabourGroupById])

  useEffect(() => {
    dispatch(getLabourGroupByIdAction(labourElementGroupId, () => {}))
  }, [dispatch, labourElementGroupId])

  return (
    <div className="min-h-[85vh] py-10 flex flex-col gap-6">
      <div className="border border-b-1 pb-4 border-gray-200 flex flex-col gap-10">
        <div
          className="flex text-blue-300 items-center gap-6 pb-2 cursor-pointer "
          onClick={() => navigate('/settings/labour-element-management')}
        >
          <span>
            <AiOutlineArrowLeft />
          </span>
          <span>Back to labour group list</span>
        </div>
        <div className=" flex justify-between text-lg font-bold text-black">
          <span>
            {individualLabourGroup && individualLabourGroup?.group_details.name}
          </span>
          <div className="pb-4">
            <Button
              size="sm"
              title="Update labour element group"
              onClick={handleModalOpen}
              className="text-md"
            />
          </div>
        </div>
      </div>
      <div className="text-gray-400 my-10">
        {individualLabourGroup &&
          individualLabourGroup?.group_details.description}
      </div>
      <LabourElementTable groupLabourList={groupLabourList} isGroup />

      <AddLabourGroup
        addLabourGroupModal={addLabourGroupModal}
        setAddLabourGroupModal={setAddLabourGroupModal}
        mode="update"
        labourElementGroupData={{
          id: individualLabourGroup?.group_details?.id,
          groupName: individualLabourGroup?.group_details.name ?? 'N/A',
          description:
            individualLabourGroup?.group_details.description ?? 'N/A',
          labourElements:
            individualLabourGroup?.labour_elements?.map((el) => ({
              id: el.id,
              value: el.name,
              label: el.name,
              disabled: true,
            })) ?? [],
        }}
      />
    </div>
  )
}
