import {
  ADD_NEW_LINE_ITEM_MANUAL,
  GET_ACCEPTED_QUOTE_LINE_ITEM,
  GET_LINE_ITEM_LABOUR_ELEMENTS,
  GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT,
  GET_QUOTE_DETAILS,
  GET_QUOTE_OVERVIEW,
  VOID_QUOTE
} from './actionTypes.action'
import {toast} from 'app/common'

import {errorHandler} from '../../utils'
import {
  ACCEPT_QUOTE,
  ADD_NEW_LINE_ITEM,
  ADD_NEW_QUOTE,
  ADD_NEW_QUOTE_ITEM,
  DECLINE_QUOTE,
  DELETE_LINE_ITEM,
  DELETE_QUOTE_ITEM,
  GET_QUOTES,
  GET_QUOTE_ITEMS,
  MARK_AS_SENT_QUOTE,
  PUBLISH_AND_MARK_AS_SENT_QUOTE,
  PUBLISH_QUOTE,
  UNACCEPT_QUOTE,
  UNDECLINE_QUOTE,
  UPDATE_LINE_ITEM
} from '.'
import {api, APIS} from '../../config'

export function getQuotesAction(
  projectId: number,
  page: number = 1,
  tableLimit: number = 10
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_QUOTES.LOADING})
      res = await api<Api.Base<Api.QuoteVersionList>>(
        `${APIS.projects}/${projectId}/quotes?page=${page}&limit=${tableLimit}`
      )
      const {
        success,
        data: {data: quotes, message}
      } = res.data

      if (success) {
        dispatch({type: GET_QUOTES.SUCCESS, payload: quotes})
        return 1
      } else {
        dispatch({type: GET_QUOTES.ERROR, payload: message})
        return 0
      }
    } catch (error) {
      dispatch({type: GET_QUOTES.ERROR})
      return 1
    }
  }
}

export function getQuoteOverviewAction(
  projectId: number,
  quoteId: number | string,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_QUOTE_OVERVIEW.LOADING})
      res = await api<Api.Base<Api.QuoteOverview>>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/overview`
      )
      const {
        success,
        data: {data: quoteDetails, message}
      } = res.data

      if (success) {
        dispatch({type: GET_QUOTE_OVERVIEW.SUCCESS, payload: quoteDetails})
        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_QUOTE_OVERVIEW.ERROR, payload: message})
        return 0
      }
    } catch (error) {
      dispatch({type: GET_QUOTE_OVERVIEW.ERROR})
      return 1
    }
  }
}

export function getQuoteItem(projectId?: any, quoteId?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_QUOTE_ITEMS.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}`
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: GET_QUOTE_ITEMS.SUCCESS, payload: data})

        // successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_QUOTE_ITEMS.ERROR, payload: message})
        return 0
      }
    } catch (error) {
      dispatch({type: GET_QUOTE_ITEMS.ERROR})
      return 1
    }
  }
}

export function getQuoteBasicDetailsAction(
  projectId: number,
  quoteId: number | string,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_QUOTE_DETAILS.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/details`
      )
      const {
        success,
        data: {data: quoteDetails, message}
      } = res.data

      if (success) {
        dispatch({type: GET_QUOTE_DETAILS.SUCCESS, payload: quoteDetails})
        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_QUOTE_DETAILS.ERROR, payload: message})
        return 0
      }
    } catch (error) {
      dispatch({type: GET_QUOTE_DETAILS.ERROR})
      return 1
    }
  }
}

export function createNewVersionAction(
  projectId: number,
  quoteId: number,
  // body: any,
  successCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_NEW_QUOTE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/new-version`,
        'POST'
        // body
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: ADD_NEW_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ADD_NEW_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        return 0
      }
    } catch (error) {
      dispatch({type: ADD_NEW_QUOTE.ERROR})
      toast.error('Error creating new version!')
      return 1
    }
  }
}

export function createNewQuoteItemAction(
  projectId: number,
  quoteId: number,
  body: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_NEW_QUOTE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}${APIS.quotes}/${quoteId}/items`,
        'POST',
        body
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: ADD_NEW_QUOTE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ADD_NEW_QUOTE_ITEM.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: ADD_NEW_QUOTE_ITEM.ERROR})
      toast.error('Error creating new quote item!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateQuoteSectionTitleAction(
  projectId: number,
  quoteId: number,
  quoteItemId: number,
  body: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_NEW_QUOTE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/items/${quoteItemId}`,
        'PATCH',
        body
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: ADD_NEW_QUOTE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ADD_NEW_QUOTE_ITEM.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: ADD_NEW_QUOTE_ITEM.ERROR})
      toast.error('Error updating section title!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function deleteQuoteItemAction(
  projectId: number | string,
  quoteId: number | string,
  quoteItemId: number | string,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_QUOTE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/items/${quoteItemId}`,
        'DELETE'
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: DELETE_QUOTE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_QUOTE_ITEM.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: DELETE_QUOTE_ITEM.ERROR})
      toast.error('Error deleting quote/section item!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function createNewLineItemAction(
  projectId: number | string,
  quoteId: number | string,
  quoteItemId: number | string,
  itemId: number | string,
  successCallback?: () => void,
  onErrorCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_NEW_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/items/${quoteItemId}/line-items/item/${itemId}`,
        'POST'
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: ADD_NEW_LINE_ITEM.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ADD_NEW_LINE_ITEM.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: ADD_NEW_LINE_ITEM.ERROR})
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function createNewLineItemManually(
  projectId: number | string,
  quoteId: number | string,
  quoteItemId: number | string,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_NEW_LINE_ITEM_MANUAL.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/items/${quoteItemId}/line-items/manual`,
        'POST',
        body
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: ADD_NEW_LINE_ITEM_MANUAL.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ADD_NEW_LINE_ITEM_MANUAL.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: ADD_NEW_LINE_ITEM_MANUAL.ERROR})
      toast.error('Error Creating new line item !')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function deleteLineItemAction(
  projectId: number | string,
  quoteId: number | string,
  quoteItemId: number | string,
  quoteLineItemId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/items/${quoteItemId}/line-items/${quoteLineItemId}`,
        'DELETE'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: DELETE_LINE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_LINE_ITEM.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: DELETE_LINE_ITEM.ERROR})
      toast.error('Error deleting version!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function voidQuoteAction(
  projectId: number | string,
  quoteId: number | string,
  successCallback?: () => void,
  onErrorCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: VOID_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/void`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: VOID_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: VOID_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: VOID_QUOTE.ERROR})
      toast.error('Error void quote!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateLineItemAction(
  projectId: number | string,
  quoteId: number | string,
  quoteItemId: number | string,
  quoteLineItemId: number | string,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/items/${quoteItemId}/line-items/${quoteLineItemId}`,
        'PATCH',
        body
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: UPDATE_LINE_ITEM.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_LINE_ITEM.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (error) {
      dispatch({type: UPDATE_LINE_ITEM.ERROR})
      toast.error('Error updating the quote line item !')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function publishQuoteAction(
  projectId: any,
  quoteId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: PUBLISH_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/publish`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: PUBLISH_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: PUBLISH_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: PUBLISH_QUOTE.ERROR})
      toast.error('Error publishing this version!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function markAsSentQuoteAction(
  projectId: any,
  quoteId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MARK_AS_SENT_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/mark-as-sent`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: MARK_AS_SENT_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: MARK_AS_SENT_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: MARK_AS_SENT_QUOTE.ERROR})
      toast.error('Error marking as sent for this version!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function publishAndMarkAsSentQuoteAction(
  projectId: any,
  quoteId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: PUBLISH_AND_MARK_AS_SENT_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/publish-and-mark-as-sent`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({
          type: PUBLISH_AND_MARK_AS_SENT_QUOTE.SUCCESS,
          payload: data
        })
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: PUBLISH_AND_MARK_AS_SENT_QUOTE.ERROR,
          payload: message
        })
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: PUBLISH_AND_MARK_AS_SENT_QUOTE.ERROR})
      toast.error('Error publishing and marking as sent!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function acceptQuoteAction(
  projectId: any,
  quoteId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ACCEPT_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/accept`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: ACCEPT_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ACCEPT_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: ACCEPT_QUOTE.ERROR})
      toast.error('Error accepting this version!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function changeAcceptedQuoteAction(
  projectId: any,
  quoteId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ACCEPT_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/change-accepted-quote`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: ACCEPT_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ACCEPT_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: ACCEPT_QUOTE.ERROR})
      toast.error('Error changing accepted version!')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function declineQuoteAction(
  projectId: any,
  quoteId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DECLINE_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/decline`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: DECLINE_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DECLINE_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: DECLINE_QUOTE.ERROR})
      toast.error('Error declining this version !')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function unacceptQuoteAction(
  projectId: any,
  quoteId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UNACCEPT_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/unaccept`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: UNACCEPT_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UNACCEPT_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: UNACCEPT_QUOTE.ERROR})
      toast.error('Error updating new version !')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function undeclineQuoteAction(
  projectId: any,
  quoteId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UNDECLINE_QUOTE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/undecline`,
        'PATCH'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: UNDECLINE_QUOTE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UNDECLINE_QUOTE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (error) {
      dispatch({type: UNDECLINE_QUOTE.ERROR})
      toast.error('Error Creating new version !')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

//MARK: Get accepted quote line item list

export function getAcceptedQuoteLineItemList(
  projectId: number,
  quoteId: number | string,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ACCEPTED_QUOTE_LINE_ITEM.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/line-item-list`
      )
      const {
        success,
        data: {data: quoteLineItemList, message}
      } = res.data
      if (success) {
        dispatch({
          type: GET_ACCEPTED_QUOTE_LINE_ITEM.SUCCESS,
          payload: quoteLineItemList
        })
        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_ACCEPTED_QUOTE_LINE_ITEM.ERROR, payload: message})
        return 0
      }
    } catch (error) {
      dispatch({type: GET_ACCEPTED_QUOTE_LINE_ITEM.ERROR})
      return 1
    }
  }
}

//MARK: GET Line Item Labour Element List

export function getLineItemLabourElementList(
  projectId: number,
  quoteId: number,
  quoteItemId: number,
  quoteLineItemId: number,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LINE_ITEM_LABOUR_ELEMENTS.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/quotes/${quoteId}/items/${quoteItemId}/line-items/${quoteLineItemId}/labour-elements`
      )
      const {
        success,
        data: {data: quoteLineItemLabourElements, message}
      } = res.data
      if (success) {
        dispatch({
          type: GET_LINE_ITEM_LABOUR_ELEMENTS.SUCCESS,
          payload: quoteLineItemLabourElements
        })
        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_LINE_ITEM_LABOUR_ELEMENTS.ERROR, payload: message})
        return 0
      }
    } catch (error) {
      dispatch({type: GET_LINE_ITEM_LABOUR_ELEMENTS.ERROR})
      return 1
    }
  }
}

//MARK: Quote material purchase order of projects

export function getMaterialPurchaseOrderOfProjects(
  page: number,
  limit: number,
  projectId: number,
  supplierId: number,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT.LOADING})
      res = await api<Api.Base<any>>(
        `${
          APIS.projects
        }/${projectId}/purchase-orders/material?page=${page}&limit=${limit}${
          supplierId ? `&supplierId=${supplierId}` : ''
        }`
      )
      const {
        success,
        data: {
          data: {rows, total},
          message
        }
      } = res.data
      if (success) {
        dispatch({
          type: GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT.SUCCESS,
          payload: {rows, total}
        })
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT.ERROR,
          payload: message
        })
        return 0
      }
    } catch (error) {
      dispatch({type: GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT.ERROR})
      return 1
    }
  }
}
