import {MouseEvent, useCallback, useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'
import {useDispatch} from 'react-redux'

import {DescriptionBox} from 'app/components'
import {Button, Input} from 'app/common'

import {getCompanyDetails, updateCompanyFinancialDetails} from 'redux-src'

export const FinancialSection = () => {
  const dispatch = useDispatch()

  const [isEdit, setEdit] = useState(false)

  const {currencySymbol} = useAuth()

  const {companyDetails: company} = useAuth()
  const companyDetails: Api.CompanyDetails = company

  const [financialSettings, {setValue, onChange, onSubmit}] = useFormInput(
    {
      netMargin: 0,
      expectedTurnover: 0,
      overheadsPerYear: 0,
      netCost: 0,
      defaultTaxPercentage: 0,
      defaultDiscountPercentage: 0,
      dntNetCost: 0,
    },
    (data) => {
      const finaicialDetailsBody = {
        nmToSalesPercent: +(data?.netMargin ?? 0),
        expectedTo: +(data?.expectedTurnover ?? 0),
        ohPerYear: +(data?.overheadsPerYear ?? 0),
        netCost: +(data?.netCost ?? 0),
        defaultTaxPercent: +(data?.defaultTaxPercentage ?? 0),
        defaultDiscountPercent: +(data?.defaultDiscountPercentage ?? 0),
        dntNetCost: +(data?.dntNetCost ?? 0),
      }

      dispatch(
        updateCompanyFinancialDetails(finaicialDetailsBody, () => {
          dispatch(getCompanyDetails())
          setEdit(false)
        }),
      )
    },
    (data) => {
      const errors: any = {}

      return errors
    },
  )

  const setCompanyDetails = useCallback(() => {
    setValue(
      'netMargin',
      companyDetails?.company_details?.nm_to_sales_percent ?? 0,
    )
    setValue(
      'overheadsPerYear',
      companyDetails?.company_details?.oh_per_year ?? 0,
    )
    setValue(
      'expectedTurnover',
      companyDetails?.company_details?.expected_to ?? 0,
    )
    setValue('dntNetCost', companyDetails?.company_details?.dnt_net_cost ?? 0)
    setValue('netCost', companyDetails?.company_details?.net_cost ?? 0)
    setValue(
      'defaultTaxPercentage',
      companyDetails?.company_details?.default_tax_percent ?? 0,
    )
    setValue(
      'defaultDiscountPercentage',
      companyDetails?.company_details?.default_discount_percent ?? 0,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyDetails?.company_details?.default_discount_percent,
    companyDetails?.company_details?.default_tax_percent,
    companyDetails?.company_details?.dnt_net_cost,
    companyDetails?.company_details?.expected_to,
    companyDetails?.company_details?.net_cost,
    companyDetails?.company_details?.nm_to_sales_percent,
    companyDetails?.company_details?.oh_per_year,
  ])

  useEffect(() => {
    setCompanyDetails()
  }, [setCompanyDetails])

  const doSetEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setEdit(true)
  }

  return (
    <form onSubmit={onSubmit} id="financial-settings">
      <DescriptionBox
        bodyClassName="p-0"
        title={
          <div className="flex items-center justify-between">
            <div className="font-bold text-base ml-4">
              <span className="">Financial</span>
            </div>
            <div className="text-sm">
              {!isEdit ? (
                <Button
                  title={'Edit Settings'}
                  size="xs"
                  buttonColor="bg-white text-black  hover:bg-gray-300 hover:text-white"
                  className="border-1 border-black hover:border-gray-300"
                  onClick={doSetEdit}
                />
              ) : (
                <Button
                  form="financial-settings"
                  type="submit"
                  title={'Save Changes'}
                  size="xs"
                />
              )}
            </div>
          </div>
        }
      >
        <div className={!isEdit ? 'form-disable p-24' : 'p-24'}>
          <h3 className="font-bold text-md mb-16">Financial Settings</h3>
          {/* {modified.bankAccountNumber && errors.bankAccountNumber && (
            <p className="bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
              {modified.bankAccountNumber && errors.bankAccountNumber}
            </p>
          )} */}
          <table className="w-full mb-32">
            <tbody>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Net Margin Wanted on Turnover/Sales :
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    inputType="decimal"
                    name="netMargin"
                    value={financialSettings.netMargin}
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    prepend={'%'}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>
              <tr>
                <td width="30%">
                  <div className="text-sm text-gray-400">
                    Expected Turnover :
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    inputType="decimal"
                    name="expectedTurnover"
                    value={financialSettings.expectedTurnover}
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    prepend={currencySymbol}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Overheads Per Year :
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    name="overheadsPerYear"
                    value={financialSettings.overheadsPerYear}
                    inputType="decimal"
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    prepend={currencySymbol}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">Net Cost : </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    name="netCost"
                    value={financialSettings.netCost}
                    size="sm"
                    inputType="decimal"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    prepend={currencySymbol}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Default Tax Percentage :
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    inputType="decimal"
                    name="defaultTaxPercentage"
                    value={financialSettings.defaultTaxPercentage}
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    prepend={'%'}
                  />
                </td>
                {/* <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td> */}
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Default Discount Percentage :
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    inputType="decimal"
                    name="defaultDiscountPercentage"
                    value={financialSettings.defaultDiscountPercentage}
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    prepend={'%'}
                  />
                </td>
                {/* <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td> */}
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">DNT Net Cost:</div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    inputType="decimal"
                    name="dntNetCost"
                    value={financialSettings.dntNetCost}
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    prepend={currencySymbol}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex gap-20 ">
            <div>
              <span className="text-sm font-bold text-gray-400 ">
                Calculated Profit ( % ) :{'  '}
              </span>
              <span className="text-md font-bold text-black">
                {Number(
                  companyDetails?.company_details?.predicted_profit_percent,
                )}
              </span>
            </div>
            <div>
              <span className="text-sm font-bold text-gray-400">
                Calculated OverHead ( % ) :{' '}
              </span>
              <span className="text-md font-bold text-black">
                {Number(
                  companyDetails?.company_details?.predicted_overhead_percent,
                )}
              </span>
            </div>
          </div>
        </div>
      </DescriptionBox>
    </form>
  )
}
