import {useEffect, useState} from 'react'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth} from 'react-auth-navigation'
import {FaTrash} from 'react-icons/fa'

import {
  Button,
  Checkbox,
  ConfirmationModal,
  CustomModal,
  Input,
  SelectField,
  toast,
} from 'app/common'
import {DataTable} from 'app/components'

import {
  createCompanyBankDetail,
  deleteBankDetail,
  getCompanyBankDetails,
  updateDefaultBank,
} from 'redux-src'

import type {SelectOptionReduxType} from 'redux-src/reducers/selectOptions.reducer'

// * This modal header is used for main modal
export const BankDetailModalHeader = () => {
  return (
    <div className="flex w-full h-max justify-between items-center p-0">
      <h3 className="font-bold text-md mb-16">Banking Details</h3>
    </div>
  )
}

const isEmpty = (str: string) => {
  return str === '' || str === null || str === undefined
}

export function CompanyBankDetails() {
  const dispatch = useDispatch()
  const {companyDetails: company} = useAuth()

  const companyDetails: Api.CompanyDetails = company
  const {
    companyBankDetails,
    isCompanyBankDetailLoading,
    deleteCompanyBankDetailLoading: deleteLoading,
    companyBankDetailsTotal,
  }: RT.CompanyReduxType = useSelector((state: any) => state.company)

  useEffect(() => {
    dispatch(getCompanyBankDetails(companyDetails?.company_details?.id))
  }, [companyDetails?.company_details?.id, dispatch])

  const columns: TableColumn<Api.CompanyBankDetail>[] = [
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Account Number
        </div>
      ),
      selector: (row) => row?.id,
      cell: (row) => <>{row?.account_number}</>,
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Title
        </div>
      ),
      selector: (row) => row?.id,
      cell: (row) => <>{row?.title}</>,
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Sort Code
        </div>
      ),
      selector: (row) => row?.id,
      cell: (row) => <>{row?.sort_code}</>,
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          IBAN
        </div>
      ),
      selector: (row) => row?.id,
      cell: (row) => <>{row?.iban ?? 'N/A'}</>,
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Currency
        </div>
      ),
      selector: (row) => row?.currency,
      cell: (row) => (
        <div className="flex h-[24px]">
          {row?.currency} - {row?.symbol}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Mark Default
        </div>
      ),
      cell: (row) => (
        <>
          <MarkAsDetaultCheckbox row={row} />{' '}
          {!!!row?.is_default && (
            <div className="flex justify-between items-center gap-x-10">
              <div />

              <ConfirmationModal
                onConfirmClick={(closeModalHandler) => {
                  dispatch(
                    deleteBankDetail(row?.company_id, row.id, () => {
                      dispatch(
                        getCompanyBankDetails(
                          companyDetails?.company_details?.id,
                          undefined,
                          () => {
                            closeModalHandler()
                          },
                        ),
                      )
                    }),
                  )
                }}
                danger
                label={'Are you sure you want to delete this bank detail?'}
                displayElement={
                  <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </button>
                }
                confirmLabel="Delete Bank Detail"
                loading={deleteLoading}
              />
            </div>
          )}
        </>
      ),
    },
  ]

  return (
    <div className="px-20 py-6">
      <div className="flex justify-end py-6">
        <CustomModal
          displayElement={
            <Button
              form="labour-element-type"
              type="submit"
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer text-sm "
            >
              Add bank detail
            </Button>
          }
          title={<AddBankModalHead />}
        >
          {({onCloseModalHandler}) => (
            <AddBankModalBody onClose={onCloseModalHandler} />
          )}
        </CustomModal>
      </div>

      <DataTable
        loading={isCompanyBankDetailLoading}
        data={companyBankDetails}
        columns={columns}
        pagination
        totalRows={companyBankDetailsTotal}
        fetchData={(page, limit) =>
          dispatch(
            getCompanyBankDetails(companyDetails?.company_details?.id, {
              page,
              limit,
            }),
          )
        }
        onChangeRowsPerPage={(limit) =>
          dispatch(
            getCompanyBankDetails(companyDetails?.company_details?.id, {
              limit,
            }),
          )
        }
      />
    </div>
  )
}

function MarkAsDetaultCheckbox({row}: {row: Api.CompanyBankDetail}) {
  const dispatch = useDispatch()

  const handleChange = () => {
    if (row?.is_default) {
      toast.error('Please select another bank detail as default.')
    } else {
      dispatch(
        updateDefaultBank(row?.company_id, row?.id, () => {
          dispatch(getCompanyBankDetails(row?.company_id))
        }),
      )
    }
  }

  // row?.is_default ? (
  //   <label
  //     className={`block pl-20 cursor-pointer w-[max-content]`}
  //     style={{
  //       paddingTop: 1.5,
  //     }}
  //   ></label>
  // ) :
  return (
    <Checkbox
      inputClassName="top-fix-on-check"
      dataId={`${row?.title}-${row?.id}`}
      value={row?.is_default ?? false}
      isChecked={row?.is_default ?? false}
      onChange={() => {
        handleChange()
      }}
    />
  )
}

function AddBankModalBody({onClose}: {onClose: () => void}) {
  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )

  const {
    selectOptions: {currencyList},
  }: SelectOptionReduxType = useSelector((state: any) => state.selectOption)

  const dispatch = useDispatch()

  const [title, setTitle] = useState<string>('')
  const [acc, setAcc] = useState<string>('')
  const [currency, setCurrency] = useState<string>('')
  const [symbol, setSymbol] = useState<string>('')
  const [iban, setIban] = useState<string>('')
  const [sortCode, setSortCode] = useState<string>('')

  const [submissionError, setSubmissionError] = useState(false)

  const handleFormSubmission = () => {
    if (
      isEmpty(title) ||
      isEmpty(acc) ||
      isEmpty(currency) ||
      isEmpty(sortCode)
    ) {
      setSubmissionError(true)
    } else {
      dispatch(
        createCompanyBankDetail(
          companyDetails?.company_details?.id,

          {
            title,
            accountNumber: acc,
            currency: currency,
            isDefault: false,
            symbol: symbol,
            sortCode,
            iban: !!iban || iban?.length > 0 ? iban : undefined,
          },
          () => {
            dispatch(
              getCompanyBankDetails(
                companyDetails?.company_details?.id,
                undefined,
                () => {
                  onClose()
                },
              ),
            )
          },
        ),
      )

      setSubmissionError(false)
    }
  }

  return (
    <div className="px-24 ">
      <div className="text-sm text-gray-400 mt-10 mb-6">
        <p className="text-red-300 mb-0 text-sm">Required field are marked *</p>
        {submissionError && (
          <i className="text-red-300 mb-0 text-sm">
            Please fill all the required fields
          </i>
        )}
      </div>
      <table className="w-full mb-32">
        <tbody>
          <tr>
            <td width="40%">
              <div className="text-sm text-gray-400">
                Currency: <i className="text-red-300 mb-0 text-sm">*</i>
              </div>
            </td>
            <td width="40%" className="px-[10%]">
              <SelectField
                getOptionValue={'key'}
                getOptionLabel={'value'}
                options={currencyList}
                placeholder="Select Currency"
                onChangeValue={(data) => {
                  setCurrency(data?.value)
                  setSymbol(data?.symbol)
                }}
                formatOptionLabel={(data) => {
                  return (
                    <div>
                      {data?.symbol} {data.value}
                    </div>
                  )
                }}
              />
            </td>
          </tr>
          <tr>
            <td width="40%">
              <div className="text-sm text-gray-400">
                Title: <i className="text-red-300 mb-0 text-sm">*</i>
              </div>
            </td>
            <td width="50%" className="px-[10%]">
              <Input
                type="text"
                name="title"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
              />
            </td>
          </tr>
          <tr>
            <td width="40%">
              <div className="text-sm text-gray-400">
                Account Number: <i className="text-red-300 mb-0 text-sm">*</i>
              </div>
            </td>
            <td width="40%" className="px-[10%]">
              <Input
                type="number"
                inputType="number"
                name="bankAccountNumber"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                value={acc}
                onChange={(e) => {
                  setAcc(e.target.value)
                }}
              />
            </td>
          </tr>
          <tr>
            <td width="40%">
              <div className="text-sm text-gray-400">
                Sort Code: <i className="text-red-300 mb-0 text-sm">*</i>
              </div>
            </td>
            <td width="40%" className="px-[10%]">
              <Input
                type="number"
                inputType="number"
                name="sortCode"
                size="sm"
                value={sortCode}
                containerClass="pb-0"
                className="text-sm"
                onChange={(e) => {
                  setSortCode(e.target.value)
                }}
              />
            </td>
          </tr>
          <tr>
            <td width="40%">
              <div className="text-sm text-gray-400">IBAN:</div>
            </td>
            <td width="40%" className="px-[10%]">
              <Input
                type="text"
                inputType="alphanumeric"
                name="iban"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                value={iban}
                onChange={(e) => {
                  setIban(e.target.value)
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <NotificationFooter onSubmit={handleFormSubmission} onClose={onClose} />
    </div>
  )
}

function NotificationFooter({
  onSubmit,
  onClose,
}: {
  onSubmit: () => void
  onClose: () => void
}) {
  const {createCompanyBankDetailLoading: loading}: RT.CompanyReduxType =
    useSelector((state: any) => state.company)
  return (
    <div className="border border-t-1 border-gray-200 px-20 py-10">
      <div className="flex justify-end gap-8">
        <Button
          className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          form="labour-element-type"
          type="submit"
          className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
          onClick={onSubmit}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

function AddBankModalHead() {
  return <div className="font-bold text-md mb-16">Add new banking detail</div>
}
