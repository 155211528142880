export const getAccumulatedContacts = (
  mainContactData: any,
  billingContactData: any,
  withId?: boolean
) => {
  let defaultContact,
    billingContact = undefined

  if (mainContactData) {
    defaultContact = getRemappedContacts(mainContactData, withId)
  }

  if (billingContactData) {
    billingContact = getRemappedContacts(billingContactData, withId)
  }

  return {defaultContact, billingContact}
}

export const getRemappedContacts = (data: any, withId?: boolean) => {
  const {dynamicFields, firstName, lastName, title} = data

  let emails: Array<any> = [],
    phones: Array<any> = [],
    otherPhones: Array<any> = [],
    faxes: Array<any> = [],
    websites: Array<any> = []

  if (!!data?.email && data?.email !== '') {
    emails.push(data?.email)
  }

  dynamicFields.map((field: any): null => {
    const {contactFieldId, value} = field
    if (value) {
      if (contactFieldId === 1) {
        emails.push(value)
      }
      if (contactFieldId === 2) {
        phones.push(value)
      }
      if (contactFieldId === 3) {
        otherPhones.push(value)
      }
      if (contactFieldId === 4) {
        faxes.push(value)
      }
      if (contactFieldId === 5) {
        websites.push(value)
      }
    }
    return null
  })

  return {
    id: withId ? data?.id : undefined,
    firstname: firstName,
    lastname: lastName,
    position: title,
    emails: getValidArray(emails),
    phones: getValidArray(phones),
    otherPhones: getValidArray(otherPhones),
    fax: getValidArray(faxes),
    websites: getValidArray(websites)
  }
}

type ObjectOfContacts = {
  emails: Array<string>
  fax: Array<string>
  phones: Array<string>
  otherPhones: Array<string>
  websites: Array<string>
}

export const getContactDynamicFields = (data: ObjectOfContacts) => {
  const {emails, fax, phones, otherPhones, websites} = data

  let dynamicFieldId = 0

  let dynamicFields: Array<any> = []

  if (emails.length !== 0) {
    emails.map((email: string) =>
      dynamicFields.push({
        dynamicFieldId: ++dynamicFieldId,
        contactFieldId: 1,
        value: email
      })
    )
  }
  if (phones.length !== 0) {
    phones.map((phone: string) =>
      dynamicFields.push({
        dynamicFieldId: ++dynamicFieldId,
        contactFieldId: 2,
        value: phone
      })
    )
  }
  if (otherPhones.length !== 0) {
    otherPhones.map((otherPhone: string) =>
      dynamicFields.push({
        dynamicFieldId: ++dynamicFieldId,
        contactFieldId: 3,
        value: otherPhone
      })
    )
  }
  if (fax.length !== 0) {
    fax.map((faxItem: string) =>
      dynamicFields.push({
        dynamicFieldId: ++dynamicFieldId,
        contactFieldId: 4,
        value: faxItem
      })
    )
  }
  if (websites.length !== 0) {
    websites.map((website: string) =>
      dynamicFields.push({
        dynamicFieldId: ++dynamicFieldId,
        contactFieldId: 5,
        value: website
      })
    )
  }

  const defaultDynamicField = [
    {
      dynamicFieldId: 1,
      contactFieldId: 1,
      value: ''
    }
  ]
  return dynamicFields.length === 0 ? defaultDynamicField : dynamicFields
}

const getValidArray = (array: Array<any>) => {
  const newArray = array?.filter(
    (item) => item !== undefined || item !== '' || item !== 0 || item !== null
  )
  return newArray.length === 0 ? undefined : newArray
}

// const request = {
//   customerData: {
//     name: 'Codniv',
//     info: {
//       physicalAddress: {
//         address: 'Tokha Road, Tokha 44600, Nepal',
//         suburb: 'Basundhara',
//         city: 'Tokha',
//         state: 'Bagmati Province',
//         country: 'Nepal',
//       },
//       defaultContact: {
//         firstname: 'Something',
//         lastname: 'Contact',
//         position: 'Title',
//         emails: ['vvicran@gmail.com'],
//         phones: ['9898989898'],
//         faxes: [],
//         websites: [],
//       },
//     },
//   },
// }
