import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Auth} from 'react-auth-navigation'

import {getSupplierAction} from 'redux-src'
import {useDispatch, useSelector} from 'react-redux'
import {useDebounceValue} from 'hooks'

import {Supplier} from './components/supplierTypes'
import {SupplierTable} from './components/supplierTable'

export const SupplierPage = () => {
  return (
    <div>
      <Auth.Screens path="/settings/supplier" />
    </div>
  )
}

export const SupplierPageComp = () => {
  const [searchValue, setSearchValue] = useState<string>()

  const {supplierList: listFromApi}: RT.SupplierReduxType = useSelector(
    (state: any) => state.suppliers
  )

  const dispatch = useDispatch()

  const newSupplierList = useMemo(() => {
    const finalSupplierList = listFromApi?.map((supplier: any) => {
      return {
        id: supplier.supplier_details.id,
        supplierName: supplier.supplier_details.name
      }
    })

    return finalSupplierList
  }, [listFromApi])

  const search = useDebounceValue(searchValue)

  const getSupplierList = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getSupplierAction({page, limit, search}))
    },
    [search, dispatch]
  )

  useEffect(() => {
    getSupplierList()
  }, [getSupplierList])

  return (
    <div className="pt-4 px-10 min-h-[85vh]">
      <Supplier setSearchValue={setSearchValue} />

      <SupplierTable
        items={newSupplierList}
        getSupplierList={getSupplierList}
      />
    </div>
  )
}
