import React, {useCallback, useEffect, useMemo} from 'react'
import moment from 'moment'
import {TableColumn} from 'react-data-table-component'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'

import {DataTable} from 'app/components'
import {Modal} from 'app/common'
import {ProgressBar} from 'app/pages/jobs/pages/ticket/components/progressBar'
import {getTicketListByFormId} from 'redux-src'
import { getStatusChip } from 'app/pages/jobs/pages/ticket/components/planList/components'

interface FormTicketListModalProps {
  isModalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  projectFormId: number
}

export const FormTicketListModal = ({
  isModalVisible,
  setModalVisible,
  projectFormId
}: FormTicketListModalProps) => {
  const dispatch = useDispatch()

  const {params} = useNavigation()

  const {projectId} = params as any

  const fetchProjectTicketList = useCallback(
    (page: number, limit: number) => {
      !!projectFormId &&
        dispatch(
          getTicketListByFormId(
            projectId,
            projectFormId,
            {page, limit},
            () => {}
          )
        )
    },
    [dispatch, projectFormId, projectId]
  )

  useEffect(() => {
    fetchProjectTicketList(1, 10)
  }, [fetchProjectTicketList])

  return (
    <Modal
      isActive={isModalVisible}
      toggleModal={setModalVisible}
      title="Ticket List"
      size="lg"
    >
      <div className="flex flex-col p-16">
        <FormTicketList {...{fetchProjectTicketList}} />
      </div>
    </Modal>
  )
}

interface ProjectTicketListType {
  id: number
  title: string
  status: string
  project: string
  dueDate: string
  progressPercentage: number
  parentTicketDetails: Api.SiteVisitTicketIndividual['ticket_details']
}

const FormTicketList = ({
  fetchProjectTicketList
}: {
  fetchProjectTicketList: (page: number, limit: number) => void
}) => {
  const {
    projectTicketListByFormId,
    projectTicketListByFormIdLoading,
    projectTicketListByFormIdTotal
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const remappedTicketList = useMemo(() => {
    if (!projectTicketListByFormId) return []

    const remappedList = projectTicketListByFormId?.map(
      ({ticket_details, parent_ticket_details}) => {
        return {
          id: ticket_details?.id,
          title: ticket_details?.title,
          status: ticket_details?.status,
          dueDate: String(moment(ticket_details?.created_at)),
          project: 'Project',
          progressPercentage: ticket_details?.progress_percent,
          parentTicketDetails: parent_ticket_details
        }
      }
    )

    return remappedList
  }, [projectTicketListByFormId])

  const columns: TableColumn<ProjectTicketListType>[] = [
    {
      name: 'Name',
      selector: (row) => row.title,
      cell: (row) => {
        return (
          <div
            className={'text-blue-300 font-medium cursor-pointer'}
            onClick={() => {}}
          >
            {row.title}
          </div>
        )
      },
      sortable: true
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        return (
          <div className="flex items-center justify-between px-6 ">
            {getStatusChip(
              row.status as 'open' | 'in progress' | 'feedback' | 'completed'
            )}
          </div>
        )
      }
    },
    {
      name: 'Progress',
      selector: (row) => row.progressPercentage,
      sortable: true,
      cell: (row) => {
        return (
          <div className="w-full text-md">
            <ProgressBar progressPercentage={row.progressPercentage} />
          </div>
        )
      }
    },
    {
      name: 'Due Date',
      selector: (row) => row.dueDate,
      sortable: true,
      cell: (row) => {
        return <div>{moment(row.dueDate).format('ddd, MMM DD YYYY')}</div>
      }
    },
    {
      name: 'Parent Ticket',
      selector: (row) => row.project,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            <span className="text-blue-300 font-bold">
              {!!row.parentTicketDetails
                ? `${row.parentTicketDetails?.title}`
                : `---`}
            </span>
          </div>
        )
      }
    }
  ]

  return (
    <div className="flex flex-col items-center justify-center">
      <DataTable
        columns={columns}
        data={remappedTicketList}
        fetchData={fetchProjectTicketList}
        totalRows={projectTicketListByFormIdTotal}
        loading={projectTicketListByFormIdLoading}
        pagination
      />
    </div>
  )
}

