import {useCallback, useEffect, useMemo} from 'react'
import {TableColumn} from 'react-data-table-component'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {useSelector, useDispatch} from 'react-redux'
import {FaPen, FaTrash} from 'react-icons/fa'

import {ConfirmationModal} from 'app/common'
import {DataTable} from 'app/components'

import {
  deleteMaterialElementGroupAction,
  getMaterialElementGroupAction,
} from 'redux-src'

export const MaterialGroupTable = ({searchGroup}: {searchGroup?: string}) => {
  const {currencySymbol} = useAuth()

  const {
    materialElementGroups: groupListFromApi,
    materialElementGroupTotal: total,
    materialElementGroupsLoading: loading,
  }: RT.MaterialElementReduxType = useSelector(
    (state: any) => state.materialElement,
  )

  const page = 1
  const limit = 10

  const {
    navigation: {navigate},
  } = useNavigation()

  const dispatch = useDispatch()

  const remappedMaterialGroupData = useMemo(() => {
    return groupListFromApi?.map((group_details) => {
      return {
        id: group_details.id,
        groupName: group_details.name,
        description: group_details.description,
        totalCost: group_details.total_cost,
      }
    })
  }, [groupListFromApi])

  const getMaterialElementGroup = useCallback(
    (page?: number, limit?: number) => {
      dispatch(
        getMaterialElementGroupAction({page, limit, search: searchGroup}),
      )
    },
    [dispatch, searchGroup],
  )

  useEffect(() => {
    getMaterialElementGroup(1, 10)
  }, [getMaterialElementGroup])

  const handleMaterialGroupDelete = (groupId: number) => {
    dispatch(
      deleteMaterialElementGroupAction(groupId, () => {
        dispatch(
          dispatch(
            getMaterialElementGroupAction({page, limit, search: searchGroup}),
          ),
        )
      }),
    )
  }

  const columnData: TableColumn<{
    id: number
    groupName: string
    description: string
    totalCost: string
  }>[] = [
    {
      name: 'Name',
      selector: (row) => row.groupName,
      sortable: true,
      cell: (row) => (
        <span
          className="items-center justify-center text-blue-300  py-4 mr-1 cursor-pointer  px-6 leading-none "
          onClick={() => navigate(`/settings/material-list/${row.id}`)}
        >
          {row.groupName}
        </span>
      ),
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row.description}
        </span>
      ),
    },
    {
      name: 'Total Cost',
      selector: (row) => row.totalCost,
      sortable: true,
      cell: (row) => (
        <div className="flex justify-between">
          <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
            {currencySymbol}
            {row.totalCost}
          </span>

          <div className="flex justify-between items-center gap-x-10">
            <button
              className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
              onClick={() => {
                navigate(`/settings/material-list/${row.id}`)
              }}
            >
              <span className="text-left inline-flex cursor-pointer">
                <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
              </span>
            </button>

            <ConfirmationModal
              // loading={deleteSupplierLoading}
              onConfirmClick={(closeModalHandler) => {
                handleMaterialGroupDelete(row.id)
              }}
              danger
              label={'Are you sure you want to delete this Material Group?'}
              displayElement={
                <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                  <span className="text-left inline-flex cursor-pointer">
                    <FaTrash fill="red" size={12} />
                  </span>
                </button>
              }
              confirmLabel="Delete Material Group"
            ></ConfirmationModal>
          </div>
        </div>
      ),
    },
  ]

  return (
    <div>
      <DataTable
        columns={columnData}
        data={remappedMaterialGroupData ?? []}
        pagination
        totalRows={+total}
        fetchData={getMaterialElementGroup}
        loading={loading}
      />
    </div>
  )
}
