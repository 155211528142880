import {useCallback, useEffect, useState} from 'react'
import {Auth} from 'react-auth-navigation'

import {ItemTable} from './components'
import {useSelector, useDispatch} from 'react-redux'
import {getItemTypeListAction} from 'redux-src'

import {Button, Input} from 'app/common'
import {ItemTypeModal} from './components/itemTypeModal'
import colors from 'tailwindcss/colors'
import {FaSearch} from 'react-icons/fa'
import {useDebounceValue} from 'hooks'
import {SettingsHeader} from '../../components'

export const ItemTypesPage = () => {
  return (
    <div className="mt-4 mx-10 min-h-[85vh]">
      <Auth.Screens path="/settings/item-type-management" />
    </div>
  )
}

export const ItemTypesManagement = () => {
  const dispatch = useDispatch()

  const [newItemType, setNewItemType] = useState<boolean>(false)

  const [filterData, setFitlerData] = useState<string>()

  const {itemTypeList}: RT.ItemTypeReduxType = useSelector(
    (state: any) => state.itemTypes
  )

  const search = useDebounceValue(filterData)

  const getItemTypeList = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getItemTypeListAction({page, limit, search}))
    },
    [dispatch, search]
  )

  useEffect(() => {
    getItemTypeList()
  }, [getItemTypeList])

  return (
    <div>
      <SettingsHeader
        title={'Item Types'}
        append={
          <Button
            title="Add New Item Type"
            onClick={() => setNewItemType(true)}
            type={'button'}
          />
        }
        subHeader={'The list allows you to manage item types'}
      />

      <div className="mt-20">
        <div className="flex items-center justify-between pb-10">
          <Input
            placeholder="Search item types"
            name="filterValue"
            value={filterData}
            containerClass="w-[300px]"
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
            onChange={(e) => setFitlerData(e.target.value)}
          />
        </div>
      </div>

      <div className="my-10 mb-24 border-b-1 border-gray-200" />
      <ItemTable
        items={itemTypeList}
        filterData={filterData}
        callback={getItemTypeList}
      />
      <ItemTypeModal
        mode="add"
        itemTypeModal={newItemType}
        close={() => setNewItemType(false)}
      />
    </div>
  )
}
