import {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {alterFormContent} from 'redux-src'
import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface HyperlinkProps {
  id: number
  onSelectElement: (id: number) => void
}

interface HyperlinkContentType {
  label: string
  description: string
  value: string
}

export const Hyperlink = ({id, onSelectElement}: HyperlinkProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [hyperlinkDetails, setHyperlink] = useState<HyperlinkContentType>(
    individualElement?.content as HyperlinkContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setHyperlink((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, hyperlinkDetails, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hyperlinkDetails])

  const isHyperlinkAdded = !!(individualElement?.content?.value.length === 0)

  return (
    <div
      className={`flex flex-col border border-1 rounded-sm overflow-hidden border-solid py-6 ${
        selectedFormElementId === id
          ? `border-solid border-${isHyperlinkAdded ? 'red' : 'blue'}-300`
          : `border-dashed border-${
              isHyperlinkAdded ? 'red' : 'gray'
            }-300 hover:bg-${isHyperlinkAdded ? 'red' : 'blue'}-100`
      }
       ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="px-8 py-4">
          <input
            type="text"
            name="label"
            value={hyperlinkDetails?.label}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type link label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div className="px-8">
          <input
            type="text"
            name="description"
            value={hyperlinkDetails?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}
      <div className="overflow-hidden mx-12 my-6 border border-1 border-gray-200 py-6">
        <input
          type="text"
          name="value"
          value={hyperlinkDetails?.value}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          placeholder="Enter link here"
          className="text-black text-sm w-full ml-14 bg-transparent"
        />
      </div>
    </div>
  )
}
