import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
// import {formatDate} from '@fullcalendar/core'
import {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
  EventHoveringArg,
} from '@fullcalendar/core'
import {EventImpl} from '@fullcalendar/core/internal'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, {EventReceiveArg} from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import resourcePlugin, {
  ColHeaderContentArg,
  ResourceLabelMountArg,
} from '@fullcalendar/resource'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import timelinePlugin from '@fullcalendar/timeline'
import {Avatar} from 'app/components'
import {generateRandomAvatarColor} from 'helpers/avatarGenerator.helper'
import {makeAbbr} from 'helpers/makeAbbr.helper'
import moment from 'moment'
import {FaClock, FaUser} from 'react-icons/fa'
import {GiSandsOfTime} from 'react-icons/gi'
import {useDispatch} from 'react-redux'
import {
  createNewCalendarEvent,
  getUnAssignedTicketForCalendar,
  removeAssignedEmployeeFromTicket,
  updateScheduleTimeOfEmployee,
} from 'redux-src'
import {getStatusChip} from '../jobs/pages/ticket/components/planList/components'
import {ProgressBar} from '../jobs/pages/ticket/components/progressBar'
import {CalendarHeader, CalendarSidenav} from './components'
import CalendarModal from './components/calendarAddEventModal/calendarModal.components'
import {CalendarProvider, useCalendar} from './context'

export const CalendarPageWithContext = () => {
  return (
    <CalendarProvider>
      <CalendarPage />
    </CalendarProvider>
  )
}

const CalendarPage = () => {
  const calendarRef = useRef<FullCalendar>(null)
  const dispatch = useDispatch()
  // * State to show or hide modal to add new events
  const [addEventModal, setAddEventModal] = useState(false)
  const [isModalOnEditMode, setIsModalOnEditMode] = useState(false)
  const [onDragSelectedTicketId, setOnDragSelectedTicketId] = useState<number>()
  const [selectedDateRange, setSelectedDateRange] = useState<{
    start: Date
    end: Date
  }>()
  const [selectedResourceId, setSelectedResourceId] = useState<number>()
  const [modalMode, setModalMode] = useState<'edit' | 'add'>('add')
  // * view mode
  const [viewMode, setViewMode] = useState<'h' | 'v'>('h')
  // * calendar context
  const {calendarState, calendarDispatch, fetchCalendarEvents} = useCalendar()

  // * reset all clicked resource on view change
  useEffect(() => {
    if (viewMode === 'v') {
      calendarDispatch({
        type: 'EMPTY_CLICKED_RESOURCE',
      })
    }
  }, [calendarDispatch, viewMode])

  function handleDateClick(arg: any) {}

  // * edit schedule of event
  const handleEventDateChange = (
    e: EventChangeArg,
    {
      start,
      end,
      eventId,
    }: {
      eventId: string
      start: Date
      end: Date
    },
  ) => {
    const changedEventData: Api.CalendarEvent['event_lists'][number] =
      calendarState?.events?.find((event) => {
        return +event?.id === +eventId
      }).allData

    // * If event only changes dates and not resource, update only date
    if (e.event._def.resourceIds[0] === e.oldEvent._def.resourceIds[0]) {
      dispatch(
        updateScheduleTimeOfEmployee(
          changedEventData?.project_details?.id,
          changedEventData?.ticket_details?.id,
          changedEventData?.event_details?.id,
          {
            scheduledTo: moment(end).format(),
            scheduledFrom: moment(start).format(),
          },
          () => {
            dispatch(
              getUnAssignedTicketForCalendar({
                type:
                  calendarState?.selectedProjectType?.value === 'all'
                    ? undefined
                    : (calendarState?.selectedProjectType?.label as
                        | 'Quote'
                        | 'Days Work'),
                projects: calendarState?.selectedProjectsList?.join(','),
              }),
            )
            fetchCalendarEvents()
          },
          () => {
            e.revert()
          },
        ),
      )
    } else {
      // * If event changes resources, first delete event from previous user and set event to new user
      dispatch(
        removeAssignedEmployeeFromTicket(
          changedEventData?.project_details?.id,
          changedEventData?.ticket_details?.id,
          {
            assignedUserIds: e.oldEvent._def.resourceIds,
          },

          () => {
            dispatch(
              createNewCalendarEvent(
                {
                  ticketId: changedEventData?.ticket_details?.id,
                  scheduledFrom: moment(start).format(),
                  scheduledTo: moment(end).format(),
                  userId: +e.event._def.resourceIds[0],
                  siteVisitId: changedEventData?.site_visit_details?.id,
                },
                () => {
                  fetchCalendarEvents()
                },
              ),
            )
          },
        ),
      )
    }
  }

  // * Show add new event modal when date range is selected
  function handleDateSelect(selectInfo: DateSelectArg) {
    selectInfo.view.calendar.unselect()
    setModalMode('add')
    setSelectedDateRange((prev) => ({
      ...prev,
      end: selectInfo?.end,
      start: selectInfo?.start,
    }))
    setSelectedResourceId(+selectInfo?.resource?.id)
    setAddEventModal(true)
  }

  // * Custom render function for event chip
  function renderEventContent(eventInfo: EventContentArg) {
    return (
      <div className="h-full flex flex-col items-start justify-center px-4">
        <div className="title font-bold text-sm overflow-hidden text-nowrap text-ellipsis">
          {eventInfo.event.title}
        </div>
        <div className="subtitle font-medium overflow-hidden text-nowrap text-ellipsis flex gap-x-4 items-center">
          <FaClock />
          {moment(eventInfo?.event?.start).format('hh:mm a')}
          {' - '}
          {moment(eventInfo?.event?.end).format('hh:mm a')}
          <GiSandsOfTime />
          {moment(eventInfo?.event?.end).diff(eventInfo?.event?.start, 'hours')}
          H
        </div>
      </div>
    )
  }

  //? * To control dropdown about event info when hovering on event
  const [isDropdownShown, setIsDropdownShown] = useState(false)
  const [dropdownInfo, setDropdownInfo] = useState<{
    x: number
    y: number
    hoveredEvent: EventImpl
  }>({
    x: undefined,
    y: undefined,
    hoveredEvent: null,
  })

  const hoveredElementTicket = useMemo(() => {
    if (isDropdownShown) {
      return calendarState?.assigned_tickets?.find(
        (ticket) =>
          +ticket?.event_details?.id === +dropdownInfo?.hoveredEvent?.id,
      )
    } else {
      return null
    }
  }, [
    calendarState?.assigned_tickets,
    dropdownInfo?.hoveredEvent?.id,
    isDropdownShown,
  ])
  const dropdownRef = useRef<HTMLDivElement>(null)
  // * To show dropdown about event when hovering the event
  const handleEventMouseMove = (e: MouseEvent) => {
    if (viewMode === 'h') {
      setDropdownInfo((prev) => ({
        ...prev,
        x:
          e?.clientX + dropdownRef?.current?.clientWidth / 2 >
          window?.document?.body?.clientWidth
            ? e?.clientX - dropdownRef?.current?.clientWidth
            : e?.clientX - dropdownRef?.current?.clientWidth / 2,
      }))
    } else {
      setDropdownInfo((prev) => ({
        ...prev,
        y: e?.clientY - dropdownRef?.current?.clientHeight - 5,
      }))
    }
  }
  const handleEventMouseEnter = (e: EventHoveringArg) => {
    if (!!dropdownRef) {
      setIsDropdownShown(true)
      const target = e?.jsEvent?.target as HTMLDivElement
      if (!!target) {
        target?.addEventListener('mousemove', handleEventMouseMove)
        if (viewMode === 'h') {
          setDropdownInfo({
            // ? If dropdown overflows, render it to other side
            x:
              e.jsEvent?.clientX + dropdownRef?.current?.clientWidth / 2 >
              window?.document?.body?.clientWidth
                ? e.jsEvent?.clientX - dropdownRef?.current?.clientWidth
                : e.jsEvent?.clientX - dropdownRef?.current?.clientWidth / 2,
            y:
              target?.getBoundingClientRect()?.top -
              dropdownRef?.current?.clientHeight -
              5,
            hoveredEvent: e.event,
          })
        } else {
          setDropdownInfo({
            // ? If dropdown overflows, render it to other side
            x:
              e.el.getBoundingClientRect().x +
                e.el.getBoundingClientRect().width +
                dropdownRef?.current?.getBoundingClientRect()?.width >
              window?.document?.body?.clientWidth
                ? e.el.getBoundingClientRect().x -
                  dropdownRef?.current?.getBoundingClientRect().width
                : e.el.getBoundingClientRect().x +
                  e.el.getBoundingClientRect().width,
            y: e.jsEvent?.clientY - dropdownRef?.current?.clientHeight,
            hoveredEvent: e.event,
          })
        }
      }
    }
  }

  // * Hide dropdown on mouse leave
  const handleEventMouseLeave = (e: EventHoveringArg) => {
    const target = e?.jsEvent?.target as HTMLDivElement
    target?.removeEventListener('mousemove', handleEventMouseMove)
    setDropdownInfo({
      x: undefined,
      y: undefined,
      hoveredEvent: null,
    })
    setIsDropdownShown(false)
  }

  const [selectedEventId, setSelectedEventId] = useState<number>()
  // * When an event is clicked
  function handleEventClick(clickInfo: EventClickArg) {
    setSelectedDateRange({
      start: clickInfo?.event?.start,
      end: clickInfo?.event?.end,
    })
    setIsModalOnEditMode(true)
    setSelectedResourceId(+clickInfo?.event?._def?.resourceIds[0])
    setOnDragSelectedTicketId(
      calendarState?.events?.find((event) => +event.id === +clickInfo.event.id)
        ?.allData?.ticket_details?.id,
    )
    setModalMode('edit')
    setSelectedEventId(+clickInfo?.event?.id)
    setAddEventModal(true)
  }

  function handleEvents(events: any) {}

  // * enable scrolling by dragging the timeline same as in fergus
  const currentView = calendarRef?.current?.getApi()?.view?.type
  useEffect(() => {
    const handleMouseMoveScroll = (e: MouseEvent) => {
      document.querySelectorAll('.fc-scroller')[1].scrollLeft -= e.movementX
    }

    let header = document.querySelectorAll('.fc-timeline-header-row')

    header.forEach((div) =>
      div.addEventListener('mousedown', (e) => {
        //@ts-ignore
        document.addEventListener('mousemove', handleMouseMoveScroll)
      }),
    )

    document.addEventListener('mouseup', (e) => {
      //@ts-ignore
      document.removeEventListener('mousemove', handleMouseMoveScroll)
    })

    return () => {
      header.forEach((div) =>
        div.removeEventListener('mousedown', (e) => {
          //@ts-ignore
          document.addEventListener('mousemove', handleMouseMoveScroll)
        }),
      )
      document.removeEventListener('mouseup', (e) => {
        //@ts-ignore
        document.removeEventListener('mousemove', handleMouseMoveScroll)
      })
      // @ts-ignore
      document.removeEventListener('mousemove', handleMouseMoveScroll)
    }
  }, [currentView, viewMode])

  // * Event receive event handler for drag and drop
  const handleEventReceive = (e: EventReceiveArg) => {
    const draggedElement = e.draggedEl
    const draggedTicketId = draggedElement?.getAttribute('data-id')
    const combinedAssignedUnassigned = [
      ...calendarState?.assigned_tickets,
      ...calendarState?.unassigned_tickets,
    ]
    const draggedTicket = combinedAssignedUnassigned?.find(
      (ticket) => +ticket?.ticket_details?.id === +draggedTicketId,
    )

    if (!!draggedTicket) {
      setSelectedResourceId(
        +(e?.event?.getResources()?.at(0)?._resource?.id ?? -1),
      )
      setOnDragSelectedTicketId(draggedTicket?.ticket_details?.id)
      setIsModalOnEditMode(true)
      setSelectedDateRange({
        start: e.event.start,
        end: moment(e.event.start).add(1, 'hour').toDate(),
      })
      setModalMode('add')
      setAddEventModal(true)
    }

    e.revert()
  }

  // * highlight resource on resource click
  const handleResourceClick = useCallback(
    (e: MouseEvent, mountArg: ResourceLabelMountArg) => {
      const clickedResourceId = +mountArg?.resource?.id
      calendarDispatch({
        type: 'CLICKED_RESOURCE',
        payload: clickedResourceId,
      })
    },
    [calendarDispatch],
  )

  useEffect(() => {
    document.querySelectorAll('.fc-datagrid-cell.fc-resource').forEach((el) => {
      if (
        calendarState?.clickedResources?.includes(
          Number(el.getAttribute('data-resource-id') ?? ''),
        )
      ) {
        el.classList.add('bg-[#737f8b]', 'text-white')
      } else {
        el.classList.remove('bg-[#737f8b]', 'text-white')
      }
    })
  }, [calendarState?.clickedResources, calendarState?.clickedResources?.length])

  return (
    <div className="calendar-page flex w-full h-[94vh] ">
      <CalendarSidenav calendarRef={calendarRef} />
      <div className="calendar-page-main  p-12 ">
        <CalendarHeader
          switchViewMode={setViewMode}
          viewMode={viewMode}
          calendarRef={calendarRef}
        />
        <FullCalendar
          dragScroll={true}
          schedulerLicenseKey="0221225037-fcs-1719212319"
          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            resourcePlugin,
            resourceTimelinePlugin,
            timelinePlugin,
            scrollGridPlugin,
            resourceTimeGridPlugin,
          ]}
          views={{
            resourceTimeGridFortnight: {
              type: viewMode === 'h' ? 'resourceTimeline' : 'resourceTimeGrid',
              duration: {days: 15},
              dayHeaderContent: function (arg) {
                return moment(arg.date).format('ddd, DD MMM')
              },
              resourceAreaWidth: '10%',
              slotDuration:
                viewMode === 'h'
                  ? {
                      day: 1,
                    }
                  : {
                      hours: 0.5,
                    },
              slotLabelInterval:
                viewMode === 'v'
                  ? {
                      hours: 1,
                    }
                  : {
                      day: 1,
                    },

              slotLabelContent: function (arg) {
                if (viewMode === 'h') {
                  return moment(arg.date).format('ddd, DD MMM')
                } else {
                  return moment(arg.date).format('hh:mm a')
                }
              },
            },
            resourceTimeGridWeekCustom: {
              type: viewMode === 'h' ? 'resourceTimeline' : 'resourceTimeGrid',
              duration: {days: 7},
              resourceAreaWidth: '10%',
              dayHeaderContent: function (arg) {
                return moment(arg.date).format('ddd, DD MMM')
              },
              slotDuration:
                viewMode === 'h'
                  ? {
                      day: 1,
                    }
                  : {
                      hours: 0.5,
                    },
              slotLabelInterval:
                viewMode === 'v'
                  ? {
                      hours: 1,
                    }
                  : {
                      day: 1,
                    },

              slotLabelContent: function (arg) {
                if (viewMode === 'h') {
                  return moment(arg.date).format('ddd, DD MMM')
                } else {
                  return moment(arg.date).format('hh:mm a')
                }
              },
            },
            resourceTimeGridDayCustom: {
              // type: 'resourceTimeGrid',
              type: viewMode === 'h' ? 'resourceTimeline' : 'resourceTimeGrid',
              duration: {days: 1},
              resourceAreaWidth: '10%',
              scrollTimeReset: true,
              slotDuration: {
                hour: 0.25,
              },
              slotLabelInterval: {
                hour: 1,
              },
            },
          }}
          height={'70vh'}
          // ? Default header toolbar is replaced by custom header
          // headerToolbar={{
          //   // left: "myCustomButton prev,today,next",
          //   left: 'prev,today,next hView,vView',
          //   center: 'title',
          //   right:
          //     'resourceTimeGridFortnight,resourceTimeGridWeekCustom,resourceTimeGridDayCustom',
          // }}
          headerToolbar={false}
          titleFormat={({date}) => {
            return moment(date).format('dddd, Do [of] MMMM YYYY')
          }}
          themeSystem="standard"
          buttonText={{
            today: 'Today',
            month: 'Month',
            resourceTimeGridWeekCustom: 'Week',
            resourceTimeGridDayCustom: 'Day',
            list: 'List',
            resourceTimeGridFortnight: 'Fortnight',
          }}
          buttonIcons={{
            prev: 'chevron-left',
            next: 'chevron-right',
            prevYear: 'chevrons-left', // double chevron
            nextYear: 'chevrons-right', // double chevron
            hView: 'change-view-h',
            vView: 'change-view-v',
          }}
          initialView="resourceTimeGridDayCustom"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          expandRows={true}
          nowIndicator={true}
          // selectConstraint="businessHours"
          resources={calendarState?.resources as any}
          resourceOrder={'title'}
          resourceLabelClassNames="overflow-hidden text-ellipsis text-clip capitalize items-center justify-center hover:bg-[#dceef9] hover:text-[#000000] cursor-pointer w-full"
          resourceLabelContent={(content) => {
            if (viewMode === 'h') {
              const fullName = content.resource.title.split(' ')
              return (
                <div className="flex justify-between items-center w-full">
                  <div
                    // onClick={e => {
                    //   console.log({content})
                    // }}
                    className="flex  items-center gap-6 "
                  >
                    <Avatar
                      showTooltip={false}
                      display_name={fullName?.at(0) ?? ''}
                      lastname={fullName?.at(-1) ?? ''}
                    />
                    <div className="name">{content.resource.title}</div>
                  </div>
                  {/* {calendarState?.clickedResources?.includes(
                    +content?.resource?.id,
                  ) && <FaTimes />} */}
                </div>
              )
            } else {
              return (
                <div
                  title={content.resource.title}
                  style={{
                    background: generateRandomAvatarColor(
                      content.resource.title,
                    ),
                  }}
                  className="w-full h-full text-white cursor-pointer py-6"
                >
                  {makeAbbr(content.resource.title)}
                </div>
              )
            }
          }}
          datesAboveResources={true}
          scrollTime={moment().subtract('70', 'minutes').format('HH:mm:ss')}
          eventMaxStack={2}
          slotEventOverlap={false}
          events={calendarState.events}
          select={handleDateSelect}
          eventMouseEnter={handleEventMouseEnter}
          eventMouseLeave={handleEventMouseLeave}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventsSet={(events) => handleEvents(events)}
          eventReceive={handleEventReceive} // * Triggers when event is dropped from outside of calendar (like when dropped from sidebar)
          // * triggers when event changes resources within the calendar
          // eventDrop={(arg) => {
          //   arg.jsEvent.stopPropagation()
          // }}
          droppable={true}
          dateClick={handleDateClick}
          // * Do not allow event to be transfered between resources
          // eventAllow={(dropInfo, dragInfo) => {
          //   return dropInfo?.resource?.id === dragInfo?.getResources()[0]?.id
          // }}
          eventClassNames="custom-event-class"
          eventAdd={(e) => {
            console.log('eventAdd', e)
          }}
          // * Triggers when attributes of event changes like title or date
          eventChange={(e) => {
            /**
             * ? When stacking multiple events on the same day and same time, sometimes, end date gets bugged out. To prevent that from happening, incase of end date being null, we calculate the previous duration using old start date and end date and add the duration to new start date to get the new end date
             */
            if (!e.event.end) {
              const old_duration = moment(e.oldEvent.end).diff(
                e.oldEvent.start,
                'minutes',
              )
              calendarRef?.current
                ?.getApi()
                .getEventById(e.event.id)
                .setEnd(
                  moment(e.event.start).add(old_duration, 'minutes').toDate(),
                )
            }
            // console.log('eventChange', e)

            if (!!e.event.end && !!e.event.start) {
              // * Call api accordingly when event is changed
              handleEventDateChange(e, {
                eventId: e.event.id,
                start: e.event.start,
                end: e.event.end,
              })
            }
          }}
          // allDayClassNames="hidden"
          allDaySlot={false}
          resourceAreaHeaderContent={(arg: ColHeaderContentArg) => (
            <span>Employees</span>
          )}
          resourceLabelDidMount={(mountArg) => {
            if (viewMode === 'h') {
              mountArg?.el?.addEventListener('click', (e) => {
                handleResourceClick(e, mountArg)
              })

              if (
                calendarState?.clickedResources?.includes(
                  Number(mountArg?.resource?.id),
                )
              ) {
                mountArg?.el?.classList.add('bg-[#737f8b]', 'text-white')
              } else {
                mountArg?.el?.classList.remove('bg-[#737f8b]', 'text-white')
              }
            }
          }}
        />
      </div>

      {/* Modal to add new events */}
      <CalendarModal
        selectedEventId={selectedEventId}
        mode={modalMode}
        calendarRef={calendarRef}
        isVisible={addEventModal}
        setIsVisible={setAddEventModal}
        dateTimeInfo={selectedDateRange}
        selectedResourceId={selectedResourceId}
        selectedTicketId={
          isModalOnEditMode ? onDragSelectedTicketId : undefined
        }
        onClose={() => {
          setSelectedResourceId(null)
          setOnDragSelectedTicketId(null)
        }}
      />

      {/* Event Details Dropdown */}
      {isDropdownShown && (
        <div
          ref={dropdownRef}
          className="event-details-dropdown w-[300px] h-[180px]  rounded-sm shadow-sm absolute bg-white z-90 p-12 flex flex-col gap-10"
          style={{
            top: dropdownInfo?.y,
            left: dropdownInfo?.x,
            transition: 'all 0.3s ease',
            transitionDelay: '300ms',
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="font-bold text-blue-300">
              {hoveredElementTicket?.project_details?.project_prefix}-
              {hoveredElementTicket?.project_details?.id} |{' '}
              {hoveredElementTicket?.project_details?.title}
            </div>
            <div className="">
              {hoveredElementTicket?.ticket_details?.title}
            </div>
            <div className="flex justify-between mt-6">
              {getStatusChip(
                hoveredElementTicket?.ticket_details?.status as any,
              )}
              <ProgressBar
                progressPercentage={
                  hoveredElementTicket?.ticket_details?.progress_percent
                }
              />
            </div>
          </div>

          <div className="flex flex-col justify-between text-blue-300">
            <div className="flex gap-4 items-center capitalize">
              <FaUser /> {hoveredElementTicket?.user_detail?.display_name}{' '}
              {hoveredElementTicket?.user_detail?.lastname}
            </div>

            <div className="flex items-center gap-4">
              <FaClock />
              {moment(
                hoveredElementTicket?.event_details?.scheduled_from,
              ).format('Do MMM YYYY, HH:mm')}{' '}
              -{' '}
              {moment(hoveredElementTicket?.event_details?.scheduled_to).format(
                'Do MMM YYYY, HH:mm',
              )}
            </div>

            <div className="flex items-center gap-4">
              <GiSandsOfTime />{' '}
              {moment(hoveredElementTicket?.event_details?.scheduled_to).diff(
                hoveredElementTicket?.event_details?.scheduled_from,
                'hours',
              )}{' '}
              Hrs
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
