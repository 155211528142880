import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {Button, Modal, Loading, toast} from 'app/common'

import {DynamicSearchSelect} from 'app/components'

import {addProjectUserGroups, getProjectUserGroupList} from 'redux-src'

export const ProjectGroupModal = ({newGroupModal, setNewGroupModal}: any) => {
  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const {
    getAppUserList_loading
  }: // companyUserList,
  RT.UserReduxType = useSelector((state: any) => state.user)

  const {add_loading} = useSelector((state: any) => state.group)

  const [formData, {onSubmit, setValue}] = useFormInput(
    {
      groupIds: []
    },
    (data) => {
      const userGroupBody = {
        userGroupIds: data?.groupIds
      }

      if (data?.groupIds?.length === 0) {
        return toast.error('Please select atleast one group !!')
      }

      dispatch(
        addProjectUserGroups(projectId, userGroupBody, () => {
          setNewGroupModal(false)
          dispatch(getProjectUserGroupList(projectId, 1, 10, ''))
        })
      )
    }
  )

  return (
    <>
      <Modal
        size="sm"
        isActive={newGroupModal}
        toggleModal={setNewGroupModal}
        title="Add a New Group to project"
      >
        {getAppUserList_loading || add_loading ? (
          <Loading />
        ) : (
          <form onSubmit={onSubmit}>
            <div className="mx-16 my-6 mb-0 h-[20vh] flex flex-col flex-nowrap  overflow-y-auto">
              <div className="w-full px-16 my-8 flex flex-col gap-6 mt-[-4px]">
                <div className="text-sm text-gray-400">Add Group Members</div>
                <div className="">
                  <DynamicSearchSelect
                    actionUrl={`/projects/${projectId}/users/groups/company`}
                    remapOptions={(data: any) => {
                      return data?.map((item: any) => {
                        return {
                          id: item?.id,
                          label: item?.name,
                          value: item?.name
                        }
                      })
                    }}
                    onMultiChangeValue={(value) => {
                      setValue(
                        'groupIds',
                        value?.map((group) => group.id)
                      )
                    }}
                    placeholder={'Add groups'}
                    isMulti
                  />
                </div>
              </div>
            </div>

            <div className="border-t-1 border-gray-200 "></div>

            <div className="flex justify-end px-16 py-10">
              <Button
                title="cancel"
                buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
                size="sm"
                type="button"
                onClick={() => setNewGroupModal(false)}
              />
              <div className="ml-16">
                <Button title="Save" size="sm" type="submit" />
              </div>
            </div>
          </form>
        )}
      </Modal>
    </>
  )
}
