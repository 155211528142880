import {useEffect} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {HiArrowLeft} from 'react-icons/hi'
import {useDispatch} from 'react-redux'

import {PreliminaryTemplateEditor} from 'app/components'
import {getPreliminaryTemplateDetailsAction} from 'redux-src'

export const PreliminaryTemplateDetailsPage = () => {
  const {
    params,
    navigation: {routes, navigate}
  } = useNavigation()

  const {preliminaryTemplateId} = params as any

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getPreliminaryTemplateDetailsAction(preliminaryTemplateId, () => {})
    )
  }, [preliminaryTemplateId, dispatch])

  return (
    <div className="min-h-[85vh] flex pt-10 px-16 flex-col gap-16">
      <div className="flex items-center justify-between">
        <h2
          className="text-sm flex items-center w-[150px] mb-10 cursor-pointer"
          style={{color: 'blue'}}
          onClick={() => {
            navigate(routes['Preliminary Templates'].path)
          }}
        >
          <span className="mr-10 mt-[3px]">
            <HiArrowLeft />
          </span>
          Back to template list
        </h2>
      </div>
      <div className="flex flex-col gap-6">
        <PreliminaryTemplateEditor />
      </div>
    </div>
  )
}
