import {useNavigation} from 'react-auth-navigation'
import {GrClose} from 'react-icons/gr'
import {useDispatch} from 'react-redux'
import {getCompanyUserByIdAction, removeUserFromGroupAction} from 'redux-src'

interface GroupItemProps {
  details: Api.UserAssociatedGroups
}

export const GroupItem = ({details}: GroupItemProps) => {
  const {params} = useNavigation()
  const {id} = params as any

  const dispatch = useDispatch()

  const onRemoveUserFromGroup = () => {
    dispatch(
      removeUserFromGroupAction(details?.id, id, () => {
        dispatch(getCompanyUserByIdAction(id))
      })
    )
  }

  return (
    <div className="flex rounded-[25px] border-1 border-gray-200 items-center px-4 py-4 gap-8 bg-gray-100">
      {/* AVATAR */}
      <div className="w-[25px] h-[25px] flex items-center justify-center rounded-full bg-gray-400">
        <h1 className="text-white font-semibold text-md">
          {details?.name?.at(0).toUpperCase()}
        </h1>
      </div>
      {/* GROUP NAME */}
      <p className="text-sm">{details.name}</p>
      {/* CLOSE */}
      <GrClose
        size={12}
        className="mr-4 cursor-pointer"
        onClick={onRemoveUserFromGroup}
      />
    </div>
  )
}
