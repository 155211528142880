import {
  UPDATE_PRICE_BOOKS,
  ADD_PRICE_BOOKS,
  GET_PRICE_BOOKS,
  SEARCH_PRICE_BOOKS,
} from '../actions'

const initialState: any = {
  add_loading: false,
  update_loading: false,
  get_loading: false,
  search_loading: false,

  searchedPriceBooks: [],
  priceBooks: [],
}

export function priceBookReducer(state = initialState, action: any) {
  const { type, payload } = action

  switch (type) {
    case UPDATE_PRICE_BOOKS.LOADING:
      return { ...state, update_loading: true }
    case SEARCH_PRICE_BOOKS.LOADING:
      return { ...state, search_loading: true }

    case GET_PRICE_BOOKS.LOADING:
      return { ...state, get_loading: true }

    case ADD_PRICE_BOOKS.LOADING:
      return { ...state, get_loading: true }

    case UPDATE_PRICE_BOOKS.SUCCESS:
      return {
        ...state,
        update_loading: false,
      }

    case ADD_PRICE_BOOKS.SUCCESS:
      return {
        ...state,
        add_loading: false,
      }

    case SEARCH_PRICE_BOOKS.SUCCESS:
      return {
        ...state,
        search_loading: false,
        searchPriceBooks: payload,
      }

    case GET_PRICE_BOOKS.SUCCESS:
      return {
        ...state,
        get_loading: false,
        priceBooks: payload,
      }

    case UPDATE_PRICE_BOOKS.ERROR:
      return { ...state, update_loading: false }

    case ADD_PRICE_BOOKS.ERROR:
      return { ...state, add_loading: false }

    case SEARCH_PRICE_BOOKS.ERROR:
      return { ...state, search_loading: false, searchedPriceBooks: [] }

    case GET_PRICE_BOOKS.ERROR:
      return { ...state, get_loading: false, priceBooks: [] }

    default:
      return state
  }
}
