import {useCallback, useEffect, useMemo} from 'react'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'

import {DataTable, DescriptionBox} from 'app/components'
import {
  Button,
  Checkbox,
  Loading,
  Modal,
  SelectField,
  ToolTip
} from 'app/common'

import {
  createWorkerInvoiceLineItem,
  getInvoicableProjectLabourEntries,
  getLabourEntryByTicketId,
  getWorkerInvoiceById
} from 'redux-src'
import {useFormInput} from 'use-form-input'
import {BsChevronRight} from 'react-icons/bs'
import moment from 'moment'

interface RemainingTicketModalProps {
  projectId?: number
  openModal?: boolean
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
  setProjectId?: React.Dispatch<React.SetStateAction<number>>
  sectionDetail?: Api.WorkerInvoiceDetailsById['worker_invoice_item_list'][0]
}

export const RemainingTicketModal = ({
  projectId,
  openModal,
  setProjectId,
  setOpenModal,
  sectionDetail
}: RemainingTicketModalProps) => {
  const {
    // addInvoiceLineItemWithTicketLoading,
    invoicableProjectLabourEntries
  }: RT.ProjectInvoicingReduxType = useSelector((state: any) => state.invoices)

  const {projectList}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project
  )

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {invoiceId, id: userId} = params as any

  const {currencySymbol} = useAuth()

  const [data, {setValue, onSubmit}] = useFormInput<{
    allProjects: Api.InvoicableProjectLabourEntryList
    activeProject: ActiveProjectTickets
    selectedProject: {id: number; label: string; value: number}
    selectedTicket: {id: number; label: string; value: boolean}
  }>(
    {
      allProjects: undefined,
      activeProject: undefined,
      selectedProject: undefined,
      selectedTicket: undefined
    },
    (data) => {
      generateWorkerInvoiceUsingProjectTickets()
    }
  )

  const columns: TableColumn<SelectedEntryForTable>[] = [
    {
      name: 'Item Name',
      selector: (row) => row.title,
      wrap: true,
      style: {width: 200},
      cell: (row) => {
        return <div className={'text-blue-300 font-medium'}>{row.title}</div>
      },
      sortable: true
    },
    // {
    //   name: 'Labour Entry',
    //   selector: (row) => row.labour_element_name,
    //   cell: (row) => {
    //     return (
    //       <div className={'text-blue-300 font-medium'}>
    //         {row.labour_element_name}
    //       </div>
    //     )
    //   },
    //   sortable: true
    // },
    {
      name: 'Time (mins)',
      selector: (row) => row.time_mins,
      sortable: true,
      cell: (row) => {
        return <div className="text-sm">{row.time_mins}</div>
      }
    },
    {
      name: 'Pay Rate',
      selector: (row) => row.pay_rate,
      sortable: true,
      cell: (row) => {
        return (
          <div className="text-sm">
            {currencySymbol}
            {row.pay_rate}
          </div>
        )
      }
    },
    {
      name: 'Completed At',
      selector: (row) => row?.completed_at,
      sortable: true,
      cell: (row) => {
        return (
          <div className="text-sm">
            {moment(row.completed_at)?.format('ddd, DD, MMM, YYYY hh:mma')}
          </div>
        )
      }
    }
  ]

  const projectListOptions: Array<{
    id: number
    label: string
    value: number
  }> = useMemo(() => {
    const remappedOptions = projectList?.find(
      (project) => project?.project_details?.id === projectId
    )

    return [
      {
        id: remappedOptions?.project_details?.id,
        label: remappedOptions?.project_details?.title,
        value: remappedOptions?.project_details?.id
      }
    ]
  }, [projectId, projectList])

  const projectWithEntryList = useMemo(() => {
    const remappedTicketWithEntryList = invoicableProjectLabourEntries?.map(
      (ticket) => {
        return {
          ...ticket,
          isChecked: true
        }
      }
    )

    return {
      label: 'All Tickets',
      isChecked: true,
      ticket_list:
        remappedTicketWithEntryList?.length > 0
          ? [...remappedTicketWithEntryList]
          : []
    }
  }, [invoicableProjectLabourEntries])

  useEffect(() => {
    setValue('activeProject', projectWithEntryList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectWithEntryList])

  const activeTicket = useMemo(() => {
    const activeItem = data?.activeProject?.ticket_list?.find(
      (ticket) => ticket?.ticket_id === data?.selectedTicket?.id
    )

    return activeItem
  }, [data?.activeProject?.ticket_list, data?.selectedTicket?.id])

  const updateAllTicketItems = useCallback(
    (value: boolean) => {
      const allTicketsBuffer = {
        ...data?.activeProject,
        isChecked: !value,
        ticket_list:
          data?.activeProject?.ticket_list?.map((ticket) => ({
            ...ticket,
            isChecked: !value,
            project_labour_entries_list:
              (ticket?.project_labour_entries_list &&
                Array.isArray(ticket?.project_labour_entries_list) &&
                ticket?.project_labour_entries_list?.map((labourEntry) => ({
                  ...labourEntry,
                  isChecked: !value
                }))) ??
              []
          })) ?? []
      }

      setValue('activeProject', allTicketsBuffer)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.activeProject, data?.selectedTicket]
  )

  const updateActiveAllTicketItem = useCallback(
    (value: boolean, ticketId: number) => {
      const alterEntryBuffer = {
        ...data?.activeProject,
        ticket_list:
          data?.activeProject?.ticket_list?.map((ticket) =>
            ticket?.ticket_id === activeTicket?.ticket_id
              ? {
                  ...ticket,
                  isChecked: !value,
                  project_labour_entries_list:
                    (ticket?.project_labour_entries_list &&
                      Array.isArray(ticket?.project_labour_entries_list) &&
                      ticket?.project_labour_entries_list?.map(
                        (labourEntry) => ({
                          ...labourEntry,
                          isChecked: !value
                        })
                      )) ??
                    []
                }
              : ticket
          ) ?? []
      }

      const alterTicketBuffer = {
        ...alterEntryBuffer,
        ticket_list:
          alterEntryBuffer?.ticket_list?.map((ticket) => ({
            ...ticket,
            isChecked:
              ticket?.ticket_id === ticketId
                ? !ticket?.isChecked
                : ticket?.isChecked
          })) ?? []
      }

      const alterProjectBuffer = {
        ...alterTicketBuffer,
        isChecked: alterTicketBuffer?.ticket_list?.every(
          (ticket) => ticket?.isChecked
        )
      }

      setValue('activeProject', alterProjectBuffer)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.activeProject]
  )

  const updateActiveTicketLabourEntries = useCallback(
    (value: boolean, entryId: number) => {
      const alterEntryBuffer = {
        ...data?.activeProject,
        ticket_list:
          data?.activeProject?.ticket_list?.map((ticket) => ({
            ...ticket,
            project_labour_entries_list:
              (ticket?.project_labour_entries_list &&
                Array.isArray(ticket?.project_labour_entries_list) &&
                ticket?.project_labour_entries_list?.map((labourEntry) => ({
                  ...labourEntry,
                  isChecked:
                    labourEntry?.project_labour_entry_id === entryId
                      ? !labourEntry?.isChecked
                      : labourEntry?.isChecked
                }))) ??
              []
          })) ?? []
      }

      const alterTicketBuffer = {
        ...alterEntryBuffer,
        ticket_list:
          alterEntryBuffer?.ticket_list?.map((ticket) => ({
            ...ticket,
            isChecked: ticket?.project_labour_entries_list?.every(
              (item) => item?.isChecked
            )
          })) ?? []
      }

      const alterProjectBuffer = {
        ...alterTicketBuffer,
        isChecked: alterTicketBuffer?.ticket_list?.every(
          (ticket) => ticket?.isChecked
        )
      }

      setValue('activeProject', alterProjectBuffer)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.activeProject]
  )

  const selectedTicketItems = useMemo(() => {
    console.log({
      selectedTicketItems:
        data?.activeProject?.ticket_list
          ?.map((item) =>
            item?.project_labour_entries_list?.filter((item) => item?.isChecked)
          )
          ?.flat(1)
          ?.filter((item) => !!item) ?? []
    })
    return (
      data?.activeProject?.ticket_list
        ?.map((item) =>
          item?.project_labour_entries_list?.filter((item) => item?.isChecked)
        )
        ?.flat(1)
        ?.filter((item) => !!item) ?? []
    )
  }, [data?.activeProject])

  const generateWorkerInvoiceUsingProjectTickets = () => {
    const body = {
      projectLabourEntryIds: selectedTicketItems
        ?.filter((itm) => itm?.isChecked)
        ?.map((item) => item?.project_labour_entry_id)
    }

    dispatch(
      createWorkerInvoiceLineItem(
        invoiceId,
        sectionDetail?.worker_inv_item_details?.id,
        body,
        () => {
          dispatch(getWorkerInvoiceById(invoiceId))
          setOpenModal(false)
        }
      )
    )
  }

  const getLabourEntryByTicketCallback = useCallback(
    (entryList: Array<Api.ProjectLabourEntryIndividual>, ticketId: number) => {
      const projectBuffer = {
        ...data?.activeProject,
        ticket_list: data?.activeProject?.ticket_list?.map((ticket) =>
          ticket?.ticket_id === ticketId
            ? {
                ...ticket,
                apiHit: true,
                project_labour_entries_list: entryList?.map((entry) => ({
                  ...entry,
                  isChecked: true
                }))
              }
            : ticket
        )
      }

      setValue('activeProject', projectBuffer)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.activeProject]
  )

  useEffect(() => {
    projectId &&
      openModal &&
      dispatch(
        getInvoicableProjectLabourEntries(
          invoiceId,
          sectionDetail?.worker_inv_item_details?.id
        )
      )
  }, [dispatch, projectId, openModal, invoiceId, sectionDetail])

  const onCloseModal = () => {
    setProjectId(undefined)
  }

  return (
    <Modal
      title="Select Ticket"
      toggleModal={setOpenModal}
      isActive={openModal}
      size="lg"
      onClose={onCloseModal}
    >
      <div className="p-20">
        <DescriptionBox title="Project Labour Entry List">
          <div className="flex w-full gap-20 mb-20">
            <div className="flex flex-col gap-10 w-1/4">
              <div className="text-md font-bold">Selected a project</div>
              <SelectField
                options={projectListOptions}
                placeholder="Select a project"
                value={projectListOptions[0]}
                onChangeValue={(data) => {
                  setValue('selectedProject', data)
                }}
              />
            </div>

            <div className="flex items-center gap-10 w-3/4 bg-blue-100 rounded-md px-20 py-16 text-sm text-blue-300">
              Select a project to show the list of tickets.
            </div>
          </div>

          <div className="flex w-full gap-20 ">
            <div className="flex flex-col gap-10 w-1/4 bg-gray-100 rounded-md px-10 py-10">
              <Checkbox
                dataId={`selectAll-ticketlist`}
                label={<div className="font-bold text-md">Ticket List</div>}
                labelClassName="ml-8"
                isChecked={data?.activeProject?.isChecked}
                value={data?.activeProject?.isChecked}
                onChange={(value) => {
                  updateAllTicketItems(value)
                }}
              />
              <div className="overflow-y-auto overflow-x-hidden h-[225px]">
                {data?.activeProject?.ticket_list?.map((ticket) => (
                  <div
                    onClick={() => {
                      setValue('selectedTicket', {
                        id: ticket.ticket_id,
                        value: ticket.apiHit ?? false,
                        label: ticket.ticket_title
                      })

                      !!!ticket.apiHit &&
                        dispatch(
                          getLabourEntryByTicketId(
                            ticket.ticket_id,
                            userId,
                            getLabourEntryByTicketCallback
                          )
                        )
                    }}
                    className={` my-6 bg-white cursor-pointer border-1 rounded-sm h-[50px] w-full flex items-center justify-between gap-8 px-10 ${
                      data?.selectedTicket?.id === ticket?.ticket_id
                        ? 'border-blue-200 '
                        : 'border-gray-100 '
                    }`}
                    key={'ticket-worker-invoice-' + ticket.ticket_id}
                  >
                    <div className="flex flex-row flex-nowrap gap-8 items-center">
                      <ToolTip text="Ticket Number" right>
                        <span className="flex text-sm items-center justify-center h-[24px] w-[max-content] text-white bg-red-400 rounded-md px-8">
                          {ticket?.ticket_number ?? ticket?.ticket_id}
                        </span>
                      </ToolTip>
                      <div className="text-sm text-black max-w-[80px] truncate">
                        {ticket?.ticket_title}
                      </div>
                    </div>
                    <div
                      className={`self-center justify-self-end ${
                        data?.selectedTicket?.id === ticket?.ticket_id
                          ? 'text-blue-200 '
                          : 'text-gray-100 '
                      }`}
                    >
                      <BsChevronRight
                        color={
                          data?.selectedTicket?.id === ticket?.ticket_id
                            ? 'blue'
                            : '#dadada'
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-10 w-3/4 bg-[#fafafa] rounded-md px-20 py-16">
              {false ? (
                <Loading small />
              ) : (
                <>
                  {!!activeTicket ? (
                    <>
                      <div className="flex flex-col gap-8">
                        <Checkbox
                          dataId={`selectAll-${
                            activeTicket?.ticket_title +
                            activeTicket?.ticket_number
                          }`}
                          label={
                            <div className="font-bold text-md break-normal max-w-[420px]">
                              Labour Entry List -{' '}
                              <span className="text-blue-300">
                                {activeTicket?.ticket_title}
                              </span>
                            </div>
                          }
                          labelClassName="ml-8"
                          isChecked={activeTicket?.isChecked}
                          value={activeTicket?.isChecked}
                          onChange={(value) => {
                            updateActiveAllTicketItem(
                              value,
                              activeTicket?.ticket_id
                            )
                          }}
                        />
                      </div>

                      <div className="flex flex-col gap-6  h-[200px] overflow-y-auto">
                        {activeTicket?.project_labour_entries_list &&
                        Array.isArray(
                          activeTicket?.project_labour_entries_list
                        ) &&
                        activeTicket?.project_labour_entries_list?.length >
                          0 ? (
                          activeTicket?.project_labour_entries_list?.map(
                            (item, index: number) => {
                              return (
                                <div
                                  className="flex gap-6 items-center"
                                  key={index}
                                >
                                  <div className="">
                                    <Checkbox
                                      label={
                                        <div className="flex items-center font-normal text-sm break-normal max-w-[420px]">
                                          {item?.title}
                                        </div>
                                      }
                                      labelClassName="ml-8"
                                      isChecked={item?.isChecked}
                                      value={item?.isChecked}
                                      dataId={`ticket-${item?.project_labour_entry_id}`}
                                      onChange={(value) => {
                                        updateActiveTicketLabourEntries(
                                          value,
                                          item?.project_labour_entry_id
                                        )
                                      }}
                                    />
                                  </div>
                                  <span>-</span>
                                  <ToolTip
                                    text="Pay rate per time in mins"
                                    down
                                  >
                                    <div className="font-medium text-black text-sm px-10 bg-blue-150 py-4 rounded-sm w-[max-content]">
                                      {currencySymbol}
                                      {item?.pay_rate ?? 0} / {item?.time_mins}
                                      mins
                                    </div>
                                  </ToolTip>
                                </div>
                              )
                            }
                          )
                        ) : (
                          <div className="text-sm text-gray-300 h-[235px]">
                            Labour entries not available for selected ticket.
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="text-sm text-gray-300 h-[235px]">
                      Please select a ticket to show the list of labour entries.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {!!selectedTicketItems && selectedTicketItems?.length > 0 && (
            <div className="flex flex-col gap-10 mt-20">
              <div className="font-bold text-md">Selected Items</div>
              <div className="flex flex-col gap-16">
                <DataTable columns={columns} data={selectedTicketItems ?? []} />
              </div>
            </div>
          )}
        </DescriptionBox>

        <div className="flex justify-end p-18 border-t-1 border-gray-200 w-full">
          <Button
            title="Cancel"
            buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
            size="sm"
            type="button"
            onClick={() => {
              setOpenModal(false)
              onCloseModal()
            }}
          />
          <div className="ml-16">
            <Button
              title="Add Tickets"
              size="sm"
              type="submit"
              onClick={onSubmit}
              // loading={addInvoiceLineItemWithTicketLoading}
              // isDisabled={selectedTicketItems?.length <= 0}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

type SelectedEntryForTable = {
  pay_rate: number
  time_mins: number
  title: string
  completed_at: string
  project_labour_entry_id: number
}

interface ActiveProjectTickets {
  label: string
  isChecked: boolean
  ticket_list: Api.InvoicableProjectLabourEntryList
}
