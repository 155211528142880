import {Button, Modal, Textarea, toast} from 'app/common'
import {MessageBox} from 'app/components'
import React from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {ticketFeedbackAction} from 'redux-src'
import {useFormInput} from 'use-form-input'

export const FeedbackModal = ({
  modalVisible,
  setModalVisible,
  ticketId,
  getTicketLogList,
  fetchTicketDetailsCB
}: {
  modalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  ticketId: number
  getTicketLogList: () => void
  fetchTicketDetailsCB: () => void
}) => {
  const {ticketFeedbackLoading}: RT.TicktesReduxType = useSelector(
    (state: any) => state.tickets
  )

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const [data, {setValue, errors, modified, onSubmit}] = useFormInput(
    {message: undefined},
    () => {},
    (data) => {
      let errors: any = {}

      if (data?.message?.length === 0) {
        errors.message = 'Message cannot be empty !!'
      }

      return errors
    }
  )

  const handleSubmitFeedback = (e: any) => {
    onSubmit(e)

    if (data?.message?.length > 0) {
      dispatch(
        ticketFeedbackAction(
          projectId,
          ticketId,
          {message: data?.message},
          () => {
            setValue('message', undefined)
            setModalVisible(false)
            getTicketLogList()
            fetchTicketDetailsCB()
          }
        )
      )
    } else {
      return toast.error('Message cannot be empty !!')
    }
  }

  return (
    <React.Fragment>
      <Modal
        title={'Leave a feedback'}
        toggleModal={setModalVisible}
        isActive={modalVisible}
      >
        <div className="min-h-[22vh] flex flex-col gap-6">
          <div className="px-10">
            <MessageBox message="Your feedback will be recorded on the journal !!" />
          </div>
          <div className="px-10 py-4">
            <Textarea
              name="feedback"
              value={data?.message ?? undefined}
              placeholder="Message"
              onChange={(e: any) => {
                setValue('message', e.target.value)
              }}
              error={errors['message'] && modified['message']}
            />
          </div>
          <div className="flex gap-10 items-center py-16 px-10 justify-end border-t-1 border-gray-200">
            <Button
              title="Cancel"
              onClick={() => {
                setModalVisible(false)
                setValue('message', undefined)
              }}
              buttonColor="text-blue-400 bg-blue-100 hover:bg-blue-150"
            />
            <Button
              title="Save"
              onClick={handleSubmitFeedback}
              loading={ticketFeedbackLoading}
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
