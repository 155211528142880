import { useSelector } from 'react-redux'

import { Map } from 'app/common'
import { AddressComp } from './component'

export const AddressPage = () => {
  const { siteById: site }: RT.SiteReduxType = useSelector(
    (state: any) => state.site
  )

  return (
    <div className="min-h-[85vh] flex flex-col gap-30 relative">
      <div className="text-lg font-bold mt-10">Address</div>

      {!!site?.physical_address_details?.coordinates ? (
        <div className="mb-10 border-1 border-gray-100 rounded-sm">
          <Map centerData={site?.physical_address_details?.coordinates} />
        </div>
      ) : (
        <div className="w-full text-center py-40 text-red-300 mb-10 border-1 border-red-300 rounded-sm bg-red-100">
          Map coordinates not available.
        </div>
      )}

      <AddressComp />
    </div>
  )
}
