import {
  ADD_ATTACHMENTS_TO_TICKET,
  CREATE_TICKETS,
  GET_PROJECT_TICKET_LIST,
  GET_SITE_VISIT_TICKETLIST,
  GET_TICKET_ATTACHMENT_LIST,
  GET_TICKET_DETAILS_BY_ID,
  GET_TICKET_LIST_BY_PROJECTPLAN_ID,
  REMOVE_TICKET,
  REMOVE_TICKET_ATTACHMENTS,
  UPDATE_TICKET,
  SET_MARKER_COORDINATES,
  GET_TICKET_LOG_LIST,
  GET_TICKET_ASSIGNED_EMPLOYEE_LIST,
  TICKET_FEEDBACK,
  DOWNLOAD_TICKET_PLAN_PDF,
  GET_TICKET_UNASSIGNED_EMPLOYEE_LIST,
  GET_LABOUR_ELEMENTS_OF_TICKET,
  CREATE_SUB_TICKET,
  UPDATE_SUB_TICKET,
  REMOVE_SUB_TICKET,
  GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION,
  ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET,
  DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET,
  CREATE_DAYS_WORK_TICKET,
  GET_LABOUR_ELEMENTS_OF_SUB_TICKET,
  GET_LABOUR_ELEMENTS_OF_PARENT_TICKET,
  GET_PARENT_TICKET_DETAILS,
  GET_TICKET_LIST_BY_PROJECT_FORM_ID
} from '../actions'

const initalState: RT.TicktesReduxType = {
  createTicketsLoading: false,
  siteVisitTicketList: undefined,
  siteVisitTicketCount: 0,
  siteVisitTicketListLoading: false,
  ticketListByProjectPlan: undefined,
  ticketListByProjectPlanCount: 0,
  isTicketListLast: false,
  ticketListByProjectPlanLoading: false,
  ticketDetailsById: undefined,
  ticketDetailsBydIdLoading: false,
  updateTicketLoading: false,
  addAttachmentsToTicketLoading: false,
  removeTicketAttachmentLoading: false,
  getTicketAttachmentListLoading: false,
  ticketAttachments: undefined,
  ticketAttachmentsCount: 0,
  removeTicketLoading: false,
  getProjectTicketListLoading: false,
  projectTicketcount: 0,
  projectTicketList: undefined,

  ticketLogList: undefined,
  ticketLogListLoading: false,
  ticketLogListTotal: 0,
  isLastTicketLogList: false,

  ticketAssignedEmployeeListTotal: 0,
  ticketAssignedEmployeeList: undefined,
  ticketAssignedEmployeeListLoading: false,

  unAssignedEmployeeList: undefined,
  unAssignedEmployeeListLoading: false,

  ticketFeedbackLoading: false,

  downloadPlanwithTicketLoading: false,
  downloadPlanwithTicket: undefined,

  markerCoordinates: undefined,
  reportLayerCoordinates: undefined,

  labourElements: undefined,
  labourElementListLoading: false,

  labourElementsOfSubTicket: undefined,
  labourElementsOfSubTicketLoading: false,

  createSubTicketLoading: false,

  updateSubTicketLoading: false,

  removeSubTicketLoading: false,

  ticketLabourElementSubTicket: undefined,
  ticketLabourElementSubTicketLoading: false,

  attachLabourElementToSubTicketLoading: false,

  deattachLabourElementToSubTIcketLoading: false,

  createDaysWorkTicketLoading: false,

  labourElementListOfParentTickets: undefined,
  labourElementListOfParentLoading: false,

  parentTicketDetails: undefined,

  projectTicketListByFormIdLoading: false,
  projectTicketListByFormId: undefined,
  projectTicketListByFormIdTotal: 0
}

export function ticketsReducer(state = initalState, action: any) {
  const {type, payload} = action

  //

  switch (type) {
    case CREATE_TICKETS.LOADING:
      return {...state, createTicketsLoading: true}
    case CREATE_TICKETS.SUCCESS:
      return {
        ...state,
        createTicketsLoading: false
      }
    case CREATE_TICKETS.ERROR:
      return {...state, createTicketsLoading: false}

    case GET_SITE_VISIT_TICKETLIST.LOADING:
      return {
        ...state,
        siteVisitTicketListLoading: true
      }
    case GET_SITE_VISIT_TICKETLIST.SUCCESS:
      return {
        ...state,
        siteVisitTicketListLoading: false,
        siteVisitTicketList: payload.rows,
        siteVisitTicketCount: payload.total
      }
    case GET_SITE_VISIT_TICKETLIST.ERROR:
      return {...state, siteVisitTicketListLoading: false}

    case GET_TICKET_LIST_BY_PROJECTPLAN_ID.LOADING:
      return {
        ...state,
        ticketListByProjectPlanLoading: true
      }
    case GET_TICKET_LIST_BY_PROJECTPLAN_ID.SUCCESS:
      return {
        ...state,
        ticketListByProjectPlanCount: payload.total,
        ticketListByProjectPlanLoading: false,
        ticketListByProjectPlan: payload.rows,
        isTicketListLast: payload.isLast
      }
    case GET_TICKET_LIST_BY_PROJECTPLAN_ID.ERROR:
      return {...state, ticketListByProjectPlanLoading: false}

    case GET_TICKET_DETAILS_BY_ID.LOADING:
      return {
        ...state,
        ticketDetailsById: {},
        ticketDetailsBydIdLoading: true
      }
    case GET_TICKET_DETAILS_BY_ID.SUCCESS:
      return {
        ...state,
        ticketDetailsBydIdLoading: false,
        ticketDetailsById: payload
      }
    case GET_TICKET_DETAILS_BY_ID.ERROR:
      return {...state, ticketDetailsById: false}

    case UPDATE_TICKET.LOADING:
      return {
        ...state,
        updateTicketLoading: true
      }

    case UPDATE_TICKET.SUCCESS:
      return {
        ...state,
        updateTicketLoading: false
      }
    case UPDATE_TICKET.ERROR:
      return {
        ...state,
        updateTicketLoading: false
      }

    case ADD_ATTACHMENTS_TO_TICKET.LOADING:
      return {
        ...state,
        addAttachmentsToTicketLoading: true
      }

    case ADD_ATTACHMENTS_TO_TICKET.SUCCESS:
      return {
        ...state,
        addAttachmentsToTicketLoading: false
      }
    case ADD_ATTACHMENTS_TO_TICKET.ERROR:
      return {
        ...state,
        addAttachmentsToTicketLoading: false
      }

    case REMOVE_TICKET_ATTACHMENTS.LOADING:
      return {
        ...state,
        removeTicketAttachmentLoading: true
      }

    case REMOVE_TICKET_ATTACHMENTS.SUCCESS:
      return {
        ...state,
        removeTicketAttachmentLoading: false
      }
    case REMOVE_TICKET_ATTACHMENTS.ERROR:
      return {
        ...state,
        removeTicketAttachmentLoading: false
      }

    case GET_TICKET_ATTACHMENT_LIST.LOADING:
      return {
        ...state,
        getTicketAttachmentListLoading: true
      }

    case GET_TICKET_ATTACHMENT_LIST.SUCCESS:
      return {
        ...state,
        getTicketAttachmentListLoading: false,
        ticketAttachmentsCount: payload.total,
        ticketAttachments: payload.rows
      }
    case GET_TICKET_ATTACHMENT_LIST.ERROR:
      return {
        ...state,
        getTicketAttachmentListLoading: false
      }

    case REMOVE_TICKET.LOADING:
      return {
        ...state,
        removeTicketLoading: true
      }

    case REMOVE_TICKET.SUCCESS:
      return {
        ...state,
        removeTicketLoading: false
      }
    case REMOVE_TICKET.ERROR:
      return {
        ...state,
        removeTicketLoading: false
      }

    case GET_PROJECT_TICKET_LIST.LOADING:
      return {
        ...state,
        getProjectTicketListLoading: true
      }

    case GET_PROJECT_TICKET_LIST.SUCCESS:
      return {
        ...state,
        getProjectTicketListLoading: false,
        projectTicketcount: payload.total,
        projectTicketList: payload.rows
      }
    case GET_PROJECT_TICKET_LIST.ERROR:
      return {
        ...state,
        getProjectTicketListLoading: false
      }

    case GET_TICKET_LOG_LIST.LOADING:
      return {
        ...state,
        ticketLogListLoading: true
      }

    case GET_TICKET_LOG_LIST.SUCCESS:
      return {
        ...state,
        ticketLogListLoading: false,
        ticketLogList: payload.rows,
        ticketLogListTotal: payload.total,
        isLastTicketLogList: payload.isLast
      }
    case GET_TICKET_LOG_LIST.ERROR:
      return {
        ...state,
        ticketLogListLoading: false
      }

    case GET_TICKET_ASSIGNED_EMPLOYEE_LIST.LOADING:
      return {
        ...state,
        ticketAssignedEmployeeListLoading: true
      }

    case GET_TICKET_ASSIGNED_EMPLOYEE_LIST.SUCCESS:
      return {
        ...state,
        ticketAssignedEmployeeListLoading: false,
        ticketAssignedEmployeeListTotal: payload.total,
        ticketAssignedEmployeeList: payload.rows
      }
    case GET_TICKET_ASSIGNED_EMPLOYEE_LIST.ERROR:
      return {
        ...state,
        ticketAssignedEmployeeListLoading: false
      }

    case TICKET_FEEDBACK.LOADING:
      return {
        ...state,
        ticketFeedbackLoading: true
      }

    case TICKET_FEEDBACK.SUCCESS:
      return {
        ...state,
        ticketFeedbackLoading: false
      }
    case TICKET_FEEDBACK.ERROR:
      return {
        ...state,
        ticketFeedbackLoading: false
      }

    case DOWNLOAD_TICKET_PLAN_PDF.LOADING:
      return {
        ...state,
        downloadPlanwithTicketLoading: true
      }

    case DOWNLOAD_TICKET_PLAN_PDF.SUCCESS:
      return {
        ...state,
        downloadPlanwithTicketLoading: false,
        downloadPlanwithTicket: payload
      }
    case DOWNLOAD_TICKET_PLAN_PDF.ERROR:
      return {
        ...state,
        downloadPlanwithTicketLoading: false
      }

    case SET_MARKER_COORDINATES.SUCCESS:
      return {
        ...state,
        markerCoordinates: payload.markerCoordinates,
        reportLayerCoordinates: payload.reportLayerCoordinates
      }

    case GET_TICKET_UNASSIGNED_EMPLOYEE_LIST.LOADING:
      return {
        ...state,
        unAssignedEmployeeListLoading: true
      }
    case GET_TICKET_UNASSIGNED_EMPLOYEE_LIST.SUCCESS:
      return {
        ...state,
        unAssignedEmployeeListLoading: false,
        unAssignedEmployeeList: payload
      }
    case GET_TICKET_UNASSIGNED_EMPLOYEE_LIST.ERROR:
      return {...state, unAssignedEmployeeListLoading: false}

    case GET_LABOUR_ELEMENTS_OF_TICKET.LOADING:
      return {
        ...state,
        labourElementListLoading: true
      }

    case GET_LABOUR_ELEMENTS_OF_TICKET.SUCCESS:
      return {
        ...state,
        labourElementListLoading: false,
        labourElements: payload
      }
    case GET_LABOUR_ELEMENTS_OF_TICKET.ERROR:
      return {
        ...state,
        labourElementListLoading: false
      }

    case GET_LABOUR_ELEMENTS_OF_SUB_TICKET.LOADING:
      return {
        ...state,
        labourElementsOfSubTicketLoading: true
      }

    case GET_LABOUR_ELEMENTS_OF_SUB_TICKET.SUCCESS:
      return {
        ...state,
        labourElementsOfSubTicketLoading: false,
        labourElementsOfSubTicket: payload
      }
    case GET_LABOUR_ELEMENTS_OF_SUB_TICKET.ERROR:
      return {
        ...state,
        labourElementsOfSubTicketLoading: false
      }

    case GET_LABOUR_ELEMENTS_OF_PARENT_TICKET.LOADING:
      return {
        ...state,
        labourElementListOfParentLoading: true
      }

    case GET_LABOUR_ELEMENTS_OF_PARENT_TICKET.SUCCESS:
      return {
        ...state,
        labourElementListOfParentLoading: false,
        labourElementListOfParentTickets: payload
      }
    case GET_LABOUR_ELEMENTS_OF_PARENT_TICKET.ERROR:
      return {
        ...state,
        labourElementListOfParentLoading: false
      }

    case CREATE_SUB_TICKET.LOADING:
      return {
        ...state,
        createSubTicketLoading: true
      }

    case CREATE_SUB_TICKET.SUCCESS:
      return {
        ...state,
        createSubTicketLoading: false
      }
    case CREATE_SUB_TICKET.ERROR:
      return {
        ...state,
        createSubTicketLoading: false
      }
    case UPDATE_SUB_TICKET.LOADING:
      return {
        ...state,
        updateSubTicketLoading: true
      }

    case UPDATE_SUB_TICKET.SUCCESS:
      return {
        ...state,
        updateSubTicketLoading: false
      }
    case UPDATE_SUB_TICKET.ERROR:
      return {
        ...state,
        updateSubTicketLoading: false
      }
    case REMOVE_SUB_TICKET.LOADING:
      return {
        ...state,
        removeSubTicketLoading: true
      }

    case REMOVE_SUB_TICKET.SUCCESS:
      return {
        ...state,
        removeSubTicketLoading: false
      }
    case REMOVE_SUB_TICKET.ERROR:
      return {
        ...state,
        removeSubTicketLoading: false
      }

    case GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION.LOADING:
      return {
        ...state,
        ticketLabourElementSubTicketLoading: true
      }

    case GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION.SUCCESS:
      return {
        ...state,
        ticketLabourElementSubTicketLoading: false,
        ticketLabourElementSubTicket: payload
      }
    case GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION.ERROR:
      return {
        ...state,
        ticketLabourElementSubTicketLoading: false
      }
    case ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET.LOADING:
      return {
        ...state,
        attachLabourElementToSubTicketLoading: true
      }

    case ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET.SUCCESS:
      return {
        ...state,
        attachLabourElementToSubTicketLoading: false
      }
    case ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET.ERROR:
      return {
        ...state,
        attachLabourElementToSubTicketLoading: false
      }
    case DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET.LOADING:
      return {
        ...state,
        deattachLabourElementToSubTIcketLoading: true
      }

    case DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET.SUCCESS:
      return {
        ...state,
        deattachLabourElementToSubTIcketLoading: false
      }
    case DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET.ERROR:
      return {
        ...state,
        deattachLabourElementToSubTIcketLoading: false
      }
    case CREATE_DAYS_WORK_TICKET.LOADING:
      return {
        ...state,
        createDaysWorkTicketLoading: true
      }

    case CREATE_DAYS_WORK_TICKET.SUCCESS:
      return {
        ...state,
        createDaysWorkTicketLoading: false
      }
    case CREATE_DAYS_WORK_TICKET.ERROR:
      return {
        ...state,
        createDaysWorkTicketLoading: false
      }

    case GET_PARENT_TICKET_DETAILS.SUCCESS:
      return {
        ...state,
        parentTicketDetails: payload
      }
    case GET_TICKET_LIST_BY_PROJECT_FORM_ID.LOADING:
      return {
        ...state,
        projectTicketListByFormIdLoading: true
      }

    case GET_TICKET_LIST_BY_PROJECT_FORM_ID.SUCCESS:
      return {
        ...state,
        projectTicketListByFormIdLoading: false,
        projectTicketListByFormId: payload.rows,
        projectTicketListByFormIdTotal: payload.total
      }
    case GET_TICKET_LIST_BY_PROJECT_FORM_ID.ERROR:
      return {
        ...state,
        projectTicketListByFormIdLoading: false
      }

    default:
      return state
  }
}
