import {
  ADD_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_FILES_AND_PHOTOS,
  UPDATE_CUSTOMER,
  UPLOAD_CUSTOMER_FILES_AND_PHOTOS
} from '../actions'

const initalState: RT.CustomerReduxType = {
  customers: undefined,
  customerTotal: 0,
  add_loading: false,
  add_error: '',

  get_customer_by_id_loading: false,
  customerById: undefined,

  update_customer_loading: false,
  upload_customer_files_loading: false,

  get_customer_files_loading: false,
  customer_files: undefined,

  get_loading: false,
  get_error: ''
}

export function customerReducer(state = initalState, action: any) {
  const {type, payload} = action

  //

  switch (type) {
    case ADD_CUSTOMER.LOADING:
      return {...state, add_loading: true, add_error: false}
    case ADD_CUSTOMER.SUCCESS:
      return {
        ...state,
        add_loading: false,
        add_error: false
      }
    case ADD_CUSTOMER.ERROR:
      return {...state, add_loading: false, add_error: payload}

    case GET_CUSTOMER.LOADING:
      return {
        ...state,
        get_loading: true,
        get_error: false
      }
    case GET_CUSTOMER.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        customers: payload.rows,
        customerTotal: payload.total
      }
    case GET_CUSTOMER.ERROR:
      return {...state, get_loading: false, get_error: payload}

    case UPDATE_CUSTOMER.LOADING:
      return {
        ...state,
        update_customer_loading: true
      }
    case UPDATE_CUSTOMER.SUCCESS:
      return {
        ...state,
        update_customer_loading: false
      }
    case UPDATE_CUSTOMER.ERROR:
      return {...state, update_customer_loading: false}

    case GET_CUSTOMER_BY_ID.LOADING:
      return {
        ...state,
        customerById: {},
        get_customer_by_id_loading: true
      }
    case GET_CUSTOMER_BY_ID.SUCCESS:
      return {
        ...state,
        get_customer_by_id_loading: false,
        customerById: payload
      }
    case GET_CUSTOMER_BY_ID.ERROR:
      return {...state, get_customer_by_id_loading: false, customerById: {}}

    case GET_CUSTOMER_BY_ID.CLEAR:
      return {...state, get_customer_by_id_loading: false, customerById: {}}

    case UPLOAD_CUSTOMER_FILES_AND_PHOTOS.LOADING:
      return {
        ...state,
        upload_customer_files_loading: true
      }

    case UPLOAD_CUSTOMER_FILES_AND_PHOTOS.SUCCESS:
      return {
        ...state,
        upload_customer_files_loading: false
      }
    case UPLOAD_CUSTOMER_FILES_AND_PHOTOS.ERROR:
      return {
        ...state,
        upload_customer_files_loading: false
      }

    case GET_CUSTOMER_FILES_AND_PHOTOS.LOADING:
      return {
        ...state,
        get_customer_files_loading: true
      }

    case GET_CUSTOMER_FILES_AND_PHOTOS.SUCCESS:
      return {
        ...state,
        get_customer_files_loading: false,
        customer_files: payload
      }
    case GET_CUSTOMER_FILES_AND_PHOTOS.ERROR:
      return {
        ...state,
        get_customer_files_loading: false
      }

    default:
      return state
  }
}
