import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import {FaPen} from 'react-icons/fa'

import {ClearableChip, DataTable, DynamicSearchSelect} from 'app/components'
import {Checkbox, Input, SelectField, ToolTip} from 'app/common'
import {getCompanyTimeEntryList} from 'redux-src'
import {useDebounceValue} from 'hooks'
import {CreateTicketModel} from '../ticket'
import {UpdateCompletedDateModal} from '../siteVisits/pages/labour/components'

export const TimesheetPage = () => {
  const {companyTimeEntryList}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project
  )

  const [data, {setValue}] = useFormInput({
    user: undefined,
    ticket: undefined,
    fromDate: undefined,
    toDate: undefined,
    filter: undefined,
    filterValue: undefined,
    siteVisit: undefined
  })

  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  const debounceTicketName = useDebounceValue(data?.ticket)
  const debounceSiteVisitName = useDebounceValue(data?.siteVisit)

  const companyTimeSheetList = useMemo(() => {
    if (companyTimeEntryList) {
      const remappedList = companyTimeEntryList?.map((timeEntry) => {
        return {
          id: timeEntry?.project_labour_entry_details?.id,
          projectName: timeEntry?.project_details?.title,
          projectPrefix: timeEntry?.project_details?.project_prefix,
          projectNumber: timeEntry?.project_details?.project_number,
          projectId: timeEntry?.project_details?.id,
          siteVisitName: timeEntry?.site_visit_details?.title,
          siteVisitId: timeEntry?.site_visit_details?.id,
          ticketTitle: timeEntry?.ticket_details?.title,
          ticketId: timeEntry?.ticket_details?.id,
          labourElement: timeEntry?.labour_element_details?.name,
          userName: [
            timeEntry?.user_details?.display_name,
            timeEntry?.user_details?.lastname
          ].join(' '),
          userId: timeEntry?.user_details?.id,
          timeMins: timeEntry?.project_labour_entry_details?.time_mins,
          isInvoiced: timeEntry?.project_labour_entry_details?.is_invoiced,
          isWorkerPaid: timeEntry?.project_labour_entry_details?.is_worker_paid,
          completedAt: timeEntry?.project_labour_entry_details?.completed_at
        }
      })

      return remappedList
    } else {
      return []
    }
  }, [companyTimeEntryList])

  const fetchProjectTimesheetList = useCallback(
    (page?: number, limit?: number) => {
      const fromDate = data?.fromDate
        ? moment(data?.fromDate).format('YYYY-MM-DD')
        : undefined

      const toDate = data?.toDate
        ? moment(data?.toDate).format('YYYY-MM-DD')
        : undefined

      const filterValue = data.filterValue?.reduce(
        (
          acc: {
            isWorkerPaid?: 'true' | 'false'
            isInvoiced?: 'true' | 'false'
          },
          curr: {
            id: number
            label: string
            value: 'isWorkerPaid' | 'isInvoiced'
            isChecked: boolean
          }
        ) => {
          const newAcc = {...acc, [curr.value]: curr.isChecked}
          return newAcc
        },
        {}
      )

      const params = {
        page: page ?? 1,
        limit: limit ?? 10,
        fromDate,
        toDate,
        ...filterValue,
        userId: data?.user?.id,
        projectId: projectId,
        siteVisitName:
          debounceSiteVisitName?.length === 0
            ? undefined
            : debounceSiteVisitName,
        ticketName:
          debounceTicketName?.length === 0 ? undefined : debounceTicketName
      }

      dispatch(getCompanyTimeEntryList(params, () => {}))
    },
    [
      data.filterValue,
      data?.fromDate,
      data?.toDate,
      data?.user?.id,
      debounceSiteVisitName,
      debounceTicketName,
      dispatch,
      projectId
    ]
  )

  useEffect(() => {
    fetchProjectTimesheetList()
  }, [fetchProjectTimesheetList])

  const handleCheckboxChange = (tempItem: {
    id: number
    label: string
    isChecked: false
  }) => {
    const finalFilterValue = data?.filterValue?.map((item: any) => {
      if (item?.id === tempItem?.id) {
        return {
          ...item,
          isChecked: !item?.isChecked
        }
      } else {
        return {...item}
      }
    })

    setValue('filterValue', finalFilterValue)
  }
  return (
    <div className="flex flex-col gap-14 min-h-[80vh]">
      <div className="text-lg font-bold text-black">Timesheet</div>
      <div>
        <div className={`flex items-start gap-10`}>
          <div className="flex flex-1 flex-col justify-start items-start gap-4">
            <Input
              containerClass="flex flex-1 items-center w-full relative"
              className="w-full flex-1"
              placeholder="Search by sitevisit"
              value={!!data?.siteVisit ? data?.siteVisit : ''}
              onChange={(e) => {
                setValue('siteVisit', e.target.value)
              }}
            />
            <ClearableChip
              filteredValue={data?.siteVisit}
              isClearable
              handleClearValue={() => {
                setValue('siteVisit', undefined)
              }}
            />
          </div>
          <div className="flex flex-1 flex-col gap-4">
            <DynamicSearchSelect
              actionUrl={`/projects/${projectId}/users`}
              remapOptions={(data: Api.ProjectUserListIndividual[]) => {
                return data?.map(({user_details}) => {
                  return {
                    id: user_details?.id,
                    label: user_details?.display_name,
                    value: user_details?.id
                  }
                })
              }}
              onChangeValue={(data) => {
                setValue('user', data)
              }}
              placeholder="Search by user"
            />

            <ClearableChip
              filteredValue={data?.user?.label}
              isClearable
              handleClearValue={() => {
                setValue('user', undefined)
              }}
            />
          </div>
          <div className="flex flex-1 flex-col gap-4">
            <Input
              containerClass="flex flex-1 items-center w-full relative"
              className="w-full flex-1"
              placeholder="Search by ticket title"
              value={!!data?.ticket ? data?.ticket : ''}
              onChange={(e) => {
                setValue('ticket', e.target.value)
              }}
            />

            <ClearableChip
              filteredValue={data?.ticket}
              isClearable
              handleClearValue={() => {
                setValue('ticket', undefined)
              }}
            />
          </div>
          <div className="flex flex-1 w-full items-start justify-start gap-8">
            <div className="flex flex-1 flex-col w-full gap-4">
              <ReactDatePicker
                selected={data?.fromDate ? new Date(data?.fromDate) : undefined}
                onChange={(date: Date) =>
                  setValue('fromDate', moment(date).toISOString())
                }
                className="py-6 pl-4 rounded-sm border-1 border-gray-200 w-full"
                placeholderText="From Date"
                maxDate={new Date(data?.toDate)}
              />
              <ClearableChip
                filteredValue={data?.fromDate?.split('T')?.at(0)}
                isClearable
                handleClearValue={() => {
                  setValue('fromDate', undefined)
                }}
              />
            </div>
            <div className="mt-6">
              <HiOutlineArrowNarrowRight size={20} />
            </div>
            <div className="flex flex-1 flex-col w-full gap-4">
              <ReactDatePicker
                selected={data?.toDate ? new Date(data?.toDate) : undefined}
                onChange={(date) => {
                  setValue('toDate', moment(date).toISOString())
                }}
                className="py-6 pl-4 rounded-sm border-1 border-gray-200 w-full"
                placeholderText="To Date"
                minDate={new Date(data?.fromDate)}
              />

              <ClearableChip
                filteredValue={data?.toDate?.split('T')?.at(0)}
                isClearable
                handleClearValue={() => {
                  setValue('toDate', undefined)
                }}
              />
            </div>
          </div>
          <div className="flex flex-1 items-center">
            <SelectField
              options={filterOptions}
              multiValue={data?.filter}
              onMultiChangeValue={(data) => {
                const prepopulatedCheck = data?.map((item: any) => {
                  return {
                    ...item,
                    isChecked: true
                  }
                })
                setValue('filterValue', prepopulatedCheck)
                setValue('filter', prepopulatedCheck)
              }}
              isMulti
              placeholder="Select more filter options..."
            />
          </div>
        </div>
        {!!data?.filterValue && (
          <div className="flex gap-10 mt-4">
            {data?.filterValue?.map((item: any, index: number) => {
              return (
                <Checkbox
                  key={index}
                  label={item?.label}
                  dataId={`filter-${item?.label}`}
                  isChecked={item?.isChecked}
                  onChange={() => {
                    handleCheckboxChange(item)
                  }}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-10 mt-20">
        <SelectedLabourEntryList
          companyTimeSheetList={companyTimeSheetList}
          fetchProjectTimesheetList={fetchProjectTimesheetList}
        />
      </div>
    </div>
  )
}

const SelectedLabourEntryList = ({
  companyTimeSheetList,
  fetchProjectTimesheetList
}: {
  companyTimeSheetList: any
  fetchProjectTimesheetList: (page?: number, limit?: number) => void
}) => {
  const {companyTimeEntryTotal, companyTimeEntryLoading}: RT.ProjectReduxType =
    useSelector((state: any) => state.project)

  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [selectedRowsDetails, setRowsDetails] = useState<any>()

  const [isTicketModalVisible, setTicketModalVisible] = useState<boolean>(false)
  const [ticketId, setTicketId] = useState<number>()

  const {
    navigation: {navigate},
    params
  } = useNavigation()

  const {projectId} = params as any

  const columns: Array<TableColumn<any>> = [
    {
      name: 'Site visit',
      selector: (row: any) => row.siteVisitName,
      sortable: true,
      cell: (row: any) => (
        <div
          className="text-blue-200 cursor-pointer  items-center justify-center py-4 mr-1 px-6 leading-none"
          onClick={() => {
            navigate(`/projects/${projectId}/site-visit/${row.siteVisitId}`)
          }}
        >
          {row.siteVisitName}
        </div>
      )
    },
    {
      name: 'Username',
      selector: (row: any) => row.userName,
      sortable: true,
      cell: (row: any) => (
        <div
          className="text-blue-200 cursor-pointer items-center justify-center py-4 mr-1 px-6 leading-none"
          onClick={() => {
            navigate(`/settings/users/${row.userId}`)
          }}
        >
          {row.userName}
        </div>
      )
    },
    {
      name: 'Ticket Title',
      selector: (row: any) => row.ticketTitle,
      sortable: true,
      cell: (row: any) => (
        <div
          className="text-blue-200 cursor-pointer items-center justify-center py-4 mr-1 px-6 leading-none"
          onClick={() => {
            setTicketId(row.ticketId)
            setTicketModalVisible(true)
          }}
        >
          {row.ticketTitle}
        </div>
      )
    },
    {
      name: 'Labour Element',
      selector: (row: any) => row.labourElement,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row.labourElement}
        </span>
      )
    },
    {
      name: 'Time Mins',
      selector: (row: any) => row.timeMins,
      sortable: true,
      wrap: true,
      style: {
        width: 100
      },
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row.timeMins}
        </span>
      )
    },
    {
      name: 'Completed At',
      selector: (row: any) => row.completedAt,
      sortable: true,
      cell: (row: any) => (
        <div className="flex justify-between">
          <span className="text-sm text-[#333]">
            {moment(row?.completedAt ?? Date.now()).format(
              'ddd, DD MMM YYYY, hh:mm a'
            )}
          </span>
          <ToolTip text="Update Date" top>
            <button
              className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
              onClick={() => {
                setModalVisible(true)
                setRowsDetails(row)
              }}
            >
              <span className="text-left inline-flex cursor-pointer">
                <FaPen size={12} className="cursor-pointer text-gray-400" />
              </span>
            </button>
          </ToolTip>
        </div>
      )
    },
    {
      name: 'Invoiced',
      selector: (row: any) => row.isInvoiced,
      sortable: true,
      wrap: true,
      center: true,
      style: {
        width: 100
      },
      cell: (row: any) => (
        <div className="flex w-full items-stretch justify-center">
          {getStatusChip(row.isInvoiced ? 'Paid' : 'Unpaid')}
        </div>
      )
    },
    {
      name: 'Worker Paid',
      selector: (row: any) => row.isWorkerPaid,
      wrap: true,
      center: true,
      style: {
        width: 100
      },
      sortable: true,
      cell: (row: any) => (
        <div className="flex w-full items-stretch justify-center">
          {getStatusChip(row.isWorkerPaid ? 'Paid' : 'Unpaid')}
        </div>
      )
    }
  ]

  return (
    <div className="">
      <DataTable
        columns={columns}
        data={companyTimeSheetList ?? []}
        totalRows={companyTimeEntryTotal}
        fetchData={fetchProjectTimesheetList}
        loading={companyTimeEntryLoading}
        pagination
      />
      <UpdateCompletedDateModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        data={selectedRowsDetails}
        fetchLabourTimeEntry={fetchProjectTimesheetList}
      />
      {isTicketModalVisible && (
        <CreateTicketModel
          key={'Ticket Model From TimeSheet '}
          modalId="Ticket Model From TimeSheet"
          isModelVisible={isTicketModalVisible}
          setIsModelVisible={setTicketModalVisible}
          ticketIdFromSitevisit={ticketId}
          edit
        />
      )}
    </div>
  )
}

const filterOptions: Array<{
  id: number
  label: string
  value: string
  isChecked: boolean | undefined
}> = [
  {
    id: 1,
    label: 'Invoiced Entry',
    value: 'isInvoiced',
    isChecked: undefined
  },
  {
    id: 2,
    label: 'WorkerPaid Entry',
    value: 'isWorkerPaid',
    isChecked: undefined
  }
]

export const getStatusChip = (status?: string) => {
  switch (!!status) {
    case status === 'Unpaid':
      return (
        <span className="text-[10px] h-[24px] flex items-center justify-center font-bold bg-[#ffb500] text-white px-12 py-4 rounded-md w-[max-content]">
          {status.toUpperCase()}
        </span>
      )
    case status === 'Paid':
      return (
        <span className="text-[10px] h-[24px] flex items-center justify-center font-bold bg-[#76cc00] text-white px-12 py-4 rounded-md w-[max-content]">
          {status.toUpperCase()}
        </span>
      )
    default:
      return <span></span>
  }
}
