import {useEffect, useMemo, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {FaSearch} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import colors from 'tailwindcss/colors'
import {useFormInput} from 'use-form-input'
import moment from 'moment'

import {Button, Input} from 'app/common'
import {getProjectUserGroupList, removeProjectUserGroupAction} from 'redux-src'
import {SettingsHeader} from 'app/pages/settings/components'
import {ProjectGroupModal, GroupTable} from './../../components'
import {useDebounceValue} from 'hooks'

export const ProjectMainGroupPage = () => {
  const {projectUserGroupList}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const {
    navigation: {navigate, routes},
  } = useNavigation()

  //* FORM STATES
  const [filterData, filterHandler] = useFormInput({
    filterValue: undefined,
  })

  const debounceFilterValue = useDebounceValue(filterData?.filterValue)

  const [newGroupModal, setNewGroupModal] = useState(false)

  const onEditGroup = (id: number) => {
    navigate(routes['Groups'].path + '/edit-group/' + id)
  }

  const onRemoveGroupFromProject = (groupId: number) => {
    const body = {
      userGroupIds: [groupId],
    }

    dispatch(
      removeProjectUserGroupAction(projectId, body, () => {
        dispatch(getProjectUserGroupList(projectId, 1, 10, ''))
      }),
    )
  }

  const newProjectGroupList = useMemo(() => {
    const finalUserGroupList = projectUserGroupList?.map((group) => {
      return {
        id: group?.user_group_details?.id,
        name: group?.user_group_details?.name,
        dateCreated: moment(group?.user_group_details?.created_at),
        usersCount: group?.users_count,
        colorCode: 'blue',
      }
    })

    return finalUserGroupList
  }, [projectUserGroupList])

  useEffect(() => {
    dispatch(
      getProjectUserGroupList(projectId, 1, 10, debounceFilterValue, () => {}),
    )
  }, [dispatch, projectId, debounceFilterValue])

  return (
    <div className="flex flex-col min-h-[70vh]">
      <SettingsHeader
        title="Groups"
        // overview
        // help
        subHeader="The Group list allows you to organise your users into different
            groups to help you better manage business units."
      />

      <div className="flex items-center justify-between py-10">
        <Input
          name="filterValue"
          type="text"
          // size="sm"
          prepend={<FaSearch size={16} color={colors.gray[500]} />}
          containerClass="w-[300px]"
          placeholder="Search group by name"
          className="w-full text-sm"
          value={filterData.filterValue}
          onChange={filterHandler.onChange}
        />

        <Button
          className=""
          size="sm"
          title="Add new group"
          onClick={() => setNewGroupModal(true)}
        />
      </div>
      <div className="border-b-1 border-gray-200 my-10" />
      <GroupTable
        onEditGroup={onEditGroup}
        onRemoveGroupFromProject={onRemoveGroupFromProject}
        groups={newProjectGroupList}
        debounceFilterValue={debounceFilterValue}
      />

      <ProjectGroupModal
        newGroupModal={newGroupModal}
        setNewGroupModal={setNewGroupModal}
      />
    </div>
  )
}
