import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'app/common'

import {
  AdditionalContactTable,
  SiteContactBlock,
  ContactModal,
} from './components'

export const SiteContactsPage = () => {
  const [isModalActive, setModalActive] = useState<boolean>(false)
  const [isEditModalActive, setEditModalActive] = useState<boolean>(false)

  const { siteById: site }: RT.SiteReduxType = useSelector(
    (state: any) => state.site
  )

  const remappedOtherContacts = useMemo(() => {
    return (
      site?.other_contacts?.map((contact) => ({
        id: contact.id,
        name: `${contact.firstname} ${contact.lastname}`,
        title: contact.position,
        phone: contact.phones,
        email: contact.emails,
      })) ?? []
    )
  }, [site])

  return (
    <div>
      <div className="flex justify-between items-center py-10 pb-20">
        <div className="flex items-center gap-6">
          <span className="font-bold text-lg">Contacts</span>
        </div>
        <div
          className="flex items-center gap-6"
          onClick={() => setModalActive((prev) => !prev)}
        >
          <Button title="Add New Contact" />
        </div>
      </div>
      <SiteContactComp />
      <AdditionalContactTable
        data={remappedOtherContacts}
        isModalActive={isEditModalActive}
        setModalActive={setEditModalActive}
      />
      <div className="mb-30" />
      <ContactModal {...{ isModalActive, setModalActive }} />
      <ContactModal
        isModalActive={isEditModalActive}
        setModalActive={setEditModalActive}
        editMode
      />
    </div>
  )
}

const SiteContactComp = () => {
  const { siteById: site }: RT.SiteReduxType = useSelector(
    (state: any) => state.site
  )

  return (
    <div className="flex flex-row flex-nowrap gap-20 w-[100%]">
      {site?.default_contact_details?.id ===
      site?.billing_contact_details?.id ? (
        <>
          <SiteContactBlock
            type={'both'}
            contactId={site?.default_contact_details?.id}
          />
          <div className="flex flex-1" />
        </>
      ) : (
        <>
          {site?.default_contact_details?.id && (
            <SiteContactBlock
              type={'default'}
              contactId={site?.default_contact_details?.id}
            />
          )}
          {site?.billing_contact_details?.id && (
            <SiteContactBlock
              type={'billing'}
              contactId={site?.billing_contact_details?.id}
            />
          )}
        </>
      )}
    </div>
  )
}
