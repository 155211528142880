import {useCallback, useEffect, useMemo, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {FaPen, FaTrash} from 'react-icons/fa'

import {DataTable} from 'app/components'
import {ConfirmationModal} from 'app/common'

import {
  deleteItemAction,
  getItemList,
  getItemListBySupplierIdAction,
} from 'redux-src'

import {AddItemModal} from '../addItem'

// import { colors } from 'modules'

export const ItemManagementTable = ({
  search,
  supplierItemList,
  supplierData,
}: {
  search?: string
  supplierItemList?: any
  supplierData?: Com.SelectOption
}) => {
  const {toast} = useAuth()

  const dispatch = useDispatch()
  // const [newItemList, setNewItemList] = useState<
  //   Array<{
  //     id: number
  //     name: string
  //     cost: number
  //     supplierName: string
  //     fixingValue: number
  //     materialCost: number
  //     attributes: object
  //     itemTypeId: number
  //     itemCategoryId: number
  //     labourElementId?: number
  //     labourElementGroupId?: number
  //   }>
  // >([])

  const [newItem, setNewItem] = useState(false)

  const [itemModalKey, setItemModalKey] = useState<number>(0)

  const [itemDetails, setItemDetails] = useState<Comp.ItemDetailsForModal>()

  const {
    itemList,
    itemTotal,
    getItemListLoading,
    deleteItemLoading,
  }: RT.ItemReduxType = useSelector((state: any) => state.itemManagement)

  const getItem = useCallback(
    (page?: number, limit?: number) => {
      !!supplierData
        ? dispatch(
            getItemListBySupplierIdAction(Number(supplierData?.id), {
              page,
              limit,
              search,
            }),
          )
        : dispatch(getItemList({page, limit, search}))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, search],
  )

  const newItemList = useMemo(() => {
    if (!!!itemList) return []

    return itemList?.map(
      ({
        item_details,
        supplier_details,
        item_type_details,
        labour_element_details,
        item_category_details,
        labour_element_group_details,
        material_element_details,
        material_element_group_details,
      }) => {
        return {
          id: item_details?.id,
          name: item_details?.name,
          cost: item_details?.cost,
          fixingValue: item_details?.fixing_value,
          materialCost: item_details?.material_cost,
          supplierName: supplier_details?.name,
          supplierId: supplier_details?.id,
          attributes: item_details?.attributes,
          itemTypeId: item_details?.item_type_id,
          itemCategoryId: item_details?.item_category_id,
          labourElementId: item_details?.labour_element_group_id
            ? undefined
            : item_details?.labour_element_id,
          labourElementGroupId: item_details?.labour_element_group_id,
          materialElementId: material_element_details?.id,
          materialElementGroupId: material_element_group_details?.id,
          item_type_details,
          labour_element_details,
          labour_element_group_details,
          item_category_details,
          supplier_details,
          materialElementDetails: material_element_details,
          materialElementGroupDetails: material_element_group_details,
          totalMaterialUnits: item_details?.total_material_units,
        }
      },
    )

    // return setNewItemList(finalList)
  }, [itemList])

  const handleRemoveItem = (itemId: number | string) => {
    dispatch(deleteItemAction(itemId, toast, getItem))
  }

  useEffect(() => {
    getItem()
  }, [getItem])

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.name,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center text-blue-400  py-4 mr-1   px-6 leading-none ">
          {row.name}
        </span>
      ),
    },

    {
      name: 'Supplier',
      selector: (row: any) => row.supplierName,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row.supplierName}
        </span>
      ),
    },
    {
      name: 'Material Cost',
      selector: (row: any) => row.materialCost,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row.materialCost}
        </span>
      ),
    },
    {
      name: 'Fixing Cost',
      selector: (row: any) => row.fixingValue,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row.fixingValue}
        </span>
      ),
    },
    {
      name: 'Total Cost',
      selector: (row: any) => row.cost,
      sortable: true,
      cell: (row: any) => (
        <div
          className="flex badge items-center justify-between text-sm"
          key={`item-management-edit-${row?.id}`}
        >
          <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
            {row.cost}
          </span>

          <div className="flex justify-between items-center gap-x-10">
            <button
              className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
              onClick={() => {
                setItemDetails(row)
                setItemModalKey(row?.id)
                setNewItem(true)
              }}
            >
              <span className="text-left inline-flex cursor-pointer">
                <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
              </span>
            </button>

            <ConfirmationModal
              loading={deleteItemLoading}
              onConfirmClick={(closeModalHandler) => {
                handleRemoveItem(row.id)
                closeModalHandler()
              }}
              danger
              label={'Are you sure you want to delete this Item?'}
              displayElement={
                <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                  <span className="text-left inline-flex cursor-pointer">
                    <FaTrash fill="red" size={12} />
                  </span>
                </button>
              }
              confirmLabel="Delete Item"
            ></ConfirmationModal>
          </div>
        </div>
      ),
    },
  ]

  console.log({supplierItemList, selectedItem: itemDetails})

  return (
    <>
      <DataTable
        columns={columns}
        data={supplierItemList ?? newItemList ?? []}
        pagination
        totalRows={itemTotal}
        fetchData={getItem}
        loading={getItemListLoading}
      />
      {newItem && (
        <AddItemModal
          key={itemModalKey}
          addItemModal={newItem}
          setAddItemModal={setNewItem}
          close={() => {
            setNewItem(false)
            setItemDetails(undefined)
            setItemModalKey(0)
          }}
          mode="edit"
          itemDetails={itemDetails}
          supplierData={supplierData}
        />
      )}
    </>
  )
}
