import React, {useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'

import {Button, Input} from 'app/common'
import {useDebounceValue} from 'hooks'

import {MaterialGroupTable} from '../materialGroupTable'
import {AddMaterialGroup} from '../addMaterialGroup'
import {SettingsHeader} from 'app/pages/settings/components'

const MaterialElementGroupComp = () => {
  const [searchGroup, setSearchGroup] = useState<string>()

  const [addMaterialGroupModal, setAddMaterialGroupModal] =
    useState<boolean>(false)

  const debouncedSearchValue = useDebounceValue(searchGroup)

  const handleMaterialGroupModalOpen = () => {
    setAddMaterialGroupModal((prev) => !prev)
  }

  return (
    <div>
      <SettingsHeader
        title={'Material Group'}
        append={
          <Button
            title="Create Material Group"
            onClick={handleMaterialGroupModalOpen}
            type={'button'}
          />
        }
        subHeader={`The group list allows you to organize your materials into different
        groups`}
      />

      <div className="flex items-center justify-between my-20">
        <Input
          name="filter groups"
          type="text"
          // size="sm"
          prepend={<FaSearch size={16} color={colors.gray[500]} />}
          containerClass="w-[300px]"
          placeholder="Search groups"
          className="w-full text-sm"
          onChange={(e: any) => {
            setSearchGroup(e.target.value)
          }}
          value={searchGroup}
        />
      </div>

      <div className="my-10 mb-24 border-b-1 border-gray-200" />
      <MaterialGroupTable searchGroup={debouncedSearchValue} />

      <AddMaterialGroup
        addMaterialGroupModal={addMaterialGroupModal}
        setAddMaterialGroupModal={setAddMaterialGroupModal}
      />
    </div>
  )
}

export const MaterialElementGroup = React.memo(MaterialElementGroupComp)
