import {TableColumn} from 'react-data-table-component'
import {MdLocationPin} from 'react-icons/md'
import {HiDotsHorizontal} from 'react-icons/hi'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'

import {DataTable} from 'app/components'
import {Dropdown} from 'app/common'
import {
  alterContactTypes,
  deleteSiteContact,
  getSiteById
  // getSiteContact,
} from 'redux-src'

interface ContactListRow {
  name: string
  title: string
  phone: Array<string>
  email: Array<string>
  id: number
}

export const AdditionalContactTable = ({
  data,
  isModalActive,
  setModalActive
}: {
  data: Array<ContactListRow>
  isModalActive: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const dispatch = useDispatch()
  const {
    navigation: {navigate}
  } = useNavigation()

  const {siteById: site}: RT.SiteReduxType = useSelector(
    (state: any) => state.site
  )

  const handleAlterContactType = (
    contactType: 'other-contacts' | 'main-contact',
    setAs: 'default' | 'billing',
    contactId: number
  ) => {
    dispatch(
      alterContactTypes(
        site?.site_details?.id,
        site?.site_details?.info,
        contactId,
        contactType,
        setAs,
        () => {
          if (site?.site_details?.id)
            dispatch(getSiteById(site?.site_details?.id))
        }
      )
    )
  }

  const deleteContact = (contactId: number) => {
    dispatch(
      deleteSiteContact(
        site?.site_details?.id,
        site?.site_details?.info,
        contactId,
        () => {
          if (site?.site_details?.id)
            dispatch(getSiteById(site?.site_details?.id))
        }
      )
    )
  }

  // const getSiteContacts = (page: number, limit: number) => {
  //   // dispatch(getSiteById(page))
  // }

  const columns: TableColumn<ContactListRow>[] = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,

      cell: (row) => (
        <div className="text-sm flex flex-row flex-nowrap items-center text-blue-300">
          <MdLocationPin size={16} /> {row.name}
        </div>
      )
    },
    {
      name: 'Title / Position',
      sortable: true,

      selector: (row) => row.title,
      cell: (row) => <div className="text-sm">{row.title}</div>
    },
    {
      name: 'Phone',
      selector: (row) => row.id,

      sortable: true,
      cell: (row) => (
        //   <Link
        //     to={`/projects/${row.id}/jobs-summary`}
        //     className="text-sm font-bold text-blue-300 cursor-pointer"
        //   >
        //     {row.subject}
        //   </Link>
        <div className="text-sm ">{row?.phone?.join(', ')}</div>
      )
    },
    {
      name: 'Email',
      selector: (row) => row.id,

      sortable: true,
      cell: (row) => (
        //   <Link
        //     to={`/projects/${row.id}/jobs-summary`}
        //     className="text-sm font-bold text-blue-300 cursor-pointer"
        //   >
        //     {row.subject}
        //   </Link>
        <div className="text-sm flex flex-row flex-nowrap items-center justify-between">
          {row?.email?.join(', ')}
          <Dropdown
            withPortal
            placement="topright"
            triggerElement={
              <button className="p-10 py-6 bg-grey-100 shadow-sm rounded-sm border-1 transition-all duration-200 ease-in-out border-none hover:bg-grey-200">
                <HiDotsHorizontal size={20} />
              </button>
            }
          >
            <div style={{minWidth: '12rem', zIndex: 1}}>
              <div className=" w-full mt-10 bg-white">
                {/* <DropDownFiles /> */}

                <div
                  className="flex-1 w-full   overflow-visible "
                  style={{width: 200}}
                >
                  <ul className=" border-gray-200 rounded-sm shadow-xl overflow-auto ">
                    <li
                      className={`p-10 pl-20 cursor-pointer  bg-white text-gray-500 hover:bg-blue-100 text-[12px]`}
                      onClick={() => {
                        navigate(
                          `/sites/view/${site?.site_details?.id}?contactId=${row.id}&contactType=other`
                        )
                        setModalActive(true)
                      }}
                    >
                      <span className="text-left inline-flex items-center gap-4">
                        Edit
                      </span>
                    </li>
                    <li
                      className={`p-10 pl-20 text-[12px] ${'bg-white hover:bg-blue-100 text-gray-500 cursor-pointer'}`}
                      onClick={() =>
                        handleAlterContactType(
                          'other-contacts',
                          'default',
                          row.id
                        )
                      }
                    >
                      <span className="text-left inline-flex items-center gap-4">
                        Set as Default Contact
                      </span>
                    </li>
                    <li
                      className={`p-10 pl-20 text-[12px] ${'bg-white hover:bg-blue-100 text-gray-500 cursor-pointer'}`}
                      onClick={() =>
                        handleAlterContactType(
                          'other-contacts',
                          'billing',
                          row.id
                        )
                      }
                    >
                      <span className="text-left inline-flex items-center gap-4">
                        Set as Billing Contact
                      </span>
                    </li>
                    <li
                      className={`p-10 pl-20 cursor-pointer  bg-white text-gray-500 hover:bg-blue-100 text-[12px]`}
                      onClick={() => deleteContact(row.id)}
                    >
                      <span className="text-left inline-flex items-center gap-4">
                        Remove Contact
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      )
    }
  ]

  return (
    <div className="flex flex-col mt-30 gap-10">
      <div className="text-md font-bold capitalize">
        Additional Site Contacts
      </div>
      <DataTable
        data={data}
        columns={columns}
        // pagination
        // fetchData={getSiteContacts}
      />
    </div>
  )
}
