import {useState, useEffect} from 'react'
import {Auth, withNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'

import {publicPaths, privatePaths} from './routes.app'
import {userRoles, userType} from './userRoles.app'
import {
  userAuthAction,
  userAuthLogoutAction,
  getSelectOptionsFromAPI,
  getCompanyDetails,
} from '../redux-src'
import {removeCookie, setCookie, getCookie} from '../helpers'

import {Toast, toast} from '../app/common'
import {Header} from './components'
import {useMedia} from 'hooks'

const MemoChild = ({children}: {children: any}) => {
  const dispatch = useDispatch()
  const [authLoading, setAuthLoading] = useState(true)
  const userId = getCookie('userId')
  const {userDetails}: RT.UserReduxType = useSelector(
    (state: any) => state.user,
  )

  const {companyDetails, currencySymbol}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )

  const {lg} = useMedia()

  const [config, setConfig] = useState({
    isLoggedIn: false,
    userRole: userType.USER,
  })

  const [sideNavExpanded, setSideNavExpanded] = useState(true) // for collapsible sidenav
  const [sideMenuStable, setSideMenuStable] = useState(sideNavExpanded)

  const loginSuccess = (role: any) => {
    setConfig({
      isLoggedIn: true,
      userRole: role,
    })
  }

  const loginFailure = () => {
    setConfig({
      isLoggedIn: false,
      userRole: userType.USER,
    })
  }

  useEffect(() => {
    dispatch(getSelectOptionsFromAPI())
    const userGetCallBack = () => {
      dispatch(getCompanyDetails())
    }

    setAuthLoading(true)
    dispatch(
      userAuthAction(
        setAuthLoading,
        loginSuccess,
        loginFailure,
        userId,
        userGetCallBack,
        () => {
          removeCookie('token')
          removeCookie('userId')
          removeCookie('userRole')
          loginFailure()
        },
      ),
    )
  }, [userId, dispatch])

  if (authLoading) {
    return <div>Redirecting...</div>
  }

  return (
    <Auth
      config={config}
      state={{
        userDetails,
        companyDetails,
        currencySymbol,
        toast,
        sideNavExpanded,
        setSideNavExpanded,
        sideMenuStable,
        setSideMenuStable,
        handleLogin: (userId: any, role: any, token: any) => {
          setCookie('userId', userId)
          setCookie('token', token)
          setCookie('userRole', role)
          loginSuccess(role)
        },
        handleLogout: () => {
          dispatch(
            userAuthLogoutAction(() => {
              removeCookie('token')
              removeCookie('userId')
              removeCookie('userRole')
              toast.success('User logged out successfully!')
              loginFailure()
            }),
          )
        },
      }}
    >
      <div className={`${!lg ? 'overflow-hidden' : ''}`} id="auth-children">
        {children}
      </div>
      <Toast />
    </Auth>
  )
}

const App = () => {
  return (
    <MemoChild>
      {
        <>
          <Header />
          <Auth.Screens />
        </>
      }
    </MemoChild>
  )
}

export default withNavigation(App, {
  publicPaths,
  privatePaths,
  userRoles,
  routerType: 'hash',
})
