import {useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {FaHourglassEnd, FaHourglassStart} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import moment from 'moment'

import {DataTable} from 'app/components'
import {Checkbox, DateInput, Dropdown, Input, ToolTip} from 'app/common'
import {getProjectListByDefinedId} from 'redux-src'
import {getConcatenatedValue, handleMultiCheckbox} from 'helpers'
import {useDebounceValue} from 'hooks'

interface ProjectListRow {
  project: string
  projectType: string
  projectStatus: string
  title: string
  customer: string
  dateCreated: string
  variations: string
  id: number
  customerId: number
}

const columns: TableColumn<ProjectListRow>[] = [
  {
    name: 'Project',
    selector: (row) => row.project,
    sortable: true,

    cell: (row) => (
      <Link
        to={`/projects/${row.id}/projects-summary`}
        className="text-sm font-bold text-blue-300 cursor-pointer"
      >
        {row.project}
      </Link>
    ),
  },
  {
    name: 'Project Type',
    selector: (row) => row.projectType,
    sortable: true,

    cell: (row) => (
      <div className="text-xs font-bold bg-blue-300 text-white px-6 py-4 rounded-full uppercase w-[max-content]">
        {row.projectType}
      </div>
    ),
  },
  {
    name: 'Project Status',
    selector: (row) => row.projectStatus,

    cell: (row) => (
      <div className="text-xs font-bold bg-blue-300 text-white px-6 py-4 rounded-full uppercase w-[max-content]">
        {row.projectStatus}
      </div>
    ),
  },
  {
    name: 'Title',
    selector: (row) => row.title,
    cell: (row) => (
      <Link
        to={`/projects/${row.id}/projects-summary`}
        className="text-sm text-blue-300 cursor-pointer"
      >
        {row.title}
      </Link>
    ),
  },
  {
    name: 'Customer',
    selector: (row) => row.customer,

    sortable: true,
    cell: (row) => (
      <Link
        to={`/customers/view/${row.customerId}`}
        className="text-sm text-blue-300 cursor-pointer"
      >
        {row.customer}
      </Link>
    ),
  },
  {
    name: 'Date',
    sortable: true,

    selector: (row) => row.dateCreated,
    cell: (row) => (
      <div className="text-sm">
        <div className="text-sm">
          {moment(row.dateCreated).format('DD/MM/YYYY')}
        </div>
      </div>
    ),
  },
  {
    name: 'Variations',
    sortable: true,
    selector: (row) => row.variations,
    cell: (row) => (
      <div className="text-sm flex flex-col w-[max-content]">
        <span className="text-white px-8 rounded-xs py-4 bg-gray-600">
          {row.variations}
        </span>
      </div>
    ),
  },
]

type ProjectTypeFilterUnion = 'All' | 'Days Work' | 'Quote'

type ProjectTypeFilterTypes = {
  label: string
  id: number
  isChecked: boolean
  dataId: ProjectTypeFilterUnion
}

type StatusTypeFilterUnion = 'All' | 'Active' | 'To Price' | 'Completed'

type StatusTypeFilterTypes = {
  label: string
  id: number
  isChecked: boolean
  dataId: StatusTypeFilterUnion
}

const defaultProjectTypeFilter: Array<ProjectTypeFilterTypes> = [
  {
    label: 'All',
    dataId: 'All',
    isChecked: false,
    id: 1,
  },
  {
    label: 'Days Work',
    dataId: 'Days Work',
    isChecked: false,
    id: 2,
  },
  {
    label: 'Quote',
    dataId: 'Quote',
    isChecked: false,
    id: 3,
  },
]

const defaultStatusTypeFilter: Array<StatusTypeFilterTypes> = [
  {
    label: 'All',
    dataId: 'All',
    isChecked: false,
    id: 1,
  },

  {
    label: 'Active',
    dataId: 'Active',
    isChecked: false,
    id: 2,
  },
  {
    label: 'Completed',
    dataId: 'Completed',
    isChecked: false,
    id: 3,
  },

  {
    label: 'To Price',
    dataId: 'To Price',
    isChecked: false,
    id: 4,
  },
]

export const SiteProjectList = () => {
  const {params} = useNavigation()
  const dispatch = useDispatch()

  const {siteId} = params as any

  const [projectTypeFilter, setProjectTypeFilter] = useState<
    Array<ProjectTypeFilterTypes>
  >(defaultProjectTypeFilter)

  const [statusTypeFilter, setStatusTypeFilter] = useState<
    Array<StatusTypeFilterTypes>
  >(defaultStatusTypeFilter)

  const [data, {onChange, clear, setValue}] = useFormInput({
    search: undefined,
    dateFrom: '',
    projectType: '',
    state: '',
    dateTo: '',
  })

  const debounceFilterValue = useDebounceValue(data.search)

  const getProject = useCallback(
    (page: number = 1, limit: number = 10) => {
      const query = {
        page,
        limit,
        projectType: getConcatenatedValue(projectTypeFilter, 'All'),
        startDate: data.dateFrom === '' ? undefined : data.dateFrom,
        projectStatus: getConcatenatedValue(statusTypeFilter, 'All'),
        endDate: data.dateTo === '' ? undefined : data.dateTo,
        search: debounceFilterValue === '' ? undefined : debounceFilterValue,
      }
      dispatch(getProjectListByDefinedId('sites', siteId, query))
    },
    [
      projectTypeFilter,
      data.dateFrom,
      data.dateTo,
      statusTypeFilter,
      debounceFilterValue,
      dispatch,
      siteId,
    ],
  )

  useEffect(() => {
    getProject()
  }, [getProject])

  const {
    projectListByDefinedId,
    projectListTotal,
    projectListByDefinedIdLoading,
  }: RT.ProjectReduxType = useSelector((state: any) => state.project)

  const newProjectList = useMemo(() => {
    const updatedJobList = projectListByDefinedId?.map((list) => {
      return {
        id: list.project_details.id,
        project: list.project_details.project_prefix,
        projectType: list.project_details.project_type,
        title: list.project_details.title,
        customer: list.customer_details.name,
        projectStatus: list.project_details.project_status,
        dateCreated: list.project_details.created_at,
        variations: '0',
        customerId: list.customer_details?.id,
      }
    })

    return updatedJobList
  }, [projectListByDefinedId])

  const handleProjectTypeCheckbox = useCallback(
    (
      dataId: ProjectTypeFilterUnion,
      filterArray: Array<ProjectTypeFilterTypes>,
    ) => {
      const finalProjectType = handleMultiCheckbox(dataId, filterArray, 'All')
      setProjectTypeFilter(finalProjectType)
    },
    [],
  )

  const handleStatusTypeCheckbox = useCallback(
    (
      dataId: StatusTypeFilterUnion,
      filterArray: Array<StatusTypeFilterTypes>,
    ) => {
      const finalProjectType = handleMultiCheckbox(dataId, filterArray, 'All')
      setStatusTypeFilter(finalProjectType)
    },
    [],
  )

  const handleResetFilter = () => {
    setProjectTypeFilter(defaultProjectTypeFilter)
    setStatusTypeFilter(defaultStatusTypeFilter)
    clear()
  }

  return (
    <div className="flex flex-col px-10 py-10 gap-10 pb-0">
      <div className="text-lg font-bold text-black">Projects</div>
      <div className="flex flex-col gap-4">
        <div
          className="flex text-blue-300 justify-end cursor-pointer"
          onClick={() => handleResetFilter()}
        >
          Reset filter
        </div>
        <div className="flex flex-row gap-10">
          <div className="flex flex-1">
            <Input
              type="text"
              name="search"
              placeholder="Search Title..."
              onChange={onChange}
              value={data.search}
            />
          </div>
          <div className="flex flex-1">
            <Dropdown
              containerClass="flex flex-1 w-full"
              outDismiss={false}
              inDismiss={false}
              placement="bottomright"
              triggerElement={
                <Input
                  placeholder="Filter by project type"
                  className="flex flex-1 w-full cursor-pointer"
                  value={
                    getConcatenatedValue(
                      projectTypeFilter,
                      'All',
                      'All Project Types',
                    ) ?? ''
                  }
                  // disabled
                  onChange={() => {}}
                />
              }
            >
              <div className="shadow-md w-full ">
                <div className="flex-1 w-full text-[12px] overflow-auto ">
                  <ul className=" border-gray-200 rounded-sm shadow-sm overflow-auto ">
                    {projectTypeFilter.map((value) => {
                      return (
                        <li
                          className="p-10 px-20 cursor-pointer bg-white text-black  hover:bg-blue-100  "
                          key={value.id}
                        >
                          <span className="text-left inline-flex cursor-pointer">
                            <Checkbox
                              dataId={value.dataId + 'projectType'}
                              label={value.label}
                              isChecked={value.isChecked}
                              value={value.isChecked}
                              className="w-full"
                              labelClassName={'ml-8'}
                              onChange={() => {
                                handleProjectTypeCheckbox(
                                  value.dataId,
                                  projectTypeFilter,
                                )
                              }}
                            />
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </Dropdown>
          </div>
          <div className="flex flex-1">
            <Dropdown
              containerClass="flex flex-1 w-full"
              outDismiss={false}
              inDismiss={false}
              animationType="fade"
              placement="bottomright"
              triggerElement={
                <Input
                  placeholder="Filter by status type"
                  className="flex flex-1 w-full cursor-pointer"
                  value={
                    getConcatenatedValue(
                      statusTypeFilter,
                      'All',
                      'All Status Types',
                    ) ?? ''
                  }
                  onChange={() => {}}
                  // disabled
                />
              }
            >
              <div className="shadow-md w-full">
                <div className="flex-1 w-full text-[12px] overflow-auto ">
                  <ul className=" border-gray-200 rounded-sm shadow-sm overflow-auto ">
                    {statusTypeFilter.map((value) => {
                      return (
                        <li
                          className="p-10 px-20 cursor-pointer  bg-white text-black hover:bg-blue-100  "
                          key={value.id}
                        >
                          <span className="text-left inline-flex cursor-pointer">
                            <Checkbox
                              dataId={value.dataId + 'statusType'}
                              label={value.label}
                              labelClassName={'ml-8'}
                              className="w-full"
                              isChecked={value.isChecked}
                              onChange={() => {
                                handleStatusTypeCheckbox(
                                  value.dataId,
                                  statusTypeFilter,
                                )
                              }}
                            />
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </Dropdown>
          </div>
          <ToolTip text="Project Start" top className="flex flex-1">
            <DateInput
              prepend={<FaHourglassStart className="text-blue-300" />}
              name="dateFrom"
              placeholder="Date From"
              containerClass="w-full relative"
              value={data.dateFrom}
              onChange={(date) => {
                setValue('dateFrom', date)
              }}
            />
          </ToolTip>
          <ToolTip text="Project End" top className="flex flex-1">
            <DateInput
              prepend={<FaHourglassEnd className="text-blue-300" />}
              name="dateTo"
              placeholder="Date To"
              containerClass="w-full relative"
              value={data.dateTo}
              onChange={(date) => {
                setValue('dateTo', date)
              }}
              minDate={new Date(data?.dateFrom)}
            />
          </ToolTip>
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          data={newProjectList}
          pagination
          persistTableHead
          dense
          fetchData={getProject}
          totalRows={projectListTotal}
          loading={projectListByDefinedIdLoading}
        />
      </div>
    </div>
  )
}
