import {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {AiTwotoneDelete} from 'react-icons/ai'

import {useIndividualFormElement} from 'hooks'
import {REMOVE_ASSET_IDS, addFormAssets, alterFormContent} from 'redux-src'
import {getAssetUrl} from 'helpers'

import {FormContext} from '../formBuilder'

interface ImageProps {
  id: number
  onSelectElement: (id: number) => void
}

interface PhotoProps {
  id: number
  onSelectElement: (id: number) => void
}

interface SignatureProps {
  id: number
  onSelectElement: (id: number) => void
}

interface VideoProps {
  id: number
  onSelectElement: (id: number) => void
}

interface ImageContentType {
  label: string
  description: string
  value: string
}

export const ImageComp = ({id, onSelectElement}: ImageProps) => {
  const {selectedFormElementId} = useContext(FormContext)
  const {individualElement, individualStyles, alterElementSettings} =
    useIndividualFormElement(id)

  const [imageContent, setImageContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setImageContent((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  const [imagePreview, setImagePreview] = useState<string>()

  const changeImage = (data: any) => {
    if (data) {
      const assetsFormData = new FormData()

      assetsFormData.append('file', data)

      dispatch(
        addFormAssets(assetsFormData, (data) => {
          // dispatch(getFormByIdAction(formId))
          alterElementSettings(data)
        })
      )
    }
    // return
  }

  useEffect(() => {
    individualElement?.asset_details
      ? setImagePreview(getAssetUrl(individualElement?.asset_details))
      : setImagePreview('')
  }, [
    individualElement,
    individualElement?.asset_details,
    individualElement?.content?.value
  ])

  useEffect(() => {
    dispatch(alterFormContent(id, imageContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageContent])

  const isImageAdded = !(!!!imagePreview || !!!individualElement?.asset_details)

  return (
    <div
      className={`border border-1 rounded-sm overflow-hidden ${
        selectedFormElementId === id
          ? `border-solid border-${isImageAdded ? 'blue' : 'red'}-300`
          : `border-dashed border-${
              isImageAdded ? 'gray' : 'red'
            }-300 hover:bg-${isImageAdded ? 'blue' : 'red'}-100`
      }
      ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="py-8">
          <input
            type="text"
            name="label"
            value={imageContent?.label}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}

      {!individualElement?.settings?.hideDescription && (
        <div className="py-4">
          <input
            type="text"
            name="description"
            value={imageContent?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}

      <div>
        {!!individualElement?.asset_details ? (
          <div className="flex-col w-full my-6 bg-blue-100 flex h-[200px] relative">
            {!!imagePreview ? (
              <img
                src={imagePreview}
                alt="previewImage"
                className="h-[200px] w-full object-contain"
              />
            ) : (
              !!individualElement?.asset_details && (
                <img
                  src={String(getAssetUrl(individualElement?.asset_details))}
                  alt="previewImage"
                  className="h-[200px] w-full object-contain"
                />
              )
            )}
            <span
              className="flex justify-end px-8 py-4 absolute right-10 bottom-6"
              onClick={() => {
                setImagePreview('')
                setImageContent((prev) => ({...prev, value: ''}))
                alterElementSettings({asset_details: undefined})
                dispatch({
                  type: REMOVE_ASSET_IDS.SUCCESS,
                  payload: individualElement?.asset_details?.id
                })
              }}
            >
              <AiTwotoneDelete
                size={18}
                className="cursor-pointer hover:text-red-300 transition-all duration-200 ease-in-out"
              />
            </span>
          </div>
        ) : (
          <label
            htmlFor={`image-${id}}`}
            className="cursor-pointer w-full mx-10 mr-10 py-20 my-6 bg-blue-100 flex items-center justify-center h-[200px]"
          >
            <label
              htmlFor={`image-${id}}`}
              className="text-sm font-bold text-gray-400"
            >
              {/* <span>Drag & drop an image or </span> */}
              <span className="text-blue-300">Upload image here</span>
            </label>
          </label>
        )}
        <input
          type="file"
          name="value"
          id={`image-${id}}`}
          className="hidden"
          accept="image/jpeg|image/png"
          onChange={(e: any) => {
            if (e?.target.files && e?.target.files[0]) {
              setImageContent((prev) => ({
                ...prev,
                value: URL.createObjectURL(e?.target.files[0])
              }))
              changeImage(e.target.files[0])
              setImagePreview(URL.createObjectURL(e?.target.files[0]))
            }
          }}
        />
      </div>
    </div>
  )
}

export const Photo = ({id, onSelectElement}: PhotoProps) => {
  const {selectedFormElementId} = useContext(FormContext)
  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [photoContent, setPhotoContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setPhotoContent((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, photoContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoContent])
  return (
    <div
      className={`border border-1 rounded-sm px-12 overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
      ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="flex gap-4 py-8">
          <input
            type="text"
            name="label"
            value={photoContent?.label}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full bg-transparent"
          />
          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}

      {!individualElement?.settings?.hideDescription && (
        <div className="py-4">
          <input
            type="text"
            name="description"
            value={photoContent?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full bg-transparent"
          />
        </div>
      )}

      <div className="w-full py-20 my-6 bg-blue-100 flex items-center justify-center">
        <label
          htmlFor={`photo-${id}}`}
          className="text-sm font-bold text-gray-400"
        >
          <span>End user completing the form will upload photos here </span>
        </label>
        <input
          type="file"
          name="value"
          id="photo"
          className="hidden"
          onChange={(e: any) => {
            setPhotoContent((prev) => ({...prev, value: e.target.files}))
          }}
        />
      </div>
    </div>
  )
}

export const Signature = ({id, onSelectElement}: SignatureProps) => {
  const {selectedFormElementId} = useContext(FormContext)
  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [signatureContent, setSignatureContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setSignatureContent((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, signatureContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureContent])
  return (
    <div
      className={`border border-1 rounded-sm px-12 overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
      ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="flex gap-4 py-4">
          <input
            type="text"
            name="label"
            value={signatureContent?.label}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full bg-transparent"
          />
          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}

      {!individualElement?.settings?.hideDescription && (
        <div className="py-4">
          <input
            type="text"
            name="description"
            value={signatureContent?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full bg-transparent"
          />
        </div>
      )}

      <div className="w-full py-20 realtive my-6 bg-blue-100 flex items-center justify-center">
        <label htmlFor="signature" className="text-sm font-bold text-gray-400">
          <span>
            End user completing the form will put their signature here{' '}
          </span>
        </label>
        <input
          type="file"
          name="value"
          id="signature"
          className="hidden"
          onChange={(e: any) => {
            setSignatureContent((prev) => ({...prev, value: e.target.files}))
          }}
        />
        {/* <span className="absolute bottom-0 right-0">
        <FiCamera size={20} className="cursor-pointer" />
      </span> */}
      </div>
      {individualElement?.settings?.allowClearSignature && (
        <div className="text-md mr-6 text-blue-300 flex justify-end cursor-move">
          Clear Signature
        </div>
      )}
    </div>
  )
}

export const Video = ({id, onSelectElement}: VideoProps) => {
  const {selectedFormElementId} = useContext(FormContext)
  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [videoContent, setVideoContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setVideoContent((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, videoContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoContent])
  return (
    <div
      className={`border border-1 rounded-sm px-12 overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
      ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="flex gap-4 py-8">
          <input
            type="text"
            name="label"
            value={videoContent?.label}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full bg-transparent"
          />
          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}

      {!individualElement?.settings?.hideDescription && (
        <div className="py-4">
          <input
            type="text"
            name="description"
            value={videoContent?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full bg-transparent"
          />
        </div>
      )}

      <div className="w-full py-20 my-6 bg-blue-100 flex items-center justify-center">
        <label htmlFor="video" className="text-sm font-bold text-gray-400">
          <span>End user completing the form will upload video here </span>
        </label>
        {/* <input
          type=""
          name="value"
          id="photo"
          className="hidden"
          onChange={(e: any) => {
            setPhotoContent((prev) => ({...prev, value: e.target.files}))
          }}
        /> */}
      </div>
    </div>
  )
}
