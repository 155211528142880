import {ReactNode, useState} from 'react'
import ReactDataTable, {
  TableColumn,
  TableStyles,
} from 'react-data-table-component'
import colors from 'tailwindcss/colors'

import {Loading} from '../../common'

export const DataTable = <T,>({
  columns,
  data,
  fixedHeader,
  fixedHeaderScrollHeight,
  customStyles: cs,
  defaultSortFieldId,
  pagination,
  persistTableHead = true,
  dense = true,
  striped = true,
  totalRows,
  paginationServer = true,
  paginationTotalRows,
  fetchData,
  onChangePage,
  onChangeRowsPerPage,
  noDataComponent,
  loading,
  actions,
  selectableRows,
  ...rest
}: {
  columns: Array<TableColumn<T>>
  data: Array<T>
  fixedHeader?: boolean
  fixedHeaderScrollHeight?: string
  customStyles?: TableStyles
  defaultSortFieldId?: number
  pagination?: boolean
  persistTableHead?: boolean
  dense?: boolean
  striped?: boolean
  paginationServer?: boolean
  paginationTotalRows?: number
  totalRows?: number
  fetchData?: (page: number, tableLimit: number) => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (tableLimit: number) => void
  noDataComponent?: ReactNode
  loading?: boolean
  actions?: React.ReactNode
  selectableRows?: boolean
  onSelectedRowsChange?: (selected: {
    selectedRows: Array<T>
    allSelected: boolean
    selectedCount: number
  }) => void
  clearSelectedRows?: boolean
  selectableRowSelected?: (row: T) => boolean
  selectableRowDisabled?: (row: T) => boolean
  contextActions?: () => void
}) => {
  const [tableLimit, setTableLimit] = useState<number>(10)

  const handlePageChange = (newPage: number) => {
    // setPage(newPage)
    fetchData?.(newPage, tableLimit)
  }

  const handleTableLimitChange = (
    newTableLimit: number,
    currentPage: number,
  ) => {
    setTableLimit(newTableLimit)
    fetchData?.(currentPage, newTableLimit)
  }

  const extendedCustomStyles: TableStyles = {
    ...cs,
    table: {
      style: {
        border: '1px solid',
        borderRadius: '5px',
        borderColor: colors.gray[200],
        width: 'inherit',
        ...cs?.table?.style,
      },
    },
    rows: {
      style: {
        minHeight: '40px !important',
        backgroundColor: '#f5f5f5',
        '&:nth-child(2n-1)': {
          backgroundColor: colors.white,
        },
        ...cs?.rows?.style,
      },
    },

    headCells: {
      style: {
        fontWeight: 'bold',
        padding: '6px 10px',

        '&:not(:last-child)': {
          borderRight: '1px solid',
          borderColor: colors.gray[200],
        },
        ...cs?.headCells?.style,
      },
    },
    headRow: {
      style: {
        fontWeight: 'bold',
        minHeight: '42px !important',
        background: colors.gray[100],
        ...cs?.headRow?.style,
      },
    },
    cells: {
      style: {
        padding: '6px 10px',
        '&:not(:last-child)': {
          borderRight: '1px solid',
          borderColor: colors.gray[200],
        },
        ...cs?.cells?.style,
      },
    },
  }

  return (
    <>
      {actions && <div className="flex justify-end mb-6">{actions}</div>}

      {data && data.length > 0 ? (
        <ReactDataTable
          columns={columns}
          data={data}
          fixedHeader={fixedHeader}
          fixedHeaderScrollHeight={fixedHeaderScrollHeight}
          customStyles={extendedCustomStyles}
          defaultSortFieldId={defaultSortFieldId}
          pagination={pagination}
          persistTableHead={persistTableHead}
          dense={dense}
          striped={striped}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleTableLimitChange}
          noDataComponent={noDataComponent}
          paginationServer
          paginationServerOptions={{
            persistSelectedOnPageChange: true,
            persistSelectedOnSort: true,
          }}
          progressPending={loading}
          progressComponent={
            <div
              className={`rdt_ProgressComponent h-[100px] flex items-center justify-center flex-nowrap w-full`}
            >
              <div
                className={`flex items-center justify-center gap-10 flex-nowrap`}
              >
                <Loading small /> Loading...
              </div>
            </div>
          }
          actions={actions}
          selectableRows={selectableRows}
          responsive
          {...rest}
        />
      ) : (
        noDataComponent ?? (
          <div className="w-full rounded-md border-1 border-gray-300 bg-gray-200 text-gray-400 flex items-center justify-center gap-16 h-[100px]">
            No records available!
          </div>
        )
      )}
    </>
  )
}
