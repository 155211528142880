import {useContext, useEffect} from 'react'
import {FormContext} from '../formBuilder'
import {useDispatch} from 'react-redux'
import {useIndividualFormElement} from 'hooks'
import {alterFormContent} from 'redux-src'

interface DividerProps {
  id?: number
  onSelectElement: (id: number) => void
}

interface PageBreakProps {
  id?: number
  onSelectElement: (id: number) => void
}

interface BlankSpaceProps {
  id?: number
  onSelectElement: (id: number) => void
}

export const Divider = ({id, onSelectElement}: DividerProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualStyles} = useIndividualFormElement(id)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(alterFormContent(id, undefined, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={`border py-20 px-16  border-1 rounded-sm overflow-hidden gap-4 ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
      ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      <div className="w-full  border border-t-1 border-gray-200"></div>
    </div>
  )
}

export const PageBreak = ({id, onSelectElement}: PageBreakProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(alterFormContent(id, undefined, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={`flex border py-30 px-16 items-center border-1 w-full rounded-sm overflow-hidden gap-4 ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      <div className="w-full  border border-t-1 border-dashed border-gray-300"></div>
      <span className="w-[155px] mx-4">Page Break</span>
      <div className="w-full  border border-t-1 border-dashed border-gray-300"></div>
    </div>
  )
}

export const BlankSpace = ({id, onSelectElement}: BlankSpaceProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualStyles} = useIndividualFormElement(id)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(alterFormContent(id, undefined, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={`flex flex-col justify-center items-center border py-30 px-16 border-1 rounded-sm overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
       ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      <div className="border border-r-1 border-dashed border-gray-300"></div>
      <span className="mx-4">Blank Space</span>
      <div className="border border-r-1 border-dashed border-gray-300"></div>
    </div>
  )
}
