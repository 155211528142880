import React, {useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'

import {LabourElementTable} from '../labourElementTable'
import {AddLabourElement} from '../addLabourElement'
import {Button, Input} from 'app/common'
import {useDebounceValue} from 'hooks'
import {SettingsHeader} from 'app/pages/settings/components'

export const LabourElement = () => {
  const [searchLabour, setSearchLabour] = useState<string>()

  const [addLabourElementModal, setLabourElementModal] =
    useState<boolean>(false)

  const debouncedSearchValue = useDebounceValue(searchLabour)

  const handleModalOpen = () => {
    setLabourElementModal(true)
  }

  return (
    <div>
      <SettingsHeader
        title={'Labour Element'}
        append={
          <Button
            title="Add New Labour Element"
            onClick={handleModalOpen}
            type={'button'}
          />
        }
        subHeader={'The list allows you to manage labour element'}
      />

      <div className="mt-20">
        <div className="flex items-center justify-between pb-10">
          <Input
            name="filterValue"
            type="text"
            // size="sm"
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
            containerClass="w-[300px]"
            placeholder="Search labour element"
            className="w-full text-sm"
            onChange={(e: any) => setSearchLabour(e.target.value)}
          />
        </div>
      </div>

      <div className="my-10 mb-24 border-b-1 border-gray-200" />
      <div className="mt-10">
        <LabourElementTable search={debouncedSearchValue} />
      </div>

      <AddLabourElement
        addLabourElementModal={addLabourElementModal}
        setLabourElementModal={setLabourElementModal}
      />
    </div>
  )
}
