import {useEffect, useMemo} from 'react'
import moment from 'moment'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import {useNavigation} from 'react-auth-navigation'
import {LiaCalendarWeekSolid} from 'react-icons/lia'
import {MdInfo} from 'react-icons/md'
import {FaUsers} from 'react-icons/fa'
import {BsFillQuestionCircleFill} from 'react-icons/bs'

import {Button, Input, ToolTip} from 'app/common'
import {DataTable, MessageBox} from 'app/components'
import {getCustomProjectDateCalculator} from 'redux-src'

export function DateCalculator() {
  const {params} = useNavigation()

  const {projectId} = params as any

  const dispatch = useDispatch()

  const [workerData, {onChange, onSubmit, errors, modified, setValue}] =
    useFormInput(
      {
        totalMens: 1,
        numberRef: 1,
      },
      ({totalMens}) => {
        dispatch(
          getCustomProjectDateCalculator(projectId, {totalMens}, () => {
            setValue('numberRef', totalMens)
          }),
        )
      },
      (data) => {
        const errors: any = {}

        if (+data.totalMens === 0 || !!!data.totalMens) {
          errors.totalMens =
            'Please enter the valid number of worker for estimation!'
        }

        return errors
      },
    )

  const {
    isProjectDateLoading,
    projectDateCalculator: data,

    isCustomProjectDateLoading,
    customProjectDateCalculator: customData,
  }: RT.ProjectFinancialSummaryReduxType = useSelector(
    (state: any) => state.financeSummary,
  )

  const lastData = data?.at(-1)
  const simulatedWeeks = useMemo(() => {
    const projectedCompletionWeeks = Number(
      (
        (isNaN(+lastData?.weeks) ? 0 : +lastData?.weeks) -
        Math.round(customData?.total_completion_weeks ?? 0)
      )?.toFixed(2),
    )

    return +Number(projectedCompletionWeeks ?? 0).toFixed(2)
  }, [customData?.total_completion_weeks, lastData?.weeks])

  const columns: TableColumn<Api.DateCalculatorDataIndividual>[] = [
    {
      name: 'Workers',
      selector: (row) => row.mens,
      sortable: true,
      cell: (row) => row.mens,
    },
    {
      name: 'Hours/week',
      selector: (row) => row?.hoursPerWeek,
      sortable: true,
      cell: (row) => Number(row?.hoursPerWeek ?? 0).toFixed(2),
    },
    {
      name: 'Working Hours Required',
      selector: (row) => row?.workingHours,
      sortable: true,
      cell: (row) => Number(row?.workingHours ?? 0).toFixed(2),
    },

    {
      name: 'Weeks Required',
      selector: (row) => row?.weeks,
      sortable: true,
      cell: (row) => Number(row?.weeks ?? 0).toFixed(2),
    },
  ]

  useEffect(() => {
    dispatch(
      getCustomProjectDateCalculator(projectId, {
        totalMens: workerData?.totalMens,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projectId])

  return (
    <div>
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16">
        Project Resource Estimation
      </h3>

      <div className="grid grid-cols-2 gap-40 p-16 w-full ">
        <div className="flex flex-col">
          <div className="chart-heading mb-10">
            <div className="font-bold text-md w-max text-gray-500">
              Optimal Estimation
            </div>

            <div className="text-gray-300 font-sm mb-10">
              The resource allocation required to complete the project under
              given working hours per week.
            </div>
          </div>

          <div className="my-10">
            <DataTable
              columns={columns}
              loading={isProjectDateLoading}
              data={data}
              persistTableHead
              dense
              striped
              fixedHeader
              fixedHeaderScrollHeight="300px"
            />
          </div>
          <div className="inline-flex gap-8 justify-start items-center mt-20 mb-10">
            <MdInfo size={24} fill="rgb(3, 84, 214)" />
            <span className="text-blue-500">
              <strong className="text-md">{lastData?.mens}</strong>{' '}
              {lastData?.mens === 1 ? `worker is` : `workers are`} estimated to
              complete the project in{' '}
              <strong className="text-md">
                {Number(lastData?.weeks ?? 0).toFixed(2)}
              </strong>{' '}
              weeks.
            </span>
          </div>
          <div className="flex flex-col border-t-1 border-dashed border-gray-200 w-full pt-10">
            <strong className="text-md">Deduction</strong>
            <div className="flex justify-start items-center gap-8">
              <FaUsers size={20} fill="rgb(3, 84, 214)" />
              Optimal Number of Workers: <strong>{lastData?.mens}</strong>
            </div>
            <div className="flex justify-start items-center gap-8">
              <LiaCalendarWeekSolid size={20} fill="rgb(3, 84, 214)" />
              Optimal Completion Weeks: <strong>{lastData?.weeks}</strong>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="chart-heading mb-10">
            <div className="font-bold text-md w-max text-gray-500">
              Simulated Estimation
            </div>

            <div className="text-gray-300 font-sm mb-10">
              The resource allocation deduced for given number of workers.
            </div>
          </div>
          <form
            onSubmit={onSubmit}
            className="my-10 flex flex-nowrap gap-10 items-center justify-end"
          >
            <Input
              label="Total Workers"
              type="number"
              required
              name="totalMens"
              value={workerData?.totalMens}
              onChange={onChange}
              error={modified.totalMens && errors.totalMens}
            />
            <div className="flex items-center justify-end mt-4">
              <Button
                loading={isCustomProjectDateLoading}
                title="Estimate"
                type="submit"
              />
            </div>
          </form>

          {!!customData ? (
            <div className="flex flex-col gap-8 justify-center items-start mt-10">
              <div className="inline-flex gap-8 justify-start items-center">
                <MdInfo size={24} fill="rgb(3, 84, 214)" />
                <span className="text-blue-500">
                  <strong className="text-md">{workerData?.numberRef}</strong>{' '}
                  {workerData?.numberRef === 1 ? `worker is` : `workers are`}{' '}
                  estimated to complete the project in{' '}
                  <strong className="text-md">
                    {Math.round(customData?.total_completion_weeks ?? 0)}
                  </strong>{' '}
                  weeks on{' '}
                  <strong className="text-md">
                    {moment(customData?.completion_date).format(
                      'ddd, D MMM YYYY',
                    )}
                  </strong>
                  .
                </span>
              </div>

              <div className="flex flex-col border-t-1 border-dashed border-gray-200 w-full pt-10">
                <strong className="text-md">Deduction</strong>

                <div className="flex items-center gap-8">
                  <span className="text-grey-500 font-normal">
                    {simulatedWeeks < 0 ? (
                      <span className="text-red-500">
                        The project will be delayed by{' '}
                        {/* Conver to absolute because weeks cant be on negative */}
                        <strong className="text-lg">
                          {Math.abs(simulatedWeeks)}
                        </strong>{' '}
                        weeks.
                      </span>
                    ) : // * Case of positivive: AHEAD OF TIME
                    simulatedWeeks > 0 ? (
                      <span className="text-green-300">
                        The project will be ahead of time by{' '}
                        <strong className="text-lg"> {simulatedWeeks}</strong>{' '}
                        weeks.
                      </span>
                    ) : (
                      // * Case of equality : On Time
                      'The project will be running on par with the estimated time.'
                    )}
                  </span>
                  <ToolTip
                    down
                    text="Based on comparison of simulated estimation against optimal estimation."
                  >
                    <BsFillQuestionCircleFill
                      fill="orange"
                      size={16}
                      className="cursor-pointer"
                    />
                  </ToolTip>
                </div>
              </div>
            </div>
          ) : (
            <MessageBox
              error
              message="Please enter the valid number of worker for estimation!"
            />
          )}
        </div>
      </div>
    </div>
  )
}
