import {
  QUOTE_HTML_END,
  QUOTE_HTML_START,
} from 'app/components/invoiceEditor/constants'

import moment from 'moment'

import {
  PRELIMINARY_TABLE_HEADINGS,
  preliminaryKeys,
} from 'app/components/preliminaryEditor/constants'

export const generateQuoteHTMLString = (
  companyDetails: Api.CompanyDetails,
  projectDetail: Api.ProjectDetailsById,
  quoteDetail: Api.QuoteDetailsById,
  quoteBasicDetails: Api.QuoteBasicDetails,
  currencySymbol: string,
) => {
  let companyDetail = `<tr class="top">
          <td colspan="2">
            <table>
              <tr>
                <td class="title">
                  
                </td>

                <td class="company-details">
                  <strong class="company-title"
                    >${companyDetails?.company_details?.name}</strong
                  ><br />
                  ${companyDetails?.address_details?.address}<br/>
                  ${companyDetails?.company_details?.email}<br />
                  ${companyDetails?.company_details?.mobile}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let siteAndJobDetail = `<tr class="information">
          <td colspan="2">
            <table>
              <tr class="billing-details">
                <td>
                  ${projectDetail?.customer_contact_details?.firstname} ${
    projectDetail?.customer_contact_details?.lastname
  }     <br/>
                ${
                  projectDetail?.customer_address_details?.suburb
                    ? `${projectDetail?.customer_address_details?.suburb}<br/>`
                    : ''
                }
                  ${projectDetail?.customer_address_details?.city}${
    projectDetail?.customer_address_details?.zip_code
      ? `, ${projectDetail?.customer_address_details?.zip_code}`
      : ''
  }</td>

                <td class="site-details">
                  <table>
                    <tr>
                      <td class="site-address">
                        <strong>Site Address</strong>
                        <br />
                        ${
                          projectDetail?.site_contact_details?.firstname
                            ? `${projectDetail?.site_contact_details?.firstname}<br/>`
                            : ''
                        }
                        ${
                          projectDetail?.site_address_details?.place
                            ? `${projectDetail?.site_address_details?.place}<br/>`
                            : ''
                        }
                        ${
                          projectDetail?.site_address_details?.suburb
                            ? `${projectDetail?.site_address_details?.suburb}<br/>`
                            : ''
                        }
                        ${projectDetail?.site_address_details?.city}${
    projectDetail?.site_address_details?.zip_code
      ? `, ${projectDetail?.site_address_details?.zip_code}`
      : ''
  }<br />
                      </td>
                      <td class="quote-details">
                        Project Number: ${
                          projectDetail?.project_details?.project_prefix +
                          '-' +
                          projectDetail?.project_details?.project_number
                        }<br />
                        Company Registration Number: ${
                          companyDetails?.company_details?.tax_number
                        }<br />
                        Quote Date: ${moment().format('Do MMM YYYY')}<br />
                        Valid Until: ${moment(
                          quoteBasicDetails?.quote_details?.expiry_date,
                        ).format('Do MMM YYYY')}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        `

  let quoteDesc = `<tr>
          <table>
            <tr>
              <strong class="quote-title">Quote</strong>
            </tr>
            <br />
            <tr>
<p class="quote-description">
               ${quoteBasicDetails?.quote_details?.description?.replaceAll(
                 '\n',
                 '<br/>',
               )}
              </p>            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />
        `

  let quoteTableHead = `<tr>
          <table class="quote-table">
            <tr class="quote-head">
              <th>Name</th>
              <th>Quantity</th>
              <th>Cost</th>
              <th>Price</th>
              <th>Tax</th>
              <th>Discount</th>
              <th>Total</th>
            </tr>
        `

  let quoteTableItem = `<tr class="quote-item-title">
              ${
                quoteDetail?.length > 0 &&
                quoteDetail
                  ?.map(({quote_item_details: item, line_item_list}: any) => {
                    return `
                  <tr class="quote-item-title">
                    <td><strong>${item.title}</strong></td>
                  </tr>
                  
                      ${
                        line_item_list?.length > 0 &&
                        line_item_list
                          .map((item: any) => {
                            return `<tr class="line-item">
                              ${[
                                'name',
                                'quantity',
                                'cost',
                                'price',
                                'tax_percent',
                                'discount_percent',
                                'total_cost',
                              ]
                                .map((key) => {
                                  return `<td>
                                    ${item[key]}
                                  </td>`
                                })
                                .join('')}
                            </tr>`
                          })
                          .join('')
                      }
              `
                  })
                  .join('')
              }
            <tr>
              <td style="padding-bottom: 16px"></td>
            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />`

  let amountDetails = `<tr class="amount-details">
          <td colspan="2">
            <table>
              <tr>
                <td style="width: 250px"></td>
                <td class="totals">
                  <table>
                    <tr>
                      <td class="totals-cols">
                        Sub Total <br />
                        Discount <br />
                        Discounted Sub Total <br />
                        Tax Amount <br />
                        <br />
                        Total <br />
                      </td>
                      <td class="totals-cols">
                        ${currencySymbol}${
    quoteBasicDetails?.quote_details?.taxable_cost ?? 0.0
  }<br />-${currencySymbol}${
    quoteBasicDetails?.quote_details?.discount ?? 0.0
  }<br />${currencySymbol}${
    quoteBasicDetails?.quote_details?.discounted_cost ?? 0.0
  }<br />${currencySymbol}${
    quoteBasicDetails?.quote_details?.tax ?? 0.0
  }<br /><br />${currencySymbol}${
    quoteBasicDetails?.quote_details?.total_cost ?? 0.0
  }<br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let quoteFoot = `<tr>
          <p class="quote-footer">
            ${quoteBasicDetails?.quote_details?.footer_notes?.replaceAll(
              '\n',
              '<br/>',
            )}
          </p>
        </tr>`
  return (
    QUOTE_HTML_START +
    companyDetail +
    siteAndJobDetail +
    quoteDesc +
    quoteTableHead +
    quoteTableItem +
    amountDetails +
    quoteFoot +
    QUOTE_HTML_END
  )
}

export const generatePreliminaryHTMLString = (
  companyDetails: any,
  projectDetail: Api.ProjectDetailsById,
  preliminaryDetail: Api.PreliminaryList,
  totalAmount: any,
  currencySymbol: any,
) => {
  let companyDetail = `<tr class="top">
          <td colspan="2">
            <table>
              <tr>
                <td class="title">
                  
                </td>

                <td class="company-details">
                  <strong class="company-title"
                    >${companyDetails?.company_details?.name}</strong
                  ><br />
                  ${companyDetails?.address_details?.address}<br/>
                  ${companyDetails?.company_details?.email}<br />
                  ${companyDetails?.company_details?.mobile}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let siteAndJobDetail = `<tr class="information">
          <td colspan="2">
            <table>
              <tr class="billing-details">
                <td>
                  ${projectDetail?.customer_contact_details?.firstname} ${
    projectDetail?.customer_contact_details?.lastname
  }     <br/>
                ${
                  projectDetail?.customer_address_details?.suburb
                    ? `${projectDetail?.customer_address_details?.suburb}<br/>`
                    : ''
                }
                  ${projectDetail?.customer_address_details?.city}${
    projectDetail?.customer_address_details?.zip_code
      ? `, ${projectDetail?.customer_address_details?.zip_code}`
      : ''
  }</td>

                <td class="site-details">
                  <table>
                    <tr>
                      <td class="site-address">
                        <strong>Site Address</strong>
                        <br />
                        ${
                          projectDetail?.site_contact_details?.firstname
                            ? `${projectDetail?.site_contact_details?.firstname}<br/>`
                            : ''
                        }
                        ${
                          projectDetail?.site_address_details?.place
                            ? `${projectDetail?.site_address_details?.place}<br/>`
                            : ''
                        }
                        ${
                          projectDetail?.site_address_details?.suburb
                            ? `${projectDetail?.site_address_details?.suburb}<br/>`
                            : ''
                        }
                        ${projectDetail?.site_address_details?.city}${
    projectDetail?.site_address_details?.zip_code
      ? `, ${projectDetail?.site_address_details?.zip_code}`
      : ''
  }<br />
                      </td>
                      <td class="quote-details">
                        Project Number: ${
                          projectDetail?.project_details?.project_prefix +
                          '-' +
                          projectDetail?.project_details?.project_number
                        }<br />
                        Company Registration Number: ${
                          companyDetails?.company_details?.tax_number
                        }<br />
                        Quote Date: ${moment().format('Do MMM YYYY')}<br />
                        Valid Until: ${moment().format('Do MMM YYYY')}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        `

  let preliminaryDesc = `<tr>
          <table>
            <tr>
              <strong class="quote-title">Preliminary</strong>
            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />
        `

  let preliminaryTableHead = `<tr>    

          <table class="quote-table">

        `

  let preliminaryTableItem = `<tr class="quote-item-title">
              ${
                preliminaryDetail?.length > 0 &&
                preliminaryDetail
                  ?.map(({item_details, line_item_list}) => {
                    // console.log('items details', item_details)
                    const preliminaryType: Api.PreliminaryTypes =
                      item_details?.type as Api.PreliminaryTypes
                    return `
          <table class="quote-table">
                  <tr class="quote-item-title">
                    <td><strong>${item_details.title}</strong></td>
                  </tr>
                    
                  <tr class='quote-head'>
                    ${PRELIMINARY_TABLE_HEADINGS[preliminaryType]
                      ?.map((heading) => {
                        return `
                          <th class="preliminary-quote-heading">
                              ${
                                heading?.key === 'noOfQuantity'
                                  ? `No. of ${item_details?.frequency_unit}`
                                  : heading?.title
                              }
                          </th>
                        `
                      })
                      .join(' ')}

                  </tr>
                    ${
                      line_item_list?.length > 0 &&
                      line_item_list
                        .map((item) => {
                          return `
                            <tr class="line-item">
                              ${preliminaryKeys[preliminaryType]
                                .map((key) => {
                                  return `<td>
                                    ${
                                      item[
                                        key as keyof Api.PreliminaryLineItemDetails
                                      ] ?? ''
                                    }
                                    ${
                                      key === 'total_frequency' ||
                                      key === 'total_vehicles'
                                        ? item_details[key]
                                        : ''
                                    }
                                    ${
                                      key === 'total_weeks'
                                        ? projectDetail?.project_details
                                            ?.prelim_total_weeks
                                        : ''
                                    }
                                  </td>`
                                })
                                .join(' ')}
                            </tr>`
                        })
                        .join(' ')
                    }
              `
                  })
                  .join(' ')
              }
            <tr>
              <td style="padding-bottom: 16px"></td>
            </tr>
            <br />
          </table>
        </tr>
        
        <hr class="horizontal-break" />`

  let amountDetails = `
  </table>
  </tr>
  <tr class="amount-details">
          <td colspan="2">
            <table>
              <tr>
                <td style="width: 250px"></td>
                <td class="totals">
                  <table>
                    <tr>
                      <td class="totals-cols">
                        Preliminary Cost <br />
                      </td>
                      <td class="totals-cols">
                        ${currencySymbol}${(
    projectDetail?.project_details?.total_prelim_cost ?? 0.0
  ).toFixed(2)}<br />
                      </td>
                    </tr>
                    <tr>
                      <td class="totals-cols">
                        Delivery Cost <br />
                      </td>
                      <td class="totals-cols">
                        ${currencySymbol}${(
    projectDetail?.project_details?.prelim_delivery_cost ?? 0.0
  ).toFixed(2)}<br />
                      </td>
                    </tr>
                    <tr>
                      <td class="totals-cols">
                        Grand Total <br />
                      </td>
                      <td class="totals-cols">
                        ${currencySymbol}${(
    Number(projectDetail?.project_details?.total_prelim_cost ?? 0.0) +
    Number(projectDetail?.project_details?.prelim_delivery_cost ?? 0.0)
  ).toFixed(2)}<br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  return (
    QUOTE_HTML_START +
    companyDetail +
    siteAndJobDetail +
    preliminaryDesc +
    preliminaryTableHead +
    preliminaryTableItem +
    amountDetails +
    QUOTE_HTML_END
  )
}

export const generatePurchaseHTMLString = (
  companyDetails: any,
  projectDetail: any,
  purchaseOrderDetail: any,
  selectedSupplierName?: any,
) => {
  let companyDetail = `<tr class="top">
          <td colspan="2">
            <table>
              <tr>
                <td class="title">
                  
                </td>

                <td class="company-details">
                  <strong class="company-title"
                    >${companyDetails?.company_details?.name}</strong
                  ><br />
                  ${companyDetails?.address_details?.address}<br/>
                  ${companyDetails?.company_details?.email}<br />
                  ${companyDetails?.company_details?.mobile}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let quoteDesc = `<tr>
          <table class="table-container">
            <span>
              <strong class="quote-title">Item Purchase Orders</strong>
            </span>   
            ${
              !!selectedSupplierName && selectedSupplierName?.id !== 0
                ? `<div>
                <strong class={'supplier-name'}>Supplier : ${selectedSupplierName?.label}</strong>
              </div>`
                : `<div>
                <strong class={'supplier-name'}>${'All Suppliers'}</strong>
              </div>`
            }        
          </table>
        </tr>
        <hr class="horizontal-break" />
        `

  let purchaseTableHead = `<tr>
          <table class="quote-table">
            <tr class="quote-head">
              <th>Name</th>
              <th>Quantity</th>
             ${
               !!selectedSupplierName && selectedSupplierName?.id !== 0
                 ? ''
                 : ` <th>Supplier Name</th>`
             }
              
            </tr>
        `

  let purchaseTableItem = `<tr class="quote-item-title">
              ${
                purchaseOrderDetail?.length > 0 &&
                purchaseOrderDetail
                  ?.map(
                    ({purchase_order_details: item, supplier_details}: any) => {
                      const tableHeader =
                        !!selectedSupplierName && selectedSupplierName?.id !== 0
                          ? ['name', 'quantity']
                          : ['name', 'quantity', 'supplierName']

                      return `<tr class="line-item">
                              ${tableHeader
                                .map((key) => {
                                  return `<td>
                                    ${item[key] ?? ''}
                                    ${
                                      key === 'supplierName'
                                        ? supplier_details?.name
                                        : ''
                                    }
                                  </td>`
                                })
                                .join('')}
                            </tr>`
                    },
                  )
                  .join('')
              }
            <tr>
              <td style="padding-bottom: 16px"></td>
            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />`

  return (
    QUOTE_HTML_START +
    companyDetail +
    // siteAndJobDetail +
    quoteDesc +
    purchaseTableHead +
    purchaseTableItem +
    QUOTE_HTML_END
  )
}

export const generateMaterialPurchaseHTMLString = (
  companyDetails: any,
  projectDetail: any,
  materialPurchaseOrderDetatils: any,
  selectedSupplierName?: any,
) => {
  let companyDetail = `<tr class="top">
          <td colspan="2">
            <table>
              <tr>
                <td class="title">
                  
                </td>

                <td class="company-details">
                  <strong class="company-title"
                    >${companyDetails?.company_details?.name}</strong
                  ><br />
                  ${companyDetails?.address_details?.address}<br/>
                  ${companyDetails?.company_details?.email}<br />
                  ${companyDetails?.company_details?.mobile}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let quoteDesc = `<tr>
          <table class="table-container">
            <span>
              <strong class="quote-title">Material Purchase Orders</strong>
            </span>   
            ${
              !!selectedSupplierName && selectedSupplierName?.id !== 0
                ? `<div>
                <strong class={'supplier-name'}>Supplier : ${selectedSupplierName?.label}</strong>
              </div>`
                : `<div>
                <strong class={'supplier-name'}>${'All Suppliers'}</strong>
              </div>`
            }        
          </table>
        </tr>
        <hr class="horizontal-break" />
        `

  let purchaseTableHead = `<tr>
          <table class="quote-table">
            <tr class="quote-head">
              <th>Name</th>
              <th>No. Per Unit</th>
              <th>Quantity</th>
              ${
                !!selectedSupplierName && selectedSupplierName?.id !== 0
                  ? ''
                  : ` <th>Supplier Name</th>`
              }
            </tr>
        `

  let purchaseTableItem = `<tr class="quote-item-title">
              ${
                materialPurchaseOrderDetatils?.length > 0 &&
                materialPurchaseOrderDetatils
                  ?.map(
                    ({
                      quote_material_element_details: item,
                      supplier_details,
                    }: any) => {
                      const tableHeader =
                        !!selectedSupplierName && selectedSupplierName?.id !== 0
                          ? ['name', 'noPerUnit', 'quantity']
                          : ['name', 'noPerUnit', 'quantity', 'supplierName']

                      return `<tr class="line-item">
                              ${tableHeader
                                .map((key) => {
                                  return `<td>
                                    ${item[key] ?? ''}
                                    ${
                                      key === 'noPerUnit'
                                        ? item?.number_per_unit +
                                          ' ' +
                                          item?.unit_name
                                        : ''
                                    }
                                    ${
                                      key === 'quantity'
                                        ? item?.total_units
                                        : ''
                                    }
                                    ${
                                      key === 'supplierName'
                                        ? supplier_details?.name
                                        : ''
                                    }
                                  </td>`
                                })
                                .join('')}
                            </tr>`
                    },
                  )
                  .join('')
              }
            <tr>
              <td style="padding-bottom: 16px"></td>
            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />`

  return (
    QUOTE_HTML_START +
    companyDetail +
    // siteAndJobDetail +
    quoteDesc +
    purchaseTableHead +
    purchaseTableItem +
    QUOTE_HTML_END
  )
}

export const generateInvoiceHTMLString = (
  companyDetails: Api.CompanyDetails,
  projectDetail: Api.ProjectDetailsById,
  invoiceDetail: Api.InvoiceDetailsById,
  currencySymbol: string,
) => {
  let companyDetail = `<tr class="top">
          <td colspan="2">
            <table>
              <tr>
                <td class="title">
                  
                </td>

                <td class="company-details">
                  <strong class="company-title"
                    >${companyDetails?.company_details?.name}</strong
                  ><br />
                  ${companyDetails?.address_details?.address}<br/>
                  ${companyDetails?.company_details?.email}<br />
                  ${companyDetails?.company_details?.mobile}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let siteAndJobDetail = `<tr class="information">
          <td colspan="2">
            <table>
              <tr class="billing-details">
                <td>
                  ${
                    projectDetail?.customer_contact_details?.firstname
                      ? `${projectDetail?.customer_contact_details?.firstname} ${projectDetail?.customer_contact_details?.lastname}<br/>`
                      : ''
                  }
                ${
                  projectDetail?.customer_address_details?.suburb
                    ? `${projectDetail?.customer_address_details?.suburb}<br/>`
                    : ''
                }
                  ${projectDetail?.customer_address_details?.city}${
    projectDetail?.customer_address_details?.zip_code
      ? `, ${projectDetail?.customer_address_details?.zip_code}`
      : ''
  }
     </td>
<td class="site-details">
                  <table>
                    <tr>
                      <td class="site-address">
                        <strong>Site Address</strong>
                        <br />
                        ${
                          projectDetail?.site_contact_details?.firstname
                            ? `${projectDetail?.site_contact_details?.firstname} ${projectDetail?.site_contact_details?.lastname}<br/>`
                            : ''
                        }
                        ${
                          projectDetail?.site_address_details?.suburb
                            ? `${projectDetail?.site_address_details?.suburb}<br/>`
                            : ''
                        }
                        ${projectDetail?.site_address_details?.city}${
    projectDetail?.site_address_details?.zip_code
      ? `, ${projectDetail?.site_address_details?.zip_code}`
      : ''
  }<br /></td>
                      <td class="quote-details">
                      Invoice Number: ${
                        invoiceDetail?.invoice_details?.invoice_number
                      }<br/>
                        Job Number: ${
                          projectDetail?.project_details?.project_prefix
                        }-${
    projectDetail?.project_details?.project_number
  }<br />
                        Company Registration Number: ${
                          companyDetails?.company_details?.tax_number
                        }<br />
                        Invoice Date: ${moment().format('Do MMM YYYY')}<br />
                        Valid Until: ${moment(
                          invoiceDetail?.invoice_details?.due_date,
                        ).format('Do MMM YYYY')}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        `

  let quoteDesc = `<tr>
          <table>
            <tr>
              <strong class="quote-title"> <span className="invoice-title">
            ${invoiceDetail?.invoice_details?.title}
          </span>
          <span
            className='invoice-name'
          >
            ${' - '}${invoiceDetail?.invoice_details?.invoice_number}
          </span> </strong>
            </tr>
            <br />
            <tr>
<p class="quote-description"><p class="quote-description">${invoiceDetail?.invoice_details?.description?.replace(
    '\n',
    '<br/><br/>',
  )}</p>
              </p>            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />
        `

  let quoteTableHead = `<tr>
          <table class="quote-table">
            <tr class="quote-head">
              <th>Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Tax (%)</th>
              <th>Discount (%)</th>
              <th>Total</th>
            </tr>
        `

  let quoteTableItem = `<tr class="quote-item-title">
              ${
                invoiceDetail?.invoice_items?.length &&
                invoiceDetail?.invoice_items
                  .map((item) => {
                    return `
                  <tr class="quote-item-title">
                    <td><strong>${
                      item.invoice_item_details?.title
                    }</strong></td>
                  </tr>
                  
                      ${
                        item?.invoice_line_items?.length > 0 &&
                        item?.invoice_line_items
                          ?.map((item: any) => {
                            return `<tr class="line-item">
                              ${[
                                'title',
                                'quantity',
                                'price',
                                'tax_percent',
                                'discount_percent',
                                'total_cost',
                              ]
                                .map((key) => {
                                  return `<td>
                                    ${item[key]}
                                  </td>`
                                })
                                .join('')}
                            </tr>`
                          })
                          .join('')
                      }
              `
                  })
                  .join('')
              }
            <tr>
              <td style="padding-bottom: 16px"></td>
            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />`

  let amountDetails = `<tr class="amount-details">
          <td colspan="2">
            <table>
              <tr>
                <td style="width: 250px"></td>
                <td class="totals">
                  <table>
                    <tr>
                      <td class="totals-cols">
                        Sub Total <br />
                        Discount <br />
                        Discounted Sub Total <br />
                        Tax <br />
                        <br />
                        Total <br />
                      </td>
                      <td class="totals-cols">
                        ${currencySymbol}${
    Number(invoiceDetail?.invoice_details?.taxable_cost)?.toFixed(2) ?? 0.0
  }<br />-${currencySymbol}${
    Number(invoiceDetail?.invoice_details?.discount).toFixed(2) ?? 0.0
  }<br />${currencySymbol}${
    Number(invoiceDetail?.invoice_details?.discounted_cost)?.toFixed(2) ?? 0.0
  }<br />${currencySymbol}${
    Number(invoiceDetail?.invoice_details?.tax).toFixed(2) ?? 0.0
  }<br /><br />${currencySymbol}${
    Number(invoiceDetail?.invoice_details?.total_cost).toFixed(2) ?? 0.0
  }<br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let quoteFoot = invoiceDetail?.invoice_details?.footer_notes
    ? `<tr>
          <p class="quote-footer">${invoiceDetail?.invoice_details?.footer_notes?.replace(
            '\n',
            '<br/><br/>',
          )}</p>
        </tr>`
    : ``
  return (
    QUOTE_HTML_START +
    companyDetail +
    siteAndJobDetail +
    quoteDesc +
    quoteTableHead +
    quoteTableItem +
    amountDetails +
    quoteFoot +
    QUOTE_HTML_END
  )
}

export const generateWorkerInvoiceHTMLString = (
  companyDetails: Api.CompanyDetails,
  companyUserDetails: Api.CompanyUserDetails,
  workerInvoiceDetail: Api.WorkerInvoiceDetailsById,
  currencySymbol: string,
) => {
  let companyDetail = `<tr class="top">
          <td colspan="2">
            <table>
              <tr>
                <td class="title">
                  
                </td>

                <td class="company-details">
                  <strong class="company-title"
                    >${companyDetails?.company_details?.name}</strong
                  ><br />
                  ${companyDetails?.address_details?.address}<br/>
                  ${companyDetails?.company_details?.email}<br />
                  ${companyDetails?.company_details?.mobile}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let siteAndJobDetail = `<tr class="information">
          <td colspan="2">
            <table>
              <tr class="billing-details">
                <td>
                  
     </td>
<td class="site-details">
                  <table>
                    <tr>
                      <td class="site-address">
                        <strong>Details</strong>
                        <br />
                        ${
                          companyUserDetails?.user_details?.display_name
                            ? `${companyUserDetails?.user_details?.display_name} ${companyUserDetails?.user_details?.lastname}<br/>`
                            : ''
                        }
                        ${
                          companyUserDetails?.address_details?.suburb
                            ? `${companyUserDetails?.address_details?.suburb}<br/>`
                            : ''
                        }
                        ${companyUserDetails?.address_details?.city ?? ''}${
    companyUserDetails?.address_details?.zip_code
      ? `, ${companyUserDetails?.address_details?.zip_code}`
      : ''
  }<br /></td>
                      <td class="quote-details">
                      Invoice Number: ${
                        workerInvoiceDetail?.worker_invoice_detail
                          ?.invoice_number
                      }<br/>
                        Company Registration Number: ${
                          companyDetails?.company_details?.tax_number
                        }<br />
                        Invoice Date: ${moment().format('Do MMM YYYY')}<br />
                        Valid Until: ${moment(
                          workerInvoiceDetail?.worker_invoice_detail?.due_date,
                        ).format('Do MMM YYYY')}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        `

  let quoteDesc = `<tr>
          <table>
            <tr>
              <strong class="quote-title"> <span className="invoice-title">
            ${workerInvoiceDetail?.worker_invoice_detail?.title}
          </span>
          <span
            className='invoice-name'
          >
            ${' - '}${
    workerInvoiceDetail?.worker_invoice_detail?.invoice_number
  }
          </span> </strong>
            </tr>
            <br />
            <tr>
<p class="quote-description"><p class="quote-description">${workerInvoiceDetail?.worker_invoice_detail?.description?.replace(
    '\n',
    '<br/><br/>',
  )}</p>
              </p>            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />
        `

  let quoteTableHead = `<tr>
          <table class="quote-table">
            <tr class="quote-head">
              <th>Name</th>
              <th>Pay Rate (/hr)</th>
              <th>Time (mins)</th>
              <th>Total</th>
            </tr>
        `

  let quoteTableItem = `<tr class="quote-item-title">
              ${
                workerInvoiceDetail?.worker_invoice_item_list?.length &&
                workerInvoiceDetail?.worker_invoice_item_list
                  .map((item) => {
                    return `
                  <tr class="quote-item-title">
                    <td><strong>${
                      item.worker_inv_item_details?.title
                    }</strong></td>
                  </tr>
                  
                      ${
                        item?.worker_inv_line_item_list?.length > 0 &&
                        item?.worker_inv_line_item_list
                          ?.map((item: any) => {
                            return `<tr class="line-item">
                              ${['title', 'pay_rate', 'time_mins', 'total_cost']
                                .map((key) => {
                                  return `<td>
                                    ${item[key]}
                                  </td>`
                                })
                                .join('')}
                            </tr>`
                          })
                          .join('')
                      }
              `
                  })
                  .join('')
              }
            <tr>
              <td style="padding-bottom: 16px"></td>
            </tr>
          </table>
        </tr>
        <hr class="horizontal-break" />`

  let amountDetails = `<tr class="amount-details">
          <td colspan="2">
            <table>
              <tr>
                <td style="width: 250px"></td>
                <td class="totals">
                  <table>
                    <tr>
                      <td class="totals-cols">
                        <br />
                        Total <br />
                      </td>
                      <td class="totals-cols">
                       <br />${currencySymbol}${
    Number(workerInvoiceDetail?.worker_invoice_detail?.total_cost).toFixed(2) ??
    0.0
  }<br />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>`

  let quoteFoot = workerInvoiceDetail?.worker_invoice_detail?.footer_notes
    ? `<tr>
          <p class="quote-footer">${workerInvoiceDetail?.worker_invoice_detail?.footer_notes?.replace(
            '\n',
            '<br/><br/>',
          )}</p>
        </tr>`
    : ``
  return (
    QUOTE_HTML_START +
    companyDetail +
    siteAndJobDetail +
    quoteDesc +
    quoteTableHead +
    quoteTableItem +
    amountDetails +
    quoteFoot +
    QUOTE_HTML_END
  )
}
