import {GET_QUOTE_SETTINGS, UPDATE_QUOTE_SETTINGS} from '../actions'

const initialState: RT.QuoteSettingsReduxType = {
  loading: false,
  get_loading: false,

  defaultQuoteSettings: undefined,

  error: '',
  get_error: ''
}

export function quoteSettingsReducer(state = initialState, action: any) {
  const {type, payload} = action

  //

  switch (type) {
    case UPDATE_QUOTE_SETTINGS.LOADING:
      return {...state, loading: true, error: false}
    case UPDATE_QUOTE_SETTINGS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      }
    case UPDATE_QUOTE_SETTINGS.ERROR:
      return {...state, loading: false, error: payload}

    case GET_QUOTE_SETTINGS.LOADING:
      return {
        ...state,
        get_loading: true,
        get_error: false
      }
    case GET_QUOTE_SETTINGS.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        defaultQuoteSettings: payload
      }
    case GET_QUOTE_SETTINGS.ERROR:
      return {...state, get_loading: false, get_error: payload}

    default:
      return state
  }
}
