import { useCallback, useEffect, useState } from 'react'
import {
  FaComments,
  FaEnvelope,
  FaEye,
  FaMapMarkerAlt,
  FaPen,
  FaPrint,
  FaUser,
  FaUserAlt,
} from 'react-icons/fa'
import { HiDotsHorizontal, HiMail } from 'react-icons/hi'
import { FaRegChartBar } from 'react-icons/fa'
import { AiFillCaretDown } from 'react-icons/ai'
import { MdClose } from 'react-icons/md'
import { Auth, useNavigation } from 'react-auth-navigation'
import { useDispatch, useSelector } from 'react-redux'

import { CustomSidePanel, NotesSummary } from 'app/components'
import { customerSidePanelData } from 'constants-src'
import {
  Button,
  ButtonGroup,
  ConfirmationModal,
  Dropdown,
  Loading,
} from 'app/common'
import { CustomerModal } from 'app/pages/customers/components'
import { getCustomerById } from 'redux-src'

export const CustomerViewPage = () => {
  const { params } = useNavigation()
  const { customerId } = params as any
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getCustomerById(customerId, () => {
        // setNewCustomerModal(true);
        // navigate(`/customers/view/${id}`)
      })
    )
  }, [dispatch, customerId])

  const [newCustomerModal, setNewCustomerModal] = useState(false)

  // const { customerById: customer } = useSelector((state: any) => state.customer)
  const {
    customerById: customer,
    get_customer_by_id_loading: loading,
  }: RT.CustomerReduxType = useSelector((state: any) => state.customer)

  const customerAddCallback = useCallback(
    (page?: number, tableLimit?: number) => {
      dispatch(getCustomerById(customer?.customer_details?.id))
    },
    [customer?.customer_details?.id, dispatch]
  )

  return loading ? (
    <Loading />
  ) : (
    <div className="w-9/12 mx-auto border-x-1 border-b-1 border-gray-200">
      <div className="flex border-t-1 border-t-gray-200">
        <div className="bg-white border-r-1 border-r-gray-200 py-10 w-[200px] min-h-[85vh]">
          <CustomSidePanel
            append={
              <div className="mb-12 pb-6 border-b-1 border-gray-200">
                <div className="p-8 gap-6 flex flex-col">
                  <button className="text-sm text-gray-400 border-1 border-gray-200 px-12 py-[3px] w-full rounded-md bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out flex items-center justify-center gap-4">
                    <FaRegChartBar size={12} />
                    Customer Report
                  </button>
                  <Dropdown
                    placement="bottomleft"
                    triggerElement={
                      <button className="text-sm text-gray-400 border-1 border-gray-200 px-12 py-[3px] w-full rounded-md bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out flex items-center justify-center gap-4">
                        Summary Of Activity <AiFillCaretDown size={12} />
                      </button>
                    }
                    triggerToggle
                  >
                    <div
                      style={{ minWidth: '13rem', zIndex: 1, marginTop: 10 }}
                    >
                      <div className="flex-1 w-full   overflow-visible ">
                        <ul className=" border-gray-200 rounded-sm shadow-xl overflow-auto ">
                          <li className="p-10 pl-20 cursor-pointer bg-white text-black hover:bg-blue-100  text-md">
                            <span className="text-left inline-flex cursor-pointer items-center gap-4">
                              <FaEye size={14} />
                              View summary of activity
                            </span>
                          </li>

                          <li className="p-10 pl-20 cursor-pointer  bg-white text-black hover:bg-blue-100  text-md ">
                            <span className="text-left inline-flex cursor-pointer items-center gap-4">
                              <FaPrint size={14} />
                              Print summary of activity
                            </span>
                          </li>
                          <li className="p-10 pl-20 cursor-pointer  bg-white text-black hover:bg-blue-100  text-md ">
                            <span className="text-left inline-flex cursor-pointer items-center gap-4">
                              <HiMail size={14} />
                              Email summary of activity
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            }
            routeIdAlias="customerId"
            routeName="CustomerView"
            data={customerSidePanelData}
          />
        </div>

        <div className="flex-1 bg-white pt-10 px-16">
          <div className="pb-20 flex items-center justify-between">
            <div className="">
              <span className="text-black font-bold text-[18px]">
                {customer?.customer_details?.name}
              </span>
            </div>

            <div className="">
              <div className="flex flex-wrap">
                <div>
                  <Button
                    className="mr-20"
                    size="sm"
                    title="Add Project to Customer"
                    onClick={() => {
                      // dispatch({
                      //   type: GET_CUSTOMER_BY_ID.CLEAR,
                      // })
                    }}
                  />
                </div>

                <div>
                  <Dropdown
                    inDismiss={false}
                    outDismiss={false}
                    placement="bottomright"
                    triggerElement={
                      <button className="p-10 py-6 bg-grey-100 shadow-sm rounded-sm border-1 transition-all duration-200 ease-in-out border-none hover:bg-grey-200">
                        <HiDotsHorizontal size={20} />
                      </button>
                    }
                  >
                    <div style={{ minWidth: '12rem', zIndex: 1 }}>
                      <div className=" w-full mt-10">
                        {/* <DropDownFiles /> */}

                        <div
                          className="flex-1 w-full   overflow-visible "
                          style={{ width: 200 }}
                        >
                          <ul className=" border-gray-200 rounded-sm shadow-xl overflow-auto ">
                            <ConfirmationModal
                              danger
                              label={
                                'Are you sure you want to delete this customer?'
                              }
                              displayElement={
                                <li className="p-10 pl-20 cursor-pointer bg-white text-black hover:bg-blue-100  text-md">
                                  <span className="text-left inline-flex cursor-pointer items-center gap-4">
                                    <MdClose size={14} />
                                    Delete Customer
                                  </span>
                                </li>
                              }
                              confirmLabel="Delete Customer"
                            >
                              <div className="p-10 font-bold bg-red-200 text-red-400 rounded-sm">
                                This will archive all attached jobs. This cannot
                                be undone.
                              </div>
                            </ConfirmationModal>

                            <ConfirmationModal
                              size="md"
                              label={'Merge Customers'}
                              displayElement={
                                <li className="p-10 pl-20 cursor-pointer  bg-white text-black hover:bg-blue-100  text-md ">
                                  <span className="text-left inline-flex cursor-pointer items-center gap-4">
                                    <FaUser size={12} />
                                    Merge Customer
                                  </span>
                                </li>
                              }
                              confirmLabel="Ok"
                            >
                              <div className="p-10 font-bold flex flex-col gap-20 items-center justify-center">
                                <div className="my-10">
                                  Merge {customer?.customer_details?.name} with:
                                </div>
                                <div className="w-[300px]">
                                  <input
                                    placeholder="Search for a customer to merge with..."
                                    className="w-[300px] placeholder:font-normal px-10 py-6 border-b-1 border-blue-100 outline-none hover:border-blue-150 focus:border-blue-200"
                                  />
                                </div>
                                <div className="my-10">
                                  Please search for a customer by entering their
                                  name and pressing enter.
                                </div>
                              </div>
                            </ConfirmationModal>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-20 flex items-start justify-between border-1 rounded-sm border-gray-200">
            <div className="flex items-center justify-between ">
              <div className="px-10 flex items-center justify-center cursor-pointer border-gray-200 relative w-[130px] text-gray-300">
                <div className="flex-nowrap">
                  <FaUser size={30} className="mx-auto" />
                  <p className="text-center mt-20 text-sm text-gray-300">
                    Customer
                  </p>
                </div>
              </div>

              <div className="flex flex-col border-l-1 border-gray-200 p-10 px-16 ">
                <span className="text-blue-300 font-bold text-[14px]">
                  {customer?.customer_details?.name}
                </span>
                <span className="flex ml-20 my-6 text-gray-400 items-center justify-start">
                  <FaMapMarkerAlt
                    className="mx-6 mr-10"
                    size={14}
                    color="#c9c9c9"
                  />
                  {customer?.postal_address_details?.address}
                </span>

                <span className="flex ml-20 my-6 font-bold gap-4 items-center justify-start">
                  <FaUserAlt className="mx-6" size={14} color="#c9c9c9" />
                  <span className="text-gray-500 text-md">
                    {customer?.default_contact_details?.firstname}
                  </span>
                  <span className="text-gray-500 text-md">
                    {customer?.default_contact_details?.lastname}
                  </span>
                  <span className="text-gray-500 text-md">
                    ({customer?.default_contact_details?.position})
                  </span>
                </span>

                <span className="ml-8 mb-6 text-sm">
                  {customer?.default_contact_details?.phones !== null &&
                    customer?.default_contact_details?.phones.length !== 0 && (
                      <span className="text-gray-300 text-sm px-4">
                        {customer?.default_contact_details?.phones[0]},
                      </span>
                    )}
                  {customer?.default_contact_details?.emails !== null &&
                    customer?.default_contact_details?.emails.length !== 0 && (
                      <span className="text-blue-300 text-sm px-4">
                        {customer?.default_contact_details?.emails[0]}
                      </span>
                    )}
                </span>
              </div>
            </div>

            <div className="mt-10 mr-16">
              <ButtonGroup
                data={[
                  {
                    icon: (
                      <FaEnvelope
                        // onClick={handleQuickEmail}
                        size={12}
                      />
                    ),
                  },
                  {
                    icon: (
                      <FaComments
                        // onClick={handleQuickSms}
                        size={12}
                      />
                    ),
                  },
                  {
                    icon: (
                      <FaMapMarkerAlt
                        // onClick={handleQuickMap}
                        size={12}
                      />
                    ),
                  },
                  {
                    icon: <FaPen size={12} />,
                    onClick: () => {
                      setNewCustomerModal((prev) => !prev)
                    },
                  },
                ]}
              />
            </div>
          </div>

          <div className="mb-20">
            <NotesSummary
              type="customer"
              hostName={customer?.customer_details?.name}
            />
          </div>

          <CustomerModal
            newCustomerModal={newCustomerModal}
            setNewCustomerModal={setNewCustomerModal}
            customerAddCallback={customerAddCallback}
            activeCustomer={customer}
          />
          <Auth.Screens path="/customers/view/:customerId" />
        </div>
      </div>
    </div>
  )
}
