import {useIndividualFormElement} from 'hooks'
import {useAuth} from 'react-auth-navigation'

interface HyperlinkPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

export const HyperlinkPreview = ({id, type}: HyperlinkPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  return individualElement?.visibleTo.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold">*</span>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
      <a
        href={
          !Array.isArray(individualElement?.content?.value)
            ? individualElement?.content?.value
            : '/'
        }
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        {individualElement?.content?.value}
      </a>
    </div>
  ) : (
    <></>
  )
}
