import {useEffect, useState, useRef} from 'react'
import {Auth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {DefaultButton, Input, Modal} from 'app/common'
import {SidePanel, Collapsible, SiteVisitLink} from 'app/components'

import {
  createSiteVisitAction,
  getSiteVisitListAction,
  getVoidedSiteVisitListAction,
  getProjectByIdAction,
  getProjectPlanList,
} from 'redux-src'
import {sidePanelData} from 'constants-src'

import {JobsHeader} from './components'

export const JobsPage = () => {
  const {
    params,
    navigation: {navigate},
  } = useNavigation()
  const {projectId} = params as any
  const dispatch = useDispatch()
  const [createSiteVisitModal, setCreateSiteVisitModal] = useState(false)

  const {
    createSiteVisitLoading,
    getSiteVisitList,
    getVoidedSiteVisitList,
  }: RT.SiteVisitReduxType = useSelector((state: any) => state.siteVisit)

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const collapseRef = useRef(null)

  const [data, {onChange, validator, setErrors, errors, modified, clear}] =
    useFormInput({
      titleName: '',
      description: '',
    })

  useEffect(() => {
    dispatch(getProjectPlanList(projectId))
    dispatch(getProjectByIdAction(projectId))
    dispatch(getSiteVisitListAction(projectId))
    dispatch(getVoidedSiteVisitListAction(projectId, 1, 10, () => {}))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const openCollapse = () => {
    collapseRef?.current?.setOpenCollapse()
  }

  const onCreateSiteVisit = () => {
    const {titleName, description} = data
    const catchedErrors = {}
    const validate = validator(catchedErrors)

    validate('titleName', {
      condition: titleName.length === 0,
      message: 'Title can not be empty!',
    })

    validate('description', {
      condition: description.length === 0,
      message: 'Description can not be empty!',
    })

    setErrors(catchedErrors)

    if (titleName.length === 0 && description.length === 0) return

    const body = {
      title: titleName,
      description: description,
    }
    dispatch(
      createSiteVisitAction(projectId, body, (data) => {
        setCreateSiteVisitModal(false)
        dispatch(getSiteVisitListAction(projectId))
        clear()
        navigate(`/projects/${projectId}/site-visit/${data?.siteVisit?.id}`)
        openCollapse()
      }),
    )
  }
  return (
    <>
      <div className="w-9/12 mx-auto border-x-1 border-b-1 border-gray-200">
        <JobsHeader />

        <div className="flex border-t-1 border-t-gray-200">
          <div
            className="bg-white border-r-1 border-r-gray-200 py-10 pt-24"
            style={{width: 200}}
          >
            <SidePanel
              data={
                projectDetail?.project_details?.project_type === 'Quote'
                  ? sidePanelData
                  : {
                      overview: sidePanelData?.overview,
                      'user management': sidePanelData?.['user management'],
                      tools: sidePanelData?.tools?.filter(
                        (item) => item?.name !== 'Quote',
                      ),
                    }
              }
            />
            <div className="px-16">
              {projectDetail?.project_details?.is_quote_accepted && (
                <>
                  <Collapsible
                    active={true}
                    ref={collapseRef}
                    title={
                      <div className="flex-col">
                        Site Visits ({getSiteVisitList?.length ?? 0})
                        <button
                          onClick={() => setCreateSiteVisitModal(true)}
                          className="border-1 mt-16 border-gray-200 bg-white hover:bg-gray-100 rounded-sm shadow-sm px-16 py-4 mb-16"
                        >
                          Create Site Visit
                        </button>
                      </div>
                    }
                  >
                    {getSiteVisitList?.length > 0 &&
                      getSiteVisitList?.map((item, index) => (
                        <SiteVisitLink
                          to={`/projects/${projectId}/site-visit/${item.site_visit_details?.id}/scheduling`}
                          key={index}
                          detail={item?.site_visit_details}
                        />
                      ))}
                  </Collapsible>

                  {Array.isArray(getVoidedSiteVisitList) &&
                    getVoidedSiteVisitList?.length > 0 && (
                      <Collapsible
                        // active={true}
                        title={`Voided Site Visits (${
                          getVoidedSiteVisitList?.length ?? 0
                        })`}
                      >
                        {getVoidedSiteVisitList?.length > 0 &&
                          getVoidedSiteVisitList?.map(
                            ({site_visit_details}) => (
                              <SiteVisitLink
                                to={`/projects/${projectId}/site-visit/${site_visit_details.id}/scheduling`}
                                key={site_visit_details.id}
                                detail={site_visit_details}
                              />
                            ),
                          )}
                      </Collapsible>
                    )}
                </>
              )}
            </div>{' '}
          </div>

          <div className="flex-1 bg-white pt-24 px-16">
            <Auth.Screens path="/projects/:projectId" />
          </div>
        </div>
      </div>

      <Modal
        title="New Site Visit"
        isActive={createSiteVisitModal}
        toggleModal={setCreateSiteVisitModal}
      >
        <div>
          <div className="p-16 flex flex-col gap-16">
            <div className="flex items-center">
              <div className="w-[100px] font-bold mt-[-10px]">
                Title <span className="text-red-300 ml-4">*</span>
              </div>
              <div className="flex-1">
                <Input
                  label
                  className={`border-1 border-gray-200 rounded-sm p-4 w-[100%]`}
                  value={data.titleName}
                  name="titleName"
                  error={errors?.titleName && modified?.titleName}
                  onChange={onChange}
                />
                {errors?.titleName && (
                  <span className="text-red-300">{errors?.titleName}</span>
                )}
              </div>
            </div>

            <div className="flex items-center">
              <div className="w-[100px] font-bold">
                Description <span className="text-red-300 ml-4">*</span>
              </div>
              <div className="flex-1">
                <textarea
                  className="border-1 border-gray-200 rounded-sm p-4 w-[100%]"
                  name="description"
                  value={data.description}
                  onChange={onChange}
                ></textarea>
                {errors?.description && (
                  <span className="text-red-300">{errors?.description}</span>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-8 p-16 justify-end border-t-1 border-gray-200">
            <DefaultButton
              title="Cancel"
              onClick={() => setCreateSiteVisitModal(false)}
            />
            <DefaultButton
              title="Save"
              className="!bg-blue-300 text-white hover:!bg-blue-400"
              onClick={onCreateSiteVisit}
              loading={createSiteVisitLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
