import { useCallback, useEffect } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L, { LatLngTuple } from 'leaflet'

interface MapProps {
  centerData: LatLngTuple
  height?: number
}

export const Map = ({ centerData, height = 300 }: MapProps) => {
  const memo = useCallback(
    (zoomValue?: number) => {
      return {
        centerData: centerData || [50, 50],
        zoom: zoomValue ?? 12,
      }
    },
    [centerData]
  )

  return (
    <MapContainer
      center={memo().centerData}
      zoom={memo().zoom}
      scrollWheelZoom={false}
      className={`w-full h-[${height}px] z-0`}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <MarkerComp centerData={memo().centerData} />
    </MapContainer>
  )
}

const MarkerComp = ({ centerData }: { centerData: LatLngTuple }) => {
  const map = useMap()

  useEffect(() => {
    map.closePopup()
    map.flyTo(centerData, 12)
  }, [centerData, map])

  return (
    <>
      <Marker
        position={centerData}
        icon={L.icon({
          iconUrl: '/Google_Maps_pin.svg',
          iconSize: [30, 30],
        })}
      />
    </>
  )
}
