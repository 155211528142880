import {DataTable} from 'app/components'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch} from 'react-redux'
import {getSiteVisitFinancialBreakdown} from 'redux-src'

interface SiteVisitDataType {
  data: Api.SiteVisitFinancialBreakdownList | []
}
export const SiteVisitBreakdown = ({data}: SiteVisitDataType) => {
  const {currencySymbol} = useAuth()
  const columns: TableColumn<Api.SiteVisitFinancialBreakdownIndividual>[] = [
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Site Visit #
        </div>
      ),
      // selector: (row) => row.site_visit_number,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.quote_details.id}`}
            className="text-blue-300"
          >
            {/* smart 15 */}
            {row.id} - {row.title}
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Labour Cost
        </div>
      ),
      selector: (row: Api.SiteVisitFinancialBreakdownIndividual) =>
        row.labour_cost,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.labour_cost}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Paid Labour Cost
        </div>
      ),
      selector: (row: Api.SiteVisitFinancialBreakdownIndividual) =>
        row.paid_labour_cost,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.paid_labour_cost}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Unpaid Labour Cost
        </div>
      ),
      selector: (row: Api.SiteVisitFinancialBreakdownIndividual) =>
        row.unpaid_labour_cost,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.unpaid_labour_cost}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Material Cost
        </div>
      ),
      selector: (row: Api.SiteVisitFinancialBreakdownIndividual) =>
        row.material_cost,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.material_cost}
        </div>
      ),
    },

    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Invoiced Material Cost
        </div>
      ),
      selector: (row: Api.SiteVisitFinancialBreakdownIndividual) =>
        row.invoiced_material_cost,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.invoiced_material_cost}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Uninvoiced Material Cost
        </div>
      ),
      selector: (row: Api.SiteVisitFinancialBreakdownIndividual) =>
        row.uninvoiced_material_cost,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.uninvoiced_material_cost}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Total Invoiced Cost
        </div>
      ),
      selector: (row: Api.SiteVisitFinancialBreakdownIndividual) =>
        row.total_invoiced_cost,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="text-sm font-light px-6 ">
          {row.total_invoiced_cost}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Total Cost
        </div>
      ),
      selector: (row: Api.SiteVisitFinancialBreakdownIndividual) =>
        row.total_cost,
      sortable: true,
      cell: (row: Api.SiteVisitFinancialBreakdownIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.total_cost}
        </div>
      ),
    },
  ]

  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  return (
    <div>
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16 -mx-16">
        Site Visit Breakdown
      </h3>

      <div className="-mx-16">
        <DataTable
          columns={columns}
          data={data}
          // defaultSortFieldId={2}
          pagination
          persistTableHead
          dense
          striped
          fetchData={(page, limit) => {
            dispatch(getSiteVisitFinancialBreakdown(projectId, {page, limit}))
          }}
        />
      </div>
    </div>
  )
}
