import {useIndividualFormElement} from 'hooks'
import {useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

interface ShortTextPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isPreview?: boolean
}

interface LongTextPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

interface TextContentType {
  label: string
  description: string
  value: string
}

export const ShortTextPreview = ({id, type}: ShortTextPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  const [shortText, setShortText] = useState<TextContentType>(
    individualElement?.content as TextContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setShortText((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    if (shortText) dispatch(alterFormContent(id, shortText, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortText])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div className="text-sm text-gray-400">
          {individualElement?.content?.description}
        </div>
      )}
      <div className="w-full h-[35px] border border-solid border-1 border-gray-300 rounded-sm overflow-hidden">
        <input
          name="value"
          id="shortText"
          value={shortText?.value}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          placeholder="Type here"
          disabled={
            type !== 'web view' ||
            !individualElement?.editableBy?.includes(userRole)
          }
          className="px-4 w-full h-full text-sm"
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export const LongTextPreview = ({id, type}: LongTextPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  const [longText, setLongText] = useState<TextContentType>(
    individualElement?.content as TextContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setLongText((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    if (longText) dispatch(alterFormContent(id, longText, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longText])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div className="w-full border border-solid border-1 border-gray-300 rounded-sm overflow-hidden">
        <textarea
          name="value"
          id="longText"
          value={longText?.value}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          style={{
            resize: 'none'
          }}
          disabled={
            type !== 'web view' ||
            !individualElement?.editableBy?.includes(userRole)
          }
          cols={10}
          rows={4}
          placeholder="Type here"
          className={`w-full h-full px-4 text-sm`}
        ></textarea>
      </div>
    </div>
  ) : (
    <></>
  )
}
