import {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'
import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface ParagraphProps {
  id: number
  onSelectElement: (id: number) => void
}

interface ParagraphContentType {
  label: string
  description: string
  value: string
}

export const Paragraph = ({id, onSelectElement}: ParagraphProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [paragraphDetails, setParagraphDetails] =
    useState<ParagraphContentType>(
      individualElement?.content as ParagraphContentType
    )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setParagraphDetails((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, paragraphDetails, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragraphDetails])

  const isParagraphAdded = !!(individualElement?.content?.value.length === 0)

  return (
    <div
      className={`flex flex-col border border-1 rounded-sm overflow-hidden gap-4 ${
        selectedFormElementId === id
          ? `border-solid border-${isParagraphAdded ? 'red' : 'blue'}-300`
          : `border-dashed border-${
              isParagraphAdded ? 'red' : 'gray'
            }-300 hover:bg-${isParagraphAdded ? 'red' : 'blue'}-100`
      }
      ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="py-8">
          <input
            type="text"
            name="label"
            value={paragraphDetails?.label}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div className="pb-6">
          <input
            type="text"
            name="description"
            value={paragraphDetails?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className={`text-black text-sm w-full ml-14 bg-transparent ${
              !!individualElement?.settings?.hideLabel ? 'mt-4' : ''
            }`}
          />
        </div>
      )}
      <div
        className={`overflow-hidden ${
          individualElement?.settings?.hideLabel &&
          individualElement?.settings?.hideDescription
            ? 'mt-4'
            : ''
        }`}
      >
        <textarea
          name="value"
          id="paragraph"
          value={paragraphDetails?.value}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          cols={10}
          rows={5}
          placeholder="Type here"
          className="w-full focus:outline-none text-black text-sm ml-12 bg-transparent resize-none"
        />
      </div>
    </div>
  )
}
