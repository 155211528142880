import {useCallback, useEffect} from 'react'
import {
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  StylesConfig,
  MenuListProps,
  SelectInstance,
} from 'react-select'
import {components} from 'react-select'
import {withAsyncPaginate, wrapMenuList} from 'react-select-async-paginate'

import {SelectField, SelectFieldProps} from 'app/common'
import {api} from 'config'
import {colors} from 'modules'
import {getParsedUrl} from 'helpers'

interface DynamicSearchSelectType<T>
  extends Omit<SelectFieldProps<T>, 'options'> {
  label?: React.ReactNode
  required?: boolean
  actionUrl: string
  remapOptions: (res: any) => Array<T>
  debounceTimeout?: number
  additional?: {
    page?: number
    dependencies?: Array<unknown>
  }
  defaultAdditional?: {
    page?: number
  }
  options?: Array<T>
  cacheOptions?: boolean
  containerClassname?: string
  customOption?: React.ReactNode
  params?: {[key: string]: string | number | boolean}
}

const CustomAsyncPaginate = withAsyncPaginate(SelectField as any)

export const DynamicSearchSelect = <
  T extends {id: number; label: string; value: number | string}
>({
  label,
  containerClassname = 'w-full',
  required,
  actionUrl,
  getOptionLabel,
  getOptionValue,
  debounceTimeout = 500,
  remapOptions,
  additional = {
    page: 1,
  },
  defaultAdditional,
  options = [],
  defaultOptions,
  borderless,
  error,
  isMulti,
  height = '31px',
  cacheOptions,
  customOption,
  params,
  ...rest
}: DynamicSearchSelectType<T>) => {
  const selectStyles: StylesConfig<T, boolean, GroupBase<T>> = {
    container: styles => ({
      ...styles,
    }),
    // @ts-ignore
    control: (styles, {isFocused}) => ({
      ...styles,
      borderRadius: 4,
      borderColor: borderless
        ? 'transparent'
        : !!error
        ? colors.light.red
        : isFocused
        ? colors.light.primary200
        : colors.light.grey200,
      backgroundColor: 'white',
      boxShadow: isFocused && 'none',
      '&:hover': {
        borderColor: borderless ? 'transparent' : colors.light.primary300,
      },
      fontSize: 12,
      maxWidth: '100%',
      minWidth: '100%',
      minHeight: isMulti ? '100%' : height,
      maxHeight: isMulti ? '100%' : height,
      padding: '0px 8px',
      overflow: isMulti ? 'initial' : 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    }),
    // @ts-ignore
    valueContainer: styles => ({
      ...styles,
      padding: 0,
    }),
    // @ts-ignore
    singleValue: styles => ({
      ...styles,
      height,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflow: 'hidden',
      padding: '6px 0',
    }),
    // @ts-ignore
    menu: styles => ({
      ...styles,
      borderRadius: 4,
      fontSize: 12,
    }),

    // @ts-ignore
    multiValue: (styles, {data}: any) => {
      return {
        ...styles,
        backgroundColor: data?.disabled
          ? colors.light.grey200
          : colors.light.grey400,
        color: data?.disabled ? colors.light.grey100 : colors.light.blue,
      }
    },
    // @ts-ignore
    multiValueLabel: (
      styles,
      {data}: {data: T & {disabled?: boolean; color?: string}},
    ) => ({
      ...styles,
      cursor: data?.disabled ? 'not-allowed' : 'default',
      color: data?.color,
    }),
    // @ts-ignore
    multiValueRemove: (styles, {data}: any) => ({
      ...styles,
      pointerEvents: data?.disabled ? 'none' : 'all',
      ':hover': {
        backgroundColor: data.color,
        color: data?.disabled ? 'white' : colors.light.red,
      },
    }),

    // @ts-ignore
    dropdownIndicator: (styles, {isFocused}: {isFocused: boolean}) => ({
      ...styles,
      fontSize: 14,
      color: isFocused ? colors.light.grey100 : colors.light.grey200,
      padding: 0,
    }),
    // @ts-ignore
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    // @ts-ignore
    option: (
      styles,
      {
        isSelected,
        isFocused,
        isDisabled,
      }: {isSelected: boolean; isFocused: boolean; isDisabled: boolean},
    ) => ({
      ...styles,
      backgroundColor: isSelected
        ? colors.light.primary200
        : isFocused
        ? 'rgb(0, 0, 200, 0.2)'
        : '',
      color: isDisabled ? '#afafaf' : isSelected ? '#ffffff' : '',
      transition: 'all 0.3s ease-in-out',
      fontSize: 12,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      // textAlign: 'left',
    }),
  }

  const loadOptions = useCallback(
    async (search: string, loadedOptions: unknown, {page}: {page: number}) => {
      try {
        const res = await api<Api.Base<any>>(
          getParsedUrl(`${actionUrl}`, {page, limit: 10, search, ...params}),
          'GET',
        )
        const {
          data: {data},
        } = res.data

        const remappedOpt = remapOptions(data?.rows ?? data)

        if (!!!remappedOpt || !Array.isArray(remappedOpt)) {
          throw new Error(
            'Remapped options is either undefined or not an array!',
          )
        }

        return {
          options: remappedOpt,
          hasMore: !(data?.isLast ?? true),
          additional: {
            page: page + 1,
          },
        }
      } catch (error) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 1,
          },
        }
      }
    },
    [actionUrl, params, remapOptions],
  )
  const CustomMenuList = ({
    children,
    ...rest
  }: MenuListProps<T, boolean, GroupBase<T>>) => {
    return (
      <components.MenuList {...rest}>
        {customOption}
        {children}
      </components.MenuList>
    )
  }

  const MenuList = wrapMenuList(CustomMenuList)

  return (
    <div className={`flex flex-col gap-6 relative ${containerClassname}`}>
      {label && (
        <span>
          <span>{label}</span>{' '}
          {required && (
            <span className="text-red-950" style={{color: 'red'}}>
              *
            </span>
          )}
        </span>
      )}
      <CustomAsyncPaginate
        options={options}
        styles={selectStyles}
        isMulti={isMulti}
        debounceTimeout={debounceTimeout}
        getOptionLabel={getOptionLabel as GetOptionLabel<T>}
        getOptionValue={getOptionValue as GetOptionValue<T>}
        loadOptions={loadOptions as any}
        additional={additional}
        defaultOptions={defaultOptions}
        defaultAdditional={defaultAdditional}
        cacheUniqs={
          cacheOptions
            ? [actionUrl, ...(additional?.dependencies ?? [])]
            : undefined
        }
        components={{
          MenuList,
        }}
        value={isMulti ? rest?.multiValue : rest?.value}
        {...rest}
      />
    </div>
  )
}
