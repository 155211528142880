import {useCallback, useContext, useEffect, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useSelector, useDispatch} from 'react-redux'
import {FaPen, FaTrash} from 'react-icons/fa'
import moment from 'moment'

import {DataTable} from 'app/components'
import {ConfirmationModal, ToolTip} from 'app/common'
import {
  getProjectTicketList,
  removeDaysWorkTickets,
  removeSubTicket,
  removeTicketAction,
} from 'redux-src'

import {CreateTicketModel} from '../createTicketModel'
import {TicketContext} from '../../ticket.page'
import {ProgressBar} from '../progressBar'
import {getStatusChip} from '../planList/components'

interface ProjectTicketListType {
  id: number
  title: string
  status: string
  project: string
  dueDate: string
  progressPercentage: number
  parentTicketDetails: Api.SiteVisitTicketIndividual['ticket_details']
  showAction?: boolean
}

export const ProjectTicketList = ({
  showAction = true,
  onTitleClick,
}: {
  showAction?: boolean
  onTitleClick?: (ticketId: number) => void
}) => {
  const {
    projectTicketList: projectTicketListFromApi,
    projectTicketcount: totalTicketCount,
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const {projectDetail: projectDetailsFromApi}: RT.ProjectReduxType =
    useSelector((state: any) => state.project)

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false)

  const {
    setProjectTicketId,
    setSubTicketModalVisible,
    isSubTicketModalVisible,
  } = useContext(TicketContext)

  const [newProjectTicketList, setProjectTicketList] =
    useState<Array<ProjectTicketListType>>()

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const remappedProjectTicketList = useCallback(() => {
    const newProjectTickets = projectTicketListFromApi?.map((ticket) => {
      return {
        id: ticket?.ticket_details?.id,
        title: ticket?.ticket_details?.title,
        status: ticket?.ticket_details?.status,
        dueDate: String(moment(ticket?.ticket_details?.due_date)),
        project: 'Project',
        progressPercentage: ticket?.ticket_details?.progress_percent,
        parentTicketDetails: ticket?.parent_ticket_details,
      }
    })

    setProjectTicketList(newProjectTickets)
  }, [projectTicketListFromApi])

  useEffect(() => {
    remappedProjectTicketList()
  }, [remappedProjectTicketList])

  const onRemoveProjectTicket = (ticketId: number) => {
    if (!!!ticketId) return

    dispatch(
      removeTicketAction(projectId, ticketId, () => {
        dispatch(getProjectTicketList(projectId))
      }),
    )
  }

  const onRemoveProjectSubTicket = (ticketId: number, subTicketId: number) => {
    if (!!!subTicketId) return

    dispatch(
      removeSubTicket(projectId, ticketId, subTicketId, () => {
        dispatch(getProjectTicketList(projectId))
      }),
    )
  }

  const onRemoveDaysWorkTicket = (ticketId: number) => {
    if (!!!ticketId) return

    dispatch(
      removeDaysWorkTickets(projectId, ticketId, () => {
        dispatch(getProjectTicketList(projectId))
      }),
    )
  }

  const getFetchData = (page?: number, limit?: number) => {
    dispatch(getProjectTicketList(projectId, {page, limit}))
  }

  const columns: TableColumn<ProjectTicketListType>[] = [
    {
      name: 'Name',
      selector: (row) => row.title,
      cell: (row) => {
        return (
          <div
            className={'text-blue-300 font-medium cursor-pointer'}
            onClick={() => {
              if (!!onTitleClick) {
                onTitleClick(row.id)
              } else {
                if (!!row.parentTicketDetails) {
                  setSubTicketModalVisible((prev) => !prev)
                } else {
                  setEditModalVisible((prev) => !prev)
                }
                setProjectTicketId(row.id)
              }
            }}
          >
            {row.title}
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        return getStatusChip(
          row.status as 'open' | 'in progress' | 'feedback' | 'completed',
        )
      },
    },
    {
      name: 'Progress',
      selector: (row) => row.progressPercentage,
      sortable: true,
      cell: (row) => {
        return (
          <div className="translate-y-[6px]">
            <ProgressBar progressPercentage={row.progressPercentage} />
          </div>
        )
      },
    },
    {
      name: 'Due Date',
      selector: (row) => row.dueDate,
      sortable: true,
      cell: (row) => {
        return <div>{moment(row.dueDate).format('ddd, MMM DD YYYY')}</div>
      },
    },
    {
      name: 'Parent Ticket',
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => {
        return (
          <div className="flex items-center">
            {!!row.parentTicketDetails ? (
              <span
                className="text-blue-300 font-bold cursor-pointer"
                onClick={() => {
                  if (!!onTitleClick) {
                    onTitleClick(row.parentTicketDetails?.id)
                  } else {
                    setEditModalVisible((prev) => !prev)
                    setProjectTicketId(row.parentTicketDetails?.id)
                  }
                }}
              >
                {row.parentTicketDetails?.title}
              </span>
            ) : (
              <span className="text-black flex items-center">---</span>
            )}
          </div>
        )
      },
    },
    {
      name: 'Actions',
      selector: (row) => row.id,
      sortable: true,
      right: true,
      omit: !showAction,
      cell: (row) => {
        return (
          <div className="flex justify-end items-center gap-x-10">
            <ToolTip text="Edit" top>
              <button
                className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
                onClick={() => {
                  if (!!row.parentTicketDetails) {
                    setSubTicketModalVisible((prev) => !prev)
                  } else {
                    setEditModalVisible((prev) => !prev)
                  }
                  setProjectTicketId(row.id)
                }}
              >
                <span className="text-left inline-flex cursor-pointer">
                  <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
                </span>
              </button>
            </ToolTip>

            <ConfirmationModal
              onConfirmClick={(closeModalHandler) => {
                if (
                  projectDetailsFromApi?.project_details?.project_type ===
                  'Days Work'
                ) {
                  if (!!row.parentTicketDetails) {
                    onRemoveProjectSubTicket(
                      row?.parentTicketDetails?.id,
                      row?.id,
                    )
                  } else {
                    onRemoveDaysWorkTicket(row?.id)
                  }
                } else {
                  if (!!row.parentTicketDetails) {
                    onRemoveProjectSubTicket(
                      row.parentTicketDetails?.id,
                      row.id,
                    )
                  } else {
                    onRemoveProjectTicket(row.id)
                  }
                }

                closeModalHandler()
              }}
              danger
              label={'Are you sure you want to delete this Ticket?'}
              displayElement={
                <ToolTip text="Delete" left>
                  <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </button>
                </ToolTip>
              }
              confirmLabel="Delete"
            />
          </div>
        )
      },
    },
  ]

  return (
    <div className="mt-16">
      {/* Project Ticket List */}
      <div className="">
        <DataTable
          columns={columns}
          data={newProjectTicketList ?? []}
          striped
          pagination
          totalRows={totalTicketCount ?? 0}
          fetchData={getFetchData}
        />
      </div>

      {isSubTicketModalVisible && (
        <CreateTicketModel
          key={'Update subTicket Modal'}
          modalId={'Ticket Model Two'}
          isModelVisible={isSubTicketModalVisible}
          setIsModelVisible={setSubTicketModalVisible}
          edit
          isSubTicketModal
          isSubTicketEdit
        />
      )}
      {editModalVisible && (
        <CreateTicketModel
          key={'Update ticket modal'}
          modalId={'Ticket Model Five'}
          isModelVisible={editModalVisible}
          setIsModelVisible={setEditModalVisible}
          edit
        />
      )}
    </div>
  )
}
