// import React from "react";
import { useState } from 'react'
import { HiChevronDown, HiSearch } from 'react-icons/hi'
import {
  AnimatedBlock,
  interpolate,
  TransitionBlock,
  useOutsideClick,
} from 'react-ui-animate'

import { useFocus } from '../../../utils'

export const Select = ({
  name,
  value,
  label,
  onChange,
  options,
  error,
  containerClass,
  className,
  defaultValue,
  size,
  placeholder,
  symbol,
  disabled,
}: any) => {
  const { elRef: selectRef, setFocus: setSelectFocus } = useFocus()
  return (
    <div
      className={`${
        !containerClass ? 'relative w-full form-group mb-18' : containerClass
      }`}
    >
      {label && <label className="font-medium text-sm mb-6">{label}</label>}
      <div className="relative" onClick={setSelectFocus}>
        <div className="flex">
          <select
            value={value}
            defaultValue={defaultValue}
            name={name}
            ref={selectRef}
            onChange={onChange}
            className={`border-1 appearance-none rounded-sm px-8 py-4  w-full bg-gray-100 ${
              error ? 'border-red-300' : ' border-gray-200'
            } ${className}`}
            disabled={disabled}
          >
            {placeholder && <option disabled={true}>{placeholder}</option>}
            {options?.map((item: any, i: any) => {
              return (
                <option
                  disabled={!item.value === null ? true : false}
                  key={i}
                  value={item.value}
                >
                  {symbol
                    ? item.symbol
                      ? item.symbol + ' ' + item.value
                      : item.value
                    : item.key}
                </option>
              )
            })}
          </select>
        </div>
        <div className="absolute right-10 top-10 isolate z-0">
          <HiChevronDown />
        </div>
      </div>
      {error && error?.length > 0 && (
        <p className="text-red-300 text-sm absolute -bottom-18 left-16">
          {error}
        </p>
      )}
    </div>
  )
}

export const SearchSelect = ({
  name,
  value,
  label,
  onChange,
  options,
  error,
  containerClass,
  className,
  optionContainerClassName,
  size,
  placeholder,
  customOption,
  customValue,
  customAction,
  setValue,
  noAutoComplete = true,
  symbol,
  ...rest
}: Comp.SearchSelectProps) => {
  const { elRef: selectRef, setFocus: setSelectFocus } = useFocus()
  const [optionShow, setOptionShow] = useState(false)
  useOutsideClick(selectRef, () => setOptionShow(false))
  return (
    <div
      className={`${
        !containerClass ? 'relative w-full form-group mb-18' : containerClass
      }`}
    >
      {label && <label className="font-medium text-sm mb-6">{label}</label>}
      <div className="relative">
        <div className="flex flex-col relative">
          <div
            className="flex"
            onClick={() => {
              setSelectFocus()
              setOptionShow((prev) => !prev)
            }}
          >
            <div className="p-6 border-1 border-r-0 rounded-l-sm rounded-r-none border-gray-200">
              <HiSearch size={18} />
            </div>

            <input
              type="text"
              autoComplete={noAutoComplete ? 'off' : 'on'}
              name={name}
              value={value}
              ref={selectRef}
              placeholder={placeholder}
              onChange={(e: any) => {
                setOptionShow(true)
                onChange(e)
              }}
              className={`border-1 appearance-none w-full rounded-r-sm active:rounded-r-sm focus:rounded-r-sm ${
                error ? 'border-red-300' : ' border-gray-200'
              } ${
                size === 'sm' ? 'px-16 py-6 text-sm' : 'px-16 py-10'
              } ${className} `}
              {...rest}
            />

            <div className="absolute right-10 top-10">
              <HiChevronDown />
            </div>
          </div>
          <TransitionBlock state={optionShow}>
            {(animation) => (
              <AnimatedBlock
                className={`bg-white absolute top-40 left-40 w-10/12 border-1 border-gray-200 rounded-sm h-drop overflow-y-auto ${optionContainerClassName}`}
                style={{
                  zIndex: 20,
                  opacity: interpolate(animation.value, [0, 1], [0, 1]),
                  transform: interpolate(
                    animation.value,
                    [0, 1],
                    ['scale(0)', 'scale(1)']
                  ),
                }}
              >
                <div className="flex flex-col">
                  {customOption && (
                    <div
                      className="cursor-pointer p-10 border-b-1 border-dotted border-gray-200 hover:bg-gray-200"
                      onClick={() => {
                        customAction()
                        setOptionShow((prev) => !prev)
                      }}
                    >
                      {customValue}
                    </div>
                  )}
                  {options !== null &&
                  options !== undefined &&
                  Array.isArray(options) &&
                  options?.length !== 0 ? (
                    <>
                      {options?.map((item: any, i: any) => {
                        return (
                          <option
                            disabled={!item.value === null ? true : false}
                            key={i}
                            className="cursor-pointer p-10 hover:bg-gray-200"
                            onClick={() => {
                              setValue && setValue(item.key)
                              setOptionShow((prev) => !prev)
                            }}
                          >
                            {symbol
                              ? item.name + ' - ' + item.key
                              : item.name
                              ? item.value === item.name
                                ? item.value
                                : item.value + ' - ' + item.name
                              : item.value}
                          </option>
                        )
                      })}
                    </>
                  ) : (
                    <div className="flex justify-center items-center text-gray-300 my-16">
                      No records available!
                    </div>
                  )}
                </div>
              </AnimatedBlock>
            )}
          </TransitionBlock>
        </div>
      </div>
      {error && (
        <p className="text-red-300 text-sm absolute -bottom-18 left-16">
          {error}
        </p>
      )}
    </div>
  )
}

export const DefaultSelect = ({
  options,
  className,
}: {
  options: Array<{ key: string; value: string }>
  className?: string
}) => {
  return (
    <select className={`border-1 border-gray-200 p-6 rounded-sm ${className}`}>
      {options.map(({ key, value }) => {
        return (
          <option key={key} value={value}>
            {key}
          </option>
        )
      })}
    </select>
  )
}
