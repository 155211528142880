import {
  ADD_SALES_ACCOUNT_CODES,
  DELETE_SALES_ACCOUNT_CODES,
  GET_SALES_ACCOUNT_CODES,
  UPDATE_SALES_ACCOUNT_CODES,
} from '../actions'

export type SalesAccountCodeType = {
  id: number
  created_at: string
  updated_at: string
  name: string
  icon: string
  company_id: number
  can_be_deleted: boolean
}

export type SalesAccountCodeList = Array<SalesAccountCodeType>

type SalesAccountState = {
  get_loading: boolean
  add_loading: boolean

  salesAccountCodes: SalesAccountCodeList

  deleteSalesAccountCodeLoader: boolean
  updateSalesAccountCodeLoading: boolean
}

const initialState: SalesAccountState = {
  get_loading: false,
  add_loading: false,

  salesAccountCodes: [],

  deleteSalesAccountCodeLoader: false,
  updateSalesAccountCodeLoading: false,
}

export const salesAccountReducer = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case ADD_SALES_ACCOUNT_CODES.LOADING:
      return { ...state, add_loading: true }

    case GET_SALES_ACCOUNT_CODES.LOADING:
      return { ...state, GET_loading: true }

    case ADD_SALES_ACCOUNT_CODES.SUCCESS:
      return { ...state, add_loading: false }

    case GET_SALES_ACCOUNT_CODES.SUCCESS:
      return { ...state, get_loading: false, salesAccountCodes: payload }

    case ADD_SALES_ACCOUNT_CODES.ERROR:
      return { ...state, add_loading: false }

    case GET_SALES_ACCOUNT_CODES.ERROR:
      return { ...state, get_loading: false }

    case DELETE_SALES_ACCOUNT_CODES.LOADING:
      return {
        ...state,
        deleteSalesAccountCodeLoader: false,
      }
    case DELETE_SALES_ACCOUNT_CODES.SUCCESS:
      return {
        ...state,
        deleteSalesAccountCodeLoader: false,
      }
    case DELETE_SALES_ACCOUNT_CODES.ERROR:
      return {
        ...state,
        deleteSalesAccountCodeLoader: false,
      }

    // UPDATE SALES ACCOUNT CODE
    case UPDATE_SALES_ACCOUNT_CODES.LOADING:
      return {
        ...state,
        updateSalesAccountCodeLoading: true,
      }
    case UPDATE_SALES_ACCOUNT_CODES.SUCCESS:
      return {
        ...state,
        updateSalesAccountCodeLoading: false,
      }
    case UPDATE_SALES_ACCOUNT_CODES.ERROR:
      return {
        ...state,
        updateSalesAccountCodeLoading: false,
      }
    default:
      return state
  }
}
