import React, {useEffect, useMemo} from 'react'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'

import {Button, Input, Modal, SelectField} from 'app/common'
import {createWorkerInvoiceManualLineItem} from 'redux-src'
import {useFormInput} from 'use-form-input'
import {SelectedItemType} from '../addLineItem'
import {DynamicSearchSelect} from 'app/components'

interface AddCustomItemProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  fetchInvoiceItem?: () => void
  sectionDetail: Api.WorkerInvoiceDetailsById['worker_invoice_item_list'][0]
  selectedLabour?: SelectedItemType
}

export const AddCustomItem = ({
  openModal,
  setOpenModal,
  fetchInvoiceItem,
  sectionDetail,
  selectedLabour
}: AddCustomItemProps) => {
  const {
    addManualLineItemLoading,
    createWorkerInvoiceManualLineItemLoading
  }: RT.ProjectInvoicingReduxType = useSelector((state: any) => state.invoices)

  const {companyUserDetails: userDetailsFromApi}: RT.UserReduxType =
    useSelector((state: any) => state.user)

  const {params} = useNavigation()
  const dispatch = useDispatch()

  const {currencySymbol} = useAuth()

  const {invoiceId} = params as any

  const [
    data,
    {onChange: onChangeHandler, errors, modified, onSubmit, setValue}
  ] = useFormInput(
    {
      title: undefined,
      timeMins: undefined,
      payRate: undefined,
      selectedOption: undefined,
      projectPayRate: undefined
    },
    (data) => {
      const body = {
        title: data?.title,
        timeMins: data?.timeMins,
        payRate:
          data?.selectedOption?.value === 'from_project'
            ? data?.projectPayRate?.value
            : data?.payRate
      }

      !!sectionDetail?.worker_inv_item_details?.id &&
        dispatch(
          createWorkerInvoiceManualLineItem(
            invoiceId,
            sectionDetail?.worker_inv_item_details?.id,
            body,
            () => {
              fetchInvoiceItem()
              setOpenModal(false)
            }
          )
        )
    },
    (data) => {
      const errors: any = {}

      if (data?.title?.length === 0) {
        errors.title = 'Title is required !!'
      }

      if (data?.timeMins === undefined) {
        errors.timeMins = 'Time mins is required !!'
      }

      return errors
    }
  )

  const totalCost = useMemo(() => {
    return (
      (data?.timeMins / 60) *
      (data?.selectedOption?.value === 'from_project'
        ? data?.projectPayRate?.value
        : data?.payRate)
    )
  }, [
    data?.payRate,
    data?.projectPayRate?.value,
    data?.selectedOption?.value,
    data?.timeMins
  ])

  useEffect(() => {
    if (!!!data?.selectedOption) {
      setValue('payRate', userDetailsFromApi?.user_details?.charge_out_rate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailsFromApi?.user_details?.charge_out_rate])

  useEffect(() => {
    if (!!selectedLabour) {
      setValue('title', selectedLabour?.value)
      setValue('timeMins', selectedLabour?.timeMins)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLabour])

  const onCloseModal = () => {
    setValue('timeMins', undefined)
    setValue('title', undefined)
    setValue('selectedOption', undefined)
    setValue('projectPayRate', undefined)
  }

  return (
    <Modal
      onClose={onCloseModal}
      toggleModal={setOpenModal}
      isActive={openModal}
      size="sm"
      title={'Add Manual Labour Entry Details'}
    >
      <div className="p-20 flex flex-col gap-6 relative">
        <div className="flex gap-20">
          <Input
            name="title"
            label="Title"
            value={data?.title}
            error={modified?.title && errors?.title}
            onChange={onChangeHandler}
            placeholder="Title"
            required
          />
          <Input
            name="timeMins"
            label="Time Mins"
            value={data?.timeMins}
            error={modified?.timeMins && errors?.timeMins}
            onChange={onChangeHandler}
            placeholder="Time Mins"
            required
          />
        </div>

        <div className="flex gap-20">
          <div className="flex flex-col gap-6 flex-1">
            <span className="text-sm font-bold text-black">
              Add PayRate <span className="text-red-300 pl-6">*</span>
            </span>
            <SelectField
              options={options}
              value={data?.selectedOption}
              onChangeValue={(item) => {
                setValue('selectedOption', item)
              }}
            />
          </div>

          <div className="flex flex-1">
            {data?.selectedOption?.value === 'charge_out_rate' && (
              <div className="flex gap-20 w-full">
                <Input
                  prepend={<span>{currencySymbol}</span>}
                  name="payRate"
                  label="Charge Out Rate"
                  value={data?.payRate}
                  error={modified?.payRate && errors?.payRate}
                  onChange={onChangeHandler}
                  placeholder="Pay Rate"
                  disabled
                  className="bg-blue-100"
                />
              </div>
            )}
            {data?.selectedOption?.value === 'from_project' && (
              <div className="flex flex-col gap-6 w-full pb-20">
                <span className="text-sm font-bold text-black">
                  Select Project <span className="text-red-300 pl-6">*</span>
                </span>
                <DynamicSearchSelect
                  height="31px"
                  containerClassname="w-[full]"
                  placeholder={'Select Project Pay Rate'}
                  actionUrl={`/projects`}
                  value={data?.projectPayRate}
                  remapOptions={(data: Api.ProjectList['rows']) => {
                    return data?.map((item) => {
                      return {
                        id: item?.project_details?.id,
                        label: item?.project_details?.title,
                        value: item?.project_details?.pay_rate
                      }
                    })
                  }}
                  formatOptionLabel={(item: {
                    id: number
                    label: string
                    value: number
                  }) => (
                    <div key={item.id} className="cursor-pointer px-10">
                      <div className="flex flex-col gap-6">
                        <div className="flex gap-4">
                          {item.id > 0 && item.id} <strong>{item.label}</strong>
                        </div>
                        <div className="flex gap-4 flex-wrap flex-col">
                          <div className="flex gap-4 flex-wrap">
                            Pay Rate:{' '}
                            <strong>
                              {currencySymbol}
                              {item.value}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  onChangeValue={(data) => {
                    setValue('projectPayRate', data)
                  }}
                  cacheOptions
                />
              </div>
            )}
          </div>
        </div>
        {!!data?.projectPayRate &&
          data?.selectedOption?.value === 'from_project' && (
            <div className="w-[50%] flex flex-1">
              <Input
                prepend={<span>{currencySymbol}</span>}
                name="payRate"
                label="Project Pay Rate"
                value={data?.projectPayRate?.value}
                placeholder="Pay Rate"
                disabled
                className="bg-blue-100"
              />
            </div>
          )}

        {!isNaN(totalCost) && !!data?.timeMins && (
          <div className="flex flex-col  gap-8 w-full">
            <span className="font-bold text-black">Total</span>
            <span className="text-lg text-red-300 font-bold flex gap-6">
              <span>{currencySymbol}</span>
              <span>{totalCost.toFixed(2)}</span>
            </span>
          </div>
        )}

        <div className="border-t-1 border-gray-200 "></div>

        <div className="flex justify-end px-16 py-10">
          <Button
            title="cancel"
            buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
            size="sm"
            type="button"
            onClick={() => {
              setOpenModal(false)
              onCloseModal()
            }}
            loading={addManualLineItemLoading}
          />
          <div className="ml-16">
            <Button
              title="Add"
              size="sm"
              type="submit"
              onClick={onSubmit}
              loading={createWorkerInvoiceManualLineItemLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const options = [
  {id: 1, label: 'Standard Charge Out Rate', value: 'charge_out_rate'},
  {id: 2, label: 'Add From Project', value: 'from_project'}
]
