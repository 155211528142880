import {
  CREATE_PRELIMINARY_ITEM_TEMPLATE,
  CREATE_PRELIMINARY_LINE_ITEM_TEMPLATE,
  CREATE_PRELIMINARY_TEMPLATES,
  DELETE_PRELIMINARY_ITEM_TEMPLATE,
  DELETE_PRELIMINARY_TEMPLATE,
  GENERATE_PROJECT_PRELIMINARY_FROM_TEMPLATE,
  GET_PRELIMINARY_TEMPLATE_BY_ID,
  GET_PRELIMINARY_TEMPLATE_LIST,
  UPDATE_PRELIMINARY_ITEM_MANDATORY_INFO,
  UPDATE_PRELIMINARY_ITEM_TEMPLATES,
  UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE,
  UPDATE_PRELIMINARY_TEMPLATE
} from './actionTypes.action'

import {toast} from 'app/common'

import {APIS, api} from 'config'
import {getParsedUrl} from 'helpers'

//MARK: Create Preliminary Templates
export const createPreliminaryTemplates = (
  body: any,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: CREATE_PRELIMINARY_TEMPLATES.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}`,
        'POST',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({type: CREATE_PRELIMINARY_TEMPLATES.SUCCESS, payload: data})

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({type: CREATE_PRELIMINARY_TEMPLATES.ERROR, payload: data})

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: CREATE_PRELIMINARY_TEMPLATES, payload: message})
    }
  }
}

//MARK: Get Preliminary Template List
export const getPreliminaryTemplateAction = (
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10
  },
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_PRELIMINARY_TEMPLATE_LIST.LOADING})

      res = await api<Api.Base<Api.PreliminaryTemplateList>>(
        getParsedUrl(APIS.preliminaryTemplates, params)
      )

      const {
        success,
        data: {
          message,
          data: {rows, total}
        }
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: GET_PRELIMINARY_TEMPLATE_LIST.SUCCESS,
            payload: {rows, total}
          })

          successCallback?.()
        } else {
          dispatch({
            type: GET_PRELIMINARY_TEMPLATE_LIST.ERROR,
            payload: message
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: GET_PRELIMINARY_TEMPLATE_LIST, payload: message})
    }
  }
}

//MARK: Generate Project Preliminary From Preliminary Templates
export const generateProjectPreliminaryFromTemplate = (
  projectId: number,
  premTemplateId: number,
  body: any,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GENERATE_PROJECT_PRELIMINARY_FROM_TEMPLATE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}/project-preliminary/${projectId}`,
        'POST',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: GENERATE_PROJECT_PRELIMINARY_FROM_TEMPLATE.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: GENERATE_PROJECT_PRELIMINARY_FROM_TEMPLATE.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({
        type: GENERATE_PROJECT_PRELIMINARY_FROM_TEMPLATE,
        payload: message
      })
    }
  }
}

//MARK: get Preliminary Template details
export const getPreliminaryTemplateDetailsAction = (
  preliminaryTemplateId: number,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: GET_PRELIMINARY_TEMPLATE_BY_ID.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${preliminaryTemplateId}`,
        'GET'
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: GET_PRELIMINARY_TEMPLATE_BY_ID.SUCCESS,
            payload: data
          })

          successCallback?.()
        } else {
          dispatch({
            type: GET_PRELIMINARY_TEMPLATE_BY_ID.ERROR,
            payload: message
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: GET_PRELIMINARY_TEMPLATE_BY_ID, payload: message})
    }
  }
}

//MARK: Delete Preliminary Template

export const deletePreliminaryTemplateAction = (
  premTemplateId: number,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: DELETE_PRELIMINARY_TEMPLATE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}`,
        'DELETE'
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: DELETE_PRELIMINARY_TEMPLATE.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: DELETE_PRELIMINARY_TEMPLATE.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: DELETE_PRELIMINARY_TEMPLATE, payload: message})
    }
  }
}

//MARK: Update Preliminary Template Action
export const updatePreliminaryTemplateAction = (
  premTemplateId: number,
  body: any,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_PRELIMINARY_TEMPLATE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}`,
        'PATCH',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({type: UPDATE_PRELIMINARY_TEMPLATE.SUCCESS, payload: data})

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({type: UPDATE_PRELIMINARY_TEMPLATE.ERROR, payload: data})

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: UPDATE_PRELIMINARY_TEMPLATE, payload: message})
    }
  }
}

//MARK: Preliminary Item Template
//MARK: Create Preliminary Item Template

export const createPreliminaryItemTemplate = (
  premTemplateId: number,
  body: any,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: CREATE_PRELIMINARY_ITEM_TEMPLATE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}/items`,
        'POST',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: CREATE_PRELIMINARY_ITEM_TEMPLATE.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: CREATE_PRELIMINARY_ITEM_TEMPLATE.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: CREATE_PRELIMINARY_ITEM_TEMPLATE, payload: message})
    }
  }
}

//MARK: Update Preliminary Item Template

export const updatePreliminaryItemTemplate = (
  premTemplateId: number,
  premItemId: number,
  body: any,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_PRELIMINARY_ITEM_TEMPLATES.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}/items/${premItemId}`,
        'PATCH',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: UPDATE_PRELIMINARY_ITEM_TEMPLATES.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: UPDATE_PRELIMINARY_ITEM_TEMPLATES.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: UPDATE_PRELIMINARY_ITEM_TEMPLATES, payload: message})
    }
  }
}

//MARK: Update Preliminary Item Template Mandatory Info

export const updatePreliminaryItemMandatoryInfo = (
  premTemplateId: number,
  premItemId: number,
  body: any,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_PRELIMINARY_ITEM_MANDATORY_INFO.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}/items/monetary/${premItemId}`,
        'PATCH',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: UPDATE_PRELIMINARY_ITEM_MANDATORY_INFO.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: UPDATE_PRELIMINARY_ITEM_MANDATORY_INFO.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: UPDATE_PRELIMINARY_ITEM_MANDATORY_INFO, payload: message})
    }
  }
}

//MARK: Delete Preliminary Item Template

export const deletePreliminaryItemTemplate = (
  premTemplateId: number,
  premItemId: number,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: DELETE_PRELIMINARY_ITEM_TEMPLATE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}/items/${premItemId}`,
        'DELETE'
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: DELETE_PRELIMINARY_ITEM_TEMPLATE.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: DELETE_PRELIMINARY_ITEM_TEMPLATE.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: DELETE_PRELIMINARY_ITEM_TEMPLATE, payload: message})
    }
  }
}

// MARK: Preliminary Line Item Template
// MARK: Create Preliminary Line Item Template

export const createPreliminaryLineItemTemplate = (
  premTemplateId: number,
  premItemId: number,
  body: any,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: CREATE_PRELIMINARY_LINE_ITEM_TEMPLATE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}/items/${premItemId}/line-items`,
        'POST',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: CREATE_PRELIMINARY_LINE_ITEM_TEMPLATE.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: CREATE_PRELIMINARY_LINE_ITEM_TEMPLATE.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: CREATE_PRELIMINARY_LINE_ITEM_TEMPLATE, payload: message})
    }
  }
}

//MARK: Update Preliminary Line Item Template

export const updatePreliminaryLineItemTemplate = (
  premTemplateId: number,
  premItemId: number,
  premLineItemId: number,
  body: any,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}/items/${premItemId}/line-items/${premLineItemId}`,
        'PATCH',
        body
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE, payload: message})
    }
  }
}

//MARK: Delete Preliminary Line Item Template

export const deletePreliminaryLineItemTemplate = (
  premTemplateId: number,
  premItemId: number,
  premLineItemId: number,
  successCallback?: () => void
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.preliminaryTemplates}/${premTemplateId}/items/${premItemId}/line-items/${premLineItemId}`,
        'DELETE'
      )

      const {
        success,
        data: {message, data}
      } = res.data

      if (res.data !== undefined) {
        if (success) {
          dispatch({
            type: UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE.SUCCESS,
            payload: data
          })

          successCallback?.()
          toast.success(message)
        } else {
          dispatch({
            type: UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE.ERROR,
            payload: data
          })

          toast.error(message)
        }
      }
    } catch (message) {
      dispatch({type: UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE, payload: message})
    }
  }
}
