// import React from "react";
// import { Link } from 'react-router-dom';
// import logoImage from '../../../assets/images/logo.png'
// import logoImageInline from '../../../assets/images/logo-2.png'

import logoImage from '../../../assets/images/smartplan-logo-transparent.png'
import logoImageInline from '../../../assets/images/smartplan-logo-transparent.png'

export const Logo = ({ type }: any) => {
  return (
    <div className="flex px-10 py-24 relative gap-18">
      <img
        src={type === 'inline' ? logoImageInline : logoImage}
        alt={process.env.REACT_APP_SITE_NAME}
        className=" h-[50px] w-[50px]"
      />
      <div className="mt-6 text-2xl font-bold  text-gray-900">Smart Clerk</div>
    </div>
  )
}
