import {APIS, api} from 'config'
import {
  CREATE_ITEM_CATEGORY,
  CREATE_ITEM_TYPE,
  DELETE_ITEM_CATEGORY,
  DELETE_ITEM_TYPE,
  GET_ITEM_CATEGORY_LIST,
  GET_ITEM_TYPE_BY_ID,
  GET_ITEM_TYPE_LIST,
  UPDATE_ITEM_TYPE
} from '.'

import {errorHandler} from '../../utils'
import {getParsedUrl} from 'helpers'

export function getItemTypeListAction(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10
  }
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ITEM_TYPE_LIST.LOADING})

      res = await api<Api.Base<Api.ItemTypeList>>(
        getParsedUrl(APIS.itemType, params)
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: GET_ITEM_TYPE_LIST.SUCCESS, payload: data})
        return 1
      } else {
        dispatch({type: GET_ITEM_TYPE_LIST.ERROR, payload: message})
        return 0
      }
    } catch (message) {
      dispatch({type: GET_ITEM_TYPE_LIST.ERROR})
      return 1
    }
  }
}

export function getItemTypeByIdAction(itemTypeId: string) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ITEM_TYPE_BY_ID.LOADING})

      res = await api<Api.Base<Api.ItemTypeList>>(
        `${APIS.itemType}/${itemTypeId}`,
        'GET'
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: GET_ITEM_TYPE_BY_ID.SUCCESS, payload: data})
        return 1
      } else {
        dispatch({type: GET_ITEM_TYPE_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (message) {
      dispatch({type: GET_ITEM_TYPE_BY_ID.ERROR})
      return 1
    }
  }
}

export function createItemTypeAction(
  toast: any,
  createItemData: any,
  successCallback?: (data: any) => void
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: CREATE_ITEM_TYPE.LOADING})
      res = await api<Api.Base<any>>(`${APIS.itemType}`, 'POST', createItemData)
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: CREATE_ITEM_TYPE.SUCCESS, payload: data})
        successCallback && successCallback(data)
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_ITEM_TYPE.ERROR, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch (message) {
      dispatch({type: CREATE_ITEM_TYPE.ERROR})
      toast.error('Error Creating new item type!')
      return 1
    }
  }
}

export function deleteItemTypeAction(
  itemTypeId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_ITEM_TYPE.LOADING})
      res = await api<any>(`${APIS.itemType}/${itemTypeId}`, 'DELETE')
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: DELETE_ITEM_TYPE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_ITEM_TYPE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (message) {
      dispatch({type: DELETE_ITEM_TYPE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function createItemCategoryAction(
  createItemCategoryData: any,
  successCallback: (data: any) => void,
  toast: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_ITEM_CATEGORY.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.itemCategory}`,
        'POST',
        createItemCategoryData
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: CREATE_ITEM_CATEGORY.SUCCESS, payload: data})
        successCallback && successCallback(data)
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_ITEM_CATEGORY, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch (message) {
      dispatch({type: CREATE_ITEM_CATEGORY.ERROR})
      toast.error('Error Creating new item category !')
      return 1
    }
  }
}

export function updateItemTypeAction(
  itemTypeId: any,
  body: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_ITEM_TYPE.LOADING})
      res = await api<any>(`${APIS.itemType}/${itemTypeId}`, 'PATCH', body)
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_ITEM_TYPE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_ITEM_TYPE.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (message) {
      dispatch({type: UPDATE_ITEM_TYPE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function getItemCategoryListAction(
  itemTypeId: number,
  params: {page: number; limit: number; filterValue?: string} = {
    page: 1,
    limit: 10
  }
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ITEM_CATEGORY_LIST.LOADING})

      res = await api<Api.Base<Api.ItemCategoryList>>(
        getParsedUrl(`${APIS.itemCategory}/${itemTypeId}`, params),
        'GET'
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: GET_ITEM_CATEGORY_LIST.SUCCESS, payload: data})
        return 1
      } else {
        dispatch({type: GET_ITEM_CATEGORY_LIST.ERROR, payload: message})
        return 0
      }
    } catch (message) {
      dispatch({type: GET_ITEM_CATEGORY_LIST.ERROR})
      return 1
    }
  }
}

export function deleteItemCategoryAction(
  itemCategoryId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_ITEM_CATEGORY.LOADING})
      res = await api<any>(`${APIS.itemCategory}/${itemCategoryId}`, 'DELETE')
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: DELETE_ITEM_CATEGORY.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_ITEM_CATEGORY.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (message) {
      dispatch({type: DELETE_ITEM_CATEGORY.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateItemCategoryAction(
  itemCategoryId: any,
  body: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_ITEM_TYPE.LOADING})
      res = await api<any>(
        `${APIS.itemCategory}/${itemCategoryId}`,
        'PATCH',
        body
      )
      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_ITEM_TYPE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_ITEM_TYPE.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (message) {
      dispatch({type: UPDATE_ITEM_TYPE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}
