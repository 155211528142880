import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios'
import {getCookie} from '.'
// import { getCookie } from "./cookie.helper";

interface ApiGeneratorParams {
  baseURL: string
}

interface ApiConfigParams {
  file?: boolean
  headers?: any
  fileUploadProgress?: (progress: number) => void
  fileDownloadProgress?: (progress: number) => void
}

export type ApiGeneratorResponse = <ApiResponseType>(
  url: string,
  method?: Method,
  body?: any,
  apiConfig?: ApiConfigParams,
) => Promise<AxiosResponse<ApiResponseType>>

// MARK: - getParsedUrl
export const getParsedUrl = (
  url: string,
  params?: {[key: string]: number | string | boolean},
) => {
  if (!params) {
    return url
  }

  const paramEntries = Object.entries(params).filter(
    ([_, value]) => value !== undefined && value !== null,
  )

  let urlString = paramEntries?.length > 0 ? '?' : ''

  paramEntries.forEach(([key, value], index, array) => {
    if (value !== null && value !== undefined && value !== '') {
      urlString += `${key}=${value}${index !== array.length - 1 ? '&' : ''}`
    }
  })

  if (urlString.endsWith('&')) {
    urlString = urlString.slice(0, -1)
  }
  return url + urlString
}

export function apiGenerator({
  baseURL,
}: ApiGeneratorParams): ApiGeneratorResponse {
  return async function api<ApiResponseType>(
    url: string,
    method?: Method,
    body?: any,
    apiConfig?: ApiConfigParams,
  ) {
    const token = getCookie('token')
    const config: AxiosRequestConfig = {
      method: 'GET',
      baseURL,
      url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    if (method) config.method = method
    if (body) config.data = body
    if (apiConfig?.headers) config.headers = apiConfig.headers

    // For file
    if (apiConfig?.file) {
      config.headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      }
      // FOR UPLOAD
      config.onUploadProgress = function (progressEvent: any) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        apiConfig?.fileUploadProgress && apiConfig.fileUploadProgress(progress)
      }
      // FOR DOWNLOAD
      config.onDownloadProgress = function (progressEvent: any) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        apiConfig?.fileDownloadProgress &&
          apiConfig.fileDownloadProgress(progress)
      }
    }

    let response: AxiosResponse<ApiResponseType>
    try {
      response = await axios(config)
      return response
    } catch (e: any) {
      // console.log('>>>>>', e)
      return e?.response?.data?.message || e?.response?.data || e?.message
    }
  }
}
