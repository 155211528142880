import {errorHandler} from 'utils'
import {api, APIS} from 'config'
import {
  BASIC_PROJECT_PROGRESS_DETAILS,
  FINANCE_SUMMARY,
  GENERATE_PROJECT_REPORTS,
  COST_REPORT,
  PROJECT_DATE_CALCULATOR,
  PROJECT_STATUS,
  SITE_VISIT_FINANCIAL_BREAKDOWN,
  USERS_FINANCIAL_DETAILS,
  CUSTOM_PROJECT_DATE_CALCULATOR,
} from './actionTypes.action'
import {toast} from 'app/common'
import {getParsedUrl} from 'helpers'

export const getProjectFinancialSummary = (
  projectId: number,
  withVariations?: boolean,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: FINANCE_SUMMARY.LOADING})
      res = await api<Api.Base<Api.ProjectFinancialSummary>>(
        getParsedUrl(`${APIS.projects}/${projectId}/financial-summary`, {
          withVariations,
        }),
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({type: FINANCE_SUMMARY.SUCCESS, payload: data})
          callback?.()
        } else {
          dispatch({type: FINANCE_SUMMARY.ERROR})
          errorHandler(res.data, toast)
        }
      }
    } catch (message) {
      dispatch({type: FINANCE_SUMMARY.ERROR})
    }
  }
}

export const getUsersFinancialDetailsOfProject = (
  projectId: number,
  params: {page?: number; limit?: number; withVariations?: boolean} = {
    page: 1,
    limit: 10,
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({type: USERS_FINANCIAL_DETAILS.LOADING})

      const api_url = getParsedUrl(
        `${APIS.projects}/${projectId}/financial-summary/users`,
        {...params, withVariations: true},
      )
      const res = await api<Api.Base<Api.UsersFinancialDetailsList>>(api_url)

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({type: USERS_FINANCIAL_DETAILS.SUCCESS, payload: data})
          callback?.()
        } else {
          dispatch({type: USERS_FINANCIAL_DETAILS.ERROR})
          errorHandler(res.data, toast)
        }
      }
    } catch (message) {
      dispatch({type: USERS_FINANCIAL_DETAILS.ERROR})
    }
  }
}

export const getSiteVisitFinancialBreakdown = (
  projectId: number,
  params: {page?: number; limit?: number; withVariations?: boolean} = {
    page: 1,
    limit: 10,
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: SITE_VISIT_FINANCIAL_BREAKDOWN.LOADING})
      const api_url = getParsedUrl(
        `${APIS.projects}/${projectId}/financial-summary/site-visits`,
        {
          ...params,
          withVariations: true,
        },
      )
      res = await api<Api.Base<Api.SiteVisitFinancialBreakdownList>>(api_url)

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({
            type: SITE_VISIT_FINANCIAL_BREAKDOWN.SUCCESS,
            payload: data,
          })
          callback?.()
        } else {
          dispatch({type: SITE_VISIT_FINANCIAL_BREAKDOWN.ERROR})
          errorHandler(res.data, toast)
        }
      }
    } catch (message) {
      dispatch({type: SITE_VISIT_FINANCIAL_BREAKDOWN.ERROR})
    }
  }
}

//* project progress
export const getBasicPojectProgressDetail = (
  projectId: number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let response

    try {
      dispatch({type: BASIC_PROJECT_PROGRESS_DETAILS.LOADING})
      response = await api<Api.Base<Api.BasicProjectProgressDetails>>(
        `${APIS.projects}/${projectId}/progress/basic-details`,
      )

      if (response.data !== undefined) {
        // fetching successful
        const {
          success,
          data: {data},
        } = response.data
        if (success) {
          dispatch({
            type: BASIC_PROJECT_PROGRESS_DETAILS.SUCCESS,
            payload: data,
          })

          callback?.()
        } else {
          errorHandler(response.data, toast)
          throw new Error(
            `PROJECT_PROGRESS_STATUS Fetch Error ${response.data.message} `,
          )
        }
      }
    } catch (error) {
      dispatch({type: BASIC_PROJECT_PROGRESS_DETAILS.ERROR})
    }
  }
}

export const getProjectDateCalculator = (
  projectId: number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      res = await api<Api.Base<Api.ProjectDateCalculatorList>>(
        `${APIS.projects}/${projectId}/progress/days-calculator`,
      )

      if (res.data !== undefined) {
        // fetching successful
        const {
          data: {data},
          success,
        } = res.data
        if (success) {
          dispatch({type: PROJECT_DATE_CALCULATOR.SUCCESS, payload: data})
          callback?.()
        } else {
          errorHandler(res.data, toast)
          throw new Error(`PROJECT_DATE_CALCULATOR Fetch Error ${res.data}`)
        }
      } else {
        errorHandler(res.data, toast)
        throw new Error(`PROJECT_DATE_CALCULATOR Fetch Error ${res.data}`)
      }
    } catch (error) {
      dispatch({type: PROJECT_DATE_CALCULATOR.ERROR})
    }
  }
}

export const getCustomProjectDateCalculator = (
  projectId: number,
  body: {
    totalMens: number
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({
        type: CUSTOM_PROJECT_DATE_CALCULATOR.LOADING,
      })
      res = await api<Api.Base<Api.CustomProjectDateCalculator>>(
        `${APIS.projects}/${projectId}/progress/days-calculator`,
        'post',
        body,
      )

      if (res.data !== undefined) {
        // fetching successful
        const {
          data: {data},
          success,
        } = res.data
        if (success) {
          dispatch({
            type: CUSTOM_PROJECT_DATE_CALCULATOR.SUCCESS,
            payload: data,
          })
          callback?.()
        } else {
          errorHandler(res.data, toast)
          throw new Error(
            `CUSTOM_PROJECT_DATE_CALCULATOR Fetch Error ${res.data}`,
          )
        }
      } else {
        errorHandler(res.data, toast)
        throw new Error(
          `CUSTOM_PROJECT_DATE_CALCULATOR Fetch Error ${res.data}`,
        )
      }
    } catch (error) {
      dispatch({type: CUSTOM_PROJECT_DATE_CALCULATOR.ERROR})
    }
  }
}

export const getProjectStatus = (projectId: number, callback?: () => void) => {
  return async (dispatch: any) => {
    let response

    try {
      dispatch({type: PROJECT_STATUS.LOADING})
      response = await api<Api.Base<Api.ProjectStatus>>(
        `${APIS.projects}/${projectId}/progress/status`,
      )

      if (response.data !== undefined) {
        // fetching successful
        const {
          success,
          data: {data},
        } = response.data
        if (success) {
          dispatch({
            type: PROJECT_STATUS.SUCCESS,
            payload: data,
          })

          callback?.()
        } else {
          errorHandler(response.data, toast)
          throw new Error(
            `PROJECT_STATUS Fetch Error ${response.data.message} `,
          )
        }
      }
    } catch (error) {
      dispatch({type: PROJECT_STATUS.ERROR})
    }
  }
}

export const generateProjectsReports = (
  projectId: number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let response

    try {
      dispatch({type: GENERATE_PROJECT_REPORTS.LOADING})
      response = await api<Api.Base<null>>(
        `${APIS.projects}/${projectId}/reports`,
      )

      if (response.data !== undefined) {
        // fetching successful
        const {success} = response.data
        if (success) {
          dispatch({
            type: GENERATE_PROJECT_REPORTS.SUCCESS,
          })

          callback?.()
        } else {
          errorHandler(response.data, toast)
          throw new Error(
            `GENERATE_PROJECT_REPORTS Fetch Error ${response.data.message} `,
          )
        }
      }
    } catch (error) {
      dispatch({type: GENERATE_PROJECT_REPORTS.ERROR})
    }
  }
}

// Cost Report Data
export const getCostReport = (
  projectId: number,
  params: {
    type: 'item' | 'labour' | 'material'
    siteVisitId?: number
    page?: number
    limit?: number
  } = {
    type: 'item',
    page: 1,
    limit: 10,
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res

    try {
      dispatch({type: COST_REPORT.LOADING})

      const api_url = getParsedUrl(
        `${APIS.projects}/${projectId}/reports/cost-report`,
        {...params},
      )

      res = await api<Api.Base<Api.PaginatedData<Api.CostReportRows>>>(api_url)

      if (res.data !== undefined) {
        // Cost Report fetched
        const {
          success,
          data: {data},
        } = res.data
        if (success) {
          dispatch({
            type: COST_REPORT.SUCCESS,
            payload: data,
          })
          callback?.()
        } else {
          errorHandler(res?.data?.message, toast)
          throw new Error(`COST_REPORT Fetch Error ${res.data.message} `)
        }
      }
    } catch (error) {
      dispatch({type: COST_REPORT.ERROR})
    }
  }
}
