import {useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'

import {DataTable} from 'app/components'

export const SiteTable = ({
  sites,
  siteAddCallback,
  siteTotal,
  status,
}: {
  sites: any
  siteTotal: number
  siteAddCallback: any
  status: 'active' | 'archived'
}) => {
  const {
    navigation: {navigate},
  } = useNavigation()

  const {getArchivedSiteLoading, get_loading}: RT.SiteReduxType = useSelector(
    (state: any) => state.site,
  )
  const columns: TableColumn<any>[] = [
    {
      name: 'Status',
      selector: (row) => row.name,
      sortable: true,
      cell: () => (
        <div
          key={status}
          className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase ${
            status === 'active' ? 'bg-blue-300' : 'bg-gray-400'
          }`}
          // style={{
          //   backgroundColor: chipColor[value as keyof typeof chipColor]
          // }}
        >
          {status}
        </div>
      ),
    },
    {
      name: 'Address',
      selector: (row) => row.address,
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center">
          <div
            className="text-blue-300 cursor-pointer hover:underline"
            onClick={() => navigate(`/sites/view/${row.id}`)}
          >
            {row.address}
          </div>
        </div>
      ),
    },

    {
      name: 'Default Site Contact',
      selector: (row) => row.defaultSiteContact,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/Sites/Site-view/projects/${row.id}`}
            className="text-black"
          >
            {row.defaultSiteContact}
          </div>
        </div>
      ),
    },
    {
      name: 'Phone',
      selector: (row) => row.phone,

      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/Sites/Site-view/projects/${row.id}`}
            className="text-black"
          >
            {row.phone}
          </div>
        </div>
      ),
    },
  ]

  return (
    <>
      <DataTable
        columns={columns}
        data={sites}
        totalRows={siteTotal}
        fetchData={siteAddCallback}
        loading={status === 'archived' ? getArchivedSiteLoading : get_loading}
        persistTableHead
        pagination
        striped
        dense
      />
    </>
  )
}
