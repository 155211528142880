import { useEffect } from "react";
import { Auth, useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";

import { Loading } from "app/common";
import { CustomSidePanel } from "app/components";
import { SiteHeader } from "../../components";
import { getSiteById } from "redux-src";
import { siteSidePanelData } from "constants-src";

export const SiteViewPage = () => {
  const { params } = useNavigation();
  const { siteId } = params as any;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSiteById(siteId, () => {
        // setNewCustomerModal(true);
        // navigate(`/customers/view/${id}`)
      })
    );
  }, [dispatch, siteId]);

  const { getSiteByIdLoading: loading }: RT.SiteReduxType = useSelector(
    (state: any) => state.site
  );

  return loading ? (
    <Loading />
  ) : (
    <div className="w-9/12 mx-auto border-x-1 border-b-1 border-gray-200">
      <SiteHeader />

      <div className="flex border-t-1 border-t-gray-200">
        <div className="bg-white border-r-1 border-r-gray-200 py-10 w-[200px] min-h-[65vh]">
          <CustomSidePanel
            append={
              <div className="text-md font-bold capitalize px-18">Menu</div>
            }
            routeIdAlias="siteId"
            routeName="SiteView"
            data={siteSidePanelData}
          />
        </div>

        <div className="flex-1 bg-white pt-10 px-16">
          <Auth.Screens path="/sites/view/:siteId" />
        </div>
      </div>
    </div>
  );
};
