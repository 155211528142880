import {
  ADD_PROJECT_PLAN,
  UPDATE_PROJECT_PLAN,
  DELETE_PROJECT_PLAN,
  GET_PROJECT_PLAN_LIST
} from '../actions'

const initalState: RT.ProjectPlanReduxType = {
  loading: false,
  projectPlanList: undefined,
  projectPlanTotal: 0,

  projectPlanTicketListBuffer: undefined
}

export function projectPlanReducer(state = initalState, action: any) {
  const {type, payload} = action

  switch (type) {
    case ADD_PROJECT_PLAN.LOADING:
      return {...state, loading: true}
    case ADD_PROJECT_PLAN.SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ADD_PROJECT_PLAN.ERROR:
      return {...state, loading: false}

    case GET_PROJECT_PLAN_LIST.LOADING:
      return {...state, loading: true}
    case GET_PROJECT_PLAN_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        projectPlanList: payload.rows,
        projectPlanTotal: payload.total
      }
    case GET_PROJECT_PLAN_LIST.ERROR:
      return {...state, loading: false}

    case UPDATE_PROJECT_PLAN.LOADING:
      return {...state, loading: true}
    case UPDATE_PROJECT_PLAN.SUCCESS:
      return {
        ...state,
        loading: false
      }
    case UPDATE_PROJECT_PLAN.ERROR:
      return {...state, loading: false}

    case DELETE_PROJECT_PLAN.LOADING:
      return {...state, loading: true}
    case DELETE_PROJECT_PLAN.SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DELETE_PROJECT_PLAN.ERROR:
      return {...state, loading: false}

    case 'UPDATE_PROJECT_PLAN_TICKET_LIST_BUFFER':
      return {...state, projectPlanTicketListBuffer: payload}

    default:
      return state
  }
}
