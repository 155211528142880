import { GET_INVOICE_SETTINGS, UPDATE_INVOICE_SETTINGS } from '../actions'

const initialState = {
  loading: false,
  get_loading: false,

  defaultInvoiceSettings: {},

  error: '',
  get_error: '',
}

export function invoiceSettingsReducer(state = initialState, action: any) {
  const { type, payload } = action

  //

  switch (type) {
    case UPDATE_INVOICE_SETTINGS.LOADING:
      return { ...state, loading: true, error: false }
    case UPDATE_INVOICE_SETTINGS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case UPDATE_INVOICE_SETTINGS.ERROR:
      return { ...state, loading: false, error: payload }

    case GET_INVOICE_SETTINGS.LOADING:
      return {
        ...state,
        get_loading: true,
        get_error: false,
      }
    case GET_INVOICE_SETTINGS.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        defaultInvoiceSettings: payload,
      }
    case GET_INVOICE_SETTINGS.ERROR:
      return { ...state, get_loading: false, get_error: payload }

    default:
      return state
  }
}
