import {useCallback, useEffect} from 'react'
import colors from 'tailwindcss/colors'
import {useFormInput} from 'use-form-input'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {RiDeleteBin2Fill} from 'react-icons/ri'
import {useDispatch, useSelector} from 'react-redux'

import {Button, Input, Modal} from 'app/common'

import {
  createItemCategoryAction,
  createItemTypeAction,
  getItemCategoryListAction,
  getItemTypeListAction,
  updateItemCategoryAction,
  updateItemTypeAction
} from 'redux-src'

export const ItemTypeModal = ({
  itemTypeModal,
  close,
  mode,
  datas
}: {
  itemTypeModal: boolean
  close: () => void
  mode: 'add' | 'edit'
  datas?: Api.ItemTypeIndividual
}) => {
  const dispatch = useDispatch()
  const {toast} = useAuth()

  const {createItemTypeLoading, updateItemTypeLoading} = useSelector(
    (state: any) => state.itemTypes
  )

  const [
    itemTypeData,
    {setValue, onSubmit, onChange, clear, errors, modified}
  ] = useFormInput(
    {
      name: '',
      wastage_per: '',
      attributes: []
    },
    () => {
      mode === 'add'
        ? dispatch(
            createItemTypeAction(
              toast,
              {
                name: itemTypeData?.name,
                wastagePer: itemTypeData?.wastage_per,
                attributes: itemTypeData?.attributes ?? []
              },
              () => {
                dispatch(getItemTypeListAction())
                close()
                clear()
              }
            )
          )
        : datas?.id &&
          dispatch(
            updateItemTypeAction(
              datas?.id,
              {
                name: itemTypeData?.name,
                wastagePer: itemTypeData?.wastage_per,
                attributes: itemTypeData?.attributes ?? []
              },
              toast,

              () => {
                dispatch(getItemTypeListAction())
                close()
                clear()
              }
            )
          )
    },
    (data) => {
      type error = {[key: string]: string}
      const errors: error = {}

      if (Number(data.wastage_per) < 0 || Number(data.wastage_per) > 100) {
        errors.wastage_per = 'Wastage percentage must be between 1-100'
      } else if (data.wastage_per.length === 0) {
        errors.wastage_per = "Wastage percentage can't be empty"
      }

      if (data.name.length === 0) {
        errors.name = "Name can't e empty"
      }

      return errors
    }
  )

  const addTags = (e: any) => {
    if (e.key === 'Enter') {
      if (itemTypeData.attributes.includes(e.target.value.toString().trim())) {
        e.target.value = ''
        toast.error('Please provide unique attributes to the item type!')
        return
      } else
        setValue('attributes', [
          ...itemTypeData.attributes,
          e.target.value.toString().trim()
        ])
      e.target.value = ''
    }
  }

  const removeAttribute = (index: number) => {
    const remainingAttributeList =
      itemTypeData?.attributes?.filter((_, i) => i !== index) ?? []

    setValue('attributes', remainingAttributeList)
  }

  const prePopulatedValue = useCallback(() => {
    setValue('name', datas?.name ?? '')
    setValue('wastage_per', datas?.wastage_per ?? '')
    setValue('attributes', datas?.attributes ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas])

  useEffect(() => {
    prePopulatedValue()

    return () => {
      clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prePopulatedValue])

  return (
    <Modal
      isActive={itemTypeModal}
      toggleModal={close}
      title={mode === 'edit' ? 'Edit Item Type' : 'Add New Item Type'}
      className="flex justify-center items-center lg:w-2/5"
    >
      <div className="px-32 pt-10">
        <Input
          type="text"
          name="name"
          size="sm"
          label="Name"
          placeholder="Enter Name"
          value={itemTypeData?.name}
          error={modified.name && errors.name}
          onChange={onChange}
          required
        />

        <Input
          type="number"
          name="wastage_per"
          size="sm"
          label="Wastage Percentage"
          placeholder="Enter Wastage Percentage"
          value={itemTypeData?.wastage_per}
          error={modified.wastage_per && errors.wastage_per}
          onChange={onChange}
          required
        />
      </div>

      <div className="px-32 pt-8 flex flex-wrap space-x-4 jusitfy-start gap-y-8 flex-col">
        <label className="flex text-sm font-bold">Attributes</label>
        {itemTypeData?.attributes.length > 0 && (
          <div className="flex flex-wrap space-x-8 justify-start">
            {itemTypeData?.attributes?.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className="flex  rounded-sm  space-x-6 items-center px-6 py-4 bg-blue-300 text-sm text-white"
                >
                  <div key="index">{item}</div>
                  <RiDeleteBin2Fill
                    size={12}
                    fill="white"
                    color={colors.gray[500]}
                    cursor="pointer"
                    onClick={() => removeAttribute(index)}
                  />
                </div>
              )
            })}
          </div>
        )}
        <Input
          type="text"
          size="sm"
          placeholder="Press enter to add attribute..."
          onKeyUp={addTags}
          style={{borderBottom: '1px solid gray200'}}
          // error={modified.attributes && errors.attributes}
        />
      </div>
      <div className="mt-8 mb-8 border-t-1 border-gray-200"></div>

      <div className="flex gap-6 justify-end items-center pb-20 pr-20">
        <Button
          className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          loading={
            mode === 'edit' ? updateItemTypeLoading : createItemTypeLoading
          }
          form="item-type"
          type="submit"
          className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
          onClick={onSubmit}
        >
          {mode !== 'edit' ? 'Save' : 'Edit'}
        </Button>
      </div>
    </Modal>
  )
}

export const ItemDetailsModal = ({
  showCategoryModal,
  close,
  categoryDetails,
  mode,
  title
}: any) => {
  const dispatch = useDispatch()
  const {toast} = useAuth()
  const {params} = useNavigation()
  const {itemId} = params as any

  const {updateItemCategoryLoading, createItemCategoryLoading} = useSelector(
    (state: any) => state.itemTypes
  )

  const [
    itemCategoryData,
    {onChange, onSubmit, clear, setValue, modified, errors}
  ] = useFormInput(
    {
      name: ''
    },
    (data) => {
      mode === 'add'
        ? dispatch(
            createItemCategoryAction(
              {
                name: data.name,
                itemTypeId: itemId
              },
              () => {
                close()
                dispatch(getItemCategoryListAction(itemId))
                clear()
              },
              toast
            )
          )
        : dispatch(
            updateItemCategoryAction(
              categoryDetails.id,
              {name: data.name},
              toast,
              () => {
                dispatch(getItemCategoryListAction(itemId))
                close()
                clear()
              }
            )
          )
    },
    (data) => {
      type error = {[key: string]: string}
      const errors: error = {}

      if (data.name.length === 0) {
        errors.name = "Category Name can't empty"
      }

      return errors
    }
  )

  const prePopulatedCategoryValue = useCallback(() => {
    if (!!categoryDetails) {
      Object.keys(categoryDetails).map((itemCategory) => {
        return setValue(itemCategory as any, categoryDetails[itemCategory])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDetails])

  useEffect(() => {
    prePopulatedCategoryValue()
  }, [prePopulatedCategoryValue, dispatch])

  return (
    <div>
      <Modal
        isActive={showCategoryModal}
        toggleModal={close}
        title={
          mode === 'add' ? `Add ${title} Category` : `Edit ${title} Category`
        }
        className="lg:w-1/2 flex justify-center items-center"
      >
        <form id="item-type" onSubmit={onSubmit}>
          <div className="px-32 pt-10">
            <Input
              type="text"
              name="name"
              size="sm"
              label="Category Name"
              placeholder="Enter category name"
              onChange={onChange}
              value={itemCategoryData.name}
            />

            {modified.name && errors.name && (
              <span style={{color: 'red'}}>{errors.name}</span>
            )}
          </div>
          <div className="pt-16 border-t-1 border-gray-200"></div>

          <div className="flex gap-6 justify-end items-center pb-20 pr-20">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm text-xs border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              loading={
                mode === 'edit'
                  ? updateItemCategoryLoading
                  : createItemCategoryLoading
              }
              form="item-type"
              onClick={onSubmit}
              className="bg-blue-200 px-20 py-4 rounded-sm text-xs text-gray-200 border-1 border-gray-200 cursor-pointer"
            >
              {mode !== 'edit' ? 'Save' : 'Edit'}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}
