import {combineReducers} from 'redux'
// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

import {loginReducer} from './login.reducer'
import {registerReducer} from './register.reducer'
import {userReducer} from './user.reducer'
import {customerReducer} from './customer.reducer'
import {selectOptionReducer} from './selectOptions.reducer'
import {siteReducer} from './site.reducer'
import {jobsReducer} from './jobs.reducer'
import {checklistReducer} from './checkList.reducer'
import {groupReducer} from './groups.reducer'
import {companyReducer} from './company.reducer'
import {quoteSettingsReducer} from './quoteSettings.reducer'
import {priceBookReducer} from './priceBooks.reducer'
import {salesAccountReducer} from './salesAccount.reducer'
import {quotesReducer} from './quotes.reducer'
import {quoteItemsReducer} from './quoteItems.reducer'
import {siteVisitReducer} from './siteVisits.reducer'
import {filesReducer} from './files.reducer'
import {invoicesReducer} from './invoice.reducer'
import {invoiceItemsReducer} from './invoiceItems.reducer'
import {invoiceSettingsReducer} from './invoiceSettings.reducer'
import {noteHistoryReducer} from './noteHistory.reducer'
import {financeSummaryReducer} from './financeSummary.reducer'
import {statusBoardReducer} from './statusBoard.reducer'
import {planviewerReducer} from './planviewer.reducer'
import {floorPlanWebViewReducer} from './floorPlanWebView.reducer'
import {supplierSettingReducer} from './suppliers.reducer'
import {labourElementReducer} from './labourElement.reducer'
import {itemTypesReducer} from './itemTypes.reducer'

import {itemManagementReducer} from './itemManagement.reducer'
import {projectReducer} from './projects.reducer'
import {preliminaryReducer} from './preliminary.reducer'
import {formsReducer} from './forms.reducer'
import {formBuilderReducer} from './formBuilder.reducer'

import {projectPlanReducer} from './projectPlan.reducer'
import {ticketsReducer} from './tickets.reducer'
import {materialElementReducer} from './materialElement.reducer'
import {preliminaryTemplateReducer} from './preliminaryTemplate.reducer'
import {notificationReducer} from './notification.reducer'

import {noticeReducer} from './noticeBoard.reducer'

import {calendarReducer} from './calendar.reducer'
// const rootPersistConfig = {
//   key: 'root',
//   storage: storage,
//   blacklist: ['auth', 'quoteItems'],
// }

const rootReducer = combineReducers({
  user: userReducer,
  login: loginReducer,
  register: registerReducer,
  customer: customerReducer,
  selectOption: selectOptionReducer,
  site: siteReducer,
  job: jobsReducer,
  project: projectReducer,
  checkList: checklistReducer,
  group: groupReducer,
  company: companyReducer,
  quotes: quotesReducer,
  quoteItems: quoteItemsReducer,
  quoteSetting: quoteSettingsReducer,
  invoices: invoicesReducer,
  invoiceItems: invoiceItemsReducer,
  invoiceSetting: invoiceSettingsReducer,
  priceBook: priceBookReducer,
  salesAccount: salesAccountReducer,
  file: filesReducer,
  siteVisit: siteVisitReducer,
  noteHistory: noteHistoryReducer,
  financeSummary: financeSummaryReducer,
  statusBoard: statusBoardReducer,
  planViewer: planviewerReducer,
  floorPlanWebView: floorPlanWebViewReducer,
  suppliers: supplierSettingReducer,
  labourElements: labourElementReducer,
  itemTypes: itemTypesReducer,
  itemManagement: itemManagementReducer,
  preliminary: preliminaryReducer,
  forms: formsReducer,
  projectPlan: projectPlanReducer,
  formBuilder: formBuilderReducer,

  tickets: ticketsReducer,
  materialElement: materialElementReducer,
  preliminaryTemplate: preliminaryTemplateReducer,

  notifications: notificationReducer,

  notice: noticeReducer,

  calendar: calendarReducer,
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
// export default persistReducer(rootPersistConfig, rootReducer)
