import React, {useState} from 'react'
// import { Link } from 'react-router-dom'
import {useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {FaPen, FaTrash} from 'react-icons/fa'

import {ConfirmationModal} from 'app/common'
import {DataTable} from 'app/components'
import {deleteSupplierAction} from 'redux-src'

import {SupplierModal} from '../supplierModal'

export const SupplierTable = ({
  items,
  getSupplierList
}: {
  items: Array<{id: number; supplierName: string}>
  getSupplierList: (page: number, limit: number) => void
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [supplierDetails, setSupplierDetails] = useState<{
    id: string | number
    supplierName: string
  }>()

  const dispatch = useDispatch()

  const {supplierTotal, get_supplier_loading}: RT.SupplierReduxType =
    useSelector((state: any) => state.suppliers)

  const {
    navigation: {navigate}
  } = useNavigation()

  const columns: TableColumn<{
    id: number
    supplierName: string
  }>[] = [
    {
      name: 'Name',
      selector: (row) => row.supplierName,
      sortable: true,
      cell: (row) => (
        <>
          <div className="flex badge items-center justify-between text-sm">
            <span
              className="cursor-pointer text-blue-300"
              onClick={() => {
                navigate(`/settings/supplier/${row.id}`)
              }}
            >
              {row.supplierName}
            </span>

            <div className="flex justify-between items-center gap-x-10">
              <button
                className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
                onClick={() => {
                  setIsModalVisible((prev) => !prev)
                  setSupplierDetails({
                    id: row.id,
                    supplierName: row && row.supplierName
                  })
                }}
              >
                <span className="text-left inline-flex cursor-pointer">
                  <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
                </span>
              </button>

              <ConfirmationModal
                onConfirmClick={(closeModalHandler) => {
                  dispatch(
                    deleteSupplierAction(row.id, () => {
                      getSupplierList(1, 10)
                      closeModalHandler()
                    })
                  )
                }}
                danger
                label={'Are you sure you want to delete this Supplier?'}
                displayElement={
                  <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </button>
                }
                confirmLabel="Delete"
              />
            </div>
          </div>
        </>
      )
    }
  ]

  return (
    <div>
      <DataTable
        columns={columns}
        data={items}
        pagination
        totalRows={supplierTotal}
        fetchData={getSupplierList}
        loading={get_supplier_loading}
      />
      <SupplierModal
        itemTypeModal={isModalVisible}
        close={() => setIsModalVisible(false)}
        mode="edit"
        supplierDetails={supplierDetails}
      />
    </div>
  )
}
