import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {HorizontalSimpleBarchart, PieChartComp} from './components/'
import {Loading, ToolTip} from 'app/common'
import {BsFillCheckCircleFill, BsFillQuestionCircleFill} from 'react-icons/bs'

export function ProjectStatus() {
  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const {
    isProjectStatusLoading,
    projectStatus: data,
    isProjectDetailLoading,
    basicProjectProgressDetails,

    projectDateCalculator: dateData,
  }: RT.ProjectFinancialSummaryReduxType = useSelector(
    (state: any) => state.financeSummary,
  )

  const lastData = dateData?.at(-1)

  const projectStatusData = useMemo(
    () => [
      {
        name: 'Project Hours',
        completed: data?.project_status?.completed_hrs ?? 0,
        total: data?.project_status?.total_hours ?? 0,
      },
    ],
    [data?.project_status?.completed_hrs, data?.project_status?.total_hours],
  )

  const ticketStatusData = useMemo(
    () => [
      {
        name: 'Completed',
        value: data?.ticket_status?.completed_tickets,
        type: 'completed',
        className: 'green-bar',
      },
      {
        name: 'In Progress',
        value: data?.ticket_status?.in_progress_tickets,
        type: 'remaining',
        className: 'blue-bar',
      },
      {
        name: 'Open',
        value: data?.ticket_status?.open_tickets,
        type: 'open',
        className: 'red-bar',
      },
    ],
    [
      data?.ticket_status?.completed_tickets,
      data?.ticket_status?.in_progress_tickets,
      data?.ticket_status?.open_tickets,
    ],
  )

  const projectScheduledData = useMemo(
    () => [
      {
        name: 'Project Scheduled Progress',
        completed: data?.project_scheduled_progress?.completed_weeks ?? 0,
        current: data?.project_scheduled_progress?.current_week ?? 0,
        // projected:
        //   data?.project_scheduled_progress?.projected_completion_week ?? 0,
        projected:
          data?.project_scheduled_progress?.projected_completion_week === 0
            ? Number(
                (isNaN(
                  data?.project_scheduled_progress?.current_week +
                    +lastData?.weeks,
                )
                  ? 0
                  : data?.project_scheduled_progress?.current_week +
                    +lastData?.weeks
                )?.toFixed(2),
              )
            : +data?.project_scheduled_progress?.projected_completion_week?.toFixed(
                2,
              ),
        total:
          basicProjectProgressDetails?.estimatedProjectDuration?.weeks ?? 0,
      },
    ],
    [
      basicProjectProgressDetails?.estimatedProjectDuration?.weeks,
      data?.project_scheduled_progress?.completed_weeks,
      data?.project_scheduled_progress?.current_week,
      data?.project_scheduled_progress?.projected_completion_week,
      lastData?.weeks,
    ],
  )

  const comparedWeeks = +Number(
    (lastData?.weeks ?? 0) -
      (data?.project_scheduled_progress?.projected_completion_week ?? 0),
  ).toFixed(2)

  const isProjectComplete =
    projectDetail?.project_details?.project_status?.toLowerCase() ===
    'completed'

  return (
    <div className="w-full">
      <h3 className="flex items-center gap-8 font-bold text-md border-y-1 border-y-gray-200 py-12 px-16">
        Project Status -{' '}
        <span className="text-grey-500 font-normal flex items-center gap-8">
          {isProjectComplete ? (
            <span className="text-green-500 flex items-center justify-start gap-8">
              Project has been completed.{' '}
              <BsFillCheckCircleFill
                fill="rgb(98 163 2)"
                size={16}
                className="cursor-pointer"
              />
            </span>
          ) : (
            <>
              {!!data?.project_scheduled_progress ? (
                data?.project_scheduled_progress?.projected_completion_week ===
                0 ? (
                  <span className="text-[#ffb500]">
                    Project is under progress. The deduced duration required for
                    project completion is{' '}
                    <strong className="text-lg">
                      {projectScheduledData?.at(0)?.projected}
                    </strong>{' '}
                    weeks.
                  </span>
                ) : // * The project scheduled week is not zero, so compare it with date calculator dates
                comparedWeeks < 0 ? (
                  <span className="text-red-500">
                    Project is delayed by{' '}
                    {/* Conver to absolute because weeks cant be on negative */}
                    <strong className="text-lg">
                      {Math.abs(comparedWeeks)}
                    </strong>{' '}
                    weeks.
                  </span>
                ) : // * Case of positivive: AHEAD OF TIME
                comparedWeeks > 0 ? (
                  <span className="text-green-300">
                    Project is ahead of time by{' '}
                    <strong className="text-lg"> {comparedWeeks}</strong> weeks.
                  </span>
                ) : (
                  // * Case of equality : On Time
                  'Project is running on par with the estimated time.'
                )
              ) : (
                <span className="text-red-500">
                  Project status is not known at the moment.
                </span>
              )}
              <ToolTip
                down
                text="Based on optimal estimation and current completion rate."
              >
                <BsFillQuestionCircleFill
                  fill="orange"
                  size={16}
                  className="cursor-pointer"
                />
              </ToolTip>
            </>
          )}
        </span>
      </h3>

      <div className="grid grid-cols-2 gap-40 p-16 w-full ">
        <div className="flex flex-col">
          <div className="chart-heading mb-10">
            <div className="font-bold text-md w-max text-gray-500">
              Project Hours
            </div>

            <div className="text-gray-300 font-sm mb-10">
              The calculated duration in hours of project deduced from the
              assigned and completed quote line items' labour elements.
            </div>
          </div>

          {isProjectStatusLoading ? (
            <Loading />
          ) : !!data?.project_status ? (
            data?.project_status?.completed_hrs === 0 &&
            data?.project_status?.total_hours === 0 ? (
              <div className="flex">
                <div className="font-normal text-[#E2420D] px-20">
                  Project hours are not assigned or calculated yet.
                </div>
              </div>
            ) : (
              <HorizontalSimpleBarchart
                showName={false}
                dataType="hours"
                data={projectStatusData}
              />
            )
          ) : (
            <div className="flex">
              <div className="font-normal text-gray-400 px-20">
                No data available
              </div>
            </div>
          )}
        </div>

        {!isProjectComplete && (
          <div className="flex flex-col">
            <div className="chart-heading mb-10">
              <div className="font-bold text-md text-gray-500">
                Project Scheduled Progress
              </div>

              <div className="text-gray-300 font-sm mb-10">
                The comparison of estimated duration deduced from the sum of
                total time required for accepted quote line items' labour
                elements against calendar date set and estimated time in weeks
                for project completion.
              </div>
            </div>

            {isProjectStatusLoading || isProjectDetailLoading ? (
              <Loading />
            ) : !!data?.project_scheduled_progress ? (
              data?.project_scheduled_progress?.completed_weeks === 0 &&
              data?.project_scheduled_progress?.current_week === 0 &&
              data?.project_scheduled_progress?.projected_completion_week ===
                0 ? (
                <div className="flex">
                  <div className="font-normal text-[#E2420D] px-20">
                    Project schedule are not prepared yet.
                  </div>
                </div>
              ) : (
                <HorizontalSimpleBarchart
                  showName={false}
                  dataType="weeks"
                  data={projectScheduledData}
                />
              )
            ) : (
              <div className="flex">
                <div className="font-normal text-gray-400 px-20">
                  No data available
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col">
          <div className="chart-heading mb-10">
            <div className="font-bold text-md w-max text-gray-500">
              Ticket Status
            </div>

            <div className="text-gray-300 font-sm mb-10">
              The number of tickets assigned in the project that are either
              open, completed or in progress.
            </div>
          </div>
          {isProjectStatusLoading ? (
            <Loading />
          ) : !!data?.ticket_status ? (
            data?.ticket_status?.completed_tickets === 0 &&
            data?.ticket_status?.in_progress_tickets === 0 &&
            data?.ticket_status?.open_tickets === 0 ? (
              <div className="flex">
                <div className="font-normal text-[#E2420D] px-20">
                  No tickets have been opened or assigned till now
                </div>
              </div>
            ) : (
              <PieChartComp data={ticketStatusData} />
            )
          ) : (
            <div className="flex">
              <div className="font-normal text-gray-400 px-20">
                No data available
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
