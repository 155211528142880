import React, {useCallback, useEffect, useMemo} from 'react'
import {useFormInput} from 'use-form-input'
import {useDispatch} from 'react-redux'
import {TableColumn} from 'react-data-table-component'

import {DataTable, DynamicSearchSelect} from 'app/components'
import {Button, ConfirmationModal, Input, Modal} from 'app/common'

import {
  createMaterialElementGroupAction,
  getMaterialElementAndGroupList,
  getMaterialElementGroupAction,
  getMaterialElementGroupById,
  removeMaterialElementFromGroup,
} from 'redux-src'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {FaTrash} from 'react-icons/fa'

export interface SelectedMaterialElementsForGroup {
  id: number
  label: string
  value: number
  cost: number
  totalCost: string
  numberPerUnit: number
  unit: string
  disabled?: boolean
  totalUnits?: number
}

interface AddMaterialGroupProps {
  addMaterialGroupModal: boolean
  setAddMaterialGroupModal: React.Dispatch<React.SetStateAction<boolean>>
  mode?: 'update' | 'attach'
  materialElementGroupData?: {
    id: number
    groupName: string
    description: string
    materialElements: Array<SelectedMaterialElementsForGroup>
  }
}

export const AddMaterialGroup = ({
  addMaterialGroupModal,
  setAddMaterialGroupModal,
  mode,
  materialElementGroupData,
}: AddMaterialGroupProps) => {
  const dispatch = useDispatch()

  const [data, {onChange, onSubmit, clear, errors, modified, setValue}] =
    useFormInput<{
      groupName: string
      description: string
      materialElements: Array<SelectedMaterialElementsForGroup>
      defaultElementsId: undefined
    }>(
      {
        groupName: '',
        description: '',
        materialElements: undefined,
        defaultElementsId: undefined,
      },
      () => {
        dispatch(
          createMaterialElementGroupAction(
            {
              name: data.groupName,
              description: data.description,
              materialElements: data.materialElements?.map(({id, value}) => ({
                id,
                totalUnits: value,
              })),
            },
            () => {
              clear()
              setAddMaterialGroupModal(false)
              dispatch(getMaterialElementGroupAction())
            },
          ),
        )
      },
      (data) => {
        const errors: any = {}

        if (data.groupName.length === 0) {
          errors.groupName = 'Please enter group name!'
        } else if (data.description.length === 0) {
          errors.description = 'Please enter description!'
        } else if (data.materialElements?.length === 0) {
          errors.materialElements =
            'Please select at least one material element!'
        }

        return errors
      },
    )

  const onQuantityChangeHandler = (id: number, value: number | string) => {
    const newMaterialElements = [...data?.materialElements]

    const remappedMaterialElements = newMaterialElements.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value,
          totalCost: Number(((value || 1) as any) * item.cost).toFixed(2),
        }
      } else return {...item}
    })

    setValue('materialElements', remappedMaterialElements)
  }

  const prePopulatedGroupInfo = useCallback(() => {
    if (mode === 'update' && materialElementGroupData) {
      setValue('groupName', materialElementGroupData?.groupName)
      setValue('description', materialElementGroupData?.description)
      setValue(
        'defaultElementsId',
        materialElementGroupData?.materialElements?.map((el) => el.id),
      )
      setValue('materialElements', materialElementGroupData?.materialElements)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialElementGroupData])

  useEffect(() => {
    prePopulatedGroupInfo()
  }, [prePopulatedGroupInfo])

  useEffect(() => {
    dispatch(getMaterialElementAndGroupList())
  }, [dispatch])

  return (
    <Modal
      isActive={addMaterialGroupModal}
      toggleModal={setAddMaterialGroupModal}
      title={mode === 'update' ? 'Update group' : 'Add a new group'}
      size="lg"
    >
      <form id="add-material-group" onSubmit={onSubmit}>
        <div className="pt-[18px] px-[20px]">
          <label htmlFor="groupName" className="flex flex-col gap-6">
            <Input
              type="text"
              name="groupName"
              value={data.groupName}
              error={modified.groupName && errors.groupName}
              onChange={onChange}
              placeholder="Group Name"
              label={'Group Name'}
              required
            />
          </label>
          <label htmlFor="description" className="flex flex-col gap-6">
            <Input
              type="text"
              name="description"
              value={data.description}
              error={modified.description && errors.description}
              onChange={onChange}
              placeholder="Description"
              label={'Description'}
              required
            />
          </label>
        </div>
        <div className="flex flex-col gap-6 px-[20px]">
          <span className="text-black font-bold text-sm">
            Material List <span className="text-red-300 pl-4">*</span>
          </span>
          <DynamicSearchSelect
            actionUrl={`/material-elements`}
            multiValue={data?.materialElements ?? undefined}
            remapOptions={(data: Api.MaterialElementIndividual[]) => {
              return data?.map(({material_element_details}) => {
                return {
                  id: material_element_details?.id,
                  label: material_element_details?.name,
                  value: material_element_details?.total_units ?? 1,
                  cost: material_element_details?.cost ?? 0,
                  totalCost: Number(
                    (material_element_details?.total_units ?? 1) *
                      (material_element_details?.cost ?? 0),
                  ).toFixed(2),
                  numberPerUnit: material_element_details?.number_per_unit ?? 0,
                  unit: material_element_details?.unit_name,
                }
              })
            }}
            onMultiChangeValue={(data) => {
              setValue('materialElements', data)
            }}
            isMulti
          />
          {!!data?.materialElements && data?.materialElements.length > 0 && (
            <div className="mt-10">
              <SelectedMaterialElementList
                materialElements={data?.materialElements}
                onChangeHandler={onQuantityChangeHandler}
              />
            </div>
          )}
        </div>

        <div className="border border-t-1 border-gray-200 px-20 py-12 mt-10">
          <div className="flex justify-end gap-8">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={() => setAddMaterialGroupModal(false)}
            >
              Cancel
            </Button>
            <Button
              form="add-material-group"
              type="submit"
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
            >
              {mode === 'update' ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

interface SelectedMaterialElementListProps {
  onChangeHandler?: (id: number, value: any) => void
  materialElements: Array<SelectedMaterialElementsForGroup>
  isUpdate?: boolean
  isGroupPage?: boolean
}

export const SelectedMaterialElementList = ({
  onChangeHandler,
  materialElements: materialElementsTemp,
  isGroupPage,
}: SelectedMaterialElementListProps) => {
  const {currencySymbol} = useAuth()

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {materialElementGroupId} = params as any

  const columns: TableColumn<SelectedMaterialElementsForGroup>[] = [
    {
      name: 'Material Element',
      selector: (row) => row.label,
      sortable: true,
      cell: (row) => {
        return <div>{row.label}</div>
      },
    },
    {
      name: 'Quantity',
      selector: (row) => row.id,
      sortable: true,

      cell: (row) => {
        return (
          <div className="">
            {isGroupPage ? (
              <div>{row.totalUnits}</div>
            ) : (
              <Input
                name="materialQuantity"
                placeholder="Enter Quantity"
                value={row.value ?? 1}
                inputType="decimal"
                onChange={(e) => {
                  onChangeHandler?.(row.id, e.target.value)
                }}
                containerClass="mb-0 w-[60px]"
              />
            )}
          </div>
        )
      },
    },
    {
      name: 'Unit',
      selector: (row) => row.unit,
      sortable: true,
      cell: (row) => {
        return <div>{row.unit}</div>
      },
    },
    {
      name: 'No. Per Unit',
      selector: (row) => row.numberPerUnit,
      sortable: true,
      cell: (row) => {
        return <div>{row.numberPerUnit}</div>
      },
    },
    {
      name: 'Cost Per Quantity',
      selector: (row) => row.cost,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            {currencySymbol}
            {row.cost}
          </div>
        )
      },
    },
    {
      name: 'Total Cost',
      selector: (row) => row.totalCost,
      sortable: true,
      cell: (row) => {
        return (
          <div className="flex justify-between">
            <div>
              {currencySymbol}
              {row.totalCost}
            </div>

            {isGroupPage && (
              <ConfirmationModal
                onConfirmClick={(closeModalHandler) => {
                  dispatch(
                    removeMaterialElementFromGroup(
                      materialElementGroupId,
                      {
                        materialElements: [{id: row.id}],
                      },
                      () => {
                        closeModalHandler()
                        dispatch(
                          getMaterialElementGroupById(materialElementGroupId),
                        )
                      },
                    ),
                  )
                }}
                danger
                label={'Are you sure you want to delete this material?'}
                displayElement={
                  <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </button>
                }
                confirmLabel="Delete Material"
              ></ConfirmationModal>
            )}
          </div>
        )
      },
    },
  ]

  const materialElements = useMemo(() => {
    const filteredElements = materialElementsTemp?.filter(
      (item) => !item?.disabled,
    )

    return filteredElements
  }, [materialElementsTemp])

  return (
    <div className="flex flex-col gap-10">
      {!isGroupPage && (
        <div className="font-bold text-md text-black">
          Selected Material Elements
        </div>
      )}
      <div>
        <DataTable data={materialElements} columns={columns} />
      </div>
    </div>
  )
}
