import {DescriptionBox, MessageBox} from 'app/components'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useMemo, useState} from 'react'
import {Loading, SelectField} from 'app/common'
import {useNavigation} from 'react-auth-navigation'
import {getCostReport} from 'redux-src'
import {CostReportTable} from './components'

type TableType = 'item' | 'material' | 'labour'

export const CostReportPage = () => {
  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  //  Getting datas using selectors
  const {costReport, isCostReportLoading}: RT.ProjectFinancialSummaryReduxType =
    useSelector((state: any) => state.financeSummary)

  const {
    getSiteVisitList: siteVisits,
    getSiteVisitListLoading,
  }: RT.SiteVisitReduxType = useSelector((state: any) => state.siteVisit)

  // when sitevisit id is -1, no site visits are present for the project
  const [siteVisitId, setSiteVisitId] = useState<number | undefined>(undefined)
  const [type, setType] = useState<TableType>('item')

  useEffect(() => {
    if (!getSiteVisitListLoading) {
      setSiteVisitId(siteVisits.length <= 0 ? -1 : undefined)
    }
  }, [getSiteVisitListLoading, siteVisits])

  const handleTypeChange = (newType: TableType) => {
    setType(newType)
  }

  const handleSiteVisitChange = (newSiteVisit: number) => {
    setSiteVisitId(newSiteVisit)
  }

  useEffect(() => {
    if (siteVisits?.length > 0) {
      dispatch(
        getCostReport(projectId, {
          type: type,
          siteVisitId: siteVisitId,
        }),
      )
    }
  }, [dispatch, projectId, siteVisitId, siteVisits, type])

  const onLimitChange = (page: number, limit: number) => {
    dispatch(
      getCostReport(projectId, {
        type: type,
        siteVisitId: siteVisitId,
        page: page,
        limit: limit,
      }),
    )
  }

  return (
    <DescriptionBox
      title={`Cost Report for ${projectDetail?.project_details?.title}`}
      rightElement={
        <DataSelectors
          siteVisits={siteVisits}
          onSitevisitSelectChange={handleSiteVisitChange}
          onTypeSelectChange={handleTypeChange}
        />
      }
    >
      {
        // Check if site visits are loading
        getSiteVisitListLoading ? (
          <Loading />
        ) : siteVisits?.length <= 0 ? (
          <MessageBox
            error
            message={
              'Site visits are not created for this project. To generate cost report, start by creating site visits.'
            }
          />
        ) : isCostReportLoading ? (
          <Loading />
        ) : !!costReport ? (
          <CostReportTable
            tableType={type}
            data={costReport}
            onLimitChange={onLimitChange}
          />
        ) : (
          <MessageBox
            error
            message={
              'There are no cost report records available for selected project or site visit or type.'
            }
          />
        )
      }
    </DescriptionBox>
  )
}

// Selectors for desription box
function DataSelectors({
  onTypeSelectChange,
  onSitevisitSelectChange,
  siteVisits,
}: {
  onTypeSelectChange: (newType: TableType) => void
  onSitevisitSelectChange: (newSiteVisit: number) => void
  siteVisits: Array<Api.SiteVisitIndividual>
}) {
  const siteVisitsList = useMemo(() => {
    let tempSiteVisitOption: Array<{id: number; value: string; label: string}> =
      [{id: undefined, value: 'All', label: 'All Site Visits'}]
    siteVisits?.map((siteVisit, i) => {
      tempSiteVisitOption.push({
        id: siteVisit?.site_visit_details?.id,
        value: siteVisit?.site_visit_details?.title,
        label: siteVisit?.site_visit_details?.title,
      })
    })

    return tempSiteVisitOption
  }, [siteVisits])

  const [selectedSiteVisit, setSelectedSiteVisit] = useState<{
    id: number | undefined
    label: string
    value: string
  }>(() => {
    return siteVisitsList?.at(0)
  })

  return (
    <div className="flex cost-type-selectors gap-x-10" style={{width: '300px'}}>
      <SelectField
        onChangeValue={(newValue, _) => {
          setSelectedSiteVisit(newValue)
          onSitevisitSelectChange(newValue.id)
        }}
        options={[...siteVisitsList]}
        value={selectedSiteVisit}
      />

      <SelectField
        onChangeValue={(newValue, actionMeta) => {
          onTypeSelectChange(newValue.value as TableType)
        }}
        options={[
          {value: 'item', label: 'Item', id: 'item'},
          {value: 'labour', label: 'Labour', id: 'labour'},
          {
            value: 'material',
            label: 'Material',
            id: 'material',
          },
        ]}
        defaultValue={{
          value: 'item',
          label: 'Item',
          id: 'item',
        }}
      />
    </div>
  )
}
