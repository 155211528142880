import {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface DateInputProps {
  id: number
  onSelectElement: (id: number) => void
}

interface DateInputContentType {
  label: string
  description: string
  value: string
}

export const DateInput = ({id, onSelectElement}: DateInputProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [dateDetails, setDateDetails] = useState<DateInputContentType>(
    individualElement?.content as DateInputContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setDateDetails((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, dateDetails, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateDetails])

  return (
    <div
      className={`flex flex-col border border-1 rounded-sm overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
        ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="flex gap-4 py-8">
          <input
            type="text"
            name="label"
            value={dateDetails?.label}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div className="pb-6">
          <input
            type="text"
            name="description"
            value={dateDetails?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className={`text-black text-sm w-full ml-14 bg-transparent ${
              individualElement?.settings?.hideLabel ? 'mt-4' : ''
            }`}
          />
          <span></span>
        </div>
      )}
      <div className="mx-12 my-6  overflow-hidden border border-1 border-gray-300 rounded-sm py-6">
        <input
          type="date"
          name="value"
          placeholder="dd/mm/yyyy"
          value={dateDetails?.value}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          className={`text-black w-full select-none text-sm px-6 bg-transparent ${
            selectedFormElementId === id
              ? 'cursor-not-allowed'
              : 'cursor-pointer'
          }`}
          user-select="none"
          disabled={selectedFormElementId === id}
        />
      </div>
    </div>
  )
}
