import {saveAs} from 'file-saver'

import {Logo} from 'app/common'
import {LOCAL_APK_DOWNLOAD_URL} from 'config'

export const DownloadApk = () => {
  return (
    <div className="flex gap-6 items-center justify-start">
      <div className="text-gray-400">Don't have an android app ?</div>
      <div className="text-blue-300 font-bold cursor-pointer hover:text-blue-400 hover:transition-all hover:duration-150 hover:ease-in-out select-none">
        <a
          rel="noreferrer"
          href={LOCAL_APK_DOWNLOAD_URL}
          download={'SmartClerk.apk'}
        >
          DOWNLOAD
        </a>
      </div>
    </div>
  )
}

export const LoginDownloadInfo = () => {
  return (
    <div className="flex flex-col items-center w-full justify-center py-12 px-4 lg:flex-none h-full overflow-x-hidden overflow-y-auto">
      <div className="mb-32 text-center">
        <div className="flex justify-center">
          <Logo />
        </div>
        <h2 className="mt-6 text-xl font-bold  text-gray-900">
          Download Android Apk
        </h2>
        <button
          className="mt-14 block border-1 bg-blue-300 border-blue-300 rounded-md py-8 text-white w-full px-10"
          onClick={() => {
            saveAs(LOCAL_APK_DOWNLOAD_URL, 'SmartClerk.apk')
          }}
        >
          Download
        </button>
        <div className="text-gray-300 text-md pt-10">Version: 1.0.0</div>
      </div>
    </div>
  )
}
