import {useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'
import {Auth} from 'react-auth-navigation'

import {Button, Input} from 'app/common'
import {CreateTemplateModal, PreliminaryTemplateList} from './components'
import {useDebounceValue} from 'hooks'
import {SettingsHeader} from '../../components'

export const PreliminaryTemplatePage = () => {
  return (
    <div className="mt-4 mx-10 min-h-[85vh]">
      <Auth.Screens path="/settings/preliminary-templates" />
    </div>
  )
}

export const PreliminaryTemplateMain = () => {
  const [isCreateTemplateModal, setCreateTemplateModal] =
    useState<boolean>(false)

  const [filterValue, setFilterValue] = useState<string>()

  const debounceValue = useDebounceValue(filterValue)

  return (
    <div>
      <SettingsHeader
        title={'Preliminary Templates'}
        append={
          <Button
            title="Create Preliminary Template"
            onClick={() => setCreateTemplateModal(true)}
          />
        }
        subHeader={'The list allows you to manage preliminary templates'}
      />

      <div className="my-20">
        <div className="flex items-center justify-between">
          <Input
            name="filterValue"
            type="text"
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
            containerClass="w-[300px]"
            placeholder="Search preliminary template"
            className="w-full text-sm"
            onChange={(e: any) => setFilterValue(e.target.value)}
          />
        </div>
      </div>

      <div className="my-10 mb-24 border-b-1 border-gray-200" />
      <PreliminaryTemplateList filterValue={debounceValue} />

      <CreateTemplateModal
        isModalVisible={isCreateTemplateModal}
        setModalVisible={setCreateTemplateModal}
      />
    </div>
  )
}
