import {createContext, useCallback, useEffect, useState} from 'react'
import {Auth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {FaHome} from 'react-icons/fa'

import {Button, Dropdown} from 'app/common'
import {getCompanyWorkerInvoiceList} from 'redux-src'

import {CreateWorkerInvoiceModal, InvoiceFlowHeadComponent} from './components'

export const InvoicingContext = createContext(null)

export const UserInvoice = () => {
  const {
    activeInvoice: activeInvoiceFromAPI,
    invoicesLoading,
    invoiceDetailLoading,
    addInvoicesLoading,
    workerInvoiceDetails,
  }: RT.ProjectInvoicingReduxType = useSelector((state: any) => state.invoices)

  const dispatch = useDispatch()

  const {
    params,
    navigation: {
      routes: {'User Invoicing Detail': invoiceDetailRoute},
    },
  } = useNavigation()
  const {id: userId} = params as any

  const [isInvoiceFlag, setInvoiceFlag] = useState(true)
  const [isInvoiceLoading, setInvoiceLoading] = useState(false)
  const [isInvoiceDetailRoute, setInvoiceDetailRoute] = useState(false)
  const [activeInvoice, setActiveInvoice] = useState<any>({})

  const [openSelectTicketModal, setSelectTicketModal] = useState<boolean>(false)

  const toggleLoading = useCallback((value: boolean) => {
    setInvoiceLoading(value)
  }, [])

  useEffect(() => {
    setInvoiceDetailRoute(invoiceDetailRoute.active)
  }, [invoiceDetailRoute.active, isInvoiceDetailRoute])

  useEffect(() => {
    setActiveInvoice(activeInvoiceFromAPI)
  }, [activeInvoiceFromAPI])

  useEffect(() => {
    invoicesLoading
      ? toggleLoading(true)
      : addInvoicesLoading
      ? toggleLoading(true)
      : toggleLoading(false)

    return () => toggleLoading(false)
  }, [addInvoicesLoading, invoicesLoading, toggleLoading])

  const fetchWorkerInvoicesList = useCallback(
    (page?: number, limit?: number) => {
      const params = {
        page,
        limit,
        userId,
      }

      dispatch(getCompanyWorkerInvoiceList(params))
    },
    [dispatch, userId],
  )

  useEffect(() => {
    fetchWorkerInvoicesList()
    setInvoiceFlag(false)
  }, [fetchWorkerInvoicesList, isInvoiceFlag])

  const firstItem = {
    name: 'Invoicing',
    icon: <FaHome />,
    url: `/settings/users/${userId}/invoice`,
  }

  const secondItem = {
    name:
      workerInvoiceDetails?.worker_invoice_detail?.type === 'draft'
        ? 'Draft'
        : workerInvoiceDetails?.worker_invoice_detail?.invoice_number,
    icon: '',
    url: `/settings/users/${userId}/invoice`,
  }

  return (
    <div className="w-full min-h-[80vh]">
      <div className="w-full flex justify-between">
        <h1 className="font-bold text-xl mb-4">Invoicing</h1>

        {!isInvoiceDetailRoute && (
          <Dropdown
            triggerElement={
              <Button
                title="Create Invoice"
                className={`${
                  isInvoiceDetailRoute
                    ? 'cursor-not-allowed bg-blue-200 hover:bg-blue-150'
                    : ''
                }`}
              />
            }
            triggerToggle
            placement="bottomright"
          >
            <div
              className="bg-white shadow-md rounded-sm overflow-hidden"
              onClick={() => {
                setSelectTicketModal((prev) => !prev)
              }}
            >
              <div className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer">
                Create Invoice Using Tickets
              </div>
            </div>
          </Dropdown>
        )}
      </div>
      <InvoicingContext.Provider
        value={{
          loader: {
            isInvoiceLoading,
            setInvoiceLoading,
            toggleLoading,
            invoiceDetailLoading,
          },
          activeRoute: {isInvoiceDetailRoute, setInvoiceDetailRoute},
          selectInvoice: {activeInvoice, setActiveInvoice},
        }}
      >
        <InvoiceFlowHeadComponent
          firstItem={firstItem}
          secondItem={secondItem}
        />

        <Auth.Screens path="/settings/users/:id/invoice" />
        {openSelectTicketModal && (
          <CreateWorkerInvoiceModal
            openModal={openSelectTicketModal}
            setOpenModal={setSelectTicketModal}
          />
        )}
      </InvoicingContext.Provider>
    </div>
  )
}
