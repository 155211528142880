import React, {useCallback, useEffect, useRef, useState} from 'react'
import {AnimatedBlock, TransitionBlock, interpolate} from 'react-ui-animate'
import {BsArrowRight} from 'react-icons/bs'
import {GrUpdate} from 'react-icons/gr'
import {FaHourglassEnd, FaHourglassStart} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'

import {DescriptionBox} from 'app/components'
import {
  Button,
  DateInput,
  Input,
  Modal,
  SelectField,
  Textarea
} from 'app/common'
import {getAssetUrl} from 'helpers'
import {
  getProjectByIdAction,
  updateProjectAction,
  uploadProjectImageAction
} from 'redux-src'

interface ProjectDetailsProps {
  customerSiteId: {
    customerId: number
    siteId: number
  }
}

export const ProjectDetails = ({customerSiteId}: ProjectDetailsProps) => {
  const {
    projectDetail: projectDetailsFromApi,
    update_project_loading
  }: RT.ProjectReduxType = useSelector((state: any) => state.project)
  const [dragActive, setDragActive] = useState(false)

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const [projectData, {onChange, errors, modified, onSubmit, setValue}] =
    useFormInput(
      {
        projectType: undefined,
        title: '',
        description: '',
        projectStart: '',
        projectEnd: '',
        projectRate: 0,
        projectWebsite: ''
      },
      (data) => {
        const {
          projectType,
          title,
          description,
          projectEnd,
          projectStart,
          projectRate,
          projectWebsite
        } = data

        const body = {
          projectType: projectType?.value,
          title,
          description,
          startDate: projectDetailsFromApi?.project_details?.is_quote_accepted
            ? undefined
            : projectStart,
          endDate: projectDetailsFromApi?.project_details?.is_quote_accepted
            ? undefined
            : projectEnd,
          payRate: projectRate,
          website:
            projectWebsite && projectWebsite.length > 0
              ? projectWebsite
              : undefined
        }

        dispatch(
          updateProjectAction(projectId, body, () => {
            dispatch(getProjectByIdAction(projectId))
          })
        )
      },
      (data) => {
        const errors: any = {}
        if (data?.title?.length === 0) {
          errors.title = 'Please enter the project name!'
        }

        if (data?.description?.length === 0) {
          errors.description = 'Please enter the project description!'
        }

        if (data?.projectStart === '') {
          errors.projectStart = 'Please select project start date.'
        }

        if (data?.projectEnd === '') {
          errors.projectEnd = 'Please select project end date.'
        }

        return errors
      }
    )

  // const inputRef = useRef<HTMLInputElement>()
  const logoRef = useRef<HTMLInputElement>()

  const [logoPreview, setLogoPreview] = useState<string | ArrayBuffer>(null)
  const [isModalActive, setIsModalActive] = useState<boolean>(false)

  const [isDelete, setDelete] = useState(false)

  const uploadProjectImage = (e: any) => {
    const projectImage = new FormData()
    projectImage.append('files', e.target.files[0])

    dispatch(
      uploadProjectImageAction(projectId, projectImage, () => {
        dispatch(getProjectByIdAction(projectId))
      })
    )
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setLogoPreview(reader.result)
      }
    }

    reader.readAsDataURL(e.target.files[0])
  }

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const projectImage = new FormData()
      projectImage.append('files', e.dataTransfer.files[0])

      dispatch(
        uploadProjectImageAction(projectId, projectImage, () => {
          dispatch(getProjectByIdAction(projectId))
        })
      )
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogoPreview(reader.result)
        }
      }

      reader.readAsDataURL(e.dataTransfer.files[0])
    }
  }

  const prePopulatedProjectDetails = useCallback(() => {
    setValue('title', projectDetailsFromApi?.project_details?.title)
    setValue('description', projectDetailsFromApi?.project_details?.description)
    setValue('projectStart', projectDetailsFromApi?.project_details?.start_date)
    setValue('projectEnd', projectDetailsFromApi?.project_details?.end_date)
    setValue('projectWebsite', projectDetailsFromApi?.project_details?.website)
    setValue('projectRate', projectDetailsFromApi?.project_details?.pay_rate)
    setLogoPreview(
      projectDetailsFromApi?.asset_details
        ? getAssetUrl(projectDetailsFromApi?.asset_details)
        : ''
    )
    setValue(
      'projectType',
      OPTIONS?.find(
        (item) =>
          item?.value === projectDetailsFromApi?.project_details?.project_type
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetailsFromApi])

  useEffect(() => {
    prePopulatedProjectDetails()
  }, [prePopulatedProjectDetails])

  return (
    <DescriptionBox
      title="Project Details"
      bodyClassName="p-16"
      rightElement={
        <div>
          <Button
            title="Update"
            onClick={onSubmit}
            loading={update_project_loading}
          />
        </div>
      }
    >
      <form>
        <div className="mt-1 relative rounded-md pt-10">
          <div className="flex flex-col flex-wrap ">
            <div className="flex justify-between w-full gap-20">
              <div className="flex flex-2 h-[34vh] py-14">
                <div
                  className={` py-14 flex flex-1 items-center justify-center cursor-pointer border-gray-200 relative bg-[#eef5ff] rounded-sm ${
                    dragActive
                      ? 'bg-gray-200 border-gray-300'
                      : 'bg-blue-100 border-blue-200'
                  }`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  {logoPreview ? (
                    <div
                      className="rounded-sm w-full"
                      onMouseEnter={() => setDelete(true)}
                      onMouseLeave={() => setDelete(false)}
                    >
                      {/* <a
                        href={logoPreview?.toString()}
                        target="_blank"
                        rel="noreferrer"
                      > */}
                      <img
                        src={logoPreview?.toString()}
                        className="rounded-sm object-cover w-[300px] h-[34vh] py-14"
                        alt="ProjectImg"
                        // onClick={() => {
                        //   window.open(String(logoPreview), '_blank')
                        // }}
                        // onclick="window.open('anotherpage.html', '_blank')
                        onClick={() => {
                          setIsModalActive((prev) => !prev)
                        }}
                      />
                      {/* </a> */}
                      <TransitionBlock state={isDelete}>
                        {(animation) => (
                          <AnimatedBlock
                            className="p-4 bg-green-400 text-white rounded-sm hover:bg-green-500"
                            style={{
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              translateY: interpolate(
                                animation.value,
                                [0, 1],
                                [10, 0]
                              ),
                              opacity: animation.value
                            }}
                          >
                            <label
                              htmlFor="projectImage"
                              className="inline-flex gap-6 text-[12px]"
                            >
                              <GrUpdate size={10} className="my-4" /> Change
                              Image
                            </label>
                          </AnimatedBlock>
                        )}
                      </TransitionBlock>
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-col gap-10 w-[300px] items-center justify-center">
                        <label
                          htmlFor="projectImage"
                          className="cursor-pointer text-gray-400 mt-auto"
                        >
                          Drag & drop your project image here or
                        </label>
                        <label
                          htmlFor="projectImage"
                          className="my-10 mb-auto border-1 cursor-pointer rounded-sm transition duration-500 ease-in-out text-center text-sm text-gray-300 border-gray-200 px-8 py-4 hover:bg-gray-200 hover:text-gray-400"
                        >
                          Choose project image
                        </label>
                      </div>
                    </>
                  )}
                  <input
                    ref={logoRef}
                    className="cursor-pointer hidden"
                    type="file"
                    id="projectImage"
                    name="projectImage"
                    accept="image/*"
                    multiple={false}
                    onChange={uploadProjectImage}
                  />
                </div>
              </div>
              <div className="flex flex-col flex-1">
                {/* <div className="flex flex-1 flex-nowrap w-full lg:w-12/12 gap-16"> */}
                <div className="flex flex-1">
                  <Input
                    name="title"
                    size="sm"
                    placeholder="Project Name"
                    label="Project Name"
                    className="border-1 border-gray-200"
                    value={projectData?.title}
                    onChange={onChange}
                    required
                    error={modified.title && errors.title}
                  />
                </div>
                <div className="flex gap-6 flex-col w-full flex-1 flex-nowrap">
                  <span className="font-bold">
                    Project Type <span className="text-red-500 ml-2">*</span>
                  </span>
                  <SelectField
                    options={OPTIONS}
                    className="w-full"
                    value={projectData?.projectType}
                    isDisabled={true}
                  />
                </div>
                {/* </div> */}
                <div className="flex w-full mt-[10px] gap-16">
                  <div className="w-full flex flex-1 lg:w-12/12">
                    <DateInput
                      prepend={<FaHourglassStart className="text-blue-300" />}
                      name="projectStart"
                      size="sm"
                      placeholder="Project Start"
                      label="Project Timeline"
                      containerClass="w-full relative mb-18"
                      value={projectData.projectStart}
                      error={modified.projectStart && errors.projectStart}
                      onChange={(date) => {
                        setValue('projectStart', date)
                      }}
                      required
                      disabled={
                        projectDetailsFromApi?.project_details
                          ?.is_quote_accepted
                      }
                    />
                  </div>
                  <div className="flex items-center">
                    <BsArrowRight size={20} />
                  </div>
                  <div className="w-full flex flex-1 lg:w-12/12">
                    <DateInput
                      prepend={<FaHourglassEnd className="text-blue-300" />}
                      name="projectEnd"
                      label={<div></div>}
                      placeholder="Project End"
                      containerClass="w-full relative mb-18 pt-16"
                      value={projectData.projectEnd}
                      error={modified.projectEnd && errors.projectEnd}
                      onChange={(date) => {
                        setValue('projectEnd', date)
                      }}
                      minDate={new Date(projectData?.projectStart)}
                      disabled={
                        projectDetailsFromApi?.project_details
                          ?.is_quote_accepted
                      }
                    />
                  </div>
                </div>
                <div className="w-full flex flex-1 mt-[-20px] lg:w-12/12">
                  <Input
                    name="projectWebsite"
                    size="sm"
                    type="text"
                    placeholder="Project website"
                    className="border-1 border-gray-200"
                    label="Project Website"
                    containerClass="mt-24 flex-1"
                    value={projectData.projectWebsite}
                    error={modified.projectWebsite && errors.projectWebsite}
                    onChange={onChange}
                  />
                </div>
                {projectData.projectType?.label === 'Quote' && (
                  <div className="w-full mt-[10px] flex justify-start flex-1">
                    <Input
                      size="sm"
                      name="projectRate"
                      type="number"
                      label="Project Rate"
                      min={0}
                      className="border-1 border-gray-200"
                      value={projectData?.projectRate}
                      // error={modified.projectRate && errors.projectRate}
                      onChange={onChange}
                      required
                      placeholder="Project rate"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full lg:w-12/12">
              <Textarea
                name="description"
                size="sm"
                placeholder="Project Description"
                label="Description"
                className="border-1 border-gray-200"
                value={projectData?.description}
                error={modified.description && errors.description}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </form>
      <ProjectImageOverview
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
        projectImage={logoPreview?.toString()}
      />
    </DescriptionBox>
  )
}

const ProjectImageOverview = ({
  isModalActive,
  setIsModalActive,
  projectImage
}: {
  isModalActive: boolean
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>
  projectImage: string
}) => {
  return (
    <>
      <Modal
        isActive={isModalActive}
        toggleModal={setIsModalActive}
        title="Project Image Overview"
      >
        <div className="flex w-full p-20 justify-center items-center">
          <img
            src={projectImage}
            alt="project"
            className="rounded-sm object-cover w-full"
          />
        </div>
      </Modal>
    </>
  )
}

const OPTIONS = [
  {
    id: 1,
    label: 'Quote',
    value: 'Quote'
  },
  {
    id: 2,
    label: 'Days Work',
    value: 'Days Work'
  }
]
