import {useCallback, useEffect, useMemo} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'
import {useDispatch, useSelector} from 'react-redux'

import {Button, Modal, SelectField, toast} from 'app/common'

import {
  addUsersToProject,
  getCompanyUserToAddedInProject,
  getProjectUserList
} from 'redux-src'

interface AddNewUserModalType {
  isActive: boolean
  close: () => void
}
export const AddNewUserModal = ({isActive, close}: AddNewUserModalType) => {
  const {getCreateCompanyUserLoading}: RT.UserReduxType = useSelector(
    (state: any) => state.user
  )

  const {companyUsersToAddedInProject}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project
  )

  const userOptions = useMemo(() => {
    return companyUsersToAddedInProject?.map((user) => ({
      id: user.id,
      label: `${user.display_name} ${user.lastname}`,
      value: user.lastname ?? user.display_name
    }))
  }, [companyUsersToAddedInProject])

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const [data, {setValue, errors, modified, onSubmit}] = useFormInput(
    {
      userIds: []
    },
    (data) => {
      const errors: any = {}

      if (data?.userIds.length === 0) {
        errors.userIds = 'Please select atleast one user !!'
      }

      return errors
    }
  )

  const getProjectUsersCallback = useCallback(() => {
    dispatch(getProjectUserList(projectId, 1, 10, ''))
    dispatch(getCompanyUserToAddedInProject(projectId))
  }, [dispatch, projectId])

  const onAddUserToProject = () => {
    if (data?.userIds.length === 0) {
      return toast.error('Please select atleast one user !!')
    }

    const body = {
      userIds: data?.userIds
    }

    dispatch(
      addUsersToProject(projectId, body, (data) => {
        close()
        getProjectUsersCallback()
      })
    )
  }

  useEffect(() => {
    getProjectUsersCallback()
  }, [getProjectUsersCallback])

  return (
    <Modal
      isActive={isActive}
      toggleModal={close}
      title="Add a new user to project"
      size="sm"
    >
      <form
        className="relative px-20 my-6 mb-0 overflow-y-auto h-[20vh]"
        onSubmit={onSubmit}
      >
        <div className="px-14">
          <div className="my-16">
            <SelectField
              options={userOptions}
              onMultiChangeValue={(value) => {
                setValue(
                  'userIds',
                  value?.map((user) => user?.id)
                )
              }}
              placeholder={'Add users'}
              isMulti
              menuHeight={100}
            />

            {errors?.userIds && modified?.userIds && (
              <span className="mt-10 text-red-300">{errors?.userIds}</span>
            )}
          </div>
        </div>
      </form>
      <div className="border-t-1 border-gray-200 "></div>

      <div className="flex justify-end px-16 py-10">
        <Button
          title="cancel"
          buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
          size="sm"
          type="button"
          onClick={close}
        />
        <div className="ml-16">
          <Button
            title="Save"
            size="sm"
            type="submit"
            onClick={onAddUserToProject}
            loading={getCreateCompanyUserLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
