import {useMemo} from 'react'
import {useAuth} from 'react-auth-navigation'
import {FaQuestionCircle} from 'react-icons/fa'
import {useSelector} from 'react-redux'

import {Loading, ToolTip} from 'app/common'

import {BarChartComp} from './components'

export const OverallPosition = () => {
  const {currencySymbol} = useAuth()

  const {financialSummary: data, loading}: RT.ProjectFinancialSummaryReduxType =
    useSelector((state: any) => state.financeSummary)

  const labourStats = useMemo(
    () => ({
      name: 'Cost',
      invoiced: Math.abs(data?.cost?.labour?.invoiced_cost),
      uninvoiced: Math.abs(data?.cost?.labour?.uninvoiced_cost),
      total: data?.cost?.labour?.total_labour_cost,
      deficit: Math.abs(
        data?.cost?.labour?.total_labour_cost -
          (Math.abs(data?.cost?.labour?.invoiced_cost ?? 0) +
            Math.abs(data?.cost?.labour?.uninvoiced_cost ?? 0)),
      ),
    }),
    [
      data?.cost?.labour?.invoiced_cost,
      data?.cost?.labour?.uninvoiced_cost,
      data?.cost?.labour?.total_labour_cost,
    ],
  )

  const labourHour = useMemo(
    () => ({
      name: 'Hours',
      completed: data?.cost?.labour?.completed_labour_hours,
      incomplete:
        data?.cost?.labour?.total_labour_hours ??
        0 - data?.cost?.labour?.completed_labour_hours ??
        0,
      total: data?.cost?.labour?.total_labour_hours,
    }),
    [
      data?.cost?.labour?.completed_labour_hours,
      data?.cost?.labour?.total_labour_hours,
    ],
  )
  const overallStats = useMemo(
    () => ({
      name: 'Cost',
      invoiced: data?.cost?.project?.invoiced_cost,
      uninvoiced: Math.abs(+(data?.cost?.project?.uninvoiced_cost ?? 0)),
      total: data?.cost?.project?.total_project_cost,
      deficit: Math.abs(
        data?.cost?.project?.total_project_cost -
          (Math.abs(+(data?.cost?.project?.invoiced_cost ?? 0)) +
            Math.abs(+(data?.cost?.project?.uninvoiced_cost ?? 0))),
      ),
    }),
    [
      data?.cost?.project?.invoiced_cost,
      data?.cost?.project?.total_project_cost,
      data?.cost?.project?.uninvoiced_cost,
    ],
  )

  const spentData = useMemo(
    () => ({
      name: 'Cost',
      item: data?.spent_cost?.item_costs,
      labour: data?.spent_cost?.labour_costs,
      total: data?.spent_cost?.item_costs + data?.spent_cost?.labour_costs,
    }),
    [data?.spent_cost],
  )

  const materialStats = useMemo(
    () => ({
      name: 'Cost',
      invoiced: Math.abs(data?.cost?.material?.invoiced_material_cost),
      uninvoiced: Math.abs(data?.cost?.material?.uninvoiced_material_cost),
      total: data?.cost?.material?.total_material_cost,
      deficit: Math.abs(
        data?.cost?.material?.total_material_cost -
          Math.abs(data?.cost?.material?.invoiced_material_cost) +
          Math.abs(data?.cost?.material?.uninvoiced_material_cost),
      ),
    }),
    [
      data?.cost?.material?.invoiced_material_cost,
      data?.cost?.material?.total_material_cost,
      data?.cost?.material?.uninvoiced_material_cost,
    ],
  )

  return (
    <>
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16 -mx-16">
        Overall Position
      </h3>

      <div className="px-20 pt-10 flex justify-between items-center my-10">
        <div className="font-bold text-sm text-red-500 flex justify-between items-center">
          {currencySymbol}
          {data?.cost?.project?.uninvoiced_cost ?? 0} Costs Uninvoiced
        </div>
        <ToolTip
          text="The total invoiced cost against uninvoiced cost for the current project."
          left
        >
          <FaQuestionCircle className="text-md cursor-pointer" />
        </ToolTip>
      </div>

      {loading ? (
        <Loading />
      ) : !!data?.cost?.project ? (
        <BarChartComp
          showLegend
          showName
          height={55}
          data={overallStats}
          symbol={currencySymbol}
          notations={{
            name: 'name',
            pv: 'invoiced',
            pvName: 'Invoiced Cost',
            total: 'total',
            totalName: 'Total Project Cost',
            uv: 'uninvoiced',
            uvName: 'Uninvoiced Cost',
            dv: 'deficit',
            dvName:
              overallStats?.invoiced + overallStats?.uninvoiced >
              overallStats?.total
                ? 'Surplus Cost'
                : 'Unspent Cost',
          }}
        />
      ) : (
        <div className="flex px-20">
          <div className="font-normal">Cost</div>

          <div className="font-normal text-gray-400 px-20">
            No data available
          </div>
        </div>
      )}

      <div className="px-20 mt-10 text-md">Showing data for entire project</div>
      <h3 className="font-bold text-md border-y-1 border-gray-200 py-12 px-16 -mx-16 my-12">
        Financial Overview
      </h3>

      {/* Overall Stats */}
      <div className="flex flex-row flex-1 gap-16 py-16">
        <div className="flex flex-col w-full ">
          <div className="font-bold text-gray-500">Overview</div>
          {loading ? (
            <Loading />
          ) : !!data?.cost?.project ? (
            data?.cost?.project?.invoiced_cost === 0 &&
            data?.cost?.project?.total_project_cost === 0 &&
            data?.cost?.project?.uninvoiced_cost === 0 ? (
              <div className="flex">
                <div className="font-normal text-gray-400 px-20">
                  The project costs are not calculated yet.
                </div>
              </div>
            ) : (
              <BarChartComp
                data={overallStats}
                symbol={currencySymbol}
                notations={{
                  name: 'name',
                  pv: 'invoiced',
                  pvName: 'Invoiced Cost',
                  total: 'total',
                  totalName: 'Total Project Cost',
                  uv: 'uninvoiced',
                  uvName: 'Uninvoiced Cost',
                  dv: 'deficit',
                  dvName:
                    overallStats?.invoiced + overallStats?.uninvoiced >
                    overallStats?.total
                      ? 'Surplus Cost'
                      : 'Unspent Cost',
                }}
              />
            )
          ) : (
            <div className="flex">
              <div className="font-normal text-gray-400 px-20">
                No data available
              </div>
            </div>
          )}
        </div>
        <div className="w-full ">
          <KeyValue
            prepend={currencySymbol}
            data={[
              {
                value: data?.cost?.project?.invoiced_cost,
                label: 'Invoiced Cost',
                tooltipText:
                  'The total cost of items that has been invoiced till date.',
              },
              {
                value: data?.cost?.project?.uninvoiced_cost,
                label: 'Uninvoiced Cost',
                tooltipText:
                  'The total cost of item that are allocated to project phases and not yet invoiced.',
              },
              {
                value: data?.cost?.project?.total_project_cost,
                label: 'Total Project Cost',
                tooltipText: 'The total cost allocated for the project.',
              },
            ]}
          />
        </div>
      </div>

      {/* Spent cost */}
      <div className="flex flex-row flex-1 gap-16 border-y-1 border-gray-200 py-16">
        <div className="flex flex-col w-full ">
          <div className="font-bold text-gray-500">Spent Costs</div>
          {loading ? (
            <Loading />
          ) : !!data?.spent_cost?.item_costs &&
            !!data?.spent_cost?.labour_costs ? (
            <BarChartComp
              data={spentData}
              symbol={currencySymbol}
              notations={{
                name: 'name',
                pv: 'item',
                pvName: 'Item Cost',
                total: 'total',
                totalName: 'Total Spent Cost',
                uv: 'labour',
                uvName: 'Labour Cost',
              }}
            />
          ) : (
            <div className="flex">
              {/* <div className="font-normal">Spent</div> */}
              <div className="font-normal text-gray-400 px-20">
                The spent costs can not be calculated yet.
              </div>
            </div>
          )}{' '}
        </div>
        <div className="w-full ">
          <KeyValue
            prepend={currencySymbol}
            data={[
              {
                value: data?.spent_cost?.item_costs,
                label: 'Item Cost',
                tooltipText:
                  'The total amount already spent on the items of project.',
              },
              {
                value: data?.spent_cost?.labour_costs,
                label: 'Labour Cost',
                tooltipText:
                  'The total amount already spent on the labour elements of project.',
              },
            ]}
          />
        </div>
      </div>

      {/* Labour Stats */}
      <div className="flex flex-row flex-1 gap-16 border-y-1 border-gray-200 py-16">
        <div className="flex flex-col w-full ">
          <div className="font-bold text-gray-500">Labour Costs</div>
          {loading ? (
            <Loading />
          ) : !!data?.cost?.labour ? (
            data?.cost?.labour?.total_labour_cost === 0 ? (
              <div className="flex">
                <div className="font-normal text-gray-400 px-20">
                  The labour costs can not be calculated yet.
                </div>
              </div>
            ) : (
              <BarChartComp
                data={labourStats}
                symbol={currencySymbol}
                notations={{
                  name: 'name',
                  pv: 'invoiced',
                  pvName: 'Invoiced Cost',
                  total: 'total',
                  totalName: 'Total Labour Cost',
                  uv: 'uninvoiced',
                  uvName: 'Uninvoiced Cost',
                  dv: 'deficit',
                  dvName:
                    labourStats?.invoiced + labourStats?.uninvoiced >
                    labourStats?.total
                      ? 'Surplus Cost'
                      : 'Unspent Cost',
                }}
              />
            )
          ) : (
            <div className="flex">
              <div className="font-normal text-gray-400 px-20">
                No data available
              </div>
            </div>
          )}
        </div>
        <div className="w-full ">
          <KeyValue
            prepend={currencySymbol}
            data={[
              {
                value: data?.cost?.labour?.invoiced_cost,
                label: 'Invoiced Cost',
                tooltipText:
                  'The total cost value of labour that has been invoiced to the customer.',
              },
              {
                value: data?.cost?.labour?.uninvoiced_cost,
                label: 'Uninvoiced Cost',
                tooltipText:
                  'The total cost value of labour that is yet to be invoiced to the customer.',
              },
              {
                value: data?.cost?.labour?.total_labour_cost,
                label: 'Total Labour Cost',
                tooltipText:
                  'The total cost of labour allocated for the project phase.',
              },
              {
                value: data?.cost?.labour?.estimated_labour_budget,
                label: 'Estimated Labour Budget',
                tooltipText: 'The estimated labour cost for the project phase.',
              },
            ]}
          />
        </div>
      </div>

      {/* Labour Hours */}
      <div className="flex flex-row flex-1 gap-16 border-y-1 border-gray-200 py-16">
        <div className="flex flex-col w-full ">
          <div className="font-bold text-gray-500">Labour Hours</div>
          {loading ? (
            <Loading />
          ) : !!data?.cost?.labour ? (
            data?.cost?.labour?.total_labour_hours === 0 ? (
              <div className="flex">
                <div className="font-normal text-gray-400 px-20">
                  The labour hours can not be calculated yet.
                </div>
              </div>
            ) : (
              <BarChartComp
                data={labourHour}
                notations={{
                  name: 'name',
                  pv: 'completed',
                  pvName: 'Completed Hour',
                  total: 'total',
                  totalName: 'Total Labour Hours',
                  uv: 'incomplete',
                  uvName: 'Remaining Hour',
                }}
              />
            )
          ) : (
            <div className="flex">
              <div className="font-normal text-gray-400 px-20">
                No data available
              </div>
            </div>
          )}
        </div>
        <div className="w-full ">
          <KeyValue
            append="hrs"
            data={[
              {
                value: data?.cost?.labour?.completed_labour_hours,
                label: 'Completed Hour',
                tooltipText:
                  'The total number of hours that are already used in the project.',
              },
              {
                value: data?.cost?.labour?.total_labour_hours,
                label: 'Total Hour',
                tooltipText:
                  'The total number of hour allocated for the project.',
              },
            ]}
          />
        </div>
      </div>

      {/* Material cost */}
      <div className="flex flex-row flex-1 gap-16 border-y-1 border-gray-200 py-16">
        <div className="flex flex-col w-full ">
          <div className="font-bold text-gray-500">Material Costs</div>
          {loading ? (
            <Loading />
          ) : !!data?.cost?.material ? (
            data?.cost?.material?.total_material_cost === 0 ? (
              <div className="flex">
                <div className="font-normal text-gray-400 px-20">
                  The material costs can not be calculated yet.
                </div>
              </div>
            ) : (
              <BarChartComp
                data={materialStats}
                notations={{
                  name: 'name',
                  pv: 'invoiced',
                  pvName: 'Invoiced Cost',
                  total: 'total',
                  totalName: 'Total Material Cost',
                  uv: 'uninvoiced',
                  uvName: 'Uninvoiced Cost',
                  dv: 'deficit',
                  dvName:
                    materialStats?.invoiced + materialStats?.uninvoiced >
                    materialStats?.total
                      ? 'Surplus Cost'
                      : 'Unspent Cost',
                }}
              />
            )
          ) : (
            <div className="flex">
              <div className="font-normal text-gray-400 px-20">
                No data available
              </div>
            </div>
          )}
        </div>
        <div className="w-full ">
          <KeyValue
            prepend={currencySymbol}
            data={[
              {
                value: data?.cost?.material?.invoiced_material_cost,
                label: 'Invoiced Cost',
                tooltipText:
                  'The total cost value of material that has been invoiced to the customer.',
              },
              {
                value: data?.cost?.material?.uninvoiced_material_cost,
                label: 'Uninvoiced Cost',
                tooltipText:
                  'The total cost value of material that is yet to be invoiced to the customer.',
              },
              {
                value: data?.cost?.material?.total_material_cost,
                label: 'Total Material Cost',
                tooltipText:
                  'The total cost value of material that has been allocated for the project phase.',
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

interface KeyValueProps {
  data?: Array<{
    value?: number
    label?: string
    tooltipText?: string
  }>
  append?: React.ReactNode
  prepend?: React.ReactNode
}
const KeyValue = ({data, append, prepend}: KeyValueProps) => {
  return (
    <div className="w-full">
      <table className="w-4/5 ml-20 align-middle">
        <tbody>
          {data?.map(({value, label, tooltipText}, index) => {
            if (value === null) return <></>
            return (
              <tr key={index} className="w-full">
                <td align="left" className="2/4">
                  {label}
                </td>
                <td align="left" className="w-1/4">
                  <p className="text-gray-400">
                    {/* Show zero if the value is unknown or undefined */}
                    {prepend} {value ?? 0} {append}
                  </p>
                </td>
                <td align="right" className="w-1/4">
                  {isStringEmpty(tooltipText) ? (
                    <FaQuestionCircle className="text-md cursor-pointer" />
                  ) : (
                    <ToolTip text={tooltipText} top>
                      <FaQuestionCircle className="text-md cursor-pointer" />
                    </ToolTip>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function isStringEmpty(str: string) {
  return str === null || str === undefined || str === ''
}
