import moment from 'moment'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'
import ReactDatePicker from 'react-datepicker'

import {
  getWorkerInvoiceById,
  updateWorkerInvoiceBasicDetailsAction,
} from 'redux-src'

export const LetterHead = ({
  previewMode,
  companyDetails,
  invoiceDetail,
  defaultInvoiceSettings,
}: {
  previewMode?: boolean
  companyDetails: Api.CompanyDetails
  invoiceDetail: Api.WorkerInvoiceDetailsById
  defaultInvoiceSettings: any
}) => {
  const {companyUserDetails}: RT.UserReduxType = useSelector(
    (state: any) => state.user,
  )

  const [data, {setValue}] = useFormInput({dueDate: undefined})

  const {params} = useNavigation()
  const {invoiceId} = params as any

  const dispatch = useDispatch()

  const [showLetterHead, setShowLetterHead] = useState(true)
  const [isEditActive, setIsEditActive] = useState<boolean>(false)

  const {worker_invoice_detail} = !!invoiceDetail && invoiceDetail

  useEffect(() => {
    setValue('dueDate', worker_invoice_detail?.due_date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worker_invoice_detail])

  return (
    <>
      <button
        onClick={() => setShowLetterHead((prev) => !prev)}
        className="text-blue-200 hover:underline mt-4"
      >
        {showLetterHead ? 'Hide' : 'Show'} Letter Head
      </button>

      {showLetterHead && (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div></div>
            <div className="text-right">
              <div className="text-lg font-bold">
                {companyDetails?.company_details?.name.toUpperCase() ?? 'JFDS'}
              </div>
              <ul>
                {/* <li>
                  {companyDetails?.address_details?.city},{' '}
                  {companyDetails?.address_details?.state}
                </li> */}
                <li>{companyDetails?.address_details?.address}</li>
                {companyDetails?.contact_details?.emails?.map(
                  (item: string) => (
                    <li key={item}>{item}</li>
                  ),
                )}
                {companyDetails?.contact_details?.phones?.map(
                  (item: string) => (
                    <li key={item}>{item}</li>
                  ),
                )}
                {/* <li>info@jfds.co.uk</li>
                <li>0633310649</li> */}
              </ul>
            </div>
          </div>

          <div className="text-right text-orange-700 mb-4 mt-12">
            <h3 className="text-lg font-bold uppercase">
              {worker_invoice_detail?.status}
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div />
            <div>
              <div
                className="bg-blue-100 flex justify-between p-10"
                style={{minWidth: 400}}
              >
                <div>
                  <span className="font-bold">Worker Details</span>
                  <ul>
                    <li>
                      {companyUserDetails?.user_details?.display_name}{' '}
                      {companyUserDetails?.user_details?.lastname}
                    </li>
                    <li>{companyUserDetails?.address_details?.suburb}</li>
                    <li>
                      {companyUserDetails?.address_details?.city}{' '}
                      {companyUserDetails?.address_details?.zip_code
                        ? `, ${companyUserDetails?.address_details?.zip_code}`
                        : ''}
                    </li>
                  </ul>
                </div>

                <div className="justify-end">
                  <ul className="text-right flex flex-col gap-6">
                    <li>
                      Invoice Number:{' '}
                      <span
                        className={
                          worker_invoice_detail?.status !== 'draft'
                            ? 'text-gray-500'
                            : 'text-gray-300'
                        }
                      >
                        INV-
                        {worker_invoice_detail?.status !== 'draft'
                          ? worker_invoice_detail?.id
                          : 'DRAFT'}
                      </span>
                    </li>
                    {/* <li>
                      Job Number:{' '}
                      {projectDetailsFromApi?.project_details?.project_prefix}-
                      {projectDetailsFromApi?.project_details?.project_number}
                    </li> */}
                    <li>
                      Company Registration Number:{' '}
                      {companyDetails?.company_details?.tax_number}
                    </li>
                    <li>Invoice Date: {moment().format('Do MMM YYYY')}</li>
                    <li>
                      {isEditActive ? (
                        <span className="flex w-full">
                          <ReactDatePicker
                            selected={
                              data?.dueDate
                                ? new Date(data?.dueDate)
                                : undefined
                            }
                            onChange={(date: any) => {
                              setValue('dueDate', moment(date).toISOString())
                            }}
                            className="w-[70%] py-6 pl-4 border-1 border-gray-200 rounded-sm"
                            placeholderText="Completed Date"
                          />
                          <span
                            className="px-8 py-6 text-white font-bold bg-blue-200 rounded-sm ml-4 cursor-pointer hover:bg-blue-300 active:bg-blue-200"
                            onClick={() => {
                              const body = {
                                dueDate: data?.dueDate,
                              }

                              dispatch(
                                updateWorkerInvoiceBasicDetailsAction(
                                  invoiceId,
                                  body,
                                  () => {
                                    dispatch(getWorkerInvoiceById(invoiceId))
                                    setIsEditActive(false)
                                  },
                                ),
                              )
                            }}
                          >
                            Save
                          </span>
                        </span>
                      ) : (
                        <span>
                          Valid Until:{' '}
                          {moment(worker_invoice_detail?.due_date).format(
                            'Do MMM YYYY',
                          )}
                        </span>
                      )}
                      {worker_invoice_detail?.status === 'unpaid' &&
                        !isEditActive && (
                          <span
                            className="px-8 py-6 text-back font-bold bg-orange-200 rounded-sm ml-4 cursor-pointer hover:bg-orange-300 active:bg-orange-200"
                            onClick={() => {
                              setIsEditActive((prev) => !prev)
                            }}
                          >
                            Edit
                          </span>
                        )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
