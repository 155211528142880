import {useCallback, useEffect, useMemo, useState} from 'react'
import {ActiveLink} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'

import {Loading} from 'app/common'
import {DataTable} from 'app/components'
import {getCompanyUserList} from 'redux-src'

import {UserListType} from './index'

export const UserList = ({search}: {search?: string}) => {
  const [showDisabledUsers, setShowDisabledUsers] = useState(false)
  const {getAppUserList_loading: loading, companyUserList}: RT.UserReduxType =
    useSelector((state: any) => state.user)

  const {companyDisabledUsers, companyUserListTotal}: RT.UserReduxType =
    useSelector((state: any) => state.user)

  const dispatch = useDispatch()

  const newCompanyUserList = useMemo(() => {
    const remappedUserList = companyUserList?.map(
      (user: Api.CompanyUserDetails) => {
        return {
          id: user?.users_details?.id,
          fullName: `${user?.users_details?.display_name} ${user?.users_details?.lastname}`,
          userType: user?.users_details?.role,
          dateCreated: String(moment(user?.users_details?.created_at)),
          lastLogin: 'Pata Nahi'
        }
      }
    )

    return remappedUserList
  }, [companyUserList])

  const newCompanyDisabledUserList = useMemo(() => {
    const remappedUserList = companyDisabledUsers?.map(
      (user: Api.CompanyUserDetails) => {
        return {
          id: user?.users_details?.id,
          fullName: `${user?.users_details?.lastname} ${user?.users_details?.lastname}`,
          userType: user?.users_details?.role,
          dateCreated: String(moment(user?.users_details?.created_at)),
          lastLogin: 'Pata Nahi'
        }
      }
    )

    return remappedUserList
  }, [companyDisabledUsers])

  const getProjectUserListPagination = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getCompanyUserList({page, limit, search}))
    },
    [search, dispatch]
  )

  useEffect(() => {
    getProjectUserListPagination()
  }, [getProjectUserListPagination])

  const columns: TableColumn<UserListType>[] = [
    {
      name: 'Name',
      selector: (row) => row.fullName,
      cell: (row) => {
        return (
          <ActiveLink
            to={`/settings/users/${row.id}`}
            className={'text-blue-300 font-medium'}
          >
            {row.fullName}
          </ActiveLink>
        )
      },
      sortable: true
    },
    {
      name: 'User Type',
      selector: (row) => row.userType,
      sortable: true,
      cell: (row) => {
        return <div className="text-sm">{row.userType}</div>
      }
    },
    {
      name: 'Date Created',
      selector: (row) => row.dateCreated,
      sortable: true,
      cell: (row) => {
        return <div>{moment(row.dateCreated).format('ddd, MMM DD YYYY')}</div>
      }
    }
  ]

  return loading ? (
    <Loading />
  ) : (
    <div className="mt-16">
      <DataTable
        columns={columns}
        data={newCompanyUserList ?? []}
        striped
        totalRows={companyUserListTotal}
        pagination
        fetchData={getProjectUserListPagination}
      />

      {/* DISABLED USER LIST */}

      <div className="mb-16">
        <div className="flex items-center justify-between my-16">
          <div className="text-lg font-bold text-gray-500">Disabled users</div>
          <div
            className="cursor-pointer transition duration-500 ease-in-out bg-transparent hover:bg-transparent text-[14px] text-blue-400 hover:text-blue-500"
            onClick={() => setShowDisabledUsers((prev) => !prev)}
          >
            {showDisabledUsers ? 'Hide' : 'Show'} disabled users
          </div>
        </div>
        {/* LIST */}

        {showDisabledUsers && (
          <DataTable
            columns={columns}
            data={newCompanyDisabledUserList ?? []}
            persistTableHead
          />
        )}
      </div>
    </div>
  )
}
