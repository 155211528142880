import {
  GET_PRELIMINARY_LIST,
  CREATE_PRELIMINARY_ITEM,
  UPDATE_PRELIMINARY_ITEM_INFO,
  UPDATE_PRELIMINARY_ITEM_MONITORY_INFO,
  DELETE_PRELIMINARY_ITEM,
  CREATE_PRELIMINARY_LINE_ITEM,
  UPDATE_PRELIMINARY_LINE_ITEM,
  DELETE_PRELIMINARY_LINE_ITEM,
  UPDATE_PROJECT_PRELIMINARY_DETAILS
} from '../actions'

const initialState: RT.PreliminaryReduxType = {
  preliminaryList: undefined,
  getPreliminaryListLoading: false,

  addPreliminaryItemLoading: false,
  updatePreliminaryItemLoading: false,

  updateMonetaryInfoLoading: false,
  deletePreliminaryItemLoading: false,

  createPreliminaryLineItemLoading: false,
  updatePreliminaryLineItemLoading: false,
  deletePreliminaryLineItemLoading: false,

  getProjectPreliminaryDetailsLoading: false
}

export function preliminaryReducer(state = initialState, action: any) {
  const {type, payload} = action

  //PRELIMINARY

  switch (type) {
    case GET_PRELIMINARY_LIST.LOADING:
      return {
        ...state,
        getPreliminaryListLoading: true
      }
    case GET_PRELIMINARY_LIST.SUCCESS:
      return {
        ...state,
        getPreliminaryListLoading: false,
        preliminaryList: payload
      }
    case GET_PRELIMINARY_LIST.ERROR:
      return {...state, getPreliminaryListLoading: false}

    case CREATE_PRELIMINARY_ITEM.LOADING:
      return {
        ...state,
        addPreliminaryItemLoading: true
      }
    case CREATE_PRELIMINARY_ITEM.SUCCESS:
      return {
        ...state,
        addPreliminaryItemLoading: false
      }
    case CREATE_PRELIMINARY_ITEM.ERROR:
      return {...state, addPreliminaryItemLoading: false}

    case UPDATE_PRELIMINARY_ITEM_INFO.LOADING:
      return {
        ...state,
        updatePreliminaryItemLoading: true
      }
    case UPDATE_PRELIMINARY_ITEM_INFO.SUCCESS:
      return {
        ...state,
        updatePreliminaryItemLoading: false
      }
    case UPDATE_PRELIMINARY_ITEM_INFO.ERROR:
      return {...state, updatePreliminaryItemLoading: false}

    case UPDATE_PRELIMINARY_ITEM_MONITORY_INFO.LOADING:
      return {
        ...state,
        updateMonetaryInfoLoading: true
      }
    case UPDATE_PRELIMINARY_ITEM_MONITORY_INFO.SUCCESS:
      return {
        ...state,
        updateMonetaryInfoLoading: false
      }
    case UPDATE_PRELIMINARY_ITEM_MONITORY_INFO.ERROR:
      return {...state, updateMonetaryInfoLoading: false}

    case DELETE_PRELIMINARY_ITEM.LOADING:
      return {
        ...state,
        deletePreliminaryItemLoading: true
      }
    case DELETE_PRELIMINARY_ITEM.SUCCESS:
      return {
        ...state,
        deletePreliminaryItemLoading: false
      }
    case DELETE_PRELIMINARY_ITEM.ERROR:
      return {...state, deletePreliminaryItemLoading: false}

    case CREATE_PRELIMINARY_LINE_ITEM.LOADING:
      return {
        ...state,
        createPreliminaryLineItemLoading: true
      }
    case CREATE_PRELIMINARY_LINE_ITEM.SUCCESS:
      return {
        ...state,
        createPreliminaryLineItemLoading: false
      }
    case CREATE_PRELIMINARY_LINE_ITEM.ERROR:
      return {...state, createPreliminaryLineItemLoading: false}

    case UPDATE_PRELIMINARY_LINE_ITEM.LOADING:
      return {
        ...state,
        updatePreliminaryLineItemLoading: true
      }
    case UPDATE_PRELIMINARY_LINE_ITEM.SUCCESS:
      return {
        ...state,
        updatePreliminaryLineItemLoading: false
      }
    case UPDATE_PRELIMINARY_LINE_ITEM.ERROR:
      return {...state, updatePreliminaryLineItemLoading: false}

    case DELETE_PRELIMINARY_LINE_ITEM.LOADING:
      return {
        ...state,
        deletePreliminaryLineItemLoading: true
      }
    case DELETE_PRELIMINARY_LINE_ITEM.SUCCESS:
      return {
        ...state,
        deletePreliminaryLineItemLoading: false
      }
    case DELETE_PRELIMINARY_LINE_ITEM.ERROR:
      return {...state, deletePreliminaryLineItemLoading: false}

    case UPDATE_PROJECT_PRELIMINARY_DETAILS.LOADING:
      return {
        ...state,
        getProjectPreliminaryDetailsLoading: true
      }
    case UPDATE_PROJECT_PRELIMINARY_DETAILS.SUCCESS:
      return {
        ...state,
        getProjectPreliminaryDetailsLoading: false
      }
    case UPDATE_PROJECT_PRELIMINARY_DETAILS.ERROR:
      return {...state, getProjectPreliminaryDetailsLoading: false}

    default:
      return state
  }
}
