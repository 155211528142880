import { errorHandler } from '../../utils'
import { api, APIS } from '../../config'
import { REGISTER } from './actionTypes.action'

export function register(
  body: {
    displayName: string
    companyName: string
    email: string
    mobile: number
    numberOfStaffs: string
    country: string
    password: string
    industry: string
  },
  callback: () => void,
  toast?: any
) {
  return async function (dispatch: any) {
    let res

    // const {displayName, emailAddress, password, companyName, mobileNumber}

    try {
      dispatch({ type: REGISTER.LOADING })

      res = await api<any>(APIS.register, 'POST', body)

      if (res.data !== undefined) {
        const {
          success,
          data: { message },
        } = res.data
        // // console.log('responseRegister: ', res)
        if (success) {
          dispatch({ type: REGISTER.SUCCESS })
          callback()
          toast.success(message)
        } else {
          dispatch({ type: REGISTER.ERROR, payload: message })
          toast.error('Error while signing up! Please try again later!')
        }
      }
      //
    } catch (message) {
      dispatch({ type: REGISTER.ERROR, payload: message })
      errorHandler(res.data, toast)
    }
  }
}
