import {useState} from 'react'
import {MdAdd} from 'react-icons/md'
import {FaBriefcase, FaBuildingFlag, FaBuildingUser} from 'react-icons/fa6'
import {FaAngleRight, FaUserTie} from 'react-icons/fa'
import {HiDocumentText} from 'react-icons/hi'

import {CustomCollapse} from 'app/common'
import {useNavigation} from 'react-auth-navigation'

export const QuickActions = () => {
  const {
    navigation: {navigate},
  } = useNavigation()

  const [quickOpen, setQuickOpen] = useState(true)

  const quickActions = [
    {
      title: 'Create Project',
      id: 1,
      icon: <FaBriefcase />,
      action: () => {
        navigate('/new-project')
      },
    },
    {
      title: 'Create Customer',
      id: 2,
      icon: <FaBuildingUser />,
      action: () => {
        navigate('/customers?create=true')
      },
    },
    {
      title: 'Create Site',
      id: 3,
      icon: <FaBuildingFlag />,
      action: () => {
        navigate('/sites?create=true')
      },
    },
    {
      title: 'Create Form',
      id: 4,
      icon: <HiDocumentText />,
      action: () => {
        navigate('/settings/forms/create')
      },
    },
    {
      title: 'Create User',
      id: 5,
      icon: <FaUserTie />,
      action: () => {
        navigate('/settings/users?create=true')
      },
    },
  ]

  return (
    <div className="rounded-sm bg-white border-1 border-[#d1d1d1]">
      <div className="p-16">
        <CustomCollapse
          header={
            <h2
              className="flex flex-row items-center justify-between text-base text-gray-500 font-bold cursor-pointer pr-10"
              onClick={() => {
                setQuickOpen((prev) => !prev)
              }}
            >
              Quick Actions
              <FaAngleRight />
            </h2>
          }
          content={
            <div className="flex flex-col pt-10">
              {quickActions?.map((item) => {
                return (
                  <div
                    key={item.id}
                    onClick={item.action}
                    className="flex items-center justify-between p-10 hover:bg-blue-100 transition-all duration-200 ease-in-out cursor-pointer rounded-sm"
                  >
                    <div className="flex items-center justify-start font-normal gap-8">
                      {item.icon}
                      {item.title}
                    </div>
                    <MdAdd size={20} />
                  </div>
                )
              })}
            </div>
          }
          trigger={quickOpen}
        />
      </div>
    </div>
  )
}
