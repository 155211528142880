import React, {useCallback, useEffect, useState} from 'react'
import Geocode from 'react-geocode'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete' // import { useFormInput } from 'use-form-input'
import {HiMinusSm, HiPlusSm} from 'react-icons/hi'
import {useAuth, useQuery} from 'react-auth-navigation'

import {
  Button,
  Checkbox,
  Input,
  Loading,
  Modal,
  Select,
  SelectField,
} from 'app/common'

import {
  capitalizeFirstLetter,
  capitalizeFirstLetterWithSpace,
  validationHandler,
} from 'utils'
import {
  addressRemapper,
  addressStyles,
  finalAddressRemapper,
  getContactDynamicFields,
  getCountryId,
  getRemappedContacts,
} from 'helpers'
import {
  alterContactTypes,
  createSiteContact,
  getSiteById,
  getSiteContact,
  updateSiteContact,
} from 'redux-src'
import {REACT_APP_GOOGLE_KEY} from 'config'

export const ContactModal = ({
  isModalActive,
  setModalActive,
  onAddNewContact,
  editMode,
  type,
}: {
  isModalActive: boolean
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewContact?: (...arg: any) => void
  editMode?: boolean
  type?: 'both' | 'default' | 'billing'
}) => {
  const {toast} = useAuth()
  const dispatch = useDispatch()

  const queries: any = useQuery()
  const {contactId, contactType} = queries

  const {
    siteById: site,
    get_site_contact_loading: getLoading,
    add_site_contact_loading: addLoading,
    update_site_contact_loading: updateLoading,
    site_contact,
  }: RT.SiteReduxType = useSelector((state: any) => state.site)

  useEffect(() => {
    if (contactId && editMode)
      dispatch(getSiteContact(site?.site_details?.id, contactId))
  }, [contactId, dispatch, editMode, site?.site_details?.id])

  const [contactData, contactHandler] = useFormInput({
    defaultContact: false,
    billingContact: false,
  })

  //? DEFAULT MAIN CONTACT
  const [siteContactData, setSiteContactData] = useState({
    contactTypeId: 2,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: ''}],
    firstName: '',
    lastName: '',
    title: '',
    company: '',
  })

  //? POSTAL ADDRESS
  const [postalAddressData, postalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 2,
  })
  const [postalAddressActive, setPostalAddressActive] = useState(true)
  // const [postAddress_loading, setPostAddress_loading] = useState(false)

  const handleSiteContactCb = (id: number) => {
    contactData.billingContact &&
      dispatch(
        alterContactTypes(
          site?.site_details?.id,
          site?.site_details?.info,
          id,
          contactType === 'other' || !editMode
            ? 'other-contacts'
            : 'main-contact',
          'billing',
          () => {
            setModalActive(false)
            if (site?.site_details?.id)
              dispatch(getSiteById(site?.site_details?.id))
            onAddNewContact?.()
          },
        ),
      )

    contactData.defaultContact &&
      dispatch(
        alterContactTypes(
          site?.site_details?.id,
          site?.site_details?.info,
          id,
          contactType === 'other' || !editMode
            ? 'other-contacts'
            : 'main-contact',
          'default',
          () => {
            setModalActive(false)
            if (site?.site_details?.id)
              dispatch(getSiteById(site?.site_details?.id))

            onAddNewContact?.()
          },
        ),
      )

    if (!contactData.billingContact && !contactData.defaultContact) {
      setModalActive(false)
      if (site?.site_details?.id) dispatch(getSiteById(site?.site_details?.id))

      onAddNewContact?.()
    }
  }

  const onPostalAddressSubmit = () => {
    const postal = postalAddressActive
      ? true
      : validationHandler(
          {
            address: postalAddressData.address,
            city: postalAddressData.city,
            country: postalAddressData.countryId,
          },
          toast,
          'postal',
        )
    return postal
  }

  const onSiteContactSubmit = () => {
    const site = validationHandler(
      {
        'first name': siteContactData.firstName,
        'last name': siteContactData.lastName,
        title: siteContactData.title,
      },
      toast,
      'site contact',
    )
    // return billingContactActive ? site : (site && billing)
    return site
  }

  const onSiteContactUpdate = (e: any) => {
    e.preventDefault()

    const contact = getRemappedContacts(siteContactData)

    const postalAddress = postalAddressActive
      ? undefined
      : finalAddressRemapper(postalAddressData)

    const updatedPostalAddress = postalAddressActive
      ? undefined
      : {
          ...finalAddressRemapper(postalAddressData),
          id: site_contact?.contact_details?.address_details?.id,
        }

    const _requestData: any = {
      contact,
      postalAddress,
    }

    const _updateData: any = {
      contact,
      postalAddress: updatedPostalAddress,
    }

    if (onPostalAddressSubmit() && onSiteContactSubmit()) {
      contactId && editMode
        ? dispatch(
            updateSiteContact(
              site?.site_details?.id,
              contactId,
              _updateData,
              (data) => handleSiteContactCb(data.id),
              toast,
            ),
          )
        : dispatch(
            createSiteContact(
              site?.site_details?.id,
              _requestData,
              (data) => handleSiteContactCb(data.id),
              toast,
            ),
          )
    } else {
      toast.error('Please fill all the required fields!')
    }
  }

  const assignContactDetails = useCallback(() => {
    if (site_contact && editMode) {
      postalAddressHandler.setValue(
        'address',
        site_contact?.contact_details?.address_details?.address ?? '',
      )
      postalAddressHandler.setValue(
        'state',
        site_contact?.contact_details?.address_details?.state ?? '',
      )
      postalAddressHandler.setValue(
        'suburb',
        site_contact?.contact_details?.address_details?.suburb ?? '',
      )
      postalAddressHandler.setValue(
        'city',
        site_contact?.contact_details?.address_details?.city ?? '',
      )
      postalAddressHandler.setValue(
        'place',
        site_contact?.contact_details?.address_details?.place ?? '',
      )
      postalAddressHandler.setValue(
        'countryId',
        getCountryId(site_contact?.contact_details?.address_details?.country) ??
          0,
      )
      postalAddressHandler.setValue(
        'zip',
        site_contact?.contact_details?.address_details?.zip_code ?? '',
      )
      postalAddressHandler.setValue(
        'lat',
        site_contact?.contact_details?.address_details?.coordinates
          ? site_contact?.contact_details?.address_details?.coordinates[0]
          : 0,
      )
      postalAddressHandler.setValue(
        'lng',
        site_contact?.contact_details?.address_details?.coordinates
          ? site_contact?.contact_details?.address_details?.coordinates[1]
          : 0,
      )

      setPostalAddressActive(
        Object.keys(site_contact?.contact_details?.address_details).length ===
          0,
      )

      const getContacts = () => {
        return {
          contactTypeId: 2,
          dynamicFields: getContactDynamicFields({
            emails: site_contact?.contact_details?.emails ?? [],
            phones: site_contact?.contact_details?.phones ?? [],
            otherPhones: site_contact?.contact_details?.other_phones ?? [],
            fax: site_contact?.contact_details?.fax ?? [],
            websites: site_contact?.contact_details?.websites ?? [],
          }),
          firstName: site_contact?.contact_details?.firstname ?? '',
          lastName: site_contact?.contact_details?.lastname ?? '',
          title: site_contact?.contact_details?.position ?? '',
        }
      }

      setSiteContactData(getContacts() as any)

      contactHandler.setValue(
        'billingContact',
        type === 'both' || type === 'billing',
      )
      contactHandler.setValue(
        'defaultContact',
        type === 'both' || type === 'default',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site_contact, editMode, type])

  useEffect(() => {
    assignContactDetails()
  }, [dispatch, assignContactDetails])

  return (
    <Modal
      isActive={isModalActive}
      size="md"
      toggleModal={() => setModalActive(false)}
      title={editMode ? 'Edit Site Contact' : 'New Site Contact'}
    >
      {getLoading && editMode ? (
        <Loading />
      ) : (
        <div className="flex flex-col h-modal overflow-y-auto overflow-x-hidden relative">
          <div className="flex flex-col flex-1 w-full p-10 gap-20 mb-20">
            <ContactTable
              contactProps={{
                contactData: siteContactData,
                setContactData: setSiteContactData,
              }}
            />
            {!postalAddressActive ? (
              <AddressTable
                addressProps={{
                  addressData: postalAddressData,
                  addressHandler: postalAddressHandler,
                }}
                assignSiteAddress={() => setPostalAddressActive(true)}
              />
            ) : (
              <div
                className="font-normal text-blue-300 mx-20 cursor-pointer w-[max-content]"
                onClick={() => setPostalAddressActive(false)}
              >
                Add a postal address for this contact
              </div>
            )}

            <Checkbox
              dataId={'default-contact'}
              className={'mx-20 w-[max-content]'}
              isChecked={contactData.defaultContact}
              value={contactData.defaultContact}
              onChange={() =>
                contactHandler.setValue(
                  'defaultContact',
                  !contactData.defaultContact,
                )
              }
              label={
                <div
                  className={`font-normal ${
                    type === 'both' || type === 'default'
                      ? 'text-gray-300'
                      : 'text-blue-300'
                  } cursor-pointer w-[max-content] ml-8 select-none`}
                >
                  Set as default contact
                </div>
              }
              disabled={type === 'both' || type === 'default'}
            />

            <Checkbox
              dataId={'billing-contact'}
              className={'mx-20 w-[max-content]'}
              isChecked={contactData.billingContact}
              value={contactData.billingContact}
              onChange={() =>
                contactHandler.setValue(
                  'billingContact',
                  !contactData.billingContact,
                )
              }
              label={
                <div
                  className={`font-normal ${
                    type === 'both' || type === 'billing'
                      ? 'text-gray-300'
                      : 'text-blue-300'
                  } cursor-pointer w-[max-content] ml-8 select-none`}
                >
                  Set as billing contact
                </div>
              }
              disabled={type === 'both' || type === 'billing'}
            />
          </div>

          <div className="flex justify-end w-full sticky bottom-0 mt-20 py-20 px-16 gap-16 border-t-1 border-gray-200 bg-white">
            <Button
              className="hover:bg-red-400 bg-red-300"
              onClick={() => setModalActive(false)}
            >
              Cancel
            </Button>
            <Button
              loading={addLoading || updateLoading}
              className="hover:bg-blue-400 bg-blue-300"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                onSiteContactUpdate(e)
              }
            >
              Save Contact
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

const ContactTable = ({
  contactProps: {contactData, setContactData},
  billing,
}: any) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {contactTypeOptions} = selectOptions

  //? MAIN CONTACT CHANGE HANDLING
  const onContactInputChange = (e: any) =>
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value ?? undefined,
    })

  const onContactDynamicFieldChange = (
    e: any,
    key: 'value' | 'contactFieldId',
    i: any,
  ) => {
    const _contactData = {...contactData}

    const newDynamicFields = _contactData?.dynamicFields.map(
      (dynamicField: any) => {
        if (dynamicField.dynamicFieldId === i) {
          return {
            ...dynamicField,
            [key]: key === 'contactFieldId' ? +e.target.value : e.target.value,
          }
        }

        return dynamicField
      },
    )

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}

    setContactData(newContactData)
  }

  const onContactDynamicFieldAddClick = (e: any) => {
    e.preventDefault()
    const _contactData = {...contactData}

    const {dynamicFields} = _contactData
    const dynamicField =
      _contactData?.dynamicFields[_contactData?.dynamicFields.length - 1]
    const newDynamicFields = [
      ...dynamicFields,
      {
        dynamicFieldId: dynamicField.dynamicFieldId + 1,
        contactFieldId: 1,
        value: undefined,
      },
    ]

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}
    setContactData(newContactData)
  }

  const onContactDynamicFieldRemoveClick = (e: any, i: any) => {
    e.preventDefault()
    const _contactData = {...contactData}

    const newDynamicFields = _contactData?.dynamicFields.filter(
      (dynamicField: any) => dynamicField.dynamicFieldId !== i,
    )

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}
    setContactData(newContactData)
  }

  return (
    <div>
      <div className="-mx-16 flex flex-wrap items-center ml-10 mr-10 mt-10">
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">First name *</div>
          <div className="mx-2 mb-18">
            <Input
              name="firstName"
              error={contactData?.firstName?.length === 0}
              value={contactData?.firstName}
              size="sm"
              onChange={(e: any) => {
                setContactData({
                  ...contactData,
                  [e.target.name]: capitalizeFirstLetter(e.target.value),
                })
              }}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Last name *</div>
          <div className="mx-2 mb-18">
            <Input
              name="lastName"
              error={contactData?.lastName.length === 0}
              value={contactData?.lastName}
              size="sm"
              onChange={(e: any) => {
                setContactData({
                  ...contactData,
                  [e.target.name]: capitalizeFirstLetter(e.target.value),
                })
              }}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Title/Position *</div>
          <div className="mx-2 mb-18">
            <Input
              name="title"
              error={contactData?.title?.length === 0}
              value={contactData?.title}
              size="sm"
              onChange={onContactInputChange}
            />
          </div>
        </div>
        {/* {!billing && (
          <div className="w-full lg:w-6/12 px-16">
            <div className="text-sm text-gray-400">Company</div>
            <div className="mx-2 mb-18">
              <Input
                name="company"
                value={contactData?.company}
                size="sm"
                onChange={onContactInputChange}
              />
            </div>
          </div>
        )} */}
      </div>

      {contactData?.dynamicFields?.map((field: any, index: any) => {
        return (
          <div
            className="-mx-16 flex flex-wrap items-center ml-10 mr-10"
            key={field.dynamicFieldId}
          >
            <div className="w-full lg:w-6/12 px-16">
              <div className="text-sm text-gray-400">Contact type</div>
              <div className="mx-2 mb-18">
                <Select
                  name="contactFieldId"
                  options={contactTypeOptions}
                  size="sm"
                  className="text-sm"
                  value={contactData?.dynamicFields[index].contactFieldId}
                  onChange={(e: any) =>
                    onContactDynamicFieldChange(
                      e,
                      'contactFieldId',
                      field.dynamicFieldId,
                    )
                  }
                />
              </div>
            </div>

            <div className="w-full lg:w-5/12 px-16">
              <div className="text-sm text-gray-400">
                {
                  contactTypeOptions.find(
                    (contact: any) =>
                      contact.value ===
                      contactData?.dynamicFields[index].contactFieldId,
                  ).label
                }
              </div>
              <div className="mx-2 mb-18">
                <Input
                  name="value"
                  size="sm"
                  className="text-sm"
                  value={contactData?.dynamicFields[index].value}
                  onChange={(e: any) =>
                    onContactDynamicFieldChange(
                      e,
                      'value',
                      field.dynamicFieldId,
                    )
                  }
                />
              </div>
            </div>
            <div className="w-full lg:w-1/12 px-16">
              {contactData?.dynamicFields[index]?.value === undefined ? (
                <div className="-mx-16 flex">
                  <HiPlusSm
                    className="mx-6 mb-0 bg-gray-200 rounded-sm text-white cursor-not-allowed"
                    size={24}
                  />
                </div>
              ) : contactData?.dynamicFields?.length === index + 1 ? (
                <div className="-mx-16 flex">
                  <HiPlusSm
                    className="mx-6 mb-0 bg-blue-400 hover:bg-blue-500 rounded-sm text-white cursor-pointer"
                    size={24}
                    onClick={onContactDynamicFieldAddClick}
                  />
                </div>
              ) : (
                <div className="-mx-16 flex">
                  <HiMinusSm
                    className="mx-6 mb-0 bg-red-400 hover:bg-red-500 rounded-sm text-white cursor-pointer"
                    size={24}
                    onClick={(e: any) =>
                      onContactDynamicFieldRemoveClick(e, field.dynamicFieldId)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

// MARK: - Address
const AddressTable = ({
  addressProps: {addressData, addressHandler},
  assignSiteAddress,
}: {
  addressProps: {
    addressData: any
    addressHandler: any
  }
  assignSiteAddress: () => void
}) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {countryOptions} = selectOptions
  const [addressValue, setAddressValue] = useState(null)

  const handleAddressDropChange = (item: any) => {
    setAddressValue(item)
    Geocode.setApiKey(REACT_APP_GOOGLE_KEY)
    Geocode.setLanguage('en')
    Geocode.fromAddress(item.label).then(
      async (response) => {
        const responseFromHelper = await addressRemapper(response)
        //

        const _aaddressData = {
          ...addressData,
          ...responseFromHelper,
        }

        const {
          address,
          city,
          countryId,
          lat,
          lng,
          placeId,
          state,
          zip,
          suburb,
          country,
        } = _aaddressData
        const {setValue} = addressHandler

        setValue('address', address)
        setValue('city', city)
        setValue('countryId', countryId)
        setValue('lat', lat)
        setValue('lng', lng)
        setValue('placeId', placeId)
        setValue('state', state)
        setValue('zip', zip)
        setValue('suburb', suburb)
        setValue('country', country)
      },
      (error) => {},
    )
  }

  return (
    <div>
      <div className="text-sm text-gray-700 ml-20">
        <h4 className="flex font-bold text-md justify-between">
          Contact's Postal Address (optional)
        </h4>
      </div>

      <div className=" flex flex-wrap items-center mx-10">
        <>
          <div className="w-full lg:w-6/12 px-16">
            <div>
              <span className="text-sm text-gray-400">Address *</span>
              <span
                className="font-normal text-blue-300 ml-10 cursor-pointer"
                onClick={() => assignSiteAddress()}
              >
                Use site address
              </span>
            </div>
            <div className="mb-20">
              <GooglePlacesAutocomplete
                apiKey={REACT_APP_GOOGLE_KEY}
                selectProps={{
                  value:
                    {
                      label: addressData?.address,
                      value: addressData?.address,
                    } ?? addressValue,
                  onChange: handleAddressDropChange,
                  styles: addressStyles(addressData?.address?.length === 0),
                }}
              />
              <Input
                name="address"
                size="sm"
                value={addressData?.address}
                className="text-sm hidden"
                onChange={addressHandler?.onChange}
                error={addressData?.address?.length === 0}
              />
            </div>
          </div>
        </>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">
            Room/Apartment/Building(Optional)
          </div>
          <div className="mx-2 mb-18">
            <Input
              name="place"
              value={addressData?.place}
              size="sm"
              onChange={addressHandler?.onChange}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Suburb</div>
          <div className="mx-2 mb-18">
            <Input
              name="suburb"
              value={addressData?.suburb}
              size="sm"
              onChange={(e: any) =>
                addressHandler?.setValue(
                  e.target.name,
                  capitalizeFirstLetterWithSpace(e.target.value),
                )
              }
            />
          </div>
        </div>

        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">City *</div>
          <div className="mx-2 mb-18">
            <Input
              name="city"
              size="sm"
              value={addressData?.city}
              onChange={addressHandler?.onChange}
              error={addressData?.city?.length === 0}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">State/Region</div>
          <div className="mx-2 mb-18">
            <Input
              name="state"
              size="sm"
              value={addressData?.state}
              onChange={addressHandler?.onChange}
            />
          </div>
        </div>

        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Zip/Postal Code</div>
          <div className="mx-2 mb-18">
            <Input
              name="zip"
              size="sm"
              value={addressData?.zip}
              onChange={addressHandler?.onChange}
            />
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-16">
          <div className="text-sm text-gray-400">Country *</div>
          <div className="mx-2 mb-18">
            <SelectField
              options={countryOptions}
              placeholder="Select country"
              getOptionLabel={'key'}
              getOptionValue={'value'}
              value={countryOptions.filter((country: any) => {
                return country.value === addressData.countryId
              })}
              onChangeValue={(e) => {
                addressHandler?.setValue('countryId', e.value)
              }}
              error={addressData?.countryId === 0}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
