import React, {useCallback, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useFormInput} from 'use-form-input'
import {FaHourglassStart} from 'react-icons/fa'
import moment from 'moment'

import {DataTable, DescriptionBox} from 'app/components'
import {
  Button,
  Checkbox,
  CollapsibleBlock,
  DateInput,
  Input,
  Modal,
  SelectField,
  Textarea,
  ToolTip,
  toast,
} from 'app/common'
import {
  createInvoiceItemsWithTickets,
  generateSelectedTicketInvoices,
  getInvoiceById,
  getInvoicesAction,
  getSiteVisitListWithTickets,
  getSiteVisitNotPresentInInvoice,
} from 'redux-src'

interface SelectTicketModalProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  isDetailsPage?: boolean
}

export const SelectTicketModal = ({
  openModal,
  setOpenModal,
  isDetailsPage,
}: SelectTicketModalProps) => {
  const {
    siteVisitsAlongWithTickets,
    generateTicketsInvoiceLoading,
    siteVisitsNotPresentInInvoice,
    siteVisitWithTicketsLoading,
  }: RT.ProjectInvoicingReduxType = useSelector((state: any) => state.invoices)

  const [
    data,
    {onChange: onChangeHandler, setValue, errors, modified, onSubmit},
  ] = useFormInput<{
    selectedSiteVisit: {id: number; label: string; value: number}
    siteVisits: SiteVisitTypes
    title: string
    description: string
    dueDate: any
    footerNotes: string
  }>(
    {
      selectedSiteVisit: undefined,
      siteVisits: undefined,
      title: '',
      description: '',
      dueDate: moment.now(),
      footerNotes: '',
    },
    (data) => {
      handleGenerateSiteVisitUsingTicket()
    },
    (data) => {
      const errors: any = {}

      if (data?.title?.length === 0) {
        errors.title = 'Title cannot be empty !!'
      }

      if (data?.dueDate?.length === 0) {
        errors.title = 'Title cannot be empty !!'
      }
      if (data?.footerNotes?.length === 0) {
        errors.footerNotes = 'Footer Notes cannot be empty !!'
      }

      return errors
    },
  )

  const {
    params,
    navigation: {navigate},
  } = useNavigation()
  const {projectId, invoiceId} = params as any

  const dispatch = useDispatch()

  const siteVisitOptionsList: Array<{
    id: number
    label: string
    value: number
  }> = useMemo(() => {
    const remappedOptions =
      siteVisitsAlongWithTickets?.site_visits?.map((item) => ({
        id: item?.site_visit_detail?.id,
        label: item?.site_visit_detail?.title,
        value: item?.site_visit_detail?.id,
      })) ?? []

    const unattachedTicketOption = {
      id: 0,
      label: 'Unattached Ticket List',
      value: 0,
    }

    return [...remappedOptions, unattachedTicketOption]
  }, [siteVisitsAlongWithTickets?.site_visits])

  const remainingSiteVisitOptions: Array<{
    id: number
    label: string
    value: number
  }> = useMemo(() => {
    const remappedOptions = siteVisitsNotPresentInInvoice?.site_visits?.map(
      (siteVisit) => {
        return {
          id: siteVisit?.site_visit_detail?.id,
          label: siteVisit?.site_visit_detail?.title,
          value: siteVisit?.site_visit_detail?.id,
        }
      },
    )

    const unattachedTicketOption = {
      id: 0,
      label: 'Unattached Ticket List',
      value: 0,
    }

    return [remappedOptions, unattachedTicketOption].flat(1)
  }, [siteVisitsNotPresentInInvoice])

  const siteVisitTicketList = useMemo(() => {
    const remappedSiteVisitList =
      siteVisitsAlongWithTickets?.site_visits?.map((item) => {
        return {
          site_visit_detail: {
            ...item?.site_visit_detail,
            isChecked: true,
          },
          ticket_list: item?.ticket_list?.map((ticket) => ({
            ...ticket,
            isChecked: true,
          })),
        }
      }) ?? []

    const unattachedTicketList = {
      site_visit_detail: {
        id: 0,
        title: 'Unattached Ticket List',
        status: 'to schedule',
        project_id: projectId,
        description:
          'This is the list of unattached ticket lists for invoicing.',
        site_visit_number: 0,
        isChecked: true,
      },
      ticket_list:
        siteVisitsAlongWithTickets?.site_visit_unattached_tickets?.map(
          (item) => {
            return {
              ...item,
              isChecked: true,
            }
          },
        ),
    }

    return [...remappedSiteVisitList, unattachedTicketList]
  }, [
    projectId,
    siteVisitsAlongWithTickets?.site_visit_unattached_tickets,
    siteVisitsAlongWithTickets?.site_visits,
  ])

  const siteVisitListNotPresentInInvoice = useMemo(() => {
    const remappedSiteVisits =
      siteVisitsNotPresentInInvoice?.site_visits?.map((item) => {
        return {
          site_visit_detail: {
            ...item?.site_visit_detail,
            isChecked: true,
          },
          ticket_list: item?.ticket_list?.map((ticket) => ({
            ...ticket,
            isChecked: true,
          })),
        }
      }) ?? []

    const unattachedTickes = {
      site_visit_detail: {
        id: 0,
        title: 'Unattached Ticket List',
        status: 'to schedule',
        project_id: projectId,
        description:
          'This is the list of unattached ticket lists for invoicing.',
        site_visit_number: 0,
        isChecked: true,
      },
      ticket_list:
        siteVisitsNotPresentInInvoice?.site_visit_unattached_tickets?.map(
          (item) => {
            return {
              ...item,
              isChecked: true,
            }
          },
        ),
    }

    return [...remappedSiteVisits, unattachedTickes]
  }, [
    projectId,
    siteVisitsNotPresentInInvoice?.site_visits,
    siteVisitsNotPresentInInvoice?.site_visit_unattached_tickets,
  ])

  useEffect(() => {
    if (!isDetailsPage) {
      setValue('siteVisits', siteVisitTicketList)
    } else {
      setValue('siteVisits', siteVisitListNotPresentInInvoice)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteVisitTicketList, isDetailsPage, siteVisitListNotPresentInInvoice])

  const activeSiteVisitOfMainPage = useMemo(() => {
    return data?.siteVisits?.find(
      (siteVisitTicket) =>
        siteVisitTicket?.site_visit_detail?.id === data?.selectedSiteVisit?.id,
    )
  }, [data?.selectedSiteVisit?.id, data?.siteVisits])

  const activeSiteVisitOfDetailsPage = useMemo(() => {
    return data?.siteVisits?.find(
      (siteVisitTicket) =>
        siteVisitTicket?.site_visit_detail?.id === data?.selectedSiteVisit?.id,
    )
  }, [data?.selectedSiteVisit?.id, data?.siteVisits])

  const activeSiteVisit = !isDetailsPage
    ? activeSiteVisitOfMainPage
    : activeSiteVisitOfDetailsPage

  const handleSelectSiteVisit = (data: {
    id: number
    label: string
    value: number
  }) => {
    setValue('selectedSiteVisit', data)
  }

  const updateActiveSiteVisit = useCallback(
    (value: boolean) => {
      const siteVisitBuffer = {
        site_visit_detail: {
          ...activeSiteVisit.site_visit_detail,
          isChecked: !value,
        },
        ticket_list: activeSiteVisit?.ticket_list?.map((item) => ({
          ...item,
          isChecked: !value,
        })),
      }

      setValue('siteVisits', () =>
        data?.siteVisits?.map((siteVisitTicket) =>
          siteVisitTicket?.site_visit_detail?.id ===
          activeSiteVisit?.site_visit_detail?.id
            ? siteVisitBuffer
            : siteVisitTicket,
        ),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeSiteVisit?.site_visit_detail,
      activeSiteVisit?.ticket_list,
      data?.siteVisits,
    ],
  )

  const updateTicketItem = useCallback(
    (value: boolean, itemId: number) => {
      const ticketItemList = activeSiteVisit?.ticket_list?.map((item) =>
        item.id === itemId ? {...item, isChecked: !value} : item,
      )

      const siteVisitBuffer = {
        site_visit_detail: {
          ...activeSiteVisit.site_visit_detail,
          isChecked: ticketItemList?.every((item) => item.isChecked === true),
        },
        ticket_list: ticketItemList,
      }

      setValue('siteVisits', () =>
        data?.siteVisits?.map((siteVisitTicket) =>
          siteVisitTicket?.site_visit_detail?.id ===
          activeSiteVisit?.site_visit_detail?.id
            ? siteVisitBuffer
            : siteVisitTicket,
        ),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeSiteVisit?.site_visit_detail,
      activeSiteVisit?.ticket_list,
      data?.siteVisits,
    ],
  )

  const getUniqueTicketItemList = (ticketItems: Array<TicketItem>) => {
    const ticketItemList = ticketItems
      ?.filter((ticket) => ticket.isChecked)
      ?.map((ticket, _, orgArray) => {
        const quantity = orgArray
          ?.filter(
            (qtyItem) =>
              qtyItem.quote_line_item_id === ticket.quote_line_item_id,
          )
          ?.reduce((acc, curr) => {
            return acc + curr.quantity
          }, 0)

        return {
          quote_line_item_id: ticket.quote_line_item_id,
          quote_line_item_name: ticket.quote_line_item_name,
          quantity,
          price: ticket.price,
          tax_percent: ticket.tax_percent,
          discount_percent: ticket.discount_percent,
          total: Number(
            Number(Number(quantity) * Number(ticket.price)).toFixed(2),
          ),
        }
      })

    return Array.from(
      new Set(ticketItemList?.map((item) => item.quote_line_item_id)),
    )?.map((item) =>
      ticketItemList?.find((ticket) => ticket.quote_line_item_id === item),
    )
  }

  const selectedTicketItems: SiteVisitsWithSelectedItem = useMemo(() => {
    return data?.siteVisits?.map((item) => {
      return {
        ...item,
        ticket_item_list: getUniqueTicketItemList(item.ticket_list),
      }
    })
  }, [data?.siteVisits])

  const handleGenerateSiteVisitUsingTicket = useCallback(() => {
    const body = {
      title: data.title,
      footerNotes: data.footerNotes,
      description: !!data.description ? data.description : undefined,
      dueDate: data.dueDate,
      ticketIds: [
        ...(data?.siteVisits?.map(({ticket_list}) => [
          ...(ticket_list
            ?.filter((ticket) => ticket.isChecked)
            ?.reduce((ticketIds, curr) => {
              return [...ticketIds, curr.id]
            }, [] as Array<number>) ?? []),
        ]) ?? []),
      ].flat(1),
    }

    dispatch(
      generateSelectedTicketInvoices(projectId, body, (response) => {
        navigate(`/projects/${projectId}/invoicing/${response?.invoice_id}`)
        setOpenModal(false)
        dispatch(getInvoicesAction(projectId, 1, 10))
      }),
    )
  }, [
    data.description,
    data.dueDate,
    data.footerNotes,
    data?.siteVisits,
    data.title,
    dispatch,
    navigate,
    projectId,
    setOpenModal,
  ])

  const handleCreateInvoiceItemUsingTickets = () => {
    const body = {
      ticketIds: [
        ...(data?.siteVisits?.map(({ticket_list}) => [
          ...(ticket_list
            ?.filter((ticket) => ticket.isChecked)
            ?.reduce((ticketIds, curr) => {
              return [...ticketIds, curr.id]
            }, [] as Array<number>) ?? []),
        ]) ?? []),
      ].flat(1),
    }

    if (body?.ticketIds?.length > 0) {
      dispatch(
        createInvoiceItemsWithTickets(projectId, invoiceId, body, () => {
          dispatch(getInvoiceById(projectId, invoiceId))
          setOpenModal(false)
        }),
      )
    } else {
      return toast.error(
        'Please select atleast one ticket to create a invoice item !!',
      )
    }
  }

  useEffect(() => {
    projectId &&
      !!openModal &&
      !isDetailsPage &&
      dispatch(getSiteVisitListWithTickets(projectId))
  }, [projectId, dispatch, openModal, isDetailsPage])

  useEffect(() => {
    !!projectId &&
      !!invoiceId &&
      !!openModal &&
      !!isDetailsPage &&
      dispatch(getSiteVisitNotPresentInInvoice(projectId, invoiceId, 1, 10))
  }, [dispatch, invoiceId, projectId, openModal, isDetailsPage])

  return (
    <Modal
      toggleModal={setOpenModal}
      isActive={openModal}
      title={
        !!!isDetailsPage
          ? 'Create Invoice Using Tickets'
          : 'Create Invoice Items Using Tickets'
      }
      size="md"
    >
      <div className="flex justify-between items-center flex-col w-full">
        <div className="w-full px-24 pt-20 min-h-[50vh] flex flex-col gap-20 h-[78vh] overflow-y-auto">
          {!isDetailsPage && (
            <div className="border-1 border-gray-200 rounded-sm">
              <CollapsibleBlock
                topContainerClassName="flex flex-row justify-between items-center bg-blue-100 px-12 py-12 cursor-pointer "
                topContainer={
                  <div className="font-bold text-md">Basic Details</div>
                }
                indicator
              >
                <div className="flex flex-col gap-6 relative px-14 py-10">
                  <div className="flex gap-20">
                    <Input
                      name="title"
                      label="Title"
                      value={data?.title}
                      error={modified?.title && errors?.title}
                      onChange={onChangeHandler}
                      placeholder="Title"
                      required
                    />
                    <div className="">
                      <DateInput
                        prepend={<FaHourglassStart className="text-blue-300" />}
                        name="projectStart"
                        size="sm"
                        placeholder="Project Start"
                        label="Due Date"
                        containerClass="w-full relative mb-18 gap-[0px]"
                        value={String(
                          moment(data?.dueDate).format('YYYY-MM-DD'),
                        )}
                        error={modified?.dueDate && errors?.dueDate}
                        onChange={(date) => {
                          setValue('dueDate', date)
                        }}
                        dateFormat="YYYY-MM-DD"
                        required
                      />
                    </div>
                  </div>
                  <div className="">
                    <Textarea
                      label="Description"
                      name="description"
                      value={data?.description}
                      onChange={onChangeHandler}
                      placeholder="Description"
                      className="resize-none"
                      error={modified?.description && errors?.description}
                    />
                  </div>
                  <div className="">
                    <Textarea
                      label="Footer Notes"
                      name="footerNotes"
                      value={data?.footerNotes}
                      onChange={onChangeHandler}
                      placeholder="Footer Notes"
                      error={modified?.footerNotes && errors?.footerNotes}
                      className="resize-none"
                      required
                    />
                  </div>
                </div>
              </CollapsibleBlock>
            </div>
          )}

          <DescriptionBox title="Site Visit Ticket List">
            <div className="flex w-full gap-20">
              <div className="flex flex-col gap-10 w-1/4">
                <div className="text-md font-bold">Select Site Visit</div>
                <SelectField
                  options={
                    isDetailsPage
                      ? remainingSiteVisitOptions
                      : siteVisitOptionsList
                  }
                  placeholder="Select Site Visit"
                  value={
                    !!data?.selectedSiteVisit
                      ? data?.selectedSiteVisit
                      : undefined
                  }
                  onChangeValue={(data) => {
                    handleSelectSiteVisit(data)
                  }}
                />
              </div>

              <div className="flex flex-col gap-10 w-3/4 bg-gray-100 rounded-md px-20 py-16">
                {!!activeSiteVisit && !!activeSiteVisit?.site_visit_detail ? (
                  activeSiteVisit?.ticket_list.length > 0 ? (
                    <>
                      <div className="flex flex-col gap-8">
                        <Checkbox
                          dataId={`selectAll-${activeSiteVisit?.site_visit_detail?.title}-${activeSiteVisit?.site_visit_detail?.id}`}
                          label={
                            <div className="font-bold text-md">Ticket List</div>
                          }
                          labelClassName="ml-8"
                          isChecked={
                            activeSiteVisit?.site_visit_detail?.isChecked
                          }
                          value={activeSiteVisit?.site_visit_detail?.isChecked}
                          onChange={(value) => {
                            updateActiveSiteVisit(value)
                          }}
                        />
                      </div>

                      <div className="flex flex-col gap-6  h-[200px] overflow-y-auto">
                        {activeSiteVisit?.ticket_list?.map(
                          (item, index: number) => {
                            return (
                              <div
                                className="flex gap-6 items-center"
                                key={index}
                              >
                                <div className="">
                                  <Checkbox
                                    label={item?.title}
                                    labelClassName="ml-8"
                                    isChecked={item?.isChecked}
                                    value={item?.isChecked}
                                    dataId={`ticket-${item?.id}`}
                                    onChange={(value) => {
                                      updateTicketItem(value, item?.id)
                                    }}
                                  />
                                </div>
                                <span>-</span>
                                <ToolTip text="Line Item Name" top>
                                  <div className="font-medium text-black text-sm px-10 bg-blue-150 py-4 rounded-sm w-[max-content]">
                                    {item?.quote_line_item_name}
                                  </div>
                                </ToolTip>
                              </div>
                            )
                          },
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="text-sm text-gray-300 h-[235px]">
                      No tickets found.
                    </div>
                  )
                ) : (
                  <div className="text-sm text-gray-300 h-[235px]">
                    Please select a site visit to show the list of ticket items.
                  </div>
                )}
              </div>
            </div>
            {!!selectedTicketItems &&
              selectedTicketItems?.length > 0 &&
              selectedTicketItems?.some(
                (item) => item?.ticket_item_list?.length > 0,
              ) && (
                <div className="flex flex-col gap-10 mt-20">
                  <div className="font-bold text-md">Selected Items</div>
                  <div className="flex flex-col gap-16">
                    {selectedTicketItems?.map((item) => {
                      return (
                        <div
                          key={item?.site_visit_detail?.id}
                          className="flex flex-col gap-10"
                        >
                          {!!item?.ticket_item_list &&
                            item?.ticket_item_list?.length > 0 && (
                              <React.Fragment>
                                <div className="text-sm text-blue-200 font-bold">
                                  {item?.site_visit_detail?.title}
                                </div>
                                <DataTable
                                  key={item?.site_visit_detail?.id}
                                  columns={columns}
                                  data={item.ticket_item_list}
                                />
                              </React.Fragment>
                            )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
          </DescriptionBox>
        </div>
        <div className="flex justify-end p-18 border-t-1 border-gray-200 w-full">
          <Button
            title="Cancel"
            buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
            size="sm"
            type="button"
            onClick={() => {
              setOpenModal(false)
            }}
          />
          <div className="ml-16">
            <Button
              title={
                !!isDetailsPage
                  ? 'Create Invoice Items'
                  : 'Generate Invoice Using Ticket'
              }
              size="sm"
              type="submit"
              onClick={
                !!!isDetailsPage
                  ? onSubmit
                  : handleCreateInvoiceItemUsingTickets
              }
              loading={
                !!!isDetailsPage
                  ? generateTicketsInvoiceLoading
                  : siteVisitWithTicketsLoading
              }
              isDisabled={
                !!selectedTicketItems
                  ?.map((item) => item?.ticket_item_list?.length > 0)
                  ?.every((itm) => !!!itm)
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const columns: TableColumn<SelectedItemForTable>[] = [
  {
    name: 'Item Name',
    selector: (row) => row.quote_line_item_name,
    cell: (row) => {
      return (
        <div className={'text-blue-300 font-medium'}>
          {row.quote_line_item_name}
        </div>
      )
    },
    sortable: true,
  },
  {
    name: 'Quantity',
    selector: (row) => row.quantity,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.quantity}</div>
    },
  },
  {
    name: 'Price',
    selector: (row) => row.price,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.price}</div>
    },
  },
  {
    name: 'Tax Percent',
    selector: (row) => row.tax_percent,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.tax_percent}</div>
    },
  },
  {
    name: 'Discount Percent',
    selector: (row) => row.discount_percent,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.discount_percent}</div>
    },
  },
  {
    name: 'Total',
    selector: (row) => row.total,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.total}</div>
    },
  },
]

type TicketItem = {
  id: number
  price: number
  title: string
  status: string
  quantity: number
  tax_percent: number
  ticket_number: number
  discount_percent: number
  quote_line_item_id: number
  quote_line_item_name: string
  isChecked: boolean
}

type SiteVisitTypes = Array<{
  site_visit_detail: {
    id: number
    title: string
    status: string
    project_id: number
    description: string
    site_visit_number: number
    isChecked: boolean
  }
  ticket_list: Array<TicketItem>
}>

type SelectedItemForTable = {
  quote_line_item_id: number
  quote_line_item_name: string
  quantity: number
  price: number
  tax_percent: number
  discount_percent: number
  total: number
}

type SiteVisitsWithSelectedItem = Array<{
  site_visit_detail: {
    id: number
    title: string
    status: string
    project_id: number
    description: string
    site_visit_number: number
    isChecked: boolean
  }
  ticket_item_list: Array<SelectedItemForTable>
}>
