function stringToHash(str: string): number {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i)
    hash = (hash << 5) - hash + charCode
  }
  return hash
}

export function generateRandomAvatarColor(name: string): string {
  // Convert the name to a hash value
  const hashValue = stringToHash(name)

  // Use the hash value to determine RGB values
  const red = ((hashValue & 0xff0000) >> 16) % 180 // Ensure red is in the lower range
  const green = ((hashValue & 0x00ff00) >> 8) % 180 // Ensure green is in the lower range
  const blue = (hashValue & 0x0000ff) % 180 // Ensure blue is in the lower range

  // Combine RGB values to form a hex color code
  const color = `#${((1 << 24) | (red << 16) | (green << 8) | blue)
    .toString(16)
    .slice(1)}`

  return color
}
