import {useCallback, useEffect, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useSelector, useDispatch} from 'react-redux'
import {AiFillEye} from 'react-icons/ai'
import {BsFillEyeFill} from 'react-icons/bs'
import {FaTrash} from 'react-icons/fa'

import {DataTable} from 'app/components'
import {ConfirmationModal, ToolTip} from 'app/common'
import {
  deleteProjectFormAction,
  getProjectFormByIdAction,
  getUnattachedFormsAction
} from 'redux-src'
import {FormTicketListModal} from '../formTicketListModal'

interface ProjectFormListType {
  id: number
  status: string
  forms: string
  formId: number
  totalTickets: string
}

export const ProjectFormListTable = ({
  fetchProjectFormById
}: {
  fetchProjectFormById: (page: number, limit: number) => void
}) => {
  const {
    projectFormByIdData,
    projectFormByIdLoading,
    projectFormByIdTotal
  }: RT.FormsReduxType = useSelector((state: any) => state.forms)

  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [newProjectFormList, setNewProjectFormList] =
    useState<Array<ProjectFormListType>>()

  const [projectFormId, setProjectFormId] = useState<number>()

  const {
    navigation: {navigate},
    params
  } = useNavigation()

  const dispatch = useDispatch()
  const {projectId} = params as any

  const remappedProjectFormList = useCallback(() => {
    const finalProjectFormList = projectFormByIdData?.map((form) => {
      return {
        id: form?.project_form?.id,
        status: 'to start',
        forms: form?.project_form?.form_name,
        formId: form?.project_form?.form_id,
        totalTickets: form?.total_tickets
      }
    })

    setNewProjectFormList(finalProjectFormList)
  }, [projectFormByIdData])

  const onDeleteProjectForm = (formId: number) => {
    !!formId &&
      dispatch(
        deleteProjectFormAction(projectId, formId, () => {
          dispatch(getProjectFormByIdAction(projectId))
          dispatch(
            getUnattachedFormsAction(projectId, {
              page: 1,
              limit: 100,
              search: undefined,
              status: 'published'
            })
          )
        })
      )
  }

  useEffect(() => {
    remappedProjectFormList()
  }, [remappedProjectFormList])

  const columns: TableColumn<ProjectFormListType>[] = [
    {
      name: 'Status',
      selector: (row) => row?.status,
      sortable: true,
      cell: (row) => getStatusChip(row?.status as 'to start')
    },
    {
      name: 'Forms',
      selector: (row) => row?.forms,
      sortable: true,
      cell: (row) => (
        <span
          className="text-blue-300  py-4 mr-1 cursor-pointer px-6 leading-none "
          onClick={() => {
            navigate(`/projects/${projectId}/forms/${row.id}/preview`)
          }}
        >
          {row?.forms}
        </span>
      )
    },
    {
      name: 'No. of tickets',
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => (
        <div className="flex items-center justify-between  px-6 py-4  w-full">
          <span className="text-md font-bold">{row.totalTickets}</span>
          <ToolTip text="See all tickets" top>
            <div
              className="px-6 py-4 rounded-sm hover:bg-blue-100 transition-all duration-150 ease-in-out cursor-pointer"
              onClick={() => {
                setModalVisible((prev) => !prev)
                setProjectFormId(row.id)
              }}
            >
              <AiFillEye size={20} color="gray" />
            </div>
          </ToolTip>
        </div>
      )
    },
    {
      name: 'Actions',
      selector: (row) => row.id,
      sortable: true,
      right: true,
      cell: (row) => {
        return (
          <div className="flex justify-end items-center gap-x-10">
            <ToolTip text="View" top>
              <button
                className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
                onClick={() => {
                  navigate(`/projects/${projectId}/forms/${row.id}/preview`)
                }}
              >
                <span className="text-left inline-flex cursor-pointer">
                  <BsFillEyeFill
                    size={12}
                    className="cursor-pointer text-gray-400"
                  />{' '}
                </span>
              </button>
            </ToolTip>

            <ConfirmationModal
              onConfirmClick={(closeModalHandler) => {
                onDeleteProjectForm(row?.id)
                closeModalHandler?.()
              }}
              danger
              label={'Are you sure you want to delete this form template?'}
              displayElement={
                <ToolTip text="Delete" left>
                  <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </button>
                </ToolTip>
              }
              confirmLabel="Delete"
            />
          </div>
        )
      }
    }
  ]

  return (
    <div className="">
      <DataTable
        columns={columns}
        data={newProjectFormList ?? []}
        fetchData={fetchProjectFormById}
        totalRows={projectFormByIdTotal}
        loading={projectFormByIdLoading}
        pagination
      />
      <FormTicketListModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        {...{projectFormId}}
      />
    </div>
  )
}

const getStatusChip = (status: 'to start' | 'published') => {
  const statusColor = {
    'to start': 'bg-orange-400',
    published: 'bg-blue-300'
  }

  return (
    <div
      key={status}
      className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase ${
        statusColor[status as keyof typeof statusColor]
      }`}
      // style={{
      //   backgroundColor: chipColor[value as keyof typeof chipColor]
      // }}
    >
      {status}
    </div>
  )
}
