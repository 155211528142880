export const ToolTip = (props: Com.ToolTipProps) => {
  const {
    children,
    text,
    style,
    containerStyle,
    top,
    right,
    left,
    breakText = false,
    className,
    content
  } = props
  const position = top
    ? {bottom: '100%', left: '50%', transform: 'translateX(-50%)'}
    : right
    ? {left: '100%', top: '50%', transform: 'translateY(-50%)'}
    : left
    ? {right: '100%', top: '50%', transform: 'translateY(-50%)'}
    : {top: '100%', left: '50%', transform: 'translateX(-50%)'}

  return (
    <div className={`tooltip-wrapper ${className}`} style={{...containerStyle}}>
      {children ?? null}
      {content ? (
        <div className="tooltip-text" style={Object.assign(position, style)}>
          {content}
        </div>
      ) : (
        <div
          className="tooltip-text"
          style={Object.assign(position, style)}
          dangerouslySetInnerHTML={{
            __html: breakText ? getBrokenText(text) : text ?? ''
          }}
        />
      )}
    </div>
  )
}

const getBrokenText = (text?: string) => {
  const newText = text?.split(' ').join('<br/>')
  return newText ?? ''
}
