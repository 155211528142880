import {ActiveLink, Auth, useNavigation} from 'react-auth-navigation'
import {useSelector} from 'react-redux'

export const QuotePage = () => {
  const {params} = useNavigation()
  const {quoteId} = params as any

  return (
    <div>
      <h1 className="font-bold text-lg mb-4">Quote</h1>
      <QuoteTab quoteId={quoteId} />

      <div className="border-1 border-gray-200 mb-16 pt-4 min-h-[65vh]">
        <Auth.Screens path={`/projects/:projectId/quote`} />
      </div>
    </div>
  )
}

export const QuoteTabItem = ({
  title,
  path,
  subtitle
}: {
  title: string
  path: string
  subtitle?: string
}) => {
  return (
    <ActiveLink
      exact
      to={path}
      className="block p-12 px-16 bg-gray-100 rounded-t-sm border-1 border-gray-200 border-b-0 relative text-gray-400"
      activeClassName="bg-white after:content-[''] after:absolute after:left-0 after:top-[100%] after:w-[100%] after:h-[3px] after:bg-white after:-translate-y-half font-bold text-gray-500"
    >
      {title}
      <div className="text-[12px] font-normal ">{subtitle}</div>
    </ActiveLink>
  )
}

const QuoteTab = ({quoteId}: {quoteId: string}) => {
  const {params, history} = useNavigation()
  const {projectId} = params as any
  const {quoteTotal} = useSelector((state: any) => state.quotes)

  const isPreliminaryRoute = history.location.pathname.includes('preliminary')

  return (
    <div className="grid grid-cols-3 gap-4 w-full">
      <QuoteTabItem
        title="Quote Versions"
        path={`/projects/${projectId}/quote`}
        subtitle={`${quoteTotal} Versions`}
      />
      <QuoteTabItem
        title="Preliminary"
        path={
          isPreliminaryRoute
            ? history.location.pathname
            : `/projects/${projectId}/quote/preliminary`
        }
      />

      <QuoteTabItem
        title="Purchase Orders"
        path={`/projects/${projectId}/quote/purchase-orders`}
      />
    </div>
  )
}
