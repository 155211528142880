import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'
import {FaLayerGroup} from 'react-icons/fa'
import colors from 'tailwindcss/colors'

import {DynamicSearchSelect} from 'app/components'
import {Button, Input, Modal} from 'app/common'
import {
  CLEAR_ITEM_TYPE,
  createItemAction,
  getItemCategoryListAction,
  getItemList,
  getItemListBySupplierIdAction,
  getItemTypeByIdAction,
  getItemTypeListAction,
  getLabourElementAction,
  getLabourElementAndGroupListAction,
  getMaterialElementAction,
  getMaterialElementGroupById,
  getSupplierAction,
  updateItemAction,
} from 'redux-src'

export const AddItemModal = ({
  addItemModal,
  setAddItemModal,
  mode,
  close,
  itemDetails,
  supplierData,
}: Comp.AddItemModalProps) => {
  const dispatch = useDispatch()

  const {currencySymbol} = useAuth()

  const [itemTypeListMain, setItemTypeListMain] = useState<
    Array<{id: number; name: string}>
  >([])

  const [supplierListMain, setSupplierListMain] = useState([])
  const [labourElementListMain, setLabourElementListMain] = useState([])

  const [data, {onChange, errors, onSubmit, modified, clear, setValue}] =
    useFormInput<{
      name: string
      cost: number
      attributes: object
      fixingValue: number
      materialCost: number
      itemTypeId: number | undefined
      isItemTypeModified: boolean
      itemCategoryId: number | undefined
      supplierId: number | undefined
      labourElementId?: number | undefined
      labourElementGroupId?: number | undefined

      wastagePercentage: number | undefined
      isLabourElementGroup?: boolean
      totalMaterialUnits?: number

      materialElementId?: {
        id: number
        label: string
        value: string
        is_group: boolean
      }
      materialElementGroupId?: {
        id: number
        label: string
        value: string
        is_group: boolean
      }
      isMaterialElementGroup?: boolean
    }>(
      {
        name: '',
        cost: 0,
        fixingValue: 0,
        materialCost: 0,
        itemTypeId: undefined,
        isItemTypeModified: false,
        itemCategoryId: undefined,
        supplierId: undefined,
        labourElementId: undefined,
        labourElementGroupId: undefined,

        attributes: undefined,
        wastagePercentage: undefined,
        isLabourElementGroup: false,

        materialElementGroupId: undefined,
        materialElementId: undefined,
        isMaterialElementGroup: false,
        totalMaterialUnits: 0,
      },
      (data) => {
        const createBody = {
          name: data.name,
          itemTypeId: data.itemTypeId,
          itemCategoryId:
            data.itemCategoryId === 0 ? undefined : data.itemCategoryId,
          attributes: data.attributes,
          fixingValue: data.fixingValue,
          labourElementId: data.isLabourElementGroup
            ? undefined
            : data.labourElementId,
          labourElementGroupId: !data.isLabourElementGroup
            ? undefined
            : data.labourElementGroupId,
          supplierId: data.supplierId,
          materialElementGroupId: data?.isMaterialElementGroup
            ? data?.materialElementGroupId?.id
            : undefined,
          materialElementId: data?.isMaterialElementGroup
            ? undefined
            : data?.materialElementId?.id,
          totalMaterialUnits: data?.isMaterialElementGroup
            ? undefined
            : data?.totalMaterialUnits ?? 0,
        }

        mode === 'add'
          ? dispatch(
              createItemAction(createBody, () => {
                dispatch({type: CLEAR_ITEM_TYPE.SUCCESS})
                dispatch(getItemList())
                clear()
                // setErrors({})
                close()
                dispatch(getItemTypeListAction())
                dispatch(getSupplierAction())
                dispatch(
                  getItemListBySupplierIdAction(Number(data.supplierId), {
                    limit: 100,
                  }),
                )
              }),
            )
          : dispatch(
              updateItemAction(
                itemDetails && itemDetails.id,
                createBody,
                () => {
                  dispatch({type: CLEAR_ITEM_TYPE.SUCCESS})
                  dispatch(getItemList())

                  dispatch(
                    getItemListBySupplierIdAction(Number(data.supplierId), {
                      limit: 100,
                    }),
                  )

                  close()
                },
              ),
            )
      },

      (data) => {
        const errors: any = {}

        if (data.name.length === 0) {
          errors.name = "Item name can't be empty!"
        }
        if (!data?.totalMaterialUnits && !data?.isMaterialElementGroup) {
          errors.totalMaterialUnits = `Material Units cannot be empty !!`
        }
        if (!data.fixingValue) {
          errors.fixingValue = "Fixing cost can't be empty!"
        }

        if (data.itemTypeId === undefined) {
          errors.itemTypeId = "Item type  can't be empty! !"
        }
        if (!data.supplierId) {
          errors.supplierId = "Supplier can't be empty!"
        }
        if (!data.labourElementId && !data.labourElementGroupId) {
          errors.labourElementId = "Labour Element can't be empty!"
        }
        if (!data?.materialElementId && !data?.materialElementGroupId) {
          errors.materialElementId = "Material Element can't be empty !"
        }
        return errors
      },
    )

  const [newItemTypeList, setNewItemTypeList] = useState<Com.SelectOption[]>()

  const [newItemCategoryList, setNewItemCategoryList] =
    useState<Com.SelectOption[]>()

  const [newItemSuppplierList, setNewSupplierList] =
    useState<Com.SelectOption[]>()

  const [newLabourElementList, setNewLabourElementList] = useState<
    {
      id: number
      label: string
      value: string
      is_group: boolean
    }[]
  >()

  const {supplierList: listFromApi}: RT.SupplierReduxType = useSelector(
    (state: any) => state.suppliers,
  )

  const {labourElementAndGroupList}: RT.LabourElementReduxType = useSelector(
    (state: any) => state.labourElements,
  )

  const {
    itemCategoryList,
    itemTypeDetails,
    itemTypeList,
  }: RT.ItemTypeReduxType = useSelector((state: any) => state.itemTypes)

  const {
    materialElements: materialElementsFromApi,
    materialElementGroupById: materialElementGroupDetailsFromApi,
  }: RT.MaterialElementReduxType = useSelector(
    (state: any) => state.materialElement,
  )

  // api call for selection field value
  useEffect(() => {
    const itemTypeData = itemDetails?.item_type_details

    const filteredItemTypeList = itemTypeList?.filter((item) => {
      return item?.id === itemTypeData?.id
    })

    if (!!itemDetails && filteredItemTypeList?.length === 0) {
      setItemTypeListMain([
        ...itemTypeList,
        {
          name: itemDetails?.item_type_details?.name,
          id: itemDetails?.item_type_details?.id,
        },
      ])
    } else {
      setItemTypeListMain([...itemTypeList])
    }
  }, [itemTypeList, itemDetails])

  useEffect(() => {
    const labourElementData = itemDetails?.labourElementId
      ? itemDetails?.labour_element_details
      : itemDetails?.labour_element_group_details

    const filteredLabourElementList = labourElementAndGroupList?.filter(
      (item) => {
        return item?.id === labourElementData?.id
      },
    )

    if (!!itemDetails && filteredLabourElementList?.length === 0) {
      setLabourElementListMain([
        ...labourElementAndGroupList,
        {
          name: itemDetails?.labourElementId
            ? itemDetails?.labour_element_details?.name
            : itemDetails?.labour_element_group_details?.name,
          id: itemDetails?.labourElementId
            ? itemDetails?.labourElementId
            : itemDetails?.labourElementGroupId,
          is_group: itemDetails?.labourElementId
            ? itemDetails?.labour_element_details?.is_group
            : itemDetails?.labour_element_group_details?.is_group,
        },
      ])
    } else {
      setLabourElementListMain(labourElementAndGroupList)
    }
  }, [labourElementAndGroupList, itemDetails])

  const [modifiedSupplierList, setModifiedSupplierList] =
    useState<Array<{id: number; name: string; company_id: number}>>()

  useEffect(() => {
    const modifiedSupplier = listFromApi.map((item, index) => {
      return item.supplier_details
    })
    setModifiedSupplierList(modifiedSupplier)
  }, [listFromApi, dispatch])

  useEffect(() => {
    const supplierData = itemDetails?.supplier_details

    const filteredSupplierList = modifiedSupplierList?.filter((item) => {
      return item?.id === supplierData?.id
    })

    if (!!itemDetails && filteredSupplierList?.length === 0) {
      setSupplierListMain([
        ...modifiedSupplierList,
        {
          name: itemDetails?.supplierName,
          id: itemDetails?.supplier_details?.id,
        },
      ])
    } else {
      setSupplierListMain(modifiedSupplierList)
    }
  }, [modifiedSupplierList, itemDetails, dispatch])

  const {updateItemLoading, createItemLoading}: RT.ItemReduxType = useSelector(
    (state: any) => state.itemManagement,
  )

  // // for prefilling of data
  const prePopulatedValue = useCallback(() => {
    if (itemDetails) {
      setValue('name', itemDetails?.name ?? '')
      setValue('cost', itemDetails?.cost ?? 0)
      setValue('attributes', itemDetails?.attributes)
      setValue('fixingValue', itemDetails?.fixingValue ?? 0)
      setValue('materialCost', itemDetails?.materialCost ?? 0)
      setValue('itemTypeId', itemDetails?.itemTypeId)
      setValue('itemCategoryId', itemDetails?.itemCategoryId)
      setValue('supplierId', itemDetails?.supplier_details?.id)
      setValue('labourElementId', itemDetails?.labourElementId)
      setValue('labourElementGroupId', itemDetails?.labourElementGroupId)
      // setValue('wastagePercentage', itemDetails?.wastagePercentage)
      setValue('isLabourElementGroup', !!itemDetails?.labourElementGroupId)

      itemDetails?.materialElementGroupDetails &&
        setValue('materialElementGroupId', {
          id: itemDetails?.materialElementGroupDetails?.id,
          label: itemDetails?.materialElementGroupDetails?.name,
          value: itemDetails?.materialElementGroupDetails?.name,
          is_group: true,
        })

      itemDetails?.materialElementDetails &&
        setValue('materialElementId', {
          id: itemDetails?.materialElementDetails?.id,
          label: itemDetails?.materialElementDetails?.name,
          value: itemDetails?.materialElementDetails?.name,
          is_group: false,
        })
      setValue('isMaterialElementGroup', !!itemDetails?.materialElementGroupId)
      setValue('totalMaterialUnits', itemDetails?.totalMaterialUnits)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDetails])

  console.log({itemDetails})

  useEffect(() => {
    prePopulatedValue()
  }, [prePopulatedValue])

  // remapped dataitemTypeListMain

  const remappedSupplierList = useCallback(() => {
    const finalSupplierList = supplierListMain?.map((supplier) => {
      return {
        id: supplier && supplier.id,
        label: supplier && supplier.name,
        value: supplier && supplier.name,
      }
    })

    setNewSupplierList(finalSupplierList)
  }, [supplierListMain])

  const remappedLabourElementList = useCallback(() => {
    const finalLabourElementList = labourElementListMain?.map(
      (labourElement) => {
        return {
          id: labourElement && labourElement.id,
          label: labourElement && labourElement.name,
          value: labourElement && labourElement.name,
          is_group: labourElement && labourElement.is_group,
        }
      },
    )

    setNewLabourElementList(finalLabourElementList)
  }, [labourElementListMain])

  const remappedItemTypeList = useCallback(() => {
    const finalItemTypeList = itemTypeListMain?.map((itemType) => {
      return {
        id: itemType.id,
        label: itemType.name,
        value: itemType.name,
      }
    })

    setNewItemTypeList(finalItemTypeList)
  }, [itemTypeListMain])

  const remappedItemCategoryList = useCallback(() => {
    const finalItemCategoryList = itemCategoryList?.map(
      (category: Api.ItemCategoryIndividual) => {
        return {
          id: category.id,
          label: category.name,
          value: category.name,
        }
      },
    )

    setNewItemCategoryList(finalItemCategoryList)
  }, [itemCategoryList])

  useEffect(() => {
    if (!!data?.itemTypeId) {
      dispatch({type: CLEAR_ITEM_TYPE.SUCCESS})
      dispatch(getItemCategoryListAction(data.itemTypeId))
      dispatch(getItemTypeByIdAction(String(data.itemTypeId)))
      dispatch(getLabourElementAction())
      dispatch(getLabourElementAndGroupListAction())
    }
  }, [data?.itemTypeId, dispatch])

  useEffect(() => {
    if (supplierData) {
      setValue('supplierId', supplierData?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierData, dispatch])

  useEffect(() => {
    remappedSupplierList()
  }, [remappedSupplierList])

  useEffect(() => {
    remappedItemTypeList()
  }, [remappedItemTypeList])

  useEffect(() => {
    remappedLabourElementList()
  }, [remappedLabourElementList])

  useEffect(() => {
    remappedItemCategoryList()
  }, [remappedItemCategoryList])

  useEffect(() => {
    // if (data.attributes)
    const attributes = itemDetails?.attributes
    const newAttributesOnItemTypeChange = itemTypeDetails?.attributes?.reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: '',
        }
      },
      {},
    )
    if (attributes && !data.isItemTypeModified) {
      setValue('attributes', {...newAttributesOnItemTypeChange, ...attributes})
      return
    } else setValue('attributes', newAttributesOnItemTypeChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTypeDetails])

  const tableStyle = {
    style: {
      minHeight: '40px !important',
      backgroundColor: '#f5f5f5',
    },
  }

  useEffect(() => {
    !!data?.materialElementGroupId &&
      dispatch(
        getMaterialElementGroupById(data?.materialElementGroupId?.id, () => {}),
      )
  }, [data?.materialElementGroupId, dispatch])

  useEffect(() => {
    !!data?.materialElementId &&
      dispatch(getMaterialElementAction({page: 1, limit: 100, search: ''}))
  }, [data?.materialElementId, dispatch])

  const selectedMaterialElementDetails = useMemo(() => {
    const selectedMaterialDetails = materialElementsFromApi?.find(
      ({material_element_details: {id}}) => id === data?.materialElementId?.id,
    )

    return selectedMaterialDetails
  }, [data?.materialElementId, materialElementsFromApi])

  return (
    <Modal
      isActive={addItemModal}
      onClose={() => {
        setValue('attributes', undefined)
        clear()

        dispatch({type: CLEAR_ITEM_TYPE.SUCCESS})
        close()
      }}
      toggleModal={setAddItemModal}
      title={mode === 'edit' ? 'Update Combination' : 'Add Combination'}
      size="lg"
    >
      <form
        id="form-item-modal"
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(e)
        }}
      >
        <div className="pt-[18px] px-[20px]">
          <div>
            <div className="flex justify-between ">
              <span
                className="flex flex-col gap-6 relative"
                style={{width: '30%'}}
              >
                <span>
                  <span>Name</span>{' '}
                  <span className="text-red-950" style={{color: 'red'}}>
                    *
                  </span>
                </span>
                <Input
                  size="sm"
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={onChange}
                  error={modified.name && errors.name}
                  placeholder="Name"
                  required
                />
              </span>

              <span
                className="flex flex-col gap-6 relative"
                style={{width: '30%'}}
              >
                <span>
                  <span>Item Type</span>{' '}
                  <span className="text-red-950" style={{color: 'red'}}>
                    *
                  </span>
                </span>

                <DynamicSearchSelect
                  actionUrl="/item/type"
                  placeholder="Search for Item Type"
                  remapOptions={(data: Api.ItemTypeIndividual[]) => {
                    return data?.map((item) => {
                      return {
                        id: item.id,
                        label: item.name,
                        value: item.name,
                      }
                    })
                  }}
                  value={newItemTypeList?.find((item) => {
                    return item?.id === data.itemTypeId
                  })}
                  onChangeValue={(value) => {
                    setValue('itemTypeId', value.id)
                    setValue('isItemTypeModified', true)
                    setValue('itemCategoryId', 0)
                  }}
                />

                {errors.itemTypeId && modified.itemTypeId && (
                  <span style={{color: 'red'}}>{errors.itemTypeId}</span>
                )}
              </span>

              <span
                className="flex flex-col gap-6 relative"
                style={{width: '30%'}}
              >
                <DynamicSearchSelect
                  label="Item Category"
                  actionUrl={`item/category/${data.itemTypeId}`}
                  remapOptions={(data: Api.ItemCategoryIndividual[]) => {
                    return data?.map((item) => {
                      return {
                        id: item.id,
                        label: item.name,
                        value: item.name,
                      }
                    })
                  }}
                  value={
                    data.itemCategoryId === 0
                      ? {
                          id: 0,
                          label: 'Select Category',
                          value: 'Select Category',
                        }
                      : newItemCategoryList?.find((item) => {
                          return item?.id === data.itemCategoryId
                        })
                  }
                  cacheOptions
                  additional={{dependencies: [data.itemTypeId]}}
                  defaultOptions
                  onChangeValue={(value) => {
                    setValue('itemCategoryId', value.id)
                  }}
                  placeholder={'Select Item Category'}
                />
              </span>
            </div>

            <div className="flex justify-between ">
              <div
                className="flex flex-col gap-6 relative"
                style={{width: '30%'}}
              >
                <span>
                  <span>Supplier Name</span>{' '}
                  <span className="text-red-950" style={{color: 'red'}}>
                    *
                  </span>
                </span>

                <div className="relative">
                  <DynamicSearchSelect
                    actionUrl="/suppliers"
                    remapOptions={(data: Api.SupplierType[]) => {
                      return data?.map((item) => {
                        return {
                          id: item.supplier_details.id,
                          label: item.supplier_details.name,
                          value: item.supplier_details.name,
                        }
                      })
                    }}
                    value={newItemSuppplierList?.find((item) => {
                      return item?.id === data.supplierId
                    })}
                    onChangeValue={(value) => {
                      setValue('supplierId', value.id)
                    }}
                    placeholder={'Select Supplier'}
                    isDisabled={
                      supplierData && Object.keys(supplierData).length > 0
                    }
                  />

                  {errors.supplierId && modified.supplierId && (
                    <span style={{color: 'red'}}>{errors.supplierId}</span>
                  )}
                </div>
              </div>

              <div
                className="flex flex-col gap-6 relative"
                style={{width: '30%'}}
              >
                <span>
                  <span>Labour Elements</span>{' '}
                  <span className="text-red-950" style={{color: 'red'}}>
                    *
                  </span>
                </span>
                <div>
                  <DynamicSearchSelect
                    actionUrl="/labour-elements/labour-elements-and-group-list"
                    remapOptions={(
                      data: Api.LabourElementAndGroupIndividual[],
                    ) => {
                      return data?.map((item) => {
                        return {
                          id: item.id,
                          label: item.name,
                          value: item.name,
                          is_group: item.is_group,
                        }
                      })
                    }}
                    value={newLabourElementList?.find((item) => {
                      return data.labourElementId
                        ? item?.id === data.labourElementId
                        : item?.id === data.labourElementGroupId
                    })}
                    formatOptionLabel={(data: any) => {
                      if (data.is_group) {
                        return (
                          <div className="flex gap-8 items-center justify-between">
                            <p>{data.label}</p>
                            <FaLayerGroup />
                          </div>
                        )
                      } else {
                        return <div>{data.label}</div>
                      }
                    }}
                    onChangeValue={(value) => {
                      if (value.is_group) {
                        setValue('labourElementGroupId', value.id)
                        setValue('labourElementId', undefined)
                        setValue('isLabourElementGroup', true)
                      } else if (!value.is_group) {
                        setValue('labourElementGroupId', undefined)
                        setValue('labourElementId', value.id)
                        setValue('isLabourElementGroup', false)
                      }
                    }}
                    getOptionValue="value"
                    placeholder={'Select Labour Element'}
                  />

                  {errors.labourElementId && modified.labourElementId && (
                    <span style={{color: 'red'}}>{errors.labourElementId}</span>
                  )}
                </div>
              </div>

              <div
                className="flex flex-col gap-6 relative"
                style={{width: '30%'}}
              >
                <span>
                  <span>Material</span>{' '}
                  <span className="text-red-950" style={{color: 'red'}}>
                    *
                  </span>
                </span>
                <div>
                  <DynamicSearchSelect
                    actionUrl="/material-elements/with-groups"
                    remapOptions={(data: Api.MaterialIsGroup[]) => {
                      return data?.map((item) => {
                        return {
                          id: item.id,
                          label: item.name,
                          value: item.name,
                          is_group: item.is_group,
                        }
                      })
                    }}
                    value={
                      data?.isMaterialElementGroup
                        ? data?.materialElementGroupId
                        : data?.materialElementId
                    }
                    formatOptionLabel={(data: any) => {
                      if (data.is_group) {
                        return (
                          <div className="flex gap-8 items-center justify-between">
                            <p>{data.label}</p>
                            <FaLayerGroup />
                          </div>
                        )
                      } else {
                        return <div>{data.label}</div>
                      }
                    }}
                    onChangeValue={(value) => {
                      if (value.is_group) {
                        setValue('materialElementGroupId', value)
                        setValue('materialElementId', undefined)
                        setValue('isMaterialElementGroup', true)
                      } else if (!value.is_group) {
                        setValue('materialElementGroupId', undefined)
                        setValue('materialElementId', value)
                        setValue('isMaterialElementGroup', false)
                      }
                    }}
                    getOptionValue="value"
                    placeholder={'Select Material'}
                  />

                  {errors.materialElementId && modified.materialElementId && (
                    <span style={{color: 'red'}}>
                      {errors.materialElementId}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {!!data?.materialElementId && (
              <div className="flex flex-col gap-6 py-6">
                <div className="text-black font-bold text-sm">
                  Material Units <span className="text-red-300 pl-6">*</span>
                </div>
                <div className="flex gap-10">
                  <span className="text-black font-bold text-sm pt-6">
                    {data?.materialElementId?.label} :
                  </span>
                  <div className="w-[60px]">
                    <Input
                      value={data?.totalMaterialUnits}
                      name="materialUnits"
                      type="number"
                      onChange={(e) => {
                        setValue('totalMaterialUnits', e.target.value)
                      }}
                      error={
                        errors['totalMaterialUnits'] &&
                        modified['totalMaterialUnits']
                      }
                      className="w-full"
                      placeholder="Total Material Units"
                    />
                  </div>
                  <span className="italic pt-6">
                    [
                    {
                      selectedMaterialElementDetails?.material_element_details
                        ?.unit_name
                    }
                    ]
                  </span>
                </div>
              </div>
            )}

            {!!data?.materialElementGroupId && (
              <div className="flex flex-col gap-6 py-6 mt-10">
                <div className="text-black font-bold text-sm">
                  Material Units <span className="text-red-300 pl-6">*</span>
                </div>
                <div className="flex gap-18">
                  {materialElementGroupDetailsFromApi?.material_elements?.map(
                    ({details, total_units}) => {
                      return (
                        <div
                          className="flex gap-10 items-center"
                          key={details?.id}
                        >
                          <span className="text-black font-bold text-sm">
                            {details?.name} :
                          </span>
                          <div className="w-[max-content] text-sm">
                            <strong className="text-red-500">
                              {total_units}
                            </strong>{' '}
                            <i>{details?.unit_name}</i>
                          </div>
                        </div>
                      )
                    },
                  )}
                </div>
              </div>
            )}

            <div className="mb-6 mt-6">
              <span className="font-bold">Cost</span>
              <div className="flex justify-between">
                <span className="flex flex-col mb-6" style={{width: '28%'}}>
                  <span>
                    <span>Fixing Cost</span>{' '}
                    <span className="text-red-950" style={{color: 'red'}}>
                      *
                    </span>
                  </span>
                  <Input
                    className="[&>p]:bottom-0"
                    prepend={`${currencySymbol}`}
                    inputType="decimal"
                    name="fixingValue"
                    value={data.fixingValue}
                    onChange={onChange}
                    placeholder="Fixing Cost"
                    defaultValue="0"
                    min="0"
                    // required
                    error={modified.fixingValue && errors.fixingValue}
                  />
                </span>

                <div
                  className="flex flex-col mb-6 gap-4"
                  style={{
                    width: '28%',
                  }}
                >
                  <span>
                    <span>Material Cost</span>{' '}
                    <span className="text-red-950" style={{color: 'red'}}>
                      *
                    </span>
                  </span>
                  {data?.isMaterialElementGroup ? (
                    <div className="text-black text-sm">
                      {currencySymbol}
                      {(
                        materialElementGroupDetailsFromApi
                          ?.material_element_group_details?.total_cost ?? 0
                      )?.toFixed(2)}
                    </div>
                  ) : (
                    <div className="text-black text-sm">
                      {currencySymbol}
                      {(
                        selectedMaterialElementDetails?.material_element_details
                          ?.cost ?? 0
                      )?.toFixed(2)}
                    </div>
                  )}
                </div>

                <span className="flex flex-col gap-6" style={{width: '18%'}}>
                  <span className="font-bold">
                    Wastage (
                    {mode === 'add' && !data.itemTypeId
                      ? ''
                      : itemTypeDetails?.wastage_per}
                    %)
                  </span>

                  <div className="font-bold">
                    {currencySymbol}{' '}
                    {(
                      (Number(
                        data?.isMaterialElementGroup
                          ? materialElementGroupDetailsFromApi
                              ?.material_element_group_details?.total_cost ?? 0
                          : selectedMaterialElementDetails
                              ?.material_element_details?.cost ?? 0,
                      ) *
                        Number(itemTypeDetails?.wastage_per ?? '0')) /
                      100
                    ).toFixed(2)}
                  </div>
                </span>

                <span className="flex flex-col gap-6" style={{width: '18%'}}>
                  <span className="font-bold">Total Cost </span>

                  <div className="font-bold">
                    {currencySymbol}
                    {(
                      Number(data.fixingValue) +
                      Number(
                        data?.isMaterialElementGroup
                          ? materialElementGroupDetailsFromApi
                              ?.material_element_group_details?.total_cost ?? 0
                          : selectedMaterialElementDetails
                              ?.material_element_details?.cost ?? 0,
                      ) +
                      (Number(
                        data?.isMaterialElementGroup
                          ? materialElementGroupDetailsFromApi
                              ?.material_element_group_details?.total_cost ?? 0
                          : selectedMaterialElementDetails
                              ?.material_element_details?.cost ?? 0,
                      ) *
                        Number(itemTypeDetails?.wastage_per ?? '0')) /
                        100
                    )?.toFixed(2)}
                  </div>
                </span>
              </div>
            </div>
          </div>

          {/* attribute section */}

          <div className="-mt-10 mb-10">
            <span className="font-bold">Attributes </span>
            <div
              className="flex flex-col border-1 border-[#e5e7eb] mt-4"
              style={{
                width: '50%',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
            >
              <div className="flex w-full justify-between bg-[#f5f5f5]">
                <p
                  className=" font-bold  w-1/2 border-1 border-gray-200"
                  style={{padding: '6px 10px'}}
                >
                  Name
                </p>
                <p
                  className=" font-bold  w-1/2 border-1 border-gray-200"
                  style={{padding: '6px 10px'}}
                >
                  Value
                </p>
              </div>
              <div className="[&>*:nth-child(even)]:bg-[#f5f5f5]">
                {data.attributes ? (
                  Object.entries(data.attributes)?.map(([key, value]) => {
                    return (
                      <div key={key} className="flex">
                        <div
                          style={{
                            padding: '6px 10px',
                          }}
                          className="border-1 border-gray-200  w-1/2"
                        >
                          <p className="capitalize">{key}</p>
                        </div>
                        <div
                          style={{
                            padding: '6px 10px',
                          }}
                          className="flex items-center px-2.5 w-1/2 border-1 border-gray-200"
                        >
                          <input
                            style={{
                              outline: 'none',
                              borderBottom: `1px solid ${colors.gray[300]}`,
                            }}
                            type="text"
                            name={key}
                            value={value}
                            onChange={(e: any) => {
                              setValue('attributes', {
                                ...data.attributes,
                                [key]: e.target.value,
                              })
                            }}
                            className="bg-transparent"
                            placeholder="Enter value"
                          />
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div
                    className="w-full   bg-gray-200 text-gray-200 flex items-center justify-center h-[30px]
              
                "
                    style={tableStyle.style}
                  >
                    <p className=" text-black">No records available!</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="border border-t-1 border-gray-200 px-20 py-10">
            <div className="flex justify-end gap-10">
              <Button
                className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
                onClick={() => {
                  clear()

                  setValue('attributes', undefined)

                  close()

                  dispatch({type: CLEAR_ITEM_TYPE.SUCCESS})
                }}
              >
                Cancel
              </Button>
              <Button
                loading={
                  mode === 'edit' ? updateItemLoading : createItemLoading
                }
                form="form-item-modal"
                type="submit"
                className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
              >
                {mode !== 'edit' ? 'Save' : 'Edit'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}
