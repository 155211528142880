import {
  CREATE_LABOUR_ELEMENT_SETTING,
  GET_LABOUR_ELEMENT_SETTING,
  UPDATE_LABOUR_ELEMENT_SETTING,
  GET_LABOUR_ELEMENT_UNITS,
  GET_LABOUR_ELEMENT_GROUP,
  CREATE_LABOUR_ELEMENT_GROUP,
  UPDATE_LABOUR_ELEMENT_GROUP,
  GET_LABOUR_GROUP_BY_ID,
  GET_LABOUR_ELEMENT_FOR_SELECT_FIELD,
  GET_LABOUR_ELEMENT_AND_GROUP_LIST,
} from '../actions'

const initalState: RT.LabourElementReduxType = {
  create_labour_element_loading: false,
  data: null,

  get_labour_element_loading: false,
  labourElementList: undefined,
  labourElementTotal: 0,

  update_labour_element_loading: false,

  get_unit_list_loading: false,
  unitList: undefined,
  unitListTotal: 0,

  create_labour_element_group_loading: false,
  get_labour_element_group_loading: false,
  labourElementGroupList: undefined,
  labourElementGroupTotal: 0,

  update_labour_element_group_loading: false,

  get_labour_group_by_id_loading: false,
  individualLabourGroup: undefined,

  labourElementListForSF: undefined,
  labourElementAndGroupList: undefined,
  getlabourElementAndGroupListLoading: false,
}

export function labourElementReducer(state = initalState, action: any) {
  const { type, payload } = action

  switch (type) {
    case CREATE_LABOUR_ELEMENT_SETTING.LOADING:
      return { ...state, create_labour_element_loading: true }
    case CREATE_LABOUR_ELEMENT_SETTING.SUCCESS:
      return { ...state, create_labour_element_loading: false, data: payload }
    case CREATE_LABOUR_ELEMENT_SETTING.ERROR:
      return { ...state, create_labour_element_loading: false }

    case GET_LABOUR_ELEMENT_SETTING.LOADING:
      return { ...state, get_labour_element_loading: true }
    case GET_LABOUR_ELEMENT_SETTING.SUCCESS:
      return {
        ...state,
        get_labour_element_loading: false,
        labourElementList: payload?.rows,
        labourElementTotal: payload?.total,
      }
    case GET_LABOUR_ELEMENT_SETTING.ERROR:
      return { ...state, get_labour_element_loading: false }

    case UPDATE_LABOUR_ELEMENT_SETTING.LOADING:
      return { ...state, update_labour_element_loading: true }
    case UPDATE_LABOUR_ELEMENT_SETTING.SUCCESS:
      return { ...state, update_labour_element_loading: false }
    case UPDATE_LABOUR_ELEMENT_SETTING.ERROR:
      return { ...state, update_labour_element_loading: false }

    case GET_LABOUR_ELEMENT_UNITS.LOADING:
      return { ...state, get_unit_list_loading: true }
    case GET_LABOUR_ELEMENT_UNITS.SUCCESS:
      return {
        ...state,
        get_unit_list_loading: false,
        unitList: payload.rows,
        unitListTotal: payload.total,
      }
    case GET_LABOUR_ELEMENT_UNITS.ERROR:
      return { ...state, get_unit_list_loading: false }

    case CREATE_LABOUR_ELEMENT_GROUP.LOADING:
      return { ...state, create_labour_element_group_loading: true }
    case CREATE_LABOUR_ELEMENT_GROUP.SUCCESS:
      return {
        ...state,
        create_labour_element_group_loading: false,
      }
    case CREATE_LABOUR_ELEMENT_GROUP.ERROR:
      return { ...state, create_labour_element_group_loading: false }

    case GET_LABOUR_ELEMENT_GROUP.LOADING:
      return { ...state, get_labour_element_group_loading: true }
    case GET_LABOUR_ELEMENT_GROUP.SUCCESS:
      return {
        ...state,
        get_labour_element_group_loading: false,
        labourElementGroupList: payload.rows,
        labourElementGroupTotal: payload.total,
      }
    case GET_LABOUR_ELEMENT_GROUP.ERROR:
      return { ...state, get_labour_element_group_loading: false }

    case UPDATE_LABOUR_ELEMENT_GROUP.LOADING:
      return { ...state, update_labour_element_group_loading: true }
    case UPDATE_LABOUR_ELEMENT_GROUP.SUCCESS:
      return {
        ...state,
        update_labour_element_group_loading: false,
      }
    case UPDATE_LABOUR_ELEMENT_GROUP.ERROR:
      return { ...state, update_labour_element_group_loading: false }

    case GET_LABOUR_GROUP_BY_ID.LOADING:
      return { ...state, get_labour_group_by_id_loading: true }
    case GET_LABOUR_GROUP_BY_ID.SUCCESS:
      return {
        ...state,
        get_labour_group_by_id_loading: false,
        individualLabourGroup: payload,
      }
    case GET_LABOUR_GROUP_BY_ID.ERROR:
      return { ...state, get_labour_group_by_id_loading: false }

    case GET_LABOUR_ELEMENT_FOR_SELECT_FIELD.LOADING:
      return { ...state, get_labour_group_by_id_loading: true }
    case GET_LABOUR_ELEMENT_FOR_SELECT_FIELD.SUCCESS:
      return {
        ...state,
        get_labour_group_by_id_loading: false,
        labourElementListForSF: payload,
      }
    case GET_LABOUR_ELEMENT_FOR_SELECT_FIELD.ERROR:
      return { ...state, get_labour_group_by_id_loading: false }

    case GET_LABOUR_ELEMENT_AND_GROUP_LIST.LOADING:
      return { ...state, getlabourElementAndGroupListLoading: true }
    case GET_LABOUR_ELEMENT_AND_GROUP_LIST.SUCCESS:
      return {
        ...state,
        getlabourElementAndGroupListLoading: false,
        labourElementAndGroupList: payload.rows,
        labourElementAndGroupTotal: payload.total,
      }
    case GET_LABOUR_ELEMENT_AND_GROUP_LIST.ERROR:
      return { ...state, getlabourElementAndGroupListLoading: false }

    default:
      return state
  }
}
