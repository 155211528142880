import {useIndividualFormElement} from 'hooks'
import {useAuth} from 'react-auth-navigation'

interface ParagraphPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

export const ParagraphPreview = ({id, type}: ParagraphPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div>{individualElement?.content?.value}</div>
    </div>
  ) : (
    <></>
  )
}
