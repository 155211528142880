import React from 'react'

import {DropdownWithPortal} from './dropdownWithPortal.component'
import {DropdownWithoutPortal} from './dropdownWithoutPortal.common'

import {DropdownProps} from './dropdown.type'
interface DropdownPropsLite extends DropdownProps {
  withPortal?: boolean
  onActiveChange?: (active: boolean) => void
}

export const Dropdown = ({withPortal, ...rest}: DropdownPropsLite) => {
  return (
    <React.Fragment>
      {withPortal ? (
        <DropdownWithPortal {...rest} />
      ) : (
        <DropdownWithoutPortal {...rest} />
      )}
    </React.Fragment>
  )
}
