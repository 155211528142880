import clsx from 'clsx'
import { ActiveLink } from 'react-auth-navigation'

export interface NavLinkProps {
  to: string
  title: string
  premium?: boolean
}

/**
 * NavLink component returns the link component to navigate
 */
export const NavLink = ({ to, title, premium }: NavLinkProps) => {
  return (
    <ActiveLink
      to={to}
      className={clsx(
        'transition relative block duration-150 lg:mx-10 md:mx-6 hover:text-blue-200 whitespace-nowrap',
        premium ? 'text-gray-300' : 'text-white'
      )}
      activeClassName="activebar"
    >
      {title}
    </ActiveLink>
  )
}
