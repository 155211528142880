import {FaBook, FaPlay} from 'react-icons/fa'

export const SettingsHeader = ({
  title,
  overview,
  help,
  prepend,
  append,
  subHeader
}: {
  title: React.ReactNode
  overview?: boolean
  help?: boolean
  prepend?: React.ReactNode
  subHeader?: React.ReactNode
  append?: React.ReactNode
}) => {
  return (
    <div>
      <div className="flex justify-between items-center mb-10">
        <h3 className="font-bold text-lg">{title}</h3>

        <div className="flex items-center justify-between font-light cursor-pointer text-[14px]">
          {prepend}
          {overview && (
            <div>
              <span className="flex text-blue-300">
                <FaPlay className="mx-4 my-[2px]" size={14} />
                Watch overview{' '}
              </span>
            </div>
          )}
          {help && (
            <div>
              <span className="ml-20 flex text-blue-300">
                {' '}
                <FaBook className="mx-4 my-[2px]" size={14} />
                Need Help?
              </span>
            </div>
          )}
          {append}
        </div>
      </div>
      <div className="text-sm my-6">{subHeader}</div>
    </div>
  )
}
