import {errorHandler} from '../../utils'
import {api, APIS} from '../../config'
import {
  LOGIN,
  LOGOUT,
  PASSWORD,
  CHANGE_PASSWORD,
  FORGET_PASSWORD,
  RESET_PASSWORD
} from '.'
import {userType} from 'app/userRoles.app'
import {toast} from 'app/common'

/**
 * Login function handles the login of a user
 */
export function login(
  body: {email: string; password: string},
  callback?: (clientId: any, role: any, token: any) => void
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: LOGIN.LOADING})

      res = await api<any>(APIS.login, 'POST', body)

      //
      if (res.data !== undefined) {
        var {
          success,
          data: {data: successData, message}
        } = res.data

        if (success) {
          const {
            user: {id, role},
            token
          } = successData

          toast.success(message)
          dispatch({type: LOGIN.SUCCESS})
          callback(id, role || userType.FULL_USER, token)
          return 1
        } else {
          dispatch({type: LOGIN.ERROR, payload: message})
          toast.error(res.data.message)
          return 0
        }
      }
      //
    } catch (message) {
      dispatch({type: LOGIN.ERROR, payload: message})
      errorHandler(res.data, toast)

      return 0
    }
    return 0
  }
}

export function logoutAction(callback: any, toast?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: LOGOUT.LOADING})
      res = await api<any>(APIS.logout)

      const {success, data} = res.data

      if (success) {
        dispatch({type: LOGOUT.SUCCESS})
        callback && callback()
        toast({
          message: data.message,
          type: 'success'
        })
        return 1
      } else {
        dispatch({type: LOGOUT.ERROR})
        toast({
          message: data.message,
          type: 'error'
        })
        return 0
      }
    } catch (e) {
      dispatch({type: LOGOUT.ERROR})
      toast({
        message: 'Logout Failed!!',
        type: 'error'
      })

      return 0
    }
  }
}

export function passwordAction(body: any, modalCloseHandler: any, toast?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: PASSWORD.LOADING})
      res = await api<any>(APIS.change, 'PATCH', body)

      const {success, data} = res.data

      if (success) {
        dispatch({type: PASSWORD.SUCCESS})
        !!toast &&
          toast({message: 'Password Changed Successfully', type: 'success'})
        modalCloseHandler()
        return 1
      } else {
        dispatch({type: PASSWORD.ERROR})
        !!toast && toast({message: data.message, type: 'error'})
        return 0
      }
    } catch (e) {
      dispatch({type: PASSWORD.ERROR})

      !!toast && toast({message: 'Error Changing Password', type: 'error'})
      return 0
    }
  }
}

export function changePassword(body: any, toast: any, successCallback: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CHANGE_PASSWORD.LOADING})

      res = await api<any>(`${APIS.forgot}`, 'PATCH', body)

      const {success, data} = res.data

      if (success) {
        dispatch({type: CHANGE_PASSWORD.SUCCESS})

        !!toast && toast({message: data.message, type: 'success'})
        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: CHANGE_PASSWORD.ERROR})
        !!toast && toast({message: res.data.message, type: 'error'})
        return 0
      }
    } catch (e) {
      dispatch({type: CHANGE_PASSWORD.ERROR})
      !!toast && toast({message: res.data.message, type: 'error'})

      return 0
    }
  }
}

export function forgetPassword(email: string, successCallback: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: FORGET_PASSWORD.LOADING})

      res = await api<any>(`${APIS.forgot + email}`, 'GET')

      const {success, errors} = res.data

      if (success) {
        dispatch({type: FORGET_PASSWORD.SUCCESS})
        successCallback && successCallback()

        return 1
      } else {
        dispatch({type: FORGET_PASSWORD.ERROR, payload: errors[0]})

        return 0
      }
    } catch (e) {
      dispatch({type: FORGET_PASSWORD.ERROR})

      return 0
    }
  }
}

export function validateForgetPassword(
  email: string,
  token: any,
  successCallback: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: FORGET_PASSWORD.LOADING})

      res = await api<any>(
        `${APIS.validateForgot + 'token=' + token + '&email=' + email}`,
        'GET'
      )

      const {success} = res.data

      if (success) {
        dispatch({type: FORGET_PASSWORD.SUCCESS})
        successCallback && successCallback()

        return 1
      } else {
        dispatch({type: FORGET_PASSWORD.ERROR})

        return 0
      }
    } catch (e) {
      dispatch({type: FORGET_PASSWORD.ERROR})

      return 0
    }
  }
}

export function resetPassword(body: any, successCallback: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: RESET_PASSWORD.LOADING})

      res = await api<any>(`${APIS.reset}`, 'POST', body)

      const {success} = res.data

      if (success) {
        dispatch({type: RESET_PASSWORD.SUCCESS})

        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: RESET_PASSWORD.ERROR})
        return 0
      }
    } catch (e) {
      dispatch({type: RESET_PASSWORD.ERROR})
      return 0
    }
  }
}
