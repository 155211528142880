import {useEffect, useState} from 'react'
import {VscFilePdf} from 'react-icons/vsc'
import {saveAs} from 'file-saver'
import {AnimatedBlock, interpolate, TransitionBlock} from 'react-ui-animate'
import {AiOutlineDownload, AiOutlineEye} from 'react-icons/ai'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth, useNavigation} from 'react-auth-navigation'

import {uploadSiteFiles, getSiteFiles} from 'redux-src'

import {getAssetName, getAssetUrl} from 'helpers'
import {ImageDataTypes, ImageUpload} from 'app/common'
import {ImagePreviewModal} from 'app/components'

export const SiteFilesAndPhotosPage = () => {
  const {params}: any = useNavigation()
  const {toast} = useAuth()

  const [files, setFiles] = useState([])

  const {siteId} = params

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSiteFiles(siteId, () => {}, toast))
  }, [dispatch, siteId, toast])

  const {site_files: filesFromAPI}: RT.SiteReduxType = useSelector(
    (state: any) => state.site
  )

  useEffect(() => {
    setFiles(filesFromAPI?.map(({asset_details: ad}) => ad))

    return () => {
      setFiles([])
    }
  }, [filesFromAPI])

  const [assets, setAssets] = useState<Array<{url: string; file: File}>>([])

  const [dragActive, setDragActive] = useState(false)

  const changeImage = (data: Array<ImageDataTypes>) => {
    if (data.length > 0) {
      const assetsFormData = new FormData()

      data.map((item) => {
        return assetsFormData.append('files', item.file)
      })

      dispatch(
        uploadSiteFiles(
          siteId,
          assetsFormData,
          () => {
            setAssets([])
            dispatch(getSiteFiles(siteId, () => {}, toast))
          },
          toast
        )
      )
      setAssets(data)
    }
    return
  }

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
  }

  const FileDisplayComponent = ({
    item,
    index
  }: {
    item: Api.AssetDetailsType
    index: number
  }) => {
    const [isInfo, setInfo] = useState(false)
    const [isModalVisible, setModalVisible] = useState<boolean>(false)

    return (
      <div
        key={index}
        className="flex w-[160px] h-[160px] m-20 border-1 border-gray-200 rounded-[6px] relative overflow-hidden object-cover justify-center items-center"
        onMouseEnter={() => setInfo(true)}
        onMouseLeave={() => setInfo(false)}
      >
        {files.length > 0 && item.file_type === 'image' ? (
          <>
            <img
              src={getAssetUrl(item)}
              className="rounded-sm object-cover"
              alt={item.name}
            />
          </>
        ) : (
          <div>
            <VscFilePdf size={50} />
          </div>
        )}
        <TransitionBlock state={isInfo} key={index}>
          {(animation) => (
            <>
              <AnimatedBlock
                className="transition-colors duration-100 ease-in-out p-4 bg-white bg-opacity-[0.89] hover:bg-opacity-[0.98] text-black font-bold rounded-sm  w-full"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: 30,
                  translateY: interpolate(animation.value, [0, 1], [-20, 0]),
                  opacity: animation.value
                }}
              >
                <span className="inline-flex gap-16 text-[12px] h-[30px] w-full items-start justify-end">
                  {/* <a href={getAssetUrl(item)} target="_blank" rel="noreferrer"> */}
                  {item.file_type === 'image' && (
                    <span
                      onClick={() => {
                        setModalVisible((prev) => !prev)
                      }}
                    >
                      <AiOutlineEye
                        size={16}
                        className="transition-all duration-100 ease-in-out hover:scale-125 cursor-pointer"
                      />
                    </span>
                  )}
                  {/* </a> */}

                  <AiOutlineDownload
                    onClick={() => {
                      saveAs(
                        getAssetUrl(item),
                        `${item?.label ? item?.label : getAssetName(item)}`
                      )
                    }}
                    size={16}
                    className="mr-10 transition-all duration-100 ease-in-out hover:scale-125 cursor-pointer"
                  />
                </span>
              </AnimatedBlock>
              <AnimatedBlock
                className="transition-colors duration-100 ease-in-out py-6 px-8 bg-white bg-opacity-[0.89] hover:bg-opacity-[0.98] text-black font-bold rounded-sm  w-full"
                style={{
                  position: 'absolute',
                  bottom: 0,
                  height: 30,
                  left: 0,
                  translateY: interpolate(animation.value, [0, 1], [20, 0]),
                  opacity: animation.value
                }}
              >
                <div className="text-[12px] h-[30px] w-full text-center truncate ">
                  {item?.label ? item?.label : getAssetName(item)}
                </div>
              </AnimatedBlock>
            </>
          )}
        </TransitionBlock>

        <ImagePreviewModal
          modalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          assetDetails={item}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="text-lg font-bold my-10">Files &amp; Photos</div>

      <ImageUpload
        images={assets}
        onChange={changeImage}
        style={{background: 'red'}}
        title="upload image"
        multiple
        clearCache
      >
        {({onUploadImage, imageData}) => (
          <>
            <input
              className="cursor-pointer hidden"
              type="file"
              id="companyLogo"
              name="companyLogo"
              accept="image/*|pdf"
              multiple={true}
              onChange={(e) => onUploadImage(e.target.files)}
            />
            <label
              htmlFor="companyLogo"
              className={
                'cursor-pointer border-1 w-full h-[110px] rounded-sm flex justify-center items-center flex-col ' +
                `${
                  dragActive
                    ? 'bg-gray-200 border-gray-300'
                    : 'bg-blue-100 border-blue-200'
                }`
              }
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={(e) => {
                handleDrop(e)
                onUploadImage(e.dataTransfer.files)
              }}
            >
              {imageData.length > 0 ? (
                <div className="inline-flex flex-row overflow-x-auto gap-10">
                  {imageData.map(({url, file}, index) => {
                    return (
                      <div key={index}>
                        {file.type === 'image' ? (
                          <img
                            key={index.toString()}
                            src={url}
                            alt="customer-files-and-photos"
                            className="h-[80px] w-[70px] object-cover rounded-sm"
                          />
                        ) : (
                          <div className="flex  items-center justify-center flex-col gap-6 text-gray-400">
                            <VscFilePdf size={50} />
                            {file?.name}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <>
                  <label
                    htmlFor="companyLogo"
                    className="cursor-pointer text-gray-400 mt-auto"
                  >
                    Drag & drop your files here or
                  </label>
                  <label
                    htmlFor="companyLogo"
                    className="my-10 mb-auto border-1 cursor-pointer rounded-sm transition duration-500 ease-in-out text-center text-sm text-gray-300 border-gray-200 px-8 py-4 hover:bg-gray-200 hover:text-gray-400"
                  >
                    Choose Files
                  </label>
                </>
              )}
            </label>
          </>
        )}
      </ImageUpload>
      {files && Array.isArray(files) && files?.length > 0 ? (
        <div className="grid pt-20 text-gray-400 border-t-1 border-gray-200 flex-wrap grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5  justify-center justify-items-center">
          {files.map((item, index) => (
            <FileDisplayComponent key={index} {...{item, index}} />
          ))}
        </div>
      ) : (
        <div className="pt-20 border-t-1 border-gray-200">
          <div className="py-40 border-1 border-blue-150 bg-blue-100 rounded-sm w-full flex justify-center items-center">
            There are no files &amp; photos on current customer.
          </div>
        </div>
      )}
    </div>
  )
}
