import {
  ADD_CHECKLIST,
  ADD_JOB_CHECKLIST,
  GET_CHECKLIST,
  GET_JOB_CHECKLIST,
  GET_JOB_CHECKLIST_BY_ID,
  ADD_SITE_VISIT_CHECKLIST,
  GET_SITE_VISIT_CHECKLIST,
  UPDATE_JOB_CHECKLIST,
  UPDATE_JOB_CHECKLIST_ITEM,
} from '../actions'

const initialState = {
  checklistById: {},
  checkLists: [{}],
  checklistTotal: 0,
  jobChecklists: [{}],
  jobChecklistTotal: 0,
  siteVisitChecklists: [{}],
  siteVisitChecklistTotal: 0,

  update_loading: false,
  update_item_loading: false,
  checklist_item_id: 0,

  add_loading: false,
  add_error: '',

  get_loading: false,
  get_error: '',
}

export function checklistReducer(state = initialState, action: any) {
  const { type, payload } = action

  //* CHECKLISTS

  switch (type) {
    case ADD_CHECKLIST.LOADING:
      return { ...state, add_loading: true, add_error: false }
    case ADD_CHECKLIST.SUCCESS:
      return {
        ...state,
        add_loading: false,
        add_error: false,
      }
    case ADD_CHECKLIST.ERROR:
      return { ...state, add_loading: false, add_error: payload }

    case GET_CHECKLIST.LOADING:
      return {
        checklists: [{}],
        ...state,
        get_loading: true,
        get_error: false,
      }
    case GET_CHECKLIST.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        checklists: payload.rows,
        checklistTotal: payload.total,
      }
    case GET_CHECKLIST.ERROR:
      return { ...state, get_loading: false, get_error: payload }

    //* JOB CHECKLISTS
    case ADD_JOB_CHECKLIST.LOADING:
      return { ...state, add_loading: true, add_error: false }
    case ADD_JOB_CHECKLIST.SUCCESS:
      return {
        ...state,
        add_loading: false,
        add_error: false,
      }
    case ADD_JOB_CHECKLIST.ERROR:
      return { ...state, add_loading: false, add_error: payload }

    case GET_JOB_CHECKLIST.LOADING:
      return {
        ...state,
        get_loading: true,
        get_error: false,
      }
    case GET_JOB_CHECKLIST.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        jobChecklists: payload,
        jobChecklistTotal: payload.total,
      }
    case GET_JOB_CHECKLIST.ERROR:
      return { ...state, get_loading: false }

    //* JOB CHECKLIST BY ID
    case GET_JOB_CHECKLIST_BY_ID.LOADING:
      return {
        ...state,
        get_loading: true,
      }
    case GET_JOB_CHECKLIST_BY_ID.SUCCESS:
      return {
        ...state,
        get_loading: false,
        get_error: false,
        checklistById: payload,
      }
    case GET_JOB_CHECKLIST_BY_ID.ERROR:
      return { ...state, get_loading: false }

    //* SITE_VISIT CHECKLISTS
    case ADD_SITE_VISIT_CHECKLIST.LOADING:
      return { ...state, add_loading: true, add_error: false }
    case ADD_SITE_VISIT_CHECKLIST.SUCCESS:
      return {
        ...state,
        add_loading: false,
        add_error: false,
      }
    case ADD_SITE_VISIT_CHECKLIST.ERROR:
      return { ...state, add_loading: false, add_error: payload }

    case GET_SITE_VISIT_CHECKLIST.LOADING:
      return {
        ...state,
        get_loading: true,
        get_error: false,
      }
    case GET_SITE_VISIT_CHECKLIST.SUCCESS:
      // console.log('siteVisitChecklists from API: ', payload)
      return {
        ...state,
        get_loading: false,
        get_error: false,
        siteVisitChecklists: payload,
        siteVisitChecklistTotal: payload.total,
      }
    case GET_SITE_VISIT_CHECKLIST.ERROR:
      return { ...state, get_loading: false }

    case UPDATE_JOB_CHECKLIST.LOADING:
      return { ...state, update_loading: true }
    case UPDATE_JOB_CHECKLIST.SUCCESS:
      return {
        ...state,
        update_loading: false,
      }
    case UPDATE_JOB_CHECKLIST.ERROR:
      return { ...state, update_loading: false }

    case UPDATE_JOB_CHECKLIST_ITEM.LOADING:
      return { ...state, update_item_loading: true, checklist_item_id: payload }
    case UPDATE_JOB_CHECKLIST_ITEM.SUCCESS:
      return {
        ...state,
        update_item_loading: false,
      }
    case UPDATE_JOB_CHECKLIST_ITEM.ERROR:
      return { ...state, update_item_loading: false }

    default:
      return state
  }
}
