import {useCallback, useEffect, useMemo, useState} from 'react'
import {TableColumn} from 'react-data-table-component'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {useSelector, useDispatch} from 'react-redux'
import {FaPen, FaTrash} from 'react-icons/fa'

import {ConfirmationModal} from 'app/common'
import {DataTable} from 'app/components'

import {
  deleteLabourElementGroupAction,
  getLabourElementGroupAction,
  getLabourGroupByIdAction
} from 'redux-src'
import {AddLabourGroup} from '../addLabourGroup'

export const LabourGroupTable = ({search}: any) => {
  const {toast} = useAuth()

  const {
    labourElementGroupList: groupListFromApi,
    labourElementGroupTotal: total,
    get_labour_element_group_loading
  }: RT.LabourElementReduxType = useSelector(
    (state: any) => state.labourElements
  )
  const {
    navigation: {navigate}
  } = useNavigation()

  const dispatch = useDispatch()
  const [addLabourGroupModal, setAddLabourGroupModal] = useState<boolean>(false)
  const {individualLabourGroup}: RT.LabourElementReduxType = useSelector(
    (state: any) => state.labourElements
  )

  const newLabourGroupList = useMemo(() => {
    console.log({groupListFromApi})
    const remappedGroupList = groupListFromApi?.map(({group_details}) => {
      return {
        id: group_details.id,
        groupName: group_details.name,
        description: group_details.description
      }
    })

    return remappedGroupList
  }, [groupListFromApi])

  const getLabourElementGroup = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getLabourElementGroupAction({page, limit, search}))
    },
    [dispatch, search]
  )

  useEffect(() => {
    getLabourElementGroup()
  }, [getLabourElementGroup])

  const handleLabourGroupDelete = (groupId: number | string) => {
    dispatch(
      deleteLabourElementGroupAction(
        groupId,
        () => {
          getLabourElementGroup()
        },
        toast
      )
    )
  }

  const columnData: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: (row: any) => row.groupName,
      sortable: true,
      cell: (row: any) => (
        <span
          className="items-center justify-center text-blue-300  py-4 mr-1 cursor-pointer  px-6 leading-none "
          onClick={() =>
            navigate(`/settings/labour-element-management/${row.id}`)
          }
        >
          {row.groupName}
        </span>
      )
    },
    {
      name: 'Description',
      selector: (row: any) => row.description,
      sortable: true,
      cell: (row: any) => (
        <div className="flex justify-between">
          <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
            {row.description}
          </span>

          <div className="flex justify-between items-center gap-x-10">
            <button
              className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
              onClick={() => {
                // navigate(`/settings/labour-element-management/${row.id}`)

                dispatch(getLabourGroupByIdAction(row.id, () => {}))

                setAddLabourGroupModal((prev) => !prev)
              }}
            >
              <span className="text-left inline-flex cursor-pointer">
                <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
              </span>
            </button>

            <ConfirmationModal
              // loading={deleteSupplierLoading}
              onConfirmClick={(closeModalHandler) => {
                handleLabourGroupDelete(row.id)
              }}
              danger
              label={'Are you sure you want to delete this Labour Group?'}
              displayElement={
                <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                  <span className="text-left inline-flex cursor-pointer">
                    <FaTrash fill="red" size={12} />
                  </span>
                </button>
              }
              confirmLabel="Delete Labour Group"
            ></ConfirmationModal>
          </div>
        </div>
      )
    }
  ]

  return (
    <div>
      <DataTable
        columns={columnData}
        data={newLabourGroupList}
        pagination
        totalRows={+total}
        fetchData={getLabourElementGroup}
        loading={get_labour_element_group_loading}
      />

      <AddLabourGroup
        addLabourGroupModal={addLabourGroupModal}
        setAddLabourGroupModal={setAddLabourGroupModal}
        mode="update"
        labourElementGroupData={{
          id: individualLabourGroup?.group_details?.id,
          groupName: individualLabourGroup?.group_details.name ?? 'N/A',
          description:
            individualLabourGroup?.group_details.description ?? 'N/A',
          labourElements:
            individualLabourGroup?.labour_elements?.map((el) => ({
              id: el.id,
              value: el.name,
              label: el.name,
              disabled: true
            })) ?? []
        }}
      />
    </div>
  )
}
