export const getFormElementStyle = (
  formElement: RT.FormElementType,
  type: 'builder' | 'preview' | 'web view' = 'builder'
) => {
  const elementSizeStyle =
    type === 'preview'
      ? formElement?.elementSize
        ? formElement?.elementSize?.includes('66')
          ? 'w-2/3'
          : `w-${widthMap[formElement?.elementSize]}`
        : 'w-full'
      : 'w-full'

  const fontSizeStyle = formElement?.size
    ? `text-[${fontSizeMap[formElement?.size]}]`
    : 'text-md'

  const elementAlignmentStyle = formElement?.alignment
    ? alignmentMap[formElement?.alignment]
    : 'justify-start'

  return ` flex-1 ${elementSizeStyle} ${fontSizeStyle} ${elementAlignmentStyle} `
}

export const getFormElementAlignment = (formElement: RT.FormElementType) => {
  const elementAlignmentStyle = formElement?.alignment
    ? alignmentMap[formElement?.alignment]
    : 'justify-start'

  return ` ${elementAlignmentStyle} `
}

const widthMap = {
  '100%': 'full',
  '66%': '3/4',
  '50%': '1/2',
  '33%': '1/3'
}

const fontSizeMap = {
  small: '14px',
  medium: '16px',
  large: '18px'
}

const alignmentMap = {
  left: 'items-start',
  center: 'items-center',
  right: 'items-end'
}
