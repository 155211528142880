import {
  ADD_COMPANY_LOGO,
  CREATE_COMPANY_BANK_DETAIL,
  DELETE_COMPANY_BANK_DETAIL,
  GET_ACTIVE_LIST_FOR_DASHBOARD,
  GET_ALL_LIST_FOR_DASHBOARD,
  GET_COMPANY_BANK_DETAILS,
  GET_COMPANY_DETAILS,
  GET_COMPELTED_LIST_FOR_DASHBOARD,
  GET_OVERDUE_TICKET,
  GET_TO_PRICE_LIST_FOR_DASHBOARD,
  UPDATE_COMPANY_BANK_DETAIL,
  UPDATE_COMPANY_DETAILS,
  UPDATE_COMPANY_FINANCIAL_DETAILS,
  UPDATE_COMPANY_NAME,
} from '.'
import {api, APIS} from '../../config'
import {errorHandler, getCurrencySymbol} from '../../utils'

import {toast} from 'app/common'
import {getParsedUrl} from 'helpers'

export function getCompanyDetails(successCallback?: () => void) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_COMPANY_DETAILS.LOADING})
      res = await api<Api.Base<Api.CompanyDetails>>(`${APIS.company_details}`)
      const {
        success,
        data: {data: companyDetails, message},
      } = res.data

      if (success) {
        if (companyDetails?.company_bank_details?.currency) {
          var currencySymbol = getCurrencySymbol(
            companyDetails?.company_bank_details?.currency,
          )
        } else {
          currencySymbol = getCurrencySymbol('US Dollar')
        }
        const companyDetailsWithCurrency = {companyDetails, currencySymbol}

        dispatch({
          type: GET_COMPANY_DETAILS.SUCCESS,
          payload: companyDetailsWithCurrency,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_COMPANY_DETAILS.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_COMPANY_DETAILS.ERROR})
      return 1
    }
  }
}

export function updateCompanyDetails(
  body: Partial<{
    taxNumber: number
    bankAccountNumber: number
    jobNumberPrefix: string
    startingJobNumber: number
    workingHrPerWeek: number
    workingDaysPerWeek: number
    dateFormat: string
    timezone: string
    mobile: number
    country: string
    numberOfStaffs: string
    industry: string
    iban?: string
    sortCode: string
  }>,
  callback?: () => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res

    // const {displayName, emailAddress, password, companyName, mobileNumber}

    try {
      dispatch({type: UPDATE_COMPANY_DETAILS.LOADING})
      delete body.sortCode
      delete body.iban
      delete body.bankAccountNumber
      res = await api<any>(APIS.update_company_details, 'PATCH', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: UPDATE_COMPANY_DETAILS.SUCCESS})
          callback()
          toast.success(message)
        } else dispatch({type: UPDATE_COMPANY_DETAILS.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: UPDATE_COMPANY_DETAILS.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

export function updateCompanyName(
  body: any,
  callback?: () => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res

    // const {displayName, emailAddress, password, companyName, mobileNumber}

    try {
      dispatch({type: UPDATE_COMPANY_NAME.LOADING})

      res = await api<any>(APIS.update_company_name, 'PATCH', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: UPDATE_COMPANY_NAME.SUCCESS})
          callback()
          toast.success(message)
        } else dispatch({type: UPDATE_COMPANY_NAME.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: UPDATE_COMPANY_NAME.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

export function updateCompanyAddressAndContact(
  body: any,
  callback?: () => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res

    // const {displayName, emailAddress, password, companyName, mobileNumber}

    try {
      dispatch({type: UPDATE_COMPANY_DETAILS.LOADING})

      res = await api<any>(APIS.update_company_address_contact, 'PATCH', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: UPDATE_COMPANY_DETAILS.SUCCESS})
          callback()
          toast.success(message)
        } else dispatch({type: UPDATE_COMPANY_DETAILS.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: UPDATE_COMPANY_DETAILS.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

export function addCompanyLogo(
  body: FormData,
  callback?: () => void,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: ADD_COMPANY_LOGO.LOADING})

      res = await api<any>(APIS.add_company_logo, 'PATCH', body, {
        file: true,
      })

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: ADD_COMPANY_LOGO.SUCCESS})
          callback()
          toast.success(message)
        } else dispatch({type: ADD_COMPANY_LOGO.ERROR, payload: message})
      }
      //
    } catch (message) {
      dispatch({type: ADD_COMPANY_LOGO.ERROR, payload: message})
      errorHandler(res.data, toast)
    }
  }
}

export function updateCompanyFinancialDetails(
  body: any,
  callback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_COMPANY_FINANCIAL_DETAILS.LOADING})

      res = await api<any>(APIS.update_company_financial_details, 'PATCH', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {message},
        } = res.data
        if (success) {
          dispatch({type: UPDATE_COMPANY_FINANCIAL_DETAILS.SUCCESS})
          callback()
          toast.success(message)
        } else
          dispatch({
            type: UPDATE_COMPANY_FINANCIAL_DETAILS.ERROR,
            payload: message,
          })
      }
      //
    } catch (message) {
      dispatch({
        type: UPDATE_COMPANY_FINANCIAL_DETAILS.ERROR,
        payload: message,
      })
      errorHandler(res.data, toast)
    }
  }
}

// * Company bank details
export const getCompanyBankDetails = (
  companyId: number,
  params: {page?: number; limit?: number; search?: string} = {
    page: 1,
    limit: 10,
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    dispatch({type: GET_COMPANY_BANK_DETAILS.LOADING})
    try {
      const api_url = getParsedUrl(`company/${companyId}/bank`, {...params})

      res = await api<Api.Base<Api.PaginatedData<Api.CompanyBankDetail>>>(
        api_url,
      )

      if (res.status === 200) {
        if (res.data !== undefined) {
          const {
            success,
            data: {data},
          } = res.data

          if (success) {
            dispatch({type: GET_COMPANY_BANK_DETAILS.SUCCESS, payload: data})
            dispatch(getCompanyDetails(callback))
          } else {
            dispatch({
              type: GET_COMPANY_BANK_DETAILS.ERROR,
            })
            errorHandler(res?.data, toast)
          }
        }
      } else {
        dispatch({
          type: GET_COMPANY_BANK_DETAILS.ERROR,
        })
        errorHandler(
          {message: `Fetching data returned with status ${res?.status}`},
          toast,
        )
      }
    } catch (e) {
      dispatch({
        type: GET_COMPANY_BANK_DETAILS.ERROR,
      })
      errorHandler(e, toast)
    }
  }
}

export const createCompanyBankDetail = (
  companyId: number,
  body: {
    title: string
    accountNumber: string
    currency: string
    isDefault: boolean
    symbol: string
    sortCode: string
    iban?: string
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    dispatch({type: CREATE_COMPANY_BANK_DETAIL.LOADING})
    try {
      res = await api<Api.Base<any>>(`company/${companyId}/bank`, 'POST', body)

      if (res.data !== undefined) {
        const {success} = res.data

        if (success) {
          dispatch({type: CREATE_COMPANY_BANK_DETAIL.SUCCESS})
          toast.success(res?.data?.message)
          dispatch(getCompanyDetails(callback))
        } else {
          dispatch({
            type: CREATE_COMPANY_BANK_DETAIL.ERROR,
          })
          errorHandler(res?.data, toast)
        }
      }
    } catch (e) {
      dispatch({
        type: CREATE_COMPANY_BANK_DETAIL.ERROR,
      })
      errorHandler(res?.data, toast)
    }
  }
}

export const updateDefaultBank = (
  companyId: number,
  bankId: number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    dispatch({type: UPDATE_COMPANY_BANK_DETAIL.LOADING})
    try {
      res = await api<Api.Base<any>>(
        `company/${companyId}/bank/${bankId}/update-default`,
        'PATCH',
      )

      if (res.data !== undefined) {
        const {success} = res.data

        if (success) {
          dispatch({type: UPDATE_COMPANY_BANK_DETAIL.SUCCESS})
          toast.success(res?.data?.message)
          dispatch(getCompanyDetails(callback))
        } else {
          dispatch({
            type: UPDATE_COMPANY_BANK_DETAIL.ERROR,
          })
          errorHandler(res?.data, toast)
        }
      }
    } catch (e) {
      dispatch({
        type: UPDATE_COMPANY_BANK_DETAIL.ERROR,
      })
      errorHandler(res?.data, toast)
    }
  }
}

export const deleteBankDetail = (
  companyId: number,
  bankId: number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    dispatch({type: DELETE_COMPANY_BANK_DETAIL.LOADING})
    try {
      res = await api<Api.Base<any>>(
        `company/${companyId}/bank/${bankId}`,
        'delete',
      )

      if (res.data !== undefined) {
        const {success} = res.data

        if (success) {
          dispatch({type: DELETE_COMPANY_BANK_DETAIL.SUCCESS})
          toast.success(res?.data?.message)
          dispatch(getCompanyDetails(callback))
        } else {
          dispatch({
            type: DELETE_COMPANY_BANK_DETAIL.ERROR,
          })
          errorHandler(res?.data, toast)
        }
      }
    } catch (e) {
      dispatch({
        type: DELETE_COMPANY_BANK_DETAIL.ERROR,
        payload: {e},
      })
      errorHandler(res?.data, toast)
    }
  }
}

// * GET_PROJECT_LIST_FOR_DASHBOARD
export const getToPriceListForDashboard = (
  params: {limit?: number; search?: string},
  onSuccess?: (data: Api.PaginatedData<Api.ProjectListForDashboard>) => void,
) => {
  const {limit, search} = params
  return async function (dispatch: any) {
    try {
      dispatch({type: GET_TO_PRICE_LIST_FOR_DASHBOARD.LOADING})
      const api_url = getParsedUrl('/company/projects/dashboard', {
        status: 'To Price',
        limit,
        search: search === '' ? undefined : search,
        page: 1,
      })
      const resp = await api<
        Api.Base<Api.PaginatedData<Api.ProjectListForDashboard>>
      >(api_url, 'GET')
      if (resp.data !== undefined) {
        if (resp?.data?.success) {
          dispatch({
            type: GET_TO_PRICE_LIST_FOR_DASHBOARD.SUCCESS,
            payload: resp?.data?.data?.data,
          })
          onSuccess?.(resp?.data?.data?.data)
          return resp?.data?.data?.data
        } else {
          throw new Error(resp?.data?.message ?? resp?.data?.data?.message)
        }
      } else {
        throw new Error(
          'Some error occured while trying to fetch project list.',
        )
      }
    } catch (e: any) {
      dispatch({type: GET_TO_PRICE_LIST_FOR_DASHBOARD.ERROR})
      errorHandler(
        e?.message ?? 'Some error occured while trying to fetch project list.',
        toast,
      )

      return undefined
    }
  }
}

export const getActiveListForDashboard = (
  params: {limit?: number; search?: string},
  onSuccess?: (data: Api.PaginatedData<Api.ProjectListForDashboard>) => void,
) => {
  const {limit, search} = params
  return async function (dispatch: any) {
    try {
      dispatch({type: GET_ACTIVE_LIST_FOR_DASHBOARD.LOADING})
      const api_url = getParsedUrl('/company/projects/dashboard', {
        status: 'Active',
        limit,
        search: search === '' ? undefined : search,
        page: 1,
      })
      const resp = await api<
        Api.Base<Api.PaginatedData<Api.ProjectListForDashboard>>
      >(api_url, 'GET')
      if (resp.data !== undefined) {
        if (resp?.data?.success) {
          dispatch({
            type: GET_ACTIVE_LIST_FOR_DASHBOARD.SUCCESS,
            payload: resp?.data?.data.data,
          })
          onSuccess?.(resp?.data?.data?.data)
          return resp?.data?.data?.data
        } else {
          throw new Error(resp?.data?.message ?? resp?.data?.data?.message)
        }
      } else {
        throw new Error(
          'Some error occured while trying to fetch project list.',
        )
      }
    } catch (e: any) {
      dispatch({type: GET_ACTIVE_LIST_FOR_DASHBOARD.ERROR})
      errorHandler(
        e?.message ?? 'Some error occured while trying to fetch project list.',
        toast,
      )
      return undefined
    }
  }
}
export const getCompletedListForDashboard = (
  params: {limit?: number; search?: string},
  onSuccess?: (data: Api.PaginatedData<Api.ProjectListForDashboard>) => void,
) => {
  return async function (dispatch: any) {
    const {limit, search} = params

    try {
      dispatch({type: GET_COMPELTED_LIST_FOR_DASHBOARD.LOADING})
      const api_url = getParsedUrl('/company/projects/dashboard', {
        status: 'Completed',
        limit,
        search: search === '' ? undefined : search,

        page: 1,
      })
      const resp = await api<
        Api.Base<Api.PaginatedData<Api.ProjectListForDashboard>>
      >(api_url, 'GET')
      if (resp.data !== undefined) {
        if (resp?.data?.success) {
          dispatch({
            type: GET_COMPELTED_LIST_FOR_DASHBOARD.SUCCESS,
            payload: resp?.data?.data?.data,
          })
          onSuccess?.(resp?.data?.data?.data)
          return resp?.data?.data?.data
        } else {
          throw new Error(resp?.data?.message ?? resp?.data?.data?.message)
        }
      } else {
        throw new Error(
          'Some error occured while trying to fetch project list.',
        )
      }
    } catch (e: any) {
      dispatch({type: GET_COMPELTED_LIST_FOR_DASHBOARD.ERROR})
      errorHandler(
        e?.message ?? 'Some error occured while trying to fetch project list.',
        toast,
      )

      return undefined
    }
  }
}

export const getAllProjectsForDashboardModal = (
  params: {
    limit?: number
    page?: number
    search?: string
    status: 'Completed' | 'Active' | 'To Price'
  },
  onSuccess?: (data: Api.PaginatedData<Api.ProjectListForDashboard>) => void,
) => {
  return async function (dispatch: any) {
    const {limit, search, status, page} = params

    try {
      dispatch({type: GET_ALL_LIST_FOR_DASHBOARD.LOADING})
      const api_url = getParsedUrl('/company/projects/dashboard', {
        status,
        limit,
        search: search === '' ? undefined : search,

        page,
      })
      const resp = await api<
        Api.Base<Api.PaginatedData<Api.ProjectListForDashboard>>
      >(api_url, 'GET')
      if (resp.data !== undefined) {
        if (resp?.data?.success) {
          dispatch({
            type: GET_ALL_LIST_FOR_DASHBOARD.SUCCESS,
            payload: resp?.data?.data?.data,
          })
          onSuccess?.(resp?.data?.data?.data)
          return resp?.data?.data?.data
        } else {
          throw new Error(resp?.data?.message ?? resp?.data?.data?.message)
        }
      } else {
        throw new Error(
          'Some error occured while trying to fetch project list.',
        )
      }
    } catch (e: any) {
      dispatch({type: GET_ALL_LIST_FOR_DASHBOARD.ERROR})
      errorHandler(
        e?.message ?? 'Some error occured while trying to fetch project list.',
        toast,
      )

      return undefined
    }
  }
}

// * Get overdue tickets of a company

export const getOverdueTicket = (limit?: number, page?: number) => {
  return async function (dispatch: any) {
    let resp
    dispatch({type: GET_OVERDUE_TICKET.LOADING})
    try {
      resp = await api<Api.Base<Api.PaginatedData<Api.TicketOverdueList>>>(
        getParsedUrl('company/overdue-tickets', {page, limit}),
        'GET',
      )

      if (resp.data !== undefined) {
        if (!resp?.data?.success) {
          throw new Error(
            resp?.data?.message ??
              resp?.data?.data?.message ??
              'Could not fetch overdue ticket list',
          )
        }
        dispatch({
          type: GET_OVERDUE_TICKET.SUCCESS,
          payload: resp?.data?.data?.data,
        })
      } else {
        throw new Error('Could not fetch overdue ticket list')
      }
    } catch (error: any) {
      dispatch({type: GET_OVERDUE_TICKET.ERROR})

      errorHandler(error.message, toast)
    }
  }
}
