import {forwardRef} from 'react'
import RGL, {WidthProvider} from 'react-grid-layout'

const ReactGridLayout = WidthProvider(RGL)

export const GridLayout = ({
  children,
  layout,
  onDrop,
  onLayoutChange,
  isDraggable,
  isDroppable,
  rowHeight = 8,
  droppingItem
}: {
  children: React.ReactNode
  layout?: RGL.Layout[]
  onLayoutChange?: (layout: RGL.Layout[]) => void
  onDrop?(layout: RGL.Layout[], item: RGL.Layout, e: Event): void
  isDraggable?: boolean
  isDroppable?: boolean
  rowHeight?: number
  droppingItem?: Pick<RGL.Layout, 'h' | 'w'>
}) => {
  return (
    <ReactGridLayout
      className="layout min-h-full"
      layout={layout}
      cols={6}
      rowHeight={rowHeight}
      droppingItem={
        droppingItem
          ? {...droppingItem, i: '__dragging-elem__'}
          : {
              i: '__dragging-elem__',
              h: 6,
              w: 6
            }
      }
      measureBeforeMount={false}
      //   width={500}
      onLayoutChange={(layout) => {
        onLayoutChange?.(layout)
      }}
      isDroppable={isDroppable}
      isDraggable={isDraggable}
      isResizable={false}
      onDrop={(layouts, item, e) => {
        onDrop?.(layouts, item, e)
      }}
      // draggableCancel=".grid-item"
      // resizeHandle=".resizable-handle"
      // draggableHandle=".react-draggable-handle"
    >
      {children}
    </ReactGridLayout>
  )
}

export const CustomGridItem = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(
  (
    {style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props},
    ref
  ) => {
    return (
      <div
        style={{...style}}
        className={className}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        {...props}
      >
        {children}
      </div>
    )
  }
)
