import { useRef, useState, RefObject } from 'react'
import { SketchPicker } from 'react-color'
import { FaTimes, FaTint } from 'react-icons/fa'
import { useOutsideClick } from 'react-ui-animate'

export const ColorPicker = (props: any) => {
  const [selectedColor, setSelectedColor] = useState(props.value)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const containerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const handleChangeComplete = (e: any) => {
    setSelectedColor(e.hex)
    props.onChange(e.hex)
  }

  useOutsideClick(containerRef, () => setDisplayColorPicker(false))

  return (
    <div className="" ref={containerRef}>
      <button
        className="text-white rounded-sm mr-4 p-4"
        style={
          props.invert
            ? {
                backgroundColor: 'white',
                color: selectedColor ? selectedColor : 'black',
                border: '0.3px solid gray',
              }
            : {
                color: 'white',
                backgroundColor: selectedColor ? selectedColor : 'black',
              }
        }
        onClick={() => setDisplayColorPicker((prev) => !prev)}
      >
        <FaTint size={14} />
      </button>
      {displayColorPicker ? (
        <div
          style={{
            position: 'absolute',
            zIndex: '2',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-7px',
              right: '-7px',
              border: '1px solid gray',
              borderRadius: '3px',
              backgroundColor: 'white',
              cursor: 'pointer',
              color: 'gray',
              padding: '2px',
            }}
            onClick={() => setDisplayColorPicker(false)}
          >
            <FaTimes size={12} />
          </div>
          <SketchPicker
            color={selectedColor}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      ) : null}
    </div>
  )
}
