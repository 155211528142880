import * as React from 'react'
import {
  useOutsideClick,
  AnimatedBlock,
  useMountedValue,
  interpolate,
  AnimationConfigUtils,
} from 'react-ui-animate'
// import { ReactPortal } from './components'
// import { AnimationType, getAnimationConfig } from '../modules';

// import { Button } from '../button'

import {DropdownProps, placementType} from './dropdown.type'

export const DropdownWithoutPortal = ({
  children,
  // trigger = () => <></>,
  triggerElement,
  active = false,
  isAnimated = true,
  animationType = 'expand',
  style,
  placement = 'bottomleft',
  // outDismiss = true,
  inDismiss = true,
  triggerToggle = true,
  containerClass,
  onActiveChange,
}: DropdownProps & {onActiveChange?: (active: boolean) => void}) => {
  const containerRef: React.RefObject<HTMLDivElement> =
    React.useRef<HTMLDivElement>(null)

  const [dropdownActive, setDropdownActive] = React.useState<boolean>(active)
  React.useEffect(() => {
    onActiveChange && onActiveChange(dropdownActive)
  }, [dropdownActive])

  const config = isAnimated ? {...AnimationConfigUtils.ELASTIC} : {duration: 1}

  const dropdownAnimation = useMountedValue(dropdownActive, {
    from: 0,
    enter: 1,
    exit: 0,
    config,
  })

  // Open dropdown method
  const openDropdown: () => void = React.useCallback(() => {
    if (!dropdownActive) {
      setDropdownActive(true)
    }
  }, [dropdownActive])

  // Open dropdown method
  const closeDropdown: () => void = () => {
    setDropdownActive(false)
  }

  // Toggle dropdown
  const toggleDropdown: () => void = React.useCallback(() => {
    if (dropdownActive) {
      closeDropdown()
    } else {
      openDropdown()
    }
  }, [dropdownActive, openDropdown])

  const containerStyles: React.CSSProperties = {
    position: 'relative',
    display: 'inline-block',
  }

  // Direction of dropdown menu
  const getDirectionStyles: (pm: placementType) => React.CSSProperties = (
    pm: placementType,
  ) => {
    switch (pm) {
      case 'bottomleft':
        return {left: 0, top: '100%'}
      case 'bottommiddle':
        return {left: '50%', top: '100%'}
      case 'bottomright':
        return {right: 0, top: '100%'}
      case 'topleft':
        return {left: 0, bottom: '100%'}
      case 'topmiddle':
        return {left: '50%', bottom: '100%'}
      case 'topright':
        return {right: 0, bottom: '100%'}
    }
  }

  // Transform origin of dropdown animation
  const getTransformOrigin: (pm: placementType) => React.CSSProperties = (
    pm: placementType,
  ) => {
    switch (pm) {
      case 'bottomleft':
        return {transformOrigin: '0% 0%'}
      case 'bottommiddle':
        return {transformOrigin: '0% 0%'}
      case 'bottomright':
        return {transformOrigin: '100% 0%'}
      case 'topleft':
        return {transformOrigin: '0% 100%'}
      case 'topmiddle':
        return {transformOrigin: '0% 100%'}
      case 'topright':
        return {transformOrigin: '100% 100%'}
    }
  }

  const dropdownElementStyles: React.CSSProperties = {}
  const dropdownMenuStyles: any = {
    zIndex: 3,
    whiteSpace: 'nowrap',
    ...getDirectionStyles(placement),
    ...getTransformOrigin(placement),
    ...style,
  }

  // DismissOnElementClick
  const onClick = triggerToggle ? toggleDropdown : openDropdown

  // INTERPOLATION
  const minScale = animationType === 'fade' ? 0 : 0.6
  const maxScale = 1

  let translateX: number
  if (placement === 'bottommiddle' || placement === 'topmiddle') {
    translateX = -50
  } else {
    translateX = 0
  }

  // Handle outside click on container
  // if (outDismiss) {
  useOutsideClick(containerRef, () => {
    closeDropdown()
  })
  // }

  return (
    <span
      ref={containerRef}
      style={{...containerStyles}}
      className={containerClass}
    >
      <span
        {...{onClick}}
        // {...trigger({
        //   active: dropdownActive,
        // })}
        style={dropdownElementStyles}
      >
        {triggerElement}
      </span>
      {/* <span className="absolute">
        <ReactPortal> */}
      {dropdownAnimation((animation, mounted) => {
        return (
          mounted && (
            <AnimatedBlock
              onClick={() => (inDismiss ? closeDropdown() : false)}
              style={{
                ...dropdownMenuStyles,
                zIndex: 3,
                pointerEvents: dropdownActive ? 'auto' : 'none',
                display: 'flex',
                justifyContent: 'flex-start',
                justifyItems: 'center',
                alignItems: 'flex-start',
                alignContent: 'center',
                flexDirection: 'column',
                position: 'absolute',
                opacity: animation.value,
                transform: interpolate(
                  animation.value,
                  [0, 1],
                  [
                    `scale(${minScale}) translateX(${translateX}%)`,
                    `scale(${maxScale}) translateX(${translateX}%)`,
                  ],
                ),
                // ...containerClass
              }}
            >
              {children}
            </AnimatedBlock>
          )
        )
      })}
      {/* </ReactPortal>
      </span> */}
    </span>
  )
}
