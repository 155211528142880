import {
  GET_MATERIAL_ELEMENT_GROUP,
  GET_MATERIAL_ELEMENT_SETTING,
  GET_MATERIAL_ELEMENT_UNIT_LIST,
  GET_MATERIAL_GROUP_BY_ID,
  UPDATE_MATERIAL_ELEMENT_GROUP
} from '../actions'

const initalState: RT.MaterialElementReduxType = {
  materialElementUnitListLoading: false,
  materialElementUnitList: undefined,

  materialElementsLoading: false,
  materialElementTotal: undefined,
  materialElements: undefined,

  materialElementGroupsLoading: false,
  materialElementGroupTotal: undefined,
  materialElementGroups: undefined,

  materialElementGroupById: undefined,
  materialElementGroupByIdLoading: false,

  materialElementIsGroupsLoading: false,
  materialElementIsGroups: undefined,
  updateMaterialElementLoading: false
}

export function materialElementReducer(state = initalState, action: any) {
  const {type, payload} = action

  switch (type) {
    case GET_MATERIAL_ELEMENT_UNIT_LIST.LOADING:
      return {
        ...state,
        materialElementUnitListLoading: true
      }
    case GET_MATERIAL_ELEMENT_UNIT_LIST.SUCCESS:
      return {
        ...state,
        materialElementUintListLoading: false,
        materialElementUnitList: payload.rows
      }
    case GET_MATERIAL_ELEMENT_UNIT_LIST.ERROR:
      return {
        ...state,
        materialElementUintListLoading: false
      }

    case GET_MATERIAL_ELEMENT_SETTING.LOADING:
      return {
        ...state,
        materialElementsLoading: true
      }
    case GET_MATERIAL_ELEMENT_SETTING.SUCCESS:
      return {
        ...state,
        materialElementsLoading: false,
        materialElementTotal: payload.total,
        materialElements: payload.rows
      }
    case GET_MATERIAL_ELEMENT_SETTING.ERROR:
      return {
        ...state,
        materialElementsLoading: false
      }

    case GET_MATERIAL_ELEMENT_GROUP.LOADING:
      return {
        ...state,
        materialElementGroupsLoading: true
      }
    case GET_MATERIAL_ELEMENT_GROUP.SUCCESS:
      return {
        ...state,
        materialElementGroupsLoading: false,
        materialElementGroupTotal: payload.total,
        materialElementGroups: payload.rows
      }
    case GET_MATERIAL_ELEMENT_GROUP.ERROR:
      return {
        ...state,
        materialElementGroupsLoading: false
      }

    case GET_MATERIAL_GROUP_BY_ID.LOADING:
      return {
        ...state,
        materialElementGroupByIdLoading: true
      }
    case GET_MATERIAL_GROUP_BY_ID.SUCCESS:
      return {
        ...state,
        materialElementGroupByIdLoading: false,
        materialElementGroupById: payload
      }
    case GET_MATERIAL_GROUP_BY_ID.ERROR:
      return {
        ...state,
        materialElementGroupByIdLoading: false
      }

    case UPDATE_MATERIAL_ELEMENT_GROUP.LOADING:
      return {
        ...state,
        updateMaterialElementLoading: true
      }
    case UPDATE_MATERIAL_ELEMENT_GROUP.SUCCESS:
      return {
        ...state,
        updateMaterialElementLoading: false
      }

    default:
      return state
  }
}
