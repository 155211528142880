import {APIS, api} from 'config'
import {
  CREATE_ITEM,
  DELETE_ITEM,
  GET_ITEM_LIST,
  GET_ITEM_LIST_BY_SUPPLIER_ID,
  UPDATE_ITEM,
} from './actionTypes.action'
import {errorHandler} from 'utils'

import {toast} from 'app/common'
import {getParsedUrl} from 'helpers'

export function getItemList(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10,
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    dispatch({type: GET_ITEM_LIST.LOADING})

    const res = await api<Api.Base<Api.ItemList>>(
      getParsedUrl(APIS.item, params),
    )

    const {
      success,
      data: {
        message,
        data: {total, rows},
      },
    } = res.data

    if (success) {
      dispatch({
        type: GET_ITEM_LIST.SUCCESS,
        payload: {total, rows},
      })
      successCallback?.()
    } else {
      dispatch({type: GET_ITEM_LIST.ERROR, payload: message})
    }
  }
}

export function createItemAction(
  createItemData: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: CREATE_ITEM.LOADING})
      res = await api<Api.Base<any>>(`${APIS.item}`, 'POST', createItemData)
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_ITEM.SUCCESS, payload: data})
        successCallback?.()

        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_ITEM.ERROR, payload: message})

        return 0
      }
    } catch (e: any) {
      dispatch({type: CREATE_ITEM.ERROR})
      toast.error('Error Creating new item type!')
      return 1
    }
  }
}

export function deleteItemAction(
  itemId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_ITEM.LOADING})
      res = await api<any>(`${APIS.item}/${itemId}`, 'DELETE')
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: DELETE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_ITEM.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: DELETE_ITEM.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateItemAction(
  itemId: any,
  body: any,
  successCallback?: any,
  onErrorCallback?: any,
) {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: UPDATE_ITEM.LOADING})
      res = await api<Api.Base<Api.SupplierItemLists>>(
        `${APIS.item}/${itemId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_ITEM.ERROR, payload: message})
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: UPDATE_ITEM.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

// Get ItemListBySupplierId

export function getItemListBySupplierIdAction(
  supplierId: number,
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10,
  },
  successCallback?: any,
) {
  return async function (dispatch: any) {
    dispatch({type: GET_ITEM_LIST_BY_SUPPLIER_ID.LOADING})

    const res = await api<Api.Base<Api.SupplierItemLists>>(
      getParsedUrl(`${APIS.item}/supplier/${supplierId}`, params),
    )

    const {
      success,
      data: {
        message,
        data: {total, rows},
      },
    } = res.data

    if (success) {
      dispatch({
        type: GET_ITEM_LIST_BY_SUPPLIER_ID.SUCCESS,
        payload: {total, rows},
      })
      successCallback?.()
    } else {
      dispatch({type: GET_ITEM_LIST_BY_SUPPLIER_ID.ERROR, payload: message})
    }
  }
}
