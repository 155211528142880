import {
  GET_PRELIMINARY_TEMPLATE_LIST,
  GET_PRELIMINARY_TEMPLATE_BY_ID,
  CREATE_PRELIMINARY_TEMPLATES,
  UPDATE_PRELIMINARY_TEMPLATE
} from '../actions'

const initalState: RT.PreliminaryTemplateReduxType = {
  createPreliminaryTemplateLoading: false,
  updatePreliminaryTemplateLoading: false,
  preliminaryTemplateListLoading: false,
  preliminaryTemplateList: undefined,
  preliminaryTemplateTotal: 0,

  preliminaryTemplateDetailsLoading: false,
  preliminaryTemplateDetails: undefined
}

export function preliminaryTemplateReducer(state = initalState, action: any) {
  const {type, payload} = action

  switch (type) {
    case CREATE_PRELIMINARY_TEMPLATES.LOADING:
      return {
        ...state,
        createPreliminaryTemplateLoading: true
      }
    case CREATE_PRELIMINARY_TEMPLATES.SUCCESS:
      return {
        ...state,
        createPreliminaryTemplateLoading: false
      }

    case UPDATE_PRELIMINARY_TEMPLATE.LOADING:
      return {
        ...state,
        updatePreliminaryTemplateLoading: true
      }
    case UPDATE_PRELIMINARY_TEMPLATE.SUCCESS:
      return {
        ...state,
        updatePreliminaryTemplateLoading: false
      }
    case GET_PRELIMINARY_TEMPLATE_LIST.LOADING:
      return {
        ...state,
        preliminaryTemplateListLoading: true
      }
    case GET_PRELIMINARY_TEMPLATE_LIST.SUCCESS:
      return {
        ...state,
        preliminaryTemplateListLoading: false,
        preliminaryTemplateList: payload.rows,
        preliminaryTemplateTotal: payload.total
      }

    case GET_PRELIMINARY_TEMPLATE_LIST.ERROR:
      return {
        ...state,
        preliminaryTemplateListLoading: false
      }

    case GET_PRELIMINARY_TEMPLATE_BY_ID.LOADING:
      return {
        ...state,
        preliminaryTemplateDetailsLoading: true
      }
    case GET_PRELIMINARY_TEMPLATE_BY_ID.SUCCESS:
      return {
        ...state,
        preliminaryTemplateDetailsLoading: false,
        preliminaryTemplateDetails: payload
      }
    case GET_PRELIMINARY_TEMPLATE_BY_ID.ERROR:
      return {
        ...state,
        preliminaryTemplateDetailsLoading: false
      }
    default:
      return state
  }
}
