import {useSelector} from 'react-redux'
import {Loading} from 'app/common'
import {HorizontalSimpleBarchart} from './components/'

export function BasicProjectDetails() {
  const {
    isProjectDetailLoading,
    basicProjectProgressDetails: data,
  }: RT.ProjectFinancialSummaryReduxType = useSelector(
    (state: any) => state.financeSummary,
  )

  return (
    <div className="my-10">
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16 ">
        Basic Project Details
      </h3>
      <div className="grid grid-cols-1 p-16">
        {isProjectDetailLoading ? (
          <Loading />
        ) : !!data ? (
          <HorizontalSimpleBarchart showName showLegend={false} data={data} />
        ) : (
          <div className="flex">
            <div className="font-normal text-[#E2420D] px-20">
              Details are being processed. Please return later.
            </div>
          </div>
        )}
      </div>

      <Descriptions />
    </div>
  )
}

function Descriptions() {
  return (
    <div className="basic-description-container px-16 py-12">
      <div className="description">
        <span className="heading text-sm">Project Duration - </span>
        <span className="subtitle text-gray-300">
          The calendar date set for project completion.
        </span>
      </div>
      <div className="description">
        <span className="heading text-sm">Estimated Project Duration - </span>
        <span className="subtitle text-gray-300">
          The calculated duration deduced from the sum of total time required
          for accepted quote line items' labour elements.
        </span>
      </div>
      <div className="description">
        <span className="heading text-sm">Completed Duration - </span>
        <span className="subtitle text-gray-300">
          The calculated duration deduced from the sum of total time required
          for accepted quote line items' completed labour elements.
        </span>
      </div>
    </div>
  )
}
