import {useEffect, useRef, useState} from 'react'
import {FaTimes} from 'react-icons/fa'
import {Transition} from 'react-transition-group'
import {useOutsideClick} from 'utils'
import {ReactPortal} from '../reactPortal'
const duration = 300

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 1,
  popOverVisible: false,
  zIndex: 100,
}
const transitionStyles = {
  entering: {opacity: 1},
  entered: {opacity: 1},
  exiting: {opacity: 0},
  exited: {opacity: 0},
  unmounted: {opacity: 0},
}

type ModalProps = {
  title?: React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full'
  height?: string
  children: React.ReactNode
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>
  isActive?: boolean
  className?: string
  onClose?: () => void
  id?: string
}

export const Modal = ({
  withPortal = true,
  id,
  ...rest
}: ModalProps & {withPortal?: boolean}) => {
  return withPortal ? (
    <ReactPortal wrapperId="modal-portal">
      <ModalComp {...rest} key={id} id={id} />
    </ReactPortal>
  ) : (
    <ModalComp {...rest} />
  )
}

export const ModalComp = ({
  title,
  size,
  children,
  toggleModal,
  isActive,
  className,
  onClose,
  height,
  id,
}: ModalProps) => {
  const [active, setActive] = useState(isActive)
  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  const modalRef = useRef<HTMLDivElement>(null)
  // * Uncomment if modal is to be closed when clicked outside of modal body
  // useOutsideClick(modalRef, () => {
  //   onClose?.()
  //   toggleModal(false)
  // })

  return (
    <>
      <Transition in={active} timeout={duration} key={id}>
        {(state) => (
          <div
            key={id}
            id={id}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {active && (
              <div className="c-modal">
                <div
                  style={{opacity: 0.7}}
                  // style={{zIndex: 98, opacity: 0.7}}
                  className="animated fadeIn fixed bg-black inset-0"
                  onClick={() => {
                    toggleModal(false)
                  }}
                ></div>
                <div
                  // style={{zIndex: 99}}
                  className="flex justify-between  fixed inset-0 top-0 left-0 right-0 max-h-screen overflow-auto"
                >
                  <div
                    className={`w-full mx-auto ${
                      size === 'full'
                        ? 'md:w-12/12 lg:w-12/12 h-[96vh] my-0 px-8'
                        : size === 'lg'
                        ? 'md:w-7/12 lg:w-12/12 my-56 px-16'
                        : size === 'xl'
                        ? 'md:w-9/12 lg:w-12/12 my-56 px-16'
                        : size === 'md'
                        ? 'md:w-8/12 lg:w-6/12 my-56 px-16'
                        : 'md:w-6/12 lg:w-4/12 my-56 px-16'
                    } ${className ?? ''} `}
                  >
                    <div
                      ref={modalRef}
                      className={`shadow-xl bg-white w-full rounded-sm`}
                    >
                      <div className="pt-10 my-10 px-14 pr-40 relative">
                        <div className="font-bold text-base text-gray-600 px-10 mt-8 ">
                          {title}
                        </div>
                        <div
                          className="absolute right-16 top-16 cursor-pointer opacity-75 hover:opacity-100"
                          onClick={() => {
                            onClose && onClose()
                            toggleModal(false)
                          }}
                        >
                          <FaTimes size={20} />
                        </div>
                      </div>
                      <div className="pt-5 border-t-1 border-gray-200"></div>

                      <div className="py-0">
                        {children ? children : 'no-children'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Transition>
    </>
  )
}
