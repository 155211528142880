import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import {useFormInput} from 'use-form-input'

import {Button, Modal} from 'app/common'
import {updateCompletedAtOfLabourEntry} from 'redux-src'

interface UpdateCompletedDateModalProps {
  isModalVisible: boolean
  setModalVisible?: React.Dispatch<React.SetStateAction<boolean>>
  data?: any
  fetchLabourTimeEntry?: () => void
}

export const UpdateCompletedDateModal = ({
  isModalVisible,
  setModalVisible,
  data: selectedData,
  fetchLabourTimeEntry
}: UpdateCompletedDateModalProps) => {
  const [data, {setValue}] = useFormInput<{
    completedAt: string | undefined
    completedTime: string | undefined
  }>({
    completedAt: undefined,
    completedTime: undefined
  })

  const dispatch = useDispatch()

  const handleUpdateCompletedDate = () => {
    const completedAt = `${data?.completedAt
      ?.split('T')
      ?.at(0)}T${data?.completedTime?.split('T')?.at(1)}`

    const body = {
      completedAt: !!completedAt ? moment(completedAt) : undefined
    }

    dispatch(
      updateCompletedAtOfLabourEntry(
        selectedData?.projectId,
        selectedData?.id,
        body,
        () => {
          setModalVisible(false)
          fetchLabourTimeEntry()
        }
      )
    )
  }

  useEffect(() => {
    selectedData && setValue('completedAt', selectedData?.completedAt)
    selectedData && setValue('completedTime', selectedData?.completedAt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData])
  return (
    <Modal
      title={'Update Completed Date'}
      toggleModal={setModalVisible}
      isActive={isModalVisible}
      size="sm"
    >
      <div className="flex w-full flex-col  py-14 gap-12">
        <div className="flex items-center justify-between">
          <div className="flex flex-col w-full px-20">
            <div className="text-black text-sm font-bold pb-8">Date</div>
            <ReactDatePicker
              selected={
                data?.completedAt ? new Date(data?.completedAt) : undefined
              }
              onChange={(date: any) =>
                setValue('completedAt', moment(date).toISOString())
              }
              className="w-full py-6 pl-4 border-1 border-gray-200 rounded-sm"
              placeholderText="Completed Date"
            />
          </div>
          <div className="flex flex-col w-full px-20">
            <div className="text-black text-sm pb-8 font-bold">Time</div>
            <ReactDatePicker
              selected={
                data?.completedTime ? new Date(data?.completedTime) : undefined
              }
              onChange={(date: any) =>
                setValue('completedTime', moment(date).toISOString())
              }
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
              placeholderText="Completed Time"
              className="w-full py-6 pl-4 border-1 border-gray-200 rounded-sm"
            />
          </div>
        </div>
        <div className="flex gap-10 items-center mt-10 py-10 px-10 justify-end border-t-1 border-gray-200">
          <Button
            title="Cancel"
            onClick={() => {
              setModalVisible(false)
            }}
            buttonColor="text-blue-400 bg-blue-100 hover:bg-blue-150"
          />
          <Button title="Update" onClick={handleUpdateCompletedDate} />
        </div>
      </div>
    </Modal>
  )
}
