import {useIndividualFormElement} from 'hooks'
import {useAuth} from 'react-auth-navigation'

interface HeadingPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

export const HeadingPreview = ({id, type}: HeadingPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="text-lg text-black font-bold">
        {individualElement?.content?.label}
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}
    </div>
  ) : (
    <></>
  )
}
