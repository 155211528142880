import {apiGenerator} from 'helpers'

export const TABLE_LIMIT = 5
// const ENV: 'development' | 'production' = 'development'
const ENV: 'development' | 'production' = 'production'

let LOCAL_SERVER_URL = 'https://smart-clerk.co.uk'
let LOCAL_FILE_URL = 'https://api.smart-clerk.co.uk'
let LOCAL_IMAGE_URL = 'https://s3.smart-clerk.co.uk'
let LOCAL_BASE_URL = 'https://api.smart-clerk.co.uk/api'
let LOCAL_APK_DOWNLOAD_URL =
  'https://s3.smart-clerk.co.uk/android-app/smart-clerk.apk'
// @ts-ignore
if (ENV === 'development') {
  LOCAL_SERVER_URL = 'https://dev.smart-clerk.co.uk'
  LOCAL_FILE_URL = 'https://dev-api.smart-clerk.co.uk'
  LOCAL_IMAGE_URL = 'https://dev-s3.smart-clerk.co.uk'
  LOCAL_BASE_URL = 'https://dev-api.smart-clerk.co.uk/api'
  LOCAL_APK_DOWNLOAD_URL =
    'https://dev-s3.smart-clerk.co.uk/android-app/dev-smart-clerk.apk'
}

// if (ENV === 'development') {
//   LOCAL_SERVER_URL = 'http://192.168.1.152:8851'
//   LOCAL_FILE_URL = 'https://dev-api.smart-clerk.co.uk'
//   LOCAL_IMAGE_URL = 'https://dev-s3.smart-clerk.co.uk'
//   LOCAL_BASE_URL = 'http://192.168.1.152:8851/api'
//   LOCAL_APK_DOWNLOAD_URL =
//     'https://dev-s3.smart-clerk.co.uk/android-app/dev-smart-clerk.apk'
// }

export {
  LOCAL_APK_DOWNLOAD_URL,
  LOCAL_BASE_URL,
  LOCAL_FILE_URL,
  LOCAL_IMAGE_URL,
  LOCAL_SERVER_URL,
}

export const REACT_APP_GOOGLE_KEY = 'AIzaSyAfajRSB31ocfghiKo3BLA7oM6rzAt70GQ'

// export const LOCAL_BASE_URL = 'http://192.168.1.82:8848/api'

export const fileApi: any = apiGenerator({baseURL: `${LOCAL_FILE_URL}/api/`})

// API FUNCTION
// export const api: any = apiGenerator({ baseURL: LOCAL_SERVER_URL })
export const api = apiGenerator({baseURL: LOCAL_BASE_URL})
// export const api: any = apiGenerator({ baseURL: BASE_URL })

// API PATHS
export const APIS = {
  login: '/auth/login',

  logout: '/auth/logout',
  // forget: 'auth/forget',
  register: '/auth/signup',

  verify: '/auth/verify',
  forgot: '/auth/createForgetPasswordToken?email=',
  validateForgot: '/auth/validateForgetPasswordToken?',
  reset: '/auth/resetPassword',

  change: '/auth/change',
  userByClientId: '/AppUser/getByClientId?clientId=',
  userByUserId: '/aspnetuser/getByuserId?userId=',
  user: '/users/',
  userList: '/users/',
  companyUserList: '/users/company-users',
  updateUserProfilePic: '/users/change-pic',
  company_details: '/company',
  update_company_details: '/company',
  add_company_logo: '/company/logo',
  update_company_name: '/company/update-company-name',
  update_company_address_contact: '/company/update-address-contact',
  update_company_financial_details: '/company/financial-details',

  quote_settings: '/quote-settings',
  update_quote_settings: '/quote-settings/update-quote-setting',

  invoice_settings: '/invoice-settings',
  mark_as_ready_to_invoice:
    'jobs/:projectId/site-visits/:siteVisitId/mark-as-ready-to-invoice',
  manually_mark_as_invoiced:
    'jobs/:projectId/site-visits/:siteVisitId/mark-as-invoiced',
  update_invoice_settings: '/invoice-settings/update-invoice-setting',

  sales_account_codes: '/sales-account-codes',

  price_books: '/price-books',
  search_price_books: '/price-books/search?name=',
  delete_price_books: '/price-books/',

  add_customer: '/customers',
  get_customer: '/customers',

  add_site: '/sites',
  get_site: '/sites',

  jobs: '/jobs',
  jobList: '/jobs',
  add_job: '/jobs',
  projects: '/projects',
  project_files_and_photos: '/files/project',

  job_checklists: '/checklists/jobs',
  get_checklists: '/checklists',
  update_checklists: '/checklists',
  check_uncheck_checklists: '/checklists/check',
  get_site_visit_checklists:
    '/jobs/:projectId/site-visits/:siteVisitId/checklist/all',
  add_site_visit_checklists:
    '/jobs/:projectId/site-visits/:siteVisitId/attach-checklist',

  checklists: '/checklist-templates',

  add_group: '/Group/add',
  update_group: '/Group/update',
  delete_group: '/Group/delete?id=',
  get_group: '/Group/list',

  quotes: '/quotes',
  quotesItem: '/quote-items',

  invoices: '/invoices',
  invoicesItem: '/invoice-items',
  add_costs: '/add-costs',

  files_and_photos: '/files',
  job_files_and_photos: '/files/job',
  siteVisits: '/site-visits',
  assignedEmployee: '/assigned-employees',
  sitevisitEvents: '/site-visit-events',

  timeEntry: '/time-entry',

  labour_complete:
    '/site-visits/:siteVisitId/assigned-employees/:empId/mark-labour-complete',
  labour_needs_to_return:
    '/site-visits/:siteVisitId/assigned-employees/:empId/needs-to-return',

  signedOff: '/signoff/job/:projectId?type=job',

  notes: '/notes',
  notesHistory: '/notes/jobs/',
  todaysJob: '/site-visit-events/user-events',
  statusBoard: '/status-board',
  pagination: 'page=1&limit=100',

  add_supplier: '/suppliers',
  get_supplier: '/suppliers',

  add_labour_elements: '/labour-elements',
  get_labour_elements: '/labour-elements',
  createItemType: '/item/type',
  itemType: '/item/type',
  itemCategory: '/item/category',
  materialElement: '/material-elements',
  preliminaryTemplates: '/preliminary-templates',
  item: '/item',
  forms: '/forms',
  generate_pdf: '/generate-pdf',
  worker_invoice: '/worker-invoice',

  notification: '/notifications',
}
