import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useAuth, useNavigation} from 'react-auth-navigation'
import colors from 'tailwindcss/colors'
import {TableColumn, TableStyles} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {FaPen, FaTrash} from 'react-icons/fa'

import {DataTable} from 'app/components'
import {AddMaterialElement} from '../addMaterialElement'
import {ConfirmationModal} from 'app/common'

import {
  deleteMaterialElementAction,
  getMaterialElementAction,
  getMaterialElementAndGroupList,
  getMaterialElementGroupById,
  removeMaterialElementFromGroup,
} from 'redux-src'

export interface MaterialElementDetails {
  id: number
  name: string
  supplier: {id: number; label: string; value: string}
  unitName: string
  numberPerUnit: number
  cost: number
}

export const MaterialElementTable = ({
  search,
  groupMaterialList,
  isGroup = false,
}: {
  search?: string
  groupMaterialList?: any
  isGroup?: boolean
}) => {
  const {
    materialElements: listFromApi,
    materialElementsLoading,
    materialElementTotal,
  }: RT.MaterialElementReduxType = useSelector(
    (state: any) => state.materialElement,
  )

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const [materialElementDetails, setMaterialElementDetails] =
    useState<MaterialElementDetails>()
  const {currencySymbol} = useAuth()

  const {
    params: {materialElementGroupId},
  }: any = useNavigation()
  const dispatch = useDispatch()

  const remappedMaterialElement = useMemo(() => {
    if (!!!listFromApi) return undefined

    const finalList = listFromApi?.map(
      ({material_element_details, supplier_details}) => {
        return {
          id: material_element_details?.id,
          name: material_element_details?.name,
          supplier: {
            id: supplier_details?.id,
            label: supplier_details?.name,
            value: supplier_details?.name,
          },
          unitName: material_element_details?.unit_name,
          numberPerUnit: material_element_details?.number_per_unit,
          cost: material_element_details?.cost,
        }
      },
    )

    return finalList
  }, [listFromApi])

  const fetchMaterialElementList = useCallback(
    (page?: number, limit?: number) => {
      dispatch(
        getMaterialElementAction({
          page: page ?? 1,
          limit: limit ?? 10,
          search,
        }),
      )
    },
    [dispatch, search],
  )

  const handleRemoveMaterialElementFromGroup = (materialElementId: number) => {
    isGroup
      ? dispatch(
          removeMaterialElementFromGroup(
            materialElementGroupId,
            {materialElements: [{id: materialElementId}]},
            () => {
              dispatch(getMaterialElementAction())
              dispatch(getMaterialElementAndGroupList())
              dispatch(getMaterialElementGroupById(materialElementGroupId))
            },
          ),
        )
      : dispatch(
          deleteMaterialElementAction(materialElementId, () => {
            dispatch(getMaterialElementAction())
            dispatch(getMaterialElementAndGroupList())
          }),
        )
  }

  useEffect(() => {
    fetchMaterialElementList()
  }, [dispatch, fetchMaterialElementList])

  const columns: TableColumn<MaterialElementDetails>[] = [
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: true,

      cell: (row) => (
        <span className="items-center justify-center text-blue-400  py-4 mr-1   px-6 leading-none w-[70vw]">
          {row?.name}
        </span>
      ),
    },
    {
      name: 'Unit Name',
      selector: (row) => row?.unitName,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row?.unitName}
        </span>
      ),
    },
    {
      name: 'Supplier',
      selector: (row) => row?.supplier?.label ?? row?.id,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row?.supplier?.label}
        </span>
      ),
    },
    {
      name: 'Cost',
      selector: (row) => row?.cost,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol}
          {row?.cost}
        </span>
      ),
    },

    {
      name: 'Number Per Unit',
      selector: (row) => row?.numberPerUnit,
      sortable: true,
      cell: (row) => (
        <div className="flex justify-between">
          <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
            {row?.numberPerUnit}
          </span>

          <div className="flex justify-between items-center gap-x-10">
            <button
              className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
              onClick={() => {
                setIsModalVisible(true)
                setMaterialElementDetails(row)
              }}
            >
              <span className="text-left inline-flex cursor-pointer">
                <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
              </span>
            </button>

            <ConfirmationModal
              onConfirmClick={(closeModalHandler) => {
                handleRemoveMaterialElementFromGroup(row?.id)
              }}
              danger
              label={'Are you sure you want to delete this Material?'}
              displayElement={
                <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                  <span className="text-left inline-flex cursor-pointer">
                    <FaTrash fill="red" size={12} />
                  </span>
                </button>
              }
              confirmLabel="Delete Material"
            />
          </div>
        </div>
      ),
    },
  ]
  const customStyles: TableStyles = {
    table: {
      style: {
        border: '1px solid',
        borderRadius: '5px',
        borderColor: colors.gray[200],
        width: 'inherit',
      },
    },
    rows: {
      style: {
        minHeight: '40px !important',
        backgroundColor: '#f5f5f5',
        '&:nth-child(2n-1)': {
          backgroundColor: colors.white,
        },
      },
    },

    headCells: {
      style: {
        fontWeight: 'bold',
        padding: '6px 10px',

        '&:not(:last-child)': {
          borderRight: '1px solid',
          borderColor: colors.gray[200],
        },
      },
    },
    headRow: {
      style: {
        fontWeight: 'bold',
        minHeight: '42px !important',
        background: colors.gray[100],
      },
    },
    cells: {
      style: {
        padding: '6px 10px',
        '&:first-child': {
          width: '40%',
        },
        '&:not(:last-child)': {
          borderRight: '1px solid',
          borderColor: colors.gray[200],
        },
      },
    },
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={isGroup ? groupMaterialList ?? [] : remappedMaterialElement ?? []}
        pagination
        totalRows={+materialElementTotal}
        fetchData={fetchMaterialElementList}
        customStyles={customStyles}
        loading={materialElementsLoading}
      />
      <AddMaterialElement
        addMaterialElementModal={isModalVisible}
        setMaterialElementModal={setIsModalVisible}
        mode={'edit'}
        materialElementDetails={materialElementDetails}
      />
    </div>
  )
}
