import {
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_UNREAD,
} from 'redux-src/actions'

const initialState: RT.NotificationReduxType = {
  isNotificationsLoading: false,
  notifications: undefined,
  totalNotification: 0,
  unreadCount: 0,
  fetchOnlyUnread: false,
  fetchLimit: 10,

  isNotificationLast: true,
  isMarkAllAsReadLoading: false,
  isMarkReadLoading: false,
  isMarkUnreadLoading: false,
}

export const notificationReducer = (state = initialState, action: any) => {
  const {type, payload} = action

  switch (type) {
    // * to control show only read and unread
    case 'NOTIFICATION_FETCH_ONLY_UNREAD':
      return {...state, fetchOnlyUnread: payload as boolean}

    case 'NOTIFICATION_SET_FETCH_LIMIT':
      return {...state, fetchLimit: payload as number}

    case GET_NOTIFICATIONS.LOADING:
      return {...state, isNotificationsLoading: true}
    case GET_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        isNotificationsLoading: false,
        isNotificationLast: payload.isLast,
        notifications: payload.rows,
        totalNotification: payload.total,
        unreadCount: payload.unread_count,
      }
    case GET_NOTIFICATIONS.ERROR:
      return {...state, isNotificationsLoading: false, notifications: undefined}

    // * Mark as read/unread reducers

    case MARK_ALL_NOTIFICATION_AS_READ.LOADING:
      return {...state, isMarkAllAsReadLoading: true}

    case MARK_ALL_NOTIFICATION_AS_READ.SUCCESS:
    case MARK_ALL_NOTIFICATION_AS_READ.ERROR:
      return {...state, isMarkAllAsReadLoading: false}

    case MARK_NOTIFICATION_AS_READ.LOADING:
      return {...state, isMarkReadLoading: true}
    case MARK_NOTIFICATION_AS_READ.ERROR:
    case MARK_NOTIFICATION_AS_READ.SUCCESS:
      return {...state, isMarkReadLoading: false}

    case MARK_NOTIFICATION_AS_UNREAD.LOADING:
      return {...state, isMarkUnreadLoading: true}
    case MARK_NOTIFICATION_AS_UNREAD.SUCCESS:
    case MARK_NOTIFICATION_AS_UNREAD.ERROR:
      return {...state, isMarkUnreadLoading: false}

    default:
      return {...state}
  }
}
