import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import {saveAs} from 'file-saver'

import {MessageBox, PlanTicketMap} from 'app/components'
import {
  DefaultButton,
  Dropdown,
  InfiniteScrollBlock,
  SelectField,
  toast
} from 'app/common'

import {
  downloadPlanWithTicketAction,
  getTicketListByProjectPlanId
} from 'redux-src'
import PlanTicketCart from './components/planTicketCart/planTicketCart.component'
import {AiOutlineCloudDownload} from 'react-icons/ai'
import {getAssetUrl, getPdfLink} from 'helpers'
import {TbDownload} from 'react-icons/tb'
import {IoTicketSharp} from 'react-icons/io5'
import {TicketContext} from '../../ticket.page'
import {CreateTicketModel} from '../createTicketModel'

export const PlanList = () => {
  const [ticketModal, setTicketModal] = useState<boolean>(false)

  const {setProjectTicketId} = useContext(TicketContext)

  const {
    isTicketListLast,
    ticketListByProjectPlan: projectPlanTicketsFromApi,
    ticketListByProjectPlanCount: ticketTotalPerPlan
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const {
    projectPlanList: projectPlanListFromApi,
    projectPlanTicketListBuffer
  }: RT.ProjectPlanReduxType = useSelector((state: any) => state.projectPlan)

  const [data, {setValue}] = useFormInput({
    planList: undefined
  })

  const [page, setPage] = useState<number>(1)

  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  const [projectPlanTicketList, setProjectPlanTicketList] =
    useState<Array<Api.SiteVisitTicketIndividual>>()

  const [selectedTicket, setSelectedTicket] =
    useState<Api.SiteVisitTicketIndividual>()
  const [planListOptions, setPlanListOptions] =
    useState<Array<{id: number; label: string; value: number}>>()

  const remappedPlanListOptions = useCallback(() => {
    if (!!!projectPlanListFromApi) return

    const remappedData = projectPlanListFromApi?.map((planItem) => {
      return {
        id: planItem?.project_plan_details?.id,
        label: planItem?.project_plan_details?.name,
        value: planItem?.project_plan_details?.id
      }
    })

    setPlanListOptions(remappedData)
  }, [projectPlanListFromApi])

  const selectedProjectPlan = useMemo(() => {
    if (!!projectPlanListFromApi && !!data?.planList) {
      const filteredSelectedPlan = projectPlanListFromApi?.find(
        (plan) => plan?.project_plan_details?.id === data?.planList?.id
      )

      return filteredSelectedPlan
    } else {
      return undefined
    }
  }, [projectPlanListFromApi, data?.planList])

  useEffect(() => {
    setProjectPlanTicketList((prev) =>
      prev?.length > 0
        ? [...prev, ...(projectPlanTicketsFromApi ?? [])]
        : [...(projectPlanTicketsFromApi ?? [])]
    )
  }, [projectPlanTicketsFromApi])

  useEffect(() => {
    dispatch({
      type: 'UPDATE_PROJECT_PLAN_TICKET_LIST_BUFFER',
      payload: projectPlanTicketList
    })
  }, [dispatch, projectPlanTicketList])

  const getPlanTicketListCallback = useCallback(() => {
    return dispatch(
      getTicketListByProjectPlanId(
        projectId,
        data?.planList?.id,
        page,
        100,
        () => {
          ;(page < Number(ticketTotalPerPlan) || page === 1) &&
            setProjectPlanTicketList([])
        }
      )
    )
  }, [data?.planList?.id, page, projectId, ticketTotalPerPlan, dispatch])

  useEffect(() => {
    data?.planList && getPlanTicketListCallback()
  }, [data?.planList, getPlanTicketListCallback])

  useEffect(() => {
    remappedPlanListOptions()
  }, [remappedPlanListOptions])

  useEffect(() => {
    const prepopulatedValue = data?.planList ?? projectPlanListFromApi?.at(0)
    const finalValue = prepopulatedValue
      ? {
          id: prepopulatedValue['project_plan_details']?.id,
          label: prepopulatedValue['project_plan_details']?.name,
          value: prepopulatedValue['project_plan_details']?.id
        }
      : undefined

    setValue('planList', finalValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   const prepopulatedData = projectPlanTicketList?.at(0)

  //   setSelectedTicket(prepopulatedData)
  // }, [projectPlanTicketList])

  const downloadPdfWithTickets = () => {
    if (projectPlanTicketList && projectPlanTicketList.length > 0) {
      toast.info('Your download will start after processing is complete.')
      dispatch(
        downloadPlanWithTicketAction(
          projectId,
          selectedProjectPlan?.project_plan_details?.id,
          (item) => {
            if (item && item.length > 0) {
              toast.success('Your download is complete.')
              saveAs(
                getPdfLink(item),
                `${selectedProjectPlan?.project_plan_details?.name}-with-annotated-ticket-ids`
              )
            }
          },
          (err) => {
            toast.error(err)
          }
        )
      )
    }
  }

  return (
    <div className="flex w-full flex-col gap-10 mb-20">
      <div className="flex w-full flex-1 items-center justify-between gap-6 py-8  rounded-sm">
        <div className="w-[35%] mt-8 flex flex-col gap-8">
          <div className="text-md text-black font-bold">Select plan</div>
          <SelectField
            options={planListOptions}
            value={data?.planList ?? undefined}
            onChangeValue={(value) => {
              setValue('planList', value)
              setProjectPlanTicketList([])
              setPage(1)
              setSelectedTicket(undefined)
            }}
          />
        </div>

        <Dropdown
          placement="bottomright"
          triggerElement={
            <DefaultButton
              title="Download PDF"
              leftIcon={<AiOutlineCloudDownload />}
              className="text-sm"
            />
          }
        >
          <div className="flex flex-col bg-white w-[200px] overflow-hidden rounded-sm shadow-md">
            <div
              className="flex items-center justify-start gap-10 px-8 py-6 text-sm hover:bg-blue-150 transition-all duration-200 ease-in-out cursor-pointer"
              onClick={() =>
                saveAs(
                  getAssetUrl(selectedProjectPlan?.asset_details),
                  `${selectedProjectPlan?.project_plan_details?.name}`
                )
              }
            >
              <TbDownload size={12} />
              Download plan
            </div>
            <div
              onClick={downloadPdfWithTickets}
              className={`flex items-center justify-start gap-10 px-8 py-6 text-sm transition-all duration-200 ease-in-out ${
                projectPlanTicketList && projectPlanTicketList.length > 0
                  ? 'hover:bg-blue-150 cursor-pointer'
                  : 'text-gray-300 cursor-not-allowed'
              }`}
            >
              <IoTicketSharp size={12} />
              Download plan with tickets
            </div>
          </div>
        </Dropdown>
      </div>
      <div className="flex flex-row flex-nowrap gap-10 items-stretch justify-stretch">
        {!!data.planList &&
        !!projectPlanTicketListBuffer &&
        projectPlanTicketListBuffer?.length > 0 ? (
          <>
            <InfiniteScrollBlock
              key={data?.planList?.label + data?.planList?.id}
              className="flex flex-1 flex-col gap-14 py-6 pr-10 border-r-1 border-gray-200 overflow-y-scroll overflow-x-hidden h-[600px]"
              triggerCallback={() => {
                setPage((prev) =>
                  prev === Math.ceil(Number(ticketTotalPerPlan) / 10)
                    ? prev
                    : prev + 1
                )
              }}
              isLast={isTicketListLast}
            >
              {!!projectPlanTicketListBuffer &&
                projectPlanTicketListBuffer?.length > 0 &&
                projectPlanTicketListBuffer?.map((ticket, index) => {
                  return (
                    <PlanTicketCart
                      key={index}
                      ticketDetails={ticket}
                      getTicketId={(data) => {
                        if (!!data?.ticket_details?.layer_coordinates) {
                          setSelectedTicket(data)
                        } else {
                          setProjectTicketId(data?.ticket_details?.id)
                          setTicketModal(true)
                          toast.warning(
                            'Please assign plan position for this ticket!'
                          )
                        }
                      }}
                      selectedTicket={selectedTicket}
                    />
                  )
                })}
            </InfiniteScrollBlock>
            <div className="flex-[3]">
              <PlanTicketMap
                plan={selectedProjectPlan}
                // planTicketList={projectPlanTicketList}
                selectedTicket={selectedTicket}
                getPlanTicketListCallback={getPlanTicketListCallback}
                setPlanTicketList={setProjectPlanTicketList}
                setTicketModal={setTicketModal}
              />
              <CreateTicketModel
                modalId={'Plan Ticket Map Modal'}
                isModelVisible={ticketModal}
                setIsModelVisible={setTicketModal}
                edit
                planCallback={getPlanTicketListCallback}
              />
            </div>
          </>
        ) : (
          <MessageBox
            message={<div>This project plan has no ticket list !!</div>}
          />
        )}
      </div>
    </div>
  )
}
