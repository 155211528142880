import React, {useCallback, useEffect, useState} from 'react'
import {useFormInput} from 'use-form-input'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {FaLayerGroup} from 'react-icons/fa'

import {DynamicSearchSelect} from 'app/components'
import {Button, Input, Modal, SelectField} from 'app/common'
import {getUnitListAction, createNewLineItemManually} from 'redux-src'

interface AddItemManuallyProps {
  openItemModal: boolean
  setOpenItemModal: React.Dispatch<React.SetStateAction<boolean>>
  sectionDetail?: Api.QuoteItemIndividual
  fetchQuoteItem?: () => void
}

export const AddItemManually = ({
  openItemModal,
  setOpenItemModal,
  sectionDetail,
  fetchQuoteItem
}: AddItemManuallyProps) => {
  const [newUnitList, setNewUnitList] = useState<
    {
      id: number
      label: string
      value: string
    }[]
  >()
  const {unitList}: RT.LabourElementReduxType = useSelector(
    (state: any) => state.labourElements
  )
  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const [data, {onChange, setValue, errors, modified, clear}] = useFormInput(
    {
      itemName: '',
      labourElementId: 0,
      labourElementGroupId: 0,
      addLabourElement: false,
      isLabourElementGroup: false,
      name: '',
      timeMins: '',
      unitName: ''
    },
    () => {},
    () => {
      const errors: any = {}

      if (data.itemName.length === 0) {
        errors.name = 'Please enter item name!'
      }

      if (data.name.length === 0) {
        errors.name = 'Please enter labour element name!'
      } else if (data.timeMins.length === 0) {
        errors.timeMins = 'Please enter timeMins'
      }

      return errors
    }
  )

  const createLineItemManuallyAction = () => {
    if (!data.labourElementId && !data.labourElementGroupId) {
      const lineItemBody = {
        name: data?.itemName,
        labourElementDetail: {
          name: data?.name,
          timeMins: data?.timeMins,
          unitName: data?.unitName
        }
      }

      dispatch(
        createNewLineItemManually(
          projectId,
          sectionDetail?.quote_id,
          sectionDetail?.id,
          lineItemBody,
          () => {
            setOpenItemModal(false)
            fetchQuoteItem()
          }
        )
      )
    }

    if (data.labourElementGroupId) {
      const lineItemBody = {
        name: data?.itemName,
        labourElementGroupId: data.labourElementGroupId
      }

      dispatch(
        createNewLineItemManually(
          projectId,
          sectionDetail?.quote_id,
          sectionDetail?.id,
          lineItemBody,
          () => {
            setOpenItemModal(false)
            fetchQuoteItem()
          }
        )
      )
    }

    if (data.labourElementId) {
      const lineItemBody = {
        name: data?.itemName,
        labourElementId: data.labourElementId
      }

      dispatch(
        createNewLineItemManually(
          projectId,
          sectionDetail?.quote_id,
          sectionDetail?.id,
          lineItemBody,
          () => {
            setOpenItemModal(false)
            fetchQuoteItem()
          }
        )
      )
    }
  }

  const remappedUnitList = useCallback(() => {
    const finalUnitList = unitList?.map((lists: any, idx: number) => {
      return {
        id: idx + 1,
        label: lists.unit_details.name,
        value: lists.unit_details.symbol
      }
    })

    setNewUnitList(finalUnitList)
  }, [unitList])

  useEffect(() => {
    remappedUnitList()
  }, [remappedUnitList])

  useEffect(() => {
    dispatch(getUnitListAction(() => {}))
  }, [dispatch])

  useEffect(() => {
    if (openItemModal === false) {
      clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openItemModal])
  return (
    <Modal
      isActive={openItemModal}
      toggleModal={setOpenItemModal}
      title={'Add Item Manually'}
    >
      <div>
        <div className="pt-[18px] px-[20px]">
          <label htmlFor="name" className="flex flex-col gap-6">
            <Input
              type="text"
              name="itemName"
              value={data.itemName}
              onChange={onChange}
              error={errors.itemName && modified.itemName}
              placeholder="Name"
              label={'Item Name'}
              required
            />
            {modified.itemName && errors.itemName && (
              <span className="text-red-500 -mt-10">{errors.itemName}</span>
            )}
          </label>

          {data.addLabourElement !== true && (
            <span
              className="flex flex-col gap-6 relative mb-12"
              style={{width: '100%'}}
            >
              <span>Labour Element</span>{' '}
              <div>
                <DynamicSearchSelect
                  actionUrl="/labour-elements/labour-elements-and-group-list"
                  remapOptions={(
                    data: Api.LabourElementAndGroupIndividual[]
                  ) => {
                    return data?.map((item: any) => {
                      return {
                        id: item.id,
                        label: item.name,
                        value: item.name,
                        is_group: item.is_group
                      }
                    })
                  }}
                  options={[
                    {
                      id: 0,
                      label: 'Add New Labour Element',
                      value: true,
                      is_group: false
                    }
                  ]}
                  // value={newLabourElementList?.find(
                  //   (item: any, index: number) => {
                  //     return item?.id === data.labourElementId
                  //   }
                  // )}
                  formatOptionLabel={(data: any) => {
                    if (data.is_group) {
                      return (
                        <div className="flex gap-8 items-center justify-between">
                          <p>{data.label}</p>
                          <FaLayerGroup />
                        </div>
                      )
                    } else {
                      return <div>{data.label}</div>
                    }
                  }}
                  onChangeValue={(value: any) => {
                    setValue('addLabourElement', value?.value)

                    if (value.is_group) {
                      setValue('labourElementGroupId', value.id)
                      setValue('labourElementId', undefined)
                      setValue('isLabourElementGroup', true)
                      //   console.log('labourGroup: ', value)
                    } else if (!value.is_group) {
                      setValue('labourElementGroupId', undefined)
                      setValue('labourElementId', value.id)
                      setValue('isLabourElementGroup', false)
                    }
                  }}
                  getOptionValue="value"
                  placeholder={'Select Labour Element'}
                />
              </div>
            </span>
          )}

          {data?.addLabourElement === true && (
            <div>
              <div className="text-md font-bold mb-6">
                Labour Element Details
              </div>
              <label htmlFor="name" className="flex flex-col gap-6">
                <Input
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={onChange}
                  error={errors.name && modified.name}
                  placeholder="Name"
                  label={'Name'}
                  required
                />
                {modified.name && errors.name && (
                  <span className="text-red-500 -mt-10">{errors.name}</span>
                )}
              </label>
              <div className="flex justify-between">
                <span className="flex flex-col gap-6 w-1/2 ">
                  <div className="flex gap-4">
                    <span>Unit Name</span>
                    <span className="text-red-500">*</span>
                  </div>
                  <SelectField
                    options={newUnitList}
                    value={newUnitList?.find((item) => {
                      return item.label === data.unitName
                    })}
                    onChangeValue={(e) =>
                      !Array.isArray(e) && setValue('unitName', e.label)
                    }
                    placeholder={'Select unit name'}
                  />
                </span>
                <label htmlFor="timeMins" className="flex flex-col gap-6">
                  <Input
                    type="number"
                    name="timeMins"
                    value={data.timeMins}
                    onChange={onChange}
                    error={errors.timeMins && modified.timeMins}
                    placeholder="Time Mins"
                    label={'Time Mins'}
                    required
                  />
                  {modified.timeMins && errors.timeMins && (
                    <span className="text-red-500">{errors.timeMins}</span>
                  )}
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="border border-t-1 border-gray-200 px-20 py-10">
          <div className="flex justify-end gap-8">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={() => setOpenItemModal(false)}
            >
              Cancel
            </Button>
            <Button
              form="labour-element-type"
              type="submit"
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
              onClick={createLineItemManuallyAction}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
