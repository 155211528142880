import {useCallback, useEffect, useState} from 'react'
import Geocode from 'react-geocode'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete' // import { useFormInput } from 'use-form-input'
import {HiMinusSm, HiPlusSm} from 'react-icons/hi'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {Input, Modal, Select, SelectField, toast} from 'app/common'
import {DescriptionBox} from 'app/components'
import {addSite, updateSite} from 'redux-src'

import {REACT_APP_GOOGLE_KEY} from 'config'
import {
  addressRemapper,
  addressStyles,
  finalAddressRemapper,
  getAccumulatedContacts,
  getContactDynamicFields,
  getCountryId,
  getRemappedContacts,
} from 'helpers'
import {capitalizeFirstLetter, capitalizeFirstLetterWithSpace} from 'utils'

export const NewContactModal = ({
  addNewContactModal,
  setNewContactModal,
  onAddNewContact,
  activeSiteDetails,
  setSiteDetails,
}: {
  addNewContactModal: boolean
  setNewContactModal: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewContact?: () => void
  activeSiteDetails?: Api.SiteDetailsByIdType
  setSiteDetails?: (id: number) => void
}) => {
  const {
    selectOptions: {countryOptions, contactTypeOptions},
  } = useSelector((state: any) => state.selectOption)

  const dispatch = useDispatch()

  const [physicalAddressData, physicalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 1,
  })

  // const [physAddress_loading, setPhysAddress_loading] = useState(false)

  //? POSTAL ADDRESS
  const [postalAddressData, postalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 2,
  })
  const [postalAddressActive, setPostalAddressActive] = useState(false)
  // const [postAddress_loading, setPostAddress_loading] = useState(false)

  //? DEFAULT MAIN CONTACT
  const [mainContactData, setMainContactData] = useState({
    contactTypeId: 2,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
    firstName: '',
    lastName: '',
    title: '',
    attn: '',
  })

  //? BILLING CONTACT
  const [billingContactData, setBillingContactData] = useState({
    contactTypeId: 3,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: ''}],
    firstName: '',
    lastName: '',
    title: '',
  })
  const [billingContactActive, setBillingContactActive] = useState(false)

  const [otherContactData, setOtherContactData] = useState([])

  const remapSiteDetails = useCallback(() => {
    if (activeSiteDetails) {
      // dispatch({ type: GET_CUSTOMER_BY_ID.CLEAR })

      const getCustomerAddresses = (
        addressType: 'physical' | 'postal',
        setValue: any,
      ) => {
        const addressKey =
          addressType === 'physical'
            ? 'physical_address_details'
            : 'postal_address_details'
        setValue('address', activeSiteDetails[addressKey]?.address ?? '')
        setValue('state', activeSiteDetails[addressKey]?.state ?? '')
        setValue('suburb', activeSiteDetails[addressKey]?.suburb ?? '')
        setValue('city', activeSiteDetails[addressKey]?.city ?? '')
        setValue('place', activeSiteDetails[addressKey]?.place ?? '')
        setValue(
          'countryId',
          getCountryId(activeSiteDetails[addressKey]?.country) ?? 0,
        )
        setValue('zip', activeSiteDetails[addressKey]?.zip_code ?? '')
        setValue(
          'lat',
          activeSiteDetails[addressKey]?.coordinates
            ? activeSiteDetails[addressKey]?.coordinates[0]
            : 0,
        )
        setValue(
          'lng',
          activeSiteDetails[addressKey]?.coordinates
            ? activeSiteDetails[addressKey]?.coordinates[1]
            : 0,
        )
      }

      getCustomerAddresses('physical', physicalAddressHandler.setValue)
      getCustomerAddresses('postal', postalAddressHandler.setValue)
      setPostalAddressActive(
        activeSiteDetails?.postal_address_details?.id ===
          activeSiteDetails?.physical_address_details?.id,
      )

      const getContacts = (contactTypeId: 2 | 3) => {
        const contactType =
          contactTypeId === 2
            ? 'default_contact_details'
            : 'billing_contact_details'

        return {
          contactTypeId,
          dynamicFields: getContactDynamicFields({
            emails: activeSiteDetails[contactType]?.emails ?? [],
            phones: activeSiteDetails[contactType]?.phones ?? [],
            otherPhones: activeSiteDetails[contactType]?.other_phones ?? [],
            fax: activeSiteDetails[contactType]?.fax ?? [],
            websites: activeSiteDetails[contactType]?.websites ?? [],
          }),
          firstName: activeSiteDetails[contactType]?.firstname ?? '',
          lastName: activeSiteDetails[contactType]?.lastname ?? '',
          title: activeSiteDetails[contactType]?.position ?? '',
          attn: '',
        }
      }

      setMainContactData(getContacts(2))
      setBillingContactData(getContacts(3))
      setBillingContactActive(
        activeSiteDetails?.default_contact_details?.id ===
          activeSiteDetails?.billing_contact_details?.id,
      )

      if (!!activeSiteDetails?.other_contacts) {
        const getOtherContacts = (otherContact: Api.ContactType) => {
          return {
            contactTypeId: 4,
            id: otherContact?.id,
            dynamicFields: getContactDynamicFields({
              emails: otherContact?.emails ?? [],
              phones: otherContact?.phones ?? [],
              otherPhones: otherContact?.other_phones ?? [],
              fax: otherContact?.fax ?? [],
              websites: otherContact?.websites ?? [],
            }),
            firstName: otherContact?.firstname ?? '',
            lastName: otherContact?.lastname ?? '',
            title: otherContact?.position ?? '',
            attn: '',
          }
        }

        const remappedOtherContacts = activeSiteDetails?.other_contacts.map(
          (otherContact) => {
            return getOtherContacts(otherContact)
          },
        )

        setOtherContactData(remappedOtherContacts)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSiteDetails])

  const onUpdateSiteDetails = (e: any) => {
    e.preventDefault()
    const {defaultContact, billingContact} = getAccumulatedContacts(
      mainContactData,
      billingContactData,
    )

    const otherContacts = otherContactData.map((contact) =>
      getRemappedContacts(contact, true),
    )

    const physicalAddress = finalAddressRemapper(physicalAddressData)

    const updatedPostalAddress = postalAddressActive
      ? {
          ...physicalAddress,
          id: activeSiteDetails?.physical_address_details?.id,
        }
      : {
          ...finalAddressRemapper(postalAddressData),
          id: activeSiteDetails?.postal_address_details?.id,
        }

    const updatedBillingContact = billingContactActive
      ? {
          ...defaultContact,
          id: activeSiteDetails?.default_contact_details?.id,
        }
      : {
          ...billingContact,
          id: activeSiteDetails?.billing_contact_details?.id,
        }

    const _requestData: any = {
      // name: customerName.name,
      info: {
        physicalAddress,
        postalAddress: postalAddressActive
          ? undefined
          : finalAddressRemapper(postalAddressData),
        defaultContact,
        billingContact: billingContactActive ? undefined : billingContact,
        otherContacts,
      },
    }

    const updateData: any = {
      // id: activeSiteDetails?.customer_details?.id,
      // name: activeSiteDetails?.default_contact_details?.,
      info: {
        // id: activeSiteDetails?.info_id,
        physicalAddress: {
          ...physicalAddress,
          id: activeSiteDetails?.physical_address_details?.id,
        },
        postalAddress: updatedPostalAddress,
        defaultContact: {
          ...defaultContact,
          id: activeSiteDetails?.default_contact_details?.id,
        },
        billingContact: updatedBillingContact,
        otherContacts,
      },
    }

    const requiredDatas = [
      defaultContact.firstname,
      defaultContact.lastname,
      defaultContact.position,
      physicalAddress.address,
      physicalAddress.city,
      physicalAddress.country === 'Select Country'
        ? ''
        : physicalAddress.country,
    ]

    const isAnyRequiredUnfilled = requiredDatas.some(
      (requiredData) => !requiredData,
    )

    if (
      // false &&
      !isAnyRequiredUnfilled
    ) {
      activeSiteDetails
        ? dispatch(
            updateSite(
              activeSiteDetails?.site_details?.id,
              updateData,
              (data) => {
                setSiteDetails(activeSiteDetails?.site_details?.id)
                setNewContactModal(false)
              },
              // toast
            ),
          )
        : dispatch(addSite(_requestData, () => {}, toast))
    } else {
      toast.error('Please fill all the required fields!')
    }
  }

  useEffect(() => {
    remapSiteDetails()
  }, [remapSiteDetails])

  return (
    <Modal
      isActive={addNewContactModal}
      size="md"
      toggleModal={() => setNewContactModal(false)}
      title="New Contact"
    >
      <div className="flex ">
        <div className="flex-1 w-full p-10">
          {/* //main contact */}
          <ContactTable
            contactProps={{
              contactData: mainContactData,
              setContactData: setMainContactData,
            }}
          />
        </div>
        <div className="flex-1 w-full p-10">
          {/* //physical address */}
          <div className="mb-32">
            <AddressTable
              addressProps={{
                addressData: physicalAddressData,
                addressHandler: physicalAddressHandler,
              }}
            />
          </div>
        </div>
      </div>

      <br></br>
      <div className="border-b-1 my-6 border-gray-200" />
      <div className="flex justify-end px-16 py-10">
        <button
          className="p-6 rounded-sm cursor-pointer text-sm border-1 hover:bg-gray-300 border-gray-200 bg-gray-200 px-22"
          onClick={() => setNewContactModal(false)}
        >
          Cancel
        </button>
        <div className="ml-16">
          <button
            className="p-6 rounded-sm cursor-pointer text-sm text-white hover:bg-blue-400 bg-blue-300 px-22"
            onClick={(e) => onUpdateSiteDetails(e)}
          >
            Save Contact
          </button>
        </div>
      </div>
    </Modal>
  )
}

// MARK: - Address
const AddressTable = ({addressProps: {addressData, addressHandler}}: any) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {countryOptions} = selectOptions
  const [addressValue, setAddressValue] = useState(null)

  const handleAddressDropChange = (item: any) => {
    setAddressValue(item)
    Geocode.setApiKey(REACT_APP_GOOGLE_KEY)
    Geocode.setLanguage('en')
    Geocode.fromAddress(item.label).then(
      async (response) => {
        const responseFromHelper = await addressRemapper(response)
        //

        const _aaddressData = {
          ...addressData,
          ...responseFromHelper,
        }

        const {
          address,
          city,
          countryId,
          lat,
          lng,
          placeId,
          state,
          zip,
          suburb,
          country,
        } = _aaddressData
        const {setValue} = addressHandler

        setValue('address', address)
        setValue('city', city)
        setValue('countryId', countryId)
        setValue('lat', lat)
        setValue('lng', lng)
        setValue('placeId', placeId)
        setValue('state', state)
        setValue('zip', zip)
        setValue('suburb', suburb)
        setValue('country', country)
      },
      (error) => {},
    )
  }

  return (
    <DescriptionBox title="Address">
      <table className="w-full ml-20">
        <tbody>
          <tr>
            <td>
              <div className="text-sm text-gray-400">Address *:</div>
            </td>
            <td>
              <Input
                name="address"
                size="sm"
                containerClass="pb-0"
                value={addressData?.address}
                className="text-sm hidden"
                onChange={addressHandler?.onChange}
                required
                error={
                  addressHandler?.modified?.address &&
                  addressData?.address?.length === 0
                }
              />
              <GooglePlacesAutocomplete
                apiKey={REACT_APP_GOOGLE_KEY}
                selectProps={{
                  value:
                    {
                      label: addressData?.address,
                      value: addressData?.address,
                    } ?? addressValue,
                  onChange: handleAddressDropChange,
                  styles: addressStyles(addressData?.address.length === 0),
                }}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="text-sm text-gray-400">Suburb:</div>
            </td>
            <td>
              <Input
                name="suburb"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                value={addressData?.suburb}
                onChange={addressHandler?.onChange}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="text-sm text-gray-400">City *:</div>
            </td>
            <td>
              <Input
                name="city"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                value={addressData?.city}
                error={
                  // addressHandler?.modified.city &&
                  addressData?.city.length === 0
                }
                onChange={addressHandler?.onChange}
              />
            </td>
            <td></td>
          </tr>

          <tr>
            <td>
              <div className="text-sm text-gray-400">State / Region:</div>
            </td>
            <td>
              <Input
                name="state"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                value={addressData?.state}
                onChange={addressHandler?.onChange}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="text-sm text-gray-400">Zip / Post Code:</div>
            </td>
            <td>
              <Input
                name="zip"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                value={addressData?.zip}
                onChange={addressHandler?.onChange}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td width="40%">
              <div className="text-sm text-gray-400">Country *:</div>
            </td>
            <td width="50%">
              <SelectField
                options={countryOptions}
                placeholder="Select country"
                getOptionLabel={'key'}
                getOptionValue={'value'}
                value={countryOptions.filter((country: any) => {
                  return country.value === addressData.countryId
                })}
                onChangeValue={(e) => {
                  addressHandler?.setValue('countryId', e.value)
                }}
                error={addressData?.countryId === 0}
              />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </DescriptionBox>
  )
}

const ContactTable = ({
  contactProps: {contactData, setContactData},
  billing,
}: any) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {contactTypeOptions} = selectOptions

  //? MAIN CONTACT CHANGE HANDLING
  const onContactInputChange = (e: any) =>
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value ?? undefined,
    })

  const onContactDynamicFieldChange = (
    e: any,
    key: 'value' | 'contactFieldId',
    i: any,
  ) => {
    const _contactData = {...contactData}

    const newDynamicFields = _contactData.dynamicFields.map(
      (dynamicField: any) => {
        if (dynamicField.dynamicFieldId === i) {
          return {
            ...dynamicField,
            [key]: key === 'contactFieldId' ? +e.target.value : e.target.value,
          }
        }

        return dynamicField
      },
    )

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}

    setContactData(newContactData)
  }

  const onContactDynamicFieldAddClick = (e: any) => {
    e.preventDefault()
    const _contactData = {...contactData}

    const {dynamicFields} = _contactData
    const dynamicField =
      _contactData.dynamicFields[_contactData.dynamicFields.length - 1]
    const newDynamicFields = [
      ...dynamicFields,
      {
        dynamicFieldId: dynamicField.dynamicFieldId + 1,
        contactFieldId: 1,
        value: undefined,
      },
    ]

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}
    setContactData(newContactData)
  }

  const onContactDynamicFieldRemoveClick = (e: any, i: any) => {
    e.preventDefault()
    const _contactData = {...contactData}

    const newDynamicFields = _contactData.dynamicFields.filter(
      (dynamicField: any) => dynamicField.dynamicFieldId !== i,
    )

    const newContactData = {..._contactData, dynamicFields: newDynamicFields}
    setContactData(newContactData)
  }

  return (
    <DescriptionBox title="Person">
      <table className="w-full ml-20">
        <tbody>
          {billing && (
            <tr>
              <td>
                <div className="text-sm text-gray-400">ATTN:</div>
              </td>
              <td>
                <Input
                  name="attn"
                  size="sm"
                  containerClass="pb-0"
                  className="text-sm"
                  value={contactData?.attn}
                  onChange={onContactInputChange}
                />
              </td>
              <td></td>
            </tr>
          )}
          <tr>
            <td>
              <div className="text-sm text-gray-400">First Name *:</div>
            </td>
            <td>
              <Input
                name="firstName"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                value={contactData?.firstName}
                error={contactData?.firstName?.length === 0}
                onChange={(e: any) =>
                  setContactData({
                    ...contactData,
                    [e.target.name]: capitalizeFirstLetterWithSpace(
                      e.target.value,
                    ),
                  })
                }
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="text-sm text-gray-400">Last Name *:</div>
            </td>
            <td>
              <Input
                name="lastName"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                error={contactData?.lastName?.length === 0}
                value={contactData?.lastName}
                onChange={(e: any) =>
                  setContactData({
                    ...contactData,
                    [e.target.name]: capitalizeFirstLetter(e.target.value),
                  })
                }
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td width="40%">
              <div className="text-sm text-gray-400">Title/Position *:</div>
            </td>
            <td width="50%">
              <Input
                name="title"
                size="sm"
                containerClass="pb-0"
                className="text-sm"
                error={contactData?.title?.length === 0}
                value={contactData?.title}
                onChange={onContactInputChange}
              />
            </td>
            <td></td>
          </tr>
          {contactData?.dynamicFields?.map((dynamicField: any, id: number) => {
            return (
              <tr key={dynamicField.dynamicFieldId}>
                <td>
                  <div className="text-sm text-gray-400">
                    <Select
                      name="contactFieldId"
                      options={contactTypeOptions}
                      size="sm"
                      containerClass="pb-0"
                      className="text-sm"
                      value={dynamicField.contactFieldId}
                      onChange={(e: any) =>
                        onContactDynamicFieldChange(
                          e,
                          'contactFieldId',
                          dynamicField.dynamicFieldId,
                        )
                      }
                    />
                  </div>
                </td>
                <td>
                  <Input
                    name="value"
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    value={dynamicField.value}
                    onChange={(e: any) =>
                      onContactDynamicFieldChange(
                        e,
                        'value',
                        dynamicField.dynamicFieldId,
                      )
                    }
                  />
                </td>
                <td>
                  <div className="flex">
                    {contactData?.dynamicFields[id]?.value === undefined ? (
                      <span className="cursor-not-allowed text-gray-400">
                        <HiPlusSm />
                      </span>
                    ) : contactData?.dynamicFields?.length === id + 1 ? (
                      <span className="cursor-pointer">
                        <HiPlusSm onClick={onContactDynamicFieldAddClick} />
                      </span>
                    ) : (
                      <span className="cursor-pointer">
                        <HiMinusSm
                          onClick={(e: any) =>
                            onContactDynamicFieldRemoveClick(
                              e,
                              dynamicField.dynamicFieldId,
                            )
                          }
                        />
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </DescriptionBox>
  )
}
