import {
  ALTER_FORM_BUILDER_CONTENT,
  ALTER_FORM_BUILDER_SETTINGS,
  DELETE_FORM_ELEMENT,
  ON_ADD_FORM_SECTIONS,
  REMOVE_ASSET_IDS,
  UPDATE_FORM_FROM_API,
  UPDATE_FORM_LAYOUTS
  // ON_SELECT_FORM_SECTION
} from 'redux-src/actions'
import {groupArrayBasedOnY} from 'utils'

const initialState: RT.FormBuilderReduxType = {
  forms: [],
  formsForDesktopPreview: undefined,
  formName: '',

  layouts: [],

  addedFieldIds: [],
  updatedFieldIds: [],
  deletedFieldIds: [],

  isFormAltered: false,

  deletedAssetIds: []
}

export function formBuilderReducer(state = initialState, action: any) {
  const {type, payload} = action

  switch (type) {
    case 'UPDATE_FORMS_FOR_PREVIEW': {
      const formsForDesktopPreview = groupArrayBasedOnY(payload.forms)

      return {
        ...state,
        formsForDesktopPreview
      }
    }

    case UPDATE_FORM_FROM_API.SUCCESS: {
      return {
        ...state,
        forms: payload.forms,
        formName: payload.formName,
        layouts: payload.layouts ?? [],
        addedFieldIds: [] as any,
        updatedFieldIds: [] as any,
        deletedFieldIds: [] as any,
        isFormAltered: false
      }
    }

    case ON_ADD_FORM_SECTIONS.SUCCESS:
      return {
        ...state,
        forms: payload.forms,
        layouts: payload.layouts ?? [],
        addedFieldIds: [...new Set([...state.addedFieldIds, payload.addedId])],
        isFormAltered: true
      }

    case UPDATE_FORM_LAYOUTS.SUCCESS:
      return {
        ...state,
        forms: payload.updatedFormElement,
        layouts: payload.layouts ?? [],
        addedFieldIds: payload.addedId
          ? [...new Set([...state.addedFieldIds, payload.addedId])]
          : [...new Set([...state.addedFieldIds])],
        isFormAltered: true
      }

    case ALTER_FORM_BUILDER_CONTENT.SUCCESS: {
      return {
        ...state,
        forms: payload.updatedFormElement,
        updatedFieldIds: [
          ...new Set([...state.updatedFieldIds, payload.updatedId])
        ],
        isFormAltered: true
      }
    }

    case ALTER_FORM_BUILDER_SETTINGS.SUCCESS: {
      return {
        ...state,
        forms: payload.updatedFormElement,
        layouts: payload.layouts,
        updatedFieldIds: [
          ...new Set([...state.updatedFieldIds, payload.updatedId])
        ],
        isFormAltered: true
      }
    }

    case DELETE_FORM_ELEMENT.SUCCESS:
      return {
        ...state,
        forms: payload.updatedFormElement,
        layouts:
          payload.updatedFormElement &&
          Array.isArray(payload.updatedFormElement) &&
          payload.updatedFormElement.length > 0
            ? payload.updatedFormElement?.map(
                (formElement: RT.FormElementType) => formElement.layout
              )
            : state.layouts,
        deletedFieldIds: [
          ...new Set([...state.deletedFieldIds, payload.deletedId])
        ],
        isFormAltered: true
      }

    case REMOVE_ASSET_IDS.SUCCESS:
      return {
        ...state,
        deletedAssetIds: [...new Set([...state.deletedAssetIds, payload])]
      }

    default:
      return state
  }
}
