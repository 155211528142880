import {useFormInput} from 'use-form-input'
import {useNavigation, useAuth} from 'react-auth-navigation'
import {useSelector, useDispatch} from 'react-redux'

import {Logo, Input, Loading, Select, SelectField} from 'app/common'
import {DownloadApk, LandingSection} from 'app/components'
import {emailValidation, passwordValidation} from 'utils'
import {getCountry, getIndustry, getStaff} from 'helpers'
import {register} from 'redux-src'

export const RegisterPage = () => {
  const {toast} = useAuth()
  const dispatch = useDispatch()
  const {loading, error} = useSelector((state: any) => state.register)

  const {navigation} = useNavigation()
  const {routes, navigate} = navigation

  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {countryOptions, industryTypeOptions, companyCategoryOptions} =
    selectOptions

  const [data, {onChange, errors, onSubmit, modified, setValue}] = useFormInput(
    {
      displayName: '',
      emailAddress: '',
      password: '',
      companyName: '',
      mobileNumber: 0,
      country: 0,
      companyCategory: 0,
      industryType: 0
    },
    (data) => {
      const handleRegister = () => {
        navigate(routes['Root'].path)
      }

      const {
        displayName,
        emailAddress,
        password,
        companyName,
        mobileNumber,
        country,
        companyCategory,
        industryType
      } = data
      const requestBody = {
        displayName,
        companyName,
        email: emailAddress,
        mobile: Number(mobileNumber),
        password,
        country: getCountry(country),
        numberOfStaffs: getStaff(companyCategory),
        industry: getIndustry(industryType)
      }

      // // console.log('register request body: ', JSON.stringify(requestBody))
      dispatch(register(requestBody, handleRegister, toast))
    },
    (data) => {
      const errors: any = {}
      if (data.displayName.length === 0) {
        errors.displayName = 'Empty display name!'
      }

      if (data.emailAddress.length === 0) {
        errors.emailAddress = 'Empty email address!'
      } else if (emailValidation(data.emailAddress)) {
        errors.emailAddress = 'Enter a valid email address!'
      }

      const {condition, message} = passwordValidation(data.password)

      if (data.password.length === 0) {
        errors.password = 'Empty password!'
      } else if (condition) {
        errors.password = message
      }

      if (data.companyName.length === 0) {
        errors.companyName = 'Empty company name!'
      }
      if (data.mobileNumber === 0) {
        errors.mobileNumber = 'Empty mobile number!'
      }
      if (data.country === 0) {
        errors.country = 'Empty country field!'
      }
      if (data.companyCategory === 0) {
        errors.companyCategory = 'Empty staff field!'
      }
      if (data.industryType === 0) {
        errors.industryType = 'Empty industry!'
      }

      return errors
    }
  )

  return (
    <div className="min-h-screen bg-white flex flex-row w-full">
      <div className="flex flex-col items-center w-full lg:w-5/12 exlg:w-4/12 xl:w-3/12 justify-center py-12 px-4 lg:flex-none h-auto overflow-x-hidden overflow-y-auto">
        {loading ? (
          <Loading />
        ) : (
          <div className="px-20 lg:px-40 xl:px-56 w-full">
            <div className="mb-32 text-center">
              <div className="flex justify-center">
                <Logo />
              </div>
              <div className="mt-6 text-xl mx-[20px] font-bold  text-gray-900 inline-flex flex-nowrap w-full">
                Register to Smart Clerk
              </div>
              <p className="text-gray-400 leading-tight">
                Join to Smart Clerk to effectively manage your tasks.
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                {error && (
                  <p className="bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                    {error}
                  </p>
                )}
                <form className="-mx-16 mb-24" onSubmit={onSubmit}>
                  <Input
                    label="Display Name"
                    name="displayName"
                    placeholder="Enter display name"
                    type="text"
                    error={modified.displayName && errors.displayName}
                    onChange={onChange}
                  />
                  <Input
                    label="Email Address"
                    name="emailAddress"
                    placeholder="Enter email Address"
                    type="text"
                    error={modified.emailAddress && errors.emailAddress}
                    onChange={onChange}
                  />
                  <Input
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    type="password"
                    error={modified.password && errors.password}
                    onChange={onChange}
                  />

                  <div className="flex gap-4">
                    <Input
                      label="Company Name"
                      name="companyName"
                      placeholder="Company name"
                      type="text"
                      error={modified.companyName && errors.companyName}
                      onChange={onChange}
                    />
                    <Input
                      label="Mobile Number"
                      name="mobileNumber"
                      placeholder="Mobile number"
                      type="number"
                      error={modified.mobileNumber && errors.mobileNumber}
                      onChange={onChange}
                    />
                  </div>

                  <div className="flex flex-col gap-6 mb-18">
                    <span>
                      Country<span className="text-red-300 ml-6">*</span>
                    </span>
                    <SelectField
                      getOptionValue={'value'}
                      getOptionLabel={'key'}
                      options={countryOptions}
                      value={countryOptions?.find((item: any) => {
                        return item.value === data.country
                      })}
                      placeholder="Select Country"
                      onChangeValue={(e) => {
                        setValue('country', e?.value)
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-6 mb-18">
                    <span>
                      Staff<span className="text-red-300 ml-6">*</span>
                    </span>
                    <SelectField
                      getOptionValue={'value'}
                      getOptionLabel={'key'}
                      options={companyCategoryOptions}
                      value={companyCategoryOptions?.find((item: any) => {
                        return item.value === data.companyCategory
                      })}
                      onChangeValue={(e) => {
                        setValue('companyCategory', e?.value)
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-6 mb-18">
                    <span>
                      Industry<span className="text-red-300 ml-6">*</span>
                    </span>
                    <SelectField
                      getOptionValue={'value'}
                      getOptionLabel={'key'}
                      options={industryTypeOptions}
                      value={industryTypeOptions?.find((item: any) => {
                        return item.value === data.industryType
                      })}
                      onChangeValue={(e) => {
                        setValue('industryType', e?.value)
                      }}
                    />
                  </div>

                  {/* <div className="pl-18">
                                <div className="flex">
                                    <Checkbox className="mt-4" type="checkbox" id="tnc" onChange={handleTnCChange} />
                                    <label className="mb-10 text-sm" htmlFor="tnc">I agree to Smartplan's
                                <a href="/terms-of-service" className="text-blue-300 mr-6 ml-6">Terms of Service</a>
                                 and
                                <a href="/privacy-policy" className="text-blue-300 ml-6">Privacy Policy.</a></label>
                                </div>
                                <div className="text-sm text-red-300 -pt-10">{errors && errors.tnc}</div>
                            </div> */}

                  <div className="px-16 mt-10">
                    <button
                      type="submit"
                      className="bg-blue-300 w-full hover:bg-blue-400 py-10 px-20 lg:px-56 rounded-sm text-white font-medium text-sm "
                    >
                      Create Trial Account
                    </button>
                  </div>
                </form>
                <div
                  className="w-[max-content] block text-gray-400 hover:text-blue-300 font-medium cursor-pointer"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault()
                    navigate(routes['Root'].path)
                  }}
                >
                  Already an account?{' '}
                  <span className="text-sm font-bold uppercase text-blue-300 hover:text-blue-400">
                    LogIn here
                  </span>
                </div>
                <div className="mt-10">
                  <DownloadApk />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <LandingSection />
    </div>
  )
}
