import {useCallback} from 'react'
import moment from 'moment'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {FaTrash} from 'react-icons/fa'

import {DataTable} from 'app/components'
import {ConfirmationModal} from 'app/common'
import {getCompanyUserGroupList} from 'redux-src'

export const GroupTable = ({groups, onEditGroup, search}: any) => {
  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const {companyUserGroupListTotal}: RT.UserReduxType = useSelector(
    (state: any) => state.user
  )
  const dispatch = useDispatch()

  const getCompanyUserGroupListPagination = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getCompanyUserGroupList({page, limit, search}))
    },
    [dispatch, search]
  )

  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div className="flex items-center" onClick={() => onEditGroup(row.id)}>
          <div
            className="p-14 text-white rounded-full mr-10  relative"
            style={{backgroundColor: row.colorCode}}
          >
            <span className="absolute inset-0 flex items-center justify-center">
              {row.name?.charAt(0)?.toUpperCase()}
            </span>
          </div>
          <div
            // onClick={() => handelGroupEdit(row)}
            className="text-blue-400 cursor-pointer"
          >
            {row.name}
          </div>
        </div>
      )
    },
    {
      name: 'Date Created',
      selector: (row) => row.dateCreated,
      sortable: true,
      cell: (row: any) => (
        <div className="relative">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.id}`}
            className={`flex justify-between items-center text-black'
            }`}
            // style={{ minWidth: '210px', maxWidth: '240px' }}
          >
            <div className="">{moment(row.dateCreated).format('ddd, LL')}</div>
          </div>
        </div>
      )
    },
    {
      name: 'Users',
      selector: (row) => row.usersCount,
      sortable: true,
      cell: (row) => (
        <div className="flex items-center">
          <div className="p-14 bg-blue-200 text-white rounded-full mr-10  relative">
            <span className="absolute inset-0 flex items-center justify-center">
              {row.usersCount}
            </span>
          </div>
        </div>
      )
    }
  ]

  return (
    <div className="mt-20">
      <DataTable
        columns={columns}
        data={groups}
        defaultSortFieldId={2}
        pagination
        persistTableHead
        dense
        totalRows={+companyUserGroupListTotal}
        fetchData={getCompanyUserGroupListPagination}
      />
    </div>
  )
}

export const GroupSelectedAppUserTable = ({
  selectedAppUsers,
  onDelete
}: any) => {
  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div
          className="flex flex-nowrap justify-between items-center relative "
          style={{minWidth: '100%', maxWidth: '100%'}}
        >
          <div className="flex items-center ">
            <div
              className="p-14 text-white rounded-full mr-10  relative"
              style={{backgroundColor: 'gray'}}
            >
              <span className="absolute inset-0 flex items-center justify-center">
                {row.name?.charAt(0)?.toUpperCase()}
              </span>
            </div>
            <div className="text-blue-400 cursor-pointer">
              {row.name} {row.lastname}
            </div>
          </div>

          <ConfirmationModal
            onConfirmClick={(closeModalHandler) => {
              onDelete(row.id)
              closeModalHandler()
            }}
            danger
            label={'Are you sure you want to remove this User from this Group?'}
            displayElement={
              <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                <span className="text-left inline-flex cursor-pointer">
                  <FaTrash fill="red" size={12} />
                </span>
              </button>
            }
            confirmLabel="Delete"
          />
        </div>
      )
    }
  ]

  return (
    <>
      <DataTable
        columns={columns}
        data={selectedAppUsers}
        defaultSortFieldId={1}
        persistTableHead
        dense
        // paginationComponentOptions={{noRowsPerPage: true}}
      />
    </>
  )
}
