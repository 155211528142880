import {useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'
import {useDispatch, useSelector} from 'react-redux'

import {DescriptionBox} from 'app/components'
import {Button, Input, Modal, SelectField} from 'app/common'

import {PasswordValidationInfo} from '../../../../components'
import {addNewUserSelectOptions} from '../../constants'
import {AddressTable} from 'app/pages/customers/components/customerModal/customerModal.component'
import {createCompanyUserAction, getCompanyUserList} from 'redux-src'
import {useInput, validateEmail, validatePassword} from 'hooks'
import {toast} from 'app/common'

interface AddNewUserModalType {
  isActive: boolean
  close: () => void
}
export const AddNewUserModal = ({isActive, close}: AddNewUserModalType) => {
  const {getCreateCompanyUserLoading}: RT.UserReduxType = useSelector(
    (state: any) => state.user
  )

  const {currencySymbol} = useAuth()

  const [userType, setUserType] = useState<string>('')

  const [physicalAddressData, physicalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 1
  })

  const {data, onChangeHandler, getErrors, error} = useInput({
    userType: '',
    firstName: '',
    lastName: '',
    loginEmail: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    payRates: ''
  })

  const dispatch = useDispatch()

  const onCreateCompanyUser = () => {
    const {
      confirmPassword,
      firstName,
      lastName,
      loginEmail,
      password,
      payRates,
      phoneNumber
    } = data

    const {errorFlag} = getErrors([
      {
        key: 'firstName',
        condition: firstName.length === 0,
        cb: () => {
          toast.error(`Please enter your first name.`)
        }
      },
      {
        key: 'lastName',
        condition: lastName.length === 0,
        cb: () => {
          toast.error(`Please enter your lastname.`)
        }
      },
      {
        key: 'loginEmail',
        condition: validateEmail(loginEmail),
        cb: () => {
          toast.error(`Please enter valid email.`)
        }
      },
      {
        key: 'payRates',
        condition: payRates.length === 0,
        cb: () => {
          toast.error(`Please enter pay rates.`)
        }
      },
      {
        key: 'password',
        condition: validatePassword(password).condition,
        cb: (msg: any) => {
          toast.error(msg ?? `Please enter vaid password.`)
        }
      },
      {
        key: 'confirmPassword',
        condition: password !== confirmPassword,
        cb: () => {
          toast.error(`Password doesnt match.`)
        }
      }
    ])

    if (errorFlag) {
      return toast.error('Please fill up the required fields !!!')
    }

    const body = {
      displayName: firstName,
      lastname: lastName,
      role: userType,
      phone: Number(phoneNumber),
      email: loginEmail,
      password: password,
      confirmPassword: confirmPassword,
      chargeOutRate: payRates,
      addressDetails: {
        address: physicalAddressData?.address,
        city: physicalAddressData?.city,
        country: physicalAddressData?.country
      }
    }

    dispatch(
      createCompanyUserAction(body, () => {
        close()
        dispatch(getCompanyUserList())
      })
    )
  }

  return (
    <Modal
      isActive={isActive}
      toggleModal={close}
      title="Add a new user"
      size="md"
    >
      <form className="relative px-20 my-6 mb-0 overflow-y-auto max-h-[500px] ">
        <div className="px-14">
          {/* <InfoBar text="Prices per user type are based on your current Fergus account plan:Free" /> */}

          <div className="my-16">
            <h2 className="text-lg font-bold text-gray-500 mb-16">
              User details
            </h2>

            {/* FORM */}
            <div className="flex gap-18">
              <div className="flex flex-col gap-6 w-6/12">
                <div>
                  User Type <span className="text-red-300 px-6">*</span>
                </div>
                <SelectField
                  options={addNewUserSelectOptions}
                  onChangeValue={(e) => {
                    setUserType(e.value)
                  }}
                  placeholder="Select User Type"
                  className="h-[30px] w-full"
                />
              </div>

              <div className="flex text-gray-500 w-6/12">
                <Input
                  label="Standard charge out rate"
                  size="sm"
                  prepend={currencySymbol}
                  className="rounded-tr-sm rounded-br-sm h-[32px]"
                  placeholder={`Standard charge out rate`}
                  name="payRates"
                  onChange={onChangeHandler('payRates')}
                  error={error['payRates']}
                  required
                />
              </div>
            </div>

            {/* FIRSTNAME AND LASTNAME */}
            <div className="flex mt-16 gap-16 text-gray-500">
              <Input
                label="Firstname"
                placeholder="John"
                size="sm"
                name="firstName"
                onChange={onChangeHandler('firstName')}
                error={error['firstName']}
                required
              />
              <Input
                label="Lastname"
                name="lastName"
                placeholder="Smith"
                size="sm"
                onChange={onChangeHandler('lastName')}
                error={error['lastName']}
                required
              />
            </div>

            <div>
              <div className="text-gray-500 mt-16">
                <Input
                  label="Login email"
                  placeholder="john@gmail.com"
                  size="sm"
                  name="loginEmail"
                  onChange={onChangeHandler('loginEmail')}
                  error={error['loginEmail']}
                  required
                />
              </div>
              <div className="text-gray-500 mt-16">
                <Input
                  label="Phone number"
                  placeholder="Enter phone number"
                  size="sm"
                  type="number"
                  name="phoneNumber"
                  onChange={onChangeHandler('phoneNumber')}
                  error={error['phoneNumber']}
                  optional
                />
              </div>

              <div className="mb-14">
                <DescriptionBox
                  title={
                    <div className="text-sm text-gray-700 ml-20">
                      <h4 className="flex font-bold text-md justify-between">
                        Address
                      </h4>
                    </div>
                  }
                >
                  {physicalAddressHandler?.errors.address && (
                    <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                      {physicalAddressHandler?.errors.address}
                    </p>
                  )}
                  <AddressTable
                    addressProps={{
                      addressData: physicalAddressData,
                      addressHandler: physicalAddressHandler
                    }}
                  />
                </DescriptionBox>
              </div>
            </div>

            <div>
              <h1 className="text-lg font-bold text-gray-500 mb-8">
                Create a password
              </h1>
              <PasswordValidationInfo />
            </div>

            <div className="flex mt-16 gap-16 text-gray-500">
              <Input
                type="password"
                label="Password"
                name="password"
                placeholder="********"
                size="sm"
                onChange={onChangeHandler('password')}
                error={error['password']}
              />
              <Input
                type="password"
                label="Confirm password"
                placeholder="********"
                size="sm"
                name="confirmPassword"
                onChange={onChangeHandler('confirmPassword')}
                error={error['confirmPassword']}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="border-t-1 border-gray-200 "></div>

      <div className="flex justify-end px-16 py-10">
        <Button
          title="cancel"
          buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
          size="sm"
          type="button"
          onClick={close}
        />
        <div className="ml-16">
          <Button
            title="Save"
            size="sm"
            type="submit"
            onClick={onCreateCompanyUser}
            loading={getCreateCompanyUserLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
