import {
  GET_JOB_LIST,
  ADD_JOB,
  JOB_DETAIL_BY_ID,
  SIGNED_OFF_LIST,
} from '../actions'

const initalState: any = {
  jobList: [],
  jobListTotal: 0,
  jobListLoading: false,

  add_loading: false,
  add_error: '',

  jobDetailLoading: false,
  jobDetail: {},

  signedJobListLoading: false,
  signedJobList: [],
}

export function jobsReducer(state = initalState, action: any) {
  const { type, payload } = action

  switch (type) {
    case ADD_JOB.LOADING:
      return { ...state, add_loading: true, add_error: false }
    case ADD_JOB.SUCCESS:
      return {
        ...state,
        add_loading: false,
        add_error: '',
      }
    case ADD_JOB.ERROR:
      return { ...state, add_loading: false, add_error: payload }

    case GET_JOB_LIST.LOADING:
      return { ...state, jobListLoading: true }
    case GET_JOB_LIST.SUCCESS:
      console.log('payload: ', payload)
      return {
        ...state,
        jobListLoading: false,
        jobList: payload.rows,
        jobListTotal: +payload.total,
      }
    case GET_JOB_LIST.ERROR:
      return { ...state, jobListLoading: false }

    case JOB_DETAIL_BY_ID.LOADING:
      return { ...state, jobDetailLoading: true }
    case JOB_DETAIL_BY_ID.SUCCESS:
      return {
        ...state,
        jobDetailLoading: false,
        jobDetail: payload,
      }
    case JOB_DETAIL_BY_ID.ERROR:
      return { ...state, jobDetailLoading: false }

    case SIGNED_OFF_LIST.LOADING:
      return { ...state, signedJobListLoading: true }
    case SIGNED_OFF_LIST.SUCCESS:
      return {
        ...state,
        signedJobListLoading: false,
        signedJobList: payload,
      }
    case SIGNED_OFF_LIST.ERROR:
      return { ...state, signedJobListLoading: false }

    default:
      return state
  }
}
