import React, { useState } from 'react'
import { TableColumn, TableStyles } from 'react-data-table-component'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth, useNavigation } from 'react-auth-navigation'
import { FaPen } from 'react-icons/fa'
import colors from 'tailwindcss/colors'

import { DataTable } from 'app/components'
import { ConfirmationModal } from 'app/common'
import {
  deleteItemCategoryAction,
  getItemCategoryListAction,
  getItemTypeByIdAction,
  updateItemTypeAction,
} from 'redux-src'

import { ItemDetailsModal } from '../itemTypeModal'

export const ItemAttributes = ({
  items,
  customStyles,
}: {
  items: any
  customStyles: TableStyles
}) => {
  const dispatch = useDispatch()

  const { params } = useNavigation()
  const { itemId } = params as any

  const {
    updateItemTypeLoading,
    getItemTypeByIdLoading,
  }: RT.ItemTypeReduxType = useSelector((state: any) => state.itemTypes)

  const remappedAttributes = items?.attributes?.map(
    (item: string, index: number) => {
      return { id: index + 1, name: item }
    }
  )

  const toast = useAuth()

  const deleteAttributeHandler = (
    index: number,
    closeModalHandler: () => void
  ) => {
    console.log(updateItemTypeLoading, 'updateItemTypeLoading')

    const remainingAttributes = remappedAttributes.filter(
      (_: any, i: any) => i + 1 !== index
    )

    const newAttribute = remainingAttributes.map((item: any, index: number) => {
      return item.name
    })

    dispatch(
      updateItemTypeAction(
        itemId,
        {
          name: items.name,
          wastagePer: items.wastage_per,
          attributes: newAttribute,
        },
        toast,
        () => {
          closeModalHandler()
          dispatch(getItemTypeByIdAction(itemId))
        }
      )
    )
  }

  const columns: TableColumn<{
    id: number
    name: string
  }>[] = [
    {
      name: `${items?.name} Attributes`,
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-between items-center">
          <span>{row.name}</span>

          <ConfirmationModal
            loading={updateItemTypeLoading}
            onConfirmClick={(closeModalHandler) => {
              deleteAttributeHandler(row.id, closeModalHandler)
            }}
            danger
            label={'Are you sure you want to delete this Attribute?'}
            displayElement={
              <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100">
                <span className="text-left inline-flex cursor-pointer">
                  <RiDeleteBin5Fill color={colors.gray[900]} size={16} />
                </span>
              </button>
            }
            confirmLabel="Delete Attribute"
          ></ConfirmationModal>
        </div>
      ),
    },
  ]

  return (
    <div>
      <DataTable
        columns={columns}
        data={remappedAttributes}
        customStyles={customStyles}
        loading={getItemTypeByIdLoading}
      />
    </div>
  )
}

export const ItemCategories = ({
  items,
  customStyles,
  name,
}: {
  items: Api.ItemCategoryIndividual[]
  customStyles: any

  name: string
}) => {
  const [showCategory, setShowCategory] = useState(false)
  const toast = useAuth()

  const [singleCategoryData, setSingleCategoryData] = useState<any>()

  const editCategoryHandler = (row: Api.ItemCategoryIndividual) => {
    setShowCategory(true)
    setSingleCategoryData(row)
  }

  const { deleteItemCategoryLoading, getItemCategoryListLoading } = useSelector(
    (state: any) => state.itemTypes
  )

  const dispatch = useDispatch()
  const columns: TableColumn<{
    item_type_id: number
    id: number
    name: string
  }>[] = [
    {
      name: `${name} Categories`,
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div className=" flex justify-between items-center">
          <span>{row.name}</span>

          <div className="flex justify-between items-center gap-x-10">
            <button
              className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100"
              onClick={() => editCategoryHandler(row)}
            >
              <span className="text-left inline-flex cursor-pointer">
                <FaPen size={14} className="cursor-pointer text-gray-400" />{' '}
              </span>
            </button>

            <ConfirmationModal
              loading={deleteItemCategoryLoading}
              onConfirmClick={(closeModalHandler) => {
                dispatch(
                  deleteItemCategoryAction(row.id, toast, () => {
                    closeModalHandler()
                    dispatch(getItemCategoryListAction(row.item_type_id))
                  })
                )
              }}
              danger
              label={'Are you sure you want to delete this Category?'}
              displayElement={
                <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100">
                  <span className="text-left inline-flex cursor-pointer">
                    <RiDeleteBin5Fill color={colors.gray[900]} size={14} />
                  </span>
                </button>
              }
              confirmLabel="Delete Category"
            ></ConfirmationModal>
          </div>
        </div>
      ),
    },
  ]

  return (
    <div>
      <DataTable
        columns={columns}
        data={items}
        customStyles={customStyles}
        loading={getItemCategoryListLoading}
      />

      <ItemDetailsModal
        mode="edit"
        title={name}
        showCategoryModal={showCategory}
        close={() => setShowCategory(false)}
        categoryDetails={singleCategoryData}
      />
    </div>
  )
}
