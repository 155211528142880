// import React from "react";

export const Checkbox = ({
  type,
  dataId,
  label,
  value,
  name,
  isChecked,
  className,
  onChange,
  disabled,
  labelClassName = 'text-sm text-gray-500',
  inputClassName,
  ...rest
}: Com.CheckboxProps) => {
  return (
    <div className={`c-checkbox relative ${className}`}>
      <input
        type={type ?? 'checkbox'}
        checked={isChecked}
        id={dataId}
        value={value}
        name={name}
        disabled={disabled}
        className={`${disabled ? 'text-gray-300' : ''} ${inputClassName ?? ''}`}
        onChange={(e) => onChange(value, e)}
        {...rest}
      />

      <label
        className={`block pl-20 cursor-pointer w-[max-content] ${labelClassName} ${
          disabled ? 'text-gray-300 pointer-events-none' : ''
        }`}
        htmlFor={dataId}
        style={{
          paddingTop: 1.5,
        }}
      >
        {label}
      </label>
    </div>
  )
}
