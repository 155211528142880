import { ADD_GROUP, GET_GROUP } from '../actions'

const initialState = {
	groups: [{}],
	add_loading: false,
	add_error: '',

	get_loading: false,
	get_error: ''
}

export function groupReducer(state = initialState, action: any) {
	const { type, payload } = action

	//

	switch (type) {
		case ADD_GROUP.LOADING:
			return { ...state, add_loading: true, add_error: false }
		case ADD_GROUP.SUCCESS:
			return {
				...state,
				add_loading: false,
				add_error: false
			}
		case ADD_GROUP.ERROR:
			return { ...state, add_loading: false, add_error: payload }

		case GET_GROUP.LOADING:
			return {
				...state,
				get_loading: true,
				get_error: false
			}
		case GET_GROUP.SUCCESS:
			return {
				...state,
				get_loading: false,
				get_error: false,
				groups: payload
			}
		case GET_GROUP.ERROR:
			return { ...state, get_loading: false, get_error: payload }

		default:
			return state
	}
}
