import {PlanPositionMap} from 'app/pages/jobs/pages/planViewer/components'
import {useCallback, useEffect, useMemo} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {
  getProjectPlanList,
  getTicketDetailsById,
  setMarkerCoordinateAction
} from 'redux-src'

export const TicketPlanPositionWebView = () => {
  const {projectPlanList}: RT.ProjectPlanReduxType = useSelector(
    (state: any) => state.projectPlan
  )

  const {ticketDetailsById}: RT.TicktesReduxType = useSelector(
    (state: any) => state.tickets
  )

  const {params} = useNavigation()
  const {projectId, ticketId, ticketPlanId} = params as any

  const dispatch = useDispatch()

  const selectedPlan = useMemo(() => {
    const floorPlanDetails = projectPlanList?.find(
      (floorPlan) =>
        floorPlan?.project_plan_details?.id === Number(ticketPlanId)
    )

    return floorPlanDetails
  }, [projectPlanList, ticketPlanId])

  const ticketStatus: 'in progress' | 'open' | 'feedback' | 'completed' =
    useMemo(() => {
      return (
        (ticketDetailsById?.ticket_details?.status as
          | 'in progress'
          | 'open'
          | 'feedback'
          | 'completed') ?? 'in progress'
      )
    }, [ticketDetailsById?.ticket_details?.status])

  const fetchTicketPlanDetails = useCallback(() => {
    projectId && dispatch(getTicketDetailsById(projectId, ticketId))
    ticketPlanId && dispatch(getProjectPlanList(projectId))
  }, [dispatch, projectId, ticketId, ticketPlanId])

  useEffect(() => {
    fetchTicketPlanDetails()
  }, [fetchTicketPlanDetails])

  useEffect(() => {
    if (ticketDetailsById?.ticket_details?.layer_coordinates !== null) {
      dispatch(
        setMarkerCoordinateAction(
          {
            x: ticketDetailsById?.ticket_details?.layer_coordinates[0],
            y: ticketDetailsById?.ticket_details?.layer_coordinates[1]
          },
          {
            x: ticketDetailsById?.ticket_details?.report_layer_coordinates[0],
            y: ticketDetailsById?.ticket_details?.report_layer_coordinates[1]
          }
        )
      )
    }
  }, [
    dispatch,
    ticketDetailsById?.ticket_details?.layer_coordinates,
    ticketDetailsById?.ticket_details?.report_layer_coordinates
  ])

  return (
    <div className="flex flex-col">
      <PlanPositionMap
        mapContainerHeight={'100dvh'}
        plan={selectedPlan}
        ticketStatus={ticketStatus}
        edit
        planCallback={fetchTicketPlanDetails}
      />
    </div>
  )
}
