import {Checkbox} from 'app/common'
import {getAssetUrl} from 'helpers'
import {useIndividualFormElement} from 'hooks'
import {useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

interface PhotoPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

interface SignaturePreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

interface VideoPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

interface ImagePreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

interface ImageContentType {
  label: string
  description: string
  value: string
}

export const PhotoPreview = ({id, type}: PhotoPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  const [isMulti, setIsMulti] = useState<boolean>(false)

  const [photoContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(alterFormContent(id, photoContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoContent])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="text-md text-black flex items-center justify-between">
        <div>
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>

        {type === 'web view' && (
          <div className="">
            <Checkbox
              label={'Multiple Photos'}
              dataId={`multiple-photos-${id}`}
              isChecked={isMulti}
              onChange={() => {
                setIsMulti((prev) => !prev)
              }}
            />
          </div>
        )}
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}

      {!!!individualElement?.assets ? (
        <div className="h-[100px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center">
          <span className="h-full w-full flex items-center justify-center text-gray-400 px-20">
            End user completing the form will put their photo here{' '}
          </span>
        </div>
      ) : (
        <div className="min-h-[150px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center gap-8">
          {individualElement?.assets?.slice(0, 2)?.map((image) => {
            return (
              <div
                className="h-[140px] w-[160px] rounded-sm bg-blue-100"
                key={image?.asset_details?.id}
              >
                <img
                  src={getAssetUrl(image?.asset_details)}
                  alt={image?.asset_details?.label}
                  className="h-full w-full object-contain"
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export const SignaturePreview = ({id, type}: SignaturePreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  const [signatureContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(alterFormContent(id, signatureContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureContent])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="flex justify-between items-center">
        <div className="text-md text-black">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}
      {!!!individualElement?.assets ? (
        <div className="h-[100px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center">
          <span className="h-full w-full flex items-center justify-center text-gray-400 px-20">
            End user completing the form will put their signature here{' '}
          </span>
        </div>
      ) : (
        <div className="min-h-[150px]  w-full border border-1 border-solid border-gray-200 flex gap-8 items-center justify-center">
          {individualElement?.assets?.slice(-1)?.map((signature) => {
            console.log({iE: individualElement?.assets?.slice(-1)})
            return (
              <div
                className="h-[140px] w-[160px] bg-blue-100"
                key={signature?.asset_details?.id}
              >
                <img
                  src={getAssetUrl(signature?.asset_details)}
                  alt={signature?.asset_details?.label}
                  className="h-full w-full object-contain"
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export const VideoPreview = ({id, type}: VideoPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  const [videoContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType
  )
  const [isMulti, setIsMulti] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    videoContent && dispatch(alterFormContent(id, videoContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoContent])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="text-md text-black flex items-center justify-between">
        <div>
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
        {type === 'web view' && (
          <div className="">
            <Checkbox
              label={'Multiple Video'}
              dataId={`multiple-video-${id}`}
              isChecked={isMulti}
              onChange={() => {
                setIsMulti((prev) => !prev)
              }}
            />
          </div>
        )}
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}
      {!!!individualElement?.assets ? (
        <div className="h-[100px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center">
          <span className="h-full w-full flex items-center justify-center text-gray-400 px-20">
            End user completing the form will upload their video here{' '}
          </span>
        </div>
      ) : (
        <div className="min-h-[150px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center gap-8">
          {individualElement?.assets?.slice(0, 4)?.map((video) => {
            return (
              <div
                className="h-[140px] w-[160px] bg-blue-100"
                key={video?.asset_details?.id}
              >
                <video
                  src={getAssetUrl(video?.asset_details)}
                  controls
                  className="object-contain w-full h-full"
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export const ImagePreview = ({id, type}: ImagePreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="text-md text-black">
        {individualElement?.content?.label}
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div
        key={id}
        className="flex-col w-full my-6 bg-blue-100 flex h-[150px] relative"
      >
        {individualElement?.asset_details ? (
          <img
            src={
              individualElement?.asset_details
                ? String(
                    getAssetUrl(individualElement?.asset_details) ??
                      '/noImgTemplate.png'
                  )
                : '/noImgTemplate.png'
            }
            alt="previewImage"
            className="h-[150px] w-full object-contain"
          />
        ) : (
          <img
            src={
              individualElement?.content?.value &&
              individualElement?.content?.value?.length > 0
                ? String(
                    individualElement?.content?.value ?? '/noImgTemplate.png'
                  )
                : '/noImgTemplate.png'
            }
            alt="previewImage"
            className="h-[150px] w-full object-contain"
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}
