import {DataTable} from 'app/components'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch} from 'react-redux'
import {getUsersFinancialDetailsOfProject} from 'redux-src'

export function UsersFinancialBreakdown({
  data,
}: {
  data: Api.UsersFinancialDetailsList
}) {
  const {currencySymbol} = useAuth()

  const dispatch = useDispatch()

  const columns: TableColumn<Api.UsersFinancialDetailsIndividual>[] = [
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Id
        </div>
      ),
      // selector: (row) => row.site_visit_number,
      sortable: true,
      selector: (row: Api.UsersFinancialDetailsIndividual) => {
        return row.id
      },
      cell: (row: Api.UsersFinancialDetailsIndividual) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.quote_details.id}`}
            className="text-blue-300"
          >
            {/* smart 15 */}
            {row.id}
          </div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Name
        </div>
      ),
      selector: (row: Api.UsersFinancialDetailsIndividual) => row.display_name,
      sortable: true,
      cell: (row: Api.UsersFinancialDetailsIndividual) => (
        <div className="text-sm font-light px-6 ">
          {row.display_name} {row.lastname ?? ''}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Paid Total Cost
        </div>
      ),
      selector: (row: Api.UsersFinancialDetailsIndividual) =>
        row.paid_total_cost,
      sortable: true,
      cell: (row: Api.UsersFinancialDetailsIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.paid_total_cost.toFixed(2)}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Unpaid Total Cost
        </div>
      ),
      selector: (row: Api.UsersFinancialDetailsIndividual) =>
        row.unpaid_total_cost,
      sortable: true,
      cell: (row: Api.UsersFinancialDetailsIndividual) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.unpaid_total_cost.toFixed(2)}
        </div>
      ),
    },
  ]

  const {params} = useNavigation()
  const {projectId} = params as any
  return (
    <div>
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16 -mx-16">
        Users Financial Breakdown
      </h3>
      <div className="-mx-16">
        <DataTable
          columns={columns}
          data={data}
          // defaultSortFieldId={2}
          pagination
          persistTableHead
          dense
          striped
          fetchData={(page, limit) => {
            dispatch(
              getUsersFinancialDetailsOfProject(projectId, {page, limit}),
            )
          }}
        />
      </div>
    </div>
  )
}
