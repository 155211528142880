import React, {useCallback, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useFormInput} from 'use-form-input'
import {FaHourglassStart} from 'react-icons/fa'
import moment from 'moment'

import {DataTable, DescriptionBox} from 'app/components'
import {
  Button,
  Checkbox,
  CollapsibleBlock,
  DateInput,
  Input,
  Loading,
  Modal,
  SelectField,
  Textarea,
  ToolTip,
} from 'app/common'
import {
  createWorkerInvoiceItemWithLabourEntries,
  generateWorkerInvoice,
  getCompanyWorkerInvoiceList,
  getLabourEntryByTicketId,
  getProjectListOfUserNotIncludedInInvoice,
  getWorkerInvoiceById,
  getWorkerProjectLabourEntries,
} from 'redux-src'
import {BsChevronRight} from 'react-icons/bs'

interface SelectTicketModalProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  isDetailsPage?: boolean
}

export const CreateWorkerInvoiceModal = ({
  openModal,
  setOpenModal,
  isDetailsPage,
}: SelectTicketModalProps) => {
  const {currencySymbol} = useAuth()

  const {
    params,
    navigation: {navigate},
  } = useNavigation()
  const {id: userId, invoiceId} = params as any

  const dispatch = useDispatch()

  const {
    generateTicketsInvoiceLoading,
    projectLabourEntriesForWorkerInvoice: labourEntriesForWorkerInvoice,
    getLabourEntryByTicketIdLoading,
    projectListOfUserNotIncludedInWorkerInvoice,
  }: // projectListOfUserNotIncludedInInvoiceLoading
  RT.ProjectInvoicingReduxType = useSelector((state: any) => state.invoices)

  // const [allProjects, setAllProjects] =
  //   useState<Api.WorkerProjectLabourEntryList>()

  const [
    data,
    {onChange: onChangeHandler, setValue, errors, modified, onSubmit},
  ] = useFormInput<{
    allProjects: Api.WorkerProjectLabourEntryList
    selectedProject: {id: number; label: string; value: number}
    selectedTicket: {id: number; label: string; value: boolean}
    title: string
    description: string
    dueDate: any
    footerNotes: string
  }>(
    {
      allProjects: undefined,
      selectedProject: undefined,
      selectedTicket: undefined,
      title: '',
      description: '',
      dueDate: moment.now(),
      footerNotes: '',
    },
    (data) => {
      generateWorkerInvoiceUsingProjectTickets()
    },
    (data) => {
      const errors: any = {}

      if (data?.title?.length === 0) {
        errors.title = 'Title cannot be empty !!'
      }

      if (data?.dueDate?.length === 0) {
        errors.title = 'Title cannot be empty !!'
      }
      if (data?.footerNotes?.length === 0) {
        errors.footerNotes = 'Footer Notes cannot be empty !!'
      }

      return errors
    },
  )

  const columns: TableColumn<SelectedEntryForTable>[] = [
    {
      name: 'Ticket',
      selector: (row) => row.ticket_title,
      wrap: true,
      style: {
        width: 300,
      },
      cell: (row) => {
        return (
          <div className={'text-blue-300 font-medium'}>{row.ticket_title}</div>
        )
      },
      sortable: true,
    },
    {
      name: 'Labour Entry',
      selector: (row) => row.title,
      cell: (row) => {
        return <div className={'text-blue-300 font-medium'}>{row.title}</div>
      },
      sortable: true,
    },
    {
      name: 'Time (mins)',
      selector: (row) => row.time_mins,
      sortable: true,
      cell: (row) => {
        return <div className="text-sm">{row.time_mins}</div>
      },
    },
    {
      name: 'Pay Rate (/hr)',
      selector: (row) => row.pay_rate,
      sortable: true,
      cell: (row) => {
        return (
          <div className="text-sm">
            {currencySymbol}
            {row.pay_rate}
          </div>
        )
      },
    },
    {
      name: 'Completed At',
      selector: (row) => row.completed_at,
      sortable: true,
      cell: (row) => {
        return (
          <div className="text-sm">
            {moment(row.completed_at)?.format('ddd, DD, MMM, YYYY hh:mma')}
          </div>
        )
      },
    },
  ]

  const projectListOptions: Array<{
    id: number
    label: string
    value: number
  }> = useMemo(() => {
    const remappedOptions =
      labourEntriesForWorkerInvoice?.map((item) => ({
        id: item?.project_id,
        label: item?.project_title,
        value: item?.project_id,
      })) ?? []

    return [...remappedOptions]
  }, [labourEntriesForWorkerInvoice])

  const projectListOptionsDetailsPage: Array<{
    id: number
    label: string
    value: number
  }> = useMemo(() => {
    const remappedOptions =
      projectListOfUserNotIncludedInWorkerInvoice?.map((item) => ({
        id: item?.project_id,
        label: item?.project_title,
        value: item?.project_id,
      })) ?? []

    return [...remappedOptions]
  }, [projectListOfUserNotIncludedInWorkerInvoice])

  const projectWithEntryList = useMemo(() => {
    const remappedProjectWithEntryList =
      labourEntriesForWorkerInvoice?.map((project) => {
        return {
          ...project,
          isChecked: true,
          ticket_detail_list:
            project?.ticket_detail_list?.map((ticket) => ({
              ...ticket,
              isChecked: true,
            })) ?? [],
        }
      }) ?? []

    return [...remappedProjectWithEntryList]
  }, [labourEntriesForWorkerInvoice])

  const projectWithEntryListDetailsPage = useMemo(() => {
    const remappedProjectWithEntryList =
      projectListOfUserNotIncludedInWorkerInvoice?.map((project) => {
        return {
          ...project,
          isChecked: true,
          ticket_detail_list:
            project?.ticket_detail_list?.map((ticket) => ({
              ...ticket,
              isChecked: true,
            })) ?? [],
        }
      }) ?? []

    return [...remappedProjectWithEntryList]
  }, [projectListOfUserNotIncludedInWorkerInvoice])

  // projectListOfUserNotIncludedInWorkerInvoice
  useEffect(() => {
    if (data.allProjects && data.allProjects?.length > 0) {
      return
    } else {
      if (isDetailsPage && data?.selectedProject) {
        setValue('allProjects', projectWithEntryListDetailsPage)
      } else {
        setValue('allProjects', projectWithEntryList)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectWithEntryList,
    data?.selectedProject,
    isDetailsPage,
    projectWithEntryListDetailsPage,
  ])

  const activeProject = useMemo(() => {
    return data?.allProjects?.find(
      (project) => project?.project_id === data?.selectedProject?.id,
    )
  }, [data?.selectedProject?.id, data?.allProjects])

  const updateAllTicketItems = useCallback(
    (value: boolean) => {
      const allTicketsBuffer = {
        ...activeProject,
        isChecked: !value,
        ticket_detail_list:
          activeProject?.ticket_detail_list?.map((ticket) => ({
            ...ticket,
            isChecked: !value,
            project_labour_entries_list:
              (ticket?.project_labour_entries_list &&
                Array.isArray(ticket?.project_labour_entries_list) &&
                ticket?.project_labour_entries_list?.map((labourEntry) => ({
                  ...labourEntry,
                  isChecked: !value,
                }))) ??
              [],
          })) ?? [],
      }

      setValue(
        'allProjects',
        data?.allProjects?.map((project) =>
          project?.project_id === activeProject?.project_id
            ? allTicketsBuffer
            : project,
        ),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProject, data?.allProjects],
  )

  const updateActiveAllTicketItem = useCallback(
    (value: boolean) => {
      const activeEntryBuffer = {
        ...activeProject,
        ticket_detail_list:
          activeProject?.ticket_detail_list?.map((ticket) =>
            ticket?.ticket_id === activeTicket?.ticket_id
              ? {
                  ...ticket,
                  isChecked: !value,
                  project_labour_entries_list:
                    (ticket?.project_labour_entries_list &&
                      Array.isArray(ticket?.project_labour_entries_list) &&
                      ticket?.project_labour_entries_list?.map(
                        (labourEntry) => ({
                          ...labourEntry,
                          isChecked: !value,
                        }),
                      )) ??
                    [],
                }
              : ticket,
          ) ?? [],
      }

      const activeTicketBuffer = {
        ...activeEntryBuffer,
        isChecked: activeEntryBuffer?.ticket_detail_list?.every(
          (ticket) => ticket?.isChecked,
        ),
      }

      setValue(
        'allProjects',
        data?.allProjects?.map((project) =>
          project?.project_id === activeProject?.project_id
            ? activeTicketBuffer
            : project,
        ),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProject],
  )

  const updateActiveTicketLabourEntries = useCallback(
    (value: boolean, entryId: number) => {
      const alterEntryBuffer = {
        ...activeProject,
        ticket_detail_list:
          activeProject?.ticket_detail_list?.map((ticket) => ({
            ...ticket,
            project_labour_entries_list:
              (ticket?.project_labour_entries_list &&
                Array.isArray(ticket?.project_labour_entries_list) &&
                ticket?.project_labour_entries_list?.map((labourEntry) => ({
                  ...labourEntry,
                  isChecked:
                    labourEntry?.project_labour_entry_id === entryId
                      ? !labourEntry?.isChecked
                      : labourEntry?.isChecked,
                }))) ??
              [],
          })) ?? [],
      }

      const alterTicketBuffer = {
        ...alterEntryBuffer,
        ticket_detail_list:
          alterEntryBuffer?.ticket_detail_list?.map((ticket) => ({
            ...ticket,
            isChecked: ticket?.project_labour_entries_list?.every(
              (item) => item?.isChecked,
            ),
          })) ?? [],
      }

      const alterProjectBuffer = {
        ...alterTicketBuffer,
        isChecked: alterTicketBuffer?.ticket_detail_list?.every(
          (ticket) => ticket?.isChecked,
        ),
      }

      setValue(
        'allProjects',
        data?.allProjects?.map((project) =>
          project?.project_id === activeProject?.project_id
            ? alterProjectBuffer
            : project,
        ),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProject, data?.allProjects],
  )

  const activeTicket = useMemo(() => {
    return activeProject?.ticket_detail_list?.find(
      (ticket) => ticket?.ticket_id === data?.selectedTicket?.id,
    )
  }, [activeProject?.ticket_detail_list, data?.selectedTicket?.id])

  const selectedTicketItems = useMemo(() => {
    return data?.allProjects?.map((item) => ({
      projectDetails: {
        id: item?.project_id,
        title: item?.project_title,
      },
      ticketLabourEntryList: item?.ticket_detail_list
        ?.filter((ticket) => ticket?.apiHit)
        ?.map((itm) =>
          itm?.project_labour_entries_list &&
          Array.isArray(itm?.project_labour_entries_list)
            ? itm?.project_labour_entries_list?.map((labour) => ({
                ...labour,
                ticket_title: itm?.ticket_title,
              }))
            : [],
        )
        ?.flat(1)
        ?.filter((item) => !!item && item?.isChecked),
    }))
  }, [data?.allProjects])

  const generateWorkerInvoiceUsingProjectTickets = () => {
    const body = {
      title: data.title,
      footerNotes: data.footerNotes,
      description: !!data.description ? data.description : undefined,
      dueDate: data.dueDate,
      projectLabourEntryIdList: selectedTicketItems
        ?.map((item) =>
          item?.ticketLabourEntryList
            ?.map((itm) => itm?.project_labour_entry_id)
            ?.flat(1),
        )
        ?.flat(1),
    }

    dispatch(
      generateWorkerInvoice(userId, body, (response) => {
        navigate(
          `/settings/users/${userId}/invoice/${response?.workerInvoiceId}`,
        )
        setOpenModal(false)
        dispatch(
          getCompanyWorkerInvoiceList({page: 1, limit: 10, userId: userId}),
        )
      }),
    )
  }

  const onCreateWorkerInvoiceItem = () => {
    const body = {
      projectLabourEntryIdList: selectedTicketItems
        ?.map((item) =>
          item?.ticketLabourEntryList
            ?.map((itm) => itm?.project_labour_entry_id)
            ?.flat(1),
        )
        ?.flat(1),
    }

    dispatch(
      createWorkerInvoiceItemWithLabourEntries(invoiceId, body, () => {
        setOpenModal(false)
        dispatch(getWorkerInvoiceById(invoiceId))
      }),
    )
  }

  useEffect(() => {
    if (userId && openModal && !isDetailsPage) {
      dispatch(getWorkerProjectLabourEntries(userId))
    }
  }, [userId, dispatch, openModal, isDetailsPage])

  useEffect(() => {
    if (openModal && isDetailsPage && userId && invoiceId) {
      dispatch(getProjectListOfUserNotIncludedInInvoice(invoiceId, userId))
    }
  }, [dispatch, openModal, isDetailsPage, invoiceId, userId])

  const getLabourEntryByTicketCallback = useCallback(
    (entryList: Array<Api.ProjectLabourEntryIndividual>, ticketId: number) => {
      const projectBuffer = {
        ...activeProject,
        ticket_detail_list: activeProject?.ticket_detail_list?.map((ticket) =>
          ticket?.ticket_id === ticketId
            ? {
                ...ticket,
                apiHit: true,
                project_labour_entries_list: entryList?.map((entry) => ({
                  ...entry,
                  isChecked: true,
                })),
              }
            : ticket,
        ),
      }

      setValue(
        'allProjects',
        data?.allProjects?.map((project) =>
          project.project_id === activeProject?.project_id
            ? projectBuffer
            : project,
        ),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProject, data?.allProjects],
  )

  return (
    <Modal
      toggleModal={setOpenModal}
      isActive={openModal}
      title={
        !isDetailsPage
          ? 'Create Worker Invoice'
          : 'Create Invoice Item Using Tickets'
      }
      size="md"
    >
      <div className="flex justify-between items-center flex-col w-full">
        <div className="w-full px-24 pt-20 min-h-[50vh] flex flex-col gap-20 h-[78vh] overflow-y-auto">
          {!isDetailsPage && (
            <div className="border-1 border-gray-200 rounded-sm">
              <CollapsibleBlock
                topContainerClassName="flex flex-row justify-between items-center bg-blue-100 px-12 py-12 cursor-pointer "
                topContainer={
                  <div className="font-bold text-md">Basic Details</div>
                }
                indicator
              >
                <div className="flex flex-col gap-6 relative px-14 py-10">
                  <div className="flex gap-20">
                    <Input
                      name="title"
                      label="Title"
                      value={data?.title}
                      error={modified?.title && errors?.title}
                      onChange={onChangeHandler}
                      placeholder="Title"
                      required
                    />
                    <div className="">
                      <DateInput
                        prepend={<FaHourglassStart className="text-blue-300" />}
                        name="projectStart"
                        size="sm"
                        placeholder="Project Start"
                        label="Due Date"
                        containerClass="w-full relative mb-18 gap-[0px]"
                        value={String(
                          moment(data?.dueDate).format('YYYY-MM-DD'),
                        )}
                        error={modified?.dueDate && errors?.dueDate}
                        onChange={(date) => {
                          setValue('dueDate', date)
                        }}
                        dateFormat="YYYY-MM-DD"
                        required
                      />
                    </div>
                  </div>
                  <div className="">
                    <Textarea
                      label="Description"
                      name="description"
                      value={data?.description}
                      onChange={onChangeHandler}
                      placeholder="Description"
                      className="resize-none"
                      error={modified?.description && errors?.description}
                    />
                  </div>
                  <div className="">
                    <Textarea
                      label="Footer Notes"
                      name="footerNotes"
                      value={data?.footerNotes}
                      onChange={onChangeHandler}
                      placeholder="Footer Notes"
                      error={modified?.footerNotes && errors?.footerNotes}
                      className="resize-none"
                      required
                    />
                  </div>
                </div>
              </CollapsibleBlock>
            </div>
          )}

          <DescriptionBox title="Project Labour Entry List">
            <div className="flex w-full gap-20 mb-20">
              <div className="flex flex-col gap-10 w-1/4">
                <div className="text-md font-bold">Select a project</div>
                <SelectField
                  options={
                    isDetailsPage
                      ? projectListOptionsDetailsPage
                      : projectListOptions
                  }
                  placeholder="Select a project"
                  value={
                    !!data?.selectedProject ? data?.selectedProject : undefined
                  }
                  onChangeValue={(data) => {
                    setValue('selectedProject', data)
                  }}
                />
              </div>

              <div className="flex items-center gap-10 w-3/4 bg-blue-100 rounded-md px-20 py-16 text-sm text-blue-300">
                Select a project to show the list of tickets.
              </div>
            </div>

            <div className="flex w-full gap-20 ">
              <div className="flex flex-col gap-10 w-1/4 bg-gray-100 rounded-md px-10 py-10">
                <Checkbox
                  dataId={`selectAll-ticketlist`}
                  label={<div className="font-bold text-md">Ticket List</div>}
                  labelClassName="ml-8"
                  isChecked={activeProject?.isChecked}
                  value={activeProject?.isChecked}
                  onChange={(value) => {
                    updateAllTicketItems(value)
                  }}
                />
                <div className="overflow-y-auto overflow-x-hidden h-[225px]">
                  {activeProject?.ticket_detail_list?.map((ticket) => (
                    <div
                      onClick={() => {
                        setValue('selectedTicket', {
                          id: ticket.ticket_id,
                          value: ticket.apiHit ?? false,
                          label: ticket.ticket_title,
                        })

                        !!!ticket.apiHit &&
                          dispatch(
                            getLabourEntryByTicketId(
                              ticket.ticket_id,
                              userId,
                              getLabourEntryByTicketCallback,
                            ),
                          )
                      }}
                      className={` my-6 bg-white cursor-pointer border-1 rounded-sm h-[50px] w-full flex items-center justify-between gap-8 px-10 ${
                        data?.selectedTicket?.id === ticket?.ticket_id
                          ? 'border-blue-200 '
                          : 'border-gray-100 '
                      }`}
                      key={'ticket-worker-invoice-' + ticket.ticket_id}
                    >
                      <div className="flex flex-row flex-nowrap gap-8 items-center">
                        <ToolTip text="Ticket Number" right>
                          <span className="flex text-sm items-center justify-center h-[24px] w-[max-content] text-white bg-red-400 rounded-md px-8">
                            {ticket?.ticket_number ?? ticket?.ticket_id}
                          </span>
                        </ToolTip>
                        <div className="text-sm text-black max-w-[80px] truncate">
                          {ticket?.ticket_title}
                        </div>
                      </div>
                      <div
                        className={`self-center justify-self-end ${
                          data?.selectedTicket?.id === ticket?.ticket_id
                            ? 'text-blue-200 '
                            : 'text-gray-100 '
                        }`}
                      >
                        <BsChevronRight
                          color={
                            data?.selectedTicket?.id === ticket?.ticket_id
                              ? 'blue'
                              : '#dadada'
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-10 w-3/4 bg-[#fafafa] rounded-md px-20 py-16">
                {getLabourEntryByTicketIdLoading ? (
                  <Loading small />
                ) : (
                  <>
                    {!!activeTicket ? (
                      <>
                        <div className="flex flex-col gap-8">
                          <Checkbox
                            dataId={`selectAll-ticket-labour-entries-${
                              activeTicket?.ticket_title +
                              activeTicket?.ticket_number +
                              '-ticket-id-' +
                              activeTicket?.ticket_id
                            }`}
                            label={
                              <div className="font-bold text-md break-normal max-w-[420px]">
                                Labour Entry List -{' '}
                                <span className="text-blue-300">
                                  {activeTicket?.ticket_title}
                                </span>
                              </div>
                            }
                            labelClassName="ml-8"
                            isChecked={activeTicket?.isChecked}
                            value={activeTicket?.isChecked}
                            onChange={(value) => {
                              updateActiveAllTicketItem(value)
                            }}
                          />
                        </div>

                        <div className="flex flex-col gap-6  h-[200px] overflow-y-auto">
                          {activeTicket?.project_labour_entries_list &&
                          Array.isArray(
                            activeTicket?.project_labour_entries_list,
                          ) &&
                          activeTicket?.project_labour_entries_list?.length >
                            0 ? (
                            activeTicket?.project_labour_entries_list?.map(
                              (item, index: number) => {
                                return (
                                  <div
                                    className="flex gap-6 items-center"
                                    key={index}
                                  >
                                    <div className="">
                                      <Checkbox
                                        label={
                                          <div className="flex items-center font-normal text-sm break-normal max-w-[420px]">
                                            {item?.title}
                                          </div>
                                        }
                                        labelClassName="ml-8"
                                        isChecked={item?.isChecked}
                                        value={item?.isChecked}
                                        dataId={`ticket-${item?.project_labour_entry_id}`}
                                        onChange={(value) => {
                                          updateActiveTicketLabourEntries(
                                            value,
                                            item?.project_labour_entry_id,
                                          )
                                        }}
                                      />
                                    </div>
                                    <span>-</span>
                                    <ToolTip
                                      text="Pay rate per time in mins"
                                      down
                                    >
                                      <div className="font-medium text-black text-sm px-10 bg-blue-150 py-4 rounded-sm w-[max-content]">
                                        {item?.time_mins}mins @ {currencySymbol}
                                        {item?.pay_rate ?? 0}/hr
                                      </div>
                                    </ToolTip>
                                  </div>
                                )
                              },
                            )
                          ) : (
                            <div className="text-sm text-gray-300 h-[235px]">
                              Labour entries not available for selected ticket.
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="text-sm text-gray-300 h-[235px]">
                        Please select a ticket to show the list of labour
                        entries.
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {!!selectedTicketItems &&
              selectedTicketItems?.length > 0 &&
              selectedTicketItems?.some(
                (item) => item?.ticketLabourEntryList?.length > 0,
              ) && (
                <div className="flex flex-col gap-10 mt-20">
                  <div className="font-bold text-md">Selected Items</div>
                  <div className="flex flex-col gap-16">
                    {selectedTicketItems
                      ?.filter(
                        (item) => item?.ticketLabourEntryList?.length > 0,
                      )
                      ?.map((item) => {
                        return (
                          <div
                            key={item?.projectDetails?.id}
                            className="flex flex-col gap-10"
                          >
                            <div className="text-sm text-blue-200 font-bold">
                              {item?.projectDetails?.title}
                            </div>
                            <DataTable
                              key={item?.projectDetails?.id}
                              columns={columns}
                              data={item.ticketLabourEntryList as any}
                            />
                          </div>
                        )
                      })}
                  </div>
                </div>
              )}
          </DescriptionBox>
        </div>
        <div className="flex justify-end p-18 border-t-1 border-gray-200 w-full">
          <Button
            title="Cancel"
            buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
            size="sm"
            type="button"
            onClick={() => {
              setOpenModal(false)
            }}
          />
          <div className="ml-16">
            <Button
              title={
                !isDetailsPage
                  ? 'Generate Invoice Using Ticket'
                  : 'Create Invoice Item'
              }
              size="sm"
              type="submit"
              onClick={isDetailsPage ? onCreateWorkerInvoiceItem : onSubmit}
              loading={generateTicketsInvoiceLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

type SelectedEntryForTable = {
  ticket_title: string
  pay_rate: number
  time_mins: number
  title: string
  completed_at: string
  project_labour_entry_id: number
}
