import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {getAssignedTicketForCalendar} from 'redux-src'

type TCalendarProvider = {
  children: ReactNode
}

export type TUnAssignedTicket = Array<
  Api.CalendarTicketList[number]['ticket_list'][number] & {
    project_details: Api.ProjectDetails
  }
>

export type TAssignedTicket = Array<
  Api.CalendarTicketListAssigned[number]['ticket_list'][number] & {
    user_detail: Api.CalendarTicketListAssigned[number]['user_details']
  }
>

type TStateType = {
  error: string
  isLoading: boolean
  events: any[]
  unassigned_tickets: TUnAssignedTicket
  assigned_tickets: TAssignedTicket
  resources: Array<CustomResourceType>
  selectedDate: {start: Date; end: Date}
  selectedProjectsList: Array<{
    value: number
    label: string
    id: number
  }>
  sidebarOpen: boolean
  selectedUserType: 'user' | 'group'
  selectedUserGroupId: Array<number>
  clickedResources: Array<number>
  selectedProjectType: {
    value: string
    label: string
    id: number
  }
}
export type CustomResourceType = {
  id: string | number
  title: string

  eventClassNames?: string
  eventBackgroundColor?: string
  eventBorderColor?: string
  eventTextColor?: string
}
type TActions =
  | {type: 'SET_ERROR'; payload: string}
  | {
      type: 'SET_UNASSIGNED_TICKETS'
      payload: TUnAssignedTicket
    }
  | {
      type: 'SET_ASSIGNED_TICKETS'
      payload: TAssignedTicket
    }
  | {type: 'DELETE_UNASSIGNED_TICKET'; payload: {id: string}}
  | {type: 'SET_LOADING'; payload: boolean}
  | {type: 'SET_EVENT'; payload: any}
  | {type: 'SET_EVENTS'; payload: any[]}
  | {type: 'ADD_EVENT'; payload: any}
  | {type: 'DELETE_EVENT'; payload: {id: string}}
  | {type: 'SET_RESOURCE'; payload: any}
  | {type: 'SET_RESOURCES'; payload: Array<CustomResourceType>}
  | {type: 'SET_SELECTED_DATE'; payload: {start: Date; end: Date}}
  | {type: 'SET_SELECTED_PROJECTS'; payload: any[]}
  | {type: 'RESET_SELECTED_PROJECTS'}
  | {type: 'ADD_RESOURCE'; payload: any}
  | {type: 'REMOVE_RESOURCE'; payload: {id: number}}
  | {type: 'SET_SIDEBAR_OPEN'; payload: boolean}
  | {type: 'SET_SELECTED_USER_TYPE'; payload: 'user' | 'group'}
  | {type: 'SET_SELECTED_USER_GROUP_ID'; payload: Array<number>}
  | {type: 'CLICKED_RESOURCE'; payload: number}
  | {type: 'EMPTY_CLICKED_RESOURCE'}
  | {
      type: 'SET_SELECTED_PROJECT_TYPE'
      payload: {
        value: string
        label: string
        id: number
      }
    }

type TDispatch = (action: TActions) => void

const initialState: TStateType = {
  error: '',
  isLoading: true,
  events: [],
  resources: [],
  unassigned_tickets: [],
  selectedDate: {start: new Date(), end: new Date()},
  selectedProjectsList: [],
  assigned_tickets: [],
  sidebarOpen: true,
  selectedUserType: 'user',
  selectedUserGroupId: [],
  clickedResources: [],
  selectedProjectType: {
    value: 'all',
    label: 'All',
    id: 1,
  },
}

const CalendarStateContext = createContext<
  | {
      calendarState: TStateType
      calendarDispatch: React.Dispatch<TActions>
      fetchCalendarEvents?: (params?: {
        // userIds?: string
        from?: string
        to?: string
        // userGroupIds?: string
        // projectIds?: string
      }) => void
    }
  | undefined
>(undefined)

const calendarReducer = (state: TStateType, action: TActions): TStateType => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    case 'SET_EVENTS':
      return {
        ...state,
        events: action.payload,
      }
    case 'ADD_EVENT':
      return {
        ...state,
        events: [...state.events, action.payload],
      }

    case 'DELETE_EVENT':
      return {
        ...state,
        events: state.events.filter(
          (event) => +event.id !== +action.payload.id,
        ),
      }
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      }
    case 'SET_RESOURCES':
      // * sort in ascending order name
      let resTemp = action.payload.sort((a, b) =>
        a.title.localeCompare(b.title),
      )

      // * remove duplicate object
      resTemp = resTemp.filter(
        (u, i) => resTemp.findIndex((t) => t.id === u.id) === i,
      )

      return {
        ...state,
        resources: resTemp,
      }
    case 'ADD_RESOURCE':
      // * sort in ascending order name
      let temp = [action.payload, ...state.resources].sort((a, b) =>
        a.title.localeCompare(b.title),
      )

      // * remove duplicate object
      temp = temp.filter((u, i) => temp.findIndex((t) => t.id === u.id) === i)
      return {
        ...state,
        resources: temp,
      }

    case 'REMOVE_RESOURCE':
      return {
        ...state,
        resources: state.resources.filter(
          (resource) => +resource.id !== +action.payload.id,
        ),
      }
    case 'SET_SELECTED_DATE':
      return {
        ...state,
        selectedDate: action.payload,
      }
    case 'SET_UNASSIGNED_TICKETS':
      return {
        ...state,
        unassigned_tickets: action.payload,
      }

    case 'SET_ASSIGNED_TICKETS':
      return {
        ...state,
        assigned_tickets: action.payload,
      }

    case 'DELETE_UNASSIGNED_TICKET':
      return {
        ...state,
        unassigned_tickets: state.unassigned_tickets.filter(
          (ticket) => +ticket.ticket_details.id !== +action.payload.id,
        ),
      }
    case 'SET_SELECTED_PROJECTS':
      return {
        ...state,
        selectedProjectsList: action.payload,
      }

    case 'RESET_SELECTED_PROJECTS':
      return {
        ...state,
        selectedProjectsList: null,
      }

    case 'SET_SIDEBAR_OPEN':
      return {
        ...state,
        sidebarOpen: action.payload,
      }

    case 'SET_SELECTED_USER_TYPE':
      return {
        ...state,
        selectedUserType: action.payload,
      }

    case 'SET_SELECTED_USER_GROUP_ID':
      return {
        ...state,
        selectedUserGroupId: action.payload,
      }

    case 'CLICKED_RESOURCE':
      if (state?.clickedResources?.includes(+action.payload)) {
        return {
          ...state,
          clickedResources: state.clickedResources.filter(
            (resource) => +resource !== +action.payload,
          ),
        }
      } else {
        return {
          ...state,
          clickedResources: [...state.clickedResources, +action.payload],
        }
      }
    case 'EMPTY_CLICKED_RESOURCE':
      return {
        ...state,
        clickedResources: [],
      }

    case 'SET_SELECTED_PROJECT_TYPE':
      return {
        ...state,
        selectedProjectType: action.payload,
      }
    default:
      return state
  }
}

const CalendarProvider = ({children}: TCalendarProvider): ReactElement => {
  const [calendarState, calendarDispatch] = useReducer(
    calendarReducer,
    initialState,
  )

  const [initialFetchDone, setInitialFetchDone] = useState(false)

  const dispatch = useDispatch()

  // * Fetch events for calendar
  const fetchEvents = useCallback(
    (params?: {
      userIds?: string
      from?: string
      to?: string
      userGroupIds?: string
      projectIds?: string
    }) => {
      dispatch(
        getAssignedTicketForCalendar(
          {
            from: !!calendarState?.selectedDate?.start
              ? encodeURIComponent(
                  moment(calendarState?.selectedDate?.start)
                    .startOf('day')
                    .format(),
                )
              : undefined,
            to: !!calendarState?.selectedDate?.end
              ? encodeURIComponent(
                  moment(calendarState?.selectedDate?.end)
                    .endOf('day')
                    .format(),
                )
              : undefined,
            projectIds: calendarState?.selectedProjectsList
              ?.map((project) => project?.id)
              .join(','),
            userGroupIds:
              calendarState?.selectedUserType === 'group'
                ? calendarState?.selectedUserGroupId?.length > 0
                  ? calendarState?.selectedUserGroupId?.join(',')
                  : undefined
                : undefined,
            userIds:
              calendarState?.selectedUserType === 'user'
                ? calendarState?.resources?.map((user) => user?.id)?.join(',')
                : undefined,
            projectType:
              calendarState?.selectedProjectType?.value === 'all'
                ? undefined
                : (calendarState?.selectedProjectType?.label as any),
          },
          (data) => {
            const eventList: any[] = []
            data.forEach((ticketList) => {
              const resourceId = ticketList?.user_details?.id
              ticketList?.ticket_list?.forEach((ticket) => {
                eventList.push({
                  allDay: false,
                  id: ticket?.event_details?.id,
                  start: ticket?.event_details?.scheduled_from,
                  end: ticket?.event_details?.scheduled_to,
                  resourceId: resourceId,
                  title: `#${ticket?.ticket_details?.ticket_number} - ${ticket?.ticket_details?.title}`,
                  allData: {...ticket},
                })
              })
            })

            let assigned_temp: any[] = []
            data?.forEach((ticketDetail) => {
              ticketDetail?.ticket_list?.forEach((ticket) => {
                assigned_temp.push({
                  ...ticket,
                  user_detail: ticketDetail.user_details,
                })
              })
            })

            calendarDispatch({
              type: 'SET_ASSIGNED_TICKETS',
              payload: assigned_temp,
            })
            calendarDispatch({type: 'SET_EVENTS', payload: eventList})
          },
        ),
      )
    },
    [
      calendarState?.resources,
      calendarState?.selectedDate?.end,
      calendarState?.selectedDate?.start,
      calendarState?.selectedProjectType?.label,
      calendarState?.selectedProjectType?.value,
      calendarState?.selectedProjectsList,
      calendarState?.selectedUserGroupId,
      calendarState?.selectedUserType,
      dispatch,
    ],
  )

  // * While fetching data, prevent it from fetching same data multiple time after each dependency changes initially (optimization on api fetch)

  // ? Initially fetch data after all dependency are loaded
  useEffect(() => {
    const {end, start} = calendarState?.selectedDate
    if (
      !!end &&
      !!start &&
      !!calendarState?.resources &&
      calendarState?.resources?.length > 0 &&
      !initialFetchDone
    ) {
      setInitialFetchDone(true)
      fetchEvents()
    }
  }, [
    calendarState?.resources,
    calendarState?.selectedDate,
    fetchEvents,
    initialFetchDone,
  ])

  useEffect(() => {
    if (initialFetchDone) {
      fetchEvents()
    }
  }, [fetchEvents, initialFetchDone])

  useEffect(() => {
    if (calendarState?.selectedProjectsList) {
      calendarDispatch({
        type: 'EMPTY_CLICKED_RESOURCE',
      })
    }
  }, [calendarState?.selectedProjectsList])

  return (
    <CalendarStateContext.Provider
      value={{
        calendarState,
        calendarDispatch,
        fetchCalendarEvents: fetchEvents,
      }}
    >
      {children}
    </CalendarStateContext.Provider>
  )
}

const useCalendar = () => {
  const context = useContext(CalendarStateContext)

  if (context === undefined) {
    throw new Error('useCalendar must be used within a ServiceProvider')
  }

  return context
}

export {CalendarProvider, useCalendar}
