import ReactGridLayout from 'react-grid-layout'
import {
  ALTER_FORM_BUILDER_CONTENT,
  ALTER_FORM_BUILDER_SETTINGS,
  DELETE_FORM_ELEMENT,
  ON_ADD_FORM_SECTIONS,
  UPDATE_FORM_FROM_API,
  UPDATE_FORM_LAYOUTS
} from './actionTypes.action'
import {sortArrayBasedOnXYBuilder} from 'utils'

interface ContentType {
  label: string
  description?: string
  value?: string | Array<{id: number; text: string}>
}

// MARK UPDATE FORM FROM API

export function updateFormFromApi(
  form: Api.IndividualFormById,

  successCallback?: () => void
) {
  return function (dispatch: any) {
    try {
      const newFormFields =
        form?.fields && Array.isArray(form?.fields) && form?.fields?.length > 0
          ? [...form?.fields]
          : []

      const newForms = sortArrayBasedOnXYBuilder(newFormFields)?.map(
        (currForm) => {
          return {
            ...currForm.properties,
            id: currForm.id,
            asset_details: currForm.asset_details,
            assets: currForm?.assets
          }
        }
      )

      const layouts =
        newFormFields?.map((currForm) => currForm.properties?.layout) ?? []

      if (form) {
        dispatch({
          type: 'UPDATE_FORMS_FOR_PREVIEW',
          payload: {forms: newForms}
        })
        dispatch({
          type: UPDATE_FORM_FROM_API.SUCCESS,
          payload: {formName: form.form_name, forms: newForms, layouts}
        })
      } else {
        dispatch({
          type: 'UPDATE_FORMS_FOR_PREVIEW',
          payload: {forms: undefined}
        })
        dispatch({
          type: UPDATE_FORM_FROM_API.SUCCESS,
          payload: {formName: '', forms: [], layouts}
        })
      }
      successCallback?.()
    } catch (updateFormErr) {
      dispatch({
        type: 'UPDATE_FORMS_FOR_PREVIEW',
        payload: {forms: undefined}
      })
      dispatch({
        type: UPDATE_FORM_FROM_API.SUCCESS,
        payload: {formName: '', forms: []}
      })
    }
  }
}

// MARK ADD FORM SECTIONS

export function onAddFormSections(
  formElement: RT.FormElementType,
  successCallback?: (id: number, formEl: RT.FormElementType) => void
) {
  return function (dispatch: any, getState: any) {
    const newFormElementId = Math.floor(Math.random() * 1000000)

    const formBuilderState = getState().formBuilder as RT.FormBuilderReduxType

    const tempForms = formBuilderState?.forms
    const tempLayouts = formBuilderState?.layouts

    const newLayout = {
      ...formElement.layout,
      i: String(newFormElementId),
      y: Infinity
    }

    const layouts = [...tempLayouts, newLayout]

    const newFormElement = {
      ...formElement,
      id: newFormElementId,
      layout: newLayout
    }

    const newForms = [...tempForms, newFormElement]

    dispatch({
      type: 'UPDATE_FORMS_FOR_PREVIEW',
      payload: {forms: newForms}
    })

    dispatch({
      type: ON_ADD_FORM_SECTIONS.SUCCESS,
      payload: {forms: newForms ?? [], addedId: newFormElementId, layouts}
    })

    successCallback?.(newFormElementId, newFormElement)
  }
}

//MARK: ON CHANGE FORMSECTION CONTENT

export function alterFormContent(
  id: number,
  content: ContentType,
  successCallback?: () => void
) {
  return function (dispatch: any, getState: any) {
    const formBuilderState = getState().formBuilder as RT.FormBuilderReduxType

    const tempForms = formBuilderState.forms
    const updatedFormElement = tempForms?.map((formElement) => {
      if (formElement.id === id) {
        return {
          ...formElement,
          content: content ?? formElement.content
        }
      } else {
        return formElement
      }
    })

    const addedId = formBuilderState.addedFieldIds
    const updatedId = addedId.includes(id) ? undefined : id

    dispatch({
      type: 'UPDATE_FORMS_FOR_PREVIEW',
      payload: {forms: updatedFormElement}
    })

    dispatch({
      type: ALTER_FORM_BUILDER_CONTENT.SUCCESS,
      payload: {updatedFormElement, updatedId}
    })

    successCallback?.()
  }
}

//MARK: ON CHANGE FORMSECTION SETTINGS

export function alterFormSettings(
  id: number,
  formSettings: {
    settings?: {
      hideDescription?: boolean
      hideLabel?: boolean
      markAsRequired?: boolean
      numbered?: boolean
    }
    size?: 'large' | 'medium' | 'small'
    alignment?: 'left' | 'center' | 'right'
    elementSize?: '100%' | '50%' | '33%' | '66%'
    visibleTo?: string[]
    editableBy?: string[]
    layout?: ReactGridLayout.Layout
  },
  successCallback?: () => void
) {
  return function (dispatch: any, getState: any) {
    try {
      const formBuilderState = getState().formBuilder as RT.FormBuilderReduxType

      const tempForms = formBuilderState.forms
      const tempLayouts = formBuilderState.layouts
      const updatedFormElement = tempForms?.map((formElement) => {
        if (formElement.id === id) {
          return {
            ...formElement,
            ...formSettings
          }
        } else {
          return formElement
        }
      })

      const layouts = tempLayouts?.map((lyt) =>
        lyt.i === String(id) ? formSettings.layout ?? lyt : lyt
      )

      const addedId = formBuilderState.addedFieldIds
      const updatedId = addedId.includes(id) ? undefined : id

      dispatch({
        type: 'UPDATE_FORMS_FOR_PREVIEW',
        payload: {forms: updatedFormElement}
      })

      dispatch({
        type: ALTER_FORM_BUILDER_SETTINGS.SUCCESS,
        payload: {updatedFormElement, updatedId, layouts}
      })

      successCallback?.()
    } catch (err) {
      console.error({err})
    }
  }
}

//MARK: UPDATING FORM LAYOUTS BULK/INDIVIDUAL

export function updateFormLayouts(layouts: ReactGridLayout.Layout[]) {
  return function (dispatch: any, getState: any) {
    const formBuilderState = getState().formBuilder as RT.FormBuilderReduxType

    const tempForms = formBuilderState.forms

    const updatedFormElement = tempForms.map((el) => {
      return {...el, layout: layouts.find((lyt) => lyt.i === String(el.id))}
    })

    dispatch({
      type: 'UPDATE_FORMS_FOR_PREVIEW',
      payload: {forms: updatedFormElement}
    })

    dispatch({
      type: UPDATE_FORM_LAYOUTS.SUCCESS,
      payload: {layouts, updatedFormElement}
    })
  }
}

export function handleDroppedFormLayouts(
  layouts: ReactGridLayout.Layout[],
  draggedElement: RT.FormElementType,
  successCallback?: (id: number, formElement: RT.FormElementType) => void
) {
  return function (dispatch: any, getState: any) {
    const newFormElementId = Math.floor(Math.random() * 1000000)

    const formBuilderState = getState().formBuilder as RT.FormBuilderReduxType

    const tempForms = formBuilderState.forms

    const draggedLayout = {
      ...layouts.find((lyt) => lyt.i === '__dragging-elem__')
    }

    const newElement = {
      ...draggedElement,
      layout: {...draggedElement.layout, i: String(newFormElementId)},
      id: newFormElementId
    }

    const updatedFormElement = [
      ...tempForms.map((el) => {
        return {...el, layout: layouts.find((lyt) => lyt.i === String(el.id))}
      }),
      newElement
    ]

    dispatch({
      type: 'UPDATE_FORMS_FOR_PREVIEW',
      payload: {forms: updatedFormElement}
    })

    dispatch({
      type: UPDATE_FORM_LAYOUTS.SUCCESS,
      payload: {
        layouts: updatedFormElement.map((el) => el.layout),
        updatedFormElement,
        addedId: newFormElementId
      }
    })

    draggedLayout && successCallback?.(newFormElementId, newElement)
  }
}

//MARK: ON CHANGE FORMSECTION SETTINGS

export function deleteFormSection(
  id: number,

  successCallback?: () => void
) {
  return function (dispatch: any, getState: any) {
    const formBuilderState = getState().formBuilder as RT.FormBuilderReduxType

    const tempForms = formBuilderState.forms

    const updatedFormElement = tempForms?.filter(
      (formElement: RT.FormElementType) => {
        return formElement.id !== id
      }
    )

    dispatch({
      type: 'UPDATE_FORMS_FOR_PREVIEW',
      payload: {forms: updatedFormElement}
    })

    dispatch({
      type: DELETE_FORM_ELEMENT.SUCCESS,
      payload: {updatedFormElement, deletedId: id}
    })

    successCallback?.()
  }
}
