import {
  CREATE_ITEM,
  DELETE_ITEM,
  GET_ITEM_LIST,
  UPDATE_ITEM,
  GET_ITEM_LIST_BY_SUPPLIER_ID,
} from '../actions'

const initialState: RT.ItemReduxType = {
  itemList: undefined,
  itemTotal: 0,
  getItemListLoading: false,
  createItemLoading: false,
  updateItemLoading: false,
  getItemByIdLoading: false,
  itemDetails: undefined,
  deleteItemLoading: false,
  getSupplierItemListLoading: false,
  supplierItemLists: undefined,
  supplier_item_total: 0,
}

export function itemManagementReducer(state = initialState, action: any) {
  const { type, payload } = action

  switch (type) {
    case GET_ITEM_LIST.LOADING:
      return {
        ...state,
        getItemListLoading: true,
      }
    case GET_ITEM_LIST.SUCCESS:
      return {
        ...state,
        getItemListLoading: false,
        itemList: payload.rows,
        itemTotal: payload.total,
      }
    case GET_ITEM_LIST.ERROR:
      return { ...state, getItemListLoading: false }

    case CREATE_ITEM.LOADING:
      return {
        ...state,
        createItemLoading: true,
      }
    case CREATE_ITEM.SUCCESS:
      return {
        ...state,
        createItemLoading: false,
      }
    case CREATE_ITEM.ERROR:
      return { ...state, createItemLoading: false }

    case DELETE_ITEM.LOADING:
      return {
        ...state,
        deleteItemLoading: true,
      }
    case DELETE_ITEM.SUCCESS:
      return {
        ...state,
        deleteItemLoading: false,
        itemList: payload.rows,
      }
    case DELETE_ITEM.ERROR:
      return { ...state, deleteItemLoading: false }

    case UPDATE_ITEM.LOADING:
      return {
        ...state,
        updateItemLoading: true,
      }
    case UPDATE_ITEM.SUCCESS:
      return {
        ...state,
        updateItemLoading: false,
      }
    case UPDATE_ITEM.ERROR:
      return { ...state, updateItemLoading: false }

    case GET_ITEM_LIST_BY_SUPPLIER_ID.LOADING:
      return {
        ...state,
        getSupplierItemListLoading: true,
      }
    case GET_ITEM_LIST_BY_SUPPLIER_ID.SUCCESS:
      return {
        ...state,
        getSupplierItemListLoading: false,
        supplierItemLists: payload.rows,
        supplier_item_total: payload.total,
      }
    case GET_ITEM_LIST_BY_SUPPLIER_ID.ERROR:
      return { ...state, getSupplierItemListLoading: false }

    default:
      return state
  }
}
