import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'

import {MessageBox} from 'app/components'
import {FormSectionPreview} from 'app/pages/settings/pages/forms/components'
import {Loading} from 'app/common'

interface TicketFormProps {
  selectedForm: {id: number; label: string; value: string}
}

export const TicketForm = ({selectedForm}: TicketFormProps) => {
  const {formsForDesktopPreview}: RT.FormBuilderReduxType = useSelector(
    (state: any) => state.formBuilder
  )

  const formsPreview = useMemo(() => {
    return formsForDesktopPreview
  }, [formsForDesktopPreview])

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 300)

    return () => {
      clearTimeout(timeout)
      setLoading(true)
    }
  }, [selectedForm])

  return loading ? (
    <Loading />
  ) : (
    <div className="px-20 py-8">
      <div className="py-4 font-bold text-gray-400 text-lg">
        {selectedForm?.label}
      </div>
      <div className="flex flex-col flex-1 gap-8 mb-20">
        {selectedForm?.id !== 0 && formsPreview?.length > 0 ? (
          formsPreview?.map((fm, index) => {
            return (
              <div
                key={`form-preview-item-container-${index}`}
                id={`form-preview-item-container-${index}`}
                className={`flex flex-row gap-8 w-full`}
              >
                {fm?.map((form) => {
                  return (
                    <FormSectionPreview
                      key={form.id}
                      formType={form?.formType}
                      id={form?.id}
                      type={'preview'}
                    />
                  )
                })}
              </div>
            )
          })
        ) : (
          <MessageBox
            message={
              <div className="flex items-center">
                Select project form to see the preview.
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}
