import moment from 'moment'
import {useState} from 'react'

import {Input, Textarea} from 'app/common'

export const LetterHead = ({
  previewMode,
  companyDetails,
  quoteDetail,
  quoteBasicDetails,
  quoteVersionDetail,
}: {
  previewMode?: boolean
  companyDetails: any
  quoteDetail: any
  quoteBasicDetails: Api.QuoteBasicDetails
  quoteVersionDetail: any
}) => {
  const [showLetterHead, setShowLetterHead] = useState(true)

  const {customer_details, site_details} = !!quoteDetail && quoteDetail

  return (
    <div className="px-10">
      <button
        onClick={() => setShowLetterHead((prev) => !prev)}
        className="text-blue-200 hover:underline mt-4"
      >
        {showLetterHead ? 'Hide' : 'Show'} Letter Head
      </button>

      {showLetterHead && (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div></div>
            <div className="text-right">
              <div className="text-lg font-bold">
                {companyDetails?.company_details?.name.toUpperCase() ?? 'JFDS'}
              </div>
              <ul>
                {/* <li>
                  {companyDetails?.address_details?.city},{' '}
                  {companyDetails?.address_details?.state}
                </li> */}
                <li>{companyDetails?.address_details?.address}</li>
                {companyDetails?.contact_details?.emails?.map(
                  (item: string) => (
                    <li key={item}>{item}</li>
                  ),
                )}
                {companyDetails?.contact_details?.phones?.map(
                  (item: string) => (
                    <li key={item}>{item}</li>
                  ),
                )}
                {/* <li>info@jfds.co.uk</li>
                <li>0633310649</li> */}
              </ul>
            </div>
          </div>

          {quoteVersionDetail?.quote_details?.status === 'draft' &&
            !quoteVersionDetail?.quote_details?.is_super_seeded && (
              <div className="text-right text-red-300 mb-4 mt-12">
                <h3 className="text-lg font-bold uppercase">DRAFT</h3>
              </div>
            )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {true ? (
              // {previewMode ? (
              <ul>
                <li>{customer_details?.name}</li>
                <li>{customer_details?.suburb}</li>
                <li>
                  {customer_details?.city}, {customer_details?.zip_code}
                </li>
              </ul>
            ) : (
              <div>
                <div className="my-12">
                  <Input
                    name="atn"
                    size="sm"
                    prepend={'ATNN:'}
                    placeholder="Optional"
                    onChange={() => false}
                  />
                </div>

                <Textarea name="invoiceAddress" />
              </div>
            )}
            <div>
              <div
                className="bg-blue-100 flex justify-between p-10"
                style={{minWidth: 400}}
              >
                <div>
                  <span className="font-bold">Site Address</span>
                  <ul>
                    <li>{site_details?.name}</li>
                    <li>{site_details?.building}</li>
                    {/* <li>{site_details?.address}</li> */}
                    <li>{site_details?.suburb}</li>
                    <li>
                      {site_details?.city}, {site_details?.zipCode}
                    </li>
                  </ul>
                </div>

                <div className="justify-end">
                  <ul className="text-right">
                    <li>
                      Project Number:
                      {companyDetails?.company_details?.job_number_prefix +
                        '-' +
                        companyDetails?.company_details?.starting_job_number}
                    </li>
                    <li>
                      Company Registration Number:{' '}
                      {companyDetails?.company_details?.tax_number}
                    </li>
                    <li>Quote Date: {moment().format('Do MMM YYYY')}</li>
                    <li>
                      Valid Until:{' '}
                      {moment()
                        .add(quoteBasicDetails?.quote_details?.expiry_date, 'd')
                        .format('Do MMM YYYY')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
