import {errorHandler} from '../../utils'
import {
  ASSIGN_SITE_EMPLOYEE,
  CREATE_EVENT,
  CREATE_SITE_VISIT_LIST,
  CREATE_TIME_ENTRY,
  GET_ASSIGNED_SITE_EMPLOYEE_LIST,
  GET_SITE_VISIT_DETAIL,
  GET_SITE_VISIT_LIST,
  GET_TIME_ENTRY,
  UPDATE_TIME_ENTRY,
  DELETE_TIME_ENTRY,
  GET_VOIDED_SITE_VISIT_LIST,
  UNASSIGN_SITE_EMPLOYEE,
  LABOUR_COMPLETE,
  LABOUR_NEEDS_TO_RETURN,
  GET_SITE_VISIT_TODAY_EVENTS_LIST,
  GET_TIME_ENTRY_TODAY_EVENTS_LIST,
  MARK_AS_READY_TO_INVOICE,
  MANUALLY_MARK_AS_INVOICED,
  VOID_SITE_VISIT,
  UPDATE_SITE_VISIT_BASIC_DETAILS,
} from '.'
import {api, APIS} from '../../config'
import {toast} from 'app/common'

export function getSiteVisitListAction(
  projectId: any,
  page: number = 1,
  tableLimit: number = 10,
  successCallback?: (data?: Api.SiteVisitList) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_VISIT_LIST.LOADING})
      res = await api<Api.Base<Api.SiteVisitList>>(
        `${APIS.projects}/${projectId}/site-visits?page=${page}&limit=${tableLimit}`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: GET_SITE_VISIT_LIST.SUCCESS, payload: data})
        successCallback && successCallback(data)
        return 1
      } else {
        dispatch({type: GET_SITE_VISIT_LIST.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_SITE_VISIT_LIST.ERROR})
      return 1
    }
  }
}

export function getVoidedSiteVisitListAction(
  projectId: number,
  page: number,
  limit: number,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_VOIDED_SITE_VISIT_LIST.LOADING})
      res = await api<Api.Base<Api.SiteVisitList>>(
        `${APIS.projects}/${projectId}/site-visits/voided?page=${page}&limit=${limit}`,
      )

      const {
        success,
        data: {
          data: {rows, total},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_VOIDED_SITE_VISIT_LIST.SUCCESS,
          payload: {rows, total},
        })
        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_VOIDED_SITE_VISIT_LIST.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_VOIDED_SITE_VISIT_LIST.ERROR})
      return 1
    }
  }
}

export function createSiteVisitAction(
  projectId: number,
  body: any,
  successCallback?: (data?: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_SITE_VISIT_LIST.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/site-visits`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_SITE_VISIT_LIST.SUCCESS, payload: data})
        successCallback && successCallback(data)
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_SITE_VISIT_LIST.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: CREATE_SITE_VISIT_LIST.ERROR})
      return 1
    }
  }
}

// update site visit basic details

export function updateSiteVisitBasicDetails(
  projectId: number,
  siteVisitId: number,
  body: any,
  successCallback?: (data?: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_SITE_VISIT_BASIC_DETAILS.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/site-visits/${siteVisitId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_SITE_VISIT_BASIC_DETAILS.SUCCESS, payload: data})
        successCallback && successCallback(data)
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: UPDATE_SITE_VISIT_BASIC_DETAILS.ERROR,
          payload: message,
        })
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: UPDATE_SITE_VISIT_BASIC_DETAILS.ERROR})
      return 1
    }
  }
}

export function getSiteVisitDetailAction(
  projectId: any,
  siteId: any,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_VISIT_DETAIL.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/site-visits/${siteId}`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: GET_SITE_VISIT_DETAIL.SUCCESS, payload: data})
        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_SITE_VISIT_DETAIL.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_SITE_VISIT_DETAIL.ERROR})
      return 1
    }
  }
}

export function assignEmployeeAction(
  projectId: number,
  siteId: number,
  userId: number,
  body: any,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ASSIGN_SITE_EMPLOYEE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/${APIS.siteVisits}/${siteId}/assigned-employees/${userId}`,
        'POST',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ASSIGN_SITE_EMPLOYEE.SUCCESS, payload: data})
        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: ASSIGN_SITE_EMPLOYEE.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: ASSIGN_SITE_EMPLOYEE.ERROR})
      return 1
    }
  }
}

export function unAssignEmployeeAction(
  siteId: number,
  employeeId: number,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UNASSIGN_SITE_EMPLOYEE.LOADING})
      res = await api<any>(
        `${APIS.siteVisits}/${siteId}/assigned-employees/${employeeId}`,
        'DELETE',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UNASSIGN_SITE_EMPLOYEE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        // console.log('message: ', res.data.message)
        dispatch({type: UNASSIGN_SITE_EMPLOYEE.ERROR, payload: message})
        toast.error(
          'There are ongoing labour time entries entered in the site visit!',
        )
        return 0
      }
    } catch (message: any) {
      dispatch({type: UNASSIGN_SITE_EMPLOYEE.ERROR})
      toast.error(
        'There are ongoing labour time entries entered in the site visit!',
      )
      return 1
    }
  }
}

export function getAssignedEmployeeAction(siteId: any, successCallback?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ASSIGNED_SITE_EMPLOYEE_LIST.LOADING})
      res = await api<any>(`${APIS.siteVisits}/${siteId}/assigned-employees`)

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_ASSIGNED_SITE_EMPLOYEE_LIST.SUCCESS,
          payload: data,
        })
        // console.log('data from assigned employee: ', data)
        successCallback && successCallback()
        return 1
      } else {
        dispatch({
          type: GET_ASSIGNED_SITE_EMPLOYEE_LIST.ERROR,
          payload: message,
        })
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_ASSIGNED_SITE_EMPLOYEE_LIST.ERROR})
      return 1
    }
  }
}

export function createSiteVisitEventAction(
  projectId: number,
  siteVisitId: number,
  body: any,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_EVENT.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/site-visits/${siteVisitId}/events`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_EVENT.SUCCESS, payload: data})
        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: CREATE_EVENT.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: CREATE_EVENT.ERROR})
      return 1
    }
  }
}

export function createSiteVisitEntryAction(
  body: any,
  successCallback?: any,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_TIME_ENTRY.LOADING})
      res = await api<any>(`${APIS.timeEntry}`, 'POST', body)
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_TIME_ENTRY.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_TIME_ENTRY.ERROR, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch ({message}: any) {
      dispatch({type: CREATE_TIME_ENTRY.ERROR})
      return 1
    }
  }
}

export function getSiteVisitEntryAction(
  siteVisitId: number,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TIME_ENTRY.LOADING})
      res = await api<any>(`${APIS.timeEntry}/site-visits/${siteVisitId}`)
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: GET_TIME_ENTRY.SUCCESS, payload: data})
        successCallback && successCallback()
        return 1
      } else {
        dispatch({type: GET_TIME_ENTRY.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_TIME_ENTRY.ERROR})
      return 1
    }
  }
}

export function updateSiteVisitEntryAction(
  timeEntryId: number,
  body: any,
  successCallback?: any,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_TIME_ENTRY.LOADING})
      res = await api<any>(`${APIS.timeEntry}/${timeEntryId}`, 'PATCH', body)
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_TIME_ENTRY.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_TIME_ENTRY.ERROR, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch ({message}: any) {
      dispatch({type: UPDATE_TIME_ENTRY.ERROR})
      return 1
    }
  }
}

export function deleteSiteVisitEntryAction(
  timeEntryId: number,
  successCallback?: any,
  toast?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_TIME_ENTRY.LOADING})
      res = await api<any>(`${APIS.timeEntry}/${timeEntryId}`, 'DELETE')
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: DELETE_TIME_ENTRY.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_TIME_ENTRY.ERROR, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch ({message}: any) {
      dispatch({type: DELETE_TIME_ENTRY.ERROR})
      return 1
    }
  }
}

export function markAsReadyToInvoice(
  projectId: number,
  siteVisitId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MARK_AS_READY_TO_INVOICE.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/site-visits/${siteVisitId}/mark-as-ready-to-invoice`,
        'PATCH',
      )

      const {success, data} = res.data

      if (success) {
        dispatch({type: MARK_AS_READY_TO_INVOICE.SUCCESS})
        successCallback?.()
        toast.success(data.message)
      } else {
        dispatch({type: MARK_AS_READY_TO_INVOICE.ERROR})
        errorHandler(res.data, toast)
      }
    } catch (message) {
      dispatch({type: MARK_AS_READY_TO_INVOICE.ERROR})
      errorHandler(res.data, toast)
    }
  }
}

export function manuallyMarkAsInvoiced(
  projectId: number,
  siteVisitId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MANUALLY_MARK_AS_INVOICED.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/site-visits/${siteVisitId}/mark-as-invoiced`,
        'PATCH',
      )

      const {success, data} = res.data

      if (success) {
        dispatch({type: MANUALLY_MARK_AS_INVOICED.SUCCESS})
        successCallback?.()
        toast.success(data.message)
      } else {
        dispatch({type: MANUALLY_MARK_AS_INVOICED.ERROR})
        errorHandler(res.data, toast)
      }
    } catch (message) {
      dispatch({type: MANUALLY_MARK_AS_INVOICED.ERROR})
      errorHandler(res.data, toast)
    }
  }
}

export function voidSiteVisitAction(
  projectId: number,
  siteVisitId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: VOID_SITE_VISIT.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/site-visits/${siteVisitId}/void`,
        'PATCH',
      )

      const {success, data} = res.data

      if (success) {
        dispatch({type: VOID_SITE_VISIT.SUCCESS})
        successCallback?.()
        toast.success(data.message)
      } else {
        dispatch({type: VOID_SITE_VISIT.ERROR})
        errorHandler(res.data, toast)
      }
    } catch (message) {
      dispatch({type: VOID_SITE_VISIT.ERROR})
      errorHandler(res.data, toast)
    }
  }
}
export function markLabourComplete(
  siteVisitId: number,
  assignedEmpId: number,
  body: any,
  callback: any,
  toast: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      res = await api<any>(
        APIS.labour_complete
          .replace(':siteVisitId', String(siteVisitId))
          .replace(':empId', String(assignedEmpId)),
        'PATCH',
        body,
      )

      const {success, data} = res.data

      if (success) {
        dispatch({type: LABOUR_COMPLETE.SUCCESS})
        callback && callback()
        toast.success(data.message)
      } else {
        dispatch({type: LABOUR_COMPLETE.ERROR})
        errorHandler(res.data, toast)
      }
    } catch (message) {
      dispatch({type: LABOUR_COMPLETE.ERROR})
      errorHandler(res.data, toast)
    }
  }
}

export function labourNeedsToReturn(
  siteVisitId: number,
  assignedEmpId: number,
  callback: any,
  toast: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      res = await api<any>(
        APIS.labour_needs_to_return
          .replace(':siteVisitId', String(siteVisitId))
          .replace(':empId', String(assignedEmpId)),
        'PATCH',
      )

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: LABOUR_NEEDS_TO_RETURN.SUCCESS})
        callback && callback()
        toast.success(message)
      } else {
        dispatch({type: LABOUR_NEEDS_TO_RETURN.ERROR})
        errorHandler(res.data, toast)
      }
    } catch (message) {
      dispatch({type: LABOUR_NEEDS_TO_RETURN.ERROR})
      errorHandler(res.data, toast)
    }
  }
}

export function getSiteVisitTodayEventsListAction(
  userId: number,
  date: string,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_VISIT_TODAY_EVENTS_LIST.LOADING})
      res = await api<any>(`${APIS.todaysJob}/${userId}?date=${date}`)

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_SITE_VISIT_TODAY_EVENTS_LIST.SUCCESS,
          payload: data,
        })
        successCallback && successCallback()
        return 1
      } else {
        dispatch({
          type: GET_SITE_VISIT_TODAY_EVENTS_LIST.ERROR,
          payload: message,
        })
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_SITE_VISIT_TODAY_EVENTS_LIST.ERROR})
      return 1
    }
  }
}

export function getTimeEntryTodayEventsListAction(
  userId: number,
  date: string,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TIME_ENTRY_TODAY_EVENTS_LIST.LOADING})
      res = await api<any>(`${APIS.timeEntry}/user/${userId}?date=${date}`)

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_TIME_ENTRY_TODAY_EVENTS_LIST.SUCCESS,
          payload: data,
        })
        successCallback && successCallback()
        return 1
      } else {
        dispatch({
          type: GET_TIME_ENTRY_TODAY_EVENTS_LIST.ERROR,
          payload: message,
        })
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_TIME_ENTRY_TODAY_EVENTS_LIST.ERROR})
      return 1
    }
  }
}
