export const PRELIMINARY_TABLE_HEADINGS = {
  transport: [
    {key: 'name', title: 'Name', width: '50%', hidden: false},
    {key: 'noOfWeeks', title: 'No. of weeks', width: '10%', hidden: true},
    {
      key: 'noOfQuantity',
      title: 'No. of Transport',
      width: '10%',
      hidden: true
    },

    {key: 'rate', title: 'Rate', width: '10%', hidden: false},
    {key: 'totalCost', title: 'Total Cost', width: '10%', hidden: true}
  ],
  fuel: [
    {key: 'name', title: 'Name', width: '40%', hidden: false},
    {key: 'noOfWeeks', title: 'No. of weeks', width: '10%', hidden: true},
    {
      key: 'noOfQuantity',
      title: 'No. of Fuel',
      width: '10%',
      hidden: true
    },
    {key: 'rate', title: 'Rate', width: '10%', hidden: false},
    {
      key: 'noOfTransport',
      title: 'No. of transport',
      width: '10%',
      hidden: true
    },
    {key: 'totalCost', title: 'Total Cost', width: '10%', hidden: false}
  ],
  'dynamic item': [
    {key: 'name', title: 'Name', width: '50%', hidden: false},
    {
      key: 'noOfQuantity',
      title: 'No. of Item',
      width: '10%',
      hidden: true
    },
    {key: 'rate', title: 'Rate', width: '10%', hidden: false},
    {key: 'totalCost', title: 'Total Cost', width: '10%', hidden: false}
  ],
  item: [
    {key: 'name', title: 'Name', width: '50%', hidden: false},
    {key: 'noOfWeeks', title: 'No. of weeks', width: '10%', hidden: true},
    {key: 'rate', title: 'Rate', width: '10%', hidden: false},
    {key: 'totalCost', title: 'Total Cost', width: '10%', hidden: false}
  ]
}

export const preliminaryKeys = {
  fuel: [
    'name',
    'total_weeks',
    'total_frequency',
    'total_vehicles',
    'rate',
    'total_cost'
  ],
  'dynamic item': ['name', 'total_frequency', 'rate', 'total_cost'],
  transport: ['name', 'total_weeks', 'total_frequency', 'rate', 'total_cost'],
  item: ['name', 'total_weeks', 'rate', 'total_cost']
}
