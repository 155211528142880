import {
  GET_INVOICES,
  ADD_NEW_INVOICE,
  GET_INVOICE_BY_ID,
  ADD_COST_TO_INVOICE,
  CREATE_INVOICE_MANUAL_LINE_ITEM,
  GENERATE_SELECTED_TICKETS_INVOICE,
  GET_SITE_VISIT_LIST_WITH_TICKETS,
  GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE,
  GET_INVOICABLE_TICKETS_OF_SITE_VISIT,
  ADD_INVOICE_ITEM_WITH_TICKETS,
  ADD_INVOICE_LINE_ITEM_WITH_TICKETS,
  CREATE_WORKER_INVOICE_ITEM,
  UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM,
  CREATE_WORKER_INVOICE_LINE_ITEM,
  GET_WORKER_PROJECT_LABOUR_ENTRIES,
  GET_WORKER_INVOICE_BY_ID,
  GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE,
  GET_INVOICABLE_PROJECT_LABOUR_ENTRIES,
  GET_WORKER_INVOICE_LIST_OF_COMPANY,
  GET_UNASSIGNED_TICKETS_OF_PROJECTS,
  CREATE_INVOICE_ITEMS_WITH_TICKETS,
  GET_LABOUR_ENTRY_BY_TICKET_ID,
  ADD_INVOICE_MANUAL_LABOUR_ENTRY,
  CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM,
  GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE,
  GET_INVOICABLE_PROJECT_LABOUR_ENTRIES_OF_PROJECT
} from '../actions'

const initialState: RT.ProjectInvoicingReduxType = {
  invoicesLoading: false,
  invoicesError: false,
  invoices: undefined,
  invoiceTotal: 0,

  activeInvoice: {},
  addInvoicesLoading: false,

  invoiceDetailLoading: false,
  invoiceDetailError: false,
  invoiceDetail: undefined,
  addCostLoading: false,

  addManualLineItemLoading: false,
  generateTicketsInvoiceLoading: false,

  siteVisitsAlongWithTickets: undefined,
  siteVisitWithTicketsLoading: false,

  siteVisitsNotPresentInInvoice: undefined,
  siteVisitsNotPresentInInvoiceLoading: false,

  invoicableTicketList: undefined,
  invoicableTicketListLoading: false,
  invoicableTicketListTotal: 0,

  addInvoiceItemWithTicketLoading: false,
  addInvoiceLineItemWithTicketLoading: false,

  updateWorkerInvoiceItemLoading: false,
  createWorkerInvoiceItemLoading: false,

  createWorkerInvoiceLineItemLoading: false,
  updateWorkerInvoiceLineItemLoading: false,

  labourEntryByTicketId: undefined,
  getLabourEntryByTicketIdLoading: false,

  projectLabourEntriesForWorkerInvoice: undefined,
  projectLabourEntriesLoading: false,

  workerInvoiceDetails: undefined,
  workerInvoiceDetailsLoading: false,

  projectListOfUserNotIncludedInWorkerInvoice: undefined,
  projectListOfUserNotIncludedInInvoiceLoading: false,

  invoicableProjectLabourEntries: undefined,
  invoicableProjectLabourEntriesLoading: false,

  workerInvoiceLisOfCompany: undefined,
  workerInvoiceLisOfCompanyTotal: 0,
  workerInvoiceLisOfCompanyLoading: false,

  unassignedTicketsOfProject: undefined,
  unassignedTicketsOfProjectLoading: false,

  createInvoiceItemsWithTicketsLoading: false,
  addInvoiceManualLabourEntryLoading: false,
  createWorkerInvoiceManualLineItemLoading: false,

  projectListNotIncludedInWorkerInvoice: undefined,
  projectListNotIncludedInWorkerInvoiceLoading: false,

  invoicableProjectLabourEntriesOfWorkerInvoice: undefined,
  invoicableProjectLabourEntriesOfWorkerInvoiceLoading: false
}

export function invoicesReducer(state = initialState, action: any) {
  const {type, payload} = action

  switch (type) {
    case 'LOCAL_ACTIVE_INVOICE':
      return {
        ...state,
        activeInvoice: payload
      }

    case GET_INVOICES.LOADING:
      return {
        ...state,
        invoiceDetailLoading: false,
        invoicesLoading: true,
        invoicesError: false
      }
    case GET_INVOICES.SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoicesError: false,
        invoices: payload.rows,
        invoiceTotal: payload.total
      }
    case GET_INVOICES.ERROR:
      return {...state, invoicesLoading: false, invoicesError: payload}

    case ADD_NEW_INVOICE.LOADING:
      return {
        ...state,
        addInvoicesLoading: true,
        invoicesError: false
      }
    case ADD_NEW_INVOICE.SUCCESS:
      return {
        ...state,
        addInvoicesLoading: false,
        invoicesError: false,
        draftInvoice: payload
      }
    case ADD_NEW_INVOICE.ERROR:
      return {...state, addInvoicesLoading: false, invoicesError: payload}

    case ADD_COST_TO_INVOICE.LOADING:
      return {
        ...state,
        addCostLoading: true
      }
    case ADD_COST_TO_INVOICE.SUCCESS:
      return {
        ...state,
        addCostLoading: false
      }
    case ADD_COST_TO_INVOICE.ERROR:
      return {...state, addCostLoading: false}

    case GET_INVOICE_BY_ID.LOADING:
      return {
        ...state,
        invoiceDetailLoading: true,
        invoiceDetailError: false
      }
    case GET_INVOICE_BY_ID.SUCCESS:
      return {
        ...state,
        invoiceDetailLoading: false,
        invoiceDetailError: false,
        invoiceDetail: payload
      }
    case GET_INVOICE_BY_ID.ERROR:
      return {
        ...state,
        invoiceDetailLoading: false,
        invoiceDetailError: payload
      }
    case CREATE_INVOICE_MANUAL_LINE_ITEM.LOADING:
      return {
        ...state,
        addManualLineItemLoading: true
      }
    case CREATE_INVOICE_MANUAL_LINE_ITEM.SUCCESS:
      return {
        ...state,
        addManualLineItemLoading: false
      }
    case CREATE_INVOICE_MANUAL_LINE_ITEM.ERROR:
      return {
        ...state,
        addManualLineItemLoading: false
      }

    case GENERATE_SELECTED_TICKETS_INVOICE.LOADING:
      return {
        ...state,
        generateTicketsInvoiceLoading: true
      }
    case GENERATE_SELECTED_TICKETS_INVOICE.SUCCESS:
      return {
        ...state,
        generateTicketsInvoiceLoading: false
      }
    case GENERATE_SELECTED_TICKETS_INVOICE.ERROR:
      return {
        ...state,
        generateTicketsInvoiceLoading: false
      }

    case GET_SITE_VISIT_LIST_WITH_TICKETS.LOADING:
      return {
        ...state,
        siteVisitWithTicketsLoading: true
      }
    case GET_SITE_VISIT_LIST_WITH_TICKETS.SUCCESS:
      return {
        ...state,
        siteVisitsAlongWithTickets: payload,
        siteVisitWithTicketsLoading: false
      }
    case GET_SITE_VISIT_LIST_WITH_TICKETS.ERROR:
      return {
        ...state,
        siteVisitWithTicketsLoading: false
      }

    case GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE.LOADING:
      return {
        ...state,
        siteVisitsNotPresentInInvoiceLoading: true
      }
    case GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE.SUCCESS:
      return {
        ...state,
        siteVisitsNotPresentInInvoice: payload,
        siteVisitsNotPresentInInvoiceLoading: false
      }
    case GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE.ERROR:
      return {
        ...state,
        siteVisitsNotPresentInInvoiceLoading: false
      }

    case GET_INVOICABLE_TICKETS_OF_SITE_VISIT.LOADING:
      return {
        ...state,
        invoicableTicketListLoading: true
      }
    case GET_INVOICABLE_TICKETS_OF_SITE_VISIT.SUCCESS:
      return {
        ...state,
        invoicableTicketListLoading: false,
        invoicableTicketList: payload
      }
    case GET_INVOICABLE_TICKETS_OF_SITE_VISIT.ERROR:
      return {
        ...state,
        invoicableTicketListLoading: false
      }

    case ADD_INVOICE_ITEM_WITH_TICKETS.LOADING:
      return {
        ...state,
        addInvoiceItemWithTicketLoading: true
      }
    case ADD_INVOICE_ITEM_WITH_TICKETS.SUCCESS:
      return {
        ...state,
        addInvoiceItemWithTicketLoading: false
      }
    case ADD_INVOICE_ITEM_WITH_TICKETS.ERROR:
      return {
        ...state,
        addInvoiceItemWithTicketLoading: false
      }

    case ADD_INVOICE_LINE_ITEM_WITH_TICKETS.LOADING:
      return {
        ...state,
        addInvoiceLineItemWithTicketLoading: true
      }
    case ADD_INVOICE_LINE_ITEM_WITH_TICKETS.SUCCESS:
      return {
        ...state,
        addInvoiceLineItemWithTicketLoading: false
      }
    case ADD_INVOICE_LINE_ITEM_WITH_TICKETS.ERROR:
      return {
        ...state,
        addInvoiceLineItemWithTicketLoading: false
      }

    case CREATE_WORKER_INVOICE_ITEM.LOADING:
      return {
        ...state,
        createWorkerInvoiceItemLoading: true
      }
    case CREATE_WORKER_INVOICE_ITEM.SUCCESS:
      return {
        ...state,
        createWorkerInvoiceItemLoading: false
      }
    case CREATE_WORKER_INVOICE_ITEM.ERROR:
      return {
        ...state,
        createWorkerInvoiceItemLoading: false
      }

    case UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.LOADING:
      return {
        ...state,
        updateWorkerInvoiceItemLoading: true
      }
    case UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.SUCCESS:
      return {
        ...state,
        updateWorkerInvoiceItemLoading: false
      }
    case UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.ERROR:
      return {
        ...state,
        updateWorkerInvoiceItemLoading: false
      }

    case CREATE_WORKER_INVOICE_LINE_ITEM.LOADING:
      return {
        ...state,
        createWorkerInvoiceLineItemLoading: true
      }
    case CREATE_WORKER_INVOICE_LINE_ITEM.SUCCESS:
      return {
        ...state,
        createWorkerInvoiceLineItemLoading: false
      }
    case CREATE_WORKER_INVOICE_LINE_ITEM.ERROR:
      return {
        ...state,
        createWorkerInvoiceLineItemLoading: false
      }

    case GET_WORKER_PROJECT_LABOUR_ENTRIES.LOADING:
      return {
        ...state,
        projectLabourEntriesLoading: true
      }
    case GET_WORKER_PROJECT_LABOUR_ENTRIES.SUCCESS:
      return {
        ...state,
        projectLabourEntriesForWorkerInvoice: payload,
        projectLabourEntriesLoading: false
      }
    case GET_WORKER_PROJECT_LABOUR_ENTRIES.ERROR:
      return {
        ...state,
        projectLabourEntriesLoading: false
      }

    case GET_LABOUR_ENTRY_BY_TICKET_ID.LOADING:
      return {
        ...state,
        getLabourEntryByTicketIdLoading: true
      }
    case GET_LABOUR_ENTRY_BY_TICKET_ID.SUCCESS:
      return {
        ...state,
        labourEntryByTicketId: payload,
        getLabourEntryByTicketIdLoading: false
      }
    case GET_LABOUR_ENTRY_BY_TICKET_ID.ERROR:
      return {
        ...state,
        getLabourEntryByTicketIdLoading: false
      }

    case GET_WORKER_INVOICE_BY_ID.LOADING:
      return {
        ...state,
        workerInvoiceDetailsLoading: true
      }
    case GET_WORKER_INVOICE_BY_ID.SUCCESS:
      return {
        ...state,
        workerInvoiceDetails: payload,
        workerInvoiceDetailsLoading: false
      }
    case GET_WORKER_INVOICE_BY_ID.ERROR:
      return {
        ...state,
        workerInvoiceDetailsLoading: false
      }

    case GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE.LOADING:
      return {
        ...state,
        projectListOfUserNotIncludedInInvoiceLoading: true
      }
    case GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE.SUCCESS:
      return {
        ...state,
        projectListOfUserNotIncludedInWorkerInvoice: payload,
        projectListOfUserNotIncludedInInvoiceLoading: false
      }
    case GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE.ERROR:
      return {
        ...state,
        projectListOfUserNotIncludedInInvoiceLoading: false
      }

    case GET_INVOICABLE_PROJECT_LABOUR_ENTRIES.LOADING:
      return {
        ...state,
        invoicableProjectLabourEntriesLoading: true
      }
    case GET_INVOICABLE_PROJECT_LABOUR_ENTRIES.SUCCESS:
      return {
        ...state,
        invoicableProjectLabourEntries: payload,
        invoicableProjectLabourEntriesLoading: false
      }
    case GET_INVOICABLE_PROJECT_LABOUR_ENTRIES.ERROR:
      return {
        ...state,
        invoicableProjectLabourEntriesLoading: false
      }

    case GET_UNASSIGNED_TICKETS_OF_PROJECTS.LOADING:
      return {
        ...state,
        unassignedTicketsOfProjectLoading: true
      }
    case GET_UNASSIGNED_TICKETS_OF_PROJECTS.SUCCESS:
      return {
        ...state,
        unassignedTicketsOfProject: payload,
        unassignedTicketsOfProjectLoading: false
      }
    case GET_UNASSIGNED_TICKETS_OF_PROJECTS.ERROR:
      return {
        ...state,
        unassignedTicketsOfProjectLoading: false
      }

    case GET_WORKER_INVOICE_LIST_OF_COMPANY.LOADING:
      return {
        ...state,
        workerInvoiceLisOfCompanyLoading: true
      }
    case GET_WORKER_INVOICE_LIST_OF_COMPANY.SUCCESS:
      return {
        ...state,
        workerInvoiceLisOfCompany: payload?.rows,
        workerInvoiceLisOfCompanyTotal: payload?.total,
        workerInvoiceLisOfCompanyLoading: false
      }
    case GET_WORKER_INVOICE_LIST_OF_COMPANY.ERROR:
      return {
        ...state,
        workerInvoiceLisOfCompanyLoading: false
      }
    case CREATE_INVOICE_ITEMS_WITH_TICKETS.LOADING:
      return {
        ...state,
        createInvoiceItemsWithTicketsLoading: true
      }
    case CREATE_INVOICE_ITEMS_WITH_TICKETS.SUCCESS:
      return {
        ...state,
        createInvoiceItemsWithTicketsLoading: false
      }
    case CREATE_INVOICE_ITEMS_WITH_TICKETS.ERROR:
      return {
        ...state,
        createInvoiceItemsWithTicketsLoading: false
      }

    case ADD_INVOICE_MANUAL_LABOUR_ENTRY.LOADING:
      return {
        ...state,
        addInvoiceManualLabourEntryLoading: true
      }
    case ADD_INVOICE_MANUAL_LABOUR_ENTRY.SUCCESS:
      return {
        ...state,
        addInvoiceManualLabourEntryLoading: false
      }
    case ADD_INVOICE_MANUAL_LABOUR_ENTRY.ERROR:
      return {
        ...state,
        addInvoiceManualLabourEntryLoading: false
      }

    case CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM.LOADING:
      return {
        ...state,
        createWorkerInvoiceManualLineItemLoading: true
      }
    case CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM.SUCCESS:
      return {
        ...state,
        createWorkerInvoiceManualLineItemLoading: false
      }
    case CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM.ERROR:
      return {
        ...state,
        createWorkerInvoiceManualLineItemLoading: false
      }

    case GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE.LOADING:
      return {
        ...state,
        projectListNotIncludedInWorkerInvoiceLoading: true
      }
    case GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE.SUCCESS:
      return {
        ...state,
        projectListNotIncludedInWorkerInvoice: payload,
        projectListNotIncludedInWorkerInvoiceLoading: false
      }
    case GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE.ERROR:
      return {
        ...state,
        projectListNotIncludedInWorkerInvoiceLoading: false
      }

    case GET_INVOICABLE_PROJECT_LABOUR_ENTRIES_OF_PROJECT.LOADING:
      return {
        ...state,
        invoicableProjectLabourEntriesOfWorkerInvoiceLoading: true
      }
    case GET_INVOICABLE_PROJECT_LABOUR_ENTRIES_OF_PROJECT.SUCCESS:
      return {
        ...state,
        invoicableProjectLabourEntriesOfWorkerInvoice: payload,
        invoicableProjectLabourEntriesOfWorkerInvoiceLoading: false
      }
    case GET_INVOICABLE_PROJECT_LABOUR_ENTRIES_OF_PROJECT.ERROR:
      return {
        ...state,
        invoicableProjectLabourEntriesOfWorkerInvoiceLoading: false
      }
    default:
      return state
  }
}
