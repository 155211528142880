import {
  GET_QUOTES,
  GET_QUOTE_ITEMS,
  GET_QUOTE_DETAILS,
  ADD_NEW_LINE_ITEM_MANUAL,
  GET_QUOTE_OVERVIEW,
  VOID_QUOTE,
  GET_ACCEPTED_QUOTE_LINE_ITEM,
  GET_LINE_ITEM_LABOUR_ELEMENTS,
  GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT
} from '../actions'

const initialState: RT.QuoteReduxType = {
  quotesLoading: false,
  quotesError: false,

  quotes: undefined,
  quoteTotal: 0,

  quoteDetailLoading: true,
  quoteDetailError: false,
  quoteDetail: undefined,

  quoteBasicDetailsLoading: false,
  quoteBasicDetails: undefined,

  activeQuoteId: 0,

  addLineItemManualLoading: false,

  quoteOverviewDetails: undefined,
  getQuoteOverviewLoading: false,

  getVoidQuoteLoading: false,

  acceptedQuoteLineItemList: undefined,
  acceptedQuoteLineItemLoading: false,
  lineItemLabourElementList: undefined,
  getLineItemLabourElementListLoading: false,

  materialPurchaseOrder: undefined,
  materialPurchaseOrderLoading: false,
  materialPurchaseOrderTotal: 0
}

export function quotesReducer(state = initialState, action: any) {
  const {type, payload} = action

  switch (type) {
    case GET_QUOTES.LOADING:
      return {
        ...state,
        quotesLoading: true,
        quotesError: false
      }
    case GET_QUOTES.SUCCESS:
      return {
        ...state,
        quotesLoading: false,
        quotesError: false,
        quotes: payload.rows,
        quoteTotal: payload.total
      }
    case GET_QUOTES.ERROR:
      return {...state, quotesLoading: false, quotesError: payload}

    case GET_QUOTE_DETAILS.LOADING:
      return {
        ...state,
        quoteBasicDetailsLoading: true
      }
    case GET_QUOTE_DETAILS.SUCCESS:
      return {
        ...state,
        quoteBasicDetailsLoading: false,
        quoteBasicDetails: payload
      }
    case GET_QUOTE_DETAILS.ERROR:
      return {...state, quoteBasicDetailsLoading: false}

    case GET_QUOTE_ITEMS.CLEAR:
      return {
        ...state,
        quoteDetail: {}
      }

    case GET_QUOTE_ITEMS.LOADING:
      return {
        ...state,
        quoteDetailLoading: true,
        quoteDetailError: false
      }
    case GET_QUOTE_ITEMS.SUCCESS:
      return {
        ...state,
        quoteDetailLoading: false,
        quoteDetailError: false,
        quoteDetail: payload
      }
    case GET_QUOTE_ITEMS.ERROR:
      return {...state, quoteDetailLoading: false, quoteDetailError: payload}

    case ADD_NEW_LINE_ITEM_MANUAL.LOADING:
      return {
        ...state,
        addLineItemManualLoading: true
      }
    case ADD_NEW_LINE_ITEM_MANUAL.SUCCESS:
      return {
        ...state,
        addLineItemManualLoading: false
      }
    case ADD_NEW_LINE_ITEM_MANUAL.ERROR:
      return {
        ...state,
        addLineItemManualLoading: false
      }

    case GET_QUOTE_OVERVIEW.LOADING:
      return {
        ...state,
        getQuoteOverviewLoading: true
      }
    case GET_QUOTE_OVERVIEW.SUCCESS:
      return {
        ...state,
        getQuoteOverviewLoading: false,
        quoteOverviewDetails: payload
      }
    case GET_QUOTE_OVERVIEW.ERROR:
      return {
        ...state,
        getQuoteOverviewLoading: false
      }

    case GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT.LOADING:
      return {
        ...state,
        materialPurchaseOrderLoading: true
      }
    case GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT.SUCCESS:
      return {
        ...state,
        materialPurchaseOrderLoading: false,
        materialPurchaseOrder: payload.rows,
        materialPurchaseOrderTotal: payload.total
      }
    case GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT.ERROR:
      return {
        ...state,
        materialPurchaseOrderLoading: false
      }

    case VOID_QUOTE.LOADING:
      return {
        ...state,
        getVoidQuoteLoading: true
      }
    case VOID_QUOTE.SUCCESS:
      return {
        ...state,
        getVoidQuoteLoading: false
      }
    case VOID_QUOTE.ERROR:
      return {
        ...state,
        getVoidQuoteLoading: false
      }

    case GET_ACCEPTED_QUOTE_LINE_ITEM.LOADING:
      return {
        ...state,
        acceptedQuoteLineItemLoading: true
      }
    case GET_ACCEPTED_QUOTE_LINE_ITEM.SUCCESS:
      return {
        ...state,
        acceptedQuoteLineItemLoading: false,
        acceptedQuoteLineItemList: payload
      }
    case GET_ACCEPTED_QUOTE_LINE_ITEM.ERROR:
      return {...state, acceptedQuoteLineItemLoading: false}
    case GET_LINE_ITEM_LABOUR_ELEMENTS.LOADING:
      return {
        ...state,
        getLineItemLabourElementListLoading: true
      }
    case GET_LINE_ITEM_LABOUR_ELEMENTS.SUCCESS:
      return {
        ...state,
        acceptedQuoteLineItemLoading: false,
        lineItemLabourElementList: payload
      }
    case GET_LINE_ITEM_LABOUR_ELEMENTS.ERROR:
      return {...state, getLineItemLabourElementListLoading: false}

    case 'SET_ACTIVE_QUOTE':
      return {...state, activeQuoteId: payload}

    default:
      return state
  }
}
