import {useAuth} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {
  acceptQuoteAction,
  changeAcceptedQuoteAction,
  declineQuoteAction,
  getCompanyDetails,
  getProjectByIdAction,
  getQuotesAction,
  getSiteVisitListAction,
  markAsSentQuoteAction,
  publishAndMarkAsSentQuoteAction,
  publishQuoteAction,
  RootState,
} from 'redux-src'
import {Dropdown} from 'app/common'
import {useEffect} from 'react'

export const ButtonAction = ({
  projectId,
  activeQuoteId,
  quoteDetails,
  generateQuotePDF,
  quote,
}: {
  projectId: number
  activeQuoteId: number
  quoteDetails: any
  generateQuotePDF: () => void
  quote: Api.QuoteDetailsById
}) => {
  const dispatch = useDispatch()
  const {toast} = useAuth()
  useEffect(() => {
    dispatch(getCompanyDetails())
  }, [dispatch])

  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: RootState) => state.company,
  )

  const onPublishHandler = () => {
    dispatch(
      publishQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getQuotesAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onPublishandMarkAsSentHandler = () => {
    dispatch(
      publishAndMarkAsSentQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getQuotesAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onSendHandler = () => {
    dispatch(
      markAsSentQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getQuotesAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onAccecptHandler = () => {
    dispatch(
      acceptQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getSiteVisitListAction(projectId))
          dispatch(getQuotesAction(projectId))
          dispatch(getProjectByIdAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onChangeAcceptedQuote = () => {
    dispatch(
      changeAcceptedQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getSiteVisitListAction(projectId))
          dispatch(getQuotesAction(projectId))
          dispatch(getProjectByIdAction(projectId))
        },
        () => {},
      ),
    )
  }

  const onDeclineHandler = () => {
    dispatch(
      declineQuoteAction(
        projectId,
        activeQuoteId,
        toast,
        () => {
          dispatch(getSiteVisitListAction(projectId))
          dispatch(getQuotesAction(projectId))
          dispatch(getProjectByIdAction(projectId))
        },
        () => {},
      ),
    )
  }

  const getButtonGroup = (status: string, delivery?: boolean) => {
    switch (true) {
      case status === 'draft':
        return (
          <>
            {quote?.length > 0 &&
            quote.some((value) => {
              return !!value.line_item_list
            }) &&
            !!companyDetails?.company_bank_details?.account_number &&
            !!companyDetails?.company_details?.tax_number ? (
              <Dropdown
                triggerElement={
                  <button
                    type="button"
                    className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm hover:bg-blue-400 hover:text-blue-700 active:bg-blue-400 active:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  >
                    Publish
                  </button>
                }
                inDismiss
                placement="bottomright"
              >
                <div className="w-[200px] bg-white border-1 border-gray-200 rounded-sm">
                  <div
                    className="flex pl-14 py-8 text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200"
                    onClick={onPublishHandler}
                  >
                    Publish
                  </div>

                  <div
                    className="flex  pl-14 py-8  text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200"
                    onClick={onPublishandMarkAsSentHandler}
                  >
                    Publish and Mark as sent
                  </div>
                </div>
              </Dropdown>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  var toastMessage =
                    quote?.length === 0
                      ? 'Empty quotes cannot be published.'
                      : !quote.some((value) => {
                          return !!value.line_item_list
                        })
                      ? 'Quotes with empty line item cannot be published.'
                      : 'You will not be able to create any quotes without filling up necessary company details in general settings page.                      '
                  toast.error(toastMessage)
                }}
                className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm hover:bg-blue-400 hover:text-blue-700 active:bg-blue-400 active:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                Publish
              </button>
            )}
          </>
        )

      case status === 'published' && delivery === false:
        return (
          <button
            type="button"
            className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm 
hover:bg-blue-400 hover:text-blue-700
active:bg-blue-400 active:text-blue-700
 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            onClick={onSendHandler}
          >
            Send
          </button>
        )
      case status === 'published' && delivery === true:
        return (
          <div className="flex gap-8">
            <button
              type="button"
              className=" py-8 px-8 text-sm font-medium text-white bg-blue-300 rounded-sm 
hover:bg-blue-400 hover:text-blue-700
active:bg-blue-400 active:text-blue-700
focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              onClick={() =>
                quoteDetails?.is_super_seeded
                  ? onChangeAcceptedQuote()
                  : onAccecptHandler()
              }
            >
              Accept
            </button>

            <button
              type="button"
              className=" py-8 px-8 text-sm font-medium text-white bg-red-300 rounded-sm 
hover:bg-red-400 hover:text-blue-700
active:bg-red-400 active:text-blue-700
focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              onClick={onDeclineHandler}
            >
              Decline
            </button>
          </div>
        )

      default:
        return null
    }
  }
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      {getButtonGroup(quoteDetails?.status, quoteDetails?.is_delivered)}
    </div>
  )
}
