import {TableColumn, TableStyles} from 'react-data-table-component'
import {useSelector} from 'react-redux'
import colors from 'tailwindcss/colors'
// import { CSVLink, CSVDownload } from 'react-csv'

import {Loading} from '../../../../common'
import {DataTable} from '../../../../components'

export const CustomerTable = (props: any) => {
  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const {get_customer_by_id_loading: loading, get_loading} = useSelector(
    (state: any) => state.customer,
  )

  const columns: TableColumn<any>[] = [
    {
      name: 'Status',
      selector: (row) => row.name,
      sortable: true,
      cell: (row: any) => (
        <div
          key={row.name}
          className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase ${
            row.name ? 'bg-blue-300' : 'bg-gray-400'
          }`}
          // style={{
          //   backgroundColor: chipColor[value as keyof typeof chipColor]
          // }}
        >
          {row.name ? 'Active' : 'Inactive'}
        </div>
      ),
    },
    {
      name: 'Customer Name',
      selector: (row) => row.customerName,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center cursor-pointer text-sm">
          <div
            onClick={() => props.onCustomerClick(row.id)}
            className="text-blue-300 hover:underline"
          >
            {row.customerName}
          </div>
        </div>
      ),
    },
    {
      name: 'Postal Address',
      selector: (row) => row.postalAddress,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center text-sm">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.id}`}
            className="text-black"
          >
            {row.postalAddress}
          </div>
        </div>
      ),
    },
    {
      name: 'Default Customer Contact',
      selector: (row) => row.defaultCustomerContact,
      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center text-sm">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.id}`}
            className="text-black"
          >
            {row.defaultCustomerContact}
          </div>
        </div>
      ),
    },
    {
      name: 'Phone',
      selector: (row) => row.phone,

      sortable: true,
      cell: (row: any) => (
        <div className="flex badge items-center text-sm">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.id}`}
            className="text-black"
          >
            {row.phone}
          </div>
        </div>
      ),
    },
  ]

  const customStyles: TableStyles = {
    table: {
      style: {
        border: '1px solid',
        borderRadius: '5px',
        borderColor: colors.gray[200],
      },
    },
    rows: {
      style: {
        minHeight: '40px !important',
      },
    },

    headCells: {
      style: {
        fontWeight: 'bold',
        // status
        '&:first-child': {
          display: 'flex',
          justifyContent: 'center',
          minWidth: '80px',
          maxWidth: '100px',
        },
        '&:nth-child(2)': {
          minWidth: '200px',
          maxWidth: '200px',
        },
        '&:nth-child(4)': {
          minWidth: '220px',
          maxWidth: '240px',
        },

        '&:not(:last-child)': {
          borderRight: '1px solid',
          borderColor: colors.gray[200],
        },

        // phone
        '&:nth-last-child(2)': {
          minWidth: '130px',
          maxWidth: '130px',
        },
        // jobs
        '&:last-child': {
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'end',
          paddingRight: '20px',
          minWidth: '70px',
          maxWidth: '110px',
        },
      },
    },
    headRow: {
      style: {
        fontWeight: 'bold',
        minHeight: '42px !important',
        background: colors.gray[100],
      },
    },
    cells: {
      style: {
        // status
        '&:first-child': {
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: '600',
          minWidth: '80px',
          maxWidth: '100px',
        },
        '&:nth-child(2)': {
          minWidth: '200px',
          maxWidth: '200px',
        },
        '&:nth-child(4)': {
          minWidth: '220px',
          maxWidth: '240px',
        },
        // phone
        '&:nth-last-child(2)': {
          minWidth: '130px',
          maxWidth: '130px',
        },
        // jobs
        '&:last-child': {
          display: 'flex',
          justifyContent: 'end',
          paddingRight: '20px',
          minWidth: '70px',
          maxWidth: '110px',
        },
        '&:not(:last-child)': {
          borderRight: '1px solid',
          borderColor: colors.gray[200],
        },
      },
    },
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <DataTable
          columns={columns}
          data={props.customers}
          persistTableHead
          dense
          customStyles={customStyles}
          striped
          pagination
          loading={get_loading}
          fetchData={props.customerAddCallback}
          totalRows={props.customerTotal}
        />
      )}
    </>
  )
}
