import moment from 'moment'
import {forwardRef} from 'react'
import DatePicker from 'react-datepicker'

// import React from "react";
export const Input = forwardRef<HTMLInputElement, Com.InputProps>(
  (
    {
      type,
      disabled,
      name,
      onFocus,
      label,
      value,
      placeholder,
      onChange,
      onBlur,
      defaultValue,
      containerClass,
      prepend,
      className,
      error,
      size,
      min,
      max,
      onKeyUp,
      required = false,
      optional,
      inputType,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={`${
          !containerClass ? 'relative w-full form-group mb-18' : containerClass
        }`}
      >
        {label && (
          <label className="flex gap-6 font-medium text-sm mb-6">
            <span className="text-sm font-bold">{label}</span>
            {required && <span className="text-red-500">*</span>}
            {optional && <span className="text-gray-400">(Optional)</span>}
          </label>
        )}
        <div className="flex w-full">
          {prepend && (
            <div className="bg-gray-100 border-1 border-gray-200 rounded-l-sm text-sm flex items-center justify-center px-6 min-w-[24px]">
              {prepend}
            </div>
          )}
          <input
            ref={ref}
            min={min}
            max={max}
            disabled={disabled}
            type={type === 'number' ? 'text' : type}
            name={name}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              if (inputType === 'nonZero') {
                if (/^[1-9]*$|^[1-9][0-9]*$/.test(e?.target?.value)) {
                  e.preventDefault()
                  onChange?.(e)
                  return
                }
                return
              }

              if (inputType === 'numSymbol') {
                if (/^[(0-9a-z-/)]*$/i.test(e?.target?.value)) {
                  e.preventDefault()
                  onChange?.(e)
                  return
                }
                return
              }
              if (inputType === 'alphanumeric') {
                if (/^[0-9A-Za-z]*$/.test(e?.target?.value)) {
                  e.preventDefault()
                  onChange?.(e)
                  return
                }
                return
              }

              if (inputType === 'number' || type === 'number') {
                if (/^(\d*)$/i.test(e?.target?.value)) {
                  e.preventDefault()
                  onChange?.(e)
                  return
                }
                return
              }

              if (inputType === 'decimal') {
                if (/^((\d*(\.\d*)?)|(\.\d+))$/i.test(e?.target?.value)) {
                  e.preventDefault()
                  onChange?.(e)

                  return
                }
                return
              }

              onChange?.(e)
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            className={` ${className} ${
              prepend
                ? 'border-1 border-l-0 rounded-r-sm'
                : 'border-1   rounded-sm'
            } w-full ${error ? 'border-red-300' : ' border-gray-200'} ${
              size === 'sm' ? 'px-10 py-4' : 'px-16 py-6'
            } `}
            onKeyUp={onKeyUp}
            required={required}
            {...rest}
          />
        </div>
        {error && typeof error === 'string' && error?.length > 0 && (
          <p className="text-red-300 text-sm absolute -bottom-18 left-6 ">
            {error}
          </p>
        )}
      </div>
    )
  },
)

interface DateInputProps {
  label?: React.ReactNode
  name?: string
  value?: string
  selected?: Date
  onChange: (date: string) => void
  error?: string | boolean | any
  size?: 'sm' | 'default' | 'md' | 'lg'
  placeholder?: string
  containerClass?: string
  startDate?: Date
  endDate?: Date
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  dateFormat?: string
  required?: boolean
  className?: string
  prepend?: React.ReactNode
}

export const DateInput = ({
  label,
  containerClass,
  onChange,
  error,
  required,
  className,
  dateFormat = 'YYYY-MM-DD',
  placeholder,
  value,
  selected,
  prepend,
  ...rest
}: DateInputProps) => {
  return (
    <div
      className={`flex flex-col gap-6 ${
        !containerClass ? 'relative w-full form-group' : containerClass
      }`}
    >
      {label && (
        <label className="flex gap-6 font-medium text-sm">
          <span className="text-sm font-bold">{label}</span>
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="flex flex-1 w-full">
        {prepend && (
          <div className="bg-gray-100 border-1 border-gray-200 rounded-l-sm text-sm flex items-center px-6">
            {prepend}
          </div>
        )}
        <DatePicker
          wrapperClassName="w-full"
          onChange={(date) => {
            onChange(moment(date).format(dateFormat))
          }}
          className={`border-gray-200 h-[28px] w-full px-8 ${className} ${
            prepend ? 'border-1 border-l-0 rounded-r-sm' : 'border-1 rounded-sm'
          } ${error ? 'border-red-300' : ''}`}
          // dateFormat={dateFormat}
          placeholderText={placeholder}
          value={value}
          selected={selected || new Date(!!value ? value : Date.now())}
          {...rest}
        />
      </div>
      {error && typeof error === 'string' && error?.length > 0 && (
        <p className="text-red-300 text-sm absolute -bottom-18 left-6 ">
          {error}
        </p>
      )}
    </div>
  )
}
