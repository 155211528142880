import {
  FaCalendar,
  FaChevronDown,
  FaChevronUp,
  FaClock,
  FaPen,
  FaReply,
  FaTimes,
} from 'react-icons/fa'
import {AiFillInfoCircle} from 'react-icons/ai'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {
  AnimatedBlock,
  TransitionBlock,
  useAnimatedValue,
} from 'react-ui-animate'
import {
  ButtonGroup,
  ConfirmationModal,
  InfiniteScrollBlock,
  Loading,
  ToolTip,
} from 'app/common'
import {FiCheckSquare} from 'react-icons/fi'
import {
  RootState,
  createProjectNotesAction,
  deleteProjectNotesAction,
  getProjectHistory,
  getProjectNoteByNoteId,
  updateProjectNotesAction,
} from 'redux-src'

import {isStringEmpty} from 'helpers'

const HistoryItem = ({
  data: {date, history, total_count},
  projectId,
  openLogId,
  setOpenLogId,
  fetchParams,
}: {
  projectId: number
  data: Api.ProjectHistory
  openLogId: number
  setOpenLogId: (id: number) => void
  fetchParams: {[key: string]: string | number | any}
}) => {
  const dispatch = useDispatch()
  return (
    <div className="relative">
      <div
        style={{
          position: 'absolute',
          content: '',
          width: 3,
          height: '115%',
          backgroundColor: '#cadffc',
          top: 0,
          left: 70,
        }}
      />

      <div className="relative z-10 bg-blue-150 rounded-sm flex w-[200px] justify-center items-center p-6">
        <span>
          <FaCalendar />
        </span>
        <span className="block ml-4">
          {moment(date).format('ddd, DD MMM YYYY')}
        </span>
      </div>

      {history.map((item, i) => (
        <React.Fragment key={item.id}>
          <div className="flex items-center gap-20 mt-10 mb-30">
            <div className="text-gray-400 flex items-center text-sm">
              <FaClock size={16} />{' '}
              <span
                style={{
                  minWidth: 'fit-content',
                }}
                className="block ml-4 "
              >
                {moment(item.created_at).format('HH:mm a')}
              </span>
            </div>

            {item.type === 'note' ? (
              // <div className="relative w-full border-1 border-gray-200 rounded-sm flex items-center p-6 shadow-inner">
              <div className="w-full">
                {/* <h3> {item.text} </h3> */}
                <NoteContent
                  openLogIndex={openLogId}
                  setOpenLogIndex={setOpenLogId}
                  // linkedId={1}
                  noteDetails={{
                    id: item.note_details?.id,
                    text: item.note_details?.text,
                    created_at: item?.note_details?.created_at,
                  }}
                  userDetails={{
                    display_name: item.display_name,
                    lastname: item.lastname,
                  }}
                  create={(data: {text: string; parentId?: number}, cb) => {
                    if (!isStringEmpty(data?.text)) {
                      dispatch(
                        createProjectNotesAction(projectId, data, () => {
                          dispatch(getProjectNoteByNoteId(projectId, openLogId))
                          cb?.()
                        }),
                      )
                    }
                  }}
                  remove={(noteId, cb) => {
                    dispatch(
                      deleteProjectNotesAction(projectId, noteId, () => {
                        cb?.()

                        dispatch(
                          getProjectHistory(projectId, fetchParams, () => {
                            if (!!openLogId) {
                              dispatch(
                                getProjectNoteByNoteId(projectId, openLogId),
                              )
                            }
                          }),
                        )
                      }),
                    )
                  }}
                  update={(noteId, data) => {
                    dispatch(
                      updateProjectNotesAction(projectId, noteId, data, () => {
                        dispatch(
                          getProjectHistory(projectId, fetchParams, () => {
                            dispatch(
                              getProjectNoteByNoteId(projectId, openLogId),
                            )
                          }),
                        )
                      }),
                    )
                  }}
                />
              </div>
            ) : (
              <div className="relative w-full border-1 border-gray-200 rounded-sm flex items-center p-6 shadow-inner">
                <div className="absolute bg-purple-800 text-white w-24 h-24 flex items-center justify-center rounded-sm p-[2px]">
                  {item.display_name?.split(' ')[0]?.charAt(0)}
                  {item.display_name?.split(' ')[1]?.charAt(0)}
                </div>
                <div className="pl-32 pr-10 flex-1">{item.text}</div>

                <div
                  style={{
                    position: 'absolute',
                    content: '',
                    width: 10,
                    height: 1,
                    backgroundColor: '#E5E5E5',
                    top: '50%',
                    right: '100%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

const HistoryItemLast = () => {
  return (
    <div className="bg-blue-150 flex items-center py-10 px-16 rounded-sm">
      <AiFillInfoCircle className="text-lg text-blue-300" />
      <span className="block ml-6 text-blue-300">End of history log</span>
    </div>
  )
}

export const HistoryListItem = ({
  projectId,
  setLimit,
  fetchParams,
}: {
  projectId: number
  setLimit: React.Dispatch<React.SetStateAction<number>>
  fetchParams?: Record<string, string | number | boolean>
}) => {
  const {projectHistory, isProjectHistoryLoading}: RT.NotesReduxType =
    useSelector((state: RootState) => state.noteHistory)
  const [openLogId, setOpenLogId] = useState<number>()
  const dispatch = useDispatch()
  // * Fetch reply each time selected note changes
  useEffect(() => {
    if (!!openLogId) {
      dispatch(getProjectNoteByNoteId(projectId, openLogId))
    }
  }, [dispatch, openLogId, projectId])

  return (
    <>
      {isProjectHistoryLoading && (
        <div className="w-full h-[100px] flex items-center justify-center">
          <Loading small />
        </div>
      )}
      {!!projectHistory && (
        <InfiniteScrollBlock
          triggerCallback={() => {
            setLimit((prev) => prev + 10)
          }}
          isLast={projectHistory?.isLast}
        >
          {projectHistory?.rows?.map((row, i) => (
            <HistoryItem
              fetchParams={fetchParams}
              openLogId={openLogId}
              setOpenLogId={setOpenLogId}
              data={row}
              projectId={projectId}
              key={`history-${row.date}`}
            />
          ))}
        </InfiniteScrollBlock>
      )}
      <HistoryItemLast />
    </>
  )
}

const NoteContent = ({
  userDetails,
  noteDetails,
  update,
  get,
  remove,
  create,
  linkedId,
  openLogIndex,
  setOpenLogIndex,
}: {
  openLogIndex?: number
  setOpenLogIndex?: (index: number) => void
  create?: (data: {text: string; parentId?: number}, cb?: () => void) => void
  get?: () => void
  remove?: (customerNoteId: string | number, cb: () => void) => void
  update?: (
    noteId: string | number,
    data: {text: string},
    cb?: () => void,
  ) => void
  userDetails: {
    display_name: string
    lastname: string
  }
  noteDetails: {
    id: number
    text: string
    created_at: string
    replies?: Array<any>
  }
  linkedId?: number
}) => {
  const [isReplyActive, setReplyActive] = useState<boolean>(false)
  const [replyValue, setReplyValue] = useState<string>('')

  const {projectNoteById, isProjectNoteByIdLoading}: RT.NotesReduxType =
    useSelector((state: RootState) => state.noteHistory)

  useEffect(() => {
    setReplyActive(openLogIndex === noteDetails.id ? true : false)
  }, [noteDetails.id, openLogIndex])

  const onSubmitHandle = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (replyValue.length === 0) {
      return
    } else {
      create?.(
        {text: replyValue, parentId: projectNoteById?.linked_note_id},
        () => {
          setReplyValue('')
        },
      )
    }
  }

  const [contentHeight, setContentHeight] = useState<number>(0)

  // const bind = useMeasure(({height}) => {
  //   setContentHeight(Number(height))
  // }, [])

  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setContentHeight(parentRef?.current?.clientHeight)
  }, [noteDetails, isReplyActive])

  const heightAnimation = useAnimatedValue(
    isReplyActive ? contentHeight + 65 : contentHeight + 15,
  )

  return (
    <TransitionBlock state={isReplyActive}>
      {(animation) => (
        <AnimatedBlock
          className={`relative flex flex-col p-8 border-1 border-gray-200 rounded-sm my-8 w-full`}
          style={{
            height: heightAnimation.value,
          }}
          // onMouseOver={() => {
          //   setReplyActive(true)
          // }}
          // onMouseLeave={() => {
          //   setReplyActive(false)
          // }}
        >
          <div ref={parentRef}>
            <NoteTextComp
              {...{update, get, remove, noteDetails, userDetails}}
              isParent={true}
              isReplyActive={isReplyActive}
              setIsReplyActive={setReplyActive}
              setOpenLogIndex={setOpenLogIndex}
            />

            {isReplyActive &&
              noteDetails?.id === projectNoteById?.note_details?.id &&
              projectNoteById?.note_details?.replies &&
              projectNoteById?.note_details?.replies.length > 0 && (
                <div className="flex flex-col gap-4 pl-20 py-4">
                  {projectNoteById?.note_details?.replies.map((reply) => {
                    return (
                      <div
                        key={reply.id}
                        className="flex flex-col border border-1 border-gray-200 rounded-sm py-6 px-8"
                      >
                        {isProjectNoteByIdLoading ? (
                          <Loading small />
                        ) : (
                          <NoteTextComp
                            {...{update, get, remove}}
                            noteDetails={{
                              ...reply,
                              created_at: reply?.created_at,
                              replies: undefined,
                            }}
                            userDetails={{
                              display_name: reply?.display_name,
                              lastname: reply?.lastname,
                            }}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              )}

            {!!isReplyActive && (
              <AnimatedBlock
                style={{opacity: animation.value}}
                className="absolute bottom-0 left-0 w-full px-10 py-8"
              >
                <form
                  onSubmit={onSubmitHandle}
                  className={
                    'flex flex-nowrap gap-6 items-center text-gray-400'
                  }
                >
                  <span>
                    <FaReply />
                  </span>
                  <input
                    type="text"
                    placeholder="Type a reply and press enter"
                    value={replyValue}
                    autoFocus
                    onChange={(e) => {
                      setReplyValue(e.target.value)
                    }}
                    className="w-full outline-none py-3 border-b-1 border-gray-200 focus:border-blue-200 hover:border-blue-200 text-gray-600"
                  />
                </form>
              </AnimatedBlock>
            )}
          </div>
        </AnimatedBlock>
      )}
    </TransitionBlock>
  )
}

const NoteTextComp = ({
  userDetails,
  noteDetails,
  update,
  get,
  remove,
  isParent,
  isReplyActive,
  setIsReplyActive,
  setOpenLogIndex,
}: {
  setOpenLogIndex?: any
  get: () => void
  remove: (customerNoteId: string | number, cb: () => void) => void
  update: (
    noteId: string | number,
    data: {text: string},
    cb: () => void,
  ) => void
  userDetails: {
    display_name: string
    lastname: string
  }
  noteDetails: {
    id: number
    text: string
    created_at: string
    replies?: {}
  }
  isParent?: boolean
  isReplyActive?: boolean
  setIsReplyActive?: any
}) => {
  const [isInputShow, setIsInputShow] = useState<boolean>(false)
  const [newEditedNote, setNewEditedNote] = useState<string>(noteDetails.text)
  const handleEditNote = () => {
    setIsInputShow((prev) => !prev)

    if (!!isInputShow) {
      update?.(noteDetails?.id, {text: newEditedNote}, get)
    }
  }

  const buttonGroupData = useMemo(() => {
    let temp = [
      {
        icon: isInputShow ? (
          <FiCheckSquare
            className="border-gray-200"
            size={13}
            color={'green'}
          />
        ) : (
          <FaPen className="border-gray-200" size={11} color={'blue'} />
        ),
        type: 'submit',
        // onClick: () => {
        //   handleEditNote()
        // },
      },
      {
        icon: (
          <ConfirmationModal
            displayElement={
              <FaTimes className="border-gray-200" size={12} color={'red'} />
            }
            danger
            label="Are you sure you want to delete this log?"
            onConfirmClick={() => {
              remove?.(noteDetails?.id, get)
            }}
          />
        ),
        type: 'button',
        onClick: () => {
          // remove?.(noteDetails.id, get)
        },
      },
    ]

    if (isParent) {
      temp = [
        {
          icon: (
            <ToolTip top text={`${isReplyActive ? 'Hide' : 'Show'} Replies`}>
              {!isReplyActive ? (
                <FaChevronDown
                  className="border-gray-200"
                  size={12}
                  color={'gray'}
                />
              ) : (
                <FaChevronUp
                  className="border-gray-200"
                  size={12}
                  color={'gray'}
                />
              )}
            </ToolTip>
          ),
          type: 'button',
          onClick: () => {
            setOpenLogIndex(isReplyActive ? null : noteDetails?.id)
          },
        },
        ...temp,
      ]
    }

    return temp
  }, [
    get,
    isInputShow,
    isParent,
    isReplyActive,
    noteDetails.id,
    remove,
    setOpenLogIndex,
  ])
  return (
    <>
      <div className="pb-8 flex flex-nowrap">
        <div className="bg-purple-800 text-white w-24 h-24 flex items-center justify-center rounded-sm p-[2px] uppercase">
          {userDetails.display_name?.split(' ')[0]?.charAt(0)}
          {userDetails.display_name?.split(' ')[1]?.charAt(0)}
        </div>
        <div className=" px-8 flex-1 text-sm text-gray-400">
          {userDetails.display_name?.split(' ')[0]} | Created at{' '}
          {moment(noteDetails?.created_at).format('ddd, LL')}
        </div>
        {/* {isParent && (
          <div
            className="cursor-pointer text-gray-300 flex justify-end"
            onClick={() => {
              if (isReplyActive) {
                setOpenLogIndex(undefined)
              } else {
                setOpenLogIndex(noteDetails?.id)
              }
            }}
          >
            <Button
              onClick={() => {}}
              title={`${isReplyActive ? 'Hide' : 'Show'} Replies`}
            />
          </div>
        )} */}
      </div>
      <form
        className="flex flex-row justify-between items-center py-[2px] text-sm w-full"
        onSubmit={(e) => {
          e.preventDefault()
          handleEditNote()
        }}
      >
        {isInputShow ? (
          <div className="w-full mr-10">
            <input
              type="text"
              placeholder="Edit note"
              value={newEditedNote}
              autoFocus
              onChange={(e) => {
                setNewEditedNote(e.target.value)
              }}
              className="w-full outline-none py-3 border-b-1 border-gray-200 focus:border-blue-200 hover:border-blue-200 transition-all duration-200 ease-in-out"
            />
          </div>
        ) : (
          <div>{noteDetails.text}</div>
        )}
        <ButtonGroup data={buttonGroupData as any} />
      </form>
    </>
  )
}
