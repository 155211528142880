import React from 'react'
import {Auth} from 'react-auth-navigation'
import {LabourElement, LabourElementGroup} from './component'

export const LabourElementManagamentPage = () => {
  return (
    <div>
      <Auth.Screens path="/settings/labour-element-management" />
    </div>
  )
}

export const LabourElementManagamentComp = () => {
  return (
    <div className="flex flex-col gap-40 m-10 mt-4 mb-10 min-h-[85vh]">
      <LabourElement />
      <LabourElementGroup />
    </div>
  )
}
