import {
  FINANCE_SUMMARY,
  SITE_VISIT_FINANCIAL_BREAKDOWN,
  USERS_FINANCIAL_DETAILS,
  BASIC_PROJECT_PROGRESS_DETAILS,
  PROJECT_DATE_CALCULATOR,
  PROJECT_STATUS,
  GENERATE_PROJECT_REPORTS,
  COST_REPORT,
  CUSTOM_PROJECT_DATE_CALCULATOR,
} from '../actions'

const initalState: RT.ProjectFinancialSummaryReduxType = {
  loading: false,

  financialSummary: undefined,

  usersFinancialDetails: undefined,

  siteVisitFinancialBreakdown: undefined,

  projectDateCalculator: undefined,
  isProjectDateLoading: false,

  customProjectDateCalculator: undefined,
  isCustomProjectDateLoading: false,

  isProjectDetailLoading: false,
  basicProjectProgressDetails: undefined,

  isProjectStatusLoading: false,
  projectStatus: undefined,

  isGenerateReportLoading: false,

  costReport: undefined,
  totalCostReport: 0,
  isCostReportLoading: false,
}

export function financeSummaryReducer(state = initalState, action: any) {
  const {type, payload} = action

  switch (type) {
    case FINANCE_SUMMARY.LOADING:
      return {...state, loading: true}
    case FINANCE_SUMMARY.SUCCESS:
      return {
        ...state,
        loading: false,
        financialSummary: payload,
      }
    case FINANCE_SUMMARY.ERROR:
      return {...state, loading: false}

    case USERS_FINANCIAL_DETAILS.LOADING:
      return {...state, loading: true}
    case USERS_FINANCIAL_DETAILS.SUCCESS:
      return {
        ...state,
        loading: false,
        usersFinancialDetails: payload,
      }
    case USERS_FINANCIAL_DETAILS.ERROR:
      return {...state, loading: false}

    case SITE_VISIT_FINANCIAL_BREAKDOWN.LOADING:
      return {...state, loading: true}
    case SITE_VISIT_FINANCIAL_BREAKDOWN.SUCCESS:
      return {
        ...state,
        loading: false,
        siteVisitFinancialBreakdown: payload,
      }
    case SITE_VISIT_FINANCIAL_BREAKDOWN.ERROR:
      return {...state, loading: false}

    // Cases for project progress

    // Date calculator
    case PROJECT_DATE_CALCULATOR.LOADING:
      return {...state, isProjectDateLoading: true}
    case PROJECT_DATE_CALCULATOR.SUCCESS:
      return {
        ...state,
        isProjectDateLoading: false,
        projectDateCalculator: payload,
      }
    case PROJECT_DATE_CALCULATOR.ERROR:
      return {
        ...state,
        isProjectDateLoading: false,
        projectDateCalculator: undefined,
      }

    case CUSTOM_PROJECT_DATE_CALCULATOR.LOADING:
      return {...state, isCustomProjectDateLoading: true}
    case CUSTOM_PROJECT_DATE_CALCULATOR.SUCCESS:
      return {
        ...state,
        isCustomProjectDateLoading: false,
        customProjectDateCalculator: payload,
      }
    case CUSTOM_PROJECT_DATE_CALCULATOR.ERROR:
      return {
        ...state,
        isCustomProjectDateLoading: false,
        customProjectDateCalculator: undefined,
      }

    // Basic details
    case BASIC_PROJECT_PROGRESS_DETAILS.LOADING:
      return {...state, isProjectDetailLoading: true}
    case BASIC_PROJECT_PROGRESS_DETAILS.SUCCESS:
      return {
        ...state,
        isProjectDetailLoading: false,
        basicProjectProgressDetails: payload,
      }
    case BASIC_PROJECT_PROGRESS_DETAILS.ERROR:
      return {
        ...state,
        isProjectDetailLoading: false,
        basicProjectProgressDetails: undefined,
      }

    // Project Status
    case PROJECT_STATUS.LOADING:
      return {...state, isProjectStatusLoading: true}
    case PROJECT_STATUS.SUCCESS:
      return {...state, isProjectStatusLoading: false, projectStatus: payload}
    case PROJECT_STATUS.ERROR:
      return {...state, projectStatus: undefined, isProjectStatusLoading: false}

    // Generate Report
    case GENERATE_PROJECT_REPORTS.LOADING:
      return {...state, isGenerateReportLoading: true}
    case GENERATE_PROJECT_REPORTS.SUCCESS:
      return {...state, isGenerateReportLoading: false}
    case GENERATE_PROJECT_REPORTS.ERROR:
      return {
        ...state,
        isGenerateReportLoading: false,
      }

    // Get cost report
    case COST_REPORT.LOADING:
      return {...state, isCostReportLoading: true}
    case COST_REPORT.SUCCESS:
      return {
        ...state,
        costReport: payload.rows,
        totalCostReport: payload.total,
        isCostReportLoading: false,
      }
    case COST_REPORT.ERROR:
      return {
        ...state,
        isCostReportLoading: false,
        costReport: undefined,
      }

    default:
      return state
  }
}
