import {useNavigation} from 'react-auth-navigation'
import {FaHourglassEnd, FaHourglassStart} from 'react-icons/fa'
import {useDispatch} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {
  Button,
  DateInput,
  Input,
  SelectField,
  Textarea,
  toast,
} from 'app/common'
import {addProject} from 'redux-src'

export const JobDetails = ({
  detailsForJob,
  existingData,
  onCustomSubmit,
}: any) => {
  const {
    navigation: {navigate},
  } = useNavigation()
  const dispatch = useDispatch()

  const [projectData, {onSubmit, onChange, modified, errors, setValue}] =
    useFormInput(
      {
        projectType: !!existingData
          ? OPTIONS?.find((d) => d.label === existingData?.projectType) ??
            undefined
          : undefined,
        title: existingData?.title ?? '',
        description: existingData?.description ?? '',
        projectStart: existingData?.projectStart ?? '',
        projectEnd: existingData?.projectEnd ?? '',
        projectRate: 0,
      },
      (data) => {
        const {
          projectType,
          title,
          description,
          projectEnd,
          projectStart,
          projectRate,
        } = data
        const {customerId, siteId} = detailsForJob

        const projectDetails = {
          customerId: Number(customerId),
          siteId: !!!siteId ? undefined : Number(siteId),
          projectType: projectType?.label,
          title,
          description: description === '' ? undefined : description,
          startDate: projectStart,
          endDate: projectEnd,
          payRate: projectType?.label === 'Quote' ? projectRate : undefined,
        }

        if (!projectDetails.customerId && !onCustomSubmit) {
          return toast.error('Please add customer details.')
        }

        // if (
        //   detailsForJob?.customerId === 0 ||
        //   detailsForJob?.customerId === undefined ||
        //   detailsForJob?.customerId === null
        // ) {
        //   toast.error('Please enter the customer details!')
        //   return
        // }
        if (!onCustomSubmit) {
          dispatch(
            addProject(projectDetails, (data) => {
              setTimeout(() => {
                data &&
                  data?.id &&
                  navigate(`/projects/${data?.id}/projects-summary`)
              }, 500)
            }),
          )
        } else {
          onCustomSubmit(projectDetails)
        }
      },
      (data: any) => {
        const errors: any = {}

        if (data.title.length === 0) {
          errors.title = 'Please enter the project name!'
        }

        if (data.projectStart === '') {
          errors.projectStart = 'Please select project start date.'
        }

        if (data.projectEnd === '') {
          errors.projectEnd = 'Please select project end date.'
        }

        if (data?.projectType?.label === 'Quote' && data.projectRate === 0) {
          errors.projectRate = 'Project rate cannot be 0.'
        }

        return errors
      },
    )

  // console.log('projectData', projectData)

  return (
    <form onSubmit={onSubmit}>
      <div className="h-auto bg-white flex-1 w-full px-16">
        <div className="text-xl border-b-1  border-gray-200 pt-10 pb-4 text-gray-600 font-600">
          Enter project Details
        </div>
        <p className=" py-16 text-blue-300">
          Select a project type below to decide how the project will be run
        </p>
        <div className="flex justify-center items-center w-full  "></div>
        <div className="mt-1 relative rounded-md shadow-sm  pt-10">
          <div className="flex flex-col flex-wrap items-center">
            <div className="flex flex-1 flex-nowrap w-full lg:w-12/12 gap-16">
              <div className="flex flex-1">
                <Input
                  name="title"
                  size="sm"
                  placeholder="Project Name"
                  label="Project Name"
                  className="border-1 border-gray-200"
                  value={projectData.title}
                  onChange={onChange}
                  required
                  error={modified.title && errors.title}
                />
              </div>
              <div className="flex gap-6 flex-col w-full flex-1 flex-nowrap">
                <span>
                  Project Type <span className="text-red-500 ml-2">*</span>
                </span>
                <SelectField
                  options={OPTIONS}
                  className="w-full"
                  value={projectData?.projectType}
                  onChangeValue={(data) => {
                    setValue('projectType', data)
                  }}
                />
              </div>
            </div>
            <div className="flex w-full gap-16">
              <div className="w-full flex flex-1 lg:w-12/12">
                <DateInput
                  prepend={<FaHourglassStart className="text-blue-300" />}
                  name="projectStart"
                  size="sm"
                  placeholder="Project Start"
                  label="Project Start"
                  containerClass="w-full relative mb-18"
                  value={projectData.projectStart}
                  error={modified.projectStart && errors.projectStart}
                  onChange={(date) => {
                    setValue('projectStart', date)
                  }}
                  required
                />
              </div>

              <div className="w-full flex flex-1 lg:w-12/12">
                <DateInput
                  prepend={<FaHourglassEnd className="text-blue-300" />}
                  name="projectEnd"
                  label={'Project End'}
                  placeholder="Project End"
                  containerClass="w-full relative mb-18"
                  value={projectData.projectEnd}
                  error={modified.projectEnd && errors.projectEnd}
                  onChange={(date) => {
                    setValue('projectEnd', date)
                  }}
                  required
                  minDate={new Date(projectData?.projectStart)}
                />
              </div>
            </div>

            {projectData.projectType?.value === 'Quote' && (
              <div className="w-full flex justify-start flex-1">
                <Input
                  size="sm"
                  name="projectRate"
                  label="Project Rate"
                  inputType="decimal"
                  min={0}
                  className="border-1 border-gray-200"
                  error={modified.projectRate && errors.projectRate}
                  value={projectData?.projectRate}
                  onChange={onChange}
                  required
                  placeholder="Project rate"
                />
              </div>
            )}
            <div className="w-full lg:w-12/12">
              <Textarea
                name="description"
                size="sm"
                placeholder="Project Description"
                label="Description"
                className="border-1 border-gray-200"
                value={projectData.description}
                error={modified.description && errors.description}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-20 ">
        <div className="px-16 m-0">
          <Button
            size="sm"
            className="flex h-6 px-5 m-0  w-full justify-center items-center cursor-pointer rounded-sm justify center"
            title="Create Project"
            type="submit"
            onClick={onSubmit}
          />
        </div>
      </div>
    </form>
  )
}

const OPTIONS = [
  {
    id: 1,
    label: 'Quote',
    value: 'Quote',
  },
  {
    id: 2,
    label: 'Days Work',
    value: 'Days Work',
  },
]
