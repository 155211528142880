// import React from 'react'
import { useFormInput } from 'use-form-input'
import { useNavigation } from 'react-auth-navigation'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Logo, Input, Loading } from '../../common'
import { resetPassword } from '../../../redux-src'
import { passwordValidation } from '../../../utils'

export const ResetPasswordPage = () => {
  const dispatch = useDispatch()
  const { loading, error } = useSelector((state: any) => state.login)

  const { navigation } = useNavigation()
  const { routes, navigate } = navigation

  const [data, { onChange, errors, onSubmit, modified }] = useFormInput(
    {
      password: '',
      confirm_password: '',
    },
    (data) => {
      const params = new URLSearchParams(window.location.search)
      const dataToBeSent = {
        email: params.get('email'),
        token: params.get('token'),
        password: data.password,
      }
      const handleResetPassword = () => {
        navigate(routes['Forgot Password PostConfirmation'].path)
      }
      dispatch(resetPassword(dataToBeSent, handleResetPassword))
    },
    (data) => {
      const errors: any = {}

      const { condition, message } = passwordValidation(data.password)

      if (data.password.length === 0) {
        errors.password = 'Empty password!'
      } else if (condition) {
        errors.password = message
      }

      if (data.confirm_password.length === 0) {
        errors.confirm_password = 'Empty confirm password!'
      } else if (data.confirm_password !== data.password) {
        errors.confirm_password = 'Password mis-match!'
      }

      return errors
    }
  )

  return (
    <div className="px-16 lg:px-40 xl:px-56 w-full">
      <div className="-mx-16  flex items-center   min-h-screen justify-center">
        <div className="w-full sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-4/12 px-16  py-40">
          <div className="mb-32 w-full text-center">
            <div className="flex justify-center mb-32">
              <Logo />
            </div>
            <div className="bg-white w-full py-20 px-20 shadow-default">
              {loading ? (
                <Loading size="auto" />
              ) : (
                <>
                  <h2 className="mb-6 text-2xl font-bold font-merriweather text-gray-900">
                    Password Reset Form
                  </h2>
                  <p className="mb-32">
                    Please privide new password. You will use this password for
                    login from next time.
                  </p>
                  {error && (
                    <div className="px-16">
                      <p className="bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                        {error}
                      </p>
                    </div>
                  )}
                  <form method="post" onSubmit={onSubmit}>
                    <Input
                      type="password"
                      name="password"
                      value={data.password}
                      placeholder="Enter new password"
                      error={modified.password && errors.password}
                      onChange={onChange}
                    />
                    <Input
                      type="password"
                      name="confirm_password"
                      value={data.confirm_password}
                      placeholder="Confirm new password"
                      error={
                        modified.confirm_password && errors.confirm_password
                      }
                      onChange={onChange}
                      className="mt-10"
                    />
                    <div className="px-16 mt-20">
                      <Button
                        type="submit"
                        title="Reset Password"
                        size="sm"
                        className="block w-full"
                      />
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
