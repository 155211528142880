import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {getCompanyDetails} from 'redux-src'
import {
  CompanyDetails,

  // Taxes,
  FinancialSection,
  GeneralSection,
} from './components'

export const GeneralPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCompanyDetails())
  }, [dispatch])

  return (
    <div className="w-12/12 m-10 mt-6 mb-0">
      <CompanyDetails />
      <GeneralSection />
      {/* <CompanyBankDetails /> */}
      {/* <Taxes /> */}
      <FinancialSection />
    </div>
  )
}
