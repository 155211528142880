import { useMemo } from 'react'
import { getCheckListStatus } from '../../../../../helpers'

export const ChecklistStatusChip = ({
  checklist,
}: {
  checklist: { checked_item: number; total_item: number }
}) => {
  const checklistStatus: 'in progress' | 'to start' | 'completed' =
    useMemo(() => {
      return getCheckListStatus(checklist?.checked_item, checklist?.total_item)
    }, [checklist])

  return (
    <>
      {checklistStatus === 'in progress' ? (
        <span className="items-center justify-center py-4 mr-1 text-xs text-white px-6 leading-none bg-blue-300 rounded-full">
          {checklistStatus}
        </span>
      ) : checklistStatus === 'to start' ? (
        <span className="items-center justify-center py-4 mr-1 text-xs text-white px-6 leading-none bg-orange-300 rounded-full">
          {checklistStatus}
        </span>
      ) : (
        <span className="items-center justify-center py-4 mr-1 text-xs text-white px-6 leading-none bg-green-500 rounded-full">
          {checklistStatus}
        </span>
      )}
    </>
  )
}
