import {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'
import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface ShortTextProps {
  id: number
  onSelectElement: (id: number) => void
}

interface LongTextProps {
  id: number
  onSelectElement: (id: number) => void
}

interface TextContentType {
  label: string
  description: string
  value: string
}

//MARK: For Short Text

export const ShortText = ({id, onSelectElement}: ShortTextProps) => {
  const {selectedFormElementId} = useContext(FormContext)
  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [shortText, setShortText] = useState<TextContentType>(
    individualElement?.content as TextContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setShortText((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, shortText, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortText])

  return (
    <div
      className={`border border-1 rounded-sm overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
      ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="flex gap-4 py-8">
          <input
            type="text"
            name="label"
            value={shortText?.label}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />

          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}

      {!individualElement?.settings?.hideDescription && (
        <div className="py-4">
          <input
            type="text"
            name="description"
            value={shortText?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}
      <div className="mx-12 my-6  overflow-hidden border border-1 border-gray-300 rounded-sm py-6">
        <textarea
          name="value"
          id="shortText"
          value={shortText?.value}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          cols={10}
          rows={1}
          placeholder="Type here"
          disabled
          className={`w-full focus:outline-none text-black text-sm ml-12 bg-transparent ${
            selectedFormElementId === id
              ? 'cursor-not-allowed'
              : 'cursor-pointer'
          }`}
        />
      </div>
    </div>
  )
}

//MARK: For Long Text

export const LongText = ({id, onSelectElement}: LongTextProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [longText, setLongText] = useState<TextContentType>(
    individualElement?.content as TextContentType
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setLongText((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, longText, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longText])

  return (
    <div
      className={`flex flex-col border border-1 rounded-sm overflow-hidden ${
        selectedFormElementId === id
          ? 'border-solid border-blue-300'
          : 'border-dashed border-gray-300 hover:bg-blue-100'
      }
      ${individualStyles}
      `}
      onClick={() => {
        onSelectElement(id)
      }}
    >
      {!individualElement?.settings?.hideLabel && (
        <div className="flex gap-4 py-8">
          <input
            type="text"
            name="label"
            value={longText?.label}
            placeholder="Type label here"
            className="text-black text-sm w-full ml-14 bg-transparent"
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
          />
          {individualElement?.settings?.markAsRequired && (
            <div className="text-sm text-red-300 w-[100px] flex flex-nowrap font-bold">
              {' '}
              - Required
            </div>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div className="py-4">
          <input
            type="text"
            name="description"
            value={longText?.description}
            onChange={(e: any) => {
              handleChangeInput(e)
            }}
            placeholder="Type description here"
            className="text-black text-sm w-full ml-14 bg-transparent"
          />
        </div>
      )}
      <div className="mx-12 my-6  overflow-hidden border border-1 border-gray-300 rounded-sm py-6 ">
        <textarea
          name="value"
          id="longText"
          value={longText?.value}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          style={{
            resize: 'none'
          }}
          disabled
          cols={10}
          rows={4}
          placeholder="Type here"
          className={`w-full focus:outline-none text-black text-sm ml-12 bg-transparent resize-none ${
            selectedFormElementId === id
              ? 'cursor-not-allowed'
              : 'cursor-pointer'
          }`}
        />
      </div>
    </div>
  )
}
