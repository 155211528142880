import {toast} from 'app/common'
import {api} from 'config'
import {getParsedUrl} from 'helpers'
import {GET_NOTICE} from './actionTypes.action'

export const createNotice = (
  body: Record<string, any>,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    try {
      const res = await api<Api.Base<any>>('notices', 'POST', {...body})
      if (res?.data !== undefined) {
        const {data, success} = res.data
        if (success) {
          toast.success('Notice created successfully!')
          callback?.()
        } else {
          throw new Error(
            data?.message ??
              'Something went wrong while trying to create a notice. Please try again later.',
          )
        }
      } else {
        throw new Error(
          'Something went wrong while trying to create a notice. Please try again later.',
        )
      }
    } catch (e: any) {
      toast.error(e.message)
    }
  }
}

// * Get company notices

export const getNotice = (
  params?: {page?: number; limit?: number},
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({type: GET_NOTICE.LOADING})

      const res = await api<Api.Base<Api.NoticeList>>(
        getParsedUrl('notices', params),
        'GET',
      )
      if (res.data !== undefined) {
        const {data, success} = res.data
        if (success && data.data !== undefined) {
          // toast.success('Notice created successfully!')
          dispatch({type: GET_NOTICE.SUCCESS, payload: data.data})
          callback?.()
        } else {
          throw new Error(
            data?.message ??
              'Something went wrong while trying to fetch a notice.',
          )
        }
      } else {
        throw new Error('Something went wrong while trying to fetch a notice.')
      }
    } catch (e: any) {
      toast.error(e.message)
      dispatch({type: GET_NOTICE.ERROR})
    }
  }
}
