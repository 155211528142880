import { useEffect, useState } from 'react'
import { useNavigation } from 'react-auth-navigation'
import { HiArrowLeft } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'

import { ItemAttributes, ItemCategories } from '../components/itemAttributes'
import { Button } from 'app/common'
import { ItemDetailsModal } from '../components/itemTypeModal'
import { getItemCategoryListAction, getItemTypeByIdAction } from 'redux-src'

export const ItemDetails = () => {
  const { params } = useNavigation()
  const { itemId } = params as any
  const [showCategory, setShowCategory] = useState(false)
  const {
    navigation: { navigate, routes },
  } = useNavigation()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getItemCategoryListAction(itemId))
    dispatch(getItemTypeByIdAction(itemId))
  }, [dispatch, itemId])

  const { itemCategoryList, itemTypeDetails }: RT.ItemTypeReduxType =
    useSelector((state: any) => state.itemTypes)

  const customStyles = {
    table: {
      style: {
        borderRadius: 'none',
      },
    },
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex items-center justify-between">
          <h2
            className="text-sm flex items-centerw-[150px] mb-10 cursor-pointer"
            style={{ color: 'blue' }}
            onClick={() => navigate(routes['Item Type Management'].path)}
          >
            <span className="mr-10 mt-[3px]">
              <HiArrowLeft />
            </span>
            Back to Item Type List
          </h2>
        </div>
        {/* <button className="px-6 py-4 text-black bg-gray-100 shadow-sm rounded-sm border-1 border-gray-200 hover:bg-gray-100 focus:bg-gray-100">
          <BsThreeDots color={colors.gray[500]} size={16} />
        </button> */}
      </div>
      <div className="flex justify-between border-b-1 border-gray-200 pb-8 mb-20">
        <span className="font-bold text-lg pl-20">{itemTypeDetails?.name}</span>
      </div>

      <ItemAttributes items={itemTypeDetails} customStyles={customStyles} />

      <div className="flex w-full justify-end my-20">
        <Button
          title={`Add ${itemTypeDetails?.name} Category`}
          onClick={() => setShowCategory(true)}
          className="py-8 font-bold"
        />
      </div>

      <ItemDetailsModal
        mode="add"
        title={itemTypeDetails?.name}
        showCategoryModal={showCategory}
        close={() => setShowCategory(false)}
      />

      <ItemCategories
        items={itemCategoryList}
        name={itemTypeDetails?.name}
        customStyles={customStyles}
      />

      <div className="border-b-1 border-gray-200 my-10" />
    </div>
  )
}
