import FullCalendar from '@fullcalendar/react'
import {ButtonGroup} from 'app/common'
import moment from 'moment'
import React, {MutableRefObject, useEffect, useMemo, useState} from 'react'
import ReactDatePicker from 'react-datepicker'
import {CiCalendar} from 'react-icons/ci'
import {
  Datepicker,
  DatepickerEvent,
} from '@meinefinsternis/react-horizontal-date-picker'
import {enUS} from 'date-fns/locale'

import {
  FaArrowsAltH,
  FaArrowsAltV,
  FaCalendar,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa'
import {useCalendar} from '../../context'

interface CalendarHeaderProps {
  calendarRef: MutableRefObject<FullCalendar>
  switchViewMode: React.Dispatch<React.SetStateAction<'h' | 'v'>>
  viewMode: 'h' | 'v'
  isSidebarExpanded?: boolean
  // dateRange: 'fortnight' | 'week' | 'day'
}

export const CalendarHeader = ({
  calendarRef,
  switchViewMode,
  viewMode,
  isSidebarExpanded = true,
}: CalendarHeaderProps) => {
  const calendarApi = calendarRef?.current?.getApi()
  const datePickerRef = React.useRef<ReactDatePicker>(null)

  const {fetchCalendarEvents, calendarState, calendarDispatch} = useCalendar()

  const handleDateChange = (e: DatepickerEvent) => {
    handleCalendarDateChange(e[0])
  }

  // const [calendarDate, setCalendarDate] = useState<{start: Date; end: Date}>()

  useEffect(() => {
    calendarDispatch({
      type: 'SET_SELECTED_DATE',
      payload: {
        start: calendarApi?.getDate(),
        end: moment(calendarApi?.view?.activeEnd).subtract(1, 'day').toDate(),
      },
    })
  }, [calendarApi, calendarDispatch])

  const [selectedView, setSelectedView] = useState<string>()
  useEffect(() => {
    setSelectedView(calendarApi?.view?.type)
  }, [calendarApi?.view?.type])

  // * handle view change
  const handleViewChange = (
    newView:
      | 'resourceTimeGridFortnight'
      | 'resourceTimeGridWeekCustom'
      | 'resourceTimeGridDayCustom',
  ) => {
    calendarApi?.changeView(newView)
    calendarDispatch({
      type: 'SET_SELECTED_DATE',
      payload: {
        start: calendarApi?.getDate(),
        end: moment(calendarApi?.view?.activeEnd).subtract(1, 'day').toDate(),
      },
    })
    setSelectedView(calendarApi?.view?.type)
  }

  // * Handle calendar date change
  const handleCalendarDateChange = (date: Date) => {
    calendarApi?.gotoDate(date)
    // setCalendarDate({
    //   start: date,
    //   end: moment(calendarApi?.view?.activeEnd)
    //     .subtract(1, 'day')
    //     .toDate(),
    // })
    calendarDispatch({
      type: 'SET_SELECTED_DATE',
      payload: {
        start: date,
        end: moment(calendarApi?.view?.activeEnd).subtract(1, 'day').toDate(),
      },
    })
  }

  // * fetch events every time the date changes

  const calendarDate = useMemo(
    () => ({
      start: moment(calendarState?.selectedDate?.start).format('YYYY-MM-DD'),
      end: moment(calendarState?.selectedDate?.end)?.format('YYYY-MM-DD'),
    }),
    [calendarState?.selectedDate],
  )

  // useEffect(() => {
  //   if (
  //     moment(calendarDate?.end).diff(moment(calendarDate?.start), 'day') >= 0
  //   ) {
  //     fetchCalendarEvents?.()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   calendarDate?.end,
  //   calendarDate?.start,
  //   calendarState?.selectedProjectsList,
  // ])

  const [datePickerOpen, setDatePickerOpen] = useState(false)

  useEffect(() => {
    datePickerRef?.current?.setOpen(datePickerOpen)
  }, [datePickerOpen])

  return (
    <div className="calendar-heading py-12">
      <div className="calendar-heading-controls flex justify-between">
        <div className="calendar-heading-controls-left flex gap-x-6">
          <label htmlFor="calendar-date-picker">
            <button
              title="Pick a date"
              onClick={() => {
                setDatePickerOpen((prev) => !prev)
              }}
              className="calendar-heading-controls-button rounded-sm z-10 cursor-pointer p-12"
            >
              <FaCalendar />
            </button>
            <ReactDatePicker
              selected={calendarApi?.getDate()}
              value={moment(calendarApi?.getDate()).format('YYYY-MM-DD')}
              showPopperArrow={false}
              ref={datePickerRef}
              name="calendar-date-picker"
              onChange={(newDate) => {
                handleCalendarDateChange(newDate)
              }}
              className="calendar-heading-controls-button w-[30px] rounded-sm z-10 cursor-pointer hidden"
              icon={<CiCalendar />}
              title="Date Picker"
            />
          </label>

          <ButtonGroup
            data={[
              {
                icon: <FaChevronLeft />,
                className: 'calendar-heading-controls-button rounded-l-sm',
                onClick: () => {
                  calendarApi?.prev()
                  calendarDispatch({
                    type: 'SET_SELECTED_DATE',
                    payload: {
                      end: moment(calendarApi?.view?.activeEnd)
                        .subtract(1, 'day')
                        .toDate(),
                      start: calendarApi?.getDate(),
                    },
                  })
                },
              },
              {
                title: 'Today',
                icon: <></>,
                className: `calendar-heading-controls-button ${
                  moment(
                    calendarState?.selectedDate?.start,
                    'YYYY-MM-DD',
                  ).isSame(moment(), 'day')
                    ? 'calendar-heading-controls-button--date-active'
                    : ''
                }`,
                onClick: () => {
                  calendarApi?.today()
                  calendarDispatch({
                    type: 'SET_SELECTED_DATE',
                    payload: {
                      end: moment(calendarApi?.view?.activeEnd)
                        .subtract(1, 'day')
                        .toDate(),
                      start: calendarApi?.getDate(),
                    },
                  })
                },
              },
              {
                icon: <FaChevronRight />,
                className: 'calendar-heading-controls-button rounded-r-sm',
                onClick: () => {
                  calendarApi?.next()
                  calendarDispatch({
                    type: 'SET_SELECTED_DATE',
                    payload: {
                      end: moment(calendarApi?.view?.activeEnd)
                        .subtract(1, 'day')
                        .toDate(),
                      start: calendarApi?.getDate(),
                    },
                  })
                },
              },
            ]}
          />

          {/* Switch mode button */}
          <ButtonGroup
            data={[
              {
                icon: <FaArrowsAltH />,
                className: `calendar-heading-controls-button rounded-l-sm ${
                  viewMode === 'h'
                    ? 'calendar-heading-controls-button--date-active'
                    : ''
                }`,
                onClick: () => {
                  switchViewMode('h')
                },
              },
              {
                icon: <FaArrowsAltV />,
                className: `calendar-heading-controls-button rounded-r-sm ${
                  viewMode === 'v'
                    ? 'calendar-heading-controls-button--date-active'
                    : ''
                }`,
                onClick: () => {
                  switchViewMode('v')
                },
              },
            ]}
          />
        </div>

        <div className="calendar-heading-controls-center">
          <span className="text-lg">
            {moment(calendarState?.selectedDate?.start).format(
              'dddd, Do [of] MMMM YYYY',
            )}
          </span>
        </div>

        <div className="calendar-heading-controls-right">
          <ButtonGroup
            data={[
              {
                title: 'Fortnight',
                className: `calendar-heading-controls-button rounded-l-sm ${
                  selectedView === 'resourceTimeGridFortnight'
                    ? 'calendar-heading-controls-button--date-active'
                    : ''
                }`,
                onClick: () => {
                  handleViewChange('resourceTimeGridFortnight')
                },
              },
              {
                title: 'Week',
                className: `calendar-heading-controls-button ${
                  selectedView === 'resourceTimeGridWeekCustom'
                    ? 'calendar-heading-controls-button--date-active'
                    : ''
                }`,
                onClick: () => {
                  handleViewChange('resourceTimeGridWeekCustom')
                },
              },
              {
                title: 'Day',
                className: `calendar-heading-controls-button rounded-r-sm ${
                  selectedView === 'resourceTimeGridDayCustom'
                    ? 'calendar-heading-controls-button--date-active'
                    : ''
                }`,
                onClick: () => {
                  handleViewChange('resourceTimeGridDayCustom')
                },
              },
            ]}
          />
        </div>
      </div>

      {/* Horizontal DatePicker */}
      <Datepicker
        locale={enUS}
        onChange={handleDateChange}
        startValue={calendarState?.selectedDate?.start}
        endValue={
          selectedView === 'resourceTimeGridDayCustom'
            ? calendarState?.selectedDate?.start
            : calendarState?.selectedDate?.end
        }
        startDate={
          selectedView !== 'resourceTimeGridFortnight'
            ? moment(calendarState?.selectedDate?.start)
                .subtract(12 + (!calendarState?.sidebarOpen ? 3 : 0), 'days')
                .toDate()
            : moment(calendarState?.selectedDate?.start)
                .subtract(5 + (!calendarState?.sidebarOpen ? 3 : 0), 'days')
                .toDate()
        }
        endDate={
          selectedView !== 'resourceTimeGridFortnight'
            ? moment(calendarState?.selectedDate?.start)
                .add(13 + (!calendarState?.sidebarOpen ? 3 : 0), 'days')
                .toDate()
            : moment(calendarState?.selectedDate?.start)
                .add(19 + (!calendarState?.sidebarOpen ? 4 : 0), 'days')
                .toDate()
        }
        classNames={{
          rangeDays: 'horizontal-selected-date-range',
        }}
      />
    </div>
    // </div>
  )
}
