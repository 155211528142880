import { useState } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { MountedBlock, AnimatedBlock } from 'react-ui-animate'

import { Loading } from '../../../../common'
import { Status, STATUS_COLUMN_ITEMS, StatusPhase } from './constants'

interface StatusBoardProps {
  data: Array<Status>
  loading?: boolean
}

export const StatusBoard = ({ data, loading }: StatusBoardProps) => {
  return (
    <div className="min-w-[800px]">
      {loading ? (
        <Loading />
      ) : (
        <div className="flex px-4 border-t-1 border-gray-200">
          {data.map((d) => (
            <StatusColumn key={d.key} {...d} />
          ))}
        </div>
      )}

      {/* <div className="flex border-t-1 border-gray-200">
        {sampleStatusData.map((_, i) => (
          <div key={i} className="flex-1 text-center text-sm font-bold p-8">
            {currencySymbol}0.00
          </div>
        ))}
      </div> */}
    </div>
  )
}

// MARK: - StatusColumn
const StatusColumn = ({ name, ...rest }: Status) => (
  <div className="flex-1 p-8 pb-0 border-r-1 border-gray-200 last:border-r-0">
    <div className="text-center text-sm font-bold pb-8">{name}</div>

    <div
      className="h-[200px] bg-gray-200 flex flex-col justify-end rounded-t-sm"
      style={{
        border: '1px solid #d1d1d1',
      }}
    >
      {STATUS_COLUMN_ITEMS.map(({ bg, hoverBg, key }, index) => (
        <StatusColumnItem
          key={index}
          bg={bg}
          hoverBg={hoverBg}
          rounded={index === 0}
          data={rest[key as keyof Omit<Status, 'name' | 'key'>]}
        />
      ))}
    </div>
  </div>
)

const StatusColumnItemContainer = styled.div<{ hoverBg: string }>`
  &:hover {
    background-color: ${(props) => props.hoverBg} !important;
  }
`

// MARK: - StatusColumnItem
const StatusColumnItem = ({
  bg,
  hoverBg,
  rounded,
  data,
}: {
  bg: string
  hoverBg: string
  rounded: boolean
  data: StatusPhase
}) => {
  const [hovered, setHovered] = useState(false)
  const phaseStyle = {
    flex: data.value > 0 ? 1 : undefined,
  }

  return (
    <StatusColumnItemContainer
      hoverBg={hoverBg}
      className={clsx(
        'relative min-h-[7px] max-h-[120px] cursor-pointer text-center pt-4 text-white',
        rounded && 'rounded-t-sm'
      )}
      style={{
        backgroundColor: bg,
        ...phaseStyle,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {data.value ? data.value : null}

      <MountedBlock
        state={hovered}
        config={{ from: 0, enter: 1, exit: 0, config: { duration: 140 } }}
      >
        {(animation) => (
          <AnimatedBlock
            style={{
              opacity: animation.value,
            }}
            className="absolute min-w-[180px] left-100 top-0 z-10"
          >
            <div
              className="relative w-full h-full left-10 top-0 opacity-95 rounded-sm text-left p-12"
              style={{
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: '100%',
                  top: 4,
                  width: 0,
                  borderWidth: 4,
                  borderStyle: 'solid',
                  borderColor:
                    'transparent rgba(0,0,0,0.8) transparent transparent',
                }}
              />

              <div>
                <h3 className="mb-8">{data.title}</h3>

                {data.qa.map(({ title, descp }, i) => (
                  <div key={i} className="mt-8">
                    <h3 className="text-sm  text-gray-200">{title}</h3>
                    <p className="text-sm text-gray-300">{descp}</p>
                  </div>
                ))}
              </div>
            </div>
          </AnimatedBlock>
        )}
      </MountedBlock>
    </StatusColumnItemContainer>
  )
}
