import {MouseEvent, ReactNode, useState} from 'react'
import {Modal} from '..'

interface CustomModalProps {
  displayElement: ReactNode
  title?: ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xl'
  className?: string
  children: (arg: any) => React.ReactNode
  withPortal?: boolean
  onCloseCB?: () => void
}
export const CustomModal = ({
  displayElement,
  title,
  size,
  className,
  children,
  onCloseCB,
  withPortal = true,
}: CustomModalProps) => {
  const [visible, setVisible] = useState(false)

  const onCloseModalHandler = () => {
    setVisible(false)
  }

  return (
    <div>
      <div
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.preventDefault()
          setVisible((prev) => !prev)
          visible && onCloseCB?.()
        }}
      >
        {displayElement}
      </div>
      <Modal
        title={title}
        isActive={visible}
        toggleModal={setVisible}
        size={size}
        className={className}
        withPortal={withPortal}
        onClose={onCloseCB}
      >
        {children({onCloseModalHandler})}
      </Modal>
    </div>
  )
}
