import {useIndividualFormElement} from 'hooks'
import {useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

interface MultipleChoicePreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

interface MultipleChoiceContentType {
  label: string
  description: string
  value: Array<{id: number; text: string; isChecked: boolean}>
}

export const MultipleChoicePreview = ({
  id,
  type
}: MultipleChoicePreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  const [multipleChoice, setMultipleChoice] = useState<{
    label: string
    description: string
    value: Array<{id: number; text: string; isChecked: boolean}>
  }>(individualElement?.content as MultipleChoiceContentType)

  const dispatch = useDispatch()

  const onChangeOptions = (event: any, choiceId: number) => {
    const tempChoiceList = [...multipleChoice?.value]

    const updatedChoiceList = tempChoiceList?.map((choice) => {
      if (choice?.id === choiceId) {
        return {
          ...choice,
          isChecked: !choice?.isChecked
        }
      } else {
        return choice
      }
    })

    setMultipleChoice((prev) => ({...prev, value: updatedChoiceList}))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, multipleChoice, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleChoice])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div className="">
        {Array.isArray(individualElement?.content?.value) &&
          individualElement?.content?.value?.map((multipleChoice) => {
            return (
              <label
                key={multipleChoice?.id}
                className="flex items-center gap-6 bg-none px-6 rounded-sm"
              >
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={multipleChoice?.isChecked}
                  disabled={
                    type !== 'web view' &&
                    individualElement?.editableBy?.includes(userRole)
                  }
                  onChange={(e) => {
                    onChangeOptions(e, multipleChoice?.id)
                  }}
                />
                <span className="px-4 py-6 w-[50%] mt-4 rounded-xs text-sm">
                  {multipleChoice?.text}
                </span>
              </label>
            )
          })}
      </div>
    </div>
  ) : (
    <></>
  )
}
