interface ButtonGroupProps {
  data: Array<
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
      icon?: React.ReactNode
      title?: React.ReactNode
    }
  >
  className?: string
}

export const ButtonGroup = ({data, className}: ButtonGroupProps) => {
  return (
    <div className={`flex w-[max-content] ${className ?? ''}`}>
      {data.map(({icon, title, ...rest}, index) => (
        <button
          key={index}
          className="border-1 border-r-0 border-gray-200 p-4 flex items-center gap-4 px-8 last:border-r-1 first:rounded-l-sm last:rounded-r-sm hover:bg-gray-100 whitespace-nowrap"
          {...rest}
        >
          {icon}
          {title}
        </button>
      ))}
    </div>
  )
}
