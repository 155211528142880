import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BsFillInfoCircleFill, BsTextCenter} from 'react-icons/bs'
import {AiOutlineAlignLeft, AiOutlineAlignRight} from 'react-icons/ai'

import {Checkbox, SelectField} from 'app/common'

import {useIndividualFormElement} from 'hooks'
import {deleteFormSection} from 'redux-src'

import {FormContext} from '../formBuilder'
import {formsUserOptions} from '../../../users/pages/usersMainPage/constants'

interface FormElementSettingType {
  label: string
  isChecked: boolean
  id: number
}

interface ElementSizeType<T> {
  id: number
  icon: React.ReactNode
  value: T
  isActive: boolean
}

const settingsLabelMap = {
  hideLabel: 'Hide Label',
  hideDescription: 'Hide Description',
  markAsRequired: 'Mark As Required',
  numbered: 'Numbered',
  allowClearSignature: 'Allow Clear Signature',
  multipleUpload: 'Multiple Upload'
}

const defaultElementSize: Array<
  ElementSizeType<'100%' | '50%' | '33%' | '66%'>
> = [
  {
    id: 6,
    icon: (
      <div className="flex items-center justify-center">
        <div className="flex h-[17px] w-[16px] rounded-xs bg-gray-500 items-center"></div>
      </div>
    ),
    isActive: true,
    value: '100%'
  },
  {
    id: 3,
    icon: (
      <div className="flex gap-[1px] items-center justify-center">
        <div className="flex h-[17px] w-[8px] rounded-xs bg-gray-500 items-center"></div>
        <div className="flex h-[17px] w-[8px] rounded-xs bg-gray-500 items-center"></div>
      </div>
    ),
    isActive: false,
    value: '50%'
  },
  {
    id: 2,
    icon: (
      <div className="flex gap-[1px] items-center">
        <div className="flex h-[17px] w-[6px] rounded-xs bg-gray-500 items-center"></div>
        <div className="flex h-[17px] w-[6px] rounded-xs bg-gray-500 items-center"></div>
        <div className="flex h-[17px] w-[6px] rounded-xs bg-gray-500 items-center"></div>
      </div>
    ),
    isActive: false,
    value: '33%'
  },
  {
    id: 4,
    icon: (
      <div className="flex gap-[1px] items-center">
        <div className="flex h-[17px] w-[6px] rounded-xs bg-gray-500 items-center"></div>
        <div className="flex h-[17px] w-[6px] rounded-xs bg-gray-500 items-center"></div>
        <div className="flex h-[17px] w-[6px] rounded-xs border-1 border-gray-300 items-center"></div>
      </div>
    ),
    isActive: false,
    value: '66%'
  }
]

const defaultElementAlignment: Array<
  ElementSizeType<'left' | 'center' | 'right'>
> = [
  {
    id: 1,
    icon: <AiOutlineAlignLeft size={18} />,
    isActive: true,
    value: 'left'
  },
  {
    id: 2,
    icon: <BsTextCenter size={18} />,
    isActive: false,
    value: 'center'
  },
  {
    id: 3,
    icon: <AiOutlineAlignRight size={18} />,
    isActive: false,
    value: 'right'
  }
]

export const FormElementSettings = () => {
  const dispatch = useDispatch()

  const [fontSize, setFontSize] = useState<{
    id: number
    value: 'small' | 'medium' | 'large'
    label: string
  }>()

  const [elementSize, setElementSize] =
    useState<Array<ElementSizeType<'100%' | '50%' | '33%' | '66%'>>>()

  const [elementAlignment, setElementAlignment] =
    useState<Array<ElementSizeType<'left' | 'center' | 'right'>>>()

  const [formElementSetting, setFormElementSetting] =
    useState<Array<FormElementSettingType>>()

  const [editableBy, setEditableBy] = useState<
    Array<{id: number; label: string; value: string}>
  >(formsUserOptions?.filter((users) => users?.id === 1))

  const [visibleTo, setVisibleTo] = useState<
    Array<{id: number; label: string; value: string}>
  >(formsUserOptions?.filter((users) => users?.id === 1))

  const {
    selectedFormElementId: elementId,
    removeSelectedElement,
    selectedElement: individualElement
  } = useContext(FormContext)

  const {
    individualElement: hookElement,
    alterElementSettings,
    defaultElement
  } = useIndividualFormElement(elementId)

  const {formElementSettings}: RT.FormsReduxType = useSelector(
    (state: any) => state.forms
  )

  const onElementSizeChange = (tempSizeId: number) => {
    const tempElementSize = [...elementSize]

    const updatedElementSize = tempElementSize?.map((elementSize) => {
      if (tempSizeId === elementSize?.id) {
        return {
          ...elementSize,
          isActive: true
        }
      } else {
        return {
          ...elementSize,
          isActive: false
        }
      }
    })

    setElementSize(updatedElementSize)

    const selectedSize = updatedElementSize?.find((el) => el.isActive)

    const selectedLayout = {
      ...hookElement.layout,
      w: selectedSize?.id ?? hookElement?.layout.w
    }

    alterElementSettings({
      elementSize: !!individualElement?.elementSize
        ? selectedSize?.value
        : undefined,
      layout: selectedLayout
    })
  }

  const onElementAlignmentChange = (alignmentId: number) => {
    const tempElementAlignment = [...elementAlignment]

    const updatedElementAlignment = tempElementAlignment?.map((alignment) => {
      if (alignmentId === alignment?.id) {
        return {
          ...alignment,
          isActive: true
        }
      } else {
        return {
          ...alignment,
          isActive: false
        }
      }
    })

    setElementAlignment(updatedElementAlignment)

    const selectedAlignment = updatedElementAlignment?.find((el) => el.isActive)

    alterElementSettings({
      alignment: !!individualElement?.alignment
        ? selectedAlignment?.value
        : undefined
    })
  }

  const onDeleteFormElement = (elementId: number) => {
    dispatch(
      deleteFormSection(elementId, () => {
        removeSelectedElement()
      })
    )
  }

  const handleCheckboxChange = (tempId: number) => {
    const tempSettingsItems = [...formElementSetting]

    const updatedSettingItems = tempSettingsItems?.map((element) => {
      if (element?.id === tempId) {
        return {
          ...element,
          isChecked: !element?.isChecked
        }
      } else {
        return {...element}
      }
    })

    setFormElementSetting(updatedSettingItems)
    const updatedChoices = updatedSettingItems?.filter(
      (item) =>
        !!!item?.isChecked &&
        item?.label !== 'numbered' &&
        item?.label !== 'markAsRequired'
    )

    if (
      individualElement?.formType === 'multiple choice' ||
      individualElement?.formType === 'single choice'
    ) {
      const updatedChoiceHeight =
        defaultElement?.layout?.h +
        (individualElement?.content?.value.length - 1) * 2 +
        (updatedChoices.length - 1) * 2

      const selectedLayout = {
        ...hookElement.layout,
        h: updatedChoiceHeight
      }

      alterElementSettings({
        settings: updatedSettingItems?.reduce((acc, curr) => {
          return {...acc, [curr.label]: curr.isChecked}
        }, {} as RT.FormElementType['settings']),
        layout: selectedLayout
      })
      return
    }

    const updatedLayoutHeight =
      defaultElement?.layout?.h + (updatedChoices.length - 1)

    const selectedLayout = {
      ...hookElement.layout,
      h: updatedLayoutHeight
    }

    alterElementSettings({
      settings: updatedSettingItems?.reduce((acc, curr) => {
        return {...acc, [curr.label]: curr.isChecked}
      }, {} as RT.FormElementType['settings']),
      layout: selectedLayout
    })
  }

  useEffect(() => {
    if (hookElement) {
      setEditableBy(
        hookElement?.editableBy?.map((item) =>
          formsUserOptions?.find((user) => user?.value === item)
        )
      )
      setVisibleTo(
        hookElement?.visibleTo?.map((item) =>
          formsUserOptions?.find((user) => user?.value === item)
        )
      )
      setElementSize(
        defaultElementSize.map((elSize) => {
          if (elSize.value === hookElement.elementSize) {
            return {...elSize, isActive: true}
          }
          return {...elSize, isActive: false}
        })
      )

      setFontSize(
        fontSizeOptions?.find((elFont) => {
          return elFont?.value === hookElement?.size
        })
      )

      setElementAlignment(
        defaultElementAlignment?.map((alignment) => {
          if (alignment?.value === hookElement?.alignment) {
            return {
              ...alignment,
              isActive: true
            }
          }
          return {
            ...alignment,
            isActive: false
          }
        })
      )

      setFormElementSetting(() =>
        formElementSettings
          ? formElementSettings[
              hookElement?.formType as RT.FormBuilderType
            ]?.map((elSetting) => {
              return {
                ...elSetting,
                isChecked:
                  hookElement?.settings &&
                  hookElement?.settings[
                    elSetting?.label as keyof RT.FormElementType['settings']
                  ]
              }
            })
          : undefined
      )
    }
  }, [elementId, formElementSettings, hookElement])

  return (
    <div className="flex flex-col gap-8 px-16">
      <div className="text-md font-bold capitalize ">
        {individualElement?.formType} -{' '}
        <span className="text-blue-300">
          {individualElement?.elementType === 'data-entry'
            ? 'Data Entry Element'
            : 'Instructional Element'}
        </span>
      </div>
      {individualElement?.settings !== null &&
        individualElement?.settings !== undefined && (
          <div className="flex flex-col">
            <div className="text-sm font-bold">Settings</div>
            <div className="flex flex-col my-6 gap-4">
              {formElementSetting?.map((value) => {
                return (
                  <span
                    className="text-left text-md inline-flex cursor-pointer"
                    key={value?.id}
                  >
                    <Checkbox
                      dataId={value?.label + 'formElement'}
                      label={
                        settingsLabelMap[
                          value.label as keyof typeof settingsLabelMap
                        ]
                      }
                      isChecked={value.isChecked}
                      value={value}
                      className="w-full"
                      labelClassName={'ml-8 text-sm'}
                      onChange={(value, e) => {
                        handleCheckboxChange(value?.id)
                      }}
                    />
                  </span>
                )
              })}
            </div>
          </div>
        )}
      {!!individualElement?.size && (
        <div className="flex flex-col gap-6">
          <div className="text-sm font-bold">Size</div>
          <div className="">
            <SelectField
              options={fontSizeOptions}
              value={fontSize}
              onChangeValue={(value) => {
                setFontSize(value)
                alterElementSettings({size: value?.value})
              }}
            />
          </div>
        </div>
      )}

      {!!individualElement?.alignment && (
        <div className="flex gap-6 flex-col">
          <div className="text-sm font-bold">Alignment</div>
          <div className="flex border border-1 border-solid rounded-sm border-gray-300 w-[max-content] overflow-hidden">
            {elementAlignment?.map((alignment) => {
              return (
                <span
                  key={alignment?.id}
                  className={`px-8 py-8 cursor-pointer transition-all duration-200 ease-in-out ${
                    alignment?.isActive ? 'bg-blue-150' : ''
                  }`}
                  onClick={() => {
                    onElementAlignmentChange(alignment?.id)
                  }}
                >
                  {alignment?.icon}
                </span>
              )
            })}
          </div>
        </div>
      )}

      {!!individualElement?.elementSize && (
        <div className="flex gap-6 flex-col">
          <div className="text-sm font-bold">Element Size</div>
          <div className="flex border border-1 border-solid rounded-sm border-gray-300 w-[max-content] overflow-hidden">
            {elementSize?.map((size) => {
              return (
                <span
                  key={size?.id}
                  className={`flex items-center px-8 py-8 cursor-pointer transition-all duration-200 ease-in-out ${
                    size?.isActive ? 'bg-blue-150' : ''
                  }`}
                  onClick={() => {
                    onElementSizeChange(size?.id)
                  }}
                >
                  {size?.icon}
                </span>
              )
            })}
          </div>
        </div>
      )}

      {!!individualElement?.noSettingMessage && (
        <div className="flex gap-8 py-14 items-center px-12 bg-blue-150 rounded-sm">
          <span>
            <BsFillInfoCircleFill size={18} className="text-blue-300" />
          </span>
          <div className="text-black text-sm">
            {individualElement?.noSettingMessage}
          </div>
        </div>
      )}

      <div className="flex flex-col gap-6 mb-6">
        <div className="text-sm font-bold">Editable By :</div>
        <SelectField
          options={formsUserOptions}
          onMultiChangeValue={(e) => {
            setEditableBy(e?.map((item) => item))
            alterElementSettings({editableBy: e?.map((item) => item.value)})
          }}
          multiValue={editableBy}
          placeholder="Select User Type"
          isMulti
          className="w-full"
        />
      </div>

      <div className="flex flex-col gap-6 ">
        <div className="text-sm font-bold">Visible To :</div>
        <SelectField
          options={formsUserOptions}
          onMultiChangeValue={(e) => {
            setVisibleTo(e?.map((item) => item))
            alterElementSettings({visibleTo: e?.map((item) => item.value)})
          }}
          multiValue={visibleTo}
          placeholder="Select User Type"
          isMulti
          className="w-full"
        />
      </div>

      {!!individualElement && (
        <div
          className="my-10 text-red-300 cursor-pointer text-sm"
          onClick={() => {
            onDeleteFormElement(individualElement?.id)
          }}
        >
          Delete Element
        </div>
      )}
    </div>
  )
}

const fontSizeOptions: Array<{
  id: number
  label: string
  value: 'small' | 'medium' | 'large'
}> = [
  {
    id: 1,
    label: 'Small',
    value: 'small'
  },
  {
    id: 2,
    label: 'Medium',
    value: 'medium'
  },
  {
    id: 3,
    label: 'Large',
    value: 'large'
  }
]
