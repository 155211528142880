import {useCallback, useEffect, useState} from 'react'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {FaInfoCircle} from 'react-icons/fa'
import {HiArrowLeft, HiDotsHorizontal} from 'react-icons/hi'
import {ImWarning} from 'react-icons/im'
import {MdWarning} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import {
  addUserToGroupAction,
  deleteUserGroupAction,
  getUserGroupByIdAction,
  // getUserToAddedInGroup,
  removeUserFromGroupAction,
  updateUserGroupBasicDetails
} from 'redux-src'
import {capitalizeFirstLetterWithSpace} from 'utils'
import {Button, ColorPicker, Dropdown, Input, Loading} from 'app/common'
import {GroupSelectedAppUserTable} from '../../components'
import {DynamicSearchSelect} from 'app/components'
import moment from 'moment'

export const EditGroupPage = () => {
  const {
    navigation: {navigate, routes},
    params
  } = useNavigation()

  const {id}: any = params
  //! REDUX HANDLING
  const {
    userDetails: {clientId}
  } = useAuth()

  const dispatch = useDispatch()
  const {getAppUserList_loading, companyGroupDetails}: RT.UserReduxType =
    useSelector((state: any) => state.user)

  const {
    add_loading
    // groups
  } = useSelector((state: any) => state.group)

  const [isTable, setTable] = useState(false)
  const [selectedAppUsers, setSelectedAppUsers] = useState([])

  const [formData, {onChange, setValue, errors, modified}] = useFormInput(
    {
      id,
      clientId,
      name: '',
      colorCode: '',
      description: '',
      companyUsers: ''
    },
    (data) => {},
    (data) => {
      const errors: any = {}
      if (data?.name?.length === 0) {
        errors.name = 'Please enter the group name!'
      }
      if (data?.description?.length === 0) {
        errors.description = 'Please enter a description for the group!'
      }
      if (data?.companyUsers?.length === 0) {
        errors.appUsers = 'Please select at least one app user!'
      }
      return errors
    }
  )

  // let appUserBufferList

  const onSaveChanges = () => {
    const body = {
      name: formData?.name,
      description: formData?.description
    }

    // data?.colorCode?.length === 0
    //   ? toast.error('Please choose a color for the group!')
    dispatch(
      updateUserGroupBasicDetails(id, body, () => {
        dispatch(getUserGroupByIdAction(id))
      })
    )
  }

  const remappedGroupUserList = useCallback(() => {
    const finalUserList = companyGroupDetails?.group_users?.map((user) => {
      return {
        id: user?.id,
        name: user?.display_name,
        lastname: user?.lastname
      }
    })
    setSelectedAppUsers(finalUserList)
  }, [companyGroupDetails?.group_users])

  const handelGroupColorChange = (colorValue: string) => {
    setValue('colorCode', colorValue)
  }

  const onDeleteGroup = () => {
    dispatch(
      deleteUserGroupAction(id, () => {
        navigate(`/settings/groups`)
      })
    )
  }

  const onSelectedUserDelete = (userId: number) => {
    dispatch(
      removeUserFromGroupAction(id, userId, () => {
        dispatch(getUserGroupByIdAction(id))
      })
    )
  }

  useEffect(() => {
    remappedGroupUserList()
    if (remappedGroupUserList?.length > 0) {
      setTable(true)
    }
  }, [remappedGroupUserList])

  const prePopulatedGroupDetails = useCallback(() => {
    // setValue('colorCode', )
    setValue('name', companyGroupDetails?.group_details?.name ?? '')
    setValue(
      'description',
      companyGroupDetails?.group_details?.description ?? ''
    )
    setValue('colorCode', companyGroupDetails?.group_details?.color ?? '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyGroupDetails])

  useEffect(() => {
    prePopulatedGroupDetails()
  }, [prePopulatedGroupDetails])

  useEffect(() => {
    dispatch(getUserGroupByIdAction(id))
  }, [dispatch, id])
  return (
    <div className="h-[90vh] mb-0 flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <h2
          className="text-sm flex items-center w-[150px] mb-10 cursor-pointer"
          style={{color: 'blue'}}
          onClick={() => navigate(routes['Group Main'].path)}
        >
          <span className="mr-10 mt-[3px]">
            <HiArrowLeft />
          </span>
          Back to Groups
        </h2>
        <div className="md-10">
          <Dropdown
            placement="bottomright"
            triggerElement={
              <button className="p-8 py-4 bg-gray-200 shadow-sm rounded-sm border-1 border-gray-200 hover:bg-gray-300">
                <HiDotsHorizontal size={20} />
              </button>
            }
            triggerToggle
          >
            <div style={{minWidth: '12rem', zIndex: 1}}>
              <div className=" w-full mt-10">
                {/* <DropDownFiles /> */}

                <div
                  className="flex-1 w-full   overflow-visible "
                  style={{width: 200}}
                >
                  <ul
                    className=" border-gray-300 rounded-sm shadow-xl overflow-auto cursor-pointer"
                    onClick={() => onDeleteGroup()}
                  >
                    <li className="p-10   bg-white text-black hover:text-white hover:bg-blue-200  text-md">
                      <span className="text-left inline-flex cursor-pointer">
                        Delete Group
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
      {getAppUserList_loading || add_loading ? (
        <Loading />
      ) : (
        <div className="flex-1 w-full">
          {/* <div className="ml-10 px-8 py-6" >
                        <SketchPicker className="" />
                    </div> */}
          <div className="pb-10 border-b-1 border-gray-200 mb-20">
            <div className="flex items-center  p-20">
              <div className="relative">
                <div className="p-[48px] mr-10 inline-flex  relative">
                  <span
                    className={`p-[48px] text-white rounded-full absolute text-[30px] font-bold inset-0 flex items-center justify-center `}
                    style={{
                      backgroundColor: `${formData?.colorCode}`
                    }}
                  >
                    {formData?.name?.at(0)?.toUpperCase()}
                  </span>
                </div>
                <div className="absolute right-8 bottom-2">
                  <ColorPicker
                    invert
                    onChange={handelGroupColorChange}
                    value={formData.colorCode}
                  />
                </div>
              </div>
              <div className="ml-20 px-16 py-10">
                <h2 className="text-xl font-bold">
                  {companyGroupDetails?.group_details?.name}
                </h2>

                <span className="text-sm text-gray-400 px-4">
                  Date created:{' '}
                  {moment(
                    String(companyGroupDetails?.group_details?.created_at)
                  ).format('MM/DD/YYYY')}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap ">
            <div className="w-full lg:w-4/12">
              <div className="mx-16">
                <Input
                  name="name"
                  size="sm"
                  label="Group Name (80 char.max)"
                  value={formData.name}
                  onChange={(e: any) =>
                    setValue(
                      'name',
                      capitalizeFirstLetterWithSpace(e.target.value)
                    )
                  }
                  error={modified.name && errors.name}
                />
              </div>
            </div>
            <div className="w-full lg:w-8/12 ">
              <div className="mx-16">
                <Input
                  name="description"
                  label="Description (80 char.max)"
                  size="sm"
                  value={formData.description}
                  onChange={onChange}
                  error={modified.description && errors.name}
                />
              </div>
            </div>
          </div>
          <div className="w-auto  mx-16">
            <div className="flex flex-wrap">
              <DynamicSearchSelect
                actionUrl={`/users/group/${id}/company-users`}
                remapOptions={(data: any) => {
                  return data?.map((item: any) => {
                    return {
                      id: item?.id,
                      label: `${item?.display_name} ${item?.lastname}`,
                      value: item?.display_name
                    }
                  })
                }}
                onChangeValue={(value) => {
                  setValue('companyUsers', value?.id)
                  dispatch(
                    addUserToGroupAction(id, value?.id, () => {
                      dispatch(getUserGroupByIdAction(id))
                    })
                  )
                }}
                cacheOptions={true}
                placeholder={'Add users'}
              />
            </div>
            <div>
              {!!isTable ? (
                modified.companyUsers && errors.companyUsers ? (
                  <div className="p-6 rounded-sm bg-red-100 text-sm flex">
                    <div className="mx-6">
                      <MdWarning size={14} className="text-orange-300" />
                    </div>
                    <div className="text-[10px]">
                      This group has no members yet. Add some users to create a
                      new group.
                    </div>
                  </div>
                ) : (
                  <div className="p-6 rounded-sm bg-blue-100 text-sm flex">
                    <div className="mx-6">
                      <FaInfoCircle size={14} className="text-blue-400" />
                    </div>
                    <div className="text-[10px]">
                      This group has no members yet. Add some users to create a
                      new group.
                    </div>
                  </div>
                )
              ) : (
                <div className="mt-10">
                  <GroupSelectedAppUserTable
                    selectedAppUsers={selectedAppUsers}
                    onDelete={onSelectedUserDelete}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between border-t-1 border-gray-200 py-16 mt-16">
        <div className="flex m-4">
          <ImWarning className="text-[orange] m-[2px]" />
          <div className="text-gray-500 ">
            Don't forget to save the changes!
          </div>
        </div>
        <div className="">
          <Button
            className="justify-end"
            size="sm"
            title="Save Changes"
            onClick={onSaveChanges}
          />
        </div>
      </div>
    </div>
  )
}
