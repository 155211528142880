import {Button, Input, Modal} from 'app/common'
import React from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {createManualInvoiceLineItem} from 'redux-src'
import {useFormInput} from 'use-form-input'

interface AddCustomItemProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  fetchInvoiceItem?: () => void
  sectionDetail: Api.InvoiceItemDetails
}

export const AddCustomItem = ({
  openModal,
  setOpenModal,
  fetchInvoiceItem,
  sectionDetail
}: AddCustomItemProps) => {
  const {addManualLineItemLoading}: RT.ProjectInvoicingReduxType = useSelector(
    (state: any) => state.invoices
  )

  const {params} = useNavigation()
  const dispatch = useDispatch()

  const {projectId, invoiceId} = params as any

  const [data, {onChange: onChangeHandler, errors, modified, onSubmit}] =
    useFormInput(
      {
        title: '',
        quantity: undefined,
        taxPercent: undefined,
        discountPercent: undefined,
        price: undefined
      },
      (data) => {
        const body = {
          title: data?.title,
          quantity: data?.quantity,
          taxPercent: data?.taxPercent,
          discountPercent: data?.discountPercent,
          price: data?.price
        }

        !!sectionDetail?.invoice_item_details?.id &&
          dispatch(
            createManualInvoiceLineItem(
              projectId,
              invoiceId,
              sectionDetail?.invoice_item_details?.id,
              body,
              () => {
                fetchInvoiceItem()
                setOpenModal(false)
              }
            )
          )
      },
      (data) => {
        const errors: any = {}

        if (data?.title?.length === 0) {
          errors.title = 'Title is required !!'
        }

        if (data?.quantity === undefined) {
          errors.quantity = 'Quantity is required !!'
        }

        if (data?.price === undefined) {
          errors.price = 'Price is required !!'
        }

        if (data?.taxPercent === undefined) {
          errors.taxPercent = 'Tax percent is required !!'
        }
        if (data?.discountPercent === undefined) {
          errors.discountPercent = 'Discount percent is required !!'
        }

        return errors
      }
    )

  return (
    <Modal
      toggleModal={setOpenModal}
      isActive={openModal}
      size="sm"
      title={'Invoice Basic Details'}
    >
      <div className="p-20 flex flex-col gap-6 relative">
        <div className="flex gap-20">
          <Input
            name="title"
            label="Title"
            value={data?.title}
            error={modified?.title && errors?.title}
            onChange={onChangeHandler}
            placeholder="Title"
            required
          />
        </div>
        <div className="flex gap-20">
          <Input
            name="quantity"
            label="Quentity"
            value={data?.quantity}
            error={modified?.quantity && errors?.quantity}
            onChange={onChangeHandler}
            placeholder="Quantity"
            required
          />
          <Input
            name="taxPercent"
            label="Tax Percent"
            value={data?.taxPercent}
            error={modified?.taxPercent && errors?.taxPercent}
            onChange={onChangeHandler}
            placeholder="Tax"
            required
          />
        </div>
        <div className="flex gap-20">
          <Input
            name="price"
            label="price"
            value={data?.price}
            error={modified?.price && errors?.price}
            onChange={onChangeHandler}
            placeholder="price"
            required
          />
          <Input
            name="discountPercent"
            label="Discount Percent"
            value={data?.discountPercent}
            error={modified?.discountPercent && errors?.discountPercent}
            onChange={onChangeHandler}
            placeholder="Discount"
            required
          />
        </div>

        <div className="border-t-1 border-gray-200 "></div>

        <div className="flex justify-end px-16 py-10">
          <Button
            title="cancel"
            buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
            size="sm"
            type="button"
            onClick={() => {
              setOpenModal(false)
            }}
            loading={addManualLineItemLoading}
          />
          <div className="ml-16">
            <Button title="Add" size="sm" type="submit" onClick={onSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  )
}
