import {
  GET_SUPPLIER_SETTING,
  CREATE_SUPPLIER_SETTING,
  UPDATE_SUPPLIER_SETTING
} from './actionTypes.action'

import {api, APIS} from '../../config'

import {errorHandler} from '../../utils'
import {getParsedUrl} from 'helpers'

export function addSupplierAction(
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_SUPPLIER_SETTING.LOADING})

      res = await api<any>(`${APIS.add_supplier}`, 'POST', body)

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: CREATE_SUPPLIER_SETTING.SUCCESS})

        successCallback?.()
        toast.success('Suppiler successfully added!')
        return 1
      } else {
        dispatch({type: CREATE_SUPPLIER_SETTING.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_SUPPLIER_SETTING.ERROR})

      return 0
    }
  }
}

export function getSupplierAction(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10
  },
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SUPPLIER_SETTING.LOADING})

      res = await api<Api.Base<Api.SupplierList>>(
        getParsedUrl(APIS.add_supplier, params)
      )

      const {
        success,
        data: {
          data: {total, rows},
          message
        }
      } = res.data

      if (success) {
        dispatch({
          type: GET_SUPPLIER_SETTING.SUCCESS,
          payload: {total, rows}
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_SUPPLIER_SETTING.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_SUPPLIER_SETTING.ERROR})

      return 0
    }
  }
}

export function updateSupplierAction(
  supplierId: number | string,
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_SUPPLIER_SETTING.LOADING})

      res = await api<any>(`${APIS.add_supplier}/${supplierId}`, 'PATCH', body)

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_SUPPLIER_SETTING.SUCCESS})

        successCallback?.()
        toast.success('Suppiler successfully updated!')
        return 1
      } else {
        dispatch({type: UPDATE_SUPPLIER_SETTING.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_SUPPLIER_SETTING.ERROR})

      return 0
    }
  }
}

export function deleteSupplierAction(
  supplierId: number | string,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_SUPPLIER_SETTING.LOADING})

      res = await api<any>(`${APIS.add_supplier}/${supplierId}`, 'DELETE')

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_SUPPLIER_SETTING.SUCCESS})

        successCallback?.()
        toast.success('Suppiler successfully deleted!')
        return 1
      } else {
        dispatch({type: UPDATE_SUPPLIER_SETTING.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_SUPPLIER_SETTING.ERROR})

      return 0
    }
  }
}
