import {errorHandler} from '../../utils'
import {
  UPDATE_INVOICE,
  DELETE_INVOICE,
  GET_INVOICE_BY_ID,
  ADD_NEW_LINE_ITEM_INVOICE,
  ADD_NEW_INVOICE,
  ADD_NEW_INVOICE_ITEM,
  DELETE_LINE_ITEM_INVOICE,
  DELETE_INVOICE_ITEM,
  GET_INVOICES,
  UPDATE_INVOICE_ITEM,
  // GET_INVOICE_ITEMS,
  ADD_COST_TO_INVOICE,
  MARK_AS_SENT_INVOICE,
  UPDATE_LINE_ITEM_INVOICE,
  VOID_INVOICE,
  APPROVE_AUTOMATIC_INVOICE,
  CREATE_MANUAL_INVOICE,
  APPROVE_MANUAL_INVOICE,
  CHANGE_AUTOMATIC_INVOICE_STATUS_TO_PAID,
  CHANGE_MANUAL_INVOICE_STATUS_TO_PAID,
  CREATE_INVOICE_MANUAL_LINE_ITEM,
  UPDATE_INVOICE_MANUAL_LINE_ITEM,
  CREATE_INVOICE_WITH_SITE_VISITS,
  MAKE_WORKER_INVOICE_STATUS_TO_PAID,
  CHANGE_INVOICE_STATUS_TO_DISPUTE,
  CHANGE_INVOICE_STATUS_TO_UNDISPUTE,
  CHANGE_INVOICE_STATUS_TO_VOID,
  CHANGE_INVOICE_STATUS_TO_UNPAID,
  GENERATE_SELECTED_TICKETS_INVOICE,
  GET_SITE_VISIT_LIST_WITH_TICKETS,
  GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE,
  GENERATE_WORKER_INVOICE,
  APPROVE_WORKER_INVOICE,
  GET_INVOICABLE_TICKETS_OF_SITE_VISIT,
  UPDATE_WORKER_INVOICE_BASIC_DETAILS,
  GET_WORKER_INVOICE_LIST_OF_COMPANY,
  GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE,
  GET_INVOICABLE_PROJECT_LABOUR_ENTRIES,
  ADD_INVOICE_LINE_ITEM_WITH_TICKETS,
  ADD_INVOICE_ITEM_WITH_TICKETS,
  CREATE_WORKER_INVOICE_ITEM,
  UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM,
  DELETE_WORKER_INVOICE_ITEM,
  CREATE_WORKER_INVOICE_LINE_ITEM,
  UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_LINE_ITEM,
  ADD_REMARKS_INVOICE,
  ADD_REMARK_TO_WORKER_INVOICE,
  GET_WORKER_PROJECT_LABOUR_ENTRIES,
  GET_WORKER_INVOICE_BY_ID,
  GET_UNASSIGNED_TICKETS_OF_PROJECTS,
  CREATE_INVOICE_ITEMS_WITH_TICKETS,
  ADD_INVOICE_MANUAL_LABOUR_ENTRY,
  GET_LABOUR_ENTRY_BY_TICKET_ID,
  CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM,
  GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE,
} from '.'
import {api, APIS} from '../../config'
import {getParsedUrl} from 'helpers'
import {toast} from 'app/common'

export function generateSelectedTicketInvoices(
  projectId: number,
  body: any,
  successCallback?: (data: any) => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GENERATE_SELECTED_TICKETS_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        getParsedUrl(`${APIS.projects}/${projectId}/invoices/selected-tickets`),
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GENERATE_SELECTED_TICKETS_INVOICE.SUCCESS,
          payload: data,
        })

        successCallback?.(data)
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: GENERATE_SELECTED_TICKETS_INVOICE.ERROR,
          payload: message,
        })

        errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({type: GENERATE_SELECTED_TICKETS_INVOICE.ERROR})
      return 1
    }
  }
}

export function approveAutomaticInvoiceAction(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: APPROVE_AUTOMATIC_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/approve`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: APPROVE_AUTOMATIC_INVOICE.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: APPROVE_AUTOMATIC_INVOICE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: APPROVE_AUTOMATIC_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function getInvoicesAction(
  projectId: number,
  page: number = 1,
  tableLimit: number = 10,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_INVOICES.LOADING})
      res = await api<any>(
        getParsedUrl(`${APIS.projects}/${projectId}/invoices`, {
          page: page,
          limit: tableLimit,
        }),
      )
      const {
        success,
        data: {data: invoices, message},
      } = res.data

      if (success) {
        dispatch({type: GET_INVOICES.SUCCESS, payload: invoices})

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_INVOICES.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_INVOICES.ERROR})
      return 1
    }
  }
}

export function enterActiveInvoice(activeInvoice: any) {
  return {type: 'LOCAL_ACTIVE_INVOICE', payload: activeInvoice}
}

export function getInvoiceById(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_INVOICE_BY_ID.LOADING})
      res = await api<Api.Base<Api.InvoiceDetailsById>>(
        getParsedUrl(`${APIS.projects}/${projectId}/invoices/${invoiceId}`),
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: GET_INVOICE_BY_ID.SUCCESS, payload: data})

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_INVOICE_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_INVOICE_BY_ID.ERROR})
      return 1
    }
  }
}

export function createManualInvoice(
  projectId: number,
  body: any,
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_MANUAL_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/manual`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        successCallback?.(data)

        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_MANUAL_INVOICE.ERROR, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_MANUAL_INVOICE.ERROR})
      toast.error('Error Creating new invoice !')
      return 1
    }
  }
}

export function approveManualInvoice(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: APPROVE_MANUAL_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/manual/approve`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: APPROVE_MANUAL_INVOICE.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: APPROVE_MANUAL_INVOICE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: APPROVE_MANUAL_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function changeInvoiceStatusToPaid(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CHANGE_AUTOMATIC_INVOICE_STATUS_TO_PAID.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/paid`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({
          type: CHANGE_AUTOMATIC_INVOICE_STATUS_TO_PAID.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CHANGE_AUTOMATIC_INVOICE_STATUS_TO_PAID.ERROR,
          payload: message,
        })
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CHANGE_AUTOMATIC_INVOICE_STATUS_TO_PAID.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function changeInvoiceStatusToUnPaid(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CHANGE_INVOICE_STATUS_TO_UNPAID.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/unpaid`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({
          type: CHANGE_INVOICE_STATUS_TO_UNPAID.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CHANGE_INVOICE_STATUS_TO_UNPAID.ERROR,
          payload: message,
        })
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CHANGE_INVOICE_STATUS_TO_UNPAID.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function changeInvoiceStatusToDispute(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CHANGE_INVOICE_STATUS_TO_DISPUTE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/dispute`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({
          type: CHANGE_INVOICE_STATUS_TO_DISPUTE.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CHANGE_INVOICE_STATUS_TO_DISPUTE.ERROR,
          payload: message,
        })
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CHANGE_INVOICE_STATUS_TO_DISPUTE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function changeInvoiceStatusToUnDispute(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CHANGE_INVOICE_STATUS_TO_UNDISPUTE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/undispute`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({
          type: CHANGE_INVOICE_STATUS_TO_UNDISPUTE.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CHANGE_INVOICE_STATUS_TO_UNDISPUTE.ERROR,
          payload: message,
        })
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CHANGE_INVOICE_STATUS_TO_UNDISPUTE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function changeInvoiceStatusToVoid(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CHANGE_INVOICE_STATUS_TO_VOID.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/void`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({
          type: CHANGE_INVOICE_STATUS_TO_VOID.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CHANGE_INVOICE_STATUS_TO_VOID.ERROR,
          payload: message,
        })
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CHANGE_INVOICE_STATUS_TO_VOID.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function changeManualInvoiceStatusToPaid(
  projectId: number,
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CHANGE_MANUAL_INVOICE_STATUS_TO_PAID.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/manual/paid`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({
          type: CHANGE_MANUAL_INVOICE_STATUS_TO_PAID.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CHANGE_MANUAL_INVOICE_STATUS_TO_PAID.ERROR,
          payload: message,
        })
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CHANGE_MANUAL_INVOICE_STATUS_TO_PAID.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function createNewInvoiceAction(
  projectId: number,
  body: any,
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_NEW_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ADD_NEW_INVOICE.SUCCESS, payload: data})
        dispatch({type: 'LOCAL_ACTIVE_INVOICE', payload: data})
        successCallback?.(data)
        toast.success(message)
        return 1
      } else {
        dispatch({type: ADD_NEW_INVOICE.ERROR, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: ADD_NEW_INVOICE.ERROR})
      toast.error('Error Creating new invoice !')
      return 1
    }
  }
}

export function updateNewInvoiceAction(
  projectId: number,
  invoiceId: number,
  body: {
    title?: string
    description?: string
    footerNotes?: string
    dueDate?: string
  },
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_INVOICE.SUCCESS, payload: data})

        successCallback && successCallback?.(data)
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_INVOICE.ERROR, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_INVOICE.ERROR})
      toast.error('Error while updating invoice !')
      return 1
    }
  }
}

//MARK: PROJECT INVOICE ITEM

export function createNewInvoiceItemAction(
  projectId: number,
  invoiceId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_NEW_INVOICE_ITEM.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ADD_NEW_INVOICE_ITEM.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ADD_NEW_INVOICE_ITEM.ERROR, payload: message})

        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_NEW_INVOICE_ITEM.ERROR})
      toast.error('Error Creating new invoice !')
      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateInvoiceItemAction(
  projectId: number,
  invoiceId: number,
  invoiceItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_INVOICE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/${invoiceItemId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_INVOICE_ITEM.SUCCESS, payload: data})
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({type: UPDATE_INVOICE_ITEM.ERROR, payload: message})

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_INVOICE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function deleteInvoiceItemAction(
  projectId: number,
  invoiceId: number,
  invoiceItemId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_INVOICE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/${invoiceItemId}`,
        'DELETE',
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: DELETE_INVOICE_ITEM.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_INVOICE_ITEM.ERROR, payload: message})

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: DELETE_INVOICE_ITEM.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

//MARK: PROJECT INVOICE LINE ITEMS
//MARK: ACTION WITH ITEM

export function createNewInvoiceLineItemAction(
  projectId: number,
  invoiceId: number,
  invoiceItemId: number,
  itemId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_NEW_LINE_ITEM_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/${invoiceItemId}/line-items/item/${itemId}`,
        'POST',
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ADD_NEW_LINE_ITEM_INVOICE.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({type: ADD_NEW_LINE_ITEM_INVOICE.ERROR, payload: message})

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_NEW_LINE_ITEM_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateInvoiceLineItemAction(
  projectId: number,
  invoiceId: number,
  invoiceItemId: number,
  lineItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_LINE_ITEM_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/${invoiceItemId}/line-items/${lineItemId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: UPDATE_LINE_ITEM_INVOICE.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_LINE_ITEM_INVOICE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_LINE_ITEM_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

//MARK: PROJECT INVOICE MANUAL LINE ITEM

export function createManualInvoiceLineItem(
  projectId: number,
  invoiceId: number,
  invoiceItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_INVOICE_MANUAL_LINE_ITEM.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/${invoiceItemId}/line-items/manual`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_INVOICE_MANUAL_LINE_ITEM.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: CREATE_INVOICE_MANUAL_LINE_ITEM.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_INVOICE_MANUAL_LINE_ITEM.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateInvoiceManualLineItem(
  projectId: number,
  invoiceId: number,
  invoiceItemId: number,
  lineItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_INVOICE_MANUAL_LINE_ITEM.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/${invoiceItemId}/line-items/manual/${lineItemId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: UPDATE_INVOICE_MANUAL_LINE_ITEM.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: UPDATE_INVOICE_MANUAL_LINE_ITEM.ERROR,
          payload: message,
        })
        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_INVOICE_MANUAL_LINE_ITEM.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function deleteInvoiceLineItemAction(
  projectId: number,
  invoiceId: number,
  invoiceItemId: number,
  lineItemId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_LINE_ITEM_INVOICE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/${invoiceItemId}/line-items/${lineItemId}`,
        'DELETE',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: DELETE_LINE_ITEM_INVOICE.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({type: DELETE_LINE_ITEM_INVOICE.ERROR, payload: message})

        toast.error(errorMessage)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: DELETE_LINE_ITEM_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function addInvoiceManualLabourEntry(
  invoiceId: number,
  invoiceItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_INVOICE_MANUAL_LABOUR_ENTRY.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.worker_invoice}/${invoiceId}/items/${invoiceItemId}/line-items/manual`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ADD_INVOICE_MANUAL_LABOUR_ENTRY.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: ADD_INVOICE_MANUAL_LABOUR_ENTRY.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_INVOICE_MANUAL_LABOUR_ENTRY.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

//MARK: PROJECT SUB-CONTRACTOR INVOICE

export function createInvoiceWithSiteVisits(
  projectId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_INVOICE_WITH_SITE_VISITS.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/subcontractor-invoice`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_INVOICE_WITH_SITE_VISITS.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: CREATE_INVOICE_WITH_SITE_VISITS.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_INVOICE_WITH_SITE_VISITS.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function getWorkerProjectLabourEntries(
  userId: number,
  params?: {fromDate?: string; toDate?: string},
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_WORKER_PROJECT_LABOUR_ENTRIES.LOADING})
      res = await api<Api.Base<Api.WorkerProjectLabourEntryList>>(
        getParsedUrl(`/worker-invoice/users/${userId}`, params),
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_WORKER_PROJECT_LABOUR_ENTRIES.SUCCESS,
          payload: data,
        })
        successCallback && successCallback?.()

        return 1
      } else {
        dispatch({
          type: GET_WORKER_PROJECT_LABOUR_ENTRIES.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: GET_WORKER_PROJECT_LABOUR_ENTRIES.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function getLabourEntryByTicketId(
  ticketId: number,
  userId: number,
  successCallback?: (
    data: Array<Api.ProjectLabourEntryIndividual>,
    id: number,
  ) => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ENTRY_BY_TICKET_ID.LOADING})
      res = await api<Api.Base<Api.LabourEntryByTicketId>>(
        getParsedUrl(`/worker-invoice/tickets/${ticketId}/users/${userId}`),
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ENTRY_BY_TICKET_ID.SUCCESS,
          payload: data,
        })
        successCallback && successCallback?.(data, ticketId)

        return 1
      } else {
        dispatch({
          type: GET_LABOUR_ENTRY_BY_TICKET_ID.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ENTRY_BY_TICKET_ID.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function generateWorkerInvoice(
  userId: number,
  body: {
    title: string
    dueDate: string
    description: string
    footerNotes: string
    projectLabourEntryIdList: Array<number>
  },
  successCallback?: (data: any) => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GENERATE_WORKER_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `/worker-invoice/users/${userId}`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GENERATE_WORKER_INVOICE.SUCCESS,
          payload: data,
        })
        successCallback?.(data)
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: GENERATE_WORKER_INVOICE.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: GENERATE_WORKER_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateWorkerInvoiceBasicDetailsAction(
  invoiceId: number,
  body: {
    title?: string
    description?: string
    footerNotes?: string
    dueDate?: string
  },
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_WORKER_INVOICE_BASIC_DETAILS.LOADING})
      res = await api<Api.Base<any>>(
        `/worker-invoice/${invoiceId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: UPDATE_WORKER_INVOICE_BASIC_DETAILS.SUCCESS,
          payload: data,
        })

        successCallback && successCallback?.(data)
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: UPDATE_WORKER_INVOICE_BASIC_DETAILS.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_WORKER_INVOICE_BASIC_DETAILS.ERROR})
      toast.error('Error while updating worker invoice!')
      return 1
    }
  }
}

export function getCompanyWorkerInvoiceList(
  params?: {
    page: number
    limit: number
    userId: number
  },
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_WORKER_INVOICE_LIST_OF_COMPANY.LOADING})
      res = await api<Api.Base<Api.CompanyWorkerInvoiceList>>(
        getParsedUrl(`/worker-invoice`, params),
      )
      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_WORKER_INVOICE_LIST_OF_COMPANY.SUCCESS,
          payload: {total, rows},
        })

        successCallback && successCallback?.({total, rows})

        return 1
      } else {
        dispatch({
          type: GET_WORKER_INVOICE_LIST_OF_COMPANY.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: GET_WORKER_INVOICE_LIST_OF_COMPANY.ERROR})
      toast.error('Error while updating worker invoice!')
      return 1
    }
  }
}

export function getWorkerInvoiceById(
  invoiceId: number,
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_WORKER_INVOICE_BY_ID.LOADING})
      res = await api<Api.Base<Api.WorkerInvoiceDetailsById>>(
        `/worker-invoice/${invoiceId}`,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_WORKER_INVOICE_BY_ID.SUCCESS,
          payload: data,
        })

        successCallback && successCallback?.(data)
        return 1
      } else {
        dispatch({
          type: GET_WORKER_INVOICE_BY_ID.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: GET_WORKER_INVOICE_BY_ID.ERROR})
      return 1
    }
  }
}

export function getProjectListOfUserNotIncludedInInvoice(
  invoiceId: number,
  userId: number,
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE.LOADING})
      res = await api<Api.Base<Api.WorkerProjectList>>(
        `/worker-invoice/${invoiceId}/users/${userId}/project-list-not-included-in-worker-invoice`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE.SUCCESS,
          payload: data,
        })

        successCallback && successCallback?.(data)
        return 1
      } else {
        dispatch({
          type: GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE.ERROR})
      return 1
    }
  }
}

export function getInvoicableProjectLabourEntries(
  workerInvoiceId: number,
  workerInvoiceItemId: number,
  successCallback?: (data: any) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_INVOICABLE_PROJECT_LABOUR_ENTRIES.LOADING})
      res = await api<Api.Base<Api.InvoicableProjectLabourEntryList>>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/${workerInvoiceItemId}/invoicable-project-tickets`,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_INVOICABLE_PROJECT_LABOUR_ENTRIES.SUCCESS,
          payload: data,
        })

        successCallback && successCallback?.(data)

        return 1
      } else {
        dispatch({
          type: GET_INVOICABLE_PROJECT_LABOUR_ENTRIES.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)

        return 0
      }
    } catch (e) {
      dispatch({type: GET_INVOICABLE_PROJECT_LABOUR_ENTRIES.ERROR})
      return 1
    }
  }
}

export function approveWorkerInvoice(
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: APPROVE_WORKER_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `/worker-invoice/${invoiceId}/approve`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: APPROVE_WORKER_INVOICE.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: APPROVE_WORKER_INVOICE.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: APPROVE_WORKER_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function makeWorkerInvoiceStatusToPaid(
  invoiceId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MAKE_WORKER_INVOICE_STATUS_TO_PAID.LOADING})
      res = await api<Api.Base<any>>(
        `/worker-invoice/${invoiceId}/paid`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: MAKE_WORKER_INVOICE_STATUS_TO_PAID.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: MAKE_WORKER_INVOICE_STATUS_TO_PAID.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: MAKE_WORKER_INVOICE_STATUS_TO_PAID.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function addCostToInvoiceAction(
  body: any,
  invoiceId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_COST_TO_INVOICE.LOADING})
      res = await api<any>(
        `${APIS.invoices}/${invoiceId}/add-costs`,
        'POST',
        body,
      )
      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: ADD_COST_TO_INVOICE.SUCCESS})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: ADD_COST_TO_INVOICE.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_COST_TO_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function deleteInvoiceAction(
  invoiceId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_INVOICE.LOADING})
      res = await api<any>(`${APIS.invoices}/${invoiceId}`, 'DELETE')
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: DELETE_INVOICE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_INVOICE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (e) {
      dispatch({type: DELETE_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function markAsSentInvoiceAction(
  projectId: any,
  invoiceId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MARK_AS_SENT_INVOICE.LOADING})
      res = await api<any>(
        `${APIS.jobList}/${projectId}/INVOICEs/${invoiceId}/mark-as-sent`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: MARK_AS_SENT_INVOICE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: MARK_AS_SENT_INVOICE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (e) {
      dispatch({type: MARK_AS_SENT_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function voidInvoiceAction(
  projectId: any,
  invoiceId: any,
  toast: any,
  successCallback?: any,
  onErrorCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: VOID_INVOICE.LOADING})
      res = await api<any>(
        `${APIS.jobList}/${projectId}/invoices/${invoiceId}/void`,
        'PATCH',
      )
      const {
        success,
        data: {data, message},
        message: errorMessage,
      } = res.data

      if (success) {
        dispatch({type: VOID_INVOICE.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: VOID_INVOICE.ERROR, payload: message})
        toast.error(errorMessage)
        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (e) {
      dispatch({type: VOID_INVOICE.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

//MARK: ADDITIONAL APIS FOR INVOICING
//MARK: GET SITE VISIT LIST OF PROJECT ALONG WITH COMPLETED TICKETS

export function getSiteVisitListWithTickets(
  projectId: number,
  page: number = 1,
  tableLimit: number = 10,
  successCallback?: () => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_VISIT_LIST_WITH_TICKETS.LOADING})
      res = await api<Api.Base<any>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/invoices/additional/site-visits-with-tickets`,
          {
            page: page,
            limit: tableLimit,
          },
        ),
      )
      const {
        success,
        data: {data: siteVisits, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_SITE_VISIT_LIST_WITH_TICKETS.SUCCESS,
          payload: siteVisits,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_SITE_VISIT_LIST_WITH_TICKETS.ERROR,
          payload: message,
        })

        errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({type: GET_SITE_VISIT_LIST_WITH_TICKETS.ERROR})
      return 1
    }
  }
}

//MARK: GET SITE VISIT LIST NOT PRESENT IN INVOICE

export function getSiteVisitNotPresentInInvoice(
  projectId: number,
  invoiceId: number,
  page: number = 1,
  tableLimit: number = 10,
  successCallback?: () => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/invoices/additional/${invoiceId}/site-visits-not-present-in-invoice`,
          {
            page: page,
            limit: tableLimit,
          },
        ),
      )
      const {
        success,
        data: {data: siteVisits, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE.SUCCESS,
          payload: siteVisits,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE.ERROR,
          payload: message,
        })

        errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({type: GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE.ERROR})
      return 1
    }
  }
}

export function getInvoicableTicketsOfSiteVisit(
  projectId: number,
  siteVisitId: number,
  page: number = 1,
  tableLimit: number = 10,
  successCallback?: () => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_INVOICABLE_TICKETS_OF_SITE_VISIT.LOADING})
      res = await api<Api.Base<any>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/invoices/additional/site-visits/${siteVisitId}/invoicable-tickets`,
          {
            page: page,
            limit: tableLimit,
          },
        ),
      )
      const {
        success,
        data: {data: ticketList, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_INVOICABLE_TICKETS_OF_SITE_VISIT.SUCCESS,
          payload: ticketList,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_INVOICABLE_TICKETS_OF_SITE_VISIT.ERROR,
          payload: message,
        })

        errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({type: GET_INVOICABLE_TICKETS_OF_SITE_VISIT.ERROR})
      return 1
    }
  }
}

export function getUnassignedTicketsOfProject(
  projectId: number,
  successCallback?: () => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_UNASSIGNED_TICKETS_OF_PROJECTS.LOADING})
      res = await api<Api.Base<any>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/invoices/additional/unassigned-tickets`,
        ),
      )
      const {
        success,
        data: {data: ticketList, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_UNASSIGNED_TICKETS_OF_PROJECTS.SUCCESS,
          payload: ticketList,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_UNASSIGNED_TICKETS_OF_PROJECTS.ERROR,
          payload: message,
        })

        errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({type: GET_UNASSIGNED_TICKETS_OF_PROJECTS.ERROR})
      return 1
    }
  }
}

//MARK: add line item of invoice item with tickets

export function addInvoiceLineItemWithTickets(
  projectId: number,
  invoiceId: number,
  invoiceItemId: number,
  body: any,
  successCallback?: () => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_INVOICE_LINE_ITEM_WITH_TICKETS.LOADING})
      res = await api<Api.Base<any>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/${invoiceItemId}/line-items/with-tickets`,
        ),
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: ADD_INVOICE_LINE_ITEM_WITH_TICKETS.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: ADD_INVOICE_LINE_ITEM_WITH_TICKETS.ERROR,
          payload: message,
        })

        errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({type: ADD_INVOICE_LINE_ITEM_WITH_TICKETS.ERROR})
      return 1
    }
  }
}

//MARK: add invoice item of invoice item with tickets

export function addInvoiceItemWithTickets(
  projectId: number,
  invoiceId: number,
  body: {},
  successCallback?: () => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_INVOICE_ITEM_WITH_TICKETS.LOADING})
      res = await api<Api.Base<any>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/with-tickets`,
        ),
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: ADD_INVOICE_ITEM_WITH_TICKETS.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: ADD_INVOICE_ITEM_WITH_TICKETS.ERROR,
          payload: message,
        })

        errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({type: ADD_INVOICE_ITEM_WITH_TICKETS.ERROR})
      return 1
    }
  }
}

export function getProjectListThatIsNotIncludedInWorkerInvoice(
  projectId: number,
  userId: number,
  workerInvoiceId: number,
  successCallback?: () => void,
  errorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE.LOADING})
      res = await api<any>(
        getParsedUrl(
          `${APIS.worker_invoice}/${workerInvoiceId}/users/${userId}/project-list-not-included-in-worker-invoice`,
        ),
      )
      const {
        success,
        data: {data: ticketList, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE.SUCCESS,
          payload: ticketList,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE.ERROR,
          payload: message,
        })

        errorCallback?.()

        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE.ERROR})
      return 1
    }
  }
}

export function createWorkerInvoiceItem(
  workerInvoiceId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_WORKER_INVOICE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/manual`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_WORKER_INVOICE_ITEM.SUCCESS, payload: data})
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({type: CREATE_WORKER_INVOICE_ITEM.ERROR, payload: message})

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_WORKER_INVOICE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function createWorkerInvoiceItemWithLabourEntries(
  workerInvoiceId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_WORKER_INVOICE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/with-labour-entries`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_WORKER_INVOICE_ITEM.SUCCESS, payload: data})
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({type: CREATE_WORKER_INVOICE_ITEM.ERROR, payload: message})

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_WORKER_INVOICE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateBasicDetailsOfWorkerInvoice(
  workerInvoiceId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.worker_invoice}/${workerInvoiceId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.SUCCESS,
          payload: data,
        })
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateBasicDetailsWorkerInvoiceItem(
  workerInvoiceId: number,
  workerInvoiceItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/${workerInvoiceItemId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.SUCCESS,
          payload: data,
        })
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function deleteWorkerInvoiceItem(
  workerInvoiceId: number,
  workerInvoiceItemId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_WORKER_INVOICE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/${workerInvoiceItemId}`,
        'DELETE',
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: DELETE_WORKER_INVOICE_ITEM.SUCCESS,
          payload: data,
        })
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: DELETE_WORKER_INVOICE_ITEM.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: DELETE_WORKER_INVOICE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

// Worker invoice line item

export function createWorkerInvoiceLineItem(
  workerInvoiceId: number,
  workerInvoiceItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_WORKER_INVOICE_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/${workerInvoiceItemId}/line-items`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_WORKER_INVOICE_LINE_ITEM.SUCCESS, payload: data})
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: CREATE_WORKER_INVOICE_LINE_ITEM.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_WORKER_INVOICE_LINE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function createWorkerInvoiceManualLineItem(
  workerInvoiceId: number,
  workerInvoiceItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/${workerInvoiceItemId}/line-items/manual`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM.SUCCESS,
          payload: data,
        })
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function updateBasicDetailsOfWorkerInvoiceLineItem(
  workerInvoiceId: number,
  workerInvoiceItemId: number,
  workerInvoiceLineItemId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/${workerInvoiceItemId}/line-items/${workerInvoiceLineItemId}`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_LINE_ITEM.SUCCESS,
          payload: data,
        })
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_LINE_ITEM.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_LINE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function removeWorkerInvoiceLineItem(
  workerInvoiceId: number,
  workerInvoiceItemId: number,
  workerInvoiceLineItemId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_WORKER_INVOICE_LINE_ITEM.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.worker_invoice}/${workerInvoiceId}/items/${workerInvoiceItemId}/line-items/${workerInvoiceLineItemId}`,
        'DELETE',
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_WORKER_INVOICE_LINE_ITEM.SUCCESS, payload: data})
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: CREATE_WORKER_INVOICE_LINE_ITEM.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_WORKER_INVOICE_LINE_ITEM.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function addRemarksToInvoice(
  projectId: number,
  invoiceId: number,
  body: {
    remarks: string
  },
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_REMARK_TO_WORKER_INVOICE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/remark`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ADD_REMARK_TO_WORKER_INVOICE.SUCCESS, payload: data})
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: ADD_REMARK_TO_WORKER_INVOICE.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_REMARK_TO_WORKER_INVOICE.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function addRemarksToWorkerInvoice(
  workerInvoiceId: number,
  body: {
    remarks: string
  },
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_REMARK_TO_WORKER_INVOICE.LOADING})
      res = await api<any>(
        `${APIS.worker_invoice}/${workerInvoiceId}/remarks`,
        'PATCH',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ADD_REMARK_TO_WORKER_INVOICE.SUCCESS, payload: data})
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: ADD_REMARK_TO_WORKER_INVOICE.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_REMARK_TO_WORKER_INVOICE.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

//MARK: ADD INVOICE REMARK

export function addInvoiceRemarks(
  projectId: number,
  invoiceId: number,
  body: {
    remarks: string
  },
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_REMARKS_INVOICE.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/remarks`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ADD_REMARKS_INVOICE.SUCCESS, payload: data})
        successCallback()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: ADD_REMARKS_INVOICE.ERROR,
          payload: message,
        })

        onErrorCallback?.()
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_REMARKS_INVOICE.ERROR})

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

//Create Invoice Items with tickets

export function createInvoiceItemsWithTickets(
  projectId: number,
  invoiceId: number,
  body: {
    ticketIds: Array<number>
  },
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_INVOICE_ITEMS_WITH_TICKETS.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/invoices/${invoiceId}/items/with-tickets`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: CREATE_INVOICE_ITEMS_WITH_TICKETS.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: CREATE_INVOICE_ITEMS_WITH_TICKETS.ERROR,
          payload: message,
        })

        toast.error(message)
        onErrorCallback?.()
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_INVOICE_ITEMS_WITH_TICKETS.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}
