import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts'

export function HorizontalSimpleBarchart({
  data,
  height = 250,
  showLegend = true,
  showName = true,
  dataType = 'weeks',
}: {
  data: Array<{
    name: string
    completed?: number
    current?: number
    projected?: number
    total?: number
  }>
  dataType?: string
  height?: number
  showLegend?: boolean
  showName?: boolean
}) {
  // Custom tooltip to show on barchart

  const CustomTooltip = <T extends string | number, E extends string | number>({
    active,
    payload,
  }: TooltipProps<T, E>) => {
    const hoveredBar = payload?.at(0)

    if (active && payload && payload.length) {
      return (
        <div className="basic-project-details-tooltip">
          <div className="label">{hoveredBar?.payload?.name}</div>

          <div className="separator" />
          <div className="description">
            {data?.at(0)?.total !== undefined && (
              <div
                className="desc blue-bar"
                style={{color: `${hoveredBar?.payload?.fill} !important`}}
              >
                <div className="desc-title">Total {dataType}: </div>
                <div className="desc-value">{data?.at(0)?.total}</div>
              </div>
            )}
            {data?.at(0)?.completed !== undefined && (
              <div
                className="desc green-bar"
                style={{color: `${hoveredBar?.payload?.fill} !important`}}
              >
                <div className="desc-title">Completed {dataType}: </div>
                <div className="desc-value">{data?.at(0)?.completed}</div>
              </div>
            )}
            {data?.at(0)?.current !== undefined && (
              <div
                className="desc red-bar"
                style={{color: `${hoveredBar?.payload?.fill} !important`}}
              >
                <div className="desc-title">Current {dataType}: </div>
                <div className="desc-value">{data?.at(0)?.current}</div>
              </div>
            )}
            {data?.at(0)?.projected !== undefined && (
              <div
                className="desc text-[#5a0c7e]"
                style={{color: `${hoveredBar?.payload?.fill} !important`}}
              >
                <div className="desc-title">Projected {dataType}: </div>
                <div className="desc-value">{data?.at(0)?.projected}</div>
              </div>
            )}
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        layout="vertical"
        data={data}
        // barGap={'20%'}
        barCategoryGap={'20%'}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" axisLine={false} />

        <YAxis type="category" dataKey="name" hide={!showName} />
        <Tooltip content={<CustomTooltip />} />

        {data?.at(0)?.total !== undefined && (
          <Bar
            name={`Total ${dataType}`}
            dataKey="total"
            fill="#3452c0"
            barSize={30}
          />
        )}

        {data?.at(0)?.completed !== undefined && (
          <Bar
            name={`Completed ${dataType}`}
            dataKey="completed"
            fill="#76cc00"
            barSize={30}
          />
        )}

        {data?.at(0)?.current !== undefined && (
          <Bar
            name={`Current ${dataType}`}
            dataKey="current"
            fill="#e2420d"
            barSize={30}
          />
        )}

        {data?.at(0)?.projected !== undefined && (
          <Bar
            name={`Projected ${dataType}`}
            dataKey="projected"
            fill="#5a0c7e"
            barSize={30}
          />
        )}

        {showLegend && <Legend align="center" />}
      </BarChart>
    </ResponsiveContainer>
  )
}
