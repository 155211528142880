import {useEffect, useMemo} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'

import {DescriptionBox, MessageBox} from 'app/components'
import {
  getProjectFinancialSummary,
  getSiteVisitFinancialBreakdown,
  getUsersFinancialDetailsOfProject,
} from 'redux-src'

import {JobTitle} from '../../components'

import {
  OverallPosition,
  SiteVisitBreakdown,
  UsersFinancialBreakdown,
} from './components'
import {SelectField} from 'app/common'
import {width} from '@material-ui/system'
import {getStatusChip} from '../ticket/components/planList/components'

export const FinancialSummaryPage = () => {
  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any
  const {
    financialSummary,
    siteVisitFinancialBreakdown,
    usersFinancialDetails,
  }: RT.ProjectFinancialSummaryReduxType = useSelector(
    (state: any) => state.financeSummary,
  )

  const {
    projectDetail,
    projectVariations,
    isProjectVariationLoading,
  }: RT.ProjectReduxType = useSelector((state: any) => state.project)

  useEffect(() => {
    if (projectDetail?.project_details?.is_quote_accepted) {
      dispatch(
        getProjectFinancialSummary(
          projectId,
          !projectDetail?.project_parent_details ? true : undefined,
        ),
      )
      dispatch(getSiteVisitFinancialBreakdown(projectId))
      dispatch(getUsersFinancialDetailsOfProject(projectId))
    }
  }, [
    dispatch,
    projectDetail?.project_details?.is_quote_accepted,
    projectDetail?.project_parent_details,
    projectId,
  ])

  function handleVariationChange(newVariation: {
    id: string | number
    value: string | number
    label: string
  }) {
    if (+newVariation.id === -1) {
      dispatch(getProjectFinancialSummary(projectId, true))
      dispatch(
        getSiteVisitFinancialBreakdown(projectId, {
          limit: 10,
          page: 1,
          withVariations: true,
        }),
      )
      dispatch(
        getUsersFinancialDetailsOfProject(projectId, {
          limit: 10,
          page: 1,
          withVariations: true,
        }),
      )
    } else {
      dispatch(getProjectFinancialSummary(+newVariation.id))
      dispatch(getSiteVisitFinancialBreakdown(+newVariation.id))
      dispatch(getUsersFinancialDetailsOfProject(+newVariation.id))
    }
  }

  const variationSelectOptions = useMemo(() => {
    if (projectVariations?.isParent) {
      return [
        {
          value: '-1',
          label: 'Entire Project (All)',
          id: '-1',
          isDisabled: false,
        },

        ...projectVariations?.variations?.map((variation) => ({
          value: variation.id,
          label: `${variation.project_prefix}-${variation.project_number} | ${variation.title}`,
          id: variation.id,
          isDisabled: variation?.accepted_quote_id === null,
        })),
      ]
    } else {
      return []
    }
  }, [projectVariations])

  return (
    <div className="min-h-[70vh]">
      <div className="flex justify-between items-center">
        <JobTitle>Financial Summary</JobTitle>
      </div>

      {projectDetail?.project_details?.is_quote_accepted ? (
        <DescriptionBox
          title={`Financial Summary for ${
            projectDetail?.project_details?.project_prefix +
            '-' +
            projectDetail?.project_details?.project_number
          } | ${projectDetail?.project_details?.title}`}
          rightElement={
            <div>
              {projectVariations?.isParent && (
                <div className="w-[250px]">
                  <SelectField
                    isLoading={isProjectVariationLoading}
                    options={variationSelectOptions}
                    onChangeValue={(newValue, _) => {
                      // console.log('Variation Changed to', newValue.label)
                      handleVariationChange(newValue)
                    }}
                    defaultValue={{
                      value: 'Entire Project',
                      label: 'Entire Project',
                      id: 'entire',
                      isDisabled: false,
                    }}
                    formatOptionLabel={(option) => {
                      return (
                        <div
                          className={`flex flex-nowrap items-center justify-start gap-8  ${
                            option.isDisabled ? 'hover:cursor-not-allowed' : ''
                          }`}
                        >
                          {option.label}
                          {option.isDisabled && (
                            <div
                              className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-fit uppercase`}
                              style={{
                                backgroundColor: '#e2420d',
                              }}
                            >
                              not_quoted
                            </div>
                          )}
                        </div>
                      )
                    }}
                  />
                </div>
              )}

              {/* <button className="px-10 py-6 bg-white text-sm border-1 border-gray-200 rounded-sm shadow-sm mx-4 whitespace-nowrap">
                Download CSV
              </button> */}
            </div>
          }
          bodyClassName="px-16 bg-gray-100"
        >
          {!!financialSummary ? (
            <>
              <OverallPosition />
            </>
          ) : (
            <MessageBox
              error
              message={
                'There are no financial records available for the project.'
              }
            />
          )}
          {!!siteVisitFinancialBreakdown ? (
            <>
              <SiteVisitBreakdown data={siteVisitFinancialBreakdown || []} />
            </>
          ) : (
            <MessageBox
              error
              message={
                'There are no site visit financial records available for the project.'
              }
            />
          )}
          {!!usersFinancialDetails ? (
            <>
              <UsersFinancialBreakdown data={usersFinancialDetails} />
            </>
          ) : (
            <MessageBox
              error
              message={
                'There are no financial records available for users in the project.'
              }
            />
          )}
        </DescriptionBox>
      ) : (
        <>
          <MessageBox message="Project does not have accepted quote." error />
        </>
      )}
    </div>
  )
}
