import moment from 'moment'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Input, Textarea} from 'app/common'
import {useFormInput} from 'use-form-input'
import ReactDatePicker from 'react-datepicker'
import {useNavigation} from 'react-auth-navigation'
import {getInvoiceById, updateNewInvoiceAction} from 'redux-src'

export const LetterHead = ({
  previewMode,
  companyDetails,
  invoiceDetail,
  defaultInvoiceSettings,
}: {
  previewMode?: boolean
  companyDetails: any
  invoiceDetail: any
  defaultInvoiceSettings: any
}) => {
  const {projectDetail: projectDetailsFromApi}: RT.ProjectReduxType =
    useSelector((state: any) => state.project)

  const {invoiceDetail: invoiceDetailsFromApi}: RT.ProjectInvoicingReduxType =
    useSelector((state: any) => state.invoices)

  const dispatch = useDispatch()

  const {params} = useNavigation()

  const {projectId, invoiceId: invoiceIdParams} = params as any

  const [data, {setValue}] = useFormInput({dueDate: undefined})

  const [showLetterHead, setShowLetterHead] = useState(true)
  const [isEditActive, setIsEditActive] = useState<boolean>(false)

  const {invoice_billing_contact, invoice_details} =
    !!invoiceDetail && invoiceDetail

  useEffect(() => {
    setValue('dueDate', invoice_details?.due_date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_details])

  return (
    <>
      <button
        onClick={() => setShowLetterHead((prev) => !prev)}
        className="text-blue-200 hover:underline mt-4"
      >
        {showLetterHead ? 'Hide' : 'Show'} Letter Head
      </button>

      {showLetterHead && (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div></div>
            <div className="text-right">
              <div className="text-lg font-bold">
                {companyDetails?.company_details?.name.toUpperCase() ?? 'JFDS'}
              </div>
              <ul>
                {/* <li>
                  {companyDetails?.address_details?.city},{' '}
                  {companyDetails?.address_details?.state}
                </li> */}
                <li>{companyDetails?.address_details?.address}</li>
                {companyDetails?.contact_details?.emails?.map(
                  (item: string) => (
                    <li key={item}>{item}</li>
                  ),
                )}
                {companyDetails?.contact_details?.phones?.map(
                  (item: string) => (
                    <li key={item}>{item}</li>
                  ),
                )}
                {/* <li>info@jfds.co.uk</li>
                <li>0633310649</li> */}
              </ul>
            </div>
          </div>

          <div className="text-right text-orange-700 mb-4 mt-12">
            <h3 className="text-lg font-bold uppercase">
              {invoice_details?.status}
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {true ? (
              // {previewMode ? (
              <ul>
                <li>
                  {invoice_billing_contact?.firstname}{' '}
                  {invoice_billing_contact?.lastname}
                </li>
                <li>{invoice_billing_contact?.suburb}</li>
                <li>
                  {invoice_billing_contact?.city}{' '}
                  {invoice_billing_contact?.zipCode
                    ? `, ${invoice_billing_contact?.zipCode}`
                    : ''}
                </li>
              </ul>
            ) : (
              <div>
                <div className="my-12">
                  <Input
                    name="atn"
                    size="sm"
                    prepend={'ATNN:'}
                    placeholder="Optional"
                    onChange={() => false}
                  />
                </div>

                <Textarea name="invoiceAddress" />
              </div>
            )}
            <div>
              <div
                className="bg-blue-100 flex justify-between p-10"
                style={{minWidth: 400}}
              >
                <div>
                  <span className="font-bold">Site Address</span>
                  <ul>
                    <li>
                      {[
                        projectDetailsFromApi?.site_contact_details?.firstname,
                        projectDetailsFromApi?.site_contact_details?.lastname,
                      ]?.join(' ')}
                    </li>
                    <li>
                      {projectDetailsFromApi?.site_address_details?.place}
                    </li>
                    <li>
                      {projectDetailsFromApi?.site_address_details?.suburb}
                    </li>
                    <li>
                      {projectDetailsFromApi?.site_address_details?.city},{' '}
                      {projectDetailsFromApi?.site_address_details?.zip_code}
                    </li>
                  </ul>
                </div>

                <div className="justify-end">
                  <ul className="text-right flex flex-col gap-6">
                    <li>
                      Invoice Number:{' '}
                      <span
                        className={
                          invoice_details?.status !== 'draft'
                            ? 'text-gray-500'
                            : 'text-gray-300'
                        }
                      >
                        INV-
                        {invoice_details?.status !== 'draft'
                          ? invoice_details?.id
                          : 'DRAFT'}
                      </span>
                    </li>
                    <li>
                      Job Number:{' '}
                      {projectDetailsFromApi?.project_details?.project_prefix}-
                      {projectDetailsFromApi?.project_details?.project_number}
                    </li>
                    <li>
                      Company Registration Number:{' '}
                      {companyDetails?.company_details?.tax_number}
                    </li>
                    <li>Invoice Date: {moment().format('Do MMM YYYY')}</li>
                    <li>
                      {isEditActive ? (
                        <span className="flex w-full">
                          <ReactDatePicker
                            selected={
                              data?.dueDate
                                ? new Date(data?.dueDate)
                                : undefined
                            }
                            onChange={(date: any) => {
                              setValue('dueDate', moment(date).toISOString())
                            }}
                            className="w-[70%] py-6 pl-4 border-1 border-gray-200 rounded-sm"
                            placeholderText="Completed Date"
                          />
                          <span
                            className="px-8 py-6 text-white font-bold bg-blue-200 rounded-sm ml-4 cursor-pointer hover:bg-blue-300 active:bg-blue-200"
                            onClick={() => {
                              const body = {
                                dueDate: data?.dueDate,
                              }

                              dispatch(
                                updateNewInvoiceAction(
                                  projectId,
                                  invoiceIdParams,
                                  body,
                                  () => {
                                    dispatch(
                                      getInvoiceById(
                                        projectId,
                                        invoiceIdParams,
                                      ),
                                    )
                                    setIsEditActive(false)
                                  },
                                ),
                              )
                            }}
                          >
                            Save
                          </span>
                        </span>
                      ) : (
                        <span>
                          Valid Until:{' '}
                          {moment(invoice_details?.due_date).format(
                            'Do MMM YYYY',
                          )}
                        </span>
                      )}

                      {invoiceDetailsFromApi?.invoice_details?.status ===
                        'unpaid' &&
                        !isEditActive && (
                          <span
                            className="px-8 py-6 text-back font-bold bg-orange-200 rounded-sm ml-4 cursor-pointer hover:bg-orange-300 active:bg-orange-200"
                            onClick={() => {
                              setIsEditActive((prev) => !prev)
                            }}
                          >
                            Edit
                          </span>
                        )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
