import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {saveAs} from 'file-saver'

import {Button} from 'app/common'
import {LOCAL_BASE_URL} from 'config'

import {
  getBasicPojectProgressDetail,
  getProjectDateCalculator,
  getProjectStatus,
} from 'redux-src'

import {JobTitle} from '../../components'
import {BasicProjectDetails, DateCalculator, ProjectStatus} from './components/'
import {MessageBox} from 'app/components'

// projectname - report.pdf
export const ProgressPage = () => {
  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId} = params as any

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  // fetch data now
  useEffect(() => {
    if (projectDetail?.project_details?.is_quote_accepted) {
      dispatch(getProjectStatus(projectId))
      dispatch(getBasicPojectProgressDetail(projectId))
      dispatch(getProjectDateCalculator(projectId))
    }
  }, [dispatch, projectDetail?.project_details?.is_quote_accepted, projectId])

  return (
    <div className="min-h-[70vh]">
      <div className="flex justify-between items-center">
        <JobTitle>Project Progress</JobTitle>
        {projectDetail?.project_details?.is_quote_accepted && (
          <Button
            title="Download Report"
            onClick={() => {
              saveAs(
                `${LOCAL_BASE_URL}/projects/${projectId}/reports`,
                `${projectDetail?.project_details.title}-report.pdf`,
              )
            }}
            className="inline-flex items-center justify-center"
          />
        )}
      </div>

      {projectDetail?.project_details?.is_quote_accepted ? (
        <div className="flex flex-col gap-40 pb-40">
          <BasicProjectDetails />
          <DateCalculator />
          <ProjectStatus />
        </div>
      ) : (
        <>
          <MessageBox message="Project does not have accepted quote." error />
        </>
      )}
    </div>
  )
}
