import {errorHandler} from '../../utils'
import {api, APIS} from '../../config'
import {toast} from 'app/common'
import {
  ADD_NOTES_ENTRY,
  CREATE_CUSTOMER_NOTES,
  UPDATE_CUSTOMER_NOTES,
  DELETE_CUSTOMER_NOTES,
  GET_CUSTOMER_NOTES,
  GET_JOB_NOTES,
  GET_NOTES,
  GET_NOTES_HOSTORY_LIST,
  GET_SITE_VISIT_NOTES,
  DELETE_SITE_NOTES,
  UPDATE_SITE_NOTES,
  CREATE_SITE_NOTES,
  GET_SITE_NOTES,
  CREATE_PROJECT_NOTES,
  UPDATE_PROJECT_NOTES,
  GET_PROJECT_NOTES,
  DELETE_PROJECT_NOTES,
  GET_PROJECT_HISTORY,
  GET_PROJECT_NOTE_BY_ID,
} from './actionTypes.action'
import {getParsedUrl} from 'helpers'

export const getNoteHistoryListAction = (
  id: number,
  type: string,
  callback?: any,
  toast?: any,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_NOTES_HOSTORY_LIST.LOADING})

      res = await api<any>(`${APIS.notesHistory}${id}/history?type=${type}`)

      // console.log('getNoteHistoryListAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows, message},
        } = res.data

        if (success) {
          dispatch({type: GET_NOTES_HOSTORY_LIST.SUCCESS, payload: rows})
          callback && callback()
          toast.success(message)
        } else {
          dispatch({type: GET_NOTES_HOSTORY_LIST.ERROR})
          errorHandler(res.data, toast)
        }
      }
    } catch (message) {
      dispatch({type: GET_NOTES_HOSTORY_LIST.ERROR})
    }
  }
}

interface AddNotesBodyProps {
  text: string
  projectId: number
  siteVisitId?: number
  parentId?: number
}
export const addNoteEntryAction = (
  body: AddNotesBodyProps,
  toast?: any,
  callback?: any,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: ADD_NOTES_ENTRY.LOADING})

      res = await api<any>(`${APIS.notes}`, 'POST', body)

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows, message},
        } = res.data

        if (success) {
          dispatch({type: ADD_NOTES_ENTRY.SUCCESS, payload: rows})
          callback && callback()
          toast.success(message)
        } else {
          dispatch({type: ADD_NOTES_ENTRY.ERROR})
          errorHandler(res.data, toast)
        }
      }
    } catch (message) {
      dispatch({type: ADD_NOTES_ENTRY.ERROR})
    }
  }
}

export const getNotesAction = (toast?: any, callback?: any) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_NOTES.LOADING})

      res = await api<any>(`${APIS.notes}`)

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows, message},
        } = res.data

        if (success) {
          dispatch({type: GET_NOTES.SUCCESS, payload: rows})
          callback && callback()
          toast.success(message)
        } else {
          dispatch({type: GET_NOTES.ERROR})
          errorHandler(res.data, toast)
        }
      }
    } catch (message) {
      dispatch({type: GET_NOTES.ERROR})
    }
  }
}

export const getJobNotesAction = (projectId: any, callback?: any) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_JOB_NOTES.LOADING})

      res = await api<any>(`${APIS.notes}/jobs/${projectId}`)

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: GET_JOB_NOTES.SUCCESS, payload: rows})
          callback && callback()
        } else {
          dispatch({type: GET_JOB_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: GET_JOB_NOTES.ERROR})
    }
  }
}

export const getCustomerNotesAction = (
  customerId: string | number,
  callback?: (data: Api.NoteListType) => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_CUSTOMER_NOTES.LOADING})

      res = await api<Api.Base<Api.NoteListType>>(
        `${APIS.get_customer}/${customerId}/notes`,
      )

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({type: GET_CUSTOMER_NOTES.SUCCESS, payload: data})
          callback?.(data)
        } else {
          dispatch({type: GET_CUSTOMER_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: GET_CUSTOMER_NOTES.ERROR})
    }
  }
}

export const createCustomerNotesAction = (
  customerId: string | number,
  body: {
    text: string
    parentId?: number
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: CREATE_CUSTOMER_NOTES.LOADING})

      res = await api<any>(
        `${APIS.get_customer}/${customerId}/notes`,
        'POST',
        body,
      )

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: CREATE_CUSTOMER_NOTES.SUCCESS, payload: rows})
          callback?.()
          toast.success('Note added successfully !!')
        } else {
          dispatch({type: CREATE_CUSTOMER_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: CREATE_CUSTOMER_NOTES.ERROR})
    }
  }
}

export const updateCustomerNotesAction = (
  customerId: string | number,
  noteId: number | string,
  body: {
    text: string
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: UPDATE_CUSTOMER_NOTES.LOADING})

      res = await api<any>(
        `${APIS.get_customer}/${customerId}/notes/${noteId}`,
        'PATCH',
        body,
      )

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: UPDATE_CUSTOMER_NOTES.SUCCESS, payload: rows})
          callback?.()
        } else {
          dispatch({type: UPDATE_CUSTOMER_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: UPDATE_CUSTOMER_NOTES.ERROR})
    }
  }
}

export const deleteCustomerNotesAction = (
  customerId: string | number,
  customerNoteId: string | number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: DELETE_CUSTOMER_NOTES.LOADING})

      res = await api<any>(
        `${APIS.get_customer}/${customerId}/notes/${customerNoteId}`,
        'DELETE',
      )

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: DELETE_CUSTOMER_NOTES.SUCCESS, payload: rows})
          callback?.()
          toast.success('Note deleted successfully !!')
        } else {
          dispatch({type: DELETE_CUSTOMER_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: DELETE_CUSTOMER_NOTES.ERROR})
    }
  }
}

export const getSiteNotesAction = (
  siteId: string | number,
  callback?: (data: Api.NoteListType) => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_SITE_NOTES.LOADING})

      res = await api<Api.Base<Api.NoteListType>>(
        `${APIS.get_site}/${siteId}/notes`,
      )

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({type: GET_SITE_NOTES.SUCCESS, payload: data})
          callback?.(data)
        } else {
          dispatch({type: GET_SITE_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: GET_SITE_NOTES.ERROR})
    }
  }
}

export const createSiteNotesAction = (
  siteId: string | number,
  body: {
    text: string
    parentId?: number
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: CREATE_SITE_NOTES.LOADING})

      res = await api<any>(`${APIS.get_site}/${siteId}/notes`, 'POST', body)

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: CREATE_SITE_NOTES.SUCCESS, payload: rows})
          callback?.()
          toast.success('Note added successfully !!')
        } else {
          dispatch({type: CREATE_SITE_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: CREATE_SITE_NOTES.ERROR})
    }
  }
}

export const updateSiteNotesAction = (
  siteId: string | number,
  noteId: number | string,
  body: {
    text: string
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: UPDATE_SITE_NOTES.LOADING})

      res = await api<any>(
        `${APIS.get_site}/${siteId}/notes/${noteId}`,
        'PATCH',
        body,
      )

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: UPDATE_SITE_NOTES.SUCCESS, payload: rows})
          callback?.()
        } else {
          dispatch({type: UPDATE_SITE_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: UPDATE_SITE_NOTES.ERROR})
    }
  }
}

export const deleteSiteNotesAction = (
  siteId: string | number,
  siteNoteId: string | number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: DELETE_SITE_NOTES.LOADING})

      res = await api<any>(
        `${APIS.get_site}/${siteId}/notes/${siteNoteId}`,
        'DELETE',
      )

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: DELETE_SITE_NOTES.SUCCESS, payload: rows})
          callback?.()
          toast.success('Note deleted successfully !!')
        } else {
          dispatch({type: DELETE_SITE_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: DELETE_SITE_NOTES.ERROR})
    }
  }
}

export const getSiteVisitNotesAction = (
  projectId: any,
  siteVisitId: any,
  callback?: any,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_SITE_VISIT_NOTES.LOADING})

      res = await api<any>(
        `${APIS.notes}/jobs/${projectId}?siteVisit=${siteVisitId}`,
      )

      // console.log('addNoteHistoryAction', res)
      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: GET_SITE_VISIT_NOTES.SUCCESS, payload: rows})
          callback && callback()
        } else {
          dispatch({type: GET_SITE_VISIT_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: GET_SITE_VISIT_NOTES.ERROR})
    }
  }
}

// PROJECT NOTES

export const createProjectNotesAction = (
  projectId: string | number,
  body: {
    text: string
    parentId?: number
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: CREATE_PROJECT_NOTES.LOADING})

      res = await api<any>(`${APIS.projects}/${projectId}/notes`, 'POST', body)

      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: CREATE_PROJECT_NOTES.SUCCESS, payload: rows})
          callback?.()
        } else {
          dispatch({type: CREATE_PROJECT_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: CREATE_PROJECT_NOTES.ERROR})
    }
  }
}

export const updateProjectNotesAction = (
  projectId: number,
  noteId: number | string,
  body: {
    text: string
  },
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: UPDATE_PROJECT_NOTES.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/notes/${noteId}`,
        'PATCH',
        body,
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: UPDATE_PROJECT_NOTES.SUCCESS, payload: rows})
          callback?.()
        } else {
          dispatch({type: UPDATE_PROJECT_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: UPDATE_PROJECT_NOTES.ERROR})
    }
  }
}

export const getProjectNotesAction = (
  projectId: string | number,
  successCallback?: (data: Api.NoteListType) => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_PROJECT_NOTES.LOADING})

      res = await api<Api.Base<Api.NoteListType>>(
        `${APIS.projects}/${projectId}/notes`,
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {data},
        } = res.data

        if (success) {
          dispatch({type: GET_PROJECT_NOTES.SUCCESS, payload: data})
          successCallback?.(data)
        } else {
          dispatch({type: GET_PROJECT_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: GET_PROJECT_NOTES.ERROR})
    }
  }
}

export const deleteProjectNotesAction = (
  projectId: number | string,
  noteId: number | string,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: DELETE_PROJECT_NOTES.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/notes/${noteId}`,
        'DELETE',
      )

      if (res.data !== undefined) {
        const {
          success,
          data: {data: rows},
        } = res.data

        if (success) {
          dispatch({type: DELETE_PROJECT_NOTES.SUCCESS, payload: rows})
          callback?.()
          toast.success('Project notes deleted successfully !')
        } else {
          dispatch({type: DELETE_PROJECT_NOTES.ERROR})
        }
      }
    } catch (message) {
      dispatch({type: DELETE_PROJECT_NOTES.ERROR})
    }
  }
}

// * GET PROJECT HISTORY
export const getProjectHistory = (
  projectId: number,
  params?: Record<string, string | number | boolean>,
  callback?: any,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_PROJECT_HISTORY.LOADING})
      res = await api<Api.Base<Api.ProjectHistoryList>>(
        getParsedUrl(`${APIS.projects}/${projectId}/app-logs`, params),
      )

      if (res.data !== undefined) {
        const {success, data} = res.data

        if (success) {
          dispatch({type: GET_PROJECT_HISTORY.SUCCESS, payload: data?.data})
          callback?.(data.data)
        } else {
          throw new Error(data?.message ?? 'Something went wrong')
        }
      } else {
        throw new Error('Something went wrong')
      }
    } catch (message: any) {
      toast.error(message?.message)
      dispatch({type: GET_PROJECT_HISTORY.ERROR})
    }
  }
}

export const createMannualLog = (
  projectId: number,
  body: {text: string},
  callback?: any,
) => {
  return async (dispatch: any) => {
    let res
    try {
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/app-logs`,
        'POST',
        body,
      )

      if (res.data !== undefined) {
        const {success, data} = res.data

        if (success) {
          toast.success('Log created successfully !')
          callback?.(data.data)
        } else {
          throw new Error(data?.message ?? 'Something went wrong')
        }
      } else {
        throw new Error('Something went wrong')
      }
    } catch (message: any) {
      toast.error(message?.message)
    }
  }
}

export const getProjectNoteByNoteId = (
  projectId: number,
  noteId: number,
  callback?: any,
) => {
  return async (dispatch: any) => {
    let res
    try {
      dispatch({type: GET_PROJECT_NOTE_BY_ID.LOADING})
      res = await api<Api.Base<Api.ProjectNoteById>>(
        `${APIS.projects}/${projectId}/notes/${noteId}`,
      )

      if (res.data !== undefined) {
        const {success, data} = res.data

        if (success) {
          dispatch({type: GET_PROJECT_NOTE_BY_ID.SUCCESS, payload: data?.data})
          callback?.(data.data)
        } else {
          throw new Error(data?.message ?? 'Something went wrong')
        }
      } else {
        throw new Error(res?.data?.message ?? 'Something went wrong')
      }
    } catch (message: any) {
      dispatch({type: GET_PROJECT_NOTE_BY_ID.ERROR})
      // toast.error(message?.message)
    }
  }
}
