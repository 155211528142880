export const handleMultiCheckbox = <DataIdUnionType>(
  dataId: DataIdUnionType,
  filterArray: Array<{
    label: string
    id: number
    isChecked: boolean
    dataId: DataIdUnionType
  }>,
  defaultId: DataIdUnionType
) => {
  const newArray = filterArray.map((item) => {
    if (item.dataId === dataId) {
      return {...item, isChecked: !item.isChecked}
    }
    return item
  })

  const defaultArray = newArray.find((data) => data.dataId === defaultId)

  const itemExceptAll = newArray.filter((item) => item.dataId !== defaultId)

  const remappedArrayFlag = itemExceptAll.reduce(
    (acc, value, _, array) => {
      if (value.isChecked) {
        acc.count += 1
      }
      acc.flag = acc.count === array.length
      return acc
    },
    {count: 0, flag: false}
  ).flag

  let result = newArray

  if (dataId === defaultId) {
    result = newArray.map((item) => {
      return {...item, isChecked: defaultArray.isChecked}
    })
  } else {
    result = newArray.map((item) => {
      if (item.dataId === defaultId) {
        return {...item, isChecked: remappedArrayFlag}
      }
      return item
    })
  }

  return result
}

export const getConcatenatedValue = <DataIdUnionType>(
  filterArray: Array<{
    label: string
    id: number
    isChecked: boolean
    dataId: DataIdUnionType
  }>,
  defaultId: DataIdUnionType,
  label: string | 'default' = 'default'
) => {
  const result = filterArray
    .filter((item) => item.isChecked)
    .reduce((acc, currValue, _, array) => {
      if (array?.filter((item) => item.dataId === defaultId).length > 0) {
        if (label === 'default') {
          return acc
        } else {
          if (acc.includes(label)) {
            return acc
          }
          acc.push(label)
          return acc
        }
      } else {
        acc.push(currValue.dataId)
        return acc
      }
    }, [])
    .join(',')
  // .toLowerCase()

  return result === '' ? undefined : result
}
