import {useEffect, useRef, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {
  FaBook,
  FaCheck,
  FaComments,
  FaEnvelope,
  FaInfoCircle,
  FaMapMarkerAlt,
  FaPen,
  FaPlay,
  FaTimes,
  FaUserAlt
} from 'react-icons/fa'
import {useDispatch} from 'react-redux'
import {AnimatedBlock, interpolate, TransitionBlock} from 'react-ui-animate'
import {useFormInput} from 'use-form-input'

import {Button, ButtonGroup, Input} from 'app/common'
import {addCompanyLogo, getCompanyDetails, updateCompanyName} from 'redux-src'
import {CompanyDetailsModal} from './components'
import {getAssetUrl} from 'helpers'

export const CompanyDetails = () => {
  const dispatch = useDispatch()
  const [isCompanyTitleClicked, setCompanyTitleClicked] = useState(false)
  const inputRef = useRef<HTMLInputElement>()
  const logoRef = useRef<HTMLInputElement>()

  const {companyDetails: companyFromAuth, toast} = useAuth()

  const companyDetails = companyFromAuth as Api.CompanyDetails

  const [logoPreview, setLogoPreview] = useState<string | ArrayBuffer>(null)

  const [dragActive, setDragActive] = useState(false)
  const [isDelete, setDelete] = useState(false)

  const [companyDetail, companyDetailHandler] = useFormInput({
    logo: logoPreview,
    companyName: ''
  })

  const [isCompanyDetailModal, setCompanyDetailModal] = useState(false)
  const [isGeneralSectionError, setGeneralSectionError] = useState(false)

  useEffect(() => {
    if (companyDetails && companyDetails.company_details) {
      companyDetailHandler.setValue(
        'companyName',
        companyDetails?.company_details?.name
      )
    }

    if (
      companyDetails &&
      companyDetails.asset_details &&
      Object.entries(companyDetails.asset_details)?.every(
        ([_, value]) => value !== null && value !== undefined
      )
    ) {
      setLogoPreview(getAssetUrl(companyDetails.asset_details))
    }
    if (
      companyDetails?.company_details?.dnt_net_cost <= 0 ||
      companyDetails?.company_details?.expected_to <= 0 ||
      companyDetails?.company_details?.oh_per_year <= 0 ||
      companyDetails?.company_details?.predicted_oh_profit_percent <= 0 ||
      companyDetails?.company_details?.nm_to_sales_percent <= 0 ||
      companyDetails?.company_details?.net_cost <= 0
    ) {
      setGeneralSectionError(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails])

  const logoHandler = (e: any) => {
    const companyLogoFormData = new FormData()
    companyLogoFormData.append('files', e.target.files[0])

    dispatch(
      addCompanyLogo(
        companyLogoFormData,
        () => {
          dispatch(getCompanyDetails())
        },
        toast
      )
    )
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setLogoPreview(reader.result)
      }
    }

    reader.readAsDataURL(e.target.files[0])
  }

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          setLogoPreview(reader.result)
        }
      }

      reader.readAsDataURL(e.dataTransfer.files[0])
    }
  }

  const saveCompanyName = () => {
    dispatch(
      updateCompanyName(
        {
          name: companyDetail.companyName
        },
        () => {
          dispatch(getCompanyDetails())
          setCompanyTitleClicked(false)
        },
        toast
      )
    )
  }

  const handleQuickEdit = () => {
    setCompanyDetailModal(true)
  }

  if (!companyDetails) {
    return null
  }

  return (
    <form onDragEnter={handleDrag} onSubmit={(e: any) => e.preventDefault()}>
      <div className="flex items-center rounded-sm justify-between text-lg font-bold mb-6">
        <div className="flex text-lg font-bold cursor-pointer">
          <div className="mx-4 w-[200px] max-w-[400px]">
            <Input
              ref={inputRef}
              name="companyName"
              size="sm"
              className="flex outline-none text-lg font-bold border-none disabled:bg-white bg-[#ff8c0033]"
              containerClass="w-[inherit] max-w-[400px]"
              placeholder="Company Name"
              value={companyDetail.companyName}
              onChange={companyDetailHandler.onChange}
              disabled
            />
          </div>
          <div className="flex justify-center items-center ml-10 gap-10">
            <FaPen
              size={14}
              onClick={() => {
                setCompanyTitleClicked((prev) => !prev)

                inputRef.current.disabled = isCompanyTitleClicked
                inputRef.current?.focus()
              }}
            />{' '}
            {isCompanyTitleClicked && (
              <FaCheck color="green" onClick={saveCompanyName} />
            )}
          </div>
        </div>

        <div className="flex items-center justify-between font-light cursor-pointer text-[14px]">
          <div>
            <span className="flex text-blue-300">
              <FaPlay className="mx-4 my-[2px]" size={14} />
              Watch overview{' '}
            </span>
          </div>
          <div>
            <span className="ml-20 flex text-blue-300">
              {' '}
              <FaBook className="mx-4 my-[2px]" size={14} />
              Need Help?
            </span>
          </div>
        </div>
      </div>
      {(companyDetails?.address_details?.address === null ||
        companyDetails?.address_details?.address === 'default') && (
        <div className="h-full w-full bg-[#F4D6CC] text-[#CC3300] my-20 px-14 py-8 border-1 rounded-sm border-[#f1c4bf]">
          <div className="text-base font-bold flex items-center gap-10">
            <FaInfoCircle size={20} />
            You have no address set for your company!
          </div>
          <div className="my-10 flex items-center gap-10">
            You will not be able to create any quotes without your company
            address.
            <Button title="Add Company Address" onClick={handleQuickEdit} />
          </div>
        </div>
      )}
      <div className="mb-20 flex items-start justify-between border-1 rounded-sm border-gray-200">
        <div className="flex items-center justify-between ">
          <div className="px-10 flex items-center justify-center cursor-pointer border-gray-200 relative w-[130px]">
            {logoPreview ? (
              <div
                className="rounded-sm"
                onMouseEnter={() => setDelete(true)}
                onMouseLeave={() => setDelete(false)}
              >
                <img
                  src={logoPreview?.toString()}
                  className="rounded-sm"
                  alt="Company Logo"
                />
                <TransitionBlock state={isDelete}>
                  {(animation) => (
                    <AnimatedBlock
                      className="p-4 bg-red-400 text-white rounded-sm hover:bg-red-500"
                      style={{
                        position: 'absolute',
                        translateY: interpolate(
                          animation.value,
                          [0, 1],
                          [-20, -30]
                        ),
                        opacity: animation.value
                      }}
                      onClick={() => setLogoPreview(null)}
                    >
                      <span className="inline-flex gap-6 text-[12px]">
                        <FaTimes size={10} className="my-4" /> Delete Logo
                      </span>
                    </AnimatedBlock>
                  )}
                </TransitionBlock>
              </div>
            ) : (
              <>
                <label
                  htmlFor="companyLogo"
                  className="cursor-pointer rounded-sm transition duration-500 ease-in-out text-center text-sm text-gray-300 hover:text-gray-400"
                >
                  Your logo here
                </label>
                <input
                  ref={logoRef}
                  className="cursor-pointer hidden"
                  type="file"
                  id="companyLogo"
                  name="companyLogo"
                  accept="image/*"
                  multiple={false}
                  onChange={logoHandler}
                />
              </>
            )}
          </div>

          <div className="flex flex-col border-l-1 border-gray-200 p-10 px-16">
            <span className="text-blue-400 font-bold text-[16px]">
              {companyDetails?.company_details?.name}
            </span>
            <span className="flex ml-20 my-6 text-gray-400">
              <FaMapMarkerAlt
                className="mx-6 mr-10"
                size={14}
                color="#c9c9c9"
              />
              {companyDetails?.address_details?.address === 'default'
                ? 'No address details'
                : companyDetails?.address_details?.address}
            </span>
            {companyDetails?.address_details?.address !== 'default' && (
              <span className="flex ml-20 my-6 font-bold gap-4">
                <FaUserAlt className="mx-6" size={14} color="#c9c9c9" />
                <span className="text-gray-500 text-md">
                  {companyDetails?.contact_details?.firstname}
                </span>
                <span className="text-gray-500 text-md">
                  {companyDetails?.contact_details?.lastname}
                </span>
                <span className="text-gray-500 text-md">
                  ({companyDetails?.contact_details?.position})
                </span>
              </span>
            )}

            <span className="ml-8 mb-6 text-sm">
              <span className="text-gray-400 text-sm">
                {companyDetails?.company_details?.mobile},
              </span>
              <span className="text-blue-300 text-sm px-4">
                {companyDetails?.company_details?.email}
              </span>
            </span>
          </div>
        </div>

        <div className="mt-10 mr-16">
          <ButtonGroup
            data={[
              {
                icon: (
                  <FaEnvelope
                    // onClick={handleQuickEmail}
                    size={12}
                  />
                )
              },
              {
                icon: (
                  <FaComments
                    // onClick={handleQuickSms}
                    size={12}
                  />
                )
              },
              {
                icon: (
                  <FaMapMarkerAlt
                    // onClick={handleQuickMap}
                    size={12}
                  />
                )
              },
              {
                icon: <FaPen size={12} />,
                onClick: handleQuickEdit
              }
            ]}
          />
        </div>
      </div>

      <label
        htmlFor="companyLogo"
        className={
          'cursor-pointer border-1 w-full h-[110px] rounded-sm mb-20 flex justify-center items-center flex-col ' +
          `${
            dragActive
              ? 'bg-gray-200 border-gray-300'
              : 'bg-blue-100 border-blue-200'
          }`
        }
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <label
          htmlFor="companyLogo"
          className="cursor-pointer text-gray-400 mt-auto"
        >
          Drag & drop your company logo here or
        </label>
        <label
          htmlFor="companyLogo"
          className="my-10 mb-auto border-1 cursor-pointer rounded-sm transition duration-500 ease-in-out text-center text-sm text-gray-300 border-gray-200 px-8 py-4 hover:bg-gray-200 hover:text-gray-400"
        >
          Choose logo
        </label>
      </label>
      {isGeneralSectionError && (
        <div className="h-full w-full bg-[#F4D6CC] text-[#CC3300] my-20 px-14 py-8 border-1 rounded-sm border-[#f1c4bf]">
          <div className="text-base font-bold flex items-center gap-10">
            <FaInfoCircle size={20} />
            You have not filled up all of your company settings!
          </div>
          <div className="my-10 flex items-center gap-10">
            You will not be able to create any quotes without filling up
            necessary details below.
          </div>
        </div>
      )}
      <CompanyDetailsModal
        isCompanyDetailModal={isCompanyDetailModal}
        setCompanyDetailModal={setCompanyDetailModal}
      />
    </form>
  )
}
