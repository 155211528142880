import {createContext, useEffect, useState, useRef, useCallback} from 'react'
import {HiArrowLeft} from 'react-icons/hi'
import {ActiveLink, useNavigation, Auth} from 'react-auth-navigation'
import {BsThreeDotsVertical} from 'react-icons/bs'
import colors from 'tailwindcss/colors'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import {ErrorType, ValidatorType, ValueType} from 'use-form-input/dist/types'

import {Dropdown, toast} from 'app/common'
import {AvatarModal} from 'app/components/header/components/userProfile/components'
import {useInput, validateEmail} from 'hooks'
import {
  activateCompanyUser,
  deactivateUser,
  getCompanyUserByIdAction,
  updateCompanyUserAction
} from 'redux-src'
import {getAssetUrl, getCountryId} from 'helpers'

import {GroupItem} from '../../components'
import {AddUserToGroup} from '../../components/addUserToGroup'

interface InitialContextTypes<T> {
  physicalAddressData: T
  physicalAddressHandler?: {
    setValue: (
      name?: keyof T,
      value?: ValueType | ((previousValue: ValueType) => any)
    ) => void
    onChange: (event?: React.ChangeEvent<any>) => void
    onSubmit: (e: React.FormEvent) => void
    validator: ValidatorType<T>
    isValid: (errors: ErrorType<T> | {}) => boolean
    errors: ErrorType<T>
    setErrors: React.Dispatch<React.SetStateAction<{}>>
    clear: () => void
    modified: T
  }
  data: any
  onChangeHandler: (name: any) => any
  userType: string
  setUserType: React.Dispatch<React.SetStateAction<string>>
  error?: any
  isSaveSectionVisible: boolean
  onUpdateCompanyUser?: () => void
  handleUndoChanges?: () => void
}

const initialContextVariables: InitialContextTypes<{
  address: string
  zip: string
  country: string
  state: string
  city: string
  lat: number
  lng: number
  placeId: number
  place: string
  suburb: string
  countryId: number
  active: boolean
  addressTypeId: number
}> = {
  physicalAddressData: {
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 1
  },
  physicalAddressHandler: undefined,
  data: {},
  onChangeHandler: (name) => '',
  userType: '',
  setUserType: () => '',
  error: '',
  isSaveSectionVisible: false
}

export const UserDetailsContext = createContext(initialContextVariables)

export const UserDetailsMain = () => {
  const {companyUserDetails}: RT.UserReduxType = useSelector(
    (state: any) => state.user
  )

  const {
    navigation: {navigate, routes},
    params
  } = useNavigation()
  const userRef = useRef<boolean>(true)

  const dispatch = useDispatch()

  const [isSaveSectionVisible, setSaveSectionVisible] = useState<boolean>(false)
  // const [newCompanyUserDetails, setCompanyUserDetails] = useState<any>()
  const [isAvatarShow, setAvatarShow] = useState(false)
  const [initialChar, setInitialChar] = useState('')
  const [addToGroupModal, setAddToGroupModal] = useState<boolean>(false)

  const [userType, setUserType] = useState<string>('')
  const [physicalAddressData, physicalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 1
  })

  const {data, onChangeHandler, getErrors, error, setData} = useInput({
    firstName: '',
    lastName: '',
    loginEmail: '',
    phoneNumber: '',
    payRates: '',
    otherPhones: '',
    emailAddress: ''
  })

  const {id} = params as any

  const onUpdateCompanyUser = () => {
    const {firstName, lastName, loginEmail, payRates, phoneNumber} = data

    const {errorFlag} = getErrors([
      {
        key: 'firstName',
        condition: firstName?.length === 0,
        cb: () => {
          toast.error(`Please enter your first name.`)
        }
      },
      {
        key: 'lastName',
        condition: lastName?.length === 0,
        cb: () => {
          toast.error(`Please enter your lastname.`)
        }
      },
      {
        key: 'loginEmail',
        condition: validateEmail(loginEmail),
        cb: () => {
          toast.error(`Please enter valid email.`)
        }
      },
      {
        key: 'payRates',
        condition: payRates.length === 0,
        cb: () => {
          toast.error(`Please enter pay rates.`)
        }
      }
    ])

    if (errorFlag) {
      return toast.error('Please fill up the required fields !!!')
    }

    const body = {
      displayName: firstName,
      lastname: lastName,
      role: userType === 'System Admin' ? undefined : userType,
      phone: Number(phoneNumber),
      email:
        companyUserDetails?.user_details?.email === loginEmail
          ? undefined
          : loginEmail,
      chargeOutRate: payRates,
      addressDetails: {
        address: physicalAddressData?.address,
        city: physicalAddressData?.city,
        country: physicalAddressData?.country,
        suburb: !!physicalAddressData?.suburb
          ? physicalAddressData?.suburb
          : undefined,
        state: !!physicalAddressData?.state
          ? physicalAddressData?.state
          : undefined,
        zipCode: !!physicalAddressData?.zip
          ? physicalAddressData?.zip
          : undefined
      }
    }

    dispatch(
      updateCompanyUserAction(id, body, () => {
        dispatch(getCompanyUserByIdAction(id))
      })
    )
  }

  const handleUndoChanges = () => {
    setSaveSectionVisible(false)
    dispatch(
      getCompanyUserByIdAction(id, () => {
        setSaveSectionVisible(false)
      })
    )
  }

  const deactivateCompanyUser = () => {
    dispatch(
      deactivateUser(id, () => {
        dispatch(getCompanyUserByIdAction(id, () => {}))
      })
    )
  }

  const activateUserHandel = () => {
    dispatch(
      activateCompanyUser(id, () => {
        dispatch(getCompanyUserByIdAction(id, () => {}))
      })
    )
  }

  const prepopulatedUserDetails = useCallback(() => {
    setData({
      firstName: companyUserDetails?.user_details?.display_name ?? '',
      lastName: companyUserDetails?.user_details?.lastname ?? '',
      payRates: String(
        companyUserDetails?.user_details?.charge_out_rate ?? '0'
      ),
      phoneNumber: String(companyUserDetails?.user_details?.phone ?? ''),
      loginEmail: companyUserDetails?.user_details?.email ?? '',
      emailAddress: companyUserDetails?.user_details?.email ?? '',
      otherPhones: String(companyUserDetails?.user_details?.other_phones ?? '')
    })

    physicalAddressHandler?.setValue(
      'address',
      companyUserDetails?.address_details?.address ?? ''
    )
    physicalAddressHandler?.setValue(
      'suburb',
      companyUserDetails?.address_details?.suburb ?? ''
    )

    physicalAddressHandler?.setValue(
      'city',
      companyUserDetails?.address_details?.city ?? ''
    )

    physicalAddressHandler?.setValue(
      'countryId',
      getCountryId(companyUserDetails?.address_details?.country) ?? ''
    )

    physicalAddressHandler?.setValue(
      'country',
      companyUserDetails?.address_details?.country ?? ''
    )

    physicalAddressHandler?.setValue(
      'state',
      companyUserDetails?.address_details?.state ?? ''
    )

    physicalAddressHandler?.setValue(
      'zip',
      companyUserDetails?.address_details?.zip_code ?? ''
    )

    setUserType(companyUserDetails?.user_details?.role)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUserDetails])

  useEffect(() => {
    prepopulatedUserDetails()
  }, [prepopulatedUserDetails])

  useEffect(() => {
    if (userRef?.current) {
      userRef.current = false
    } else {
      setSaveSectionVisible(true)
    }
  }, [data, physicalAddressData])

  useEffect(() => {
    dispatch(getCompanyUserByIdAction(id, () => {}))
  }, [dispatch, id])

  useEffect(() => {
    setInitialChar(
      companyUserDetails?.user_details?.display_name
        ? companyUserDetails?.user_details?.display_name.charAt(0)
        : '-'
    )
  }, [companyUserDetails])

  return (
    <UserDetailsContext.Provider
      value={{
        physicalAddressData,
        data,
        onChangeHandler,
        userType,
        setUserType,
        error,
        physicalAddressHandler,
        isSaveSectionVisible,
        handleUndoChanges,
        onUpdateCompanyUser
      }}
    >
      <div>
        {/* HEAD SECTION */}
        <div className="flex mt-16 items-center justify-between">
          <div className="flex items-center justify-between">
            <h2
              className="text-sm flex items-centerw-[150px] mb-10 cursor-pointer"
              style={{color: 'blue'}}
              onClick={() => navigate(routes['Users Main'].path)}
            >
              <span className="mr-10 mt-[3px]">
                <HiArrowLeft />
              </span>
              Back to Users List
            </h2>
          </div>

          <Dropdown
            triggerElement={
              <div className="transition duration-300 ease-in w-[30px] h-[30px] flex items-center border-1 border-gray-300 justify-center bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                <BsThreeDotsVertical size={20} color={colors.gray[600]} />
              </div>
            }
            placement="bottomright"
          >
            <div className="w-[200px] shadow-xl bg-white border-1 border-gray-200 mt-4 rounded-sm">
              {companyUserDetails?.user_details?.is_active ? (
                <div
                  className="hover:bg-gray-100 p-8 cursor-pointer"
                  onClick={() => {
                    deactivateCompanyUser()
                  }}
                >
                  Disable User
                </div>
              ) : (
                <div
                  className="hover:bg-gray-100 p-8 cursor-pointer"
                  onClick={() => {
                    activateUserHandel()
                  }}
                >
                  Enable User
                </div>
              )}
            </div>
          </Dropdown>
        </div>

        {/* USER DETAILS MAIN CONTENT */}
        <div className="mt-16">
          {/* USER DETAILS TOP SECTION */}
          <div className="flex gap-14 items-start">
            {/* USER PROFILE */}
            <div className="text-center">
              <div className="rounded-full text-white flex items-center justify-center w-[90px] h-[90px] bg-gray-300">
                <h1 className="text-3xl">
                  {companyUserDetails?.asset_details === null ? (
                    companyUserDetails?.user_details?.display_name
                      ?.at(0)
                      .toUpperCase()
                  ) : (
                    <>
                      <div className="rounded-sm" style={{borderRadius: '50%'}}>
                        <img
                          src={getAssetUrl(companyUserDetails?.asset_details)}
                          alt="profilePic"
                          style={{borderRadius: '50%'}}
                        />
                      </div>
                    </>
                  )}
                </h1>
              </div>
              <button
                className="border-none mt-8 text-sm bg-none text-blue-300"
                onClick={() => setAvatarShow((prev) => !prev)}
              >
                Edit avatar
              </button>
            </div>
            {/* BASIC DETAIL */}
            <div>
              <h2 className="text-2xl font-bold text-gray-500">
                {companyUserDetails?.user_details?.display_name}{' '}
                {companyUserDetails?.user_details?.lastname}
              </h2>
              <div className="flex gap-4 text-gray-400 ml-[2px] items-center text-sm">
                <p>User Type: </p>
                <p>{companyUserDetails?.user_details?.role}</p>
                {companyUserDetails?.user_details?.is_active ? (
                  <span className="bg-green-400 px-16 py-[2px] rounded-md text-white font-bold">
                    Active
                  </span>
                ) : (
                  <span className="bg-red-300 px-16 py-[2px] rounded-md text-white font-bold">
                    Disabled
                  </span>
                )}
              </div>

              {/* USER GROUPS SECTION  */}
              <div className="mt-18">
                <h2 className="font-bold text-lg mb-8">Groups</h2>

                <div className="flex gap-8 items-center">
                  {companyUserDetails?.user_associated_groups?.map(
                    (group, id) => {
                      return <GroupItem details={group} key={id} />
                    }
                  )}

                  <div
                    className="text-blue-300 cursor-pointer text-sm"
                    onClick={() => {
                      setAddToGroupModal((prev) => !prev)
                    }}
                  >
                    Add to group
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* USERS BOTTOM DETAILS SECTION (TAB PANEL) */}
          <div className="mt-32">
            <div className="grid grid-cols-3 gap-4 border-b-1 border-gray-200">
              <AuthTabItem
                path={`/settings/users/${id}`}
                exact
                title={'Details'}
              />
              <AuthTabItem
                path={`/settings/users/${id}/timesheet`}
                title={'Timesheet'}
              />
              <AuthTabItem
                path={`/settings/users/${id}/invoice`}
                title={'Invoice'}
              />
            </div>
            <div className="py-20">
              <Auth.Screens path="/settings/users/:id" />
            </div>
          </div>
        </div>
      </div>
      <AvatarModal
        isAvatarShow={isAvatarShow}
        setAvatarShow={setAvatarShow}
        initialChar={initialChar}
        editMode="edit"
      />
      <AddUserToGroup
        newGroupModal={addToGroupModal}
        setNewGroupModal={setAddToGroupModal}
      />
    </UserDetailsContext.Provider>
  )
}

const AuthTabItem = ({
  title,
  path,
  subtitle,
  exact
}: {
  title: string
  path: string
  subtitle?: string
  exact?: boolean
}) => (
  <ActiveLink
    to={path}
    className="block p-12 px-16 bg-gray-100 rounded-t-sm border-1 border-gray-200 border-b-0 relative text-gray-400"
    activeClassName="bg-white after:content-[''] after:absolute after:left-0 after:top-[100%] after:w-[100%] after:h-[3px] after:bg-white after:-translate-y-half font-bold text-gray-500"
    exact={exact}
  >
    <div className="text-[16px]">{title}</div>
    <div className="text-sm">{subtitle}</div>
  </ActiveLink>
)
