export const errorHandler = (resData: any, toast: any) => {
  const data = resData?.data
  const errorMessage = resData?.message
  if (Array.isArray(data)) {
    data?.map(({message}) => {
      toast.error(message)

      return null
    })
  } else if (data?.message) {
    toast.error(data?.message)
  } else {
    toast.error(errorMessage)
  }
}
