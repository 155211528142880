import {
  CREATE_ITEM_TYPE,
  CREATE_ITEM_CATEGORY,
  GET_ITEM_TYPE_LIST,
  GET_ITEM_CATEGORY_LIST,
  GET_ITEM_TYPE_BY_ID,
  UPDATE_ITEM_CATEGORY,
  DELETE_ITEM_TYPE,
  DELETE_ITEM_CATEGORY,
  UPDATE_ITEM_TYPE,
  CLEAR_ITEM_TYPE
} from '../actions'

const initialState: RT.ItemTypeReduxType = {
  itemTypeList: [],
  itemTypeTotal: 0,
  getItemTypeListLoading: false,
  createItemTypeLoading: false,
  createItemCategoryLoading: false,
  updateItemTypeLoading: false,
  deleteItemTypeLoading: false,
  itemCategoryList: [],
  getItemCategoryListLoading: false,
  getItemTypeByIdLoading: false,
  itemTypeDetails: undefined,
  updateItemCategoryLoading: false,
  deleteItemCategoryLoading: false
}

export function itemTypesReducer(state = initialState, action: any) {
  const {type, payload} = action

  switch (type) {
    case CLEAR_ITEM_TYPE.SUCCESS:
      return {
        ...state,
        itemTypeDetails: undefined
      }

    case GET_ITEM_TYPE_LIST.LOADING:
      return {
        ...state,
        getItemTypeListLoading: true
      }
    case GET_ITEM_TYPE_LIST.SUCCESS:
      return {
        ...state,
        getItemTypeListLoading: false,
        itemTypeList: payload.rows,
        itemTypeTotal: payload.total
      }
    case GET_ITEM_TYPE_LIST.ERROR:
      return {...state, getItemTypeListLoading: false}

    case GET_ITEM_TYPE_BY_ID.LOADING:
      return {
        ...state,
        getItemTypeByIdLoading: true
      }
    case GET_ITEM_TYPE_BY_ID.SUCCESS:
      return {
        ...state,
        getItemTypeByIdLoading: false,
        itemTypeDetails: payload
      }
    case GET_ITEM_TYPE_BY_ID.ERROR:
      return {...state, getItemTypeByIdLoading: false}

    case CREATE_ITEM_TYPE.LOADING:
      return {
        ...state,
        createItemTypeLoading: true
      }
    case CREATE_ITEM_TYPE.SUCCESS:
      return {
        ...state,
        createItemTypeLoading: false
      }
    case CREATE_ITEM_TYPE.ERROR:
      return {...state, addInvoicesLoading: false}

    case UPDATE_ITEM_TYPE.LOADING:
      return {
        ...state,
        updateItemTypeLoading: true
      }

    case UPDATE_ITEM_TYPE.SUCCESS:
      return {
        ...state,
        updateItemTypeLoading: false
      }

    case UPDATE_ITEM_TYPE.ERROR:
      return {
        ...state,
        updateItemTypeLoading: false
      }

    case DELETE_ITEM_TYPE.LOADING:
      return {
        ...state,
        deleteItemTypeLoading: true
      }

    case DELETE_ITEM_TYPE.SUCCESS:
      return {
        ...state,
        deleteItemTypeLoading: false
      }

    case DELETE_ITEM_TYPE.ERROR:
      return {
        ...state,
        deleteItemTypeLoading: false
      }

    case GET_ITEM_CATEGORY_LIST.LOADING:
      return {
        ...state,
        getItemCategoryListLoading: true
      }
    case GET_ITEM_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        getItemCategoryListLoading: false,
        itemCategoryList: payload.rows
      }
    case GET_ITEM_CATEGORY_LIST.ERROR:
      return {...state, getItemCategoryListLoading: false}

    case CREATE_ITEM_CATEGORY.LOADING:
      return {
        ...state,
        createItemCategoryLoading: true
      }
    case CREATE_ITEM_CATEGORY.SUCCESS:
      return {
        ...state,
        createItemCategoryLoading: false
      }
    case CREATE_ITEM_CATEGORY.ERROR:
      return {...state, createItemCategoryLoading: false}

    case UPDATE_ITEM_CATEGORY.LOADING:
      return {
        ...state,
        updateItemCategoryLoading: true
      }

    case UPDATE_ITEM_CATEGORY.SUCCESS:
      return {
        ...state,
        updateItemCategoryLoading: false
      }

    case UPDATE_ITEM_CATEGORY.ERROR:
      return {
        ...state,
        updateItemCategoryLoading: false
      }

    case DELETE_ITEM_CATEGORY.LOADING:
      return {
        ...state,
        deleteItemCategoryLoading: true
      }

    case DELETE_ITEM_CATEGORY.SUCCESS:
      return {
        ...state,
        deleteItemCategoryLoading: false
      }

    case DELETE_ITEM_CATEGORY.ERROR:
      return {
        ...state,
        deleteItemCategoryLoading: false
      }

    default:
      return state
  }
}
