import {useEffect, useMemo, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {FaStickyNote} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {
  // addNoteEntryAction,
  // getJobNotesAction,
  // getSiteVisitNotesAction,
  createCustomerNotesAction,
  getCustomerNotesAction,
  updateCustomerNotesAction,
  deleteCustomerNotesAction,
  createSiteNotesAction,
  getSiteNotesAction,
  updateSiteNotesAction,
  deleteSiteNotesAction,
  createProjectNotesAction,
  getProjectNotesAction,
  updateProjectNotesAction,
  deleteProjectNotesAction,
  RootState,
} from 'redux-src'
import {NoteModal} from '../noteModal'
import {toast} from 'app/common'

export const NotesSummary = ({
  hostName,
  type = 'customer',
  hostId: hostIdFromProps,
}: Comp.NoteSummaryProps) => {
  const [length, setLength] = useState(0)
  const [noteModal, setNoteModal] = useState(false)

  const dispatch = useDispatch()
  // const { toast } = useAuth()
  const {params} = useNavigation()
  const {siteId, customerId, projectId, siteVisitId} = params as any
  const [displayNotes, setDisplayNotes] = useState([])

  const {projectNotes}: RT.NotesReduxType = useSelector(
    (state: RootState) => state.noteHistory,
  )
  const hostId = useMemo(() => {
    let switchId = 0

    switch (type) {
      case 'project':
        switchId = +projectId
        break
      case 'site visit':
        switchId = +siteVisitId
        break
      case 'site':
        switchId = +siteId
        break
      case 'customer':
        switchId = +customerId
        break
      default:
        switchId = 0
        break
    }

    return hostIdFromProps ?? switchId
  }, [customerId, hostIdFromProps, projectId, siteId, siteVisitId, type])

  type NoteCRUD = {
    create: (data: {text: string; parentId?: number}, cb: () => void) => void
    get: () => void
    remove: (customerNoteId: string | number, cb: () => void) => void
    update: (
      noteId: string | number,
      data: {text: string},
      cb: () => void,
    ) => void
  }

  type NoteActions = Record<Comp.NoteSummaryProps['type'], NoteCRUD>

  const noteActions: NoteActions = useMemo(
    () => ({
      project: {
        create: (data: {text: string}, cb: () => void) => {
          if (data.text.length === 0) {
            return toast.error('Note can not be empty !!')
          }

          dispatch(createProjectNotesAction(hostId, data, cb))
        },
        get: () => {
          dispatch(
            getProjectNotesAction(hostId, (data) => {
              setDisplayNotes(data?.rows?.map((notes: any) => notes))
              setLength(+data?.total ?? 0)
            }),
          )
        },
        update: (noteId: number | string, data, cb) => {
          dispatch(updateProjectNotesAction(hostId, noteId, data, cb))
        },
        remove: (projectNoteId: string | number, cb) => {
          dispatch(deleteProjectNotesAction(hostId, projectNoteId, cb))
        },
      },
      'site visit': {
        create: () => {},
        get: () => {},
        update: () => {},
        remove: () => {},
      },
      customer: {
        create: (data, cb) => {
          if (data?.text?.length === 0) {
            return toast.error(`Customer Notes can not be empty !!`)
          }

          dispatch(createCustomerNotesAction(hostId, data, cb))
        },
        get: () => {
          dispatch(
            getCustomerNotesAction(hostId, (data) => {
              setDisplayNotes(data?.rows?.map((notes: any) => notes))
              setLength(+data?.total ?? 0)
            }),
          )
        },
        update: (noteId: number | string, data, cb) => {
          dispatch(updateCustomerNotesAction(hostId, noteId, data, cb))
        },
        remove: (customerNoteId: string | number, cb) => {
          dispatch(deleteCustomerNotesAction(hostId, customerNoteId, cb))
        },
      },
      site: {
        create: (data, cb) => {
          dispatch(createSiteNotesAction(hostId, data, cb))
        },
        get: () => {
          dispatch(
            getSiteNotesAction(hostId, (data) => {
              setDisplayNotes(data?.rows?.map((notes: any) => notes))
              setLength(+data?.total ?? 0)
            }),
          )
        },
        update: (noteId: number | string, data: any, cb) => {
          dispatch(updateSiteNotesAction(hostId, noteId, {text: data}, cb))
        },
        remove: (siteNoteId: string | number, cb) => {
          dispatch(deleteSiteNotesAction(hostId, siteNoteId, cb))
        },
      },
    }),
    [dispatch, hostId],
  )

  useEffect(() => {
    noteActions[type]?.get()
  }, [noteActions, type])

  return (
    <>
      <div
        className={`cursor-pointer border-1 border-gray-200 rounded-sm p-4 px-8 flex items-center relative text-gray-400 bg-white ${
          displayNotes?.length > 0 ? 'hover:bg-yellow-100' : ''
        } transition-all duration-200 ease-in-out`}
        onClick={() => {
          setNoteModal((prev: boolean) => !prev)
        }}
      >
        <FaStickyNote className="mr-4" size={16} />
        {length} note{length !== 1 && 's'} for{' '}
        {hostName ? hostName : `this ${type}`}
        <button className="absolute right-8 text-sm text-gray-300">
          Click to add or view all
        </button>
      </div>

      <NoteModal
        {...noteActions[type]}
        {...{noteModal, setNoteModal, displayNotes}}
      />
    </>
  )
}
