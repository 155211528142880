import {useEffect, useRef} from 'react'

import {Loading} from '../Loading'

export const InfiniteScrollBlock = ({
  children,
  triggerCallback,
  isLast,
  ...rest
}: Comp.InfiniteScrollBlockProps) => {
  const scrollableBlockRef = useRef<HTMLDivElement>(null)
  const triggerBlockRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const _scrollableBlock = scrollableBlockRef.current
    const _triggerBlock = triggerBlockRef.current

    const observer = new IntersectionObserver(
      function ([entry]) {
        const {isIntersecting} = entry

        if (isIntersecting) {
          // console.log({isIntersecting: true})
          !isLast && triggerCallback?.()
        } else {
          // console.log({isIntersecting: false})
        }
      },
      {
        root: _scrollableBlock,
        threshold: 0.1,
      },
    )

    if (_triggerBlock) {
      observer.observe(_triggerBlock)
    }

    return () => {
      if (_triggerBlock) {
        observer.unobserve(_triggerBlock)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLast])

  return (
    <div ref={scrollableBlockRef} {...rest}>
      {children}
      {!isLast && (
        <div
          ref={triggerBlockRef}
          className="w-full flex justify-center items-center h-[30px]"
        >
          <Loading small />
        </div>
      )}
    </div>
  )
}
