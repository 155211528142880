import {useEffect, useMemo, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import ReactDatePicker from 'react-datepicker'
import {useSelector, useDispatch} from 'react-redux'
import {useFormInput} from 'use-form-input'
import moment from 'moment'

import {DynamicSearchSelect} from 'app/components'
import {Button, DefaultButton, Modal, ToolTip, toast} from 'app/common'
import {
  getSiteVisitTicketList,
  scheduleAssignedEmployee,
  updateScheduleTimeOfEmployee,
  assignAndScheduleProjectUserToTicket
} from 'redux-src'

import {SelectedAssignedEmployeeTypes} from './AssignedEmployee.component'
import PlanTicketCart from 'app/pages/jobs/pages/ticket/components/planList/components/planTicketCart/planTicketCart.component'

interface ScheduleModalProps {
  modalId?: string
  scheduleModal: boolean
  setScheduleModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedEmployeeId?: {
    employe_details: {
      id: number
      scheduled_from: string
      scheduled_to: string
      ticket_id: number
    }
    user_details: {
      display_name: string
      id: number
      lastname: string
    }
  }
  selectedEmployeeDetails?: SelectedAssignedEmployeeTypes
  setSelectedEmployeeId?: React.Dispatch<React.SetStateAction<number>>
  setSelectedAssignedEmployee?: React.Dispatch<React.SetStateAction<any>>
  edit?: boolean
  assignEmployee?: boolean
}

export const ScheduleModal = ({
  scheduleModal,
  setScheduleModal,
  selectedEmployeeId,
  selectedEmployeeDetails,
  setSelectedEmployeeId,
  setSelectedAssignedEmployee,
  modalId
}: ScheduleModalProps) => {
  moment().local(true)

  const {ticketDetailsById: ticketDetailsFromApi}: RT.TicktesReduxType =
    useSelector((state: any) => state.tickets)

  const [scheduleDuration, setScheduleDuration] = useState<any>()

  const {params} = useNavigation()
  const {projectId, siteVisitId} = params as any

  const dispatch = useDispatch()

  const [data, {setValue, clear}] = useFormInput({
    startDate: undefined,
    endDate: undefined,
    startTime: undefined,
    endTime: undefined,
    selectedStaff: undefined
  })

  const ticketDetails = useMemo(() => {
    if (!!ticketDetailsFromApi) {
      return {
        ticket_details: ticketDetailsFromApi['ticket_details'],
        ticket_owner_details: ticketDetailsFromApi['ticket_owner_details']
      }
    } else {
      return {}
    }
  }, [ticketDetailsFromApi])

  const handleScheduledEvent = () => {
    const body = {
      scheduledFrom:
        !!data?.startDate && !!data?.startTime
          ? `${data?.startDate?.split('T').at(0)}T${data?.startTime
              ?.split('T')
              .at(1)}`
          : undefined,
      scheduledTo:
        !!data?.endDate && !!data?.endTime
          ? `${data?.endDate?.split('T').at(0)}T${data?.endTime
              ?.split('T')
              .at(1)}`
          : undefined
    }

    if (!!!selectedEmployeeDetails && !!!selectedEmployeeId) {
      const assignNScheduleBody = {
        userId: data?.selectedStaff?.value,
        schedule: {
          scheduledFrom:
            !!data?.startDate && !!data?.startTime
              ? `${data?.startDate?.split('T').at(0)}T${data?.startTime
                  ?.split('T')
                  .at(1)}`
              : undefined,
          scheduledTo:
            !!data?.endDate && !!data?.endTime
              ? `${data?.endDate?.split('T').at(0)}T${data?.endTime
                  ?.split('T')
                  .at(1)}`
              : undefined
        }
      }

      dispatch(
        assignAndScheduleProjectUserToTicket(
          projectId,
          ticketDetailsFromApi?.ticket_details?.id,
          assignNScheduleBody,
          () => {
            onCloseModal()
            setScheduleModal(false)
            dispatch(
              getSiteVisitTicketList(projectId, siteVisitId, 1, 100, () => {})
            )
          }
        )
      )
    } else {
      if (!!selectedEmployeeDetails) {
        dispatch(
          updateScheduleTimeOfEmployee(
            projectId,
            selectedEmployeeDetails?.employe_details?.ticket_id,
            selectedEmployeeDetails?.employe_details?.id,
            body,
            () => {
              onCloseModal()
              setScheduleModal(false)

              dispatch(
                getSiteVisitTicketList(projectId, siteVisitId, 1, 100, () => {})
              )
            }
          )
        )
      } else {
        if (!!data?.selectedStaff?.id) {
          if (
            body?.scheduledFrom?.length > 0 &&
            body?.scheduledTo?.length > 0
          ) {
            dispatch(
              scheduleAssignedEmployee(
                projectId,
                ticketDetailsFromApi?.ticket_details?.id,
                data?.selectedStaff?.id,
                body,
                () => {
                  setScheduleModal(false)
                  onCloseModal()
                  dispatch(
                    getSiteVisitTicketList(
                      projectId,
                      siteVisitId,
                      1,
                      100,
                      () => {}
                    )
                  )
                }
              )
            )
          } else {
            return toast.error('Please fill the schedule date and time !!')
          }
        } else {
          return toast.error('Please select the staff to assign !!')
        }
      }
    }
  }

  useEffect(() => {
    const startDate = `${moment(data?.startDate).format('YYYY/MM/DD')} ${moment(
      data?.startTime
    ).format('h:mm:ss a')}`

    const updatedStartedDate = moment(startDate)

    const endDate = `${moment(data?.endDate).format('YYYY/MM/DD')} ${moment(
      data?.endTime
    ).format('h:mm:ss a')}`

    const updatedEndDate = moment(endDate)

    const scheduledDuration = updatedStartedDate.diff(updatedEndDate, 'days')

    if (
      !!data?.endDate &&
      !!data?.startDate &&
      !!data?.endTime &&
      !!data?.startTime
    ) {
      setScheduleDuration(Math.abs(scheduledDuration))
    }
  }, [data?.endDate, data?.startDate, data?.endTime, data?.startTime])

  useEffect(() => {
    !!selectedEmployeeId &&
      setValue('selectedStaff', {
        id: selectedEmployeeId?.employe_details?.id,
        label: selectedEmployeeId?.user_details?.display_name,
        value: selectedEmployeeId?.user_details?.id
      })

    if (!!selectedEmployeeDetails) {
      setValue(
        'startDate',
        selectedEmployeeDetails?.employe_details?.scheduled_from
      )

      setValue(
        'endDate',
        selectedEmployeeDetails?.employe_details?.scheduled_to
      )

      setValue(
        'startTime',
        selectedEmployeeDetails?.employe_details?.scheduled_from
      )

      setValue(
        'endTime',
        selectedEmployeeDetails?.employe_details?.scheduled_to
      )

      !!selectedEmployeeDetails?.employe_details?.id &&
        setValue('selectedStaff', {
          id: selectedEmployeeDetails?.employe_details?.id,
          label: selectedEmployeeDetails?.user_details?.display_name,
          value: selectedEmployeeDetails?.user_details?.id
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployeeId, selectedEmployeeDetails])

  const onCloseModal = () => {
    clear()
    setValue('selectedStaff', undefined)
    setValue('startDate', undefined)
    setValue('endDate', undefined)
    setValue('startTime', undefined)
    setValue('endTime', undefined)
    setSelectedEmployeeId?.(undefined)
    setSelectedAssignedEmployee?.(undefined)
    setScheduleDuration(undefined)

    dispatch(getSiteVisitTicketList(projectId, siteVisitId, 1, 10, () => {}))
  }

  return (
    <Modal
      id={modalId}
      title={!!selectedEmployeeDetails ? 'Update Event' : 'New Event'}
      onClose={onCloseModal}
      isActive={scheduleModal}
      toggleModal={setScheduleModal}
      size="sm"
    >
      <div className="flex flex-col p-24 gap-20">
        <div className="">
          <PlanTicketCart ticketDetails={ticketDetails} />
        </div>
        <div className="flex flex-col gap-6">
          <div className="text-md font-bold text-black">Schedule Date</div>
          <div className="flex flex-row gap-10 items-center flex-wrap">
            <div className="flex relative flex-column gap-4 mt-30">
              <div className="absolute top-[-25px] left-0">Date</div>
              <div className="flex flex-row items-center border-1   border-gray-200 ">
                <ReactDatePicker
                  selected={
                    data?.startDate ? new Date(data?.startDate) : undefined
                  }
                  onChange={(date: Date) =>
                    setValue('startDate', moment(date).toISOString())
                  }
                  className="py-6 pl-4 w-96 rounded-sm"
                  placeholderText="Start Date"
                />
              </div>

              <div className="absolute top-[-25px] right-[67px]">Time</div>
              <div className="flex flex-row items-center border-1 border-gray-200 px">
                <ReactDatePicker
                  selected={
                    data?.startTime ? new Date(data?.startTime) : undefined
                  }
                  onChange={(date: any) =>
                    setValue('startTime', moment(date).toISOString())
                  }
                  className="py-6 pl-4 w-96 rounded-sm"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="Start Time"
                />
              </div>
            </div>
            <div className="mx-20 pt-20">to</div>
            <div className="flex relative flex-column gap-4 mt-30">
              <div className="absolute top-[-25px] left-0">Date</div>
              <div className="flex flex-row items-center border-1 border-gray-200 ">
                <ReactDatePicker
                  selected={data?.endDate ? new Date(data?.endDate) : undefined}
                  onChange={(date: Date) =>
                    setValue('endDate', moment(date).toISOString())
                  }
                  className="py-6 pl-4 w-96 rounded-sm"
                  placeholderText="End Date"
                  minDate={new Date(data?.startDate)}
                />
              </div>

              <div className="absolute top-[-25px] right-[67px]">Time</div>
              <div className="flex flex-row items-center border-1 border-gray-200 px">
                <ReactDatePicker
                  selected={data?.endTime ? new Date(data?.endTime) : undefined}
                  onChange={(date: any) =>
                    setValue('endTime', moment(date).toISOString())
                  }
                  className="py-6 pl-4 w-96 rounded-sm"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="End Time"
                />
              </div>
            </div>
          </div>
          {!!scheduleDuration && (
            <div className="text-sm italic text-gray-400">
              Duration: {Number(scheduleDuration)} Days
            </div>
          )}
        </div>
        <div className="flex flex-col gap-8">
          <div className="text-md font-bold text-black">Assigned Staff</div>
          <div className="w-full">
            {!!!selectedEmployeeDetails && !!!selectedEmployeeId && (
              <DynamicSearchSelect
                actionUrl={`/projects/${projectId}/tickets/${ticketDetails?.ticket_details?.id}/unassigned-users`}
                value={
                  data?.selectedStaff?.id ? data?.selectedStaff : undefined
                }
                remapOptions={(
                  data: Api.TicketAssignedEmployeeIndividual[]
                ) => {
                  return data?.map(({user_details}) => {
                    return {
                      id: user_details?.id,
                      label: user_details?.display_name,
                      value: user_details?.id
                    }
                  })
                }}
                onChangeValue={(data) => {
                  setValue('selectedStaff', data)
                }}
                isDisabled={
                  !!selectedEmployeeId || !!selectedEmployeeDetails
                    ? true
                    : false
                }
              />
            )}
          </div>
          <div className="flex gap-10 w-[max-content]">
            {!!data?.selectedStaff && !!data?.selectedStaff?.id && (
              <ToolTip text={data?.selectedStaff?.label}>
                <div className="relative w-[55px] h-[55px] flex flex-row rounded-full justify-center items-center bg-blue-300">
                  <span className="text-white font-bold text-md">
                    {data?.selectedStaff?.label?.at(0)?.toUpperCase()}
                  </span>
                </div>
              </ToolTip>
            )}
          </div>
        </div>
      </div>
      <div className="border-t-1 border-gray-200 flex flex-row-reverse px-24 py-14 gap-10">
        <Button
          title={
            !!!selectedEmployeeDetails && !!!selectedEmployeeId
              ? 'Assign and Schedule'
              : 'Schedule'
          }
          className="text-white font-bold"
          type="button"
          onClick={handleScheduledEvent}
          isDisabled={
            !!!data?.selectedStaff ||
            !!!data?.endDate ||
            !!!data?.endTime ||
            !!!data?.startDate ||
            !!!data?.startTime
          }
        />
        <DefaultButton
          title="Cancel"
          className="text-blue-400 bg-blue-100 hover:bg-blue-150"
          type="button"
          onClick={() => {
            setScheduleModal(false)
            onCloseModal()
          }}
        />
      </div>
    </Modal>
  )
}
