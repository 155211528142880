import React from 'react'
import {useFormInput} from 'use-form-input'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {useSelector, useDispatch} from 'react-redux'

import {Logo, Input, Loading} from 'app/common'
import {DownloadApk, LandingSection, LoginDownloadInfo} from 'app/components'
import {login} from 'redux-src'
import {emailValidation} from 'utils'
import {useMedia} from 'hooks'

export const LoginPage = () => {
  const {handleLogin} = useAuth()

  const dispatch = useDispatch()
  const {loading, error} = useSelector((state: any) => state.login)

  const {navigation} = useNavigation()
  const {routes, navigate} = navigation

  const {lg} = useMedia()

  const [data, {onChange, errors, onSubmit, modified}] = useFormInput(
    {
      email: '',
      password: ''
    },
    (data) => {
      dispatch(login(data, handleLogin))
    },
    (data) => {
      const errors: any = {}

      if (data.email.length === 0) {
        errors.email = 'Empty email address!'
      } else if (emailValidation(data.email)) {
        errors.email = 'Enter a valid email address!'
      }

      if (data.password.length === 0) {
        errors.password = 'Empty password!'
      }

      return errors
    }
  )

  return (
    <div className="min-h-screen bg-white flex flex-row w-full">
      <div className="flex flex-col items-center w-full lg:w-5/12 exlg:w-4/12 xl:w-3/12 justify-center py-12 px-4 lg:flex-none h-auto overflow-x-hidden overflow-y-auto">
        {loading ? (
          <Loading />
        ) : (
          <>
            {lg && (
              <div className="lg:px-40 w-full">
                <div className="mb-32 text-center">
                  <div className="flex justify-center">
                    <Logo />
                  </div>
                  <h2 className="mt-6 text-2xl font-bold  text-gray-900">
                    Sign in to your account
                  </h2>
                </div>

                <div className="mt-8 sm:h-[30vh]">
                  <div className="mt-6">
                    {error && (
                      <p className="bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                        {error}
                      </p>
                    )}
                    <form className="lg:-mx-16 mb-24 " onSubmit={onSubmit}>
                      <Input
                        label="Email"
                        type="text"
                        name="email"
                        placeholder="Enter email"
                        value={data.email}
                        onChange={onChange}
                        error={modified.email && errors.email}
                      />
                      <Input
                        label="Password"
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        value={data.password}
                        onChange={onChange}
                        error={modified.password && errors.password}
                      />

                      <div className="flex items-center justify-between pt-8">
                        <button
                          type="submit"
                          className="bg-blue-300 hover:bg-blue-400 py-10 px-20 lg:px-56 rounded-sm text-white font-medium"
                        >
                          Log In
                        </button>
                        <span
                          onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                            e.preventDefault()

                            navigate(routes['Forgot Password'].path)
                          }}
                          className="text-sm text-gray-400 hover:text-blue-300 font-medium cursor-pointer"
                        >
                          Forgot your password?
                        </span>
                      </div>
                    </form>
                    <span
                      onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                        e.preventDefault()
                        navigate(routes['Register'].path)
                      }}
                      className="w-[max-content] block text-gray-400 hover:text-blue-300 font-medium cursor-pointer"
                    >
                      Don't have an account?{' '}
                      <span className="text-sm font-bold uppercase text-blue-300 hover:text-blue-400">
                        Register
                      </span>
                    </span>
                  </div>
                  <div className="mt-10">
                    <DownloadApk />
                  </div>
                </div>
              </div>
            )}
            {!lg && <LoginDownloadInfo />}
          </>
        )}
      </div>
      {lg && <LandingSection />}
    </div>
  )
}
