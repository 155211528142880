import {
  GET_ACTIVE_TICKET_OF_COMPANY,
  GET_ACTIVE_USER_OF_COMPANY,
  GET_ASSIGNED_TICKET_FOR_CALENDAR,
  GET_CALENDAR_EVENT_LIST,
  GET_UNASSIGNED_TICKET_FOR_CALENDAR,
} from 'redux-src/actions'

const initialState: RT.CalendarReduxType = {
  isActiveTicketListLoading: false,
  isActiveTicketListError: false,
  activeTicketList: undefined,

  isActiveUserListLoading: false,
  isActiveUserListError: false,
  activeUserList: undefined,

  eventListForCalendar: undefined,
  isEventListForCalendarLoading: false,
  isEventListForCalendarError: false,

  isUnassignedTicketListError: false,
  isUnassignedTicketListLoading: false,
  unassignedTicketList: undefined,

  isAssignedTicketListError: false,
  isAssignedTicketListLoading: false,
  assignedTicketList: undefined,
}

export const calendarReducer = (
  state: RT.CalendarReduxType = initialState,
  action: {type: string; payload: any},
) => {
  const {payload, type} = action

  switch (type) {
    // * Get active ticket list for assigned and unassigned ticket list
    case GET_ACTIVE_TICKET_OF_COMPANY.LOADING:
      return {
        ...state,
        isActiveTicketListLoading: true,
        isActiveTicketListError: false,
      }
    case GET_ACTIVE_TICKET_OF_COMPANY.SUCCESS:
      return {
        ...state,
        isActiveTicketListLoading: false,
        isActiveTicketListError: false,
        activeTicketList: payload,
      }
    case GET_ACTIVE_TICKET_OF_COMPANY.ERROR:
      return {
        ...state,
        isActiveTicketListLoading: false,
        isActiveTicketListError: true,
      }

    // * Get company active user list
    case GET_ACTIVE_USER_OF_COMPANY.LOADING:
      return {
        ...state,
        isActiveUserListLoading: true,
        isActiveUserListError: false,
      }
    case GET_ACTIVE_USER_OF_COMPANY.SUCCESS:
      return {
        ...state,
        isActiveUserListLoading: false,
        isActiveUserListError: false,
        activeUserList: payload,
      }
    case GET_ACTIVE_USER_OF_COMPANY.ERROR:
      return {
        ...state,
        isActiveUserListLoading: false,
        isActiveUserListError: true,
      }

    case GET_CALENDAR_EVENT_LIST.LOADING:
      return {
        ...state,
        isEventListForCalendarLoading: true,
        isEventListForCalendarError: false,
      }
    case GET_CALENDAR_EVENT_LIST.SUCCESS:
      return {
        ...state,
        isEventListForCalendarLoading: false,
        isEventListForCalendarError: false,
        eventListForCalendar: payload,
      }
    case GET_CALENDAR_EVENT_LIST.ERROR:
      return {
        ...state,
        isEventListForCalendarLoading: false,
        isEventListForCalendarError: true,
      }

    case GET_UNASSIGNED_TICKET_FOR_CALENDAR.LOADING:
      return {
        ...state,
        isUnassignedTicketListLoading: true,
        isUnassignedTicketListError: false,
      }
    case GET_UNASSIGNED_TICKET_FOR_CALENDAR.SUCCESS:
      return {
        ...state,
        isUnassignedTicketListLoading: false,
        isUnassignedTicketListError: false,
        unassignedTicketList: payload,
      }
    case GET_UNASSIGNED_TICKET_FOR_CALENDAR.ERROR:
      return {
        ...state,
        isUnassignedTicketListLoading: false,
        isUnassignedTicketListError: true,
      }

    case GET_ASSIGNED_TICKET_FOR_CALENDAR.LOADING:
      return {
        ...state,
        isAssignedTicketListLoading: true,
        isAssignedTicketListError: false,
      }
    case GET_ASSIGNED_TICKET_FOR_CALENDAR.SUCCESS:
      return {
        ...state,
        isAssignedTicketListLoading: false,
        isAssignedTicketListError: false,
        assignedTicketList: payload,
      }
    case GET_ASSIGNED_TICKET_FOR_CALENDAR.ERROR:
      return {
        ...state,
        isAssignedTicketListLoading: false,
        isAssignedTicketListError: true,
      }

    default:
      return {...state}
  }
}
