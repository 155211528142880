import {
  ADD_ATTACHMENTS_TO_TICKET,
  ADD_ATTACHMENT_TO_LABOUR_ELEMENT,
  ADD_TICKET_FORM_FIELD_ATACHMENT,
  ASSIGN_AND_SCHEDULE_USER_TO_TICKET,
  ASSIGN_NEW_USER_TO_TICKET,
  ASSIGN_TICKET_TO_SITE_VISIT,
  CREATE_TICKETS,
  CREATE_TICKET_COMMENT,
  DOWNLOAD_TICKET_PLAN_PDF,
  GET_PROJECT_TICKET_LIST,
  GET_SCHEDULED_EMPLOYEE_OF_TICKET,
  GET_SITE_VISIT_TICKETLIST,
  GET_TICKET_ASSIGNED_EMPLOYEE_LIST,
  GET_TICKET_ATTACHMENT_LIST,
  GET_TICKET_DETAILS_BY_ID,
  GET_TICKET_LIST_BY_PROJECTPLAN_ID,
  GET_TICKET_LOG_LIST,
  GET_TICKET_UNASSIGNED_EMPLOYEE_LIST,
  GET_UNSCHEDULED_EMPLOYEE_OF_TICKET,
  GET_UN_ASSIGNED_TICKET_LIST,
  MAKE_LABOUR_ELEMENT_MANDATORY,
  MAKE_LABOUR_ELEMENT_TO_COMPLETE,
  MAKE_LABOUR_ELEMENT_TO_INCOMPLETE,
  REMOVE_ASSIGNED_USER_FROM_TICKET,
  REMOVE_ATTACHMENTS_LABOUR_ELEMENT,
  REMOVE_LABOUR_ELEMENT_MANDATORY_STATUS,
  REMOVE_TICKET,
  REMOVE_TICKET_ATTACHMENTS,
  REMOVE_TICKET_FROM_FORM_FIELD,
  SCHEDULE_ASSIGNED_EMPLOYEE,
  SET_MARKER_COORDINATES,
  UNASSIGN_TICKET_TO_SITE_VISIT,
  TICKET_FEEDBACK,
  UNSCHEDULE_ASSIGNED_EMPLOYEE,
  UPDATE_SCHEDULE_TIME_OF_EMPLOYEE,
  UPDATE_TICKET,
  GET_LABOUR_ELEMENTS_OF_TICKET,
  CREATE_SUB_TICKET,
  MAKE_SUB_TICKET_LABOUR_COMPLETE,
  UPDATE_SUB_TICKET,
  GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION,
  REMOVE_SUB_TICKET,
  ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET,
  DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET,
  CREATE_DAYS_WORK_TICKET,
  GET_LABOUR_ELEMENTS_OF_SUB_TICKET,
  GET_LABOUR_ELEMENTS_OF_PARENT_TICKET,
  GET_PARENT_TICKET_DETAILS,
  GET_TICKET_LIST_BY_PROJECT_FORM_ID,
  REMOVE_DAYS_WORK_TICKET,
} from './actionTypes.action'

import {toast} from 'app/common'

import {api, APIS} from 'config'
import {getParsedUrl} from 'helpers'
import {errorHandler} from 'utils'

//MARK: Create Tickets

export function createTickets(
  projectId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_TICKETS.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets`,
        'POST',
        body,
      )

      const {success, data} = res.data

      if (success) {
        dispatch({type: CREATE_TICKETS.SUCCESS})

        successCallback?.()
        toast.success(data.message)
        return 1
      } else {
        dispatch({type: CREATE_TICKETS.ERROR, payload: data?.message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_TICKETS.ERROR})
      toast.error('Error creating tickets!')
      return 0
    }
  }
}

export function getSiteVisitTicketList(
  projectId: number,
  siteVisitId: number,
  page: number,
  limit: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SITE_VISIT_TICKETLIST.LOADING})
      res = await api<Api.Base<Api.SiteVisitTicketList>>(
        `${APIS.projects}/${projectId}/tickets/site-visits/${siteVisitId}?page=${page}&limit=${limit}`,
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_SITE_VISIT_TICKETLIST.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_SITE_VISIT_TICKETLIST.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_SITE_VISIT_TICKETLIST.ERROR})
      return 0
    }
  }
}

export function getTicketListByProjectPlanId(
  projectId: number,
  projectPlanId: number,
  page: number = 1,
  tableLimit: number = 10,
  successCallback?: (isLast?: boolean) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TICKET_LIST_BY_PROJECTPLAN_ID.LOADING})
      res = await api<Api.Base<Api.TicketListByProjectPlan>>(
        `${APIS.projects}/${projectId}/tickets/plan/${projectPlanId}?page=${page}&limit=${tableLimit}&onlyParent=true`,
      )

      const {
        success,
        data: {
          data: {total, rows, isLast},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_TICKET_LIST_BY_PROJECTPLAN_ID.SUCCESS,
          payload: {total, rows, isLast},
        })

        successCallback?.(isLast)
        return 1
      } else {
        dispatch({
          type: GET_TICKET_LIST_BY_PROJECTPLAN_ID.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_TICKET_LIST_BY_PROJECTPLAN_ID.ERROR})
      return 0
    }
  }
}

export function getTicketDetailsById(
  projectId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TICKET_DETAILS_BY_ID.LOADING})

      res = await api<Api.Base<Api.TicketDetailsIndividual>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: GET_TICKET_DETAILS_BY_ID.SUCCESS, payload: data})
        successCallback?.()

        return 1
      } else {
        dispatch({type: GET_TICKET_DETAILS_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_TICKET_DETAILS_BY_ID.ERROR})
      return 0
    }
  }
}

export function updateTicketActions(
  projectId: number,
  ticketId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_TICKET.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}`,
        'PATCH',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_TICKET.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_TICKET.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_TICKET.ERROR})
      return 0
    }
  }
}

export function addAttachmentsToTicket(
  projectId: number,
  ticketId: number,
  body: FormData,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_ATTACHMENTS_TO_TICKET.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/attachments`,
        'PUT',
        body,
      )

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({type: ADD_ATTACHMENTS_TO_TICKET.SUCCESS, payload: data})
        toast.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({type: ADD_ATTACHMENTS_TO_TICKET.ERROR})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_ATTACHMENTS_TO_TICKET.ERROR})
      toast.error('Error attaching to ticket!')
      return 0
    }
  }
}

export function removeTicketAttachments(
  projectId: number,
  ticketId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_TICKET_ATTACHMENTS.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/attachments`,
        'DELETE',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: REMOVE_TICKET_ATTACHMENTS.SUCCESS, payload: data})

        successCallback?.()
        toast?.success(message)
        return 1
      } else {
        dispatch({type: REMOVE_TICKET_ATTACHMENTS.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_TICKET_ATTACHMENTS.ERROR})
      return 0
    }
  }
}

export function getTicketAttachmentList(
  projectId: number,
  ticketId: number,
  params: {
    fileType?: 'video' | 'image' | 'document'
    isCompleted?: boolean
    isMandatory?: boolean
    labourElements?: boolean
    labourElementId?: number
    page?: number
    limit?: number
  } = {page: 1, limit: 100},
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TICKET_ATTACHMENT_LIST.LOADING})
      res = await api<Api.Base<Api.TIcketAttachmentList>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/tickets/${ticketId}/attachments`,
          params,
        ),
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_TICKET_ATTACHMENT_LIST.SUCCESS,
          payload: {total, rows},
        })
        successCallback?.()
        // toast?.success(message)
        return 1
      } else {
        dispatch({type: GET_TICKET_ATTACHMENT_LIST.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_TICKET_ATTACHMENT_LIST.ERROR})
      return 0
    }
  }
}

export function removeTicketAction(
  projectId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_TICKET.LOADING})

      res = await api<any>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}`,
        'DELETE',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: REMOVE_TICKET.SUCCESS, payload: data})
        successCallback?.()
        toast?.success(message)
        return 1
      } else {
        dispatch({type: REMOVE_TICKET.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_TICKET.ERROR})
      return 0
    }
  }
}

export function getProjectTicketList(
  projectId: number,
  params: {page: number; limit: number; status?: string} = {page: 1, limit: 10},
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_TICKET_LIST.LOADING})
      res = await api<Api.Base<Api.ProjectTicketList>>(
        getParsedUrl(`${APIS.projects}/${projectId}/tickets`, params),
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_TICKET_LIST.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_PROJECT_TICKET_LIST.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_TICKET_LIST.ERROR})
      return 0
    }
  }
}

//MARK: LabourElements

export function addAttachmentToLabourElement(
  projectId: number,
  ticketId: number,
  ticketLabourElementId: number,
  body: any,
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_ATTACHMENT_TO_LABOUR_ELEMENT.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/labour-elements/${ticketLabourElementId}/attachments`,
        'PUT',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: ADD_ATTACHMENT_TO_LABOUR_ELEMENT.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: ADD_ATTACHMENT_TO_LABOUR_ELEMENT.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_ATTACHMENT_TO_LABOUR_ELEMENT.ERROR})
      return 0
    }
  }
}

export function removeAtachmentLabourElement(
  projectId: number,
  ticketId: number,
  ticketLabourElementId: number,
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_ATTACHMENTS_LABOUR_ELEMENT.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/labour-elements/${ticketLabourElementId}/attachments`,
        'DELETE',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: REMOVE_ATTACHMENTS_LABOUR_ELEMENT.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: REMOVE_ATTACHMENTS_LABOUR_ELEMENT.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_ATTACHMENTS_LABOUR_ELEMENT.ERROR})
      return 0
    }
  }
}

export function makeLabourElementToComplete(
  projectId: number,
  ticketId: number,
  ticketLabourElementId: number,
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MAKE_LABOUR_ELEMENT_TO_COMPLETE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/labour-elements/${ticketLabourElementId}/complete`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: MAKE_LABOUR_ELEMENT_TO_COMPLETE.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: MAKE_LABOUR_ELEMENT_TO_COMPLETE.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: MAKE_LABOUR_ELEMENT_TO_COMPLETE.ERROR})
      return 0
    }
  }
}

export function makeLabourElementToInComplete(
  projectId: number,
  ticketId: number,
  ticketLabourElementId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MAKE_LABOUR_ELEMENT_TO_INCOMPLETE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/labour-elements/${ticketLabourElementId}/incomplete`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: MAKE_LABOUR_ELEMENT_TO_INCOMPLETE.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: MAKE_LABOUR_ELEMENT_TO_INCOMPLETE.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: MAKE_LABOUR_ELEMENT_TO_INCOMPLETE.ERROR})
      return 0
    }
  }
}

export function removeLabourElementMandatoryStatus(
  projectId: number,
  ticketId: number,
  ticketLabourElementId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_LABOUR_ELEMENT_MANDATORY_STATUS.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/labour-elements/${ticketLabourElementId}/remove-mandatory`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: REMOVE_LABOUR_ELEMENT_MANDATORY_STATUS.SUCCESS,
          payload: data,
        })

        successCallback?.()

        toast.success(message)
        return 1
      } else {
        dispatch({
          type: REMOVE_LABOUR_ELEMENT_MANDATORY_STATUS.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_LABOUR_ELEMENT_MANDATORY_STATUS.ERROR})
      return 0
    }
  }
}

export function makeLabourElementMandatory(
  projectId: number,
  ticketId: number,
  ticketLabourElementId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MAKE_LABOUR_ELEMENT_MANDATORY.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/labour-elements/${ticketLabourElementId}/make-mandatory`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: MAKE_LABOUR_ELEMENT_MANDATORY.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)

        return 1
      } else {
        dispatch({
          type: MAKE_LABOUR_ELEMENT_MANDATORY.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: MAKE_LABOUR_ELEMENT_MANDATORY.ERROR})
      return 0
    }
  }
}

//MARK: FORM_FILDS

export function addTicketFormFieldAtachment(
  projectId: number,
  ticketId: number,
  formFieldId: number,
  body: any,
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_TICKET_FORM_FIELD_ATACHMENT.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/form-fields/${formFieldId}/attachments`,
        'POST',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: ADD_TICKET_FORM_FIELD_ATACHMENT.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: ADD_TICKET_FORM_FIELD_ATACHMENT.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_TICKET_FORM_FIELD_ATACHMENT.ERROR})
      return 0
    }
  }
}

export function removeTicketFromFormField(
  projectId: number,
  ticketId: number,
  formFieldId: number,
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_TICKET_FROM_FORM_FIELD.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/form-fields/${formFieldId}/attachments`,
        'DELETE',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: REMOVE_TICKET_FROM_FORM_FIELD.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: REMOVE_TICKET_FROM_FORM_FIELD.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_TICKET_FROM_FORM_FIELD.ERROR})
      return 0
    }
  }
}

//MARK: ASSIGNED EMPLOYEES

export function assignNewUserToTicket(
  projectId: number,
  ticketId: number,
  body: any,
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ASSIGN_NEW_USER_TO_TICKET.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees`,
        'POST',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: ASSIGN_NEW_USER_TO_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: ASSIGN_NEW_USER_TO_TICKET.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: ASSIGN_NEW_USER_TO_TICKET.ERROR})
      return 0
    }
  }
}

export function removeAssignedEmployeeFromTicket(
  projectId: number,
  ticketId: number,
  body: any,
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_ASSIGNED_USER_FROM_TICKET.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees`,
        'DELETE',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        toast.success(message)
        dispatch({
          type: REMOVE_ASSIGNED_USER_FROM_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: REMOVE_ASSIGNED_USER_FROM_TICKET.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_ASSIGNED_USER_FROM_TICKET.ERROR})
      return 0
    }
  }
}

export function scheduleAssignedEmployee(
  projectId: number,
  ticketId: number,
  assignedEmployeeId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: SCHEDULE_ASSIGNED_EMPLOYEE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees/${assignedEmployeeId}/schedule`,
        'POST',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: SCHEDULE_ASSIGNED_EMPLOYEE.SUCCESS,
          payload: data,
        })
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: SCHEDULE_ASSIGNED_EMPLOYEE.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: SCHEDULE_ASSIGNED_EMPLOYEE.ERROR})
      return 0
    }
  }
}

export function unScheduledAssignedEmployee(
  projectId: number,
  ticketId: number,
  assignedEmployeeId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UNSCHEDULE_ASSIGNED_EMPLOYEE.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees/${assignedEmployeeId}/unschedule`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        toast.success(message)
        dispatch({
          type: UNSCHEDULE_ASSIGNED_EMPLOYEE.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: UNSCHEDULE_ASSIGNED_EMPLOYEE.ERROR,
          payload: message,
        })
        toast.error(res?.data?.message ?? message)
        return 0
      }
    } catch (e: any) {
      dispatch({type: UNSCHEDULE_ASSIGNED_EMPLOYEE.ERROR})
      toast.error(e.message)

      return 0
    }
  }
}

export function updateScheduleTimeOfEmployee(
  projectId: number,
  ticketId: number,
  assignedEmployeeId: number,
  body: any,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_SCHEDULE_TIME_OF_EMPLOYEE.LOADING})
      res = await api<Api.Base<any[]>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees/${assignedEmployeeId}/schedule`,
        'PATCH',
        body,
      )

      if (res?.data !== undefined) {
        const {success, data} = res.data

        if (success) {
          toast.success(data?.message)
          dispatch({
            type: UPDATE_SCHEDULE_TIME_OF_EMPLOYEE.SUCCESS,
            payload: data?.data,
          })
          successCallback?.()

          return 1
        } else {
          throw new Error(data?.data?.[0]?.message ?? res?.data?.message)
        }
      } else {
        throw new Error(
          res?.data?.message ??
            'Something went wrong while updating schedule time of employee',
        )
      }
    } catch (e: any) {
      toast?.error(
        !!e.message
          ? e.message
          : 'Something went wrong while updating schedule time of employee',
      )
      dispatch({type: UPDATE_SCHEDULE_TIME_OF_EMPLOYEE.ERROR})
      onErrorCallback?.()
      return 0
    }
  }
}

export function getTicketAssignedEmployeeList(
  projectId: number,
  ticketId: number,
  page: number,
  limit: number,
  search: string = '',
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TICKET_ASSIGNED_EMPLOYEE_LIST.LOADING})
      res = await api<Api.Base<Api.TicketAssignedEmployeeList>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees?page=${page}&limit=${limit}&search=${search}`,
        'GET',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_TICKET_ASSIGNED_EMPLOYEE_LIST.SUCCESS,
          payload: data,
        })
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_TICKET_ASSIGNED_EMPLOYEE_LIST.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_TICKET_ASSIGNED_EMPLOYEE_LIST.ERROR})
      return 0
    }
  }
}

export function assignAndScheduleProjectUserToTicket(
  projectId: number,
  ticketId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ASSIGN_AND_SCHEDULE_USER_TO_TICKET.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees/assign-and-schedule`,
        'POST',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: ASSIGN_AND_SCHEDULE_USER_TO_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: ASSIGN_AND_SCHEDULE_USER_TO_TICKET.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: ASSIGN_AND_SCHEDULE_USER_TO_TICKET.ERROR})
      return 0
    }
  }
}

//MARK: getTicketLogLists

export function getTicketLogListAction(
  page: number = 1,
  limit: number = 10,
  projectId: number,
  projectTicketId: number,
  order: 'desc' | 'asc',
  type?: string,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TICKET_LOG_LIST.LOADING})
      res = await api<Api.Base<Api.TicketLogList>>(
        `${
          APIS.projects
        }/${projectId}/tickets/${projectTicketId}/logs?order=${order}${
          !!type ? `&type=${type}` : ''
        }&page=${page}&limit=${limit}`,
      )

      const {
        success,
        data: {
          data: {total, rows, isLast},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_TICKET_LOG_LIST.SUCCESS,
          payload: {total, rows, isLast},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_TICKET_LOG_LIST.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_TICKET_LOG_LIST.ERROR})
      return 0
    }
  }
}

export function createTicketCommentAction(
  projectId: number,
  ticketId: number,
  body: any,
  successCallback: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_TICKET_COMMENT.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/logs`,
        'POST',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: CREATE_TICKET_COMMENT.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CREATE_TICKET_COMMENT.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_TICKET_COMMENT.ERROR})
      return 0
    }
  }
}

export function ticketFeedbackAction(
  projectId: number,
  ticketId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: TICKET_FEEDBACK.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/feedback`,
        'PUT',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: TICKET_FEEDBACK.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: TICKET_FEEDBACK.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: TICKET_FEEDBACK.ERROR})
      return 0
    }
  }
}

//MARK: DOWNLOAD TICKET PLAN PDF

export const downloadPlanWithTicketAction = (
  projectId: number,
  projectPlanId: number,
  successCallback?: (link: string) => void,
  errorCallback?: (err: string) => void,
) => {
  return async function (dispatch: any) {
    let res

    try {
      dispatch({type: DOWNLOAD_TICKET_PLAN_PDF})

      res = await api<Api.Base<{downloadLink?: string}>>(
        `${APIS.projects}/${projectId}/plans/${projectPlanId}/pdf-tickets`,
      )

      const {
        data: {data},
        message: errMsg,
        success,
      } = res.data

      if (res.data !== undefined && success) {
        dispatch({type: DOWNLOAD_TICKET_PLAN_PDF, payload: data})

        successCallback?.(data.downloadLink)
      } else {
        dispatch({type: DOWNLOAD_TICKET_PLAN_PDF})
        errorCallback?.(errMsg)
      }
    } catch (message) {
      dispatch({type: DOWNLOAD_TICKET_PLAN_PDF, payload: message})
      toast.error(String(message))
    }
  }
}

//MARK: MARKER COORDINATE

export function setMarkerCoordinateAction(
  markerCoordinates?: {x: number; y: number},
  reportLayerCoordinates?: {x: number; y: number},
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    dispatch({
      type: SET_MARKER_COORDINATES.SUCCESS,
      payload: {markerCoordinates, reportLayerCoordinates},
    })
  }
}

//MARK: assign Ticket to site visit

export function assignTicketToSiteVisit(
  projectId: number,
  sitevisitId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ASSIGN_TICKET_TO_SITE_VISIT.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/site-visits/${sitevisitId}/assign`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: ASSIGN_TICKET_TO_SITE_VISIT.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: ASSIGN_TICKET_TO_SITE_VISIT.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: ASSIGN_TICKET_TO_SITE_VISIT.ERROR})
      return 0
    }
  }
}

export function unAssignTicketToSiteVisit(
  projectId: number,
  sitevisitId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UNASSIGN_TICKET_TO_SITE_VISIT.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/site-visits/${sitevisitId}/unassign`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: UNASSIGN_TICKET_TO_SITE_VISIT.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: UNASSIGN_TICKET_TO_SITE_VISIT.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: UNASSIGN_TICKET_TO_SITE_VISIT.ERROR})
      return 0
    }
  }
}

//MARK: GET UN-ASSIGNED TICKET LIST

export function getUnAssignedTicketList(
  projectId: number,
  page: number = 1,
  limit: number = 10,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_UN_ASSIGNED_TICKET_LIST.LOADING})
      res = await api<Api.Base<Api.SiteVisitTicketList>>(
        `${APIS.projects}/${projectId}/tickets/unassigned?page=${page}&limit=${limit}`,
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_UN_ASSIGNED_TICKET_LIST.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_UN_ASSIGNED_TICKET_LIST.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_UN_ASSIGNED_TICKET_LIST.ERROR})
      return 0
    }
  }
}

//MARK: get scheduledEmployeeOf Ticket

export function getScheduledEmployeeOfTicket(
  projectId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_SCHEDULED_EMPLOYEE_OF_TICKET.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees/scheduled`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_SCHEDULED_EMPLOYEE_OF_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_SCHEDULED_EMPLOYEE_OF_TICKET.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_SCHEDULED_EMPLOYEE_OF_TICKET.ERROR})
      return 0
    }
  }
}

//MARK: get un scheduledEmployeeOf Ticket

export function getUnScheduledEmployeeOfTicket(
  projectId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_UNSCHEDULED_EMPLOYEE_OF_TICKET.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/assigned-employees/unscheduled`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_UNSCHEDULED_EMPLOYEE_OF_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_UNSCHEDULED_EMPLOYEE_OF_TICKET.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_UNSCHEDULED_EMPLOYEE_OF_TICKET.ERROR})
      return 0
    }
  }
}

export function getUnassignedEmployeeList(
  projectId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TICKET_UNASSIGNED_EMPLOYEE_LIST.LOADING})
      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/unassigned-users`,
        'GET',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_TICKET_UNASSIGNED_EMPLOYEE_LIST.SUCCESS,
          payload: data,
        })
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_TICKET_UNASSIGNED_EMPLOYEE_LIST.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_TICKET_UNASSIGNED_EMPLOYEE_LIST.ERROR})
      return 0
    }
  }
}

//MARK: getAll labourElements of ticket

export function getLabourElementsOfTickets(
  projectId: number,
  ticketId: number,
  params?: {
    attachedToSubticket?: boolean
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ELEMENTS_OF_TICKET.LOADING})
      res = await api<Api.Base<Array<Api.LabourElementsOfTicketDetails>>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/tickets/${ticketId}/labour-elements`,
          params,
        ),
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ELEMENTS_OF_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_LABOUR_ELEMENTS_OF_TICKET.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ELEMENTS_OF_TICKET.ERROR})
      return 0
    }
  }
}

//MARK: Create SubTickets

export function createSubTicket(
  projectId: number,
  ticketId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_SUB_TICKET.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/sub-tickets`,
        'POST',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_SUB_TICKET.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_SUB_TICKET.ERROR, payload: message})
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_SUB_TICKET.ERROR})
      toast.error('Error creating tickets!')
      return 0
    }
  }
}

//MARK: Make labour Elements of sub ticket complete

export function makeSubTicketLabourCompleteAction(
  projectId: number,
  ticketId: number,
  subTicketId: number,
  ticketLabourElementId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: MAKE_SUB_TICKET_LABOUR_COMPLETE.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/sub-tickets/${subTicketId}/labour-elements/${ticketLabourElementId}/complete`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: MAKE_SUB_TICKET_LABOUR_COMPLETE.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: MAKE_SUB_TICKET_LABOUR_COMPLETE.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: MAKE_SUB_TICKET_LABOUR_COMPLETE.ERROR})
      toast.error('Error making sub tickets labour element complete!')
      return 0
    }
  }
}

//MARK: Update Sub-Tickets

export function updateSubTicket(
  projectId: number,
  ticketId: number,
  subTicketId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_SUB_TICKET.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/sub-tickets/${subTicketId}`,
        'PATCH',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_SUB_TICKET.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_SUB_TICKET.ERROR, payload: message})

        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_SUB_TICKET.ERROR})
      toast.error('Error updating sub tickets!')
      return 0
    }
  }
}

//MARK: GET ticket labour elements during sub ticket creation

export function getLabourElementListDuringSubTicketCreation(
  projectId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({
        type: GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION.LOADING,
      })

      res = await api<Api.Base<Api.TicketLabourElementList>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/sub-tickets/ticket-labour-elements`,
        'GET',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION.ERROR,
          payload: message,
        })

        return 0
      }
    } catch (e) {
      dispatch({
        type: GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION.ERROR,
      })
      toast.error(
        'Error getting labour element list during sub ticket creations!',
      )
      return 0
    }
  }
}

//MARK: Remove sub-ticket

export function removeSubTicket(
  projectId: number,
  ticketId: number,
  subTicketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_SUB_TICKET.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/sub-tickets/${subTicketId}`,
        'DELETE',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: REMOVE_SUB_TICKET.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: REMOVE_SUB_TICKET.ERROR, payload: message})

        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_SUB_TICKET.ERROR})
      toast.error('Error removing sub ticket!')
      return 0
    }
  }
}

//MARK: Attach labour element to sub-ticket

export function attachLabourElementToSubTicket(
  projectId: number,
  ticketId: number,
  subTicketId: number,
  labourElementId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/sub-tickets/${subTicketId}/labour-elements/${labourElementId}/attach`,
        'PUT',
      )

      const {
        success,
        data: {data, message},
        message: errMsg,
      } = res.data

      if (success) {
        dispatch({
          type: ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET.ERROR,
          payload: errMsg,
        })

        toast.error(errMsg)
        return 0
      }
    } catch (e) {
      dispatch({type: ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET.ERROR})
      toast.error('Error while attaching labour element to sub ticket!')
      return 0
    }
  }
}

//MARK: Deattach labour element to sub-ticket

export function deattachLabourElementToSubTicket(
  projectId: number,
  ticketId: number,
  subTicketId: number,
  labourElementId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/sub-tickets/${subTicketId}/labour-elements/${labourElementId}/detach`,
        'DELETE',
      )

      const {
        success,
        data: {data, message: successMsg},
        message,
      } = res.data

      if (success) {
        dispatch({
          type: DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(successMsg)
        return 1
      } else {
        dispatch({
          type: DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET.ERROR,
          payload: message,
        })

        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET.ERROR})
      toast.error('Error while detaching labour element from sub ticket!')
      return 0
    }
  }
}

//MARK: Create days work tickets

export function createDaysWorkTicket(
  projectId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_DAYS_WORK_TICKET.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/days-work/tickets`,
        'POST',
        body,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_DAYS_WORK_TICKET.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_DAYS_WORK_TICKET.ERROR, payload: message})
        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_DAYS_WORK_TICKET.ERROR})
      toast.error('Error creating days work!')
      return 0
    }
  }
}

//MARK: getAll labourElements of sub-ticket

export function getLabourElementsOfSubTickets(
  projectId: number,
  ticketId: number,
  subTicketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ELEMENTS_OF_SUB_TICKET.LOADING})
      res = await api<Api.Base<Array<Api.LabourElementsOfTicketDetails>>>(
        `${APIS.projects}/${projectId}/tickets/${ticketId}/sub-tickets/${subTicketId}/ticket-labour-elements`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ELEMENTS_OF_SUB_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_LABOUR_ELEMENTS_OF_SUB_TICKET.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ELEMENTS_OF_SUB_TICKET.ERROR})
      return 0
    }
  }
}

//MARK: getAll labourElements of sub-ticket

export function getLabourElementsOfParentTickets(
  projectId: number,
  ticketId: number,
  params?: {attachedToSubticket?: boolean},
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_LABOUR_ELEMENTS_OF_PARENT_TICKET.LOADING})
      res = await api<Api.Base<Array<Api.LabourElementsOfTicketDetails>>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/tickets/${ticketId}/labour-elements`,
          params,
        ),
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_LABOUR_ELEMENTS_OF_PARENT_TICKET.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_LABOUR_ELEMENTS_OF_PARENT_TICKET.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_LABOUR_ELEMENTS_OF_PARENT_TICKET.ERROR})
      return 0
    }
  }
}

export function getParentTicketDetails(details: {
  selectedLabourElements: any
  parentTicketDetails: Api.TicketDetailsIndividual['ticket_details']
}) {
  return function (dispatch: any) {
    if (!!details) {
      dispatch({type: GET_PARENT_TICKET_DETAILS.SUCCESS, payload: details})
    }
  }
}

//MARK: Ticket list by project form Id

export function getTicketListByFormId(
  projectId: number,
  projectFormId: number,
  params: {
    page: number
    limit: number
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_TICKET_LIST_BY_PROJECT_FORM_ID.LOADING})
      res = await api<Api.Base<Api.ProjectTicketListByFormIdList>>(
        getParsedUrl(
          `${APIS.projects}/${projectId}/tickets/project-form/${projectFormId}`,
          params,
        ),
      )
      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_TICKET_LIST_BY_PROJECT_FORM_ID.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()

        return 1
      } else {
        dispatch({type: GET_TICKET_LIST_BY_PROJECT_FORM_ID, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_TICKET_LIST_BY_PROJECT_FORM_ID.ERROR})
      return 0
    }
  }
}

//MARK: Remove days work ticket

export function removeDaysWorkTickets(
  projectId: number,
  ticketId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_DAYS_WORK_TICKET.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.projects}/${projectId}/days-work/tickets/${ticketId}`,
        'DELETE',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: REMOVE_DAYS_WORK_TICKET.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: REMOVE_DAYS_WORK_TICKET.ERROR, payload: message})

        toast.error(message)
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_DAYS_WORK_TICKET.ERROR})
      toast.error('Error removing sub ticket!')
      return 0
    }
  }
}
