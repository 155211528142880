import {useCallback, useEffect, useState} from 'react'
import {TableColumn} from 'react-data-table-component'
import {useNavigation} from 'react-auth-navigation'
import {useSelector, useDispatch} from 'react-redux'
import {AiFillEye} from 'react-icons/ai'
import {FaPen, FaTrash} from 'react-icons/fa'
import moment from 'moment'

import {DataTable} from 'app/components'
import {ConfirmationModal, ToolTip} from 'app/common'
import {deactivateFormTemplate, getFormLists} from 'redux-src'
import {FormProjectListModal} from '../formProjectListModal'

interface FormListType {
  status: 'draft' | 'published'
  templateName: string
  type: string
  dateCreated: string
  id: number
  isActive: boolean
  totalProjects: number
}

interface FormTemplateListProps {
  fetchFormList?: (page: number, limit: number) => void
}

export const FormTempleteList = ({fetchFormList}: FormTemplateListProps) => {
  const {formList, formListLoading, formListTotal}: RT.FormsReduxType =
    useSelector((state: any) => state.forms)

  const [newFormList, setNewFormList] = useState<Array<FormListType>>()
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [formTemplateId, setFormTemplateId] = useState<number>()

  const {
    navigation: {navigate}
  } = useNavigation()

  const dispatch = useDispatch()

  const remappedFormList = useCallback(() => {
    const finalFormList = formList?.map((form) => {
      return {
        id: form?.id,
        status: form?.status,
        templateName: form?.name,
        type: form?.status,
        dateCreated: form?.created_at,
        isActive: form?.is_active,
        totalProjects: form?.total_projects
      }
    })

    setNewFormList(finalFormList)
  }, [formList])

  const deactivateForm = (formId: number) => {
    dispatch(
      deactivateFormTemplate(formId, () => {
        dispatch(getFormLists())
      })
    )
  }

  useEffect(() => {
    remappedFormList()
  }, [remappedFormList])

  const columns: TableColumn<FormListType>[] = [
    {
      name: 'Status',
      selector: (row) => row?.status,
      sortable: true,
      cell: (row) => (
        <span className="mr-1 px-6 leading-none">
          {getStatusChip(row?.status, row.isActive)}
        </span>
      )
    },
    {
      name: 'Template Name',
      selector: (row) => row?.templateName,
      sortable: true,
      cell: (row) => (
        <span
          className="text-blue-300  py-4 mr-1 cursor-pointer px-6 leading-none "
          onClick={() =>
            navigate(
              `/settings/forms/edit/${row?.id}${
                row?.status === 'published' ? '?preview=true' : ''
              }`
            )
          }
        >
          {row?.templateName}
        </span>
      )
    },
    {
      name: 'No. of Projects',
      selector: (row) => row?.type,
      sortable: true,
      cell: (row) => (
        <div className="flex items-center justify-between  px-6 py-4  w-full">
          <span className="text-md font-bold">{row.totalProjects}</span>
          <ToolTip text="See all projects" top>
            <div
              className="px-6 py-4 rounded-sm hover:bg-blue-100 transition-all duration-150 ease-in-out cursor-pointer"
              onClick={() => {
                setModalVisible((prev) => !prev)
                setFormTemplateId(row.id)
              }}
            >
              <AiFillEye size={20} color="gray" />
            </div>
          </ToolTip>
        </div>
      )
    },
    {
      name: 'Date Created',
      selector: (row) => row?.dateCreated,
      sortable: true,
      cell: (row) => (
        <div className="flex items-center justify-between text-blue-300  py-4 mr-1 cursor-pointer  px-6 leading-none ">
          <div className="flex items-center justify-center">
            {moment(row.dateCreated).format('MMM, DD, YYYY')}
          </div>

          <div className="flex gap-8">
            <ToolTip text="Edit" top>
              <button
                className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
                onClick={() => {
                  navigate(`/settings/forms/edit/${row.id}`)
                }}
              >
                <span className="text-left inline-flex cursor-pointer">
                  <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
                </span>
              </button>
            </ToolTip>
            <ConfirmationModal
              onConfirmClick={(closeModalHandler) => {
                deactivateForm(row.id)
                closeModalHandler()
              }}
              danger
              label={'Are you sure you want to delete form template?'}
              displayElement={
                <ToolTip text="Delete" top>
                  <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </button>
                </ToolTip>
              }
              confirmLabel="Delete"
            />
          </div>
        </div>
      )
    }
  ]

  return (
    <div className="">
      <DataTable
        columns={columns}
        data={newFormList ?? []}
        fetchData={fetchFormList}
        totalRows={formListTotal}
        loading={formListLoading}
        pagination
      />
      <FormProjectListModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        {...{formTemplateId}}
      />
    </div>
  )
}

const getStatusChip = (status: 'draft' | 'published', isActive: boolean) => {
  switch (true) {
    case status === 'draft':
      return (
        <span className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-orange-400">
          {status.toUpperCase()}
        </span>
      )
    case status === 'published':
      return (
        <span className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-blue-300">
          {status.toUpperCase()}
        </span>
      )
    case !isActive:
      return (
        <span className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-gray-400">
          {status.toUpperCase()}
        </span>
      )
    default:
      return <span></span>
  }
}
