import React, {useEffect, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth, useNavigation} from 'react-auth-navigation'

import {InvoiceEditor} from 'app/components'
import {getCompanyDetails, getQuoteSettings, getQuotesAction} from 'redux-src'
import {QuoteVersion} from '../../components'

export const QuoteVersionPage = () => {
  const {companyDetails} = useAuth()

  const company = companyDetails as Api.CompanyDetails

  const dispatch = useDispatch()
  const {
    params,
    navigation: {routes},
  } = useNavigation()

  const {projectId} = params as any

  // Fetch quotes on beginning

  const refetchQuotes = useCallback(
    (page?: number, tableLimit?: number) => {
      projectId && dispatch(getQuotesAction(projectId, page, tableLimit))
    },
    [dispatch, projectId],
  )

  const {quotes, quotesLoading}: RT.QuoteReduxType = useSelector(
    (state: any) => state.quotes,
  )

  useEffect(() => {
    refetchQuotes()

    dispatch(getCompanyDetails())
  }, [dispatch, refetchQuotes])

  useEffect(() => {
    dispatch(getQuoteSettings(company?.company_details?.id, () => {}))
  }, [company?.company_details?.id, dispatch])

  useEffect(() => {
    const acceptedQuote = quotes?.filter(
      (quote) => quote?.quote_details?.status === 'accepted',
    )

    const currentActiveQuote =
      acceptedQuote?.length > 0 ? acceptedQuote?.at(0) : quotes?.at(0)

    const currentActiveQuoteId = currentActiveQuote?.quote_details?.id
    dispatch({
      type: 'SET_ACTIVE_QUOTE',
      payload: currentActiveQuoteId,
    })
  }, [dispatch, quotes])

  return (
    <div>
      <QuoteVersion getQuotes={refetchQuotes} />

      {!!quotes && routes['Quote']?.active && (
        <>
          {quotesLoading ? (
            'Loading...'
          ) : (
            <InvoiceEditor value="Quote" projectId={projectId} />
          )}
        </>
      )}
    </div>
  )
}
