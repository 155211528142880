/* eslint-disable no-constant-condition */
import {MouseEvent, useCallback, useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useFormInput} from 'use-form-input'
import {useDispatch, useSelector} from 'react-redux'
// import {CiBank} from 'react-icons/ci'
import {HiPlusSm} from 'react-icons/hi'

import {DescriptionBox} from 'app/components'
import {Button, CustomModal, Input, SelectField, ToolTip} from 'app/common'

import {getCompanyDetails, updateCompanyDetails} from 'redux-src'
import type {SelectOptionReduxType} from 'redux-src/reducers/selectOptions.reducer'

import {BankDetailModalHeader, CompanyBankDetails} from '../companyBankDetails'

export const GeneralSection = () => {
  const dispatch = useDispatch()

  const [isEdit, setEdit] = useState(false)

  const {companyDetails: company, toast} = useAuth()

  const companyDetails: Api.CompanyDetails = company

  const {
    selectOptions: {currencyList, timeZoneOptions, dateFormatOptions},
  }: SelectOptionReduxType = useSelector((state: any) => state.selectOption)

  const [accountSettings, {setValue, onChange, onSubmit, errors, modified}] =
    useFormInput(
      {
        taxNumber: 0,
        bankAccountNumber: 0,
        jobNumberPrefix: '',
        currency: '',
        dateFormat: '',
        timezone: '',
        startingJobNumber: 0,
        workingDaysPerWeek: 0,
        workingHourPerWeek: 0,
        sortCode: '',
        iban: '',
      },
      (data) => {
        dispatch(
          updateCompanyDetails(
            {
              taxNumber: data?.taxNumber,
              bankAccountNumber: data?.bankAccountNumber,
              jobNumberPrefix: data?.jobNumberPrefix,
              dateFormat: data?.dateFormat,
              startingJobNumber: data?.startingJobNumber,
              timezone: data?.timezone,
              workingDaysPerWeek: data?.workingDaysPerWeek,
              workingHrPerWeek: data?.workingHourPerWeek,
              sortCode: data?.sortCode,
              iban:
                !!data?.iban && data?.iban?.length > 0 ? data?.iban : undefined,
              // country: data?.country,
              // industry: data?.industry,
              // mobile: data?.mobile,
              // numberOfStaffs: data?.numberOfStaffs
            },
            () => {
              dispatch(getCompanyDetails())
              setEdit(false)
            },
            toast,
          ),
        )
      },
      (data) => {
        const errors: any = {}
        if (
          data.taxNumber === 0 ||
          data.bankAccountNumber === 0 ||
          data.jobNumberPrefix.length === 0 ||
          data.startingJobNumber === 0
        ) {
          errors.bankAccountNumber = 'Please fill up all the required fields!'
        }

        return errors
      },
    )

  const setCompanyDetails = useCallback(() => {
    setValue(
      'bankAccountNumber',
      companyDetails?.company_bank_details?.account_number ?? 0,
    )
    setValue('sortCode', companyDetails?.company_bank_details?.sort_code ?? '')
    setValue('iban', companyDetails?.company_bank_details?.iban ?? '')
    setValue('taxNumber', companyDetails?.company_details?.tax_number ?? 0)
    setValue(
      'jobNumberPrefix',
      companyDetails?.company_details?.job_number_prefix ?? '',
    )
    setValue('currency', companyDetails?.company_details?.currency ?? '')
    setValue('dateFormat', companyDetails?.company_details?.date_format ?? '')
    setValue('timezone', companyDetails?.company_details?.timezone ?? '')
    setValue(
      'startingJobNumber',
      companyDetails?.company_details?.starting_job_number ?? 0,
    )

    setValue(
      'workingDaysPerWeek',
      companyDetails?.company_details?.working_days_per_week,
    )

    setValue(
      'workingHourPerWeek',
      companyDetails?.company_details?.working_hr_per_week,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyDetails?.company_bank_details?.account_number,
    companyDetails?.company_bank_details?.iban,
    companyDetails?.company_bank_details?.sort_code,
    companyDetails?.company_details?.currency,
    companyDetails?.company_details?.date_format,
    companyDetails?.company_details?.job_number_prefix,
    companyDetails?.company_details?.starting_job_number,
    companyDetails?.company_details?.tax_number,
    companyDetails?.company_details?.timezone,
    companyDetails?.company_details?.working_days_per_week,
    companyDetails?.company_details?.working_hr_per_week,
  ])

  useEffect(() => {
    setCompanyDetails()
  }, [setCompanyDetails])

  const doSetEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setEdit(true)
  }

  return (
    <form onSubmit={onSubmit} id="general-settings">
      <DescriptionBox
        bodyClassName="p-0"
        title={
          <div className="flex items-center justify-between">
            <div className="font-bold text-base ml-4">
              <span className="">General</span>
            </div>
            <div className="text-sm">
              {!isEdit ? (
                <Button
                  title={'Edit Settings'}
                  size="xs"
                  buttonColor="bg-white text-black  hover:bg-gray-300 hover:text-white"
                  className="border-1 border-black hover:border-gray-300"
                  onClick={doSetEdit}
                />
              ) : (
                <Button
                  form="general-settings"
                  type="submit"
                  title={'Save Changes'}
                  size="xs"
                />
              )}
            </div>
          </div>
        }
      >
        <div className={!isEdit ? 'form-disable p-24' : 'p-24'}>
          <h3 className="font-bold text-md mb-16">Account Settings</h3>
          {modified.bankAccountNumber && errors.bankAccountNumber && (
            <p className="bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
              {modified.bankAccountNumber && errors.bankAccountNumber}
            </p>
          )}
          <table className="w-full mb-32">
            <tbody>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Company Registration Number:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    type="number"
                    name="taxNumber"
                    value={accountSettings.taxNumber}
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>

              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Bank Account Number:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    disabled
                    type="number"
                    name="bankAccountNumber"
                    value={companyDetails?.company_bank_details?.account_number}
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm "
                    // onChange={onChange}
                  />
                </td>
                <td className="flex justify-center items-center text-xl cursor-pointer pointer-events-auto">
                  <CustomModal
                    size="md"
                    title={<BankDetailModalHeader />}
                    displayElement={
                      <ToolTip text="Add Bank Detail" className="-mx-16 flex">
                        <HiPlusSm
                          className={`mx-6 mb-0 bg-blue-400 hover:bg-blue-500 rounded-sm text-white cursor-pointer`}
                          size={24}
                        />
                      </ToolTip>
                    }
                  >
                    {() => <CompanyBankDetails />}
                  </CustomModal>
                </td>
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">Currency:</div>
                </td>
                <td width="40%" className="px-[10%]">
                  <SelectField
                    isDisabled
                    getOptionValue={'key'}
                    getOptionLabel={'value'}
                    options={currencyList}
                    value={currencyList?.find((item) => {
                      return (
                        item.value ===
                        companyDetails?.company_bank_details?.currency
                      )
                    })}
                    placeholder="Select Currency"
                    // onChangeValue={(e) => {
                    //   setValue('currency', e?.value)
                    // }}
                    formatOptionLabel={(data) => {
                      return (
                        <div>
                          {data.symbol} {data.value}
                        </div>
                      )
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">Sort Code:</div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    name="sortCode"
                    value={accountSettings.sortCode}
                    type="text"
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    disabled
                  />
                </td>
                <td className="flex justify-center items-center"></td>
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">IBAN:</div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    name="iban"
                    value={accountSettings.iban}
                    type="text"
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                    disabled
                  />
                </td>
                <td className="flex justify-center items-center"></td>
              </tr>

              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Project Number Prefix:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    name="jobNumberPrefix"
                    value={accountSettings.jobNumberPrefix}
                    type="text"
                    size="sm"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>

              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Starting Project Number:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    name="startingJobNumber"
                    value={accountSettings.startingJobNumber}
                    size="sm"
                    type="number"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>

              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">Date Format:</div>
                </td>
                <td width="40%" className="px-[10%]">
                  <SelectField
                    getOptionValue={'key'}
                    getOptionLabel={'value'}
                    options={dateFormatOptions}
                    value={dateFormatOptions?.find((item) => {
                      return item.value === accountSettings.dateFormat
                    })}
                    placeholder="Select Date Format"
                    onChangeValue={(e) => {
                      setValue('dateFormat', e?.value)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Company Time Zone:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <SelectField
                    getOptionValue={'key'}
                    getOptionLabel={'value'}
                    options={timeZoneOptions}
                    value={timeZoneOptions?.find((item) => {
                      return item.value === accountSettings.timezone
                    })}
                    placeholder="Select Time Zone"
                    onChangeValue={(e) => {
                      setValue('timezone', e?.value)
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Working hours per week:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    name="workingHourPerWeek"
                    value={accountSettings?.workingHourPerWeek}
                    size="sm"
                    type="number"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>

              <tr>
                <td width="40%">
                  <div className="text-sm text-gray-400">
                    Working days per week:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Input
                    name="workingDaysPerWeek"
                    value={accountSettings?.workingDaysPerWeek}
                    size="sm"
                    type="number"
                    containerClass="pb-0"
                    className="text-sm"
                    onChange={onChange}
                  />
                </td>
                <td className="flex justify-center items-center">
                  <i className="text-red-300 mb-0 text-sm">* Required</i>
                </td>
              </tr>
            </tbody>
          </table>
          {/* 
          <div className="border-t-1 border-gray-200 py-10" />

          <h3 className="font-bold text-md mb-16">Purchase Order Settings</h3>
          <table className="w-full mb-32">
            <tbody>
              <tr>
                <td width="40%" className="">
                  <div className="text-sm text-gray-400">
                    Default attachment when sending purchase orders:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <Select
                    name="defaultAttachmentTypeId"
                    // defaultValue={accountSettings.defaultAttachmentTypeId}
                    size="sm"
                    // options={attachmentTypeOptions}
                    containerClass="pb-0"
                    className="text-sm"
                    // onChange={onChange}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td width="40%" className="">
                  <div className="text-sm text-gray-400">
                    Automatically create invoice to come after sending purchase
                    order:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <ToggleButton
                    toggle={handelAutomaticallyCreateInvoiceToggle}
                    isActive={automaticallyCreateInvoice}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="border-t-1 border-gray-200 py-10" />

          <h3 className="font-bold text-md mb-16">Miscellaneous Settings</h3>
          <table className="mb-32 w-full">
            <tbody>
              <tr>
                <td width="40%" className="">
                  <div className="text-sm text-gray-400">
                    Group Tracking - Auto assign to site visits:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <ToggleButton
                  // toggle={handelAutoAssignToSiteVisitToggle}
                  // isActive={accountSettings.autoAssignToSiteVisit}
                  />
                </td>
                <td className="flex justify-center items-center"></td>
              </tr>
              <tr>
                <td width="40%" className="">
                  <div className="text-sm text-gray-400">
                    Show state / region on invoices and quotes:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <ToggleButton
                  // toggle={handelShowRegionOnInvoiceToggle}
                  // isActive={accountSettings.showRegionOnInvoice}
                  />
                </td>
                <td className="flex justify-center items-center"></td>
              </tr>
              <tr>
                <td width="40%" className="">
                  <div className="text-sm text-gray-400">
                    Fallback Materials Markup Rate:
                  </div>
                </td>
                <td width="40%" className="px-[10%]">
                  <div className="inline-flex relative">
                    <Input
                      name="markupRatePercentage"
                      //   value={accountSettings.markupRatePercentage}
                      placeholder="0"
                      size="sm"
                      containerClass="pb-0"
                      className="text-sm"
                      //   onChange={onChange}
                    />
                    <div className="bg-gray-200 px-10 flex items-center justify-center text-gray-300 font-bold text-sm rounded-r-sm absolute right-0 top-0 bottom-0">
                      %
                    </div>
                  </div>
                </td>
                <td className="flex justify-center items-center"></td>
              </tr>
            </tbody>
          </table>*/}
        </div>
      </DescriptionBox>
    </form>
  )
}
