import {useCallback, useEffect, useState} from 'react'
import {useFormInput} from 'use-form-input'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {FaTrash} from 'react-icons/fa'

import {Input, SelectField, Textarea, ToolTip} from 'app/common'
import {PreliminarySectionTable} from './components'

import {
  updatePreliminaryItemInfoAction,
  updatePreliminaryItemMonetary,
  createPreliminaryLineItem,
  updatePreliminaryLineItem,
  deletePreliminaryLineItem,
  getProjectByIdAction
} from 'redux-src'

import {PRELIMINARY_TABLE_HEADINGS} from '../../constants'

interface RemappedLineItemType {
  id: number
  name: string
  noOfWeeks: number
  noOfQuantity: number
  rate: number
  noOfTransport: number
  // quantityUnit: string
}

export const PreliminarySectionCard = ({
  sectionDetail,
  onRemoveSection,
  lineItemList,
  fetchPreliminaryItems,
  sectionList
}: {
  sectionDetail?: Api.PreliminaryItemsDetails
  onRemoveSection?: (sectionId: number) => void
  sectionList?: any
  lineItemList?: Api.PreliminaryLineItemDetails[]
  fetchPreliminaryItems?: () => void
}) => {
  const [lineItemName, setLineItemName] = useState<string>('')
  const [lineItems, setLineItems] = useState<Array<RemappedLineItemType>>()

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project
  )

  const [preliminaryHeading, setPreliminaryHeading] = useState<any>()

  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  // console.log('sectionDetali', sectionDetail)

  const [constantData, {onChange, setValue}] = useFormInput(
    {
      noOfQuantity: 0,
      unitName: '',
      noOfWeeks: 0,
      noOfTransport: 0,
      sectionTitle: '',
      type: 'item',
      description: ''
    },
    () => {}
  )

  type LineItemKeys = 'name' | 'rate'

  const onUpdateLineItemHandler = (
    key: LineItemKeys,
    value: any,
    item: any
  ) => {
    if (+value === +item[key]) return

    const lineItemBody = {
      name: item?.name,
      rate: item?.rate
    }

    lineItemBody[key] = value

    dispatch(
      updatePreliminaryLineItem(
        projectId,
        sectionDetail?.id,
        item?.id,
        lineItemBody,
        () => {
          fetchPreliminaryItems()
          dispatch(getProjectByIdAction(projectId))
        }
      )
    )
  }

  const remappedLineItemList = useCallback(() => {
    if (!lineItemList) return []

    const remappedList = lineItemList?.map((lineItem) => {
      return {
        id: lineItem?.id,
        name: lineItem?.name,
        noOfWeeks: projectDetail?.project_details?.prelim_total_weeks,
        noOfQuantity: sectionDetail?.total_frequency,
        rate: lineItem?.rate,
        noOfTransport: sectionDetail?.total_vehicles,
        // quantityUnit: sectionDetail?.frequency_unit,
        totalCost: lineItem?.total_cost
      }
    })

    return setLineItems(remappedList)
  }, [
    lineItemList,
    projectDetail?.project_details?.prelim_total_weeks,
    sectionDetail
  ])

  const onUpdatePreliminaryMonetaryInfo = () => {
    if (
      sectionDetail?.total_frequency === constantData?.noOfQuantity &&
      sectionDetail?.total_vehicles === constantData?.noOfTransport
    ) {
      return
    }

    const monetaryInfoBody = {
      totalFrequency: Number(constantData?.noOfQuantity),
      totalVehicles: Number(constantData?.noOfTransport)
    }

    dispatch(
      updatePreliminaryItemMonetary(
        projectId,
        sectionDetail?.id,
        monetaryInfoBody,
        () => {
          fetchPreliminaryItems()
        }
      )
    )
  }

  const onUpdatePreliminarySectionInfo = () => {
    if (
      sectionDetail?.description === constantData?.description &&
      sectionDetail?.type === constantData?.type &&
      sectionDetail?.title === constantData?.sectionTitle &&
      sectionDetail?.frequency_unit === constantData?.unitName
    ) {
      return
    }

    const infoBody = {
      title: constantData?.sectionTitle,
      type: constantData?.type,
      description: constantData?.description ?? undefined,
      frequencyUnit: String(constantData?.unitName)
    }

    dispatch(
      updatePreliminaryItemInfoAction(
        projectId,
        sectionDetail?.id,
        infoBody,
        () => {
          fetchPreliminaryItems()
        }
      )
    )
  }

  const onCreatePreliminaryLineItem = (event: any) => {
    event.preventDefault()

    if (lineItemName !== '') {
      const lineItemBody = {
        name: lineItemName,
        rate: 0
      }

      dispatch(
        createPreliminaryLineItem(
          projectId,
          sectionDetail?.id,
          lineItemBody,
          () => {
            fetchPreliminaryItems()
            setLineItemName('')
            dispatch(getProjectByIdAction(projectId))
          }
        )
      )
    }
  }

  const onRemoveLineItem = (lineItemId: number) => {
    dispatch(
      deletePreliminaryLineItem(
        projectId,
        sectionDetail?.id,
        lineItemId,
        () => {
          fetchPreliminaryItems()
        }
      )
    )
  }

  const clearField = useCallback(() => {
    setValue('noOfQuantity', 0)
    setValue('unitName', sectionDetail?.frequency_unit)
    setValue('noOfWeeks', 0)
    setValue('noOfTransport', 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constantData?.type])

  const onSelectType = (type: string) => {
    setPreliminaryHeading((prev: any) => {
      let tempData = [...prev]

      if (type === 'fuel') {
        tempData.splice(0, 1, {
          type: 'fuel',
          header: PRELIMINARY_TABLE_HEADINGS['fuel'],
          data: lineItems
        })
      }

      if (type === 'dynamic item') {
        tempData.splice(0, 1, {
          type: 'dynamic item',
          header: PRELIMINARY_TABLE_HEADINGS['dynamic item'],
          data: lineItems
        })
      }

      if (type === 'transport') {
        tempData.splice(0, 1, {
          type: 'transport',
          header: PRELIMINARY_TABLE_HEADINGS['transport'],
          data: lineItems
        })
      }

      if (type === 'item') {
        tempData.splice(0, 1, {
          type: 'item',
          header: PRELIMINARY_TABLE_HEADINGS['item'],
          data: lineItems
        })
      }

      return tempData
    })

    clearField()
  }

  useEffect(() => {
    if (sectionDetail !== undefined && sectionDetail !== null) {
      setValue('type', sectionDetail?.type)
      setValue('noOfQuantity', sectionDetail?.total_frequency ?? 0)
      setValue('sectionTitle', sectionDetail?.title)
      setValue('unitName', sectionDetail?.frequency_unit)
      setValue('noOfTransport', sectionDetail?.total_vehicles)
      setValue('description', sectionDetail?.description)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionDetail])

  useEffect(() => {
    remappedLineItemList()
  }, [remappedLineItemList])

  useEffect(() => {
    setPreliminaryHeading([
      {
        type: sectionDetail?.type ?? 'dynamicItem',
        header:
          //@ts-ignore
          PRELIMINARY_TABLE_HEADINGS[sectionDetail?.type ?? 'dynamicItem'],
        data: lineItems
      }
    ])
  }, [sectionDetail, lineItems])

  return (
    <>
      <div className="mt-6 py-16 px-20 bg-gray-100 rounded-md">
        <div className="flex gap-8">
          <input
            className="w-1/3 rounded-sm text-xl border-1 border-gray-200 shadow-sm font-bold mb-12 px-6"
            value={constantData?.sectionTitle}
            name="sectionTitle"
            onChange={onChange}
            onBlur={onUpdatePreliminarySectionInfo}
          />

          <SelectField
            options={options}
            placeholder="Select..."
            className={'w-[100px]'}
            value={options.find((item) => item.value === constantData?.type)}
            onChangeValue={(value) => {
              setValue('type', value?.value)
              onSelectType(value?.value)
            }}
            onBlur={onUpdatePreliminarySectionInfo}
            isDisabled={lineItemList === null ? false : true}
          />

          <ToolTip text="Delete Section" top>
            <div
              className="flex justify-center items-center h-[32px] cursor-pointer hover:text-red-300 transition-all duration-200 ease-in-out"
              onClick={() => {
                onRemoveSection(sectionDetail?.id)
              }}
            >
              <FaTrash size={16} />
            </div>
          </ToolTip>
        </div>

        <div>
          {constantData?.type === 'transport' && (
            <div className="flex gap-16">
              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of quantity : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfQuantity"
                    value={constantData?.noOfQuantity}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div>

              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">Unit name : </label>
                <span className="">
                  <Input
                    type="text"
                    name="unitName"
                    value={constantData?.unitName}
                    onChange={onChange}
                    onBlur={onUpdatePreliminarySectionInfo}
                  />
                </span>
              </div>

              {/* <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of weeks : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfWeeks"
                    value={constantData?.noOfWeeks}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div> */}
            </div>
          )}

          {constantData?.type === 'dynamic item' && (
            <div className="flex gap-16">
              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of quantity : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfQuantity"
                    value={constantData?.noOfQuantity}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div>

              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">Unit name : </label>
                <span className="">
                  <Input
                    type="text"
                    name="unitName"
                    value={constantData?.unitName}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminarySectionInfo}
                  />
                </span>
              </div>
            </div>
          )}

          {constantData?.type === 'fuel' && (
            <div className="flex gap-16">
              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of quantity : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfQuantity"
                    value={constantData?.noOfQuantity}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div>

              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">Unit name : </label>
                <span className="">
                  <Input
                    type="text"
                    name="unitName"
                    value={constantData?.unitName}
                    onChange={onChange}
                    onBlur={onUpdatePreliminarySectionInfo}
                  />
                </span>
              </div>

              {/* <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of weeks : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfWeeks"
                    value={constantData?.noOfWeeks}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div> */}
              <div className="flex justify-center gap-6 items-center">
                <label className="text-md h-[38px]">No. of transport : </label>
                <span className="w-[80px]">
                  <Input
                    type="number"
                    name="noOfTransport"
                    value={constantData?.noOfTransport}
                    onChange={onChange}
                    min={0}
                    onBlur={onUpdatePreliminaryMonetaryInfo}
                  />
                </span>
              </div>
            </div>
          )}
        </div>

        <Textarea
          className="text-sm mt-10"
          placeholder="Click here to enter description"
          size="sm"
          name="description"
          value={constantData?.description}
          onChange={onChange}
          onBlur={onUpdatePreliminarySectionInfo}
        />

        <div className="flex flex-col gap-14">
          {preliminaryHeading?.map((item: any, index: number) => {
            return (
              <div key={index} className="flex flex-col gap-4">
                <div className="text-md text-blue-300 font-bold underline decoration-solid decoration-blue-300">
                  {/* {item.type.toUpperCase()} <span>TABLE</span> */}
                </div>
                <PreliminarySectionTable
                  preliminaryType={item?.type}
                  preliminaryHeader={item?.header}
                  preliminaryData={item?.data}
                  onRemoveLineItem={onRemoveLineItem}
                  onUpdateLineItemHandler={onUpdateLineItemHandler}
                  sectionDetails={sectionDetail}
                />
              </div>
            )
          })}
        </div>
      </div>
      <div className="mt-10 w-[400px]">
        <form onSubmit={(e) => onCreatePreliminaryLineItem(e)}>
          <Input
            type="text"
            name="lineItemName"
            value={lineItemName}
            placeholder="Enter a line item"
            onChange={(e) => setLineItemName(e.target.value)}
            onBlur={(e) => onCreatePreliminaryLineItem(e)}
          />
        </form>
      </div>
    </>
  )
}

const options: Array<{id: number; label: string; value: string}> = [
  {
    id: 1,
    label: 'Dynamic Item',
    value: 'dynamic item'
  },
  {
    id: 2,
    label: 'Transport',
    value: 'transport'
  },
  {
    id: 3,
    label: 'Fuel',
    value: 'fuel'
  },
  {
    id: 4,
    label: 'Item',
    value: 'item'
  }
]
