import {Modal} from 'app/common'
import {getAssetUrl} from 'helpers'
// import {PdfViewer} from '../pdfViewer'

interface ImagePreviewModalProps {
  modalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  assetDetails?: Api.AssetDetailsType
}

export const ImagePreviewModal = ({
  modalVisible,
  setModalVisible,
  assetDetails
}: ImagePreviewModalProps) => {
  return (
    <Modal
      toggleModal={setModalVisible}
      isActive={modalVisible}
      title={
        assetDetails?.file_type === 'document'
          ? `Document Preview`
          : 'Image Preview'
      }
      size="md"
    >
      <div className="h-[80vh] w-full p-20">
        {assetDetails && assetDetails?.file_type === 'image' ? (
          <img
            src={getAssetUrl(assetDetails)}
            alt={assetDetails?.name ?? 'previewImage'}
            className="object-contain h-full w-full rounded-md"
          />
        ) : (
          <div />
        )}
      </div>
    </Modal>
  )
}
