import styled, {keyframes} from 'styled-components'

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  border: 4px solid #e1e1e1;
  border-top: 4px solid indigo;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: ${spinner} 1s ease-in-out infinite;
  margin: 0 5px;
`

const SmallLoader = styled.div`
  border: 1px solid #e1e1e1;
  border-top: 1px solid indigo;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: ${spinner} 1s ease-in-out infinite;
  margin: 0 5px;
`

const MediumLoader = styled.div`
  border: 1px solid #e1e1e1;
  border-top: 1px solid indigo;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: ${spinner} 1s ease-in-out infinite;
`

export const Loading = ({
  small,
  medium
}: {
  small?: boolean
  medium?: boolean
  size?: string
}) => {
  if (medium) {
    return <MediumLoader />
  }
  return small ? (
    <SmallLoader />
  ) : (
    <div className={'flex items-center justify-around h-loader'}>
      <Loader />
    </div>
  )
}
