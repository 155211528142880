import { CustomPriceBookTable } from '../components'
import colors from 'tailwindcss/colors'
import { TableStyles } from 'react-data-table-component'

export const data: CustomPriceBookTable[] = [
  {
    productCode: 'Beetlejuice',
    itemName: 'Orange',
    costPrice: 1200,
    retailPrice: 4500,
    salesAccountType: 'test',
  },
  {
    productCode: 'Apple',
    itemName: 'Mango',
    costPrice: 1200,
    retailPrice: 200000,
    salesAccountType: 'test',
  },
]

export const customStyles: TableStyles = {
  table: {
    style: {
      border: '1px solid',
      borderRadius: '5px',
      borderColor: colors.gray[200],
    },
  },
  rows: {
    style: {
      minHeight: '40px', // override the row height
      '&:first-child': {
        minHeight: '55px',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      '&:not(:last-child)': {
        borderRight: '1px solid',
        borderColor: colors.gray[200],
      },
      '&:last-child': {
        minWidth: '50px',
        maxWidth: '50px',
      },
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      '&:not(:last-child)': {
        borderRight: '1px solid',
        borderColor: colors.gray[200],
      },
      '&:last-child': {
        minWidth: '50px',
        maxWidth: '50px',
      },
    },
  },
  headRow: {
    style: {
      fontWeight: 'bold',
      minHeight: '42px',
      background: colors.gray[100],
    },
  },
}
