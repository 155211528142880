import {createContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigation, useQuery} from 'react-auth-navigation'
import {TbList} from 'react-icons/tb'
import {FaMapLocationDot} from 'react-icons/fa6'

import {Button, SelectField} from 'app/common'
import {getProjectTicketList} from 'redux-src'

import {CreateTicketModel, PlanList, ProjectTicketList} from './components'

interface DefaultValueContextTypes {
  projectTicketId?: number
  setProjectTicketId: React.Dispatch<React.SetStateAction<number | undefined>>
  createTicketModel?: boolean
  setCreateTicketModel: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  isSubTicketModalVisible?: boolean
  setSubTicketModalVisible?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >

  createSubTicketModal?: boolean
  setCreateSubTicketModal?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  setView?: React.Dispatch<React.SetStateAction<'list' | 'plan'>>
}

const defaultValue: DefaultValueContextTypes = {
  projectTicketId: 0,
  setProjectTicketId: () => {},
  createTicketModel: false,
  setCreateTicketModel: () => {},
}

export const TicketContext = createContext(defaultValue)

export const TicketMainPage = () => {
  const [createTicketModel, setCreateTicketModel] = useState<boolean>(false)
  const [projectTicketId, setProjectTicketId] = useState<number>()
  const [isSubTicketModalVisible, setSubTicketModalVisible] =
    useState<boolean>(false)

  const [createSubTicketModal, setCreateSubTicketModal] =
    useState<boolean>(false)

  const [view, setView] = useState<'list' | 'plan'>('list')

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const query: any = useQuery()
  const {showTicket, ticketId} = query

  useEffect(() => {
    dispatch(getProjectTicketList(projectId))
  }, [dispatch, projectId])

  const handleStatusChange = (newStatus: any) => {
    dispatch(
      getProjectTicketList(projectId, {
        page: 1,
        limit: 10,
        status: newStatus?.value as string,
      }),
    )
  }

  const [showTicketModalOpen, setShowTicketModalOpen] = useState(false)
  useEffect(() => {
    setShowTicketModalOpen(!!showTicket)
  }, [showTicket])
  return (
    <TicketContext.Provider
      value={{
        projectTicketId,
        setProjectTicketId,
        createTicketModel,
        setCreateTicketModel,
        isSubTicketModalVisible,
        setSubTicketModalVisible,
        createSubTicketModal,
        setCreateSubTicketModal,
        setView,
      }}
    >
      <div className="flex flex-col gap-10 min-h-[75vh]">
        <div className="text-lg text-black font-bold">Tickets</div>
        <div className="flex justify-between items-center">
          <div className="text-md font-bold text-black">Ticket List</div>
          <div className="flex items-center justify-end gap-10">
            <div className="font-bold">Status:</div>

            <SelectField
              options={[
                {
                  id: -1,
                  value: undefined,
                  label: 'All',
                },
                {
                  id: 1,
                  value: 'open',
                  label: 'Open',
                },
                {
                  id: 2,
                  value: 'in progress',
                  label: 'In Progress',
                },
                {
                  id: 3,
                  value: 'completed',
                  label: 'Completed',
                },
                {
                  id: 4,
                  value: 'feedback',
                  label: 'Feedback',
                },
              ]}
              defaultValue={{
                id: -1,
                value: undefined,
                label: 'All',
              }}
              onChangeValue={(newStatus) => {
                handleStatusChange(newStatus)
              }}
              className="w-[150px]"
            />
            <div className="font-bold">View:</div>
            <SelectField
              className="w-[150px]"
              value={
                view === 'list'
                  ? {id: 1, label: 'List', value: 'list'}
                  : {id: 2, label: 'Plan', value: 'plan'}
              }
              options={[
                {id: 1, label: 'List', value: 'list'},
                {id: 2, label: 'Plan', value: 'plan'},
              ]}
              onChangeValue={(value) => {
                setView(value.value as 'list' | 'plan')
              }}
              formatOptionLabel={(option) => {
                return (
                  <div className="flex flex-nowrap items-center justify-start gap-8">
                    {option.value === 'list' ? (
                      <TbList size={14} />
                    ) : (
                      <FaMapLocationDot size={14} />
                    )}{' '}
                    {option.label}
                  </div>
                )
              }}
            />

            <Button
              title={'Create Ticket'}
              onClick={() => {
                setCreateTicketModel((prev) => !prev)
              }}
              className="bg-blue-400 text-white hover:bg-blue-300 w-[max-content]"
            />
          </div>
        </div>
        {/* createTicketsLoading || updateTicketLoading ? (
        <Loading />) : */}
        {view === 'list' ? <ProjectTicketList /> : <PlanList />}
      </div>

      {createTicketModel && (
        <CreateTicketModel
          key={'Create Ticket Modal'}
          modalId={'Ticket Model One'}
          isModelVisible={createTicketModel}
          setIsModelVisible={setCreateTicketModel}
        />
      )}
      {createSubTicketModal && (
        <CreateTicketModel
          key={'Create Sub Ticket Modal'}
          modalId="Create Sub Ticket Modal"
          isModelVisible={createSubTicketModal}
          setIsModelVisible={setCreateSubTicketModal}
          isSubTicketModal
        />
      )}

      {showTicket && (
        <CreateTicketModel
          key={'Create Sub Ticket Modal'}
          modalId="Create Sub Ticket Modal"
          isModelVisible={showTicketModalOpen}
          setIsModelVisible={setShowTicketModalOpen}
          isSubTicketEdit
          edit
          ticketIdFromSitevisit={ticketId}
        />
      )}
    </TicketContext.Provider>
  )
}
