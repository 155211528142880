import {useEffect} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch} from 'react-redux'

import {getFormByIdAction} from 'redux-src'

import {FormBuilder} from '../../components'

export const FormBuilderEdit = () => {
  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {formId} = params as any

  useEffect(() => {
    dispatch(getFormByIdAction(formId))
  }, [dispatch, formId])

  return (
    <div className="m-10 mt-6 mb-0 min-h-[85vh]">
      <FormBuilder edit />
    </div>
  )
}
