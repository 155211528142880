import {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  createContext,
  useMemo,
} from 'react'
import L, {LatLngTuple, PointExpression} from 'leaflet'
import {MapContainer, Marker, useMap, Popup} from 'react-leaflet'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {TiTick} from 'react-icons/ti'
import {FaTimes} from 'react-icons/fa'
import {MdMyLocation} from 'react-icons/md'

import {Button, Loading, ToolTip, toast} from 'app/common'
import {MessageBox} from 'app/components'
import {getTileUrl} from 'helpers'
import {updateTicketActions} from 'redux-src'

import PlanTicketCart from 'app/pages/jobs/pages/ticket/components/planList/components/planTicketCart/planTicketCart.component'
import {TicketContext} from 'app/pages/jobs/pages/ticket'
import {HiOutlineArrowsExpand} from 'react-icons/hi'

interface MapOptionsType {
  maxZoom: number
  mapDim: {width: number; height: number}
  center: LatLngTuple
  bounds: LatLngTuple[]
  offset: {x: number; y: number}
  planTicketList: Array<Api.SiteVisitTicketIndividual>
}

const MAP_CONTAINER_HEIGHT = 600

const offset = {x: 5, y: 5}
// const MAP_DIM = {width: 3370, height: 2384}
const MAP_DIM: {width: number; height: number} = {
  width: 595,
  height: 842,
}
// const MAP_DIM = {width: 2592, height: 1728}

const CENTER: LatLngTuple = [MAP_DIM.height / 2, MAP_DIM.width / 2] // Here latLng is (y,x);
const BOUNDS: LatLngTuple[] = [
  [0, 0],
  [MAP_DIM.width, MAP_DIM.height],
]

const FloorMapContext = createContext<Comp.PlanTicketMapProps & MapOptionsType>(
  {
    plan: undefined,
    getPlanTicketListCallback: undefined,
    mapContainerHeight: 600,
    planTicketList: undefined,
    selectedTicket: undefined,
    maxZoom: 4,
    mapDim: MAP_DIM,
    center: CENTER,
    bounds: BOUNDS,
    offset,
  },
)

export const PlanTicketMap = ({
  mapContainerHeight,
  plan,
  // planTicketList,
  selectedTicket,
  getPlanTicketListCallback,
  setPlanTicketList,
  setTicketModal,
}: Comp.PlanTicketMapProps) => {
  const {projectPlanTicketListBuffer: planTicketList}: RT.ProjectPlanReduxType =
    useSelector((state: any) => state.projectPlan)

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setLoading(false)
  //   }, 2000)

  //   return () => {
  //     clearTimeout(timeout)
  //     setLoading(true)
  //   }
  // }, [plan])

  const mapOptions: MapOptionsType = useMemo(() => {
    const mapDim = {
      width: plan?.project_plan_details?.width
        ? plan?.project_plan_details?.rotation === 90 &&
          plan?.project_plan_details?.width > plan?.project_plan_details?.height
          ? plan?.project_plan_details?.width
          : plan?.project_plan_details?.height
        : MAP_DIM.width,
      height: plan?.project_plan_details?.height
        ? plan?.project_plan_details?.rotation === 90 &&
          plan?.project_plan_details?.width > plan?.project_plan_details?.height
          ? plan?.project_plan_details?.height
          : plan?.project_plan_details?.width
        : MAP_DIM.width,
    }

    return {
      maxZoom: plan?.project_plan_details?.max_zoom_level ?? 4,
      mapDim,
      center: selectedTicket
        ? [
            selectedTicket?.ticket_details?.layer_coordinates?.at(0),
            selectedTicket?.ticket_details?.layer_coordinates?.at(1),
          ]
        : [mapDim.height / 2, mapDim.width / 2],
      bounds: [
        [0, 0],
        [mapDim.height, mapDim.width],
      ],
      offset,
      planTicketList,
    }
  }, [
    plan?.project_plan_details?.height,
    plan?.project_plan_details?.max_zoom_level,
    plan?.project_plan_details?.rotation,
    plan?.project_plan_details?.width,
    planTicketList,
    selectedTicket,
  ])

  return (
    <>
      <FloorMapContext.Provider
        value={{
          mapContainerHeight,
          plan,
          selectedTicket,
          getPlanTicketListCallback,
          setPlanTicketList,
          setTicketModal,
          ...mapOptions,
        }}
      >
        {/* {loading || getProjectTicketListLoading || updateTicketLoading ? ( */}
        {false ? (
          mapContainerHeight && Number(mapContainerHeight) < 600 ? (
            <div
              className={`flex justify-center items-center`}
              style={{
                height: mapContainerHeight,
              }}
            >
              <Loading small />
            </div>
          ) : (
            <Loading />
          )
        ) : (
          <>
            <div
              style={{
                height: mapContainerHeight ?? MAP_CONTAINER_HEIGHT,
                overflow: 'hidden',
                zIndex: '230000000 !important',
                isolation: 'isolate',
                width: '100%',
                borderRadius: 8,
              }}
            >
              {plan?.project_plan_details.from_pdf === null ? (
                plan?.project_plan_details.has_error ? (
                  <MessageBox
                    error
                    message={
                      <div className="flex gap-10">
                        Error! Can't process the project plan, please re-upload
                        file.
                      </div>
                    }
                  />
                ) : (
                  <MessageBox
                    message={
                      <div className="flex gap-10">
                        <Loading small /> Project plan is processing... please
                        comeback after a while.
                      </div>
                    }
                  />
                )
              ) : plan?.project_plan_details.from_pdf === true ? (
                <MapContainer
                  // center={mapOptions.center}
                  zoom={1}
                  minZoom={1}
                  maxZoom={mapOptions.maxZoom}
                  bounds={BOUNDS}
                  boundsOptions={{
                    maxZoom: mapOptions.maxZoom,
                    padding: [0, 0],
                  }}
                  crs={L.CRS.Simple}
                  attributionControl={false}
                  closePopupOnClick
                  style={{
                    width: '100%',
                    height: '100%',
                    zIndex: '1 !important',
                  }}
                >
                  <TileMap
                    projectId={plan?.project_plan_details.project_id}
                    projectPlanId={plan?.project_plan_details.id}
                    marker={{
                      x: selectedTicket?.ticket_details?.layer_coordinates?.at(
                        0,
                      ),
                      y: selectedTicket?.ticket_details?.layer_coordinates?.at(
                        1,
                      ),
                    }}
                  />
                  {!!planTicketList &&
                    planTicketList
                      ?.filter(
                        (ticket) => !!ticket?.ticket_details?.layer_coordinates,
                      )
                      ?.map((ticket) => {
                        return (
                          <MarkerComp
                            key={`tile-marker-${ticket.ticket_details.id}`}
                            ticket={ticket}
                          />
                        )
                      })}
                </MapContainer>
              ) : (
                <MessageBox
                  message={
                    <div className="flex gap-10">
                      <Loading small /> Project plan is processing... please
                      comeback after a while.
                    </div>
                  }
                />
              )}
            </div>
          </>
        )}
      </FloorMapContext.Provider>
    </>
  )
}

// MARK: - TileMap
const TileMap = ({
  projectId,
  projectPlanId,
  marker,
}: {
  projectId: number
  projectPlanId: number
  marker: {x: number; y: number}
}) => {
  const {maxZoom, mapDim, selectedTicket, center} = useContext(FloorMapContext)

  const map = useMap()
  const layerRef = useRef<L.LayerGroup>(null)

  useEffect(() => {
    layerRef.current = L.layerGroup().addTo(map)
    layerRef?.current?.clearLayers()
  }, [map])

  useEffect(() => {
    if (map) {
      L.tileLayer(getTileUrl(projectId, projectPlanId), {
        minZoom: 1,
        maxZoom,
      })?.addTo(map)

      map.fitBounds(BOUNDS)
    }
  }, [map, maxZoom, projectId, projectPlanId])

  useEffect(() => {
    layerRef?.current?.clearLayers()
  }, [])

  useEffect(() => {
    !!selectedTicket
      ? map?.flyTo([marker?.x ?? -80, marker?.y ?? 80], 2)
      : map?.panTo([marker?.x ?? -80, marker?.y ?? 80], {
          noMoveStart: true,
        })
  }, [map, marker, selectedTicket])

  const unprojectedCenter = useMemo(() => {
    const unprojectedCenter = map.unproject(center as PointExpression, maxZoom)
    return {x: unprojectedCenter.lat * 4, y: unprojectedCenter.lng * 4}
  }, [center, map, maxZoom])

  return (
    <>
      <div
        id="map"
        style={{height: mapDim.height ?? MAP_CONTAINER_HEIGHT, zIndex: 1}}
      />

      <MapResetOverlay
        resetHandler={() => {
          map?.panTo(
            [unprojectedCenter?.x ?? -80, unprojectedCenter?.y ?? 80],
            {
              noMoveStart: true,
            },
          )
          map?.setZoom(1)
        }}
      />
    </>
  )
}

export const MarkerComp = ({
  ticket,
}: {
  ticket: Api.SiteVisitTicketIndividual
}) => {
  const {plan, selectedTicket, planTicketList, setTicketModal} =
    useContext(FloorMapContext)

  const {setProjectTicketId} = useContext(TicketContext)

  const [isDraggable, setDraggable] = useState<boolean>(false)

  const dispatch = useDispatch()
  const {params} = useNavigation()

  const map = useMap()
  const originalPoint: [number, number] = useMemo(
    () => [
      ticket?.ticket_details?.layer_coordinates?.at(0) ?? 0,
      ticket?.ticket_details?.layer_coordinates?.at(1) ?? 0,
    ],
    [ticket?.ticket_details?.layer_coordinates],
  )

  const layerCoordRef = useRef({x: originalPoint.at(0), y: originalPoint.at(1)})
  const reportLayerCoordRef = useRef({
    x: originalPoint.at(0),
    y: originalPoint.at(1),
  })

  const maxZoom = map.getMaxZoom()

  const mapFlyTo = useCallback(
    (x: number, y: number) => {
      map?.flyTo([x ?? -80, y ?? 80], maxZoom)
    },
    [map, maxZoom],
  )

  const {projectId} = params as any

  const iconSize: [number, number] = useMemo(() => [30, 40], [])

  const ticketStatus: 'open' | 'in progress' | 'feedback' | 'completed' =
    useMemo(() => {
      return (ticket?.ticket_details?.status ?? 'open') as
        | 'open'
        | 'in progress'
        | 'feedback'
        | 'completed'
    }, [ticket])

  let statusIcons: Record<
    'open' | 'in progress' | 'feedback' | 'completed',
    L.Icon
  > = {
    open: L.icon({
      iconUrl: '/map_pointer_open.svg',
      iconSize,
    }),
    'in progress': L.icon({
      iconUrl: '/map_pointer_progress.svg',
      iconSize,
    }),
    feedback: L.icon({
      iconUrl: '/map_pointer_feedback.svg',
      iconSize,
    }),
    completed: L.icon({
      iconUrl: '/map_pointer_completed.svg',
      iconSize,
    }),
  }

  let draggableIcon = L.icon({
    iconUrl: '/map_pointer_draggable.svg',
    iconSize: [35, 48],
  })

  const handleDraggableMarker = () => {
    setDraggable((prev) => !prev)
    setTicketModal?.(false)
    mapFlyTo(
      ticket?.ticket_details?.layer_coordinates[0],
      ticket?.ticket_details?.layer_coordinates[1],
    )
  }

  const handleSaveLayerCoordinates = useCallback(() => {
    const markerCoordinates = layerCoordRef.current
    const reportLayerCoordinates = reportLayerCoordRef.current

    if (!!markerCoordinates) {
      const body = {
        layerCoordinates: [
          markerCoordinates?.x ??
            ticket?.ticket_details?.layer_coordinates.at(0),
          markerCoordinates?.y ??
            ticket?.ticket_details?.layer_coordinates.at(1),
        ],
        reportLayerCoordinates: [
          reportLayerCoordinates?.x ??
            ticket?.ticket_details?.report_layer_coordinates.at(0),
          reportLayerCoordinates?.y ??
            ticket?.ticket_details?.report_layer_coordinates.at(1),
        ],
      }

      const updatedProjectPlanTicketList = planTicketList.map((planTicket) => {
        if (ticket?.ticket_details?.id === planTicket?.ticket_details?.id)
          return {
            ...ticket,
            ticket_details: {
              ...ticket?.ticket_details,
              layer_coordinates: body.layerCoordinates,
              report_layer_coordinates: body.reportLayerCoordinates,
            },
          }

        return planTicket
      })

      dispatch({
        type: 'UPDATE_PROJECT_PLAN_TICKET_LIST_BUFFER',
        payload: updatedProjectPlanTicketList,
      })
      map.setZoom(1)

      dispatch(
        updateTicketActions(projectId, ticket?.ticket_details?.id, body, () => {
          setDraggable(false)
          map?.panTo(
            [markerCoordinates?.x ?? -80, markerCoordinates?.y ?? 80],
            {
              noMoveStart: true,
              animate: false,
            },
          )
          map.closePopup()
        }),
      )
    } else {
      return toast.error('Please set the plan position first !!')
    }
  }, [dispatch, map, planTicketList, projectId, ticket])

  const handleDragData = (d: any) => {
    const [x, y] = [
      d?.target?.getLatLng()?.lat ??
        ticket?.ticket_details?.layer_coordinates[0],
      d?.target?.getLatLng()?.lng ??
        ticket?.ticket_details?.layer_coordinates[1],
    ]

    mapFlyTo(x, y)

    //* projectedPoint for original LatLngTuple with default zoom = 4
    const projectedPoint4 = map.project([x, y], maxZoom)

    const midPointIconSize: [number, number] = [
      iconSize.at(0) / 2,
      iconSize.at(1) / 2,
    ]

    //* projectedPoint recalculated with the offset of the marker icon size
    const projectedPointAdd4 = projectedPoint4.add(midPointIconSize).divideBy(4)

    //* funtion to calculate optimal point with respect to offset.x and offset.y percentage
    const getOptimalPoint = (x: number, y: number) => ({
      x: x - (offset.x / 100) * x,
      y: y - (offset.y / 100) * y,
    })

    //* optimal point with respect to offset.x and offset.y percentage
    const optimalPoint4 = getOptimalPoint(
      projectedPointAdd4.x,
      projectedPointAdd4.y,
    )

    const finalOffset = {x: 0.009, y: 0.009}

    const finalPoint: [number, number] =
      plan?.project_plan_details?.rotation === 90 &&
      plan?.project_plan_details?.height > plan?.project_plan_details?.width
        ? [
            optimalPoint4.y + optimalPoint4.y * finalOffset.y,
            optimalPoint4.x + optimalPoint4.x * finalOffset.x,
          ]
        : [
            optimalPoint4.x,
            plan?.project_plan_details?.height - optimalPoint4.y,
          ]

    layerCoordRef.current = {
      x,
      y,
    }
    reportLayerCoordRef.current = {
      x: finalPoint.at(0),
      y: finalPoint.at(1),
    }
  }

  const markerRef = useRef<L.Marker>(null)

  useEffect(() => {
    if (map && markerRef.current) {
      ticket?.ticket_details?.id === selectedTicket?.ticket_details?.id &&
        markerRef.current?.openPopup()
    }
  }, [ticket, selectedTicket, map])

  return (
    <Marker
      ref={markerRef}
      position={[
        ticket?.ticket_details?.layer_coordinates?.at(0),
        ticket?.ticket_details?.layer_coordinates?.at(1),
      ]}
      icon={isDraggable ? draggableIcon : statusIcons[ticketStatus]}
      draggable={isDraggable}
      eventHandlers={{
        dragend(d) {
          handleDragData(d)
          d?.target?.openPopup()
        },
        click() {
          mapFlyTo(
            isDraggable
              ? layerCoordRef.current.x ??
                  ticket?.ticket_details?.layer_coordinates[0]
              : ticket?.ticket_details?.layer_coordinates[0],
            isDraggable
              ? layerCoordRef.current.y ??
                  ticket?.ticket_details?.layer_coordinates[1]
              : ticket?.ticket_details?.layer_coordinates[1],
          )
        },
      }}
      riseOnHover
    >
      {isDraggable ? (
        <Popup
          className="draggable-actions"
          autoClose={false}
          closeOnClick={false}
          closeButton={false}
          closeOnEscapeKey={false}
          key={'draggable-popup'}
        >
          <div className="flex gap-16 flex-nowrap">
            <div
              className="flex items-center cursor-pointer justify-center border-[4px] border-red-500 bg-white rounded-full h-[44px] w-[44px]"
              onClick={() => {
                setDraggable(false)
                mapFlyTo(
                  ticket?.ticket_details?.layer_coordinates[0],
                  ticket?.ticket_details?.layer_coordinates[1],
                )
              }}
            >
              <FaTimes size={16} />
            </div>
            <div
              className="flex border-[4px] border-green-500 bg-white cursor-pointer rounded-full items-center justify-center h-[44px] w-[44px]"
              onClick={handleSaveLayerCoordinates}
            >
              <TiTick size={22} />
            </div>
          </div>
        </Popup>
      ) : (
        <Popup
          maxWidth={400}
          minWidth={300}
          className={`${isDraggable}`}
          key={'original-popup'}
        >
          <PlanTicketCart ticketDetails={ticket} />

          <div className="flex justify-between items-center pt-10">
            <ToolTip
              text={
                isDraggable
                  ? 'Drag the position icon to reposition.'
                  : 'Click to reposition.'
              }
              top
            >
              <div
                className="bg-blue-100 p-6 hover:bg-blue-150 cursor-pointer rounded-md"
                onClick={handleDraggableMarker}
              >
                <MdMyLocation size={16} />
              </div>
            </ToolTip>
            <div className="flex gap-10">
              <Button
                title="Open"
                size="sm"
                buttonColor="text-blue-400 bg-blue-100 hover:bg-blue-150"
                onClick={() => {
                  setProjectTicketId(ticket?.ticket_details?.id)
                  setTicketModal?.(true)
                  setDraggable(false)
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </Marker>
  )
}

export const MapResetOverlay = ({resetHandler}: {resetHandler: () => void}) => {
  return (
    <div className="leaflet-top leaflet-left mt-80">
      <div className="leaflet-control leaflet-bar">
        <div
          className="h-[30px] w-[30px] bg-white flex items-center justify-center cursor-pointer"
          onClick={() => {
            resetHandler()
          }}
        >
          <HiOutlineArrowsExpand size={18} />
        </div>
      </div>
    </div>
  )
}
