interface DescriptionBoxType {
	title: React.ReactNode
	children: React.ReactNode
	leftElement?: React.ReactNode
	rightElement?: React.ReactNode
	subHeaderElement?: React.ReactNode
	className?: string
	bodyClassName?: string
	trigger?: boolean
}

export const DescriptionBox = ({
	title,
	children,
	leftElement,
	rightElement,
	subHeaderElement,
	className,
	bodyClassName,
	trigger = true
}: DescriptionBoxType) => {
	return (
		<div className={`rounded-sm border-1 border-gray-200 mb-10 ${className}`}>
			<div className="bg-blue-100 py-12 rounded-t-sm border-b-1 border-b-gray-200  flex items-center justify-between px-12">
				<div className="w-full">
					<h1 className="text-md font-bold w-full">{title}</h1>
					{leftElement && <div>{leftElement}</div>}
				</div>

				{rightElement && <div>{rightElement}</div>}
			</div>

			{subHeaderElement}

			{trigger && (
				<div className={bodyClassName ? bodyClassName : 'p-16'}>{children}</div>
			)}
		</div>
	)
}
