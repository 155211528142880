import {
  ADD_PROJECT_PLAN,
  UPDATE_PROJECT_PLAN,
  DELETE_PROJECT_PLAN,
  GET_PROJECT_PLAN_LIST
} from './actionTypes.action'

import {api, APIS} from 'config'
import {errorHandler} from 'utils'

import {toast} from 'app/common'
import {getParsedUrl} from 'helpers'

/**
 * get the project list for a logged user
 */
export function getProjectPlanList(
  projectId: number,
  params: {page: number; limit: number; search?: string} = {
    page: 1,
    limit: 100
  },
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    dispatch({type: GET_PROJECT_PLAN_LIST.LOADING})

    const res = await api<Api.Base<Api.ProjectPlanList>>(
      getParsedUrl(`${APIS.projects}/${projectId}/plans`, params)
    )

    const {
      success,
      data: {
        message,
        data: {total, rows}
      }
    } = res.data

    if (success) {
      dispatch({type: GET_PROJECT_PLAN_LIST.SUCCESS, payload: {total, rows}})
      successCallback?.()
    } else {
      dispatch({type: GET_PROJECT_PLAN_LIST.ERROR, payload: message})
    }
  }
}

// CREATE PROJECT Plan

export function addProjectPlan(
  projectId: number,
  body: FormData,
  successCallback: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_PROJECT_PLAN.LOADING})

      res = await api<Api.Base<null>>(
        `${APIS.projects}/${projectId}/plans`,
        'POST',
        body
      )

      const {success, data} = res.data

      if (success) {
        dispatch({type: ADD_PROJECT_PLAN.SUCCESS})

        successCallback?.()
        toast.success(data.message)
        return 1
      } else {
        dispatch({type: ADD_PROJECT_PLAN.ERROR, payload: data?.message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_PROJECT_PLAN.ERROR})
      toast.error('Error adding the project plan!')
      return 0
    }
  }
}

// UPDATE PROJECT ACTION

export function updateProjectPlan(
  projectId: number | string,
  projectPlanId: number | string,
  body: FormData,
  successCallback?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_PROJECT_PLAN.LOADING})

      res = await api<Api.Base<null>>(
        `${APIS.projects}/${projectId}/plans/${projectPlanId}`,
        'PATCH',
        body
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_PROJECT_PLAN.SUCCESS})

        successCallback?.()
        toast.success('Project plan successfully updated!')
        return 1
      } else {
        dispatch({
          type: UPDATE_PROJECT_PLAN.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_PROJECT_PLAN.ERROR})

      return 0
    }
  }
}

export function deleteProjectPlan(
  projectId: number | string,
  projectPlanId: number | string,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_PROJECT_PLAN.LOADING})

      res = await api<Api.Base<null>>(
        `${APIS.projects}/${projectId}/plans/${projectPlanId}`,
        'DELETE'
      )

      const {
        success,
        data: {message}
      } = res.data

      if (success) {
        dispatch({type: DELETE_PROJECT_PLAN.SUCCESS})

        successCallback?.()
        toast.success('Project plan successfully deleted!')
        return 1
      } else {
        dispatch({
          type: DELETE_PROJECT_PLAN.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: DELETE_PROJECT_PLAN.ERROR})

      return 0
    }
  }
}
