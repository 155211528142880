import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {Button} from 'app/common'
import {MessageBox} from 'app/components'

import {FloorMap, FloorMapList, UploadFloorPlan} from './components'

export const PlanViewerPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)

  const [selectedPlan, setSelectedPlan] = useState<Api.ProjectPlanIndividual>()
  const [editedPlan, setEditedPlan] = useState<Api.ProjectPlanIndividual>()

  const {projectPlanList}: RT.ProjectPlanReduxType = useSelector(
    (state: any) => state.projectPlan
  )

  useEffect(() => {
    setSelectedPlan(projectPlanList?.at(0))
  }, [projectPlanList])

  return (
    <div className="w-full flex justify-center flex-col items-center mb-24">
      <h1 className="font-bold text-lg mb-4 self-start">Plan Viewer</h1>

      <div className="mt-20 mb-20 w-full">
        <div className="flex justify-between items-center mb-8 w-full">
          <div className="font-bold text-md">Project Plans</div>
          <div className="flex">
            <Button
              title="Upload Project Plan"
              onClick={() => {
                setEditedPlan(undefined)
                setIsOpen(true)
              }}
            />

            <UploadFloorPlan {...{isOpen, setIsOpen}} />
          </div>
        </div>
        <FloorMapList
          selectedPlan={selectedPlan}
          viewPlan={(plan) => {
            setSelectedPlan(plan)
          }}
          editPlan={(plan) => {
            setIsEditOpen(true)
            setEditedPlan(plan)
          }}
        />
      </div>
      {selectedPlan ? (
        <FloorMap plan={selectedPlan} />
      ) : (
        <MessageBox
          message={'You can select a project plan from the table above!'}
          error
        />
      )}

      <UploadFloorPlan
        {...{isOpen: isEditOpen, setIsOpen: setIsEditOpen, plan: editedPlan}}
      />
    </div>
  )
}
