import {
  UPDATE_COMPANY_DETAILS,
  GET_COMPANY_DETAILS,
  UPDATE_COMPANY_NAME,
  ADD_COMPANY_LOGO,
  UPDATE_COMPANY_FINANCIAL_DETAILS,

  // Banking actions
  GET_COMPANY_BANK_DETAILS,
  CREATE_COMPANY_BANK_DETAIL,
  UPDATE_COMPANY_BANK_DETAIL,
  DELETE_COMPANY_BANK_DETAIL,
  GET_TO_PRICE_LIST_FOR_DASHBOARD,
  GET_ACTIVE_LIST_FOR_DASHBOARD,
  GET_COMPELTED_LIST_FOR_DASHBOARD,
  GET_OVERDUE_TICKET,
  GET_ALL_LIST_FOR_DASHBOARD,
} from '../actions'

const initalState: RT.CompanyReduxType = {
  add_loading: false,
  get_loading: false,
  addCompanyLogoLoading: false,

  companyDetails: undefined,
  currencySymbol: '',

  companyBankDetails: undefined,
  companyBankDetailsTotal: 0,
  isCompanyBankDetailLoading: false,
  createCompanyBankDetailLoading: false,
  updateCompanyBankDetailLoading: false,
  deleteCompanyBankDetailLoading: false,

  updateCompanyFinancialLoading: false,

  isToPriceForDashboardLoading: false,
  isToPriceForDashboardError: false,
  toPriceListForDashboard: undefined,

  isActiveListForDashboardError: false,
  isActiveListForDashboardLoading: false,
  activeListForDashboard: undefined,

  isCompletedListForDashboardError: false,
  isCompletedListForDashboardLoading: false,
  completedListForDashboard: undefined,

  isOverdueTicketLoading: false,
  isOverdueTicketError: false,
  overdueTicketList: undefined,

  isAllProjectsListForDashboardError: false,
  isAllProjectsListForDashboardLoading: false,
  allProjectsListForDashboard: undefined,
}

export function companyReducer(state = initalState, action: any) {
  const {type, payload} = action

  switch (type) {
    case UPDATE_COMPANY_DETAILS.LOADING:
    case UPDATE_COMPANY_NAME.LOADING:
      return {...state, add_loading: true}

    case GET_COMPANY_DETAILS.LOADING:
      return {...state, get_loading: true}

    case UPDATE_COMPANY_DETAILS.SUCCESS:
    case UPDATE_COMPANY_NAME.SUCCESS:
      return {
        ...state,
        add_loading: false,
      }

    case GET_COMPANY_DETAILS.SUCCESS:
      return {
        ...state,
        get_loading: false,
        companyDetails: payload.companyDetails,
        currencySymbol: payload.currencySymbol,
      }

    case UPDATE_COMPANY_DETAILS.ERROR:
    case UPDATE_COMPANY_NAME.ERROR:
      return {...state, add_loading: false}

    case GET_COMPANY_DETAILS.ERROR:
      return {...state, get_loading: false, companyDetails: {}}

    case ADD_COMPANY_LOGO.LOADING:
      return {
        ...state,
        addCompanyLogoLoading: true,
      }
    case ADD_COMPANY_LOGO.SUCCESS:
      return {
        ...state,
        addCompanyLogoLoading: false,
      }
    case ADD_COMPANY_LOGO.ERROR:
      return {...state, addCompanyLogoLoading: false}

    case UPDATE_COMPANY_FINANCIAL_DETAILS.LOADING:
      return {
        ...state,
        updateCompanyFinancialLoading: true,
      }
    case UPDATE_COMPANY_FINANCIAL_DETAILS.SUCCESS:
      return {
        ...state,
        updateCompanyFinancialLoading: false,
      }
    case UPDATE_COMPANY_FINANCIAL_DETAILS.ERROR:
      return {...state, updateCompanyFinancialLoading: false}

    // * Company Bank Actions

    case GET_COMPANY_BANK_DETAILS.LOADING:
      return {...state, isCompanyBankDetailLoading: true}
    case GET_COMPANY_BANK_DETAILS.SUCCESS:
      return {
        ...state,
        isCompanyBankDetailLoading: false,
        companyBankDetails: payload.rows,
        companyBankDetailsTotal: payload.total,
      }
    case GET_COMPANY_BANK_DETAILS.ERROR:
      return {
        ...state,
        isCompanyBankDetailLoading: false,
      }

    case CREATE_COMPANY_BANK_DETAIL.LOADING:
      return {...state, createCompanyBankDetailLoading: true}
    case CREATE_COMPANY_BANK_DETAIL.SUCCESS:
      return {
        ...state,
        createCompanyBankDetailLoading: false,
      }
    case CREATE_COMPANY_BANK_DETAIL.ERROR:
      return {
        ...state,
        createCompanyBankDetailLoading: false,
      }

    case UPDATE_COMPANY_BANK_DETAIL.LOADING:
      return {...state, updateCompanyBankDetailLoading: true}
    case UPDATE_COMPANY_BANK_DETAIL.SUCCESS:
      return {
        ...state,
        updateCompanyBankDetailLoading: false,
      }
    case UPDATE_COMPANY_BANK_DETAIL.ERROR:
      return {
        ...state,
        updateCompanyBankDetailLoading: false,
      }

    case DELETE_COMPANY_BANK_DETAIL.LOADING:
      return {...state, deleteCompanyBankDetailLoading: true}
    case DELETE_COMPANY_BANK_DETAIL.SUCCESS:
      return {
        ...state,
        deleteCompanyBankDetailLoading: false,
      }
    case DELETE_COMPANY_BANK_DETAIL.ERROR:
      return {
        ...state,
        deleteCompanyBankDetailLoading: false,
      }

    // * Get company projects for dashboard

    case GET_TO_PRICE_LIST_FOR_DASHBOARD.LOADING:
      return {
        ...state,
        isToPriceForDashboardLoading: true,
        isToPriceForDashboardError: false,
      }

    case GET_TO_PRICE_LIST_FOR_DASHBOARD.ERROR:
      return {
        isToPriceForDashboardLoading: false,
        isToPriceForDashboardError: true,
      }

    case GET_TO_PRICE_LIST_FOR_DASHBOARD.SUCCESS:
      return {
        isToPriceForDashboardLoading: false,
        isToPriceForDashboardError: false,
        toPriceListForDashboard: payload,
      }

    case GET_ACTIVE_LIST_FOR_DASHBOARD.LOADING:
      return {
        ...state,
        isActiveListForDashboardLoading: true,
        isActiveListForDashboardError: false,
      }

    case GET_ACTIVE_LIST_FOR_DASHBOARD.ERROR:
      return {
        isActiveListForDashboardLoading: false,
        isActiveListForDashboardError: true,
      }

    case GET_ACTIVE_LIST_FOR_DASHBOARD.SUCCESS:
      return {
        isActiveListForDashboardLoading: false,
        isActiveListForDashboardError: false,
        activeListForDashboard: payload,
      }

    case GET_COMPELTED_LIST_FOR_DASHBOARD.LOADING:
      return {
        ...state,
        isCompletedListForDashboardLoading: true,
        isCompletedListForDashboardError: false,
      }

    case GET_COMPELTED_LIST_FOR_DASHBOARD.ERROR:
      return {
        isCompletedListForDashboardLoading: false,
        isCompletedListForDashboardError: true,
      }

    case GET_COMPELTED_LIST_FOR_DASHBOARD.SUCCESS:
      return {
        isCompletedListForDashboardLoading: false,
        isCompletedListForDashboardError: false,
        completedListForDashboard: payload,
      }

    case GET_ALL_LIST_FOR_DASHBOARD.LOADING:
      return {
        ...state,
        isAllProjectsListForDashboardLoading: true,
        isAllProjectsListForDashboardError: false,
      }

    case GET_ALL_LIST_FOR_DASHBOARD.ERROR:
      return {
        isAllProjectsListForDashboardLoading: false,
        isAllProjectsListForDashboardError: true,
      }

    case GET_ALL_LIST_FOR_DASHBOARD.SUCCESS:
      return {
        isAllProjectsListForDashboardLoading: false,
        isAllProjectsListForDashboardError: false,
        allProjectsListForDashboard: payload,
      }

    case GET_OVERDUE_TICKET.LOADING:
      return {
        ...state,
        isOverdueTicketLoading: true,
        isOverdueTicketError: false,
      }

    case GET_OVERDUE_TICKET.ERROR:
      return {
        isOverdueTicketLoading: false,
        isOverdueTicketError: true,
        overdueTicketList: undefined,
      }

    case GET_OVERDUE_TICKET.SUCCESS:
      return {
        isOverdueTicketLoading: false,
        isOverdueTicketError: false,
        overdueTicketList: payload,
      }
    default:
      return state
  }
}
