import {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  createContext,
  useMemo,
} from 'react'
import L, {LatLngTuple, PointExpression} from 'leaflet'
import {MapContainer, Marker, useMap, Popup} from 'react-leaflet'
import {useDispatch, useSelector} from 'react-redux'
import {TiTick} from 'react-icons/ti'
import {FaTimes} from 'react-icons/fa'
import {MdMyLocation} from 'react-icons/md'

import {Loading, toast} from 'app/common'
import {MapResetOverlay, MessageBox} from 'app/components'
import {getTileUrl} from 'helpers'
import {setMarkerCoordinateAction, updateTicketActions} from 'redux-src'
import {useNavigation} from 'react-auth-navigation'
import {TicketContext} from '../../ticket'

interface MapOptionsType {
  maxZoom?: number
  mapDim?: {width: number; height: number}
  center?: LatLngTuple
  bounds?: LatLngTuple[]
  offset?: {x: number; y: number}
}

const MAP_CONTAINER_HEIGHT = 600

const offset = {x: 5, y: 5}
// const MAP_DIM = {width: 3370, height: 2384}
const MAP_DIM: {width: number; height: number} = {
  width: 595,
  height: 842,
}
// const MAP_DIM = {width: 2592, height: 1728}

const CENTER: LatLngTuple = [MAP_DIM.height / 2, MAP_DIM.width / 2] // Here latLng is (y,x);
const BOUNDS: LatLngTuple[] = [
  [0, 0],
  [MAP_DIM.width, MAP_DIM.height],
]

const FloorMapContext = createContext<
  Comp.PlanPositionMapProps & MapOptionsType
>({
  plan: undefined,
  mapContainerHeight: 600,
  ticketStatus: 'open',
  maxZoom: 4,
  mapDim: MAP_DIM,
  center: CENTER,
  bounds: BOUNDS,
  offset,
})

export const PlanPositionMap = ({
  mapContainerHeight,
  plan,
  ticketStatus = 'open',
  edit,
  planCallback,
}: Comp.PlanPositionMapProps) => {
  const dispatch = useDispatch()

  const {markerCoordinates: markerFromRedux}: RT.TicktesReduxType = useSelector(
    (state: any) => state.tickets,
  )

  const [loading, setLoading] = useState<boolean>(true)

  const {
    getProjectTicketListLoading,
    updateTicketLoading,
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
      setLoading(true)
    }
  }, [])

  const mapOptions: MapOptionsType = useMemo(() => {
    const mapDim = {
      width: plan?.project_plan_details?.width
        ? plan?.project_plan_details?.rotation === 90 &&
          plan?.project_plan_details?.width > plan?.project_plan_details?.height
          ? plan?.project_plan_details?.width
          : plan?.project_plan_details?.height
        : MAP_DIM.width,
      height: plan?.project_plan_details?.height
        ? plan?.project_plan_details?.rotation === 90 &&
          plan?.project_plan_details?.width > plan?.project_plan_details?.height
          ? plan?.project_plan_details?.height
          : plan?.project_plan_details?.width
        : MAP_DIM.width,
    }

    return {
      maxZoom: plan?.project_plan_details?.max_zoom_level ?? 4,
      mapDim,
      center: [mapDim.height / 2, mapDim.width / 2],
      bounds: [
        [0, 0],
        [mapDim.height, mapDim.width],
      ],
      offset,
    }
  }, [
    plan?.project_plan_details?.height,
    plan?.project_plan_details?.max_zoom_level,
    plan?.project_plan_details?.rotation,
    plan?.project_plan_details?.width,
  ])

  const updatePlanPosition = useCallback(
    (
      markerCoordinates: LatLngTuple,
      reportLayerCoordinates: LatLngTuple,
      cb?: () => void,
    ) => {
      dispatch(
        setMarkerCoordinateAction(
          {x: markerCoordinates.at(0), y: markerCoordinates.at(1)},
          {x: reportLayerCoordinates.at(0), y: reportLayerCoordinates.at(1)},
          cb,
        ),
      )
    },
    [dispatch],
  )

  return (
    <FloorMapContext.Provider
      value={{
        mapContainerHeight,
        plan,
        ticketStatus,
        planCallback,
        ...mapOptions,
      }}
    >
      {!!!plan ||
      !!loading ||
      !!getProjectTicketListLoading ||
      !!updateTicketLoading ? (
        mapContainerHeight && Number(mapContainerHeight) < 600 ? (
          <div
            className={`flex justify-center items-center`}
            style={{
              height: mapContainerHeight,
            }}
          >
            <Loading small />
          </div>
        ) : (
          <Loading />
        )
      ) : (
        <>
          <div
            style={{
              height: mapContainerHeight ?? MAP_CONTAINER_HEIGHT,
              overflow: 'hidden',
              zIndex: '230000000 !important',
              isolation: 'isolate',
              width: '100%',
              borderRadius: 8,
            }}
          >
            {plan?.project_plan_details.from_pdf === null ? (
              plan?.project_plan_details.has_error ? (
                <MessageBox
                  error
                  message={
                    <div className="flex gap-10">
                      Error! Can't process the project plan, please re-upload
                      file.
                    </div>
                  }
                />
              ) : (
                <MessageBox
                  message={
                    <div className="flex gap-10">
                      <Loading small /> Project plan is processing... please
                      comeback after a while.
                    </div>
                  }
                />
              )
            ) : plan?.project_plan_details.from_pdf === true ? (
              <MapContainer
                // center={mapOptions.center}
                zoom={1}
                minZoom={1}
                maxZoom={mapOptions.maxZoom}
                bounds={BOUNDS}
                boundsOptions={{
                  maxZoom: mapOptions.maxZoom,
                  padding: [0, 0],
                }}
                crs={L.CRS.Simple}
                attributionControl={false}
                closePopupOnClick
                style={{
                  width: '100%',
                  height: '100%',
                  zIndex: '1 !important',
                }}
              >
                <TileMap
                  projectId={plan?.project_plan_details.project_id}
                  projectPlanId={plan?.project_plan_details.id}
                />

                <MarkerComp
                  key={`tile-marker-set-plan-position`}
                  marker={
                    edit
                      ? {
                          x: markerFromRedux?.x ?? -80,
                          y: markerFromRedux?.y ?? 80,
                        }
                      : markerFromRedux
                  }
                  updatePlanPosition={updatePlanPosition}
                  edit={edit}
                />
              </MapContainer>
            ) : (
              <MessageBox
                message={
                  <div className="flex gap-10">
                    <Loading small /> Project plan is processing... please
                    comeback after a while.
                  </div>
                }
              />
            )}
          </div>
        </>
      )}
    </FloorMapContext.Provider>
  )
}

// MARK: - TileMap
const TileMap = ({
  projectId,
  projectPlanId,
}: {
  projectId: number
  projectPlanId: number
}) => {
  const {maxZoom, mapDim, center} = useContext(FloorMapContext)

  const map = useMap()
  const layerRef = useRef<L.LayerGroup>(null)

  useEffect(() => {
    layerRef.current = L.layerGroup().addTo(map)
    layerRef?.current?.clearLayers()
  }, [map])

  useEffect(() => {
    if (map) {
      L.tileLayer(getTileUrl(projectId, projectPlanId), {
        minZoom: 1,
        maxZoom,
      })?.addTo(map)

      map.fitBounds(BOUNDS)
    }
  }, [map, maxZoom, projectId, projectPlanId])

  useEffect(() => {
    layerRef?.current?.clearLayers()
  }, [])

  const unprojectedCenter = useMemo(() => {
    const unprojectedCenter = map.unproject(center as PointExpression, maxZoom)
    return {x: unprojectedCenter.lat * 4, y: unprojectedCenter.lng * 4}
  }, [center, map, maxZoom])

  return (
    <>
      <div
        id="map"
        style={{height: mapDim.height ?? MAP_CONTAINER_HEIGHT, zIndex: 1}}
      />

      <MapResetOverlay
        resetHandler={() => {
          map?.panTo(
            [unprojectedCenter?.x ?? -80, unprojectedCenter?.y ?? 80],
            {
              noMoveStart: true,
              animate: false,
            },
          )
          map?.setZoom(1)
        }}
      />
    </>
  )
}

export const MarkerComp = ({
  marker: markerProp,
  updatePlanPosition,
  edit,
}: {
  marker: {x: number; y: number}
  edit?: boolean
  updatePlanPosition: (
    layerCoordinates: LatLngTuple,
    reportLayerCoordinates: LatLngTuple,
    cb?: () => void,
  ) => void
}) => {
  const {plan, ticketStatus, planCallback, center} = useContext(FloorMapContext)
  const {projectTicketId: projectTicketIdFromContext} =
    useContext(TicketContext)

  const [isDraggable, setDraggable] = useState<boolean>(true)

  const map = useMap()

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId, ticketId} = params as any
  const projectTicketId = ticketId ?? projectTicketIdFromContext

  const maxZoom = map.getMaxZoom()

  const marker: {x: number; y: number} = useMemo(() => {
    if (markerProp) {
      return markerProp
    }
    const unprojectedCenter = map.unproject(center as PointExpression, maxZoom)
    return {x: unprojectedCenter.lat * 4, y: unprojectedCenter.lng * 4}
  }, [center, map, markerProp, maxZoom])

  const originalPoint: [number, number] = useMemo(() => {
    return [marker?.x ?? 0, marker?.y ?? 0]
  }, [marker])

  const layerCoordRef = useRef({x: originalPoint.at(0), y: originalPoint.at(1)})
  const reportLayerCoordRef = useRef({
    x: originalPoint.at(0),
    y: originalPoint.at(1),
  })

  const mapFlyTo = useCallback(
    (x: number, y: number) => {
      map?.flyTo([x ?? -80, y ?? 80], maxZoom)
    },
    [map, maxZoom],
  )

  const iconSize: [number, number] = useMemo(() => [30, 40], [])

  let statusIcons: Record<
    'open' | 'in progress' | 'feedback' | 'completed',
    L.Icon
  > = {
    open: L.icon({
      iconUrl: '/map_pointer_open.svg',
      iconSize,
    }),
    'in progress': L.icon({
      iconUrl: '/map_pointer_progress.svg',
      iconSize,
    }),
    feedback: L.icon({
      iconUrl: '/map_pointer_feedback.svg',
      iconSize,
    }),
    completed: L.icon({
      iconUrl: '/map_pointer_completed.svg',
      iconSize,
    }),
  }

  let draggableIcon = L.icon({
    iconUrl: '/map_pointer_draggable.svg',
    iconSize: [35, 48],
  })

  const handleDraggableMarker = () => {
    setDraggable((prev) => !prev)
    mapFlyTo(marker?.x, marker?.y)
  }

  const handleSaveLayerCoordinates = useCallback(() => {
    const markerCoordinates = layerCoordRef.current
    const reportLayerCoordinates = reportLayerCoordRef.current

    if (!!markerCoordinates) {
      const body: {
        layerCoordinates: LatLngTuple
        reportLayerCoordinates: LatLngTuple
      } = {
        layerCoordinates: [
          markerCoordinates?.x ?? marker.x,
          markerCoordinates?.y ?? marker.y,
        ],
        reportLayerCoordinates: [
          reportLayerCoordinates?.x ?? marker.x,
          reportLayerCoordinates?.y ?? marker.y,
        ],
      }
      map.setZoom(1)

      edit &&
        dispatch(
          updateTicketActions(projectId, projectTicketId, body, () => {
            planCallback?.()
            map?.panTo(
              [markerCoordinates?.x ?? -80, markerCoordinates?.y ?? 80],
              {
                noMoveStart: true,
                animate: false,
              },
            )
          }),
        )

      updatePlanPosition(
        body.layerCoordinates,
        body.reportLayerCoordinates,
        () => {
          map?.panTo(
            [markerCoordinates?.x ?? -80, markerCoordinates?.y ?? 80],
            {
              noMoveStart: true,
              animate: false,
            },
          )
        },
      )
    } else {
      return toast.error('Please set the plan position first !!')
    }

    setDraggable(false)
  }, [
    dispatch,
    edit,
    map,
    marker.x,
    marker.y,
    planCallback,
    projectId,
    projectTicketId,
    updatePlanPosition,
  ])

  const rerenderFlag = useRef<boolean>(false)
  const handleDragData = (d?: any) => {
    const [x, y] = [
      d?.target?.getLatLng()?.lat ?? marker.x,
      d?.target?.getLatLng()?.lng ?? marker.y,
    ]

    mapFlyTo(x, y)

    //* projectedPoint for original LatLngTuple with default zoom = 4
    const projectedPoint4 = map.project([x, y], maxZoom)

    const midPointIconSize: [number, number] = [
      iconSize.at(0) / 2,
      iconSize.at(1) / 2,
    ]

    //* projectedPoint recalculated with the offset of the marker icon size
    const projectedPointAdd4 = projectedPoint4.add(midPointIconSize).divideBy(4)

    //* funtion to calculate optimal point with respect to offset.x and offset.y percentage
    const getOptimalPoint = (x: number, y: number) => ({
      x: x - (offset.x / 100) * x,
      y: y - (offset.y / 100) * y,
    })

    //* optimal point with respect to offset.x and offset.y percentage
    const optimalPoint4 = getOptimalPoint(
      projectedPointAdd4.x,
      projectedPointAdd4.y,
    )

    const finalOffset = {x: 0.009, y: 0.009}

    const finalPoint: [number, number] =
      plan?.project_plan_details?.rotation === 90 &&
      plan?.project_plan_details?.height > plan?.project_plan_details?.width
        ? [
            optimalPoint4.y + optimalPoint4.y * finalOffset.y,
            optimalPoint4.x + optimalPoint4.x * finalOffset.x,
          ]
        : [
            optimalPoint4.x,
            plan?.project_plan_details?.height - optimalPoint4.y,
          ]

    layerCoordRef.current = {
      x: x ?? marker.x,
      y: y ?? marker.y,
    }
    reportLayerCoordRef.current = {
      x: finalPoint.at(0),
      y: finalPoint.at(1),
    }
    rerenderFlag.current = true
  }

  const markerRef = useRef<L.Marker>(null)

  useEffect(() => {
    if (map && markerRef.current) {
      markerRef.current?.openPopup()
    }
    !rerenderFlag.current && handleDragData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker, map])

  return (
    <Marker
      position={[marker.x, marker.y]}
      icon={isDraggable ? draggableIcon : statusIcons[ticketStatus]}
      draggable={isDraggable}
      ref={markerRef}
      eventHandlers={{
        dragend(d) {
          handleDragData(d)
          d?.target?.openPopup()
        },
        click() {
          mapFlyTo(
            isDraggable ? layerCoordRef.current.x ?? marker.x : marker.x,
            isDraggable ? layerCoordRef.current.y ?? marker.y : marker.y,
          )
        },
      }}
    >
      {isDraggable ? (
        <Popup
          className="draggable-actions plan-position-marker"
          autoClose={false}
          closeOnClick={false}
          closeButton={false}
          closeOnEscapeKey={false}
          key={'draggable-popup'}
        >
          <div className="flex gap-16 flex-nowrap">
            <div
              className="flex items-center cursor-pointer justify-center border-[4px] border-red-500 bg-white rounded-full h-[44px] w-[44px]"
              onClick={() => {
                setDraggable(false)
                mapFlyTo(marker.x, marker.y)
              }}
            >
              <FaTimes size={16} />
            </div>
            <div
              className="flex border-[4px] border-green-500 bg-white cursor-pointer rounded-full items-center justify-center h-[44px] w-[44px]"
              onClick={handleSaveLayerCoordinates}
            >
              <TiTick size={22} />
            </div>
          </div>
        </Popup>
      ) : (
        <Popup
          maxWidth={280}
          minWidth={280}
          className={`${isDraggable} "plan-position-marker"`}
          key={'original-popup'}
        >
          <div className="flex justify-start items-center pt-10 gap-16">
            <div
              className="bg-blue-100 p-6 hover:bg-blue-150 cursor-pointer rounded-md"
              onClick={handleDraggableMarker}
            >
              <MdMyLocation size={16} />
            </div>
            <div className="flex gap-10 font-sm text-gray-500">
              {'Click this icon to reposition.'}
            </div>
          </div>
        </Popup>
      )}
    </Marker>
  )
}
