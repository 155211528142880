// import React, { useEffect, useState } from 'react'
import {useEffect, useState} from 'react'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {BsArrowRight, BsThreeDotsVertical} from 'react-icons/bs'
import {
  FaCaretDown,
  FaHourglassEnd,
  FaHourglassStart,
  FaPlus,
  FaSuitcaseRolling,
  FaUser,
} from 'react-icons/fa'
import {MdLocationPin} from 'react-icons/md'
import {RiFile4Fill} from 'react-icons/ri'

import {NotesSummary} from 'app/components'
import {
  ConfirmationModal,
  Dropdown,
  Loading,
  Modal,
  ToolTip,
  toast,
} from 'app/common'
import {capitalizeFirstLetter} from 'utils'
import {
  createProjectVariation,
  getCustomerNotesAction,
  getProjectVariation,
} from 'redux-src'
import {useNavigation} from 'react-auth-navigation'
import {JobDetails} from 'app/pages/newJob/components'

export const JobsHeader = () => {
  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )
  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )
  const {customerNotesTotal}: RT.NotesReduxType = useSelector(
    (state: any) => state.noteHistory,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    projectDetail?.project_details?.customer_id &&
      dispatch(
        getCustomerNotesAction(projectDetail?.project_details?.customer_id),
      )
  }, [dispatch, projectDetail?.project_details?.customer_id])

  useEffect(() => {
    if (projectDetail?.project_details?.id) {
      dispatch(getProjectVariation(projectDetail?.project_details?.id))
    }
  }, [projectDetail?.project_details?.id])

  const {projectVariations, isProjectVariationLoading}: RT.ProjectReduxType =
    useSelector((state: any) => state.project)

  // console.log(projectVariations)
  const [variationType, setVariationType] = useState<'Quote' | 'Days Work'>(
    'Quote',
  )
  const [showCreateVariation, setShowCreateVariation] = useState(false)
  const [isCreateVariationLoading, setIsCreateVariationLoading] =
    useState(false)
  return (
    <div className="p-16 pt-30 bg-blue-100">
      <div className="flex justify-between items-center mb-10">
        <div className="font-bold text-lg flex">
          <span>
            {projectDetail?.project_details?.project_prefix +
              '-' +
              projectDetail?.project_details?.project_number}{' '}
          </span>
          <span className="ml-10 border-r-[4px] border-black"></span>
          <div className="block ml-16">
            <input
              className="border border-1 border-dashed border-gray-300 bg-transparent font-bold w-[max-content]"
              value={projectDetail?.project_details?.title ?? ''}
              onChange={() => false}
            />
          </div>
        </div>

        <div className="flex">
          <div>
            <label>
              Project Type
              <select
                className="border-1 border-gray-200 inline-block mx-6 p-4 rounded-sm"
                value={capitalizeFirstLetter(
                  projectDetail?.project_details?.project_type ?? '',
                )}
                disabled
              >
                <option>Days Work</option>
                <option>Quote</option>
              </select>
            </label>
          </div>
          <button className="border-1 border-gray-200 inline-block p-4 rounded-sm bg-white">
            <BsThreeDotsVertical />
          </button>
        </div>
      </div>

      <div className="flex justify-between my-8">
        <div className="flex items-center gap-8 lg:flex-wrap">
          {/* Variations control */}
          {/* {!projectDetail?.project_parent_details && ( */}
          <Dropdown
            triggerElement={
              <div className="min-w-[max-content] text-sm group flex items-center gap-4 bg-white rounded-sm px-6 py-4 cursor-pointer hover:text-white hover:bg-blue-300">
                <FaSuitcaseRolling className="text-blue-300 group-hover:text-white" />
                <ToolTip text="Variations" top>
                  <span>
                    {!!projectVariations && projectVariations?.isParent
                      ? projectVariations?.variations?.length ?? 0
                      : 1}{' '}
                    Variations
                  </span>
                </ToolTip>
                <FaCaretDown className="text-blue-300 group-hover:text-white" />
              </div>
            }
          >
            <div className="w-[300px] flex flex-col bg-white mt-16 p-8 rounded-md shadow-lg">
              <div className="flex flex-row justify-between h-fit items-center p-[10px] border-0 border-b-1 border-gray-200">
                <div className="text-md font-bold cursor-pointer">
                  Variations
                </div>
                {projectVariations?.isParent && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowCreateVariation(true)
                    }}
                    className="bg-blue-300 hover:bg-blue-400 px-20 py-8  text-sm text-white cursor-pointer rounded-sm whitespace-nowrap font-bold"
                  >
                    <span className="my-6">Create Variation</span>
                  </button>
                )}
              </div>
              <div className="w-full">
                {isProjectVariationLoading && <Loading />}
                {projectVariations?.isParent === false && (
                  <VariationTab
                    key={projectVariations?.parentProject?.title}
                    data={projectVariations?.parentProject}
                    hasParent
                  />
                )}

                {projectVariations?.isParent && !!projectVariations && (
                  <>
                    {projectVariations?.variations?.length > 0 ? (
                      projectVariations?.variations?.map((variation) => (
                        <VariationTab key={variation.title} data={variation} />
                      ))
                    ) : (
                      <div className="break-all font-bold p-14 text-red-300">
                        There are no variation at the moment.
                      </div>
                    )}
                  </>
                )}

                {}
              </div>
            </div>
          </Dropdown>
          {/* )} */}
          <Modal
            toggleModal={setShowCreateVariation}
            isActive={showCreateVariation}
            title={'New Variation'}
            size="md"
          >
            <div className="-py-20 px-10">
              <JobDetails
                existingData={{
                  title: projectDetail?.project_details?.title,
                  description: projectDetail?.project_details?.description,
                  projectEnd: projectDetail?.project_details?.end_date,
                  projectStart: projectDetail?.project_details?.start_date,
                  projectType: projectDetail?.project_details?.project_type,
                }}
                detailsForJob={[]}
                onCustomSubmit={(data: any) => {
                  if (!!data.projectType) {
                    let temp = {...data}
                    Object.entries(data).forEach(([key, value]) => {
                      if (!value || value === '') {
                        delete temp[key]
                      }
                    })

                    dispatch(
                      createProjectVariation(
                        projectDetail?.project_details?.id,
                        {...temp, payRate: data.payRate ?? 0},
                        () => {
                          setIsCreateVariationLoading(false)
                          setShowCreateVariation(false)
                          dispatch(
                            getProjectVariation(
                              projectDetail?.project_details?.id,
                            ),
                          )
                        },
                        () => {
                          setIsCreateVariationLoading(false)
                        },
                      ),
                    )
                  } else {
                    toast.error('Please select a Project Type')
                  }
                }}
              />
            </div>
          </Modal>
          {/* Project Details */}
          <div className="min-w-[max-content] text-sm group flex items-center gap-4 bg-white rounded-sm px-6 py-4 cursor-pointer hover:text-white hover:bg-blue-300">
            <FaUser className="text-blue-300 group-hover:text-white" />
            <ToolTip text="Customer" top>
              <span className="border-r-1 border-gray-200 pr-4">
                {projectDetail?.customer_contact_details?.firstname}{' '}
                {projectDetail?.customer_contact_details?.lastname}
              </span>
            </ToolTip>

            <ToolTip text="Customer Notes" top>
              <span className="flex items-center gap-6 ">
                <RiFile4Fill className="text-[inherit] " />
                <span>{customerNotesTotal}</span>
              </span>
            </ToolTip>
          </div>
          {/* w-[200px] xl:w-[285px] truncate */}
          <ToolTip text="Site Address" top>
            <div className="max-w-[max-content] text-sm group flex items-center gap-4 bg-white rounded-sm px-6 py-4 cursor-pointer hover:text-white hover:bg-blue-300">
              <MdLocationPin className="text-blue-300 relative -top-1 group-hover:text-white" />
              <span>{projectDetail?.customer_address_details?.address}</span>
            </div>
          </ToolTip>
          <div className="bg-white rounded-sm px-6 py-4 flex text-sm group items-center gap-16 cursor-pointer hover:text-white hover:bg-blue-300">
            <ToolTip text={'Project Start'} top>
              <span className="flex items-center gap-6">
                <FaHourglassStart className="text-blue-300 group-hover:text-white" />
                {moment(projectDetail?.project_details?.start_date).format(
                  'ddd, MMM DD YYYY',
                )}
              </span>
            </ToolTip>
            <span className="text-gray-300 group-hover:text-white">
              <BsArrowRight />
            </span>
            <ToolTip text={'Project End'} top>
              <span className="flex items-center gap-6">
                <FaHourglassEnd className="text-blue-300 group-hover:text-white" />
                {moment(projectDetail?.project_details?.end_date).format(
                  'ddd, MMM DD YYYY',
                )}
              </span>
            </ToolTip>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-full flex flex-nowrap gap-16 items-center">
            <div className="text-sm text-gray-400  whitespace-nowrap">
              Created at{' '}
              {moment(projectDetail?.project_details?.created_at).format(
                'ddd, MMM DD YYYY',
              )}{' '}
              {/* by {companyDetails?.company_details?.name} */}
            </div>
            <div className="bg-red-300 text-white p-4 px-10 rounded-sm font-bold text-sm w-[max-content] whitespace-nowrap">
              TERMS OF TRADE NOT SENT
            </div>
          </div>
        </div>
      </div>

      <NotesSummary
        type="project"
        hostId={projectDetail?.project_details?.id}
      />
    </div>
  )
}

function VariationTab({
  data,
  hasParent,
}: {
  data: Api.Variation
  hasParent?: boolean
}) {
  const {title, description, id, project_number, project_prefix} = data
  const status = 'active'
  const {
    navigation: {navigate},
  } = useNavigation()
  return (
    <div
      className="variation-tab hover:bg-blue-100"
      onClick={() => {
        navigate(`/projects/${id}/projects-summary`)
      }}
    >
      <div className="text-section w-2/3">
        <div className="text-md">
          {' '}
          {project_prefix}-{project_number} | {title}
        </div>
        <div className="break-all text-gray-300">{description}</div>
      </div>

      {hasParent && (
        <div className="status-section">
          <div
            className={`badge ${
              status === 'active' ? 'bg-green-300' : 'bg-red-300'
            } p-[2px] rounded-sm text-white w-[55px] flex justify-center uppercase text-sm`}
          >
            Parent
          </div>
        </div>
      )}
    </div>
  )
}
