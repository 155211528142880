import {useCallback, useEffect, useMemo, useState} from 'react'
import {HiDotsHorizontal} from 'react-icons/hi'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import {FaSearch} from 'react-icons/fa'

import {Button, Input, Dropdown} from 'app/common'

import {SiteModal, SiteTable} from './components'
import {SiteType} from './sites.type'

import {getSite, getArchivedSite} from 'redux-src'
import {useDebounceValue} from 'hooks'

import colors from 'tailwindcss/colors'
import {useQuery} from 'react-auth-navigation'

export const SitesPage = () => {
  //! REDUX HANDLING
  const dispatch = useDispatch()
  const {sites, siteTotal, archivedSiteList, archivedTotal}: RT.SiteReduxType =
    useSelector((state: any) => state.site)

  //* STATE FOR TOGGLING CUSTOMER MODAL
  const [newSiteModal, setNewSiteModal] = useState(false)

  //* HANDLE NAVIGATION OPEN MODAL
  const query: any = useQuery()
  const {create} = query
  useEffect(() => {
    setNewSiteModal(!!create)
  }, [create])

  //* FORM STATES
  const [filterData, filterHandler] = useFormInput({
    filterValue: undefined,
    archivedFilterValue: undefined,
  })

  const search = useDebounceValue(filterData?.filterValue)
  const debounceArchivedFilterValue = useDebounceValue(
    filterData?.archivedFilterValue,
  )

  //? HELPER FUNCTION TO REMAP SITES FROM API INTO CUSTOMER LIST
  const siteRemapped = useMemo(() => {
    const _temp: SiteType = []
    sites &&
      sites?.length > 0 &&
      sites?.map((item: any) => {
        return _temp.push({
          id: item.site_details?.id,
          name: item.site_details?.status,
          address: item.physical_address_details?.address,
          defaultSiteContact: `${item.main_contact_details?.firstname} ${item.main_contact_details?.lastname}`,
          phone: item.main_contact_details?.phones
            ? item.main_contact_details?.phones[0]
            : '',
          jobs: 0,
        })
      })

    return _temp
  }, [sites])

  const siteAddCallback = useCallback(
    (page?: number, limit?: number) => {
      //? GETTING CUSTOMERS SPECIFIC TO LOGGED IN CLIENTID
      dispatch(
        getSite({
          page,
          limit,
          status: 'active',
          search: search?.length > 0 ? search : undefined,
        }),
      )
    },
    [dispatch, search],
  )

  const archivedSiteRemapped = useMemo(() => {
    const _temp: SiteType = []
    archivedSiteList &&
      archivedSiteList?.length > 0 &&
      archivedSiteList.map((item: any) => {
        return _temp.push({
          id: item.site_details?.id,
          name: item.site_details?.status,
          address: item.physical_address_details?.address,
          defaultSiteContact: `${item.main_contact_details?.firstname} ${item.main_contact_details?.lastname}`,
          phone: item.main_contact_details?.phones
            ? item.main_contact_details?.phones[0]
            : '',
          jobs: 0,
        })
      })
    return _temp
  }, [archivedSiteList])

  const archiveSiteCallback = useCallback(
    (page?: number, limit?: number) => {
      //? GETTING CUSTOMERS SPECIFIC TO LOGGED IN CLIENTID
      dispatch(
        getArchivedSite({
          page,
          limit,
          status: 'archived',
          search:
            debounceArchivedFilterValue?.length > 0
              ? debounceArchivedFilterValue
              : undefined,
        }),
      )
    },
    [dispatch, debounceArchivedFilterValue],
  )

  useEffect(() => {
    siteAddCallback()
  }, [siteAddCallback])

  useEffect(() => {
    archiveSiteCallback()
  }, [archiveSiteCallback])

  return (
    <div className="w-9/12 mx-auto">
      <div className="flex bg-white">
        <div className="flex-1 w-full p-32 pb-0 min-h-[85vh]">
          <h3 className="font-bold text-lg">Sites</h3>

          <h6 className="text-md my-10">
            Search, filter, review and edit site details for all sites. Sites is
            your workbench for site management.
          </h6>

          <div className=" pb-20 mb-20 border-b-1 border-gray-200 mb-5 flex items-center justify-between">
            <div className="md-10">
              <Input
                name="filterValue"
                value={filterData.filterValue}
                type="text"
                placeholder="Filter By Address"
                prepend={<FaSearch size={16} color={colors.gray[500]} />}
                className="w-full text-sm"
                containerClass="w-full"
                onChange={filterHandler.onChange}
              />
            </div>

            <div className="">
              <div className="flex flex-wrap">
                <div>
                  <Button
                    className="mr-20"
                    size="sm"
                    title="Add New Site"
                    onClick={() => {
                      setNewSiteModal((prev) => !prev)
                    }}
                  />
                </div>

                <div>
                  <Dropdown
                    placement="bottomright"
                    triggerElement={
                      <button className="p-10 py-6 bg-gray-200 shadow-sm rounded-sm border-1 border-gray-200 hover:bg-gray-300">
                        <HiDotsHorizontal size={20} />
                      </button>
                    }
                    triggerToggle
                  >
                    <div style={{minWidth: '12rem', zIndex: 1}}>
                      <div className=" w-full mt-10">
                        {/* <DropDownFiles /> */}

                        <div
                          className="flex-1 w-full   overflow-visible "
                          style={{width: 200}}
                        >
                          <ul className=" border-gray-300 rounded-sm shadow-xl overflow-auto ">
                            <li className="p-10   bg-white text-black hover:bg-blue-100  text-md">
                              <span className="text-left inline-flex cursor-pointer">
                                Merge Sites
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>

          <SiteTable
            sites={siteRemapped}
            siteTotal={siteTotal}
            siteAddCallback={siteAddCallback}
            status="active"
          />

          <div className="mt-28">
            <div className="flex-1 w-full">
              <h3 className="font-bold text-lg">Archived Sites</h3>

              <div className=" pb-20 mb-20 border-b-1 border-gray-200 mb-5 flex items-center justify-between">
                <div className="md-10">
                  <Input
                    name="archivedFilterValue"
                    value={filterData.archivedFilterValue}
                    type="text"
                    placeholder="Filter By Address"
                    prepend={<FaSearch size={16} color={colors.gray[500]} />}
                    className="w-full text-sm"
                    containerClass="w-full"
                    onChange={filterHandler.onChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <SiteTable
            sites={archivedSiteRemapped}
            siteTotal={archivedTotal}
            siteAddCallback={archiveSiteCallback}
            status="archived"
          />

          <SiteModal
            newSiteModal={newSiteModal}
            setNewSiteModal={setNewSiteModal}
            siteAddCallback={siteAddCallback}
          />
        </div>
      </div>
    </div>
  )
}
