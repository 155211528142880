import { Logo } from '../../../../common'

import EmailSuccess from '../../../../../assets/images/icon-email-send.png'

export const RegisterPreConfirmationPage = () => {
	return (
		<div className="px-16 lg:px-40 xl:px-56 w-full">
			<div className="-mx-16  flex items-center   min-h-screen justify-center">
				<div className="w-full sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-4/12 px-16  py-40">
					<div className="w-full text-center">
						<div className="flex justify-center mb-32">
							<Logo />
						</div>
						<div className="bg-white w-full py-56 px-20 shadow-default">
							<div className="flex items-center justify-center mb-32">
								<img src={EmailSuccess} alt="success" />
							</div>
							<h2 className="mb-6 text-2xl font-bold font-merriweather text-gray-900">
								Please Confirm Your Email
							</h2>
							<p className="mb-24">
								We've sent an email with a link to activate your trial account.{' '}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
