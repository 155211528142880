import React from 'react'
import {Auth} from 'react-auth-navigation'
import {MaterialElement, MaterialElementGroup} from './component'

export const MaterialElementManagamentPage = () => {
  return (
    <div>
      <Auth.Screens path="/settings/material-list" />
    </div>
  )
}

export const MaterialElementManagamentComp = () => {
  return (
    <div className="flex flex-col gap-40 m-10 mt-4 mb-10 min-h-[85vh]">
      <MaterialElement />
      <MaterialElementGroup />
    </div>
  )
}
