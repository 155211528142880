import {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'

import {DataTable, DescriptionBox} from 'app/components'
import {Button, Checkbox, Modal, SelectField, ToolTip} from 'app/common'

import {
  addInvoiceLineItemWithTickets,
  getInvoicableTicketsOfSiteVisit,
  getInvoiceById,
  getUnassignedTicketsOfProject
} from 'redux-src'

interface RemainingTicketModalProps {
  siteVisitDetails?: {id: number; title: string}
  openModal?: boolean
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
  setSiteVisitDetails?: React.Dispatch<React.SetStateAction<any>>
  sectionDetail?: Api.InvoiceItemDetails
}

export const RemainingTicketModal = ({
  siteVisitDetails,
  openModal,
  setSiteVisitDetails,
  setOpenModal,
  sectionDetail
}: RemainingTicketModalProps) => {
  const {
    invoicableTicketList,
    addInvoiceLineItemWithTicketLoading,
    unassignedTicketsOfProject
  }: RT.ProjectInvoicingReduxType = useSelector((state: any) => state.invoices)

  const [allTicketList, setAllTicketList] = useState<TicketItemType>()

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {projectId, invoiceId} = params as any

  const remappedTicketList = useCallback(() => {
    const ticketList = invoicableTicketList?.map((ticketList) => {
      return {
        ...ticketList,
        isChecked: true
      }
    })

    return {
      basicDetails: {label: 'SelectAllTickets', isAllChecked: true},
      ticketList: ticketList
    }
  }, [invoicableTicketList])

  const remappedUnassignedTickets = useCallback(() => {
    const unassignedTickets = unassignedTicketsOfProject?.map((tickets) => {
      return {
        ...tickets,
        isChecked: true
      }
    })

    return {
      basicDetails: {label: 'TicketList', isAllChecked: true},
      ticketList: unassignedTickets
    }
  }, [unassignedTicketsOfProject])

  const updateTicketItem = (value: boolean, ticketId: number) => {
    const ticketItems = allTicketList?.ticketList?.map((item) =>
      item?.id === ticketId ? {...item, isChecked: !value} : item
    )

    const basicDetailsUpdate = {
      basicDetails: {
        ...allTicketList?.basicDetails,
        isAllChecked: ticketItems?.every((item) => item?.isChecked === true)
      },
      ticketList: ticketItems
    }

    setAllTicketList(basicDetailsUpdate)
  }

  const updateAllTicketChange = (value: boolean) => {
    const changeBasicDetails = {
      label: allTicketList?.basicDetails?.label,
      isAllChecked: !value
    }

    const ticketItems = allTicketList?.ticketList?.map((ticketItem) => {
      return {
        ...ticketItem,
        isChecked: changeBasicDetails?.isAllChecked
      }
    })

    setAllTicketList((prev) => {
      return {basicDetails: changeBasicDetails, ticketList: ticketItems}
    })
  }

  const getUniqueTicketItemList = (
    ticketItems: TicketItemType['ticketList']
  ) => {
    const ticketItemList = ticketItems
      ?.filter((ticket) => ticket.isChecked)
      ?.map((ticket, _, orgArray) => {
        const quantity = orgArray
          ?.filter(
            (qtyItem) =>
              qtyItem.quote_line_item_id === ticket.quote_line_item_id
          )
          ?.reduce((acc, curr) => {
            return acc + curr.quantity
          }, 0)

        return {
          quote_line_item_id: ticket.quote_line_item_id,
          quote_line_item_name: ticket.quote_line_item_name,
          quantity,
          price: ticket.price,
          tax_percent: ticket.tax_percent,
          discount_percent: ticket.discount_percent,
          total: Number(
            Number(Number(quantity) * Number(ticket.price)).toFixed(2)
          )
        }
      })

    return Array.from(
      new Set(ticketItemList?.map((item) => item.quote_line_item_id))
    )?.map((item) =>
      ticketItemList?.find((ticket) => ticket.quote_line_item_id === item)
    )
  }

  const selectedTicketItems = useMemo(() => {
    return getUniqueTicketItemList(allTicketList?.ticketList)
  }, [allTicketList?.ticketList])

  const onAddInvoiceLineItemWithTicket = () => {
    const ticketIds = allTicketList?.ticketList
      ?.filter((ticketItem) => ticketItem?.isChecked)
      ?.map((item) => item?.id)

    const body = {
      ticketIds: ticketIds
    }

    dispatch(
      addInvoiceLineItemWithTickets(
        projectId,
        invoiceId,
        sectionDetail?.invoice_item_details?.id,
        body,
        () => {
          dispatch(getInvoiceById(projectId, invoiceId))
          setOpenModal(false)
        }
      )
    )
  }

  useEffect(() => {
    if (!!siteVisitDetails && remappedTicketList) {
      setAllTicketList(remappedTicketList)
    } else {
      setAllTicketList(remappedUnassignedTickets)
    }
  }, [remappedTicketList, remappedUnassignedTickets, siteVisitDetails])

  useEffect(() => {
    projectId &&
      siteVisitDetails &&
      dispatch(
        getInvoicableTicketsOfSiteVisit(projectId, siteVisitDetails?.id, 1, 100)
      )
  }, [dispatch, projectId, siteVisitDetails])

  const onCloseModal = () => {
    setSiteVisitDetails(undefined)
  }

  useEffect(() => {
    projectId &&
      !!!siteVisitDetails &&
      dispatch(getUnassignedTicketsOfProject(projectId))
  }, [dispatch, projectId, siteVisitDetails])

  return (
    <Modal
      title="Import Tickets"
      toggleModal={setOpenModal}
      isActive={openModal}
      size="lg"
      onClose={onCloseModal}
    >
      <div className="p-20">
        {!!siteVisitDetails && (
          <DescriptionBox title="Site Visit Invoicable Ticket List">
            <div className="flex w-full gap-20">
              <div className="flex flex-col gap-10 w-1/4">
                <div className="text-md font-bold">Select Site Visit</div>
                <SelectField
                  options={[
                    {
                      id: siteVisitDetails?.id,
                      label: siteVisitDetails?.title,
                      value: siteVisitDetails?.id
                    }
                  ]}
                  placeholder="Select Site Visit"
                  value={{
                    id: siteVisitDetails?.id,
                    label: siteVisitDetails?.title,
                    value: siteVisitDetails?.id
                  }}
                  onChangeValue={(data) => {}}
                />
              </div>

              <div className="flex flex-col gap-10 w-3/4 bg-gray-100 rounded-md px-20 py-16">
                {allTicketList?.ticketList?.length > 0 &&
                !!allTicketList?.basicDetails ? (
                  <>
                    <div className="flex flex-col gap-8">
                      <Checkbox
                        dataId={`selectAll-${allTicketList?.basicDetails?.label}-${allTicketList?.basicDetails?.label}`}
                        label={
                          <div className="font-bold text-md">Ticket List</div>
                        }
                        labelClassName="ml-8"
                        isChecked={allTicketList?.basicDetails?.isAllChecked}
                        value={allTicketList?.basicDetails?.isAllChecked}
                        onChange={(value) => {
                          updateAllTicketChange(value)
                        }}
                      />
                    </div>

                    <div className="flex flex-col gap-6  h-[200px] overflow-y-auto">
                      {allTicketList?.ticketList?.map((item, index: number) => {
                        return (
                          <div className="flex gap-6 items-center" key={index}>
                            <div className="">
                              <Checkbox
                                label={item?.title}
                                labelClassName="ml-8"
                                isChecked={item?.isChecked}
                                value={item?.isChecked}
                                dataId={`ticket-${item?.id}`}
                                onChange={(value) => {
                                  updateTicketItem(value, item?.id)
                                }}
                              />
                            </div>
                            <span>-</span>
                            <ToolTip text="Line Item Name" top>
                              <div className="font-medium text-black text-sm px-10 bg-blue-150 py-4 rounded-sm w-[max-content]">
                                {item?.quote_line_item_name}
                              </div>
                            </ToolTip>
                          </div>
                        )
                      })}
                    </div>
                  </>
                ) : (
                  <div className="text-sm text-gray-300 h-[235px] w-full flex justify-center items-center">
                    There are no remaining ticket items to add on.
                  </div>
                )}
              </div>
            </div>
            {!!selectedTicketItems && selectedTicketItems?.length > 0 && (
              <div className="flex flex-col gap-10 mt-20">
                <div className="font-bold text-md">Selected Items</div>
                <div className="flex flex-col gap-16">
                  <DataTable columns={columns} data={selectedTicketItems} />
                </div>
              </div>
            )}
          </DescriptionBox>
        )}

        {!!!siteVisitDetails && (
          <DescriptionBox title={'Unassigned Ticket List'}>
            <div className="flex flex-col gap-10 w-full bg-gray-100 rounded-md px-20 py-16">
              {allTicketList?.ticketList?.length > 0 &&
              !!allTicketList?.basicDetails ? (
                <>
                  <div className="flex flex-col gap-8">
                    <Checkbox
                      dataId={`selectAll-${allTicketList?.basicDetails?.label}-${allTicketList?.basicDetails?.label}`}
                      label={
                        <div className="font-bold text-md">Ticket List</div>
                      }
                      labelClassName="ml-8"
                      isChecked={allTicketList?.basicDetails?.isAllChecked}
                      value={allTicketList?.basicDetails?.isAllChecked}
                      onChange={(value) => {
                        updateAllTicketChange(value)
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-6  h-[200px] overflow-y-auto">
                    {allTicketList?.ticketList?.map((item, index: number) => {
                      return (
                        <div className="flex gap-6 items-center" key={index}>
                          <div className="">
                            <Checkbox
                              label={item?.title}
                              labelClassName="ml-8"
                              isChecked={item?.isChecked}
                              value={item?.isChecked}
                              dataId={`ticket-${item?.id}`}
                              onChange={(value) => {
                                updateTicketItem(value, item?.id)
                              }}
                            />
                          </div>
                          <span>-</span>
                          <ToolTip text="Line Item Name" top>
                            <div className="font-medium text-black text-sm px-10 bg-blue-150 py-4 rounded-sm w-[max-content]">
                              {item?.quote_line_item_name}
                            </div>
                          </ToolTip>
                        </div>
                      )
                    })}
                  </div>

                  {!!selectedTicketItems && selectedTicketItems?.length > 0 && (
                    <div className="flex flex-col gap-10 mt-20">
                      <div className="font-bold text-md">Selected Items</div>
                      <div className="flex flex-col gap-16">
                        <DataTable
                          columns={columns}
                          data={selectedTicketItems}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="text-sm text-gray-300 h-[235px] w-full flex justify-center items-center">
                  There are no remaining ticket items to add on.
                </div>
              )}
            </div>
          </DescriptionBox>
        )}

        <div className="flex justify-end p-18 border-t-1 border-gray-200 w-full">
          <Button
            title="Cancel"
            buttonColor="bg-gray-200 text-black hover:bg-gray-300 hover:text-white"
            size="sm"
            type="button"
            onClick={() => {
              setOpenModal(false)
              onCloseModal()
            }}
          />
          <div className="ml-16">
            <Button
              title="Add Tickets"
              size="sm"
              type="submit"
              onClick={onAddInvoiceLineItemWithTicket}
              loading={addInvoiceLineItemWithTicketLoading}
              isDisabled={selectedTicketItems?.length <= 0}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const columns: TableColumn<SelectedItemForTable>[] = [
  {
    name: 'Item Name',
    selector: (row) => row.quote_line_item_name,
    cell: (row) => {
      return (
        <div className={'text-blue-300 font-medium'}>
          {row.quote_line_item_name}
        </div>
      )
    },
    sortable: true
  },
  {
    name: 'Quantity',
    selector: (row) => row.quantity,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.quantity}</div>
    }
  },
  {
    name: 'Price',
    selector: (row) => row.price,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.price}</div>
    }
  },
  {
    name: 'Tax Percent',
    selector: (row) => row.tax_percent,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.tax_percent}</div>
    }
  },
  {
    name: 'Discount Percent',
    selector: (row) => row.discount_percent,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.discount_percent}</div>
    }
  },
  {
    name: 'Total',
    selector: (row) => row.total,
    sortable: true,
    cell: (row) => {
      return <div className="text-sm">{row.total}</div>
    }
  }
]

type SelectedItemForTable = {
  quote_line_item_id: number
  quote_line_item_name: string
  quantity: number
  price: number
  tax_percent: number
  discount_percent: number
  total: number
}

interface TicketItemType {
  basicDetails: {
    label: string
    isAllChecked: boolean
  }
  ticketList: Array<{
    id: number
    title: string
    ticket_number: number
    status: string
    price: number
    quantity: number
    tax_percent: number
    discount_percent: number
    quote_line_item_id: number
    quote_line_item_name: string
    isChecked: boolean
  }>
}
