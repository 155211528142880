import {
  FaBriefcase,
  FaInfo,
  FaListAlt,
  FaSync,
  FaChartBar,
  FaStickyNote,
  FaRegImage,
  FaUser,
} from 'react-icons/fa'
import {GiProgression} from 'react-icons/gi'
import {BiDollar, BiFileBlank} from 'react-icons/bi'
import {HiDocumentText, HiLocationMarker, HiUserGroup} from 'react-icons/hi'

import {SidePanelItemType} from 'app/components'
import {TiContacts} from 'react-icons/ti'
import {MdEmail, MdLocationPin} from 'react-icons/md'
import {
  BsFillFileSpreadsheetFill,
  BsFillTicketPerforatedFill,
} from 'react-icons/bs'

const overview: SidePanelItemType[] = [
  {
    name: 'Project Summary',
    icon: <FaBriefcase size="15" />,
    url: 'projects-summary',
  },
  {
    name: 'Progress Report',
    icon: <GiProgression size="15" />,
    url: 'progress-report',
  },
  {
    name: 'Financial Summary',
    icon: <FaChartBar size="15" />,
    url: 'financial-summary',
  },
  {
    name: 'Notes & History',
    icon: <FaStickyNote size="15" />,
    url: 'notes-history',
  },
]

const userManagement: SidePanelItemType[] = [
  {
    name: 'Users',
    icon: <FaUser size="" />,
    url: 'project-users',
  },
  {
    name: 'Groups',
    icon: <HiUserGroup size={14} />,
    url: 'project-groups',
  },
]

const tools: SidePanelItemType[] = [
  {
    name: 'Quote',
    icon: <BiFileBlank size="" />,
    url: 'quote',
  },
  // {
  //   name: 'Hazards',
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="h-5 w-5"
  //       viewBox="0 0 20 20"
  //       fill="currentColor"
  //     >
  //       <path
  //         fillRule="evenodd"
  //         d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   ),
  //   url: 'hazard',
  // },
  {
    name: 'Invoicing',
    icon: <BiDollar size={14} />,
    url: 'invoicing',
  },

  // {
  //   name: 'Task',
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="h-5 w-5"
  //       viewBox="0 0 20 20"
  //       fill="currentColor"
  //     >
  //       <path
  //         fillRule="evenodd"
  //         d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   ),
  //   url: 'task-view',
  // },

  {
    name: 'File & Photos',
    icon: <FaRegImage />,

    url: 'files-and-photos',
  },
  // {
  //   name: 'Sign Off',
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="h-5 w-5"
  //       viewBox="0 0 20 20"
  //       fill="currentColor"
  //     >
  //       <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
  //     </svg>
  //   ),
  //   url: 'signoff',
  // },
  // {
  //   name: 'Checklists',
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="h-5 w-5"
  //       viewBox="0 0 20 20"
  //       fill="currentColor"
  //     >
  //       <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
  //       <path
  //         fillRule="evenodd"
  //         d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   ),
  //   url: 'checklist',
  // },
  {
    name: 'Forms',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
          clipRule="evenodd"
        />
      </svg>
    ),
    url: 'forms',
  },
  {
    name: 'Plan Viewer',
    icon: <HiLocationMarker />,
    url: 'floor-plan',
  },
  {
    name: 'Ticket',
    icon: <BsFillTicketPerforatedFill />,
    url: 'ticket',
  },
  {
    name: 'Time Sheet',
    icon: <BsFillFileSpreadsheetFill />,
    url: 'timesheet',
  },
]

const costing: SidePanelItemType[] = [
  {
    name: 'Cost Report',
    icon: <FaListAlt size="15" />,
    url: 'costing-report',
  },
  // {
  //   name: 'Reconciliation',
  //   icon: <FaSync size="15" />,
  //   url: 'supplier-reconciliation',
  // },
  // {
  //   name: 'Purchase Orders',
  //   icon: <FaInfo size="15" />,
  //   url: 'purchase-orders',
  // },
]

export const customerSidePanelData: SidePanelItemType[] = [
  {
    name: 'Projects',
    icon: <FaBriefcase size="15" />,
    url: '',
  },
  // {
  //   name: 'Site Addresses',
  //   icon: <MdLocationPin size="18" />,
  //   url: 'site-addresses',
  // },
  // {
  //   name: 'Emails',
  //   icon: <MdEmail size="15" />,
  //   url: 'emails',
  // },
  {
    name: 'Files & Photos',
    icon: <FaRegImage size="15" />,
    url: 'files-and-photos',
  },
  // {
  //   name: 'Invoices',
  //   icon: <BiDollar size={14} />,
  //   url: 'invoices',
  // },
]

export const siteSidePanelData: SidePanelItemType[] = [
  {
    name: 'Contacts',
    url: '',
    icon: <TiContacts size="18" />,
  },
  {
    name: 'Address',
    url: 'site-address',
    icon: <MdLocationPin size="18" />,
  },
  {
    name: 'Projects',
    url: 'projects',
    icon: <FaBriefcase size="15" />,
  },
  // {
  //   name: 'Notes & History',
  //   url: 'notes-and-history',
  //   icon: <HiDocumentText size="18" />,
  // },
  {
    name: 'Files And Photos',
    url: 'files-and-photos',
    icon: <FaRegImage size="15" />,
  },
]

export const sidePanelData = {
  overview,
  'user management': userManagement,
  tools,
  // costing,
}
