import {useCallback, useEffect, useState} from 'react'
import {TableColumn, TableStyles} from 'react-data-table-component'
import {FaBuilding, FaSearch, FaUser} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import moment from 'moment'

import {getJobList} from 'redux-src'
import {DataTable, DescriptionBox} from 'app/components'
import colors from 'tailwindcss/colors'

interface EmailListRow {
  sent_at: string
  to: Array<string>
  subject: string
  id: number
}

const columns: TableColumn<EmailListRow>[] = [
  {
    name: 'Sent',
    selector: (row) => row.sent_at,
    sortable: true,

    cell: (row) => (
      <div className="text-sm">
        {moment(row.sent_at).format('DD/MM/YYYY h:mmaa')}
      </div>
    )
  },
  {
    name: 'To',
    sortable: true,

    selector: (row) => row.id,
    cell: (row) => <div className="text-sm">{row.to.join(', ')}</div>
  },
  {
    name: 'Subject',
    selector: (row) => row.subject,

    sortable: true,
    cell: (row) => (
      //   <Link
      //     to={`/projects/${row.id}/jobs-summary`}
      //     className="text-sm font-bold text-blue-300 cursor-pointer"
      //   >
      //     {row.subject}
      //   </Link>
      <div className="text-sm font-bold text-blue-300 cursor-pointer">
        {row.subject}
      </div>
    )
  }
]

export const EmailsPage = () => {
  //   const { params } = useNavigation()
  //   const { customerId } = params as any
  //   const dispatch = useDispatch()
  //   const [newJobList, setNewJobList] = useState<Array<JobListRow>>()

  //   const getJob = useCallback(
  //     (page: number = 1, tableLimit: number = 10) => {
  //       dispatch(getJobList(page, tableLimit))
  //     },
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     [dispatch]
  //   )

  //   const { jobList, jobListTotal, jobListLoading } = useSelector(
  //     (state: any) => state.job
  //   )
  //   useEffect(() => {
  //     getJob()
  //   }, [getJob])

  //   // const { companyDetails } = useSelector((state: any) => state.company)

  //   const [filterData, filterHandler] = useFormInput({
  //     filterValue: '',
  //   })

  //   const jobRemapper = useCallback(
  //     (filterValue) => {
  //       const _temp: JobListRow[] = []
  //       jobList
  //         .filter((job: any) => {
  //           console.log(
  //             customerId,
  //             job?.customer_details?.id,
  //             job?.customer_details?.id === customerId
  //           )
  //           return job?.customer_details?.id === +customerId
  //         })
  //         .map((item: any) => {
  //           if (
  //             item.job_details?.title
  //               .toLowerCase()
  //               .includes(filterValue.toLowerCase())
  //           ) {
  //             return _temp.push({
  //               projectNo:
  //                 item.job_details?.jobPrefix + '-' + item.job_details?.jobNumber,
  //               created: item.job_details?.createdAt,
  //               status: item.job_details?.jobStatus,
  //               projectType: item.job_details?.jobType,
  //               title: item.job_details?.title,
  //               //! site contact details necessary instead of customer_details
  //               siteContact: item.customer_details?.name,
  //               siteAddress: item.address_details?.address,
  //               id: item.job_details?.id,
  //             })
  //           }
  //           return null
  //         })
  //       // // console.log('temp: ', _temp)
  //       setNewJobList(_temp)
  //     },
  //     [customerId, jobList]
  //   )

  //   useEffect(() => {
  //     jobRemapper(filterData.filterValue)
  //   }, [filterData.filterValue, jobRemapper, jobList])

  return (
    <div>
      <DescriptionBox
        bodyClassName="m-0 p-0"
        title={
          <div className="flex justify-between items-center ">
            <div className="flex items-center gap-6">
              <span className="font-bold text-lg">Emails</span>
            </div>
          </div>
        }
      >
        <DataTable
          columns={columns}
          data={emailConstants}
          pagination
          persistTableHead
          dense
          totalRows={emailConstants.length}
        />
      </DescriptionBox>
    </div>
  )
}

const emailConstants = [
  {
    id: 1,
    sent_at: Date.now().toString(),
    to: ['john@gmail.com'],
    subject:
      'Site Visit scheduled by JFDS Company for 995 Antoine Platekade, Feijenoord'
  },
  {
    id: 2,
    sent_at: Date.now().toString(),
    to: ['john@gmail.com'],
    subject: 'SMART-11: Message from JFDS Company'
  },
  {
    id: 3,
    sent_at: Date.now().toString(),
    to: ['john@gmail.com', 'binay@gmail.com'],
    subject: 'Site Visit scheduled by JFDS Company'
  },
  {
    id: 4,
    sent_at: Date.now().toString(),
    to: ['john@gmail.com'],
    subject: 'Estimate from JFDS Company'
  }
]
