import React, {useCallback, useEffect} from 'react'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {AiOutlinePlus} from 'react-icons/ai'

import {
  createPreliminaryItemTemplate,
  deletePreliminaryItemTemplate,
  getPreliminaryListAction,
  getPreliminaryTemplateDetailsAction,
  updatePreliminaryTemplateAction,
} from 'redux-src'

import {Button} from 'app/common'
import {PreliminaryTemplateSectionCard} from './components'
import {useFormInput} from 'use-form-input'

export const PreliminaryTemplateEditor = () => {
  const {
    preliminaryTemplateDetails,
    updatePreliminaryTemplateLoading,
  }: RT.PreliminaryTemplateReduxType = useSelector(
    (state: any) => state.preliminaryTemplate,
  )

  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const {params} = useNavigation()
  const {projectId, preliminaryTemplateId} = params as any
  const {currencySymbol} = useAuth()
  const dispatch = useDispatch()

  const fetchPreliminaryItems = useCallback(() => {
    dispatch(getPreliminaryListAction(projectId))
  }, [dispatch, projectId])

  const [constantData, {onChange, setValue}] = useFormInput({
    noOfWeeks: 0,
    delivaryCost: 0,
    templateTitle: undefined,
  })

  const removePreliminaryTemplateSection = (sectionId: number) => {
    dispatch(
      deletePreliminaryItemTemplate(preliminaryTemplateId, sectionId, () => {
        getPreliminaryTemplateDetails()
      }),
    )
  }

  const getPreliminaryTemplateDetails = useCallback(() => {
    dispatch(
      getPreliminaryTemplateDetailsAction(
        preliminaryTemplateDetails?.details?.id,
        () => {},
      ),
    )
  }, [dispatch, preliminaryTemplateDetails?.details?.id])

  const prepopulateProjectPrelData = useCallback(() => {
    setValue('noOfWeeks', projectDetail?.project_details?.prelim_total_weeks)
    setValue(
      'delivaryCost',
      projectDetail?.project_details?.prelim_delivery_cost,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])

  const initialPreliminaryTemplateSection = (e: React.FormEvent) => {
    e.preventDefault()

    const body = {
      title: 'Title',
      type: 'item',
      totalFrequency: 0,
      frequencyUnit: 'Quantity',
    }

    dispatch(
      createPreliminaryItemTemplate(
        preliminaryTemplateDetails?.details?.id,
        body,
        () => {
          getPreliminaryTemplateDetails()
        },
      ),
    )
  }

  useEffect(() => {
    prepopulateProjectPrelData()
  }, [projectId, fetchPreliminaryItems, prepopulateProjectPrelData])

  const onUpdatePreliminaryTemplateTitle = () => {
    const body = {
      title: constantData?.templateTitle,
    }
    if (body?.title === preliminaryTemplateDetails?.details?.title) return
    if (
      !!preliminaryTemplateDetails &&
      body?.title !== preliminaryTemplateDetails?.details?.title
    ) {
      dispatch(
        updatePreliminaryTemplateAction(
          preliminaryTemplateDetails?.details?.id,
          body,
          () => {
            getPreliminaryTemplateDetails()
          },
        ),
      )
    }
  }

  useEffect(() => {
    if (!!preliminaryTemplateDetails) {
      setValue('templateTitle', preliminaryTemplateDetails?.details?.title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preliminaryTemplateDetails])

  return (
    <div className="flex flex-col gap-14">
      <div className="flex justify-between">
        <div className="w-[40%] flex items-center flex-col border-b-1 border-gray-200">
          <input
            type="text"
            name="templateTitle"
            placeholder="Set a name for the template"
            className="text-lg w-full items-center"
            value={constantData?.templateTitle}
            onChange={onChange}
            onBlur={onUpdatePreliminaryTemplateTitle}
          />
        </div>
        <Button
          title={
            preliminaryTemplateDetails?.details?.is_published
              ? 'Published'
              : 'Publish Template'
          }
          onClick={() => {
            dispatch(
              updatePreliminaryTemplateAction(
                preliminaryTemplateDetails?.details?.id,
                {
                  isPublished: true,
                },
                () => {
                  getPreliminaryTemplateDetails()
                },
              ),
            )
          }}
          className={`${
            preliminaryTemplateDetails?.details?.is_published
              ? 'bg-green-300 hover:bg-green-300'
              : ''
          }`}
          loading={updatePreliminaryTemplateLoading}
          disabled={preliminaryTemplateDetails?.details?.is_published}
        />
      </div>

      {preliminaryTemplateDetails &&
        preliminaryTemplateDetails?.items?.length > 0 &&
        preliminaryTemplateDetails?.items?.map(
          ({prelim_item_template_details, line_items}, index: number) => {
            return (
              <PreliminaryTemplateSectionCard
                key={prelim_item_template_details?.id}
                sectionDetail={prelim_item_template_details}
                lineItemList={line_items}
                onRemoveSection={removePreliminaryTemplateSection}
                fetchPreliminaryTemplateDetails={getPreliminaryTemplateDetails}
                bottomSeparator={
                  Array.isArray(preliminaryTemplateDetails?.items) &&
                  preliminaryTemplateDetails?.items?.length > 1 &&
                  index !== preliminaryTemplateDetails?.items?.length - 1
                }
              />
            )
          },
        )}

      <div
        className="
      border-t-1 border-t-gray-200
      mt-8 pt-8
      flex flex-row justify-end
      "
      >
        <div>
          <div className="flex flex-row justify-end gap-10 mt-6">
            <div className="font-bold text-right">Grand Total</div>
            <div className="w-[50px] font-bold text-right">
              {currencySymbol}
              {preliminaryTemplateDetails?.details?.total_cost ?? 0.0}
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-16 border-t-1 border-b-1 border-gray-200 p-30 justify-center items-center">
        <span
          className="flex cursor-pointer gap-6 justify-center items-center"
          onClick={initialPreliminaryTemplateSection}
        >
          <AiOutlinePlus size={22} strokeWidth={2} className="text-gray-400" />
          <span className="text-xl text-gray-400 hover:text-gray-500 ">
            Add new preliminary
          </span>
        </span>
      </div>
    </div>
  )
}
