import {Button, Input, Modal} from 'app/common'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {
  createPreliminaryTemplates,
  getPreliminaryTemplateAction
} from 'redux-src'

interface CreateTemplateModalProps {
  isModalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateTemplateModal = ({
  isModalVisible,
  setModalVisible
}: CreateTemplateModalProps) => {
  const {createPreliminaryTemplateLoading}: RT.PreliminaryTemplateReduxType =
    useSelector((state: any) => state.preliminaryTemplate)

  const dispatch = useDispatch()

  const [data, {onChange, errors, modified, onSubmit, clear, setValue}] =
    useFormInput(
      {templateTitle: undefined},
      (data) => {
        data?.templateTitle?.length > 0 &&
          dispatch(
            createPreliminaryTemplates({title: data?.templateTitle}, () => {
              setModalVisible(false)
              onCloseModal()
              dispatch(getPreliminaryTemplateAction())
              // navigate(`/settings/preliminary-templates/${}`)
            })
          )
      },
      (data) => {
        let errors: any = {}

        if (data?.templateTitle?.length === 0) {
          errors.templateTitle =
            'Preliminary template title can not be empty !!'
        }

        return errors
      }
    )

  const onCloseModal = () => {
    clear()
    setValue('templateTitle', '')
  }

  return (
    <Modal
      onClose={onCloseModal}
      title={'Create Preliminary Template'}
      toggleModal={setModalVisible}
      isActive={isModalVisible}
      size="sm"
    >
      <div className="flex flex-col gap-12">
        <div className="p-20 flex flex-col">
          <Input
            label="Template Title"
            name="templateTitle"
            value={data?.templateTitle ?? ''}
            onChange={onChange}
            placeholder="Preliminary Template Title"
            error={errors['templateTitle'] && modified['templateTitle']}
            required
          />
        </div>
        <div className="border border-t-1 border-gray-200 px-20 py-10">
          <div className="flex justify-end gap-8">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={() => {
                setModalVisible(false)
                onCloseModal()
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
              onClick={onSubmit}
              loading={createPreliminaryTemplateLoading}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
