import {ToolTip} from 'app/common'
import {generateRandomAvatarColor} from 'helpers/avatarGenerator.helper'

export interface Avatarprops {
  display_name: string
  lastname: string
  showTooltip?: boolean
  tooltipText?: string
  id?: string
}

export const Avatar = ({
  display_name,
  lastname,
  showTooltip,
  tooltipText,
  id,
}: Avatarprops) => {
  const fullName = `${display_name} ${lastname}`
  if (!!!showTooltip)
    return (
      <div
        style={{background: generateRandomAvatarColor(fullName)}}
        className={`w-[25px] h-[25px] flex justify-center items-center rounded-full overflow-hidden  text-white cursor-pointer `}
        key={id}
      >
        {display_name?.at(0)?.toUpperCase()}
        {lastname?.at(0)?.toUpperCase()}
      </div>
    )

  return (
    <ToolTip left text={tooltipText ?? fullName}>
      <div
        style={{background: generateRandomAvatarColor(fullName)}}
        className={`w-[25px] h-[25px] flex justify-center items-center rounded-full overflow-hidden  text-white cursor-pointer`}
      >
        {display_name?.at(0)?.toUpperCase()}
        {lastname?.at(0)?.toUpperCase()}
      </div>
    </ToolTip>
  )
}
