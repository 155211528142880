import {
  GET_ALL_INFO_CONTACTS,
  GET_CUSTOMER_FILES_AND_PHOTOS,
  UPLOAD_CUSTOMER_FILES_AND_PHOTOS
} from './actionTypes.action'
import {api, APIS} from '../../config'
import {
  ADD_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMER_BY_ID,
  UPDATE_CUSTOMER
} from '.'
import {errorHandler} from '../../utils'
import {getParsedUrl} from 'helpers'

export function getCustomer(
  params: {
    page?: number
    limit?: number
    search?: string
  } = {
    page: 1,
    limit: 10
  },
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_CUSTOMER.LOADING})

      res = await api<Api.Base<Api.CustomerList>>(
        getParsedUrl(APIS.get_customer, params)
      )

      const {
        success,
        data: {
          data: {total, rows},
          message
        }
      } = res.data

      if (success) {
        dispatch({type: GET_CUSTOMER.SUCCESS, payload: {total, rows}})

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_CUSTOMER.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_CUSTOMER.ERROR})
      return 0
    }
  }
}

export function addCustomer(body: any, successCallback?: any, toast?: any) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_CUSTOMER.LOADING})

      res = await api<any>(`${APIS.add_customer}`, 'POST', body)

      const {success} = res.data

      if (success) {
        dispatch({type: ADD_CUSTOMER.SUCCESS})

        successCallback?.()
        toast.success('Customer successfully added!')
        return 1
      } else {
        dispatch({type: ADD_CUSTOMER.ERROR})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: ADD_CUSTOMER.ERROR})
      toast.error('Error adding customer!')
      return 0
    }
  }
}

export function getCustomerById(
  customerId: number = 1,
  successCallback?: (data: Api.CustomerById) => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_CUSTOMER_BY_ID.CLEAR})

      dispatch({type: GET_CUSTOMER_BY_ID.LOADING})

      res = await api<Api.Base<Api.CustomerById>>(
        `${APIS.get_customer}/${customerId}`,
        'GET'
      )

      const {
        success,
        data: {data: customer, message}
      } = res.data

      if (success) {
        dispatch({type: GET_CUSTOMER_BY_ID.SUCCESS, payload: customer})

        successCallback?.(customer)
        return 1
      } else {
        dispatch({type: GET_CUSTOMER_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_CUSTOMER_BY_ID.ERROR})
      return 0
    }
  }
}

export function updateCustomer(
  customerId: number = 0,
  body: any,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_CUSTOMER.LOADING})

      res = await api<any>(`${APIS.get_customer}/${customerId}`, 'PATCH', body)

      const {
        success,
        data: {data: customer, message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_CUSTOMER.SUCCESS, payload: customer})
        toast.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({type: UPDATE_CUSTOMER.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_CUSTOMER.ERROR})
      return 0
    }
  }
}

export function deleteOtherContact(
  customerId: number = 0,
  infoId: number = 0,
  contactId: number = 0,
  successCallback?: any,
  toast?: any
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_CUSTOMER.LOADING})

      res = await api<any>(
        `${APIS.get_customer}/${customerId}/info/${infoId}/other-contacts/${contactId}`,
        'DELETE'
      )

      const {
        success,
        data: {data: customer, message}
      } = res.data

      if (success) {
        dispatch({type: UPDATE_CUSTOMER.SUCCESS, payload: customer})
        toast?.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({type: UPDATE_CUSTOMER.ERROR, payload: message})
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_CUSTOMER.ERROR})
      return 0
    }
  }
}

export const uploadCustomerFilesAndPhotos = (
  customerId: number,
  assets: FormData,
  successCallback?: any,
  toast?: any
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPLOAD_CUSTOMER_FILES_AND_PHOTOS.LOADING})

      res = await api<any>(
        `${APIS.get_customer}/${customerId}/upload-files`,
        'POST',
        assets,
        {
          file: true
        }
      )

      const {
        success,
        data: {data: customer, message}
      } = res.data

      if (success) {
        dispatch({
          type: UPLOAD_CUSTOMER_FILES_AND_PHOTOS.SUCCESS,
          payload: customer
        })
        toast?.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: UPLOAD_CUSTOMER_FILES_AND_PHOTOS.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({
        type: UPLOAD_CUSTOMER_FILES_AND_PHOTOS.ERROR
      })
      return 0
    }
  }
}

export const getCustomerFilesAndPhotos = (
  customerId: number,
  successCallback?: any,
  toast?: any
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_CUSTOMER_FILES_AND_PHOTOS.LOADING})

      res = await api<Api.Base<Api.AssetsList>>(
        `${APIS.get_customer}/${customerId}/assets`
      )

      const {
        success,
        data: {
          data: {rows},
          message
        }
      } = res.data

      if (success) {
        dispatch({
          type: GET_CUSTOMER_FILES_AND_PHOTOS.SUCCESS,
          payload: rows
        })
        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_CUSTOMER_FILES_AND_PHOTOS.ERROR,
          payload: message
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({
        type: GET_CUSTOMER_FILES_AND_PHOTOS.ERROR
      })
      return 0
    }
  }
}

// GET ALL INFO CONTACTS

export function getAllInfoContacts(
  typeId: number,
  infoId: number,
  successCallback?: (data: Api.InfoContact[]) => void,
  type: 'customers' | 'sites' = 'customers'
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ALL_INFO_CONTACTS.LOADING})

      res = await api<Api.Base<Api.InfoContact[]>>(
        `${type}/${typeId}/info/${infoId}/all-contacts`
      )

      const {
        success,
        data: {data, message}
      } = res.data

      if (success) {
        dispatch({type: GET_ALL_INFO_CONTACTS.SUCCESS, payload: data})

        successCallback?.(data)
        return 1
      } else {
        dispatch({type: GET_ALL_INFO_CONTACTS.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_ALL_INFO_CONTACTS.ERROR})
      return 0
    }
  }
}
