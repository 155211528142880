import React, {useState} from 'react'
import moment from 'moment'
import {FiEdit} from 'react-icons/fi'
import {IoMdClose} from 'react-icons/io'
import {MdOutlineArrowDropDown, MdOutlineSchedule} from 'react-icons/md'
import {useDispatch} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {BsArrowRight, BsTrashFill} from 'react-icons/bs'
import {FaUserLarge} from 'react-icons/fa6'
import {AiTwotoneEdit} from 'react-icons/ai'

import {Button, DefaultButton, Modal, ToolTip} from 'app/common'
import {Collapsible, ScheduleModal} from '.'
import {
  getSiteVisitTicketList,
  getTicketDetailsById,
  removeAssignedEmployeeFromTicket,
  unScheduledAssignedEmployee
} from 'redux-src'
import {ProgressBar} from 'app/pages/jobs/pages/ticket/components/progressBar'
import {CreateTicketModel} from 'app/pages/jobs/pages/ticket'
import {getStatusChip} from 'app/pages/jobs/pages/ticket/components/planList/components'

export interface SelectedAssignedEmployeeTypes {
  user_details: {
    id: number
    lastname: string
    display_name: string
  }
  employe_details?: {
    id: number
    ticket_id: number
    scheduled_to: string
    scheduled_from: string
  }
}

export const AssignedEmployee = ({
  siteVisitId,
  ticketDetails,
  onRemove
}: {
  siteVisitId: number
  ticketDetails: Api.SiteVisitTicketIndividual
  onRemove?: (cb: () => void) => void
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmationModal, setCOnfirmationModal] = useState(false)

  const [scheduleModalVisible, setScheduleModalVisible] =
    useState<boolean>(false)
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<any>()
  const [selectedAssignedEmployee, setSelectedAssignedEmployee] =
    useState<SelectedAssignedEmployeeTypes>()
  const [ticketModalVisible, setTicketModalVisible] = useState<boolean>(false)
  const [ticketId, setTicketId] = useState<number>()

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const handleScheduleAssignedEmployee = (
    assignedEmpId: number,
    item?: SelectedAssignedEmployeeTypes,
    type?: 'update' | 'schedule'
  ) => {
    if (type === 'update') {
      setScheduleModalVisible(true)
      setSelectedAssignedEmployee(item)
      dispatch(
        getTicketDetailsById(
          projectId,
          ticketDetails?.ticket_details?.id,
          () => {}
        )
      )
    } else {
      setScheduleModalVisible(true)
      setSelectedEmployeeId(item)
      dispatch(
        getTicketDetailsById(
          projectId,
          ticketDetails?.ticket_details?.id,
          () => {}
        )
      )
    }
  }

  const handleUnScheduledAssignedEmployee = (assignedEmpId: number) => {
    dispatch(
      unScheduledAssignedEmployee(
        projectId,
        ticketDetails?.ticket_details?.id,
        assignedEmpId,
        () => {
          setCOnfirmationModal(false)
          setSelectedEmployeeId(undefined)
          dispatch(
            getSiteVisitTicketList(projectId, siteVisitId, 1, 100, () => {})
          )
        }
      )
    )
  }

  const handleAssignedEmployeeToTicket = () => {
    setScheduleModalVisible(true)
    dispatch(
      getTicketDetailsById(
        projectId,
        ticketDetails?.ticket_details?.id,
        () => {}
      )
    )
  }

  const removeEmployeeFromTicket = (userId: number) => {
    dispatch(
      removeAssignedEmployeeFromTicket(
        projectId,
        ticketDetails?.ticket_details?.id,
        {assignedUserIds: [Number(userId)]},
        () => {
          dispatch(
            getSiteVisitTicketList(projectId, siteVisitId, 1, 100, () => {})
          )
        }
      )
    )
  }

  return (
    <div>
      <Collapsible
        topContainer={
          <div
            className={`flex flex-row justify-between items-center bg-blue-100 px-14 py-10 cursor-pointer ${
              ticketDetails?.ticket_details?.status === 'completed'
                ? 'form-disable'
                : ''
            }`}
          >
            <div className="flex flex-row justify-between items-center gap-14">
              <div className="flex flex-row items-center justify-start gap-4">
                <MdOutlineArrowDropDown className="text-blue-300 " />
                <div className="h-[24px] w-[24px] flex items-center justify-center bg-red-400 rounded-md text-white">
                  {ticketDetails?.ticket_details?.ticket_number ??
                    ticketDetails?.ticket_details?.id}
                </div>
                <div className="text-md font-bold text-gray-500 px-4">
                  {ticketDetails?.ticket_details?.title}
                </div>
              </div>
              <div className="flex flex-row items-center gap-4 flex-nowrap pointer-events-auto">
                <ToolTip text="Update Ticket">
                  <div
                    onClick={() => {
                      setTicketModalVisible(true)
                      setTicketId(ticketDetails?.ticket_details?.id)
                    }}
                    className="px-6 py-[2px] w-[max-content] bg-blue-300 hover:bg-blue-400 active:bg-blue-300 rounded-sm"
                  >
                    <AiTwotoneEdit size={18} color="#fff" />
                  </div>
                </ToolTip>
                <div className="">
                  {getStatusChip(
                    ticketDetails?.ticket_details?.status as
                      | 'open'
                      | 'feedback'
                      | 'completed'
                      | 'in progress'
                  )}
                </div>
                <div
                  className={`text-[9px] flex items-center font-bold bg-black text-white px-6 py-[2px] rounded-full uppercase w-[max-content]`}
                >
                  {ticketDetails?.ticket_details?.is_invoiced
                    ? 'Invoiced'
                    : 'Not Invoiced'}
                </div>
              </div>
              <div className="text-sm font-bold text-gray-300"></div>
            </div>
            <div className="flex flex-row items-center gap-4">
              <div className="w-[140px]">
                <ProgressBar
                  progressPercentage={Number(
                    ticketDetails?.ticket_details?.progress_percent
                  )}
                />
              </div>
              <div
                className={`rounded-sm text-white px-10 py-4 ${
                  ticketDetails?.ticket_details?.status === 'completed'
                    ? 'bg-blue-200'
                    : ' bg-blue-300 hover:bg-blue-400 active:bg-blue-300'
                }
                `}
                onClick={() => {
                  handleAssignedEmployeeToTicket()
                }}
              >
                Assign Employee
              </div>
              <ToolTip text="Remove ticket from site visit" left>
                <IoMdClose size={18} onClick={() => setModalVisible(true)} />
              </ToolTip>
            </div>
          </div>
        }
        containerClassName="flex flex-col border-1 border-gray-200 rounded-t-sm overflow-hidden"
      >
        <div
          className={`"flex flex-col border-t-1 border-gray-200 pl-18 ${
            ticketDetails?.ticket_details?.status === 'completed'
              ? 'form-disable'
              : ''
          }`}
        >
          {ticketDetails?.assigned_employees?.length > 0 ? (
            ticketDetails?.assigned_employees?.map((item, index: number) => {
              return (
                <div key={index}>
                  <div className="flex flex-row justify-between items-center px-14 py-14 w-full overflow-hidden ">
                    <div className="flex flex-row items-center gap-10 w-full">
                      <FaUserLarge className="text-gray-500" size={24} />
                      <div className="w-full flex flex-col">
                        <div className="text-md font-bold text-gray-600">
                          {item?.user_details?.display_name}{' '}
                          {item?.user_details?.lastname}
                        </div>
                        <div className="flex gap-10">
                          <div className="flex items-center">
                            <div className="font-bold text-gray-600">
                              {!!item?.employe_details?.scheduled_from &&
                              item?.employe_details?.scheduled_to
                                ? 'Scheduled Duration :'
                                : 'Schedule This Employee: '}
                            </div>
                            <div className="font-light  text-gray-400 flex gap-8 px-6">
                              {!!item?.employe_details?.scheduled_from &&
                                item?.employe_details?.scheduled_to && (
                                  <>
                                    <span>
                                      {moment(
                                        item?.employe_details?.scheduled_from
                                      ).format('YYYY/MM/DD,  h:mm a')}
                                    </span>
                                    <span className="text-md font-bold">
                                      <BsArrowRight size={18} />
                                    </span>
                                    <span>
                                      {moment(
                                        item?.employe_details?.scheduled_to
                                      ).format('YYYY/MM/DD,  h:mm a')}
                                    </span>
                                  </>
                                )}
                            </div>
                          </div>
                          <div className="flex gap-20 items-center">
                            {!!item?.employe_details?.scheduled_from &&
                              !!item?.employe_details?.scheduled_to && (
                                <ToolTip
                                  text="Update Scheduled Time Of Employee"
                                  top
                                >
                                  <div
                                    className="cursor-pointer text-green-500"
                                    onClick={() => {
                                      handleScheduleAssignedEmployee(
                                        item?.employe_details?.id,
                                        item,
                                        'update'
                                      )
                                    }}
                                  >
                                    <FiEdit size={18} />
                                  </div>
                                </ToolTip>
                              )}

                            {!!item?.employe_details?.scheduled_from &&
                              !!item?.employe_details?.scheduled_to && (
                                <ToolTip text="Unschedule Employee" top>
                                  <div
                                    className="cursor-pointer text-red-400"
                                    onClick={() => {
                                      setSelectedEmployeeId(
                                        item?.employe_details?.id
                                      )
                                      setCOnfirmationModal(true)
                                    }}
                                  >
                                    <MdOutlineSchedule size={24} />
                                  </div>
                                </ToolTip>
                              )}

                            {!!!item?.employe_details?.scheduled_from &&
                              !!!item?.employe_details?.scheduled_to && (
                                <ToolTip text="Schedule Employee" top>
                                  <div
                                    className="cursor-pointer text-blue-400"
                                    onClick={() => {
                                      handleScheduleAssignedEmployee(
                                        item?.employe_details?.id,
                                        item,
                                        'schedule'
                                      )
                                    }}
                                  >
                                    <MdOutlineSchedule size={24} />
                                  </div>
                                </ToolTip>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <ToolTip text="Remove Employee From Ticket" left>
                      <div
                        className="cursor-pointer text-red-400"
                        onClick={() => {
                          removeEmployeeFromTicket(item?.user_details?.id)
                        }}
                      >
                        <BsTrashFill size={18} />
                      </div>
                    </ToolTip>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="flex items-center justify-center py-16 text-sm">
              This ticket does not have any assigned employees !!
            </div>
          )}
        </div>
      </Collapsible>
      {ticketModalVisible && (
        <CreateTicketModel
          modalId="Edit Ticket modal from sitevisit"
          isModelVisible={ticketModalVisible}
          setIsModelVisible={setTicketModalVisible}
          edit
          ticketIdFromSitevisit={ticketId}
        />
      )}

      <ScheduleModal
        scheduleModal={scheduleModalVisible}
        setScheduleModal={setScheduleModalVisible}
        selectedEmployeeId={selectedEmployeeId}
        selectedEmployeeDetails={selectedAssignedEmployee}
        setSelectedEmployeeId={setSelectedEmployeeId}
        setSelectedAssignedEmployee={setSelectedAssignedEmployee}
        assignEmployee
        modalId="ScheduleModal Two"
      />

      <Modal
        id="Unassign Ticket Modal"
        title="Unassign Ticket"
        isActive={modalVisible}
        toggleModal={setModalVisible}
        size="sm"
      >
        <div className="flex flex-col p-24 gap-10">
          <div>
            You are about to unassign{' '}
            <strong>{ticketDetails?.ticket_details?.title}</strong> from current
            site visit.
          </div>
          <div>Would you like to proceed?</div>
        </div>
        <div className="border-t-1 border-gray-200 flex flex-row-reverse px-24 py-14 gap-10">
          <Button
            title="Unassign"
            className="text-white font-bold "
            onClick={(e: any) => {
              e.preventDefault()
              onRemove(() => setModalVisible(false))
            }}
          />
          <DefaultButton
            title="Cancel"
            onClick={() => setModalVisible(false)}
            className="text-blue-400 bg-blue-100 hover:bg-blue-150"
          />
        </div>
      </Modal>

      <Modal
        onClose={() => {
          setSelectedEmployeeId(undefined)
        }}
        title="Unschedule Employee"
        isActive={confirmationModal}
        toggleModal={setCOnfirmationModal}
        size="sm"
      >
        <div className="flex flex-col p-24 gap-10">
          <div>
            You are about to unscheduled this employee from current ticket.
          </div>
          <div>Would you like to proceed?</div>
        </div>
        <div className="border-t-1 border-gray-200 flex flex-row-reverse px-24 py-14 gap-10">
          <Button
            title="Unschedule"
            className="text-white font-bold "
            onClick={(e: any) => {
              e.preventDefault()
              handleUnScheduledAssignedEmployee(selectedEmployeeId)
            }}
          />
          <DefaultButton
            title="Cancel"
            onClick={() => {
              setCOnfirmationModal(false)
              setSelectedEmployeeId(undefined)
            }}
            className="text-blue-400 bg-blue-100 hover:bg-blue-150"
          />
        </div>
      </Modal>
    </div>
  )
}
