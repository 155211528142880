import {useState} from 'react'
import {FaTruck, FaCaretDown} from 'react-icons/fa'
import {HiSearch} from 'react-icons/hi'
import {useAuth} from 'react-auth-navigation'

import {DynamicSearchSelect} from 'app/components'
import {Button, Dropdown} from 'app/common'
import {AddCustomItem} from '../addCustomItem'

type SelectedItemType = {
  id: number
  value: string
  label: string
  materialCost: number
  totalCost: number
}

export const AddLineItem = ({
  onAddLineItem,
  sectionDetail,
  fetchInvoiceItem,
}: {
  onAddLineItem: (a: any, b?: () => void) => void
  sectionDetail?: Api.InvoiceItemDetails
  fetchInvoiceItem?: () => void
}) => {
  const [selectedSupplier, setSelectedSupplier] = useState<{
    id: number
    label: string
    value: string
  }>()

  const [selectedItemById, setSelectedItemById] = useState<SelectedItemType>()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const [itemActionUrl, setItemActionUrl] = useState<string>('/item')

  const {currencySymbol} = useAuth()

  const handleSelectItem = (item: SelectedItemType) => {
    setSelectedItemById(item)
    const {label, totalCost, materialCost, id} = item
    const body = {
      title: label,
      cost: totalCost,
      price: materialCost,
      quantity: 1,
      type: 1,
      id: id,
    }
    onAddLineItem(body)
  }

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  return (
    <div className="flex flex-nowrap flex-row">
      <div className="flex ">
        <div className="p-6 border-1 border-r-0 rounded-l-sm rounded-r-none border-gray-200 bg-[rgb(245,245,245)]">
          <HiSearch size={18} />
        </div>

        <DynamicSearchSelect
          height="31px"
          containerClassname="w-[200px]"
          placeholder={selectedItemById?.label ?? 'Add All Suppliers Item'}
          actionUrl={itemActionUrl}
          value={selectedItemById}
          remapOptions={(data: Api.SupplierItemLists['rows']) => {
            return data?.map((item) => {
              return {
                id: item?.item_details?.id,
                label: item?.item_details?.name,
                value: item?.item_details?.name,
                totalCost: item?.item_details?.cost,
                materialCost: item?.item_details?.material_cost,
              }
            })
          }}
          formatOptionLabel={(item: {
            id: number
            label: string
            value: string
            totalCost: number
            materialCost: number
          }) => (
            <div key={item.id} className="cursor-pointer px-10">
              <div className="flex flex-col gap-6">
                <div className="flex gap-4">
                  <strong>{item.label}</strong>
                </div>
                <div className="flex gap-4 flex-wrap flex-col">
                  <div className="flex gap-4 flex-wrap">
                    Material cost:{' '}
                    <strong>
                      {currencySymbol}
                      {item.materialCost}
                    </strong>
                  </div>
                  <div className="flex gap-4 flex-wrap">
                    Total Cost:{' '}
                    <strong>
                      {currencySymbol}
                      {item.totalCost}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          )}
          onChangeValue={handleSelectItem}
          cacheOptions
        />
      </div>
      <Dropdown
        triggerToggle
        triggerElement={
          <div className="bg-gray-100 border-1 border-gray-200 relative w-[46px] h-[31px] rounded-r-sm flex justify-center items-center cursor-pointer">
            <div>
              <FaTruck />
            </div>
            <div className="text-gray-300">
              <FaCaretDown />
            </div>
          </div>
        }
        inDismiss={false}
        outDismiss={false}
      >
        <div className="w-[200px] bg-white border-1 border-gray-200 rounded-sm">
          <div className="flex gap-8 w-full text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200">
            <DynamicSearchSelect
              actionUrl="/suppliers"
              value={selectedSupplier}
              options={[
                {
                  id: 0,
                  label: 'All Suppliers',
                  value: 'All Suppliers',
                },
              ]}
              remapOptions={(data: Api.SupplierList['rows']) => {
                return data?.map((item) => {
                  return {
                    id: item?.supplier_details?.id,
                    label: item?.supplier_details?.name,
                    value: item?.supplier_details?.name,
                  }
                })
              }}
              onChangeValue={(value) => {
                setSelectedSupplier(value)
                setItemActionUrl(
                  value.id === 0 ? '/item' : `/item/supplier/${value.id}`,
                )
                setSelectedItemById({
                  id: 0,
                  label: `Add ${value.label} Item`,
                  value: `Add ${value.label} Item`,
                  materialCost: 0,
                  totalCost: 0,
                })
              }}
            />
          </div>
        </div>
      </Dropdown>

      <div className="mx-6">
        <Button
          title="Add Custom Item"
          onClick={handleModalOpen}
          type={'button'}
        />
      </div>

      <AddCustomItem
        {...{openModal, setOpenModal, fetchInvoiceItem, sectionDetail}}
      />
    </div>
  )
}
