import React, {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {alterFormContent} from 'redux-src'

import {FormContext} from '../formBuilder'
import {useIndividualFormElement} from 'hooks'

interface HeadingCompProps {
  id: number
  onSelectElement: (id: number) => void
}

interface HeadingContentType {
  label: string
  description: string
}

export const Heading = ({id, onSelectElement}: HeadingCompProps) => {
  const {selectedFormElementId} = useContext(FormContext)

  const {individualElement, individualStyles} = useIndividualFormElement(id)

  const [heading, setHeading] = useState<HeadingContentType>(
    individualElement.content as HeadingContentType
  )

  const dispatch = useDispatch()

  const handleChangeHeading = (event: any) => {
    event.preventDefault()
    setHeading((prev) => ({...prev, [event.target.name]: event.target.value}))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, heading))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heading, dispatch])

  const isHeadingAdded = !!(individualElement?.content?.label?.length === 0)

  return (
    individualStyles && (
      <div
        className={`flex flex-col border border-1 rounded-sm  ${
          selectedFormElementId === id
            ? `border-solid border-${isHeadingAdded ? 'red' : 'blue'}-300`
            : `border-dashed border-${
                isHeadingAdded ? 'red' : 'gray'
              }-300 hover:bg-${isHeadingAdded ? 'red' : 'blue'}-100`
        }
      ${individualStyles}
      `}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onSelectElement(id)
        }}
      >
        <div className="px-10 py-8 w-full">
          <input
            type="text"
            name="label"
            value={heading?.label}
            onChange={handleChangeHeading}
            placeholder="Type heading here"
            className="text-black w-full bg-transparent"
          />
        </div>
        {!individualElement?.settings?.hideDescription && (
          <div className="px-14 pb-8">
            <input
              type="text"
              name="description"
              value={heading?.description}
              onChange={handleChangeHeading}
              placeholder="Type description here"
              className="text-black text-md w-full bg-transparent"
            />
          </div>
        )}
      </div>
    )
  )
}
