import React, {useEffect, useState} from 'react'

export interface ImageDataTypes {
  url: string
  file: File
}

interface ImageUploadProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
  images?: ImageDataTypes[]
  fixedResolution?: {
    width: number
    height: number
  }
  onChange: (a: ImageDataTypes[]) => void
  children: ({
    imageData,
    onUploadImage,
    onRemove,
    deleteAllHandler,
  }: {
    imageData: ImageDataTypes[]
    onUploadImage: (a: any) => void
    onRemove: (i: number) => void
    deleteAllHandler: () => void
  }) => React.ReactNode
  multiple?: boolean
  // buttonStyle: React.CSSProperties
  // title:string,
  buttonclick?: React.ReactNode
  accept?: string
  clearCache?: boolean
}

export function ImageUpload(props: ImageUploadProps) {
  const {
    images,
    onChange,
    children,
    multiple,
    // buttonStyle,
    // title,
    buttonclick,
    accept,
    style,
    fixedResolution,
    clearCache,
    ...rest
  } = props

  const [imageData, setImageData] = useState<ImageDataTypes[]>(
    () => images ?? [],
  )

  useEffect(() => {
    onChange(imageData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData])

  const onUploadImage: (files: FileList) => void = (files) => {
    let data: any = []
    if (!files) return setImageData([])
    if (!!fixedResolution) {
      for (let i = 0; i < files.length; i++) {
        let img = new Image()
        img.src = URL.createObjectURL(files[i])

        img.onload = () => {
          if (
            img.width !== (fixedResolution.width ?? 1920) ||
            img.height !== (fixedResolution.height ?? 1080)
          ) {
            console.error(`Sorry, this image doesn't look like the size we wanted. It's 
            ${img.width} x ${img.height} but we require ${
              fixedResolution.width ?? 1920
            } x ${fixedResolution.height ?? 1080} size image.`)

            return
          } else {
            if (files) {
              let impath = URL.createObjectURL(files[i])
              data.push({url: impath, file: files[i]})

              if (multiple && !!!clearCache) {
                setImageData([...imageData, ...data])
              } else setImageData([...data])
            }
          }
        }
      }
    } else {
      for (let i = 0; i < files.length; i++) {
        let impath = URL.createObjectURL(files[i])
        data.push({url: impath, file: files[i]})
      }
      if (multiple && !!!clearCache) {
        setImageData([...imageData, ...data])
      } else setImageData([...data])
    }
  }

  const onRemove = (i: number) => {
    let data = [...imageData]
    data.splice(i, 1)
    setImageData(data)
  }

  const deleteAllHandler = () => {
    setImageData([])
  }

  return (
    <>
      {children({imageData, onUploadImage, onRemove, deleteAllHandler})}
      <label style={{display: 'inline-block', ...style}}>
        <input
          id="click"
          style={{display: 'none'}}
          type="file"
          multiple={multiple}
          name="files"
          onChange={(e) => onUploadImage(e.target.files)}
          accept={accept}
          {...rest}
        />
        {buttonclick}
      </label>
    </>
  )
}
