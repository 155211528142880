import {toast} from 'app/common'
import {api} from 'config'
import {getParsedUrl} from 'helpers'
import {
  GET_ACTIVE_TICKET_OF_COMPANY,
  GET_ACTIVE_USER_OF_COMPANY,
  GET_ASSIGNED_TICKET_FOR_CALENDAR,
  GET_CALENDAR_EVENT_LIST,
  GET_UNASSIGNED_TICKET_FOR_CALENDAR,
} from './actionTypes.action'

// * Get active ticket list for assigned and unassigned ticket list
export const getActiveTicketListOfCompany = (
  params?: {projects?: string},
  successCallback?: () => void,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ACTIVE_TICKET_OF_COMPANY.LOADING})

      res = await api<Api.Base<Api.ActiveTicketList>>(
        getParsedUrl(`/calendar/tickets`, {...params}),
        'GET',
      )

      if (res?.data) {
        const {
          success,
          data: {message, data},
        } = res.data
        if (success) {
          dispatch({
            type: GET_ACTIVE_TICKET_OF_COMPANY.SUCCESS,
            payload: data,
          })
          successCallback?.()
        } else {
          dispatch({type: GET_ACTIVE_TICKET_OF_COMPANY.ERROR, payload: message})
          throw new Error(res?.data?.message)
        }
      } else {
        throw new Error(
          `Something went wrong while fetching active tickets! ${res?.statusText}`,
        )
      }
    } catch (error: any) {
      toast.error(
        !!error.message
          ? error.message
          : 'Something went wrong while fetching active tickets!',
      )
      dispatch({type: GET_ACTIVE_TICKET_OF_COMPANY.ERROR, payload: error})
    }
  }
}

// * Get active users of a company '/calendar/users?projects=1,2,3,4'
export const getActiveUserListOfCompany = (
  params?: {projects?: string},
  successCallback?: () => void,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ACTIVE_USER_OF_COMPANY.LOADING})

      res = await api<Api.Base<Api.CompanyActiveUserList>>(
        getParsedUrl(`/calendar/users`, {...params}),
        'GET',
      )

      if (res?.data) {
        const {
          success,
          data: {message, data},
        } = res.data
        if (success) {
          dispatch({
            type: GET_ACTIVE_USER_OF_COMPANY.SUCCESS,
            payload: data,
          })
          successCallback?.()
        } else {
          dispatch({type: GET_ACTIVE_USER_OF_COMPANY.ERROR, payload: message})
          throw new Error(res?.data?.message)
        }
      } else {
        throw new Error(
          `Something went wrong while fetching active users! ${res?.statusText}`,
        )
      }
    } catch (error: any) {
      toast.error(
        !!error.message
          ? error.message
          : 'Something went wrong while fetching active users!',
      )
      dispatch({type: GET_ACTIVE_USER_OF_COMPANY.ERROR, payload: error})
    }
  }
}

// * Get Calendar Events
export const getCalendarEvents = (
  params?: {
    fromDate?: string
    toDate?: string
  },
  successCallback?: (data: Api.CalendarEventList) => void,
) => {
  return async (dispatch: any) => {
    dispatch({type: GET_CALENDAR_EVENT_LIST.LOADING})
    let res
    try {
      res = await api<Api.Base<Api.CalendarEventList>>(
        getParsedUrl('/calendar/event-list', params),
        'GET',
      )
      if (res?.data?.success) {
        dispatch({
          type: GET_CALENDAR_EVENT_LIST.SUCCESS,
          payload: res.data.data.data,
        })
        successCallback?.(res.data.data.data)
        return
      } else {
        throw new Error(res?.data?.message)
      }
    } catch (error: any) {
      toast.error(
        !!error.message
          ? error.message
          : 'Something went wrong while fetching events!',
      )
      dispatch({type: GET_CALENDAR_EVENT_LIST.ERROR})
    }
  }
}

// * Create calendar events
export const createNewCalendarEvent = (
  params: {
    ticketId: number
    userId: number
    scheduledFrom: string
    scheduledTo: string
    siteVisitId: number
  },
  successCallback?: () => void,
) => {
  return async (dispatch: any) => {
    let res
    try {
      res = await api<Api.Base<any>>('/calendar/events', 'POST', params)
      if (res?.data?.success) {
        toast.success('Successfully created event')
        successCallback?.()
        return
      } else {
        throw new Error(res?.data?.message ?? res?.data?.data?.message)
      }
    } catch (error: any) {
      toast.error(error.message ?? 'Something went wrong while creating event!')
    }
  }
}

// * Get unassigned ticket list of projects of a company
export const getUnAssignedTicketForCalendar = (
  params?: {type?: 'Quote' | 'Days Work'; projects?: string},
  successCallback?: () => void,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_UNASSIGNED_TICKET_FOR_CALENDAR.LOADING})

      res = await api<Api.Base<Api.CalendarTicketList>>(
        getParsedUrl(`/projects/unassigned-tickets`, {
          ...params,
          projectIds: params?.projects,
        }),
        'GET',
      )

      if (res?.data) {
        const {
          success,
          data: {message, data},
        } = res.data
        if (success) {
          dispatch({
            type: GET_UNASSIGNED_TICKET_FOR_CALENDAR.SUCCESS,
            payload: data,
          })
          successCallback?.()
        } else {
          throw new Error(res?.data?.message)
        }
      } else {
        throw new Error(
          `Something went wrong while fetching unassigned tickets! ${res?.statusText}`,
        )
      }
    } catch (error: any) {
      toast.error(
        !!error.message
          ? error.message
          : 'Something went wrong while fetching unassigned tickets!',
      )
      dispatch({type: GET_UNASSIGNED_TICKET_FOR_CALENDAR.ERROR, payload: error})
    }
  }
}

// * Get assigned ticket list of projects of a company
export const getAssignedTicketForCalendar = (
  params?: {
    userIds?: string
    from?: string
    to?: string
    userGroupIds?: string
    projectIds?: string
    projectType?: 'Quote' | 'Days Work'
  },
  successCallback?: (data: Api.CalendarTicketListAssigned) => void,
) => {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_ASSIGNED_TICKET_FOR_CALENDAR.LOADING})

      res = await api<Api.Base<Api.CalendarTicketListAssigned>>(
        getParsedUrl(`/projects/assigned-tickets`, {...params}),
        'GET',
      )

      if (res?.data) {
        const {
          success,
          data: {message, data},
        } = res.data
        if (success) {
          dispatch({
            type: GET_ASSIGNED_TICKET_FOR_CALENDAR.SUCCESS,
            payload: data,
          })
          successCallback?.(data)
        } else {
          throw new Error(res?.data?.message)
        }
      } else {
        throw new Error(
          `Something went wrong while fetching unassigned tickets! ${res?.statusText}`,
        )
      }
    } catch (error: any) {
      toast.error(
        !!error.message
          ? error.message
          : 'Something went wrong while fetching unassigned tickets!',
      )
      dispatch({type: GET_ASSIGNED_TICKET_FOR_CALENDAR.ERROR, payload: error})
    }
  }
}
