import {useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {FaImage, FaLock, FaSignOutAlt, FaWrench} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {Dropdown, Loading} from 'app/common'
import {AvatarModal} from './components'
import {getAssetUrl} from 'helpers'

export const UserProfile = () => {
  const {handleLogout} = useAuth()

  const {loading, userDetails}: RT.UserReduxType = useSelector(
    (state: any) => state.user
  )

  const [isAvatarShow, setAvatarShow] = useState(false)

  const [initialChar, setInitialChar] = useState('')

  useEffect(() => {
    setInitialChar(
      userDetails?.companyName ? userDetails?.companyName.charAt(0) : '-'
    )
  }, [userDetails?.companyName])

  return (
    <>
      <Dropdown
        placement="bottomright"
        triggerToggle
        triggerElement={
          <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full overflow-hidden bg-[purple] text-white font-[900] text-base cursor-pointer">
            {userDetails?.appUser?.asset_details ? (
              <img
                src={getAssetUrl(userDetails?.appUser?.asset_details)}
                alt={userDetails?.companyName}
              />
            ) : (
              initialChar
            )}
          </div>
        }
      >
        <div className="w-[250px] h-[250px] bg-white mt-16 p-8 rounded-md shadow-lg">
          <div className="flex m-8 flex-row">
            <div className=" w-[40px] h-[40px] flex justify-center items-center rounded-full overflow-hidden bg-[purple] text-white font-[900] text-base">
              {userDetails?.appUser?.asset_details ? (
                <img
                  src={getAssetUrl(userDetails?.appUser?.asset_details)}
                  alt={userDetails?.companyName}
                />
              ) : (
                initialChar
              )}
            </div>
            <div className="flex flex-col ml-[8px]">
              <div className="text-gray-500 font-bold text-base m-0 p-0">
                {userDetails?.companyName ? userDetails?.companyName : '---'}
              </div>
              <div className="text-gray-300 text-sm font-bold m-0 p-0">
                {userDetails?.email ? userDetails?.email : '---'}
              </div>
            </div>
          </div>
          <div
            className="flex gap-8 pl-14 py-4 mt-12 text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200"
            onClick={() => setAvatarShow((prev) => !prev)}
          >
            <div className="flex justify-center items-center">
              <FaImage size={14} color="blue" />
            </div>
            Change Avatar
          </div>

          <div className="border-b-1 border-gray-200 my-12" />

          <div className="flex gap-8 pl-14 py-4 mt-12 text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200">
            <div className="flex justify-center items-center">
              <FaWrench size={14} color="blue" />
            </div>
            User Preferences
          </div>

          <div className="flex gap-8 pl-14 py-4 mt-12 text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200">
            <div className="flex justify-center items-center">
              <FaLock size={14} color="blue" />
            </div>
            Two Factor Authentication
          </div>

          <div
            className="flex gap-8 pl-14 py-4 mt-12 text-gray-500 rounded-sm cursor-pointer hover:bg-gray-200"
            onClick={handleLogout}
          >
            <div className="flex justify-center items-center">
              {loading ? (
                <Loading small />
              ) : (
                <FaSignOutAlt size={14} color="blue" />
              )}
            </div>
            Log Out
          </div>
        </div>
      </Dropdown>
      <AvatarModal
        isAvatarShow={isAvatarShow}
        setAvatarShow={setAvatarShow}
        initialChar={initialChar}
      />
    </>
  )
}
