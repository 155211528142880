import {
  CREATE_SUPPLIER_SETTING,
  GET_SUPPLIER_SETTING,
  UPDATE_SUPPLIER_SETTING,
} from '../actions'

const initalState: RT.SupplierReduxType = {
  create_supplier_loading: false,

  get_supplier_loading: false,
  supplierList: [],
  supplierTotal: 0,

  update_supplier_loading: false,
}

export function supplierSettingReducer(state = initalState, action: any) {
  const { type, payload } = action

  switch (type) {
    case CREATE_SUPPLIER_SETTING.LOADING:
      return { ...state, create_supplier_loading: true }
    case CREATE_SUPPLIER_SETTING.SUCCESS:
      return { ...state, create_supplier_loading: false }
    case CREATE_SUPPLIER_SETTING.ERROR:
      return { ...state, create_supplier_loading: false }

    case GET_SUPPLIER_SETTING.LOADING:
      return { ...state, get_supplier_loading: true }
    case GET_SUPPLIER_SETTING.SUCCESS:
      return {
        ...state,
        get_supplier_loading: false,
        supplierList: payload.rows,
        supplierTotal: payload.total,
      }
    case GET_SUPPLIER_SETTING.ERROR:
      return { ...state, get_supplier_loading: false }

    case UPDATE_SUPPLIER_SETTING.LOADING:
      return { ...state, update_supplier_loading: true }
    case UPDATE_SUPPLIER_SETTING.SUCCESS:
      return { ...state, update_supplier_loading: false }
    case UPDATE_SUPPLIER_SETTING.ERROR:
      return { ...state, update_supplier_loading: false }

    default:
      return state
  }
}
