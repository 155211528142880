import {useDispatch, useSelector} from 'react-redux'
import {TableColumn, TableStyles} from 'react-data-table-component'
// import colors from 'tailwindcss/colors'
import moment from 'moment'
import {FaEdit} from 'react-icons/fa'

import {DataTable} from 'app/components'
import {ButtonGroup} from 'app/common'
import {useAuth} from 'react-auth-navigation'

export const QuoteVersion = ({
  getQuotes
}: {
  getQuotes: (page: number, limit: number) => void
}) => {
  const dispatch = useDispatch()
  const {currencySymbol} = useAuth()
  const {quotes, quoteTotal}: RT.QuoteReduxType = useSelector(
    (state: any) => state.quotes
  )

  const columns: TableColumn<Api.QuoteProjectIndividual>[] = [
    {
      name: 'Version',
      selector: (row) => row?.quote_details?.version,
      sortable: true,
      cell: (row) => (
        <div
          // to={`/dashboard/customers/customer-view/projects/${row.quote_details.id}`}
          onClick={() => {
            dispatch({
              type: 'SET_ACTIVE_QUOTE',
              payload: row?.quote_details?.id
            })
            // setActiveQuoteId(row?.quote_details?.id)
          }}
          className="flex badge items-center text-blue-300 cursor-pointer"
        >
          v{row?.quote_details?.version}
        </div>
      )
    },
    {
      name: 'Status',
      selector: (row) => row?.quote_details?.status,
      sortable: true,
      cell: (row) =>
        row?.quote_details?.is_super_seeded ? (
          <div
            className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-orange-900`}
            // style={{
            //   backgroundColor: chipColor[value as keyof typeof chipColor]
            // }}
          >
            SUPERSEDED
          </div>
        ) : (
          getChipData(row?.quote_details?.status)
        )
    },
    {
      name: 'Delivery',
      selector: (row) => row?.quote_details?.is_delivered,
      sortable: true,
      cell: (row) =>
        row?.quote_details?.is_delivered ? (
          <div className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-green-400">
            SENT
          </div>
        ) : (
          <div className="text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase bg-orange-400">
            NOT SENT
          </div>
        )
    },
    {
      name: 'Created',
      selector: (row) => row?.quote_details?.created_at,
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.quote_details.id}`}
            className="text-black"
          >
            {moment(row?.quote_details?.created_at).format('DD/MM/YYYY')}
          </div>
        </div>
      )
    },
    {
      name: 'Sub Total',
      selector: (row) => row?.quote_details?.discounted_cost,
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.quote_details.id}`}
            className="text-black"
          >
            {currencySymbol}
            {row?.quote_details?.discounted_cost}
          </div>
        </div>
      )
    },
    {
      name: 'Total Incl. Tax',
      selector: (row) => row?.quote_details?.total_cost,

      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center">
          <div className="text-black">
            {currencySymbol}
            {row?.quote_details?.total_cost}
          </div>
        </div>
      )
    },
    {
      // name: 'Jobs',
      // selector: (row) => row.quote_details.jobs,
      cell: (row) => (
        <ButtonGroup
          data={[
            {
              icon: <FaEdit />,
              title: 'View',
              onClick: () => {
                dispatch({
                  type: 'SET_ACTIVE_QUOTE',
                  payload: row?.quote_details?.id
                })
                // setActiveQuoteId(row?.quote_details?.id)
              }
            }
          ]}
        />
      )
    }
  ]

  const customStyles: TableStyles = {
    cells: {
      style: {
        '&:first-child': {
          width: '50px'
        }
      }
    }
  }

  return (
    <DataTable
      columns={columns}
      data={quotes}
      pagination
      persistTableHead
      dense
      customStyles={customStyles}
      striped
      totalRows={quoteTotal}
      fetchData={getQuotes}
    />
  )
}

export const getChipData = (value: string) => {
  const chipColor = {
    voided: 'bg-gray-400',
    declined: 'bg-red-400',
    draft: 'bg-orange-400',
    accepted: 'bg-green-400',
    published: 'bg-blue-400'
  }

  return (
    <div
      key={value}
      className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase ${
        chipColor[value as keyof typeof chipColor]
      }`}
      // style={{
      //   backgroundColor: chipColor[value as keyof typeof chipColor]
      // }}
    >
      {value}
    </div>
  )
}
