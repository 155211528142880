import {useEffect, useState} from 'react'
import {BiDollar} from 'react-icons/bi'
import {FaComments, FaEnvelope} from 'react-icons/fa'
import {HiDotsHorizontal} from 'react-icons/hi'
import {MdLocationPin} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux'
import {alterContactTypes, getSiteById, getSiteContact} from 'redux-src'

import {ConfirmationModal, Dropdown} from 'app/common'
import {useNavigation} from 'react-auth-navigation'
import {ContactModal} from '../contactModal'

export const SiteContactBlock = ({
  type = 'default',
  contactId
}: {
  type: 'default' | 'billing' | 'both'
  contactId?: number
}) => {
  const dispatch = useDispatch()
  const {
    navigation: {navigate}
  } = useNavigation()

  const [isEditModalActive, setEditModalActive] = useState<boolean>(false)

  const {siteById: site}: RT.SiteReduxType = useSelector(
    (state: any) => state.site
  )

  const [contacts, setContacts] = useState<Api.ContactType>()

  const [postalAddress, setPostalAddress] = useState<Api.AddressDetailType>()

  useEffect(() => {
    if (site?.site_details?.id && contactId)
      dispatch(
        getSiteContact(site?.site_details?.id, contactId, (data) => {
          setPostalAddress(
            !!data?.address_details &&
              Object.keys(data?.address_details).length > 0
              ? data?.address_details
              : site?.postal_address_details
          )
          setContacts(data)
        })
      )
  }, [
    contactId,
    dispatch,
    site?.postal_address_details,
    site?.site_details?.id
  ])

  const handleAlterContactType = (
    contactType: 'other-contacts' | 'main-contact',
    setAs: 'default' | 'billing'
  ) => {
    dispatch(
      alterContactTypes(
        site?.site_details?.id,
        site?.site_details?.info,
        contactId,
        contactType,
        setAs,
        () => {
          if (site?.site_details?.id)
            dispatch(getSiteById(site?.site_details?.id))
        }
      )
    )
  }

  return (
    <div className="flex flex-col border-1 border-gray-200 p-10 px-16 rounded-sm flex-nowrap flex-1">
      <div className="flex justify-between items-center pb-4">
        {(type === 'default' || type === 'both') && (
          <div className="flex flex-row flex-nowrap font-bold text-[11px] gap-4 px-8 py-[3px] bg-yellow-300 text-black uppercase items-center rounded-full">
            <MdLocationPin size={12} />
            Default Contact
          </div>
        )}
        {(type === 'billing' || type === 'both') && (
          <div className="flex flex-row flex-nowrap font-bold text-[11px] gap-4 px-8 py-[3px] bg-orange-700 text-white uppercase items-center rounded-full">
            <BiDollar size={12} />
            Billing Contact
          </div>
        )}
        <Dropdown
          inDismiss={false}
          outDismiss={false}
          placement="bottomright"
          triggerElement={
            <button className="p-10 py-6 bg-grey-100 shadow-sm rounded-sm border-1 transition-all duration-200 ease-in-out border-none hover:bg-grey-200">
              <HiDotsHorizontal size={20} />
            </button>
          }
        >
          <div style={{minWidth: '12rem', zIndex: 1}}>
            <div className=" w-full mt-10 bg-white">
              {/* <DropDownFiles /> */}

              <div
                className="flex-1 w-full   overflow-visible "
                style={{width: 200}}
              >
                <ul className=" border-gray-200 rounded-sm shadow-xl overflow-auto ">
                  <li
                    className={`p-10 pl-20 cursor-pointer  bg-white text-gray-500 hover:bg-blue-100 text-[12px]`}
                    onClick={() => {
                      navigate(
                        `/sites/view/${site?.site_details?.id}?contactId=${contactId}&contactType=main`
                      )
                      setEditModalActive(true)
                    }}
                  >
                    <span className="text-left inline-flex items-center gap-4">
                      Edit
                    </span>
                  </li>
                  <li
                    className={`p-10 pl-20 text-[12px] ${
                      type === 'default' || type === 'both'
                        ? `bg-gray-200 hover:bg-gray-200 text-gray-300 cursor-not-allowed`
                        : 'bg-white hover:bg-blue-100 text-gray-500 cursor-pointer'
                    }`}
                    onClick={() =>
                      type === 'billing' &&
                      handleAlterContactType('main-contact', 'default')
                    }
                  >
                    <span className="text-left inline-flex items-center gap-4">
                      Set as Default Contact
                    </span>
                  </li>
                  <li
                    className={`p-10 pl-20 text-[12px] ${
                      type === 'billing' || type === 'both'
                        ? `bg-gray-200 hover:bg-gray-200 text-gray-300 cursor-not-allowed`
                        : 'bg-white hover:bg-blue-100 text-gray-500 cursor-pointer'
                    }`}
                    onClick={() =>
                      type === 'default' &&
                      handleAlterContactType('main-contact', 'billing')
                    }
                  >
                    <span className="text-left inline-flex items-center gap-4">
                      Set as Billing Contact
                    </span>
                  </li>
                  <ConfirmationModal
                    label={'Removing Contacts?'}
                    confirmLabel="Accept"
                    displayElement={
                      <li
                        className={`p-10 pl-20 cursor-pointer  bg-white text-gray-500 hover:bg-blue-100 text-[12px]`}
                      >
                        <span className="text-left inline-flex items-center gap-4">
                          Remove Contact
                        </span>
                      </li>
                    }
                    onConfirmClick={(closeModal) => closeModal()}
                  >
                    <div className="flex flex-col items-center justify-center gap-30 text-center">
                      <div>
                        All Customers and Sites must have a{' '}
                        {type === 'both' ? 'default / billing' : type} contact.
                      </div>
                      <div>
                        Before you can remove this contact, you must set a new{' '}
                        {type === 'both' ? 'default / billing' : type} contact
                        first. Then, you can remove this contact from the
                        additional contact list.
                      </div>
                    </div>
                  </ConfirmationModal>
                </ul>
              </div>
            </div>
          </div>
        </Dropdown>
      </div>
      <div className="flex flex-row flex-nowrap">
        <div className="flex-1 gap-2">
          <span className="text-black font-bold text-[14px]">
            {contacts?.firstname} {contacts?.lastname}
          </span>
          {contacts?.position && (
            <span className="flex text-gray-500 items-center justify-start">
              {contacts?.position}
            </span>
          )}
          {contacts?.phones?.length > 0 && (
            <span className="flex text-gray-500 items-center justify-start">
              {contacts?.phones[0]}
            </span>
          )}
          {contacts?.emails?.length > 0 && (
            <span className="flex items-center justify-start text-blue-300">
              {contacts?.emails[0]}
            </span>
          )}
        </div>

        <div className="relative pl-20 flex-1 gap-2">
          <div className="absolute w-[2px] h-[80px] bg-gray-200 rounded-md left-0 top-6" />
          <span className="text-black font-bold text-[14px]">
            Postal Address
          </span>
          {postalAddress?.place && (
            <span className="flex text-gray-500 items-center justify-start">
              {postalAddress?.place}
            </span>
          )}
          {postalAddress?.suburb && (
            <span className="flex text-gray-500 items-center justify-start">
              {postalAddress?.suburb}
            </span>
          )}
          {postalAddress?.city && (
            <span className="flex text-gray-500 items-center justify-start">
              {postalAddress?.city}
            </span>
          )}
          {postalAddress?.country && (
            <span className="flex text-gray-500 items-center justify-start">
              {postalAddress?.country}
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-end items-center gap-10 py-10">
        <button className="p-10 py-6 bg-grey-100 shadow-sm rounded-sm border-1 transition-all duration-200 ease-in-out border-none hover:bg-grey-200">
          <FaEnvelope size={16} />
        </button>
        <button className="p-10 py-6 bg-grey-100 shadow-sm rounded-sm border-1 transition-all duration-200 ease-in-out border-none hover:bg-grey-200">
          <FaComments size={16} />
        </button>
        <button className="p-10 py-6 bg-grey-100 shadow-sm rounded-sm border-1 transition-all duration-200 ease-in-out border-none hover:bg-grey-200">
          <MdLocationPin size={16} />
        </button>
      </div>
      <ContactModal
        isModalActive={isEditModalActive}
        setModalActive={setEditModalActive}
        type={type}
        editMode
      />
    </div>
  )
}
