import {
  GET_LINKED_CHECKLISTS,
  GET_UNLINKED_CHECKLISTS,
  LINK_CHECKLIST,
  UNLINK_CHECKLIST,
  UPLOAD_FLOOR_PLAN,
} from '../actions'

const initalState: any = {
  getLinkedChecklistsLoading: true,
  getLinkedChecklists: [],

  getUnlinkedChecklistsLoading: true,
  getUnlinkedChecklists: [],

  checklistLinking: false,
  checklistUnlinking: false,
  uploadFloorPlanLoading: false,
}

export function planviewerReducer(state = initalState, action: any) {
  const { type, payload } = action

  switch (type) {
    case GET_LINKED_CHECKLISTS.LOADING:
      return { ...state, getLinkedChecklistsLoading: true }
    case GET_LINKED_CHECKLISTS.SUCCESS:
      return {
        ...state,
        getLinkedChecklistsLoading: false,
        getLinkedChecklists: payload,
      }
    case GET_LINKED_CHECKLISTS.ERROR:
      return { ...state, getLinkedChecklistsLoading: false }

    case GET_UNLINKED_CHECKLISTS.LOADING:
      return { ...state, getUnlinkedChecklistsLoading: true }
    case GET_UNLINKED_CHECKLISTS.SUCCESS:
      return {
        ...state,
        getUnlinkedChecklistsLoading: false,
        getUnlinkedChecklists: payload,
      }
    case GET_UNLINKED_CHECKLISTS.ERROR:
      return { ...state, getUnlinkedChecklistsLoading: false }

    case LINK_CHECKLIST.LOADING:
      return { ...state, checklistLinking: true }
    case LINK_CHECKLIST.SUCCESS:
      return { ...state, checklistLinking: false }
    case LINK_CHECKLIST.ERROR:
      return { ...state, checklistLinking: false }

    case UNLINK_CHECKLIST.LOADING:
      return { ...state, checklistUnlinking: true }
    case UNLINK_CHECKLIST.SUCCESS:
      return { ...state, checklistUnlinking: false }
    case UNLINK_CHECKLIST.ERROR:
      return { ...state, checklistUnlinking: false }

    case UPLOAD_FLOOR_PLAN.LOADING:
      return { ...state, uploadFloorPlanLoading: true }
    case UPLOAD_FLOOR_PLAN.SUCCESS:
      return { ...state, uploadFloorPlanLoading: false }
    case UPLOAD_FLOOR_PLAN.ERROR:
      return { ...state, uploadFloorPlanLoading: false }

    default:
      return state
  }
}
