import {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {CustomGridItem} from 'app/components'
import {Collapsible} from 'app/common'
import {onAddFormSections} from 'redux-src'

import {FormElementSettings} from '../formElementSettings'
import {FormContext} from '../formBuilder'

export const FormSidePanel = () => {
  const dispatch = useDispatch()

  const {
    changeSelectedFormElementId,
    selectedFormElementId,
    changeDraggedElement
  } = useContext(FormContext)

  const {
    formFieldDataTypeData: formElements,
    instructionsElements,
    dataEntryElements
  }: RT.FormsReduxType = useSelector((state: any) => state.forms)

  const handelClick = (
    el: React.MouseEvent<HTMLDivElement>,
    formType: RT.FormBuilderType
  ) => {
    el.preventDefault()

    dispatch(
      onAddFormSections(formElements[formType], (id, formEl) => {
        changeSelectedFormElementId(id, {...formEl, id})
      })
    )
  }

  return (
    <div className="flex flex-col w-full">
      {!!selectedFormElementId && (
        <div className="min-h-[160px]">
          <FormElementSettings />
        </div>
      )}

      <div className="flex gap-4 flex-col w-full border border-t-1 border-solid border-gray-200 py-14">
        <div className="text-md font-bold px-16">Form Elements</div>

        <div className="px-16">
          <Collapsible title={'Instructional Elements'}>
            {instructionsElements?.map((element) => (
              <CustomGridItem
                key={element?.id}
                onClick={(e: any) => {
                  handelClick(e, element.formType as RT.FormBuilderType)
                }}
                className=" w-full flex items-center justify-start text-black gap-10 px-16 py-6 text-sm rounded-xs cursor-pointer capitalize hover:bg-blue-150"
                draggable
                unselectable="on"
                data-grid={formElements[element.formType]?.layout}
                onDragStart={(e) => {
                  e.dataTransfer.setData('text/plain', '')
                  changeDraggedElement(formElements[element.formType])
                }}
              >
                <span className="bg-blue-100 p-6 rounded-xs">
                  {element.icon}
                </span>

                <span className="text-sm">{element.heading}</span>
              </CustomGridItem>
            ))}
          </Collapsible>
        </div>
        <div className="px-16">
          <Collapsible title={'Data Entry Elements'}>
            {dataEntryElements?.map((element) => (
              <CustomGridItem
                key={element?.id}
                onClick={(e: any) => {
                  handelClick(e, element.formType as RT.FormBuilderType)
                }}
                className=" w-full flex items-center justify-start text-black gap-10 px-16 py-6 text-sm rounded-xs cursor-pointer capitalize hover:bg-blue-150"
                draggable
                unselectable="on"
                data-grid={formElements[element.formType]?.layout}
                onDragStart={(e) => {
                  e.dataTransfer.setData('text/plain', '')
                  changeDraggedElement(formElements[element.formType])
                }}
              >
                <span className="bg-blue-100 p-6 rounded-xs">
                  {element.icon}
                </span>
                <span className="text-sm">{element.heading}</span>
              </CustomGridItem>
            ))}
          </Collapsible>
        </div>
      </div>
    </div>
  )
}
