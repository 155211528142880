import React, {useRef, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {IoAddOutline} from 'react-icons/io5'
import {VscFilePdf} from 'react-icons/vsc'

import {Loading} from 'app/common'
import {useDispatch, useSelector} from 'react-redux'
import {addAttachmentsToTicket, getTicketAttachmentList} from 'redux-src'
import {getAssetName, getAssetUrl} from 'helpers'
import {AllAttachmentsModal} from '../../../allAttachmentsModal'
import {MdVideoFile} from 'react-icons/md'

interface TicketAttachmentsProps {
  edit?: boolean
  isSubTicketEdit?: boolean
  projectTicketId?: number
  getTicketLogList?: () => void
  setAssets?: React.Dispatch<
    React.SetStateAction<Array<{url: string; file: File}>>
  >
  assets?: Array<{url: string; file: File}>
  addDisabled?: boolean
}

export const TicketAttachments = ({
  edit,
  isSubTicketEdit,
  projectTicketId,
  getTicketLogList,
  setAssets,
  assets,
  addDisabled
}: TicketAttachmentsProps) => {
  const {
    ticketAttachments,
    getTicketAttachmentListLoading
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const [isDragActive, setDragActive] = useState<boolean>(false)

  const [attachmentModalVisible, setAttachmentModalVisible] =
    useState<boolean>(false)

  const attachmentRef = useRef<HTMLInputElement>()

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const uploadTicketAttachment = (e: any) => {
    const uploadFiles = [...e.target.files]?.map((image) => {
      return {
        url: URL.createObjectURL(image),
        file: image
      }
    })

    setAssets((prev) => {
      if (prev === undefined || prev === null) return uploadFiles
      const previousAssets = [...prev]
      return [...previousAssets, ...uploadFiles]
    })

    if (edit || isSubTicketEdit) {
      const formData = new FormData()

      uploadFiles?.map((assets) => {
        return formData.append('files', assets?.file)
      })

      dispatch(
        addAttachmentsToTicket(projectId, projectTicketId, formData, () => {
          dispatch(getTicketAttachmentList(projectId, projectTicketId))
          getTicketLogList()
        })
      )
    }

    const reader = new FileReader()
    reader.onload = () => {
      if (reader?.readyState === 2) {
        return
      }
    }

    reader?.readAsDataURL(e?.target?.files[0])
  }

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const formData = new FormData()

      const uploadFiles = [...e.dataTransfer.files]?.map((image) => {
        return {
          url: URL.createObjectURL(image),
          file: image
        }
      })

      setAssets((prev) => {
        if (prev === undefined || prev === null) return uploadFiles
        const previousAssets = [...prev]
        return [...previousAssets, ...uploadFiles]
      })

      if (edit) {
        ;[...e.dataTransfer.files]?.map((assets: any) => {
          return formData.append('files', assets)
        })

        dispatch(
          addAttachmentsToTicket(projectId, projectTicketId, formData, () => {
            dispatch(getTicketAttachmentList(projectId, projectTicketId))

            getTicketLogList()
          })
        )
      }

      const reader = new FileReader()
      reader.onload = () => {
        if (reader?.readyState === 2) {
          return
        }
      }

      reader?.readAsDataURL(e?.dataTransfer?.files[0])
    }
  }

  return (
    <div className="flex flex-col px-10 py-8 border border-solid border-1 border-gray-200 rounded-sm min-h-[100px]">
      <div className="text-sm font-bold text-black">Attachments</div>
      <div className="flex flex-col mt-6 gap-10">
        <label
          htmlFor="attachments"
          className={`w-full flex gap-4 px-8 items-center justify-center py-6 border border-1 border-solid border-gray-200 hover:bg-gray-100 bg-white text-black cursor-pointer rounded-sm ${
            addDisabled ? 'pointer-events-none' : 'pointer-events-auto'
          }`}
        >
          <IoAddOutline size={18} />
          <span>Add new attachment</span>
        </label>
        <div
          className={`py-14 min-h-[100px] rounded-sm flex flex-1 flex-wrap text-black cursor-pointer 
                  border border-1 border-dashed border-gray-200 relative bg-white  w-fullrounded-sm ${
                    isDragActive
                      ? 'bg-red-200 border-gray-300'
                      : 'bg-blue-100 border-blue-200'
                  }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          {(!!ticketAttachments && ticketAttachments?.length > 0) ||
          (!!assets && assets?.length > 0) ? (
            <div
              className="grid grid-cols-2 gap-4 w-full justify-between cursor-pointer"
              onClick={() => {
                setAttachmentModalVisible((prev) => !prev)
              }}
            >
              {!!ticketAttachments && ticketAttachments?.length > 0 ? (
                getTicketAttachmentListLoading ? (
                  <Loading />
                ) : (
                  ticketAttachments
                    ?.slice(0, 4)
                    ?.map((image, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          {image?.asset_details?.file_type === 'image' ? (
                            <div className="rounded-sm bg-gray-100 relative flex items-center justify-center w-full h-[140px] flex-col gap-6 text-gray-400 overflow-hidden">
                              <img
                                src={getAssetUrl(image?.asset_details)}
                                alt={image?.asset_details?.name}
                                className="rounded-sm object-cover"
                              />
                              <div className="absolute left-0 bottom-4 bg-white rounded-sm shadow-sm py-4 flex justify-center items-center break-all px-[10px]">
                                {image && image?.asset_details?.label
                                  ? image?.asset_details?.label
                                  : getAssetName(image?.asset_details)}
                              </div>
                            </div>
                          ) : image?.asset_details?.file_type === 'video' ? (
                            <div className="rounded-sm bg-gray-100 relative flex items-center justify-center w-full h-[140px] flex-col gap-6 text-gray-400 overflow-hidden">
                              <MdVideoFile size={50} />
                              <div className="absolute left-0 bottom-4 bg-white rounded-sm shadow-sm py-4 flex justify-center items-center break-all px-[10px]">
                                {image && image?.asset_details?.label
                                  ? image?.asset_details?.label
                                  : getAssetName(image?.asset_details)}
                              </div>
                            </div>
                          ) : (
                            <div className="rounded-sm bg-gray-100 relative flex items-center justify-center w-full h-[140px] flex-col gap-6 text-gray-400 overflow-hidden">
                              <VscFilePdf size={50} />
                              <div className="absolute left-0 bottom-4 bg-white rounded-sm shadow-sm py-4 flex justify-center items-center break-all px-[10px]">
                                {image && image?.asset_details?.label
                                  ? image?.asset_details?.label
                                  : getAssetName(image?.asset_details)}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )
                    })
                )
              ) : (
                assets?.slice(0, 4)?.map((item) => {
                  return (
                    <React.Fragment key={item?.url}>
                      {item?.file?.type?.includes('image') ? (
                        <div className="rounded-sm bg-gray-100 relative flex items-center justify-center w-full h-[140px] flex-col gap-6 text-gray-400 overflow-hidden">
                          <img
                            src={item?.url.toString()}
                            alt={item?.file?.name}
                            className="rounded-sm object-cover"
                          />
                          <div className="absolute left-0 bottom-4 bg-white rounded-sm shadow-sm py-4 flex justify-center items-center break-all px-[10px]">
                            {item?.file?.name}
                          </div>
                        </div>
                      ) : item?.file?.type?.includes('video') ? (
                        <div className="rounded-sm bg-gray-100 relative flex items-center justify-center w-full h-[140px] flex-col gap-6 text-gray-400 overflow-hidden">
                          <MdVideoFile size={50} />
                          <div className="absolute left-0 bottom-4 bg-white rounded-sm shadow-sm py-4 flex justify-center items-center break-all px-[10px]">
                            {item?.file?.name}
                          </div>
                        </div>
                      ) : (
                        <div className="rounded-sm bg-gray-100 relative flex items-center justify-center w-full h-[140px] flex-col gap-6 text-gray-400 overflow-hidden">
                          <VscFilePdf size={50} />
                          <div className="absolute left-0 bottom-4 bg-white rounded-sm shadow-sm py-4 flex justify-center items-center break-all px-[10px]">
                            {item?.file?.name}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  )
                })
              )}
            </div>
          ) : (
            <>
              <div
                className={`flex items-center justify-center w-full h-[100px] ${
                  addDisabled ? 'pointer-events-none' : 'pointer-events-auto'
                }`}
              >
                <label
                  htmlFor="attachments"
                  className="flex items-center justify-center cursor-pointer mt-auto text-gray-400 h-full w-full"
                >
                  Drag file here
                </label>
              </div>
            </>
          )}
          <input
            ref={attachmentRef}
            className="cursor-pointer hidden"
            type="file"
            id="attachments"
            name="attachments"
            accept="application/pdf,image/png,image/jpg,image/jpeg"
            multiple={true}
            onChange={uploadTicketAttachment}
          />
        </div>

        {assets?.length > 0 ||
          (ticketAttachments?.length > 0 && (
            <div
              className="w-full border border-solid border-1 border-gray-200 flex items-center justify-center py-4 cursor-pointer rounded-sm
                        hover:bg-gray-100 transition-all duration-200 ease-in-out
                        "
              onClick={() => {
                setAttachmentModalVisible((prev) => !prev)
              }}
            >
              Show all attachments
            </div>
          ))}
      </div>
      {attachmentModalVisible && (
        <AllAttachmentsModal
          isModalVisible={attachmentModalVisible}
          setIsModalVisible={setAttachmentModalVisible}
          labelId="attachments"
          {...{assets, setAssets, addDisabled, getTicketLogList, edit}}
        />
      )}
    </div>
  )
}
