import {
  CREATE_SITE_VISIT_LIST,
  GET_SITE_VISIT_LIST,
  GET_SITE_VISIT_TODAY_EVENTS_LIST,
  GET_TIME_ENTRY_TODAY_EVENTS_LIST,
  GET_VOIDED_SITE_VISIT_LIST,
  GET_SITE_VISIT_DETAIL,
  GET_ASSIGNED_SITE_EMPLOYEE_LIST,
  ASSIGN_SITE_EMPLOYEE,
  CREATE_EVENT,
  CREATE_TIME_ENTRY,
  GET_TIME_ENTRY,
  UPDATE_TIME_ENTRY,
  DELETE_TIME_ENTRY,
} from '../actions'

const initialState: RT.SiteVisitReduxType = {
  createSiteVisitLoading: false,

  defaultQuoteSettings: {},

  createSiteVisitError: '',
  get_error: '',

  getSiteVisitListLoading: true,
  getSiteVisitListError: false,
  siteVisitTotal: 0,
  getSiteVisitList: undefined,

  getSiteVisitTodayEventsListLoading: true,
  getSiteVisitTodayEventsListError: false,
  getSiteVisitTodayEventsList: [],

  getVoidedSiteVisitListLoading: true,
  getVoidedSiteVisitListError: false,
  getVoidedSiteVisitList: [],

  getSiteVisitDetailLoading: true,
  getSiteVisitDetailError: false,
  getSiteVisitDetail: null,

  getAssignedSiteEmployeeListLoading: false,
  getAssignedSiteEmployeeListError: false,
  getAssignedSiteEmployeeList: [],

  assignSiteVisitEmployeeLoading: false,
  assignSiteVisitEmployeeError: false,

  createEventLoading: false,
  createEventError: false,

  createTimeEntryLoading: false,
  createTimeEntryError: false,

  getTimeEntryLoading: false,
  getTimeEntryError: false,
  getTimeEntryList: [],

  updateTimeEntryLoading: false,
  updateTimeEntryError: false,

  deleteTimeEntryLoading: false,
  deleteTimeEntryError: false,
}

export function siteVisitReducer(state = initialState, action: any) {
  const {type, payload} = action

  //

  switch (type) {
    case CREATE_SITE_VISIT_LIST.LOADING:
      return {
        ...state,
        createSiteVisitLoading: true,
        createSiteVisitError: false,
      }
    case CREATE_SITE_VISIT_LIST.SUCCESS:
      return {
        ...state,
        createSiteVisitLoading: false,
        createSiteVisitError: false,
      }
    case CREATE_SITE_VISIT_LIST.ERROR:
      return {
        ...state,
        createSiteVisitLoading: false,
        createSiteVisitError: true,
      }

    case GET_SITE_VISIT_LIST.LOADING:
      return {
        ...state,
        getSiteVisitListLoading: true,
        getSiteVisitListError: false,
      }
    case GET_SITE_VISIT_LIST.SUCCESS:
      return {
        ...state,
        getSiteVisitListLoading: false,
        getSiteVisitListError: false,
        getSiteVisitList: payload.rows,
        siteVisitTotal: payload.total,
      }
    case GET_SITE_VISIT_LIST.ERROR:
      return {
        ...state,
        getSiteVisitListLoading: false,
        getSiteVisitListError: true,
      }

    case GET_SITE_VISIT_TODAY_EVENTS_LIST.LOADING:
      return {
        ...state,
        getSiteVisitTodayEventsListLoading: true,
        getSiteVisitTodayEventsListError: false,
      }
    case GET_SITE_VISIT_TODAY_EVENTS_LIST.SUCCESS:
      return {
        ...state,
        getSiteVisitTodayEventsListLoading: false,
        getSiteVisitTodayEventsListError: false,
        getSiteVisitTodayEventsList: payload,
      }
    case GET_SITE_VISIT_TODAY_EVENTS_LIST.ERROR:
      return {
        ...state,
        getSiteVisitTodayEventsListLoading: false,
        getSiteVisitTodayEventsListError: true,
      }

    case GET_TIME_ENTRY_TODAY_EVENTS_LIST.LOADING:
      return {
        ...state,
        getTimeEntryTodayEventsListLoading: true,
        getTimeEntryTodayEventsListError: false,
      }
    case GET_TIME_ENTRY_TODAY_EVENTS_LIST.SUCCESS:
      return {
        ...state,
        getTimeEntryTodayEventsListLoading: false,
        getTimeEntryTodayEventsListError: false,
        getTimeEntryTodayEventsList: payload,
      }
    case GET_TIME_ENTRY_TODAY_EVENTS_LIST.ERROR:
      return {
        ...state,
        getTimeEntryTodayEventsListLoading: false,
        getTimeEntryTodayEventsListError: true,
      }

    case GET_VOIDED_SITE_VISIT_LIST.LOADING:
      return {
        ...state,
        getVoidedSiteVisitListLoading: true,
        getVoidedSiteVisitListError: false,
      }
    case GET_VOIDED_SITE_VISIT_LIST.SUCCESS:
      return {
        ...state,
        getVoidedSiteVisitListLoading: false,
        getVoidedSiteVisitListError: false,
        getVoidedSiteVisitList: payload?.rows,
      }
    case GET_VOIDED_SITE_VISIT_LIST.ERROR:
      return {
        ...state,
        getVoidedSiteVisitListLoading: false,
        getVoidedSiteVisitListError: true,
      }

    case GET_SITE_VISIT_DETAIL.LOADING:
      return {
        ...state,
        getSiteVisitDetailLoading: true,
        getSiteVisitDetailError: false,
      }
    case GET_SITE_VISIT_DETAIL.SUCCESS:
      return {
        ...state,
        getSiteVisitDetailLoading: false,
        getSiteVisitDetailError: false,
        getSiteVisitDetail: payload,
      }
    case GET_SITE_VISIT_DETAIL.ERROR:
      return {
        ...state,
        getSiteVisitDetailLoading: false,
        getSiteVisitDetailError: true,
      }

    case ASSIGN_SITE_EMPLOYEE.LOADING:
      return {
        ...state,
        assignSiteVisitEmployeeLoading: true,
        assignSiteVisitEmployeeError: false,
      }
    case ASSIGN_SITE_EMPLOYEE.SUCCESS:
      return {
        ...state,
        assignSiteVisitEmployeeLoading: false,
        assignSiteVisitEmployeeError: false,
      }
    case ASSIGN_SITE_EMPLOYEE.ERROR:
      return {
        ...state,
        assignSiteVisitEmployeeLoading: false,
        assignSiteVisitEmployeeError: true,
      }

    case GET_ASSIGNED_SITE_EMPLOYEE_LIST.LOADING:
      return {
        ...state,
        getAssignedSiteEmployeeListLoading: true,
        getAssignedSiteEmployeeListError: false,
      }
    case GET_ASSIGNED_SITE_EMPLOYEE_LIST.SUCCESS:
      return {
        ...state,
        getAssignedSiteEmployeeListLoading: false,
        getAssignedSiteEmployeeListError: false,
        getAssignedSiteEmployeeList: payload,
      }
    case GET_ASSIGNED_SITE_EMPLOYEE_LIST.ERROR:
      return {
        ...state,
        getAssignedSiteEmployeeListLoading: false,
        getAssignedSiteEmployeeListError: true,
      }

    case CREATE_EVENT.LOADING:
      return {
        ...state,
        createEventLoading: true,
        createEventError: false,
      }
    case CREATE_EVENT.SUCCESS:
      return {
        ...state,
        createEventLoading: false,
        createEventError: false,
      }
    case CREATE_EVENT.ERROR:
      return {
        ...state,
        createEventLoading: false,
        createEventError: true,
      }

    case CREATE_TIME_ENTRY.LOADING:
      return {
        ...state,
        createTimeEntryLoading: true,
        createTimeEntryError: false,
      }
    case CREATE_TIME_ENTRY.SUCCESS:
      return {
        ...state,
        createTimeEntryLoading: false,
        createTimeEntryError: false,
      }
    case CREATE_TIME_ENTRY.ERROR:
      return {
        ...state,
        createTimeEntryLoading: false,
        createTimeEntryError: true,
      }

    case GET_TIME_ENTRY.LOADING:
      return {
        ...state,
        getTimeEntryLoading: true,
        getTimeEntryError: false,
      }
    case GET_TIME_ENTRY.SUCCESS:
      return {
        ...state,
        getTimeEntryLoading: false,
        getTimeEntryError: false,
        getTimeEntryList: payload,
      }
    case GET_TIME_ENTRY.ERROR:
      return {
        ...state,
        getTimeEntryLoading: false,
        getTimeEntryError: true,
      }

    case UPDATE_TIME_ENTRY.LOADING:
      return {
        ...state,
        updateTimeEntryLoading: true,
        updateTimeEntryError: false,
      }
    case UPDATE_TIME_ENTRY.SUCCESS:
      return {
        ...state,
        updateTimeEntryLoading: false,
        updateTimeEntryError: false,
      }
    case UPDATE_TIME_ENTRY.ERROR:
      return {
        ...state,
        updateTimeEntryLoading: false,
        updateTimeEntryError: true,
      }

    case DELETE_TIME_ENTRY.LOADING:
      return {
        ...state,
        deleteTimeEntryLoading: true,
        deleteTimeEntryError: false,
      }
    case DELETE_TIME_ENTRY.SUCCESS:
      return {
        ...state,
        deleteTimeEntryLoading: false,
        deleteTimeEntryError: false,
      }
    case DELETE_TIME_ENTRY.ERROR:
      return {
        ...state,
        deleteTimeEntryLoading: false,
        deleteTimeEntryError: true,
      }
    default:
      return state
  }
}
