import {useEffect, useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'
import {useDispatch} from 'react-redux'

import {Button, Input} from 'app/common'
import {UserList} from '../../components'
import {AddNewUserModal} from './components'
import {SettingsHeader} from '../../../../components'

import {getCompanyDisabledUser} from 'redux-src'
import {useDebounceValue} from 'hooks'
import {useQuery} from 'react-auth-navigation'

export const UsersMainPage = () => {
  const [openAddNewUserModal, setOpenAddNewUserModal] = useState(false)
  //* HANDLE NAVIGATION OPEN MODAL
  const query: any = useQuery()
  const {create} = query
  useEffect(() => {
    setOpenAddNewUserModal(!!create)
  }, [create])

  const [filterValue, setFilterValue] = useState<string>()
  const dispatch = useDispatch()

  const search = useDebounceValue(filterValue)

  useEffect(() => {
    dispatch(getCompanyDisabledUser(1, 10))
  }, [dispatch])

  return (
    <div className="flex flex-col min-h-[91vh]">
      {/* HEADER SECTION */}
      <SettingsHeader
        title="Users"
        overview
        help
        subHeader="The Users list allows you to manage your users, and provide
          access to all their details and permissions."
      />

      <div className="flex justify-between items-center  py-10">
        {/* SEARCH INPUT BOX */}
        <div className="flex flex-col gap-4">
          <Input
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
            containerClass="w-[300px]"
            placeholder="Search users..."
            // size="sm"
            className="rounded-tr-sm rounded-br-sm"
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </div>
        <Button
          onClick={() => setOpenAddNewUserModal(true)}
          title="Add New User"
          size="sm"
        />
      </div>

      <div className="my-10 border-b-1 border-gray-200" />

      {/* USER LIST SECTION */}
      <UserList search={search} />

      {/* ADD NEW USER MODAL */}
      <AddNewUserModal
        isActive={openAddNewUserModal}
        close={() => setOpenAddNewUserModal(false)}
      />
    </div>
  )
}
