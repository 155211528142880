export type SelectOptions = {
  value: number
  key: string
}[]

export type SelectOption = {
  value: number
  key: string
}

export const allSelectOptions = {
  countryOptions: [
    {value: 0, key: 'Select Country'},
    {value: 1, key: 'Afghanistan'},
    {value: 2, key: 'Albania'},
    {value: 3, key: 'Algeria'},
    {value: 4, key: 'Andorra'},
    {value: 5, key: 'Angola'},
    {value: 6, key: 'Antigua and Barbuda'},
    {value: 7, key: 'Argentina'},
    {value: 8, key: 'Armenia'},
    {value: 9, key: 'Australia'},
    {value: 10, key: 'Austria'},
    {value: 11, key: 'Azerbaijan'},
    {value: 12, key: 'The Bahamas'},
    {value: 13, key: 'Bahrain'},
    {value: 14, key: 'Bangladesh'},
    {value: 15, key: 'Barbados'},
    {value: 16, key: 'Belarus'},
    {value: 17, key: 'Belgium'},
    {value: 18, key: 'Belize'},
    {value: 19, key: 'Benin'},
    {value: 20, key: 'Bhutan'},
    {value: 21, key: 'Bolivia'},
    {value: 22, key: 'Bosnia and Herzegovina'},
    {value: 23, key: 'Botswana'},
    {value: 24, key: 'Brazil'},
    {value: 25, key: 'Brunei'},
    {value: 26, key: 'Bulgaria'},
    {value: 27, key: 'Burkina Faso'},
    {value: 28, key: 'Burundi'},
    {value: 29, key: 'Cambodia'},
    {value: 30, key: 'Cameroon'},
    {value: 31, key: 'Canada'},
    {value: 32, key: 'Cape Verde'},
    {value: 33, key: 'Central African Republic'},
    {value: 34, key: 'Chad'},
    {value: 35, key: 'Chile'},
    {value: 36, key: 'China'},
    {value: 37, key: 'Colombia'},
    {value: 38, key: 'Comoros'},
    {value: 39, key: 'Congo, Republic of the'},
    {value: 40, key: 'Congo, Democratic Republic of the'},
    {value: 41, key: 'Costa Rica'},
    {value: 42, key: "Cote d'Ivoire"},
    {value: 43, key: 'Croatia'},
    {value: 44, key: 'Cuba'},
    {value: 45, key: 'Cyprus'},
    {value: 46, key: 'Czech Republic'},
    {value: 47, key: 'Denmark'},
    {value: 48, key: 'Djibouti'},
    {value: 49, key: 'Dominica'},
    {value: 50, key: 'Dominican Republic'},
    {value: 51, key: 'East Timor (Timor-Leste)'},
    {value: 52, key: 'Ecuador'},
    {value: 53, key: 'Egypt'},
    {value: 54, key: 'El Salvador'},
    {value: 55, key: 'Equatorial Guinea'},
    {value: 56, key: 'Eritrea'},
    {value: 57, key: 'Estonia'},
    {value: 58, key: 'Ethiopia'},
    {value: 59, key: 'Fiji'},
    {value: 60, key: 'Finland'},
    {value: 61, key: 'France'},
    {value: 62, key: 'Gabon'},
    {value: 63, key: 'The Gambia'},
    {value: 64, key: 'Georgia'},
    {value: 65, key: 'Germany'},
    {value: 66, key: 'Ghana'},
    {value: 67, key: 'Greece'},
    {value: 68, key: 'Grenada'},
    {value: 69, key: 'Guatemala'},
    {value: 70, key: 'Guinea'},
    {value: 71, key: 'Guinea-Bissau'},
    {value: 72, key: 'Guyana'},
    {value: 73, key: 'Haiti'},
    {value: 74, key: 'Honduras'},
    {value: 75, key: 'Hungary'},
    {value: 76, key: 'Iceland'},
    {value: 77, key: 'India'},
    {value: 78, key: 'Indonesia'},
    {value: 79, key: 'Iran'},
    {value: 80, key: 'Iraq'},
    {value: 81, key: 'Ireland'},
    {value: 82, key: 'Israel'},
    {value: 83, key: 'Italy'},
    {value: 84, key: 'Jamaica'},
    {value: 85, key: 'Japan'},
    {value: 86, key: 'Jordan'},
    {value: 87, key: 'Kazakhstan'},
    {value: 88, key: 'Kenya'},
    {value: 89, key: 'Kiribati'},
    {value: 90, key: 'Korea, North'},
    {value: 91, key: 'Korea, South'},
    {value: 92, key: 'Kosovo'},
    {value: 93, key: 'Kuwait'},
    {value: 94, key: 'Kyrgyzstan'},
    {value: 95, key: 'Laos'},
    {value: 96, key: 'Latvia'},
    {value: 97, key: 'Lebanon'},
    {value: 98, key: 'Lesotho'},
    {value: 99, key: 'Liberia'},
    {value: 100, key: 'Libya'},
    {value: 101, key: 'Liechtenstein'},
    {value: 102, key: 'Lithuania'},
    {value: 103, key: 'Luxembourg'},
    {value: 104, key: 'Macedonia'},
    {value: 105, key: 'Madagascar'},
    {value: 106, key: 'Malawi'},
    {value: 107, key: 'Malaysia'},
    {value: 108, key: 'Maldives'},
    {value: 109, key: 'Mali'},
    {value: 110, key: 'Malta'},
    {value: 111, key: 'Marshall Islands'},
    {value: 112, key: 'Mauritania'},
    {value: 113, key: 'Mauritius'},
    {value: 114, key: 'Mexico'},
    {value: 115, key: 'Micronesia, Federated States of'},
    {value: 116, key: 'Moldova'},
    {value: 117, key: 'Monaco'},
    {value: 118, key: 'Mongolia'},
    {value: 119, key: 'Montenegro'},
    {value: 120, key: 'Morocco'},
    {value: 121, key: 'Mozambique'},
    {value: 122, key: 'Myanmar (Burma)'},
    {value: 123, key: 'Namibia'},
    {value: 124, key: 'Nauru'},
    {value: 125, key: 'Nepal'},
    {value: 126, key: 'Netherlands'},
    {value: 127, key: 'New Zealand'},
    {value: 128, key: 'Nicaragua'},
    {value: 129, key: 'Niger'},
    {value: 130, key: 'Nigeria'},
    {value: 131, key: 'Norway'},
    {value: 132, key: 'Oman'},
    {value: 133, key: 'Pakistan'},
    {value: 134, key: 'Palau'},
    {value: 135, key: 'Panama'},
    {value: 136, key: 'Papua New Guinea'},
    {value: 137, key: 'Paraguay'},
    {value: 138, key: 'Peru'},
    {value: 139, key: 'Philippines'},
    {value: 140, key: 'Poland'},
    {value: 141, key: 'Portugal'},
    {value: 142, key: 'Qatar'},
    {value: 143, key: 'Romania'},
    {value: 144, key: 'Russia'},
    {value: 145, key: 'Rwanda'},
    {value: 146, key: 'Saint Kitts and Nevis'},
    {value: 147, key: 'Saint Lucia'},
    {value: 148, key: 'Saint Vincent and the Grenadines'},
    {value: 149, key: 'Samoa'},
    {value: 150, key: 'San Marino'},
    {value: 151, key: 'Sao Tome and Principe'},
    {value: 152, key: 'Saudi Arabia'},
    {value: 153, key: 'Senegal'},
    {value: 154, key: 'Serbia'},
    {value: 155, key: 'Seychelles'},
    {value: 156, key: 'Sierra Leone'},
    {value: 157, key: 'Singapore'},
    {value: 158, key: 'Slovakia'},
    {value: 159, key: 'Slovenia'},
    {value: 160, key: 'Solomon Islands'},
    {value: 161, key: 'Somalia'},
    {value: 162, key: 'South Africa'},
    {value: 163, key: 'South Sudan'},
    {value: 164, key: 'Spain'},
    {value: 165, key: 'Sri Lanka'},
    {value: 166, key: 'Sudan'},
    {value: 167, key: 'Suriname'},
    {value: 168, key: 'Swaziland'},
    {value: 169, key: 'Sweden'},
    {value: 170, key: 'Switzerland'},
    {value: 171, key: 'Syria'},
    {value: 172, key: 'Taiwan'},
    {value: 173, key: 'Tajikistan'},
    {value: 174, key: 'Tanzania'},
    {value: 175, key: 'Thailand'},
    {value: 176, key: 'Togo'},
    {value: 177, key: 'Tonga'},
    {value: 178, key: 'Trinidad and Tobago'},
    {value: 179, key: 'Tunisia'},
    {value: 180, key: 'Turkey'},
    {value: 181, key: 'Turkmenistan'},
    {value: 182, key: 'Tuvalu'},
    {value: 183, key: 'Uganda'},
    {value: 184, key: 'Ukraine'},
    {value: 185, key: 'United Arab Emirates'},
    {value: 186, key: 'United Kingdom'},
    {value: 187, key: 'United States of America'},
    {value: 188, key: 'Uruguay'},
    {value: 189, key: 'Uzbekistan'},
    {value: 190, key: 'Vanuatu'},
    {value: 191, key: 'Vatican City (Holy See)'},
    {value: 192, key: 'Venezuela'},
    {value: 193, key: 'Vietnam'},
    {value: 194, key: 'Yemen'},
    {value: 195, key: 'Zambia'},
    {value: 196, key: 'Zimbabwe'}
  ],
  industryTypeOptions: [
    {value: 0, key: 'Select your industry'},
    {value: 1, key: 'Plumbing & Gas'},
    {value: 2, key: 'Electrical'},
    {value: 3, key: 'Building'},
    {value: 4, key: 'HVAC'},
    {value: 5, key: 'Data & Security'},
    {value: 6, key: 'Landscaping'},
    {value: 7, key: 'Roofing'},
    {value: 8, key: 'Cleaning'},
    {value: 9, key: 'Repair & Maintenance'},
    {value: 10, key: 'Painting'},
    {value: 11, key: 'IT Services'},
    {value: 12, key: 'Flooring'},
    {value: 13, key: 'Other Trade'},
    {value: 14, key: 'Other'}
  ],
  companyCategoryOptions: [
    {value: 0, key: 'Select number of staff'},
    {value: 1, key: '1 Employee'},
    {value: 2, key: '2-5 Employees'},
    {value: 3, key: '6-10 Employees'},
    {value: 4, key: '11-15 Employees'},
    {value: 5, key: '16-19 Employees'},
    {value: 6, key: '20-49 Employees'},
    {value: 7, key: '50+ Employees'}
  ],
  paymentOptions: [
    {value: 0, key: 'Select payment term'},
    {value: 1, key: '30 Days'},
    {value: 2, key: '21 Days'},
    {value: 3, key: '14 Days'},
    {value: 4, key: '7 Days'},
    {value: 5, key: 'Payment on Completion'},
    {value: 6, key: 'Payment on Receipt'},
    {value: 7, key: '20th of Current Month'},
    {value: 8, key: 'Last day of Current Month'},
    {value: 9, key: '20th of Next Month'},
    {value: 10, key: 'Last day of Next Month'},
    {value: 21, key: 'Use Company Default'}
  ],
  paymentTerms: [
    {value: 0, key: 'Select payment period'},
    {value: 30, key: '30 Days'},
    {value: 21, key: '21 Days'},
    {value: 14, key: '14 Days'},
    {value: 7, key: '7 Days'}
  ],
  contactTypeOptions: [
    {value: 0, key: 'Select a contact type', label: ''},
    {value: 1, key: 'Email', label: 'Email Address'},
    {value: 2, key: 'Mobile Phone', label: 'Mobile/Phone Number'},
    {value: 3, key: 'Other Phone', label: 'Other Mobile/Phone Number'},
    {value: 4, key: 'Fax', label: 'Fax Number'},
    {value: 5, key: 'Website', label: 'Website Link'}
  ],
  disableInvoiceRemindersOptions: [
    {value: 0, key: 'Select an invoice reminder option'},
    {value: 1, key: 'Use Company Default'},
    {value: 2, key: 'Disable'}
  ],
  currencyList: [
    {value: 'Select a currency...', key: 0},
    {value: 'Australian Dollar', symbol: '$', key: 1},
    {value: 'Brazilian Real', symbol: 'R$', key: 2},
    {value: 'British Pound Sterling', symbol: '£', key: 3},
    {value: 'Canadian Dollar', symbol: '$', key: 4},
    {value: 'Czech Republic Koruna', symbol: 'Kč', key: 5},
    {value: 'Danish Krone', symbol: 'Kr.', key: 6},
    {value: 'Euro', symbol: '€', key: 7},
    {value: 'Hong Kong Dollar', symbol: '$', key: 8},
    {value: 'Hungarian Forint', symbol: 'Ft', key: 9},
    {value: 'Indian Rupee', symbol: '₹', key: 10},
    {value: 'Israeli New Sheqel', symbol: '₪', key: 11},
    {value: 'Japanese Yen', symbol: '¥', key: 12},
    {value: 'Malaysian Ringgit', symbol: 'RM', key: 13},
    {value: 'Mexican Peso', symbol: '$', key: 14},
    {value: 'Namibian Dollar', symbol: '$', key: 15},
    {value: 'New Taiwan Dollar', symbol: '$', key: 16},
    {value: 'New Zealand Dollar', symbol: '$', key: 17},
    {value: 'Norwegian Krone', symbol: 'kr', key: 18},
    {value: 'Papua New Guinean Kina', symbol: 'K', key: 19},
    {value: 'Philippine Peso', symbol: '₱', key: 20},
    {value: 'Polish Zloty', symbol: 'zł', key: 21},
    {value: 'Singapore Dollar', symbol: '$', key: 22},
    {value: 'South African Rand', symbol: 'R', key: 23},
    {value: 'Swedish Krona', symbol: 'kr', key: 24},
    {value: 'Swiss Franc', symbol: 'CHf', key: 25},
    {value: 'Thai Baht', symbol: '฿', key: 26},
    {value: 'Turkish Lira', symbol: '₺', key: 27},
    {value: 'United Arab Emirates Dirham', symbol: 'إ.د', key: 28},
    {value: 'US Dollar', symbol: '$', key: 29},
    {value: 'Vietnamese Dong', symbol: '₫', key: 30}
  ],
  dateFormatOptions: [
    {
      value: 'dd/mm/yy',
      key: 1
    },
    {
      value: 'mm/dd/yy',
      key: 2
    }
  ],
  timeZoneOptions: [
    {value: '(GMT-12:00) International Date Line West', key: '-12'},
    {value: '(GMT-11:00) Midway Island, Samoa', key: '-11'},
    {value: '(GMT-10:00) Hawaii', key: '-10'},
    {value: '(GMT-09:00) Alaska', key: '-9'},
    {value: '(GMT-08:00) Pacific Time (US & Canada)', key: '-8'},
    {value: '(GMT-08:00) Tijuana, Baja California', key: '-8'},
    {value: '(GMT-07:00) Arizona', key: '-7'},
    {value: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', key: '-7'},
    {value: '(GMT-07:00) Mountain Time (US & Canada)', key: '-7'},
    {value: '(GMT-06:00) Central America', key: '-6'},
    {value: '(GMT-06:00) Central Time (US & Canada)', key: '-6'},
    {value: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', key: '-5'},
    {value: '(GMT-05:00) Eastern Time (US & Canada)', key: '-5'},
    {value: '(GMT-05:00) Indiana (East)', key: '-5'},
    {value: '(GMT-04:00) Atlantic Time (Canada)', key: '-4'},
    {value: '(GMT-04:00) Caracas, La Paz', key: '-4'},
    {value: '(GMT-04:00) Manaus', key: '-4'},
    {value: '(GMT-04:00) Santiago', key: '-4'},
    {value: '(GMT-03:30) Newfoundland', key: '-3.5'},
    {value: '(GMT-03:00) Brasilia', key: '-3'},
    {value: '(GMT-03:00) Buenos Aires, Georgetown', key: '-3'},
    {value: '(GMT-03:00) Greenland', key: '-3'},
    {value: '(GMT-03:00) Montevideo', key: '-3'},
    {value: '(GMT-02:00) Mid-Atlantic', key: '-2'},
    {value: '(GMT-01:00) Cape Verde Is.', key: '-1'},
    {value: '(GMT-01:00) Azores', key: '-1'},
    {value: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', key: '0'},
    {
      value:
        '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
      key: '0'
    },
    {
      value: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      key: '1'
    },
    {
      value: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      key: '1'
    },
    {value: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', key: '1'},
    {value: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', key: '1'},
    {value: '(GMT+01:00) West Central Africa', key: '1'},
    {value: '(GMT+02:00) Amman', key: '2'},
    {value: '(GMT+02:00) Athens, Bucharest, Istanbul', key: '2'},
    {value: '(GMT+02:00) Beirut', key: '2'},
    {value: '(GMT+02:00) Cairo', key: '2'},
    {value: '(GMT+02:00) Harare, Pretoria', key: '2'},
    {
      value: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      key: '2'
    },
    {value: '(GMT+02:00) Jerusalem', key: '2'},
    {value: '(GMT+02:00) Minsk', key: '2'},
    {value: '(GMT+02:00) Windhoek', key: '2'},
    {value: '(GMT+03:00) Kuwait, Riyadh, Baghdad', key: '3'},
    {value: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', key: '3'},
    {value: '(GMT+03:00) Nairobi', key: '3'},
    {value: '(GMT+03:00) Tbilisi', key: '3'},
    {value: '(GMT+03:30) Tehran', key: '3.5'},
    {value: '(GMT+04:00) Abu Dhabi, Muscat', key: '4'},
    {value: '(GMT+04:00) Baku', key: '4'},
    {value: '(GMT+04:00) Yerevan', key: '4'},
    {value: '(GMT+04:30) Kabul', key: '4.5'},
    {value: '(GMT+05:00) Yekaterinburg', key: '5'},
    {value: '(GMT+05:00) Islamabad, Karachi, Tashkent', key: '5'},
    {value: '(GMT+05:30) Sri Jayawardenapura', key: '5.5'},
    {value: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', key: '5.5'},
    {value: '(GMT+05:45) Kathmandu', key: '5.75'},
    {value: '(GMT+06:00) Almaty, Novosibirsk', key: '6'},
    {value: '(GMT+06:00) Astana, Dhaka', key: '6'},
    {value: '(GMT+06:30) Yangon (Rangoon)', key: '6.5'},
    {value: '(GMT+07:00) Bangkok, Hanoi, Jakarta', key: '7'},
    {value: '(GMT+07:00) Krasnoyarsk', key: '7'},
    {value: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', key: '8'},
    {value: '(GMT+08:00) Kuala Lumpur, Singapore', key: '8'},
    {value: '(GMT+08:00) Irkutsk, Ulaan Bataar', key: '8'},
    {value: '(GMT+08:00) Perth', key: '8'},
    {value: '(GMT+08:00) Taipei', key: '8'},
    {value: '(GMT+09:00) Osaka, Sapporo, Tokyo', key: '9'},
    {value: '(GMT+09:00) Seoul', key: '9'},
    {value: '(GMT+09:00) Yakutsk', key: '9'},
    {value: '(GMT+09:30) Adelaide', key: '9.5'},
    {value: '(GMT+09:30) Darwin', key: '9.5'},
    {value: '(GMT+10:00) Brisbane', key: '10'},
    {value: '(GMT+10:00) Canberra, Melbourne, Sydney', key: '10'},
    {value: '(GMT+10:00) Hobart', key: '10'},
    {value: '(GMT+10:00) Guam, Port Moresby', key: '10'},
    {value: '(GMT+10:00) Vladivostok', key: '10'},
    {value: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', key: '11'},
    {value: '(GMT+12:00) Auckland, Wellington', key: '12'},
    {value: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', key: '12'},
    {value: "(GMT+13:00) Nuku'alofa", key: '13'}
  ],
  expireInDays: [
    {value: 60, key: '60 days'},
    {value: 30, key: '30 days'},
    {value: 14, key: '14 days'},
    {value: 7, key: '7 days'}
  ],
  fontSize: [
    {value: 'x-small', key: 'X-Small'},
    {value: 'small', key: 'Small'},
    {value: 'medium', key: 'Medium'},
    {value: 'large', key: 'Large'},
    {value: 'x-large', key: 'X-Large'}
  ]
}

export const getSelectOptions = async () => {
  return allSelectOptions
}
