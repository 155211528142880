import {
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
  createContext,
} from 'react'
import L, {LatLngTuple, PointExpression} from 'leaflet'
import {MapContainer, Marker, useMap} from 'react-leaflet'
import {useSelector} from 'react-redux'

import {Loading} from 'app/common'
import {MapResetOverlay, MessageBox} from 'app/components'
import {getTileUrl} from 'helpers'

interface FloorMapProps {
  mapContainerHeight?: number | string
  plan?: Api.ProjectPlanIndividual
  disableZoom?: boolean
  ticketStatus?: 'open' | 'in progress' | 'feedback' | 'completed'
}

interface MapOptionsType {
  maxZoom: number
  mapDim: {width: number; height: number}
  center: LatLngTuple
  bounds: LatLngTuple[]
  offset: {x: number; y: number}
  marker: {x: number; y: number}
}

const MAP_CONTAINER_HEIGHT = 600

const offset = {x: 5, y: 5}
// const MAP_DIM = {width: 3370, height: 2384}
const MAP_DIM: {width: number; height: number} = {
  width: 595,
  height: 842,
}
// const MAP_DIM = {width: 2592, height: 1728}

const CENTER: LatLngTuple = [MAP_DIM.height / 2, MAP_DIM.width / 2] // Here latLng is (y,x);
const BOUNDS: LatLngTuple[] = [
  [0, 0],
  [MAP_DIM.width, MAP_DIM.height],
]

const FloorMapContext = createContext<FloorMapProps & MapOptionsType>({
  plan: undefined,
  mapContainerHeight: 600,
  ticketStatus: 'open',
  maxZoom: 4,
  mapDim: MAP_DIM,
  center: CENTER,
  bounds: BOUNDS,
  offset,
  marker: {x: null, y: null},
})

export const FloorMap = ({
  plan,
  disableZoom,
  mapContainerHeight,
  ticketStatus = 'open',
}: FloorMapProps) => {
  const {markerCoordinates}: RT.TicktesReduxType = useSelector(
    (state: any) => state.tickets,
  )

  const zoomAttributes = useMemo(() => {
    return disableZoom
      ? {
          zoomControl: false,
          zoomSnap: 0,
          zoomDelta: 0,
          scrollWheelZoom: false,
          touchZoom: false,
          trackResize: false,
          dragging: false,
        }
      : {
          zoomControl: true,
          dragging: true,
        }
  }, [disableZoom])

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingForRerender, setLoadingForRerender] = useState<boolean>(true)

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setLoading(false)
      },
      disableZoom ? 1000 : 3000,
    )

    const rerenderTimeout = setTimeout(() => {
      setLoadingForRerender(false)
    }, 100)

    return () => {
      clearTimeout(timeout)
      clearTimeout(rerenderTimeout)
      setLoading(true)
      setLoadingForRerender(true)
    }
  }, [disableZoom, plan])

  const iconSize: [number, number] = useMemo(() => [30, 40], [])
  let statusIcons: Record<
    'open' | 'in progress' | 'feedback' | 'completed',
    L.Icon
  > = {
    open: L.icon({
      iconUrl: '/map_pointer_open.svg',
      iconSize,
    }),
    'in progress': L.icon({
      iconUrl: '/map_pointer_progress.svg',
      iconSize,
    }),
    feedback: L.icon({
      iconUrl: '/map_pointer_feedback.svg',
      iconSize,
    }),
    completed: L.icon({
      iconUrl: '/map_pointer_completed.svg',
      iconSize,
    }),
  }

  // useEffect(() => {
  //   !!markerCoordinates && setMarker(markerCoordinates)
  // }, [markerCoordinates])

  const mapOptions: MapOptionsType = useMemo(() => {
    const mapDim = {
      width: plan?.project_plan_details?.width
        ? plan?.project_plan_details?.rotation === 90 &&
          plan?.project_plan_details?.width > plan?.project_plan_details?.height
          ? plan?.project_plan_details?.width
          : plan?.project_plan_details?.height
        : MAP_DIM.width,
      height: plan?.project_plan_details?.height
        ? plan?.project_plan_details?.rotation === 90 &&
          plan?.project_plan_details?.width > plan?.project_plan_details?.height
          ? plan?.project_plan_details?.height
          : plan?.project_plan_details?.width
        : MAP_DIM.width,
    }

    return {
      maxZoom: plan?.project_plan_details?.max_zoom_level ?? 4,
      mapDim,
      center:
        // !!markerCoordinates?.x && !!markerCoordinates?.y
        //   ? [markerCoordinates?.x, markerCoordinates?.y]
        //   :
        [mapDim.height / 2, mapDim.width / 2],
      bounds: [
        [0, 0],
        [mapDim.height, mapDim.width],
      ],
      offset,
      marker: markerCoordinates,
    }
  }, [
    markerCoordinates,
    plan?.project_plan_details?.height,
    plan?.project_plan_details?.max_zoom_level,
    plan?.project_plan_details?.rotation,
    plan?.project_plan_details?.width,
  ])

  return (
    <FloorMapContext.Provider
      value={{
        mapContainerHeight,
        plan,
        ticketStatus,
        disableZoom,
        ...mapOptions,
      }}
    >
      <>
        <div
          style={{
            height: mapContainerHeight ?? MAP_CONTAINER_HEIGHT,
            overflow: 'hidden',
            zIndex: '230000000 !important',
            isolation: 'isolate',
            width: '100%',
            borderRadius: 8,
          }}
          className="relative"
        >
          {loadingForRerender ? (
            <div
              className="absolute inset-0 w-full bg-white z-[230000002]"
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {mapContainerHeight && Number(mapContainerHeight) < 600 ? (
                <div
                  className={`flex justify-center items-center`}
                  style={{
                    height: mapContainerHeight,
                  }}
                >
                  <Loading small />
                </div>
              ) : (
                <Loading />
              )}
            </div>
          ) : plan?.project_plan_details.from_pdf === null || !plan ? (
            !plan || plan?.project_plan_details.has_error ? (
              <MessageBox
                error
                message={
                  <div className="flex gap-10">
                    Error! Can't process the project plan, please re-upload
                    file.
                  </div>
                }
              />
            ) : (
              <MessageBox
                message={
                  <div className="flex gap-10">
                    <Loading small /> Project plan is processing... please
                    comeback after a while.
                  </div>
                }
              />
            )
          ) : plan?.project_plan_details.from_pdf === true ? (
            <MapContainer
              // center={mapOptions.center}
              zoom={1}
              minZoom={1}
              maxZoom={mapOptions.maxZoom}
              bounds={mapOptions.bounds}
              boundsOptions={{
                maxZoom: mapOptions.maxZoom,
                padding: [0, 0],
              }}
              crs={L.CRS.Simple}
              attributionControl={false}
              closePopupOnClick
              style={{
                width: '100%',
                height: '100%',
                zIndex: '230000001 !important',
              }}
              {...zoomAttributes}
            >
              <TileMap
                projectId={plan?.project_plan_details.project_id}
                projectPlanId={plan?.project_plan_details.id}
              />
              {disableZoom &&
                !!mapOptions.marker &&
                mapOptions.marker?.x &&
                mapOptions.marker?.y && (
                  <Marker
                    key={'mapOptions-markerPosition-tile'}
                    position={[mapOptions.marker?.x, mapOptions.marker?.y]}
                    icon={statusIcons[ticketStatus]}
                    riseOffset={20}
                    riseOnHover
                  />
                )}
            </MapContainer>
          ) : (
            <MessageBox
              message={
                <div className="flex gap-10">
                  <Loading small /> Project plan is processing... please
                  comeback after a while.
                </div>
              }
            />
          )}
          {loading && (
            <div
              className="absolute inset-0 w-full bg-white z-[230000002]"
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {mapContainerHeight && Number(mapContainerHeight) < 600 ? (
                <div
                  className={`flex justify-center items-center`}
                  style={{
                    height: mapContainerHeight,
                  }}
                >
                  <Loading small />
                </div>
              ) : (
                <Loading />
              )}
            </div>
          )}
        </div>
      </>
    </FloorMapContext.Provider>
  )
}

// MARK: - TileMap
const TileMap = ({
  projectId,
  projectPlanId,
}: {
  projectId: number
  projectPlanId: number
}) => {
  const {
    bounds,
    marker: markerProp,
    mapContainerHeight,
    center,
    disableZoom,
  } = useContext(FloorMapContext)

  const map = useMap()
  const layerRef = useRef<L.LayerGroup>(null)

  const maxZoom = map.getMaxZoom()

  const unprojectedCenter = useMemo(() => {
    const unprojectedCenter = map.unproject(center as PointExpression, maxZoom)
    return {x: unprojectedCenter.lat * 4, y: unprojectedCenter.lng * 4}
  }, [center, map, maxZoom])

  const marker: {x: number; y: number} = useMemo(() => {
    if (markerProp) {
      return markerProp
    }
    return unprojectedCenter
  }, [markerProp, unprojectedCenter])

  useEffect(() => {
    layerRef?.current?.clearLayers()
    layerRef.current = L.layerGroup().addTo(map)
  }, [map])

  useEffect(() => {
    if (map) {
      L.tileLayer(getTileUrl(projectId, projectPlanId), {
        minZoom: 1,
        maxZoom,
      })?.addTo(map)

      map.fitBounds(bounds)
    }
  }, [bounds, map, maxZoom, projectId, projectPlanId])

  useEffect(() => {
    if (disableZoom) {
      map?.panTo([marker?.x ?? -80, marker?.y ?? 80], {
        noMoveStart: true,
      })
      map?.setZoom(4)
    } else map?.flyTo([marker?.x ?? -80, marker?.y ?? 80], 2)
  }, [disableZoom, map, marker])

  return (
    <>
      <div
        id="map"
        style={{
          height: mapContainerHeight ?? MAP_CONTAINER_HEIGHT,
          zIndex: 1,
        }}
      />
      {!disableZoom && (
        <MapResetOverlay
          resetHandler={() => {
            map?.panTo([marker?.x ?? -80, marker?.y ?? 80], {
              noMoveStart: true,
            })
            map?.setZoom(1)
          }}
        />
      )}
    </>
  )
}
