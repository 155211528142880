import {useAuth, useNavigation} from 'react-auth-navigation'
import React, {useEffect, useMemo, useState} from 'react'
import {IoMdClose} from 'react-icons/io'
import clsx from 'clsx'
import {useDispatch, useSelector} from 'react-redux'
import {FaTrash} from 'react-icons/fa'
import {camelCase} from 'change-case'

import {Textarea, ToolTip} from 'app/common'

import {
  createNewInvoiceLineItemAction,
  // deleteInvoiceItemAction,
  // deleteInvoiceLineItemAction,
  // updateInvoiceItemAction,
  // updateInvoiceLineItemAction,
  updateBasicDetailsWorkerInvoiceItem,
  deleteWorkerInvoiceItem,
  updateBasicDetailsOfWorkerInvoiceLineItem,
  removeWorkerInvoiceLineItem,
  getProjectPlanList
} from 'redux-src'

import {QUOTE_TABLE_HEADINGS} from '../../constants'
import {LineItemInput} from 'app/components/invoiceEditor/components/sectionCard/components'
import {AddLineItem, RemainingTicketModal} from './components'
import {BiImport} from 'react-icons/bi'
import {CreateTicketModel} from 'app/pages/jobs/pages/ticket'
import {LOCAL_SERVER_URL} from 'config'

export const SectionCard = ({
  sectionDetail,
  onRemoveSection,
  sectionList,
  sectionIndex,
  fetchInvoiceItem,
  invoiceId
}: {
  sectionDetail?: Api.WorkerInvoiceDetailsById['worker_invoice_item_list'][0]
  onRemoveSection: (arg: any) => void
  sectionList: any
  setSectionList?: (a: any) => void
  sectionIndex: number
  fetchInvoiceItem?: () => void
  invoiceId?: number
}) => {
  const {projectList}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project
  )

  const [sectionTitle, setSectionTitle] = useState<string>()
  const [description, setDescription] = useState<string>()

  const [projectId, setProjectId] = useState<number>()
  const [openTicketModal, setOpenTicketModal] = useState<boolean>(false)
  const [isTicketModalVisible, setTicketModalVisible] = useState<boolean>(false)
  const [ticketId, setTicketId] = useState<number>()

  const dispatch = useDispatch()
  const {currencySymbol} = useAuth()

  const {params} = useNavigation()

  const {invoiceId: invoiceIdParams} = params as any

  const onAddLineItemHandler = (body: any, callback?: () => void) => {
    callback && callback()
    dispatch(
      createNewInvoiceLineItemAction(
        31,
        invoiceIdParams,
        sectionDetail?.worker_inv_item_details?.id,
        body?.id,
        () => {
          fetchInvoiceItem()
        }
      )
    )
  }

  const projectTitle = useMemo(() => {
    const projectTitle = projectList?.find(
      (item) =>
        item?.project_details?.id ===
        sectionDetail?.worker_inv_item_details?.project_id
    )?.project_details?.title

    return projectTitle
  }, [projectList, sectionDetail?.worker_inv_item_details?.project_id])

  type LineItemKeys = 'title' | 'ticketTitle'

  const onUpdateLineItemHandler = (
    key: LineItemKeys,
    value: any,
    item: any
  ) => {
    if (+value === +item[key]) return

    const body = {
      title: item.title,
      ticketTitle: item?.ticketTitle
    }

    body[key] = value

    dispatch(
      updateBasicDetailsOfWorkerInvoiceLineItem(
        invoiceIdParams,
        sectionDetail?.worker_inv_item_details?.id,
        item.id,
        body,
        () => {
          fetchInvoiceItem()
        }
      )
    )
  }

  const onRemoveLineItemHandler = (lineItemId: number) => {
    dispatch(
      removeWorkerInvoiceLineItem(
        invoiceIdParams,
        sectionDetail?.worker_inv_item_details?.id,
        lineItemId,
        () => {
          fetchInvoiceItem()
        }
      )
    )
  }

  const onRemoveItemHandler = () => {
    invoiceIdParams &&
      sectionDetail?.worker_inv_item_details?.id &&
      dispatch(
        deleteWorkerInvoiceItem(
          invoiceIdParams,
          sectionDetail?.worker_inv_item_details?.id,
          () => {
            fetchInvoiceItem()
          }
        )
      )
  }

  const onUpdateItemBasicDetails = (e: any) => {
    e.preventDefault()
    if (
      sectionDetail?.worker_inv_item_details?.title !== sectionTitle ||
      description !== sectionDetail?.worker_inv_item_details?.description
    ) {
      const body = {
        title: sectionTitle,
        description:
          description?.length === 0 || !!!description ? undefined : description
      }
      dispatch(
        updateBasicDetailsWorkerInvoiceItem(
          invoiceIdParams,
          sectionDetail?.worker_inv_item_details?.id,
          body,
          () => {
            fetchInvoiceItem()
          }
        )
      )
    }
  }

  useEffect(() => {
    if (!!sectionDetail) {
      setSectionTitle(sectionDetail?.worker_inv_item_details?.title)
      setDescription(sectionDetail?.worker_inv_item_details?.description)
    }
  }, [sectionDetail])

  return (
    <div className="my-6">
      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex flex-1 gap-10">
            <input
              className="w-1/2 rounded-sm text-xl border-1 border-gray-200 shadow-sm font-bold mb-12 px-6"
              value={sectionTitle}
              onChange={(e: any) => setSectionTitle(e.target.value)}
              onBlur={onUpdateItemBasicDetails}
            />

            <ToolTip text="Delete Item">
              <div
                className="flex items-center justify-center border-1 border-gray-200 rounded-sm px-6 py-6 cursor-pointer"
                onClick={() => {
                  onRemoveItemHandler()
                }}
              >
                <FaTrash
                  size={18}
                  color="gray"
                  className="hover:text-red-300 hover:transition-all hover:duration-150 hover:ease-in-out"
                />
              </div>
            </ToolTip>
          </div>
          {!!sectionDetail?.worker_inv_item_details?.project_id && (
            <ToolTip
              text="Import Labour Entries"
              className="flex w-[max-content] justify-end items-center"
            >
              <div
                className="px-8 py-6 flex flex-1 justify-end rounded-md w-[max-content] bg-blue-200 cursor-pointer hover:bg-blue-300 hover:transition-all hover:ease-in-out hover:duration-150"
                onClick={() => {
                  setOpenTicketModal((prev) => !prev)
                  setProjectId(
                    sectionDetail?.worker_inv_item_details?.project_id
                  )
                }}
              >
                <BiImport size={22} color={'white'} />
              </div>
            </ToolTip>
          )}
        </div>
        <div className="flex items-center justify-between">
          {!!sectionDetail?.worker_inv_item_details?.project_id && (
            <div className="flex flex-row gap-6 items-center">
              <div className="text-black font-bold">
                Imported From Project :
              </div>
              <ToolTip
                text="Navigate to project"
                top
                className="w-[max-content]"
              >
                <a
                  href={`${LOCAL_SERVER_URL}/#/projects/${sectionDetail?.worker_inv_item_details?.project_id}/projects-summary`}
                  target="_blank"
                  className="text-md text-blue-200 font-bold w-[max-content] cursor-pointer"
                  rel="noreferrer"
                >
                  {projectTitle}
                </a>
              </ToolTip>
            </div>
          )}
        </div>
      </div>

      <Textarea
        className="text-sm mt-16 mb-16"
        placeholder="Click here to enter description"
        size="sm"
        value={description}
        onChange={(e) => {
          setDescription(e.target.value)
        }}
        onBlur={onUpdateItemBasicDetails}
      />

      {!!!sectionDetail?.worker_inv_item_details?.project_id ? (
        <AddLineItem
          onAddLineItem={onAddLineItemHandler}
          fetchInvoiceItem={fetchInvoiceItem}
          sectionDetail={sectionDetail}
        />
      ) : (
        <div className="text-sm font-bold px-8">Line Item List</div>
      )}

      <div className="mt-8">
        {sectionDetail?.worker_inv_line_item_list?.length > 0 && (
          <>
            {sectionDetail?.worker_inv_line_item_list?.map(
              (item, i: number) => {
                if (item === null)
                  return <React.Fragment key={i}> </React.Fragment>
                return (
                  <div className="pt-4" key={item.id}>
                    <div className="w-full flex">
                      <div className="flex justify-center items-center mr-6">
                        <input type="checkbox" disabled />
                      </div>
                      {[
                        'title',
                        'ticket',
                        'pay_rate',
                        'time_mins',
                        'total_cost'
                      ].map((key, index) => (
                        <LineItemInput
                          key={index}
                          width={
                            QUOTE_TABLE_HEADINGS?.find(
                              (heading) => heading?.key === key
                            )?.width
                          }
                          type={
                            key === 'title' || key === 'ticket'
                              ? 'string'
                              : 'number'
                          }
                          inputType={
                            key === 'title' || key === 'ticket'
                              ? 'text'
                              : 'decimal'
                          }
                          value={
                            key === 'ticket'
                              ? sectionDetail?.worker_inv_item_details
                                  ?.project_id && item?.ticket_id
                                ? 'View Ticket'
                                : '-'
                              : String(
                                  item?.[
                                    key as keyof Api.WorkerInvoiceLineItemDetail
                                  ]
                                )
                          }
                          disabled={key !== 'ticket' && key !== 'title'}
                          className={clsx(
                            index === 0
                              ? 'text-left'
                              : index === 4
                              ? 'text-right pr-10'
                              : 'text-center last:text-right',
                            QUOTE_TABLE_HEADINGS?.find(
                              (heading) => heading?.key === key
                            )?.hidden && 'text-gray-300',
                            key === 'ticket' && 'cursor-pointer text-blue-200'
                          )}
                          onUpdate={(text: any) =>
                            onUpdateLineItemHandler(
                              camelCase(key) as LineItemKeys,
                              text,
                              item
                            )
                          }
                          openTicketModal={() => {
                            if (
                              key === 'ticket' &&
                              sectionDetail?.worker_inv_item_details
                                ?.project_id &&
                              item?.ticket_id
                            ) {
                              setTicketId(item?.ticket_id)
                              dispatch(
                                getProjectPlanList(
                                  sectionDetail?.worker_inv_item_details
                                    ?.project_id,
                                  undefined,
                                  () => {
                                    setTicketModalVisible(true)
                                  }
                                )
                              )
                            }
                          }}
                        />
                      ))}

                      <div
                        className="mx-4 cursor-pointer"
                        onClick={() => onRemoveLineItemHandler(item.id)}
                      >
                        <IoMdClose />
                      </div>
                    </div>
                  </div>
                )
              }
            )}
            <div className="flex flex-row justify-between mt-10">
              <div></div>
              <div
                style={{width: '11%'}}
                className="flex flex-row justify-center"
              >
                <div className="font-bold text-sm ">
                  {currencySymbol}{' '}
                  {Number(
                    sectionDetail?.worker_inv_line_item_list?.reduce(
                      (acc: any, o) => acc + Number(o?.total_cost),
                      0
                    )
                  ).toFixed(2) ?? '0.0'}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {!!sectionDetail?.worker_inv_item_details?.project_id && (
        <RemainingTicketModal
          projectId={sectionDetail?.worker_inv_item_details?.project_id}
          setProjectId={setProjectId}
          openModal={openTicketModal}
          setOpenModal={setOpenTicketModal}
          sectionDetail={sectionDetail}
        />
      )}

      {isTicketModalVisible && (
        <CreateTicketModel
          key={'Ticket Model From TimeSheet '}
          modalId="Ticket Model From TimeSheet"
          isModelVisible={isTicketModalVisible}
          setIsModelVisible={setTicketModalVisible}
          ticketIdFromSitevisit={ticketId}
          projectIdFromInvoice={
            sectionDetail?.worker_inv_item_details?.project_id ?? projectId
          }
          edit
        />
      )}
    </div>
  )
}
