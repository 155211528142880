import moment from 'moment'
import {TableColumn} from 'react-data-table-component'
import {MdDeleteOutline} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {FaTrash} from 'react-icons/fa'

import {DataTable} from 'app/components'
import {ConfirmationModal, ToolTip} from 'app/common'
import {getProjectUserGroupList} from 'redux-src'

export const GroupTable = ({
  groups,
  onEditGroup,
  onRemoveGroupFromProject,
  debounceFilterValue
}: any) => {
  const {projectUserGroupListTotal}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project
  )

  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  const getProjectGroupList = (page?: number, limit?: number) => {
    dispatch(
      getProjectUserGroupList(
        projectId,
        page,
        limit,
        debounceFilterValue,
        () => {}
      )
    )
  }

  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div className="flex items-center" onClick={() => onEditGroup(row.id)}>
          <div
            className="p-14 text-white rounded-full mr-10  relative"
            style={{backgroundColor: row.colorCode}}
          >
            <span className="absolute inset-0 flex items-center justify-center">
              {row.name?.charAt(0)?.toUpperCase()}
            </span>
          </div>
          <div
            // onClick={() => handelGroupEdit(row)}
            className="text-blue-400 cursor-pointer"
          >
            {row.name}
          </div>
        </div>
      )
    },
    {
      name: 'Date Created',
      selector: (row) => row.dateCreated,
      sortable: true,
      cell: (row: any) => (
        <div className="relative">
          <div
            // to={`/dashboard/customers/customer-view/projects/${row.id}`}
            className={`flex justify-between items-center text-black'
            }`}
            // style={{ minWidth: '210px', maxWidth: '240px' }}
          >
            <div className="">{moment(row.dateCreated).format('ddd, LL')}</div>
          </div>
        </div>
      )
    },
    {
      name: 'Users',
      selector: (row) => row.usersCount,
      sortable: true,
      cell: (row) => (
        <div className="flex items-center">
          <div className="p-14 bg-blue-200 text-white rounded-full mr-10  relative">
            <span className="absolute inset-0 flex items-center justify-center">
              {row.usersCount}
            </span>
          </div>
        </div>
      )
    },

    {
      name: 'Actions',
      selector: (row) => row.userCount || 'Never',
      sortable: true,
      right: true,
      cell: (row) => {
        return (
          <div className="flex items-center justify-end">
            <ConfirmationModal
              onConfirmClick={(closeModalHandler) => {
                onRemoveGroupFromProject(row.id)

                closeModalHandler()
              }}
              danger
              label={
                'Are you sure you want to remove this Group from the project?'
              }
              displayElement={
                <ToolTip text="Remove" left>
                  <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                    <span className="text-left inline-flex cursor-pointer">
                      <FaTrash fill="red" size={12} />
                    </span>
                  </button>
                </ToolTip>
              }
              confirmLabel="Remove"
            />
          </div>
        )
      }
    }
  ]

  return (
    <div className="mt-20">
      <DataTable
        columns={columns}
        data={groups}
        defaultSortFieldId={2}
        pagination
        persistTableHead
        dense
        totalRows={projectUserGroupListTotal}
        fetchData={getProjectGroupList}
        // paginationComponentOptions={{noRowsPerPage: true}}
      />
    </div>
  )
}

export const GroupSelectedAppUserTable = ({
  selectedAppUsers,
  onDelete
}: any) => {
  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div
          className="flex flex-nowrap justify-between items-center relative "
          style={{minWidth: '100%', maxWidth: '100%'}}
        >
          <div className="flex items-center ">
            <div
              className="p-14 text-white rounded-full mr-10  relative"
              style={{backgroundColor: 'gray'}}
            >
              <span className="absolute inset-0 flex items-center justify-center">
                {row.name?.charAt(0)?.toUpperCase()}
              </span>
            </div>
            <div className="text-blue-400 cursor-pointer">
              {row.name} {row.lastname}
            </div>
          </div>
          <div
            className="cursor-pointer transition-all hover:text-red-400"
            onClick={() => onDelete(row.id)}
          >
            <MdDeleteOutline size={16} />
          </div>
        </div>
      )
    }
  ]

  return (
    <>
      <DataTable
        columns={columns}
        data={selectedAppUsers}
        defaultSortFieldId={1}
        persistTableHead
        dense
        // paginationComponentOptions={{noRowsPerPage: true}}
      />
    </>
  )
}
