import {useCallback, useEffect, useState} from 'react'
import {AnimatedBlock, interpolate, TransitionBlock} from 'react-ui-animate'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {VscFilePdf} from 'react-icons/vsc'
import {AiOutlineDownload, AiOutlineEye} from 'react-icons/ai'
import {saveAs} from 'file-saver'

import {
  ClearableChip,
  DynamicSearchSelect,
  ImagePreviewModal,
  MessageBox,
} from 'app/components'
// import {getProjectFilesAndPhotos} from 'redux-src'
import {getAssetName, getAssetUrl} from 'helpers'

import {JobTitle} from '../../components'
import {
  // ImageDataTypes,
  // ImageUpload,
  // Input,
  // Pagination,
  SelectField,
} from '../../../../common'
import {useFormInput} from 'use-form-input'
import {getProjectFilesAndPhotos} from 'redux-src'
import {Pagination} from 'app/common/pagination'

const LIMIT = 10

export const FilesAndPhotosPage = () => {
  const {
    projectDetail,
    filesAndPhotos: filesFromAPI,
    filesAndPhotosTotal,
  }: RT.ProjectReduxType = useSelector((state: any) => state.project)

  const [data, {setValue}] = useFormInput({
    filterType: undefined,
    selectedItem: undefined,
  })

  const {params} = useNavigation()

  const [files, setFiles] = useState([])
  const [page, setPage] = useState<number>()

  const {projectId} = params as any

  const dispatch = useDispatch()

  const fetchProjectFilesAndPhotos = useCallback(() => {
    const params = {
      page: page,
      limit: LIMIT,
      fileType:
        data?.filterType?.value?.toLowerCase() === 'all'
          ? undefined
          : data?.filterType?.value?.toLowerCase(),
      siteVisitId: data?.selectedItem?.id,
    }

    dispatch(getProjectFilesAndPhotos(projectId, params))
  }, [page, dispatch, projectId, data])

  useEffect(() => {
    fetchProjectFilesAndPhotos()
  }, [fetchProjectFilesAndPhotos])

  useEffect(() => {
    setFiles(filesFromAPI?.map(({asset_details: ad}) => ad))

    return () => {
      setFiles([])
    }
  }, [filesFromAPI])

  const FileDisplayComponent = ({
    item,
    index,
  }: {
    item: Api.AssetDetailsType
    index: number
  }) => {
    const [isInfo, setInfo] = useState(false)
    const [isModalVisible, setModalVisible] = useState<boolean>(false)

    return (
      <div
        key={index}
        className="flex w-[160px] h-[160px] m-20 border-1 border-gray-200 rounded-[6px] relative overflow-hidden object-cover justify-center items-center"
        onMouseEnter={() => setInfo(true)}
        onMouseLeave={() => setInfo(false)}
      >
        {files.length > 0 && item.file_type === 'image' ? (
          <>
            <img
              src={getAssetUrl(item)}
              className="rounded-sm object-cover"
              alt={item.name}
            />
          </>
        ) : item?.file_type === 'video' ? (
          <div className="flex items-center justify-center w-[240px] h-[200px] flex-col gap-6 object-cover text-gray-400 bg-gray-100 rounded-sm">
            <video
              src={getAssetUrl(item)}
              controls
              style={{width: '100%', height: '200px', objectFit: 'contain'}}
            />
          </div>
        ) : (
          <div>
            <VscFilePdf size={50} />
          </div>
        )}
        <TransitionBlock state={isInfo} key={index}>
          {(animation) => (
            <>
              <AnimatedBlock
                className="transition-colors duration-100 ease-in-out p-4 bg-white bg-opacity-[0.89] hover:bg-opacity-[0.98] text-black font-bold rounded-sm  w-full"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: 30,
                  translateY: interpolate(animation.value, [0, 1], [-20, 0]),
                  opacity: animation.value,
                }}
              >
                <span className="inline-flex gap-16 text-[12px] h-[30px] w-full items-start justify-end">
                  {/* <a href={getAssetUrl(item)} target="_blank" rel="noreferrer"> */}
                  {item.file_type === 'image' && (
                    <span
                      onClick={() => {
                        setModalVisible((prev) => !prev)
                      }}
                    >
                      <AiOutlineEye
                        size={16}
                        className="transition-all duration-100 ease-in-out hover:scale-125 cursor-pointer"
                      />
                    </span>
                  )}
                  {/* </a> */}

                  <AiOutlineDownload
                    onClick={() => {
                      saveAs(
                        getAssetUrl(item),
                        `${item?.label ? item?.label : getAssetName(item)}`,
                      )
                    }}
                    size={16}
                    className="mr-10 transition-all duration-100 ease-in-out hover:scale-125 cursor-pointer"
                  />
                </span>
              </AnimatedBlock>
              <AnimatedBlock
                className="transition-colors duration-100 ease-in-out py-6 px-8 bg-white bg-opacity-[0.89] hover:bg-opacity-[0.98] text-black font-bold rounded-sm  w-full"
                style={{
                  position: 'absolute',
                  bottom: 0,
                  height: 30,
                  left: 0,
                  translateY: interpolate(animation.value, [0, 1], [20, 0]),
                  opacity: animation.value,
                }}
              >
                <div className="text-[12px] h-[30px] w-full text-center truncate ">
                  {item?.label ? item?.label : getAssetName(item)}
                </div>
              </AnimatedBlock>
            </>
          )}
        </TransitionBlock>

        <ImagePreviewModal
          modalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          assetDetails={item}
        />
      </div>
    )
  }

  return (
    <div className="min-h-[82vh]">
      <div className="mb-6">
        <JobTitle>Files &amp; Photos</JobTitle>
      </div>
      {projectDetail?.project_details?.is_quote_accepted ? (
        <>
          <div className="flex items-center justify-between py-16 border-t-1 border-gray-200 ">
            <div className="flex gap-10 flex-1 justify-end items-start">
              <div className="w-[25%] flex flex-col gap-6">
                <DynamicSearchSelect
                  placeholder="Filter By SiteVisit"
                  actionUrl={`/projects/${projectId}/dropdown-list`}
                  params={{isPublished: true}}
                  value={
                    data?.selectedItem ?? {
                      id: 0,
                      label: 'All Site Visits',
                      value: 0,
                    }
                  }
                  remapOptions={(
                    data: Array<{
                      list_item_details: {
                        id: number
                        title: string
                      }
                    }>,
                  ) => {
                    return data
                      ?.filter(
                        ({list_item_details: item}) =>
                          !!item.id && !!item.title,
                      )
                      ?.map(({list_item_details}) => {
                        return {
                          id: list_item_details?.id,
                          label: list_item_details?.title,
                          value: list_item_details?.id,
                        }
                      })
                  }}
                  onChangeValue={(data) => {
                    setValue('selectedItem', data)
                  }}
                  defaultOptions={[{id: 0, label: 'All Site Visits', value: 0}]}
                  // cacheOptions
                  // additional={{
                  //   dependencies: [data?.selectedItem],
                  // }}
                />
                <ClearableChip
                  filteredValue={data?.selectedItem?.label}
                  isClearable
                  handleClearValue={() => {
                    setValue('selectedItem', undefined)
                  }}
                />
              </div>
              <div className="flex flex-col gap-6 justify-end items-end w-[15%]">
                <SelectField
                  placeholder="Filter By FileType"
                  value={data?.filterType ?? fileTypeOptions?.at(0)}
                  options={fileTypeOptions}
                  onChangeValue={(item) => {
                    setValue('filterType', item)
                  }}
                />

                <ClearableChip
                  filteredValue={data?.filterType?.label}
                  isClearable
                  handleClearValue={() => {
                    setValue('filterType', undefined)
                  }}
                />
              </div>
            </div>
          </div>
          {files && Array.isArray(files) && files?.length > 0 ? (
            <div className="grid pt-20 text-gray-400 border-t-1 border-gray-200 flex-wrap grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5  justify-center justify-items-center">
              {files.map((item, index) => (
                <FileDisplayComponent key={index} {...{item, index}} />
              ))}
            </div>
          ) : (
            <div className="pt-20 border-t-1 border-gray-200">
              <div className="py-40 border-1 border-blue-150 bg-blue-100 rounded-sm w-full flex justify-center items-center">
                There are no files &amp; photos.
              </div>
            </div>
          )}

          {Number(filesAndPhotosTotal) > 0 ? (
            <div className="w-full flex flex-nowrap justify-end">
              <div className="py-20">
                <Pagination
                  count={Math.ceil(Number(filesAndPhotosTotal) / LIMIT)}
                  page={Number(1)}
                  onChange={(newPage: number) => {
                    setPage(+newPage)
                  }}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <MessageBox error message={'Project does not have accepted quote.'} />
      )}
    </div>
  )
}

const fileTypeOptions = [
  {
    id: 1,
    label: 'All File Types',
    value: 'all',
  },
  {
    id: 2,
    label: 'Document',
    value: 'document',
  },
  {
    id: 3,
    label: 'Image',
    value: 'image',
  },
  {
    id: 4,
    label: 'Video',
    value: 'video',
  },
]
