import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigation} from 'react-auth-navigation'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'

import {Button, Dropdown, Input, Loading} from 'app/common'
import {SettingsHeader} from 'app/pages/settings/components'
import {useDebounceValue} from 'hooks'
import {
  getProjectFormByIdAction,
  createProjectFormAction,
  getUnattachedFormsAction
} from 'redux-src'

import {ProjectFormListTable} from './components'

export const ProjectFormMainPage = () => {
  const {
    unattachedFormsByProjectId: unattachedFormList,
    unattachedFormsByProjectIdLoading
  }: RT.FormsReduxType = useSelector((state: any) => state.forms)

  const [searchValue, setSearchValue] = useState<string>()
  const [unattachedFormSearch, setUnatachedFormSearch] = useState<string>()

  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  const debounceValue = useDebounceValue(searchValue)
  const unattachedDebounceValue = useDebounceValue(unattachedFormSearch)

  const onAddFormsToTheProject = (formId: number) => {
    const body = {
      formId: formId
    }
    dispatch(
      createProjectFormAction(projectId, body, () => {
        dispatch(getProjectFormByIdAction(projectId))
        dispatch(
          getUnattachedFormsAction(projectId, {
            page: 1,
            limit: 100,
            search: unattachedDebounceValue ?? undefined,
            status: 'published'
          })
        )
      })
    )
  }

  const fetchProjectFormById = useCallback(
    (page: number, limit: number) => {
      const params = {
        page: page,
        limit: limit,
        search: debounceValue ?? undefined
      }
      dispatch(getProjectFormByIdAction(projectId, params))
    },
    [debounceValue, dispatch, projectId]
  )

  useEffect(() => {
    fetchProjectFormById(1, 100)
  }, [fetchProjectFormById])

  useEffect(() => {
    dispatch(
      getUnattachedFormsAction(projectId, {
        page: 1,
        limit: 100,
        search: unattachedDebounceValue ?? undefined,
        status: 'published'
      })
    )
  }, [dispatch, projectId, unattachedDebounceValue])

  return (
    <div>
      <SettingsHeader title="Forms" overview help />
      <div className="flex justify-between mt-8">
        <div>
          Here you can view the status and details of all forms associated with
          this project.
        </div>
        <div>
          <Dropdown
            inDismiss={false}
            triggerElement={
              <div className="transition duration-300 ease-in flex items-center border-1 border-gray-300 justify-center bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer">
                <Button
                  size={'lg'}
                  title={'Add Forms'}
                  className={'w-[max-content'}
                />
              </div>
            }
            placement="bottomright"
          >
            <div className="flex flex-col w-[280px] shadow-xl bg-white border-1 border-gray-200 mt-4 rounded-sm px-16 py-10">
              <div className="flex flex-col gap-10 w-full py-6">
                <span className="text-sm font-bold">Search</span>
                <Input
                  name="search"
                  value={unattachedFormSearch}
                  prepend={<FaSearch size={16} color={colors.gray[500]} />}
                  placeholder="Search"
                  onChange={(e) => setUnatachedFormSearch(e.target.value)}
                  containerClass="w-[100%]"
                />
              </div>
              <div className="flex flex-col max-h-[400px]">
                <span className="text-sm font-bold py-6">
                  Unattached forms:
                </span>
                {unattachedFormsByProjectIdLoading ? (
                  <div className="flex items-center justify-center">
                    <Loading small />
                  </div>
                ) : unattachedFormList?.length > 0 ? (
                  unattachedFormList?.map(({forms}) => {
                    return (
                      <span
                        key={forms?.id}
                        className="px-16 py-8 text-black hover:bg-blue-100 cursor-pointer"
                        onClick={() => {
                          onAddFormsToTheProject(forms?.id)
                        }}
                      >
                        {forms?.name}
                      </span>
                    )
                  })
                ) : (
                  <div className="py-12 px-10">
                    {' '}
                    No published forms found !!
                  </div>
                )}
              </div>
            </div>
          </Dropdown>
        </div>
      </div>

      <div className="flex gap-10 w-full">
        <Input
          name="search"
          value={searchValue}
          prepend={<FaSearch size={16} color={colors.gray[500]} />}
          placeholder="Search"
          onChange={(e) => setSearchValue(e.target.value)}
          containerClass="w-[35%]"
        />
      </div>
      <div className="mt-14">
        <ProjectFormListTable {...{fetchProjectFormById}} />
      </div>
    </div>
  )
}
