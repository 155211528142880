import {useEffect, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'

import {DynamicSearchSelect, MessageBox} from 'app/components'
import {Button, toast} from 'app/common'
import {
  getProjectTicketList,
  assignTicketToSiteVisit,
  unAssignTicketToSiteVisit,
  getSiteVisitTicketList
} from 'redux-src'
import {AssignedEmployee, ScheduleModal} from './components'
import {Pagination} from 'app/common/pagination'

export const SchedulingPage = () => {
  const {
    siteVisitTicketList: siteVisitTicketListFromApi,
    siteVisitTicketCount: totalSiteVisitTickets
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const LIMIT = 10
  const [page, setPage] = useState<number>()
  // const [totalPage] = useState<number>(Math.ceil(totalSiteVisitTickets / LIMIT))

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId, siteVisitId} = params as any

  const [scheduleModal, setScheduleModal] = useState<boolean>(false)

  const [data, {setValue}] = useFormInput({ticketId: undefined})

  const handleAssignSitevisitTicket = () => {
    if (!!data?.ticketId) {
      dispatch(
        assignTicketToSiteVisit(
          projectId,
          siteVisitId,
          data?.ticketId?.id,
          () => {
            setValue('ticketId', undefined)
            dispatch(
              getSiteVisitTicketList(projectId, siteVisitId, 1, 100, () => {})
            )
            dispatch(getProjectTicketList(projectId))
          }
        )
      )
    } else {
      return toast.error('Select ticket to assign !!')
    }
  }

  useEffect(() => {
    !!projectId &&
      !!siteVisitId &&
      dispatch(
        getSiteVisitTicketList(
          projectId,
          siteVisitId,
          page ?? 1,
          LIMIT,
          () => {}
        )
      )
  }, [dispatch, page, siteVisitId, projectId])

  useEffect(() => {
    dispatch(getProjectTicketList(projectId))
  }, [dispatch, projectId])

  return (
    <div className="p-16">
      <div className="font-bold text-base">Assignment &amp; Scheduling</div>
      <div className="flex items-center mt-8 gap-8 mb-10">
        <DynamicSearchSelect
          actionUrl={`/projects/${projectId}/tickets/unassigned`}
          value={data?.ticketId}
          remapOptions={(data: Api.SiteVisitTicketIndividual[]) => {
            return data?.map(({ticket_details}) => {
              return {
                id: ticket_details?.id,
                label: ticket_details?.title,
                value: ticket_details?.id
              }
            })
          }}
          onChangeValue={(value) => {
            setValue('ticketId', value)
          }}
          cacheOptions
        />
        <Button
          title="Assign Ticket"
          onClick={() => {
            handleAssignSitevisitTicket()
          }}
          className="w-[300px]"
          isDisabled={!!!data?.ticketId}
        />

        <ScheduleModal
          scheduleModal={scheduleModal}
          setScheduleModal={setScheduleModal}
          modalId="ScheduleModal One"
        />
      </div>

      {siteVisitTicketListFromApi?.length > 0 ? (
        siteVisitTicketListFromApi?.map((ticket) => {
          return (
            <AssignedEmployee
              key={ticket?.ticket_details?.id}
              siteVisitId={Number(siteVisitId)}
              ticketDetails={ticket}
              onRemove={(cb) => {
                dispatch(
                  unAssignTicketToSiteVisit(
                    projectId,
                    siteVisitId,
                    ticket?.ticket_details?.id,
                    () => {
                      cb()
                      dispatch(
                        getSiteVisitTicketList(
                          projectId,
                          siteVisitId,
                          1,
                          100,
                          () => {}
                        )
                      )
                    }
                  )
                )
              }}
            />
          )
        })
      ) : (
        <MessageBox message={'There are no site visit tickets !!'} />
      )}

      {Number(totalSiteVisitTickets) > 0 ? (
        <div className="w-full flex flex-nowrap justify-end">
          <div className="py-20">
            <Pagination
              count={Math.ceil(Number(totalSiteVisitTickets) / LIMIT)}
              page={Number(1)}
              onChange={(newPage: number) => {
                setPage(+newPage)
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
