import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
  TooltipProps,
} from 'recharts'

export function PieChartComp({
  data,
  height = 250,
  showLegend = true,
}: {
  data: Array<{
    name: string
    value: number
    type?: string
  }>
  height?: number
  showLegend?: boolean
}) {
  // function to return custom labels in percentage
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: {
    cx: number
    cy: number
    midAngle: number
    innerRadius: number
    outerRadius: number
    percent: number
    index: number
  }) => {
    const RADIAN = Math.PI / 180

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {percent <= 0 ? '' : ` ${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  // functional component for custom tooltip

  const CustomTooltip = <T extends string | number, E extends string | number>({
    active,
    payload,
  }: TooltipProps<T, E>) => {
    const hoveredPie = payload?.at(0)

    if (active && payload && payload.length) {
      return (
        <div
          className={`project-status-tooltip ${hoveredPie?.payload?.className}`}
          style={{width: 'fit-content', height: 'fit-content'}}
        >
          <div className="label">
            <div className="label-title">{hoveredPie?.name}: </div>
            <div className="label-value">{hoveredPie?.value}</div>
          </div>
        </div>
      )
    }

    return null
  }

  const cellColors = {
    completed: '#76cc00',
    remaining: '#3452c0',
    open: '#e2420d',
  }

  return (
    <ResponsiveContainer width={'100%'} height={height}>
      <PieChart width={400} height={400}>
        <Pie
          dataKey="value"
          isAnimationActive={true}
          data={data}
          outerRadius={80}
          cx="50%"
          cy="50%"
          labelLine={false}
          fill="#8884D8"
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => {
            const color = entry.type ?? 'completed'
            return (
              <Cell
                key={`cell-${index}`}
                fill={cellColors[color as keyof typeof cellColors]}
              />
            )
          })}
        </Pie>
        {showLegend && (
          <Legend
            verticalAlign="middle"
            align="right"
            layout="vertical"
            height={36}
          />
        )}
        <Tooltip content={<CustomTooltip />} shared={false} />
      </PieChart>
    </ResponsiveContainer>
  )
}
