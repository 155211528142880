import {toast} from 'app/common'

// import { errorHandler } from '../../utils'

import {
  GET_PRELIMINARY_LIST,
  CREATE_PRELIMINARY_ITEM,
  UPDATE_PRELIMINARY_ITEM_INFO,
  UPDATE_PRELIMINARY_ITEM_MONITORY_INFO,
  DELETE_PRELIMINARY_ITEM,
  CREATE_PRELIMINARY_LINE_ITEM,
  UPDATE_PRELIMINARY_LINE_ITEM,
  DELETE_PRELIMINARY_LINE_ITEM,
  UPDATE_PROJECT_PRELIMINARY_DETAILS,
  DELETE_PRELIMINARY_ITEMS
} from '.'
import {api, APIS} from '../../config'

// MARK: GET PROJECT PRELIMINARY LIST

export function getPreliminaryListAction(projectId: number) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PRELIMINARY_LIST.LOADING})
      res = await api<Api.Base<Api.PreliminaryList>>(
        `${APIS.projects}/${projectId}/preliminary`
      )
      const {
        success,
        data: {data: quotes, message}
      } = res.data

      if (success) {
        dispatch({type: GET_PRELIMINARY_LIST.SUCCESS, payload: quotes})
        return 1
      } else {
        dispatch({type: GET_PRELIMINARY_LIST.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: GET_PRELIMINARY_LIST.ERROR})
      return 1
    }
  }
}

// MARK: CREATE PROJECT PRELIMINARY ITEM

export function createPreliminaryItemAction(
  projectId: number | string,
  body: any,
  successCallback?: () => void,
  errorCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_PRELIMINARY_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items`,
        'POST',
        body
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: CREATE_PRELIMINARY_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_PRELIMINARY_ITEM.ERROR, payload: message})
        toast.error(errorMessage)
        errorCallback && errorCallback()
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: CREATE_PRELIMINARY_ITEM.ERROR})
      toast.error('Error Creating new preliminary item !')
      return 1
    }
  }
}

// MARK: UPDATE INFO OF PRELIMINARY ITEM

export function updatePreliminaryItemInfoAction(
  projectId: number | string,
  preliminaryItemId: number | string,
  body: any,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_PRELIMINARY_ITEM_INFO.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items/${preliminaryItemId}`,
        'PATCH',
        body
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: UPDATE_PRELIMINARY_ITEM_INFO.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_PRELIMINARY_ITEM_INFO.ERROR, payload: message})
        toast.error(errorMessage)
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: UPDATE_PRELIMINARY_ITEM_INFO.ERROR})
      toast.error('Error Updating preliminary item !')
      return 1
    }
  }
}

// MARK UPDATE MONETARY INFO OF PRELIMINARY ITEM

export function updatePreliminaryItemMonetary(
  projectId: number | string,
  preliminaryItemId: number | string,
  body: any,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_PRELIMINARY_ITEM_MONITORY_INFO.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items/monetary/${preliminaryItemId}`,
        'PATCH',
        body
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({
          type: UPDATE_PRELIMINARY_ITEM_MONITORY_INFO.SUCCESS,
          payload: data
        })
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: UPDATE_PRELIMINARY_ITEM_MONITORY_INFO.ERROR,
          payload: message
        })
        toast.error(errorMessage)
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: UPDATE_PRELIMINARY_ITEM_MONITORY_INFO.ERROR})
      toast.error('Error Updating monetary info of preliminary item !')
      return 1
    }
  }
}

//MARK: DELETE PRELIMINARY ITEM

export function deletePreliminaryItem(
  projectId: number | string,
  preliminaryItemId: number | string,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_PRELIMINARY_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items/${preliminaryItemId}`,
        'DELETE'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: DELETE_PRELIMINARY_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_PRELIMINARY_ITEM.ERROR, payload: message})
        toast.error(errorMessage)
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: DELETE_PRELIMINARY_ITEM.ERROR})
      toast.error('Error Delating preliminary item !')
      return 1
    }
  }
}

// MARK: CREATE PRELIMINARY LINE ITEM

export function createPreliminaryLineItem(
  projectId: number | string,
  preliminaryItemId: number | string,
  body: any,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_PRELIMINARY_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items/${preliminaryItemId}/line-items`,
        'POST',
        body
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: CREATE_PRELIMINARY_LINE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_PRELIMINARY_LINE_ITEM.ERROR, payload: message})
        toast.error(errorMessage)
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: CREATE_PRELIMINARY_LINE_ITEM.ERROR})
      toast.error('Error Creating preliminary line item !')
      return 1
    }
  }
}

//MARK: UPDATE PRELIMINARY LINE ITEM

export function updatePreliminaryLineItem(
  projectId: number | string,
  preliminaryItemId: number | string,
  preliminaryLineItemId: number | string,
  body: any,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_PRELIMINARY_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items/${preliminaryItemId}/line-items/${preliminaryLineItemId}`,
        'PATCH',
        body
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: UPDATE_PRELIMINARY_LINE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_PRELIMINARY_LINE_ITEM.ERROR, payload: message})
        toast.error(errorMessage)
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: UPDATE_PRELIMINARY_LINE_ITEM.ERROR})
      toast.error('Error Updating preliminary line item !')
      return 1
    }
  }
}

//MARK: Update project preliminary details

export function updateProjectPreliminaryDetails(
  projectId: number | string,
  body: any,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_PROJECT_PRELIMINARY_DETAILS.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary-details`,
        'PATCH',
        body
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({
          type: UPDATE_PROJECT_PRELIMINARY_DETAILS.SUCCESS,
          payload: data
        })
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: UPDATE_PROJECT_PRELIMINARY_DETAILS.ERROR,
          payload: message
        })
        toast.error(errorMessage)
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: UPDATE_PROJECT_PRELIMINARY_DETAILS.ERROR})
      toast.error('Error Updating project preliminary details !')
      return 1
    }
  }
}

// MARK: DELETE PROJECT PRELIMINARY LINE ITEM

export function deletePreliminaryLineItem(
  projectId: number | string,
  preliminaryItemId: number | string,
  preliminaryLineItemId: number | string,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_PRELIMINARY_LINE_ITEM.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items/${preliminaryItemId}/line-items/${preliminaryLineItemId}`,
        'DELETE'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: DELETE_PRELIMINARY_LINE_ITEM.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_PRELIMINARY_LINE_ITEM.ERROR, payload: message})
        toast.error(errorMessage)
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: DELETE_PRELIMINARY_LINE_ITEM.ERROR})
      toast.error('Error Deleting preliminary line item !')
      return 1
    }
  }
}

//MARK: DELETE PRELIMINARY ITEMS ALL

export function deletePreliminaryItemsAction(
  projectId: number,
  successCallback?: () => void
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_PRELIMINARY_ITEMS.LOADING})
      res = await api<any>(
        `${APIS.projects}/${projectId}/preliminary/items`,
        'DELETE'
      )
      const {
        success,
        data: {data, message},
        message: errorMessage
      } = res.data

      if (success) {
        dispatch({type: DELETE_PRELIMINARY_ITEMS.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_PRELIMINARY_ITEMS.ERROR, payload: message})
        toast.error(errorMessage)
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: DELETE_PRELIMINARY_ITEMS.ERROR})
      return 1
    }
  }
}
