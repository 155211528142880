import {useEffect, useState} from 'react'
import {ActiveLink, Auth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {
  getCompanyUserList,
  getSiteVisitDetailAction,
  getSiteVisitListAction,
  getSiteVisitTicketList,
  updateSiteVisitBasicDetails
} from 'redux-src'

import {Collapsible, Textarea} from 'app/common'
import {SiteVisitBottomModal, VisitSitesHeader} from './components'
import {useFormInput} from 'use-form-input'

const SiteVisitAuthTabItem = ({
  title,
  path,
  subtitle
}: {
  title: string
  path: string
  subtitle?: string
}) => (
  <ActiveLink
    to={path}
    className="block p-12 px-16 bg-gray-100 rounded-t-sm border-1 border-gray-200 border-b-0 relative text-gray-400"
    activeClassName="bg-white after:content-[''] after:absolute after:left-0 after:top-[100%] after:w-[100%] after:h-[3px] after:bg-white after:-translate-y-half font-bold text-gray-500"
  >
    <div className="text-[16px]">{title}</div>
    <div className="text-sm">{subtitle}</div>
  </ActiveLink>
)

const SiteVisitAuthTab = ({
  siteVisitId,
  assignedTicket
}: {
  siteVisitId: string
  assignedTicket?: number
}) => {
  const {params} = useNavigation()
  const {projectId} = params as any

  return (
    <div className="grid grid-cols-4 gap-4">
      <SiteVisitAuthTabItem
        title="Ticket Assignment"
        path={`/projects/${projectId}/site-visit/${siteVisitId}/scheduling`}
        subtitle={`${assignedTicket} TICKETS`}
      />
      <SiteVisitAuthTabItem
        title="Labour Entry"
        path={`/projects/${projectId}/site-visit/${siteVisitId}/labour`}
      />
    </div>
  )
}

export const SiteVisitsPage = () => {
  //* FORM STATES

  const {siteVisitTicketCount}: RT.TicktesReduxType = useSelector(
    (state: any) => state.tickets
  )

  const {
    params,
    navigation: {navigate}
  } = useNavigation()
  const {siteVisitId, projectId} = params as any
  const dispatch = useDispatch()

  const {getSiteVisitDetail} = useSelector((state: any) => state.siteVisit)

  const [bottomModalVisible, setBottomModalVisible] = useState(false)
  const [checklistId] = useState<{
    checklistId: number
    status: string
  }>({
    checklistId: 0,
    status: ''
  })

  const [data, {setValue}] = useFormInput({description: ''})

  useEffect(() => {
    if (siteVisitId) {
      navigate(`/projects/${projectId}/site-visit/${siteVisitId}/scheduling`)
      dispatch(getSiteVisitDetailAction(projectId, siteVisitId))
      dispatch(getCompanyUserList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, siteVisitId])

  const onUpdateSiteVisitDescription = () => {
    if (
      getSiteVisitDetail?.site_visit_details?.description === data?.description
    )
      return

    const body = {
      title: getSiteVisitDetail?.site_visit_details?.title ?? '',
      description: data?.description ?? ''
    }

    dispatch(
      updateSiteVisitBasicDetails(projectId, siteVisitId, body, () => {
        dispatch(getSiteVisitDetailAction(projectId, siteVisitId))
        dispatch(getSiteVisitListAction(projectId))
      })
    )
  }

  useEffect(() => {
    setValue(
      'description',
      getSiteVisitDetail?.site_visit_details?.description ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSiteVisitDetail?.site_visit_details?.description, siteVisitId])

  useEffect(() => {
    dispatch(getSiteVisitTicketList(projectId, siteVisitId, 1, 10, () => {}))
  }, [dispatch, projectId, siteVisitId])

  if (!siteVisitId) {
    return null
  }
  return (
    <div>
      <VisitSitesHeader />

      <div className="mt-16 border-t-1 border-gray-200 pt-16">
        <Collapsible
          title="Site Visit Description"
          containerClassName="pb-8 border-b-1 border-gray-200"
        >
          <Textarea
            className={`text-sm mt-16 h ${
              getSiteVisitDetail?.site_visit_details?.status === 'invoiced'
                ? 'cursor-not-allowed select-none'
                : ''
            }`}
            name="siteVisitDescription"
            placeholder="Site Visit Description"
            size="sm"
            value={data?.description}
            onChange={(e: any) => {
              setValue('description', e.target.value)
            }}
            onBlur={onUpdateSiteVisitDescription}
            disabled={
              getSiteVisitDetail?.site_visit_details?.status === 'invoiced'
            }
          />
        </Collapsible>

        <SiteVisitBottomModal
          isOpen={bottomModalVisible}
          onClose={() => setBottomModalVisible(false)}
          checklistDetails={{...checklistId}}
        />

        <SiteVisitAuthTab
          siteVisitId={siteVisitId}
          assignedTicket={siteVisitTicketCount}
        />

        <div className="border-1 border-gray-200 mb-16">
          <Auth.Screens path={`/projects/:projectId/site-visit/:siteVisitId`} />
        </div>
      </div>
    </div>
  )
}
