import { GET_SELECT_OPTIONS } from '.'
import { getSelectOptions } from '../../helpers'

export function getSelectOptionsFromAPI(successCallback?: any) {
  return async function (dispatch: any) {
    try {
      dispatch({ type: GET_SELECT_OPTIONS.LOADING })

      const selectOptions = await getSelectOptions()

      if (
        selectOptions.companyCategoryOptions !== null ||
        selectOptions.countryOptions !== null ||
        selectOptions.industryTypeOptions !== null
      ) {
        //
        dispatch({ type: GET_SELECT_OPTIONS.SUCCESS, payload: selectOptions })

        successCallback && successCallback()
        return 1
      } else {
        dispatch({
          type: GET_SELECT_OPTIONS.ERROR,
          payload: 'Error while getting select options!',
        })
        return 0
      }
    } catch ({ message }) {
      dispatch({ type: GET_SELECT_OPTIONS.ERROR, payload: message })
      return 0
    }
  }
}
