import {ReactPortal} from 'app/common/reactPortal'

import SmartPlanLogo from './../../../assets/images/smartplan-logo-transparent.png'
import {useNavigation} from 'react-auth-navigation'

export const ResponsiveOverlay = () => {
  const {
    navigation: {routes}
  } = useNavigation()

  return !routes['FormBuilderWebView']?.active &&
    !routes['TicketPlanPositionWebView']?.active ? (
    <ReactPortal wrapperId="responsive-overlay-portal">
      <div className="flex flex-col items-center h-[100%] w-[full] bg-white">
        <div className="flex flex-col items-center gap-12 pt-24">
          <div className="flex items-center justify-center gap-10">
            <img
              src={SmartPlanLogo}
              alt="Smartplan-logo"
              height={60}
              width={60}
            />
            <span className="text-[34px] font-bold text-black">
              Smart Clerk
            </span>
          </div>
          <div className="flex flex-col items-center justify-center align-center font-bold text-blue-300 text-md mt-14">
            <span>Please increase the size of your browser in</span>
            <span>order to see the content!</span>
          </div>
          <div className="flex flex-col items-center justify-center text-md text-black">
            <span>If you have any questions contact us under</span>
            <span>support@smartclerk.com</span>
          </div>
        </div>
        <div></div>
      </div>
    </ReactPortal>
  ) : (
    <></>
  )
}
