import {useIndividualFormElement} from 'hooks'
import {useAuth} from 'react-auth-navigation'

interface SectionPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

export const SectionPreview = ({id, type}: SectionPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type
  )

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      <div className="text-lg text-black font-bold border border-b-1 border-gray-200 py-4">
        {individualElement?.content?.label}
      </div>
    </div>
  ) : (
    <></>
  )
}
