import FullCalendar from '@fullcalendar/react'
import {
  Button,
  DefaultButton,
  Modal,
  SelectField,
  toast,
  ToolTip,
} from 'app/common'
import moment from 'moment'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ReactDatePicker from 'react-datepicker'
import {FaLongArrowAltRight} from 'react-icons/fa'
import {FaCircleQuestion} from 'react-icons/fa6'
import {useDispatch, useSelector} from 'react-redux'
import {
  createNewCalendarEvent,
  getActiveTicketListOfCompany,
  getActiveUserListOfCompany,
  getSiteVisitListAction,
  getUnAssignedTicketForCalendar,
  removeAssignedEmployeeFromTicket,
  RootState,
  unScheduledAssignedEmployee,
  updateScheduleTimeOfEmployee,
} from 'redux-src'
import {useCalendar} from '../../context'

type CalendarModalProp = {
  mode?: 'edit' | 'add'
  setIsVisible: Dispatch<SetStateAction<boolean>>
  isVisible: boolean
  dateTimeInfo: {
    start: Date
    end: Date
  }
  selectedResourceId?: number
  calendarRef: MutableRefObject<FullCalendar>
  selectedTicketId?: number
  selectedEventId?: number
  onClose?: () => void
}

export default function CalendarModal({
  isVisible,
  setIsVisible,
  dateTimeInfo,
  selectedResourceId,
  selectedTicketId,
  calendarRef,
  onClose,
  selectedEventId,
  mode = 'add',
}: CalendarModalProp) {
  const dispatch = useDispatch()
  const {calendarDispatch, calendarState, fetchCalendarEvents} = useCalendar()

  const [selectedResource, setSelectedResource] = useState<{
    id: any
    label: any
    value: any
  }>(null)

  useEffect(() => {
    if (!!selectedResourceId) {
      const temp = calendarState?.resources?.find((resource) => {
        return +resource?.id === +selectedResourceId
      })
      setSelectedResource({
        id: temp?.id,
        label: temp?.title,
        value: temp?.id,
      })
    }
  }, [calendarState?.resources, selectedTicketId, selectedResourceId])

  const [selectedTicket, setSelectedTicket] = useState<{
    id: number
    label: string
    value: number
  }>(null)

  useEffect(() => {
    if (!!selectedTicketId) {
      const combinedTicked = [
        ...calendarState?.unassigned_tickets,
        ...calendarState?.assigned_tickets,
      ]
      let selectedTicketTemp = combinedTicked?.find((ticket) => {
        return ticket?.ticket_details?.id === selectedTicketId
      })
      setSelectedTicket({
        id: selectedTicketTemp?.ticket_details?.id,
        label: selectedTicketTemp?.ticket_details?.title,
        value: selectedTicketTemp?.ticket_details?.id,
      })
    }
  }, [
    calendarState?.assigned_tickets,
    calendarState?.unassigned_tickets,
    selectedTicketId,
  ])

  const [userSelectedSiteVisit, setUserSelectedSiteVisit] = useState<{
    id: number
    title: string
    site_visit_number: number
  }>(undefined)

  // * check if site visit is already present for selected ticket
  const siteVisitForSelectedTicket = useMemo(() => {
    const combinedTicked = [
      ...calendarState?.unassigned_tickets,
      ...calendarState?.assigned_tickets,
    ]

    return combinedTicked?.find(
      (ticket) => ticket?.ticket_details?.id === selectedTicket?.id,
    )?.site_visit_details
  }, [
    calendarState?.assigned_tickets,
    calendarState?.unassigned_tickets,
    selectedTicket?.id,
  ])
  const {getSiteVisitList}: RT.SiteVisitReduxType = useSelector(
    (state: RootState) => state.siteVisit,
  )

  const siteVisitOptions = useMemo(
    () =>
      getSiteVisitList?.map((siteVisit) => ({
        id: siteVisit?.site_visit_details?.id,
        title: siteVisit?.site_visit_details?.title,
        site_visit_number: siteVisit?.site_visit_details?.site_visit_number,
      })),
    [getSiteVisitList],
  )

  useEffect(() => {
    if (!!siteVisitForSelectedTicket && !!siteVisitForSelectedTicket?.id) {
      setUserSelectedSiteVisit(siteVisitForSelectedTicket)
    } else {
      setUserSelectedSiteVisit(siteVisitOptions?.at(0))
    }
  }, [siteVisitForSelectedTicket, siteVisitOptions])

  // * if site visit for selected ticket is not present, fetch all site visits based on project of the ticket
  useEffect(() => {
    const combinedTicket = [
      ...calendarState?.assigned_tickets,
      ...calendarState?.unassigned_tickets,
    ]
    const userSelectedTicketProject = combinedTicket?.find(
      (ticket) => ticket?.ticket_details?.id === selectedTicket?.id,
    )?.project_details
    if (
      !!userSelectedTicketProject &&
      !!userSelectedTicketProject?.id &&
      isVisible
    ) {
      dispatch(getSiteVisitListAction(userSelectedTicketProject?.id, 1, 100))
    }
  }, [
    calendarState?.assigned_tickets,
    calendarState?.unassigned_tickets,
    dispatch,
    isVisible,
    selectedTicket?.id,
  ])

  const [dates, setDates] = useState<{
    start: Date
    end: Date
  }>({
    start: new Date(),
    end: moment().add(1, 'hour').toDate(),
  })

  useEffect(() => {
    setDates({...dateTimeInfo})
  }, [dateTimeInfo])

  // ! Project list is not required as it is assigned automatically
  // const projectListOptions = useMemo(() => {
  //   if (calendarState?.selectedProjectsList?.length > 0) {
  //     return {...calendarState?.selectedProjectsList}
  //   }

  //   let temp = projectList?.map((project) => {
  //     return {
  //       id: project?.project_details?.id,
  //       label: project?.project_details?.title,
  //       value: project?.project_details?.id,
  //     }
  //   })
  //   setSelectedProject(temp?.at(0))
  //   return temp
  // }, [calendarState?.selectedProjectsList, projectList])

  const ticketsListOptions = useMemo(() => {
    const combinedAssignedUnassigned = [
      ...calendarState?.assigned_tickets,
      ...calendarState?.unassigned_tickets,
    ]

    const isExistingID: number[] = []
    let temp: any[] = []
    combinedAssignedUnassigned?.forEach((ticket) => {
      if (!isExistingID.includes(ticket?.ticket_details?.id)) {
        isExistingID.push(ticket?.ticket_details?.id)
        temp.push({
          id: ticket?.ticket_details?.id,
          label: ticket?.ticket_details?.title,
          value: ticket?.ticket_details?.id,
        })
      }
    })

    setSelectedTicket(temp?.at(0))
    return temp
  }, [calendarState?.assigned_tickets, calendarState?.unassigned_tickets])

  const resourceOptions = useMemo(() => {
    let temp = calendarState?.resources?.map((resource) => {
      return {
        id: resource?.id,
        label: resource?.title,
        value: resource?.id,
      }
    })
    return temp
  }, [calendarState?.resources])

  const handleCreateNewEvent = useCallback(
    (isDeleteMode = false) => {
      const ticketId = selectedTicket?.id
      const title = selectedTicket?.label
      const start = dates.start
      const end = dates?.end
      const resourceId = selectedResource?.id
      const siteVisitId = userSelectedSiteVisit?.id

      // * if the end date is before than start date, throw error

      if (moment(end).diff(moment(start)) < 0) {
        toast.error('End date should be greater than start date')
        return
      }

      // * Handle event unschedule/delete
      if (isDeleteMode) {
        const combinedTicket = calendarState?.assigned_tickets

        const editedTicket = combinedTicket?.find(
          (t) =>
            +t.ticket_details?.id === +ticketId &&
            +selectedEventId === t.event_details.id,
        )

        if (!!editedTicket) {
          // TODO: Edit
          dispatch(
            unScheduledAssignedEmployee(
              editedTicket?.project_details?.id,
              editedTicket?.ticket_details?.id,
              editedTicket?.event_details?.id,
              // {
              //   scheduledFrom: null,
              //   scheduledTo: null,
              // },
              () => {
                setIsVisible((prev) => false)
                fetchCalendarEvents()
              },
            ),
          )
        }

        return
      }

      if (!!ticketId && !!title && !!start && !!end && !!resourceId) {
        if (mode === 'add') {
          const selectedTicket = calendarState?.assigned_tickets?.find(
            (ticket) => +ticket.ticket_details.id === +ticketId,
          )
          if (
            !!selectedTicket &&
            Number(selectedTicket?.user_detail?.id) !== +resourceId
          ) {
            dispatch(
              removeAssignedEmployeeFromTicket(
                selectedTicket?.project_details?.id,
                selectedTicket?.ticket_details?.id,
                {
                  assignedUserIds: [selectedTicket?.user_detail?.id],
                },
                () => {
                  dispatch(
                    createNewCalendarEvent(
                      {
                        ticketId: selectedTicket?.ticket_details?.id,
                        scheduledFrom: moment(start).format(),
                        scheduledTo: moment(end).format(),
                        userId: resourceId,
                        siteVisitId,
                      },
                      () => {
                        fetchCalendarEvents()
                        setIsVisible(false)
                      },
                    ),
                  )
                },
              ),
            )

            return
          }

          dispatch(
            createNewCalendarEvent(
              {
                ticketId: ticketId,
                scheduledFrom: moment(start)?.format(),
                scheduledTo: moment(end).format(),
                userId: resourceId,
                siteVisitId,
              },
              () => {
                const projects = calendarState?.selectedProjectsList
                  ?.map((project) => project?.id)
                  .join(',')
                dispatch(
                  getActiveTicketListOfCompany({
                    projects: projects,
                  }),
                )
                dispatch(
                  getActiveUserListOfCompany({
                    projects: projects,
                  }),
                )
                dispatch(
                  getUnAssignedTicketForCalendar({
                    type:
                      calendarState?.selectedProjectType?.value === 'all'
                        ? undefined
                        : (calendarState?.selectedProjectType?.label as
                            | 'Quote'
                            | 'Days Work'),
                    projects: calendarState?.selectedProjectsList?.join(','),
                  }),
                )
                fetchCalendarEvents()
              },
            ),
          )
        } else {
          const combinedTicket = calendarState?.assigned_tickets

          const editedTicket = combinedTicket?.find(
            (t) =>
              +t.ticket_details?.id === +ticketId &&
              +selectedEventId === t.event_details.id,
          )

          if (!!editedTicket) {
            // TODO: Edit
            dispatch(
              updateScheduleTimeOfEmployee(
                editedTicket?.project_details?.id,
                editedTicket?.ticket_details?.id,
                editedTicket?.event_details?.id,
                {
                  scheduledFrom: moment(start)?.format(),
                  scheduledTo: moment(end).format(),
                },
                () => {
                  fetchCalendarEvents()
                },
              ),
            )
          }
        }
        // * clear all inputs
        // setSelectedResource(null)
        setDates({start: null, end: null})
        setIsVisible(false)
        // setSelectedTicket(null)
      } else {
        toast.error('Please fill all the fields')
      }
    },
    [
      calendarState?.assigned_tickets,
      calendarState?.selectedProjectType?.label,
      calendarState?.selectedProjectType?.value,
      calendarState?.selectedProjectsList,
      dates?.end,
      dates.start,
      dispatch,
      mode,
      onClose,
      selectedEventId,
      selectedResource?.id,
      selectedTicket?.id,
      selectedTicket?.label,
      setIsVisible,
      userSelectedSiteVisit?.id,
    ],
  )

  return (
    <Modal
      onClose={onClose}
      id={'calendar-modal'}
      title={mode === 'add' ? 'Add New Event' : 'Update event schedule'}
      isActive={isVisible}
      toggleModal={setIsVisible}
      size="sm"
    >
      <div className="flex flex-col p-24 gap-20">
        {/** Project input
         * MARK: Not needed as when ticket is assigned to user, the project is automatically assigned with the ticket
         * eg. Ticket of project A is not assignable to project B, so project selection is not needed
         */}
        {/* <div className="flex flex-col gap-6">
          <div className="text-sm font-bold text-black">Select a project</div>
          <SelectField
            options={projectListOptions}
            placeholder="Select a project"
            onChangeValue={(newValue) => {
              setSelectedProject(newValue)
            }}
            value={selectedProject}
          />
        </div> */}

        <div className="flex flex-col gap-6">
          <div className="text-sm font-bold text-black">Selected User</div>
          <SelectField
            isDisabled
            error={!selectedResource}
            options={resourceOptions}
            placeholder="Select resource"
            onChangeValue={(newValue) => {
              setSelectedResource(newValue)
            }}
            value={selectedResource}
          />
        </div>

        {/* Ticket select */}
        <div className="flex flex-col gap-6">
          <div className="text-sm font-bold text-black">
            {mode !== 'edit' ? 'Select ' : 'Selected '}Ticket
          </div>
          <SelectField
            isDisabled={mode === 'edit'}
            error={!selectedTicket}
            value={selectedTicket}
            options={ticketsListOptions}
            onChangeValue={(newValue) => {
              setSelectedTicket(newValue)
            }}
            placeholder="Select a ticket"
          />
        </div>

        {/* Site Visit Input */}
        <div className="flex flex-col gap-6">
          <div className="text-sm font-bold text-black flex items-center gap-x-6">
            {!!siteVisitForSelectedTicket ? 'Selected ' : 'Select '}Site Visit{' '}
            {!!siteVisitForSelectedTicket &&
              !!siteVisitForSelectedTicket?.id && (
                <ToolTip
                  text="Site visit for selected ticket is pre-selected."
                  className="cursor-pointer text-blue-300"
                  top
                >
                  <FaCircleQuestion />
                </ToolTip>
              )}{' '}
          </div>
          <SelectField
            value={userSelectedSiteVisit}
            options={siteVisitOptions}
            getOptionLabel="title"
            getOptionValue="id"
            onChangeValue={(newValue) => {
              setUserSelectedSiteVisit(newValue)
            }}
            placeholder="Select Site Visit"
            isDisabled={
              !!siteVisitForSelectedTicket && !!siteVisitForSelectedTicket?.id
            }
          />
        </div>
        {/* * Date time input */}
        <div className="flex flex-col gap-6">
          <div className="text-sm font-bold text-black">Schedule Date</div>
          <div className="flex flex-row gap-10 items-center justify-between ">
            <div className="flex relative flex-column gap-4 mt-30">
              <div className="absolute top-[-25px] left-0">Date</div>
              <div className="flex flex-row items-center border-1   border-gray-200 ">
                <ReactDatePicker
                  value={moment(dates?.start).format('YYYY-MM-DD')}
                  selected={dates?.start}
                  onChange={(date: Date) => {
                    setDates((prev) => {
                      return {
                        ...prev,
                        start: moment(prev.start)
                          .set({
                            month: date?.getMonth(),
                            year: date?.getFullYear(),
                            date: date?.getDate(),
                          })
                          .toDate(),
                      }
                    })
                  }}
                  maxDate={dates?.end}
                  className="py-6 pl-4 w-96 rounded-sm"
                  placeholderText="Start Date"
                />
              </div>

              <div className="absolute top-[-25px] right-[67px]">Time</div>
              <div className="flex flex-row items-center border-1 border-gray-200 px">
                <ReactDatePicker
                  value={moment(dates?.start).format('hh:mm A')}
                  selected={dates?.start}
                  onChange={(date: Date) => {
                    setDates((prev) => {
                      return {
                        ...prev,
                        start: moment(prev.start)
                          .set({
                            hour: date?.getHours(),
                            minute: date?.getMinutes(),
                            second: 0,
                          })
                          .toDate(),
                      }
                    })
                  }}
                  className="py-6 pl-4 w-96 rounded-sm"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="Start Time"
                />
              </div>
            </div>
            <div className="mx-20 pt-20">
              <FaLongArrowAltRight size={20} />
            </div>
            <div className="flex relative flex-column gap-4 mt-30">
              <div className="absolute top-[-25px] left-0">Date</div>
              <div className="flex flex-row items-center border-1 border-gray-200 ">
                <ReactDatePicker
                  selected={dates?.end}
                  value={moment(dates?.end).format('YYYY-MM-DD')}
                  onChange={(date: Date) => {
                    setDates((prev) => {
                      return {
                        ...prev,
                        end: moment(prev.end)
                          .set({
                            month: date?.getMonth(),
                            year: date?.getFullYear(),
                            date: date?.getDate(),
                          })
                          .toDate(),
                      }
                    })
                  }}
                  className="py-6 pl-4 w-96 rounded-sm"
                  placeholderText="End Date"
                  minDate={dates?.start}
                />
              </div>

              <div className="absolute top-[-25px] right-[67px]">Time</div>
              <div className="flex flex-row items-center border-1 border-gray-200 px">
                <ReactDatePicker
                  value={moment(dates?.end).format('hh:mm A')}
                  onChange={(date: Date) => {
                    setDates((prev) => {
                      return {
                        ...prev,
                        end: moment(prev.end)
                          .set({
                            hour: date?.getHours(),
                            minute: date?.getMinutes(),
                            second: 0,
                          })
                          .toDate(),
                      }
                    })
                  }}
                  className="py-6 pl-4 w-96 rounded-sm"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="End Time"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Control buttons */}
      <div className="border-t-1 border-gray-200 flex flex-row-reverse px-24 py-14 gap-10">
        <Button
          title={`${mode === 'add' ? 'Add' : 'Edit'} Event`}
          className="text-white font-bold"
          type="button"
          onClick={(e) => {
            handleCreateNewEvent()
          }}
        />
        {mode === 'edit' && (
          <Button
            title={`Delete Event`}
            className="text-white font-bold bg-red-300 hover:bg-red-400"
            type="button"
            onClick={(e) => {
              handleCreateNewEvent(true)
            }}
          />
        )}
        <DefaultButton
          title="Cancel"
          className="text-blue-400 bg-blue-100 hover:bg-blue-150"
          type="button"
          onClick={() => {
            onClose?.()
            setIsVisible(false)
          }}
        />
      </div>
    </Modal>
  )
}
