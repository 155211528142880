import {useCallback, useMemo, useState} from 'react'
import {
  Bar,
  BarChart,
  Label,
  LabelList,
  LabelProps,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'

export const BarChartComp = <
  T extends Record<string, number | string>,
  K extends Extract<keyof T, string>,
>({
  data,
  showLegend,
  showName,
  height = 35,
  notations,
  symbol,
}: {
  data: T
  height?: number
  showLegend?: boolean
  showName?: boolean
  notations: {
    name: K
    pv: K
    pvName: string
    uv: K
    uvName: string
    total: K
    totalName: string
    dv?: K
    dvName?: string
  }
  symbol?: string
}) => {
  const total = useMemo(
    () =>
      +Number(
        isNaN(Number(data[notations.total])) ? 1 : data[notations.total],
      ).toFixed(2),
    [data, notations.total],
  )

  const preValue = useMemo(
    () =>
      +Number(
        isNaN(Number(data[notations.pv])) ? 0 : data[notations.pv],
      ).toFixed(2),
    [data, notations.pv],
  )
  const preValuePercentage = `${((+preValue / total) * 100).toFixed(2)}%`

  const upValue = useMemo(
    () =>
      +Number(
        isNaN(Number(data[notations.uv])) ? 0 : data[notations.uv],
      ).toFixed(2),
    [data, notations.uv],
  )
  const upValuePercentage = `${((+upValue / total) * 100).toFixed(2)}%`

  const defValue = useMemo(
    () =>
      notations.dv
        ? +Number(
            isNaN(Number(data[notations.dv])) ? 0 : data[notations.dv],
          ).toFixed(2)
        : 0,
    [data, notations.dv],
  )
  const defValuePercentage = `${((+defValue / total) * 100).toFixed(2)}%`

  const CustomizedLabel = ({
    content,
    percentValue,
    ...rest
  }: LabelProps & {percentValue: string}) => {
    return (
      <Label
        {...rest}
        value={percentValue}
        fontSize="12"
        fill="#FFFFFF"
        fontWeight="Bold"
      />
    )
  }

  const [hoveredBarDatakey, setHoveredBarDatakey] = useState(
    notations?.pv ?? '',
  )

  const CustomTooltip = <
    TValue extends string | number,
    TName extends string | number,
  >({
    active,
    payload,
  }: TooltipProps<TValue, TName>) => {
    // const hoveredBar = payload?.at(hoveredIndex) as (typeof payload)[0] & {
    //   fill: string
    // }

    const hoveredBar = payload.filter(
      (bar) => bar.dataKey === hoveredBarDatakey,
    )[0] as (typeof payload)[0] & {fill: string}

    if (active && payload && payload.length) {
      return (
        <div className="overall-position-tooltip">
          <div
            className="label"
            style={{
              color:
                hoveredBar?.name === notations.pvName
                  ? hoveredBar?.fill
                  : '#686868',
            }}
          >
            <div className="label-title">{`${notations.pvName}: `}</div>
            <div className="label-value">
              {symbol}
              {preValue} - (
              {`${((+(preValue ?? 0) / total) * 100).toFixed(2)}%`})
            </div>
          </div>
          <div
            className="label"
            style={{
              color:
                hoveredBar?.name === notations.uvName
                  ? hoveredBar?.fill
                  : '#686868',
            }}
          >
            <div className="label-title">{`${notations.uvName}: `}</div>
            <div className="label-value">
              {symbol}
              {upValue} - ({`${((+(upValue ?? 0) / total) * 100).toFixed(2)}%`})
            </div>
          </div>

          {notations.dv && (
            <div
              className="label"
              style={{
                color:
                  hoveredBar?.name === notations.dvName
                    ? hoveredBar?.fill
                    : '#686868',
              }}
            >
              <div className="label-title">{`${notations.dvName}: `}</div>
              <div className="label-value">
                {symbol}
                {defValue} - (
                {`${((+(defValue ?? 0) / total) * 100).toFixed(2)}%`})
              </div>
            </div>
          )}

          <div className="separator" />

          <div
            className="label"
            style={{
              color: '#3452c0',
            }}
          >
            <div className="label-title">{`${notations.totalName}: `}</div>
            <div className="label-value">
              {symbol}
              {total} - (100.00%)
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  const getRadius = useCallback(
    (type: 'pv' | 'uv' | 'dv') => {
      let radius: number | [number, number, number, number] = 0

      if (!!!upValue && !!!preValue && !!!defValue) {
        return radius
      }

      switch (type) {
        case 'pv':
          if (!!!preValue) {
            radius = 0
          } else if (
            (!!!upValue && !!defValue) ||
            (!!upValue && !!!defValue) ||
            (!!upValue && !!defValue)
          ) {
            radius = [4, 0, 0, 4]
          } else {
            radius = 4
          }
          break

        case 'uv':
          if (!!upValue && !!preValue && !!defValue) {
            radius = 0
          } else if (!!!preValue && !!defValue) {
            radius = [4, 0, 0, 4]
          } else if (!!preValue && !!!defValue) {
            radius = [0, 4, 4, 0]
          } else if (!!!preValue && !!upValue && !!!defValue) {
            radius = 4
          } else {
            radius = 0
          }
          break

        case 'dv':
          if (!!!defValue) {
            radius = 0
          } else if (
            (!!!upValue && !!preValue) ||
            (!!upValue && !!!preValue) ||
            (!!upValue && !!preValue)
          ) {
            radius = [0, 4, 4, 0]
          } else {
            radius = 4
          }
          break
      }

      return radius
    },
    [defValue, preValue, upValue],
  )

  return (
    <ResponsiveContainer height={height} width={'100%'}>
      <BarChart layout="vertical" data={[data]} stackOffset="expand">
        <XAxis hide type="number" axisLine={false} />
        <YAxis
          type="category"
          dataKey={notations.name}
          stroke="#000000"
          fontSize="12"
          axisLine={false}
          hide={showName === undefined ? true : !showName}
        />

        {/* <Tooltip content={<CustomTooltip />} shared={false} /> */}
        <Tooltip content={CustomTooltip} shared cursor={false} />
        <Bar
          dataKey={notations.pv}
          fill="#76cc00"
          stackId={notations.name}
          name={notations.pvName}
          radius={getRadius('pv')}
          hide={!!!preValue || preValue === 0}
          onMouseOver={() => {
            setHoveredBarDatakey(notations.pv)
          }}
        >
          <LabelList
            dataKey={notations.pv}
            position="center"
            content={<CustomizedLabel percentValue={preValuePercentage} />}
          />
        </Bar>
        <Bar
          dataKey={notations.uv}
          fill="#e2420d"
          stackId={notations.name}
          name={notations.uvName}
          radius={getRadius('uv')}
          hide={!!!upValue || upValue === 0}
          onMouseOver={() => {
            setHoveredBarDatakey(notations.uv)
          }}
        >
          <LabelList
            dataKey={notations.uv}
            position="center"
            content={<CustomizedLabel percentValue={upValuePercentage} />}
          />
        </Bar>
        {notations.dv && (
          <Bar
            dataKey={notations.dv}
            fill="#ffb500"
            stackId={notations.name}
            name={notations.dvName}
            radius={getRadius('dv')}
            hide={!!!defValue || defValue === 0}
            onMouseOver={() => {
              setHoveredBarDatakey(notations.dv)
            }}
          >
            <LabelList
              dataKey={notations.dv}
              position="center"
              content={<CustomizedLabel percentValue={defValuePercentage} />}
            />
          </Bar>
        )}

        {showLegend && <Legend align="center" iconType="square" />}
      </BarChart>
    </ResponsiveContainer>
  )
}
