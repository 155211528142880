import React, {useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'

import {Button, Input} from 'app/common'
import {useDebounceValue} from 'hooks'

import {MaterialElementTable} from '../materialElementTable'
import {AddMaterialElement} from '../addMaterialElement'
import {SettingsHeader} from 'app/pages/settings/components'

const MaterialElementComp = () => {
  const [searchMaterial, setSearchMaterial] = useState<string>()

  const [addMaterialElementModal, setMaterialElementModal] =
    useState<boolean>(false)

  const debouncedSearchValue = useDebounceValue(searchMaterial)

  const handleModalOpen = () => {
    setMaterialElementModal(true)
  }

  return (
    <div>
      <SettingsHeader
        title={'Material List'}
        append={
          <Button
            title="Add New Material"
            onClick={handleModalOpen}
            type={'button'}
          />
        }
        subHeader={'The list allows you to manage material list'}
      />

      <div className="mt-20">
        <div className="flex items-center justify-between pb-10">
          <Input
            name="filterValue"
            type="text"
            // size="sm"
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
            containerClass="w-[300px]"
            placeholder="Search material"
            className="w-full text-sm"
            onChange={(e) => setSearchMaterial(e.target.value)}
          />
        </div>
      </div>

      <div className="my-10 mb-24 border-b-1 border-gray-200" />
      <div className="mt-10">
        <MaterialElementTable search={debouncedSearchValue} />
      </div>

      <AddMaterialElement
        addMaterialElementModal={addMaterialElementModal}
        setMaterialElementModal={setMaterialElementModal}
      />
    </div>
  )
}

export const MaterialElement = React.memo(MaterialElementComp)
