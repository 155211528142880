import {
  CREATE_SITE_NOTES,
  DELETE_CUSTOMER_NOTES,
  DELETE_SITE_NOTES,
  GET_CUSTOMER_NOTES,
  GET_SITE_NOTES,
  UPDATE_CUSTOMER_NOTES,
  UPDATE_SITE_NOTES,
  CREATE_PROJECT_NOTES,
  UPDATE_PROJECT_NOTES,
  GET_PROJECT_NOTES,
  DELETE_PROJECT_NOTES,
  GET_PROJECT_HISTORY,
  GET_PROJECT_NOTE_BY_ID,
} from './../actions/actionTypes.action'
import {
  GET_NOTES_HOSTORY_LIST,
  GET_NOTES,
  GET_JOB_NOTES,
  ADD_NOTES_ENTRY,
  GET_SITE_VISIT_NOTES,
  CREATE_CUSTOMER_NOTES,
} from '../actions'

const initalState: RT.NotesReduxType = {
  get_loading: false,

  noteHistoryList: [],
  currencySymbol: '',

  add_notes_loading: false,
  update_notes_loading: false,
  delete_notes_loading: false,
  get_notes_loading: false,
  get_site_visit_notes_loading: false,
  get_job_notes_loading: false,
  get_customer_notes_loading: false,
  update_customer_notes_loading: false,
  delete_customer_notes_loading: false,
  get_site_notes_loading: false,
  update_site_notes_loading: false,
  delete_site_notes_loading: false,

  get_project_notes_loading: false,
  update_project_notes_loading: false,
  delete_project_notes_loading: false,

  notes: [],
  siteVisitNotes: [],
  jobNotes: [],
  customerNotes: [],
  customerNotesTotal: 0,
  siteNotes: [],
  siteNotesTotal: 0,

  projectNotes: [],
  projectNotesTotal: 0,

  isProjectHistoryError: false,
  isProjectHistoryLoading: false,
  projectHistory: undefined,

  isProjectNoteByIdError: false,
  isProjectNoteByIdLoading: false,
  projectNoteById: undefined,
}

export function noteHistoryReducer(state = initalState, action: any) {
  const {type, payload} = action

  switch (type) {
    case GET_NOTES_HOSTORY_LIST.LOADING:
      return {...state, get_loading: true}

    case GET_NOTES_HOSTORY_LIST.SUCCESS:
      return {
        ...state,
        get_loading: false,
        noteHistoryList: payload,
      }

    case GET_NOTES_HOSTORY_LIST.ERROR:
      return {...state, get_loading: false}

    //* NOTES

    case GET_NOTES.LOADING:
      return {...state, get_notes_loading: true}

    case GET_NOTES.SUCCESS:
      return {
        ...state,
        get_notes_loading: false,
        notes: payload,
      }

    case GET_NOTES.ERROR:
      return {...state, get_notes_loading: false}

    case ADD_NOTES_ENTRY.LOADING:
      return {...state, add_notes_loading: true}

    case ADD_NOTES_ENTRY.SUCCESS:
      return {
        ...state,
        add_notes_loading: false,
      }

    case ADD_NOTES_ENTRY.ERROR:
      return {...state, add_notes_loading: false}

    //* JOB NOTES

    case GET_JOB_NOTES.LOADING:
      return {...state, get_job_notes_loading: true}

    case GET_JOB_NOTES.SUCCESS:
      return {
        ...state,
        get_job_notes_loading: false,
        jobNotes: payload,
      }

    case GET_JOB_NOTES.ERROR:
      return {...state, get_job_notes_loading: false}

    //* SITE_VISIT NOTES

    case GET_SITE_VISIT_NOTES.LOADING:
      return {...state, get_site_visit_notes_loading: true}

    case GET_SITE_VISIT_NOTES.SUCCESS:
      return {
        ...state,
        get_site_visit_notes_loading: false,
        siteVisitNotes: payload,
      }

    case GET_SITE_VISIT_NOTES.ERROR:
      return {...state, get_site_visit_notes_loading: false}

    case CREATE_CUSTOMER_NOTES.LOADING:
      return {...state, add_notes_loading: true}
    case CREATE_CUSTOMER_NOTES.SUCCESS:
      return {
        ...state,
        add_notes_loading: false,
      }
    case CREATE_CUSTOMER_NOTES.ERROR:
      return {...state, add_notes_loading: false}

    case GET_CUSTOMER_NOTES.LOADING:
      return {...state, get_customer_notes_loading: true}
    case GET_CUSTOMER_NOTES.SUCCESS:
      return {
        ...state,
        customerNotes: payload?.rows,
        customerNotesTotal: payload?.total,
        get_customer_notes_loading: false,
      }

    case UPDATE_CUSTOMER_NOTES.LOADING:
      return {...state, update_customer_notes_loading: true}
    case UPDATE_CUSTOMER_NOTES.SUCCESS:
      return {
        ...state,
        update_customer_notes_loading: false,
      }
    case UPDATE_CUSTOMER_NOTES.ERROR:
      return {...state, update_customer_notes_loading: false}

    case DELETE_CUSTOMER_NOTES.LOADING:
      return {...state, delete_customer_notes_loading: true}

    case DELETE_CUSTOMER_NOTES.SUCCESS:
      return {
        ...state,
        delete_customer_notes_loading: false,
      }
    case DELETE_CUSTOMER_NOTES.ERROR:
      return {...state, delete_customer_notes_loading: false}

    case CREATE_SITE_NOTES.LOADING:
      return {...state, add_notes_loading: true}
    case CREATE_SITE_NOTES.SUCCESS:
      return {
        ...state,
        add_notes_loading: false,
      }
    case CREATE_SITE_NOTES.ERROR:
      return {...state, add_notes_loading: false}

    case GET_SITE_NOTES.LOADING:
      return {...state, get_site_notes_loading: true}
    case GET_SITE_NOTES.SUCCESS:
      return {
        ...state,
        siteNotes: payload?.rows,
        siteNotesTotal: payload?.total,
        get_site_notes_loading: false,
      }

    case UPDATE_SITE_NOTES.LOADING:
      return {...state, update_site_notes_loading: true}
    case UPDATE_SITE_NOTES.SUCCESS:
      return {
        ...state,
        update_site_notes_loading: false,
      }
    case UPDATE_SITE_NOTES.ERROR:
      return {...state, update_site_notes_loading: false}

    case DELETE_SITE_NOTES.LOADING:
      return {...state, delete_site_notes_loading: true}

    case DELETE_SITE_NOTES.SUCCESS:
      return {
        ...state,
        delete_site_notes_loading: false,
      }
    case DELETE_SITE_NOTES.ERROR:
      return {...state, delete_site_notes_loading: false}

    case GET_PROJECT_NOTES.LOADING:
      return {...state, get_project_notes_loading: true}

    case GET_PROJECT_NOTES.SUCCESS:
      return {
        ...state,
        get_project_notes_loading: false,
        projectNotes: payload?.rows,
        projectNotesTotal: payload?.total,
      }
    case GET_PROJECT_NOTES.ERROR:
      return {...state, get_project_notes_loading: false}

    case CREATE_PROJECT_NOTES.LOADING:
      return {...state, get_project_notes_loading: true}

    case CREATE_PROJECT_NOTES.SUCCESS:
      return {
        ...state,
        get_project_notes_loading: false,
      }
    case CREATE_PROJECT_NOTES.ERROR:
      return {...state, get_project_notes_loading: false}
    case UPDATE_PROJECT_NOTES.LOADING:
      return {...state, update_project_notes_loading: true}

    case UPDATE_PROJECT_NOTES.SUCCESS:
      return {
        ...state,
        update_project_notes_loading: false,
      }
    case UPDATE_PROJECT_NOTES.ERROR:
      return {...state, update_project_notes_loading: false}

    case DELETE_PROJECT_NOTES.LOADING:
      return {...state, delete_project_notes_loading: true}

    case DELETE_PROJECT_NOTES.SUCCESS:
      return {
        ...state,
        delete_project_notes_loading: false,
      }
    case DELETE_PROJECT_NOTES.ERROR:
      return {...state, delete_project_notes_loading: false}

    case GET_PROJECT_HISTORY.LOADING:
      return {
        ...state,
        isProjectHistoryError: false,
        isProjectHistoryLoading: true,
      }

    case GET_PROJECT_HISTORY.SUCCESS:
      return {
        ...state,
        isProjectHistoryError: false,
        isProjectHistoryLoading: false,
        projectHistory: payload,
      }
    case GET_PROJECT_HISTORY.ERROR:
      return {
        ...state,
        isProjectHistoryError: true,
        isProjectHistoryLoading: false,
      }

    case GET_PROJECT_NOTE_BY_ID.LOADING:
      return {
        ...state,
        isProjectNoteByIdError: false,
        isProjectNoteByIdLoading: true,
      }
    case GET_PROJECT_NOTE_BY_ID.SUCCESS:
      return {
        ...state,
        isProjectNoteByIdError: false,
        isProjectNoteByIdLoading: false,
        projectNoteById: payload,
      }
    case GET_PROJECT_NOTE_BY_ID.ERROR:
      return {
        ...state,
        isProjectNoteByIdError: true,
        isProjectNoteByIdLoading: false,
        projectNoteById: undefined,
      }

    default:
      return state
  }
}
