import {useEffect, useMemo} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {getMaterialPurchaseOrderOfProjects} from 'redux-src'

import {DataTable} from 'app/components'
interface MaterialPurchaseListProps {
  supplierId: number
}

export const MaterialPurchaseList = ({
  supplierId
}: MaterialPurchaseListProps) => {
  const {
    materialPurchaseOrder,
    materialPurchaseOrderLoading,
    materialPurchaseOrderTotal
  }: RT.QuoteReduxType = useSelector((state: any) => state.quotes)

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const remappedMaterialPurchaseOrder = useMemo(() => {
    if (!!!materialPurchaseOrder) return []

    const remappedList = materialPurchaseOrder?.map(
      ({quote_material_element_details, supplier_details}) => {
        return {
          id: quote_material_element_details?.id,
          name: quote_material_element_details?.name,
          quantity: quote_material_element_details?.total_units,
          supplier: supplier_details?.name,
          unitName: quote_material_element_details?.unit_name,
          numberPerUnit: quote_material_element_details?.number_per_unit
        }
      }
    )

    return remappedList
  }, [materialPurchaseOrder])

  useEffect(() => {
    dispatch(
      getMaterialPurchaseOrderOfProjects(1, 10, projectId, supplierId, () => {})
    )
  }, [dispatch, projectId, supplierId])

  const fetchMaterialPurchaseOrderData = (page: number, limit: number) => {
    dispatch(
      getMaterialPurchaseOrderOfProjects(
        page,
        limit,
        projectId,
        supplierId,
        () => {}
      )
    )
  }

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row?.name,
      sortable: true,
      cell: (row: any) => (
        <span className="flex items-center justify-between py-4 mr-1  text-[#333] px-6 leading-none ">
          <span>{row?.name}</span>
        </span>
      )
    },
    {
      name: 'No. Per Unit',
      selector: (row: any) => row?.numberPerUnit,
      sortable: true,
      cell: (row: any) => (
        <span className="flex items-center gap-6 py-4 mr-1  text-[#333] px-6 leading-none ">
          <span>{row?.numberPerUnit}</span>
          <span className="italic">{row.unitName}</span>
        </span>
      )
    },
    {
      name: 'Quantity',
      selector: (row: any) => row?.quantity,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row?.quantity}
        </span>
      )
    },
    {
      name: 'Supplier',
      selector: (row: any) => row?.supplier,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row?.supplier}
        </span>
      )
    }
  ]
  return (
    <div>
      <DataTable
        columns={columns}
        data={remappedMaterialPurchaseOrder ?? []}
        pagination
        totalRows={materialPurchaseOrderTotal}
        loading={materialPurchaseOrderLoading}
        fetchData={fetchMaterialPurchaseOrderData}
      />
    </div>
  )
}
