import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAuth} from 'react-auth-navigation'
import {BiInfoCircle} from 'react-icons/bi'

import {DefaultButton, Modal, ToolTip} from 'app/common'
import {DataTable} from 'app/components/dataTable'
import {TableColumn, TableStyles} from 'react-data-table-component'
import {BsCloudDownload} from 'react-icons/bs'

interface OverviewModalProps {
  isModalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const QuoteOverviewModal = ({
  isModalOpen,
  setModalOpen,
}: OverviewModalProps) => {
  const {quoteOverviewDetails, getQuoteOverviewLoading}: RT.QuoteReduxType =
    useSelector((state: any) => state.quotes)

  const {currencySymbol, companyDetails: company} = useAuth()

  const companyDetails: Api.CompanyDetails = company

  const [overviewData, setOverviewData] = useState<any>()
  const [downloadOverviewData, setDownloadOverviewData] = useState<any>()

  const remappedOverviewDetails = useCallback(() => {
    if (!quoteOverviewDetails) return []

    const finalOverviewData = quoteOverviewDetails?.map(
      ({quote_item_details, line_item_list}) => {
        if (line_item_list === null || line_item_list === undefined) return []

        const remappedLineItem = line_item_list?.map((lineItems) => {
          return {
            id: quote_item_details?.id,
            itemType: quote_item_details?.title,
            itemName: lineItems?.name,
            quantity: lineItems?.quantity,
            rate: lineItems?.price.toFixed(2),
            grossTotal: lineItems?.taxable_cost.toFixed(2),
            discountTotal: Number(lineItems?.discount).toFixed(2),
            taxTotal: Number(lineItems?.tax).toFixed(2),
            total: Number(lineItems?.total_cost).toFixed(2),
            material: lineItems?.material_cost.toFixed(2),
            wastagePercentage: lineItems?.wastage_percent.toFixed(2),
            wastageValue: lineItems?.wastage_cost.toFixed(2),
            totalWastageValue: Number(
              lineItems?.quantity * lineItems?.wastage_cost,
            ).toFixed(2),
            fixings: lineItems?.fixing_cost.toFixed(2),
            fixingsTotal: Number(
              lineItems?.quantity * lineItems?.fixing_cost,
            ).toFixed(2),
            materialValue: Number(
              lineItems?.material_cost +
                lineItems?.wastage_cost +
                lineItems?.fixing_cost,
            ).toFixed(2),
            materialTotalValue: Number(
              lineItems?.quantity *
                (lineItems?.material_cost +
                  lineItems?.wastage_cost +
                  lineItems?.fixing_cost),
            ).toFixed(2),
            labourRate: lineItems?.labour_rate.toFixed(2),
            labourValue: lineItems?.labour_rate.toFixed(2),
            labourTotal: Number(
              lineItems?.quantity *
                Number(
                  !lineItems?.labour_rate || lineItems?.labour_rate === 0
                    ? 1
                    : lineItems?.labour_rate,
                ),
            ).toFixed(2),
            totalMaterialOrLabour: Number(
              lineItems?.material_cost +
                lineItems?.wastage_cost +
                lineItems?.fixing_cost +
                lineItems?.quantity *
                  Number(
                    !lineItems?.labour_rate || lineItems?.labour_rate === 0
                      ? 1
                      : lineItems?.labour_rate,
                  ),
            ).toFixed(2),
            overhead: lineItems?.overhead.toFixed(2),
            overheadTotal: Number(
              lineItems?.quantity * lineItems?.overhead,
            ).toFixed(2),
            profit: lineItems?.profit.toFixed(2),
            profitTotal: Number(
              lineItems?.quantity * lineItems?.profit,
            ).toFixed(2),

            grandTotal: Number(lineItems?.price).toFixed(2),
            labourDetails: lineItems?.labour_details,
            attributes: lineItems?.attributes,
            length: line_item_list?.length ?? 0,
            taxPercent: lineItems?.tax_percent.toFixed(2),
            discountPercent: lineItems?.discount_percent.toFixed(2),
          }
        })

        return remappedLineItem
      },
    )

    const data = finalOverviewData.flat()

    const filteredData = [...data]?.map((item) => {
      if (item?.labourDetails || item?.attributes || item?.length) {
        return {
          'Item Type': item?.itemType,
          'Item Name': item?.itemName,
          Quantity: item?.quantity,
          Rate: item?.rate,
          'Gross Total': item?.grossTotal,
          'Discount Total': Number(item?.discountTotal),
          'Tax Total': Number(item?.taxTotal),
          Total: Number(item?.total),
          Material: item?.material,
          'Wastage Percentage': item?.wastagePercentage,
          'Wastage Value': item?.wastageValue,
          'Total Wastage Value': item?.totalWastageValue,
          Fixings: item?.fixings,
          'Fixings Total': item?.fixingsTotal,
          'Material Value': item?.materialValue,
          'Material Total Value': item?.materialTotalValue,
          'Labour Rate': item?.labourRate,
          'Labour Value': item?.labourRate,
          'Labour Total': item?.labourTotal,
          'Total Material/Labour': item?.totalMaterialOrLabour,
          Overhead: item?.overhead,
          'Overhead Total': item?.overheadTotal,
          Profit: item?.profit,
          'Profit Total': item?.profitTotal,
          'Grand Total': Number(item?.grandTotal),
        }
      }
      return {}
    })

    setDownloadOverviewData(filteredData)
    return setOverviewData([...data] ?? [])
  }, [quoteOverviewDetails])

  useEffect(() => {
    remappedOverviewDetails()
  }, [remappedOverviewDetails])

  const columns: TableColumn<any>[] = [
    {
      name: 'Item Type',
      selector: (row) => row?.itemType,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row?.itemType}
        </span>
      ),
    },
    {
      name: 'Item Name',
      selector: (row) => row?.itemName,
      sortable: true,
      cell: (row: any, index: number) => (
        <span className="flex relative justify-between py-4 mr-1  text-[#333] px-6 leading-none ">
          <span>{row?.itemName}</span>
          <ToolTip
            top={index === row?.length - 1}
            content={
              <div className="flex flex-col items-start px-8 py-6">
                {Object.keys(row?.attributes ?? {}).map(
                  (key: string, index: number) => {
                    return (
                      <span key={index} className="flex gap-10">
                        <span>{key}</span> :<span>{row?.attributes[key]}</span>
                      </span>
                    )
                  },
                )}
              </div>
            }
          >
            <span className="font-bold cursor-pointer">
              <BiInfoCircle size={16} />
            </span>
          </ToolTip>
        </span>
      ),
    },
    {
      name: 'Quantity',
      selector: (row) => row?.quantity,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row?.quantity}
        </span>
      ),
    },
    {
      name: 'Rate',
      selector: (row) => row?.rate,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.rate}
        </span>
      ),
    },
    {
      name: 'Gross Total',
      selector: (row) => row?.grossTotal,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.grossTotal}
        </span>
      ),
    },
    {
      name: 'Discount Total',
      selector: (row) => row?.discountTotal,
      sortable: true,
      cell: (row) => (
        <span className="flex  items-center justify-between py-4 mr-1  text-[#333] px-6 leading-none ">
          <div>
            {currencySymbol} {row?.discountTotal}
          </div>
          <ToolTip
            text={`Discount (%) : ${row?.discountPercent}`}
            top
            style={{
              color: 'white',
              padding: '6px 8px',
            }}
          >
            <span className="font-bold cursor-pointer">
              <BiInfoCircle size={16} />
            </span>
          </ToolTip>
        </span>
      ),
    },
    {
      name: 'Tax Total',
      selector: (row) => row?.taxTotal,
      sortable: true,
      cell: (row) => (
        <span className="flex  items-center justify-between py-4 mr-1  text-[#333] px-6 leading-none ">
          <div>
            {currencySymbol} {row?.taxTotal}
          </div>

          <ToolTip
            text={`Tax (%) : ${row?.taxPercent}`}
            top
            style={{
              color: 'white',
              padding: '6px 8px',
            }}
          >
            <span className="font-bold cursor-pointer">
              <BiInfoCircle size={16} />
            </span>
          </ToolTip>
        </span>
      ),
    },
    {
      name: 'Total',
      selector: (row) => row?.total,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.total}
        </span>
      ),
    },
    {
      name: 'Material',
      selector: (row) => row?.material,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.material}
        </span>
      ),
    },
    {
      name: 'Wastage %',
      selector: (row) => row?.wastagePercentage,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row?.wastagePercentage} %
        </span>
      ),
    },
    {
      name: 'Wastage Value',
      selector: (row) => row?.wastageValue,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.wastageValue}
        </span>
      ),
    },
    {
      name: 'Total Wastage Value',
      selector: (row) => row?.totalWastageValue,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.totalWastageValue}
        </span>
      ),
    },
    {
      name: 'Fixings',
      selector: (row) => row?.fixings,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.fixings}
        </span>
      ),
    },
    {
      name: 'Fixings Total',
      selector: (row) => row?.fixingsTotal,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.fixingsTotal}
        </span>
      ),
    },
    {
      name: 'Material value',
      selector: (row) => row?.materialValue,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.materialValue}
        </span>
      ),
    },
    {
      name: 'Material total value',
      selector: (row) => row?.materialTotalValue,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.materialTotalValue}
        </span>
      ),
    },
    {
      name: 'Labour rate',
      selector: (row) => row?.labourRate,
      sortable: true,
      cell: (row) => (
        <span className="flex justify-between items-center py-4 mr-1  text-[#333] px-6 leading-none ">
          <span>
            {currencySymbol} {row?.labourRate}
          </span>
          {row.labourDetails !== null && (
            <ToolTip
              text={`${row?.labourDetails?.unit_name ?? ''} ${
                row?.labourDetails?.name
              }`}
              top
              style={{
                color: 'white',
                padding: '6px 8px',
              }}
            >
              <span className="font-bold cursor-pointer">
                <BiInfoCircle size={16} />
              </span>
            </ToolTip>
          )}
        </span>
      ),
    },
    {
      name: 'Labour value',
      selector: (row) => row?.labourValue,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.labourValue}
        </span>
      ),
    },
    {
      name: 'Labour total',
      selector: (row) => row?.labourTotal,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.labourTotal}
        </span>
      ),
    },
    {
      name: 'Total material/labour',
      selector: (row) => row?.totalMaterialOrLabour,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.totalMaterialOrLabour}
        </span>
      ),
    },
    {
      name: 'Overhead',
      selector: (row) => row?.overhead,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-black px-6 leading-none ">
          {currencySymbol} {row?.overhead}
        </span>
      ),
    },
    {
      name: 'Overhead total',
      selector: (row) => row?.overheadTotal,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-black px-6 leading-none ">
          {currencySymbol} {row?.overheadTotal}
        </span>
      ),
    },
    {
      name: 'Profit',
      selector: (row) => row?.profit,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-black px-6 leading-none ">
          {currencySymbol} {row?.profit}
        </span>
      ),
    },
    {
      name: 'Profit total',
      selector: (row) => row?.profitTotal,
      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-black px-6 leading-none ">
          {currencySymbol} {row?.profitTotal}
        </span>
      ),
    },

    {
      name: 'Grand Total',
      selector: (row) => row?.grandTotal,

      sortable: true,
      cell: (row) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {currencySymbol} {row?.grandTotal}
        </span>
      ),
    },
  ]

  const customStyles: TableStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        '&:nth-child(2)': {
          minWidth: '300px',
        },
        '&:nth-child(9)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(10)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(11)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(12)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(13)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(14)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(15)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(16)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(17)': {
          backgroundColor: '#ffc000',
        },
        '&:nth-child(18)': {
          backgroundColor: '#ffc000',
        },
        '&:nth-child(19)': {
          backgroundColor: '#ffc000',
        },
        '&:nth-child(20)': {
          backgroundColor: '#c88d04',
        },
        '&:nth-child(21)': {
          backgroundColor: '#a446ec',
        },
        '&:nth-child(22)': {
          backgroundColor: '#a446ec',
        },
        '&:nth-child(23)': {
          backgroundColor: '#a446ec',
        },
        '&:nth-child(24)': {
          backgroundColor: '#a446ec',
        },
        '&:last-child': {
          backgroundColor: '#92d050',
        },
      },
    },
    cells: {
      style: {
        fontWeight: 'bold',
        '&:nth-child(9)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(10)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(11)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(12)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(13)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(14)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(15)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(16)': {
          backgroundColor: '#ffff00',
        },
        '&:nth-child(17)': {
          backgroundColor: '#ffc000',
        },
        '&:nth-child(18)': {
          backgroundColor: '#ffc000',
        },
        '&:nth-child(19)': {
          backgroundColor: '#ffc000',
        },
        '&:nth-child(20)': {
          backgroundColor: '#c88d04',
        },
        '&:nth-child(21)': {
          backgroundColor: '#a446ec',
        },
        '&:nth-child(22)': {
          backgroundColor: '#a446ec',
        },
        '&:nth-child(23)': {
          backgroundColor: '#a446ec',
        },
        '&:nth-child(24)': {
          backgroundColor: '#a446ec',
        },
        '&:last-child': {
          backgroundColor: '#92d050',
        },
      },
    },
  }

  function convertArrayOfObjectsToCSV(array: any) {
    let result: any

    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const keys = Object.keys(downloadOverviewData?.at(0) ?? {})

    result = ''
    result += keys.join(columnDelimiter)
    result += lineDelimiter

    array.forEach((item: any) => {
      let ctr = 1
      keys.forEach((key) => {
        if (ctr > 1) result += columnDelimiter

        result += item[key]
        // eslint-disable-next-line no-plusplus
        ctr++
      })
      result += lineDelimiter
    })

    return result
  }

  const downloadCsv = (overviewData: any) => {
    const link = document.createElement('a')
    let csv = convertArrayOfObjectsToCSV(overviewData)
    if (csv == null) return

    const filename = 'export.csv'

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`
    }

    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
  }

  const donwloadOverviewAction = React.useMemo(
    () => (
      <DownloadOverview
        disabled={!downloadOverviewData?.at(0)}
        onExport={() => {
          downloadCsv(downloadOverviewData ?? [])
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadOverviewData],
  )

  return (
    <Modal
      title="Quote Overview"
      toggleModal={setModalOpen}
      isActive={isModalOpen}
      size="xl"
    >
      <div className="pb-20">
        <div className="flex gap-20 px-20 py-20 mb-[-45px]">
          <div>
            <span className="text-sm font-bold text-gray-400 ">
              Calculated Profit ( % ) :{'  '}
            </span>
            <span className="text-md font-bold text-black">
              {Number(
                companyDetails?.company_details?.predicted_profit_percent,
              )}
            </span>
          </div>
          <div>
            <span className="text-sm font-bold text-gray-400">
              Calculated OverHead ( % ) :{' '}
            </span>
            <span className="text-md font-bold text-black">
              {Number(
                companyDetails?.company_details?.predicted_overhead_percent,
              )}
            </span>
          </div>
        </div>

        <div className="px-20">
          <DataTable
            data={overviewData ?? []}
            columns={columns}
            loading={getQuoteOverviewLoading}
            customStyles={customStyles}
            actions={donwloadOverviewAction}
          />
        </div>
      </div>
    </Modal>
  )
}

export const DownloadOverview = ({
  onExport,
  disabled,
}: {
  onExport: () => void
  disabled: boolean
}) => {
  return (
    <DefaultButton
      title="Download Quote Overview"
      leftIcon={<BsCloudDownload />}
      className="w-[max-content] hover:bg-gray-200 bg-white text-black h-[30px]"
      onClick={() => onExport()}
      disabled={disabled}
    />
  )
}
