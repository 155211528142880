import {useEffect} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {HiArrowLeft} from 'react-icons/hi'

import {Button} from 'app/common'
import {
  getProjectFormDetailsByProjectFormId,
  updateFormFromApi
} from 'redux-src'
import {useDispatch, useSelector} from 'react-redux'
import {FormSectionPreview} from 'app/pages/settings/pages/forms/components/formBuilder'

export const ProjectFormDetailsPage = () => {
  const {projectDetailsByProjectFormIdData}: RT.FormsReduxType = useSelector(
    (state: any) => state.forms
  )

  const {formsForDesktopPreview}: RT.FormBuilderReduxType = useSelector(
    (state: any) => state.formBuilder
  )

  console.log({formsForDesktopPreview})

  const {
    navigation: {navigate},
    params
  } = useNavigation()

  const {projectId, projectFormId} = params as any
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getProjectFormDetailsByProjectFormId(projectId, projectFormId, (form) => {
        dispatch(updateFormFromApi(form))
      })
    )
  }, [dispatch, projectFormId, projectId])

  return (
    <div className="flex flex-col gap-10">
      <div>
        <div className="flex items-center justify-between">
          <h2
            className="text-sm flex items-centerw-[150px] mb-10 cursor-pointer"
            style={{color: 'blue'}}
            onClick={() => navigate(`/projects/${projectId}/forms`)}
          >
            <span className="mr-10 mt-[3px]">
              <HiArrowLeft />
            </span>
            Back to forms
          </h2>
        </div>
      </div>
      <div className="flex flex-col gap-10 ">
        <div className="flex justify-end">
          <Button
            size="md"
            title="Edit Form"
            onClick={() => {
              navigate(
                `/settings/forms/edit/${projectDetailsByProjectFormIdData?.project_form_details?.form_id}`
              )
            }}
            className="w-[120px] flex items-center justify-center"
          />
        </div>

        <div className="flex flex-col border border-t-1 border-gray-200 pb-60 px-10">
          <div className="flex flex-1 flex-col gap-8 pr-20">
            <div className="text-2xl font-bold mb-8 mt-10 text-gray-600">
              {
                projectDetailsByProjectFormIdData?.project_form_details
                  ?.form_name
              }
            </div>

            <div className="flex flex-col flex-1 gap-8 mb-20">
              {formsForDesktopPreview?.length > 0 &&
                formsForDesktopPreview?.map((fm, index) => {
                  return (
                    <div
                      key={`form-preview-item-container-${index}`}
                      id={`form-preview-item-container-${index}`}
                      className={`flex flex-row gap-8 w-full`}
                    >
                      {fm?.map((form) => {
                        return (
                          <FormSectionPreview
                            key={form.id}
                            formType={form?.formType}
                            id={form?.id}
                            type={'preview'}
                          />
                        )
                      })}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
