import {useEffect, useState} from 'react'
import {TableColumn} from 'react-data-table-component'
import {DataTable} from 'app/components'
import {useAuth} from 'react-auth-navigation'
import moment from 'moment'

export function CostReportTable({
  tableType,
  data,
  onLimitChange,
}: {
  tableType: 'material' | 'labour' | 'item'
  data: Array<Api.CostReportRows>
  onLimitChange: (page: number, limit: number) => void
}) {
  const {currencySymbol} = useAuth()

  const columns: TableColumn<Api.CostReportRows>[] = [
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Site Visit
        </div>
      ),
      cell: (row) => (
        <div className="flex badge items-center">
          <div className="text-blue-300">{row?.site_visit_title}</div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Name
        </div>
      ),
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center">
          <div className="text-blue-300">{row?.name}</div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Total Cost
        </div>
      ),
      selector: (row) => row.total_cost,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol} {row?.total_cost?.toFixed(2)}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Completed Date
        </div>
      ),
      selector: (row) => row.completed_at,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">
          {row.completed_at === null
            ? 'N/A'
            : moment(row.completed_at).format('ddd, DD MMM YYYY, hh:mm a')}
        </div>
      ),
    },
  ]

  return (
    <div>
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16 -mx-16 capitalize">
        {tableType} Table
      </h3>

      <div className="-mx-16">
        <DataTable
          pagination
          fetchData={onLimitChange}
          columns={columns}
          data={data}
        />
      </div>
    </div>
  )
}

export function ItemTable({
  data,
  siteVisitName,
}: {
  data: Array<{
    id?: number | string
    name: string
    quantity: number
    unit_cost: number
    total_cost?: number
  }>
  siteVisitName: string
}) {
  const {currencySymbol} = useAuth()

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    let totalUnitCost = 0,
      totalQuantity = 0,
      totalTotalCost = 0

    data.forEach((d) => {
      totalUnitCost += d.unit_cost
      totalQuantity += d.quantity
      totalTotalCost += d.unit_cost * d.quantity
    })

    setTableData([
      ...data,
      {
        name: 'Total',
        quantity: totalQuantity,
        unit_cost: totalUnitCost,
        total_cost: totalTotalCost,
      },
    ])
  }, [data])

  const columns: TableColumn<{
    name: string
    quantity: number
    unit_cost: number
    total_cost?: number
  }>[] = [
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Site Visit
        </div>
      ),
      cell: () => (
        <div className="flex badge items-center">
          <div className="text-blue-300">{siteVisitName}</div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Name
        </div>
      ),
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center">
          <div className="text-blue-300">{row.name}</div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Quantity
        </div>
      ),
      selector: (row) => row.quantity,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">{row.quantity}</div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Unit Cost
        </div>
      ),
      selector: (row) => row.unit_cost,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.unit_cost}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Total Cost
        </div>
      ),
      selector: (row) => row.unit_cost * row.quantity,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}
          {row.total_cost ?? row.unit_cost * row.quantity}
        </div>
      ),
    },
  ]

  return (
    <div>
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16 -mx-16">
        Item Table
      </h3>

      <div className="-mx-16">
        <DataTable pagination columns={columns} data={tableData} />
      </div>
    </div>
  )
}

export function MaterialTable({
  data,
  siteVisitName,
}: {
  data: Array<{
    id?: number | string
    name: string
    quantity: number
    unit_name: string
    member_per_unit: number
    cost: number
    total_cost?: number
  }>
  siteVisitName: string
}) {
  const {currencySymbol} = useAuth()

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    let totalQuantity = 0,
      totalMemberPerUnit = 0,
      totalCost = 0,
      totalTotalCost = 0

    data.forEach((d) => {
      totalMemberPerUnit += d.member_per_unit
      totalCost += d.cost
      totalQuantity += d.quantity
      totalTotalCost += d.cost * d.quantity
    })

    setTableData([
      ...data,
      {
        id: '---',
        name: 'Total',
        quantity: totalQuantity,
        cost: totalCost,
        unit_name: '---',
        member_per_unit: totalMemberPerUnit,
        total_cost: totalTotalCost,
      },
    ])
  }, [data])

  const columns: TableColumn<{
    id: number | string
    name: string
    quantity: number
    unit_name: string
    member_per_unit: number
    cost: number
    total_cost?: number
  }>[] = [
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Site Visit
        </div>
      ),
      cell: () => (
        <div className="flex badge items-center">
          <div className="text-blue-300">{siteVisitName}</div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Name
        </div>
      ),
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center">
          <div className="text-blue-300">{row.name}</div>
        </div>
      ),
    },

    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Unit Name
        </div>
      ),
      selector: (row) => row.unit_name,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">{row.unit_name}</div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Quantity
        </div>
      ),
      selector: (row) => row.quantity,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">{row.quantity}</div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Member Per Unit
        </div>
      ),
      selector: (row) => row.member_per_unit * row.quantity,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">{row.member_per_unit}</div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Cost
        </div>
      ),
      selector: (row) => row.cost,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">
          {' '}
          {currencySymbol} {row.cost}
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Total Cost
        </div>
      ),
      selector: (row) => row.cost * row.quantity,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">
          {currencySymbol}{' '}
          {row.total_cost === undefined
            ? row.quantity * row.cost
            : row.total_cost}
        </div>
      ),
    },
  ]

  return (
    <div>
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16 -mx-16">
        Material Table
      </h3>
      <div className="-mx-16">
        <DataTable pagination columns={columns} data={tableData} />
      </div>{' '}
    </div>
  )
}

export function LabourTable({
  data,
  siteVisitName,
}: {
  data: Array<{
    name: string
    unit_name: string
    time_mins: number
    no_per_unit: number
  }>
  siteVisitName: string
}) {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    let totalNumberPerUnit = 0,
      totalTimeMins = 0

    data.forEach((d) => {
      totalTimeMins += d.time_mins
      totalNumberPerUnit += d.no_per_unit
    })

    setTableData([
      ...data,
      {
        name: 'Total',
        unit_name: '---',
        time_mins: totalTimeMins,
        no_per_unit: totalNumberPerUnit,
      },
    ])
  }, [data])

  const columns: TableColumn<{
    name: string
    unit_name: number
    time_mins: number
    no_per_unit: number
  }>[] = [
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Site Visit
        </div>
      ),
      cell: (row) => (
        <div className="flex badge items-center">
          <div className="text-blue-300">{siteVisitName}</div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Name
        </div>
      ),
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center">
          <div className="text-blue-300">{row.name}</div>
        </div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Unit Name
        </div>
      ),
      selector: (row) => row.unit_name,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">{row.unit_name}</div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Time Mins
        </div>
      ),
      selector: (row) => row.time_mins,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">{row.time_mins}</div>
      ),
    },
    {
      name: (
        <div className="flex justify-start items-center h-[60px] overflow-hidden text-clip whitespace-wrap w-full">
          Number per unit
        </div>
      ),
      selector: (row) => row.no_per_unit,
      sortable: true,
      cell: (row) => (
        <div className="text-sm font-light px-6 ">{row.no_per_unit}</div>
      ),
    },
  ]

  return (
    <div>
      <h3 className="font-bold text-md border-y-1 border-y-gray-200 py-12 px-16 -mx-16">
        Labour Table
      </h3>
      <div className="-mx-16">
        <DataTable pagination columns={columns} data={tableData} />
      </div>{' '}
    </div>
  )
}
