import {useAuth, useNavigation} from 'react-auth-navigation'
import React, {useEffect, useState} from 'react'
import {IoMdClose} from 'react-icons/io'
import clsx from 'clsx'
import {useDispatch} from 'react-redux'
import {FaTrash} from 'react-icons/fa'
import {camelCase} from 'change-case'

import {Button, Textarea, ToolTip} from 'app/common'

import {
  createNewInvoiceLineItemAction,
  deleteInvoiceItemAction,
  deleteInvoiceLineItemAction,
  updateInvoiceItemAction,
  updateInvoiceLineItemAction
} from 'redux-src'

import {LineItemInput} from '../../../../../../../../components/invoiceEditor/components/sectionCard/components'
import {QUOTE_TABLE_HEADINGS} from '../../constants'
import {AddLineItem, RemainingTicketModal} from './components'
import {BiImport} from 'react-icons/bi'

export const SectionCard = ({
  sectionDetail,
  onRemoveSection,
  sectionList,
  // setSectionList,
  invoiceDetail,
  sectionIndex,
  fetchInvoiceItem,
  invoiceId
}: {
  sectionDetail?: Api.InvoiceItemDetails
  onRemoveSection: (arg: any) => void
  sectionList: any
  setSectionList?: (a: any) => void
  invoiceDetail: any
  sectionIndex: number
  fetchInvoiceItem?: () => void
  invoiceId?: number
}) => {
  const [sectionTitle, setSectionTitle] = useState<string>()
  const [description, setDescription] = useState<string>()

  const [siteVisitDetails, setSiteVisitDetails] = useState<any>()
  const [openTicketModal, setOpenTicketModal] = useState<boolean>(false)

  const dispatch = useDispatch()
  const {currencySymbol} = useAuth()

  const {
    params,
    navigation: {navigate}
  } = useNavigation()

  const {projectId, invoiceId: invoiceIdParams} = params as any

  const onAddLineItemHandler = (body: any, callback?: () => void) => {
    callback && callback()
    dispatch(
      createNewInvoiceLineItemAction(
        projectId,
        invoiceIdParams,
        sectionDetail?.invoice_item_details?.id,
        body?.id,
        () => {
          fetchInvoiceItem()
        }
      )
    )
  }

  type LineItemKeys = 'title' | 'taxPercent' | 'quantity' | 'discountPercent'

  const onUpdateLineItemHandler = (
    key: LineItemKeys,
    value: any,
    item: any
  ) => {
    if (+value === +item[key]) return

    const body = {
      quantity: item.quantity,
      discountPercent: item.discount_percent,
      taxPercent: item.tax_percent,
      title: item.title
    }

    body[key] = value

    dispatch(
      updateInvoiceLineItemAction(
        projectId,
        invoiceIdParams,
        sectionDetail?.invoice_item_details?.id,
        item.id,
        body,
        () => {
          fetchInvoiceItem()
        }
      )
    )
  }

  const onRemoveLineItemHandler = (lineItemId: number) => {
    dispatch(
      deleteInvoiceLineItemAction(
        projectId,
        invoiceIdParams,
        sectionDetail?.invoice_item_details?.id,
        lineItemId,
        () => {
          fetchInvoiceItem()
        }
      )
    )
  }

  const onRemoveItemHandler = () => {
    invoiceIdParams &&
      sectionDetail?.invoice_item_details?.id &&
      dispatch(
        deleteInvoiceItemAction(
          projectId,
          invoiceIdParams,
          sectionDetail?.invoice_item_details?.id,
          () => {
            fetchInvoiceItem()
          }
        )
      )
  }

  const onUpdateSectionTitle = (e: any) => {
    e.preventDefault()
    if (
      sectionDetail?.invoice_item_details?.title !== sectionTitle ||
      description !== sectionDetail?.invoice_item_details?.description
    ) {
      const body = {
        title: sectionTitle,
        description:
          description?.length === 0 || !!!description ? undefined : description
      }
      dispatch(
        updateInvoiceItemAction(
          projectId,
          invoiceIdParams,
          sectionDetail?.invoice_item_details?.id,
          body,
          () => {
            fetchInvoiceItem()
          }
        )
      )
    }
  }

  useEffect(() => {
    if (!!sectionDetail) {
      setSectionTitle(sectionDetail?.invoice_item_details?.title)
      setDescription(sectionDetail?.invoice_item_details?.description)
    }
  }, [sectionDetail])

  return (
    <div className="my-6">
      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex flex-1 gap-10">
            <input
              className="w-1/2 rounded-sm text-xl border-1 border-gray-200 shadow-sm font-bold mb-12 px-6"
              value={sectionTitle}
              onChange={(e: any) => setSectionTitle(e.target.value)}
              onBlur={onUpdateSectionTitle}
            />
            <ToolTip text="Delete Item">
              <div
                className="flex items-center justify-center border-1 border-gray-200 rounded-sm px-6 py-6 cursor-pointer"
                onClick={() => {
                  onRemoveItemHandler()
                }}
              >
                <FaTrash
                  size={18}
                  color="gray"
                  className="hover:text-red-300 hover:transition-all hover:duration-150 hover:ease-in-out"
                />
              </div>
            </ToolTip>
          </div>

          <ToolTip
            text="Import Tickets"
            className="flex w-[max-content] justify-end items-center"
          >
            <div
              className="px-8 py-6 flex flex-1 justify-end rounded-md w-[max-content] bg-blue-200 cursor-pointer hover:bg-blue-300 hover:transition-all hover:ease-in-out hover:duration-150"
              onClick={() => {
                setOpenTicketModal((prev) => !prev)
                setSiteVisitDetails(sectionDetail?.site_visit_details)
              }}
            >
              <BiImport size={22} color={'white'} />
            </div>
          </ToolTip>
        </div>
        <div className="flex items-center justify-between">
          {!!sectionDetail?.site_visit_details && (
            <div className="flex flex-row gap-6 items-center">
              <div className="text-black font-bold">
                Imported From SiteVisit :
              </div>
              <ToolTip
                text="Navigate to sitevisit"
                top
                className="w-[max-content]"
              >
                <span
                  className="text-md text-blue-200 font-bold w-[max-content] cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/projects/${projectId}/site-visit/${sectionDetail?.site_visit_details?.id}/scheduling`
                    )
                  }}
                >
                  {sectionDetail?.site_visit_details?.title}
                </span>
              </ToolTip>
            </div>
          )}
        </div>
      </div>

      <Textarea
        className="text-sm mt-16 mb-16"
        placeholder="Click here to enter description"
        size="sm"
        value={description}
        onChange={(e) => {
          setDescription(e.target.value)
        }}
        onBlur={onUpdateSectionTitle}
      />

      <AddLineItem
        onAddLineItem={onAddLineItemHandler}
        fetchInvoiceItem={fetchInvoiceItem}
        sectionDetail={sectionDetail}
      />

      <div className="mt-8">
        {sectionDetail?.invoice_line_items?.length > 0 && (
          <>
            {sectionDetail?.invoice_line_items?.map((item: any, i: any) => {
              const lineItem = Object.keys(item ?? {})

              if (item === null)
                return <React.Fragment key={i}> </React.Fragment>
              return (
                <div className="pt-4" key={item.id}>
                  <div className="w-full flex">
                    <div className="flex justify-center items-center mr-6">
                      <input type="checkbox" disabled />
                    </div>
                    {[
                      'title',
                      'quantity',
                      'price',
                      'tax_percent',
                      'discount_percent',
                      'total_cost'
                    ].map((key, index) => (
                      <LineItemInput
                        key={index}
                        width={
                          QUOTE_TABLE_HEADINGS?.find(
                            (heading) => heading?.key === key
                          )?.width
                        }
                        type={key === 'title' ? 'string' : 'number'}
                        inputType={
                          key === 'quantity'
                            ? 'number'
                            : key === 'title'
                            ? 'text'
                            : 'decimal'
                        }
                        value={String(
                          item?.[key as keyof Api.LineItemIndividual]
                        )}
                        disabled={true}
                        className={clsx(
                          index === 0
                            ? 'text-left'
                            : index === lineItem?.length - 1
                            ? 'text-right'
                            : 'text-center',
                          QUOTE_TABLE_HEADINGS?.find(
                            (heading) => heading?.key === key
                          )?.hidden && 'text-gray-300'
                        )}
                        onUpdate={(text: any) =>
                          onUpdateLineItemHandler(
                            camelCase(key) as LineItemKeys,
                            text,
                            item
                          )
                        }
                      />
                      // <div
                      //   style={{
                      //     width: QUOTE_TABLE_HEADINGS?.find(
                      //       (heading) => heading?.key === key
                      //     )?.width
                      //   }}
                      // >
                      //   {String(item?.[key])}
                      // </div>
                    ))}

                    <div
                      className="mx-4 cursor-pointer"
                      onClick={() => onRemoveLineItemHandler(item.id)}
                    >
                      <IoMdClose />
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="flex flex-row justify-between mt-10">
              <div></div>
              <div
                style={{width: '11%'}}
                className="flex flex-row justify-center"
              >
                <div className="font-bold text-sm ">
                  {currencySymbol}{' '}
                  {Number(
                    sectionDetail?.invoice_line_items?.reduce(
                      (acc: any, o) => acc + Number(o?.total_cost),
                      0
                    )
                  ).toFixed(2) ?? '0.0'}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {openTicketModal && (
        <RemainingTicketModal
          siteVisitDetails={siteVisitDetails}
          setSiteVisitDetails={setSiteVisitDetails}
          openModal={openTicketModal}
          setOpenModal={setOpenTicketModal}
          sectionDetail={sectionDetail}
        />
      )}
    </div>
  )
}
