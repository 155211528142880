import {useEffect, useState} from 'react'

interface LineItemInputProps {
  width?: string | number
  value: string | number
  className?: string
  disabled?: boolean
  onUpdate?: (a: any) => void
  type?: string
  minNumber?: number
}

export const LineItemInput = ({
  width,
  className,
  value,
  onUpdate,
  disabled,
  type,
  minNumber,
  ...rest
}: LineItemInputProps) => {
  const [inputText, setInputText] = useState<string | number>()
  const onBlurHandler = (e: any) => {
    e.preventDefault()
    if (value !== inputText) {
      // // console.log('inputText', inputText)
      onUpdate(inputText)
    }
  }

  useEffect(() => {
    setInputText(value)
  }, [value])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onBlurHandler(e)
      }}
    >
      <input
        name="sectionItemName"
        className={`flex items-center text-sm border-b-1 border-dotted border-white hover:border-gray-300  ${className}`}
        style={{
          width
        }}
        {...rest}
        value={inputText}
        onFocus={(e) => e.target.select()}
        onChange={(e) => setInputText(e.target.value)}
        onBlur={onBlurHandler}
        disabled={disabled}
        type={type}
        min={minNumber}
        step={1}
      />
    </form>
  )
}
