import {getParsedUrl} from 'helpers'
import {
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_UNREAD,
} from './actionTypes.action'
import {APIS, api} from 'config'
import {errorHandler} from 'utils'
import {toast} from 'app/common'

export const showOnlyUnread = (showOnlyUnread: boolean = false) => {
  return async (dispatch: any) => {
    dispatch({type: 'NOTIFICATION_FETCH_ONLY_UNREAD', payload: showOnlyUnread})
  }
}

export const setNotificationLimit = (limit: number = 10) => {
  return async (dispatch: any) => {
    dispatch({type: 'NOTIFICATION_SET_FETCH_LIMIT', payload: limit})
  }
}

export const getNotifications = (
  params: {
    page?: number
    limit?: number
    isRead?: boolean
    search?: string
  } = {page: 1, limit: 10},

  callback?: (...args: any[]) => void,
) => {
  return async (dispatch: any, getState: any) => {
    dispatch({type: GET_NOTIFICATIONS.LOADING})

    let notif_res
    try {
      const api_url = getParsedUrl(`${APIS.notification}`, {...params})
      notif_res = await api<Api.Base<Api.NotificationList>>(api_url)
      if (notif_res?.data !== undefined) {
        // Notification fetched successfully
        const {
          success,
          data: {data},
        } = notif_res.data
        if (success) {
          dispatch({
            type: GET_NOTIFICATIONS.SUCCESS,
            payload: {
              ...data,
              rows:
                params?.limit === 0
                  ? getState()?.notifications?.notifications ?? []
                  : data?.rows,
            },
          })
          callback?.()
        }
      } else {
        errorHandler(notif_res.data, toast)
        throw new Error('NOTIFICATION_ERROR')
      }
    } catch (e) {
      dispatch({type: GET_NOTIFICATIONS.ERROR})
    }
  }
}

export const markNotificatonAsRead = (
  notificationId: number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    dispatch({type: MARK_NOTIFICATION_AS_READ.LOADING})
    let res
    try {
      res = await api<Api.Base<{}>>(
        `${APIS.notification}/${notificationId}/mark-as-read`,
        'patch',
      )

      if (res.data !== undefined) {
        const {success} = res.data
        if (success) {
          dispatch({type: MARK_NOTIFICATION_AS_READ.SUCCESS})
          callback?.()
        } else {
          errorHandler(res.data, toast)
          throw new Error('MARK_NOTIFICATION_AS_READ ERROR')
        }
      }
    } catch (e) {
      dispatch({type: MARK_NOTIFICATION_AS_READ.ERROR})
    }
  }
}

export const markNotificatonAsUnread = (
  notificationId: number,
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    dispatch({type: MARK_NOTIFICATION_AS_UNREAD.LOADING})
    let res
    try {
      res = await api<Api.Base<{}>>(
        `${APIS.notification}/${notificationId}/mark-as-unread`,
        'patch',
      )

      if (res.data !== undefined) {
        const {success} = res.data
        if (success) {
          dispatch({type: MARK_NOTIFICATION_AS_UNREAD.SUCCESS})
          callback?.()
        } else {
          errorHandler(res.data, toast)
          throw new Error('MARK_NOTIFICATION_AS_UNREAD ERROR')
        }
      }
    } catch (e) {
      dispatch({type: MARK_NOTIFICATION_AS_UNREAD.ERROR})
    }
  }
}

export const markAllNotificatonAsRead = (callback?: () => void) => {
  return async (dispatch: any) => {
    dispatch({type: MARK_ALL_NOTIFICATION_AS_READ.LOADING})
    let res
    try {
      res = await api<Api.Base<{}>>(
        `${APIS.notification}/mark-all-as-read`,
        'patch',
      )

      if (res.data !== undefined) {
        const {success} = res.data
        if (success) {
          dispatch({type: MARK_ALL_NOTIFICATION_AS_READ.SUCCESS})
          callback?.()
        } else {
          errorHandler(res.data, toast)
          throw new Error('MARK_NOTIFICATION_AS_READ ERROR')
        }
      }
    } catch (e) {
      dispatch({type: MARK_ALL_NOTIFICATION_AS_READ.ERROR})
    }
  }
}
