import React, {useEffect, useMemo, useState} from 'react'
import {AiOutlineArrowLeft} from 'react-icons/ai'
import {useNavigation} from 'react-auth-navigation'

import {
  SelectedMaterialElementList,
  SelectedMaterialElementsForGroup,
} from '../component'
import {Button, Input, Modal} from 'app/common'

import {useDispatch, useSelector} from 'react-redux'
import {
  attachMaterialElementAction,
  getMaterialElementGroupById,
  updateMaterialElementGroup,
} from 'redux-src'
import {useFormInput} from 'use-form-input'
import {DynamicSearchSelect} from 'app/components'

export const MaterialElementGroupPage = () => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  const {
    materialElementGroupById: individualMaterialGroup,
  }: RT.MaterialElementReduxType = useSelector(
    (state: any) => state.materialElement,
  )

  const {
    navigation: {navigate},
    params: {materialElementGroupId},
  }: any = useNavigation()

  const dispatch = useDispatch()

  const groupMaterialList = useMemo(() => {
    if (!!!individualMaterialGroup) {
      return []
    }

    const materialElementDetails =
      individualMaterialGroup?.material_elements?.map(
        ({details, total_units}) => {
          return {
            id: details?.id,
            label: details?.name,
            value: total_units,
            cost: details?.cost,
            totalCost: Number(
              (total_units ?? 1) * (details?.cost ?? 0),
            ).toFixed(2),
            numberPerUnit: details?.number_per_unit,
            unit: details?.unit_name,
            totalUnits: total_units,
          }
        },
      )

    return materialElementDetails
  }, [individualMaterialGroup])

  useEffect(() => {
    dispatch(getMaterialElementGroupById(materialElementGroupId))
  }, [dispatch, materialElementGroupId])

  return (
    <div className="min-h-[85vh] py-10 flex flex-col gap-6">
      <div className="border border-b-1 pb-4 border-gray-200 flex flex-col gap-10">
        <div
          className="flex text-blue-300 items-center gap-6 pb-2 cursor-pointer "
          onClick={() => navigate('/settings/material-list')}
        >
          <span>
            <AiOutlineArrowLeft />
          </span>
          <span>Back to material group list</span>
        </div>
        <div className=" flex justify-between text-lg font-bold text-black">
          <span>
            {individualMaterialGroup &&
              individualMaterialGroup?.material_element_group_details.name}
          </span>
          <div className="pb-4">
            <Button
              size="sm"
              title="Update Material Element Group"
              onClick={() => {
                setModalVisible(true)
              }}
              className="text-md"
            />
          </div>
        </div>
      </div>
      <div className="text-gray-400 my-10">
        {individualMaterialGroup &&
          individualMaterialGroup?.material_element_group_details.description}
      </div>

      <SelectedMaterialElementList
        key={'Material Element group details Page'}
        materialElements={groupMaterialList}
        isGroupPage
      />

      <AddMaterialElementToGroupModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
      />
    </div>
  )
}

interface AddMaterialElementToGroupModalProps {
  isModalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const AddMaterialElementToGroupModal = ({
  isModalVisible,
  setModalVisible,
}: AddMaterialElementToGroupModalProps) => {
  const {
    materialElementGroupById: groupDetailsFromApi,
    updateMaterialElementLoading,
  }: RT.MaterialElementReduxType = useSelector(
    (state: any) => state.materialElement,
  )

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const {materialElementGroupId} = params as any

  const [data, {onChange, onSubmit, clear, errors, modified, setValue}] =
    useFormInput<{
      groupName: string
      description: string
      materialElements: Array<SelectedMaterialElementsForGroup>
      defaultElementsId: undefined
    }>(
      {
        groupName: '',
        description: '',
        materialElements: undefined,
        defaultElementsId: undefined,
      },
      () => {
        dispatch(
          updateMaterialElementGroup(
            materialElementGroupId,
            {
              name: data.groupName,
              description: data.description,
            },
            () => {
              setModalVisible(false)
              dispatch(getMaterialElementGroupById(materialElementGroupId))
              dispatch(
                attachMaterialElementAction(
                  materialElementGroupId,
                  {
                    materialElements: data?.materialElements
                      ?.filter((item) => !item?.disabled)
                      ?.map((item) => {
                        return {
                          id: item?.id,
                          totalUnits: item?.value,
                        }
                      }),
                  },
                  () => {
                    clear()
                    setModalVisible(false)
                    setValue('materialElements', undefined)
                    dispatch(
                      getMaterialElementGroupById(materialElementGroupId),
                    )
                  },
                ),
              )
            },
          ),
        )
      },
      (data) => {
        const errors: any = {}

        if (data.materialElements?.length === 0) {
          errors.materialElements =
            'Please select at least one material element!'
        }

        return errors
      },
    )

  const onQuantityChangeHandler = (id: number, value: number) => {
    const newMaterialElements = [...data?.materialElements]

    const remappedMaterialElements = newMaterialElements.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value,
          totalCost: Number((value ?? 1) * item.cost).toFixed(2),
        }
      } else return {...item}
    })

    setValue('materialElements', remappedMaterialElements)
  }

  useEffect(() => {
    if (!!groupDetailsFromApi) {
      setValue(
        'groupName',
        groupDetailsFromApi?.material_element_group_details?.name,
      )
      setValue(
        'description',
        groupDetailsFromApi?.material_element_group_details?.description,
      )

      setValue(
        'materialElements',
        groupDetailsFromApi?.material_elements?.map(
          ({details, total_units}) => {
            return {
              id: details?.id,
              label: details?.name,
              value: total_units === 0 ? 1 : total_units,
              disabled: true,
              cost: details?.cost ?? 0,
              totalCost: Number(
                (total_units ?? 1) * (details?.cost ?? 0),
              ).toFixed(2),
              numberPerUnit: details?.number_per_unit ?? 0,
              unit: details?.unit_name,
            }
          },
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupDetailsFromApi])

  const onCloseModal = () => {
    clear()
  }

  return (
    <Modal
      onClose={onCloseModal}
      isActive={isModalVisible}
      toggleModal={setModalVisible}
      title={'Update Material Element Group'}
      size="lg"
    >
      <form id="add-material-group" onSubmit={onSubmit}>
        <div className="pt-[18px] px-[20px]">
          <label htmlFor="groupName" className="flex flex-col gap-6">
            <Input
              type="text"
              name="groupName"
              value={data.groupName}
              error={modified.groupName && errors.groupName}
              onChange={onChange}
              placeholder="Group Name"
              label={'Group Name'}
              required
            />
          </label>
          <label htmlFor="description" className="flex flex-col gap-6">
            <Input
              type="text"
              name="description"
              value={data.description}
              error={modified.description && errors.description}
              onChange={onChange}
              placeholder="Description"
              label={'Description'}
              required
            />
          </label>
        </div>
        <div className="flex flex-col gap-6 px-[20px]">
          <span className="text-black font-bold text-sm">
            Material Elements <span className="text-red-300 pl-4">*</span>
          </span>
          <DynamicSearchSelect
            actionUrl={`/material-elements`}
            multiValue={data?.materialElements ?? undefined}
            remapOptions={(data: Api.MaterialElementIndividual[]) => {
              return data?.map(({material_element_details}) => {
                return {
                  id: material_element_details?.id,
                  label: material_element_details?.name,
                  value: material_element_details?.total_units ?? 1,
                  cost: material_element_details?.cost ?? 0,
                  totalCost: Number(
                    (material_element_details?.total_units ?? 1) *
                      (material_element_details?.cost ?? 0),
                  ).toFixed(2),
                  numberPerUnit: material_element_details?.number_per_unit ?? 0,
                  unit: material_element_details?.unit_name,
                }
              })
            }}
            onMultiChangeValue={(data) => {
              setValue('materialElements', data)
            }}
            isMulti
          />
          {!!data?.materialElements && data?.materialElements.length > 0 && (
            <div className="mt-10">
              <SelectedMaterialElementList
                materialElements={data?.materialElements}
                onChangeHandler={onQuantityChangeHandler}
                isUpdate
              />
            </div>
          )}
        </div>

        <div className="border border-t-1 border-gray-200 px-20 py-12 mt-10">
          <div className="flex justify-end gap-8">
            <Button
              className="bg-red-300 px-20 py-4 rounded-sm border-1 border-gray-200 cursor-pointer text-black hover:bg-red-400"
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              form="add-material-group"
              type="submit"
              loading={updateMaterialElementLoading}
              className="bg-blue-200 px-20 py-4 rounded-sm text-gray-200 border-1 border-gray-200 cursor-pointer"
            >
              {'Update'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
