export const colors = {
	light: {
		white: '#ffffff',
		black: '#000000',
		teal: '#3180a9',
		orange: '#ff8144',
		green: '#5b9540',
		purple: '#a259f4',
		red: '#ff4343',
		blue: '#2480f5',

		// Black variants
		black100: '#13293D',
		black200: '#484451',

		// Grey variants
		grey100: '#a1a1a1',
		grey200: '#e1e1e1',
		grey300: '#f1f1f1',
		grey400: '#f8f8f8',

		// Primary variants
		primary100: '#004b7e',
		primary200: '#0078c8',
		primary300: '#0297fc',
		primary400: '#5fbfff',
		primary500: '#bae3ff'
	},
	dark: {}
}
