import {useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'react-auth-navigation'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {FaPen, FaTrash} from 'react-icons/fa'

import {DataTable} from 'app/components'
import {ConfirmationModal} from 'app/common'
import {deleteItemTypeAction, getItemTypeByIdAction} from 'redux-src'

import {ItemTypeModal} from '../itemTypeModal'

export const ItemTable = ({
  items,
  filterData,
  callback
}: {
  items: Api.ItemTypeIndividual[]
  filterData: string
  callback: (page?: number, limit?: number) => void
}) => {
  const {toast} = useAuth()

  const dispatch = useDispatch()

  const [newItemTypes, setNewItemTypes] = useState(false)

  const {
    itemTypeTotal,
    getItemTypeListLoading,
    itemTypeDetails,
    deleteItemTypeLoading
  }: RT.ItemTypeReduxType = useSelector((state: any) => state.itemTypes)

  const editItemHandler = (itemId: any) => {
    dispatch(getItemTypeByIdAction(itemId))
    setNewItemTypes(true)
  }

  const columns: TableColumn<{
    id: number
    name: string
    wastage_per: string | number
  }>[] = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center text-sm">
          <span className="cursor-pointer text-blue-300">
            <Link to={`/settings/item-type-management/${row.id}`}>
              {row.name}
            </Link>
          </span>
        </div>
      )
    },
    {
      name: 'Wastage Percentage',
      selector: (row) => row.wastage_per,
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center justify-between text-sm">
          <span>{row.wastage_per}</span>

          <div className="flex justify-between items-center gap-x-10">
            <button
              className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
              onClick={() => {
                editItemHandler(row.id)
              }}
            >
              <span className="text-left inline-flex cursor-pointer">
                <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
              </span>
            </button>

            <ConfirmationModal
              loading={deleteItemTypeLoading}
              onConfirmClick={(closeModalHandler) => {
                dispatch(
                  deleteItemTypeAction(row.id, toast, () => {
                    closeModalHandler()
                    callback()
                  })
                )
              }}
              danger
              label={'Are you sure you want to delete this Item Type?'}
              displayElement={
                <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                  <span className="text-left inline-flex cursor-pointer">
                    <FaTrash fill="red" size={12} />
                  </span>
                </button>
              }
              confirmLabel="Delete"
            ></ConfirmationModal>
          </div>
        </div>
      )
    }
  ]

  return (
    <div>
      <DataTable
        columns={columns}
        data={items}
        pagination
        fetchData={callback}
        totalRows={itemTypeTotal}
        loading={getItemTypeListLoading}
      />

      <ItemTypeModal
        mode="edit"
        itemTypeModal={newItemTypes}
        close={() => setNewItemTypes(false)}
        datas={itemTypeDetails}
      />
    </div>
  )
}
