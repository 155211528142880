import {useEffect, useState} from 'react'
import {
  MdFirstPage,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLastPage
} from 'react-icons/md'
import {useDebounceValue} from 'hooks'

import './_pagination.scss'

export const Pagination = ({
  style,
  count,
  page,
  onChange
}: Com.PaginationProps) => {
  const [newPage, setNewPage] = useState<number>(page)

  const debouncedNewPage = useDebounceValue(String(newPage))

  useEffect(() => {
    onChange(Number(newPage))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage])

  const onPageChange = (
    e: React.MouseEvent<HTMLDivElement>,
    newPage: number
  ) => {
    e.preventDefault()
    setNewPage(newPage)
  }
  let left = false
  let right = false

  return (
    <div className="pagination" style={style}>
      <div
        className={`pagination-left${newPage <= 1 ? '-disabled' : ''}`}
        onClick={(e) => onPageChange(e, 1)}
      >
        <MdFirstPage />
      </div>
      <div
        className={`pagination-left${newPage <= 1 ? '-disabled' : ''}`}
        onClick={(e) => onPageChange(e, newPage - 1)}
      >
        <MdKeyboardArrowLeft />
      </div>
      <div className="pagination-container">
        {Array(count)
          .fill('')
          .map((_, index) => {
            if (count < 10) {
              return (
                <PaginationNumber
                  key={index}
                  page={Number(debouncedNewPage)}
                  index={index}
                  onPageChange={onPageChange}
                />
              )
            } else if (count >= 10) {
              if (
                (newPage === 5 && index === 2) ||
                (newPage === count - 4 && index === count - 3)
              ) {
                if (
                  (index < page - 2 || index < Math.ceil(count / 2)) &&
                  !left
                ) {
                  left = true
                  return <div key={index}>...</div>
                }
                if (
                  index > count - 7 &&
                  index > Math.ceil(count / 2) &&
                  !right
                ) {
                  right = true
                  return <div key={index}>...</div>
                }
                return <></>
              } else if (
                index < 2 ||
                index > count - 3 ||
                index === newPage - 2 ||
                index === newPage - 1 ||
                (index < 5 && newPage < 6) ||
                (index > count - 6 && newPage > count - 5) ||
                index === newPage
              ) {
                return (
                  <PaginationNumber
                    key={index}
                    page={Number(debouncedNewPage)}
                    index={index}
                    onPageChange={onPageChange}
                  />
                )
              } else {
                if (
                  (index < page - 2 || index < Math.ceil(count / 2)) &&
                  !left
                ) {
                  left = true
                  return <div key={index}>...</div>
                }
                if (
                  index > count - 7 &&
                  index > Math.ceil(count / 2) &&
                  !right
                ) {
                  right = true
                  return <div key={index}>...</div>
                }
                return <></>
              }
            } else {
              return <></>
            }
          })}
      </div>

      <div
        className={`pagination-right${newPage >= count ? '-disabled' : ''}`}
        onClick={(e) => {
          onPageChange(e, newPage + 1)
        }}
      >
        <MdKeyboardArrowRight />
      </div>

      <div
        className={`pagination-right${newPage >= count ? '-disabled' : ''}`}
        onClick={(e) => onPageChange(e, count)}
      >
        <MdLastPage />
      </div>
    </div>
  )
}

const PaginationNumber = ({
  page,
  index,
  onPageChange
}: Com.PaginationNumberProps) => {
  return (
    <div
      className={`pagination-numbers${page === index + 1 ? '-active' : ''}`}
      onClick={(e) => {
        onPageChange(e, index + 1)
      }}
      key={index}
    >
      {index + 1}
    </div>
  )
}
