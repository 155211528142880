import {useState} from 'react'
import {useSelector} from 'react-redux'

import {Loading} from 'app/common'
import {DescriptionBox} from 'app/components'
import {CustomerDetails, JobDetails, SiteDetails} from './components'

export const NewJobPage = () => {
  const [customerDetails, setCustomerDetails] = useState(null)
  const [siteDetails, setSiteDetails] = useState<Api.SiteDetailsByIdType>()

  const detailsForJob = {
    customerId: customerDetails?.customer_details?.id,
    siteId: siteDetails?.site_details?.id ?? undefined,
  }

  const {add_loading} = useSelector((state: any) => state.job)

  return add_loading ? (
    <Loading />
  ) : (
    <div className="w-9/12 mx-auto h-custom">
      <div className="mx-auto mt-20 flex rounded-md justify-center items-center lg:w-8/12 w-full md:w-10/12 overflow-auto ">
        <div className=" border-1 w-full border-gray-200 rounded-md  mb-20">
          <DescriptionBox
            title={<div className="font-bold text-lg">New Project</div>}
            bodyClassName="p-0 bg-white"
          >
            <CustomerDetails
              customerProps={{customerDetails, setCustomerDetails}}
            />
            <SiteDetails
              customerProps={{customerDetails, setCustomerDetails}}
              siteProps={{siteDetails, setSiteDetails}}
            />

            <div className="py-60">
              <JobDetails detailsForJob={detailsForJob} />
            </div>
          </DescriptionBox>
        </div>
      </div>
    </div>
  )
}
