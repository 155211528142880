import {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {AiOutlineArrowLeft} from 'react-icons/ai'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'
import {useSelector, useDispatch} from 'react-redux'

import {AddItemModal, ItemManagementTable} from '../../itemManagement'

import {Button, Input, Loading} from 'app/common'
import {useDebounceValue} from 'hooks'

import {getItemListBySupplierIdAction, getSupplierAction} from 'redux-src'

export const SupplierDetailsPage = () => {
  const [search, setSearch] = useState<string>('')

  const [supplierFullname, setSupplierFullname] = useState<{
    id: number
    label: string
    value: string
  }>()
  // const [supplierDetails]
  // const [supplierItemList, setSupplierItemList] = useState<
  //   {
  //     id: number
  //     name: string
  //     cost: number
  //     supplierName: string
  //     fixingValue: number
  //     materialCost: number
  //     attributes: Array<string>
  //     itemTypeId: number
  //     itemCategoryId: number
  //     labourElementId: number
  //   }[]
  // >([])

  const {supplierItemLists: supplierItemListFromApi}: RT.ItemReduxType =
    useSelector((state: any) => state.itemManagement)
  const {supplierList, get_supplier_loading: loading}: RT.SupplierReduxType =
    useSelector((state: any) => state.suppliers)

  const {
    //@ts-ignore
    params: {supplierId},
    navigation: {navigate},
  } = useNavigation()

  const dispatch = useDispatch()

  const debouncedSearchValue = useDebounceValue(search)

  const [newItems, setNewItems] = useState(false)
  // const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const gettingSupplierName = useCallback(
    (tempSupplierId: number) => {
      const supplierName = supplierList?.filter(({supplier_details: item}) => {
        return item?.id === tempSupplierId
      })

      setSupplierFullname({
        id: supplierName[0]?.supplier_details?.id,
        label: supplierName[0]?.supplier_details?.name,
        value: supplierName[0]?.supplier_details?.name,
      })
      return supplierName[0]?.supplier_details?.name
    },
    [supplierList],
  )

  useEffect(() => {
    gettingSupplierName(+supplierId)
  }, [gettingSupplierName, supplierId])

  const supplierItemList = useMemo(() => {
    return supplierItemListFromApi?.map(
      ({
        item_details,
        supplier_details,
        item_type_details,
        labour_element_details,
        item_category_details,
        labour_element_group_details,
        material_element_details,
        material_element_group_details,
      }) => {
        return {
          id: item_details?.id,
          name: item_details?.name,
          cost: item_details?.cost,
          fixingValue: item_details?.fixing_value,
          materialCost: item_details?.material_cost,
          supplierName: supplierFullname?.value,
          attributes: item_details?.attributes,
          supplierId: item_details?.supplier_id,
          itemTypeId: item_details?.item_type_id,
          itemCategoryId: item_details?.item_category_id,
          labourElementId: item_details?.labour_element_id,
          labourElementGroupId: item_details?.labour_element_group_id,
          materialElementId: material_element_details?.id,
          materialElementGroupId: material_element_group_details?.id,
          item_type_details,
          labour_element_details,
          labour_element_group_details,
          materialElementDetails: material_element_details,
          materialElementGroupDetails: material_element_group_details,
          item_category_details,
          supplier_details,
          totalMaterialUnits: item_details?.total_material_units,
        }
      },
    )
    // return setSupplierItemList(remappedItemList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierItemListFromApi, supplierId, supplierList])

  useEffect(() => {
    if (supplierId) {
      dispatch(getItemListBySupplierIdAction(Number(supplierId)))
      dispatch(getSupplierAction())
    }
  }, [dispatch, supplierId])

  return (
    <div className="min-h-[85vh] py-10 flex flex-col gap-6">
      <div className="border border-b-1 pb-4 border-gray-200 flex flex-col gap-10">
        <div
          className="flex text-blue-300 items-center gap-6 pb-2 cursor-pointer"
          onClick={() => navigate('/settings/supplier')}
        >
          <span>
            <AiOutlineArrowLeft />
          </span>
          <span>Back to supplier list </span>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between">
          <div className="text-lg font-bold text-black inline-flex items-center">
            Item by {loading ? <Loading small /> : supplierFullname?.label}
          </div>

          <Button
            title="Add Supplier Items"
            onClick={() => setNewItems(true)}
            className="font-bold"
          />
        </div>

        <div className="flex flex-col">
          <div className="flex items-center justify-between pb-10">
            <Input
              name="filterValue"
              type="text"
              prepend={<FaSearch size={16} color={colors.gray[500]} />}
              containerClass="w-[300px]"
              placeholder="Search by item"
              className="w-full text-sm"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </div>

          <div className="my-5">
            <ItemManagementTable
              search={debouncedSearchValue}
              supplierItemList={supplierItemList}
              supplierData={supplierFullname}
            />
          </div>
          {newItems && (
            <AddItemModal
              supplierData={supplierFullname}
              mode="add"
              addItemModal={newItems}
              setAddItemModal={setNewItems}
              close={() => setNewItems(false)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
