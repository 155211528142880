import axios from 'axios'

import { GET_ALL_SITEVISITS_WEBVIEW } from '.'
import { APIS, LOCAL_BASE_URL } from '../../config'

export function getAllSiteVisitsWebView({
  projectId,
  token,
}: {
  projectId: number
  token: string
}) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({ type: GET_ALL_SITEVISITS_WEBVIEW.LOADING })
      res = await axios({
        baseURL: LOCAL_BASE_URL,
        url: `${APIS.jobs}/${projectId}/site-visits/with-checklist`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      const {
        success,
        data: { data, message },
      } = res.data

      if (success) {
        dispatch({ type: GET_ALL_SITEVISITS_WEBVIEW.SUCCESS, payload: data })
      } else {
        dispatch({ type: GET_ALL_SITEVISITS_WEBVIEW.ERROR, payload: message })
      }
    } catch ({ message }) {
      dispatch({ type: GET_ALL_SITEVISITS_WEBVIEW.ERROR })
    }
  }
}
