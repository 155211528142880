import { v4 as uuidv4 } from 'uuid'

const initialState: any = {
  sectionList: [],
}
export const invoiceItemsReducer = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case 'LOCAL_ADD_INVOICE_ITEM_REVERT':
      return { ...state, sectionList: payload }

    case 'LOCAL_ADD_INVOICE_ITEM':
      return { ...state, sectionList: [payload, ...state.sectionList] }
    case 'LOCAL_ADD_INVOICE_ITEM_LAST':
      return { ...state, sectionList: [...state.sectionList, payload] }

    case 'LOCAL_REMOVE_INVOICE_ITEM': {
      const sectionListClone = [...state.sectionList]
      sectionListClone.splice(payload, 1)

      return { ...state, sectionList: [...sectionListClone] }
    }

    case 'LOCAL_ADD_LINE_ITEM': {
      // const sectionIndex = state.sectionList.findIndex(
      //   ({ _id }: { _id: any }) => _id === payload.sectionId
      // )
      const sectionListClone = [...state.sectionList]

      const sectionListDetailClone = {
        ...sectionListClone[payload.sectionIndex],
      }

      const lineItemListClone = [
        {
          name: payload.name,
          quantity: '2',
          cost: '12.00',
          price: '15.00',
          markup: '25.00',
          tax: '21',
          discount: '1',
          total: '30',
          _id: uuidv4(),
        },
        ...sectionListDetailClone.lineItem,
      ]

      sectionListDetailClone.lineItem = lineItemListClone
      sectionListClone[payload.sectionIndex] = sectionListDetailClone

      return { ...state, sectionList: [...sectionListClone] }
    }

    case 'LOCAL_UPDATE_LINE_ITEM': {
      // const sectionIndex = state.sectionList.findIndex(
      //   ({ _id }: { _id: any }) => _id === payload.sectionId
      // )
      const sectionListClone = [...state.sectionList]

      const sectionListDetailClone = {
        ...sectionListClone[payload.sectionIndex],
      }

      const lineItemListClone = [...sectionListDetailClone.lineItem]

      const lineItemDetailClone = {
        ...lineItemListClone[payload.lineItemIndex],
      }

      lineItemDetailClone[payload.key] = payload.value

      lineItemListClone[payload.lineItemIndex] = lineItemDetailClone

      sectionListDetailClone.lineItem = lineItemListClone

      sectionListClone[payload.sectionIndex] = sectionListDetailClone

      return { ...state, sectionList: [...sectionListClone] }
    }

    case 'LOCAL_DELETE_LINE_ITEM': {
      // const sectionIndex = state.sectionList.findIndex(
      //   ({ _id }: { _id: any }) => _id === payload.sectionId
      // )
      const sectionListClone = [...state.sectionList]

      const sectionListDetailClone = {
        ...sectionListClone[payload.sectionIndex],
      }

      const lineItemListClone = [...sectionListDetailClone.lineItem]
      lineItemListClone.splice(payload.index, 1)
      sectionListDetailClone.lineItem = lineItemListClone
      sectionListClone[payload.sectionIndex] = sectionListDetailClone

      return { ...state, sectionList: [...sectionListClone] }
    }

    case 'LOCAL_UPDATE_FILTER': {
      // const sectionIndex = state.sectionList.findIndex(
      //   ({ _id }: { _id: any }) => _id === payload.sectionId
      // )
      const sectionListClone = [...state.sectionList]

      const sectionListDetailClone = {
        ...sectionListClone[payload.sectionIndex],
      }

      sectionListDetailClone[payload.key] = payload.value

      sectionListClone[payload.sectionIndex] = sectionListDetailClone

      return { ...state, sectionList: [...sectionListClone] }
    }

    default:
      return state
  }
}
