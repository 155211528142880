import { useState } from 'react'
import { HiDotsVertical, HiChevronDown } from 'react-icons/hi'
import { Menu, MenuItem } from '@material-ui/core'
import { BiCheck } from 'react-icons/bi'

export const InvoiceFilter = ({
  name,
  onChangeFilter,
  checked,
}: {
  name: string
  onChangeFilter?: (a: any, b: string) => void
  checked?: any
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const onClickMenuItem = (value: any) => {
    onChangeFilter(name, value)
    setAnchorEl(false)
  }
  return (
    <>
      <button
        className="border-1 border-gray-200 bg-white text-sm text-black hover:bg-gray-200 rounded-sm shadow-sm inline-flex"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <HiDotsVertical className="my-4 relative -top-1" />
        {name}
        <HiChevronDown className="m-4 text-gray-300" />
      </button>

      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => onClickMenuItem('default')}>
          {checked === 'default' ? <BiCheck /> : null}
          Default setting
        </MenuItem>
        <MenuItem onClick={() => onClickMenuItem('show')}>
          {checked === 'show' ? <BiCheck /> : null}
          Always Show
        </MenuItem>
        <MenuItem onClick={() => onClickMenuItem('hide')}>
          {checked === 'hide' ? <BiCheck /> : null}
          Always Hide
        </MenuItem>
      </Menu>
    </>
  )
}
