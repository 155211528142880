import moment from 'moment'
import {FormEvent, SetStateAction, useState} from 'react'
import {FaPen, FaPlus, FaReply, FaTimes} from 'react-icons/fa'
import {FiCheckSquare} from 'react-icons/fi'
import {useSelector} from 'react-redux'
import {
  AnimatedBlock,
  TransitionBlock,
  useAnimatedValue,
  useMeasure,
} from 'react-ui-animate'

import {ButtonGroup, DefaultButton, Modal, Textarea} from 'app/common'

export const NoteModal = ({
  create,
  get,
  update,
  remove,
  noteModal,
  displayNotes,
  setNoteModal,
}: {
  create: (data: {text: string; parentId?: number}, cb: () => void) => void
  get: () => void
  remove: (customerNoteId: string | number, cb: () => void) => void
  update: (
    noteId: string | number,
    data: {text: string},
    cb: () => void,
  ) => void
  noteModal: boolean
  displayNotes: Array<Api.NoteDetailsCustomerType>
  setNoteModal: React.Dispatch<SetStateAction<boolean>>
}) => {
  const [newNote, setNewNote] = useState('')

  const {add_notes_loading}: RT.NotesReduxType = useSelector(
    (state: any) => state.noteHistory,
  )

  return (
    <Modal
      title="Add Note"
      size="sm"
      isActive={noteModal}
      toggleModal={setNoteModal}
    >
      <div className="p-8">
        <div
          className={
            displayNotes?.length === 0
              ? 'p-16 flex flex-col gap-16 h-[25vh]'
              : 'p-16 flex flex-col gap-16 h-[55vh] overflow-y-auto'
          }
        >
          <form
            onSubmit={(e) => {
              e.preventDefault()
              create({text: newNote}, () => {
                get()
                setNewNote('')
              })
            }}
          >
            <div className="flex items-center">
              <div className="flex-1">
                <Textarea
                  className="border-1 border-gray-200 rounded-sm p-4 w-[100%]"
                  name="note"
                  placeholder="New note"
                  value={newNote}
                  onChange={(e: FormEvent<HTMLTextAreaElement>) =>
                    setNewNote(e.currentTarget.value)
                  }
                />
              </div>
            </div>

            <div className="flex gap-8 p-16 pt-0 pr-0 justify-end ">
              <DefaultButton
                type="submit"
                leftIcon={<FaPlus />}
                title="Add Note"
                className="!bg-blue-300 text-white hover:!bg-blue-400"
                // onClick={() => create(newNote, get)}
                loading={add_notes_loading}
              />
            </div>
          </form>

          {displayNotes?.length > 0 && (
            <div className="flex-col">
              <div className="w-full font-bold text-gray-500">Saved Notes</div>
              <div className="flex-col my-10 ">
                {displayNotes?.map(
                  ({
                    linked_note_id: linkedId,
                    note_details: noteDetails,
                    user_details: userDetails,
                  }) => {
                    return (
                      <NoteContent
                        key={noteDetails.id}
                        {...{
                          linkedId,
                          userDetails,
                          noteDetails,
                          update,
                          remove,
                          get,
                          create,
                        }}
                      />
                    )
                  },
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

const NoteContent = ({
  userDetails,
  noteDetails,
  update,
  get,
  remove,
  create,
  linkedId,
}: {
  create: (data: {text: string; parentId?: number}, cb: () => void) => void
  get: () => void
  remove: (customerNoteId: string | number, cb: () => void) => void
  update: (
    noteId: string | number,
    data: {text: string},
    cb: () => void,
  ) => void
  userDetails: Api.NoteDetailsCustomerType['user_details']
  noteDetails: Api.NoteDetailsCustomerType['note_details']
  linkedId: number
}) => {
  const [isReplyActive, setReplyActive] = useState<boolean>(false)
  const [replyValue, setReplyValue] = useState<string>('')

  const onSubmitHandle = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (replyValue.length === 0) {
      return
    } else {
      create({text: replyValue, parentId: linkedId}, () => {
        get()
        setReplyValue('')
      })
    }
  }

  const [contentHeight, setContentHeight] = useState<number>(0)

  const bind = useMeasure(({height}) => {
    setContentHeight(Number(+height))
  })

  const heightAnimation = useAnimatedValue(
    isReplyActive ? contentHeight + 40 : contentHeight + 15,
  )

  return (
    <TransitionBlock state={isReplyActive}>
      {(animation) => (
        <AnimatedBlock
          className={`relative flex flex-col p-8 border-1 border-gray-200 rounded-sm my-8`}
          style={{
            height: heightAnimation.value,
          }}
          onMouseOver={() => {
            setReplyActive(true)
          }}
          onMouseLeave={() => {
            setReplyActive(false)
          }}
        >
          <div {...bind()}>
            <NoteTextComp
              {...{update, get, remove, noteDetails, userDetails}}
            />

            {noteDetails.replies && noteDetails.replies.length > 0 && (
              <div className="flex flex-col gap-4 pl-20 py-4">
                {noteDetails.replies.map((reply) => {
                  return (
                    <div
                      key={reply.id}
                      className="flex flex-col border border-1 border-gray-200 rounded-sm py-6 px-8"
                    >
                      <NoteTextComp
                        {...{update, get, remove, userDetails}}
                        noteDetails={{
                          ...reply,
                          created_at: noteDetails?.created_at,
                          replies: undefined,
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            )}

            {!!isReplyActive && (
              <AnimatedBlock
                style={{opacity: animation.value}}
                className="absolute bottom-0 left-0 w-full px-10 py-8"
              >
                <form
                  onSubmit={onSubmitHandle}
                  className={
                    'flex flex-nowrap gap-6 items-center text-gray-400'
                  }
                >
                  <span>
                    <FaReply />
                  </span>
                  <input
                    type="text"
                    placeholder="Type a reply and press enter"
                    value={replyValue}
                    autoFocus
                    onChange={(e) => {
                      setReplyValue(e.target.value)
                    }}
                    className="w-full outline-none py-3 border-b-1 border-gray-200 focus:border-blue-200 hover:border-blue-200 text-gray-600"
                  />
                </form>
              </AnimatedBlock>
            )}
          </div>
        </AnimatedBlock>
      )}
    </TransitionBlock>
  )
}

const NoteTextComp = ({
  userDetails,
  noteDetails,
  update,
  get,
  remove,
}: {
  get: () => void
  remove: (customerNoteId: string | number, cb: () => void) => void
  update: (
    noteId: string | number,
    data: {text: string},
    cb: () => void,
  ) => void
  userDetails: Api.NoteDetailsCustomerType['user_details']
  noteDetails: Api.NoteDetailsCustomerType['note_details']
}) => {
  const [isInputShow, setIsInputShow] = useState<boolean>(false)
  const [newEditedNote, setNewEditedNote] = useState<string>(noteDetails.text)
  const handleEditNote = () => {
    setIsInputShow((prev) => !prev)

    if (!!isInputShow) {
      update(noteDetails.id, {text: newEditedNote}, get)
    }
  }
  return (
    <>
      <div className="pb-8 flex flex-nowrap">
        <div className="bg-purple-800 text-white w-24 h-24 flex items-center justify-center rounded-sm p-[2px] uppercase">
          {userDetails.display_name?.split(' ')[0]?.charAt(0)}
          {userDetails.display_name?.split(' ')[1]?.charAt(0)}
        </div>
        <div className=" px-8 flex-1 text-sm text-gray-400">
          {userDetails.display_name?.split(' ')[0]} | Created at{' '}
          {moment(noteDetails?.created_at).format('ddd, LL')}
        </div>
      </div>
      <form
        className="flex flex-row justify-between items-center py-[2px] text-sm w-full"
        onSubmit={(e) => {
          e.preventDefault()
          handleEditNote()
        }}
      >
        {isInputShow ? (
          <div className="w-full mr-10">
            <input
              type="text"
              placeholder="Edit note"
              value={newEditedNote}
              autoFocus
              onChange={(e) => {
                setNewEditedNote(e.target.value)
              }}
              className="w-full outline-none py-3 border-b-1 border-gray-200 focus:border-blue-200 hover:border-blue-200 transition-all duration-200 ease-in-out"
            />
          </div>
        ) : (
          <div>{noteDetails.text}</div>
        )}
        <ButtonGroup
          data={[
            {
              icon: isInputShow ? (
                <FiCheckSquare
                  className="border-gray-200"
                  size={13}
                  color={'green'}
                />
              ) : (
                <FaPen className="border-gray-200" size={11} color={'blue'} />
              ),
              type: 'submit',
              // onClick: () => {
              //   handleEditNote()
              // },
            },
            {
              icon: (
                <FaTimes className="border-gray-200" size={12} color={'red'} />
              ),
              type: 'button',
              onClick: () => {
                remove(noteDetails.id, get)
              },
            },
          ]}
        />
      </form>
    </>
  )
}
