import React, {useCallback, useContext, useEffect, useState} from 'react'
import {saveAs} from 'file-saver'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import classNames from 'classnames'
import {MdArrowDropDown} from 'react-icons/md'
import {HiPlusSm} from 'react-icons/hi'
import {CiEdit} from 'react-icons/ci'
import {IoMdEye} from 'react-icons/io'
import {BsCloudDownload} from 'react-icons/bs'

import {Input, Textarea, Button, Dropdown, DefaultButton} from 'app/common'

import {
  approveWorkerInvoice,
  createWorkerInvoiceItem,
  deleteInvoiceItemAction,
  downloadPdfAction,
  getProjectList,
  getWorkerInvoiceById,
  makeWorkerInvoiceStatusToPaid,
  updateBasicDetailsOfWorkerInvoice,
} from 'redux-src'
import {generateWorkerInvoiceHTMLString} from 'helpers'

import {QUOTE_TABLE_HEADINGS} from './constants'
import {LetterHead, SectionCard} from './components'
import {InvoicingContext} from '../../invoice.page'
import {CreateWorkerInvoiceModal} from '../../components'

export function UserInvoiceEditorPage() {
  const {companyDetails}: RT.CompanyReduxType = useSelector(
    (state: any) => state.company,
  )
  const {companyUserDetails}: RT.UserReduxType = useSelector(
    (state: any) => state.user,
  )
  const {workerInvoiceDetails}: RT.ProjectInvoicingReduxType = useSelector(
    (state: any) => state.invoices,
  )

  const {worker_invoice_item_list, worker_invoice_detail} =
    !!workerInvoiceDetails && workerInvoiceDetails

  const {params} = useNavigation()

  const {invoiceId: invoiceIdParam} = params as any

  const {
    selectInvoice: {activeInvoice},
  } = useContext(InvoicingContext)

  const [invoiceId, setInvoiceId] = useState(0)

  useEffect(() => {
    setInvoiceId(activeInvoice?.id)
  }, [activeInvoice?.id])

  const [basicDetails, {onChange: onChangeHandler, setValue}] = useFormInput({
    title: '',
    description: '',
    dueDate: '',
    footerNotes: '',
    projectId: undefined,
    remarks: undefined,
  })

  const dispatch = useDispatch()
  const {toast, currencySymbol} = useAuth()
  const {defaultInvoiceSettings} = useSelector(
    (state: any) => state.invoiceSetting,
  )

  const [addCostModal, setAddCostModal] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)

  const [initialSectionName, setInitialSectionName] = useState<string>()

  const initialSubmitSection = (e: React.FormEvent) => {
    e.preventDefault()

    if (initialSectionName.length > 0) {
      const initialSectionNameClone = initialSectionName

      const body = {title: initialSectionNameClone, description: 'Description'}

      dispatch(
        createWorkerInvoiceItem(invoiceIdParam, body, () => {
          dispatch(getWorkerInvoiceById(invoiceIdParam))
        }),
      )
      setInitialSectionName('')
    }
  }

  const onRemoveHandler = (id: any) => {
    dispatch(
      deleteInvoiceItemAction(
        invoiceIdParam,
        id,
        toast,
        () => {
          dispatch(getWorkerInvoiceById(invoiceIdParam))
        },
        () => {},
      ),
    )
  }

  const onApproveHandler = () => {
    workerInvoiceDetails?.worker_invoice_detail?.type === 'draft' &&
      dispatch(
        approveWorkerInvoice(invoiceIdParam, () => {
          fetchInvoiceDetails()
        }),
      )
  }

  const onPaidInvoiceHandler = () => {
    worker_invoice_detail?.type === 'approved' &&
      dispatch(
        makeWorkerInvoiceStatusToPaid(invoiceIdParam, () => {
          fetchInvoiceDetails()
        }),
      )
  }

  const onUpdateInvoiceHandler = () => {
    if (
      workerInvoiceDetails?.worker_invoice_detail?.title !==
        basicDetails?.title ||
      workerInvoiceDetails?.worker_invoice_detail?.description !==
        basicDetails?.description ||
      workerInvoiceDetails?.worker_invoice_detail?.footer_notes !==
        basicDetails?.footerNotes
    ) {
      const body = {
        title: basicDetails?.title,
        description:
          basicDetails?.description?.length === 0 || !!basicDetails?.description
            ? undefined
            : basicDetails?.description,
        dueDate: basicDetails?.dueDate,
        footerNotes: basicDetails?.footerNotes,
      }

      dispatch(
        updateBasicDetailsOfWorkerInvoice(invoiceIdParam, body, () => {
          fetchInvoiceDetails()
        }),
      )
    }
  }

  const fetchInvoiceDetails = useCallback(() => {
    dispatch(getWorkerInvoiceById(invoiceIdParam))
  }, [dispatch, invoiceIdParam])

  useEffect(() => {
    fetchInvoiceDetails()
  }, [fetchInvoiceDetails])

  useEffect(() => {
    workerInvoiceDetails?.worker_invoice_detail?.status === 'paid' &&
      workerInvoiceDetails?.worker_invoice_detail?.type === 'approved' &&
      setIsPreview(true)
  }, [workerInvoiceDetails])

  const {downloadPdfLoading} = useSelector((state: any) => state.file)

  const generateWorkerInvoicePDF = () => {
    dispatch(
      downloadPdfAction(
        {
          html: generateWorkerInvoiceHTMLString(
            companyDetails,
            companyUserDetails,
            workerInvoiceDetails,
            currencySymbol,
          ).toString(),
        },
        (data: any) => {
          saveAs(
            data,
            `Worker - ${workerInvoiceDetails?.worker_invoice_detail?.user_id} - ${workerInvoiceDetails?.worker_invoice_detail?.invoice_number}`,
          )
        },
      ),
    )
  }

  useEffect(() => {
    if (workerInvoiceDetails) {
      setValue('title', workerInvoiceDetails?.worker_invoice_detail?.title)
      setValue(
        'description',
        workerInvoiceDetails?.worker_invoice_detail?.description,
      )
      setValue('dueDate', workerInvoiceDetails?.worker_invoice_detail?.due_date)
      setValue(
        'footerNotes',
        workerInvoiceDetails?.worker_invoice_detail?.footer_notes,
      )
      setValue('remarks', workerInvoiceDetails?.worker_invoice_detail?.remarks)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerInvoiceDetails])

  useEffect(() => {
    dispatch(getProjectList())
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="flex flex-row justify-between bg-blue-150 p-10 ">
        <div
          className="inline-flex rounded-md shadow-sm justify-between w-full"
          role="group"
        >
          <div className="mr-10 inline-flex gap-16">
            {workerInvoiceDetails &&
              worker_invoice_detail?.type === 'draft' && (
                <Button
                  title="Add Costs"
                  className="px-12 py-[4px]"
                  onClick={() => setAddCostModal(true)}
                />
              )}

            {addCostModal && (
              <CreateWorkerInvoiceModal
                key={'addcost modal'}
                openModal={addCostModal}
                setOpenModal={setAddCostModal}
                isDetailsPage
              />
            )}
          </div>
          <div className="flex flex-row justify-center items-center ml-10 gap-10">
            <div className="flex flex-row justify-center items-center h-[23px]">
              {workerInvoiceDetails?.worker_invoice_detail?.status !== 'paid' &&
              workerInvoiceDetails?.worker_invoice_detail?.type !==
                'approved' ? (
                <button
                  type="button"
                  className={classNames(
                    'flex flex-row items-center justify-between gap-6 border-1 py-2 px-6 text-[10px] font-medium rounded-l-sm',
                    isPreview
                      ? ' text-white z-10 border-blue-400 bg-blue-400'
                      : 'text-gray-400 border-white bg-white',
                  )}
                  onClick={() => {
                    setIsPreview(true)
                  }}
                >
                  <IoMdEye size={14} />
                  Preview
                </button>
              ) : (
                <DefaultButton
                  title="Download Pdf"
                  leftIcon={<BsCloudDownload />}
                  className="w-[max-content] hover:bg-gray-200 bg-white text-black h-[30px]"
                  onClick={generateWorkerInvoicePDF}
                  loading={downloadPdfLoading}
                />
              )}

              {worker_invoice_detail?.type === 'draft' && (
                <button
                  type="button"
                  className={classNames(
                    'flex flex-row items-center justify-between gap-6 border-1 py-2 px-6 text-[10px] font-medium rounded-r-sm',
                    !isPreview
                      ? ' text-white z-10 border-blue-400 bg-blue-400'
                      : 'text-gray-400 border-white bg-white',
                  )}
                  onClick={() => {
                    setIsPreview(false)
                    // console.log('isPreview: ', isPreview)
                  }}
                >
                  <CiEdit size={14} />
                  Edit
                </button>
              )}
            </div>
            {worker_invoice_detail?.status !== 'paid' && (
              <div className="relative flex flex-row justify-center items-center">
                {worker_invoice_detail?.type === 'draft' ? (
                  <div className="bg-green-500 pr-0 inline-flex rounded-sm text-sm cursor-pointer text-white">
                    <div
                      className="inline-flex gap-10 hover:bg-green-600 px-12 py-[2px] rounded-sm"
                      onClick={onApproveHandler}
                    >
                      Approve
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-8">
                    {getChipData(worker_invoice_detail?.status)}
                    <div className="bg-green-500 pr-0 inline-flex rounded-sm text-sm cursor-pointer text-white">
                      <div className="inline-flex gap-10 hover:bg-green-600 px-12 py-[2px] rounded-sm">
                        Approved
                      </div>
                      <div className="border-l-2 border-white" />
                      <div>
                        <Dropdown
                          placement="bottomright"
                          triggerToggle
                          triggerElement={
                            <div className="transition-all duration-100 ease-in-out flex justify-center items-center px-8 h-full py-[4px] hover:bg-green-600 rounded-sm">
                              <MdArrowDropDown size={14} />
                            </div>
                          }
                        >
                          <div className="bg-white shadow-lg rounded-sm overflow-hidden text-black mt-10">
                            {workerInvoiceDetails?.worker_invoice_detail
                              ?.status !== 'paid' &&
                              worker_invoice_detail?.status !== 'dispute' &&
                              worker_invoice_detail?.status === 'unpaid' && (
                                <div
                                  className="transition-all duration-100 ease-in-out px-18 py-8 hover:bg-blue-200 hover:text-white cursor-pointer"
                                  onClick={onPaidInvoiceHandler}
                                >
                                  Paid
                                </div>
                              )}
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* letterhead */}
      <LetterHead
        previewMode={isPreview}
        companyDetails={companyDetails}
        invoiceDetail={workerInvoiceDetails}
        defaultInvoiceSettings={defaultInvoiceSettings}
      />
      {/* invoice details */}
      <div className="my-16">
        <div className="inline-flex gap-16 ">
          {basicDetails ? (
            <Input
              className="m-0 mb-0 inline-flex font-bold text-lg w-auto"
              containerClass="m-0"
              name="title"
              size="sm"
              value={basicDetails?.title}
              onChange={onChangeHandler}
              placeholder="Title"
              onBlur={onUpdateInvoiceHandler}
            />
          ) : (
            <div className="inline-flex font-bold text-lg">
              INV-{worker_invoice_detail?.id}
            </div>
          )}
          <div className="border-l-[3px] border-black" />
          <div
            className={`flex flex-row font-bold items-center justify-start text-lg ${
              worker_invoice_detail?.status === 'draft' ? 'text-gray-300' : ''
            }`}
          >
            {worker_invoice_detail?.status !== 'draft'
              ? `${workerInvoiceDetails?.worker_invoice_detail?.invoice_number}`
              : 'INV-DRAFT'}
          </div>
        </div>
        {/* invoice description */}
        <div className="text-sm pb-16 border-b-1 border-b-gray-200">
          {isPreview ? (
            <div
              dangerouslySetInnerHTML={{
                __html: basicDetails?.description?.replace('\n', '<br/>'),
              }}
              className="mt-16 text-gray-500"
            ></div>
          ) : (
            <Textarea
              className="text-sm mt-16"
              name="description"
              placeholder={`Description`}
              size="sm"
              value={basicDetails?.description}
              onChange={onChangeHandler}
              onBlur={onUpdateInvoiceHandler}
            />
          )}
        </div>
      </div>
      {/* HEADINGS */}
      <div className="w-full flex">
        {QUOTE_TABLE_HEADINGS.map(({title, width, hidden}, index) => (
          <span
            key={index}
            className={[
              `text-sm font-bold block py-16 first:pl-0 first:text-left text-center last:pr-0 last:${
                isPreview ||
                workerInvoiceDetails?.worker_invoice_detail?.type === 'approved'
                  ? 'text-right'
                  : 'text-right'
              }`,
              hidden ? 'text-gray-300' : '',
            ].join(' ')}
            style={{width}}
          >
            {title === 'Ticket' && isPreview ? '' : title}
          </span>
        ))}
      </div>
      {/* preview section form */}
      {isPreview || worker_invoice_detail?.type !== 'draft' ? (
        <div>
          {worker_invoice_item_list?.length &&
            worker_invoice_item_list?.map(
              (
                item: Api.WorkerInvoiceDetailsById['worker_invoice_item_list'][0],
              ) => {
                return (
                  <div
                    className="mt-10"
                    key={item?.worker_inv_item_details?.id}
                  >
                    <h1>
                      <strong>{item?.worker_inv_item_details?.title}</strong>
                    </h1>
                    <table
                      style={{
                        width: '100%',
                      }}
                    >
                      <tbody>
                        {item?.worker_inv_line_item_list?.length > 0 &&
                          item.worker_inv_line_item_list.map(
                            (item: any, i: number) => {
                              return (
                                <tr key={i}>
                                  {[
                                    'title',
                                    'pay_rate',
                                    'time_mins',
                                    'total_cost',
                                  ].map((key, index) => {
                                    return (
                                      <td
                                        key={index}
                                        className="first:text-left text-center last:text-right last:pr-[50px]"
                                        style={{
                                          width: QUOTE_TABLE_HEADINGS.find(
                                            (heading) => heading.key === key,
                                          )?.width,
                                        }}
                                      >
                                        {item[key]}
                                      </td>
                                    )
                                  })}
                                </tr>
                              )
                            },
                          )}
                      </tbody>
                    </table>
                  </div>
                )
              },
            )}
        </div>
      ) : (
        <>
          {/* NEW SECTION FORM */}
          <div className="flex  gap-10 mb-16 w-[70%]">
            <form onSubmit={initialSubmitSection} className="flex flex-1">
              <Input
                prepend={<HiPlusSm size={24} />}
                placeholder="Type section name and press enter"
                name="sectionName"
                onChange={(e: any) => setInitialSectionName(e.target.value)}
                value={initialSectionName}
                className={`rounded-r-sm`}
              />
            </form>
          </div>
          {worker_invoice_item_list?.length > 0 ? (
            <React.Fragment>
              {worker_invoice_item_list?.map(
                (
                  item: Api.WorkerInvoiceDetailsById['worker_invoice_item_list'][0],
                  index: any,
                ) => {
                  return (
                    <div
                      className="px-8 py-8 mb-16 bg-blue-100 rounded-sm"
                      key={item?.worker_inv_item_details?.id}
                    >
                      <SectionCard
                        invoiceId={invoiceId}
                        sectionIndex={index}
                        sectionDetail={item}
                        onRemoveSection={onRemoveHandler}
                        sectionList={worker_invoice_item_list}
                        fetchInvoiceItem={() => fetchInvoiceDetails()}
                      />
                    </div>
                  )
                },
              )}
            </React.Fragment>
          ) : null}
        </>
      )}
      {/* totals */}
      <div className="border-t-1 border-t-gray-200 mt-8 pt-8 flex flex-row justify-end">
        <div>
          <div className="flex flex-row justify-end gap-10 mt-6">
            <div className="font-bold text-right">Total</div>
            <div className="min-w-[80px] max-w-[90px] font-bold text-right">
              {currencySymbol}
              {Number(worker_invoice_detail?.total_cost).toFixed(2) ?? 0.0}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 mb-48">
        <div className="text-sm pt-16  ">
          {isPreview ? (
            <div
              dangerouslySetInnerHTML={{
                __html: basicDetails?.footerNotes?.replace('\n', '<br/>'),
              }}
              className="italic text-gray-500"
            ></div>
          ) : (
            <Textarea
              className="text-sm mt-16 italic"
              name="footerNotes"
              placeholder={`Footer Notes`}
              size="sm"
              value={basicDetails?.footerNotes}
              onChange={onChangeHandler}
              onBlur={onUpdateInvoiceHandler}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const getChipData = (value: string) => {
  switch (value) {
    case 'voided':
      return (
        <div className="text-xs font-bold bg-orange-400 text-white px-6 py-4 rounded-full">
          {value.toUpperCase()}
        </div>
      )

    case 'unpaid':
      return (
        <div className="text-xs font-bold bg-red-400 text-white px-6 py-4 rounded-full">
          {value.toUpperCase()}
        </div>
      )
    case 'disputed':
      return (
        <div className="text-xs font-bold bg-orange-400 text-white px-6 py-4 rounded-full">
          <div>{value.toUpperCase()}</div>
        </div>
      )
    case 'paid':
      return (
        <div className="text-xs font-bold bg-green-600 text-white px-6 py-4 rounded-full">
          {value.toUpperCase()}
        </div>
      )
    case 'approved':
      return (
        <div className="text-xs font-bold bg-green-500 text-white px-6 py-4 rounded-full">
          {value.toUpperCase()}
        </div>
      )

    default:
      return null
  }
}
