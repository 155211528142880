import {useCallback, useEffect, useState} from 'react'
import Geocode from 'react-geocode'
import {useDispatch, useSelector} from 'react-redux'
// import { useFormInput } from 'use-form-input'
import {HiMinusSm, HiPlusSm} from 'react-icons/hi'

import {Input, Loading, Modal, Select} from 'app/common'
import {DescriptionBox} from 'app/components'

import {capitalizeFirstLetter} from 'utils'
import {useFormInput} from 'use-form-input'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import {
  addressRemapper,
  finalAddressRemapper,
  getAccumulatedContacts,
  getCountry,
  getCountryId,
} from 'helpers'
import {useAuth} from 'react-auth-navigation'
import {getCompanyDetails, updateCompanyAddressAndContact} from 'redux-src'
import {REACT_APP_GOOGLE_KEY} from 'config'

export const CompanyDetailsModal = ({
  isCompanyDetailModal,
  setCompanyDetailModal,
}: any) => {
  const dispatch = useDispatch()
  const {
    selectOptions: {countryOptions, contactTypeOptions},
  } = useSelector((state: any) => state.selectOption)

  const {
    companyDetails: {
      company_details: {email, mobile},
      contact_details: {firstname, lastname, position},
      address_details: {city, suburb, zipCode, state, country, address},
    },
    toast,
  } = useAuth()

  const [mainContactData, setMainContactData] = useState({
    dynamicFields: [{contactFieldId: 1, value: ''}],
    firstName: '',
    lastName: '',
    title: '',
    attn: '',
  })

  const [mainContactError, setMainContactError] = useState('')

  const [physicalAddressData, physicalAddressHandler] = useFormInput(
    {
      address: '',
      zip: '',
      country: '',
      state: '',
      city: '',
      lat: 0,
      lng: 0,
      placeId: 0,
      suburb: '',
      countryId: getCountry(country),
      active: true,
      addressTypeId: 1,
    },
    (data) => {
      // const errors: any = {}
      const {errors}: any = physicalAddressHandler
      console.log('here', errors)
      if (
        data.zip.length === 0 ||
        data.state.length === 0 ||
        data.city.length === 0 ||
        data.suburb.length === 0 ||
        data.lat === 0 ||
        data.lng === 0 ||
        data.placeId === 0
      ) {
        errors.address = 'Please fill all the physical addresses!'
      }

      return errors
    },
  )
  const [physAddress_loading, setPhysAddress_loading] = useState(false)
  const [physAddressValue, setPhysAddressValue] = useState(null)

  const setAddress = useCallback(() => {
    const {setValue} = physicalAddressHandler
    setPhysAddressValue({label: address, value: address})

    setValue('address', address)
    setValue('city', city === 'no address' ? '' : city)
    setValue('countryId', getCountryId(country))
    setValue('state', state)
    setValue('zip', zipCode)
    setValue('suburb', suburb)
    // setPostAddress_loading(false)
  }, [address, city, country, physicalAddressHandler, state, suburb, zipCode])

  const setContact = useCallback(() => {
    setMainContactData({
      dynamicFields: [
        {contactFieldId: 1, value: email},
        {contactFieldId: 2, value: mobile},
      ],
      firstName: firstname === 'default' ? '' : firstname,
      lastName: lastname === 'default' ? '' : lastname,
      title: position === 'default' ? '' : position,
      attn: '',
    })
  }, [email, firstname, lastname, mobile, position])

  useEffect(() => {
    setAddress()
    setContact()
  }, [])

  //   const [isCompanyDetailModal, setCompanyDetailModal] = useState(false)

  const addressStyles = {
    container: (styles: any) => ({
      ...styles,
      height: 'auto',
      fontSize: '0.75rem',
      color: 'black',
      padding: '0px',
      width: 'inherit',
    }),
    control: (styles: any) => ({
      ...styles,
      fontSize: '0.75rem',
      color: 'black',
      padding: '0px',
      height: '16px',
      borderRadius: 5,
      overflow: 'hidden',
      borderColor: 'rgb(229, 229, 229)',
    }),
    option: (styles: any) => {
      return {
        ...styles,
        fontSize: '0.75rem',
        color: 'black',
        padding: '0px',
      }
    },
    input: (styles: any) => ({
      ...styles,
      resize: 'none',
      width: '100%',
      outline: 'none',
      border: 'none',
      fontSize: '0.75rem',
      color: 'black',
      overflow: 'hidden',
      margin: '0 6px',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      padding: '0px',
      display: 'none',
    }),
    menu: (styles: any) => ({
      padding: '0px',
      ...styles,
    }),
    menuList: (styles: any) => ({
      padding: '0px',
      ...styles,
    }),
    singleValue: (styles: any) => {
      return {
        ...styles,
        fontSize: '0.75rem',
        color: 'black',
        margin: '0px 6px',
        overflow: 'hidden',
        padding: '0px',
        // transform: 'translateY(0)',
        // margin: 0,
      }
    },
    multiValue: (styles: any) => {
      return {
        padding: '0px',
        ...styles,
      }
    },
    multiValueLabel: (styles: any) => {
      return {
        padding: '0px',
        ...styles,
      }
    },
    indicatorSeparator: (styles: any) => {
      return {
        ...styles,
        padding: '0px',
        display: 'none',
      }
    },
    dropdownIndicator: (styles: any) => {
      return {
        ...styles,
        padding: '0px',
        display: 'none',
      }
    },
  }

  const onMainContactInputChange = (e: any) =>
    setMainContactData({
      ...mainContactData,
      [e.target.name]: e.target.value,
    })

  const onMainContactDynamicFieldValueChange = (e: any, i: any) => {
    const _mainContactData = {...mainContactData}
    _mainContactData.dynamicFields.map(() => {
      _mainContactData.dynamicFields[i].value = e.target.value

      return _mainContactData
    })
    setMainContactData(_mainContactData)
  }

  const onMainContactDynamicFieldIdChange = (e: any, i: any) => {
    const _mainContactData = {...mainContactData}
    _mainContactData.dynamicFields.map(() => {
      _mainContactData.dynamicFields[i].contactFieldId = parseInt(
        e.target.value,
      )
      return _mainContactData
    })
    setMainContactData(_mainContactData)
  }

  const onMainContactDynamicFieldAddClick = (e: any) => {
    e.preventDefault()
    const _mainContactData = {...mainContactData}
    _mainContactData.dynamicFields.push({contactFieldId: 1, value: ''})
    setMainContactData(_mainContactData)
  }

  const onMainContactDynamicFieldRemoveClick = (e: any, i: any) => {
    e.preventDefault()
    const _mainContactData = {...mainContactData}

    _mainContactData.dynamicFields.splice(i, 1)
    setMainContactData(_mainContactData)
  }

  const onMainContactSubmit = () => {
    if (
      mainContactData.firstName.length === 0 ||
      mainContactData.lastName.length === 0 ||
      mainContactData.title.length === 0
    ) {
      setMainContactError('Please fill all the main contact details!')

      return false
    }
    setMainContactError('')

    return true
  }

  const handlePhysAddressDropChange = (item: any) => {
    setPhysAddressValue(item)
    Geocode.setApiKey(REACT_APP_GOOGLE_KEY)
    Geocode.setLanguage('en')
    Geocode.fromAddress(item.label).then(async (response) => {
      setPhysAddress_loading(true)
      const responseFromHelper = await addressRemapper(response)
      //

      const _aphysicalAddressData = {
        ...physicalAddressData,
        ...responseFromHelper,
      }

      const {address, city, countryId, lat, lng, placeId, state, zip, suburb} =
        _aphysicalAddressData
      const {setValue} = physicalAddressHandler

      setValue('address', address)
      setValue('city', city)
      setValue('countryId', countryId)
      setValue('lat', lat)
      setValue('lng', lng)
      setValue('placeId', placeId)
      setValue('state', state)
      setValue('zip', zip)
      setValue('suburb', suburb)

      setPhysAddress_loading(false)
    })
  }

  const handleAddNewContact = (e: any) => {
    if (
      !onMainContactSubmit() &&
      !physicalAddressHandler.isValid(physicalAddressHandler.errors)
    ) {
      return
    }
    const {defaultContact} = getAccumulatedContacts(mainContactData, null)

    const address = finalAddressRemapper(physicalAddressData)

    const _requestData: any = {
      address,
      contact: defaultContact,
    }

    const updateAddressandContactCallback = () => {
      dispatch(getCompanyDetails())
      setCompanyDetailModal(false)
    }
    dispatch(
      updateCompanyAddressAndContact(
        _requestData,
        updateAddressandContactCallback,
        toast,
      ),
    )
  }

  return (
    <Modal
      isActive={isCompanyDetailModal}
      size="lg"
      toggleModal={() => setCompanyDetailModal(false)}
      title="New Contact"
    >
      <div className="flex ">
        <div className="flex-1 w-full p-10">
          {/* //main contact */}
          <div className="mb-32">
            <DescriptionBox
              title={
                <div className="text-sm text-gray-700 ml-20">
                  <h4 className="font-bold text-md">Person</h4>
                </div>
              }
            >
              {mainContactError && (
                <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                  {mainContactError}
                </p>
              )}
              <table className="w-full ml-20">
                <tbody>
                  <tr>
                    <td>
                      <div className="text-sm text-gray-400">First Name:</div>
                    </td>
                    <td>
                      <Input
                        name="firstName"
                        size="sm"
                        containerClass="pb-0"
                        className="text-sm"
                        value={mainContactData.firstName}
                        onChange={(e: any) =>
                          setMainContactData({
                            ...mainContactData,
                            [e.target.name]: capitalizeFirstLetter(
                              e.target.value,
                            ),
                          })
                        }
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text-sm text-gray-400">Last Name:</div>
                    </td>
                    <td>
                      <Input
                        name="lastName"
                        size="sm"
                        containerClass="pb-0"
                        className="text-sm"
                        value={mainContactData.lastName}
                        onChange={(e: any) =>
                          setMainContactData({
                            ...mainContactData,
                            [e.target.name]: capitalizeFirstLetter(
                              e.target.value,
                            ),
                          })
                        }
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td width="40%">
                      <div className="text-sm text-gray-400">
                        Title/Position
                      </div>
                    </td>
                    <td width="50%">
                      <Input
                        name="title"
                        size="sm"
                        containerClass="pb-0"
                        className="text-sm"
                        value={mainContactData.title}
                        onChange={onMainContactInputChange}
                      />
                    </td>
                    <td></td>
                  </tr>
                  {mainContactData.dynamicFields.map((_, id) => {
                    return (
                      <tr key={id}>
                        <td>
                          <div className="text-sm text-gray-400">
                            <Select
                              name="contactFieldId"
                              options={contactTypeOptions}
                              size="sm"
                              containerClass="pb-0"
                              className="text-sm"
                              value={
                                mainContactData.dynamicFields[id].contactFieldId
                              }
                              onChange={(e: any) =>
                                onMainContactDynamicFieldIdChange(e, id)
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <Input
                            name="value"
                            size="sm"
                            containerClass="pb-0"
                            className="text-sm"
                            value={mainContactData.dynamicFields[id].value}
                            onChange={(e: any) =>
                              onMainContactDynamicFieldValueChange(e, id)
                            }
                          />
                        </td>
                        <td>
                          <div className="flex">
                            {mainContactData.dynamicFields[id].value.length ===
                            0 ? (
                              <span className="cursor-not-allowed text-gray-400">
                                <HiPlusSm />
                              </span>
                            ) : mainContactData.dynamicFields.length ===
                              id + 1 ? (
                              <span className="cursor-pointer">
                                <HiPlusSm
                                  onClick={onMainContactDynamicFieldAddClick}
                                />
                              </span>
                            ) : (
                              <span className="cursor-pointer">
                                <HiMinusSm
                                  onClick={(e: any) =>
                                    onMainContactDynamicFieldRemoveClick(e, id)
                                  }
                                />
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </DescriptionBox>
          </div>
        </div>
        <div className="flex-1 w-full p-10">
          {/* //physical address */}
          <div className="mb-32">
            <DescriptionBox
              title={
                <div className="text-sm text-gray-700 ml-20">
                  <h4 className="flex font-bold text-md justify-between">
                    Address
                    {physAddress_loading && <Loading small />}
                  </h4>
                </div>
              }
            >
              {false &&
                physicalAddressHandler.modified.address &&
                physicalAddressHandler.errors.address && (
                  <p className="mx-20 bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                    {physicalAddressHandler.modified.address &&
                      physicalAddressHandler.errors.address}
                  </p>
                )}
              <table className="w-full ml-20">
                <tbody>
                  <tr>
                    <td>
                      <div className="text-sm text-gray-400">Address:</div>
                    </td>
                    <td>
                      <Input
                        name="address"
                        size="sm"
                        containerClass="pb-0"
                        value={physicalAddressData.address}
                        className="text-sm hidden"
                        onChange={physicalAddressHandler.onChange}
                      />
                      <GooglePlacesAutocomplete
                        apiKey={REACT_APP_GOOGLE_KEY}
                        selectProps={{
                          value: physAddressValue,
                          onChange: handlePhysAddressDropChange,
                          styles: addressStyles,
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text-sm text-gray-400">Suburb:</div>
                    </td>
                    <td>
                      <Input
                        name="suburb"
                        size="sm"
                        containerClass="pb-0"
                        value={physicalAddressData.suburb}
                        className="text-sm"
                        onChange={physicalAddressHandler.onChange}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text-sm text-gray-400">City:</div>
                    </td>
                    <td>
                      <Input
                        name="city"
                        size="sm"
                        containerClass="pb-0"
                        className="text-sm"
                        value={physicalAddressData.city}
                        onChange={physicalAddressHandler.onChange}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text-sm text-gray-400">
                        State / Region:
                      </div>
                    </td>
                    <td>
                      <Input
                        name="state"
                        size="sm"
                        containerClass="pb-0"
                        className="text-sm"
                        value={physicalAddressData.state}
                        onChange={physicalAddressHandler.onChange}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="text-sm text-gray-400">
                        Zip / Post Code:
                      </div>
                    </td>
                    <td>
                      <Input
                        name="zip"
                        size="sm"
                        containerClass="pb-0"
                        className="text-sm"
                        value={physicalAddressData.zip}
                        onChange={physicalAddressHandler.onChange}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td width="40%">
                      <div className="text-sm text-gray-400">Country:</div>
                    </td>
                    <td width="50%">
                      <Select
                        name="countryId"
                        options={countryOptions}
                        placeholder="Select Country"
                        size="sm"
                        containerClass="pb-0"
                        className="text-sm"
                        value={physicalAddressData.countryId}
                        // defaultValue={physicalAddressData.countryId}
                        onChange={physicalAddressHandler.onChange}
                      />
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </DescriptionBox>
          </div>
        </div>
      </div>

      <br></br>
      <div className="border-b-1 my-6 border-gray-200" />
      <div className="flex justify-end px-16 py-10">
        <button
          className="p-6 rounded-sm cursor-pointer text-sm border-1 hover:bg-gray-300 border-gray-200 bg-gray-200 px-22"
          onClick={() => setCompanyDetailModal(false)}
        >
          Cancel
        </button>
        <div className="ml-16">
          <button
            className="p-6 rounded-sm cursor-pointer text-sm text-white hover:bg-blue-400 bg-blue-300 px-22"
            onClick={(e) => handleAddNewContact(e)}
          >
            Save Contact
          </button>
        </div>
      </div>
    </Modal>
  )
}
