import React from 'react'

interface ProgressBarProps {
  progressPercentage: number
  width?: string
  height?: string
  containerBackground?: string
  background?: string
  id?: string
}

export const ProgressBar = ({
  progressPercentage,
  height = '18px',
  width = '100px',
  id = 'progress-container'
}: ProgressBarProps) => {
  return (
    <div
      id={id}
      className="relative flex justify-center items-center rounded-sm overflow-hidden"
      style={{width, height}}
    >
      <span
        className="absolute text-black font-bold text-sm"
        style={{textShadow: '0 0 5px white'}}
      >
        {progressPercentage}%
      </span>
      <progress
        max={100}
        value={Number(progressPercentage ?? 0)}
        className="progress-bar h-[18px] [&::-webkit-progress-bar]:rounded-lg [&::-webkit-progress-value]:rounded-lg   [&::-webkit-progress-bar]:bg-gray-200 [&::-webkit-progress-value]:bg-green-500 [&::-moz-progress-bar]:bg-green-500"
      />
    </div>
  )
}
