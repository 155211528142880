import {useContext} from 'react'
import {useDispatch} from 'react-redux'
import {ActiveLink, useNavigation} from 'react-auth-navigation'

import {Loading} from 'app/common'
import {getInvoicesAction} from 'redux-src'
import {InvoicingContext} from '../../invoice.page'

export type FlowHeadItem = {
  name: string
  icon: React.ReactNode
  url: string
}

export const InvoiceFlowHeadComponent = ({
  firstItem,
  secondItem
}: {
  firstItem: FlowHeadItem
  secondItem?: FlowHeadItem
}) => {
  const {params} = useNavigation()
  const {projectId} = params as any
  const dispatch = useDispatch()
  const {
    loader: {isInvoiceLoading, invoiceDetailLoading},
    activeRoute: {isInvoiceDetailRoute, setInvoiceDetailRoute}
  } = useContext(InvoicingContext)

  return (
    <div className="inline-flex flex-row h-[38px] my-10 w-full bg-blue-100">
      <span>
        <ActiveLink
          onClick={() => {
            dispatch(getInvoicesAction(projectId))
            setInvoiceDetailRoute(false)
          }}
          to={firstItem.url}
          exact={firstItem.url === '/invoicing' ? true : false}
          activeClassName={
            !isInvoiceDetailRoute ? 'bg-blue-300 highlight' : 'bg-gray-100'
          }
          className="inline-flex items-center text-sm text-blue-300 h-full"
        >
          <span className="p-10 text-lg relative mr-10 pl-20 ml-10">
            <span className="flex items-center justify-center absolute inset-0">
              {firstItem.icon}
            </span>
          </span>
          <span className="text-gray-800 group-hover:text-white pr-20">
            {firstItem.name}
          </span>
          <span
            className={`arrow-block-${!isInvoiceDetailRoute ? 'end' : 'begin'}`}
          ></span>{' '}
          {/* <span className="w-[0] h-[0] border-t-[5px] border-l-[10px] border-b-[5px] border-[transparent] bg-blue-300"></span> */}
        </ActiveLink>
      </span>

      {!!secondItem && isInvoiceDetailRoute && (
        <span>
          <ActiveLink
            to={secondItem.url}
            exact={secondItem.url === '/invoicing' ? true : false}
            activeClassName="bg-blue-300 highlight"
            className="inline-flex items-center text-sm bg-blue-300 highlight h-full"
          >
            <span className="text-gray-800 group-hover:text-white px-20">
              {isInvoiceLoading || invoiceDetailLoading ? (
                <Loading medium />
              ) : (
                secondItem.name
              )}
            </span>
            <span className="arrow-block-end"></span>{' '}
          </ActiveLink>
        </span>
      )}
    </div>
  )
}
