import { allSelectOptions } from './../../helpers/selectOptions.helper'
import { GET_SELECT_OPTIONS } from '../actions'

export interface SelectOptionReduxType {
  selectOptions: typeof allSelectOptions
  loading: boolean
  error: boolean
}

const initalState: SelectOptionReduxType = {
  selectOptions: undefined,
  loading: false,
  error: false,
}

export function selectOptionReducer(state = initalState, action: any) {
  const { type, payload } = action

  //

  switch (type) {
    case GET_SELECT_OPTIONS.LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_SELECT_OPTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        selectOptions: payload,
      }
    case GET_SELECT_OPTIONS.ERROR:
      return { ...state, loading: false, error: payload }

    default:
      return state
  }
}
