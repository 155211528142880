import React from 'react'
import { useNavigation } from 'react-auth-navigation'

import { Button } from 'app/common'

export const LandingSection = () => {
  const { navigation } = useNavigation()
  const { routes, navigate } = navigation
  return (
    <div className="hidden lg:block relative w-0 flex-1">
      <div className="absolute left-20 right-20 top-20 z-10 flex items-center justify-end">
        <div className="py-10 px-20">
          <ul className="flex items-center">
            <li>
              <div className="text-sm text-gray-500 hover:text-blue-300 block px-16 cursor-pointer">
                About
              </div>
            </li>
            <li>
              <div className="text-sm text-gray-500 hover:text-blue-300 block px-16 cursor-pointer">
                Privacy Policies
              </div>
            </li>
            <li>
              <div className="text-sm text-gray-500 hover:text-blue-300 block px-16 cursor-pointer">
                Pricing
              </div>
            </li>
            <li>
              <div className="text-sm text-gray-500 hover:text-blue-300 block px-16 cursor-pointer">
                FAQs
              </div>
            </li>
          </ul>
        </div>
        <div className="flex items-center">
          <Button
            type="link"
            title="Login"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              navigate(routes['Root'].path)
            }}
            buttonColor="bg-white text-blue-400 hover:opacity-95"
            className="mr-16 cursor-pointer text-blue-400"
            size="sm"
          />
          <Button
            type="link"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              navigate(routes['Register'].path)
            }}
            title="Register"
            size="sm"
            className=" cursor-pointer"
          />
        </div>
      </div>
      <img
        className="absolute inset-0 h-full w-full object-cover"
        src="https://images.pexels.com/photos/1181345/pexels-photo-1181345.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        alt=""
      />
    </div>
  )
}
