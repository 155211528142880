import classNames from 'classnames'
import {Loading} from '..'

export const Button = ({
  children,
  type = 'button',
  //   target,
  size = 'sm',
  title,
  className,
  style,
  buttonStyle,
  buttonColor,
  onClick,
  isDisabled,
  loading,
  ...rest
}: Com.ButtonProps) => {
  let btnColor = 'text-white bg-blue-300 hover:bg-blue-400'
  let styleType = ''
  if (buttonColor) {
    btnColor = buttonColor
  }
  if (buttonStyle === 'ghost') {
    styleType = 'bg-transparent text-gray-400 border-1 border-gray-300'
  }
  if (type === 'link') {
    return (
      <div
        className={`cursor-pointer transition duration-500 ease-in-out inline-block capitalize font-medium text-sm rounded-sm ${btnColor} ${className} ${
          size === 'sm' ? 'px-22 py-6' : 'px-32 py-16'
        }`}
        onClick={onClick as any}
      >
        {title ?? children}
      </div>
    )
  } else {
    return (
      <button
        {...rest}
        type={type as 'button' | 'submit' | 'reset'}
        onClick={onClick}
        className={`w-[max-content] inline-flex flex-row flex-nowrap whitespace-nowrap break-normal gap-10 cursor-pointer transition duration-500 ease-in-out capitalize font-medium tracking-[0.5px] rounded-sm ${btnColor} ${styleType} ${
          size === 'xs'
            ? 'text-xs px-8 py-4'
            : 'sm'
            ? 'text-sm px-22 py-6'
            : 'text-md px-32 py-16'
        } ${className}`}
        style={
          isDisabled || loading
            ? {
                pointerEvents: 'none',
                cursor: 'not-allowed',
                backgroundColor: 'lightBlue',
                ...style,
              }
            : {...style}
        }
        disabled={isDisabled || loading}
      >
        {loading ? <Loading small /> : null}
        {title ?? children}
      </button>
    )
  }
}

export const DefaultButton = ({
  title,
  leftIcon,
  rightIcon,
  className = 'hover:bg-gray-100 bg-white text-black',
  disabled,
  loading,
  ...rest
}: {
  title: string
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  loading?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={classNames(
        'border-1 border-gray-200 p-4 flex items-center gap-4 px-16 rounded-sm ',
        disabled && 'text-gray-300 hover:bg-white',
        className,
      )}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? <Loading small /> : leftIcon}
      {title}
      {rightIcon}
    </button>
  )
}
