import {ReactNode} from 'react'

export const MessageBox = ({
  message,
  error,
}: {
  message: ReactNode
  error?: boolean
}) => {
  return (
    <div
      className={`mt-10 border-1 w-full h-[max-content] rounded-sm mb-20 flex justify-center items-center flex-col ${
        error
          ? ' bg-[#fa161648] border-[#f84d3a]'
          : 'border-blue-150 bg-blue-100'
      }`}
    >
      <div className={`py-40 ${error ? 'text-[#f84d3a]' : 'text-gray-400'}`}>
        {message}
      </div>
    </div>
  )
}
