import { useFormInput } from 'use-form-input'
import { useNavigation } from 'react-auth-navigation'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Logo, Input, Loading } from '../../common'
import { forgetPassword } from '../../../redux-src'
import { emailValidation } from '../../../utils'

export const ForgotPasswordPage = () => {
  const dispatch = useDispatch()
  const { forgetPasswordLoader } = useSelector((state: any) => state.login)

  const { navigation } = useNavigation()
  const { routes, navigate } = navigation

  const [data, { onChange, errors, onSubmit, modified }] = useFormInput(
    {
      emailAddress: '',
    },
    (data) => {
      const redirectPreconfirmation = navigate(
        routes['Forgot Password PreConfirmation'].path
      )

      dispatch(forgetPassword(data.emailAddress, redirectPreconfirmation))
    },
    (data) => {
      const errors: any = {}

      if (data.emailAddress.length === 0) {
        errors.emailAddress = 'Empty email address!'
      } else if (emailValidation(data.emailAddress)) {
        errors.emailAddress = 'Enter a valid email address!'
      }

      return errors
    }
  )

  return (
    <div className="px-16 lg:px-40 xl:px-56 w-full">
      <div className="-mx-16  flex items-center   min-h-screen justify-center">
        <div className="w-full sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-4/12 px-16  py-40">
          <div className="mb-32 w-full text-center">
            <div className="flex justify-center mb-32">
              <Logo />
            </div>
            <div className="bg-white w-full py-20 px-20 shadow-default">
              {forgetPasswordLoader ? (
                <Loading />
              ) : (
                <>
                  <h2 className="mb-6 text-2xl font-bold font-merriweather text-gray-900">
                    Reset your password
                  </h2>
                  <p className="mb-32">Enter your email address</p>
                  {/* {errorMessage && (
                    <div className="px-16">
                      <p className="bg-red-100 mb-10 px-16 py-4 rounded-sm text-red-400 text-sm">
                        {errorMessage}
                      </p>
                    </div>
                  )} */}
                  <form method="post" onSubmit={onSubmit}>
                    <Input
                      type="text"
                      name="emailAddress"
                      placeholder="Enter email address"
                      value={data.emailAddress}
                      error={modified.emailAddress && errors.emailAddress}
                      onChange={onChange}
                    />
                    <div className="px-16 mt-10">
                      <Button
                        type="submit"
                        title="Reset Password"
                        size="sm"
                        className="block w-full"
                      />
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
