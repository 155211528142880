import {useEffect, useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import colors from 'tailwindcss/colors'
import {useDispatch} from 'react-redux'

import {Button, Input} from 'app/common'
import {UserList} from '../../components'
import {AddNewUserModal} from './components'
import {SettingsHeader} from 'app/pages/settings/components'

import {getProjectUserList} from 'redux-src'
import {useNavigation} from 'react-auth-navigation'
import {useDebounceValue} from 'hooks'

export const ProjectUsersMainPage = () => {
  const [openAddNewUserModal, setOpenAddNewUserModal] = useState(false)
  const [filterData, setFilterData] = useState<string>()

  const dispatch = useDispatch()
  const {params} = useNavigation()
  const debounceFilterValue = useDebounceValue(filterData)
  const {projectId} = params as any

  useEffect(() => {
    dispatch(getProjectUserList(projectId, 1, 10, debounceFilterValue))
  }, [dispatch, debounceFilterValue, projectId])

  return (
    <div className="flex flex-col min-h-[70vh]">
      {/* HEADER SECTION */}
      <SettingsHeader
        title="Users"
        subHeader="The Users list allows you to manage your users, and provide
          access to all their details and permissions."
      />

      <div className="flex justify-between items-center  py-10">
        {/* SEARCH INPUT BOX */}
        <div className="flex flex-col gap-4">
          <div>Search by users</div>
          <Input
            prepend={<FaSearch size={16} color={colors.gray[500]} />}
            containerClass="w-[300px]"
            placeholder="Search users..."
            // size="sm"
            className="rounded-tr-sm rounded-br-sm"
            onChange={(e) => {
              setFilterData(e.target.value)
            }}
          />
        </div>
        <Button
          onClick={() => setOpenAddNewUserModal(true)}
          title="Add New User"
          size="sm"
        />
      </div>

      <div className="my-10 border-b-1 border-gray-200" />

      {/* USER LIST SECTION */}
      <UserList />

      {/* ADD NEW USER MODAL */}
      <AddNewUserModal
        isActive={openAddNewUserModal}
        close={() => setOpenAddNewUserModal(false)}
      />
    </div>
  )
}
