import ReactDOM from 'react-dom'
import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'

import reducers from './redux-src/reducers'
import AppWithRouter from './app'

import './sass/main.scss'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
// import {GridLayout} from 'app/components'
// import { Loading } from './app/common'
// import { PersistGate } from 'redux-persist/integration/react'
// import persistStore from 'redux-persist/lib/persistStore'

export const store = createStore(reducers, applyMiddleware(thunk))
// const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    {/* <GridLayout /> */}
    {/* <PersistGate loading={<Loading />} persistor={persistor}> */}
    <AppWithRouter />
    {/* </PersistGate> */}
  </Provider>,
  document.querySelector('#root'),
)
