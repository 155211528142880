interface AddNewUserSelectTypes {
  id: number
  label: string
  value: string
  disabled?: boolean
}

export const addNewUserSelectOptions: AddNewUserSelectTypes[] = [
  {id: 1, label: 'Full User', value: 'Full User'},
  {id: 2, label: 'Sub Contractor', value: 'Sub Contractor'},
  {id: 3, label: 'Operative', value: 'operative'}
]

export const formsUserOptions: AddNewUserSelectTypes[] = [
  {id: 1, label: 'System Admin', value: 'System Admin', disabled: true},
  {id: 2, label: 'Full User', value: 'Full User'},
  {id: 3, label: 'Sub Contractor', value: 'Sub Contractor'},
  {id: 4, label: 'Operative', value: 'operative'}
]
