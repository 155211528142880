import {Button} from 'app/common'
import {FormSectionPreview} from 'app/pages/settings/pages/forms/components'
import {useEffect} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {BiError} from 'react-icons/bi'
import {useDispatch, useSelector} from 'react-redux'
import {
  getProjectFormDetailsByProjectFormId,
  updateFormFromApi
} from 'redux-src'

export const FormBuilderWebViewPage = () => {
  const {forms}: RT.FormBuilderReduxType = useSelector(
    (state: any) => state.formBuilder
  )

  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId, formId} = params as any

  useEffect(() => {
    dispatch(
      getProjectFormDetailsByProjectFormId(projectId, formId, (form) => {
        dispatch(updateFormFromApi(form))
      })
    )
  }, [dispatch, formId, projectId])

  return (
    <div className="h-full bg-white overflow-y-scroll max-h-[100dvh] p-14 relative">
      <div className="flex flex-1 flex-col gap-8">
        <div className="text-lg font-bold mb-8 mt-10 text-gray-600">
          {/* {formTemplateData?.formTemplateTitle} */}
        </div>
        <div className="flex flex-1 flex-col gap-8 mb-20">
          {forms?.length > 0 &&
            forms?.map((form) => {
              return (
                <FormSectionPreview
                  key={form.id}
                  formType={form?.formType}
                  id={form?.id}
                  type={'web view'}
                />
              )
            })}
        </div>
      </div>

      <div className="flex justify-between sticky bottom-0 py-20 border-t-1 border-gray-200 bg-white ">
        <div className="flex gap-10 items-center">
          <span className="text-yellow-300">
            <BiError fontSize={14} />
          </span>
          <span className="text-gray-400">
            Don't forget to save your changes
          </span>
        </div>
        <div className="flex flex-row justify-center items-center">
          {/* <span
            className="text-blue-300 cursor-pointer mr-10 capitalize"
            // onClick={undoChanges}
          >
            undo changes
          </span> */}
          <Button
            title="Submit"
            onClick={(e) => {
              // onCreateFormTemplate(e)
            }}
          />
        </div>
      </div>
    </div>
  )
}
