import {useCallback, useEffect} from 'react'
import {TableColumn} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {FaTrash} from 'react-icons/fa'
import {useNavigation} from 'react-auth-navigation'
import {TbDownload, TbEdit, TbPhotoSensor} from 'react-icons/tb'
import {saveAs} from 'file-saver'

import {DataTable} from 'app/components'
import {ToolTip} from 'app/common'

import {getAssetUrl} from 'helpers'

import {
  deleteProjectPlan,
  getProjectPlanList
} from 'redux-src/actions/projectPlan.action'

export const FloorMapList = ({
  viewPlan,
  editPlan,
  isTicketSection = false,
  selectedPlan
}: {
  viewPlan: (plan: Api.ProjectPlanIndividual) => void
  editPlan?: (plan: Api.ProjectPlanIndividual) => void
  isTicketSection?: boolean
  selectedPlan?: Api.ProjectPlanIndividual
}) => {
  const dispatch = useDispatch()

  const {params} = useNavigation()
  const {projectId} = params as any

  const {loading, projectPlanTotal, projectPlanList}: RT.ProjectPlanReduxType =
    useSelector((state: any) => state.projectPlan)

  const projectPlanCallback = useCallback(
    (page: number = 1, limit: number = 100) => {
      dispatch(getProjectPlanList(projectId, {page, limit}))
    },
    [dispatch, projectId]
  )

  console.log({projectPlanList})

  useEffect(() => {
    projectPlanCallback()
  }, [projectPlanCallback])

  const columns: TableColumn<Api.ProjectPlanIndividual>[] = [
    {
      name: 'Project Plan Name',
      selector: (row) => row.project_plan_details.name,
      sortable: true,
      cell: (row) => (
        <div
          className={`flex badge items-center cursor-pointer ${
            selectedPlan?.project_plan_details?.id ===
            row?.project_plan_details?.id
              ? 'font-bold'
              : ''
          }`}
          onClick={() => {
            viewPlan(row)
          }}
        >
          <div className="text-blue-300">{row.project_plan_details.name}</div>
        </div>
      )
    },
    {
      name: 'Action',
      selector: (row) => row.project_plan_details.id,
      right: true,
      sortable: true,
      cell: (row) => (
        <div className="flex badge items-center gap-10 justify-end">
          <ToolTip text="View Project Plan" top>
            <button
              onClick={() => {
                viewPlan(row)
              }}
              className={`px-4 py-4 text-green-700 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-green-200  flex items-center hover:text-green-500 transition-all duration-200 ease-in ${
                selectedPlan?.project_plan_details?.id ===
                row?.project_plan_details?.id
                  ? 'bg-gray-100'
                  : 'bg-green-100'
              } `}
              disabled={
                selectedPlan?.project_plan_details?.id ===
                row?.project_plan_details?.id
              }
            >
              <span className="text-left inline-flex cursor-pointer gap-6 items-center justify-centerpx-6">
                View
                <TbPhotoSensor size={12} />
              </span>
            </button>
          </ToolTip>
          {!isTicketSection && (
            <>
              <ToolTip text="Edit Project Plan" top>
                <button
                  onClick={() => {
                    editPlan(row)
                  }}
                  className="px-4 py-4 text-blue-500 bg-blue-150 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-blue-100 focus:bg-gray-100 flex items-center hover:text-blue-300 transition-all duration-200 ease-in"
                >
                  <span className="text-left inline-flex cursor-pointer gap-6 items-center justify-centerpx-6">
                    Edit
                    <TbEdit size={12} />
                  </span>
                </button>
              </ToolTip>
              <ToolTip text="Delete Project Plan" top>
                <button
                  onClick={() =>
                    dispatch(
                      deleteProjectPlan(
                        row.project_plan_details.project_id,
                        row.project_plan_details.id,
                        () => {
                          projectPlanCallback()
                        }
                      )
                    )
                  }
                  className="px-4 py-4 text-red-400 bg-red-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-red-100 focus:bg-gray-100 flex items-center hover:text-red-500 transition-all duration-200 ease-in"
                >
                  <span className="text-left inline-flex cursor-pointer gap-6 items-center justify-centerpx-6">
                    Delete
                    <FaTrash size={12} />
                  </span>
                </button>
              </ToolTip>
            </>
          )}
          <ToolTip text="Download Project Plan PDF" left>
            <button
              onClick={() =>
                saveAs(
                  getAssetUrl(row?.asset_details),
                  `${row?.project_plan_details?.name}`
                )
              }
              className="px-4 py-4 text-orange-700 bg-orange-100 shadow-sm rounded-sm border-1 border-orange-500 hover:bg-orange-200 focus:bg-gray-100 flex items-center hover:text-orange-900 transition-all duration-200 ease-in"
            >
              <span className="text-left inline-flex cursor-pointer gap-6 items-center justify-centerpx-6">
                Download
                <TbDownload size={12} />
              </span>
            </button>
          </ToolTip>
        </div>
      )
    }
  ]

  return (
    <DataTable
      columns={columns}
      customStyles={{
        cells: {
          style: {
            ':last-child': {
              width: '50%',
              minWidth: '50%',
              maxWidth: '50%'
            }
          }
        }
      }}
      data={projectPlanList}
      persistTableHead
      dense
      striped
      pagination
      loading={loading}
      fetchData={projectPlanCallback}
      totalRows={projectPlanTotal}
    />
  )
}
