import {GET_NOTICE} from 'redux-src/actions'

const initialState: RT.NoticeReduxType = {
  isNoticeError: false,
  isNoticeLoading: false,
  notice: undefined,
}

export const noticeReducer = (state = initialState, action: any) => {
  const {type, payload} = action
  switch (type) {
    case GET_NOTICE.LOADING:
      return {
        ...state,
        isNoticeLoading: true,
        isNoticeError: false,
      }

    case GET_NOTICE.SUCCESS:
      return {
        ...state,
        isNoticeLoading: false,
        isNoticeError: false,
        notice: payload,
      }
    case GET_NOTICE.ERROR:
      return {
        ...state,
        isNoticeLoading: false,
        isNoticeError: true,
      }

    default:
      return state
  }
}
