import {
  CREATE_MATERIAL_ELEMENT_GROUP,
  CREATE_MATERIAL_ELEMENT_SETTING,
  GET_MATERIAL_ELEMENT_SETTING,
  UPDATE_MATERIAL_ELEMENT_SETTING,
  DELETE_MATERIAL_ELEMENT_SETTING,
  UPDATE_MATERIAL_ELEMENT_GROUP,
  DELETE_MATERIAL_ELEMENT_GROUP,
  GET_MATERIAL_ELEMENT_GROUP,
  ATTACH_MATERIAL_ELEMENT_TO_GROUP,
  REMOVE_MATERIAL_ELEMENT_FROM_GROUP,
  GET_MATERIAL_GROUP_BY_ID,
  GET_MATERIAL_ELEMENT_AND_GROUP_LIST,
  GET_MATERIAL_ELEMENT_UNIT_LIST,
  UPLOAD_MATERIAL_ELEMENT_FILE,
} from './actionTypes.action'

import {api, APIS, LOCAL_BASE_URL} from '../../config'
import {toast} from 'app/common'

import {errorHandler} from '../../utils'
import {getParsedUrl} from 'helpers'

// Get material element units

export function getMaterialUnitListAction(successCallback?: () => void) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_MATERIAL_ELEMENT_UNIT_LIST.LOADING})

      res = await api<Api.Base<Api.UnitList>>(`${APIS.materialElement}/units`)

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_MATERIAL_ELEMENT_UNIT_LIST.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_MATERIAL_ELEMENT_UNIT_LIST.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_MATERIAL_ELEMENT_UNIT_LIST.ERROR})

      return 0
    }
  }
}

export function createMaterialElementAction(
  body: {
    name: string
    unitName: string
    numberPerUnit: number
    supplierId: number
    cost: number
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_MATERIAL_ELEMENT_SETTING.LOADING})

      res = await api<Api.Base<any>>(`${APIS.materialElement}`, 'POST', body)

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({type: CREATE_MATERIAL_ELEMENT_SETTING.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CREATE_MATERIAL_ELEMENT_SETTING.ERROR,
          payload: message,
        })
        toast.error(message)
        return 0
      }
    } catch (message) {
      dispatch({type: CREATE_MATERIAL_ELEMENT_SETTING.ERROR, payload: message})

      return 0
    }
  }
}

export function getMaterialElementAction(
  params: {page: number; limit: number; search?: string} = {
    page: 1,
    limit: 10,
    search: undefined,
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_MATERIAL_ELEMENT_SETTING.LOADING})

      res = await api<Api.Base<Api.MaterialElementList>>(
        getParsedUrl(`${APIS.materialElement}`, params),
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_MATERIAL_ELEMENT_SETTING.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_MATERIAL_ELEMENT_SETTING.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_MATERIAL_ELEMENT_SETTING.ERROR})

      return 0
    }
  }
}

export function updateMaterialElementAction(
  materialElementId: number,
  body: {
    name?: string
    unitName?: string
    numberPerUnit?: number
    supplierId?: number
    cost?: number
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_MATERIAL_ELEMENT_SETTING.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.materialElement}/${materialElementId}`,
        'PATCH',
        body,
      )

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_MATERIAL_ELEMENT_SETTING.SUCCESS, payload: data})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: UPDATE_MATERIAL_ELEMENT_SETTING.ERROR,
          payload: message,
        })

        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_MATERIAL_ELEMENT_SETTING.ERROR})

      return 0
    }
  }
}

export function deleteMaterialElementAction(
  materialElementId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_MATERIAL_ELEMENT_SETTING.LOADING})

      res = await api<any>(
        `${APIS.materialElement}/${materialElementId}`,
        'DELETE',
      )

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: DELETE_MATERIAL_ELEMENT_SETTING.SUCCESS})
        successCallback?.()

        toast.success(message)
        return 1
      } else {
        dispatch({
          type: DELETE_MATERIAL_ELEMENT_SETTING.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: DELETE_MATERIAL_ELEMENT_SETTING.ERROR})
      console.error(e)
      return 0
    }
  }
}

// MATERIAL ELEMENT GROUP SETTING

export function createMaterialElementGroupAction(
  body: {
    name: string
    description: string
    materialElements: Array<{id: number; totalUnits: number}>
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_MATERIAL_ELEMENT_GROUP.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.materialElement}/groups`,
        'POST',
        body,
      )

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_MATERIAL_ELEMENT_GROUP.SUCCESS})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: CREATE_MATERIAL_ELEMENT_GROUP.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: CREATE_MATERIAL_ELEMENT_GROUP.ERROR})

      return 0
    }
  }
}

export function updateMaterialElementGroup(
  groupId: number,
  body: {
    name: string
    description: string
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_MATERIAL_ELEMENT_GROUP.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.materialElement}/groups/${groupId}`,
        'PATCH',
        body,
      )

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_MATERIAL_ELEMENT_GROUP.SUCCESS, payload: data})
        successCallback?.()

        toast.success(message)
        return 1
      } else {
        dispatch({
          type: UPDATE_MATERIAL_ELEMENT_GROUP.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: UPDATE_MATERIAL_ELEMENT_GROUP.ERROR})

      return 0
    }
  }
}

export function deleteMaterialElementGroupAction(
  groupId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_MATERIAL_ELEMENT_GROUP.LOADING})

      res = await api<any>(
        `${APIS.materialElement}/groups/${groupId}`,
        'DELETE',
      )

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: DELETE_MATERIAL_ELEMENT_GROUP.SUCCESS})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: DELETE_MATERIAL_ELEMENT_GROUP.ERROR,
          payload: message,
        })
        errorHandler(res.data, toast)
        return 0
      }
    } catch (e) {
      dispatch({type: DELETE_MATERIAL_ELEMENT_GROUP.ERROR})

      return 0
    }
  }
}

export function getMaterialElementGroupAction(
  params: {page: number; limit: number; search?: string} = {
    page: 1,
    limit: 10,
    search: undefined,
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_MATERIAL_ELEMENT_GROUP.LOADING})

      res = await api<Api.Base<Api.MaterialElementGroupList>>(
        getParsedUrl(`${APIS.materialElement}/groups`, params),
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_MATERIAL_ELEMENT_GROUP.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_MATERIAL_ELEMENT_GROUP.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_MATERIAL_ELEMENT_GROUP.ERROR})

      return 0
    }
  }
}

// MARK: Attach material element to group actions

export function attachMaterialElementAction(
  groupId: number,
  body: {
    materialElements: Array<{id: number; totalUnits: number}>
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ATTACH_MATERIAL_ELEMENT_TO_GROUP.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.materialElement}/groups/${groupId}/attach`,
        'PUT',
        body,
      )

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: ATTACH_MATERIAL_ELEMENT_TO_GROUP.SUCCESS})

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: ATTACH_MATERIAL_ELEMENT_TO_GROUP.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: ATTACH_MATERIAL_ELEMENT_TO_GROUP.ERROR})

      return 0
    }
  }
}

export function removeMaterialElementFromGroup(
  groupId: number,
  body: {
    materialElements: Array<{id: number}>
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_MATERIAL_ELEMENT_FROM_GROUP.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.materialElement}/groups/${groupId}/remove`,
        'DELETE',
        body,
      )

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({
          type: REMOVE_MATERIAL_ELEMENT_FROM_GROUP.SUCCESS,
          payload: data,
        })

        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({
          type: REMOVE_MATERIAL_ELEMENT_FROM_GROUP.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: REMOVE_MATERIAL_ELEMENT_FROM_GROUP.ERROR})

      return 0
    }
  }
}

export function getMaterialElementGroupById(
  groupId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_MATERIAL_GROUP_BY_ID.LOADING})

      res = await api<Api.Base<Api.MaterialElementGroupById>>(
        `${APIS.materialElement}/groups/${groupId}`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_MATERIAL_GROUP_BY_ID.SUCCESS,
          payload: data,
        })

        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_MATERIAL_GROUP_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_MATERIAL_GROUP_BY_ID.ERROR})

      return 0
    }
  }
}

export function getMaterialElementAndGroupList(
  params: {page: number; limit: number; search?: string} = {
    page: 1,
    limit: 10,
    search: undefined,
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_MATERIAL_ELEMENT_AND_GROUP_LIST.LOADING})

      res = await api<Api.Base<Api.MaterialIsGroupList>>(
        getParsedUrl(`${APIS.materialElement}/with-groups`, params),
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_MATERIAL_ELEMENT_AND_GROUP_LIST.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()
        return 1
      } else {
        dispatch({
          type: GET_MATERIAL_ELEMENT_AND_GROUP_LIST.ERROR,
          payload: message,
        })
        return 0
      }
    } catch (e) {
      dispatch({type: GET_MATERIAL_ELEMENT_AND_GROUP_LIST.ERROR})

      return 0
    }
  }
}

// * To upload csv or excel file in order to import as material
export const uploadMaterialElementFile = (
  formData: FormData,
  type: 'csv' | 'excel',
  callback?: () => void,
) => {
  return async (dispatch: any) => {
    dispatch({type: UPLOAD_MATERIAL_ELEMENT_FILE.LOADING})
    let res
    try {
      let endpoint = type === 'csv' ? 'upload-csv' : 'upload-excel'

      res = await api<Api.Base<any>>(
        `${LOCAL_BASE_URL}/material-elements/${endpoint}`,
        'POST',
        formData,
      )

      if (res.data !== undefined) {
        const {success} = res.data
        if (success) {
          dispatch({type: UPLOAD_MATERIAL_ELEMENT_FILE.SUCCESS})
          toast.success('File imported successfully.')
          callback?.()
        } else {
          dispatch({type: UPLOAD_MATERIAL_ELEMENT_FILE.ERROR})
          errorHandler(res.data, toast)
        }
      } else {
        dispatch({type: UPLOAD_MATERIAL_ELEMENT_FILE.ERROR})
        errorHandler(res.data, toast)
      }
    } catch (e) {
      dispatch({type: UPLOAD_MATERIAL_ELEMENT_FILE.ERROR})

      errorHandler(res.data, toast)
    }
  }
}
