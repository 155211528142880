import {useEffect, useMemo, useState} from 'react'
import {useFormInput} from 'use-form-input'
import {TableColumn} from 'react-data-table-component'
import {useSelector, useDispatch} from 'react-redux'

import {DataTable} from 'app/components'
import {Button, Modal, SelectField, toast} from 'app/common'

import {getUserGroupByIdAction} from 'redux-src'

interface AddUsersFromGroupModalProps {
  isModalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  attachUserList?: any
  assignedUserList: Array<{id: number; label: string; value: number}>
}

interface ProjectGroupListType {
  id: number
  groupName: string
  userCount: number | string
}

export const AddUsersFromGroup = ({
  isModalVisible,
  setModalVisible,
  attachUserList,
  assignedUserList
}: AddUsersFromGroupModalProps) => {
  const {projectUserGroupList: projectGroupListFromApi}: RT.ProjectReduxType =
    useSelector((state: any) => state.project)

  const {companyGroupDetails}: RT.UserReduxType = useSelector(
    (state: any) => state.user
  )

  const [addedUsers, setAddedUsers] =
    useState<Array<{id: number; label: string; value: number}>>()

  const [userData, {setValue}] = useFormInput({
    userList: []
  })

  const dispatch = useDispatch()

  const projectUserGroupList = useMemo(() => {
    const remappedList = projectGroupListFromApi?.map(
      (group, index: number) => {
        return {
          id: group?.user_group_details?.id,
          groupName: group?.user_group_details?.name,
          userCount: group?.users_count
        }
      }
    )

    return remappedList
  }, [projectGroupListFromApi])

  useEffect(() => {
    if (!!companyGroupDetails) {
      const filteredUserList = companyGroupDetails?.group_users?.map(
        (user, index) => {
          return {
            id: user?.id,
            label: `${user?.display_name} ${user?.lastname}`,
            value: user?.id
          }
        }
      )

      setAddedUsers((prev: any) => {
        if (!!prev) {
          const tempUserList = [...prev, ...filteredUserList]

          const uniqueUserList = tempUserList?.reduce((acc, curr) => {
            if (acc.length === 0) {
              acc.push(curr)
            }
            if (acc.length > 0) {
              if (!acc?.find((item: any) => item?.id === curr?.id))
                acc.push(curr)
            }

            return acc
          }, [])

          return uniqueUserList
        }

        return filteredUserList
      })
    }
  }, [companyGroupDetails])

  useEffect(() => {
    if (assignedUserList?.length > 0 || addedUsers?.length > 0) {
      const newAddedUsers = addedUsers?.length > 0 ? [...addedUsers] : []
      const newAssignedUserList =
        assignedUserList?.length > 0 ? [...assignedUserList] : []

      const tempAddedUsers = [...newAddedUsers, ...newAssignedUserList]

      const uniqueUserList = tempAddedUsers?.reduce((acc, curr) => {
        if (acc.length === 0) {
          acc.push(curr)
        }
        if (acc.length > 0) {
          if (!acc?.find((item: any) => item?.id === curr?.id)) acc.push(curr)
        }

        return acc
      }, [])

      setValue('userList', uniqueUserList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedUsers, assignedUserList])

  const onModalClose = () => {
    setValue('userList', undefined)
    setAddedUsers(undefined)
  }

  const columns: TableColumn<ProjectGroupListType>[] = [
    {
      name: 'Group Name',
      selector: (row) => row.groupName,
      cell: (row) => {
        return (
          <div className={'text-blue-300 font-medium cursor-pointer'}>
            {row.groupName}
          </div>
        )
      },
      sortable: true
    },
    {
      name: 'Users Count',
      selector: (row) => row.userCount,
      cell: (row) => {
        return (
          <div
            className={
              'text-white  font-bold cursor-pointer bg-blue-300 h-[30px] w-[30px] rounded-full flex items-center justify-center'
            }
          >
            {row.userCount}
          </div>
        )
      },
      sortable: true
    },
    {
      name: 'Actions',
      selector: (row) => row.id,
      sortable: true,
      right: true,
      cell: (row) => {
        return (
          <div className="flex items-center gap-6 justify-end">
            {/* <div className="cursor-pointer flex items-center justify-center hover:text-red-300 transition-all duration-500 ease-in-out">
              Add
            </div> */}
            <Button
              size="sm"
              title="Add Users"
              onClick={() => {
                dispatch(getUserGroupByIdAction(row.id))
              }}
            />
          </div>
        )
      }
    }
  ]

  return (
    <Modal
      onClose={onModalClose}
      toggleModal={setModalVisible}
      isActive={isModalVisible}
      title="Import Users from group"
      size="md"
    >
      <div className="min-h-[35vh] flex flex-col gap-16 m-20">
        {/* {addedUsers?.length > 0 && ( */}
        <div className="flex flex-col gap-8">
          <div className="text-md font-bold text-black">
            Preview Added Users :
          </div>
          <SelectField
            options={addedUsers ?? []}
            multiValue={
              userData?.userList?.length > 0 ? userData?.userList : undefined
            }
            onMultiChangeValue={(value) => {
              setValue('userList', value)
            }}
            isMulti
          />
        </div>
        {/* )} */}
        <div className="flex flex-col gap-8">
          <div className="text-md font-bold text-black">Group List :</div>
          <DataTable columns={columns} data={projectUserGroupList} />
        </div>
        <div className="w-full flex items-end justify-end border border-t-1 border-gray-200 py-10">
          <Button
            title="Save"
            size="sm"
            onClick={() => {
              if (userData?.userList?.length > 0) {
                attachUserList('assignedUserIds', userData?.userList)
                setModalVisible(false)
                onModalClose()
              } else {
                toast.error('Please attach users to save !!')
              }
            }}
            className="flex items-end justify-end"
          />
        </div>
      </div>
    </Modal>
  )
}
