// quotation table headings
export const QUOTE_TABLE_HEADINGS: {
  key: string
  title: string
  width: string
  hidden?: boolean
}[] = [
  {key: 'title', title: 'Name', width: '40%'},
  {key: 'quantity', title: 'Quantity', width: '10%'},
  {key: 'price', title: 'Price', width: '12%'},
  {key: 'tax_percent', title: 'Tax (%)', width: '10%'},
  {key: 'discount_percent', title: 'Discount (%)', width: '12%'},
  {key: 'total_cost', title: 'Total', width: '15%'}
]

// quote line item
export const QUOTE_LINE_ITEM = [
  {
    name: 'New sinks',
    quantity: '2.00',
    cost: '12.00',
    price: '15.00',
    markup: '25.00',
    tax: '21',
    discount: '0',
    total: '30'
  },
  {
    name: 'New sinks',
    quantity: '2.00',
    cost: '12.00',
    price: '15.00',
    markup: '25.00',
    tax: '21',
    discount: '0',
    total: '30'
  },
  {
    name: 'New sinks',
    quantity: '2.00',
    cost: '12.00',
    price: '15.00',
    markup: '25.00',
    tax: '21',
    discount: '0',
    total: '30'
  }
]

// invoice filter buttons
export const INVOICE_FILTERS: string[] = [
  'Quantity',
  'Price',
  'Total',
  'Summary',
  'Line Items'
]

export const QUOTE_HTML_START = `
<html>
  <head>
    <meta charset="utf-8" />
    <title>Invoice HTML</title>

    <style>
      * {
        font-family: Arial, Helvetica, sans-serif;
      }

      .invoice-box {
        width: 595px;
        margin: auto;
        padding: 30px;
        font-size: 14px;
        line-height: 18px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
      }
      .border-middle{
        border: 2px solid black;Site Address
      }

      .invoice-box table {
        width: 100%;
        text-align: left;
      }

      .invoice-box table td {
        vertical-align: top;
      }

      .company-details {
        text-align: right;
        padding-bottom: 20px;
        font-size: 14px;
      }

      .company-title{
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .site-details {
        background-color: rgb(238 245 255);
        padding: 0.6rem;
        border-radius: 6px;
        width: 320px;
        font-size: 14px;
        height: 50px;
        max-width: 420px;
      }

      .site-address{
        font-size: 14px;

      }

      .quote-details {
        font-size: 14px;
        float: right;
        text-align: right;
      }

      .quote-title {
        font-size: 18px;
        line-height: 48px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .quote-footer {
        font-size: 12px;
        line-height: 16px;
        font-style: italic;
      }
      .quote-description {
        font-size: 12px;
        line-height: 16px;
      }

      hr,
      .horizontal-break {
        background-color: #e1e1e1;
      }

      .line-item td {
        padding-bottom: 5px;
      }

      .line-item,
      .quote-table th {
        font-size: 12px;
        text-align: center;
      }

      .quote-table .quote-head {
        padding-top: 40px;
        padding-bottom: 40px;
        height: 40px;
      }

      .line-item :first-child,
      .quote-head :first-child {
        text-align: left;
        width: 200px;
      }

      .line-item :last-child,
      .quote-head :last-child {
        text-align: right;
      }

      .quote-item-title {
        font-size: 14px;
        text-align: left;
      }

      .amount-details {
        text-align: right;
      }

      .totals {
        font-size: 12px;
      }

      .totals-cols {
        text-align: right;
        font-size: 14px;
        font-weight: 600;
      }

      .information {
        font-size: 14px;

      }
    </style>
  </head>

  <body>
    <div class="invoice-box">
      <table cellpadding="0" cellspacing="0">
`

export const QUOTE_HTML_END = `</table>
    </div>
  </body>
</html>`
