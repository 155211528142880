import moment from 'moment'
import {IoLocationSharp} from 'react-icons/io5'

import {ToolTip} from 'app/common'

interface PlanTicketCartProps {
  ticketDetails?: Api.SiteVisitTicketIndividual
  getTicketId?: (details: Api.SiteVisitTicketIndividual) => void
  selectedTicket?: Api.SiteVisitTicketIndividual
}

const PlanTicketCart = ({
  ticketDetails,
  getTicketId,
  selectedTicket,
}: PlanTicketCartProps) => {
  const isActive = !!(
    selectedTicket?.ticket_details?.id === ticketDetails?.ticket_details?.id
  )

  return (
    <div
      className={`flex flex-col gap-10 border border-1 border-${
        isActive ? 'blue' : 'gray'
      }-200 bg-${
        isActive ? 'gray' : ''
      }-100 rounded-md px-12 py-12 cursor-pointer hover:bg-gray-100 transition-all duration-200 ease-in-out`}
      onClick={() => {
        getTicketId?.(ticketDetails)
      }}
    >
      <div className="text-sm text-gray-400">
        {'Created By '}
        {[
          ticketDetails?.ticket_owner_details?.display_name,
          ticketDetails?.ticket_owner_details?.lastname,
        ].join(' ')}
        ,{' '}
        {moment(ticketDetails?.ticket_details?.created_at).format('DD/MM/YYYY')}
      </div>
      <div className="flex flex-col gap-8">
        <div className="text-md font-bold text-black">
          {ticketDetails?.ticket_details?.title}
        </div>
        <div className="flex gap-10 items-center flex-wrap">
          <ToolTip text="Ticket Number" right>
            <span className="flex items-center justify-center h-[24px] w-[max-content] text-white bg-red-400 rounded-md px-8">
              {ticketDetails?.ticket_details?.ticket_number ??
                ticketDetails?.ticket_details?.id}
            </span>
          </ToolTip>

          <ToolTip text="Status">
            {getStatusChip(
              ticketDetails?.ticket_details?.status as
                | 'open'
                | 'in progress'
                | 'feedback'
                | 'completed',
            )}
          </ToolTip>

          {!!ticketDetails?.ticket_details?.layer_coordinates && (
            <ToolTip text="This ticket has plan position">
              <span className="flex items-center justify-center font-bold h-[24px] w-[max-content] px-8 py-4 rounded-md bg-gray-100 text-white border-1 border-gray-200">
                <IoLocationSharp size={18} color="#333" />
              </span>
            </ToolTip>
          )}

          <ToolTip text="Due date">
            <span className="flex flex-col item-center justify-center h-[24px] w-[max-content] px-8 text-gray-400 border-1 border-gray-200 rounded-md  bg-white">
              {ticketDetails?.ticket_details?.due_date}
            </span>
          </ToolTip>
        </div>
        {!!ticketDetails?.sub_tickets_count &&
          ticketDetails?.sub_tickets_count > 0 && (
            <>
              <div className="w-full h-[1px] bg-gray-200 my-4"></div>
              <div className="flex items-center justify-center gap-4 px-8 py-4 w-[max-content] bg-gray-200 rounded-md">
                <span className="text-black font-medium text-sm">
                  SUBTICKETS -
                </span>{' '}
                <span className="font-bold text-black">
                  {ticketDetails?.sub_tickets_count ?? 0}
                </span>
              </div>
            </>
          )}
      </div>
    </div>
  )
}

export default PlanTicketCart

export const getStatusChip = (
  status: 'open' | 'in progress' | 'feedback' | 'completed',
) => {
  const statusColor = {
    open: '#e2420d',
    'in progress': '#3452c0',
    feedback: '#ffb500',
    completed: '#76cc00',
  }

  return (
    <div
      key={status}
      className={`text-xs font-bold text-white px-8 py-4 rounded-sm w-[max-content] uppercase`}
      style={{
        backgroundColor: statusColor[status],
      }}
    >
      {status}
    </div>
  )
}
