export const userType = {
  SYSTEM_ADMIN: 'System Admin',
  SUB_CONTRACTOR: 'Sub Contractor',
  FULL_USER: 'Full User',
  USER: 'User',
  OPERATIVE: 'operative'
}

export const userRoles = {
  [userType.SYSTEM_ADMIN]: {
    access: ['*']
  },
  [userType.FULL_USER]: {
    access: ['*']
  },
  [userType.SUB_CONTRACTOR]: {
    access: ['*']
  },
  [userType.USER]: {
    access: [
      '/',
      '/login',
      '/register/*',
      '/forgot-password/*',
      '/reset-password/*',
      '/projects/:projectId/forms/:formId/web-view',
      '/projects/:projectId/tickets/:ticketId/ticket-plan/:ticketPlanId/web-view'
    ]
  },
  [userType.OPERATIVE]: {
    access: ['*']
  }
}
