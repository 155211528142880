import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts'

import {getProjectTicketList} from 'redux-src'
import {Modal} from 'app/common'
import {ProjectTicketList} from 'app/pages/jobs/pages/ticket'
import {getStatusChip} from 'app/pages/jobs/pages/ticket/components/planList/components'

export function SummaryCard({data}: {data: Api.ProjectListForDashboard}) {
  const dispatch = useDispatch()
  const pieChartData = useMemo(
    () => [
      {name: 'Open', value: data?.open_ticket_count, color: '#8100cc'},
      {
        name: 'In Progress',
        value: data?.inprogress_ticket_count,
        color: '#3452c0',
      },
      {
        name: 'Completed',
        value: data?.completed_ticket_count,
        color: '#76cc00',
      },
      {name: 'Feedback', value: data?.feedback_ticket_count, color: '#ffb500'},
    ],
    [data],
  )

  // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<
    'open' | 'completed' | 'in progress' | 'feedback'
  >()

  const handleOpenStatusModal = (status: typeof selectedStatus) => {
    setModalOpen(true)
    setSelectedStatus(status)
  }

  useEffect(() => {
    modalOpen &&
      dispatch(
        getProjectTicketList(data?.project_details?.id, {
          page: 1,
          limit: 10,
          status: selectedStatus,
        }),
      )
  }, [data?.project_details?.id, dispatch, modalOpen, selectedStatus])

  return (
    //border-1 border-[#d1d1d1]
    <div className="bg-white summary-board min-w-[350px] w-[33%] p-20 shadow-md rounded-md cursor-pointer grid gap-y-[30px] hover:shadow-lg transition-shadow duration-200 ease-in-out">
      <div className="flex justify-between">
        <div>
          <span className="font-bold text-md text-[#004b7e]">
            <Link
              to={`/projects/${data?.project_details?.id}/projects-summary`}
            >
              {data?.project_details?.title}
            </Link>
          </span>
        </div>
        <div>{/* <span className="font-bold text-lg">#{}</span> */}</div>
      </div>

      <div className="grid gap-y-4 text-sm ">
        <div className="flex justify-between">
          <div className="font-bold">Project code</div>
          <div className="text-blue-300">
            <Link
              to={`/projects/${data?.project_details?.id}/projects-summary`}
            >
              {data?.project_details?.project_prefix}-
              {data?.project_details?.id}
            </Link>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="font-bold">Project start</div>
          <div>{data?.project_details?.start_date}</div>
        </div>
        <div className="flex justify-between">
          <div className="font-bold">Project end</div>
          <div>{data?.project_details?.end_date}</div>
        </div>
      </div>

      <div className="flex flex-wrap justify-between gap-4 w-full text-sm">
        <div
          onClick={() => {
            handleOpenStatusModal('open')
          }}
          className="flex w-[45%] justify-between status status-open"
        >
          <div className="font-bold ml-12">Open</div>
          <div>{data?.open_ticket_count}</div>
        </div>
        <div
          onClick={() => {
            handleOpenStatusModal('in progress')
          }}
          className="flex w-[45%] justify-between status status-progress"
        >
          <div className="font-bold ml-12">In Progress</div>
          <div>{data?.inprogress_ticket_count}</div>
        </div>

        <div
          onClick={() => {
            handleOpenStatusModal('completed')
          }}
          className="flex justify-between w-[45%] status status-resolved"
        >
          <div className="font-bold ml-12">Completed</div>
          <div>{data?.completed_ticket_count}</div>
        </div>
        <div
          onClick={() => {
            handleOpenStatusModal('feedback')
          }}
          className="flex w-[45%] justify-between status status-feedback"
        >
          <div className="font-bold ml-12">Feedback</div>
          <div>{data?.feedback_ticket_count}</div>
        </div>

        <Modal
          title={
            <div className="flex justify-between">
              <span>
                Showing tickets for project{' '}
                <span className="text-blue-300">
                  {data.project_details.title}
                </span>
              </span>
              {getStatusChip(selectedStatus ?? 'open')}
            </div>
          }
          isActive={modalOpen}
          toggleModal={setModalOpen}
          onClose={() => {
            setSelectedStatus(null)
          }}
          size="md"
        >
          <div className="p-12">
            <ProjectTicketList
              onTitleClick={(ticketId: number) => {
                window.open(
                  `#/projects/${data?.project_details?.id}/ticket?showTicket=true&ticketId=${ticketId}`,
                  '_blank',
                )
              }}
              showAction={false}
            />
          </div>
        </Modal>

        {/* <div className="flex w-[45%] justify-between status status-closed">
          <div className="font-bold ml-12">Closed</div>
          <div>3</div>
        </div>
        <div className="flex w-[45%] justify-between status status-rejected">
          <div className="font-bold ml-12">Rejected</div>
          <div>7</div>
        </div> */}
      </div>

      <div className="flex justify-between">
        <div className="flex flex-col gap-y-4 align-center justify-center text-center">
          <div className="grid">
            <div className="grid text-lg">{data?.ticket_count}</div>
            <div className="font-bold">Tickets</div>
          </div>

          <div className="grid text-red-300">
            <div className="grid text-lg">{data?.overdue_ticket_count}</div>
            <div className="font-bold">Overdue</div>
          </div>
        </div>

        <ResponsiveContainer width={'50%'} height={120}>
          <PieChart>
            <Pie
              data={pieChartData}
              dataKey={'value'}
              innerRadius={45}
              outerRadius={60}
              fill="#8884d8"
              paddingAngle={5}
            >
              {pieChartData?.map((pie, i) => {
                return <Cell key={`cell-${i}`} fill={pie.color} />
              })}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
