import React, {useCallback, useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import {BiError} from 'react-icons/bi'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import Geocode from 'react-geocode'

import {Button, Input, Loading, SelectField} from 'app/common'
import {capitalizeFirstLetterWithSpace, validationHandler} from 'utils'
import {
  addressRemapper,
  addressStyles,
  finalAddressRemapper,
  getAccumulatedContacts,
  getContactDynamicFields,
  getCountryId,
} from 'helpers'
import {getSiteById, updateSite} from 'redux-src'
import {REACT_APP_GOOGLE_KEY} from 'config'

export const AddressComp = () => {
  //! REDUX HANDLING
  const {toast} = useAuth()
  const dispatch = useDispatch()
  const {getSiteByIdLoading: loading, siteById: site}: RT.SiteReduxType =
    useSelector((state: any) => state.site)

  //? SITE DETAILS
  const [siteData, siteDataHandler] = useFormInput({
    name: undefined,
  })

  //? PHYSICAL ADDRESS
  const [physicalAddressData, physicalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 1,
  })

  // const [physAddress_loading, setPhysAddress_loading] = useState(false)

  //? POSTAL ADDRESS
  const [postalAddressData, postalAddressHandler] = useFormInput({
    address: '',
    zip: '',
    country: '',
    state: '',
    city: '',
    lat: 0,
    lng: 0,
    placeId: 0,
    place: '',
    suburb: '',
    countryId: 0,
    active: true,
    addressTypeId: 2,
  })
  const [postalAddressActive, setPostalAddressActive] = useState(false)
  // const [postAddress_loading, setPostAddress_loading] = useState(false)

  //? DEFAULT MAIN CONTACT
  const [siteContactData, setSiteContactData] = useState({
    contactTypeId: 2,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: ''}],
    firstName: '',
    lastName: '',
    title: '',
  })

  //? BILLING CONTACT
  const [billingContactData, setBillingContactData] = useState({
    contactTypeId: 3,
    dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: ''}],
    firstName: '',
    lastName: '',
    title: '',
  })
  const [billingContactActive, setBillingContactActive] = useState(false)

  //? NEW CUSTOMER SUBMISSION HANDLING
  const addSiteSuccessCallback = useCallback(() => {
    // setSiteContactFirstNameError('')
    // setSiteContactLastNameError('')
    physicalAddressHandler.clear()
    postalAddressHandler.clear()

    setBillingContactData({
      contactTypeId: 3,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      title: '',
    })
    setSiteContactData({
      contactTypeId: 2,
      dynamicFields: [{dynamicFieldId: 1, contactFieldId: 1, value: undefined}],
      firstName: '',
      lastName: '',
      title: '',
    })
    if (site?.site_details?.id) dispatch(getSiteById(site?.site_details?.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const onPhysicalAddressSubmit = () => {
    const physical = validationHandler(
      {
        address: physicalAddressData.address,
        city: physicalAddressData.city,
        country: physicalAddressData.countryId,
      },
      toast,
      'physical',
    )
    const postal = postalAddressActive
      ? true
      : validationHandler(
          {
            address: postalAddressData.address,
            city: postalAddressData.city,
            country: postalAddressData.countryId,
          },
          toast,
          'postal',
        )
    return physical && postal
  }

  const onSiteContactSubmit = () => {
    const site = validationHandler(
      {
        'first name': siteContactData.firstName,
        'last name': siteContactData.lastName,
        title: siteContactData.title,
      },
      toast,
      'site contact',
    )
    const billing = billingContactActive
      ? true
      : validationHandler(
          {
            'first name': billingContactData.firstName,
            'last name': billingContactData.lastName,
            title: billingContactData.title,
          },
          toast,
          'billing contact',
        )

    // return billingContactActive ? site : (site && billing)
    return site && billing
  }

  const onAddNewSite = (e: any) => {
    e.preventDefault()
    const {defaultContact, billingContact} = getAccumulatedContacts(
      siteContactData,
      billingContactActive ? undefined : billingContactData,
    )

    const physicalAddress = finalAddressRemapper(physicalAddressData)

    const updatedPostalAddress = {
      ...finalAddressRemapper(postalAddressData),
      id:
        postalAddressActive &&
        site?.physical_address_details?.id !== site?.postal_address_details?.id
          ? undefined
          : site?.postal_address_details?.id,
    }

    const updatedBillingContact = billingContactActive
      ? {...defaultContact, id: site?.default_contact_details?.id}
      : {...billingContact, id: site?.billing_contact_details?.id}

    const updateData: any = {
      // id: site?.customer_details?.id,
      name: siteData.name ?? undefined,
      info: {
        // id: site?.info_id,
        physicalAddress: {
          ...physicalAddress,
          id: site?.physical_address_details?.id,
        },
        postalAddress: updatedPostalAddress,
        defaultContact: {
          ...defaultContact,
          id: site?.default_contact_details?.id,
        },
        billingContact: updatedBillingContact,
      },
    }

    if (onPhysicalAddressSubmit() && onSiteContactSubmit()) {
      dispatch(
        updateSite(
          site?.site_details.id,
          updateData,
          addSiteSuccessCallback,
          toast,
        ),
      )
    } else {
      toast.error('Please fill all the required fields!')
    }
  }

  const assignSiteDetails = useCallback(() => {
    if (site) {
      // dispatch({ type: GET_CUSTOMER_BY_ID.CLEAR })

      siteDataHandler.setValue('name', site?.site_details?.name ?? '')

      const getAddresses = (
        addressType: 'physical' | 'postal',
        setValue: any,
      ) => {
        const addressKey =
          addressType === 'physical'
            ? 'physical_address_details'
            : 'postal_address_details'
        setValue('address', site[addressKey]?.address ?? '')
        setValue('suburb', site[addressKey]?.suburb ?? '')
        setValue('state', site[addressKey]?.state ?? '')
        setValue('place', site[addressKey]?.place ?? '')
        setValue('city', site[addressKey]?.city ?? '')
        setValue('countryId', getCountryId(site[addressKey]?.country) ?? 0)
        setValue('zip', site[addressKey]?.zip_code ?? '')
        setValue(
          'lat',
          site[addressKey]?.coordinates ? site[addressKey]?.coordinates[0] : 0,
        )
        setValue(
          'lng',
          site[addressKey]?.coordinates ? site[addressKey]?.coordinates[1] : 0,
        )
      }

      getAddresses('physical', physicalAddressHandler.setValue)
      getAddresses('postal', postalAddressHandler.setValue)
      setPostalAddressActive(
        site?.postal_address_details?.id === site?.physical_address_details?.id,
      )

      const getContacts = (contactTypeId: 2 | 3) => {
        const contactType =
          contactTypeId === 2
            ? 'default_contact_details'
            : 'billing_contact_details'

        return {
          contactTypeId,
          dynamicFields: getContactDynamicFields({
            emails: site[contactType]?.emails ?? [],
            phones: site[contactType]?.phones ?? [],
            otherPhones: site[contactType]?.other_phones ?? [],
            fax: site[contactType]?.fax ?? [],
            websites: site[contactType]?.websites ?? [],
          }),
          firstName: site[contactType]?.firstname ?? '',
          lastName: site[contactType]?.lastname ?? '',
          title: site[contactType]?.position ?? '',
          attn: '',
        }
      }

      setSiteContactData(getContacts(2))
      setBillingContactData(getContacts(3))
      setBillingContactActive(
        site?.default_contact_details?.id === site?.billing_contact_details?.id,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site])

  useEffect(() => {
    assignSiteDetails()
  }, [dispatch, assignSiteDetails])

  return loading ? (
    <Loading />
  ) : (
    <>
      {/* //physical address */}
      <AddressTable
        addressProps={{
          addressData: physicalAddressData,
          addressHandler: physicalAddressHandler,
        }}
        siteProps={{
          siteData,
          siteDataHandler,
        }}
      />

      <div className="p-4 border-t-1 border-gray-200" />
      {/* //postal address */}
      <AddressTable
        addressProps={{
          addressData: postalAddressData,
          addressHandler: postalAddressHandler,
        }}
        siteProps={{
          siteData,
          siteDataHandler,
        }}
        postalProps={{
          postalAddressActive,
          setPostalAddressActive,
          physicalAddressData,
        }}
      />
      <div className="flex justify-between sticky bottom-0 mt-20 py-20 border-t-1 border-gray-200 bg-white">
        <div className="flex gap-10 items-center">
          <span className="text-yellow-300">
            <BiError fontSize={14} />
          </span>
          <span className="text-gray-400">
            Don't forget to save your changes
          </span>
        </div>
        <div className="flex flex-row justify-center items-center">
          <span
            className="text-blue-300 cursor-pointer mr-10 capitalize"
            onClick={() => assignSiteDetails()}
          >
            undo changes
          </span>
          <Button title="Save" onClick={(e: any) => onAddNewSite(e)} />
        </div>
      </div>
    </>
  )
}

// MARK: - Address
const AddressTable = ({
  addressProps: {addressData, addressHandler},
  siteProps: {siteData, siteDataHandler},
  postalProps,
}: {
  addressProps: {
    addressData: any
    addressHandler: any
  }
  siteProps: {
    siteData: any
    siteDataHandler: any
  }
  postalProps?: {
    postalAddressActive: boolean
    setPostalAddressActive: React.Dispatch<React.SetStateAction<boolean>>
    physicalAddressData: any
  }
}) => {
  const {selectOptions} = useSelector((state: any) => state.selectOption)
  const {countryOptions} = selectOptions
  const [addressValue, setAddressValue] = useState(null)

  const handleAddressDropChange = (item: any) => {
    setAddressValue(item)
    Geocode.setApiKey(REACT_APP_GOOGLE_KEY)
    Geocode.setLanguage('en')
    Geocode.fromAddress(item.label).then(
      async (response) => {
        const responseFromHelper = await addressRemapper(response)
        //

        const _aaddressData = {
          ...addressData,
          ...responseFromHelper,
        }

        const {
          address,
          city,
          countryId,
          lat,
          lng,
          placeId,
          state,
          zip,
          suburb,
          country,
        } = _aaddressData
        const {setValue} = addressHandler

        setValue('address', address)
        setValue('city', city)
        setValue('countryId', countryId)
        setValue('lat', lat)
        setValue('lng', lng)
        setValue('placeId', placeId)
        setValue('state', state)
        setValue('zip', zip)
        setValue('suburb', suburb)
        setValue('country', country)
      },
      (error) => {},
    )
  }

  const handleUsePhysicalAddress = () => {
    postalProps?.setPostalAddressActive(true)
    if (postalProps?.physicalAddressData) {
      Object.entries(postalProps?.physicalAddressData).map(([key, value]) => {
        return addressHandler.setValue(key, value)
      })
    }
  }

  const handleClearPostalAddress = () => {
    postalProps.setPostalAddressActive(false)
    addressHandler.clear()
  }

  return (
    <div>
      {!!postalProps && (
        <div className="flex flex-col">
          <div className="text-lg font-bold text-black">Postal Address</div>
        </div>
      )}

      <div className="flex flex-col mt-10">
        <div className="flex flex-row justify-between gap-8">
          <div className="flex flex-col gap-2 flex-[2]">
            <div className="flex gap-4">
              <span>Site Address</span>
              {!!postalProps &&
                (!postalProps?.postalAddressActive ? (
                  <span
                    className="text-blue-300 cursor-pointer"
                    onClick={handleUsePhysicalAddress}
                  >
                    Use physical address
                  </span>
                ) : (
                  <span
                    className="text-blue-300 cursor-pointer"
                    onClick={handleClearPostalAddress}
                  >
                    Clear postal address
                  </span>
                ))}
            </div>
            <GooglePlacesAutocomplete
              apiKey={REACT_APP_GOOGLE_KEY}
              selectProps={{
                value:
                  {
                    label: addressData?.address,
                    value: addressData?.address,
                  } ?? addressValue,
                onChange: handleAddressDropChange,
                styles: addressStyles(addressData?.address?.length === 0),
              }}
            />
            <Input
              name="address"
              size="sm"
              value={addressData?.address}
              className="text-sm hidden"
              onChange={addressHandler?.onChange}
              error={addressData?.address?.length === 0}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <div className="flex gap-4">
              <span>Room / Apartment / Building</span>
              <span className="text-gray-300">(optional)</span>
            </div>
            <Input
              name="place"
              value={addressData?.place}
              size="sm"
              onChange={addressHandler?.onChange}
            />{' '}
          </div>
        </div>
        <div className="flex flex-row justify-between gap-8">
          <div className="flex flex-col gap-2 flex-1">
            <div className="flex gap-4">
              <span>Site Name </span>
              <span className="text-gray-300">(optional)</span>
            </div>
            <Input
              name="name"
              value={siteData?.name}
              size="sm"
              onChange={(e: any) =>
                siteDataHandler?.setValue(
                  e.target.name,
                  capitalizeFirstLetterWithSpace(e.target.value),
                )
              }
              error={
                siteDataHandler?.modified?.name && siteDataHandler?.errors?.name
              }
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <span>Suburb</span>
            <Input
              name="suburb"
              size="sm"
              value={addressData?.suburb}
              onChange={addressHandler?.onChange}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <span>City</span>
            <Input
              name="city"
              size="sm"
              value={addressData?.city}
              onChange={addressHandler?.onChange}
              error={addressData?.city?.length === 0}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-8">
          <div className="flex flex-col gap-2 flex-1">
            <span>State / Region</span>
            <Input
              name="state"
              size="sm"
              value={addressData?.state}
              onChange={addressHandler?.onChange}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <span>Zip / Post Code</span>
            <Input
              name="zip"
              size="sm"
              value={addressData?.zip}
              onChange={addressHandler?.onChange}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <span>Country</span>
            <SelectField
              options={countryOptions}
              placeholder="Add country"
              getOptionLabel={'key'}
              getOptionValue={'value'}
              value={countryOptions.filter((country: any) => {
                return country.value === addressData.countryId
              })}
              onChangeValue={(e) => {
                addressHandler?.setValue('countryId', e.value)
              }}
              error={addressData?.countryId === 0}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
