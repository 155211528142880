import {useCallback, useEffect, useMemo, useState} from 'react'
import {HiDotsHorizontal} from 'react-icons/hi'
import {useDispatch, useSelector} from 'react-redux'
import {CSVLink} from 'react-csv'
import {useFormInput} from 'use-form-input'
import {useNavigation, useQuery} from 'react-auth-navigation'

import {
  getCustomer,
  // getCustomerById,
  GET_CUSTOMER_BY_ID,
} from 'redux-src'

import {Button, Input, Dropdown} from 'app/common'
import {CustomerModal, CustomerTable} from './components'
import {CustomerType} from './customers.type'
import {useDebounceValue} from 'hooks'
import {FaSearch} from 'react-icons/fa'

import colors from 'tailwindcss/colors'

export const CustomersPage = () => {
  //! REDUX HANDLING
  const dispatch = useDispatch()

  const {
    navigation: {navigate},
  } = useNavigation()

  const {customers, customerTotal}: RT.CustomerReduxType = useSelector(
    (state: any) => state.customer,
  )

  //* STATE FOR TOGGLING CUSTOMER MODAL
  const [newCustomerModal, setNewCustomerModal] = useState(false)

  //* HANDLE NAVIGATION OPEN MODAL
  const query: any = useQuery()
  const {create} = query
  useEffect(() => {
    setNewCustomerModal(!!create)
  }, [create])

  //* FORM STATES
  const [filterData, filterHandler] = useFormInput({
    filterValue: undefined,
  })

  const search = useDebounceValue(filterData?.filterValue)

  //? HELPER FUNCTION TO REMAP CUSTOMERS FROM API INTO CUSTOMER LIST
  const customerList = useMemo(() => {
    const _temp: CustomerType = []
    if (customers) {
      customers?.map((item) => {
        return _temp.push({
          id: item.customer_details?.id,
          name: item.customer_details?.active ? 'Active' : 'Inactive',
          customerName: item.customer_details?.name,
          postalAddress: item.postal_address_details?.address,
          defaultCustomerContact: `${item.default_contact_details?.firstname} ${item.default_contact_details?.lastname}`,
          phone:
            !!item?.default_contact_details?.phones &&
            item?.default_contact_details?.phones
              ? item?.default_contact_details?.phones[0]
              : 'N/A',
          jobs: 0,
        })
      })
    }
    return _temp
  }, [customers])

  const customerAddCallback = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getCustomer({page, limit, search}))
    },
    [dispatch, search],
  )

  const getCustomerByIdCallback = useCallback(
    (id: number) => {
      // dispatch(
      //   getCustomerById(id, () => {
      //     // setNewCustomerModal(true);
      //   })
      //   )
      navigate(`/customers/view/${id}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  )

  useEffect(() => {
    //? GETTING CUSTOMERS SPECIFIC TO LOGGED IN CLIENTID
    customerAddCallback()
  }, [customerAddCallback])

  return (
    <div className="w-9/12 mx-auto">
      <div className="flex bg-white">
        <div className="flex-1 w-full p-32 h-custom">
          <h3 className="font-bold text-lg">Customers</h3>

          <h6
            className="text-md my-10"
            // onClick={() => toast.success('success customer')}
          >
            Your customer address book. Search, edit and view customer details.
          </h6>

          <div className=" pb-20 mb-20 border-b-1 border-gray-200 mb-5 flex items-center justify-between">
            <div className="md-10">
              <Input
                name="filterValue"
                type="text"
                placeholder="Filter By Name"
                prepend={<FaSearch size={16} color={colors.gray[500]} />}
                className="w-full text-sm"
                containerClass="w-full"
                value={filterData.filterValue}
                onChange={filterHandler.onChange}
              />
            </div>

            <div className="">
              <div className="flex flex-wrap">
                <div>
                  <Button
                    className="mr-20"
                    size="sm"
                    title="Add New Customer"
                    onClick={() => {
                      dispatch({
                        type: GET_CUSTOMER_BY_ID.CLEAR,
                      })

                      setNewCustomerModal((prev) => !prev)
                    }}
                  />
                </div>

                <div>
                  <Dropdown
                    placement="bottomright"
                    triggerElement={
                      <button className="p-10 py-6 bg-grey-100 shadow-sm rounded-sm border-1 transition-all duration-200 ease-in-out border-none hover:bg-grey-200">
                        <HiDotsHorizontal size={20} />
                      </button>
                    }
                    triggerToggle
                  >
                    <div style={{minWidth: '12rem', zIndex: 1}}>
                      <div className=" w-full mt-10">
                        {/* <DropDownFiles /> */}

                        <div
                          className="flex-1 w-full   overflow-visible "
                          style={{width: 200}}
                        >
                          <ul className=" border-gray-200 rounded-sm shadow-xl overflow-auto ">
                            <li className="p-10 pl-20 cursor-pointer bg-white text-black hover:bg-blue-100  text-md">
                              <span className="text-left inline-flex cursor-pointer">
                                Import customer list
                              </span>
                            </li>
                            <li className="p-10 pl-20 cursor-pointer  bg-white text-black hover:bg-blue-100 text-md ">
                              <CSVLink data={customerList}>
                                <span className="text-left inline-flex cursor-pointer">
                                  Export CSV
                                </span>
                              </CSVLink>
                            </li>
                            <li className="p-10 pl-20 cursor-pointer  bg-white text-black hover:bg-blue-100  text-md ">
                              <span className=" inline-flex cursor-pointer">
                                Export VCard
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>

          <CustomerTable
            customers={customerList}
            customerAddCallback={customerAddCallback}
            customerTotal={customerTotal}
            onCustomerClick={getCustomerByIdCallback}
          />

          <CustomerModal
            newCustomerModal={newCustomerModal}
            setNewCustomerModal={setNewCustomerModal}
            customerAddCallback={customerAddCallback}
            // activeCustomer={activeCustomer}
          />
        </div>
      </div>
    </div>
  )
}
