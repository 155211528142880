import {
  BULK_UPDATE_FORM_FIELDS_ORDER,
  CREATE_FORM,
  CREATE_PROJECT_FORM,
  DELETE_PROJECT_FORM,
  GET_FORM_BY_ID,
  GET_FORM_FIELD_DATA_TYPE,
  GET_PROJECT_DETAILS_BY_PROJECTFORMID,
  GET_PROJECT_FORM_BY_ID,
  UPDATE_FORM_FIELD,
  UPDATE_PROJECT_MANDATORIES,
  GET_FORM_LIST,
  GET_PROJECT_LIST_BY_FORM_ID,
  GET_UNATTACHED_FORM_BY_PROJECT_ID
} from '../actions'

const initialState: RT.FormsReduxType = {
  formList: undefined,
  formListLoading: false,
  formListTotal: 0,

  createFormLoading: false,
  formByIdLoading: false,
  formByIdData: undefined,

  formFieldDataTypeLoading: false,
  formFieldDataTypeData: undefined,

  instructionsElements: undefined,
  dataEntryElements: undefined,
  formElementSettings: undefined,

  updateFormFieldLoading: false,

  bulkUpdatFormFieldOrderLoading: false,
  bulkUpdateFormFieldOrderERror: false,

  createProjectFormLoading: false,

  projectFormByIdLoading: false,
  projectFormByIdData: undefined,
  projectFormByIdTotal: 0,

  projectDetailsByProjectFormIdLoading: false,
  projectDetailsByProjectFormIdData: undefined,

  updateProjectMandatoriesLoading: false,

  deleteProjectFormLoading: false,

  getProjectListByFormIdLoading: false,
  projectListByFormId: undefined,
  projectListByFormIdTotal: 0,

  unattachedFormsByProjectIdLoading: false,
  unattachedFormsByProjectId: undefined,
  unattachedFormsByProjectIdTotal: 0
}

export function formsReducer(state = initialState, action: any) {
  const {type, payload} = action

  //

  switch (type) {
    case CREATE_FORM.LOADING:
      return {
        ...state,
        createFormLoading: true
      }
    case CREATE_FORM.SUCCESS:
      // console.log('invoices from api: ', payload)
      return {
        ...state,
        createFormLoading: false
      }
    case CREATE_FORM.ERROR:
      return {...state, createFormLoading: false}

    case GET_FORM_BY_ID.LOADING:
      return {
        ...state,
        formByIdLoading: true
      }

    case GET_FORM_BY_ID.SUCCESS:
      return {
        ...state,
        formByIdLoading: false,
        formByIdData: payload
      }

    case GET_FORM_BY_ID.ERROR:
      return {
        ...state,
        formByIdLoading: false
      }

    case GET_FORM_LIST.LOADING:
      return {
        ...state,
        formListLoading: true
      }

    case GET_FORM_LIST.SUCCESS:
      return {
        ...state,
        formListLoading: false,
        formList: payload.rows,
        formListTotal: payload.total
      }

    case GET_FORM_LIST.ERROR:
      return {
        ...state,
        formListLoading: false
      }

    case GET_FORM_FIELD_DATA_TYPE.LOADING:
      return {
        ...state,
        formFieldDataTypeLoading: true
      }

    case GET_FORM_FIELD_DATA_TYPE.SUCCESS: {
      return {
        ...state,
        formFieldDataTypeLoading: false,
        formFieldDataTypeData: payload.formFieldDataTypeData,
        instructionsElements: payload.instructionsElements,
        dataEntryElements: payload.dataEntryElements,
        formElementSettings: payload.formElementSettings
      }
    }

    case GET_FORM_FIELD_DATA_TYPE.ERROR:
      return {
        ...state,
        formFieldDataTypeLoading: false
      }

    case UPDATE_FORM_FIELD.LOADING:
      return {
        ...state,
        updateFormFieldLoading: true
      }
    case UPDATE_FORM_FIELD.SUCCESS:
      return {
        updateFormFieldLoading: false
      }
    case UPDATE_FORM_FIELD.ERROR:
      return {...state, updateFormFieldLoading: true}

    case BULK_UPDATE_FORM_FIELDS_ORDER.LOADING:
      return {
        ...state,
        bulkUpdatFormFieldOrderLoading: true
      }
    case BULK_UPDATE_FORM_FIELDS_ORDER.SUCCESS:
      return {
        bulkUpdatFormFieldOrderLoading: false
      }
    case BULK_UPDATE_FORM_FIELDS_ORDER.ERROR:
      return {...state, bulkUpdatFormFieldOrderLoading: false}

    case CREATE_PROJECT_FORM.LOADING:
      return {
        ...state,
        createProjectFormLoading: true
      }
    case CREATE_PROJECT_FORM.SUCCESS:
      return {
        ...state,
        createProjectFormLoading: false
      }
    case CREATE_PROJECT_FORM.ERROR:
      return {...state, createProjectFormLoading: false}

    case GET_PROJECT_FORM_BY_ID.LOADING:
      return {
        ...state,
        projectFormByIdLoading: true
      }

    case GET_PROJECT_FORM_BY_ID.SUCCESS:
      return {
        ...state,
        projectFormByIdLoading: false,
        projectFormByIdData: payload?.rows,
        projectFormByIdTotal: payload.total
      }

    case GET_PROJECT_FORM_BY_ID.ERROR:
      return {
        ...state,
        projectFormByIdLoading: false
      }

    case GET_PROJECT_DETAILS_BY_PROJECTFORMID.LOADING:
      return {
        ...state,
        projectDetailsByProjectFormIdLoading: true
      }

    case GET_PROJECT_DETAILS_BY_PROJECTFORMID.SUCCESS:
      return {
        ...state,
        projectDetailsByProjectFormIdLoading: false,
        projectDetailsByProjectFormIdData: payload
      }

    case GET_PROJECT_DETAILS_BY_PROJECTFORMID.ERROR:
      return {
        ...state,
        projectDetailsByProjectFormIdLoading: true
      }

    case UPDATE_PROJECT_MANDATORIES.LOADING:
      return {
        ...state,
        updateProjectMandatoriesLoading: true
      }
    case UPDATE_PROJECT_MANDATORIES.SUCCESS:
      return {
        ...state,
        updateProjectMandatoriesLoading: false
      }
    case UPDATE_PROJECT_MANDATORIES.ERROR:
      return {...state, updateProjectMandatoriesLoading: false}

    case DELETE_PROJECT_FORM.LOADING:
      return {
        ...state,
        deleteProjectFormLoading: true
      }
    case DELETE_PROJECT_FORM.SUCCESS:
      return {
        ...state,
        deleteProjectFormLoading: false
      }
    case DELETE_PROJECT_FORM.ERROR:
      return {...state, deleteProjectFormLoading: false}

    case GET_PROJECT_LIST_BY_FORM_ID.LOADING:
      return {
        ...state,
        getProjectListByFormIdLoading: true
      }
    case GET_PROJECT_LIST_BY_FORM_ID.SUCCESS:
      return {
        ...state,
        getProjectListByFormIdLoading: false,
        projectListByFormId: payload.rows,
        projectListByFormIdTotal: payload.total
      }
    case GET_PROJECT_LIST_BY_FORM_ID.ERROR:
      return {...state, getProjectListByFormIdLoading: false}

    case GET_UNATTACHED_FORM_BY_PROJECT_ID.LOADING:
      return {
        ...state,
        unattachedFormsByProjectIdLoading: true
      }
    case GET_UNATTACHED_FORM_BY_PROJECT_ID.SUCCESS:
      return {
        ...state,
        unattachedFormsByProjectIdLoading: false,
        unattachedFormsByProjectId: payload.rows,
        unattachedFormsByProjectIdTotal: payload.total
      }
    case GET_UNATTACHED_FORM_BY_PROJECT_ID.ERROR:
      return {...state, unattachedFormsByProjectIdLoading: false}

    default:
      return state
  }
}
