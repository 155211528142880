import {LabourElementManagamentComp} from './pages/settings/pages/labourElementManagement/labourElementManagement.pages'
import {useEffect} from 'react'
import {PublicPath, PrivatePath, useNavigation} from 'react-auth-navigation'

/**
 * ROOT PAGES
 */
import {
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  DashboardPage,
  GettingStartedPage,
  CustomersPage,
  SitesPage,
  MapPage,
  SupplierDocPage,
  ForPassPreConfirmationPage,
  ResetPasswordPage,
  ForPassPostConfirmationPage,
  RegisterPreConfirmationPage,
  RegisterPostConfirmationPage,
  JobsPage,
  NewJobPage,
  SettingsPage,
  FloorMapWebView,
  CalendarPageWithContext,
} from './pages'

/**
 * JOBS PAGES
 */
import {
  JobsSummaryPage,
  FinancialSummaryPage,
  NotesHistoryPage,
  HazardsPage,
  InvoicingPage,
  TaskPage,
  SignOffPage,
  ChecklistsPage,
  FormsPage,
  PlanViewerPage,
  CostReportPage,
  ReconciliationPage,
  PurchaseOrdersPage,
  SiteVisitsPage,
  QuotePage,
  ProjectUsers,
  ProjectGroupsPage,
  ProjectFormMainPage,
  ProjectFormDetailsPage,
  TimesheetPage,
  FilesAndPhotosPage,
} from './pages/jobs/pages'
import {
  CheckListPage,
  FormsPage as FormPage,
  GeneralPage,
  GroupsPage,
  InvoicingSettingsPage,
  PriceBooks,
  QuotingPage,
  SalesAccountCodes,
  ItemTypesPage,
  Users,
  ItemTypesManagement,
  LabourElementManagamentPage,
  LabourElementGroupPage,
  ItemManagementPage,
} from './pages/settings/pages'

import {
  CheckListMainPage,
  NewTemplatePage,
} from './pages/settings/pages/checkLists/pages'

import {SchedulingPage, LabourPage} from './pages/jobs/pages/siteVisits/pages'
import {EditGroupPage, MainGroupPage} from './pages/settings/pages/groups/pages'
import {
  UserDetails,
  UserDetailsMain,
  UserInvoice,
  UsersMainPage,
  UserTimesheet,
} from './pages/settings/pages/users/pages'
import {
  InvoiceEditorPage,
  InvoiceTablePage,
} from './pages/jobs/pages/invoicing/pages'
import {EmailsPage, ProjectsPage} from './pages/customers/pages'
import {CustomerViewPage} from './pages/customers/pages/view/customer.view.page'
import {CustomerFilesAndPhotosPage} from './pages/customers/pages/view/filesAndPhotos'
import {
  SupplierDetailsPage,
  SupplierPage,
  SupplierPageComp,
} from './pages/settings/pages/supplier'
import {SiteViewPage} from './pages/sites/pages/view/site.view.page'
import {
  AddressPage,
  SiteContactsPage,
  SiteFilesAndPhotosPage,
  SiteProjectsPage,
} from './pages/sites/pages'
import {SiteNotesHistoryPage} from './pages/sites/pages/view/notesHistory'
import {ItemDetails} from './pages/settings/pages/itemTypes/pages/itemDetails.page'
import {
  QuotePreliminaryPage,
  QuotePurchaseOrderPage,
  QuoteVersionPage,
} from './pages/jobs/pages/quote/pages'
import {ProjectUsersMainPage} from './pages/jobs/pages/users/pages'
import {ProjectMainGroupPage} from './pages/jobs/pages/groups/pages'
import {
  FormBuilderCreate,
  FormsMainPage,
} from './pages/settings/pages/forms/pages'
import {FormBuilderEdit} from './pages/settings/pages/forms/pages/formBuilderCreate/formBuilderEdit.page'
import {TicketMainPage} from './pages/jobs/pages/ticket'
import {
  MaterialElementGroupPage,
  MaterialElementManagamentComp,
  MaterialElementManagamentPage,
} from './pages/settings/pages/materialElementManagement'
import {
  PreliminaryTemplateDetailsPage,
  PreliminaryTemplateMain,
  PreliminaryTemplatePage,
} from './pages/settings/pages/preliminaryTemplates'
import {
  GeneratePreliminaryPage,
  PreliminaryEditPage,
  PreliminaryPreviewPage,
} from './components'
import {FormBuilderWebViewPage} from './components/formBuilderWebView'
import {TicketPlanPositionWebView} from './components/ticketPlanPositionWebView'
import {
  UserInvoiceEditorPage,
  UserInvoiceTablePage,
} from './pages/settings/pages/users/pages/invoice/pages'
import {ProgressPage} from './pages/jobs/pages/progress'
import {OverdueTab, ProjectsTab} from './pages/dashboard/pages'

/**
 * SETTINGS PAGE
 */
export {FormsPage} from './pages/settings/pages'

/**
 * Redirect component allows us to navigate to new path directly
 * without rendering any component
 */
export const Redirect = ({to}: {to: string}): void => {
  const {
    navigation: {navigate},
  } = useNavigation()

  useEffect(() => {
    navigate(to)
  }, [to, navigate])

  return null
}

/**
 * Public Paths
 */
export const publicPaths: PublicPath = [
  {
    name: 'Root',
    path: '/',
    component: LoginPage,
    restricted: true,
  },
  {
    name: 'Register',
    path: '/register',
    component: RegisterPage,
    restricted: true,
    subPaths: [
      {
        name: 'Register PreConfirmation',
        path: '/preconfirmation',
        component: RegisterPreConfirmationPage,
        restricted: true,
      },
      {
        name: 'Register PostConfirmation',
        path: '/postconfirmation',
        component: RegisterPostConfirmationPage,
        restricted: true,
      },
    ],
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPasswordPage,
    restricted: true,
    subPaths: [
      {
        name: 'Forgot Password PreConfirmation',
        path: '/preconfirmation',
        component: ForPassPreConfirmationPage,
        restricted: true,
      },
      {
        name: 'Forgot Password PostConfirmation',
        path: '/postconfirmation',
        component: ForPassPostConfirmationPage,
        restricted: true,
      },
    ],
  },
  {
    name: 'Reset Password',
    path: '/reset-password',
    component: ResetPasswordPage,
    restricted: true,
  },
  {
    name: 'FloorMapWebView',
    path: '/floormap/:projectId',
    component: FloorMapWebView,
    restricted: false,
  },
  {
    name: 'FormBuilderWebView',
    path: '/projects/:projectId/forms/:formId/web-view',
    component: FormBuilderWebViewPage,
    restricted: false,
  },
  {
    name: 'TicketPlanPositionWebView',
    path: '/projects/:projectId/tickets/:ticketId/ticket-plan/:ticketPlanId/web-view',
    component: TicketPlanPositionWebView,
    restricted: false,
  },
]

/**
 * Private Paths
 */
export const privatePaths: PrivatePath = [
  {
    name: 'Home',
    path: '/dashboard',
    component: DashboardPage,
    nestedPaths: [
      {
        name: 'Projects',
        path: '/projects',
        component: ProjectsTab,
      },

      {
        name: 'Ticket Overdues',
        path: '/overdue',
        component: OverdueTab,
      },
    ],
  },

  {
    name: 'Customers',
    path: '/customers',
    component: CustomersPage,
    subPaths: [
      {
        name: 'CustomerView',
        path: '/view/:customerId',
        component: CustomerViewPage,
        nestedPaths: [
          {
            name: 'Customer Projects',
            path: '/',
            component: ProjectsPage,
          },
          // {
          //   name: 'Emails',
          //   path: '/emails',
          //   component: EmailsPage,
          // },
          {
            name: 'File and Photos',
            path: '/files-and-photos',
            component: CustomerFilesAndPhotosPage,
          },
        ],
      },
    ],
  },
  {
    name: 'Sites',
    path: '/sites',
    component: SitesPage,
    subPaths: [
      {
        name: 'SiteView',
        path: '/view/:siteId',
        component: SiteViewPage,
        nestedPaths: [
          {
            name: 'Contacts',
            path: '/',
            component: SiteContactsPage,
          },
          {
            name: 'Site Projects',
            path: '/projects',
            component: SiteProjectsPage,
          },
          // {
          //   name: 'Notes and History',
          //   path: '/notes-and-history',
          //   component: SiteNotesHistoryPage,
          // },
          {
            name: 'File and Photos',
            path: '/files-and-photos',
            component: SiteFilesAndPhotosPage,
          },
          {
            name: 'Address',
            path: '/site-address',
            component: AddressPage,
          },
        ],
      },
    ],
  },
  {
    name: 'Calendar',
    path: '/calendar',
    component: CalendarPageWithContext,
  },
  // {
  //   name: 'Map',
  //   path: '/map',
  //   component: MapPage,
  // },
  // {
  //   name: 'Supplier Doc',
  //   path: '/supplier-doc',
  //   component: SupplierDocPage,
  // },
  {
    name: 'Projects Page',
    path: '/projects/:projectId',
    component: JobsPage,
    nestedPaths: [
      {
        name: 'Site Visits',
        path: '/site-visit/:siteVisitId',
        component: SiteVisitsPage,
        nestedPaths: [
          {
            name: 'Scheduling',
            path: '/scheduling',
            component: SchedulingPage,
          },
          {
            name: 'Labour',
            path: '/labour',
            component: LabourPage,
          },
        ],
      },
      // OVERVIEW
      {
        name: 'Project Summary',
        path: '/projects-summary',
        component: JobsSummaryPage,
      },
      {
        name: 'Progress',
        path: '/progress-report',
        component: ProgressPage,
      },
      {
        name: 'Financial Summary',
        path: '/financial-summary',
        component: FinancialSummaryPage,
      },
      {
        name: 'Note and History',
        path: '/notes-history',
        component: NotesHistoryPage,
      },
      //   TOOLS
      // {
      //   name: 'Hazards',
      //   path: '/hazard',
      //   component: HazardsPage,
      // },
      {
        name: 'Invoicing',
        path: '/invoicing',
        component: InvoicingPage,
        nestedPaths: [
          {
            name: 'Invoicing Table',
            path: '/',
            component: InvoiceTablePage,
            props: {root: true},
          },
          {
            name: 'Invoicing Detail',
            path: '/:invoiceId',
            component: InvoiceEditorPage,
          },
        ],
      },
      {
        name: 'Project Users',
        path: '/project-users',
        component: ProjectUsers,
        nestedPaths: [
          {
            name: 'Users Main',
            path: '/',
            component: ProjectUsersMainPage,
          },
        ],
      },
      {
        name: 'Project Groups',
        path: '/project-groups',
        component: ProjectGroupsPage,
        nestedPaths: [
          {
            name: 'Group Main',
            path: '/',
            component: ProjectMainGroupPage,
          },
        ],
      },
      {
        name: 'Quote',
        path: '/quote',
        component: QuotePage,
        nestedPaths: [
          {
            name: 'quote-version',
            path: '/',
            component: QuoteVersionPage,
            // props: { root: true },
          },
          {
            name: 'quote-preliminary',
            path: '/preliminary',
            component: QuotePreliminaryPage,
            nestedPaths: [
              {
                name: 'Generate Preliminary Page',
                path: '/generate',
                component: GeneratePreliminaryPage,
              },
              {
                name: 'Edit Preliminary Page',
                path: '/edit',
                component: PreliminaryEditPage,
              },
              {
                name: 'Preview Preliminary Page',
                path: '/preview',
                component: PreliminaryPreviewPage,
              },
            ],
          },

          {
            name: 'quote-purchase-order',
            path: '/purchase-orders',
            component: QuotePurchaseOrderPage,
          },
        ],
      },
      // {
      //   name: 'Task',
      //   path: '/task-view',
      //   component: TaskPage,
      // },
      {
        name: 'File and Photos',
        path: '/files-and-photos',
        component: FilesAndPhotosPage,
      },
      // {
      //   name: 'Sign Off',
      //   path: '/signoff',
      //   component: SignOffPage,
      // },
      // {
      //   name: 'Checklists',
      //   path: '/checklist',
      //   component: ChecklistsPage,
      // },
      {
        name: 'Project Forms',
        path: '/forms',
        component: FormsPage,
        nestedPaths: [
          {
            name: 'Project Forms Main Page',
            path: '/',
            component: ProjectFormMainPage,
          },
          {
            name: 'Project Forms Preview Page',
            path: '/:projectFormId/preview',
            component: ProjectFormDetailsPage,
          },
        ],
      },
      {
        name: 'Plan Viewer',
        path: '/floor-plan',
        component: PlanViewerPage,
      },
      {
        name: 'Ticket',
        path: '/ticket',
        component: TicketMainPage,
      },
      {
        name: 'Timesheet',
        path: '/timesheet',
        component: TimesheetPage,
      },
      //    COSTING
      // {
      //   name: 'Cost Report',
      //   path: '/costing-report',
      //   component: CostReportPage,
      // },
      // {
      //   name: 'Reconciliation',
      //   path: '/supplier-reconciliation',
      //   component: ReconciliationPage,
      // },
      {
        name: 'Purchase Orders',
        path: '/purchase-orders',
        component: PurchaseOrdersPage,
      },
      {
        name: 'Cost Report',
        path: '/costing-report',
        component: CostReportPage,
      },
    ],
  },
  {
    name: 'New Project',
    path: '/new-project',
    component: NewJobPage,
  },
  {
    name: 'Settings',
    path: '/settings',
    component: SettingsPage,
    nestedPaths: [
      {
        name: 'General',
        path: '/',
        component: GeneralPage,
        exact: true,
      },
      {
        name: 'Quoting',
        path: '/quoting',
        component: QuotingPage,
      },
      // {
      //   name: 'Invoicing Settings',
      //   path: '/invoicing',
      //   component: InvoicingSettingsPage
      // },
      {
        name: 'Forms',
        path: '/forms',
        component: FormPage,
        nestedPaths: [
          {
            name: 'Form Main Page',
            path: '/',
            component: FormsMainPage,
          },
          {
            name: 'FormBuilder Create Page',
            path: '/create',
            component: FormBuilderCreate,
          },
          {
            name: 'FormBuilder Edit Page',
            path: '/edit/:formId',
            component: FormBuilderEdit,
          },
        ],
      },
      {
        name: 'Preliminary Templates',
        path: '/preliminary-templates',
        component: PreliminaryTemplatePage,
        nestedPaths: [
          {
            name: 'Preliminary Template Main Page',
            path: '/',
            component: PreliminaryTemplateMain,
          },
          {
            name: 'Preliminary Template Details Page',
            path: '/:preliminaryTemplateId',
            component: PreliminaryTemplateDetailsPage,
          },
        ],
      },
      {
        name: 'Item Type Page',
        path: '/item-type-management',
        component: ItemTypesPage,
        nestedPaths: [
          {
            name: 'Item Type Management',
            path: '/',
            component: ItemTypesManagement,
          },
          {
            name: 'Item Details',
            path: '/:itemId',
            component: ItemDetails,
          },
        ],
      },
      {
        name: 'Supplier',
        path: '/supplier',
        component: SupplierPage,
        nestedPaths: [
          {
            name: 'Supplier Main',
            path: '/',
            component: SupplierPageComp,
          },
          {
            name: 'Supplier Details',
            path: '/:supplierId',
            component: SupplierDetailsPage,
          },
        ],
      },
      // {
      //   name: 'CheckLists',
      //   path: '/checklists',
      //   component: CheckListPage,
      //   nestedPaths: [
      //     {
      //       name: 'Checklist Main',
      //       path: '/',
      //       component: CheckListMainPage
      //     },
      //     {
      //       name: 'New Template',
      //       path: '/new-template',
      //       component: NewTemplatePage
      //     },
      //     {
      //       name: 'Edit Template',
      //       path: '/edit-template/:id',
      //       component: NewTemplatePage
      //     }
      //   ]
      // },
      // {
      //   name: 'Price Books',
      //   path: '/price-books',
      //   component: PriceBooks,
      // },
      // {
      //   name: 'Sales Account Codes',
      //   path: '/sales-account-codes',
      //   component: SalesAccountCodes,
      // },
      {
        name: 'Labour Element Management Page',
        path: '/labour-element-management',
        component: LabourElementManagamentPage,
        nestedPaths: [
          {
            name: 'Labour Element Management Comp',
            path: '/',
            component: LabourElementManagamentComp,
          },
          {
            name: 'Labout Element Group Page',
            path: '/:labourElementGroupId',
            component: LabourElementGroupPage,
          },
        ],
      },
      {
        name: 'Material Element Management Page',
        path: '/material-list',
        component: MaterialElementManagamentPage,
        nestedPaths: [
          {
            name: 'Material Element Management Comp',
            path: '/',
            component: MaterialElementManagamentComp,
          },
          {
            name: 'Material Element Group Page',
            path: '/:materialElementGroupId',
            component: MaterialElementGroupPage,
          },
        ],
      },
      {
        name: 'Item Management',
        path: '/labour-and-material-combined',
        component: ItemManagementPage,
      },
      {
        name: 'Users',
        path: '/users',
        component: Users,
        nestedPaths: [
          {
            name: 'Users Main',
            path: '/',
            component: UsersMainPage,
          },
          {
            name: 'UsersDetailsMain',
            path: '/:id',
            component: UserDetailsMain,
            nestedPaths: [
              {
                name: 'User Details',
                path: '/',
                component: UserDetails,
              },
              {
                name: 'User Timesheet',
                path: '/timesheet',
                component: UserTimesheet,
              },
              {
                name: 'User Invoice',
                path: '/invoice',
                component: UserInvoice,
                nestedPaths: [
                  {
                    name: 'User Invoicing Table',
                    path: '/',
                    component: UserInvoiceTablePage,
                    props: {root: true},
                  },
                  {
                    name: 'User Invoicing Detail',
                    path: '/:invoiceId',
                    component: UserInvoiceEditorPage,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Groups',
        path: '/groups',
        component: GroupsPage,
        nestedPaths: [
          {
            name: 'Group Main',
            path: '/',
            component: MainGroupPage,
          },
          {
            name: 'Edit Group',
            path: '/edit-group/:id',
            component: EditGroupPage,
          },
        ],
      },
    ],
  },
]
