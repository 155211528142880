import { useEffect, useRef } from 'react'
import L, { LatLngTuple } from 'leaflet'
import { MapContainer, ImageOverlay, Marker } from 'react-leaflet'
import { LOCAL_FILE_URL } from '../../../../config'
import { MessageBox } from '../../../components'

const MAP_CONTAINER_HEIGHT = '100vh'
const MAP_WIDTH = 1920
const MAP_HEIGHT = 1080
const CENTER: LatLngTuple = [MAP_HEIGHT / 2, MAP_WIDTH / 2] // Here latLng is (y,x);
const BOUNDS: LatLngTuple[] = [
  [0, 0],
  [MAP_HEIGHT, MAP_WIDTH],
]

export const FloorPlan = ({ siteVisit }: { siteVisit: any }) => {
  const linkedChecklists = (siteVisit.checklists ?? []).filter(
    (checklist: any) => checklist.floor_location
  )

  if (!siteVisit?.site_visit_details?.floor_plan) {
    return (
      <MessageBox
        message={'Floor plan not found! Please upload a floor plan!'}
      />
    )
  }

  return (
    <div style={{ height: MAP_CONTAINER_HEIGHT, overflow: 'hidden' }}>
      {siteVisit.from_pdf === null ? (
        siteVisit.has_error ? (
          <div>Error! Can't process site visit, please re-upload file.</div>
        ) : (
          <div>Site visit is processing... please comeback after a while.</div>
        )
      ) : siteVisit.from_pdf === true ? (
        <TileMap
          siteVisitId={siteVisit.id}
          linkedChecklists={linkedChecklists}
        />
      ) : (
        <MapContainer
          center={CENTER}
          zoom={-1}
          minZoom={-5}
          bounds={BOUNDS as any}
          crs={L.CRS.Simple}
          attributionControl={false}
          zoomControl={true}
          style={{ width: '100%', height: '100%', zIndex: 1 }}
        >
          <ImageMap
            image={siteVisit.site_visit_details.floor_plan}
            linkedChecklists={linkedChecklists}
          />
        </MapContainer>
      )}
    </div>
  )
}

// MARK: - TileMap
const TileMap = ({
  siteVisitId,
  linkedChecklists,
}: {
  siteVisitId: number
  linkedChecklists: Array<any>
}) => {
  const mapRef = useRef<L.Map>(null)
  const layerRef = useRef<L.LayerGroup>(null)

  useEffect(() => {
    mapRef.current = L.map('map', {
      crs: L.CRS.Simple,
    })
    layerRef.current = L.layerGroup().addTo(mapRef.current)
  }, [])

  useEffect(() => {
    const tileLayer = (L.tileLayer as any)
      .zoomify(
        `${LOCAL_FILE_URL}/assets/site-visit/${siteVisitId}/fptiles/{z}/{x}/{y}.png`,
        {
          width: 30000,
          height: 30000,
        }
      )
      .addTo(mapRef.current)

    mapRef.current.fitBounds(tileLayer.getBounds())
  }, [siteVisitId])

  useEffect(() => {
    layerRef.current.clearLayers()

    linkedChecklists.forEach((checklist) => {
      L.marker(checklist.floor_location)
        .addTo(layerRef.current)
        .on('click', function () {
          if ((window as any).ReactNativeWebView) {
            ;(window as any).ReactNativeWebView.postMessage(
              JSON.stringify({
                checklistId: checklist.id,
              })
            )
          }
        })
    })
  }, [linkedChecklists])

  return <div id="map" style={{ height: MAP_CONTAINER_HEIGHT, zIndex: 1 }} />
}

// MARK: - ImageMap
const ImageMap = ({
  image,
  linkedChecklists,
}: {
  image: string
  linkedChecklists: Array<any>
}) => {
  return (
    <>
      <ImageOverlay
        url={`${LOCAL_FILE_URL}/assets/site-visit/${image}`}
        bounds={BOUNDS}
      />

      {linkedChecklists.map((checklist) => (
        <Marker
          key={checklist.id}
          position={checklist.floor_location}
          eventHandlers={{
            click() {
              if ((window as any).ReactNativeWebView) {
                ;(window as any).ReactNativeWebView.postMessage(
                  JSON.stringify({
                    checklistId: checklist.id,
                  })
                )
              }
            },
          }}
        />
      ))}
    </>
  )
}
