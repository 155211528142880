import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigation} from 'react-auth-navigation'
import {useAuth} from 'react-auth-navigation'
import colors from 'tailwindcss/colors'
import {TableStyles} from 'react-data-table-component'
import {useDispatch, useSelector} from 'react-redux'
import {FaPen, FaTrash} from 'react-icons/fa'

import {DataTable} from 'app/components'
import {AddLabourElement} from '../addLabourElement'
import {ConfirmationModal} from 'app/common'

import {
  deleteLabourElementAction,
  getLabourElementAction,
  getLabourElementForSelectFieldAction,
  getLabourGroupByIdAction,
  removeLabourElementFromGroupAction
} from 'redux-src'

export const LabourElementTable = ({
  search,
  groupLabourList,
  isGroup = false
}: {
  search?: string
  groupLabourList?: any
  isGroup?: boolean
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const [labourElementDetails, setLaboutElementDetails] = useState<{
    id: number
    name: string
    timeMins: number
    unitName: string
    numberPerHr: number
  }>()

  const {
    labourElementList: listFromApi,
    get_labour_element_loading,
    labourElementTotal
  }: RT.LabourElementReduxType = useSelector(
    (state: any) => state.labourElements
  )

  const {
    params: {labourElementGroupId}
  }: any = useNavigation()
  const {toast} = useAuth()
  const dispatch = useDispatch()

  const newLabourElementList = useMemo(() => {
    if (!!!listFromApi) return []

    const finalList = listFromApi?.map(({labour_element_details}) => {
      return {
        id: labour_element_details?.id,
        name: labour_element_details?.name,
        timeMins: labour_element_details?.time_mins,
        unitName: labour_element_details?.unit_name,
        numberPerHr: labour_element_details?.number_per_hr
      }
    })

    return finalList
  }, [listFromApi])

  const getLabourElement = useCallback(
    (page?: number, limit?: number) => {
      dispatch(getLabourElementAction({page, limit, search}))
    },
    [dispatch, search]
  )

  const handleRemoveLabourElementFromGroup = (
    labourElementId: number | string
  ) => {
    isGroup
      ? dispatch(
          removeLabourElementFromGroupAction(
            labourElementGroupId,
            {labourElementIds: [labourElementId]},
            () => {
              dispatch(getLabourElementAction())

              dispatch(getLabourElementForSelectFieldAction())
              dispatch(getLabourGroupByIdAction(labourElementGroupId, () => {}))
            },
            toast
          )
        )
      : dispatch(
          deleteLabourElementAction(
            labourElementId,
            () => {
              dispatch(getLabourElementAction())

              dispatch(getLabourElementForSelectFieldAction())
            },
            toast
          )
        )
  }

  useEffect(() => {
    getLabourElement()
  }, [getLabourElement])

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.name,
      sortable: true,

      cell: (row: any) => (
        <span className="items-center justify-center text-blue-400  py-4 mr-1   px-6 leading-none w-[70vw]">
          {row.name}
        </span>
      )
    },
    {
      name: 'Unit Name',
      selector: (row: any) => row.unitName,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row.unitName}
        </span>
      )
    },
    {
      name: 'Time Mins',
      selector: (row: any) => row.timeMins,
      sortable: true,
      cell: (row: any) => (
        <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
          {row.timeMins}
        </span>
      )
    },
    {
      name: 'Number Per Hour',
      selector: (row: any) => row.numberPerHr,
      sortable: true,
      cell: (row: any) => (
        <div className="flex justify-between">
          <span className="items-center justify-center py-4 mr-1  text-[#333] px-6 leading-none ">
            {row.numberPerHr}
          </span>

          <div className="flex justify-between items-center gap-x-10">
            <button
              className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center"
              onClick={() => {
                setIsModalVisible(true)
                setLaboutElementDetails(row)
              }}
            >
              <span className="text-left inline-flex cursor-pointer">
                <FaPen size={12} className="cursor-pointer text-gray-400" />{' '}
              </span>
            </button>

            <ConfirmationModal
              onConfirmClick={(closeModalHandler) => {
                handleRemoveLabourElementFromGroup(row.id)
              }}
              danger
              label={'Are you sure you want to delete this Labour?'}
              displayElement={
                <button className="px-4 py-4 text-black bg-gray-200 shadow-sm rounded-sm border-1 border-gray-300 hover:bg-gray-100 focus:bg-gray-100 flex items-center">
                  <span className="text-left inline-flex cursor-pointer">
                    <FaTrash fill="red" size={12} />
                  </span>
                </button>
              }
              confirmLabel="Delete Labour"
            ></ConfirmationModal>
          </div>
        </div>
      )
    }
  ]
  const customStyles: TableStyles = {
    table: {
      style: {
        border: '1px solid',
        borderRadius: '5px',
        borderColor: colors.gray[200],
        width: 'inherit'
      }
    },
    rows: {
      style: {
        minHeight: '40px !important',
        backgroundColor: '#f5f5f5',
        '&:nth-child(2n-1)': {
          backgroundColor: colors.white
        }
      }
    },

    headCells: {
      style: {
        fontWeight: 'bold',
        padding: '6px 10px',

        '&:not(:last-child)': {
          borderRight: '1px solid',
          borderColor: colors.gray[200]
        }
      }
    },
    headRow: {
      style: {
        fontWeight: 'bold',
        minHeight: '42px !important',
        background: colors.gray[100]
      }
    },
    cells: {
      style: {
        padding: '6px 10px',
        '&:first-child': {
          width: '40%'
        },
        '&:not(:last-child)': {
          borderRight: '1px solid',
          borderColor: colors.gray[200]
        }
      }
    }
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={isGroup ? groupLabourList ?? [] : newLabourElementList ?? []}
        pagination
        totalRows={+labourElementTotal}
        fetchData={getLabourElement}
        customStyles={customStyles}
        loading={get_labour_element_loading}
      />
      <AddLabourElement
        addLabourElementModal={isModalVisible}
        setLabourElementModal={setIsModalVisible}
        mode={'edit'}
        labourElementDetails={labourElementDetails}
      />
    </div>
  )
}
